import { DocumentTemplateType } from '../../api-client/autogenerated';
import { isPublicPage } from '../../scripts/utils';
import { RootState } from '../../store/root-state';

export const getDocumentsState = (state: RootState) => state.documents.documents;

export const getSelectedSection = (state: RootState) => state.documents.section;

export const getSelectedDivision = (state: RootState) => state.documents.division;

export const getDocumentsType = (state: RootState) => {
  if (isPublicPage()) {
    const temp = state.document.document?.documentTemplate?.name as DocumentTemplateType;
    if (temp === DocumentTemplateType.SubmittalPackages) return DocumentTemplateType.Submittals;
    if (temp === DocumentTemplateType.CloseoutSubmittalPackages)
      return DocumentTemplateType.CloseoutSubmittals;
    return temp;
  }
  return state.documents.type;
};

export const getSubmittalSections = (state: RootState) => state.documents.submittalSections;
