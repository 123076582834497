import { ApiClient } from '../../api-client/api-client';
import { ProcoreDocumentType } from '../../api-client/autogenerated';

export const getProcoreProjects = (centerLineProjectId: string) => {
  return ApiClient.getProcoreProjects({ centerLineProjectId }).then((res) => res.data);
};

export const beginSyncRfis = (centerLineProjectId: string) => {
  try {
    ApiClient.syncProcoreDocuments({
      centerLineProjectId,
      documentType: ProcoreDocumentType.Rfi,
    }).then((res) => console.log(`Synced successfully.`));
  } catch (e: any) {
    console.warn(e);
  }
};

export const beginSyncSubmittals = (centerLineProjectId: string) => {
  try {
    ApiClient.syncProcoreDocuments({
      centerLineProjectId,
      documentType: ProcoreDocumentType.Submittal,
    }).then((res) => console.log(`Synced successfully.`));
  } catch (e: any) {
    console.warn(e);
  }
};

export const beginSyncSubmittalPackages = (centerLineProjectId: string) => {
  try {
    ApiClient.syncProcoreDocuments({
      centerLineProjectId,
      documentType: ProcoreDocumentType.SubmittalPackage,
    }).then((res) => console.log(`Synced successfully.`));
  } catch (e: any) {
    console.warn(e);
  }
};

export const beginSyncSubmittalsAndPackages = (centerLineProjectId: string) => {
  beginSyncSubmittals(centerLineProjectId);
  beginSyncSubmittalPackages(centerLineProjectId);
};

export const beginSyncPunchLists = (centerLineProjectId: string) => {
  // TODO: Fill in this function (there's no ProcoreDocumentType.PunchList so not sure how yet)
};
