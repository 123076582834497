import React from 'react';
import { Checkbox, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  company?: string | null;
  name?: string | null;
  horizontal?: boolean;
  style?: React.CSSProperties;
  checked?: boolean;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  name: {
    fontWeight: 'normal',
    fontSize: 15,
    // color: '#464546', // gray 700
  },
  company: {
    fontWeight: 700,
    fontSize: 16,
    // color: '#949494', // gray
  },
});

export default function AssignedUserText(props: Props) {
  const classes = useStyles();
  const { company, name, horizontal, style } = props;

  return (
    <div
      className={classes.container}
      style={{ flexDirection: horizontal ? 'row' : 'column', ...style }}
    >
      {company && (
        <Typography className={classes.company} style={{ marginRight: horizontal ? 4 : 0 }}>
          {company}
        </Typography>
      )}
      <Typography className={classes.name}>{name}</Typography>
    </div>
  );
}

export function AssignedUserTextMC(props: Props) {
  const classes = useStyles();
  const { company, name, horizontal, style, checked } = props;

  return (
    <div
      className={classes.container}
      style={{ width: '100%', justifyContent: 'space-between', ...style }}
    >
      <div>
        {company && (
          <Typography className={classes.company} style={{ marginRight: horizontal ? 4 : 0 }}>
            {company}
          </Typography>
        )}
        <Typography className={classes.name}>{name}</Typography>
      </div>
      <div>
        <Checkbox style={{ alignSelf: 'right', color: '#0947B9' }} checked={checked} />
      </div>
    </div>
  );
}
