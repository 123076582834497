import logoSvg from '../../images/wordmark-blue.svg';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import '../../css/punch-list-print.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllProjectUsersState,
  getPhysicalBuildingsState,
  getPhysicalFloorsState,
  getPhysicalLocationsState,
  getProjectState,
} from '../../features/project/selectors';
import { getDocumentsState } from '../../features/documents/selectors';
import {
  DocumentTemplateType,
  INumberedDocumentView,
  IPhysicalBuilding,
  IPhysicalFloor,
  IPhysicalLocation,
  IProjectUser,
  IUser,
  IUserGroup,
  PunchListStatusType,
} from '../../api-client/autogenerated';
import { getPunchListItemFromDocument } from '../punch-lists/CreatePunchListPage';
import { PunchListItem } from '../punch-lists/CreatePunchListPageItem';
import {
  createQRCode,
  DocumentOrLocationMatchParams,
  formatMoney,
  getImageByFileId,
  sortLastNamesDesc,
} from '../../scripts/utils';
import ImageMarker, { MarkerComponentProps } from 'react-image-marker';
import PunchListFlagIcon from '../icons/PunchListFlag-icon';
import { getColorFromPunchListStatus } from '../punch-lists/ImageWithMarkers';
import { getUsersFromUserGroup } from '../design/ManagePermissionsDialog';
import { getGroupsState } from '../../features/groups/selector';
import Comment from '../comment/Comment';
import { getImageRows } from './printing-utils';
import { getUserState } from '../../features/user/selectors';
import { useParams } from 'react-router';
import { fetchDocument } from '../../features/document/actions';
import { fetchDocumentsByType } from '../../features/documents/actions';
import { getDocumentState } from '../../features/document/selectors';
import { getDocumentPublicLink } from '../../models/api/users';
import Button from '@material-ui/core/Button';
import FullscreenLoader from '../loader/FullscreenLoader';
import { CancelButton } from '../custom-components/CustomButtons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Print } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

type Props = {
  mode: 'ITEM' | 'LOCATION' | 'ALL';
};

const punchListStatusToText: Record<PunchListStatusType, string> = {
  [PunchListStatusType.New]: 'New',
  [PunchListStatusType.InProgress]: 'In Progress',
  [PunchListStatusType.ReadyForVerification]: 'Ready for Verification',
  [PunchListStatusType.Rejected]: 'Rejected',
  [PunchListStatusType.Accepted]: 'Accepted',
};

const getRowsFromPunchListItem = ({
  punchListItem,
  location,
  locationImageFile,
  building,
  floor,
  defaultNotificationGroupUsers,
  projectUsers,
  groups,
  link,
}: {
  punchListItem: PunchListItem;
  defaultNotificationGroupUsers: IUser[];
  location?: IPhysicalLocation;
  locationImageFile?: string;
  building?: IPhysicalBuilding;
  floor?: IPhysicalFloor;
  projectUsers: IProjectUser[];
  groups: IUserGroup[];
  link: string;
}) => {
  let users = [...defaultNotificationGroupUsers];
  punchListItem.associatedUserIds.forEach((userId) => {
    const newUser = projectUsers.find((pUser) => pUser.userId === userId)?.user;
    if (newUser) users.push(newUser);
  });
  punchListItem.associatedUserGroupIds.forEach((groupId) => {
    const group = groups.find((group) => group.id === groupId);
    if (group) users.push(...getUsersFromUserGroup(group));
  });
  // get unique users by id
  users = Array.from(new Set(users.map((u) => u.id)))
    .map((id) => users.find((u) => u.id === id)!)
    .sort((a, b) => sortLastNamesDesc(a.name, b.name));

  return (
    <div className="page-break">
      <div className="divider" />

      <div style={{ display: 'flex' }}>
        <TableContainer className="nobreak mid-section">
          <Table className="no-padding-table">
            <TableRow>
              <TableCell>
                <Typography variant="body1">Building</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{building?.name}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="body1">Floor</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{floor?.name}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="body1">Location</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{location?.name}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="body1">Description</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{punchListItem.generalObservation}</Typography>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
        <div
          ref={(ref) => {
            if (ref)
              createQRCode({
                qrcodeRef: ref,
                link,
                width: 112,
                height: 112,
                drawer: 'svg',
              });
          }}
        />
      </div>
      <div className="mid-section">
        <Typography variant="body1" className="row-subtitle">
          Notification group for this item
        </Typography>
        <TableContainer>
          <Table className="table-with-lines">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body1">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">Company</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">Email</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Typography variant="body1">{user.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {user.company?.name || 'Unknown Company'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{user.email}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TableContainer className="mid-section">
        <Table className="fixed-width-table table-with-lines">
          <TableRow>
            <TableCell>
              <Typography variant="body1">
                <b>Date of Observation:</b> {punchListItem.dateOfObservation?.format('M/DD/YYYY')}
              </Typography>
              <Typography variant="body1">
                <b>Status:</b> {punchListStatusToText[punchListItem.status]}
              </Typography>
              <Typography variant="body1">
                <b>Cost:</b> {formatMoney(punchListItem.cost)}
              </Typography>
              <Typography variant="body1">
                <b>Responsible Subcontractor:</b> {punchListItem.responsibleSubcontractor}
              </Typography>
              <Typography variant="body1">
                <b>Work in progress:</b> {punchListItem.workInProgress}
              </Typography>
              <Typography variant="body1">
                <b>Last Modified:</b> {punchListItem.lastModified.format('M/DD/YYYY')}
              </Typography>
              <Typography variant="body1">
                <b>Created On:</b> {punchListItem.postedDate.format('M/DD/YYYY')}
              </Typography>
              {punchListItem.comments.map((c) => (
                <Comment key={c.id} comment={c} hideIcon></Comment>
              ))}
            </TableCell>
            {/*ImageMarker can have annoying overflow which needs to be hidden*/}
            <TableCell style={{ overflow: 'hidden' }}>
              {locationImageFile != null && punchListItem.markedLocation != null && (
                <>
                  <Typography variant="body1" className="row-subtitle">
                    Marked item location
                  </Typography>
                  <ImageMarker
                    src={locationImageFile}
                    markers={[
                      {
                        left: punchListItem.markedLocation.x * 100,
                        top: punchListItem.markedLocation.y * 100,
                      },
                    ]}
                    markerComponent={(props: MarkerComponentProps) => (
                      <PunchListFlagIcon
                        transformed
                        color={getColorFromPunchListStatus(punchListItem.status)}
                        width={25}
                        height={37}
                      />
                    )}
                  />
                </>
              )}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
      {punchListItem.images.length > 0 && (
        <div className="mid-section image-section">
          <Typography variant={'body1'} className="row-subtitle">
            Photo documentation
          </Typography>
          <TableContainer>
            <Table className="fixed-width-table table-with-lines">
              {Array.from(getImageRows(punchListItem.images))}
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

const getTableRowsFromDocuments = async (
  documents: INumberedDocumentView[],
  locations: IPhysicalLocation[],
  buildings: IPhysicalBuilding[],
  floors: IPhysicalFloor[],
  defaultNotificationGroupUsers: IUser[],
  projectUsers: IProjectUser[],
  groups: IUserGroup[],
) => {
  const punchListItemRowData = await Promise.all(
    documents.map(async (doc) => {
      const punchListItem = await getPunchListItemFromDocument(doc, false);
      const location = locations.find((loc) => loc.id === punchListItem.locationId);
      const floor = floors.find((floor) => floor.id === location?.floorId);
      const building = buildings.find((building) => building.id === floor?.buildingId);
      let locationImageFile: string | undefined;
      if (location?.locationImageFileId) {
        locationImageFile = await getImageByFileId(location.locationImageFileId, false);
      }
      const link = await getDocumentPublicLink(doc.id);
      return { punchListItem, location, floor, building, locationImageFile, link };
    }),
  );
  return (
    <>
      {punchListItemRowData.map((rowData) => {
        return getRowsFromPunchListItem({
          ...rowData,
          defaultNotificationGroupUsers,
          projectUsers,
          groups,
        });
      })}
    </>
  );
};

export default function PunchListPrintView(props: Props) {
  const { mode } = props;

  const { documentId, locationId } = useParams<DocumentOrLocationMatchParams>();

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const selectedProject = useSelector(getProjectState);
  const groups = useSelector(getGroupsState);
  const currentDocument = useSelector(getDocumentState);
  const documents = useSelector(getDocumentsState);
  const buildings = useSelector(getPhysicalBuildingsState);
  const locations = useSelector(getPhysicalLocationsState);
  const floors = useSelector(getPhysicalFloorsState);
  const projectUsers = useSelector(getAllProjectUsersState);

  const [tableRows, setTableRows] = useState<JSX.Element | null>(null);
  const [defaultNotificationGroupUsers, setDefaultNotificationGroupUsers] = useState<IUser[]>([]);

  const currentLocation = locations.find((l) => l.id === locationId);
  const locationDocuments = documents.filter((d) => d.physicalLocationId === locationId);
  const publishedDocuments = documents.filter((d) => !d.isDraft);

  const handleGoBack = () => {
    if (mode === 'ALL') {
      history.push(
        `${history.location.pathname.substring(0, history.location.pathname.lastIndexOf('/'))}`,
      );
    } else {
      history.push(
        `${history.location.pathname.substring(0, history.location.pathname.lastIndexOf('/'))}${
          mode === 'ITEM' ? '/edit' : '/edit-location'
        }`,
      );
    }
  };

  const getTitle = () => {
    if (mode === 'ALL') {
      return `Printed on ${new Date().toLocaleString()}`;
    }

    if (mode === 'ITEM') {
      return currentDocument?.description;
    }

    return currentLocation?.name;
  };

  const getFileNameTitle = () => {
    if (mode === 'ALL') {
      return new Date().toLocaleString();
    }

    if (mode === 'ITEM') {
      return currentDocument?.description;
    }

    return currentLocation?.name;
  };

  const printFileName = `${selectedProject?.name} Punch List ${getFileNameTitle()}`;

  useEffect(() => {
    if (selectedProject?.punchListNotificationGroupId) {
      const defaultGroup = groups.find(
        (g) => g.id === selectedProject.punchListNotificationGroupId,
      );
      if (defaultGroup) setDefaultNotificationGroupUsers(getUsersFromUserGroup(defaultGroup));
    }
  }, [selectedProject?.punchListNotificationGroup]);

  useEffect(() => {
    if (!selectedProject) return;

    if (mode === 'ITEM' && documentId) {
      dispatch(fetchDocument(documentId));
    } else if ((mode === 'LOCATION' && locationId) || mode === 'ALL') {
      dispatch(fetchDocumentsByType(selectedProject.id, DocumentTemplateType.PunchList));
    }
  }, [selectedProject, mode, documentId, locationId]);

  useEffect(() => {
    if (mode === 'ITEM' && !currentDocument) return;

    if (mode === 'LOCATION' && !locationId) return;

    let docs: INumberedDocumentView[];

    if (mode === 'ITEM') {
      docs = [currentDocument!];
    } else if (mode === 'LOCATION') {
      docs = locationDocuments;
    } else {
      docs = publishedDocuments;
    }

    getTableRowsFromDocuments(
      docs,
      locations,
      buildings,
      floors,
      defaultNotificationGroupUsers,
      projectUsers,
      groups,
    ).then(setTableRows);
  }, [
    documents,
    currentDocument,
    locationId,
    buildings.length,
    documents.length,
    floors.length,
    locations.length,
    defaultNotificationGroupUsers.length,
    projectUsers.length,
    groups.length,
  ]);

  const handlePrint = () => {
    const originalTitle = document.title;
    document.title = `${printFileName} Summary`;
    window.print();
    document.title = originalTitle;
  };

  return (
    <main className="punch-list-print-view">
      <CancelButton
        startIcon={<ArrowBackIcon />}
        onClick={handleGoBack}
        style={{ margin: '8px 16px 8px 0px' }}
      >
        Cancel
      </CancelButton>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Print />}
        onClick={handlePrint}
        style={{ width: 160, marginRight: 16, whiteSpace: 'nowrap' }}
      >
        Save as PDF
      </Button>
      <br />
      <img src={logoSvg} id="centerline-logo" alt="centerline-logo" className="logo" />
      <Typography>Printed on {new Date().toLocaleString()}</Typography>
      <Table className="no-padding-table header-table white-background">
        <TableRow>
          <TableCell>
            <Typography variant="h2">Created By:</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h2">{user.company?.name}</Typography>
            <Typography variant="h2">{user.name}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="h2">Project:</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h2">{selectedProject?.name}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="h2">Punch List:</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h2">{getTitle()}</Typography>
          </TableCell>
        </TableRow>
      </Table>
      {tableRows || <FullscreenLoader />}
    </main>
  );
}
