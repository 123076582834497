import React, { SVGProps } from 'react';

type Props = {
  color: 'red' | 'green' | 'blue';
  transformed?: boolean;
} & SVGProps<any>;

export default function PunchListFlagIcon(props: Props) {
  const { color, transformed, style, ...rest } = props;

  const getFill = () => {
    switch (color) {
      case 'blue':
        return '#0947B9';

      case 'green':
        return '#2BB073';

      default:
      case 'red':
        return '#ED3F26';
    }
  };

  const fill = getFill();

  return (
    <svg
      width="25"
      height="37"
      viewBox="0 0 25 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={transformed ? 'translate(0, -37)' : undefined}
      style={transformed ? { transform: 'translateY(-37px)', ...style } : style}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1153 4.04692C14.3123 4.44594 15.4445 4.82337 16.678 4.82337C18.6553 4.82337 20.7118 4.04671 22.3884 3.268C23.4244 2.78685 24.6094 3.54256 24.6094 4.6848V16.5549C24.6094 17.0689 24.3574 17.5513 23.9334 17.8419C22.1835 19.0413 20.3038 19.8369 17.9518 19.8369C16.2129 19.8369 14.7894 19.3624 13.4472 18.915C12.2503 18.516 11.118 18.1386 9.88452 18.1386C7.52163 18.1386 5.84771 18.5833 4.5 19.1339V34.5C4.5 35.6046 3.60457 36.5 2.5 36.5C1.39543 36.5 0.5 35.6046 0.5 34.5V4.31014C0.185101 3.86483 0 3.3213 0 2.73436C0 1.18631 1.28638 -0.0612477 2.84854 0.00232655C4.23857 0.0588695 5.37681 1.17259 5.46333 2.56107C5.48827 2.95269 5.42855 3.34509 5.28823 3.71156C6.30073 3.33905 7.39224 3.12498 8.61074 3.12498C10.3496 3.12498 11.7732 3.59952 13.1153 4.04692Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5401 7.74898C14.7709 7.04382 13.4 6.80565 12.242 7.16752C11.646 7.35378 11.0788 7.70583 10.6617 8.26385C10.2431 8.82398 10 9.55916 10 10.4687C10 12.2363 11.4222 13.4278 13 13.5456V14.9174C13 15.7458 13.6716 16.4174 14.5 16.4174H16.8734C17.1495 16.4174 17.3734 16.1935 17.3734 15.9174C17.3734 15.6413 17.1495 15.4174 16.8734 15.4174H14.5C14.2239 15.4174 14 15.1935 14 14.9174V13.4738C14.5409 13.3533 15.0691 13.0961 15.5344 12.6824C15.7408 12.499 15.7594 12.1829 15.5759 11.9765C15.3924 11.7702 15.0764 11.7516 14.87 11.935C14.5973 12.1775 14.3019 12.3442 14 12.4449V10.4995C14 10.2234 13.7761 9.99951 13.5 9.99951C13.2239 9.99951 13 10.2234 13 10.4995V12.5501C11.9248 12.4299 11 11.5817 11 10.4687C11 9.74266 11.1914 9.22559 11.4627 8.86251C11.7357 8.49732 12.1141 8.25519 12.5403 8.122C13.4268 7.84497 14.407 8.06681 14.8643 8.48611C15.0679 8.67271 15.3842 8.65897 15.5708 8.45542C15.7574 8.25187 15.7436 7.93558 15.5401 7.74898Z"
        fill="white"
      />
    </svg>
  );
}
