import { ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import {
  SET_DIVISION,
  SET_DOCUMENTS,
  SET_DOCUMENTS_TYPE,
  SET_SECTION,
  SET_SUBMITTAL_SECTIONS,
} from './constants';
import * as documentsActions from './actions';
import { Documents, Section } from './documents';
import { DocumentTemplateType, ReducedSubmittalCode } from '../../api-client/autogenerated';

export type DocumentsAction = ActionType<typeof documentsActions>;
export type DocumentsState = {
  documents: Documents;
  type: DocumentTemplateType | null;
  section: Section | null;
  division: string | null;
  submittalSections: ReducedSubmittalCode[];
};

export default combineReducers<DocumentsState, DocumentsAction>({
  documents: (state = [], action) => {
    let newState: Documents;
    switch (action.type) {
      case SET_DOCUMENTS:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
  type: (state = null, action) => {
    let newState: DocumentTemplateType | null;
    switch (action.type) {
      case SET_DOCUMENTS_TYPE:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
  section: (state = null, action) => {
    let newState: Section | null;
    switch (action.type) {
      case SET_SECTION:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
  division: (state = null, action) => {
    let newState: string | null;
    switch (action.type) {
      case SET_DIVISION:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
  submittalSections: (state = [], action) => {
    let newState: ReducedSubmittalCode[];
    switch (action.type) {
      case SET_SUBMITTAL_SECTIONS:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
});
