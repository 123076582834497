import { isPublicPage } from '../../scripts/utils';
import { Dispatch } from 'react';
import { DELETE_GROUP, SET_GROUPS, UPSERT_GROUP } from './constants';
import { Groups } from './groups';
import { action } from 'typesafe-actions';
import { getUserGroupsByProjectId } from '../../models/api/user-groups';
import { IProjectUser, IUserGroup } from '../../api-client/autogenerated';

export const setGroups = (groups: Groups) => {
  return action(SET_GROUPS, groups);
};

export const upsertGroup = (group: IUserGroup) => {
  return action(UPSERT_GROUP, group);
};

export const deleteGroup = (group: IUserGroup) => {
  return action(DELETE_GROUP, group);
};

export const fetchGroups = (projectId: string, projectUserList: IProjectUser[]) => (
  dispatch: Dispatch<any>,
) => {
  if (!isPublicPage()) {
    getUserGroupsByProjectId(projectId).then((response) =>
      dispatch({
        type: SET_GROUPS,
        payload: response.map((userGroup) => {
          return {
            ...userGroup,
            userGroupCompanyList: userGroup.userGroupCompanyList?.map((companyGroup) => {
              return {
                ...companyGroup,
                company: {
                  ...companyGroup.company,
                  users: companyGroup.company?.users?.filter(({ id }) =>
                    projectUserList.some(({ userId }) => userId === id),
                  ),
                },
              };
            }),
          };
        }),
      }),
    );
  }
};
