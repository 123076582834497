import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import NavAppbar from '../../nav-top/NavAppbar';
import PageTitle from '../../page-title/PageTitle';
import { useParams } from 'react-router-dom';
import { ProjectMatchParams } from '../../../scripts/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectId } from '../../../features/project/selectors';
import {
  getProjectLoadingState,
  getSecurityLoadingState,
} from '../../../features/loading/selectors';
import { fetchProject } from '../../../features/project/actions';
import { IUserGroup } from '../../../api-client/autogenerated';
import { Card, TableCell } from '@material-ui/core';
import { getGroupsState } from '../../../features/groups/selector';
import { deleteGroup, upsertGroup } from '../../../features/groups/actions';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import UserGroupTableRow from './UserGroupTableRow';
import Button from '@material-ui/core/Button';
import CreateUserGroup from './CreateUserGroup';
import { ascendingComparator } from '../../document-index/DocumentIndexUtils';
import { getCurrentSecurityGroup } from '../../../features/security/selectors';
import { useHistory } from 'react-router';
import { hasAdminPermissions } from '../../../scripts/store-utils';
import TableRow from '@material-ui/core/TableRow';
import { ManagePermissionsDialogType } from '../../design/ManagePermissionsDialog';
import { deleteUserGroupById } from '../../../models/api/user-groups';
import { addSnackbar } from '../../../features/snackbar/actions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: '100%',
    height: '100%',
  },
}));

export default function ManageUserGroupsPage() {
  const classes = useStyles();

  const projectId = useSelector(getProjectId);
  const projectLoading = useSelector(getProjectLoadingState);
  const groups = useSelector(getGroupsState);
  const security = useSelector(getCurrentSecurityGroup);
  const securityLoading = useSelector(getSecurityLoadingState);
  const history = useHistory();
  const dispatch = useDispatch();

  const params = useParams<ProjectMatchParams>();
  useEffect(() => {
    if (params.projectId && params.projectId !== projectId && !projectLoading)
      dispatch(fetchProject(params.projectId));
  }, [params.projectId]);

  useEffect(() => {
    if (!securityLoading && !hasAdminPermissions() && (!security || !security.canModifyUserGroups))
      history.push('/main');
  }, [security]);

  const [willCreate, setWillCreate] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<IUserGroup | null>(null);

  const handleSubmit = (group: IUserGroup) => {
    dispatch(upsertGroup(group));
    setWillCreate(false);
    setSelectedGroup(null);
  };

  const handleDelete = async (group: IUserGroup) => {
    const proceed = window.confirm(
      `Are you sure you want to delete the user group: ${group.name}?`,
    );
    if (proceed) {
      await deleteUserGroupById(group.id);
      dispatch(deleteGroup(group));
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: `${group.name} successfully deleted!`,
          severity: 'success',
        }),
      );
    }
  };

  return (
    <main className={classes.root}>
      <NavAppbar />
      <Grid container direction="column">
        <PageTitle title="Manage User Groups" />
        <Card className={classes.paper}>
          <Table style={{ border: '1px solid rgba(201, 200, 201, .8)' }}>
            <TableHead>
              <TableCell style={{ width: '90px' }} />
              <TableCell style={{ width: '250px' }}>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell style={{ width: '200px' }}># of Users</TableCell>
              <TableCell style={{ width: '200px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSelectedGroup(null);
                    setWillCreate(true);
                  }}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Create New Group
                </Button>
              </TableCell>
            </TableHead>
            <TableBody>
              {groups.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5}>No existing groups</TableCell>
                </TableRow>
              )}
              {groups
                .sort((a, b) => ascendingComparator(a, b, 'name'))
                .map((group, index) => (
                  <UserGroupTableRow
                    key={group.id}
                    group={group}
                    index={index}
                    editOnClick={setSelectedGroup}
                    deleteOnClick={handleDelete}
                  />
                ))}
            </TableBody>
          </Table>
          {(selectedGroup || willCreate) && (
            <Grid item xs={12} style={{ marginTop: 32 }}>
              <PageTitle
                title={selectedGroup ? `Editing ${selectedGroup.name}` : 'Create New Group'}
              />
              <div style={{ maxWidth: '900px' }}>
                <CreateUserGroup
                  existingGroup={selectedGroup}
                  onSubmit={handleSubmit}
                  type={ManagePermissionsDialogType.None}
                />
              </div>
            </Grid>
          )}
        </Card>
      </Grid>
    </main>
  );
}
