import React, { useState } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import { Checkbox, LinearProgress } from '@material-ui/core';
import { FileNode } from '../../api-client/autogenerated';
import { deleteTrashItemsByProjectId, emptyTrashByProjectId } from '../../models/api/filesystem';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import { addSnackbar } from '../../features/snackbar/actions';

const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    headerText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '22px',
      textTransform: 'uppercase',
    },
    disableDefaultSortIcon: {
      display: 'none',
    },
    sortIcon: {
      display: 'flex',
      flexDirection: 'column',
      height: '22px',
      paddingTop: '1px',
      marginLeft: '8px',
    },
    sortIconEnabled: {
      color: '#616061',
    },
    sortIconDisabled: {
      color: '#B2B1B2',
    },
  }),
);

export interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
}

interface DocumentIndexHeaderProps {
  type: 'Project Files' | 'BIM360';
  orderBy: string;
  columnTitles: HeadCell[];
  setOrderBy: (str: string) => void;
  download: () => Promise<void>;
  progress: number;
  total: number;
  selected: string[];
  children: FileNode[];
  handleSelectAll: () => void;
  disableSelectAll?: boolean;
  isRecycleBin?: boolean;
}

const FilesystemHeader = (props: DocumentIndexHeaderProps) => {
  const {
    type,
    orderBy,
    columnTitles,
    setOrderBy,
    download,
    progress,
    total,
    selected,
    children,
    handleSelectAll,
    disableSelectAll,
    isRecycleBin,
  } = props;

  const classes = useStyles();

  const dispatch = useDispatch();
  const project = useSelector(getProjectState);

  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      await download();
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTrash = async () => {
    if (!project) return;
    if (selected.length > 0) {
      const proceed = window.confirm(
        `Are you sure you want to permanently delete ${selected.length} file(s)?`,
      );
      if (!proceed) return;
      const response = await deleteTrashItemsByProjectId(project.id, selected);
      const failedDeletions = response.isSuccessfullyDeleted.filter((r) => !r);
      if (failedDeletions.length > 0) {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: `${failedDeletions.length} file(s) were not successfully deleted`,
            severity: 'error',
          }),
        );
      } else {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: `${response.isSuccessfullyDeleted} files were successfully deleted`,
            severity: 'success',
          }),
        );
      }
    } else {
      const proceed = window.confirm(
        'Are you sure you want to permanently delete every file in the Recycle Bin?',
      );
      if (!proceed) return;
      await emptyTrashByProjectId(project.id);
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: `Recycle bin emptied successfully`,
          severity: 'success',
        }),
      );
    }
  };

  const checked = children.every((n) => selected.includes(n.fullKey));
  const indeterminate = !checked && selected.length > 0;

  return (
    <TableHead>
      <TableRow style={{ borderTop: '1px solid #EDECEC', height: 48 }}>
        {columnTitles.map((headCell) => {
          if (headCell.id === 'select')
            return (
              <TableCell
                key={headCell.id}
                align="center"
                padding={headCell.disablePadding ? 'none' : 'default'}
              >
                {!disableSelectAll && (
                  <Checkbox
                    checked={checked}
                    indeterminate={indeterminate}
                    onChange={handleSelectAll}
                    style={{
                      padding: '0px 0px 0px 8px',
                      display: children.length === 0 ? 'none' : 'inline-flex',
                    }}
                  />
                )}
              </TableCell>
            );
          if (headCell.id === 'view' && type !== 'BIM360') {
            if (isRecycleBin) {
              return (
                <TableCell
                  key={headCell.id}
                  align="center"
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  style={{ whiteSpace: 'nowrap', border: 'none', width: 160 }}
                >
                  <Button
                    color="primary"
                    size="small"
                    disabled={children.length === 0}
                    onClick={handleDeleteTrash}
                    style={{
                      whiteSpace: 'nowrap',
                      paddingLeft: 6,
                      paddingRight: 6,
                      color: '#ED3F26',
                    }}
                  >
                    {selected.length > 0 ? 'Delete Selected' : 'Empty Recycle Bin'}
                  </Button>
                </TableCell>
              );
            }
            return (
              <TableCell
                key={headCell.id}
                align="center"
                padding={headCell.disablePadding ? 'none' : 'default'}
                style={{ whiteSpace: 'nowrap', border: 'none', width: 160 }}
              >
                {isLoading ? (
                  <LinearProgress
                    variant="determinate"
                    value={(progress / (total || 1)) * 100}
                    style={{ marginLeft: 8 }}
                  />
                ) : (
                  <Button
                    color="primary"
                    size="small"
                    disabled={children.length === 0}
                    onClick={handleDownload}
                    style={{ whiteSpace: 'nowrap', paddingLeft: 6, paddingRight: 6 }}
                  >
                    {selected.length > 0 ? 'Download Selected' : 'Download All'}
                  </Button>
                )}
              </TableCell>
            );
          }
          return (
            <TableCell
              key={headCell.id}
              align={
                headCell.id === 'sharing' || headCell.id === 'options' || headCell.id === 'log'
                  ? 'center'
                  : 'left'
              }
              padding={headCell.disablePadding ? 'none' : 'default'}
              style={{ whiteSpace: 'nowrap', border: 'none', maxWidth: 150 }}
            >
              <TableSortLabel
                onClick={() => {
                  setOrderBy(headCell.id);
                }}
                active={orderBy === headCell.id}
                classes={{
                  root: classes.headerText,
                  icon: classes.disableDefaultSortIcon,
                }}
                // Using inline style here because other approaches won't overwrite color
                style={{
                  color: '#616061',
                }}
              >
                {headCell.label}
                {headCell.label !== '' &&
                headCell.label !== 'Options' &&
                headCell.label !== 'Activity Log' ? (
                  <div className={classes.sortIcon}>
                    <FontAwesomeIcon
                      icon={faCaretUp}
                      style={{ marginBottom: '-3px' }}
                      className={
                        orderBy === headCell.id ? classes.sortIconEnabled : classes.sortIconDisabled
                      }
                    />
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      style={{ marginTop: '-3px' }}
                      className={
                        orderBy === headCell.id ? classes.sortIconEnabled : classes.sortIconDisabled
                      }
                    />
                  </div>
                ) : (
                  <div />
                )}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default FilesystemHeader;
