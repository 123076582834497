import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import pluralize from 'pluralize';
import { useDispatch, useSelector } from 'react-redux';
import CircularLoader from '../loader/CircularLoader';
import FavoriteProjectsDialog from '../dialogs/FavoriteProjectsDialog';
import OptionsIcon from '../icons/Options-icon';
import {
  IProjectView,
  ProjectPhaseType,
  ProjectSummaryType,
  SecurityPermissionLevel,
} from '../../api-client/autogenerated';
import { favoriteProject } from '../../models/api/users';
import { getProjectState } from '../../features/project/selectors';
import getProjectsState from '../../features/projects/selectors';
import { getProjectLoadingState } from '../../features/loading/selectors';
import { getUserState } from '../../features/user/selectors';
import { updateUser } from '../../features/user/actions';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { getSecurityGroupsState } from '../../features/security/selectors';
import { getFavoriteProjects, getProjectSummaryById } from '../../models/api/project';
import { formatDate, parseDate, sleep } from '../../scripts/utils';
import dayjs from 'dayjs';

interface DashboardCountdownProps {
  maxHeight?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      top: '83px',
      flexGrow: 1,
      // shadow override to fix misplaced shadow on the countdown
      boxShadow: 'none',
      [theme.breakpoints.up('xs')]: {
        //height:'120px'
      },
    },
    titleStyle: {
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
    },
    favoriteProjects: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      letterSpacing: '1.21333px',
      textTransform: 'uppercase',
      color: '#0947B9',
    },
    topProjects: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '22px',
      color: '#464546',
    },
    table: {
      maxWidth: '100%',
      overflow: 'hidden',
      height: 'fit-content',
    },
    projectName: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      letterSpacing: '1.21333px',
      textTransform: 'uppercase',
      color: '#464546',
      cursor: 'pointer',
      [theme.breakpoints.down(425)]: {
        fontSize: '14px',
      },
      [theme.breakpoints.down(375)]: {
        fontSize: '13px',
      },
    },
    completionDate: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      color: '#949494',
      [theme.breakpoints.down(600)]: {
        fontSize: '13px',
      },
      [theme.breakpoints.down(425)]: {
        fontSize: '12px',
      },
      [theme.breakpoints.down(375)]: {
        fontSize: '11px',
      },
    },
    progressBar: {
      height: '20px',
      // only display on desktop and above because this breaks the grid on smaller screens
      [theme.breakpoints.up('lg')]: {
        width: '350px',
      },
      width: 'auto',
      borderRadius: '4px',
      background: '#C4C4C4',
    },
    progressFiller: {
      height: '100%',
      borderRadius: 'inherit',
      transition: 'width .2s ease-in',
    },
    progressNumberStyling: {
      fontFamily: 'Roboto',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '32px',
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down(600)]: {
        fontSize: '18px',
      },
      [theme.breakpoints.down(425)]: {
        fontSize: '17px',
      },
      [theme.breakpoints.down(375)]: {
        fontSize: '16px',
      },
    },
    completionDateStyling: {
      fontFamily: 'Roboto',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#464546', // Gray / Gray 700
      [theme.breakpoints.down(600)]: {
        marginLeft: '16px',
      },
      [theme.breakpoints.down(425)]: {
        fontSize: '12px',
      },
      [theme.breakpoints.down(375)]: {
        fontSize: '11px',
      },
    },
    contentPadding: {
      padding: 10,
    },
    iconPadding: {
      padding: 5,
    },
    linkText: {
      fontFamily: 'Roboto',
      fontSize: '15px',
      lineHeight: '30px',
      fontStyle: 'normal',
      fontWeight: 500,
      textTransform: 'none',
      whiteSpace: 'nowrap',
      color: '#464546', // Gray / Gray 700
    },
    TableRow: {
      [theme.breakpoints.down(600)]: {
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
      },
    },
  }),
);

export default function DashboardCountdown(props: DashboardCountdownProps) {
  const classes = useStyles();
  const { maxHeight } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUserState);
  const projects = useSelector(getProjectsState);
  const selectedProject = useSelector(getProjectState);
  const isLoading = useSelector(getProjectLoadingState);
  const security = useSelector(getSecurityGroupsState);
  const favoriteProjectIds =
    user.projectUserList
      ?.filter((projectUser) => projectUser.isFavorite)
      .map((projectUser) => projectUser.projectId) || [];

  const [favoriteProjectsDialogOpen, setFavoriteProjectDialogOpen] = useState(false);
  const [favoriteProjects, setFavoriteProjects] = useState<IProjectView[]>([]);
  const [projectSummaries, setProjectSummaries] = useState<ProjectSummaryType[]>([]);
  const [permissions, setPermissions] = useState<
    {
      id: string;
      constructionPermission?: SecurityPermissionLevel | null;
      bidPermission?: SecurityPermissionLevel | null;
    }[]
  >([]);

  const fetchFavoriteProjects = () => {
    return getFavoriteProjects().then(({ favoriteProjects, projectSummaries }) => {
      setFavoriteProjects(favoriteProjects);
      setProjectSummaries(projectSummaries);
    });
  };

  useEffect(() => {
    if (favoriteProjects.length > 0) {
      const newPermissions = favoriteProjects.map((project) => {
        const group = security.find(
          (s) =>
            s.id ===
            user.projectUserList?.find((pUser) => pUser.userId === user.id)?.securityGroupId,
        );
        return {
          id: project.id,
          constructionPermission: group?.constructionManagementPermission,
          bidPermission: group?.bidManagementPermission,
        };
      });
      setPermissions(newPermissions);
    }
  }, [favoriteProjects]);

  const handleFavoriteProjectDialogOpen = () => {
    setFavoriteProjectDialogOpen(true);
  };

  const handleFavoriteProjectDialogClose = () => {
    setFavoriteProjectDialogOpen(false);
  };

  const handleProjectsSelect = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    projectIds: string[],
  ) => {
    await Promise.all([
      favoriteProjects.map(async ({ id }) => {
        if (!projectIds.includes(id)) return favoriteProject(user.id, id, false);
      }),
      projectIds.map(async (id) => {
        if (!favoriteProjectIds.includes(id)) return favoriteProject(user.id, id, true);
      }),
    ]);

    setTimeout(fetchFavoriteProjects, 100);

    //Local editing
    const newProjectUserList = user.projectUserList?.map((projectUser) => {
      const u = { ...projectUser };
      u.isFavorite = projectIds.includes(projectUser.projectId);
      return u;
    });
    dispatch(updateUser({ ...user, projectUserList: newProjectUserList }));
  };

  const fetchCurrentProjectSummary = async (projectId: string) => {
    const results = await getProjectSummaryById(projectId);
    setProjectSummaries([results]);
  };

  useEffect(() => {
    if (!selectedProject) {
      fetchFavoriteProjects();
    } else {
      fetchCurrentProjectSummary(selectedProject.id);
      setFavoriteProjects([selectedProject]);
    }
  }, [selectedProject]);

  const getContent = () => {
    return selectedProject ? (
      <></>
    ) : (
      <CardContent>
        <Grid container spacing={1} justify="flex-start" alignItems="center">
          <Grid item>
            <Typography className={classes.favoriteProjects}>Favorite Projects</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Set Favorites" arrow placement="right">
              <IconButton onClick={handleFavoriteProjectDialogOpen} className={classes.iconPadding}>
                <OptionsIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <FavoriteProjectsDialog
          dialogOpen={favoriteProjectsDialogOpen}
          handleClose={handleFavoriteProjectDialogClose}
          handleApply={handleProjectsSelect}
          initialFavoriteProjectIds={favoriteProjects.map((p) => p.id)}
        />

        <Typography className={classes.topProjects}>
          {projects.length > 0 ? (
            favoriteProjects.length > 0 ? (
              <span>Displaying up to 5 of your top projects.</span>
            ) : (
              <span>You have no favorite projects. Press the gear icon above to set them.</span>
            )
          ) : (
            <span>You have no projects.</span>
          )}
        </Typography>
      </CardContent>
    );
  };

  const getProgressText = (row: IProjectView, progress: number, daysToCompletion: number) => {
    if (!row.noticeToProceedDate) return 'NTP Date Not Set';
    if (!row.scheduledEndDate) return 'Days to Completion Not Set';
    if (row.phase !== ProjectPhaseType.Construction)
      return `Construction starts ${formatDate(row.noticeToProceedDate, true)}`;
    return Number.isNaN(progress)
      ? 'N/A'
      : `${progress}/${pluralize('Day', daysToCompletion, true)}`;
  };

  const getLinks = (row: IProjectView) => {
    const elements = [] as JSX.Element[];
    const bidPermission =
      !!user.isSiteAdmin ||
      !!user.adminOfSubscriberId ||
      permissions.find((p) => p.id === row.id)?.bidPermission === 4;
    const constPermission =
      !!user.isSiteAdmin ||
      !!user.adminOfSubscriberId ||
      permissions.find((p) => p.id === row.id)?.constructionPermission === 4;
    if (row.phase === ProjectPhaseType.Design) {
      if (bidPermission) {
        elements.push(
          <Link
            onClick={() => history.push(`/main/projects/${row.id}/bid-mgmt`)}
            className={classes.linkText}
            style={{ cursor: 'pointer' }}
          >
            Bid Setup
          </Link>,
        );
        if (constPermission)
          elements.push(
            <Typography className={classes.linkText}>&nbsp;·&nbsp;</Typography>,
            <Link
              onClick={() => history.push(`/main/projects/${row.id}/const-mgmt`)}
              className={classes.linkText}
              style={{ cursor: 'pointer' }}
            >
              Construction Setup
            </Link>,
          );
      } else
        elements.push(<Typography className={classes.linkText}>Awaiting Bid Setup</Typography>);
    } else if (constPermission)
      elements.push(
        <Link
          onClick={() => history.push(`/main/projects/${row.id}/const-mgmt`)}
          className={classes.linkText}
          style={{ cursor: 'pointer' }}
        >
          Construction Setup
        </Link>,
      );
    else
      elements.push(
        <Typography className={classes.linkText}>Awaiting Construction Setup</Typography>,
      );
    return elements;
  };

  return (
    <Card className={classes.root} style={{ height: maxHeight ? 'auto' : undefined }}>
      <div className={classes.contentPadding}>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 48,
              height: 'fit-content',
            }}
          >
            <CircularLoader />
          </div>
        ) : (
          <>
            {getContent()}
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableBody>
                  {favoriteProjects
                    .filter((p) => {
                      if (!selectedProject) return true;
                      return p.id === selectedProject.id;
                    })
                    // Sorting function to sort projects alphabetically
                    .sort((project1, project2) => {
                      return project1.name.localeCompare(project2.name);
                    })
                    .slice(0, 5)
                    .map((row) => {
                      const summary = projectSummaries.find((s) => s.projectId === row.id);

                      const startDate = parseDate(
                        row.noticeToProceedDate || row.createdOn!,
                      ).valueOf();
                      let scheduledEndDate = row.scheduledEndDate
                        ? parseDate(row.scheduledEndDate).valueOf()
                        : dayjs().valueOf();
                      if (summary?.daysAdded) scheduledEndDate += summary.daysAdded * 86400000;
                      const currentDate = dayjs().valueOf();
                      const totalTime = scheduledEndDate - startDate;
                      const elapsedTime = currentDate - startDate;
                      const daysToCompletion = Math.round(totalTime / 86400000);
                      const progress = Math.floor((elapsedTime / totalTime) * daysToCompletion);
                      const percentage = progress / daysToCompletion;
                      const date = dayjs(scheduledEndDate).format('MMMM DD, YYYY');

                      return (
                        <TableRow
                          key={row.id}
                          className={classes.TableRow}
                          style={{ height: 'fit-content' }}
                        >
                          <TableCell
                            className={classes.projectName}
                            onClick={() => history.push(`/main/projects/${row.id}`)}
                          >
                            {row.name}
                          </TableCell>
                          {row.phase === ProjectPhaseType.Construction ||
                          row.defaultArchitectUserId ? (
                            <>
                              <TableCell style={{ paddingLeft: 0 }}>
                                <Grid container alignItems="center" style={{ flexWrap: 'nowrap' }}>
                                  <TableCell style={{ paddingLeft: 0 }}>
                                    <Tooltip
                                      title={`${Math.round(percentage * 100)}%`}
                                      arrow
                                      placement="top"
                                    >
                                      <div className={classes.progressBar}>
                                        <div
                                          className={classes.progressFiller}
                                          style={{
                                            width: `${percentage > 1 ? 100 : percentage * 100}%`,
                                            background: percentage >= 1 ? '#ED3F26' : '#0947B9',
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                  </TableCell>
                                  <Typography
                                    className={classes.progressNumberStyling}
                                    style={{
                                      color: percentage >= 1 ? '#ED3F26' : '#464546',
                                    }}
                                  >
                                    {getProgressText(row, progress, daysToCompletion)}
                                    <br />
                                    <div style={{ display: 'flex' }}>
                                      <Typography className={classes.completionDate}>
                                        {`${
                                          row.liquidatedDamagesPerDay
                                            ? (row.liquidatedDamagesPerDay / 100).toLocaleString(
                                                'en-US',
                                                {
                                                  style: 'currency',
                                                  currency: 'USD',
                                                },
                                              )
                                            : 0
                                        } per day LD`}
                                      </Typography>
                                    </div>
                                  </Typography>
                                </Grid>
                              </TableCell>
                              <TableCell
                                padding="none"
                                className={classes.completionDateStyling}
                                style={{ paddingRight: '6px' }}
                              >
                                <Tooltip title="Current Scheduled End Date" arrow placement="top">
                                  <span>{date}</span>
                                </Tooltip>
                              </TableCell>
                            </>
                          ) : (
                            <TableCell colSpan={2} style={{ paddingLeft: 0 }}>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {getLinks(row)}
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    </Card>
  );
}
