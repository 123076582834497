import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { fetchProject } from '../features/project/actions';
import { ProjectMatchParams } from '../scripts/utils';
import NavAppbar from './nav-top/NavAppbar';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh',
    overflowX: 'hidden',
  },
}));

export default function DocumentAccessRestrictedPage() {
  const classes = useStyles();

  const params = useParams<ProjectMatchParams>();
  const dispatch = useDispatch();

  // this makes sure we are on the same project if we refresh the page
  useEffect(() => {
    if (params.projectId) dispatch(fetchProject(params.projectId));
  }, [dispatch, params.projectId]);

  return (
    <main className={classes.content}>
      <NavAppbar />
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
          overflowY: 'hidden',
        }}
      >
        <Typography variant="h6">
          The document you are trying to access has been restricted. Please contact an
          administrator.
        </Typography>
      </Grid>
    </main>
  );
}
