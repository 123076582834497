import React, { useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { ISimplifiedProject } from '../../api-client/autogenerated';
import { deleteProjectById } from '../../models/api/project';
import { makeStyles } from '@material-ui/core/styles';
import { CancelButton } from '../custom-components/CustomButtons';
import { DeleteForeverOutlined, HighlightOffRounded } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

type Props = {
  open: boolean;
  handleClose: () => void;
  refreshSubscriber: () => void;
  project: ISimplifiedProject;
};

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '26px',
      lineHeight: '30px',
      textAlign: 'left',
      textTransform: 'none',
      color: '#0947B9',
    },
    subtitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '21px',
      textAlign: 'left',
      textTransform: 'none',
      color: '#464546',
      whiteSpace: 'nowrap',
    },
    body: {
      fontSize: 16,
    },
    rootIconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    deleteButton: {
      backgroundColor: '#ED3F26',
      '&:hover': {
        backgroundColor: '#ED3F26',
      },
      width: 130,
    },
  }),
);

export default function DeleteProjectDialog(props: Props) {
  const classes = useStyles();
  const { open, handleClose, refreshSubscriber, project } = props;

  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const [buttonText, setButtonText] = useState('3');
  const [willDelete, setWillDelete] = useState(false);
  const [inputProjectName, setInputProjectName] = useState('');

  useEffect(() => {
    setTimeout(() => setButtonText('2'), 1000);
    setTimeout(() => setButtonText('1'), 2000);
    setTimeout(() => {
      setButtonText('Delete');
      setDeleteDisabled(false);
    }, 3000);
  }, []);

  const deleteProject = async () => {
    if (!willDelete) setWillDelete(true);
    else {
      await deleteProjectById(project.id);
      handleClose();
      refreshSubscriber();
    }
  };

  return (
    <Dialog open={open} PaperProps={{ style: { minWidth: 540 } }}>
      <DialogTitle style={{ paddingBottom: 8 }}>
        <Typography className={classes.title}>Deleting Project</Typography>
        {/*<Typography className={classes.subtitle}>{project.name}</Typography>*/}
        <IconButton
          style={{ right: '18px', top: '18px', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.body} style={{ marginBottom: 16 }}>
          You are about to delete <b>{project.name}</b>
        </Typography>
        <Typography className={classes.body} style={{ marginBottom: 16 }}>
          This action will permanently delete all information related to the project.
        </Typography>
        {/*<Typography className={classes.body}>Are you sure you want to continue?</Typography>*/}
        {willDelete && (
          <>
            <Typography className={classes.body} style={{ marginTop: 32, marginBottom: 16 }}>
              Please type the project name (case-sensitive) to proceed.
            </Typography>
            <Typography style={{ fontWeight: 600 }}>{project.name}</Typography>
            <TextField
              variant="outlined"
              value={inputProjectName}
              onChange={(e) => setInputProjectName(e.target.value)}
              fullWidth
              inputProps={{ style: { padding: '14px 10px' } }}
              style={{ marginTop: 8 }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', padding: 16 }}>
        <CancelButton autoFocus onClick={handleClose} />
        <Button
          variant="contained"
          color="primary"
          disabled={deleteDisabled || (willDelete && inputProjectName !== project.name)}
          onClick={deleteProject}
          startIcon={!deleteDisabled && <DeleteForeverOutlined style={{ marginBottom: 1 }} />}
          className={classes.deleteButton}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
