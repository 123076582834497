import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

interface NameCompanyProps {
  name: string;
  company: string;
}

const useStyles = makeStyles({
  name: {
    fontWeight: 'bold',
    color: '#464546', // gray 700
  },
  company: {
    fontStyle: 'italic',
    color: '#949494', // gray
  },
});

export default function NameCompanyText(props: NameCompanyProps) {
  const classes = useStyles();
  const { name, company } = props;
  const isSmall = useMediaQuery('(max-width: 350px)');

  return (
    <div>
      <span className={classes.name}>{name},&nbsp;</span>
      {isSmall && <br />}
      <span className={classes.company}>{company}</span>
    </div>
  );
}
