import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Table,
  TableCell,
  TableHead,
  Tooltip,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { HighlightOffRounded, InsertDriveFileOutlined, Publish } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import PackageListItem from './PackageListItem';
import RefuseReturnIcon from '../../icons/RefuseReturn-icon';
import {
  ActionTakenType,
  DocumentTemplateType,
  IComment,
  IFile,
  INumberedDocumentView,
} from '../../../api-client/autogenerated';
import {
  downloadFileAsConsultant,
  fileIsPdf,
  getDocumentIdentifier,
  getPageTitle,
  openInBluebeam,
  openInNewTab,
  waitForFileToBeVerified,
} from '../../../scripts/utils';
import CircularLoader from '../../loader/CircularLoader';
import { useDispatch, useSelector } from 'react-redux';
import { allowNavigation, blockNavigation } from '../../../features/navigation/actions';
import {
  insertAdditionalReviewByDocumentId,
  submitAdditionalReviewForPackageByDocumentId,
} from '../../../models/api/documents';
import { fetchDocument, reloadDocument } from '../../../features/document/actions';
import { getDocumentState } from '../../../features/document/selectors';
import { addSnackbar } from '../../../features/snackbar/actions';
import PDFIcon from '../../icons/PDF-icon';
import BluebeamFlattenDialog from '../../dialogs/BluebeamFlattenDialog';
import FileUploadDialog from '../../dialogs/FileUploadDialog';
import { getNavigationState } from '../../../features/navigation/selectors';
import { deleteFile } from '../../../models/api/filesystem';
import {
  BluebeamButton,
  CancelButton,
  DownloadButton,
} from '../../custom-components/CustomButtons';
import { getUserState } from '../../../features/user/selectors';
import { getLatestSubmissionFileByDocumentId } from '../../../models/api/files';
import { getDocumentsType } from '../../../features/documents/selectors';

export type SubmittalInfo = {
  document: INumberedDocumentView;
  actionTaken?: ActionTakenType;
  comment?: string;
  existingComments?: IComment[];
};

interface RefusePackageDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  packageDocuments: INumberedDocumentView[];
  reviewDocument?: INumberedDocumentView;
  isConsultantReview?: boolean;
  initialState?: SubmittalInfo[];
  submit?: (review: SubmittalInfo[]) => void;
  refreshNotifications?: () => Promise<void>;
  selectedDocument?: INumberedDocumentView;
}

const useStyles = makeStyles(() =>
  createStyles({
    rootIconButton: {
      padding: '0px 8px 0px 0px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    packageName: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '30px',
      color: '#0947B9', // Blue 700
    },
    separateButton: {
      border: '2px solid #0947B9',
      boxSizing: 'border-box',
      borderRadius: '4px',
      color: '#0947B9', // Red 300
      width: '122px',
      height: '32px',
    },
    dialogue: {
      maxWidth: '735px',
    },
    root: {
      width: '100%',
    },
    dragDropText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '22px',
      lineHeight: '0',
      textAlign: 'center',
      textTransform: 'none',
      color: '#949494', // Gray 400
    },
    browseFileButton: {
      border: '2px solid #0947B9',
      boxSizing: 'border-box',
      borderRadius: '4px',
      color: '#0947B9',
    },
    dropzoneStyling: {
      width: '400px',
      flexShrink: 0,
      background: '#F9F9F9',
      mixBlendMode: 'normal',
      border: '2px dashed #949494',
      boxSizing: 'border-box',
      borderRadius: '4px',
      paddingBottom: 32,
    },
    file: {
      display: 'inline-flex',
      alignItems: 'center',
      marginLeft: 30,
      marginTop: 16,
    },
    fileText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '13px',
      textAlign: 'center',
      textTransform: 'none',
      color: '#7A797A', // Gray 500
      paddingLeft: 8,
    },
    columnLayout: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    rowLayout: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
  }),
);

export default function RefusePackageDialog(props: RefusePackageDialogProps) {
  const classes = useStyles();
  const {
    isOpen,
    handleClose,
    packageDocuments,
    isConsultantReview,
    reviewDocument,
    initialState,
    submit,
    refreshNotifications,
    selectedDocument,
  } = props;

  const dispatch = useDispatch();

  const user = useSelector(getUserState);
  const parentDocument = useSelector(getDocumentState);
  const documentTypeState = useSelector(getDocumentsType)!;
  const shouldBlockNavigation = useSelector(getNavigationState);

  const documentType =
    documentTypeState === DocumentTemplateType.AsBuilt
      ? DocumentTemplateType.AsBuiltPackages
      : documentTypeState;

  const [submittalInfo, setSubmittalInfo] = useState<SubmittalInfo[]>(initialState || []);

  const [latestFile, setLatestFile] = useState<IFile>();

  useEffect(() => {
    if (initialState?.length) setSubmittalInfo(initialState);
    else
      setSubmittalInfo(
        packageDocuments.map((doc) => {
          return {
            document: doc,
            actionTaken: undefined,
            comment: '',
          };
        }),
      );
  }, [packageDocuments]);

  useEffect(() => {
    if (reviewDocument) {
      getLatestSubmissionFileByDocumentId(reviewDocument?.id)
        .then(setLatestFile)
        .catch(() => setLatestFile(undefined));
    }
  }, [reviewDocument]);

  const handleActionTakenChange = (event: any, index: number) => {
    const newInfo = [...submittalInfo];
    newInfo[index].actionTaken = event.target.value as ActionTakenType;
    setSubmittalInfo(newInfo);
  };

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const newInfo = [...submittalInfo];
    newInfo[index].comment = event.target.value;
    setSubmittalInfo(newInfo);
  };

  const handleRemoveComment = (commentId: string, index: number) => {
    const newInfo = [...submittalInfo];
    newInfo[index].existingComments = newInfo[index].existingComments?.filter(
      (c) => c.id !== commentId,
    );
    setSubmittalInfo(newInfo);
  };

  // Borrowed code from ConsultantFileUploadDialog
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [bluebeamOpen, setBluebeamOpen] = useState(false);
  const [bluebeamDone, setBluebeamDone] = useState(false);
  const [bluebeamFile, setBluebeamFile] = useState<IFile>();
  const [flattenedFile, setFlattenedFile] = useState<IFile>();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [readyForReview, setReadyForReview] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File>();

  const uploadConsultantFile = async () => {
    if (!parentDocument) return;
    setIsLoading(true);
    dispatch(blockNavigation());
    let verified = false;

    try {
      if (uploadedFile) {
        setLoadingText(`Uploading ${uploadedFile.name}`);
        const { file } = await insertAdditionalReviewByDocumentId(
          reviewDocument!.id,
          uploadedFile,
          handleUploadProgress,
        );
        setLoadingText('Verifying upload...');
        if (!file) verified = false;
        else verified = await waitForFileToBeVerified(file);
      } else if (flattenedFile) {
        setLoadingText('Verifying upload...');
        verified = await waitForFileToBeVerified(flattenedFile, true);
      }

      if (!verified) {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: `File ${
              flattenedFile || uploadedFile ? (flattenedFile || uploadedFile)!.name : ''
            } has not finished uploading. You must cancel the upload and try again`,
            severity: 'error',
          }),
        );
        return;
      }

      setLoadingText('Submitting Review...');
      await submitAdditionalReviewForPackageByDocumentId(
        reviewDocument!.id,
        submittalInfo.map(({ document, actionTaken, comment }) => {
          return {
            documentId: document.id,
            recommendedActionTaken: actionTaken,
            comment,
          };
        }),
        !readyForReview,
      );
      dispatch(allowNavigation());
      dispatch(fetchDocument(parentDocument.id));
    } catch (e: any) {
      console.log(e);
      dispatch(allowNavigation());
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const handleBluebeam = async () => {
    if (latestFile) {
      openInBluebeam(latestFile.id, latestFile.name);
      setBluebeamFile(latestFile);
    }
  };

  const handleBluebeamFinish = (flattenedFile?: IFile) => {
    setBluebeamDone(true);
    setFlattenedFile(flattenedFile);
    dispatch(
      addSnackbar({
        id: Date.now(),
        message: 'Your Bluebeam file is being processed',
        severity: 'success',
      }),
    );
  };

  const handleDownload = async (fileId: string, filename: string) => {
    try {
      setIsDownloading(true);
      await downloadFileAsConsultant(fileId, filename, reviewDocument!.id);
      if (refreshNotifications) {
        await refreshNotifications();
      }
    } finally {
      setIsDownloading(false);
    }
  };
  //End of borrowed code.

  const getConsultantSubmitDisabled = () => {
    if (!readyForReview) return !(uploadedFile || (bluebeamDone && flattenedFile));
    return !(
      (uploadedFile || (bluebeamDone && flattenedFile)) &&
      submittalInfo.every((s) => s.actionTaken)
    );
  };

  const getAction = () => {
    const architectSubmitDisabled = !submittalInfo.every(({ actionTaken }) => actionTaken);
    if (isLoading)
      return (
        <>
          <Typography style={{ fontWeight: 500 }}>{loadingText}</Typography>
          {loadingText.includes('Uploading') ? (
            <>
              <Typography style={{ fontSize: 14, marginTop: 4 }}>{uploadProgress}%</Typography>
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                style={{ height: 8, width: '100%', marginTop: 4, marginBottom: 8 }}
              />
            </>
          ) : (
            <CircularLoader style={{ marginTop: 4, marginBottom: 4 }} />
          )}
          <Typography style={{ fontWeight: 500 }}>DO NOT NAVIGATE AWAY FROM THIS PAGE</Typography>
        </>
      );
    if (isConsultantReview)
      return (
        <Button
          variant="contained"
          color="primary"
          disabled={getConsultantSubmitDisabled()}
          onClick={uploadConsultantFile}
          startIcon={<Publish style={{ marginBottom: 3 }} />}
          style={{ width: '100%' }}
        >
          Submit {!readyForReview && '(IN PROGRESS)'}
        </Button>
      );
    return (
      <Button
        variant="outlined"
        onClick={() => {
          if (submit) submit(submittalInfo);
          handleClose();
        }}
        disabled={architectSubmitDisabled}
        className={classes.separateButton}
      >
        Submit
      </Button>
    );
  };

  const closeClicked = () => {
    if (isLoading || shouldBlockNavigation) return;
    handleClose();
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleCancelUpload = async () => {
    if (!reviewDocument || !latestFile) return;
    await deleteFile(reviewDocument.id, latestFile.id);
    dispatch(reloadDocument());
  };

  const getTooltipTitle = () => {
    if (!latestFile) return '';
    if (!latestFile.isVerified) return 'Upload in progress...';
    if (fileIsPdf(latestFile)) return 'View PDF';
    return '';
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeClicked}
      PaperProps={{ style: { minWidth: 720 } }}
      fullWidth
      classes={{ paper: classes.dialogue }}
    >
      <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'column',
          whiteSpace: 'nowrap',
          paddingBottom: '0px',
        }}
      >
        <Typography className={classes.packageName}>
          {getPageTitle(documentType, parentDocument || selectedDocument)}
        </Typography>
        {!isConsultantReview && (
          <Typography style={{ marginTop: 4, marginBottom: 12 }}>
            <RefuseReturnIcon />
            <b style={{ color: '#464546', paddingLeft: '8px' }}>Refuse/Return Partial Package</b>
          </Typography>
        )}
        <IconButton
          onClick={closeClicked}
          style={{ right: '14px', top: '14px', position: 'absolute' }}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      {isConsultantReview && (
        <DialogContent style={{ paddingTop: '5px', overflowY: 'unset', textTransform: 'none' }}>
          {latestFile && (
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                <b style={{ fontSize: 16 }}>Reviewing: </b>
                {latestFile?.name.endsWith('.pdf') ? (
                  <PDFIcon
                    fill={latestFile.isVerified ? '#0947B9' : '#7A797A'}
                    style={{ marginLeft: 4, marginRight: 6 }}
                  />
                ) : (
                  <InsertDriveFileOutlined htmlColor="#7A797A" style={{ fontSize: 30 }} />
                )}
                <Tooltip arrow placement="right" title={getTooltipTitle()}>
                  <span
                    onClick={() =>
                      fileIsPdf(latestFile) && latestFile.isVerified
                        ? openInNewTab(latestFile.id)
                        : null
                    }
                    style={
                      fileIsPdf(latestFile) && latestFile.isVerified
                        ? { fontSize: 16, color: '#0947B9', cursor: 'pointer' }
                        : { fontSize: 16 }
                    }
                  >
                    {latestFile.name}
                  </span>
                </Tooltip>
              </div>
              {!latestFile.isVerified &&
                (latestFile.creatorUserId === user.id || user.isSiteAdmin) && (
                  <CancelButton
                    variant="outlined"
                    onClick={handleCancelUpload}
                    style={{
                      width: '50%',
                      marginTop: 8,
                      borderColor: '#ED3F26',
                      color: '#ED3F26',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Cancel Upload
                  </CancelButton>
                )}
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={classes.columnLayout}>
              <div
                className={classes.rowLayout}
                style={{ width: '100%', justifyContent: 'space-between' }}
              >
                {!isDownloading ? (
                  <DownloadButton
                    disabled={!latestFile?.isVerified}
                    onClick={() => {
                      handleDownload(latestFile!.id, latestFile!.name);
                    }}
                    style={{ marginRight: 12, width: 150 }}
                  />
                ) : (
                  <CircularLoader size={30} style={{ width: 150 }} />
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ height: 32, width: 150 }}
                  disabled={bluebeamDone || !!uploadedFile}
                  onClick={() => setUploadDialogOpen(true)}
                  startIcon={<Publish fill="#2C69D6" />}
                >
                  UPLOAD
                </Button>
              </div>
              <Typography style={{ fontWeight: 600, alignSelf: 'center', marginTop: 8 }}>
                OR
              </Typography>
              <BluebeamButton
                disabled={!!uploadedFile || !!flattenedFile || !latestFile?.isVerified}
                onClick={() => setBluebeamOpen(true)}
                style={{ width: '100%', marginTop: 8 }}
              />
              <FormControl style={{ width: '100%', marginTop: 16 }}>
                <RadioGroup
                  value={String(readyForReview)}
                  onChange={(event, value) => setReadyForReview(value === 'true')}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio style={{ padding: '0px 9px' }} color="primary" />}
                    label="Review in progress"
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="Review complete, return to architect"
                  />
                </RadioGroup>
              </FormControl>
              <div
                className={classes.file}
                style={{ visibility: !uploadedFile && !flattenedFile ? 'hidden' : 'unset' }}
              >
                <IconButton
                  onClick={() => {
                    setUploadedFile(undefined);
                    setBluebeamDone(false);
                    setFlattenedFile(undefined);
                  }}
                  style={{ padding: 0, marginRight: '8px' }}
                >
                  <HighlightOffRounded />
                </IconButton>
                {flattenedFile?.name.endsWith('.pdf') || uploadedFile?.name.endsWith('.pdf') ? (
                  <PDFIcon />
                ) : (
                  <InsertDriveFileOutlined htmlColor="#7A797A" style={{ fontSize: 30 }} />
                )}
                <Typography className={classes.fileText}>
                  {flattenedFile?.name || (uploadedFile as File)?.name}
                </Typography>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
              }}
            >
              <Typography style={{ fontSize: 14, fontWeight: 600 }}>TIP:</Typography>
              <div
                className={classes.columnLayout}
                style={{ borderWidth: 1, borderStyle: 'solid', borderColor: '#000', padding: 8 }}
              >
                <Typography style={{ fontSize: 13 }}>
                  Markups added in Bluebeam are automatically shared among all reviewers of this
                  file.
                </Typography>
                <Typography style={{ fontSize: 13, marginTop: 16 }}>
                  Downloading the file for offline review will create a new version of the file
                  which is visible to all reviewers after upload. However, this marked-up version
                  may have to be manually combined with other reviews by the architect before return
                  to contractor.
                </Typography>
              </div>
            </div>
          </div>
        </DialogContent>
      )}
      {(!isConsultantReview || readyForReview) && (
        <Table size="small">
          <TableHead>
            <TableCell />
            <TableCell>
              <Typography>
                {documentTypeState === DocumentTemplateType.AsBuilt
                  ? 'Sheet Number'
                  : 'Submittal #'}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>Description</Typography>
            </TableCell>
            <TableCell />
          </TableHead>
          {submittalInfo
            .sort((a, b) =>
              getDocumentIdentifier(a.document, documentType).localeCompare(
                getDocumentIdentifier(b.document, documentType),
              ),
            )
            .map((info, index) => (
              <PackageListItem
                key={index}
                index={index}
                document={info.document}
                actionTaken={info.actionTaken}
                onActionTakenChange={handleActionTakenChange}
                comment={info.comment}
                onCommentChange={handleCommentChange}
                existingComments={info.existingComments}
                onRemoveComment={handleRemoveComment}
                isConsultantReview={isConsultantReview}
              />
            ))}
        </Table>
      )}
      <DialogActions style={{ margin: 16, flexDirection: 'column', alignItems: 'center' }}>
        {getAction()}
      </DialogActions>
      <BluebeamFlattenDialog
        dialogOpen={bluebeamOpen}
        handleClose={() => setBluebeamOpen(false)}
        handleMarkup={handleBluebeam}
        documentId={reviewDocument?.id || ''}
        finish={handleBluebeamFinish}
        file={bluebeamFile}
        isConsultant
        studioSessionId={parentDocument?.bluebeamStudioSessionId}
        disableMarkup={!bluebeamFile?.isVerified}
      />
      <FileUploadDialog
        open={uploadDialogOpen}
        handleClose={() => setUploadDialogOpen(false)}
        title="Consultant Upload"
        addFile={(f) => setUploadedFile(f as File)}
        removeFile={() => setUploadedFile(undefined)}
        file={uploadedFile}
        canSubmit={!!uploadedFile}
        disableDesignUpload
        disableComments
      />
    </Dialog>
  );
}
