import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Add, Close, HighlightOffRounded } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { IFile } from '../../api-client/autogenerated';
import {
  flattenBluebeamByDocumentId,
  getFileById,
  getLatestSubmissionFileByDocumentId,
} from '../../models/api/files';
import { isMobileOnly } from 'react-device-detect';
import BluebeamStudioLink from '../custom-components/BluebeamStudioLink';
import { BluebeamButton } from '../custom-components/CustomButtons';
import { useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import { descendingComparator } from '../document-index/DocumentIndexUtils';

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '80px',
    padding: '16px 24px 24px 24px',
  },
  title: {
    maxWidth: '450px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
  },
  rootIconButton: {
    position: 'absolute',
    right: '22px',
    top: '19px',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonContainer: {
    padding: '16px 24px 24px 24px',
    flexDirection: 'column',
  },
  textfield: {
    height: '32px',

    /* Gray / Gray 50 */
    background: '#F9F9F9',

    /* Gray / Gray 400 Brand dark */
    border: '1px solid #949494',
    borderRadius: '5px',
  },
  button: {
    height: '32px',
    borderWidth: '2px',
    boxShadow: 'none',
  },
});

type Props = {
  dialogOpen: boolean;
  handleClose: () => void;
  documentId?: string;
  handleMarkup?: () => Promise<void>;
  finish?: (flattenedFile: IFile) => void;
  file?: IFile;
  isConsultant?: boolean;
  studioSessionId?: string | null;
  disableMarkup?: boolean;
};

export default function BluebeamFlattenDialog(props: Props) {
  const classes = useStyles();
  const {
    dialogOpen,
    handleClose,
    documentId,
    handleMarkup,
    finish,
    file,
    isConsultant,
    studioSessionId,
    disableMarkup,
  } = props;

  const user = useSelector(getUserState);

  const [filename, setFilename] = useState('');
  const [mode, setMode] = useState<'MARKUP' | 'STUDIO' | null>(null);
  const [baxFile, setBaxFile] = useState<IFile>();

  useEffect(() => {
    if (!dialogOpen) return;

    if (!studioSessionId) {
      setMode('MARKUP');
    } else if (disableMarkup && !!studioSessionId) {
      setMode('STUDIO');
    } else setMode(null);
  }, [disableMarkup, studioSessionId, dialogOpen]);

  const getBaxFromParentFile = (file: IFile) => {
    const bax = file.childBaxFiles
      ?.filter((c) => c.name === `${user.id}.bax`)
      ?.sort((a, b) => descendingComparator(a, b, 'createdOn'))[0];
    if (bax) {
      setBaxFile(bax);
    }
  };

  useEffect(() => {
    if (file) {
      setFilename(file.name ? file.name.substr(0, file.name.lastIndexOf('.')) : '');
    }
  }, [file]);

  const verifyBaxFile = (fileId: string) => {
    const interval = setInterval(async () => {
      const updatedBax = await getFileById(fileId);
      if (updatedBax.isVerified) {
        setBaxFile(updatedBax);
        clearInterval(interval);
      }
    }, 5000);

    return () => clearInterval(interval);
  };

  useEffect(() => {
    if (dialogOpen && baxFile && !baxFile.isVerified) {
      return verifyBaxFile(baxFile.id);
    }
  }, [dialogOpen, baxFile]);

  useEffect(() => {
    if (dialogOpen && mode === 'MARKUP' && handleMarkup) {
      handleMarkup().then(() => {
        getLatestSubmissionFileByDocumentId(documentId!).then((latestFile) => {
          if (latestFile) getBaxFromParentFile(latestFile);
        });
      });
    }

    if (!dialogOpen) {
      setMode(null);
    }
  }, [mode, dialogOpen]);

  const flattenFile = async () => {
    if (!documentId || !finish) return;

    try {
      const { flattenedFile } = await flattenBluebeamByDocumentId(documentId, `${filename}.pdf`);
      finish(flattenedFile);
    } finally {
      handleClose();
    }
  };

  if (isMobileOnly) {
    return (
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogContent>
          <Typography style={{ textAlign: 'center', marginTop: 5, marginBottom: 15 }}>
            This action cannot be done on mobile devices. Please try again on a desktop computer.
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={dialogOpen} onClose={handleClose}>
      {mode === null ? (
        <>
          <DialogTitle
            className={classes.titleContainer}
            style={{ height: 'auto', paddingBottom: 8 }}
          >
            <Typography className={classes.title}>Bluebeam</Typography>
            <IconButton
              onClick={handleClose}
              classes={{
                root: classes.rootIconButton,
              }}
            >
              <HighlightOffRounded />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <BluebeamButton fullWidth onClick={() => setMode('MARKUP')}>
              Markup In Bluebeam Revu
            </BluebeamButton>
            <Typography align="center" style={{ fontWeight: 700, marginTop: 8, marginBottom: 8 }}>
              OR
            </Typography>
            <BluebeamButton fullWidth onClick={() => setMode('STUDIO')}>
              Join Bluebeam Studio Session
            </BluebeamButton>
          </DialogContent>
        </>
      ) : null}
      {mode === 'MARKUP' ? (
        <>
          <DialogTitle
            className={classes.titleContainer}
            style={{ height: 'auto', paddingBottom: 0 }}
          >
            <Typography className={classes.title}>GIVE THIS NEW VERSION A NAME</Typography>
            <IconButton
              onClick={handleClose}
              classes={{
                root: classes.rootIconButton,
              }}
            >
              <HighlightOffRounded />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Typography>
              Click "NAME THIS VERSION" to save your markups from Bluebeam as a new version of the
              file. Centerline automatically flattens markups added in Bluebeam.
            </Typography>
          </DialogContent>
          <DialogActions className={classes.buttonContainer}>
            <FormControl style={{ width: '100%', marginBottom: '12px' }}>
              <Typography>Posted filename</Typography>
              <TextField
                fullWidth
                value={filename}
                onChange={(event) => setFilename(event.target.value)}
                InputProps={{
                  fullWidth: true,
                  disableUnderline: true,
                  className: classes.textfield,
                  endAdornment: <span>.pdf</span>,
                  style: { padding: '0px 4px 0px 4px' },
                }}
              />
            </FormControl>

            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
              <Button
                variant="outlined"
                onClick={handleClose}
                className={classes.button}
                style={{ color: '#7A797A', borderColor: '#949494' }}
              >
                <Close htmlColor="#7A797A" fontSize="small" />
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !filename ||
                  !baxFile?.isVerified ||
                  filename === file?.name.substr(0, file.name.lastIndexOf('.'))
                }
                startIcon={<Add />}
                onClick={flattenFile}
                className={classes.button}
              >
                NAME THIS VERSION
              </Button>
            </div>
          </DialogActions>
        </>
      ) : null}
      {mode === 'STUDIO' ? (
        <>
          <DialogTitle
            className={classes.titleContainer}
            style={{ height: 'auto', paddingBottom: 8 }}
          >
            <Typography className={classes.title}>Join Bluebeam Studio Session</Typography>
            <IconButton
              onClick={handleClose}
              classes={{
                root: classes.rootIconButton,
              }}
            >
              <HighlightOffRounded />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography>
              When all markups are complete in Bluebeam Studio, download the final version of the
              file from Bluebeam and UPLOAD it to the Action Center.
            </Typography>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <BluebeamStudioLink studioSessionId={studioSessionId!} />
          </DialogActions>
        </>
      ) : null}
    </Dialog>
  );
}
