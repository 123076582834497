import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FileNode, FileNodeTypeEnum, FilePermissionType } from '../../api-client/autogenerated';
import ManagePermissionsDialog, { ManagePermissionsDialogType } from './ManagePermissionsDialog';
import CircularLoader from '../loader/CircularLoader';
import { areFilesInSameParentFolder, isPublicPage, isUserInGroup } from '../../scripts/utils';
import {
  getDirectDesignFileDownloadLink,
  getDownloadLinkForTrashItem,
  sendDirectDesignFileDownloadEmails,
  sendPublicFolderAccessToEmails,
} from '../../models/api/filesystem';
import { useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import FileLogDialog from './FileLogDialog';
import EmailUsersDialog from './EmailUsersDialog';
import { getUserState } from '../../features/user/selectors';
import { getGroupsState } from '../../features/groups/selector';

interface FileOptionsDialogProps {
  dialogOpen: boolean;
  handleClose: () => void;
  anchor: any;
  file: FileNode | undefined;
  setFile: React.Dispatch<React.SetStateAction<FileNode | undefined>> | ((f: FileNode) => void);
  renameClicked: () => void;
  deleteClicked: () => void;
  disableShare?: boolean;
  disableRename?: boolean;
  disableDelete?: boolean;
  updateFileNode?: (node: FileNode) => void;
  openUploadFileDialog?: () => void;
  openCreateFolderDialog?: () => void;
  disableAddFile?: boolean;
  disableAddFolder?: boolean;
  viewFile?: () => void;
  downloadFile?: () => Promise<void>;
  shareButtonTrigger?: boolean | undefined;
  findParentFolder: (fullKey: string) => FileNode | undefined;
  manageParentFolder: (fullKey: string) => void;
  selectedNodes: FileNode[];
  isRecycleBin?: boolean;
}

const useStyles = makeStyles({
  popover: {
    '.rootElement > *': {
      maxHeight: '5000px',
    },
  },
  popoverRoot: {
    pointerEvents: 'none',
    outline: 'none',
  },
  paper: {
    pointerEvents: 'all',
  },
  paper2: {
    pointerEvents: 'all',
    minWidth: 290,
  },
  menuItem: {
    width: 160,
    height: 32,
  },
  spaceBetween: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuItemActive: {
    width: 140,
    height: 32,
    backgroundColor: '#E6EEFF',
  },
  checkbox: {
    padding: '0px 6px 0px 6px',
  },
});

export default function FileOptionsDialog(props: FileOptionsDialogProps) {
  const {
    dialogOpen,
    handleClose,
    anchor,
    file,
    renameClicked,
    setFile,
    deleteClicked,
    disableShare,
    disableRename,
    disableDelete,
    updateFileNode,
    openUploadFileDialog,
    openCreateFolderDialog,
    disableAddFile,
    disableAddFolder,
    shareButtonTrigger,
    viewFile,
    downloadFile,
    findParentFolder,
    manageParentFolder,
    selectedNodes,
    isRecycleBin,
  } = props;

  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);
  const groups = useSelector(getGroupsState);

  const [managePermissionsDialogOpen, setManagePermissionsDialogOpen] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [getALinkDialogOpen, setGetALinkDialogOpen] = useState(false);
  const [logDialogOpen, setLogDialogOpen] = useState(false);
  const [notifyUsersDialogOpen, setNotifyUsersDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);

  const classes = useStyles();

  const isPublic = isPublicPage();

  const isRootNode = file?.fullKey === '/';

  const canNotify = areFilesInSameParentFolder(selectedNodes);

  const hasAdminPermission = file?.fileNodePermissions?.some((p) => {
    if (!p.permissions.includes(FilePermissionType.Delete)) return false;
    if (p.shareToEveryone) return true;
    if (p.userIdTarget === user.id) return true;
    const groupTarget = groups.find((g) => g.id === p.userGroupIdTarget);
    if (groupTarget) {
      return isUserInGroup(groupTarget, user.id);
    }
  });

  useEffect(() => {
    if (shareButtonTrigger !== undefined) {
      setManagePermissionsDialogOpen(true);
    }
  }, [shareButtonTrigger]);

  const closeMenu = () => {
    handleClose();
  };

  function handleContextMenu(event: any) {
    event.stopPropagation();
    event.preventDefault();
    handleClose();
  }

  const handleRename = () => {
    setRenameDialogOpen(true);
    closeMenu();
  };

  const handleView = () => {
    viewFile && viewFile();
    closeMenu();
  };

  const handleDownload = async () => {
    setDownloading(true);
    try {
      downloadFile && (await downloadFile());
    } finally {
      setDownloading(false);
      closeMenu();
    }
  };

  const handleDelete = () => {
    deleteClicked();
    closeMenu();
  };

  const handleGetLink = async (): Promise<string> => {
    if (!file || !project) return '';
    if (isRecycleBin) return getDownloadLinkForTrashItem(file.fullKey);
    return getDirectDesignFileDownloadLink(project.id, file.fullKey, file.ownerId).then(
      (result) => result.data.downloadLink,
    );
  };

  const submitEmails = async (emails: string[]) => {
    if (!file || !project) return;
    if (file?.type === FileNodeTypeEnum.File)
      await sendDirectDesignFileDownloadEmails(project.id, file.fullKey, file.ownerId, emails);
    else await sendPublicFolderAccessToEmails(project.id, file.fullKey, file.ownerId, emails);
    handleClose();
  };

  return (
    <>
      <Menu
        onContextMenu={handleContextMenu}
        classes={{ paper: classes.paper }}
        anchorEl={anchor}
        elevation={2}
        open={dialogOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {!disableAddFile && !isPublic && !isRecycleBin && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              openUploadFileDialog && openUploadFileDialog();
              handleClose();
            }}
          >
            New File
          </MenuItem>
        )}
        {!disableAddFolder && !isPublic && !isRecycleBin && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              openCreateFolderDialog && openCreateFolderDialog();
              handleClose();
            }}
          >
            New Folder
          </MenuItem>
        )}
        {viewFile && (
          <MenuItem className={classes.menuItem} onClick={handleView}>
            View
          </MenuItem>
        )}
        {downloadFile && (
          <MenuItem className={classes.menuItem} onClick={handleDownload}>
            Download
            {downloading && <CircularLoader size={25} style={{ marginLeft: 8 }} />}
          </MenuItem>
        )}
        {!isPublic && (
          <MenuItem className={classes.menuItem} onClick={() => setGetALinkDialogOpen(true)}>
            Send A Link
          </MenuItem>
        )}
        {!disableShare && !isPublic && !isRecycleBin && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => setManagePermissionsDialogOpen(true)}
          >
            Define Access
          </MenuItem>
        )}
        {!isPublic && !isRootNode && canNotify && !isRecycleBin && (
          <MenuItem className={classes.menuItem} onClick={() => setNotifyUsersDialogOpen(true)}>
            Notify
          </MenuItem>
        )}
        {!isPublic && !isRootNode && (
          <MenuItem className={classes.menuItem} onClick={() => setLogDialogOpen(true)}>
            View Activity
          </MenuItem>
        )}
        {!disableRename && !isPublic && !isRecycleBin && (
          <MenuItem className={classes.menuItem} onClick={handleRename}>
            Rename
          </MenuItem>
        )}
        {!disableDelete && !isPublic && hasAdminPermission && (
          <MenuItem
            className={classes.menuItem}
            style={{ color: '#ED3F26' }}
            onClick={handleDelete}
          >
            Delete
          </MenuItem>
        )}
      </Menu>
      <ManagePermissionsDialog
        dialogOpen={managePermissionsDialogOpen}
        type={ManagePermissionsDialogType.Design}
        closeDialog={() => {
          setManagePermissionsDialogOpen(false);
          handleClose();
        }}
        files={file ? [file!] : undefined}
        setFile={(node: FileNode) => {
          setFile(node);
          if (updateFileNode) updateFileNode(node);
        }}
        findParentFolder={findParentFolder}
        manageParentFolder={(fullKey: string) => {
          handleClose();
          manageParentFolder(fullKey);
        }}
      />
      <EmailUsersDialog
        dialogOpen={getALinkDialogOpen}
        closeDialog={() => setGetALinkDialogOpen(false)}
        onSubmit={submitEmails}
        getLink={file?.type === FileNodeTypeEnum.File ? handleGetLink : undefined}
        defaultSubject={
          file?.type === FileNodeTypeEnum.File
            ? `New Direct Download Share in ${project?.name}`
            : `New Folder Share in ${project?.name}`
        }
      />
      {file && (
        <FileLogDialog
          open={logDialogOpen}
          handleClose={() => setLogDialogOpen(false)}
          file={file}
          isRecycleBin={isRecycleBin}
        />
      )}
      {file && (
        <EmailUsersDialog
          dialogOpen={notifyUsersDialogOpen}
          files={selectedNodes.length > 0 ? selectedNodes : [file]}
          closeDialog={() => setNotifyUsersDialogOpen(false)}
          isDesignNotify
        />
      )}
      {/* Remame Dialog */}
      <Dialog open={renameDialogOpen} onClose={() => setRenameDialogOpen(false)}>
        <DialogContent style={{ textAlign: 'center', maxWidth: 350 }}>
          Caution: renaming the file will invalidate all existing links to this file
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameDialogOpen(false)} variant="text" color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              renameClicked();
              setRenameDialogOpen(false);
            }}
            variant="text"
            color="primary"
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
