import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import { Auth } from '@aws-amplify/auth';
import { Configuration, DefaultApi } from './autogenerated';
import { getRateLimitedAxiosClient, isLoginPage, isPublicPage } from '../scripts/utils';
import store from '../store';
import { addSnackbar } from '../features/snackbar/actions';
import { API_ERROR_MESSAGE_4XX, API_ERROR_MESSAGE_5XX } from '../constants';

const statusCodesToRetry = [408, 502, 503, 504];

export const API_URL =
  process.env.REACT_APP_API_URL || 'https://api.dev.centerline.dev/internal-api';

export interface QueryFilter extends Array<QueryFilterRow> {}

export type QueryFilterRow = {
  whereColumn: string;
  whereOperator:
    | '='
    | '!='
    | 'LIKE'
    | 'ILIKE'
    | '>'
    | '<'
    | '>='
    | '<='
    | 'IN'
    | 'NOT IN'
    | 'IS'
    | 'IS NOT';
  whereValue: string | string[] | boolean | null;
};

export const getQueryFilterString = (filter: QueryFilter) => {
  return JSON.stringify(filter);
};

const shouldCancelSnackbar = (error: AxiosError) => {
  const { stack } = (error.response?.data ?? {}) as { stack?: string };
  return (
    error.response?.status.toString().startsWith('4') ||
    !!(
      error.config?.url?.toLowerCase().endsWith('previous-submission-file') &&
      stack?.toLowerCase().includes('could not find a previoussubmission')
    )
  );
};

const shouldCancelErrorEmail = (error: AxiosError) => {
  // if (!error.response?.status) return true;
  const { stack } = (error.response?.data ?? {}) as { stack?: string };
  return !!(
    (isPublicPage() || isLoginPage()) &&
    (error.config?.url || error?.response?.config.url)?.endsWith('/internal-api/users/me') &&
    stack?.includes('Invalid Authorization Header')
  );
};

const errorClient = getRateLimitedAxiosClient(undefined, 50);

const handleErrorEmail = async (error: AxiosError, getJwtToken?: () => Promise<string>) => {
  const message = JSON.stringify(
    { currentURL: window.location.href, error, errorResponseData: error.response?.data },
    undefined,
    2,
  );
  try {
    if (!isPublicPage()) {
      const token = getJwtToken ? await getJwtToken() : undefined;
      if (token)
        errorClient.post(
          `${API_URL}/outbound-email/send-error`,
          { message },
          {
            headers: {
              Authorization: `Bearer: ${token}`,
            },
          },
        );
      else if (localStorage.getItem('publicEmail')) {
        errorClient.post(`${API_URL}/outbound-email/send-error`, { message });
      } else {
        console.error(`Error email not sent. No token or publicEmail in localStorage.`);
      }
    } else if (isPublicPage() && localStorage.getItem('publicEmail'))
      errorClient.post(
        `${API_URL}/outbound-email/send-error`,
        { message },
        {
          params: { unauthenticatedUserEmail: localStorage.getItem('publicEmail') },
        },
      );
  } catch (error: any) {
    console.log(
      'Error handling email error; this may be caused by not yet having a user logged in.',
    );
  }
};

const getErrorMessageFromAxiosError = (error: AxiosError) => {
  if (!error.response) return 'No response from server';
  if (error.response.data.userFriendlyMessage !== undefined) {
    return error.response.data.userFriendlyMessage;
  }
  return error.response.status.toString().startsWith('4')
    ? API_ERROR_MESSAGE_4XX
    : API_ERROR_MESSAGE_5XX;
};

const getClient = (getJwtToken?: () => Promise<string>) => {
  const client = getRateLimitedAxiosClient(axios.create({ baseURL: API_URL }), 50);

  axiosRetry(client, {
    retries: 3,
    retryDelay: () => 1000,
    retryCondition: (error) => {
      console.log({ ...error });
      if (!error.response) return true;
      return statusCodesToRetry.includes(error.response.status);
    },
  });

  if (!isPublicPage()) {
    client.interceptors.request.use(
      async (config) => {
        const jwtToken = getJwtToken ? await getJwtToken() : undefined;
        if (jwtToken)
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer: ${jwtToken}`;
        return config;
      },
      (error) => {
        console.error('AUTH', error);
      },
    );
  }

  client.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response) {
        //For now, disable errors on public pages since there are unauthenticated API calls
        if (!isPublicPage() && !shouldCancelSnackbar(error))
          store.dispatch(
            addSnackbar({
              id: Date.now(),
              severity: 'error',
              message: getErrorMessageFromAxiosError(error),
            }),
          );
      }

      if (!shouldCancelErrorEmail(error)) handleErrorEmail(error, getJwtToken);

      throw error;
    },
  );

  return client;
};

const getCognitoJwtToken = async () => {
  const session = await Auth.currentSession();

  return session.getIdToken().getJwtToken();
};

export const createApiClient = (getJwtToken?: () => Promise<string>) => {
  return new DefaultApi(new Configuration(), API_URL, getClient(getJwtToken));
};

export let ApiClient = createApiClient(getCognitoJwtToken);

export const resetApiClient = () => {
  ApiClient = createApiClient(getCognitoJwtToken);
};
