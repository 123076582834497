import React, { useEffect, useRef, useState } from 'react';
import UnlockIcon from '@material-ui/icons/LockOpen';
import {
  Card,
  CardContent,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Alert, Pagination } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import { Search } from '@material-ui/icons';
import ProjectsList from './ProjectsList';
import { getBidSetups, unlockProjectsWithKeyByUserId } from '../../models/api/project-bid-setups';
import { IBidSetup } from '../../api-client/autogenerated';
import NavAppbar from '../../main-components/nav-top/NavAppbar';
import { Dictionary } from 'lodash';
import { getStates } from '../../models/api/countries';
import { useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import { useHistory } from 'react-router-dom';
import FullscreenLoader from '../../main-components/loader/FullscreenLoader';
import Centerlinelogo from '../../assets/icons/wordmark-white';
import SendFilesDialog from '../send-files/SendFilesDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    content: {
      // flexGrow: 1,
      // padding: theme.spacing(3),
      // minHeight: '100vh',
      display: 'flex',
      padding: 16,
      justifyContent: 'flex-end',
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      height: '100%',
      background: '#fff',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
    },
    icon: {
      fill: '#B2B1B2',
      marginLeft: '4px',
    },
    searchInput: {
      paddingLeft: 26,
    },
    searchRoot: {
      width: 280,
    },
    button: {
      outline: 0,
      border: 'none',
      borderColor: 'inherit',
      boxShadow: 'none',
      '&:focus, &:active': {
        outline: 0,
        borderColor: 'inherit',
        border: 'none',
        boxShadow: 'none',
      },
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
    popoverRoot: {
      maxHeight: 500,
    },
    filterButtons: {
      display: 'flex',
      justifyContent: 'end',
      [theme.breakpoints.down(815)]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
    },
    privateKey: {
      [theme.breakpoints.down(375)]: {
        margin: 0,
        padding: 0,
      },
    },
    filterButtonDiv: {
      [theme.breakpoints.down(815)]: {
        flexDirection: 'column',
      },
    },
    filterButtonSubDiv: {
      [theme.breakpoints.down(375)]: {
        display: 'flex',
        flexDirection: 'row',
      },
    },
    magnifyingGlass: {
      fill: '#B2B1B2',
      marginLeft: '4px',
      position: 'relative',
      left: 24,
      top: 5,
      zIndex: 1000,
      [theme.breakpoints.down(415)]: {
        left: 0,
        top: 25,
      },
      [theme.breakpoints.down(375)]: {
        left: 0,
        top: 0,
      },
    },
    title: {
      color: 'white',
      fontSize: 30,
      paddingLeft: 10,
    },
  }),
);

const states = [
  ['Arizona', 'AZ'],
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY'],
];

function Projects() {
  const history = useHistory();

  const [projects, setProjects] = useState<IBidSetup[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filter, setFilter] = useState<string | undefined | null>(null);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [privateDialogOpen, setPrivateDialogOpen] = useState(false);
  const [sortBy, setSortBy] = useState('bid due date');
  const [page, setPage] = React.useState(1);
  const filterAnchor = useRef<any>(null);
  const privateAnchor = useRef<any>(null);
  const [stateIds, setStateIds] = useState<Dictionary<string>>();
  const [inputSecret, setInputSecret] = useState<string>(
    localStorage.getItem('lastPrivateJobKey') || '',
  );

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackText, setSnackText] = useState<string>();
  const [snackSeverity, setSnackSeverity] = useState<string>();

  const [sendFilesDialogOpen, setSendFilesDialogOpen] = useState(false);

  const user = useSelector(getUserState);

  const handleChange = (event: any, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    getStates().then((res) => {
      setStateIds(res);
    });
  }, []);

  const classes = useStyles();

  const getColumnNameFromSortBy = () => {
    switch (sortBy) {
      case 'bid location':
        return 'address:stateOrProvince.name';
      default:
      case 'job name':
        return 'project.name';

      case 'company name':
        return 'bidLocationCompany';

      case 'date created':
        return 'createdOn';
      case 'job cost':
        return 'estimatedConstructionCost';
      case 'bid due date':
        return 'dueDate';
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getBidSetups(15, page - 1, `%${inputValue}%`, getColumnNameFromSortBy())
      .then((result) => {
        setProjects(result.results);
        setTotalPages(result.totalPages);
      })
      .catch(() => setProjects([]))
      .finally(() => {
        setIsLoaded(true);
        setIsLoading(false);
      });
  }, [page, sortBy]);

  useEffect(() => {
    // only make new requests when the user hasn't typed for 250ms
    const delayDebounceFn = setTimeout(() => {
      setPage(1);
      if (stateIds) console.log(stateIds);
      if (filter && stateIds) console.log(stateIds[filter]);
      getBidSetups(
        15,
        page - 1,
        `%${inputValue}%`,
        getColumnNameFromSortBy(),
        filter && stateIds ? stateIds[filter] : undefined,
      )
        .then((result) => {
          setProjects(result.results);
          setTotalPages(result.totalPages);
        })
        .catch(() => setProjects([]))
        .finally(() => setIsLoaded(true));
    }, 250);

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue, filter]);

  const unlockProject = () => {
    if (inputSecret) {
      unlockProjectsWithKeyByUserId(user.id, inputSecret).then((res) => {
        if (res.length === 0) {
          setSnackOpen(true);
          setSnackSeverity('error');
          setSnackText('No project exists with that key');
        } else {
          localStorage.setItem('lastPrivateJobKey', inputSecret);
          const bidSetupId = res[0];
          if (bidSetupId) history.push(`/bidding/${bidSetupId}`);
        }
      });
    }
  };

  return (
    <main className={classes.content} style={{ fontFamily: 'Roboto' }}>
      <NavAppbar isBiddingPortal />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Card
          id="mainTable"
          style={{ boxShadow: '0px 5px 15px rgb(0, 0, 0, .15)', maxWidth: 1200, flexGrow: 1 }}
        >
          <CardContent className={classes.titleContainer}>
            <Centerlinelogo width="200" height="100" />
            <Typography
              className={classes.title}
              style={{ fontFamily: 'Uniform Rounded Medium', letterSpacing: 4 }}
            >
              BidConnect
            </Typography>
            <div style={{ display: 'inline-flex', flexGrow: 100 }} />
            <Button
              variant="outlined"
              onClick={() => setSendFilesDialogOpen(true)}
              style={{
                fontSize: 16,
                color: 'white',
                borderColor: 'white',
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              Send Us Files
            </Button>
          </CardContent>
          <CardContent>
            {isLoading && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 48,
                }}
              >
                <FullscreenLoader />
              </div>
            )}
            <Grid container justify="center" spacing={0}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <div className={classes.filterButtonDiv} style={{ display: 'flex' }}>
                    <div className={classes.filterButtonSubDiv} style={{ paddingRight: 16 }}>
                      <span style={{ width: 0 }}>
                        <Search fontSize="small" className={classes.magnifyingGlass} />
                      </span>
                      <TextField
                        fullWidth
                        value={inputValue}
                        onChange={(event) => setInputValue(event.target.value)}
                        style={{ width: 280 }}
                        placeholder="Search in Projects..."
                        InputProps={{
                          classes: { input: classes.searchInput, root: classes.searchRoot },
                        }}
                      />
                    </div>
                    <div
                      style={{ flexDirection: 'row', flexGrow: 1 }}
                      className={classes.filterButtons}
                    >
                      <div>
                        <Button
                          color="primary"
                          ref={filterAnchor}
                          className={classes.button}
                          onClick={() => setFilterDialogOpen(true)}
                          style={{
                            marginLeft: '4px',
                            color: '#0947B9',
                            marginRight: 8,
                            fontSize: 14,
                          }}
                        >
                          Filter by State
                        </Button>
                      </div>
                      <Button
                        color="primary"
                        ref={privateAnchor}
                        className={classes.button}
                        onClick={() => setPrivateDialogOpen(true)}
                        style={{
                          marginLeft: '4px',
                          color: '#0947B9',
                          marginRight: 8,
                          fontSize: 14,
                        }}
                      >
                        Enter Private Job Key
                      </Button>
                    </div>
                  </div>
                  <Typography
                    variant="body1"
                    style={{ marginLeft: 24, marginTop: 16, fontSize: 15 }}
                  >
                    Sort by
                    <Select
                      style={{ marginLeft: 8, fontSize: 15, paddingLeft: 4 }}
                      value={sortBy}
                      onChange={(e) => {
                        setSortBy(e.target.value as string);
                      }}
                    >
                      <MenuItem value="bid due date">Bid Date</MenuItem>
                      <MenuItem value="job name">Job Name</MenuItem>
                    </Select>
                  </Typography>
                  <Divider light style={{ marginBottom: 32 }} />

                  {projects.length > 0 ? (
                    <div className="listview-wrapper results">
                      <div className="listview listview--selectable">
                        <ProjectsList bidSetups={projects} isLoaded={isLoaded} />
                      </div>
                    </div>
                  ) : (
                    <Typography>No projects are currently out for bid.</Typography>
                  )}
                  {totalPages > 1 && (
                    <Pagination
                      count={totalPages}
                      page={page}
                      style={{ paddingTop: 32 }}
                      classes={{ ul: classes.center }}
                      onChange={handleChange}
                    />
                  )}
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <Popover
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        anchorEl={filterAnchor.current}
        classes={{ root: classes.popoverRoot }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: 16 }}>
          <RadioGroup
            row
            value={filter}
            onChange={(event, value) => {
              setFilter(value ? value : null);
            }}
            style={{ marginBottom: 0, paddingBottom: 0, display: 'flex', flexDirection: 'column' }}
          >
            <FormControlLabel
              value={null}
              control={
                <Radio color="primary" size="small" style={{ paddingTop: 8, paddingBottom: 8 }} />
              }
              label="All"
            />
            {states.map((x) => {
              return (
                <FormControlLabel
                  key={`project` + x[1]}
                  value={x[1]}
                  control={
                    <Radio
                      color="primary"
                      size="small"
                      style={{ paddingTop: 8, paddingBottom: 8 }}
                    />
                  }
                  label={x[0]}
                />
              );
            })}
          </RadioGroup>
        </div>
      </Popover>

      <Popover
        open={privateDialogOpen}
        onClose={() => setPrivateDialogOpen(false)}
        anchorEl={privateAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: 16, display: 'flex', alignItems: 'center' }}>
          <TextField
            name="key"
            autoComplete="off"
            error={snackText !== undefined}
            defaultValue={inputSecret}
            onBlur={(e) => setInputSecret(e.target.value)}
            placeholder="Enter private job key..."
            style={{ paddingLeft: 0, paddingRight: 16, minWidth: 240 }}
          />
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={unlockProject}
            startIcon={<UnlockIcon />}
          >
            Unlock
          </Button>
        </div>
      </Popover>
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackOpen(false)}
          severity={snackSeverity === 'error' ? 'error' : 'success'}
          variant="filled"
        >
          {snackText}
        </Alert>
      </Snackbar>
      <Snackbar />
      <div id="bottomShadow" />
      <div className="footer-wrapper" style={{ display: 'none' }}>
        <footer className="container footer" style={{ display: 'none' }} />
      </div>
      <SendFilesDialog open={sendFilesDialogOpen} onClose={() => setSendFilesDialogOpen(false)} />
    </main>
  );
}

export default Projects;
