import { ApiClient, getQueryFilterString, QueryFilter } from '../../api-client/api-client';
import axios from 'axios';
import { InboxPermissionLevel } from '../../api-client/autogenerated';

export const getAllReceivedEmails = async (
  projectId: string,
  filters: {
    fromEmail?: string;
    toOrCcEmail?: string;
    subject?: string;
    hasAttachment?: boolean;
    fromDate?: string;
    toDate?: string;
    selectedPermission?: InboxPermissionLevel;
  },
  options: {
    page: number;
    pageSize?: number;
  },
) => {
  const {
    fromEmail,
    toOrCcEmail,
    subject,
    hasAttachment,
    fromDate,
    toDate,
    selectedPermission,
  } = filters;
  const { page, pageSize = 20 } = options;
  const queryFilters: QueryFilter = [];
  if (hasAttachment) {
    queryFilters.push({ whereColumn: 'hasAttachment', whereOperator: '=', whereValue: 'true' });
  }
  if (subject) {
    queryFilters.push({
      whereColumn: 'subject',
      whereOperator: 'ILIKE',
      whereValue: `%${subject}%`,
    });
  }
  if (fromDate) {
    queryFilters.push({ whereColumn: 'sendDate', whereOperator: '>=', whereValue: fromDate });
  }
  if (toDate) {
    queryFilters.push({ whereColumn: 'sendDate', whereOperator: '<=', whereValue: toDate });
  }
  return ApiClient.getAllReceivedEmails({
    projectId,
    selectedPermission,
    page,
    pageSize,
    fromEmail,
    toOrCcEmail,
    filterByObject: queryFilters.length > 0 ? getQueryFilterString(queryFilters) : undefined,
    orderByColumn: 'sendDate',
    orderByDirection: 'desc',
  }).then((res) => res.data);
};

export const getEmailSourceFromEmailId = async (emailId: string) => {
  const res = await ApiClient.getSignedUrlForEmail({ emailId });
  return axios.get<string>(res.data).then((res) => res.data);
};

const getMyNotificationsWithEmailsFilters = (filters: {
  projectId?: string;
  fromDate?: string;
  toDate?: string;
  isUnread?: boolean;
}) => {
  const { fromDate, toDate, isUnread } = filters;
  const queryFilters: QueryFilter = [];
  if (fromDate) {
    queryFilters.push({
      whereColumn: 'userNotification.createdOn',
      whereOperator: '>=',
      whereValue: fromDate,
    });
  }
  if (toDate) {
    queryFilters.push({
      whereColumn: 'userNotification.createdOn',
      whereOperator: '<=',
      whereValue: toDate,
    });
  }
  if (isUnread) {
    queryFilters.push({
      whereColumn: 'userNotification.isRead',
      whereOperator: '=',
      whereValue: 'false',
    });
  }
  return queryFilters;
};

export const getMyNotificationsWithEmails = async (
  filters: { projectId?: string; fromDate?: string; toDate?: string; isUnread?: boolean },
  options: {
    page: number;
    pageSize?: number;
    orderByDirection?: 'asc' | 'desc';
  },
) => {
  const { projectId } = filters;
  const { page, pageSize = 10, orderByDirection = 'desc' } = options;
  const queryFilters: QueryFilter = getMyNotificationsWithEmailsFilters(filters);
  return ApiClient.getMyNotificationsWithEmails({
    orderByColumn: 'createdOn',
    orderByDirection,
    page,
    pageSize,
    filterByObject: getQueryFilterString(queryFilters),
    projectId,
  }).then((res) => res.data);
};

export const getMyNotificationsWithEmailsCount = async (filters: {
  projectId?: string;
  fromDate?: string;
  toDate?: string;
  isUnread?: boolean;
}) => {
  const { projectId } = filters;
  const queryFilters: QueryFilter = getMyNotificationsWithEmailsFilters(filters);
  return ApiClient.getMyNotificationsWithEmailsCount({
    filterByObject: getQueryFilterString(queryFilters),
    projectId,
  }).then((res) => res.data);
};

export const sendErrorEmail = (message: string) => {
  return ApiClient.createErrorEmail({ errorEmail: { message } });
};
