import { ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import {
  SET_DOCUMENT_LOADING,
  SET_DOCUMENTS_LOADING,
  SET_PROJECT_LOADING,
  SET_PROJECTS_LOADING,
  SET_SECURITY_LOADING,
  SET_USER_LOADING,
} from './constants';
import * as loadingActions from './actions';
import { Loading } from './loading';

export type LoadingAction = ActionType<typeof loadingActions>;
export type LoadingState = {
  user: Loading;
  project: Loading;
  document: Loading;
  documents: Loading;
  projects: Loading;
  security: Loading;
};

export default combineReducers<LoadingState, LoadingAction>({
  user: (state = true, action) => {
    let newState: Loading;
    switch (action.type) {
      case SET_USER_LOADING:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
  project: (state = false, action) => {
    let newState: Loading;
    switch (action.type) {
      case SET_PROJECT_LOADING:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
  document: (state = false, action) => {
    let newState: Loading;
    switch (action.type) {
      case SET_DOCUMENT_LOADING:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
  documents: (state = false, action) => {
    let newState: Loading;
    switch (action.type) {
      case SET_DOCUMENTS_LOADING:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
  projects: (state = false, action) => {
    let newState: Loading;
    switch (action.type) {
      case SET_PROJECTS_LOADING:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
  security: (state = false, action) => {
    let newState: Loading;
    switch (action.type) {
      case SET_SECURITY_LOADING:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
});
