import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UserNotificationWithEmail } from '../../api-client/autogenerated';
import { useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import { getMyNotificationsWithEmails } from '../../models/api/email';
import CircularLoader from '../loader/CircularLoader';
import EmailPreviewDialog from '../email/EmailPreviewDialog';
import NotificationEmailItem from '../email/NotificationEmailItem';
import { markNotificationAsRead } from '../../models/api/notifications';
import SeeAllWhite from './SeeAllWhite';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    background: '#FFFFFF',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  titleStyle: {
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
  },
});

const PAGE_SIZE = 25;

export default function DashboardEmails() {
  const classes = useStyles();

  const project = useSelector(getProjectState);

  const [listLoading, setListLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [hasMoreResults, setHasMoreResults] = useState(true);

  const [notifications, setNotifications] = useState<UserNotificationWithEmail[]>([]);
  const [
    selectedNotification,
    setSelectedNotification,
  ] = useState<UserNotificationWithEmail | null>(null);

  const [previewOpen, setPreviewOpen] = useState(false);

  const fetchNextPage = async () => {
    const data = await getMyNotificationsWithEmails(
      { projectId: project?.id, isUnread: true },
      { page: page + 1, pageSize: PAGE_SIZE },
    );
    setHasMoreResults(data.length === PAGE_SIZE);
    setPage((prev) => prev + 1);
    setNotifications((prev) => [...prev, ...data]);
  };

  const fetchNotifications = async () => {
    setListLoading(true);
    const data = await getMyNotificationsWithEmails(
      { projectId: project?.id, isUnread: true },
      { page: 0, pageSize: PAGE_SIZE },
    );
    setPage(0);
    setHasMoreResults(data.length === PAGE_SIZE);
    setNotifications(data);
    setListLoading(false);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
    setSelectedNotification(null);
  };

  const selectNotification = (n: UserNotificationWithEmail) => {
    if (!n.isRead) {
      markNotificationAsRead(n.id);
    }
    setSelectedNotification(n);
    setPreviewOpen(true);
  };

  useEffect(() => {
    fetchNotifications();
  }, [project]);

  return (
    <Card className={classes.root} style={{ height: project ? 450 : 1266 }}>
      <CardContent className={classes.titleStyle}>
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Grid item>
            <h2
              className="h2"
              style={{
                textAlign: 'left',
                color: '#FFFFFF',
                margin: 0,
              }}
            >
              My Notifications
            </h2>
          </Grid>
          <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <SeeAllWhite
              link={`/main/${project ? `projects/${project.id}/` : ''}my-notifications`}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardContent
        id="emailList"
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          height: '100%',
        }}
      >
        {!listLoading ? (
          <InfiniteScroll
            next={fetchNextPage}
            hasMore={hasMoreResults}
            loader={
              <div style={{ paddingTop: 12, paddingBottom: 12 }}>
                <CircularLoader />
              </div>
            }
            dataLength={(page + 1) * PAGE_SIZE}
            scrollableTarget="emailList"
            scrollThreshold={0.92}
          >
            {notifications.map((n) => (
              <NotificationEmailItem
                key={n.id}
                notificationWithEmail={n}
                onClick={() => selectNotification(n)}
              />
            ))}
            {notifications.length === 0 ? (
              <Typography align="center" style={{ fontSize: 16, marginTop: 8 }}>
                No unread notifications.
              </Typography>
            ) : null}
          </InfiniteScroll>
        ) : (
          <div style={{ height: '100%', width: '100%', position: 'relative' }}>
            <CircularLoader
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
                top: '40%',
              }}
            />
          </div>
        )}
      </CardContent>
      <EmailPreviewDialog
        open={previewOpen}
        onClose={handleClosePreview}
        notification={selectedNotification}
      />
    </Card>
  );
}
