import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NavAppbar from '../nav-top/NavAppbar';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../page-title/PageTitle';
import { HeadCell } from '../document-index/DocumentIndexHeader';
import { UserData } from '../document-index/DocumentIndexData';
import { getAdminProjectUsersState, getProjectState } from '../../features/project/selectors';
import { useParams } from 'react-router-dom';
import { getProjectUsers, ProjectMatchParams } from '../../scripts/utils';
import { fetchProject } from '../../features/project/actions';
import DocumentIndex from '../document-index/DocumentIndex';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    height: '100%',
    marginBottom: '16px',
    boxShadow: '0px 5px 15px rgb(0, 0, 0, .15)',
  },
  table: {
    minWidth: '750px',
    maxWidth: '100%',
  },
  tableRow: {
    transition: '0.1s',
    '&:hover': {
      background: '#E5EEFE !important',
    },
  },
}));

// Head cells for User table
const headUserCells: HeadCell<UserData>[] = [
  { id: 'name', label: 'Name', width: '20%' },
  { id: 'company', label: 'Company', width: '20%' },
  { id: 'email', label: 'Email', width: '35%' },
  { id: 'securityGroup', label: 'Security Group', width: '25%' },
];

export default function ProjectDirectoryPage() {
  const classes = useStyles();
  const params = useParams<ProjectMatchParams>();
  const dispatch = useDispatch();
  const selectedProject = useSelector(getProjectState);
  const projectUsers = useSelector(getAdminProjectUsersState);

  useEffect(() => {
    if (params.projectId) dispatch(fetchProject(params.projectId));
  }, [params.projectId]);

  return (
    <main className={classes.content}>
      <NavAppbar />
      {!selectedProject ? (
        <h2>Please select a project to view its users</h2>
      ) : (
        <div>
          <PageTitle title="Project Directory" />
          <DocumentIndex
            columnTitles={headUserCells}
            projectUsers={projectUsers.filter((x) => !x.user?.isSiteAdmin)}
            isDirectory
          />
        </div>
      )}
    </main>
  );
}
