import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  createStyles,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs, { Dayjs } from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import { Add, Remove } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import { fetchProjects } from '../../features/projects/actions';
import CircularLoader from '../loader/CircularLoader';
import {
  IOptionalProject,
  IProjectUser,
  PhoneNumberType,
  ProjectResponse,
} from '../../api-client/autogenerated';
import { addOrModifyUsersOfProject, patchProject } from '../../models/api/project';
import { insertUser, removeUserFromProjectByProjectId } from '../../models/api/users';
import PageTitle from '../page-title/PageTitle';
import NavAppbar from '../nav-top/NavAppbar';
import CreateProjectUsers from '../project-users/CreateProjectUsers';
import { getAdminProjectUsersState, getProjectState } from '../../features/project/selectors';
import {
  generateProcoreLoginLink,
  parseDate,
  ProjectMatchParams,
  sortLastNamesDesc,
} from '../../scripts/utils';
import { getCurrentSecurityGroup } from '../../features/security/selectors';
import { UserActionType } from './AddOrManageProjectPage';
import FullscreenLoader from '../loader/FullscreenLoader';
import { useParams } from 'react-router';
import { fetchProject } from '../../features/project/actions';
import { insertPhoneNumberByUserId } from '../../models/api/phone-number';
import { fetchSecurityGroups } from '../../features/security/actions';
import { ProcoreButton, SubmitButton } from '../custom-components/CustomButtons';
import { hasAdminPermissions, hasCompletedConstructionSetup } from '../../scripts/store-utils';
import { addSnackbar } from '../../features/snackbar/actions';
import {
  beginSyncRfis,
  beginSyncSubmittalsAndPackages,
  getProcoreProjects,
} from '../../models/api/procore';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import { getGroupsState } from '../../features/groups/selector';
import CreateUserGroupDialog from '../design/CreateUserGroupDialog';
import InlineNotificationList from './user-groups/InlineNotificationList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      marginTop: 8,
      paddingBottom: 32,
    },
    submit: {
      margin: theme.spacing(1),
    },
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
      overflowX: 'hidden',
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      width: '100%',
    },
    loadingCenter: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
    },
    loadingMargin: {
      paddingBottom: 16,
    },
    title: {
      padding: 0,
    },
    textField: {
      maxWidth: 300,
      paddingTop: 4,
      paddingBottom: 8,
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      padding: 0,
    },
    endDiv: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 8,
      paddingBottom: 8,
    },
    widthConstraint: {
      maxWidth: 700,
    },
  }),
);

interface UserAndAction {
  user: IProjectUser;
  action: UserActionType;
}

export default function ConstructionSetupPage() {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<ProjectMatchParams>();
  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const currentProject = useSelector(getProjectState);
  const projectUsers = useSelector(getAdminProjectUsersState);
  const security = useSelector(getCurrentSecurityGroup);
  const groups = useSelector(getGroupsState);

  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (user.isSiteAdmin || user.adminOfSubscriberId) setDisabled(false);
    else if (security && security?.constructionManagementPermission! === 4) setDisabled(false);
  }, [user, security]);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const [procoreProjects, setProcoreProjects] = useState<ProjectResponse[]>([]);
  const [procoreProjectId, setProcoreProjectId] = useState<number | undefined>(undefined);
  const [procoreCompanyId, setProcoreCompanyId] = useState<number | undefined>(undefined);
  const [daysToCompletion, setDaysToCompletion] = useState<string>();
  const [liquidatedDamagesPerDay, setLiquidatedDamagesPerDay] = useState<string>();
  const [contractAmount, setContractAmount] = useState<number>();
  const [contractAmountInput, setContractAmountInput] = useState<number>();
  const [selectedArchitectAdministrator, setSelectedArchitectAdministrator] = useState<
    string | undefined
  >('');
  const [selectedContractorAdministrator, setSelectedContractorAdministrator] = useState<
    string | undefined
  >('');
  const [documentReviewPeriod, setDocumentReviewPeriod] = useState<string>();
  const [rfiReviewPeriod, setRfiReviewPeriod] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [approvedDate, setApprovedDate] = useState<Dayjs | null>(dayjs());
  const [noticeToProceedDate, setNoticeToProceedDate] = useState<Dayjs | null>(dayjs());
  const [substantialCompletionDate, setSubstantialCompletionDate] = useState<Dayjs | null>(null);
  const [oneYearInspectionAt, setOneYearInspectionAt] = useState<Dayjs | null>(null);
  const [warrantyPeriodStartDate, setWarrantyPeriodStartDate] = useState<Dayjs | null>(null);
  const [warrantyPeriodEndDate, setWarrantyPeriodEndDate] = useState<Dayjs | null>(null);

  const [defaultNotificationGroupIdString, setDefaultNotificationGroupIdString] = useState('');
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [notificationListOpen, setNotificationListOpen] = useState(false);
  const defaultNotificationGroupSelectRef = useRef<HTMLSelectElement>(null);

  const [users, setUsers] = useState<UserAndAction[]>([]);

  const [architectAdministratorError, setArchitectAdministratorError] = useState<string>();
  const [contractorAdministratorError, setContractorAdministratorError] = useState<string>();
  const [daysToCompletionError, setDaysToCompletionError] = useState<string>();
  const [documentReviewPeriodError, setDocumentReviewPeriodError] = useState<string>();
  const [rfiReviewPeriodError, setRfiReviewPeriodError] = useState<string>();
  const [noticeToProceedDateError, setNoticeToProceedDateError] = useState<string>();

  const getScheduledEndDate = () => {
    if (noticeToProceedDate && daysToCompletion) {
      return noticeToProceedDate.add(parseInt(daysToCompletion, 10), 'd');
    }
    return null;
  };

  const handleComplete = () => {
    dispatch(fetchProject(currentProject!.id));
    dispatch(fetchSecurityGroups);
    dispatch(fetchProjects()).then(() => {
      setIsLoading(false);
      dispatch(
        addSnackbar({
          id: Date.now(),
          severity: 'success',
          message: hasCompletedConstructionSetup()
            ? 'Construction information successfully updated!'
            : 'Construction setup successfully completed!',
        }),
      );
      history.push(`/main/projects/${currentProject!.id}`);
    });
  };

  const toggleProcoreIntegration = async () => {
    if (!currentProject?.procoreOauthId) {
      localStorage.setItem('projectIdCallback', currentProject!.id);
      window.location.href = generateProcoreLoginLink();
    } else if (!!currentProject) {
      await patchProject(currentProject.id, { procoreOauthId: null });
      dispatch(fetchProject(currentProject.id));
    }
  };

  const isFormValid = () => {
    return (
      !!selectedArchitectAdministrator &&
      !!selectedContractorAdministrator &&
      !!daysToCompletion &&
      !!documentReviewPeriod &&
      !!noticeToProceedDate
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    if (!currentProject) return;

    try {
      e.preventDefault();
      setIsLoading(true);
      dispatch(blockNavigation());

      const defaultNotificationGroupId =
        defaultNotificationGroupIdString === '' ? null : defaultNotificationGroupIdString;

      const project: IOptionalProject = {
        defaultArchitectUserId: selectedArchitectAdministrator,
        defaultGeneralContractorUserId: selectedContractorAdministrator,
        contractAmount,
        liquidatedDamagesPerDay: liquidatedDamagesPerDay
          ? parseInt(liquidatedDamagesPerDay!, 10)
          : undefined,
        documentReviewPeriod: parseInt(documentReviewPeriod!, 10),
        rfiReviewPeriod: rfiReviewPeriod ? parseInt(rfiReviewPeriod!, 10) : undefined,
        noticeToProceedDate: noticeToProceedDate!.format('YYYY-MM-DD'),
        scheduledEndDate:
          daysToCompletion && noticeToProceedDate
            ? getScheduledEndDate()!.format('YYYY-MM-DD')
            : undefined,
        substantialCompletionDate: substantialCompletionDate
          ? substantialCompletionDate.format('YYYY-MM-DD')
          : undefined,
        oneYearInspectionAt: oneYearInspectionAt ? oneYearInspectionAt.toISOString() : undefined,
        warrantyPeriodStartDate: warrantyPeriodStartDate
          ? warrantyPeriodStartDate.format('YYYY-MM-DD')
          : undefined,
        warrantyPeriodEndDate: warrantyPeriodEndDate
          ? warrantyPeriodEndDate.format('YYYY-MM-DD')
          : undefined,
        isProcoreIntegrationEnabled: !!procoreProjectId,
        procoreProjectId: procoreProjectId,
        procoreCompanyId: procoreCompanyId,
        defaultNotificationGroupId:
          defaultNotificationGroupId === currentProject?.defaultNotificationGroupId
            ? undefined
            : defaultNotificationGroupId,
      };
      setLoadingMessage('Submitting Construction Details');

      await patchProject(currentProject.id, project);

      if (procoreProjectId && procoreCompanyId) {
        setLoadingMessage('Syncing Procore Submittals and Submittal Packages');
        beginSyncSubmittalsAndPackages(currentProject.id);
        setLoadingMessage('Syncing Procore RFIs');
        beginSyncRfis(currentProject.id);
      }

      const newUsers = users.filter((x) => x.action === UserActionType.Add).map((x) => x.user);
      const removeUsers = users
        .filter((x) => x.action === UserActionType.Delete)
        .map((x) => x.user);
      const editUsers = users.filter((x) => x.action === UserActionType.Edit).map((x) => x.user);
      const inviteUsers = users
        .filter((x) => x.action === UserActionType.Invite)
        .map((x) => x.user);

      setLoadingMessage('Adding Project Users');
      await Promise.all(
        inviteUsers.map((projectUser) =>
          insertUser(
            {
              email: projectUser!.user!.email,
              name: projectUser!.user!.name,
              companyId: projectUser!.user!.companyId,
            },
            currentProject!.id || '',
            projectUser.securityGroupId || '',
          ).then((newUser) => {
            insertPhoneNumberByUserId(newUser.id, {
              number: projectUser!.user!.phoneNumbers![0].number,
              type: PhoneNumberType.Mobile,
              countryId: '',
            });
          }),
        ),
      );

      setLoadingMessage('Updating Project Users');
      await addOrModifyUsersOfProject(
        currentProject!.id,
        newUsers.map((projectUser) => {
          return {
            securityGroupId: projectUser.securityGroupId || undefined,
            userId: projectUser.user!.id!,
          };
        }),
      );

      await Promise.all(
        removeUsers.map((projectUser) =>
          removeUserFromProjectByProjectId(currentProject!.id, projectUser.user!.id),
        ),
      );

      await addOrModifyUsersOfProject(
        currentProject!.id,
        editUsers.map((projectUser) => {
          return { securityGroupId: projectUser.securityGroupId!, userId: projectUser.user!.id! };
        }),
      );

      dispatch(allowNavigation());
      handleComplete();
    } catch (e: any) {
      dispatch(allowNavigation());
      setIsLoading(false);
      console.log(e);
    }
  };

  const handleDefaultNotificationGroupChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const value = event.target.value as string;
    if (value === 'create') {
      setDefaultNotificationGroupIdString('');
      setGroupDialogOpen(true);
    } else if (value !== undefined && value !== null) {
      setDefaultNotificationGroupIdString(value);
      setNotificationListOpen(true);
    }
  };

  const handleDeleteUser = (id: string) => {
    const newUsers = [...users];

    const index = newUsers.findIndex((x) => x.user!.user!.id === id);
    //  If the user has just been added but the user hasn't pressed submit, simply remove the user from the user list.
    if (
      newUsers[index].action === UserActionType.Add ||
      newUsers[index].action === UserActionType.Invite
    ) {
      newUsers.splice(index, 1);
    } else {
      /* If the deletion above hasn't occurred, then that means the user is trying to delete
    a user that already exists in the database. We need to add a delete action to know to remove them
    from the database when the user submits the form */
      newUsers[index].action = UserActionType.Delete;
    }

    setUsers(newUsers);
  };

  const createProjectUser = (projectUser: IProjectUser, action = UserActionType.Add) => {
    const newUsers = [...users];
    newUsers.push({ user: projectUser, action });
    setUsers(newUsers);
  };

  const createProjectUsers = (projectUsers: IProjectUser[], action = UserActionType.Add) => {
    const newUsers = [...users];
    projectUsers.forEach((projectUser) => {
      newUsers.push({ user: projectUser, action });
    });
    setUsers(newUsers);
  };

  useEffect(() => {
    setUsers(
      projectUsers
        .filter(({ user }) => !user?.isSiteAdmin)
        .map((x) => {
          return { user: x, action: UserActionType.None };
        }),
    );
  }, [user, projectUsers]);

  useEffect(() => {
    if (!currentProject || currentProject.id !== params.projectId)
      dispatch(fetchProject(params.projectId));
  }, [params.projectId]);

  useEffect(() => {
    if (currentProject) {
      if (currentProject.procoreOauthId)
        getProcoreProjects(currentProject.id).then(setProcoreProjects);

      setSelectedArchitectAdministrator(currentProject.defaultArchitectUserId || undefined);
      setSelectedContractorAdministrator(
        currentProject.defaultGeneralContractorUserId || undefined,
      );
      setContractAmountInput(
        currentProject.contractAmount ? Math.round(currentProject.contractAmount / 100) : undefined,
      );
      setContractAmount(currentProject.contractAmount || undefined);
      setDocumentReviewPeriod(currentProject.documentReviewPeriod?.toString() || undefined);
      setRfiReviewPeriod(currentProject.rfiReviewPeriod?.toString() || undefined);
      setLiquidatedDamagesPerDay(currentProject.liquidatedDamagesPerDay?.toString() || '');
      setApprovedDate(currentProject.approvedDate ? parseDate(currentProject.approvedDate) : null);
      setNoticeToProceedDate(
        currentProject.noticeToProceedDate ? parseDate(currentProject.noticeToProceedDate) : null,
      );
      if (currentProject.scheduledEndDate && currentProject.noticeToProceedDate)
        setDaysToCompletion(
          parseDate(currentProject.scheduledEndDate)
            .diff(parseDate(currentProject.noticeToProceedDate!), 'day')
            .toString(),
        );
      setSubstantialCompletionDate(
        currentProject.substantialCompletionDate
          ? parseDate(currentProject.substantialCompletionDate)
          : null,
      );
      setOneYearInspectionAt(
        currentProject.oneYearInspectionAt ? parseDate(currentProject.oneYearInspectionAt) : null,
      );
      setWarrantyPeriodStartDate(
        currentProject.warrantyPeriodStartDate
          ? parseDate(currentProject.warrantyPeriodStartDate)
          : null,
      );
      setWarrantyPeriodEndDate(
        currentProject.warrantyPeriodEndDate
          ? parseDate(currentProject.warrantyPeriodEndDate)
          : null,
      );
      setProcoreProjectId(currentProject.procoreProjectId || undefined);
      setProcoreCompanyId(currentProject.procoreCompanyId || undefined);
      setDefaultNotificationGroupIdString(currentProject.defaultNotificationGroupId || '');
    }
  }, [currentProject]);

  return (
    <main className={classes.root}>
      <NavAppbar />
      <Grid container justify="center">
        <Grid item xs={12}>
          <PageTitle
            title={
              hasCompletedConstructionSetup() ? 'Construction Management' : 'Construction Setup'
            }
          />
        </Grid>
      </Grid>
      {currentProject ? (
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} container>
            <Card className={classes.paper}>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <form className={classes.form}>
                  <div className={classes.widthConstraint}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography className={classes.title} variant="h2">
                          {hasCompletedConstructionSetup()
                            ? 'Construction Management'
                            : 'Construction Setup'}{' '}
                          Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <ProcoreButton
                          disabled={disabled || !hasCompletedConstructionSetup()}
                          onClick={toggleProcoreIntegration}
                          style={{ marginTop: 4 }}
                        >
                          {!!currentProject?.procoreOauthId
                            ? 'Cancel Procore Sync'
                            : 'Connect With Procore'}
                        </ProcoreButton>
                      </Grid>

                      {currentProject?.procoreOauthId && (
                        <Grid item sm={6} xs={12}>
                          <FormControl style={{ display: 'flex' }}>
                            <Typography variant="body1">Procore Project</Typography>
                            <Select
                              disabled={disabled}
                              value={String(procoreProjectId)}
                              displayEmpty
                              variant="outlined"
                              style={{ marginTop: 4 }}
                              onChange={(event) => {
                                const procoreProjectId = Number(event.target.value);
                                const procoreProject = procoreProjects.find(
                                  (project) => project.id === procoreProjectId,
                                );
                                if (procoreProject) {
                                  setProcoreProjectId(procoreProject.id);
                                  setProcoreCompanyId(procoreProject.company.id);
                                } else {
                                  console.warn('Unable to find selected Procore project');
                                }
                              }}
                            >
                              <MenuItem value={undefined} disabled>
                                No project selected
                              </MenuItem>
                              {Boolean(procoreProjects) && Array.isArray(procoreProjects)
                                ? procoreProjects.map((project) => (
                                    <MenuItem key={project.id} value={project.id}>
                                      <span>
                                        {project.display_name} in <i>{project.company.name}</i>
                                      </span>
                                    </MenuItem>
                                  ))
                                : undefined}
                            </Select>
                          </FormControl>
                          <FormHelperText style={{ marginTop: 8 }}>
                            You must click "Submit Details" to initiate the Procore project sync.
                          </FormHelperText>
                        </Grid>
                      )}
                      {hasCompletedConstructionSetup() ? (
                        <Grid item xs={12}>
                          {isLoading ? (
                            <div className={classes.loadingCenter} style={{ marginTop: 16 }}>
                              <div className={classes.loadingMargin}>
                                <CircularLoader />
                              </div>
                              <div style={{ alignSelf: 'center' }}>{loadingMessage}</div>
                            </div>
                          ) : (
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                              <SubmitButton
                                disabled={disabled || !isFormValid()}
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                className={classes.submit}
                                style={{ marginLeft: 0 }}
                              >
                                <Add />
                                Submit Details
                              </SubmitButton>
                              {!isFormValid() ? (
                                <FormHelperText
                                  style={{ color: 'red', marginLeft: 8, marginBottom: 2 }}
                                >
                                  Required fields are empty.
                                </FormHelperText>
                              ) : null}
                            </div>
                          )}
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">Responsible Parties</Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <div className={classes.column}>
                          <FormControl error={architectAdministratorError !== undefined}>
                            <Typography variant="body1">
                              Architect Administrator<span style={{ color: 'red' }}>&nbsp; *</span>
                            </Typography>

                            <Select
                              disabled={disabled}
                              value={selectedArchitectAdministrator}
                              displayEmpty
                              variant="outlined"
                              style={{ marginTop: 4 }}
                              onChange={(event) =>
                                setSelectedArchitectAdministrator(event.target.value as string)
                              }
                            >
                              <MenuItem value={undefined} disabled>
                                Select Architect Administrator
                              </MenuItem>
                              {projectUsers
                                ?.sort((a, b) => sortLastNamesDesc(a.user?.name, b.user?.name))
                                .map((projectUser) => {
                                  return (
                                    <MenuItem key={projectUser.id} value={projectUser.userId}>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontWeight: 500 }}>
                                          {projectUser.user?.name}
                                        </span>
                                        <span>{projectUser.user?.company?.name}</span>
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText error={architectAdministratorError !== undefined}>
                              {architectAdministratorError}
                            </FormHelperText>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <div className={classes.column}>
                          <FormControl
                            error={contractorAdministratorError !== undefined}
                            style={{ marginBottom: 8 }}
                          >
                            <Typography variant="body1">
                              Contractor Administrator<span style={{ color: 'red' }}>&nbsp; *</span>
                            </Typography>
                            <Select
                              disabled={disabled}
                              variant="outlined"
                              value={selectedContractorAdministrator}
                              displayEmpty
                              style={{ marginTop: 4 }}
                              onChange={(event) =>
                                setSelectedContractorAdministrator(event.target.value as string)
                              }
                            >
                              <MenuItem value={undefined} disabled>
                                Select Contractor Administrator
                              </MenuItem>
                              {projectUsers
                                ?.sort((a, b) => sortLastNamesDesc(a.user?.name, b.user?.name))
                                .map((projectUser) => {
                                  return (
                                    <MenuItem key={projectUser.id} value={projectUser.userId}>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontWeight: 500 }}>
                                          {projectUser.user?.name}
                                        </span>
                                        <span>{projectUser.user?.company?.name}</span>
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText error={contractorAdministratorError !== null}>
                              {contractorAdministratorError}
                            </FormHelperText>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <div className={classes.column}>
                          <FormControl>
                            <Typography variant="body1">Default Notification Group</Typography>

                            <Select
                              disabled={disabled}
                              value={defaultNotificationGroupIdString}
                              displayEmpty
                              variant="outlined"
                              style={{ marginTop: 4, marginBottom: 8 }}
                              onChange={handleDefaultNotificationGroupChange}
                            >
                              <MenuItem key="none" value="">
                                <span style={{ fontWeight: 500 }}>None</span>
                              </MenuItem>
                              <MenuItem
                                key="create"
                                value="create"
                                disabled={
                                  !security?.canModifyUserGroups && !hasAdminPermissions(user)
                                }
                                style={{ paddingTop: 5, paddingBottom: 5 }}
                              >
                                <Add fontSize="small" style={{ marginLeft: -4, marginRight: 4 }} />
                                Create New Group
                              </MenuItem>
                              {groups.map((group) => (
                                <MenuItem key={group.id} value={group.id}>
                                  {group.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: 2,
                              }}
                            >
                              <Typography variant="body1" style={{ fontWeight: 500 }}>
                                Notification List
                              </Typography>
                              <IconButton
                                onClick={() => setNotificationListOpen((prev) => !prev)}
                                style={{ padding: 0 }}
                              >
                                {notificationListOpen ? <Remove /> : <Add />}
                              </IconButton>
                            </div>
                            {notificationListOpen && defaultNotificationGroupIdString && (
                              <InlineNotificationList
                                associatedUsers={[]}
                                associatedGroups={[defaultNotificationGroupIdString]}
                              />
                            )}
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider light />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">Financials</Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <div className={classes.column}>
                          <FormControl>
                            <Typography variant="body1">
                              Contract Amount <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <CurrencyTextField
                              disabled={disabled}
                              variant="outlined"
                              id="contract-amount"
                              placeholder="Contract Amount"
                              name="Contract Amount"
                              value={contractAmountInput}
                              required
                              currencySymbol="$"
                              minimumValue="0"
                              outputFormat="number"
                              decimalCharacter="."
                              digitGroupSeparator=","
                              onChange={(event: any, value: number) => {
                                setContractAmountInput(value);
                                setContractAmount(Math.round(value * 100)); // contract amount is in cents
                              }}
                              className={classes.textField}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <div className={classes.column}>
                          <FormControl>
                            <Typography variant="body1">Liquidated Damages Per Day</Typography>
                            <CurrencyTextField
                              disabled={disabled}
                              variant="outlined"
                              fullWidth
                              id="project-number"
                              placeholder="Liquidated Damages Per Day"
                              name="Project Number"
                              className={classes.textField}
                              value={
                                liquidatedDamagesPerDay
                                  ? parseInt(liquidatedDamagesPerDay, 10) / 100
                                  : undefined
                              }
                              currencySymbol="$"
                              minimumValue="0"
                              outputFormat="number"
                              decimalCharacter="."
                              digitGroupSeparator=","
                              onChange={(e: any, value: number) =>
                                setLiquidatedDamagesPerDay((value * 100).toString())
                              }
                              InputLabelProps={{
                                style: { overflow: 'hidden' },
                              }}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider light />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">Dates</Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <div className={classes.column}>
                          <FormControl>
                            <Typography variant="body1">
                              Days to Completion <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField
                              disabled={disabled}
                              variant="outlined"
                              fullWidth
                              required
                              id="project-number"
                              placeholder="Days to Completion"
                              name="Project Number"
                              className={classes.textField}
                              helperText={daysToCompletionError}
                              error={daysToCompletionError !== undefined}
                              value={daysToCompletion}
                              onChange={(e) =>
                                setDaysToCompletion(e.target.value.replace(/[^0-9]/g, ''))
                              }
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <Typography variant="body1">
                              Notice to Proceed Date <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <KeyboardDatePicker
                              disabled={disabled}
                              required
                              helperText={noticeToProceedDateError}
                              error={noticeToProceedDateError !== undefined}
                              value={noticeToProceedDate}
                              onChange={(d) => setNoticeToProceedDate(d)}
                              inputVariant="outlined"
                              InputProps={{ style: { padding: 0 } }}
                              placeholder="Notice to Proceed Date"
                              className={classes.textField}
                              format="MM/DD/YYYY"
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <Typography variant="body1">Scheduled End Date</Typography>
                            <KeyboardDatePicker
                              value={getScheduledEndDate()}
                              onChange={() => {}}
                              inputVariant="outlined"
                              disabled
                              InputProps={{ style: { padding: 0 } }}
                              placeholder="Scheduled End Date"
                              format="MM/DD/YYYY"
                              className={classes.textField}
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <Typography variant="body1">
                              Actual Substantial Completion Date
                            </Typography>
                            <KeyboardDatePicker
                              disabled={disabled}
                              value={substantialCompletionDate}
                              onChange={(d) => setSubstantialCompletionDate(d)}
                              inputVariant="outlined"
                              placeholder="Substantial Completion Date"
                              InputProps={{ style: { padding: 0 } }}
                              className={classes.textField}
                              format="MM/DD/YYYY"
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <div className={classes.column}>
                          <FormControl>
                            <Typography variant="body1">
                              Document Review Period (days) <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField
                              disabled={disabled}
                              variant="outlined"
                              fullWidth
                              required
                              id="project-number"
                              error={documentReviewPeriodError !== undefined}
                              helperText={documentReviewPeriodError}
                              placeholder="Document Review Period (days)"
                              name="Project Number"
                              className={classes.textField}
                              value={documentReviewPeriod}
                              onChange={(e) => setDocumentReviewPeriod(e.target.value)}
                            />
                          </FormControl>
                          <FormControl>
                            <Typography variant="body1">RFI Review Period (days)</Typography>
                            <TextField
                              disabled={disabled}
                              variant="outlined"
                              fullWidth
                              id="project-number"
                              error={rfiReviewPeriodError !== undefined}
                              helperText={rfiReviewPeriodError}
                              placeholder="RFI Review Period (days)"
                              name="Project Number"
                              className={classes.textField}
                              value={rfiReviewPeriod}
                              onChange={(e) => setRfiReviewPeriod(e.target.value)}
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <Typography variant="body1">Warranty Period Start Date</Typography>
                            <KeyboardDatePicker
                              disabled={disabled}
                              value={warrantyPeriodStartDate}
                              onChange={(d) => setWarrantyPeriodStartDate(d)}
                              inputVariant="outlined"
                              InputProps={{ style: { padding: 0 } }}
                              placeholder="Warranty Period Start Date"
                              className={classes.textField}
                              format="MM/DD/YYYY"
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <Typography variant="body1">Warranty Period End Date</Typography>
                            <KeyboardDatePicker
                              disabled={disabled}
                              value={warrantyPeriodEndDate}
                              onChange={(d) => setWarrantyPeriodEndDate(d)}
                              inputVariant="outlined"
                              InputProps={{ style: { padding: 0 } }}
                              placeholder="Warranty Period End Date"
                              className={classes.textField}
                              format="MM/DD/YYYY"
                            />
                          </FormControl>

                          <FormControl className={classes.formControl}>
                            <Typography variant="body1">One Year Inspection At</Typography>
                            <KeyboardDatePicker
                              value={
                                substantialCompletionDate
                                  ? substantialCompletionDate.add(1, 'y')
                                  : null
                              }
                              onChange={(d) => setOneYearInspectionAt(d)}
                              inputVariant="outlined"
                              InputProps={{ style: { padding: 0 } }}
                              disabled
                              placeholder="One Year Inspection At"
                              format="MM/DD/YYYY"
                              className={classes.textField}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ marginBottom: 16 }}>
                          Project Users
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>

                  <CreateProjectUsers
                    users={users
                      .filter((x) => x.action !== UserActionType.Delete)
                      .map((x) => {
                        return { ...x.user, isInvited: x.action !== UserActionType.Invite };
                      })}
                    createProjectUser={createProjectUser}
                    createProjectUsers={createProjectUsers}
                    handleDeleteUser={handleDeleteUser}
                    updateUser={() => {}}
                  />
                  <div style={{ marginBottom: 32 }} />

                  <div className={classes.widthConstraint}>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12}>
                        <Divider />
                      </Grid> */}
                      <Grid item xs={12}>
                        <div className={classes.endDiv}>
                          {isLoading ? (
                            <div className={classes.loadingCenter}>
                              <div className={classes.loadingMargin}>
                                <CircularLoader />
                              </div>
                              <div style={{ alignSelf: 'center' }}>{loadingMessage}</div>
                            </div>
                          ) : (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <SubmitButton
                                disabled={disabled || !isFormValid()}
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                className={classes.submit}
                              >
                                <Add />
                                Submit Details
                              </SubmitButton>
                              {!isFormValid() ? (
                                <FormHelperText
                                  style={{ color: 'red', marginLeft: 8, marginBottom: 2 }}
                                >
                                  Required fields are empty.
                                </FormHelperText>
                              ) : null}
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </MuiPickersUtilsProvider>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <FullscreenLoader />
      )}
      <CreateUserGroupDialog
        open={groupDialogOpen}
        handleClose={() => setGroupDialogOpen(false)}
        handleAddGroup={(g) => {
          if (defaultNotificationGroupSelectRef.current)
            defaultNotificationGroupSelectRef.current.value = g.id;
          setDefaultNotificationGroupIdString(g.id);
          setNotificationListOpen(true);
        }}
      />
    </main>
  );
}
