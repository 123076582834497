import { ApiClient } from '../../api-client/api-client';
import { PickIInsertionUserGroupUserExcludeKeyofIInsertionUserGroupUserUserGroupIdOrLastUpdatedByUserId } from '../../api-client/autogenerated';

export type InsertionUserGroupUser = PickIInsertionUserGroupUserExcludeKeyofIInsertionUserGroupUserUserGroupIdOrLastUpdatedByUserId;

export const getUserGroupsByProjectId = async (projectId: string) => {
  return ApiClient.getAllUserGroupsByProjectId({ projectId }).then((res) => res.data);
};

export const insertUserGroup = async (
  projectId: string,
  name: string,
  description?: string,
  users?: InsertionUserGroupUser[],
  companies?: string[],
) => {
  return ApiClient.insertUserGroupByProjectId({
    projectId,
    userGroupInsertionRequest: {
      userGroup: { name, description },
      insertUsers: users,
      insertCompanies: companies?.map((companyId) => {
        return { companyId };
      }),
    },
  }).then((res) => res.data);
};

export const editUserGroup = async (
  userGroupId: string,
  name?: string,
  description?: string,
  newUsers?: InsertionUserGroupUser[],
  newCompanies?: string[],
  removeUserIds?: string[],
  removeCompanyIds?: string[],
) => {
  return ApiClient.editUserGroupById({
    userGroupId,
    userGroupEdit: {
      name,
      description,
      insertUsers: newUsers,
      insertCompanies: newCompanies?.map((companyId) => {
        return { companyId };
      }),
      deleteUserGroupUserIds: removeUserIds,
      deleteUserGroupCompanyIds: removeCompanyIds,
    },
  }).then((res) => res.data);
};

export const deleteUserGroupById = (userGroupId: string) => {
  return ApiClient.deleteUserGroupById({ userGroupId }).then((res) => res.data);
};
