import { action } from 'typesafe-actions';
import { Dispatch } from 'react';
import { Projects } from './projects';
import SET_PROJECTS from './constants';
import { getProjectsSimplified } from '../../models/api/project';
import { isPublicPage } from '../../scripts/utils';

export const setProjects = (projects: Projects) => {
  return action(SET_PROJECTS, projects);
};

export const fetchProjects = (): any => (dispatch: Dispatch<any>) => {
  if (!isPublicPage()) {
    dispatch({ type: 'SET_PROJECTS_LOADING', payload: true });
    return getProjectsSimplified()
      .then((response) =>
        dispatch({ type: SET_PROJECTS, payload: response.results.filter((p) => !p.isArchived) }),
      )
      .catch()
      .finally(() => dispatch({ type: 'SET_PROJECTS_LOADING', payload: false }));
  }
};
