import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DocumentIndex from '../document-index/DocumentIndex';
import { IProjectUser } from '../../api-client/autogenerated';
import { HeadCell } from '../document-index/DocumentIndexHeader';
import { UserData } from '../document-index/DocumentIndexData';
import { UserActionType } from '../form-pages/AddOrManageProjectPage';

export type IManageProjectUser = IProjectUser & {
  isInvited?: boolean;
};

type CreateProjectUsersProps = {
  label?: string;
  users: IManageProjectUser[];
  createProjectUser?: (projectUser: IProjectUser, action?: UserActionType) => void;
  createProjectUsers: (projectUser: IProjectUser[], action?: UserActionType) => void;
  handleDeleteUser?: (id: string) => void;
  updateUser?: (
    id: string,
    name: string | undefined,
    description: string | undefined,
    email: string | undefined,
    website: string | undefined,
    securityGroupId: string | undefined,
    securityGroupName: string | undefined,
  ) => void;
};

const useStyles = makeStyles(() => ({
  content: {
    flexGrow: 1,
    // maxWidth: 1000,
  },
}));

// Head cells for User table
const headUserCells: HeadCell<UserData>[] = [
  { id: 'id', label: 'HIDE' },
  { id: 'name', label: 'Name' },
  { id: 'company', label: 'Company' },
  { id: 'email', label: 'Email' },
  { id: 'securityGroup', label: 'Security Group' },
];

function CreateProjectUsers(props: CreateProjectUsersProps) {
  const classes = useStyles();
  const {
    createProjectUser,
    createProjectUsers,
    users,
    handleDeleteUser,
    updateUser,
    label = 'Manage Project Users',
  } = props;
  const [searchInput, setSearchInput] = useState<string>('');

  return (
    <main className={classes.content}>
      <DocumentIndex
        label={label}
        columnTitles={headUserCells}
        projectUsers={users}
        createProjectPage
        createProjectUser={createProjectUser}
        createProjectUsers={createProjectUsers}
        handleDeleteUser={handleDeleteUser}
        updateUser={updateUser}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
    </main>
  );
}

export default CreateProjectUsers;
