import React, { useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Card, Grid, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import validator from 'validator';
import Button from '@material-ui/core/Button';
import { getUserState } from '../features/user/selectors';
import CenterlineIcon from '../images/CenterlineLogo.png';
import { addSnackbar } from '../features/snackbar/actions';

interface AuthenticationError {
  code: string;
  name: string;
  message: string;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  card: {
    display: 'flex',
    background: 'white',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  lineCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  avatar: {
    margin: 8,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 8,
  },
  submit: {
    marginTop: 16,
    marginBottom: 24,
    maxWidth: 240,
  },
  textField: {
    maxWidth: 350,
  },
  checkbox: {
    marginTop: 8,
    width: 400,
  },
  links: {
    maxWidth: 400,
  },
  nameContainer: {
    maxWidth: 500,
  },
  nameField: {
    maxWidth: 300,
  },
  link: {
    textDecoration: 'none',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#0947B9',
    '&:hover': {
      color: '#4380ED',
    },
  },
});

const BiddingPortalRegister: React.FC = () => {
  const classes = useStyles();
  const user = useSelector(getUserState);
  const history = useHistory();
  const dispatch = useDispatch();

  const [inputFirstName, setInputFirstName] = useState('');
  const [inputLastName, setInputLastName] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [inputCompany, setInputCompany] = useState('');

  const [errorText, setErrorText] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [captchaValue, setCaptchaValue] = useState('');

  // const [notifyNewProjects, setNotifyNewProjects] = useState(false);

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!captchaValue) {
        setErrorText('Fill out the captcha. If captcha is not shown, refresh page.');
      } else if (inputPassword !== repeatPassword) {
        setErrorText('Passwords must match');
      } else if (inputLastName === '') {
        setErrorText('Last name must not be empty.');
      } else if (inputCompany === '') {
        setErrorText('Company name must not be empty.');
      } else {
        // Amplify has bug - validationData should be an object of key:value
        const captchaValidationAttribute: any = {
          captchaValue,
        };

        await Auth.signUp({
          username: inputEmail,
          password: inputPassword,
          attributes: {
            given_name: `${inputFirstName} ${inputLastName}`,
            'custom:company_name': inputCompany.trim(),
          },
          validationData: captchaValidationAttribute,
        });

        setErrorText('');
        setRegistered(true);
      }
    } catch (error: any) {
      const message = error.toString();
      if (message.includes('AuthError')) {
        if (message.includes('Username')) setErrorText('Email Address cannot be empty');
        else setErrorText(message.slice(message.indexOf(':') + 2));
      }

      const errorObj = error as AuthenticationError;
      if (errorObj.code === 'InvalidParameterException') {
        if (errorObj.message.includes('Username should be an email'))
          setErrorText('Username should be an email');
        else setErrorText('Password must have at least 8 characters');
      } else if (errorObj.code === 'InvalidPasswordException') {
        if (errorObj.message.includes('Password not long enough'))
          setErrorText('Password must have at least 8 characters');
        else if (errorObj.message.includes('Password must have uppercase characters'))
          setErrorText('Password must have uppercase characters');
        else if (errorObj.message.includes('Password must have lowercase characters'))
          setErrorText('Password must have lowercase characters');
        else if (errorObj.message.includes('Password must have numeric characters'))
          setErrorText('Password must have numeric characters');
        else if (errorObj.message.includes('Password must have symbol characters'))
          setErrorText('Password must have symbol characters');
      } else {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: errorObj?.message || 'Something went wrong registering your account.',
            severity: 'error',
          }),
        );
      }
      console.log('error signing up:', error);
    }
  };

  const verifyCaptcha = (value: string | null) => {
    setCaptchaValue(value || '');
  };

  const handleConfirm = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const confirmedUser = await Auth.confirmSignUp(inputEmail, confirmationCode);
      console.log(confirmedUser);
      history.push('/bidding/login');
    } catch (error: any) {
      console.log('error confirming sign up: ', error);
      // TODO: remove history.push when error is fixed; add error handling for different exceptions
      if (error.code === 'UserLambdaValidationException') history.push('/main/login');
    }
  };

  const passwordOptions = {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
  };

  const isValidEmail = (email: string) => {
    if (email.length === 0) return true;
    return validator.isEmail(email);
  };

  const isValidPassword = (password: string) => {
    if (password.length === 0) return true;
    return validator.isStrongPassword(password, passwordOptions);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputEmail(event.target.value);
    setEmailError(!isValidEmail(event.target.value));
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPassword(event.target.value);
    setPasswordError(!isValidPassword(event.target.value));
  };

  useEffect(() => {
    if (user.id) history.push('/bidding');
  }, [user]);

  return (
    <Grid container className={classes.root} justify="center" alignItems="center">
      <Grid item xl={4} lg={6} sm={10} xs={11}>
        <Card className={classes.card}>
          <div className={classes.paper}>
            <img
              src={CenterlineIcon}
              alt="centerlinelogo"
              style={{ width: '350px', paddingBottom: '20px' }}
            />
            <Typography component="h1" variant="h5" style={{ textTransform: 'uppercase' }}>
              Sign Up
            </Typography>
            {errorText ? (
              <Typography style={{ color: 'red' }}>{errorText}</Typography>
            ) : (
              <span>&nbsp;&nbsp;</span>
            )}
            {registered ? (
              <form onSubmit={handleConfirm} className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label="Confirmation Code"
                  name="code"
                  autoComplete="one-time-code"
                  autoFocus
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value?.trim())}
                  className={classes.textField}
                />
                <Button
                  onClick={handleConfirm}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Confirm
                </Button>
              </form>
            ) : (
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="First Name"
                  name="First Name"
                  autoComplete="First Name"
                  autoFocus
                  value={inputFirstName}
                  onChange={(e) => setInputFirstName(e.target.value)}
                  className={classes.textField}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Last Name"
                  name="Last Name"
                  autoComplete="Last Name"
                  value={inputLastName}
                  onChange={(e) => setInputLastName(e.target.value)}
                  className={classes.textField}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={emailError}
                  value={inputEmail}
                  onChange={handleChangeEmail}
                  className={classes.textField}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="company"
                  label="Company Name"
                  name="company"
                  autoComplete="company"
                  value={inputCompany}
                  onChange={(e) => setInputCompany(e.target.value)}
                  className={classes.textField}
                />
                <Tooltip
                  title="Passwords must have at least 8 characters, and at least one of each of the
                  following: uppercase letters, lowercase letters, numbers, and symbols"
                  placement="right"
                  arrow
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    error={passwordError}
                    value={inputPassword}
                    onChange={handleChangePassword}
                    className={classes.textField}
                  />
                </Tooltip>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="repeat-password"
                  label="Repeat Password"
                  type="password"
                  id="repeat-password"
                  autoComplete="repeat-password"
                  error={repeatPassword !== inputPassword && repeatPassword.length > 0}
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  className={classes.textField}
                />
                {/*<FormControlLabel*/}
                {/*  control={*/}
                {/*    <GreenCheck*/}
                {/*      value={notifyNewProjects}*/}
                {/*      checked={notifyNewProjects}*/}
                {/*      onChange={(event: any) => setNotifyNewProjects(event.target.checked)}*/}
                {/*      name="notifyNewProjects"*/}
                {/*    />*/}
                {/*  }*/}
                {/*  label="Notify me about new projects posted to our public planroom."*/}
                {/*  style={{ marginTop: 8 }}*/}
                {/*/>*/}
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                  onChange={verifyCaptcha}
                />
                <Button
                  onClick={handleRegister}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  style={{ marginBottom: 5 }}
                >
                  Register
                </Button>
                <Link
                  to="/bidding/login"
                  className={classes.link}
                  style={{ textDecoration: 'none', marginTop: 3 }}
                >
                  Already have an account? Sign in
                </Link>
              </form>
            )}
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default BiddingPortalRegister;
