import React, { useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { getUsersFromUserGroup } from '../../design/ManagePermissionsDialog';
import { IUserGroup } from '../../../api-client/autogenerated';
import Remove from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import DistributionList from './DistributionList';

type Props = {
  group: IUserGroup;
  index: number;
  editOnClick: (group: IUserGroup) => void;
  deleteOnClick: (group: IUserGroup) => void;
};

export default function UserGroupTableRow(props: Props) {
  const { group, index, editOnClick, deleteOnClick } = props;

  const [expanded, setExpanded] = useState(false);

  const users = getUsersFromUserGroup(group);

  const backgroundColor = index % 2 === 0 ? '#F9F9F9' : 'white';

  return (
    <>
      <TableRow style={{ backgroundColor }}>
        <TableCell>
          <div style={{ display: 'flex' }}>
            <IconButton onClick={() => setExpanded((prev) => !prev)} style={{ padding: 0 }}>
              {expanded ? <Remove /> : <Add />}
            </IconButton>
            <Button color="primary" onClick={() => editOnClick(group)} style={{ padding: 0 }}>
              EDIT
            </Button>
            <Button onClick={() => deleteOnClick(group)} style={{ padding: 0, color: '#ED3F26' }}>
              DELETE
            </Button>
          </div>
        </TableCell>
        <TableCell>{group.name}</TableCell>
        <TableCell>{group.description || <i style={{ textTransform: 'none' }}>None</i>}</TableCell>
        <TableCell>{users.length}</TableCell>
        <TableCell />
      </TableRow>
      <TableRow style={{ backgroundColor }}>
        <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <DistributionList open={expanded} users={users} />
        </TableCell>
      </TableRow>
    </>
  );
}
