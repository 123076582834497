import _ from 'lodash';
import {
  ActionTakenType,
  DocumentTemplateType,
  FileCategoryType,
  IBid,
  IDocumentDocument,
  IFile,
  INotification,
  INumberedDocumentView,
  IProjectUser,
  ISecurityGroup,
  PunchListStatusType,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import {
  documentIsOverdue,
  fileIsImage,
  fileIsPdf,
  formatPhoneNumber,
  getAsBuiltStatus,
  getCurrentlyResponsibleCompany,
  getCurrentlyResponsibleUsers,
  getPageTitle,
  getSubmittalTitle,
  parseDate,
} from '../../scripts/utils';
import { addSpacing, descendingComparator } from './DocumentIndexUtils';
import { IManageProjectUser } from '../project-users/CreateProjectUsers';
import { getBidSetupById } from '../../models/api/project-bid-setups';

export type Responsibility = { ids: string[]; names: string[]; company?: string };

export type Assigned = { company: string; name: string; id: string };

export interface RowData {
  id: string;
  number: string;
  title: string;
  issueDate: string;
  dueDate: string;
  status: WorkflowStatusType | string;
  fileName: string;
  fileDate: number;
}

interface DefaultRowData {
  id: string;
}

export interface ASIData extends DefaultRowData {
  number: string;
  description: string;
  title: string;
  submittedBy: Responsibility;
  postedDate: string;
}

export interface ChangeOrderData extends DefaultRowData {
  number: string;
  title: string;
  description: string;
  cost: number;
  time: number;
  postedDate: string;
}

export interface ConstructionChangeData extends DefaultRowData {
  id: string;
  number: string;
  title: string;
  description: string;
  submittedBy: Responsibility;
  postedDate: string;
}

export interface ContractorDailyLogData extends DefaultRowData {
  logDate: string;
  description: string;
  title: string;
  attachment: IFile | null;
  postedDate: string;
}

export interface FieldReportData extends DefaultRowData {
  dateOfObservation: string;
  title: string;
  description: string;
  attachment: IFile | null;
  postedDate: string;
  isCreatedInCenterline: boolean;
  isDraft: boolean;
}

export interface MeetingMinutesData extends DefaultRowData {
  meetingDate: string;
  title: string;
  description: string;
  attachment: IFile | null;
  postedDate: string;
}

export interface MiscDocumentData extends DefaultRowData {
  number: string;
  title: string;
  description: string;
  attachment: IFile | null;
  postedDate: string;
}

export interface ScheduleData extends DefaultRowData {
  number: string;
  title: string;
  description: string;
  submittedBy: Responsibility;
  attachment: IFile | null;
  scheduleDate: string;
}

export interface TestingData extends DefaultRowData {
  title: string;
  referenceNumber: string;
  description: string;
  agency: string;
  submittedBy: Responsibility;
  attachment: IFile | null;
  postedDate: string;
}

export interface RFIData extends DefaultRowData {
  number: string;
  title: string;
  description: string;
  submittedDate: string;
  responsibility: Responsibility;
  dueDate: string;
  status: string;
  returnedDate: string;
  acceptance: string;
  overdue: boolean;
}

export interface WorkChangeData extends DefaultRowData {
  referenceNumber: string;
  title: string;
  description: string;
  submittedDate: string;
  responsibility: Responsibility;
  dueDate: string;
  status: string;
  returnedDate: string;
  overdue: boolean;
  acceptance: string;
}

export interface PayApplicationData extends DefaultRowData {
  referenceNumber: string;
  title: string;
  description: string;
  submittedDate: string;
  responsibility: Responsibility;
  status: string;
  returnedDate: string;
  executedDate: string;
  overdue: boolean;
}

export interface PotentialChangeOrderData extends DefaultRowData {
  number: string;
  title: string;
  description: string;
  cost: number | null;
  time: number | null;
  submittedDate: string;
  responsibility: Responsibility;
  dueDate: string;
  status: string;
  returnedDate: string;
  overdue: boolean;
}

export interface RequestForChangeData extends DefaultRowData {
  number: string;
  title: string;
  description: string;
  cost: number | null;
  time: number | null;
  submittedDate: string;
  responsibility: Responsibility;
  dueDate: string;
  status: string;
  returnedDate: string;
  overdue: boolean;
}

export interface SubmittalData extends DefaultRowData {
  number: string;
  submittalDescription: { sectionTitle: string; title: string };
  initialDate: string;
  actualDate: string;
  responsibility: Responsibility;
  dueDate: string;
  status: string;
  returnedDate: string;
  action: string;
  overdue: boolean;
  typeId: string;
  packageDocumentId: string;
  assignedToUserId: string;
  priority: string;
}

export interface RegulatoryApprovalData extends DefaultRowData {
  number: string;
  referenceNumber: string;
  title: string;
  filename: string;
  postedDate: string;
}

export interface PlanholderListData extends DefaultRowData {
  company: string;
  contact: string;
  email: string;
  download: string;
  view: string;
  unsubscribed: string;
}

export interface BidderRfiData extends DefaultRowData {
  number: string;
  company: string;
  contact: string;
  submittedDate: string;
  dueDate: string;
  comments: string;
  status: string;
  overdue: boolean;
}

export interface BidTabulationData extends DefaultRowData {
  number: string;
  company: string;
  license: string;
  contact: string;
  submittedDateTime: string;
  openedOn: string;
}

export interface SubstitutionRequestData extends DefaultRowData {
  number: string;
  section: string;
  company: string;
  contact: string;
  submittedDate: string;
  dueDate: string;
  comments: string;
  status: string;
  overdue: boolean;
}

export interface InformationalItemsParentData extends DefaultRowData {
  number: string;
  title: string;
  postedDate: string;
  children: InformationalItemsChildData[];
}

export interface InformationalItemsChildData extends DefaultRowData {
  file: IFile | null;
  number: string;
  title: string;
  postedDate: string;
}

export interface AddendaParentData extends DefaultRowData {
  number: string;
  title: string;
  postedDate: string;
  viewRecipients: INotification | null | undefined;
  children: AddendaChildData[];
}

export interface AddendaChildData extends DefaultRowData {
  file: IFile | null;
  number: string;
  title: string;
  postedDate: string;
}

export interface BidDrawingsData extends DefaultRowData {
  viewButton: IFile;
  file: IFile;
  filename: string;
  size: string;
  postedDate: string;
}

export interface DrawingsData extends DefaultRowData {
  viewButton: IFile;
  downloadButton: IFile;
  simplePackage: string;
  number: string;
  title: string;
  mostRecentFile: IFile | null;
  page: number;
  associatedDocuments: IDocumentDocument[];
  documentTitle: string;
  postedDate: string;
}

export interface ProjectManualData extends DefaultRowData {
  viewButton: IFile;
  file: IFile;
  filename: string;
  size: string;
  postedDate: string;
}

export interface SpecificationsData extends DefaultRowData {
  viewButton?: IFile;
  downloadButton?: IFile;
  number: string;
  title: string;
  associatedDocuments: IDocumentDocument[];
  documentTitle: string;
  // TODO: Re-enable after fixing source document logic
  // mostRecentFile: IFile | null;
  postedDate: string;
}

export interface AsBuiltData extends DefaultRowData {
  viewButton: IFile | null;
  number: string;
  title: string;
  status: string;
  responsibility: Responsibility;
  dueDate: string;
  pageNumber: number;
  typeId: string;
  packageDocumentId: string;
}

export interface SubstantialCompletionCertData extends DefaultRowData {
  number: string;
  referenceNumber: string;
  description: string;
  postedDate: string;
}

export interface ClearLPData extends DefaultRowData {
  number: string;
  referenceNumber: string;
  description: string;
  postedDate: string;
}

export interface CloseoutSubmittalData extends SubmittalData {}

export interface OperationsAndMaintenanceData extends DefaultRowData {
  number: string;
  referenceNumber: string;
  section: string;
  description: string;
  submittedBy: Responsibility;
  postedDate: string;
}

export interface PunchListData extends DefaultRowData {
  building: string;
  locationId: string;
  title: string;
  responsibleParty: string;
  cost: number;
  status: string;
  postedDate: string;
  lastModified: string;
  isDraft: boolean;
}

export interface WarrantyItemsData extends DefaultRowData {
  number: string;
  referenceNumber: string;
  submittedDate: string;
  description: string;
  location: string;
  responsibility: Responsibility | string;
  expectedCompletionDate: string;
  status: string;
  completedDate: string;
  approvedDate: string;
}

export interface TaskData extends DefaultRowData {
  number: string;
  title: string;
  description: string;
  assignedBy: Assigned;
  assignedTo: Assigned;
  priority: string;
  dueDate: string;
  submittedDate: string;
  status: string;
  returnedDate: string;
  dateOfLastUpdate: string;
  overdue: boolean;
}

export interface DesignPackageData extends DefaultRowData {
  number: string;
  title: string;
  description: string;
  assignedBy: Assigned;
  assignedTo: Assigned[];
  dueDate: string;
  submittedDate: string;
  status: string;
  returnedDate: string;
  dateOfLastUpdate: string;
  overdue: boolean;
}

export interface UserData {
  id: string;
  title: string;
  name: string;
  company: string;
  email: string;
  securityGroup: string;
}

// creating data for rows of Submittal documents
function createSubmittalData(
  id: string,
  number: string,
  submittalDescription: { sectionTitle: string; title: string },
  initialDate: string,
  actualDate: string,
  responsibility: Responsibility,
  dueDate: string,
  status: string,
  returnedDate: string,
  action: string,
  typeId: string,
  packageDocumentId: string,
  assignedToUserId: string,
  overdue: boolean,
  priority: string,
): SubmittalData {
  return {
    id,
    number,
    submittalDescription,
    initialDate,
    actualDate,
    responsibility,
    dueDate,
    status,
    returnedDate,
    action,
    typeId,
    packageDocumentId,
    assignedToUserId,
    overdue,
    priority,
  };
}

// creating data for rows of users
function createUserData(
  id: string,
  name: string,
  company: string,
  email: string,
  phone: string,
  securityGroup: ISecurityGroup | undefined,
  isRegistered: boolean,
  isInvited: boolean,
  isDisabled: boolean | null | undefined,
  adminOfSubscriberId: string | null,
  isSiteAdmin: boolean,
) {
  return {
    id,
    name,
    company,
    email,
    phone,
    securityGroup,
    isRegistered,
    isInvited,
    isDisabled,
    adminOfSubscriberId,
    isSiteAdmin,
  };
}

function createUserDirectoryData(
  id: string,
  name: string,
  company: string,
  email: string,
  securityGroup: string,
  adminOfSubscriberId: string | null,
  isSiteAdmin: boolean,
) {
  return { id, name, company, email, securityGroup, adminOfSubscriberId, isSiteAdmin };
}

// creating data for rows of ASI documents
function createASIData(
  id: string,
  number: string,
  description: string,
  title: string,
  submittedBy: Responsibility,
  postedDate: string,
): ASIData {
  return { id, number, description, title, submittedBy, postedDate };
}

// creating data for rows of Change Order documents
function createChangeOrderData(
  id: string,
  number: string,
  title: string,
  description: string,
  cost: number,
  time: number,
  postedDate: string,
): ChangeOrderData {
  return { id, number, title, description, cost, time, postedDate };
}

// creating data for rows of Construction Change documents
function createConstructionChangeData(
  id: string,
  number: string,
  title: string,
  description: string,
  submittedBy: Responsibility,
  postedDate: string,
): ConstructionChangeData {
  return { id, number, title, description, submittedBy, postedDate };
}

// creating data for rows of Contractor Daily Log documents
function createContractorDailyLogData(
  id: string,
  logDate: string,
  description: string,
  title: string,
  attachment: IFile | null,
  postedDate: string,
): ContractorDailyLogData {
  return { id, logDate, title, description, attachment, postedDate };
}

// creating data for rows of Field Report documents
function createFieldReportData(
  id: string,
  dateOfObservation: string,
  title: string,
  description: string,
  attachment: IFile | null,
  postedDate: string,
  isCreatedInCenterline: boolean,
  isDraft: boolean,
): FieldReportData {
  return {
    id,
    dateOfObservation,
    title,
    description,
    attachment,
    postedDate,
    isCreatedInCenterline,
    isDraft,
  };
}

// creating data for rows of Meeting Minutes documents
function createMeetingMinutesData(
  id: string,
  meetingDate: string,
  title: string,
  description: string,
  attachment: IFile | null,
  postedDate: string,
): MeetingMinutesData {
  return { id, meetingDate, title, description, attachment, postedDate };
}

// creating data for rows of Misc. documents
function createMiscDocumentData(
  id: string,
  number: string,
  title: string,
  description: string,
  attachment: IFile | null,
  postedDate: string,
): MiscDocumentData {
  return { id, number, title, description, attachment, postedDate };
}

// creating data for rows of Schedule documents
function createScheduleData(
  id: string,
  number: string,
  title: string,
  description: string,
  submittedBy: Responsibility,
  attachment: IFile | null,
  scheduleDate: string,
): ScheduleData {
  return { id, number, title, description, submittedBy, attachment, scheduleDate };
}

// creating data for rows of Testing documents
function createTestingData(
  id: string,
  referenceNumber: string,
  title: string,
  description: string,
  agency: string,
  submittedBy: Responsibility,
  attachment: IFile | null,
  postedDate: string,
): TestingData {
  return { id, referenceNumber, title, description, agency, submittedBy, attachment, postedDate };
}

// creating data for rows of RFI documents
function createRFIData(
  id: string,
  number: string,
  title: string,
  description: string,
  submittedDate: string,
  responsibility: Responsibility,
  dueDate: string,
  status: string,
  returnedDate: string,
  acceptance: string,
  overdue: boolean,
): RFIData {
  return {
    id,
    number,
    title,
    description,
    submittedDate,
    responsibility,
    dueDate,
    status,
    returnedDate,
    acceptance,
    overdue,
  };
}

// creating data for rows of Work Change Proposal Request documents
function createWorkChangeData(
  id: string,
  referenceNumber: string,
  title: string,
  description: string,
  submittedDate: string,
  responsibility: Responsibility,
  dueDate: string,
  status: string,
  returnedDate: string,
  acceptance: string,
  overdue: boolean,
): WorkChangeData {
  return {
    id,
    referenceNumber,
    title,
    description,
    submittedDate,
    responsibility,
    dueDate,
    status,
    returnedDate,
    acceptance,
    overdue,
  };
}

// creating data for rows of Pay Application documents
function createPayApplicationData(
  id: string,
  referenceNumber: string,
  title: string,
  description: string,
  submittedDate: string,
  responsibility: Responsibility,
  status: string,
  returnedDate: string,
  executedDate: string,
  overdue: boolean,
): PayApplicationData {
  return {
    id,
    referenceNumber,
    title,
    description,
    submittedDate,
    responsibility,
    status,
    returnedDate,
    executedDate,
    overdue,
  };
}

// creating data for rows of Potential Change Order documents
function createPotentialChangeOrderData(
  id: string,
  number: string,
  title: string,
  description: string,
  cost: number | null,
  time: number | null,
  submittedDate: string,
  responsibility: Responsibility,
  dueDate: string,
  status: string,
  returnedDate: string,
  overdue: boolean,
): PotentialChangeOrderData {
  return {
    id,
    number,
    title,
    description,
    cost,
    time,
    submittedDate,
    responsibility,
    dueDate,
    status,
    returnedDate,
    overdue,
  };
}

// creating data for rows of Request For Change documents
function createRequestForChangeData(
  id: string,
  number: string,
  title: string,
  description: string,
  cost: number | null,
  time: number | null,
  submittedDate: string,
  responsibility: Responsibility,
  dueDate: string,
  status: string,
  returnedDate: string,
  overdue: boolean,
): RequestForChangeData {
  return {
    id,
    number,
    title,
    description,
    cost,
    time,
    submittedDate,
    responsibility,
    dueDate,
    status,
    returnedDate,
    overdue,
  };
}

function createAddendaParentData(
  id: string,
  number: string,
  title: string,
  postedDate: string,
  viewRecipients: INotification | null | undefined,
  children: AddendaChildData[],
): AddendaParentData {
  return { id, number, title, postedDate, viewRecipients, children };
}

function createAddendaChildData(
  id: string,
  file: IFile | null,
  number: string,
  title: string,
  postedDate: string,
): AddendaChildData {
  return { id, file, number, title, postedDate };
}

function createWarrantyClaimsData(
  id: string,
  number: string,
  referenceNumber: string,
  description: string,
  location: string,
  submittedDate: string,
  responsibility: Responsibility | string,
  expectedCompletionDate: string,
  status: string,
  completedDate: string,
  approvedDate: string,
): WarrantyItemsData {
  return {
    id,
    number,
    referenceNumber,
    description,
    location,
    submittedDate,
    responsibility,
    expectedCompletionDate,
    status,
    completedDate,
    approvedDate,
  };
}

function createInformationalItemsParentData(
  id: string,
  number: string,
  title: string,
  postedDate: string,
  children: InformationalItemsChildData[],
): InformationalItemsParentData {
  return { id, number, title, postedDate, children };
}

function createInformationalItemsChildData(
  id: string,
  file: IFile | null,
  number: string,
  title: string,
  postedDate: string,
): InformationalItemsChildData {
  return { id, file, number, title, postedDate };
}

function createCloseoutData(
  id: string,
  number: string,
  submittalDescription: { sectionTitle: string; title: string },
  initialDate: string,
  actualDate: string,
  responsibility: Responsibility,
  dueDate: string,
  status: string,
  returnedDate: string,
  action: string,
  typeId: string,
  packageDocumentId: string,
  assignedToUserId: string,
  overdue: boolean,
  priority: string,
): CloseoutSubmittalData {
  return {
    id,
    number,
    submittalDescription,
    initialDate,
    actualDate,
    responsibility,
    dueDate,
    status,
    returnedDate,
    action,
    typeId,
    packageDocumentId,
    assignedToUserId,
    overdue,
    priority,
  };
}

function createOperationsAndMaintenanceData(
  id: string,
  number: string,
  referenceNumber: string,
  section: string,
  description: string,
  submittedBy: Responsibility,
  postedDate: string,
): OperationsAndMaintenanceData {
  return { id, number, referenceNumber, section, description, submittedBy, postedDate };
}

function createRegulatoryApprovalData(
  id: string,
  number: string,
  referenceNumber: string,
  title: string,
  filename: string,
  postedDate: string,
): RegulatoryApprovalData {
  return { id, number, referenceNumber, title, filename, postedDate };
}

function createBidTabulationData(
  id: string,
  number: string,
  company: string,
  license: string,
  contact: string,
  submittedDateTime: string,
  openedOn: string,
): BidTabulationData {
  return { id, number, company, license, contact, submittedDateTime, openedOn };
}

function createPlanholderListData(
  id: string,
  company: string,
  contact: string,
  email: string,
  download: string,
  view: string,
  unsubscribed: string,
): PlanholderListData {
  return { id, company, contact, email, download, view, unsubscribed };
}

function createBidderRfiData(
  id: string,
  number: string,
  company: string,
  contact: string,
  submittedDate: string,
  dueDate: string,
  comments: string,
  status: string,
  overdue: boolean,
): BidderRfiData {
  return {
    id,
    number,
    company,
    contact,
    submittedDate,
    dueDate,
    comments,
    status,
    overdue,
  };
}

function createPunchlistData(
  id: string,
  building: string,
  locationId: string,
  title: string,
  responsibleParty: string,
  cost: number,
  status: string,
  postedDate: string,
  lastModified: string,
  isDraft: boolean,
): PunchListData {
  return {
    id,
    building,
    locationId,
    title,
    responsibleParty,
    status,
    cost,
    postedDate,
    lastModified,
    isDraft,
  };
}

function createSubstitutionRequestData(
  id: string,
  number: string,
  section: string,
  company: string,
  contact: string,
  submittedDate: string,
  dueDate: string,
  comments: string,
  status: string,
  overdue: boolean,
): SubstitutionRequestData {
  return {
    id,
    number,
    section,
    company,
    contact,
    submittedDate,
    dueDate,
    comments,
    status,
    overdue,
  };
}

function createClearLPData(
  id: string,
  number: string,
  referenceNumber: string,
  description: string,
  postedDate: string,
): ClearLPData {
  return { id, number, referenceNumber, description, postedDate };
}

function createSubstantialCompletionCertData(
  id: string,
  number: string,
  referenceNumber: string,
  description: string,
  postedDate: string,
): SubstantialCompletionCertData {
  return { id, number, referenceNumber, description, postedDate };
}

function createProjectManualData(
  id: string,
  viewButton: IFile,
  file: IFile,
  filename: string,
  size: string,
  postedDate: string,
): ProjectManualData {
  return { id, viewButton, file, filename, size, postedDate };
}

function createAsBuiltData(
  id: string,
  viewButton: IFile | null,
  number: string,
  title: string,
  responsibility: Responsibility,
  dueDate: string,
  status: string,
  pageNumber: number,
  typeId: string,
  packageDocumentId: string,
): AsBuiltData {
  return {
    id,
    viewButton,
    number,
    title,
    responsibility,
    dueDate,
    status,
    pageNumber,
    typeId,
    packageDocumentId,
  };
}

function createBidDrawingsData(
  id: string,
  viewButton: IFile,
  file: IFile,
  filename: string,
  size: string,
  postedDate: string,
): BidDrawingsData {
  return { id, viewButton, file, filename, size, postedDate };
}

function createDrawingsData(
  id: string,
  viewButton: IFile,
  downloadButton: IFile,
  simplePackage: string,
  number: string,
  title: string,
  associatedDocuments: IDocumentDocument[],
  documentTitle: string,
  mostRecentFile: IFile | null,
  page: number,
  postedDate: string,
): DrawingsData {
  return {
    id,
    viewButton,
    downloadButton,
    simplePackage,
    number,
    title,
    associatedDocuments,
    documentTitle,
    mostRecentFile,
    page,
    postedDate,
  };
}

function createSpecificationsData(
  id: string,
  viewButton: IFile | undefined,
  downloadButton: IFile | undefined,
  number: string,
  title: string,
  // TODO: Re-enable after fixing source document logic
  // mostRecentFile: IFile | null,
  associatedDocuments: IDocumentDocument[],
  documentTitle: string,
  postedDate: string,
): SpecificationsData {
  return {
    id,
    viewButton,
    downloadButton,
    number,
    title,
    associatedDocuments,
    documentTitle,
    postedDate,
  };
}

function createTaskData(
  id: string,
  number: string,
  title: string,
  description: string,
  assignedBy: Assigned,
  assignedTo: Assigned,
  priority: string,
  dueDate: string,
  submittedDate: string,
  status: string,
  returnedDate: string,
  dateOfLastUpdate: string,
  overdue: boolean,
): TaskData {
  return {
    id,
    number,
    title,
    description,
    assignedBy,
    assignedTo,
    priority,
    dueDate,
    submittedDate,
    status,
    returnedDate,
    dateOfLastUpdate,
    overdue,
  };
}

function createDesignPackageData(
  id: string,
  number: string,
  title: string,
  description: string,
  assignedBy: Assigned,
  assignedTo: Assigned[],
  dueDate: string,
  submittedDate: string,
  status: string,
  returnedDate: string,
  dateOfLastUpdate: string,
  overdue: boolean,
): DesignPackageData {
  return {
    id,
    number,
    title,
    description,
    assignedBy,
    assignedTo,
    dueDate,
    submittedDate,
    status,
    returnedDate,
    dateOfLastUpdate,
    overdue,
  };
}

// For creating rows of documents
export const getSubmittalRows = (
  documents: INumberedDocumentView[],
  division?: string,
  section?: string,
) => {
  let result = documents;
  if (section)
    result = result.filter((document) => {
      return (
        document.submittalSection === section ||
        document.submittalChildDocuments?.some((d) => d.submittalSection === section)
      );
    });
  else if (division)
    result = result.filter((document) => {
      return (
        document.submittalDivision === division ||
        document.submittalChildDocuments?.some((d) => d.submittalSection?.startsWith(division))
      );
    });
  if (!documents) return [];

  return result.map((document) => {
    const responsibleUsers = getCurrentlyResponsibleUsers(document);
    return createSubmittalData(
      document.id!,
      document.submittalSection
        ? `${getSubmittalTitle(document, false)}`
        : document.referenceNumber || document.documentNumber?.toString()!,
      {
        sectionTitle: document.submittalSectionDescription || 'N/A',
        title: document.title || 'N/A',
      },
      document.anticipatedInitialSubmissionDate || 'N/A',
      document.actualSubmissionDate || 'N/A',
      {
        ids: responsibleUsers.map((e) => e.id).filter((id) => id) as string[],
        names: responsibleUsers.map((e) => e.name),
        company: getCurrentlyResponsibleCompany(document) || '',
      },
      document.dueDate || 'N/A',
      document.workflowStatus || 'N/A',
      document.dateReturnedToContractor || 'N/A',
      document.actionTaken || 'N/A',
      document.documentTemplateId!,
      document.submittalPackageDocumentId || '',
      document.assignedToUserId || document.generalContractorUser?.id || 'N/A',
      documentIsOverdue(document, true),
      document.priority || '',
    );
  });
};

// For creating rows of users
export const getUserRows = (users: IManageProjectUser[]) => {
  return users.map((user) => {
    if (user === null)
      return createUserData(
        'N/A',
        'N/A',
        'N/A',
        'N/A',
        'N/A',
        undefined,
        false,
        true,
        undefined,
        null,
        false,
      );
    if (user.user === null)
      return createUserData(
        'N/A',
        'N/A',
        'N/A',
        'N/A',
        'N/A',
        undefined,
        false,
        true,
        undefined,
        null,
        false,
      );
    const phone = formatPhoneNumber(
      user.user!.phoneNumbers && user.user!.phoneNumbers[0]
        ? user.user!.phoneNumbers![0].number!
        : 'N/A',
    );
    return createUserData(
      user.user!.id!,
      user.user!.name!,
      user.user!.company! ? user.user!.company!.name! : 'N/A',
      user.user!.email!,
      phone || 'N/A',
      user.securityGroup ? user.securityGroup : undefined,
      user.user?.isRegistered || false,
      !!user.isInvited,
      user.user!.isDisabled,
      user.user!.adminOfSubscriberId ?? null,
      !!user.user!.isSiteAdmin,
    );
  });
};

export const getUserDirectoryRows = (users: IProjectUser[]) => {
  return users.map((user) => {
    return createUserDirectoryData(
      user.user!.id!,
      user.user!.name!,
      user.user!.company! ? user.user!.company!.name! : 'N/A',
      user.user!.email!,
      user.securityGroup?.name || 'N/A',
      user.user!.adminOfSubscriberId ?? null,
      !!user.user!.isSiteAdmin,
    );
  });
};

// For creating rows of ASI documents
export const getASIRows = (asiDocuments: INumberedDocumentView[]) => {
  const result = asiDocuments;
  if (!asiDocuments) return [];
  return result.map((document) => {
    const creatorUser =
      document.creatorUser ||
      document.documentUserList?.find((u) => u.userId === document.creatorUserId)?.user;
    return createASIData(
      document.id || '',
      document.referenceNumber || '',
      document.title || 'N/A',
      document.description || 'N/A',
      {
        ids: [document.creatorUserId],
        names: creatorUser ? [creatorUser?.name] : [],
        company: creatorUser?.company?.name || creatorUser?.publicCompanyName || '',
      },
      document.createdOn || 'N/A',
    );
  });
};

// For creating rows of Change Order documents
export const getChangeOrderRows = (changeOrderDocuments: INumberedDocumentView[]) => {
  const result = changeOrderDocuments;
  if (!changeOrderDocuments) return [];
  return result.map((document) => {
    return createChangeOrderData(
      document.id || '',
      document.referenceNumber || 'N/A',
      document.title || 'N/A',
      document.description || 'N/A',
      document.changeOrdersValue || 0,
      document.changeOrdersDaysAdded || 0,
      document.createdOn || 'N/A',
    );
  });
};

// For creating rows of Construction Change documents
export const getConstructionChangeRows = (constructionChangeDocuments: INumberedDocumentView[]) => {
  const result = constructionChangeDocuments;
  if (!constructionChangeDocuments) return [];
  return result.map((document) => {
    const creatorUser =
      document.creatorUser ||
      document.documentUserList?.find((u) => u.userId === document.creatorUserId)?.user;
    return createConstructionChangeData(
      document.id || '',
      document.referenceNumber || '',
      document.title || 'N/A',
      document.description || 'N/A',
      {
        ids: [document.creatorUserId],
        names: creatorUser ? [creatorUser?.name] : [],
        company: creatorUser?.company?.name || creatorUser?.publicCompanyName || '',
      },
      document.createdOn || 'N/A',
    );
  });
};

// For creating rows of Contractor Daily Log documents
export const getContractorDailyLogRows = (contractorDailyLogDocuments: INumberedDocumentView[]) => {
  const result = contractorDailyLogDocuments;
  if (!contractorDailyLogDocuments) return [];
  return result.map((document) => {
    const mostRecentFile =
      document.files
        ?.slice()
        .filter((f) => fileIsPdf(f))
        .sort((a, b) => descendingComparator(a, b, 'createdOn'))[0] || null;
    return createContractorDailyLogData(
      document.id || '',
      document.dateOfObservation
        ? parseDate(document.dateOfObservation).format('YYYY-MM-DD')
        : 'N/A',
      document.description || 'N/A',
      document.title || 'N/A',
      mostRecentFile,
      document.createdOn || 'N/A',
    );
  });
};

// For creating rows of Field Report documents
export const getFieldReportRows = (fieldReportDocuments: INumberedDocumentView[]) => {
  const result = fieldReportDocuments;
  if (!fieldReportDocuments) return [];
  return result.map((document) => {
    const mostRecentFile =
      document.files
        ?.slice()
        .filter((f) => fileIsPdf(f))
        .sort((a, b) => descendingComparator(a, b, 'createdOn'))[0] || null;
    const isCreatedInCenterline =
      !!document.weather ||
      !!document.location ||
      !!document.contactCompany ||
      !!document.files?.slice().find((f) => fileIsImage(f));
    return createFieldReportData(
      document.id || '',
      document.dateOfObservation || 'N/A',
      document.title || 'N/A',
      document.description || 'N/A',
      mostRecentFile,
      document.createdOn || 'N/A',
      isCreatedInCenterline,
      !!document.isDraft,
    );
  });
};

// For creating rows of Meeting Minutes documents
export const getMeetingMinutesRows = (meetingMinutesDocuments: INumberedDocumentView[]) => {
  const result = meetingMinutesDocuments;
  if (!meetingMinutesDocuments) return [];
  return result.map((document) => {
    const mostRecentFile =
      document.files
        ?.slice()
        .filter((f) => fileIsPdf(f))
        .sort((a, b) => descendingComparator(a, b, 'createdOn'))[0] || null;
    return createMeetingMinutesData(
      document.id,
      document.dateOfObservation || '',
      document.title || 'N/A',
      document.description || 'N/A',
      mostRecentFile,
      document.createdOn || 'N/A',
    );
  });
};

// For creating rows of Misc. documents
export const getMiscRows = (miscDocuments: INumberedDocumentView[]) => {
  const result = miscDocuments;
  if (!miscDocuments) return [];
  return result.map((document) => {
    const mostRecentFile =
      document.files
        ?.slice()
        .filter((f) => fileIsPdf(f))
        .sort((a, b) => descendingComparator(a, b, 'createdOn'))[0] || null;
    return createMiscDocumentData(
      document.id || '',
      document.documentNumber?.toString() || 'N/A',
      document.title || '',
      document.description || 'N/A',
      mostRecentFile,
      document.createdOn || 'N/A',
    );
  });
};

// For creating rows of Schedule documents
export const getScheduleRows = (scheduleDocuments: INumberedDocumentView[]) => {
  const result = scheduleDocuments;
  if (!scheduleDocuments) return [];
  return result.map((document) => {
    const mostRecentFile =
      document.files
        ?.slice()
        .filter((f) => fileIsPdf(f))
        .sort((a, b) => descendingComparator(a, b, 'createdOn'))[0] || null;
    const creatorUser =
      document.creatorUser ||
      document.documentUserList?.find((u) => u.userId === document.creatorUserId)?.user;
    return createScheduleData(
      document.id || '',
      document.documentNumber?.toString() ?? 'N/A',
      document.title || '',
      document.description || 'N/A',
      {
        ids: [document.creatorUserId],
        names: creatorUser ? [creatorUser?.name] : [],
        company: creatorUser?.company?.name || creatorUser?.publicCompanyName || '',
      },
      mostRecentFile,
      document.createdOn || 'N/A',
    );
  });
};

// For creating rows of Testing documents
export const getTestingRows = (testingDocuments: INumberedDocumentView[]) => {
  const result = testingDocuments;
  if (!testingDocuments) return [];
  return result.map((document) => {
    const mostRecentFile =
      document.files
        ?.slice()
        .filter((f) => fileIsPdf(f))
        .sort((a, b) => descendingComparator(a, b, 'createdOn'))[0] || null;
    const creatorUser =
      document.creatorUser ||
      document.documentUserList?.find((u) => u.userId === document.creatorUserId)?.user;
    return createTestingData(
      document.id || '',
      document.referenceNumber || '',
      document.title || '',
      document.description || 'N/A',
      document.agency || 'N/A',
      {
        ids: [document.creatorUserId],
        names: creatorUser ? [creatorUser?.name] : [],
        company: creatorUser?.company?.name || creatorUser?.publicCompanyName || '',
      },
      mostRecentFile,
      document.createdOn || 'N/A',
    );
  });
};

// For creating rows of RFI documents
export const getRFIRows = (rfiDocuments: INumberedDocumentView[]) => {
  const getAcceptance = (document: INumberedDocumentView) => {
    if (document.actionTaken === ActionTakenType.Withdrawn) return 'Withdrawn';
    switch (document.workflowStatus) {
      case WorkflowStatusType.Resolved:
        return 'Resolved';

      case WorkflowStatusType.Resubmitted:
        return 'Resubmitted';

      default:
        return 'N/A';
    }
  };

  const result = rfiDocuments;
  if (!rfiDocuments) return [];
  return result.map((document) => {
    const responsibleUsers = getCurrentlyResponsibleUsers(document);
    return createRFIData(
      document.id || '',
      document.referenceNumber
        ? `${document.referenceNumber}${
            document.revisionNumber ? `-R${document.revisionNumber}` : ''
          }`
        : '',
      document.title || '',
      document.description || 'N/A',
      document.dateSubmitted || document.createdOn || 'N/A',
      {
        ids: responsibleUsers.map((e) => e.id).filter((id) => id) as string[],
        names: responsibleUsers.map((e) => e.name),
        company: getCurrentlyResponsibleCompany(document) || '',
      },
      document.dueDate || 'N/A',
      document.workflowStatus || 'N/A',
      document.dateReturnedToContractor || 'N/A',
      getAcceptance(document),
      documentIsOverdue(document),
    );
  });
};

// For creating rows of Work Change Proposal Request documents
export const getWorkChangeRows = (workChangeDocuments: INumberedDocumentView[]) => {
  const result = workChangeDocuments;
  const getAcceptance = (document: INumberedDocumentView) => {
    switch (document.workflowStatus) {
      case WorkflowStatusType.Resolved:
        return 'Resolved';

      case WorkflowStatusType.Resubmitted:
        return 'Resubmitted';

      default:
        return 'N/A';
    }
  };
  if (!workChangeDocuments) return [];
  return result.map((document) => {
    const responsibleUsers = getCurrentlyResponsibleUsers(document);
    return createWorkChangeData(
      document.id || '',
      document.referenceNumber || 'N/A',
      document.title || 'N/A',
      document.description || 'N/A',
      document.dateSubmitted || 'N/A',
      {
        ids: responsibleUsers.map((e) => e.id).filter((id) => id) as string[],
        names: responsibleUsers.map((e) => e.name),
        company: getCurrentlyResponsibleCompany(document) || '',
      },
      document.dueDate || 'N/A',
      document.workflowStatus || 'N/A', // TODO: may not be the right status enum
      document.dateReturnedToContractor || 'N/A',
      getAcceptance(document) || 'N/A',
      documentIsOverdue(document),
    );
  });
};

// For creating rows of Pay Application documents
export const getPayApplicationRows = (payApplicationDocuments: INumberedDocumentView[]) => {
  const result = payApplicationDocuments;
  if (!payApplicationDocuments) return [];
  return result.map((document) => {
    const responsibleUsers = getCurrentlyResponsibleUsers(document);
    return createPayApplicationData(
      document.id || '',
      document.referenceNumber || '',
      document.title || 'N/A',
      document.description || 'N/A',
      document.dateSubmitted || 'N/A',
      {
        ids: responsibleUsers.map((e) => e.id).filter((id) => id) as string[],
        names: responsibleUsers.map((e) => e.name),
        company: getCurrentlyResponsibleCompany(document) || '',
      },
      document.workflowStatus || 'N/A',
      document.dateReturnedToContractor || 'N/A',
      document.dateFinalExecuted || 'N/A',
      documentIsOverdue(document),
    );
  });
};

// For creating rows of Potential Change Order documents
export const getPotentialChangeOrderRows = (
  potentialChangeOrderDocuments: INumberedDocumentView[],
) => {
  const result = potentialChangeOrderDocuments;
  if (!potentialChangeOrderDocuments) return [];
  return result.map((document) => {
    const responsibleUsers = getCurrentlyResponsibleUsers(document);
    return createPotentialChangeOrderData(
      document.id || '',
      `${document.referenceNumber || document.documentNumber?.toString()}${
        document.revisionNumber ? `-R${document.revisionNumber}` : ''
      }`,
      document.title || 'N/A',
      document.description || 'N/A',
      document.proposedCostImpact ?? null,
      document.proposedTimeImpact ?? null,
      document.createdOn || 'N/A',
      {
        ids: responsibleUsers.map((e) => e.id).filter((id) => id) as string[],
        names: responsibleUsers.map((e) => e.name),
        company: getCurrentlyResponsibleCompany(document) || '',
      },
      document.dueDate || 'N/A',
      document.workflowStatus || 'N/A',
      document.dateReturnedToContractor || 'N/A',
      documentIsOverdue(document),
    );
  });
};

// For creating rows of Request For Change documents
export const getRequestForChangeRows = (requestForChangeDocuments: INumberedDocumentView[]) => {
  const result = requestForChangeDocuments;
  if (!requestForChangeDocuments) return [];
  return result.map((document) => {
    const responsibleUsers = getCurrentlyResponsibleUsers(document);
    return createRequestForChangeData(
      document.id || '',
      document.referenceNumber || '',
      document.title || 'N/A',
      document.description || 'N/A',
      document.proposedCostImpact ?? null,
      document.proposedTimeImpact ?? null,
      document.dateSubmitted || 'N/A',
      {
        ids: responsibleUsers.map((e) => e.id).filter((id) => id) as string[],
        names: responsibleUsers.map((e) => e.name),
        company: getCurrentlyResponsibleCompany(document) || '',
      },
      document.dueDate || 'N/A',
      document.workflowStatus || 'N/A',
      document.dateReturnedToContractor || 'N/A',
      documentIsOverdue(document),
    );
  });
};

export const getAddendaRows = (addendaDocuments: INumberedDocumentView[]) => {
  const result = addendaDocuments;
  if (!addendaDocuments) return [];
  return result.map((document) => {
    const documentNumber = document.documentNumber
      ? document.documentNumber.toString().length === 1
        ? `0${document.documentNumber}`
        : document.documentNumber.toString()
      : 'N/A';
    return createAddendaParentData(
      document.id || '',
      documentNumber,
      document.title || '',
      document.createdOn || 'N/A',
      document.lockedNotificationJson,
      document.files?.map((file) =>
        createAddendaChildData(file.id, file, documentNumber, file.name, file.createdOn || 'N/A'),
      ) || [],
    );
  });
};

export const getCloseoutRows = (
  documents: INumberedDocumentView[],
  division?: string,
  section?: string,
) => {
  let result = documents;
  if (section)
    result = result.filter((document) => {
      return (
        document.submittalSection === section ||
        document.submittalChildDocuments?.some((d) => d.submittalSection === section)
      );
    });
  else if (division)
    result = result.filter((document) => {
      return (
        document.submittalDivision === division ||
        document.submittalChildDocuments?.some((d) => d.submittalSection?.startsWith(division))
      );
    });
  if (!documents) return [];

  return result.map((document) => {
    const responsibleUsers = getCurrentlyResponsibleUsers(document);
    return createCloseoutData(
      document.id!,
      document.submittalSection
        ? `${getSubmittalTitle(document, false)}`
        : document.referenceNumber || document.documentNumber?.toString()!,
      {
        sectionTitle: document.submittalSectionDescription || 'N/A',
        title: document.title || 'N/A',
      },
      document.anticipatedInitialSubmissionDate || 'N/A',
      document.actualSubmissionDate || 'N/A',
      {
        ids: responsibleUsers.map((e) => e.id).filter((id) => id) as string[],
        names: responsibleUsers.map((e) => e.name),
        company: getCurrentlyResponsibleCompany(document) || '',
      },
      document.dueDate || 'N/A',
      document.workflowStatus || 'N/A',
      document.dateReturnedToContractor || 'N/A',
      document.actionTaken || 'N/A',
      document.documentTemplateId!,
      document.submittalPackageDocumentId || '',
      document.assignedToUserId || document.generalContractorUser?.id || 'N/A',
      documentIsOverdue(document, true),
      document.priority || '',
    );
  });
};

export const getOperationAndMaintenanceRows = (oMDocuments: INumberedDocumentView[]) => {
  const result = oMDocuments;
  if (!oMDocuments) return [];
  return result.map((document) => {
    const creatorUser =
      document.creatorUser ||
      document.documentUserList?.find((u) => u.userId === document.creatorUserId)?.user;
    return createOperationsAndMaintenanceData(
      document.id!,
      document.documentNumber?.toString() ?? 'N/A',
      document.referenceNumber || '',
      document.submittalSection ? addSpacing(document.submittalSection) : 'N/A',
      document.description || 'N/A',
      {
        ids: [document.creatorUserId],
        names: creatorUser ? [creatorUser?.name] : [],
        company: creatorUser?.company?.name || creatorUser?.publicCompanyName || '',
      },
      document.createdOn || 'N/A',
    );
  });
};

export const getRegulatoryApprovalRows = (documents: INumberedDocumentView[]) => {
  const result = documents;
  if (!documents) return [];
  return result.map((document) => {
    return createRegulatoryApprovalData(
      document.id!,
      document.documentNumber?.toString() ?? 'N/A',
      document.referenceNumber || '',
      document.title || 'N/A',
      document.files && document.files[0] !== undefined && document.files[0].name
        ? document.files[0].name
        : 'N/A',
      document.createdOn || 'N/A',
    );
  });
};

export const getBidTabulationRows = (bids: IBid[]) => {
  const result = bids.filter((b) => !b.isRetracted);
  if (!bids) return [];
  return result.map((bid) => {
    return createBidTabulationData(
      bid.id!,
      `${bid.bidNumber || 0}`,
      bid.projectUser?.primeBidder?.companyName || 'N/A',
      bid.projectUser?.primeBidder?.contractorLicenseNo || 'N/A',
      bid.projectUser?.primeBidder?.contactName || 'N/A',
      bid.createdOn || 'N/A',
      bid.openedOn || '',
    );
  });
};

export const getPlanholderListRows = (projectUsers: IProjectUser[]) => {
  if (!projectUsers) return [];
  return projectUsers
    .filter((pu) => pu.planHolderOn)
    .map((projectUser) => {
      return createPlanholderListData(
        projectUser.userId,
        projectUser.user?.company?.name || projectUser.user?.publicCompanyName || 'N/A',
        projectUser.user?.name || 'N/A',
        projectUser.user?.email || 'N/A',
        `${projectUser.bidSetupDownloads || 0}`,
        `${projectUser.bidSetupViews || 0}`,
        projectUser.unsubscribedOn || 'N/A',
      );
    });
};

export const getBidderRfiRows = (documents: INumberedDocumentView[]) => {
  const result = documents;
  if (!documents) return [];
  return result.map((document) => {
    return createBidderRfiData(
      document.id!,
      document.documentNumber?.toString() ?? 'N/A',
      document.contactCompany || 'N/A',
      document.contactName || 'N/A',
      document.dateSubmitted || 'N/A',
      document.dueDate || 'N/A',
      document.reviewComment || 'N/A',
      document.workflowStatus || 'N/A',
      documentIsOverdue(document),
    );
  });
};

export const getPunchListRows = (documents: INumberedDocumentView[]) => {
  const result = documents;
  if (!documents) return [];
  return result.map((document) => {
    return createPunchlistData(
      document.id!,
      '',
      document.physicalLocationId || 'N/A', //document.physicalLocationId
      document.description || 'N/A',
      document.customContractor || 'N/A', //document.customContractor
      document.cost || 0,
      document.punchListStatus || 'N/A', //document.punchListStatus
      document.createdOn || 'N/A',
      document.updatedOn || 'N/A',
      document.isDraft || false,
    );
  });
};

export const getSubstitutionRequestRows = (documents: INumberedDocumentView[]) => {
  const result = documents;
  if (!documents) return [];
  return result.map((document) => {
    return createSubstitutionRequestData(
      document.id!,
      document.documentNumber?.toString() ?? 'N/A',
      document.submittalSection ? addSpacing(document.submittalSection) : 'N/A',
      document.contactCompany || 'N/A',
      document.contactName || 'N/A',
      document.dateSubmitted || 'N/A',
      document.dueDate || 'N/A',
      document.reviewComment || 'N/A',
      document.workflowStatus || 'N/A',
      documentIsOverdue(document),
    );
  });
};

export const getProjectManualRows = async (bidSetupId: string) => {
  const { files } = await getBidSetupById(bidSetupId);
  if (!files || files.filter((f) => f.category === FileCategoryType.BidSpecifications).length === 0)
    return [];
  return files
    .filter((f) => f.category === FileCategoryType.BidSpecifications)
    .map((file) => {
      return createProjectManualData(
        file.id,
        file,
        file,
        file.name,
        file.size ? `${(file.size / 1024 / 1024).toFixed(2)} MB` : 'N/A',
        file.createdOn || 'N/A',
      );
    });
};

export const getClearLPRows = (documents: INumberedDocumentView[]) => {
  const result = documents;
  if (!documents) return [];
  return result.map((document) => {
    return createClearLPData(
      document.id!,
      document.documentNumber?.toString() ?? 'N/A',
      document.referenceNumber || '',
      document.description || 'N/A',
      document.createdOn || 'N/A',
    );
  });
};

export const getSubstantialCompletionCertRows = (documents: INumberedDocumentView[]) => {
  const result = documents;
  if (!documents) return [];
  return result.map((document) => {
    return createSubstantialCompletionCertData(
      document.id!,
      document.documentNumber?.toString() ?? 'N/A',
      document.referenceNumber || '',
      document.description || 'N/A',
      document.createdOn || 'N/A',
    );
  });
};

export const getBidDrawingsRows = async (bidSetupId: string) => {
  const { files } = await getBidSetupById(bidSetupId);
  if (!files || files.filter((f) => f.category === FileCategoryType.BidDrawings).length === 0)
    return [];
  return files
    .filter((f) => f.category === FileCategoryType.BidDrawings)
    .map((file) => {
      return createBidDrawingsData(
        file.id,
        file,
        file,
        file.name,
        file.size ? `${(file.size / 1024 / 1024).toFixed(2)} MB` : 'N/A',
        file.createdOn || 'N/A',
      );
    });
};

export const getDrawingsRows = (documents: INumberedDocumentView[]) => {
  const result = documents;
  if (!documents) return [];
  return result.map((document) => {
    const mostRecentFile: IFile | undefined = (_.concat(
      document?.files,
      document?.drawingsSectionFile,
    ).filter((f) => f) as IFile[]).sort((a, b) => descendingComparator(a, b, 'createdOn'))[0];
    return createDrawingsData(
      document.id,
      mostRecentFile || '',
      mostRecentFile || '',
      document.simplePackage || 'N/A',
      document.sheetNumber || 'N/A',
      document.title || 'N/A',
      [],
      getPageTitle(DocumentTemplateType.Drawings, document),
      mostRecentFile || null,
      document.pageNumber ?? 0,
      mostRecentFile?.createdOn || document.datePublished || document.createdOn || 'N/A',
    );
  });
};

export const getInformationalItemsRows = (documents: INumberedDocumentView[]) => {
  const result = documents;
  if (!documents) return [];
  return result.map((document) => {
    const documentNumber = document.documentNumber
      ? document.documentNumber.toString().length === 1
        ? `0${document.documentNumber}`
        : document.documentNumber.toString()
      : 'N/A';
    return createInformationalItemsParentData(
      document.id || '',
      documentNumber,
      document.title || '',
      document.createdOn || 'N/A',
      document.files?.map((file) =>
        createInformationalItemsChildData(
          file.id,
          file,
          documentNumber,
          file.name,
          file.createdOn || 'N/A',
        ),
      ) || [],
    );
  });
};

export const getAsBuiltRows = (documents: INumberedDocumentView[]) => {
  const result = documents;
  if (!documents) return [];
  return result.map((document) => {
    const responsibleUsers = getCurrentlyResponsibleUsers(document);
    const mostRecentFile =
      document.files
        ?.slice()
        .filter((f) => fileIsPdf(f))
        .sort((a, b) => descendingComparator(a, b, 'createdOn'))[0] || null;
    const documentNumber = document.sheetNumber || document.referenceNumber;
    return createAsBuiltData(
      document.id!,
      document.workflowStatus !== WorkflowStatusType.Initial ? mostRecentFile : null,
      documentNumber
        ? `${documentNumber}${document.revisionNumber ? `-R${document.revisionNumber}` : ''}`
        : 'N/A',
      document.title || 'N/A',
      {
        ids: responsibleUsers.map((e) => e.id).filter((id) => id) as string[],
        names: responsibleUsers.map((e) => e.name),
        company: getCurrentlyResponsibleCompany(document) || '',
      },
      document.dueDate || 'N/A',
      getAsBuiltStatus(document),

      document.pageNumber ?? 0,
      document.documentTemplateId,
      document.submittalPackageDocumentId || '',
    );
  });
};

export const getWarrantyClaimsRows = (documents: INumberedDocumentView[]) => {
  const result = documents;
  if (!documents) return [];

  const getResponsibleUser = (document: INumberedDocumentView) => {
    if (
      document.punchListStatus === PunchListStatusType.New ||
      document.punchListStatus === PunchListStatusType.InProgress
    ) {
      if (document.customContractor) return document.customContractor;
      if (document.generalContractorUser)
        return {
          ids: [document.generalContractorUser.id],
          names: [document.generalContractorUser.name],
          company: document.generalContractorUser.company?.name,
        };
    } else if (document.punchListStatus === PunchListStatusType.ReadyForVerification) {
      if (document.architectUser)
        return {
          ids: [document.architectUser.id],
          names: [document.architectUser.name],
          company: document.architectUser.company?.name,
        };
    }

    return 'N/A';
  };

  return result.map((document) => {
    return createWarrantyClaimsData(
      document.id!,
      `${document.documentNumber}${document.revisionNumber ? `-R${document.revisionNumber}` : ''}`,
      document.referenceNumber || 'N/A',
      document.description || 'N/A',
      document.location || 'N/A',
      document.createdOn || 'N/A',
      getResponsibleUser(document),
      document.expectedCompletionDate || 'N/A',
      document.punchListStatus || 'N/A',
      document.dateContractorCompleted || 'N/A',
      document.reviewCompleteOn || 'N/A',
    );
  });
};

export const getSpecificationsRows = (documents: INumberedDocumentView[], division?: string) => {
  let result = documents;
  if (division)
    result = result.filter((document) => {
      return document.submittalDivision === division;
    });
  if (!result) return [];
  return result.map((document) => {
    const mostRecentFile = _.nth(
      (_.concat(document?.files, document?.submittalSectionFile).filter(
        (f) => f,
      ) as IFile[]).sort((a, b) => descendingComparator(a, b, 'createdOn')),
      0,
    );
    return createSpecificationsData(
      document.id || '',
      mostRecentFile,
      mostRecentFile,
      document.submittalSection ? addSpacing(document.submittalSection) : 'N/A',
      document.submittalSectionDescription || 'N/A',
      // TODO: Re-enable after fixing source document logic
      // mostRecentFile || null,
      [],
      getPageTitle(DocumentTemplateType.Specifications, document),
      mostRecentFile?.createdOn || document.datePublished || document.createdOn || 'N/A',
    );
  });
};

export const getTaskRows = (documents: INumberedDocumentView[]) => {
  return documents.map((document) => {
    return createTaskData(
      document.id,
      document.referenceNumber
        ? `${document.referenceNumber}${
            document.revisionNumber ? `-R${document.revisionNumber}` : ''
          }`
        : '',
      document.title || 'N/A',
      document.description || 'N/A',
      {
        company:
          document.generalContractorUser?.company?.name ||
          document.generalContractorUser?.publicCompanyName ||
          '',
        name: document.generalContractorUser?.name || '',
        id: document.generalContractorUserId || '',
      },
      {
        company:
          document.architectUser?.company?.name || document.architectUser?.publicCompanyName || '',
        name: document.architectUser?.name || '',
        id: document.architectUserId || '',
      },
      document.priority || 'N/A',
      document.dueDate || 'N/A',
      document.dateSubmitted || 'N/A',
      document.workflowStatus || 'N/A',
      document.dateReturnedToContractor || 'N/A',
      document.dateOfLastUpdate || 'N/A',
      documentIsOverdue(document),
    );
  });
};

export const getDesignPackageRows = (documents: INumberedDocumentView[]) => {
  return documents.map((document) => {
    const assignedUsers: Assigned[] =
      document.additionalReviewDocuments?.map((doc) => ({
        company: doc.assignedToUser?.company?.name || doc.assignedToUser?.publicCompanyName || '',
        name: doc.assignedToUser?.name || '',
        id: document.assignedToUserId || '',
      })) || [];

    return createDesignPackageData(
      document.id,
      document.referenceNumber
        ? `${document.referenceNumber}${
            document.revisionNumber ? `-R${document.revisionNumber}` : ''
          }`
        : '',
      document.title || 'N/A',
      document.description || 'N/A',
      {
        company:
          document.generalContractorUser?.company?.name ||
          document.generalContractorUser?.publicCompanyName ||
          '',
        name: document.generalContractorUser?.name || '',
        id: document.generalContractorUserId || '',
      },
      assignedUsers,
      document.dueDate || 'N/A',
      document.dateSubmitted || 'N/A',
      document.workflowStatus || 'N/A',
      document.dateReturnedToContractor || 'N/A',
      document.dateOfLastUpdate || 'N/A',
      documentIsOverdue(document),
    );
  });
};
