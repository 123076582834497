import React from 'react';

const BiddingIcon = (props: any) => (
  <svg
    width={24}
    height={21}
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.431 20.03c0 .278-.226.47-.466.47a.457.457 0 01-.274-.093L14.477 18H10.76c-1.368 0-2.477-1.12-2.477-2.5v-1.792l-2.975 1.7a.456.456 0 01-.274.092.467.467 0 01-.466-.47V13H3.33C1.962 13 .853 11.88.853 10.5V3C.853 1.62 1.962.5 3.33.5h11.147c1.368 0 2.477 1.12 2.477 2.5v3.75h3.715c1.369 0 2.478 1.12 2.478 2.5v6.25c0 1.38-1.11 2.5-2.477 2.5H19.43v2.03zM5.807 11.75v1.936l3.39-1.936h5.28c.683 0 1.238-.561 1.238-1.25V3c0-.689-.555-1.25-1.238-1.25H3.33c-.683 0-1.238.561-1.238 1.25v7.5c0 .689.555 1.25 1.238 1.25h2.477zm3.406-1.25c.171 0 .31-.14.31-.313v-.523c.845-.05 1.524-.766 1.524-1.659 0-.741-.478-1.403-1.161-1.61l-1.609-.487C8.114 5.858 8 5.688 8 5.495c0-.236.168-.428.374-.428h1.004c.15 0 .296.042.424.121.123.076.283.045.387-.054l.443-.425c.136-.131.13-.36-.021-.473a2.08 2.08 0 00-1.087-.403v-.52A.311.311 0 009.213 3h-.619c-.17 0-.31.14-.31.312v.524c-.845.05-1.524.766-1.524 1.659 0 .74.478 1.403 1.162 1.61l1.608.487c.163.05.278.22.278.413 0 .236-.168.428-.374.428H8.431a.805.805 0 01-.425-.121c-.122-.076-.283-.045-.387.054l-.443.425c-.136.131-.13.36.021.473a2.08 2.08 0 001.087.403v.52c0 .173.139.313.31.313h.62zm5.59 6.25l3.39 1.936V16.75h2.476c.683 0 1.239-.561 1.239-1.25V9.25c0-.69-.556-1.25-1.239-1.25h-3.715v2.5c0 1.38-1.109 2.5-2.477 2.5H9.523v2.5c0 .689.556 1.25 1.238 1.25h4.042z"
      fill="#73A3FF"
    />
  </svg>
);

export default BiddingIcon;
