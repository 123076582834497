import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HighlightOffRounded } from '@material-ui/icons';
import { IUserGroup } from '../../api-client/autogenerated';
import CreateUserGroup from '../form-pages/user-groups/CreateUserGroup';
import { ManagePermissionsDialogType } from './ManagePermissionsDialog';
import { useDispatch } from 'react-redux';
import { upsertGroup } from '../../features/groups/actions';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleAddGroup: (userGroup: IUserGroup) => void;
  type?: ManagePermissionsDialogType;
  existingGroup?: IUserGroup | null;
};

const useStyles = makeStyles({
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'none',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export default function CreateUserGroupDialog(props: Props) {
  const classes = useStyles();
  const { open, handleClose, handleAddGroup, type, existingGroup } = props;

  const dispatch = useDispatch();

  const addGroup = (userGroup: IUserGroup) => {
    dispatch(upsertGroup(userGroup));
    handleClose();
    handleAddGroup(userGroup);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onDoubleClick={(e) => e.stopPropagation()}>
      <DialogTitle disableTypography className={classes.title}>
        Create User Group
        <IconButton
          style={{ right: '18px', top: '14px', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CreateUserGroup type={type} onSubmit={addGroup} existingGroup={existingGroup} />
      </DialogContent>
    </Dialog>
  );
}
