import { IInsertionSubscriberSecurityGroup, ISecurityGroup } from '../../api-client/autogenerated';
import { ApiClient, getQueryFilterString } from '../../api-client/api-client';

// GET //

export const getSecurityGroupsFromUserId = async (
  id: string,
  limit = 500,
): Promise<ISecurityGroup[]> => {
  const response = await ApiClient.getAllSecurityGroupsForUsersSubscribersByUserId({
    userId: id,
    pageSize: limit,
  });
  return response.data.results;
};

export const getAllSecurityGroupsBySubscriberId = async (
  subscriberId: string,
): Promise<ISecurityGroup[]> => {
  const response = await ApiClient.getAllSecurityGroupsBySubscriberId({ subscriberId });
  return response.data;
};

export const insertSecurityGroupBySubscriberId = async (
  subscriberId: string,
  securityGroup: IInsertionSubscriberSecurityGroup,
) => {
  const response = await ApiClient.insertSecurityGroupBySubscriberId({
    subscriberId,
    iInsertionSubscriberSecurityGroup: securityGroup,
  });
  return response.data;
};

export const editSecurityGroupBySubscriberId = async (
  subscriberId: string,
  securityGroupId: string,
  securityGroup: IInsertionSubscriberSecurityGroup,
) => {
  const response = await ApiClient.editSecurityGroupBySubscriberId({
    subscriberId,
    securityGroupId,
    iOptionalSubscriberSecurityGroup: securityGroup,
  });
  return response.data;
};

export const deleteSecurityGroupById = async (id: string, newSecurityGroupId?: string) => {
  return ApiClient.deleteSecurityGroupById({ id, newSecurityGroupId }).then((res) => res.data);
};

export const getProjectUserSummaryBySecurityGroupId = async (securityGroupId: string) => {
  return ApiClient.getProjectUserSummaryBySecurityGroupId({ securityGroupId }).then(
    (res) => res.data,
  );
};
