import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { INotification, SimpleUserNotificationSendingStatus } from '../../api-client/autogenerated';
import {
  getNotificationRecipientsByIds,
  getNotificationsByBidId,
  getNotificationsByDocumentId,
  getNotificationsByKey,
} from '../../models/api/notifications';
import ActivityItem from './ActivityItem';
import { useSelector } from 'react-redux';
import { getDocumentState } from '../../features/document/selectors';
import CircularLoader from '../loader/CircularLoader';
import { Pagination } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import { Remove } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import CardContent from '@material-ui/core/CardContent';
import { Card, Collapse, useMediaQuery, useTheme } from '@material-ui/core';
import { isPublicPage, PublicDocumentMatchParams } from '../../scripts/utils';
import { useParams } from 'react-router';
import { getDocumentsType } from '../../features/documents/selectors';
import { getBidState } from '../../features/bid/selectors';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'inline-flex',
      flexDirection: 'column',
      flexBasis: 'auto',
      zIndex: -1,
    },
    noPadding: {
      padding: '0',
    },
    padding: {
      padding: 5,
    },
    box: {
      overflow: 'none',
      display: 'flex',
      flexDirection: 'column',
    },
    empty: {
      textTransform: 'none',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '21px',
      color: '#464546', // Gray / Gray 700
    },
    tablePagination: {
      display: 'flex',

      paddingBottom: '16px',
    },
  }),
);

type Props = {
  title?: string;
};

export default function Activity(props: Props) {
  const classes = useStyles();
  const { title = undefined } = props;
  const document = useSelector(getDocumentState);
  const bid = useSelector(getBidState);
  const docType = useSelector(getDocumentsType);
  const { key } = useParams<PublicDocumentMatchParams>();

  const [collapseOpen, setCollapseOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [notificationRecipients, setNotificationRecipients] = useState<
    SimpleUserNotificationSendingStatus[]
  >([]);

  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const fetchNotifications = async () => {
    try {
      setIsLoading(true);

      let response: { total: number; totalPages: number; results: INotification[] } | undefined;

      if (document) {
        response = !isPublicPage()
          ? await getNotificationsByDocumentId(document.id, page, pageSize)
          : await getNotificationsByKey(key, page, pageSize);
      } else if (bid && !isPublicPage()) {
        response = await getNotificationsByBidId(bid.id, page, pageSize);
      }

      if (response) {
        setTotal(response.total);
        setTotalPages(response.totalPages);
        setNotifications(response.results);
      }
      if (response && !isPublicPage()) {
        getNotificationRecipientsByIds(response.results.map((n) => n.id)).then(
          setNotificationRecipients,
        );
      }
    } catch {
      setNotifications([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [document, bid, page, pageSize]);

  return (
    <Card className={classes.root}>
      <CardContent style={{ display: 'flex' }}>
        <h2 className="h2">
          {title
            ? `${title} Activity`
            : `${document?.referenceNumber}${
                document?.revisionNumber ? `-R${document?.revisionNumber}` : ''
              }Activity`}
        </h2>
        <div style={{ display: 'inline-flex', flexGrow: 100 }} />
        <IconButton style={{ padding: 0 }}>
          {collapseOpen ? (
            <Remove htmlColor="#0947B9" onClick={() => setCollapseOpen(false)} />
          ) : (
            <Add htmlColor="#0947B9" onClick={() => setCollapseOpen(true)} />
          )}
        </IconButton>
      </CardContent>
      <Collapse in={collapseOpen}>
        <Box className={classes.box}>
          {total === 0 && !isLoading && (
            <div className={classes.empty} style={{ paddingLeft: 16 }}>
              No activity notifications for this document.
            </div>
          )}
          <List style={{ padding: '16px' }}>
            {isLoading ? (
              <CircularLoader />
            ) : (
              notifications.map((notification, index) => {
                return (
                  <div key={notification.id}>
                    <ActivityItem
                      notification={notification}
                      notificationRecipients={notificationRecipients.filter(
                        (r) => r.notificationId === notification.id,
                      )}
                    />
                    {index !== notifications.length - 1 && (
                      <Divider variant="inset" component="li" />
                    )}
                  </div>
                );
              })
            )}
          </List>
          <div style={{ flexGrow: 1 }} />
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              siblingCount={isSmall ? 0 : 3}
              shape="rounded"
              color="primary"
              page={page + 1}
              onChange={handleChangePage}
              className={classes.tablePagination}
              style={{ justifyContent: isSmall ? 'center' : 'flex-start' }}
            />
          )}
        </Box>
      </Collapse>
    </Card>
  );
}
