/* eslint-disable @typescript-eslint/no-unused-vars */
// file is huge becuz i didnt wanna cram the directory with placeholder UI but i kinda regret it now lol
// if anyone else has to work on this code, im sorry it sucks D: -trent
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import ReactJson from 'react-json-view';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {
  deleteAnnouncement,
  getAnnouncements,
  insertAnnouncement,
  updateAnnouncement,
} from '../models/api/announcements';
import {
  deleteProductPackageById,
  getProductPackages,
  insertProductPackage,
  updateProductPackage,
} from '../models/api/product-package';
import {
  AnnouncementBroadcastOption,
  AnnouncementEmailOption,
  BillingPeriod,
  DisciplineType,
  IAnnouncement,
  ICompany,
  IProductPackage,
  ISubscriber,
  IUser,
  NotificationSettingType,
} from '../api-client/autogenerated';
import { useSelector } from 'react-redux';
import { getUserState } from '../features/user/selectors';
import {
  deleteSubscriber,
  getSubscribers,
  insertSubscriber,
  updateSubscriber,
} from '../models/api/subscribers';
import { getUsers, insertProjectlessUser, patchUser } from '../models/api/users';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormControl, Typography } from '@material-ui/core';
import DayjsUtils from '@date-io/dayjs';
import dayjs, { Dayjs } from 'dayjs';
import { getCompanies, insertCompany } from '../models/api/companies';
import { useHistory } from 'react-router-dom';
import { parseDate } from '../scripts/utils';

// import { IOptionalAnnouncement } from '../api-client/autogenerated/.openapi-generator/api';

function SimpleUI() {
  // user is tricky.. its fields start as "" so you cant use defaultvlue={},
  // but it updates every keystroke if u try to use value={}
  // so we only update it when it gets a new, non empty string for id
  const user = useSelector(getUserState);
  const history = useHistory();
  const [openSimpleUI, setOpenSimpleUI] = useState(false);

  useEffect(() => {
    console.log(user);
    if (user.id && !user.isSiteAdmin) {
      history.push('/main/login');
    }
  }, [user]);

  // get all (1000) announcements
  const testGetAnnouncements = async () => {
    console.log('Making Get Announcement request.');
    console.log('Response:');

    return getAnnouncements(1000)
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // insert announcement
  // having this many states feels horrendous but daniel told me it was ok, blame daniel :D
  // non-required fields start may be undefined. havent tested yet but were hoping this means that
  // in patch requests, that field is ignored rather than force-updated if the user doesnt want
  // not sure about this naming convention LOL... just knew there would be a billion requests with similar variables
  const [openInsertAnnouncementDialogue, setOpenInsertAnnouncementDialogue] = useState(false);
  const [insertAnnouncementError, setInsertAnnouncementError] = useState(false);
  const [insertAnnouncementHelperText, setInsertAnnouncementHelperText] = useState('');

  const [
    insertAnnouncement_lastUpdatedByUserId,
    insertAnnouncement_setLastUpdatedByUserId,
  ] = useState<string>();
  const [insertAnnouncement_headline, insertAnnouncement_setHeadline] = useState<string>(
    'Headline',
  );
  const [insertAnnouncement_details, insertAnnouncement_setDetails] = useState<string>();
  const [
    insertAnnouncement_postTimestamp,
    insertAnnouncement_setPostTimestamp,
  ] = useState<Dayjs | null>(dayjs());
  const [
    insertAnnouncement_lastTimestamp,
    insertAnnouncement_setLastTimestamp,
  ] = useState<Dayjs | null>(null);
  const [
    insertAnnouncement_broadcastTo,
    insertAnnouncement_setBroadcastTo,
  ] = useState<AnnouncementBroadcastOption>(AnnouncementBroadcastOption.AllUsers);
  const [
    insertAnnouncement_emailTo,
    insertAnnouncement_setEmailTo,
  ] = useState<AnnouncementEmailOption>(AnnouncementEmailOption.AllUsers);
  const [insertAnnouncement_creatorUserId, insertAnnouncement_setCreatorUserId] = useState(
    '---', // a string to be replaced that the user probably wont type (if they do type it the following if statement will trigger again and we only want it to run once)
  );
  if (user.id !== '' && insertAnnouncement_creatorUserId === '---') {
    // magic to get user to work as intended... basically want it to be a default value
    insertAnnouncement_setCreatorUserId(user.id);
    insertAnnouncement_setLastUpdatedByUserId(user.id);
  }

  const testInsertAnnouncement = async () => {
    console.log('Making Insert Announcement request with these parameters: ');
    console.log({
      lastUpdatedByUserId: insertAnnouncement_lastUpdatedByUserId,
      headline: insertAnnouncement_headline,
      details: insertAnnouncement_details,
      postTimestamp: insertAnnouncement_postTimestamp,
      lastTimestamp: insertAnnouncement_lastTimestamp,
      broadcastTo: insertAnnouncement_broadcastTo,
      emailTo: insertAnnouncement_emailTo,
      creatorUserId: insertAnnouncement_creatorUserId,
    });
    console.log('Response:');

    return insertAnnouncement({
      lastUpdatedByUserId: insertAnnouncement_lastUpdatedByUserId,
      headline: insertAnnouncement_headline,
      details: insertAnnouncement_details,
      postTimestamp: insertAnnouncement_postTimestamp
        ? insertAnnouncement_postTimestamp.toISOString()
        : '',
      lastTimestamp: insertAnnouncement_lastTimestamp
        ? insertAnnouncement_lastTimestamp.toISOString()
        : undefined,
      broadcastTo: insertAnnouncement_broadcastTo,
      emailTo: insertAnnouncement_emailTo,
      creatorUserId: insertAnnouncement_creatorUserId,
    })
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);

        getAnnouncements(1000).then((a) => {
          setAnnouncements(a);
        });

        // reset the state. this is so wordy fuuuuuuck
        insertAnnouncement_setLastUpdatedByUserId(undefined);
        insertAnnouncement_setHeadline('Headline');
        insertAnnouncement_setDetails(undefined);
        insertAnnouncement_setPostTimestamp(dayjs());
        insertAnnouncement_setLastTimestamp(null);
        insertAnnouncement_setBroadcastTo(AnnouncementBroadcastOption.AllUsers);
        insertAnnouncement_setEmailTo(AnnouncementEmailOption.AllUsers);
        insertAnnouncement_setCreatorUserId(user.id);
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // edit announcement
  // how come there's no 'lastupdatedbyuserid on this endpoint?'
  const [openEditAnnouncementByIdDialogue, setOpenEditAnnouncementByIdDialogue] = useState(false);
  const [editAnnouncementError, setEditAnnouncementError] = useState(false);
  const [editAnnouncementHelperText, setEditAnnouncementHelperText] = useState('');

  const [editAnnouncement_id, editAnnouncement_setId] = useState<string>(
    '00000000-0000-0000-0000-000000000000',
  );
  const [editAnnouncement_headline, editAnnouncement_setHeadline] = useState<string>();
  const [editAnnouncement_details, editAnnouncement_setDetails] = useState<string>();
  const [
    editAnnouncement_postTimestamp,
    editAnnouncement_setPostTimestamp,
  ] = useState<Dayjs | null>(null); // giving this a default value since format is not clear
  const [
    editAnnouncement_lastTimestamp,
    editAnnouncement_setLastTimestamp,
  ] = useState<Dayjs | null>(dayjs());
  const [
    editAnnouncement_broadcastTo,
    editAnnouncement_setBroadcastTo,
  ] = useState<AnnouncementBroadcastOption>();
  const [
    editAnnouncement_emailTo,
    editAnnouncement_setEmailTo,
  ] = useState<AnnouncementEmailOption>();
  // const [editAnnouncement_creatorUserId, editAnnouncement_setCreatorUserId] = useState<
  //   string | undefined
  // >(undefined);

  const [forceRefresh, setForceRefresh] = useState(false);

  const testEditAnnouncementById = async () => {
    console.log('Making Edit Announcement Request with these parameters: ');
    console.log({
      id: editAnnouncement_id,
      headline: editAnnouncement_headline,
      details: editAnnouncement_details,
      postTimestamp: editAnnouncement_postTimestamp,
      lastTimestamp: editAnnouncement_lastTimestamp,
      broadcastTo: editAnnouncement_broadcastTo,
      emailTo: editAnnouncement_emailTo,
      // creatorUserId: editAnnouncement_creatorUserId,
    });
    console.log('Response:');
    updateAnnouncement(editAnnouncement_id, {
      headline: editAnnouncement_headline,
      details: editAnnouncement_details,
      postTimestamp: editAnnouncement_postTimestamp?.toISOString() || undefined,
      lastTimestamp: editAnnouncement_lastTimestamp?.toISOString() || undefined,
      broadcastTo: editAnnouncement_broadcastTo,
      emailTo: editAnnouncement_emailTo,
      // creatorUserId: editAnnouncement_creatorUserId,
    })
      .then((result) => {
        console.log(result);
        setConsoleOutput(result);
        setForceRefresh(!forceRefresh);

        editAnnouncement_setId('00000000-0000-0000-0000-000000000000');
        editAnnouncement_setHeadline(undefined);
        editAnnouncement_setDetails(undefined);
        editAnnouncement_setPostTimestamp(null);
        editAnnouncement_setLastTimestamp(dayjs());
        editAnnouncement_setBroadcastTo(undefined);
        editAnnouncement_setEmailTo(undefined);
        // editAnnouncement_setCreatorUserId(undefined);
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // delete announcement
  const [openDeleteAnnouncementByIdDialogue, setOpenDeleteAnnouncementByIdDialogue] = useState(
    false,
  );
  const [deleteAnnouncementError, setDeleteAnnouncementError] = useState(false);
  const [deleteAnnouncementHelperText, setDeleteAnnouncementHelperText] = useState('');

  const [deleteAnnouncement_id, setDeleteAnnouncement_id] = useState<string>(
    '00000000-0000-0000-0000-000000000000',
  );

  const testDeleteAnnouncementById = async () => {
    console.log('Making Delete Announcement Request with these parameters: ');
    console.log({ id: deleteAnnouncement_id });
    console.log('Response:');

    deleteAnnouncement(deleteAnnouncement_id)
      .then((result) => {
        console.log(result);
        setConsoleOutput(result);
        setForceRefresh(!forceRefresh);

        setDeleteAnnouncement_id('00000000-0000-0000-0000-000000000000');
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // get all product packages
  const testGetProductPackages = async () => {
    console.log('Making Get Product Packages Announcement request.');
    console.log('Response:');

    return getProductPackages(1000)
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // insert product package
  const [openInsertProductPackageDialogue, setOpenInsertProductPackageDialogue] = useState(false);
  const [insertProductPackagePackageError, setInsertProductPackageError] = useState(false);
  const [insertProductPackageHelperText, setInsertProductPackageHelperText] = useState('');

  // const [
  //   insertProductPackage_lastUpdatedByUserId,
  //   insertProductPackage_setLastUpdatedByUserId,
  // ] = useState<string>();
  const [insertProductPackage_name, insertProductPackage_setName] = useState<string>('Name');
  const [
    insertProductPackage_description,
    insertProductPackage_setDescription,
  ] = useState<string>();
  const [
    insertProductPackage_numberOfProjects,
    insertProductPackage_setNumberOfProjects,
  ] = useState<number>();
  // const [insertProductPackage_numberOfUsers, insertProductPackage_setNumberOfUsers] = useState<
  //   number
  // >();
  const [
    insertProductPackage_costPerBillingPeriod,
    insertProductPackage_setCostPerBillingPeriod,
  ] = useState<number>(0);
  const [
    insertProductPackage_billingPeriod,
    insertProductPackage_setBillingPeriod,
  ] = useState<BillingPeriod>(BillingPeriod.Yearly);
  // const [insertProductPackage_expires, insertProductPackage_setExpires] = useState<
  //   string
  // >();
  const [insertProductPackage_published, insertProductPackage_setPublished] = useState<boolean>(
    false,
  );

  const testInsertProductPackage = async () => {
    console.log('Making Insert Product Package request with parameters:');
    console.log({
      // lastUpdatedByUserId: insertProductPackage_lastUpdatedByUserId,
      name: insertProductPackage_name,
      description: insertProductPackage_description,
      numberOfProjects: insertProductPackage_numberOfProjects,
      // numberOfUsers: insertProductPackage_numberOfUsers,
      costPerBillingPeriod: insertProductPackage_costPerBillingPeriod,
      billingPeriod: insertProductPackage_billingPeriod,
      // expires: insertProductPackage_expires,
      published: insertProductPackage_published,
    });
    console.log('Response:');

    return insertProductPackage({
      // lastUpdatedByUserId: insertProductPackage_lastUpdatedByUserId,
      name: insertProductPackage_name,
      description: insertProductPackage_description,
      numberOfProjects: insertProductPackage_numberOfProjects,
      // numberOfUsers: insertProductPackage_numberOfUsers,
      costPerBillingPeriod: insertProductPackage_costPerBillingPeriod,
      billingPeriod: insertProductPackage_billingPeriod,
      // expires: insertProductPackage_expires,
      published: insertProductPackage_published,
    })
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);

        getProductPackages(1000).then((a) => {
          setProductPackages(a);
        });

        // insertProductPackage_setLastUpdatedByUserId(undefined);
        insertProductPackage_setName('Name');
        insertProductPackage_setDescription(undefined);
        insertProductPackage_setNumberOfProjects(undefined);
        // insertProductPackage_setNumberOfUsers(undefined);
        insertProductPackage_setCostPerBillingPeriod(0);
        insertProductPackage_setBillingPeriod(BillingPeriod.Yearly);
        // insertProductPackage_setExpires(undefined);
        insertProductPackage_setPublished(false);
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // edit product package by id
  const [openEditProductPackageByIdDialogue, setOpenEditProductPackageByIdDialogue] = useState(
    false,
  );
  const [editProductPackageError, setEditProductPackageError] = useState(false);
  const [editProductPackageHelperText, setEditProductPackageHelperText] = useState('');

  const [editProductPackage_id, editProductPackage_setId] = useState<string>(
    '00000000-0000-0000-0000-000000000000',
  );
  const [editProductPackage_name, editProductPackage_setName] = useState<string>();
  const [editProductPackage_description, editProductPackage_setDescription] = useState<string>();
  const [
    editProductPackage_numberOfProjects,
    editProductPackage_setNumberOfProjects,
  ] = useState<number>();
  // const [editProductPackage_numberOfUsers, editProductPackage_setNumberOfUsers] = useState<
  //   number | undefined
  // >(undefined);
  const [
    editProductPackage_costPerBillingPeriod,
    editProductPackage_setCostPerBillingPeriod,
  ] = useState<string>();
  const [
    editProductPackage_billingPeriod,
    editProductPackage_setBillingPeriod,
  ] = useState<BillingPeriod>();
  // const [editProductPackage_expires, editProductPackage_setExpires] = useState<Dayjs | null>(null);
  // const [editProductPackage_published, editProductPackage_setPublished] = useState<
  //   boolean | undefined
  // >(undefined);

  const testEditProductPackage = async () => {
    console.log('Making Edit Product Package request with these parameters:');
    console.log({
      id: editProductPackage_id,
      name: editProductPackage_name,
      description: editProductPackage_description,
      numberOfProjects: editProductPackage_numberOfProjects,
      // numberOfUsers: editProductPackage_numberOfUsers,
      costPerBillingPeriod: parseInt(editProductPackage_costPerBillingPeriod!),
      billingPeriod: editProductPackage_billingPeriod,
      // published: editProductPackage_published,
    });
    console.log('Response:');

    return updateProductPackage(editProductPackage_id, {
      name: editProductPackage_name,
      description: editProductPackage_description,
      numberOfProjects: editProductPackage_numberOfProjects,
      // numberOfUsers: editProductPackage_numberOfUsers,
      costPerBillingPeriod: parseInt(editProductPackage_costPerBillingPeriod!),
      billingPeriod: editProductPackage_billingPeriod,
      // expires: editProductPackage_expires ? editProductPackage_expires.toISOString() : undefined,
      // published: editProductPackage_published,
    })
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);

        editProductPackage_setId('00000000-0000-0000-0000-000000000000');
        editProductPackage_setName(undefined);
        editProductPackage_setDescription(undefined);
        editProductPackage_setNumberOfProjects(undefined);
        // editProductPackage_setNumberOfUsers(undefined);
        editProductPackage_setCostPerBillingPeriod(undefined);
        // editProductPackage_setExpires(null);
        // editProductPackage_setPublished(undefined);
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // delete product package by id
  const [openDeleteProductPacakgeByIdDialogue, setOpenDeleteProductPackageByIdDialogue] = useState(
    false,
  );
  const [deleteProductPackageError, setDeleteProductPackageError] = useState(false);
  const [deleteProductPackageHelperText, setDeleteProductPackageHelperText] = useState('');

  const [deleteProductPackage_id, deleteProductPackage_setId] = useState<string>(
    '00000000-0000-0000-0000-000000000000',
  );

  const testDeleteProductPackage = async () => {
    console.log('Making Edit Product Package request with these parameters:');
    console.log({ id: deleteProductPackage_id });
    console.log('Response:');

    return deleteProductPackageById(deleteProductPackage_id)
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);
        setForceRefresh(!forceRefresh);

        deleteProductPackage_setId('00000000-0000-0000-0000-000000000000');
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // get all subscribers
  const testGetSubscribers = async () => {
    console.log('Making Get Subscribers request.');
    console.log('Response:');
    return getSubscribers(1000)
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // insert subscriber
  const [openInsertSubscriberDialogue, setOpenInsertSubscriberDialogue] = useState(false);
  const [insertSubscriberError, setInsertSubscriberError] = useState(false);
  const [insertSubscriberHelperText, setInsertSubscriberHelperText] = useState('');

  const [
    insertSubscriber_lastUpdatedByUserId,
    insertSubscriber_setLastUpdatedByUserId,
  ] = useState<string>();
  const [insertSubscriber_name, insertSubscriber_setName] = useState<string>('Name');
  const [insertSubscriber_description, insertSubscriber_setDescription] = useState<string>();
  const [insertSubscriber_coreClient, insertSubscriber_setCoreClient] = useState<boolean>(false);
  const [insertSubscriber_logoFileId, insertSubscriber_setLogoFileId] = useState<string>();
  const [
    insertSubscriber_productPackageId,
    insertSubscriber_setProductPackageId,
  ] = useState<string>('00000000-0000-0000-0000-000000000000');

  const testInsertSubscriber = async () => {
    console.log('Making Insert Subscriber request with parameters:');
    console.log({
      lastUpdatedByUserId: insertSubscriber_lastUpdatedByUserId,
      name: insertSubscriber_name,
      description: insertSubscriber_description,
      // coreClient: insertSubscriber_coreClient,
      logoFileId: insertSubscriber_logoFileId,
      productPackageId: insertSubscriber_productPackageId,
    });
    console.log('Response:');

    return insertSubscriber({
      lastUpdatedByUserId: insertSubscriber_lastUpdatedByUserId,
      name: insertSubscriber_name,
      description: insertSubscriber_description,
      coreClient: insertSubscriber_coreClient,
      logoFileId: insertSubscriber_logoFileId,
      productPackageId: insertSubscriber_productPackageId,
    })
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);

        getSubscribers(1000).then((a) => {
          setSubscribers(a);
        });

        insertSubscriber_setLastUpdatedByUserId(undefined);
        insertSubscriber_setName('Name');
        insertSubscriber_setDescription(undefined);
        insertSubscriber_setCoreClient(false);
        insertSubscriber_setLogoFileId(undefined);
        insertSubscriber_setProductPackageId('00000000-0000-0000-0000-000000000000');
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // edit subscriber by id
  const [openEditSubscriberByIdDialogue, setOpenEditSubscriberByIdDialogue] = useState(false);
  const [editSubscriberByIdError, setEditSubscriberByIdError] = useState(false);
  const [editSubscriberByIdHelperText, setEditSubscriberByIdHelperText] = useState('');

  const [editSubscriber_id, editSubscriber_setId] = useState<string>(
    '00000000-0000-0000-0000-000000000000',
  );
  const [editSubscriber_name, editSubscriber_setName] = useState<string>();
  const [editSubscriber_description, editSubscriber_setDescription] = useState<string>();
  // const [editSubscriber_coreClient, editSubscriber_setCoreClient] = useState<boolean>();
  const [editSubscriber_logoFileId, editSubscriber_setLogoFileId] = useState<string>();
  const [editSubscriber_productPackageId, editSubscriber_setProductPackageId] = useState<string>();

  const testUpdateSubscriber = async () => {
    console.log('Making update Subscriber request with parameters:');
    console.log({
      id: editSubscriber_id,
      name: editSubscriber_name,
      description: editSubscriber_description,
      // coreClient: editSubscriber_coreClient,
      logoFileId: editSubscriber_logoFileId,
      productpackageId: editSubscriber_productPackageId,
    });
    console.log('Response:');

    return updateSubscriber(editSubscriber_id, {
      name: editSubscriber_name,
      description: editSubscriber_description,
      // coreClient: editSubscriber_coreClient,
      logoFileId: editSubscriber_logoFileId,
      productPackageId: editSubscriber_productPackageId,
    })
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);

        editSubscriber_setId('00000000-0000-0000-0000-000000000000');
        editSubscriber_setName(undefined);
        editSubscriber_setDescription(undefined);
        // editSubscriber_setCoreClient(undefined);
        editSubscriber_setLogoFileId(undefined);
        editSubscriber_setProductPackageId(undefined);
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // delete subscriber by id
  const [openDeleteSubscriberByIdDialogue, setOpenDeleteSubscriberByIdDialogue] = useState(false);
  const [deleteSubscriberByIdError, setDeleteSubscriberByIdError] = useState(false);
  const [deleteSubscriberByIdHelperText, setDeleteSubscriberByIdHelperText] = useState('');

  const [deleteSubscriber_id, deleteSubscriber_setId] = useState<string>(
    '00000000-0000-0000-0000-000000000000',
  );

  const testDeleteSubscriber = async () => {
    console.log('Making Delete Subscriber request with parameters:');
    console.log({ id: deleteSubscriber_id });
    console.log('Response:');

    return deleteSubscriber(deleteSubscriber_id)
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);

        deleteSubscriber_setId('00000000-0000-0000-0000-000000000000');
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // get all companies
  const testGetCompanies = async () => {
    console.log('Making Get Companies request.');
    console.log('Response:');
    return getCompanies(1000)
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);
      })

      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // insert company
  const testInsertCompany = () => {
    console.log('Making Insert Company request with parameters:');
    console.log({
      name: insertCompany_name,
      // description: insertCompany_description,
      // email: insertCompany_email,
    });
    return insertCompany({
      name: insertCompany_name!,
      // description: insertCompany_description,
      // email: insertCompany_email,
    })
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);

        getCompanies(1000).then((a) => {
          setCompanies(a);
        });
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  const [insertCompanyDialogOpen, setInsertCompanyDialogOpen] = useState(false);
  const [insertCompany_name, insertCompany_setName] = useState<string>();
  // const [insertCompany_description, insertCompany_setDescription] = useState<string>();
  // const [insertCompany_email, insertCompany_setEmail] = useState<string>();

  // get all users
  const testGetUsers = async () => {
    console.log('Making Get Users request.');
    console.log('Response:');
    return getUsers(1000, 'createdOn')
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // edit user by id
  const [openEditUserByIdDialogue, setOpenEditUserByIdDialogue] = useState(false);
  const [editUserByIdError, setEditUserByIdError] = useState(false);
  const [editUserByIdHelperText, setEditUserByIdHelperText] = useState('');

  const [editUser_id, editUser_setId] = useState<string>('00000000-0000-0000-0000-000000000000');
  const [editUser_name, editUser_setName] = useState<string>();
  const [editUser_description, editUser_setDescription] = useState<string>();
  const [editUser_companyId, editUser_setCompanyId] = useState<string>();
  const [
    editUser_contractorLicenseNumber,
    editUser_setContractorLicenseNumber,
  ] = useState<string>();
  const [editUser_isRegistered, editUser_setIsRegistered] = useState<boolean>();
  const [editUser_isVerified, editUser_setIsVerified] = useState<boolean>();
  const [editUser_isSiteAdmin, editUser_setIsSiteAdmin] = useState<boolean>();
  const [editUser_email, editUser_setEmail] = useState<string>();
  const [editUser_website, editUser_setWebsite] = useState<string>();
  const [
    editUser_notificationSetting,
    editUser_setNotificationSetting,
  ] = useState<NotificationSettingType>();
  const [editUser_discipline, editUser_setDiscipline] = useState<DisciplineType[]>([]);
  const [editUser_adminOfSubscriberId, editUser_setAdminOfSubscriberId] = useState<string>();

  const testEditUserById = async () => {
    console.log('Making Patch User request with parameters:');
    console.log({
      id: editUser_id,
      name: editUser_name,
      description: editUser_description,
      companyId: editUser_companyId,
      contractorLicenseNumber: editUser_contractorLicenseNumber,
      isRegistered: editUser_isRegistered,
      isVerified: editUser_isVerified,
      isSiteAdmin: editUser_isSiteAdmin,
      email: editUser_email,
      website: editUser_website,
      notificationSetting: editUser_notificationSetting,
      disciplines: editUser_discipline,
      adminOfSubscriberId: editUser_adminOfSubscriberId,
    });
    console.log('Response:');

    return patchUser(editUser_id, {
      name: editUser_name,
      description: editUser_description,
      companyId: editUser_companyId,
      contractorLicenseNumber: editUser_contractorLicenseNumber,
      isRegistered: editUser_isRegistered,
      isVerified: editUser_isVerified,
      isSiteAdmin: editUser_isSiteAdmin,
      email: editUser_email,
      website: editUser_website,
      notificationSetting: editUser_notificationSetting,
      userDisciplineList: editUser_discipline.map((d) => {
        return {
          discipline: d,
        };
      }),
      adminOfSubscriberId: editUser_adminOfSubscriberId,
    })
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);

        editUser_setId('00000000-0000-0000-0000-000000000000');
        editUser_setName(undefined);
        editUser_setDescription(undefined);
        editUser_setCompanyId(undefined);
        editUser_setContractorLicenseNumber(undefined);
        editUser_setIsRegistered(undefined);
        editUser_setIsVerified(undefined);
        editUser_setIsSiteAdmin(undefined);
        editUser_setEmail(undefined);
        editUser_setWebsite(undefined);
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  // insert user
  const testInsertUser = () => {
    console.log('Making Insert User Request with Parameters:');
    console.log({
      email: insertUser_email,
      name: insertUser_name,
      companyId: insertUser_companyId,
    });
    insertProjectlessUser({
      email: insertUser_email!,
      name: insertUser_name!,
      companyId: insertUser_companyId,
    })
      .then((response) => {
        console.log(response);
        setConsoleOutput(response);

        getUsers(1000).then((a) => {
          setUsers(a);
        });

        insertUser_setEmail(undefined);
        insertUser_setName(undefined);
        insertUser_setCompanyId(undefined);
      })
      .catch((error) => {
        console.log(error);
        setConsoleOutput(error);
      });
  };

  const [insertUserDialogOpen, setInsertUserDialogOpen] = useState(false);
  const [insertUser_name, insertUser_setName] = useState<string>();
  const [insertUser_email, insertUser_setEmail] = useState<string>();
  const [insertUser_companyId, insertUser_setCompanyId] = useState<string>();

  const [announcements, setAnnouncements] = useState<IAnnouncement[]>([]);
  const [productPackages, setProductPackages] = useState<IProductPackage[]>([]);
  const [subscribers, setSubscribers] = useState<ISubscriber[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  useEffect(() => {
    getAnnouncements(1000).then((a) => {
      setAnnouncements(a);
    });
    getProductPackages(1000).then((a) => {
      setProductPackages(a);
    });
    getSubscribers(1000).then((a) => {
      setSubscribers(a);
    });
    getUsers(1000).then((a) => {
      setUsers(a);
    });
    getCompanies(1000).then((a) => {
      setCompanies(a);
    });
  }, [forceRefresh]);

  useEffect(() => {
    if (editAnnouncement_id) {
      const announcement = announcements.find((x) => x.id === editAnnouncement_id);
      if (announcement) {
        editAnnouncement_setHeadline(announcement!.headline);
        editAnnouncement_setDetails(announcement!.details || undefined);
        editAnnouncement_setPostTimestamp(
          announcement!.postTimestamp ? parseDate(announcement!.postTimestamp) : null,
        );
        editAnnouncement_setLastTimestamp(
          announcement!.lastTimestamp ? parseDate(announcement!.lastTimestamp) : null,
        );
      }
    }
  }, [editAnnouncement_id]);

  useEffect(() => {
    if (editProductPackage_id) {
      const announcement = productPackages.find((x) => x.id === editProductPackage_id);
      if (announcement) {
        editProductPackage_setName(announcement!.name);
        editProductPackage_setDescription(announcement!.description || undefined);
        editProductPackage_setCostPerBillingPeriod(announcement!.costPerBillingPeriod.toString());
        editProductPackage_setBillingPeriod(announcement!.billingPeriod);
        // editProductPackage_setExpires(announcement!.expires ? dayjs(announcement!.expires) : null);
        // editProductPackage_setPublished(announcement.published);
      }
    }
  }, [editProductPackage_id]);

  // visible console
  const [consoleOutput, setConsoleOutput] = useState({});

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, 0) scale(2.0)',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenSimpleUI(true);
          }}
        >
          SIMPLE UI
        </Button>
        <Dialog
          onClose={() => setOpenSimpleUI(false)}
          aria-labelledby="simple-dialog-title"
          open={openSimpleUI}
        >
          <DialogTitle>{'Simple UI'}</DialogTitle>
          <DialogContent>
            {/* announcements */}
            <DialogContentText>Announcements</DialogContentText>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justify="center"
              style={{ marginBottom: '16px' }}
            >
              <Grid item>
                <Button variant="contained" onClick={testGetAnnouncements}>
                  get All Announcements
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenInsertAnnouncementDialogue(true);
                  }}
                >
                  insert Announcement
                </Button>

                {/* insert announcement dialogue */}
                <Dialog fullWidth maxWidth="md" open={openInsertAnnouncementDialogue}>
                  <DialogTitle>{'Insert Announcement'}</DialogTitle>
                  <DialogContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justify="center"
                      direction="row"
                    >
                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          style={{ width: '100%' }}
                          label="Headline*"
                          variant="outlined"
                          error={insertAnnouncementError && insertAnnouncement_headline === ''}
                          helperText={insertAnnouncementHelperText}
                          defaultValue={insertAnnouncement_headline}
                          onBlur={(event) => {
                            insertAnnouncement_setHeadline(event.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          multiline
                          size="small"
                          variant="outlined"
                          rows={4}
                          style={{ width: '100%' }}
                          label="Details"
                          defaultValue={insertAnnouncement_details}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              insertAnnouncement_setDetails(undefined);
                            } else {
                              insertAnnouncement_setDetails(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl>
                          <Typography variant="body1">Post Timestamp</Typography>
                          <KeyboardDateTimePicker
                            required
                            disablePast
                            inputVariant="outlined"
                            InputProps={{ style: { padding: 0 } }}
                            format="MM/DD/YYYY hh:mm"
                            fullWidth
                            error={insertAnnouncementError && !insertAnnouncement_postTimestamp}
                            helperText={insertAnnouncementHelperText}
                            value={insertAnnouncement_postTimestamp}
                            onChange={(event) => {
                              insertAnnouncement_setPostTimestamp(event);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl>
                          <Typography variant="body1">Last Timestamp</Typography>
                          <KeyboardDateTimePicker
                            required
                            disablePast
                            inputVariant="outlined"
                            InputProps={{ style: { padding: 0 } }}
                            format="MM/DD/YYYY hh:mm"
                            fullWidth
                            value={insertAnnouncement_lastTimestamp}
                            onChange={(date) => {
                              insertAnnouncement_setLastTimestamp(date);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel shrink>Broadcast to*</InputLabel>
                        <Select
                          value={insertAnnouncement_broadcastTo}
                          style={{ width: '100%' }}
                          variant="outlined"
                          onChange={(event) => {
                            if (event.target.value === 'all_users') {
                              insertAnnouncement_setBroadcastTo(
                                AnnouncementBroadcastOption.AllUsers,
                              );
                            } else if (event.target.value === 'administration_only') {
                              insertAnnouncement_setBroadcastTo(
                                AnnouncementBroadcastOption.AdministrationOnly,
                              );
                            }
                          }}
                        >
                          <MenuItem value={AnnouncementBroadcastOption.AllUsers}>
                            All Users
                          </MenuItem>
                          <MenuItem value={AnnouncementBroadcastOption.AdministrationOnly}>
                            Administration Only
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel shrink>Email to*</InputLabel>
                        <Select
                          value={insertAnnouncement_emailTo}
                          style={{ width: '100%' }}
                          variant="outlined"
                          onChange={(event) => {
                            if (event.target.value === 'all_users') {
                              insertAnnouncement_setEmailTo(AnnouncementEmailOption.AllUsers);
                            } else if (event.target.value === 'administration_only') {
                              insertAnnouncement_setEmailTo(
                                AnnouncementEmailOption.AdministrationOnly,
                              );
                            } else if (event.target.value === 'no_email') {
                              insertAnnouncement_setEmailTo(AnnouncementEmailOption.NoEmail);
                            }
                          }}
                        >
                          <MenuItem value={AnnouncementEmailOption.AllUsers}>All Users</MenuItem>
                          <MenuItem value={AnnouncementEmailOption.AdministrationOnly}>
                            Administration Only
                          </MenuItem>
                          <MenuItem value={AnnouncementEmailOption.NoEmail}>No Email</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          variant="outlined"
                          disabled
                          label="User Id to last update"
                          defaultValue={insertAnnouncement_lastUpdatedByUserId}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              insertAnnouncement_setLastUpdatedByUserId(undefined);
                            } else {
                              insertAnnouncement_setLastUpdatedByUserId(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          label="Creator User Id*"
                          disabled
                          variant="outlined"
                          error={insertAnnouncementError && insertAnnouncement_creatorUserId === ''}
                          helperText={insertAnnouncementHelperText}
                          value={insertAnnouncement_creatorUserId}
                          onChange={(event) => {
                            // this one has to be onChange... more magic to get user to work
                            insertAnnouncement_setCreatorUserId(event.target.value);
                          }}
                        />
                      </Grid> */}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenInsertAnnouncementDialogue(false);
                      }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (
                          insertAnnouncement_headline === '' ||
                          !insertAnnouncement_postTimestamp ||
                          insertAnnouncement_creatorUserId === ''
                        ) {
                          setInsertAnnouncementHelperText('Required field');
                          setInsertAnnouncementError(true);
                        } else {
                          testInsertAnnouncement();
                          setInsertAnnouncementHelperText('');
                          setInsertAnnouncementError(false);
                          setOpenInsertAnnouncementDialogue(false);
                        }
                      }}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenEditAnnouncementByIdDialogue(true);
                  }}
                >
                  edit Announcement By Id
                </Button>

                {/* edit announcement by id dialogue */}
                <Dialog fullWidth maxWidth="md" open={openEditAnnouncementByIdDialogue}>
                  <DialogTitle>{'Edit Announcement By ID'}</DialogTitle>
                  <DialogContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justify="center"
                      direction="row"
                    >
                      <Grid item xs={6}>
                        <InputLabel shrink>Announcement to edit</InputLabel>
                        <Select
                          value={editAnnouncement_id}
                          style={{ width: '100%' }}
                          variant="outlined"
                          onChange={(event: any) => {
                            editAnnouncement_setId(event.target.value);
                          }}
                        >
                          {announcements.map((a) => {
                            return (
                              <MenuItem key={a.id} value={a.id} style={{ maxWidth: 400 }}>
                                {a.headline}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          variant="outlined"
                          disabled
                          label="Creator User Id"
                          defaultValue={editAnnouncement_creatorUserId}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              editAnnouncement_setCreatorUserId(undefined);
                            } else {
                              editAnnouncement_setCreatorUserId(event.target.value);
                            }
                          }}
                        /> */}
                      {/* </Grid> */}
                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          style={{ width: '100%' }}
                          label="Headline"
                          variant="outlined"
                          value={editAnnouncement_headline}
                          onChange={(event) => {
                            if (event.target.value === '') {
                              editAnnouncement_setHeadline(undefined);
                            } else {
                              editAnnouncement_setHeadline(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          style={{ width: '100%' }}
                          label="Details"
                          variant="outlined"
                          rows={4}
                          value={editAnnouncement_details}
                          multiline
                          onChange={(event) => {
                            if (event.target.value === '') {
                              editAnnouncement_setDetails(undefined);
                            } else {
                              editAnnouncement_setDetails(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl>
                          <Typography variant="body1">Post Timestamp</Typography>
                          <KeyboardDateTimePicker
                            required
                            disablePast
                            inputVariant="outlined"
                            InputProps={{ style: { padding: 0 } }}
                            format="MM/DD/YYYY hh:mm"
                            fullWidth
                            error={editAnnouncementError && !editAnnouncement_postTimestamp}
                            helperText={editAnnouncementHelperText}
                            value={editAnnouncement_postTimestamp}
                            onChange={(event) => {
                              editAnnouncement_setPostTimestamp(event);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl>
                          <Typography variant="body1">Last Timestamp</Typography>
                          <KeyboardDateTimePicker
                            required
                            disablePast
                            inputVariant="outlined"
                            InputProps={{ style: { padding: 0 } }}
                            format="MM/DD/YYYY hh:mm"
                            fullWidth
                            error={editAnnouncementError && !editAnnouncement_lastTimestamp}
                            helperText={editAnnouncementHelperText}
                            value={editAnnouncement_lastTimestamp}
                            onChange={(event) => {
                              editAnnouncement_setLastTimestamp(event);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel shrink>Broadcast to</InputLabel>
                        <Select
                          value={editAnnouncement_broadcastTo}
                          style={{ width: '100%' }}
                          variant="outlined"
                          onChange={(event) => {
                            if (event.target.value === 'none') {
                              editAnnouncement_setBroadcastTo(undefined);
                            } else if (event.target.value === 'all_users') {
                              editAnnouncement_setBroadcastTo(AnnouncementBroadcastOption.AllUsers);
                            } else if (event.target.value === 'administration_only') {
                              editAnnouncement_setBroadcastTo(
                                AnnouncementBroadcastOption.AdministrationOnly,
                              );
                            }
                          }}
                        >
                          <MenuItem value={undefined}>None</MenuItem>
                          <MenuItem value={AnnouncementBroadcastOption.AllUsers}>
                            All Users
                          </MenuItem>
                          <MenuItem value={AnnouncementBroadcastOption.AdministrationOnly}>
                            Administration Only
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel shrink>Email to</InputLabel>
                        <Select
                          value={editAnnouncement_emailTo}
                          style={{ width: '100%' }}
                          variant="outlined"
                          onChange={(event) => {
                            if (event.target.value === 'none') {
                              editAnnouncement_setEmailTo(undefined);
                            } else if (event.target.value === 'all_users') {
                              editAnnouncement_setEmailTo(AnnouncementEmailOption.AllUsers);
                            } else if (event.target.value === 'administration_only') {
                              editAnnouncement_setEmailTo(
                                AnnouncementEmailOption.AdministrationOnly,
                              );
                            } else if (event.target.value === 'no_email') {
                              editAnnouncement_setEmailTo(AnnouncementEmailOption.NoEmail);
                            }
                          }}
                        >
                          <MenuItem value={undefined}>None</MenuItem>
                          <MenuItem value={AnnouncementEmailOption.AllUsers}>All Users</MenuItem>
                          <MenuItem value={AnnouncementEmailOption.AdministrationOnly}>
                            Administration Only
                          </MenuItem>
                          <MenuItem value={AnnouncementEmailOption.NoEmail}>No Email</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenEditAnnouncementByIdDialogue(false);
                      }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (editAnnouncement_id === '') {
                          setEditAnnouncementHelperText('Required field');
                          setEditAnnouncementError(true);
                        } else {
                          testEditAnnouncementById();
                          setEditAnnouncementHelperText('');
                          setEditAnnouncementError(false);
                          setOpenEditAnnouncementByIdDialogue(false);
                        }
                      }}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenDeleteAnnouncementByIdDialogue(true);
                  }}
                >
                  delete Announcement By Id
                </Button>

                {/* delete announcement  */}
                <Dialog fullWidth maxWidth="xs" open={openDeleteAnnouncementByIdDialogue}>
                  <DialogTitle>{'Delete Announcement By ID'}</DialogTitle>
                  <DialogContent>
                    <Grid item xs={12}>
                      <InputLabel shrink>Announcement to delete</InputLabel>
                      <Select
                        value={deleteAnnouncement_id}
                        style={{ width: '100%' }}
                        variant="outlined"
                        onChange={(event: any) => {
                          setDeleteAnnouncement_id(event.target.value);
                        }}
                      >
                        {announcements.map((a) => {
                          return (
                            <MenuItem key={a.id} value={a.id} style={{ maxWidth: 400 }}>
                              {a.headline}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenDeleteAnnouncementByIdDialogue(false);
                      }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (deleteAnnouncement_id === '') {
                          setDeleteAnnouncementHelperText('Required field');
                          setDeleteAnnouncementError(true);
                        } else {
                          testDeleteAnnouncementById();
                          setDeleteAnnouncementHelperText('');
                          setDeleteAnnouncementError(false);
                          setOpenDeleteAnnouncementByIdDialogue(false);
                        }
                      }}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>

            {/* product packages */}
            <DialogContentText>Product Packages</DialogContentText>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justify="center"
              style={{ marginBottom: '16px' }}
            >
              <Grid item>
                <Button variant="contained" onClick={testGetProductPackages}>
                  get All Product Packages
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenInsertProductPackageDialogue(true);
                  }}
                >
                  insert Product Package
                </Button>

                {/* insert product packages dialogue */}
                <Dialog fullWidth maxWidth="xs" open={openInsertProductPackageDialogue}>
                  <DialogTitle>{'Insert Product Package'}</DialogTitle>
                  <DialogContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justify="center"
                      direction="row"
                    >
                      {/* <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          label="User Id to last update"
                          defaultValue={insertProductPackage_lastUpdatedByUserId}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              insertProductPackage_setLastUpdatedByUserId(undefined);
                            } else {
                              insertProductPackage_setLastUpdatedByUserId(event.target.value);
                            }
                          }}
                        />
                      </Grid> */}
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          label="Name*"
                          defaultValue={insertProductPackage_name}
                          error={
                            insertProductPackagePackageError && insertProductPackage_name === ''
                          }
                          helperText={insertProductPackageHelperText}
                          onBlur={(event) => {
                            insertProductPackage_setName(event.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          style={{ width: '100%' }}
                          label="Description"
                          defaultValue={insertProductPackage_description}
                          multiline
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              insertProductPackage_setDescription(undefined);
                            } else {
                              insertProductPackage_setDescription(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          label="Number of projects"
                          defaultValue={insertProductPackage_numberOfProjects}
                          type="number"
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              insertProductPackage_setNumberOfProjects(undefined);
                            } else {
                              insertProductPackage_setNumberOfProjects(
                                parseInt(event.target.value),
                              );
                            }
                          }}
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          label="Number of users"
                          defaultValue={insertProductPackage_numberOfUsers}
                          type="number"
                          onBlur={(event) => {
                            if (isNaN(parseInt(event.target.value))) {
                              insertProductPackage_setNumberOfUsers(undefined);
                            } else {
                              insertProductPackage_setNumberOfUsers(parseInt(event.target.value));
                            }
                          }}
                        />
                      </Grid> */}
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          label="¢ per Billing Period*"
                          type="number"
                          defaultValue={insertProductPackage_costPerBillingPeriod}
                          error={
                            insertProductPackagePackageError &&
                            isNaN(insertProductPackage_costPerBillingPeriod)
                          }
                          helperText={insertProductPackageHelperText}
                          onBlur={(event) => {
                            insertProductPackage_setCostPerBillingPeriod(
                              parseInt(event.target.value),
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel shrink>Billing Period*</InputLabel>

                        <Select
                          style={{ width: '100%' }}
                          value={insertProductPackage_billingPeriod}
                          onChange={(event) => {
                            if (event.target.value === 'monthly') {
                              insertProductPackage_setBillingPeriod(BillingPeriod.Monthly);
                            } else if (event.target.value === 'yearly') {
                              insertProductPackage_setBillingPeriod(BillingPeriod.Yearly);
                            }
                          }}
                        >
                          <MenuItem value={BillingPeriod.Monthly}>Monthly</MenuItem>
                          <MenuItem value={BillingPeriod.Yearly}>Yearly</MenuItem>
                        </Select>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          label="Expires"
                          defaultValue={insertProductPackage_expires}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              insertProductPackage_setExpires(undefined);
                            } else {
                              insertProductPackage_setExpires(event.target.value);
                            }
                          }}
                        />
                      </Grid> */}
                      {/* <Grid item xs={6}>
                        <InputLabel shrink>Published*</InputLabel>

                        <Select
                          style={{ width: '100%' }}
                          value={insertProductPackage_published}
                          onChange={(event) => {
                            if (event.target.value === 'true') {
                              insertProductPackage_setPublished(true);
                            } else if (event.target.value === 'false') {
                              insertProductPackage_setPublished(false);
                            }
                          }}
                        >
                          <MenuItem value={'true'}>Published</MenuItem>
                          <MenuItem value={'false'}>Not Published</MenuItem>
                        </Select>
                      </Grid> */}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenInsertProductPackageDialogue(false);
                      }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (
                          insertProductPackage_name === '' ||
                          isNaN(insertProductPackage_costPerBillingPeriod)
                        ) {
                          setInsertProductPackageHelperText('Required field');
                          setInsertProductPackageError(true);
                        } else {
                          testInsertProductPackage();
                          setInsertProductPackageHelperText('');
                          setInsertProductPackageError(false);
                          setOpenInsertProductPackageDialogue(false);
                        }
                      }}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenEditProductPackageByIdDialogue(true);
                  }}
                >
                  edit Product Package By Id
                </Button>

                {/* edit product package by id dialogue */}
                <Dialog fullWidth maxWidth="md" open={openEditProductPackageByIdDialogue}>
                  <DialogTitle>{'Edit Product Package By ID'}</DialogTitle>
                  <DialogContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justify="center"
                      direction="row"
                    >
                      <Grid item xs={6}>
                        <InputLabel shrink>Package to Edit</InputLabel>
                        <Select
                          value={editProductPackage_id}
                          style={{ width: '100%' }}
                          variant="outlined"
                          onChange={(event: any) => {
                            editProductPackage_setId(event.target.value);
                          }}
                        >
                          {productPackages.map((a) => {
                            return (
                              <MenuItem key={a.id} value={a.id} style={{ maxWidth: 400 }}>
                                {a.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          variant="outlined"
                          disabled
                          label="Creator User Id"
                          value={editAnnouncement_creatorUserId} --- using editannouncement in editproductpackage
                          onChange={(event) => {
                            if (event.target.value === '') {
                              editAnnouncement_setCreatorUserId(undefined);
                            } else {
                              editAnnouncement_setCreatorUserId(event.target.value);
                            }
                          }}
                        /> */}
                      {/* </Grid> */}
                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          style={{ width: '100%' }}
                          label="Name"
                          variant="outlined"
                          value={editProductPackage_name}
                          onChange={(event) => {
                            if (event.target.value === '') {
                              editProductPackage_setName(undefined);
                            } else {
                              editProductPackage_setName(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          style={{ width: '100%' }}
                          label="Details"
                          variant="outlined"
                          rows={4}
                          value={editProductPackage_description}
                          multiline
                          onChange={(event) => {
                            if (event.target.value === '') {
                              editProductPackage_setDescription(undefined);
                            } else {
                              editProductPackage_setDescription(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">Cost per Billing Period</Typography>
                        <CurrencyTextField
                          variant="outlined"
                          fullWidth
                          id="project-number"
                          placeholder="Cost per Billing Period"
                          name="Project Number"
                          value={
                            editProductPackage_costPerBillingPeriod
                              ? parseInt(editProductPackage_costPerBillingPeriod, 10) / 100
                              : undefined
                          }
                          currencySymbol="$"
                          minimumValue="0"
                          outputFormat="number"
                          decimalCharacter="."
                          digitGroupSeparator=","
                          onBlur={(e: any, value: number) =>
                            editProductPackage_setCostPerBillingPeriod((value * 100).toString())
                          }
                          InputLabelProps={{
                            style: { overflow: 'hidden' },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">Billing Period</Typography>
                        <Select
                          value={editProductPackage_billingPeriod}
                          style={{ width: '100%' }}
                          variant="outlined"
                          onChange={(event: any) => {
                            editProductPackage_setBillingPeriod(event.target.value);
                          }}
                        >
                          <MenuItem value={BillingPeriod.Monthly}>Monthly</MenuItem>
                          <MenuItem value={BillingPeriod.Yearly}>Yearly</MenuItem>
                        </Select>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <FormControl>
                          <Typography variant="body1">Expires</Typography>
                          <KeyboardDateTimePicker
                            disablePast
                            inputVariant="outlined"
                            InputProps={{ style: { padding: 0 } }}
                            format="MM/DD/YYYY hh:mm"
                            fullWidth
                            value={editProductPackage_expires}
                            onChange={(event) => {
                              editProductPackage_setExpires(event);
                            }}
                          />
                        </FormControl>
                      </Grid> */}
                      {/* <Grid item xs={6}>
                        <InputLabel shrink>Published</InputLabel>
                        <Select
                          value={editProductPackage_published === true ? 'true' : 'false'}
                          style={{ width: '100%' }}
                          variant="outlined"
                          onChange={(event: any) => {
                            editProductPackage_setPublished(
                              event.target.value === 'true' ? true : false,
                            );
                          }}
                        >
                          <MenuItem value={'true'}>True</MenuItem>
                          <MenuItem value={'false'}>False</MenuItem>
                        </Select>
                      </Grid> */}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenEditProductPackageByIdDialogue(false);
                      }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (editProductPackage_id === '') {
                          //setEditAnnouncementHelperText('Required field');
                          //setEditAnnouncementError(true);
                        } else {
                          testEditProductPackage();
                          //setEditAnnouncementHelperText('');
                          //setEditAnnouncementError(false);
                          setOpenEditAnnouncementByIdDialogue(false);
                          setOpenEditProductPackageByIdDialogue(false);
                        }
                      }}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenDeleteProductPackageByIdDialogue(true);
                  }}
                >
                  delete Product Package By Id
                </Button>

                {/* delete product packages dialogue */}
                <Dialog fullWidth maxWidth="xs" open={openDeleteProductPacakgeByIdDialogue}>
                  <DialogTitle>{'Delete Product Package'}</DialogTitle>
                  <DialogContent>
                    <Grid item xs={12}>
                      <InputLabel shrink>Product package to delete</InputLabel>
                      <Select
                        value={deleteProductPackage_id}
                        style={{ width: '100%' }}
                        variant="outlined"
                        onChange={(event: any) => {
                          deleteProductPackage_setId(event.target.value);
                        }}
                      >
                        {productPackages.map((a) => {
                          return (
                            <MenuItem key={a.id} value={a.id} style={{ maxWidth: 400 }}>
                              {a.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenDeleteProductPackageByIdDialogue(false);
                      }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (deleteProductPackage_id === '') {
                          setDeleteProductPackageHelperText('Required field');
                          setDeleteProductPackageError(true);
                        } else {
                          testDeleteProductPackage();
                          setDeleteProductPackageHelperText('');
                          setDeleteProductPackageError(false);
                          setOpenDeleteProductPackageByIdDialogue(false);
                        }
                      }}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
            <DialogContentText>Subscribers</DialogContentText>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justify="center"
              style={{ marginBottom: '16px' }}
            >
              <Grid item>
                <Button variant="contained" onClick={testGetSubscribers}>
                  get All Subscribers
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenInsertSubscriberDialogue(true);
                  }}
                >
                  insert Subscriber
                </Button>

                {/* insert subscriber dialogue */}
                <Dialog fullWidth maxWidth="xs" open={openInsertSubscriberDialogue}>
                  <DialogTitle>{'Insert Subscriber'}</DialogTitle>
                  <DialogContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justify="center"
                      direction="row"
                    >
                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          style={{ width: '100%' }}
                          label="Name*"
                          variant="outlined"
                          error={insertSubscriberError && insertSubscriber_name === ''}
                          helperText={insertSubscriberHelperText}
                          defaultValue={insertSubscriber_name}
                          onBlur={(event) => {
                            insertSubscriber_setName(event.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          multiline
                          size="small"
                          variant="outlined"
                          rows={4}
                          style={{ width: '100%' }}
                          label="Description"
                          defaultValue={insertSubscriber_description}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              insertSubscriber_setDescription(undefined);
                            } else {
                              insertSubscriber_setDescription(event.target.value);
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          variant="outlined"
                          label="Logo File Id"
                          defaultValue={insertSubscriber_logoFileId}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              insertSubscriber_setLogoFileId(undefined);
                            } else {
                              insertSubscriber_setLogoFileId(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel shrink>Package to Edit</InputLabel>
                        <Select
                          value={insertSubscriber_productPackageId}
                          style={{ width: '100%' }}
                          variant="outlined"
                          onChange={(event: any) => {
                            insertSubscriber_setProductPackageId(event.target.value);
                          }}
                        >
                          {productPackages.map((a) => {
                            return (
                              <MenuItem key={a.id} value={a.id} style={{ maxWidth: 400 }}>
                                {a.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <InputLabel shrink>Core Client?</InputLabel>
                        <Select
                          value={insertSubscriber_coreClient}
                          style={{ width: '100%' }}
                          onChange={(event) => {
                            if (event.target.value === 'true') {
                              insertSubscriber_setCoreClient(true);
                            } else {
                              insertSubscriber_setCoreClient(false);
                            }
                          }}
                        >
                          <MenuItem value={'true'}>True</MenuItem>
                          <MenuItem value={'false'}>False</MenuItem>
                        </Select>
                      </Grid> */}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenInsertSubscriberDialogue(false);
                      }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (
                          insertSubscriber_name === '' ||
                          insertSubscriber_productPackageId === ''
                        ) {
                          setInsertSubscriberHelperText('Required field');
                          setInsertSubscriberError(true);
                        } else {
                          testInsertSubscriber();
                          setInsertSubscriberHelperText('');
                          setInsertSubscriberError(false);
                          setOpenInsertSubscriberDialogue(false);
                        }
                      }}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenEditSubscriberByIdDialogue(true);
                  }}
                >
                  edit Subscriber By Id
                </Button>

                {/* edit subscriber dialogue */}
                <Dialog fullWidth maxWidth="xs" open={openEditSubscriberByIdDialogue}>
                  <DialogTitle>{'Edit Subscriber'}</DialogTitle>
                  <DialogContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justify="center"
                      direction="row"
                    >
                      <Grid item xs={6}>
                        <InputLabel shrink>Subscriber to Edit</InputLabel>
                        <Select
                          value={editSubscriber_id}
                          style={{ width: '100%' }}
                          variant="outlined"
                          onChange={(event: any) => {
                            editSubscriber_setId(event.target.value);
                          }}
                        >
                          {subscribers.map((a) => {
                            return (
                              <MenuItem key={a.id} value={a.id} style={{ maxWidth: 400 }}>
                                {a.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{ width: '100%' }}
                          label="Name*"
                          variant="outlined"
                          error={editSubscriberByIdError && editSubscriber_name === ''}
                          helperText={editSubscriberByIdHelperText}
                          defaultValue={editSubscriber_name}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              editSubscriber_setName(undefined);
                            } else {
                              editSubscriber_setName(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          multiline
                          size="small"
                          variant="outlined"
                          rows={4}
                          style={{ width: '100%' }}
                          label="Description"
                          defaultValue={editSubscriber_description}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              editSubscriber_setDescription(undefined);
                            } else {
                              editSubscriber_setDescription(event.target.value);
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          variant="outlined"
                          label="Logo File Id"
                          defaultValue={editSubscriber_logoFileId}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              editSubscriber_setLogoFileId(undefined);
                            } else {
                              editSubscriber_setLogoFileId(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel shrink>Package to Edit</InputLabel>
                        <Select
                          value={editSubscriber_productPackageId}
                          style={{ width: '100%' }}
                          variant="outlined"
                          onChange={(event: any) => {
                            editSubscriber_setProductPackageId(event.target.value);
                          }}
                        >
                          {productPackages.map((a) => {
                            return (
                              <MenuItem key={a.id} value={a.id} style={{ maxWidth: 400 }}>
                                {a.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <InputLabel shrink>Core Client?</InputLabel>
                        <Select
                          value={editSubscriber_coreClient}
                          style={{ width: '100%' }}
                          onChange={(event) => {
                            if (event.target.value === 'true') {
                              editSubscriber_setCoreClient(true);
                            } else {
                              editSubscriber_setCoreClient(false);
                            }
                          }}
                        >
                          <MenuItem value={'true'}>True</MenuItem>
                          <MenuItem value={'false'}>False</MenuItem>
                        </Select>
                      </Grid> */}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenEditSubscriberByIdDialogue(false);
                      }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (
                          insertSubscriber_name === '' ||
                          insertSubscriber_productPackageId === ''
                        ) {
                          setEditSubscriberByIdHelperText('Required field');
                          setEditSubscriberByIdError(true);
                        } else {
                          testUpdateSubscriber();
                          setEditSubscriberByIdHelperText('');
                          setEditSubscriberByIdError(false);
                          setOpenEditSubscriberByIdDialogue(false);
                        }
                      }}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenDeleteSubscriberByIdDialogue(true);
                  }}
                >
                  delete Subscriber By Id
                </Button>

                {/* delete subscriber dialogue */}
                <Dialog fullWidth maxWidth="xs" open={openDeleteSubscriberByIdDialogue}>
                  <DialogTitle>{'Delete Subscriber'}</DialogTitle>
                  <DialogContent>
                    <Grid item xs={12}>
                      <InputLabel shrink>Subscriber to delete</InputLabel>
                      <Select
                        value={deleteSubscriber_id}
                        style={{ width: '100%' }}
                        variant="outlined"
                        onChange={(event: any) => {
                          console.log(`Setting subscriberId to ${event.target.value}`);
                          deleteSubscriber_setId(event.target.value);
                        }}
                      >
                        {subscribers.map((a) => {
                          return (
                            <MenuItem key={a.id} value={a.id} style={{ maxWidth: 400 }}>
                              {a.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenDeleteSubscriberByIdDialogue(false);
                      }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (deleteSubscriber_id === '') {
                          setDeleteSubscriberByIdHelperText('Required field');
                          setDeleteSubscriberByIdError(true);
                        } else {
                          testDeleteSubscriber();
                          setDeleteSubscriberByIdHelperText('');
                          setDeleteSubscriberByIdError(false);
                          setOpenDeleteSubscriberByIdDialogue(false);
                        }
                      }}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
            <DialogContentText>Companies</DialogContentText>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justify="center"
              style={{ marginBottom: '16px' }}
            >
              <Grid item>
                <Button variant="contained" onClick={testGetCompanies}>
                  get All Companies
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={() => setInsertCompanyDialogOpen(true)}>
                  insert Company
                </Button>

                {/* insert company dialog */}
                <Dialog maxWidth="xs" fullWidth open={insertCompanyDialogOpen}>
                  <DialogTitle>Insert Company</DialogTitle>
                  <DialogContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justify="center"
                      direction="column"
                    >
                      <Grid item>
                        <InputLabel shrink>Name*</InputLabel>
                        <TextField
                          value={insertCompany_name}
                          onChange={(event) => insertCompany_setName(event.target.value)}
                        />
                      </Grid>
                      {/* <Grid item>
                        <InputLabel shrink>Description</InputLabel>
                        <TextField
                          multiline
                          value={insertCompany_description}
                          onChange={(event) => insertCompany_setDescription(event.target.value)}
                        />
                      </Grid>
                      <Grid item>
                        <InputLabel shrink>Email</InputLabel>
                        <TextField
                          value={insertCompany_email}
                          onChange={(event) => insertCompany_setEmail(event.target.value)}
                        />
                      </Grid> */}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setInsertCompanyDialogOpen(false)}>Cancel</Button>
                    <Button disabled={!insertCompany_name} onClick={testInsertCompany}>
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
            <DialogContentText>Admins</DialogContentText>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justify="center"
              style={{ marginBottom: '16px' }}
            >
              <Grid item>
                <Button variant="contained" onClick={testGetUsers}>
                  get All Users
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={() => setInsertUserDialogOpen(true)}>
                  insert user
                </Button>

                {/* insert user dialog */}
                <Dialog fullWidth maxWidth="xs" open={insertUserDialogOpen}>
                  <DialogTitle>Insert User</DialogTitle>
                  <DialogContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justify="center"
                      direction="column"
                    >
                      <Grid item xs={6}>
                        <InputLabel shrink>Name*</InputLabel>
                        <TextField
                          required
                          value={insertUser_name}
                          onChange={(event) => insertUser_setName(event.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel shrink>Email*</InputLabel>
                        <TextField
                          required
                          value={insertUser_email}
                          onChange={(event) => insertUser_setEmail(event.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel shrink>Company</InputLabel>
                        <Select
                          value={insertUser_companyId}
                          style={{ width: '100%' }}
                          variant="outlined"
                          displayEmpty
                          onChange={(event: any) => {
                            insertUser_setCompanyId(event.target.value);
                          }}
                        >
                          <MenuItem value={undefined} disabled>
                            Select Company
                          </MenuItem>
                          {companies.map((a) => {
                            return (
                              <MenuItem key={a.id} value={a.id} style={{ maxWidth: 400 }}>
                                {a.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setInsertUserDialogOpen(false)}>Cancel</Button>
                    <Button
                      disabled={!insertUser_email || !insertUser_name}
                      onClick={testInsertUser}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenEditUserByIdDialogue(true);
                  }}
                >
                  edit User By Id
                </Button>

                {/* edit user dialogue */}
                <Dialog fullWidth maxWidth="xs" open={openEditUserByIdDialogue}>
                  <DialogTitle>{'Edit User'}</DialogTitle>
                  <DialogContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justify="center"
                      direction="row"
                    >
                      <Grid item xs={6}>
                        <InputLabel shrink>User to edit</InputLabel>
                        <Select
                          value={editUser_id}
                          style={{ width: '100%' }}
                          variant="outlined"
                          onChange={(event: any) => {
                            editUser_setId(event.target.value);
                          }}
                        >
                          {users.map((a) => {
                            return (
                              <MenuItem key={a.id} value={a.id} style={{ maxWidth: 400 }}>
                                {a.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          label="Name"
                          defaultValue={editUser_name}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              editUser_setName(undefined);
                            } else {
                              editUser_setName(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          multiline
                          size="small"
                          style={{}}
                          label="Description"
                          defaultValue={editUser_description}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              editUser_setDescription(undefined);
                            } else {
                              editUser_setDescription(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel shrink>Company</InputLabel>
                        <Select
                          value={editUser_companyId}
                          style={{ width: '100%' }}
                          variant="outlined"
                          displayEmpty
                          onChange={(event: any) => {
                            editUser_setCompanyId(event.target.value);
                          }}
                        >
                          <MenuItem value={undefined} disabled>
                            Select Company
                          </MenuItem>
                          {companies.map((a) => {
                            return (
                              <MenuItem key={a.id} value={a.id} style={{ maxWidth: 400 }}>
                                {a.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          label="Contractor License"
                          defaultValue={editUser_description}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              editUser_setContractorLicenseNumber(undefined);
                            } else {
                              editUser_setContractorLicenseNumber(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel shrink>Registered?</InputLabel>
                        <Select
                          value={editUser_isRegistered}
                          style={{ width: '100%' }}
                          onChange={(event) => {
                            if (event.target.value === 'true') {
                              editUser_setIsRegistered(true);
                            } else if (event.target.value === 'false') {
                              editUser_setIsRegistered(false);
                            } else {
                              editUser_setIsRegistered(undefined);
                            }
                          }}
                        >
                          <MenuItem value={' '}>None</MenuItem>
                          <MenuItem value={'true'}>True</MenuItem>
                          <MenuItem value={'false'}>False</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel shrink>Verified?</InputLabel>
                        <Select
                          value={editUser_isVerified}
                          style={{ width: '100%' }}
                          onChange={(event) => {
                            if (event.target.value === 'true') {
                              editUser_setIsVerified(true);
                            } else if (event.target.value === 'false') {
                              editUser_setIsVerified(false);
                            } else {
                              editUser_setIsVerified(undefined);
                            }
                          }}
                        >
                          <MenuItem value={' '}>None</MenuItem>
                          <MenuItem value={'true'}>True</MenuItem>
                          <MenuItem value={'false'}>False</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel shrink>Site Admin?</InputLabel>
                        <Select
                          value={editUser_isSiteAdmin}
                          style={{ width: '100%' }}
                          onChange={(event) => {
                            if (event.target.value === 'true') {
                              editUser_setIsSiteAdmin(true);
                            } else if (event.target.value === 'false') {
                              editUser_setIsSiteAdmin(false);
                            } else {
                              editUser_setIsSiteAdmin(undefined);
                            }
                          }}
                        >
                          <MenuItem value={' '}>None</MenuItem>
                          <MenuItem value={'true'}>True</MenuItem>
                          <MenuItem value={'false'}>False</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          label="Email"
                          defaultValue={editUser_email}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              editUser_setEmail(undefined);
                            } else {
                              editUser_setEmail(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          style={{}}
                          label="Website"
                          defaultValue={editUser_website}
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              editUser_setWebsite(undefined);
                            } else {
                              editUser_setWebsite(event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel shrink>Admin of Subcriber Id</InputLabel>
                        <Select
                          value={editUser_adminOfSubscriberId}
                          style={{ width: '100%' }}
                          variant="outlined"
                          onChange={(event: any) => {
                            editUser_setAdminOfSubscriberId(event.target.value);
                          }}
                        >
                          {subscribers.map((a) => {
                            return (
                              <MenuItem key={a.id} value={a.id} style={{ maxWidth: 400 }}>
                                {a.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenEditUserByIdDialogue(false);
                      }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (editUser_id === '') {
                          setEditUserByIdHelperText('Required field');
                          setEditUserByIdError(true);
                        } else {
                          testEditUserById();
                          setEditUserByIdHelperText('');
                          setEditUserByIdError(false);
                          setOpenEditUserByIdDialogue(false);
                        }
                      }}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogTitle style={{ borderTop: '4px solid lightgrey' }}>{'Console Output'}</DialogTitle>
          <div
            style={{
              height: '640px',
              overflowY: 'auto',
              overflowX: 'hidden',
              padding: '8px',

              background: 'black',
              color: 'white',
              fontFamily: 'courier',

              borderBottom: '4px solid lightgrey',
            }}
          >
            <ReactJson
              // if the consoleOutput is an object Error or a number, convert it to an object Object
              src={
                Object.prototype.toString.call(consoleOutput) === '[object Error]'
                  ? { error: consoleOutput }
                  : typeof consoleOutput === 'number'
                  ? { success: consoleOutput }
                  : consoleOutput
              }
              theme="grayscale"
              iconStyle="triangle"
              displayDataTypes={false}
              displayObjectSize={false}
              // groupArraysAfterLength={10}
              collapseStringsAfterLength={50}
              collapsed
            />
          </div>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenSimpleUI(false);
              }}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </MuiPickersUtilsProvider>
  );
}

export default SimpleUI;
