import React, { ChangeEvent, useEffect, useState } from 'react';
import { IUser, IUserGroup } from '../../../api-client/autogenerated';
import { makeStyles } from '@material-ui/core/styles';
import { AddBoxOutlined, IndeterminateCheckBoxOutlined } from '@material-ui/icons';
import { TreeItem, TreeView } from '@material-ui/lab';
import { ManagePermissionsDialogType } from '../../design/ManagePermissionsDialog';
import LabelWithCheckbox from '../../custom-components/LabelWithCheckbox';
import { useSelector } from 'react-redux';
import { getGroupsState } from '../../../features/groups/selector';
import clsx from 'clsx';
import { getFilteredUsersFromUserGroup } from '../../../scripts/utils';

const useStyles = makeStyles({
  button: {
    padding: '6px 20px',
  },
  treeRoot: {
    marginTop: 16,
    padding: 6,
    border: '1px solid #949494',
    borderRadius: '4px',
  },
  treeLabel: {
    padding: 0,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'capitalize',
  },
  treeLabelRoot: {
    fontWeight: 600,
  },
  treeLabelParent: {
    fontWeight: 500,
  },
  treeLabelChild: {
    fontWeight: 400,
  },
  treeGroup: {
    marginLeft: '22px',
  },
});

interface GroupWithUsers {
  group: IUserGroup;
  users: IUser[];
}

type Props = {
  groupIds: string[];
  selectedGroupIds: string[];
  handleCheckGroup: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    groupId: string,
  ) => void;
  type: ManagePermissionsDialogType;
};

export default function GroupUserTreeView(props: Props) {
  const classes = useStyles();
  const { groupIds, selectedGroupIds, handleCheckGroup, type } = props;

  const allGroups = useSelector(getGroupsState);

  const [groups, setGroups] = useState<GroupWithUsers[]>([]);

  useEffect(() => {
    setGroups(
      allGroups
        .filter((group) => groupIds.includes(group.id))
        .map((group) => ({
          group,
          users: getFilteredUsersFromUserGroup(type, group),
        })),
    );
  }, [groupIds]);

  return (
    <TreeView
      multiSelect
      defaultCollapseIcon={<IndeterminateCheckBoxOutlined />}
      defaultExpandIcon={<AddBoxOutlined />}
      className={classes.treeRoot}
    >
      {groups
        .filter((group) => group.users.length > 0)
        .map((group) => (
          <TreeItem
            key={group.group.id}
            nodeId={group.group.id}
            label={
              <LabelWithCheckbox
                label={group.group.name}
                checked={selectedGroupIds.includes(group.group.id)}
                onChange={(e, checked) => handleCheckGroup(e, checked, group.group.id)}
              />
            }
            classes={{
              label: clsx(classes.treeLabel, classes.treeLabelParent),
              group: classes.treeGroup,
            }}
          >
            {group.users.map((user) => (
              <TreeItem
                key={user.id}
                nodeId={user.id}
                label={user.name}
                classes={{
                  label: clsx(classes.treeLabel, classes.treeLabelChild),
                  group: classes.treeGroup,
                }}
              />
            ))}
          </TreeItem>
        ))}
    </TreeView>
  );
}
