import { Buffer } from 'buffer';
import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Auth } from '@aws-amplify/auth';
import App from './App';
import * as serviceWorker from './bidding-components/serviceWorker';
import store from './store';
import { SET_USER } from './features/user/constants';
import { setupAmplify } from './scripts/utils';
import { fetchUser } from './features/user/actions';
import { SET_USER_LOADING } from './features/loading/constants';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

// @ts-ignore
window.Buffer = Buffer;

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

setupAmplify();

Auth.currentAuthenticatedUser()
  .then(() => {
    store.dispatch(fetchUser());
  })
  .catch(() => {
    store.dispatch({
      type: SET_USER,
      payload: { id: '', name: '', email: '', lastUpdatedByUserId: '' },
    });
    store.dispatch({
      type: SET_USER_LOADING,
      payload: false,
    });
  });

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/" exact>
        <Provider store={store}>
          <App />
        </Provider>
      </Route>
      {/*<Route path="/admin">*/}
      {/*  <React.StrictMode>*/}
      {/*    <Provider store={store}>*/}
      {/*      <App />*/}
      {/*    </Provider>*/}
      {/*  </React.StrictMode>*/}
      {/*</Route>*/}
      <Route path="/bidding">
        <React.StrictMode>
          <Provider store={store}>
            <App />
          </Provider>
        </React.StrictMode>
      </Route>

      <Route path="/main">
        <React.StrictMode>
          <Provider store={store}>
            <App />
          </Provider>
        </React.StrictMode>
      </Route>
    </Switch>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
