import {
  createStyles,
  FormControl,
  InputAdornment,
  lighten,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useState } from 'react';

interface UserIndexSearchProps {
  updateSearch: (input: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '16px',
      flexWrap: 'wrap',
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      marginBottom: '8px',
      marginRight: '16px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '30px',
      color: '#464546',
      textTransform: 'none',
      whiteSpace: 'nowrap',
    },
    button: {
      display: 'inline-flex',
      padding: '6px 6px 6px 12px',
      whiteSpace: 'nowrap',
    },
    textfield: {
      height: '32px',

      /* Gray / Gray 50 */
      background: '#F9F9F9',

      /* Gray / Gray 400 Brand dark */
      border: '1px solid #949494',
      'border-radius': '5px',
    },
    icon: {
      fill: '#B2B1B2',
      marginLeft: '4px',
    },
    formControl: {
      display: 'inline-flex',
      width: '18%',
      marginRight: '8px',
      flexGrow: 0,
    },
  }),
);

export default function UserIndexSearch(props: UserIndexSearchProps) {
  const { updateSearch } = props;
  const classes = useStyles();
  const [inputSearch, setInputSearch] = useState<string>();
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | undefined>(undefined);

  const sendSearchUpdate = (newValue: string) => {
    setSearchTimeout(undefined);
    updateSearch(newValue || '');
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.preventDefault();
    const newValue = e.target.value;
    setInputSearch(newValue);
    if (!searchTimeout) {
      setSearchTimeout(setTimeout(() => sendSearchUpdate(newValue), 300));
    } else {
      clearTimeout(searchTimeout);
      setSearchTimeout(setTimeout(() => sendSearchUpdate(newValue), 300));
    }
  };

  return (
    <FormControl variant="outlined" className={classes.formControl} style={{ paddingRight: 16 }}>
      <TextField
        className={classes.textfield}
        placeholder="Search"
        value={inputSearch}
        onChange={(e) => handleSearchChange(e)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search
                fontSize="small"
                classes={{
                  root: classes.icon,
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
}
