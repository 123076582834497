import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dictionary } from 'lodash';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import DrawerListItem from './DrawerListItem';
import DrawerNestedListItem from './DrawerNestedListItem';
import { getTemplates } from '../../models/api/templates';
import { useSelector } from 'react-redux';
import { getCurrentSecurityGroup } from '../../features/security/selectors';
import { canViewDesign, documentTypeLookup } from '../../scripts/utils';
import { getProjectState } from '../../features/project/selectors';
import { getUserState } from '../../features/user/selectors';

interface DrawerNestedListProps {
  title: string;
  listIndex: number;
  handleDrawer: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => void;
  handleNestedLists: () => void;
  selected: number;
  icon: JSX.Element;
  drawerOpen: boolean;
  menuOpen: boolean;
  subtitles: string[];
  biddingPortal?: boolean;
  projectInfoType?: string;
  disabled?: boolean;
  handleTypeSelect?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: string) => void;
  initiateSelectedItem?: (setIndex: (index: number) => void, area: string) => void;
}

export default function DrawerNestedList(props: DrawerNestedListProps) {
  const {
    title,
    listIndex,
    handleDrawer,
    handleNestedLists,
    selected,
    icon,
    drawerOpen,
    menuOpen,
    subtitles,
    biddingPortal,
    projectInfoType,
    disabled = false,
    handleTypeSelect,
    initiateSelectedItem,
  } = props;

  const history = useHistory();
  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);
  const security = useSelector(getCurrentSecurityGroup);

  const [templateIds, setTemplateIds] = useState<Dictionary<string>>();
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleNestedItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  const handleBidModuleClick = () => {
    history.push(`/bidding/${project?.bidSetupId || ''}`);
  };

  const handleBidMgmtClick = () => {
    history.push(`/main/projects/${project!.id}/bid-mgmt`);
  };

  const handleConstMgmtClick = () => {
    history.push(`/main/projects/${project!.id}/const-mgmt`);
  };

  const handlePubCenterClick = () => {
    history.push(`/main/projects/${project!.id}/pub-center`);
  };

  const handleDriveClick = () => {
    history.push(`/main/projects/${project!.id}/design`);
  };

  const handleCorrespondenceLogClick = () => {
    history.push(`/main/projects/${project!.id}/correspondence-log`);
  };

  const handleMyNotificationsClick = () => {
    history.push(`/main/projects/${project!.id}/my-notifications`);
  };

  const getDisabled = (subtitle: string) => {
    switch (subtitle) {
      case 'Centerline BidConnect':
        return false;

      default:
        return disabled;
    }
  };

  useEffect(() => {
    if (initiateSelectedItem !== undefined) initiateSelectedItem(setSelectedIndex, 'items');
  });

  const getPageChange = (subtitle: string) => {
    switch (subtitle) {
      case 'Bid Setup':
        return handleBidMgmtClick;

      case 'Bid Management':
        return handleBidMgmtClick;

      case 'Construction Setup':
        return handleConstMgmtClick;

      case 'Construction Management':
        return handleConstMgmtClick;

      case 'Centerline BidConnect':
        return handleBidModuleClick;

      case 'Publishing Center':
        return handlePubCenterClick;

      case 'File Manager':
        return handleDriveClick;

      case 'Correspondence Log':
        return handleCorrespondenceLogClick;

      case 'My Notifications':
        return handleMyNotificationsClick;

      default:
        return undefined;
    }
  };

  const userHasPermission = (subtitle: string) => {
    if (user.isSiteAdmin || user.adminOfSubscriberId) return true;
    if (subtitle === 'File Manager') return true;
    if (subtitle === 'Centerline BidConnect') return true;
    if (biddingPortal) return true;
    if (subtitle === 'Bid Setup' || subtitle === 'Bid Management') return true;
    if (subtitle === 'Construction Management' || subtitle === 'Construction Setup') return true;
    if (subtitle === 'Publishing Center') return true;
    if (subtitle === 'Correspondence Log') return true;
    if (subtitle === 'My Notifications') return true;
    if (templateIds) {
      const docType = subtitle in documentTypeLookup ? documentTypeLookup[subtitle] : subtitle;
      const docPermission = security?.securityGroupDocumentTemplateList!.find(
        (group) => group.documentTemplateId === templateIds[docType],
      )?.securityPermissionLevel;
      return !!(security && docPermission !== null && docPermission !== undefined);
    }
    return false;
  };

  const subtitlesFiltered = subtitles.filter((s) => userHasPermission(s));

  const items = subtitlesFiltered.map((subtitle, index) => {
    return (
      <div key={subtitle}>
        {index === 0 ||
        subtitle === 'Bid Drawings' ||
        subtitle === 'Drawings' ||
        subtitle === 'Submittals' ||
        subtitle === 'Change Orders' ||
        subtitle === 'Field Reports' ||
        subtitle === 'O&M Data' ||
        subtitle === 'Punch List' ||
        subtitle === 'Cert. of Substantial Completion' ? (
          <Divider variant="middle" light flexItem style={{ margin: '10px' }} />
        ) : undefined}
        <>
          <DrawerNestedListItem
            key={`${subtitle}`}
            title={subtitle}
            listIndex={index}
            onClick={handleNestedItemClick}
            selected={selectedIndex}
            handleTypeSelectBid={handleTypeSelect}
            biddingPortal={biddingPortal}
            projectInfoType={projectInfoType}
            disabled={getDisabled(subtitle)}
            handlePageChange={getPageChange(subtitle)}
          />
          {subtitle === 'Informational Items' ? (
            <Divider variant="middle" light flexItem style={{ margin: '10px' }} />
          ) : undefined}
        </>
      </div>
    );
  });

  useEffect(() => {
    getTemplates().then((result) => setTemplateIds(result));
  }, []);

  return (
    <>
      {items.filter((item) => item).length > 0 && (
        <div>
          <DrawerListItem
            title={title}
            listIndex={listIndex}
            onClick={(event) => {
              if (!biddingPortal) handleDrawer(event, listIndex);
              handleNestedLists();
            }}
            selectedIndex={selected}
            icon={icon}
            drawerOpen={drawerOpen}
            menuOpen={menuOpen}
            selectedFunc={
              projectInfoType
                ? () => {
                    return subtitlesFiltered.includes(projectInfoType!);
                  }
                : undefined
            }
          />

          <Collapse
            in={drawerOpen && menuOpen}
            timeout="auto"
            unmountOnExit
            style={{ backgroundColor: '#001A4D' }}
          >
            <List component="div" style={{ paddingBottom: 8, paddingTop: 0 }}>
              {items}
            </List>
          </Collapse>
        </div>
      )}
    </>
  );
}
