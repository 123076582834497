// @ts-nocheck
import React from 'react';
import { MDBDataTableV5 } from 'mdbreact';

class Subscriptions extends React.Component {
  constructor(props) {
    super(props);
    this.mainApp = props.mainApp;
  }

  setContent(name) {
    this.mainApp.setContent(name);
  }

  render() {
    const data = {
      columns: [
        {
          label: 'Name',
          field: 'name',
          sort: 'asc',
          width: 400,
        },
        {
          label: 'Email',
          field: 'email',
          sort: 'asc',
          width: 450,
        },
        {
          label: 'Last Login',
          field: 'login_time',
          sort: 'asc',
          width: 550,
        },
      ],
      rows: [
        {
          name: 'Lex Adams',
          email: 'lex@crimer.com',
          login_time: '8/14/2020 11:47:51 AM',
        },
        {
          name: 'Zheng Ye',
          email: 'zheng@crimer.com',
          login_time: '8/15/2020 11:46:51 AM',
        },
        {
          name: 'Charles Glass',
          email: 'charles@crimer.com',
          login_time: '8/16/2020 11:48:51 AM',
        },
        {
          name: 'Daniel Atkinson',
          email: 'daniel@crimer.com',
          login_time: '8/18/2020 11:49:51 AM',
        },
        {
          name: 'Erick Ho',
          email: 'erick@crimer.com',
          login_time: '8/11/2020 11:49:51 AM',
        },
        {
          name: 'Scott Johnson',
          email: 'scott@crimer.com',
          login_time: '8/18/2020 11:50:51 AM',
        },
        {
          name: 'Ben Geiss',
          email: 'ben@crimer.com',
          login_time: '8/11/2020 11:51:51 AM',
        },
        {
          name: 'Albert Vu',
          email: 'albert@crimer.com',
          login_time: '8/20/2020 11:52:51 AM',
        },
        {
          name: 'Arash Amini',
          email: 'arash@crimer.com',
          login_time: '8/21/2020 11:53:51 AM',
        },
        {
          name: 'Justin Varley',
          email: 'justin@crimer.com',
          login_time: '8/18/2020 11:54:51 AM',
        },
        {
          name: 'Saulomon John',
          email: 'saulomon@crimer.com',
          login_time: '8/18/2020 11:55:51 AM',
        },
        {
          name: 'Taylor Mathews',
          email: 'tj@crimer.com',
          login_time: '8/11/2020 11:56:51 AM',
        },
        {
          name: 'Taylor Davis',
          email: 'taylor@crimer.com',
          login_time: '8/11/2020 11:57:51 AM',
        },
        {
          name: 'Allan Shi',
          email: 'allan@crimer.com',
          login_time: '8/10/2020 11:58:51 AM',
        },
      ],
    };
    return (
      <>
        <div className="home-content">
          <div style={{ width: 1500, overflow: 'scroll', paddingLeft: 450, paddingRight: 450 }}>
            <MDBDataTableV5 materialSearch bordered data={data} />
          </div>
        </div>
      </>
    );
  }
}

export default Subscriptions;
