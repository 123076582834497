import React, { useEffect, useState } from 'react';
import SubscriberAdminButton from './SubscriberAdminButton';
import { useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import HelpButton from './HelpButton';
import { Tooltip } from '@material-ui/core';
import SiteAdminButton from './SiteAdminButton';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { getProjectState } from '../../features/project/selectors';
import { getMyNotificationsWithEmailsCount } from '../../models/api/email';

export default function NavIconbuttons() {
  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);
  const history = useHistory();

  const [total, setTotal] = useState<number>();

  useEffect(() => {
    getMyNotificationsWithEmailsCount({ isUnread: true }).then((res) => setTotal(res));
  }, []);

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <Tooltip title="Contact Us" arrow placement="right">
        <span>
          <HelpButton />
        </span>
      </Tooltip>

      {!!user.adminOfSubscriberId && (
        <Tooltip title="Subscriber Admin Management" arrow placement="right">
          <span>
            <SubscriberAdminButton />
          </span>
        </Tooltip>
      )}

      {user.isSiteAdmin && (
        <Tooltip title="Site Admin Management" arrow placement="right">
          <span>
            <SiteAdminButton />
          </span>
        </Tooltip>
      )}

      <IconButton
        aria-label="notifications"
        style={{ marginRight: '10px' }}
        onClick={() =>
          history.push(`/main/${project ? `projects/${project.id}/` : ''}my-notifications`)
        }
      >
        <Badge badgeContent={total} max={999} color="secondary">
          <NotificationsIcon style={{ height: '21px', color: '#7A797A' }} />
        </Badge>
      </IconButton>
    </div>
  );
}
