import React from 'react';
import { Dialog, DialogContent, DialogTitle, TableCell } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import { descendingComparator } from '../document-index/DocumentIndexUtils';
import { parseDate } from '../../scripts/utils';

type Props = {
  open: boolean;
  handleClose: () => void;
  data: { date: string; type: string }[];
  company: string;
  name: string;
};

export default function PlanholderListDialog(props: Props) {
  const { open, handleClose, data, company, name } = props;

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h6">{`${company} (${name})`}</Typography>
      </DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .sort((a, b) => descendingComparator(a, b, 'date'))
                .map((row) => (
                  <TableRow key={row.date}>
                    <TableCell>{parseDate(row.date).format('MM/DD/YY h:mm A')}</TableCell>
                    <TableCell>{row.type}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}
