import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Auth } from '@aws-amplify/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    margin: {
      margin: theme.spacing(1),
      height: '70%', // make buttons same height
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
    },
    alignLeft: {
      textAlign: 'left',
    },
  }),
);

export default function SettingsPasswordForm() {
  const classes = useStyles();
  const [snackOpen, setSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);

  const [oldPassword, setOldPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>();
  const [newPasswordError, setNewPasswordError] = useState<string>();

  const handleSubmit = () => {
    let valid = true;
    if (newPassword !== newPasswordRepeat) {
      setNewPasswordError('Passwords do not match');
      valid = false;
    }
    if (valid) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, oldPassword || '', newPassword || '');
        })
        .then((data) => {
          setSnackOpen(true);
          setNewPasswordError(undefined);
        })
        .catch((e) => setErrorSnackOpen(true));
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off" style={{ maxWidth: 500 }}>
      <Grid container spacing={2} justify="flex-start">
        <Grid item xs={11} md={8}>
          <Typography variant="caption">Enter Old Password</Typography>
          <TextField
            id="old-password"
            label="Old password"
            type="password"
            name="old-password"
            variant="outlined"
            fullWidth
            color="primary"
            defaultValue={oldPassword}
            onBlur={(e) => setOldPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={11} md={2}></Grid>
        <Grid item xs={11} md={8}></Grid>
        <Grid item xs={11} md={2}></Grid>
        <Grid item xs={11} md={8}>
          <Typography variant="caption">Enter New Password</Typography>
          <TextField
            id="new-password"
            label="New password"
            type="password"
            name="new-password"
            variant="outlined"
            fullWidth
            error={newPasswordError !== undefined}
            helperText={newPasswordError}
            defaultValue={newPassword}
            onBlur={(e) => setNewPassword(e.target.value)}
            color="primary"
          />
        </Grid>
        <Grid item xs={11} md={2}></Grid>
        <Grid item xs={11} md={8}>
          <TextField
            id="new-password-conf"
            label="Confirm Password"
            type="password"
            name="new-password-conf"
            variant="outlined"
            fullWidth
            defaultValue={newPasswordRepeat}
            onBlur={(e) => setNewPasswordRepeat(e.target.value)}
            color="primary"
          />
        </Grid>
      </Grid>

      <div>
        <Grid container>
          <Grid item>
            <Button
              variant="contained"
              color="default"
              size="medium"
              type="reset"
              disableElevation
              className={classes.margin}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={handleSubmit}
              disableElevation
              className={classes.margin}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          open={snackOpen}
          autoHideDuration={2000}
          onClose={() => setSnackOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackOpen(false)} severity="success" variant="filled">
            Successfuly changed your password!
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorSnackOpen}
          autoHideDuration={2000}
          onClose={() => setErrorSnackOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setErrorSnackOpen(false)} severity="success" variant="filled">
            Successfuly changed your password!
          </Alert>
        </Snackbar>
      </div>
    </form>
  );
}
