import { Project } from './project';
import { RootState } from '../../store/root-state';
import {
  IPhysicalBuilding,
  IPhysicalFloor,
  IPhysicalLocation,
  IProjectUser,
} from '../../api-client/autogenerated';
import { getProjectUsers, shouldDisplayUser } from '../../scripts/utils';

export const getProjectState = (state: RootState): Project => state.project.project;

export const getProjectId = (state: RootState) =>
  state.project.project?.id ?? state.document?.document?.projectId;

export const getProjectUsersState = (state: RootState): IProjectUser[] => {
  if (state.project.project?.projectUserList) {
    return getProjectUsers(state.project.project, false).filter(
      (x) =>
        x.securityGroupId !== null ||
        x.user?.isSiteAdmin ||
        (x.user?.adminOfSubscriberId &&
          x.user.adminOfSubscriberId === state.project.project?.subscriberId),
    );
  }
  return [];
};

export const getAdminProjectUsersState = (state: RootState) => {
  if (state.project.project?.projectUserList) {
    return getProjectUsers(state.project.project, true).filter(
      (x) =>
        x.securityGroupId !== null ||
        x.user?.isSiteAdmin ||
        (x.user?.adminOfSubscriberId &&
          x.user.adminOfSubscriberId === state.project.project?.subscriberId),
    );
  }
  return [];
};

export const getAllProjectUsersState = (state: RootState): IProjectUser[] => {
  return (
    state.project.project?.projectUserList?.filter(
      (x) => x.user && shouldDisplayUser(x.user, false),
    ) || []
  );
};

export const getCurrentProjectUser = (state: RootState) =>
  state.project.project?.projectUserList?.find(
    (projectUser) => projectUser.userId === state.user.user.id,
  );

export const getDefaultArchitect = (state: RootState) =>
  state.project.project?.defaultArchitectUser;

export const getDefaultContractor = (state: RootState) =>
  state.project.project?.defaultGeneralContractorUser;

export const getPhysicalBuildingsState = (state: RootState): IPhysicalBuilding[] =>
  state.project.project?.physicalBuildings || [];

export const getPhysicalFloorsState = (state: RootState): IPhysicalFloor[] =>
  getPhysicalBuildingsState(state)
    .map((building) => building.floors || [])
    .flat();

export const getPhysicalLocationsState = (state: RootState): IPhysicalLocation[] =>
  getPhysicalFloorsState(state)
    .map((floor) => floor.locations || [])
    .flat();

export const getDisableProcoreIntegrationFeatures = (state: RootState) =>
  !!state.project?.project?.isProcoreIntegrationEnabled;
