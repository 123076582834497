import {
  BidConnectFileDeliveryEmailRequest,
  IBidSetup,
  ICustomInsertionBidSetup,
  ICustomOptionalBidSetup,
  IPaginatedIBidSetup,
} from '../../api-client/autogenerated';
import {
  ApiClient,
  createApiClient,
  getQueryFilterString,
  QueryFilter,
} from '../../api-client/api-client';
import { uploadS3File } from './filesystem';
import { AxiosResponse } from 'axios';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';

// GET //

export const getBidSetups = async (
  limit = 50,
  page = 1,
  searchInput?: string,
  sortBy?: string,
  countryId?: string,
) => {
  const query: QueryFilter = [];
  if (searchInput)
    query.push({ whereColumn: 'project.name', whereOperator: 'ILIKE', whereValue: searchInput });
  if (countryId)
    query.push({
      whereColumn: 'address.stateOrProvinceId',
      whereOperator: '=',
      whereValue: countryId,
    });
  let response: AxiosResponse<IPaginatedIBidSetup>;
  if (sortBy === 'dueDate') {
    response = await ApiClient.getAllProjectBidSetupsSortedByDueDate({
      filterByObject: getQueryFilterString(query),
      page,
      pageSize: limit,
    });
  } else {
    response = await ApiClient.getAllProjectBidSetups({
      filterByObject: getQueryFilterString(query),
      orderByColumn: sortBy,
      page,
      pageSize: limit,
    });
  }

  return response.data;
};

export const getBidSetupById = async (bidSetupId: string): Promise<IBidSetup> => {
  const response = await ApiClient.getProjectBidSetupById({ id: bidSetupId });
  return response.data;
};

// POST //

export const postBidSetup = async (
  projectId: string,
  bidSetup: ICustomInsertionBidSetup,
): Promise<IBidSetup> => {
  return ApiClient.insertBidSetupByProjectId({
    iCustomInsertionBidSetup: bidSetup,
    projectId,
  }).then((res) => res.data.bidSetup);
};

export const patchBidSetup = async (projectId: string, bidSetup: ICustomOptionalBidSetup) => {
  return ApiClient.editBidSetupByProjectId({ projectId, iCustomOptionalBidSetup: bidSetup }).then(
    (res) => res.data,
  );
};

export const unlockProjectsWithKeyByUserId = (userId: string, key: string) => {
  return ApiClient.unlockProjectsWithKeyByUserId({
    userId,
    unlockProjectsWithKeyParams: { key },
  }).then((res) => res.data);
};

export const uploadBidConnectFile = async (
  filename: string,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const response = await createApiClient().getBidConnectFileDeliveryUploadLink({
    bidConnectFileDeliveryUploadRequest: {
      filename,
      useMultiPartUpload: file.size > MULTI_PART_FILE_SIZE,
    },
  });

  await uploadS3File(response.data.s3Response, file, onUploadProgress);

  return response.data;
};

export const sendBidConnectFileDeliveryEmail = (params: BidConnectFileDeliveryEmailRequest) => {
  return createApiClient().sendBidConnectFileDeliveryEmail({
    bidConnectFileDeliveryEmailRequest: params,
  });
};

export const deleteFileByBidSetupIdAndFileId = (bidSetupId: string, fileId: string) => {
  return ApiClient.deleteFileByBidSetupIdAndFileId({ bidSetupId, fileId }).then((res) => res.data);
};

export const resetBidSetupByProjectId = (projectId: string) => {
  return ApiClient.deleteBidSetupByProjectId({ projectId }).then((res) => res.data);
};
