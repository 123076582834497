import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import { Grid, Typography } from '@material-ui/core';
import { ISimplifiedProject } from '../../api-client/autogenerated';
import DashboardBlock from './DashboardBlock';
import ProjectsTable from './ProjectsTable';
import CircularLoader from '../loader/CircularLoader';
import { useDispatch } from 'react-redux';
import { setProject } from '../../features/project/actions';

type AdminProjectsProps = {
  projects: ISimplifiedProject[];
  availableProjects: number | null;
  refreshSubscriber: () => void;
};

export default function AdminProjects(props: AdminProjectsProps) {
  const { availableProjects, projects, refreshSubscriber } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // const pieData = {
  //   labels: ['Currently Using'],
  //   datasets: [
  //     {
  //       data,
  //       backgroundColor: ['#73A3FF', 'rgba(0,0,0,0)'],
  //       hoverBackgroundColor: ['#C4C4C4', 'rgba(0,0,0,0)'],
  //     },
  //   ],
  //   options: {
  //     responsive: true,
  //     maintainAspectRatio: false,
  //     width: '50%',
  //     legend: {
  //       display: false,
  //     },
  //   },
  //   text: '',
  // };

  const history = useHistory();

  const handleNewProject = () => {
    dispatch(setProject());
    history.push('/main/project');
  };

  return (
    <DashboardBlock title="Projects">
      <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent className={classes.flexColumn}>
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 48,
              }}
            >
              <CircularLoader />
            </div>
          ) : (
            <Grid container justify="center">
              <Grid item>
                <Typography className={classes.projects}>{projects.length}</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.openProjects} style={{ marginRight: '1rem' }}>
                  total projects.
                </Typography>
              </Grid>
              {availableProjects ? (
                <>
                  <Grid item>
                    <Typography className={classes.projects}>
                      {availableProjects - projects.length}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.openProjects} style={{ marginRight: '1rem' }}>
                      out of
                    </Typography>
                  </Grid>
                </>
              ) : null}
              <Grid item>
                <Typography className={classes.projects}>
                  {availableProjects ?? 'Unlimited'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.openProjects}>Project Licenses Available</Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
        <ProjectsTable
          projects={projects}
          refreshSubscriber={refreshSubscriber}
          availableProjects={availableProjects}
          handleNewProject={handleNewProject}
        />
        <Grid item xs={12} lg={4} style={{ paddingLeft: '12rem' }}></Grid>
      </Grid>
    </DashboardBlock>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: 'none', // shadow override to fix misplaced shadow on the countdown
  },
  titleStyle: {
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
  },
  projects: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '44px',
    lineHeight: '52px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.6px',
    color: '#464546',
    alignSelf: 'center',
    paddingRight: 16,
  },
  openProjects: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '18px',
    letterSpacing: '1.21333px',
    textTransform: 'uppercase',
    color: '#464546',
    alignSelf: 'center',
    marginTop: 20,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  flexRow: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    alignSelf: 'center',
  },
  createNewProject: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    background: '#0947b9',
    cursor: 'pointer',
    // '&:hover': {
    //   color: '#0947b9',
    // },
  },
}));
