import { ActionType } from 'typesafe-actions';
import { Companies } from './companies';
import { combineReducers } from 'redux';
import * as companiesActions from './actions';
import { SET_COMPANIES } from './constants';

export type CompaniesAction = ActionType<typeof companiesActions>;
export type CompaniesState = {
  companies: Companies;
};

export default combineReducers<CompaniesState, CompaniesAction>({
  companies: (state = [], action) => {
    let newState: Companies;
    switch (action.type) {
      case SET_COMPANIES:
        newState = action.payload;
        break;

      default:
        newState = state;
        break;
    }
    return newState;
  },
});
