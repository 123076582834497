import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Collapse } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { getPhysicalLocationsState, getProjectState } from '../../features/project/selectors';
import Remove from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import { documentTypeToUrl, getDrawingAndSpecificationFiles } from '../../scripts/utils';
import _ from 'lodash';
import { DocumentTemplateType, IFile } from '../../api-client/autogenerated';
import { Assignment, Brush, List, Note } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    // height: '291px',
    left: '235px',
    top: '1511px',
  },
  titleStyle: {
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
  },
  amount: {
    paddingBottom: 15,
    fontSize: 45,
    fontWeight: 500,
    lineHeight: '32px',
  },
  amountGreen: {
    paddingBottom: 15,
    fontSize: 45,
    fontWeight: 500,
    lineHeight: '32px',
    color: '#188D57',
  },
  description: {
    fontSize: 18,
    letterSpacing: 1,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  onTrack: {
    fontSize: 32,
    letterSpacing: 1,
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#188D57',
    paddingBottom: 15,
    lineHeight: '2rem',
  },
  grid: {
    textAlign: 'center',
    paddingTop: 30,
  },
  button: {
    maxWidth: 250,
    maxHeight: 100,
    paddingLeft: 30,
    paddingRight: 30,
  },
});

export default function DashboardFieldTools() {
  const classes = useStyles();
  const history = useHistory();
  const project = useSelector(getProjectState);
  const locations = useSelector(getPhysicalLocationsState);

  const [expanded, setExpanded] = useState(true);

  const [drawingsFile, setDrawingsFile] = useState<IFile>();
  const [specFile, setSpecFile] = useState<IFile>();

  const handleFieldReportsClick = () => {
    history.push(
      `/main/projects/${project!.id}/documents/${
        documentTypeToUrl[DocumentTemplateType.FieldReports]
      }/create`,
    );
  };

  const handleDrawingsClick = () => {
    history.push(
      `/main/projects/${project!.id}/documents/${documentTypeToUrl[DocumentTemplateType.Drawings]}`,
    );
  };

  const handleSpecificationsClick = () => {
    history.push(
      `/main/projects/${project!.id}/documents/${
        documentTypeToUrl[DocumentTemplateType.Specifications]
      }`,
    );
  };

  const handlePunchListClick = () => {
    history.push(
      `/main/projects/${project!.id}/documents/${
        documentTypeToUrl[DocumentTemplateType.PunchList]
      }`,
    );
  };

  useEffect(() => {
    const { specificationFiles, drawingFiles } = getDrawingAndSpecificationFiles(project);
    const mostRecentDrawingFile = _.nth(drawingFiles, 0);
    const mostRecentSpecFile = _.nth(specificationFiles, 0);
    setDrawingsFile(mostRecentDrawingFile);
    setSpecFile(mostRecentSpecFile);
  }, [project]);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.titleStyle}>
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Grid item>
            <h2
              style={{
                textAlign: 'left',
                color: '#FFFFFF',
                margin: 0,
              }}
            >
              Field Tools
            </h2>
          </Grid>
          <Grid item>
            <IconButton style={{ color: 'white', padding: 0 }}>
              {expanded ? (
                <Remove onClick={() => setExpanded(false)} />
              ) : (
                <Add onClick={() => setExpanded(true)} />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
      <Collapse in={expanded}>
        <CardContent>
          <Grid
            container
            spacing={4}
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyItems: 'center',
              alignItems: 'center',
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disableElevation
                onClick={handleFieldReportsClick}
                className={classes.button}
                // style={{ minWidth: '20vw', width: 'fit-content', height: '6vh' }}
                style={{ width: '100%', height: '6vh' }}
              >
                <Note></Note>
                <div style={{ width: 6 }} />
                Create Field Report
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disableElevation
                disabled={locations.length === 0}
                onClick={handlePunchListClick}
                className={classes.button}
                // style={{ minWidth: '20vw', width: 'fit-content', height: '6vh' }}
                style={{ width: '100%', height: '6vh', paddingLeft: 8, paddingRight: 8 }}
              >
                <List></List>
                <div style={{ width: 6 }} />
                Punch List
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disableElevation
                disabled={!drawingsFile}
                onClick={handleDrawingsClick}
                className={classes.button}
                // style={{ minWidth: '20vw', width: 'fit-content', height: '6vh' }}
                style={{ width: '100%', height: '6vh' }}
              >
                <Brush></Brush>
                <div style={{ width: 6 }} />
                View Drawings
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disableElevation
                disabled={!specFile}
                onClick={handleSpecificationsClick}
                className={classes.button}
                // style={{ minWidth: '20vw', width: 'fit-content', height: '6vh' }}
                style={{ width: '100%', height: '6vh' }}
              >
                <Assignment></Assignment>
                <div style={{ width: 6 }} />
                View Specifications
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}
