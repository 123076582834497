// original source: https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
import { ApiClient } from '../api-client/api-client';
import { MULTI_PART_FILE_CHUNK_SIZE } from './constants';
import { PresignedUrlPartResponse } from '../api-client/autogenerated';
import _ from 'lodash';

export class Uploader {
  private readonly chunkSize: number;
  private readonly threadsQuantity: number;
  private readonly file: File;
  private fileName: string;
  private aborted: boolean;
  private uploadedSize: number;
  private readonly progressCache: _.Dictionary<number>;
  private readonly activeConnections: _.Dictionary<XMLHttpRequest>;
  private parts: PresignedUrlPartResponse[];
  private readonly uploadedParts: { PartNumber: number; ETag: string }[];
  private fileId: null | string;
  private fileKey: null | string;
  private onProgressFn: (progress: { sent: number; total: number; percentage: number }) => void;
  private onErrorFn: (error: Error) => void;
  constructor(options: { file: File; threadsQuantity: number }) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = MULTI_PART_FILE_CHUNK_SIZE;
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.file = options.file;
    this.fileName = options.file.name;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = null;
    this.fileKey = null;
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
  }

  async initialize(fileId: string, fileKey: string, waitForUpload: boolean) {
    try {
      this.fileId = fileId;
      this.fileKey = fileKey;

      // retrieving the pre-signed URLs
      const numberOfParts = Math.ceil(this.file.size / this.chunkSize);

      const urlsResponse = await ApiClient.generatePresignedUrlsParts({
        presignedUrlPartsRequest: {
          uploadId: this.fileId,
          key: this.fileKey,
          parts: numberOfParts,
        },
      });

      const newParts = urlsResponse.data;
      this.parts.push(...newParts);

      if (waitForUpload) {
        await this.sendNext();
      } else {
        void this.sendNext();
      }
    } catch (error: any) {
      await this.complete(error);
    }
  }

  async sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        await this.complete();
      }

      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.partNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);
      const promises: Promise<void>[] = [];
      const sendChunkStarted = () => {
        promises.push(this.sendNext());
      };

      try {
        await this.sendChunk(chunk, part, sendChunkStarted);
      } catch (error: any) {
        this.parts.push(part);
        await this.complete(error);
      }
      promises.push(this.sendNext());

      await Promise.all(promises);
    }
  }

  // terminating the multipart upload request on success or failure
  async complete(error?: Error) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
    } catch (error: any) {
      this.onErrorFn(error);
    }
  }

  // finalizing the multipart upload request on success by calling
  // the finalization API
  async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      await ApiClient.completeMultipartUpload({
        completeMultiPartUploadRequest: {
          uploadId: this.fileId,
          key: this.fileKey,
          parts: this.uploadedParts,
        },
      });
    }
  }

  async sendChunk(chunk: Blob, part: PresignedUrlPartResponse, sendChunkStarted: () => void) {
    return new Promise<void>((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // calculating the current progress of the multipart upload request
  handleProgress(part: number, event: any) {
    if (this.file) {
      if (event.type === 'progress' || event.type === 'error' || event.type === 'abort') {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  }

  // uploading a part through its pre-signed URL
  upload(file: Blob, part: PresignedUrlPartResponse, sendChunkStarted: () => void) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        // - 1 because PartNumber is an index starting from 1 and not 0
        const xhr = (this.activeConnections[part.partNumber - 1] = new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(this, part.partNumber - 1);

        xhr.upload.addEventListener('progress', progressListener);

        xhr.addEventListener('error', progressListener);
        xhr.addEventListener('abort', progressListener);
        xhr.addEventListener('loadend', progressListener);

        xhr.open('PUT', part.url);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader('ETag');

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.partNumber,
                // removing the " enclosing carachters from
                // the raw ETag
                ETag: ETag.replaceAll('"', ''),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.partNumber - 1];
            } else {
              console.warn(
                `Could not find ETag in response headers: ${xhr.getAllResponseHeaders()}`,
              );
            }
          }
        };

        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.partNumber - 1];
        };

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'));
          delete this.activeConnections[part.partNumber - 1];
        };

        xhr.send(file);
      }
    });
  }

  onProgress(onProgress: (progress: { sent: number; total: number; percentage: number }) => void) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError: (error: Error) => void) {
    this.onErrorFn = onError;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }
}
