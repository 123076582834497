import {
  IAnnouncement,
  IInsertionAnnouncement,
  IOptionalAnnouncement,
} from '../../api-client/autogenerated';
import { ApiClient } from '../../api-client/api-client';

// GET  //

export const getAnnouncements = async (limit = 50): Promise<IAnnouncement[]> => {
  return (await ApiClient.getAllAnnouncements({ pageSize: limit })).data.results;
};

// POST //

export const insertAnnouncement = async (announcement: IInsertionAnnouncement): Promise<number> => {
  return (await ApiClient.insertAnnouncement({ iInsertionAnnouncement: announcement })).status;
};

// PATCH //

export const updateAnnouncement = async (
  announcementId: string,
  announcement: IOptionalAnnouncement,
): Promise<number> => {
  return (
    await ApiClient.editAnnouncementById({
      announcementId,
      iOptionalAnnouncement: announcement,
    })
  ).status;
};

// DELETE //

export const deleteAnnouncement = async (announcementId: string): Promise<number> => {
  return (await ApiClient.deleteAnnouncementById({ announcementId })).status;
};
