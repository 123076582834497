import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  Checkbox,
  CheckboxProps,
  createStyles,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import DayjsUtils from '@date-io/dayjs';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import { Add, Assignment, HighlightOffRounded, MailOutline, Remove } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import _ from 'lodash';
import {
  BidBIMImportFileCategory,
  BimTreeNode,
  DocumentTemplateType,
  FileCategoryType,
  FileNode,
  IBidSetup,
  ICustomInsertionBidSetup,
  ICustomOptionalBidSetup,
  IFile,
  INumberedDocumentView,
  S3UploadResponse,
} from '../../api-client/autogenerated';
import { insertAddress } from '../../models/api/addresses';
import { getStateAndProvinceItems } from '../../bidding-components/utils';
import {
  deleteFileByBidSetupIdAndFileId,
  getBidSetupById,
  patchBidSetup,
  postBidSetup,
  resetBidSetupByProjectId,
} from '../../models/api/project-bid-setups';
import {
  copyToClipboard,
  generatePassword,
  getBidDrawingAndSpecificationFiles,
  getTitle,
  parseDate,
  ProjectMatchParams,
  removeNewlines,
  sortLastNamesDesc,
} from '../../scripts/utils';
import {
  getDocumentsByProjectIdAndType,
  patchProject,
  sendPrivateProjectInviteEmails,
} from '../../models/api/project';
import PageTitle from '../page-title/PageTitle';
import FileUploadDialog from '../dialogs/FileUploadDialog';
import CircularLoader from '../loader/CircularLoader';
import NavAppbar from '../nav-top/NavAppbar';
import PDFIcon from '../icons/PDF-icon';
import {
  importBidDrawingsFileFromDesign,
  importBidSpecificationFileFromDesign,
  importBidTabulationsFileFromDesign,
  importGeneralDocumentFileFromDesign,
  uploadBidDrawingsFile,
  uploadBidSpecificationFile,
  uploadBidTabulationsFile,
  uploadGeneralDocumentFile,
} from '../../models/api/filesystem';
import { getUserState } from '../../features/user/selectors';
import { getProjectState, getProjectUsersState } from '../../features/project/selectors';
import { getStateIds, getStates } from '../../models/api/countries';
import { getCurrentSecurityGroup } from '../../features/security/selectors';
import ImportFromBIMDialog from '../dialogs/ImportFromBIMDialog';
import { useParams } from 'react-router';
import { fetchProject } from '../../features/project/actions';
import { ApiClient } from '../../api-client/api-client';
import { insertDocument, modifyDocumentById } from '../../models/api/documents';
import { getTemplateId } from '../../models/api/templates';
import { SubmitButton, TooltipIfDisabledComponent } from '../custom-components/CustomButtons';
import FilePreviewDialog from '../dialogs/FilePreviewDialog';
import IconButton from '@material-ui/core/IconButton';
import EyeIcon from '@material-ui/icons/Visibility';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import { addSnackbar } from '../../features/snackbar/actions';
import ModifyBidDateDialog from '../dialogs/ModifyBidDateDialog';
import { ascendingComparator } from '../document-index/DocumentIndexUtils';
import { getFileById } from '../../models/api/files';
import { getGroupsState } from '../../features/groups/selector';
import InlineNotificationList from './user-groups/InlineNotificationList';
import CreateUserGroupDialog from '../design/CreateUserGroupDialog';
import { hasAdminPermissions } from '../../scripts/store-utils';
import PublishFileDialog from '../dialogs/PublishFileDialog';
import EmailUsersDialog from '../design/EmailUsersDialog';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formcontrol: {
      margin: theme.spacing(2),
    },
    form: {
      marginTop: 8,
      //paddingBottom: 32,
    },
    submit: {
      //margin: theme.spacing(1),
    },
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
      overflowX: 'hidden',
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      width: '100%',
    },
    alignLeft: {
      textAlign: 'left',
    },
    paddingLeft: {
      paddingLeft: 15,
      paddingTop: 10,
    },
    loadingCenter: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
    },
    loadingNotCenter: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    loadingMargin: {
      paddingBottom: 16,
    },
    title: {
      padding: 0,
    },
    textField: {
      maxWidth: 300,
      paddingTop: 4,
      paddingBottom: 8,
    },
    textFieldSmall: {
      maxWidth: 142,
    },
    rootGrid: {
      maxWidth: 700,
    },
    checkbox: {
      padding: '12px',
      [theme.breakpoints.down('md')]: {
        padding: '0px 12px',
      },
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      padding: 0,
    },
    fileInstructions: {
      maxWidth: 500,
      marginBottom: 32,
    },
    endDiv: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 8,
      paddingBottom: 8,
    },
    widthConstraint: {
      maxWidth: 700,
    },
    inline: {
      display: 'inline-flex',
      alignItems: 'center',
      paddingBottom: 8,
      paddingLeft: 10,
    },
    pdf: {
      width: 32,
      height: 32,
      marginRight: 16,
    },
    iconText: {
      paddingLeft: 8,
    },
    buttonText: {
      fontSize: '.7rem',
      lineHeight: '85%',
      [theme.breakpoints.up('sm')]: {
        fontSize: '.9rem',
        lineHeight: '100%',
      },
    },
  }),
);

const GreenCheck = withStyles({
  root: {
    '&$checked': {
      color: '#2BB073',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface DocumentWithFiles {
  title: string;
  files: (File | FileNode)[];
}

export default function BidManagementPage() {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<ProjectMatchParams>();
  const dispatch = useDispatch();
  const currentProject = useSelector(getProjectState);
  const user = useSelector(getUserState);
  const projectUsers = useSelector(getProjectUsersState);
  const security = useSelector(getCurrentSecurityGroup);
  const groups = useSelector(getGroupsState);

  const [bidLoading, setBidLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (
      user.isSiteAdmin ||
      user.adminOfSubscriberId === currentProject?.subscriberId ||
      (security && security?.bidManagementPermission! === 4)
    )
      setDisabled(false);
  }, [user, security, currentProject]);
  const [liveDateHasPassed, setLiveDateHasPassed] = useState(false);

  const originalBidSetup = useRef<IBidSetup>();
  const [projectType, setProjectType] = useState<'public' | 'private'>('public');
  const [password, setPassword] = useState(generatePassword());
  const [estimatedCost, setEstimatedCost] = useState<number>();
  const [estimatedCostInput, setEstimatedCostInput] = useState<number>();
  const [description, setDescription] = useState(currentProject?.description);
  const [allowElectronicRfi, setAllowElectronicRfi] = useState(true);
  const [allowElectronicSubstitutionRequest, setAllowElectronicSubstitutionRequest] = useState(
    true,
  );
  const [allowElectronicBidding, setAllowElectronicBidding] = useState(true);
  const [requireElectronicBidding, setRequireElectronicBidding] = useState(true);
  const [cityName, setCity] = useState<string>();
  const [stateLocation, setStateLocation] = useState('');
  const [contactUserId, setContactUserId] = useState('');
  const [hasPreBid, setHasPreBid] = useState(false);

  const [liveAtDate, setLiveAtDate] = useState<Dayjs | null>(null);
  const [bidDate, setBidDate] = useState<Dayjs | null>(null);
  const [submissionDate, setSubmissionDate] = useState<Dayjs | null>(null);
  const [preBidDate, setPreBidDate] = useState<Dayjs | null>(null);
  const [addendaDueDate, setAddendaDueDate] = useState<Dayjs | null>(null);

  const [newSpecificationsFiles, setNewSpecificationsFiles] = useState<(File | FileNode)[]>([]);
  const [newDrawingsFiles, setNewDrawingsFiles] = useState<(File | FileNode)[]>([]);
  const [newTabulationFile, setNewTabulationFile] = useState<File | FileNode>();
  const [newAdvertisementFile, setNewAdvertisementFile] = useState<File | FileNode>();
  const [temporaryAddendumFiles, setTemporaryAddendumFiles] = useState<(File | FileNode)[]>([]);
  const [newAddendaDocuments, setNewAddendaDocuments] = useState<DocumentWithFiles[]>([]);
  const [temporaryInformationalItemFiles, setTemporaryInformationalItemFiles] = useState<
    (File | FileNode)[]
  >([]);
  const [newInformationalItems, setNewInformationalItems] = useState<DocumentWithFiles[]>([]);

  const [bimImportedSpecificationsFile, setBimImportedSpecificationsFile] = useState<{
    bimTreeNode: BimTreeNode;
    fileCategory: BidBIMImportFileCategory;
  }>();

  const [bimImportedDrawingsFile, setBimImportedDrawingsFile] = useState<{
    bimTreeNode: BimTreeNode;
    fileCategory: BidBIMImportFileCategory;
  }>();

  const [bimImportedAddendumFile, setBimImportedAddedumFile] = useState<{
    bimTreeNode: BimTreeNode;
    fileCategory: BidBIMImportFileCategory;
  }>();

  const [specificationsDialogOpen, setSpecificationsDialogOpen] = useState(false);
  const [drawingsDialogOpen, setDrawingsDialogOpen] = useState(false);
  const [tabulationDialogOpen, setTabulationDialogOpen] = useState(false);
  const [bidAdvertisementDialogOpen, setBidAdvertisementDialogOpen] = useState(false);
  const [addendumDialogOpen, setAddendumDialogOpen] = useState(false);
  const [informationalItemDialogOpen, setInformationalItemDialogOpen] = useState(false);
  const [importFromBIMDialogState, setImportFromBIMDialogState] = useState<{
    open: boolean;
    fileType: FileCategoryType | undefined;
  }>({
    open: false,
    fileType: undefined,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorText, setErrorText] = useState('');

  const [existingDrawingsFiles, setExistingDrawingsFiles] = useState<IFile[]>([]);
  const [existingSpecificationsFiles, setExistingSpecificationsFiles] = useState<IFile[]>([]);
  const [existingAddenda, setExistingAddenda] = useState<INumberedDocumentView[]>([]);
  const [existingInformationalItems, setExistingInformationalItems] = useState<
    INumberedDocumentView[]
  >([]);
  const [existingAdvertisementFiles, setExistingAdvertisementFiles] = useState<IFile[]>([]);
  const [existingTabulationFiles, setExistingTabulationFiles] = useState<IFile[]>([]);

  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState<File>();

  const [bidDateDialogOpen, setBidDateDialogOpen] = useState(false);

  const [publishFileDialogOpen, setPublishFileDialogOpen] = useState(false);
  const [willPublishCompletedBidTabulation, setWillPublishCompletedBidTabulation] = useState(false);

  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [bidNotificationGroupIdString, setBidNotificationGroupIdString] = useState('');
  const [notificationListOpen, setNotificationListOpen] = useState(false);

  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [willSendInviteEmails, setWillSendInviteEmails] = useState(false);
  const [inviteRecipientEmails, setInviteRecipientEmails] = useState<string[]>([]);

  const bidNotificationSelectRef = useRef<HTMLSelectElement>(null);

  console.log(estimatedCostInput, estimatedCost);

  const preventTyping = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const deleteExistingFile = async (fileId: string, type: 'spec' | 'drawing') => {
    if (liveDateHasPassed) return;
    if (!currentProject?.bidSetupId) return;
    const proceed = window.confirm('Are you sure you want to delete this file?');
    if (!proceed) return;
    try {
      await deleteFileByBidSetupIdAndFileId(currentProject.bidSetupId, fileId);
      if (type === 'spec') {
        setExistingSpecificationsFiles((prev) => prev.filter((f) => f.id !== fileId));
      } else {
        setExistingDrawingsFiles((prev) => prev.filter((f) => f.id !== fileId));
      }
      dispatch(
        addSnackbar({ id: Date.now(), message: 'File successfully deleted.', severity: 'success' }),
      );
    } catch {}
  };

  const removeSpecFile = (f: File | FileNode) => {
    const removeIndex = newSpecificationsFiles.indexOf(f);
    if (removeIndex !== -1)
      setNewSpecificationsFiles([
        ...newSpecificationsFiles.slice(0, removeIndex),
        ...newSpecificationsFiles.slice(removeIndex + 1),
      ]);
  };

  const addSpecFiles = (newFiles: (File | FileNode)[]) => {
    setBimImportedSpecificationsFile(undefined);
    setNewSpecificationsFiles([...newSpecificationsFiles, ...newFiles]);
  };

  const importBIMFile = (bimTreeNode: BimTreeNode) => {
    if (!importFromBIMDialogState.fileType) return;
    switch (importFromBIMDialogState.fileType) {
      case FileCategoryType.BidSpecifications:
        setBimImportedSpecificationsFile({
          bimTreeNode,
          fileCategory: importFromBIMDialogState.fileType,
        });
        break;
      case FileCategoryType.BidAddendas:
        setBimImportedAddedumFile({
          bimTreeNode,
          fileCategory: importFromBIMDialogState.fileType,
        });
        break;
      case FileCategoryType.BidDrawings:
        setBimImportedDrawingsFile({
          bimTreeNode,
          fileCategory: importFromBIMDialogState.fileType,
        });
        break;
      default:
        throw new Error('Invalid file category type to import BIM file');
    }
    setImportFromBIMDialogState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const removeDrawingsFile = (f: File | FileNode) => {
    const removeIndex = newDrawingsFiles.indexOf(f);
    if (removeIndex !== -1)
      setNewDrawingsFiles([
        ...newDrawingsFiles.slice(0, removeIndex),
        ...newDrawingsFiles.slice(removeIndex + 1),
      ]);
  };

  const addDrawingsFiles = (newFiles: (File | FileNode)[]) => {
    setBimImportedDrawingsFile(undefined);
    setNewDrawingsFiles([...newDrawingsFiles, ...newFiles]);
  };

  const removeTabulationFile = () => {
    setNewTabulationFile(undefined);
  };

  const addTabulationFile = (newFile: File | FileNode) => {
    setNewTabulationFile(newFile);
    setPublishFileDialogOpen(true);
  };

  const removeBidAdvertisementFile = () => {
    setNewAdvertisementFile(undefined);
  };

  const addBidAdvertisementFile = (newFile: File | FileNode) => {
    setNewAdvertisementFile(newFile);
  };

  const removeTemporaryAddendumFile = (f: File | FileNode) => {
    const index = temporaryAddendumFiles.indexOf(f);
    if (index !== -1)
      setTemporaryAddendumFiles((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  };

  const removeTemporaryInformationalItemFile = (f: File | FileNode) => {
    const index = temporaryInformationalItemFiles.indexOf(f);
    if (index !== -1)
      setTemporaryInformationalItemFiles((prev) => [
        ...prev.slice(0, index),
        ...prev.slice(index + 1),
      ]);
  };

  const removeAddendumFile = (title: string, f: File | FileNode) => {
    const docIndex = newAddendaDocuments.findIndex((doc) => doc.title === title);
    if (docIndex !== -1) {
      const doc = newAddendaDocuments[docIndex];
      const fileIndex = doc.files.indexOf(f);
      if (fileIndex !== -1) {
        setNewAddendaDocuments((prev) => [
          ...prev.slice(0, docIndex),
          { title, files: [...doc.files.slice(0, fileIndex), ...doc.files.slice(fileIndex + 1)] },
          ...prev.slice(docIndex + 1),
        ]);
      }
    }
  };

  const removeInformationalItemFile = (title: string, f: File | FileNode) => {
    const docIndex = newInformationalItems.findIndex((doc) => doc.title === title);
    if (docIndex !== -1) {
      const doc = newInformationalItems[docIndex];
      const fileIndex = doc.files.indexOf(f);
      if (fileIndex !== -1) {
        setNewInformationalItems((prev) => [
          ...prev.slice(0, docIndex),
          { title, files: [...doc.files.slice(0, fileIndex), ...doc.files.slice(fileIndex + 1)] },
          ...prev.slice(docIndex + 1),
        ]);
      }
    }
  };

  const addAddendumFiles = (newFiles: (File | FileNode)[]) => {
    setTemporaryAddendumFiles(newFiles);
  };

  const addInformationalItemFiles = (newFiles: (File | FileNode)[]) => {
    setTemporaryInformationalItemFiles(newFiles);
  };

  const handleCloseAddendumDialog = (title?: string) => {
    setAddendumDialogOpen(false);
    if (temporaryAddendumFiles.length > 0 && title) {
      setNewAddendaDocuments((prev) => [...prev, { title, files: temporaryAddendumFiles }]);
      setTemporaryAddendumFiles([]);
    }
  };

  const handleCloseInformationalItemDialog = (title?: string) => {
    setInformationalItemDialogOpen(false);
    if (temporaryInformationalItemFiles.length > 0 && title) {
      setNewInformationalItems((prev) => [
        ...prev,
        { title, files: temporaryInformationalItemFiles },
      ]);
      setTemporaryInformationalItemFiles([]);
    }
  };

  const fetchExistingDocumentsAndFiles = async (projectId: string) => {
    const addenda = await getDocumentsByProjectIdAndType(projectId, DocumentTemplateType.Addenda);
    setExistingAddenda(addenda.sort((a, b) => ascendingComparator(a, b, 'documentNumber')));

    const informationalItems = await getDocumentsByProjectIdAndType(
      projectId,
      DocumentTemplateType.InformationalItems,
    );
    setExistingInformationalItems(
      informationalItems.sort((a, b) => ascendingComparator(a, b, 'documentNumber')),
    );

    const advertisements = await getDocumentsByProjectIdAndType(
      projectId,
      DocumentTemplateType.BidAdvertisement,
    );
    setExistingAdvertisementFiles(
      _.flatten(
        advertisements.map((doc) => {
          return doc.files ? doc.files : [];
        }),
      ),
    );
  };

  const handleSendEmails = async (emails: string[]) => {
    if (originalBidSetup.current) {
      await sendPrivateProjectInviteEmails(currentProject!.id, emails);
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Successfully sent project invite emails!',
          severity: 'success',
        }),
      );
    } else {
      setWillSendInviteEmails(true);
      setInviteRecipientEmails(emails);
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Invite emails will not be sent until this form is submitted!',
          severity: 'warning',
          autoHideDuration: null,
        }),
      );
    }
  };

  const fetchExistingBidSetup = async (projectId: string, bidSetupId: string) => {
    const bidSetup = await getBidSetupById(bidSetupId);
    originalBidSetup.current = bidSetup;

    if (parseDate(bidSetup.liveAt).isBefore(dayjs())) setLiveDateHasPassed(true);
    if (
      bidSetup.estimatedConstructionCost !== null &&
      bidSetup.estimatedConstructionCost !== undefined
    ) {
      setEstimatedCostInput(bidSetup.estimatedConstructionCost / 100);
      setEstimatedCost(bidSetup.estimatedConstructionCost);
    }
    setProjectType(bidSetup.password ? 'private' : 'public');
    if (bidSetup.password) setPassword(bidSetup.password);
    setAllowElectronicRfi(bidSetup.allowElectronicRfiSubmittals);
    if (
      bidSetup.allowElectronicSubstitutionRequestSubmittals !== null &&
      bidSetup.allowElectronicSubstitutionRequestSubmittals !== undefined
    ) {
      setAllowElectronicSubstitutionRequest(bidSetup.allowElectronicSubstitutionRequestSubmittals);
    }
    if (bidSetup.allowElectronicBidding !== null && bidSetup.allowElectronicBidding !== undefined) {
      setAllowElectronicBidding(bidSetup.allowElectronicBidding);
    }
    if (
      bidSetup.requireElectronicBidding !== null &&
      bidSetup.requireElectronicBidding !== undefined
    ) {
      setRequireElectronicBidding(bidSetup.requireElectronicBidding);
    }
    setDescription(bidSetup.project?.description);
    setCity(bidSetup.city || '');
    setStateLocation(
      bidSetup.stateOrProvinceId ? (await getStateIds())[bidSetup.stateOrProvinceId] : '',
    );
    setContactUserId(bidSetup.contactUserId || '');
    setBidNotificationGroupIdString(bidSetup.bidNotificationGroupId || '');
    setHasPreBid(bidSetup.hasPreBidConference);
    setLiveAtDate(parseDate(bidSetup.liveAt));
    setSubmissionDate(bidSetup.requestDueDate ? parseDate(bidSetup.requestDueDate) : null);
    setBidDate(parseDate(bidSetup.dueDate));
    setPreBidDate(bidSetup.preDueDate ? parseDate(bidSetup.preDueDate) : null);
    setAddendaDueDate(bidSetup.addendaDueDate ? parseDate(bidSetup.addendaDueDate) : null);
    setBidLoading(false);

    const { specificationFiles, drawingFiles } = getBidDrawingAndSpecificationFiles(bidSetup);
    setExistingSpecificationsFiles(specificationFiles);
    setExistingDrawingsFiles(drawingFiles);

    await fetchExistingDocumentsAndFiles(projectId);
  };

  useEffect(() => {
    if (currentProject?.bidSetupId) {
      setBidLoading(true);
      fetchExistingBidSetup(currentProject.id, currentProject.bidSetupId);
      if (currentProject?.bidTabulationsFile)
        setExistingTabulationFiles([currentProject.bidTabulationsFile]);
      setBidLoading(false);
    }
  }, [currentProject]);

  const verifyFiles = async (fileIds: string[]) => {
    return new Promise<void>((resolve) => {
      const interval = setInterval(async () => {
        if (fileIds.length === 0) {
          resolve();
          clearInterval(interval);
        }
        for (let i = 0; i < fileIds.length; i += 1) {
          const fileId = fileIds[i];
          const { isVerified } = await getFileById(fileId);
          if (isVerified) {
            const verifiedFileIndex = fileIds.findIndex((id) => id === fileId);
            if (verifiedFileIndex !== -1) fileIds.splice(verifiedFileIndex, 1);
          }
        }
      }, 3000);
    });
  };

  const handleBidNotificationGroupChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const value = event.target.value as string;
    if (value === 'create') {
      setBidNotificationGroupIdString('');
      setGroupDialogOpen(true);
    } else if (value !== undefined && value !== null) {
      setBidNotificationGroupIdString(value);
      setNotificationListOpen(true);
    }
  };

  const handleSubmit = async () => {
    setLoadingMessage('Creating Bid');
    setIsLoading(true);
    dispatch(blockNavigation());
    try {
      const stateOrProvinceId = (await getStates())[stateLocation];

      let bidSetupId: string;
      if (currentProject?.bidSetupId) {
        const newAddress =
          cityName !== originalBidSetup.current?.city ||
          stateOrProvinceId !== originalBidSetup.current?.stateOrProvinceId
            ? await insertAddress({
                stateOrProvinceId: stateLocation,
                address1: '',
                address2: '',
                city: cityName || '',
                postalCode: '',
              })
            : undefined;

        const bidNotificationGroupId =
          bidNotificationGroupIdString === '' ? null : bidNotificationGroupIdString;

        const bidSetup: ICustomOptionalBidSetup = {
          estimatedConstructionCost: estimatedCost,
          allowElectronicRfiSubmittals:
            allowElectronicRfi !== originalBidSetup.current?.allowElectronicRfiSubmittals
              ? allowElectronicRfi
              : undefined,
          allowElectronicSubstitutionRequestSubmittals:
            allowElectronicSubstitutionRequest !==
            originalBidSetup.current?.allowElectronicSubstitutionRequestSubmittals
              ? allowElectronicSubstitutionRequest
              : undefined,
          allowElectronicBidding:
            allowElectronicBidding !== originalBidSetup.current?.allowElectronicBidding
              ? allowElectronicBidding
              : undefined,
          requireElectronicBidding:
            requireElectronicBidding !== originalBidSetup.current?.requireElectronicBidding
              ? requireElectronicBidding
              : undefined,
          city: cityName !== originalBidSetup.current?.city ? cityName : undefined,
          stateOrProvinceId:
            stateOrProvinceId !== originalBidSetup.current?.stateOrProvinceId
              ? stateOrProvinceId
              : undefined,
          hasPreBidConference:
            hasPreBid !== originalBidSetup.current?.hasPreBidConference ? hasPreBid : undefined,
          liveAt:
            liveAtDate?.toISOString() !== originalBidSetup.current?.liveAt
              ? liveAtDate?.toISOString()
              : undefined,
          requestDueDate:
            submissionDate?.toISOString() !== originalBidSetup.current?.requestDueDate
              ? submissionDate?.toISOString()
              : undefined,
          dueDate:
            bidDate?.toISOString() !== originalBidSetup.current?.dueDate
              ? bidDate?.toISOString()
              : undefined,
          preDueDate:
            preBidDate?.toISOString() !== originalBidSetup.current?.preDueDate
              ? preBidDate?.toISOString()
              : undefined,
          addendaDueDate:
            addendaDueDate?.toISOString() !== originalBidSetup.current?.addendaDueDate
              ? addendaDueDate?.toISOString()
              : undefined,
          password: projectType === 'private' ? password : null,
          contactUserId:
            contactUserId !== originalBidSetup.current?.contactUserId
              ? contactUserId === ''
                ? undefined
                : contactUserId
              : undefined,
          bidNotificationGroupId:
            bidNotificationGroupId !== originalBidSetup.current?.bidNotificationGroupId
              ? bidNotificationGroupId
              : undefined,
          addressId: newAddress?.id,
        };

        bidSetupId = (await patchBidSetup(currentProject.id, bidSetup)).bidSetup.id;
      } else {
        if (!liveAtDate || !bidDate) return;

        const newAddress = await insertAddress({
          stateOrProvinceId: stateLocation,
          address1: '',
          address2: '',
          city: cityName || '',
          postalCode: '',
        });

        const bidSetup: ICustomInsertionBidSetup = {
          estimatedConstructionCost: estimatedCost,
          allowElectronicRfiSubmittals: allowElectronicRfi,
          allowElectronicSubstitutionRequestSubmittals: allowElectronicSubstitutionRequest,
          allowElectronicBidding: allowElectronicBidding,
          allowInterestedPartiesToViewBidTabulation: true,
          requireElectronicBidding: requireElectronicBidding,
          city: cityName,
          stateOrProvinceId: (await getStates())[stateLocation],
          hasPreBidConference: hasPreBid,
          liveAt: liveAtDate.toISOString(),
          requestDueDate: submissionDate ? submissionDate.toISOString() : null,
          dueDate: bidDate.toISOString(),
          preDueDate: preBidDate ? preBidDate.toISOString() : null,
          addendaDueDate: addendaDueDate ? addendaDueDate.toISOString() : null,
          password: projectType === 'private' ? password : null,
          contactUserId: contactUserId === '' ? undefined : contactUserId,
          bidNotificationGroupId: bidNotificationGroupIdString || null,
          addressId: newAddress.id,
        };

        bidSetupId = (await postBidSetup(currentProject!.id, bidSetup)).id;

        if (willSendInviteEmails) {
          await sendPrivateProjectInviteEmails(currentProject!.id, inviteRecipientEmails);
        }
      }

      for (let i = 0; i < newSpecificationsFiles.length; i += 1) {
        const file = newSpecificationsFiles[i];
        setLoadingMessage(
          `Uploading Specifications File (${i + 1}/${newSpecificationsFiles.length}) - ${
            (file as File).name || (file as FileNode).relativeKey
          }`,
        );

        if ((file as FileNode).relativeKey !== undefined) {
          await importBidSpecificationFileFromDesign(bidSetupId, {
            ownerId: (file as FileNode).ownerId,
            fullKey: (file as FileNode).fullKey,
            fullFileName: (file as FileNode).relativeKey!,
            projectId: (file as FileNode).projectId,
          });
          setUploadProgress(0);
        } else {
          await uploadBidSpecificationFile(
            bidSetupId,
            {
              fullFileName: (file as File).name,
              useMultiPartUpload: (file as File).size > MULTI_PART_FILE_SIZE,
            },
            file as File,
            handleUploadProgress,
          );
          setUploadProgress(0);
        }
      }

      for (let i = 0; i < newDrawingsFiles.length; i += 1) {
        const file = newDrawingsFiles[i];
        setLoadingMessage(
          `Uploading Drawings File (${i + 1}/${newDrawingsFiles.length}) - ${
            (file as File).name || (file as FileNode).relativeKey
          }`,
        );
        if ((file as FileNode).relativeKey !== undefined) {
          await importBidDrawingsFileFromDesign(bidSetupId, {
            ownerId: (file as FileNode).ownerId,
            fullKey: (file as FileNode).fullKey,
            fullFileName: (file as FileNode).relativeKey!,
            projectId: (file as FileNode).projectId,
          });
          setUploadProgress(0);
        } else {
          await uploadBidDrawingsFile(
            bidSetupId,
            {
              fullFileName: (file as File).name,
              useMultiPartUpload: (file as File).size > MULTI_PART_FILE_SIZE,
            },
            file as File,
            handleUploadProgress,
          );
        }
        setUploadProgress(0);
      }

      if (newAddendaDocuments.length > 0) {
        for (let i = 0; i < newAddendaDocuments.length; i += 1) {
          const fileIds: string[] = [];
          const temporaryDocument = newAddendaDocuments[i];
          const addendaDocument = await insertDocument({
            documentTemplateId: await getTemplateId(DocumentTemplateType.Addenda),
            projectId: currentProject!.id,
            creatorUserId: user.id,
            title: temporaryDocument.title,
          });
          for (let j = 0; j < temporaryDocument.files.length; j += 1) {
            const file = temporaryDocument.files[j];
            setLoadingMessage(
              `Uploading Addendum File (${j + 1}/${temporaryDocument.files.length}) - ${
                (file as File).name || (file as FileNode).relativeKey
              }`,
            );
            if ((file as FileNode).relativeKey !== undefined) {
              const response = await importGeneralDocumentFileFromDesign(
                addendaDocument.id,
                FileCategoryType.BidAddendas,
                {
                  ownerId: (file as FileNode).ownerId,
                  fullKey: (file as FileNode).fullKey,
                  fullFileName: (file as FileNode).relativeKey!,
                  projectId: (file as FileNode).projectId,
                },
              );
              if (response.file) fileIds.push(response.file.id);
            } else {
              const response = await uploadGeneralDocumentFile(
                addendaDocument.id,
                {
                  fullFileName: (file as File).name,
                  fileType: FileCategoryType.BidAddendas,
                  useMultiPartUpload: (file as File).size > MULTI_PART_FILE_SIZE,
                },
                file as File,
                handleUploadProgress,
              );
              if (response.file) fileIds.push(response.file.id);
            }

            setUploadProgress(0);
          }
          setLoadingMessage('Verifying Addenda uploads');
          await verifyFiles(fileIds);
          setUploadProgress(100);
          await modifyDocumentById(addendaDocument.id, { patch: { isFileLocked: true } });
        }
      }

      if (newInformationalItems.length > 0) {
        const fileIds: string[] = [];
        for (let i = 0; i < newInformationalItems.length; i += 1) {
          const temporaryDocument = newInformationalItems[i];
          const informationalItem = await insertDocument({
            documentTemplateId: await getTemplateId(DocumentTemplateType.InformationalItems),
            projectId: currentProject!.id,
            creatorUserId: user.id,
            title: temporaryDocument.title,
          });
          for (let j = 0; j < temporaryDocument.files.length; j += 1) {
            const file = temporaryDocument.files[j];
            setLoadingMessage(
              `Uploading Informational Item (${j + 1}/${temporaryDocument.files.length}) - ${
                (file as File).name || (file as FileNode).relativeKey
              }`,
            );
            if ((file as FileNode).relativeKey !== undefined) {
              const response = await importGeneralDocumentFileFromDesign(
                informationalItem.id,
                FileCategoryType.DocumentBase,
                {
                  ownerId: (file as FileNode).ownerId,
                  fullKey: (file as FileNode).fullKey,
                  fullFileName: (file as FileNode).relativeKey!,
                  projectId: (file as FileNode).projectId,
                },
              );
              if (response.file) fileIds.push(response.file.id);
            } else {
              const response = await uploadGeneralDocumentFile(
                informationalItem.id,
                {
                  fullFileName: (file as File).name,
                  fileType: FileCategoryType.DocumentBase,
                  useMultiPartUpload: (file as File).size > MULTI_PART_FILE_SIZE,
                },
                file as File,
                handleUploadProgress,
              );
              if (response.file) fileIds.push(response.file.id);
            }

            setUploadProgress(0);
          }
        }
        setLoadingMessage('Verifying Informational Item uploads');
        await verifyFiles(fileIds);
        setUploadProgress(100);
      }

      if (newTabulationFile) {
        let response: S3UploadResponse;

        setLoadingMessage(
          `Uploading Completed Bid Tabulation File - ${
            (newTabulationFile as File).name || (newTabulationFile as FileNode).relativeKey
          }`,
        );

        if ((newTabulationFile as FileNode).relativeKey !== undefined) {
          response = await importBidTabulationsFileFromDesign(currentProject!.id, {
            ownerId: (newTabulationFile as FileNode).ownerId,
            fullKey: (newTabulationFile as FileNode).fullKey,
            fullFileName: (newTabulationFile as FileNode).relativeKey!,
            projectId: (newTabulationFile as FileNode).projectId,
            isPublic: willPublishCompletedBidTabulation,
          });
        } else {
          response = await uploadBidTabulationsFile(
            currentProject!.id,
            {
              fullFileName: (newTabulationFile as File).name,
              isPublic: willPublishCompletedBidTabulation,
              useMultiPartUpload: (newTabulationFile as File).size > MULTI_PART_FILE_SIZE,
            },
            newTabulationFile as File,
            handleUploadProgress,
          );
        }

        if (response?.file)
          await patchProject(currentProject!.id, { bidTabulationsFileId: response.file.id });

        setUploadProgress(0);
      }

      if (newAdvertisementFile) {
        const { id } = await insertDocument({
          creatorUserId: user.id,
          projectId: currentProject!.id,
          documentTemplateId: await getTemplateId(DocumentTemplateType.BidAdvertisement),
        });
        setLoadingMessage(
          `Uploading Advertisement File - ${
            (newAdvertisementFile as File).name || (newAdvertisementFile as FileNode).relativeKey
          }`,
        );
        if ((newAdvertisementFile as FileNode).relativeKey !== undefined) {
          const fileNode = newAdvertisementFile as FileNode;
          await importGeneralDocumentFileFromDesign(id, FileCategoryType.DocumentBase, {
            ownerId: fileNode.ownerId,
            fullKey: fileNode.fullKey,
            fullFileName: fileNode.relativeKey!,
            projectId: fileNode.projectId,
          });
        } else {
          const file = newAdvertisementFile as File;
          await uploadGeneralDocumentFile(
            id,
            {
              fullFileName: file.name,
              fileType: FileCategoryType.DocumentBase,
              useMultiPartUpload: file.size > MULTI_PART_FILE_SIZE,
            },
            file,
            handleUploadProgress,
          );
        }

        setUploadProgress(0);
      }

      const importedFiles = [
        bimImportedSpecificationsFile,
        bimImportedDrawingsFile,
        bimImportedAddendumFile,
      ].filter((p) => p !== undefined); // Filter by defined.

      if (importedFiles.length > 0) {
        setLoadingMessage('Uploading imported files');
        for (const [i, importedFile] of importedFiles.entries()) {
          await ApiClient.importBimFileByBidSetupId({
            bidSetupId,
            bidBIMImportParams: {
              bimNode: importedFile?.bimTreeNode!,
              fileCategory: importedFile?.fileCategory!,
            },
          });
          handleUploadProgress({
            loaded: i + 1,
            total: importedFiles.length,
          });
        }
      }

      if (!currentProject?.bidSetupId)
        await patchProject(currentProject!.id, {
          bidSetupId,
          description,
        });
      else if (description !== currentProject.description)
        await patchProject(currentProject!.id, {
          description,
        });

      dispatch(allowNavigation());
      dispatch(fetchProject(currentProject!.id)).then(() => {
        setIsLoading(false);
        history.push(`/main/projects/${currentProject!.id}`);
      });
    } catch (error: any) {
      dispatch(allowNavigation());
      console.log(error);
      setErrorText('One or more required fields are missing');
    } finally {
      setLoadingMessage('');
      setIsLoading(false);
    }
  };

  const getSubmitDisabled = (): boolean => {
    return !cityName || !stateLocation || !liveAtDate || !bidDate || !addendaDueDate;
  };

  const handlePublishCompletedBidTabulation = (willPublish: boolean) => {
    setWillPublishCompletedBidTabulation(willPublish);
    setPublishFileDialogOpen(false);
  };

  useEffect(() => {
    if (!currentProject || currentProject.id !== params.projectId)
      dispatch(fetchProject(params.projectId));
  }, [params.projectId]);

  const showResetButton = () => !!(user.isSiteAdmin && currentProject?.bidSetupId);

  const handleResetBidSetup = async () => {
    if (!currentProject?.bidSetupId) return;
    const proceed = window.confirm(
      "WARNING. You are about to reset this project's bid setup. All existing plan holders will be lost. Do you want to continue?",
    );
    if (!proceed) return;
    await resetBidSetupByProjectId(currentProject.id);
    window.location.reload();
  };

  return (
    <main className={classes.root}>
      <NavAppbar />
      <Grid container justify="center">
        <Grid item xs={12}>
          <PageTitle title={currentProject?.bidSetupId ? 'Bid Management' : 'Bid Setup'} />
        </Grid>
      </Grid>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} container>
          <Card className={classes.paper}>
            {!bidLoading
              ? currentProject && (
                  <MuiPickersUtilsProvider utils={DayjsUtils}>
                    <form className={classes.form} noValidate>
                      <div className={classes.widthConstraint}>
                        <Grid container spacing={3} alignContent="center" justify="flex-start">
                          <Grid item xs={12}>
                            <Typography className={classes.title} variant="h2">
                              Bid Details
                            </Typography>
                          </Grid>
                          {showResetButton() ? (
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                onClick={handleResetBidSetup}
                                style={{ color: 'white', backgroundColor: '#ED3F26' }}
                              >
                                Reset Bid Setup
                              </Button>
                            </Grid>
                          ) : null}
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Project Details</Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <div className={classes.column}>
                              <FormControl className={classes.formControl}>
                                <Typography variant="body1">Project Name</Typography>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  disabled
                                  id="project-name"
                                  placeholder="Project Name"
                                  name="Project Name"
                                  value={currentProject.name}
                                  className={classes.textField}
                                />
                              </FormControl>
                              <FormControl className={classes.formControl}>
                                <Typography variant="body1">Project Description</Typography>
                                <TextField
                                  disabled={disabled}
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  multiline
                                  rows={7}
                                  id="description"
                                  placeholder="Project Description"
                                  name="Project Description"
                                  value={description}
                                  onChange={(event) => setDescription(event.target.value)}
                                  className={classes.textField}
                                />
                              </FormControl>
                              <FormControl
                                required
                                className={classes.formControl}
                                style={{ marginBottom: 5, maxWidth: 300 }}
                              >
                                <Typography variant="body1" style={{ marginTop: 8 }}>
                                  City <span style={{ color: 'red' }}>*</span>
                                </Typography>
                                <TextField
                                  disabled={disabled}
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  required
                                  id="city"
                                  name="City"
                                  placeholder="City"
                                  value={cityName}
                                  onChange={(event) => setCity(removeNewlines(event.target.value))}
                                  className={classes.textField}
                                  style={{ marginBottom: 6 }}
                                />
                                <Typography variant="body1">
                                  State <span style={{ color: 'red' }}>*</span>
                                </Typography>
                                <Select
                                  disabled={disabled}
                                  value={stateLocation}
                                  required
                                  fullWidth
                                  variant="outlined"
                                  displayEmpty
                                  style={{ marginTop: 16, marginBottom: 8 }}
                                  onChange={(event) => {
                                    setStateLocation(event.target.value as string);
                                  }}
                                >
                                  <MenuItem value="" disabled>
                                    Select State
                                  </MenuItem>
                                  {getStateAndProvinceItems()}
                                </Select>
                              </FormControl>
                              <FormControl
                                required
                                className={classes.formControl}
                                style={{ maxWidth: 300 }}
                              >
                                <Typography variant="body1">Bid Contact</Typography>
                                <Select
                                  id="bid-contact"
                                  disabled={disabled}
                                  value={contactUserId}
                                  displayEmpty
                                  style={{ marginTop: 16, marginBottom: 8 }}
                                  variant="outlined"
                                  onChange={(event) =>
                                    setContactUserId(event.target.value as string)
                                  }
                                >
                                  <MenuItem value="" disabled>
                                    Select User
                                  </MenuItem>
                                  {projectUsers
                                    .sort((a, b) => sortLastNamesDesc(a.user?.name, b.user?.name))
                                    .map((projectUser) => {
                                      return (
                                        <MenuItem key={projectUser.id} value={projectUser.userId}>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontWeight: 500 }}>
                                              {projectUser.user?.name}
                                            </span>
                                            <span>{projectUser.user?.company?.name}</span>
                                          </div>
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                              <FormControl className={classes.formControl}>
                                <Typography
                                  variant="body1"
                                  style={{ paddingTop: 16, paddingBottom: 4 }}
                                >
                                  Pre-Bid Conference
                                </Typography>
                                <RadioGroup
                                  row
                                  style={{ margin: 0 }}
                                  value={String(hasPreBid)}
                                  onChange={(event, value) => setHasPreBid(value === 'true')}
                                >
                                  <FormControlLabel
                                    value="true"
                                    control={<Radio disabled={disabled} color="primary" />}
                                    label="YES"
                                  />
                                  <FormControlLabel
                                    value="false"
                                    control={<Radio disabled={disabled} color="primary" />}
                                    label="NO"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <div className={classes.column}>
                              <Typography variant="body1">Owner Project Number</Typography>
                              <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                disabled={disabled}
                                id="owner-project-number"
                                name="Owner Project Number"
                                // placeholder="Owner Project Number"
                                value={
                                  currentProject.ownerProjectNumber
                                    ? currentProject.ownerProjectNumber
                                    : 'N/A'
                                }
                                className={classes.textField}
                              />
                              <FormControl className={classes.formControl}>
                                <Typography variant="body1">Architect Project Number</Typography>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  disabled={disabled}
                                  id="project-number"
                                  name="Project Number"
                                  value={
                                    currentProject.architectProjectNumber
                                      ? currentProject.architectProjectNumber
                                      : 'N/A'
                                  }
                                  className={classes.textField}
                                />
                              </FormControl>
                              <FormControl className={classes.formControl}>
                                <Typography variant="body1">Estimated Construction Cost</Typography>
                                <CurrencyTextField
                                  disabled={disabled}
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="estimated-cost"
                                  placeholder="Estimated Construction Cost"
                                  name="Estimated Cost"
                                  value={estimatedCostInput}
                                  currencySymbol="$"
                                  minimumValue="0"
                                  outputFormat="number"
                                  decimalCharacter="."
                                  digitGroupSeparator=","
                                  onChange={(event: any, value: number) => {
                                    setEstimatedCostInput(value);
                                    setEstimatedCost(Math.round(value * 100)); // estimated cost is in cents
                                  }}
                                  onBlur={(event: any, value: any) => {
                                    setEstimatedCostInput(value);
                                    setEstimatedCost(Math.round(value * 100)); // estimated cost is in cents
                                  }}
                                  className={classes.textField}
                                />
                              </FormControl>
                              <FormControl style={{ paddingTop: 0, paddingBottom: 8 }}>
                                <Typography
                                  variant="body1"
                                  style={{ fontStyle: 'normal', paddingBottom: 4 }}
                                >
                                  Project Type
                                </Typography>
                                <RadioGroup
                                  row
                                  value={projectType}
                                  onChange={(event, value) => {
                                    setProjectType(value as 'private' | 'public');
                                  }}
                                >
                                  <FormControlLabel
                                    value="private"
                                    control={<Radio disabled={disabled} color="primary" />}
                                    label="PRIVATE"
                                  />
                                  <FormControlLabel
                                    value="public"
                                    control={<Radio disabled={disabled} color="primary" />}
                                    label="PUBLIC"
                                  />
                                </RadioGroup>
                              </FormControl>
                              <FormControl>
                                <Typography variant="body1">Project Password</Typography>
                                <Box
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'nowrap',
                                  }}
                                >
                                  <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    disabled
                                    id="project-password"
                                    placeholder={
                                      projectType === 'private' ? 'Project Password' : 'No Password'
                                    }
                                    name="Project Password"
                                    value={projectType === 'private' ? password : ''}
                                    className={classes.textField}
                                  />
                                  {projectType === 'private' && (
                                    <Tooltip title="Copy to Clipboard" placement="right">
                                      <IconButton
                                        style={{ margin: 0, padding: '0 0 0 16px' }}
                                        disabled={projectType !== 'private'}
                                        onClick={async () => {
                                          await copyToClipboard(password || '');
                                          dispatch(
                                            addSnackbar({
                                              id: Date.now(),
                                              message:
                                                'Successfully copied project password to clipboard!',
                                              severity: 'success',
                                            }),
                                          );
                                        }}
                                      >
                                        <Assignment />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              </FormControl>
                              <div style={{ maxWidth: 300 }}>
                                <TooltipIfDisabledComponent
                                  arrow
                                  placement="top"
                                  title="Only available for private projects"
                                  disabled={projectType === 'public'}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={projectType === 'public'}
                                    startIcon={<MailOutline />}
                                    onClick={() => setEmailDialogOpen(true)}
                                    style={{ marginBottom: 16 }}
                                  >
                                    Send Invitation
                                  </Button>
                                </TooltipIfDisabledComponent>
                              </div>
                              <FormControl style={{ maxWidth: 300 }}>
                                <Typography variant="body1">Bid Notification Group</Typography>
                                <Select
                                  ref={bidNotificationSelectRef}
                                  variant="outlined"
                                  displayEmpty
                                  value={bidNotificationGroupIdString}
                                  onChange={handleBidNotificationGroupChange}
                                  SelectDisplayProps={{
                                    style: { display: 'flex', alignItems: 'center' },
                                  }}
                                  style={{ marginTop: 16, marginBottom: 8 }}
                                >
                                  <MenuItem key="none" value="">
                                    <span style={{ fontWeight: 500 }}>None</span>
                                  </MenuItem>
                                  <MenuItem
                                    key="create"
                                    value="create"
                                    disabled={
                                      !security?.canModifyUserGroups && !hasAdminPermissions(user)
                                    }
                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                  >
                                    <Add
                                      fontSize="small"
                                      style={{ marginLeft: -4, marginRight: 4 }}
                                    />
                                    Create New Group
                                  </MenuItem>
                                  {groups.map((group) => (
                                    <MenuItem key={group.id} value={group.id}>
                                      {group.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: 2,
                                  }}
                                >
                                  <Typography variant="body1" style={{ fontWeight: 500 }}>
                                    Notification List
                                  </Typography>
                                  <IconButton
                                    onClick={() => setNotificationListOpen((prev) => !prev)}
                                    style={{ padding: 0 }}
                                  >
                                    {notificationListOpen ? <Remove /> : <Add />}
                                  </IconButton>
                                </div>
                                {notificationListOpen && bidNotificationGroupIdString && (
                                  <InlineNotificationList
                                    associatedUsers={[]}
                                    associatedGroups={[bidNotificationGroupIdString]}
                                  />
                                )}
                              </FormControl>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Electronic Bidding</Typography>
                          </Grid>
                          <Grid sm={6} xs={12} className={classes.checkbox}>
                            <div className={classes.column}>
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <GreenCheck
                                      disabled={disabled}
                                      id="allow-electronic-rfi"
                                      name="Allow Electronic RFI"
                                      checked={allowElectronicRfi}
                                      onChange={(event, value) => setAllowElectronicRfi(value)}
                                    />
                                  }
                                  label="Allow Electronic RFI Submittals"
                                />
                                <FormControlLabel
                                  control={
                                    <GreenCheck
                                      disabled={disabled}
                                      id="allow-electronic-substitution-Request"
                                      name="Allow Electronic Substitution Request"
                                      checked={allowElectronicSubstitutionRequest}
                                      onChange={(event, value) =>
                                        setAllowElectronicSubstitutionRequest(value)
                                      }
                                    />
                                  }
                                  label="Allow Electronic Substitution Request Submittals"
                                />
                              </FormGroup>
                            </div>
                          </Grid>
                          <Grid sm={6} xs={12} className={classes.checkbox}>
                            <div className={classes.column}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <GreenCheck
                                      disabled={disabled}
                                      id="allow-electronic-bidding"
                                      name="Allow Electronic Bidding"
                                      checked={allowElectronicBidding}
                                      onChange={(event, value) => setAllowElectronicBidding(value)}
                                    />
                                  }
                                  label="Allow Electronic Bidding"
                                />
                                {/* <FormControlLabel
                                  control={
                                    <GreenCheck
                                      disabled={disabled}
                                      id="require-electronic-bidding"
                                      name="Electronic Bidding Required"
                                      checked={requireElectronicBidding}
                                      onChange={(event, value) =>
                                        setRequireElectronicBidding(value)
                                      }
                                    />
                                  }
                                  label="Electronic Bidding Required"
                                /> */}
                              </FormGroup>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Dates</Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                              <Typography variant="body1" style={{ marginBottom: 12 }}>
                                Live Date <span style={{ color: 'red' }}>*</span>
                              </Typography>

                              <KeyboardDateTimePicker
                                disabled={disabled || liveDateHasPassed}
                                required
                                onKeyDown={(e) => preventTyping(e)}
                                value={liveAtDate}
                                maxDate={submissionDate || undefined}
                                disablePast
                                onChange={(date) => setLiveAtDate(date)}
                                inputVariant="outlined"
                                InputProps={{ style: { padding: 0 } }}
                                format="MM/DD/YYYY hh:mm A"
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                              <Typography variant="body1" style={{ marginBottom: 12 }}>
                                Pre-bid Date
                              </Typography>
                              <KeyboardDateTimePicker
                                disabled={disabled}
                                value={preBidDate}
                                maxDate={bidDate || undefined}
                                onChange={(date) => setPreBidDate(date)}
                                inputVariant="outlined"
                                onKeyDown={(e) => preventTyping(e)}
                                disablePast
                                InputProps={{ style: { padding: 0 } }}
                                format="MM/DD/YYYY hh:mm A"
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                              <Typography variant="body1" style={{ marginBottom: 12 }}>
                                RFI/Substitution Request Submission Deadline
                              </Typography>
                              <KeyboardDateTimePicker
                                disabled={disabled}
                                value={submissionDate}
                                minDate={liveAtDate}
                                maxDate={bidDate || undefined}
                                onKeyDown={(e) => preventTyping(e)}
                                onChange={(date) => setSubmissionDate(date)}
                                inputVariant="outlined"
                                InputProps={{ style: { padding: 0 } }}
                                disablePast
                                format="MM/DD/YYYY hh:mm A"
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                              <Typography variant="body1" style={{ marginBottom: 12 }}>
                                Bid Date <span style={{ color: 'red' }}>*</span>
                              </Typography>
                              <KeyboardDateTimePicker
                                disabled={disabled}
                                required
                                value={bidDate}
                                minDate={preBidDate || undefined}
                                onChange={(date) => {
                                  setBidDate(date);
                                  setAddendaDueDate(null);
                                }}
                                onKeyDown={(e) => preventTyping(e)}
                                inputVariant="outlined"
                                InputProps={{ style: { padding: 0 } }}
                                disablePast
                                format="MM/DD/YYYY hh:mm A"
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                              <Typography variant="body1" style={{ marginBottom: 12 }}>
                                Deadline To Issue Final Addendum{' '}
                                <span style={{ color: 'red' }}>*</span>
                              </Typography>
                              <KeyboardDateTimePicker
                                disabled={disabled}
                                required
                                value={addendaDueDate}
                                minDate={preBidDate || undefined}
                                maxDate={bidDate || undefined}
                                onChange={(date) => setAddendaDueDate(date)}
                                onKeyDown={(e) => preventTyping(e)}
                                inputVariant="outlined"
                                InputProps={{ style: { padding: 0 } }}
                                disablePast
                                format="MM/DD/YYYY hh:mm A"
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Specifications</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1" className={classes.fileInstructions}>
                              Upload project specification documents as .pdf files.
                            </Typography>
                            <Grid container xs={12} direction="column">
                              {existingSpecificationsFiles.map((file) => {
                                return (
                                  <div key={file.id} className={classes.inline}>
                                    {!liveDateHasPassed && (
                                      <IconButton
                                        onClick={() => deleteExistingFile(file.id, 'spec')}
                                        style={{ padding: 0, marginRight: 8 }}
                                      >
                                        <HighlightOffRounded />
                                      </IconButton>
                                    )}
                                    <PDFIcon />
                                    <div className={classes.iconText}>{file.name}</div>
                                  </div>
                                );
                              })}
                            </Grid>

                            {newSpecificationsFiles.length > 0 ? (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body1" className={classes.fileInstructions}>
                                  These files will be displayed in the "Initial Bid Documents" tab
                                </Typography>
                                {newSpecificationsFiles.map((file) => (
                                  <div
                                    key={(file as File).name || (file as FileNode).relativeKey}
                                    className={classes.inline}
                                  >
                                    <IconButton
                                      onClick={() => removeSpecFile(file)}
                                      style={{ padding: 0, marginRight: 8 }}
                                    >
                                      <HighlightOffRounded />
                                    </IconButton>
                                    <PDFIcon />
                                    <div className={classes.iconText}>
                                      {(file as File).name || (file as FileNode).relativeKey}
                                    </div>
                                    {(file as File).name !== undefined && (
                                      <Tooltip arrow title="Preview File" placement="top">
                                        <IconButton
                                          onClick={() => {
                                            setPreviewDialogOpen(true);
                                            setPreviewFile(file as File);
                                          }}
                                          style={{ padding: 0, marginLeft: 8 }}
                                        >
                                          <EyeIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </div>
                                ))}
                              </div>
                            ) : null}
                            {bimImportedSpecificationsFile ? (
                              <div className={classes.inline}>
                                <PDFIcon />{' '}
                                <div className={classes.iconText}>
                                  {bimImportedSpecificationsFile.bimTreeNode.name}
                                </div>
                              </div>
                            ) : null}
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Button
                              className={classes.buttonText}
                              disabled={disabled || liveDateHasPassed}
                              variant="contained"
                              color="primary"
                              onClick={() => setSpecificationsDialogOpen(true)}
                              startIcon={<Add />}
                              fullWidth
                            >
                              UPLOAD SPECIFICATIONS
                            </Button>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Drawings</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1" className={classes.fileInstructions}>
                              Upload project drawings documents as .pdf files.
                            </Typography>
                            <Grid container xs={12} direction="column">
                              {existingDrawingsFiles.map((file) => {
                                return (
                                  <div key={file.id} className={classes.inline}>
                                    {!liveDateHasPassed && (
                                      <IconButton
                                        onClick={() => deleteExistingFile(file.id, 'drawing')}
                                        style={{ padding: 0, marginRight: 8 }}
                                      >
                                        <HighlightOffRounded />
                                      </IconButton>
                                    )}
                                    <PDFIcon />
                                    <div className={classes.iconText}>{file.name}</div>
                                  </div>
                                );
                              })}
                            </Grid>

                            {newDrawingsFiles.length > 0 ? (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body1" className={classes.fileInstructions}>
                                  These files will be displayed in the "Initial Bid Documents" tab
                                </Typography>
                                {newDrawingsFiles.map((file) => (
                                  <div
                                    key={(file as File).name || (file as FileNode).relativeKey}
                                    className={classes.inline}
                                  >
                                    <IconButton
                                      onClick={() => removeDrawingsFile(file)}
                                      style={{ padding: 0, marginRight: 8 }}
                                    >
                                      <HighlightOffRounded />
                                    </IconButton>
                                    <PDFIcon />
                                    <div className={classes.iconText}>
                                      {(file as File).name || (file as FileNode).relativeKey}
                                    </div>
                                    {(file as File).name !== undefined && (
                                      <Tooltip arrow title="Preview File" placement="top">
                                        <IconButton
                                          onClick={() => {
                                            setPreviewDialogOpen(true);
                                            setPreviewFile(file as File);
                                          }}
                                          style={{ padding: 0, marginLeft: 8 }}
                                        >
                                          <EyeIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </div>
                                ))}
                              </div>
                            ) : null}
                            {bimImportedDrawingsFile ? (
                              <div className={classes.inline}>
                                <PDFIcon />
                                <div className={classes.iconText}>
                                  {bimImportedDrawingsFile.bimTreeNode.name}
                                </div>
                              </div>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Button
                              className={classes.buttonText}
                              disabled={disabled || liveDateHasPassed}
                              variant="contained"
                              color="primary"
                              onClick={() => setDrawingsDialogOpen(true)}
                              startIcon={<Add />}
                              fullWidth
                            >
                              UPLOAD DRAWING
                            </Button>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Addenda</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1" className={classes.fileInstructions}>
                              Upload addendum documents as .pdf files.
                            </Typography>
                            <Grid container xs={12} direction="column">
                              {existingAddenda.map((doc) => {
                                return (
                                  <div key={doc.id}>
                                    <Typography style={{ marginBottom: 12, fontWeight: 500 }}>
                                      {getTitle(DocumentTemplateType.Addenda, doc)}
                                    </Typography>
                                    {doc.files?.map((file) => (
                                      <div key={file.id} className={classes.inline}>
                                        <PDFIcon />
                                        <div className={classes.iconText}>{file.name}</div>
                                      </div>
                                    ))}
                                  </div>
                                );
                              })}
                            </Grid>

                            {newAddendaDocuments.length > 0 ? (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography
                                  variant="body1"
                                  className={classes.fileInstructions}
                                  style={{ marginTop: 12 }}
                                >
                                  These files will be displayed in the "Addenda" tab
                                </Typography>

                                {newAddendaDocuments.map(({ title, files }, index) => (
                                  <>
                                    <Typography style={{ marginBottom: 12, fontWeight: 500 }}>
                                      Addendum #
                                      {(existingAddenda.length + 1 + index).toLocaleString(
                                        'en-US',
                                        { minimumIntegerDigits: 2 },
                                      )}{' '}
                                      — {title}
                                    </Typography>
                                    {files.map((file) => (
                                      <div
                                        key={(file as File).name || (file as FileNode).relativeKey}
                                        className={classes.inline}
                                      >
                                        <IconButton
                                          onClick={() => removeAddendumFile(title, file)}
                                          style={{ padding: 0, marginRight: 8 }}
                                        >
                                          <HighlightOffRounded />
                                        </IconButton>
                                        <PDFIcon />
                                        <div className={classes.iconText}>
                                          {(file as File).name || (file as FileNode).relativeKey}
                                        </div>
                                        {(file as File).name !== undefined && (
                                          <Tooltip arrow title="Preview File" placement="top">
                                            <IconButton
                                              onClick={() => {
                                                setPreviewDialogOpen(true);
                                                setPreviewFile(file as File);
                                              }}
                                              style={{ padding: 0, marginLeft: 8 }}
                                            >
                                              <EyeIcon />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </div>
                                    ))}
                                  </>
                                ))}
                              </div>
                            ) : null}
                            {bimImportedAddendumFile ? (
                              <div className={classes.inline}>
                                <PDFIcon />
                                <div className={classes.iconText}>
                                  {bimImportedAddendumFile.bimTreeNode.name}
                                </div>
                              </div>
                            ) : null}
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Button
                              disabled={disabled}
                              className={classes.buttonText}
                              variant="contained"
                              color="primary"
                              onClick={() => setBidDateDialogOpen(true)}
                              startIcon={<Add />}
                              fullWidth
                            >
                              UPLOAD ADDENDUM
                            </Button>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Informational Items</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1" className={classes.fileInstructions}>
                              Upload informational items as .pdf files.
                            </Typography>
                            <Grid container xs={12} direction="column">
                              {existingInformationalItems.map((doc) => {
                                return (
                                  <div key={doc.id}>
                                    <Typography style={{ marginBottom: 12, fontWeight: 500 }}>
                                      {getTitle(DocumentTemplateType.InformationalItems, doc)}
                                    </Typography>
                                    {doc.files?.map((file) => (
                                      <div key={file.id} className={classes.inline}>
                                        <PDFIcon />
                                        <div className={classes.iconText}>{file.name}</div>
                                      </div>
                                    ))}
                                  </div>
                                );
                              })}
                            </Grid>

                            {newInformationalItems.length > 0 ? (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography
                                  variant="body1"
                                  className={classes.fileInstructions}
                                  style={{ marginTop: 12 }}
                                >
                                  These files will be displayed in the "Informational Items" tab
                                </Typography>
                                {newInformationalItems.map(({ title, files }) => (
                                  <>
                                    <Typography style={{ marginBottom: 12, fontWeight: 500 }}>
                                      Informational Item #
                                      {existingInformationalItems.length > 0
                                        ? existingInformationalItems[
                                            existingInformationalItems.length - 1
                                          ].documentNumber! + 1
                                        : 1}{' '}
                                      — {title}
                                    </Typography>
                                    {files.map((file) => (
                                      <div
                                        key={(file as File).name || (file as FileNode).relativeKey}
                                        className={classes.inline}
                                      >
                                        <IconButton
                                          onClick={() => removeInformationalItemFile(title, file)}
                                          style={{ padding: 0, marginRight: 8 }}
                                        >
                                          <HighlightOffRounded />
                                        </IconButton>
                                        <PDFIcon />
                                        <div className={classes.iconText}>
                                          {(file as File).name || (file as FileNode).relativeKey}
                                        </div>
                                        {(file as File).name !== undefined && (
                                          <Tooltip arrow title="Preview File" placement="top">
                                            <IconButton
                                              onClick={() => {
                                                setPreviewDialogOpen(true);
                                                setPreviewFile(file as File);
                                              }}
                                              style={{ padding: 0, marginLeft: 8 }}
                                            >
                                              <EyeIcon />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </div>
                                    ))}
                                  </>
                                ))}
                              </div>
                            ) : null}
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Button
                              disabled={disabled}
                              variant="contained"
                              color="primary"
                              onClick={() => setInformationalItemDialogOpen(true)}
                              startIcon={<Add />}
                              fullWidth
                            >
                              UPLOAD INFORMATIONAL ITEM
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Bid Advertisements</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1" className={classes.fileInstructions}>
                              Upload bid advertisement documents as .pdf files.
                            </Typography>
                            <Grid container xs={12} direction="column">
                              {existingAdvertisementFiles.map((file) => {
                                return (
                                  <div key={file.id} className={classes.inline}>
                                    <PDFIcon />
                                    <div className={classes.iconText}>{file.name}</div>
                                  </div>
                                );
                              })}
                            </Grid>

                            {newAdvertisementFile ? (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body1" className={classes.fileInstructions}>
                                  These files will be displayed in the "Initial Bid Documents" tab
                                </Typography>
                                <div
                                  key={
                                    (newAdvertisementFile as File).name ||
                                    (newAdvertisementFile as FileNode).relativeKey
                                  }
                                  className={classes.inline}
                                >
                                  <IconButton
                                    onClick={removeBidAdvertisementFile}
                                    style={{ padding: 0, marginRight: 8 }}
                                  >
                                    <HighlightOffRounded />
                                  </IconButton>
                                  <PDFIcon />
                                  <div className={classes.iconText}>
                                    {(newAdvertisementFile as File).name ||
                                      (newAdvertisementFile as FileNode).relativeKey}
                                  </div>
                                  {(newAdvertisementFile as File).name !== undefined && (
                                    <Tooltip arrow title="Preview File" placement="top">
                                      <IconButton
                                        onClick={() => {
                                          setPreviewDialogOpen(true);
                                          setPreviewFile(newAdvertisementFile as File);
                                        }}
                                        style={{ padding: 0, marginLeft: 8 }}
                                      >
                                        <EyeIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            ) : null}
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Button
                              disabled={disabled}
                              className={classes.buttonText}
                              variant="contained"
                              color="primary"
                              onClick={() => setBidAdvertisementDialogOpen(true)}
                              startIcon={<Add />}
                              fullWidth
                            >
                              UPLOAD BID ADVERTISEMENT
                            </Button>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Completed Bid Tabulation</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1" className={classes.fileInstructions}>
                              Upload the completed bid tabulation document as a .pdf file.
                            </Typography>
                            <Grid container xs={12} direction="column">
                              {existingTabulationFiles.map((file) => {
                                return (
                                  <div key={file.id} className={classes.inline}>
                                    <PDFIcon />
                                    <div className={classes.iconText}>{file.name}</div>
                                  </div>
                                );
                              })}
                            </Grid>

                            {newTabulationFile ? (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body1" className={classes.fileInstructions}>
                                  This file will be displayed above the "Bid Submissions" table
                                </Typography>

                                <div
                                  key={
                                    (newTabulationFile as File).name ||
                                    (newTabulationFile as FileNode).relativeKey
                                  }
                                  className={classes.inline}
                                >
                                  <IconButton
                                    onClick={removeTabulationFile}
                                    style={{ padding: 0, marginRight: 8 }}
                                  >
                                    <HighlightOffRounded />
                                  </IconButton>
                                  <PDFIcon />
                                  <div className={classes.iconText}>
                                    {(newTabulationFile as File).name ||
                                      (newTabulationFile as FileNode).relativeKey}
                                  </div>
                                  {(newTabulationFile as File).name !== undefined && (
                                    <Tooltip arrow title="Preview File" placement="top">
                                      <IconButton
                                        onClick={() => {
                                          setPreviewDialogOpen(true);
                                          setPreviewFile(newTabulationFile as File);
                                        }}
                                        style={{ padding: 0, marginLeft: 8 }}
                                      >
                                        <EyeIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            ) : null}
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Button
                              disabled={
                                disabled ||
                                !currentProject?.bidSetupId ||
                                (originalBidSetup.current &&
                                  dayjs(originalBidSetup.current.dueDate).isAfter(dayjs()))
                              }
                              className={classes.buttonText}
                              variant="contained"
                              color="primary"
                              onClick={() => setTabulationDialogOpen(true)}
                              startIcon={<Add />}
                              fullWidth
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              UPLOAD COMPLETED BID TABULATION
                            </Button>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                      </div>

                      <div className={classes.widthConstraint}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <div className={classes.endDiv}>
                              {isLoading ? (
                                <div
                                  className={
                                    loadingMessage === 'Creating Bid'
                                      ? classes.loadingCenter
                                      : classes.loadingNotCenter
                                  }
                                >
                                  <div className={classes.loadingMargin}>
                                    {loadingMessage === 'Creating Bid' ? (
                                      <CircularLoader />
                                    ) : (
                                      <LinearProgress
                                        variant="determinate"
                                        value={uploadProgress}
                                        style={{ height: '9px' }}
                                      />
                                    )}
                                  </div>
                                  <div style={{ alignSelf: 'center' }}>{loadingMessage}</div>
                                </div>
                              ) : (
                                <div style={{ alignSelf: 'flex-start' }}>
                                  <SubmitButton
                                    disabled={disabled || getSubmitDisabled()}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                  >
                                    <Add />
                                    {currentProject?.bidSetupId
                                      ? 'Publish Changes'
                                      : 'Publish This Project'}
                                  </SubmitButton>
                                  <p style={{ color: 'red' }}>{errorText}</p>
                                </div>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </form>
                  </MuiPickersUtilsProvider>
                )
              : currentProject && <CircularLoader />}
          </Card>
        </Grid>
      </Grid>
      <FileUploadDialog
        open={specificationsDialogOpen}
        handleClose={() => setSpecificationsDialogOpen(false)}
        title="Upload Specification"
        addFiles={addSpecFiles}
        removeFile={removeSpecFile}
        newFiles={newSpecificationsFiles}
        canSubmit={newSpecificationsFiles.length > 0}
        allowMultiple
        disableComments
      />
      <FileUploadDialog
        open={drawingsDialogOpen}
        handleClose={() => setDrawingsDialogOpen(false)}
        title="Upload Drawings"
        addFiles={addDrawingsFiles}
        removeFile={removeDrawingsFile}
        newFiles={newDrawingsFiles}
        canSubmit={newDrawingsFiles.length > 0}
        allowMultiple
        disableComments
      />
      <FileUploadDialog
        open={tabulationDialogOpen}
        handleClose={() => setTabulationDialogOpen(false)}
        title="Upload Bid Tabulation"
        addFile={addTabulationFile}
        removeFile={removeTabulationFile}
        canSubmit={!!newTabulationFile}
        file={newTabulationFile}
        disableComments
      />
      <FileUploadDialog
        open={bidAdvertisementDialogOpen}
        handleClose={() => setBidAdvertisementDialogOpen(false)}
        title="Upload Bid Advertisement"
        addFile={addBidAdvertisementFile}
        removeFile={removeBidAdvertisementFile}
        file={newAdvertisementFile}
        canSubmit={!!newAdvertisementFile}
        disableComments
      />
      <FileUploadDialog
        open={addendumDialogOpen}
        handleClose={handleCloseAddendumDialog}
        title="Upload Addendum"
        addFiles={addAddendumFiles}
        removeFile={removeTemporaryAddendumFile}
        newFiles={temporaryAddendumFiles}
        canSubmit={temporaryAddendumFiles.length > 0}
        allowMultiple
        disableComments
        requireDocumentTitle
      />
      <FileUploadDialog
        open={informationalItemDialogOpen}
        handleClose={handleCloseInformationalItemDialog}
        title="Upload Informational Item"
        addFiles={addInformationalItemFiles}
        removeFile={removeTemporaryInformationalItemFile}
        newFiles={temporaryInformationalItemFiles}
        canSubmit={temporaryInformationalItemFiles.length > 0}
        allowMultiple
        disableComments
        requireDocumentTitle
      />
      <ImportFromBIMDialog
        project={currentProject!}
        open={importFromBIMDialogState?.open!}
        importBIMFile={importBIMFile}
        handleClose={() =>
          setImportFromBIMDialogState((prevState: any) => ({ ...prevState, open: false }))
        }
      />
      {previewFile && (
        <FilePreviewDialog
          open={previewDialogOpen}
          handleClose={() => setPreviewDialogOpen(false)}
          file={previewFile}
        />
      )}
      <ModifyBidDateDialog
        open={bidDateDialogOpen}
        handleClose={() => {
          setBidDateDialogOpen(false);
          setAddendumDialogOpen(true);
        }}
        bidDate={bidDate}
        minDate={preBidDate || undefined}
        setBidDate={setBidDate}
      />
      <PublishFileDialog
        open={publishFileDialogOpen}
        submit={handlePublishCompletedBidTabulation}
      />
      <CreateUserGroupDialog
        open={groupDialogOpen}
        handleClose={() => setGroupDialogOpen(false)}
        handleAddGroup={(g) => {
          if (bidNotificationSelectRef.current) bidNotificationSelectRef.current.value = g.id;
          setBidNotificationGroupIdString(g.id);
          setNotificationListOpen(true);
        }}
      />
      <EmailUsersDialog
        dialogOpen={emailDialogOpen}
        closeDialog={() => setEmailDialogOpen(false)}
        onSubmit={handleSendEmails}
        defaultSubject={`Invitation to Bid - ${currentProject?.name}`}
        disableSnackbar={!originalBidSetup.current}
      />
    </main>
  );
}
