/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

import dayjs from 'dayjs';

class ViewSDKClient {
  readyPromise: Promise<void>;

  adobeDCView: any;

  constructor() {
    this.readyPromise = new Promise<void>((resolve) => {
      // @ts-ignore
      if (window.AdobeDC) {
        resolve();
      } else {
        /* Wait for Adobe Document Services PDF Embed API to be ready */
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve();
        });
      }
    });
    this.adobeDCView = undefined;
  }

  ready() {
    return this.readyPromise;
  }

  previewFile(divId: string, viewerConfig: any, url: string, fileName: string) {
    const config: { clientId: string; divId?: string } = {
      /* Pass your registered client id */
      clientId: getClientId(),
    };
    if (divId) {
      /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      config.divId = divId;
    }
    /* Initialize the AdobeDC View object */
    // @ts-ignore
    this.adobeDCView = new window.AdobeDC.View(config);

    /* Invoke the file preview API on Adobe DC View object */
    return this.adobeDCView.previewFile(
      {
        /* Pass information on how to access the file */
        content: {
          /* Location of file where it is hosted */
          location: {
            // url: "https://acrobatservices.adobe.com/view-sdk-demo/PDFs/Bodea Brochure.pdf",
            url,

            // headers: [
            //     {
            //         key: "",
            //         value: "",
            //     }
            // ]
          },
        },
        /* Pass meta data of file */
        metaData: {
          /* file name */
          fileName,
          /* file ID */
          id: '6d07d124-ac85-43b3-a867-36930f502ac6',
        },
      },
      viewerConfig,
    );
  }

  previewFileUsingFilePromise(divId: string, filePromise: Promise<ArrayBuffer>, fileName: string) {
    /* Initialize the AdobeDC View object */
    // @ts-ignore
    this.adobeDCView = new window.AdobeDC.View({
      /* Pass your registered client id */
      clientId: getClientId(),
      /* Pass the div id in which PDF should be rendered */
      divId,
    });

    /* Invoke the file preview API on Adobe DC View object */
    this.adobeDCView.previewFile(
      {
        /* Pass information on how to access the file */
        content: {
          /* pass file promise which resolve to arrayBuffer */
          promise: filePromise,
        },
        /* Pass meta data of file */
        metaData: {
          /* file name */
          fileName,
        },
      },
      {},
    );
  }

  registerSaveApiHandler() {
    /* Define Save API Handler */
    const saveApiHandler = (metaData: any) => {
      return new Promise((resolve) => {
        /* Dummy implementation of Save API, replace with your business logic */
        setTimeout(() => {
          const response = {
            // @ts-ignore
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              metaData: Object.assign(metaData, { updatedAt: dayjs().valueOf() }),
            },
          };
          resolve(response);
        }, 2000);
      });
    };

    this.adobeDCView.registerCallback(
      // @ts-ignore
      window.AdobeDC.View.Enum.CallbackType.SAVE_API,
      saveApiHandler,
      {},
    );
  }

  registerEventsHandler() {
    /* Register the callback to receive the events */
    this.adobeDCView.registerCallback(
      /* Type of call back */
      // @ts-ignore
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      /* call back function */
      () => {},
      /* options to control the callback execution */
      {
        /* Enable PDF analytics events on user interaction. */
        enablePDFAnalytics: false,
      },
    );
  }
}

function getClientId() {
  if (window.location.origin.includes('localhost')) return '8c0cd670273d451cbc9b351b11d22318';
  if (window.location.origin.includes('dev.centerline.dev'))
    return '9932003700a44951b7e4203725240915';
  return '421bab5d220a49b4a01a58db6b793b86';
}

export default ViewSDKClient;
