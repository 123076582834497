import {
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Webcam from 'react-webcam';
import image1 from '../../images/webcam-toggle-chrome.png';
import image2 from '../../images/webcam-toggle-firefox.png';

interface Props {
  open: boolean;
  close: () => void;
  webcamRef: React.LegacyRef<Webcam>;
  takePhoto: () => void;
}

export default function TakePhotoDialog(props: Props) {
  const { open, close, webcamRef, takePhoto } = props;

  const [selectedDeviceId, setSelectedDeviceId] = useState<string>();
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [denied, setDenied] = useState(false);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(async () => {
        setDenied(false);
        const allDevices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = allDevices.filter((d) => d.kind === 'videoinput');
        setDevices(videoDevices);
      })
      .catch(() => {
        setDenied(true);
      });
  }, []);

  useEffect(() => {
    if (devices.length > 0) {
      setSelectedDeviceId(devices[0].deviceId);
    }
  }, [devices]);

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="lg"
      PaperProps={{ style: { overflow: 'hidden' } }}
    >
      <DialogContent style={{ overflow: 'hidden' }}>
        {!denied ? (
          <Webcam
            ref={webcamRef}
            disablePictureInPicture
            audio={false}
            videoConstraints={{ deviceId: selectedDeviceId }}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <div>
            <Typography align="center">
              Allow your browser to access your webcam, then refresh the page and click "Allow" if
              prompted.
            </Typography>
            <div
              style={{
                display: 'flex',
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              <img
                src={image1}
                title="Chrome"
                alt="Chrome"
                width={400}
                style={{ objectFit: 'contain', border: '1px solid black', marginRight: 16 }}
              />
              <img
                src={image2}
                title="Firefox"
                alt="Firefox"
                width={400}
                style={{ objectFit: 'contain', border: '1px solid black' }}
              />
            </div>
            <Typography align="center">
              If issues with using your device's webcam persist, please contact
              support@centerline.co
            </Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', padding: '8px 24px' }}>
        {devices.length > 0 ? (
          <Select
            variant="outlined"
            value={selectedDeviceId}
            onChange={(e) => setSelectedDeviceId(e.target.value as string)}
            style={{ height: 32 }}
          >
            {devices.map((d, i) => (
              <MenuItem key={d.deviceId} value={d.deviceId}>
                {d.label || `Device ${i}`}
              </MenuItem>
            ))}
          </Select>
        ) : null}
        {!denied ? (
          <Button variant="contained" color="primary" onClick={takePhoto}>
            Take Photo
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
