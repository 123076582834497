import React from 'react';

const CSVIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={31.31}
    viewBox="0 0 22 31.31"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M12.2233 31.0985L16.3194 25.5083C16.5281 25.2297 16.4167 25.0032 16.0713 25.0032H14.3697C14.2666 25.0032 14.1834 24.9191 14.1834 24.8147V21.1141C14.1834 20.7642 13.9032 20.48 13.5574 20.48H10.133C9.78647 20.48 9.50666 20.7642 9.50666 21.1141V24.8147C9.50666 24.9191 9.42313 25.0032 9.32103 25.0032H7.61981C7.274 25.0032 7.16228 25.2297 7.37025 25.5083L11.4671 31.0985C11.6761 31.3771 12.0143 31.3771 12.2233 31.0985Z"
        fill="#7A797A"
      />
      <path
        d="M17.4248 0.179149C17.3271 0.0807042 17.1412 0 17.003 0H2.24133C1.96392 0 1.74014 0.227503 1.7422 0.507185V9.82574C1.7422 9.96628 1.85461 10.08 1.9928 10.08H2.77792C2.91645 10.08 3.02886 9.96628 3.02886 9.82574V1.45894C3.02886 1.35458 3.11239 1.27109 3.21448 1.27109H15.9628C16.0649 1.27109 16.1481 1.35458 16.1481 1.45894L16.1501 5.2997C16.147 5.58042 16.3705 5.80688 16.6465 5.80688H20.4766C20.5787 5.80688 20.6622 5.89106 20.6622 5.99473V26.6926C20.6622 26.7963 20.5787 26.8804 20.4766 26.8804L16.4183 26.877C16.3158 26.877 16.1831 26.9441 16.1223 27.029L15.4214 27.9894C15.3605 28.0736 15.3953 28.1418 15.4974 28.1418L21.4081 28.1512C21.6845 28.1512 21.9093 27.9247 21.9104 27.644L21.9234 4.97375C21.9244 4.83425 21.8447 4.6398 21.7474 4.54135L17.4248 0.179149Z"
        fill="#7A797A"
      />
      <path
        d="M2.77894 20.4424H1.99381C1.85563 20.4424 1.74322 20.5561 1.74219 20.6963L1.74322 27.6442C1.74219 27.9249 1.96528 28.1514 2.24234 28.1514L8.16275 28.142C8.26587 28.142 8.29922 28.0738 8.23872 27.9896L7.53816 27.0292C7.47697 26.9446 7.34394 26.8772 7.24184 26.8772L3.21584 26.8806C3.11375 26.8806 3.03022 26.7965 3.03022 26.6928V20.6963C3.03022 20.5561 2.91747 20.4424 2.77894 20.4424Z"
        fill="#7A797A"
      />
      <path
        d="M0.583781 19.3708H16.7486C17.0264 19.3708 17.2519 19.1468 17.2519 18.8636V11.6601C17.2519 11.3762 17.0264 11.1519 16.7486 11.1519H0.580344C0.301906 11.1519 0.0791561 11.3766 0.0808748 11.6601L0.078125 18.8636C0.0795 19.1468 0.305687 19.3708 0.583781 19.3708ZM12.4459 12.6751L12.9942 14.8778C13.0378 15.0531 13.0839 15.2392 13.13 15.4323C13.1723 15.2559 13.2169 15.0758 13.264 14.8966L13.8381 12.6748H15.3554L13.8216 17.9275H12.2665L10.8069 12.6748H12.4459V12.6751ZM8.14456 15.8748C7.29206 15.5363 6.85894 14.9923 6.85894 14.2576C6.85894 13.278 7.60212 12.5944 8.66466 12.5944C9.15175 12.5944 9.69625 12.7423 9.98191 12.8894L10.1651 12.9834L9.81828 14.2503L9.54122 14.0986C9.40578 14.0249 9.06066 13.9073 8.70797 13.9073C8.41475 13.9073 8.39241 14.107 8.39241 14.1682C8.39241 14.2704 8.39241 14.3442 8.97163 14.5947C9.80281 14.9446 10.1906 15.4622 10.1906 16.2237C10.1906 17.0095 9.69144 17.9279 8.28103 17.9279C7.75716 17.9279 7.09612 17.7414 6.82869 17.5734L6.68741 17.484L6.89984 16.1747L7.21369 16.3576C7.42269 16.479 7.90978 16.6154 8.27416 16.6154C8.52509 16.6154 8.67531 16.4968 8.67531 16.2988C8.67566 16.2119 8.67566 16.0926 8.14456 15.8748ZM4.54516 12.5944C5.09619 12.5944 5.51006 12.7186 5.71425 12.8355L5.87753 12.9284L5.56953 14.252L5.2935 14.125C5.07625 14.0245 4.84044 13.9741 4.58916 13.9741C3.8855 13.9741 3.48262 14.4465 3.48262 15.2712C3.48262 16.0832 3.88825 16.5483 4.59534 16.5483C4.86003 16.5483 5.14809 16.4822 5.29728 16.4168L5.58706 16.2898L5.81806 17.6096L5.66097 17.6955C5.45678 17.8075 5.03225 17.9279 4.45062 17.9279C2.94672 17.9279 1.97563 16.9097 1.97563 15.3346C1.97528 13.6954 3.00859 12.5944 4.54516 12.5944Z"
        fill="#7A797A"
      />
    </g>
  </svg>
);

export default CSVIcon;
