import { DocumentTemplateType } from '../../api-client/autogenerated';
import { HeadCell } from './DocumentIndexHeader';
import {
  AddendaChildData,
  AsBuiltData,
  ASIData,
  BidderRfiData,
  BidDrawingsData,
  BidTabulationData,
  ChangeOrderData,
  ClearLPData,
  CloseoutSubmittalData,
  ConstructionChangeData,
  ContractorDailyLogData,
  DesignPackageData,
  DrawingsData,
  FieldReportData,
  InformationalItemsChildData,
  MeetingMinutesData,
  MiscDocumentData,
  OperationsAndMaintenanceData,
  PayApplicationData,
  PlanholderListData,
  PotentialChangeOrderData,
  ProjectManualData,
  PunchListData,
  RegulatoryApprovalData,
  RequestForChangeData,
  RFIData,
  ScheduleData,
  SpecificationsData,
  SubmittalData,
  SubstantialCompletionCertData,
  SubstitutionRequestData,
  TaskData,
  TestingData,
  WarrantyItemsData,
  WorkChangeData,
} from './DocumentIndexData';

function getHeaders(type: DocumentTemplateType) {
  switch (type) {
    // BIDDING
    case DocumentTemplateType.BidTabulation: {
      const columnTitles: HeadCell<BidTabulationData>[] = [
        { id: 'number', label: 'Bid Submission #', width: '10%' },
        { id: 'company', label: 'Submitting Company', width: '20%' },
        { id: 'license', label: 'License #', width: '20%' },
        { id: 'contact', label: 'Contact Name', width: '20%' },
        { id: 'submittedDateTime', label: 'Submitted', width: '20%' },
        { id: 'openedOn', label: 'Status', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.BidDrawings: {
      const columnTitles: HeadCell<BidDrawingsData>[] = [
        { id: 'viewButton', label: 'View', width: '10%' },
        { id: 'filename', label: 'Filename', width: '40%' },
        { id: 'size', label: 'Size', width: '25%' },
        { id: 'postedDate', label: 'Posted', width: '25%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.InformationalItems: {
      const columnTitles: HeadCell<InformationalItemsChildData>[] = [
        { id: 'number', label: 'Informational Item #', width: '15%' },
        { id: 'title', label: 'Title', width: '65%' },
        { id: 'postedDate', label: 'Posted', width: '20%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.ProjectManual: {
      const columnTitles: HeadCell<ProjectManualData>[] = [
        { id: 'viewButton', label: 'View', width: '10%' },
        { id: 'filename', label: 'Filename', width: '40%' },
        { id: 'size', label: 'Size', width: '25%' },
        { id: 'postedDate', label: 'Posted', width: '25%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.Addenda: {
      const columnTitles: HeadCell<AddendaChildData>[] = [
        { id: 'number', label: 'Addendum #', width: '15%' },
        { id: 'title', label: 'Title', width: '65%' },
        { id: 'postedDate', label: 'Posted', width: '5%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.PlanholderList: {
      const columnTitles: HeadCell<PlanholderListData>[] = [
        { id: 'company', label: 'Company', width: '25%' },
        { id: 'contact', label: 'Contact Name', width: '25%' },
        { id: 'email', label: 'Email', width: '30%' },
        { id: 'download', label: 'Download', width: '5%' },
        { id: 'view', label: 'View', width: '5%' },
        { id: 'unsubscribed', label: 'Unsubscribed', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.SubstitutionRequests: {
      const columnTitles: HeadCell<SubstitutionRequestData>[] = [
        { id: 'number', label: 'Subst. Request #', width: '12%' },
        { id: 'section', label: 'Specification Section', width: '12%' },
        { id: 'company', label: 'Submitting Company', width: '14%' },
        { id: 'contact', label: 'Contact Name', width: '14%' },
        { id: 'submittedDate', label: 'Submitted', width: '12%' },
        { id: 'dueDate', label: 'Due', width: '12%' },
        { id: 'comments', label: 'Comments', width: '12%' },
        { id: 'status', label: 'Status', width: '12%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.RegulatoryApprovals: {
      const columnTitles: HeadCell<RegulatoryApprovalData>[] = [
        { id: 'number', label: 'Approval #', width: '10%' },
        { id: 'number', label: 'Reference #', width: '10%' },
        { id: 'title', label: 'Title', width: '40%' },
        { id: 'filename', label: 'Filename', width: '20%' },
        { id: 'postedDate', label: 'Posted', width: '20%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.BidderRfIs: {
      const columnTitles: HeadCell<BidderRfiData>[] = [
        { id: 'number', label: 'Bidder RFI #', width: '10%' },
        { id: 'company', label: 'Submitting Company', width: '15%' },
        { id: 'contact', label: 'Contact Name', width: '15%' },
        { id: 'submittedDate', label: 'Submitted', width: '15%' },
        { id: 'dueDate', label: 'Due', width: '10%' },
        { id: 'comments', label: 'Comments', width: '20%' },
        { id: 'status', label: 'Status', width: '15%' },
      ];
      return columnTitles;
    }

    // CONSTRUCTION
    case DocumentTemplateType.AsiDocuments: {
      const columnTitles: HeadCell<ASIData>[] = [
        { id: 'number', label: 'ASI #', width: '10%' },
        { id: 'title', label: 'Title', width: '15%' },
        { id: 'description', label: 'Description', width: '50%' },
        { id: 'submittedBy', label: 'Created By', width: '15%' },
        { id: 'postedDate', label: 'Posted', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.ChangeOrders: {
      const columnTitles: HeadCell<ChangeOrderData>[] = [
        { id: 'number', label: 'Change Order #', width: '10%' },
        { id: 'title', label: 'Title', width: '15%' },
        { id: 'description', label: 'Description', width: '35%' },
        { id: 'cost', label: 'Impact to Cost', width: '15%' },
        { id: 'time', label: 'Impact to Time', width: '15%' },
        { id: 'postedDate', label: 'Posted', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.ConstructionChangeDirectives: {
      const columnTitles: HeadCell<ConstructionChangeData>[] = [
        { id: 'number', label: 'CCD #', width: '10%' },
        { id: 'title', label: 'Title', width: '15%' },
        { id: 'description', label: 'Description', width: '55%' },
        { id: 'submittedBy', label: 'Created By', width: '10%' },
        { id: 'postedDate', label: 'Posted', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.ContractorDailyLogs: {
      const columnTitles: HeadCell<ContractorDailyLogData>[] = [
        { id: 'logDate', label: 'Log Date', width: '10%' },
        { id: 'title', label: 'Title', width: '20%' },
        { id: 'description', label: 'Description', width: '50%' },
        { id: 'attachment', label: 'Attachment', width: '10%' },
        { id: 'postedDate', label: 'Posted', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.Drawings: {
      const columnTitles: HeadCell<DrawingsData>[] = [
        { id: 'viewButton', label: 'View', width: '5%' },
        { id: 'downloadButton', label: 'Download', width: '5%' },
        { id: 'simplePackage', label: 'Package', width: '10%' },
        { id: 'number', label: 'Sheet #', width: '10%' },
        { id: 'title', label: 'Sheet Name', width: '33%' },
        { id: 'associatedDocuments', label: 'Associated Documents', width: '12%' },
        { id: 'mostRecentFile', label: 'Source Document', width: '15%' },
        { id: 'postedDate', label: 'Posted', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.FieldReports: {
      const columnTitles: HeadCell<FieldReportData>[] = [
        { id: 'dateOfObservation', label: 'Date of Observation', width: '15%' },
        { id: 'title', label: 'Title', width: '15%' },
        { id: 'description', label: 'General Observation', width: '50%' },
        { id: 'attachment', label: 'Attachment', width: '10%' },
        { id: 'postedDate', label: 'Posted', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.MeetingMinutes: {
      const columnTitles: HeadCell<MeetingMinutesData>[] = [
        { id: 'meetingDate', label: 'Meeting Date', width: '15%' },
        { id: 'title', label: 'Title', width: '15%' },
        { id: 'description', label: 'Description', width: '40%' },
        { id: 'attachment', label: 'Attachment', width: '10%' },
        { id: 'postedDate', label: 'Posted', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.MiscellaneousDocuments: {
      const columnTitles: HeadCell<MiscDocumentData>[] = [
        { id: 'number', label: 'Misc. Document #', width: '10%' },
        { id: 'title', label: 'Title', width: '10%' },
        { id: 'description', label: 'Description', width: '40%' },
        { id: 'attachment', label: 'Attachment', width: '10%' },
        { id: 'postedDate', label: 'Posted', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.PayApplications: {
      const columnTitles: HeadCell<PayApplicationData>[] = [
        { id: 'referenceNumber', label: 'Pay App #', width: '10%' },
        { id: 'title', label: 'Title', width: '10%' },
        { id: 'description', label: 'Description', width: '20%' },
        { id: 'submittedDate', label: 'Submitted', width: '10%' },
        { id: 'responsibility', label: 'Currently Responsible', width: '20%' },
        { id: 'status', label: 'Status', width: '10%' },
        { id: 'returnedDate', label: 'Returned to Contractor', width: '10%' },
        { id: 'executedDate', label: 'Executed Final', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.PotentialChangeOrders: {
      const columnTitles: HeadCell<PotentialChangeOrderData>[] = [
        { id: 'number', label: 'PCO #', width: '8%' },
        { id: 'title', label: 'Title', width: '10%' },
        { id: 'description', label: 'Description', width: '17%' },
        { id: 'cost', label: 'Impact to Cost', width: '10%' },
        { id: 'time', label: 'Impact to Time', width: '10%' },
        { id: 'submittedDate', label: 'Submitted', width: '10%' },
        { id: 'responsibility', label: 'Currently Responsible', width: '10%' },
        { id: 'dueDate', label: 'Due', width: '5%' },
        { id: 'status', label: 'Status', width: '10%' },
        { id: 'returnedDate', label: 'Returned to Contractor', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.RequestsForChange: {
      const columnTitles: HeadCell<RequestForChangeData>[] = [
        { id: 'number', label: 'RFC #', width: '5%' },
        { id: 'title', label: 'Title', width: '10%' },
        { id: 'description', label: 'Description', width: '23%' },
        { id: 'cost', label: 'Impact to Cost', width: '9%' },
        { id: 'time', label: 'Impact to Time', width: '7%' },
        { id: 'submittedDate', label: 'Submitted', width: '8%' },
        { id: 'responsibility', label: 'Currently Responsible', width: '10%' },
        { id: 'dueDate', label: 'Due', width: '8%' },
        { id: 'status', label: 'Status', width: '10%' },
        { id: 'returnedDate', label: 'Returned to Contractor', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.SubmittalPackages:
    case DocumentTemplateType.Submittals: {
      const columnTitles: HeadCell<SubmittalData>[] = [
        { id: 'number', label: 'Submittal #', width: '10%' },
        { id: 'submittalDescription', label: 'Description', width: '20%' },
        { id: 'initialDate', label: 'Anticipated Initial Submission Date', width: '6%' },
        { id: 'actualDate', label: 'Actual Date Submitted', width: '6%' },
        { id: 'responsibility', label: 'Currently Responsible', width: '16%' },
        { id: 'dueDate', label: 'Due', width: '7%' },
        { id: 'status', label: 'Status', width: '10%' },
        { id: 'returnedDate', label: 'Returned to Contractor', width: '8%' },
        { id: 'action', label: 'Action Taken', width: '12%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.Schedules: {
      const columnTitles: HeadCell<ScheduleData>[] = [
        { id: 'number', label: 'Schedule #', width: '10%' },
        { id: 'title', label: 'Title', width: '15%' },
        { id: 'description', label: 'Description', width: '40%' },
        { id: 'submittedBy', label: 'Submitted By', width: '15%' },
        { id: 'attachment', label: 'Attachment', width: '10%' },
        { id: 'scheduleDate', label: 'Posted', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.Specifications: {
      const columnTitles: HeadCell<SpecificationsData>[] = [
        { id: 'viewButton', label: 'View', width: '5%' },
        { id: 'downloadButton', label: 'Download', width: '5%' },
        { id: 'number', label: 'Section #', width: '10%' },
        { id: 'title', label: 'Section Description', width: '33%' },
        { id: 'associatedDocuments', label: 'Associated Documents', width: '12%' },
        // TODO: Re-enable after fixing source document logic
        // { id: 'mostRecentFile', label: 'Source Document', width: '25%' },
        { id: 'postedDate', label: 'Posted', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.Testing: {
      const columnTitles: HeadCell<TestingData>[] = [
        { id: 'referenceNumber', label: 'Testing #', width: '10%' },
        { id: 'title', label: 'Title', width: '10%' },
        { id: 'description', label: 'Description', width: '30%' },
        { id: 'agency', label: 'Agency', width: '15%' },
        { id: 'submittedBy', label: 'Submitted By', width: '15%' },
        { id: 'attachment', label: 'Attachment', width: '10%' },
        { id: 'postedDate', label: 'Posted', width: '15%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.RequestsForInformation: {
      const columnTitles: HeadCell<RFIData>[] = [
        { id: 'number', label: 'RFI #', width: '10%' },
        { id: 'title', label: 'Title', width: '10%' },
        { id: 'description', label: 'Description', width: '20%' },
        { id: 'submittedDate', label: 'Submitted', width: '10%' },
        { id: 'responsibility', label: 'Currently Responsible', width: '10%' },
        { id: 'dueDate', label: 'Due', width: '10%' },
        { id: 'status', label: 'Status', width: '10%' },
        { id: 'returnedDate', label: 'Returned to Contractor', width: '10%' },
        { id: 'acceptance', label: 'Contractor Acceptance', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.WorkChangeProposalRequests: {
      const columnTitles: HeadCell<WorkChangeData>[] = [
        { id: 'referenceNumber', label: 'WCPR #', width: '5%' },
        { id: 'title', label: 'Title', width: '10%' },
        { id: 'description', label: 'Description', width: '27%' },
        { id: 'submittedDate', label: 'Submitted', width: '10%' },
        { id: 'responsibility', label: 'Currently Responsible', width: '10%' },
        { id: 'dueDate', label: 'Due', width: '8%' },
        { id: 'status', label: 'Status', width: '10%' },
        { id: 'returnedDate', label: 'Returned to Contractor', width: '10%' },
        { id: 'acceptance', label: 'Contractor Acceptance', width: '10%' },
      ];
      return columnTitles;
    }

    // CLOSEOUT
    case DocumentTemplateType.AsBuiltPackages:
    case DocumentTemplateType.AsBuilt: {
      const columnTitles: HeadCell<AsBuiltData>[] = [
        { id: 'viewButton', label: 'View', width: '5%' },
        { id: 'number', label: 'Sheet #', width: '10%' },
        { id: 'title', label: 'Title', width: '30%' },
        { id: 'responsibility', label: 'Currently Responsible', width: '20%' },
        { id: 'dueDate', label: 'Due', width: '15%' },
        { id: 'status', label: 'Status', width: '20%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.CertificateOfSubstantialCompletion: {
      const columnTitles: HeadCell<SubstantialCompletionCertData>[] = [
        { id: 'number', label: 'Document #', width: '10%' },
        { id: 'number', label: 'Reference #', width: '10%' },
        { id: 'description', label: 'Description', width: '65%' },
        { id: 'postedDate', label: 'Posted', width: '15%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.ClearLp: {
      const columnTitles: HeadCell<ClearLPData>[] = [
        { id: 'number', label: 'Document #', width: '10%' },
        { id: 'number', label: 'Reference #', width: '10%' },
        { id: 'description', label: 'Description', width: '65%' },
        { id: 'postedDate', label: 'Posted', width: '15%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.CloseoutSubmittalPackages:
    case DocumentTemplateType.CloseoutSubmittals: {
      const columnTitles: HeadCell<CloseoutSubmittalData>[] = [
        { id: 'number', label: 'Submittal #', width: '10%' },
        { id: 'submittalDescription', label: 'Description', width: '20%' },
        { id: 'initialDate', label: 'Anticipated Initial Submission Date', width: '6%' },
        { id: 'actualDate', label: 'Actual Date Submitted', width: '6%' },
        { id: 'responsibility', label: 'Currently Responsible', width: '16%' },
        { id: 'dueDate', label: 'Due', width: '7%' },
        { id: 'status', label: 'Status', width: '10%' },
        { id: 'returnedDate', label: 'Returned to Contractor', width: '8%' },
        { id: 'action', label: 'Action Taken', width: '12%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.OperationsAndMaintenanceData: {
      const columnTitles: HeadCell<OperationsAndMaintenanceData>[] = [
        { id: 'number', label: 'Document #', width: '10%' },
        { id: 'number', label: 'Reference #', width: '10%' },
        { id: 'section', label: 'Specification Section', width: '20%' },
        { id: 'description', label: 'Description', width: '35%' },
        { id: 'submittedBy', label: 'Uploaded By', width: '15%' },
        { id: 'postedDate', label: 'Posted', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.PunchList: {
      const columnTitles: HeadCell<PunchListData>[] = [
        { id: 'building', label: 'Building', width: '10%', noSort: true },
        { id: 'locationId', label: 'Location', width: '10%' },
        { id: 'title', label: 'Description', width: '30%', noSort: true },
        { id: 'responsibleParty', label: 'Responsible Subcontractor', width: '10%', noSort: true },
        { id: 'status', label: 'Status', width: '10%', noSort: true },
        { id: 'cost', label: 'Cost', width: '10%' },
        { id: 'postedDate', label: 'Created', width: '10%' },
        { id: 'lastModified', label: 'Last Modified', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.WarrantyItems: {
      const columnTitles: HeadCell<WarrantyItemsData>[] = [
        { id: 'number', label: 'Item #', width: '5%' },
        { id: 'number', label: 'Reference #', width: '5%' },
        { id: 'description', label: 'Description', width: '20%' },
        { id: 'location', label: 'Location', width: '10%' },
        { id: 'submittedDate', label: 'Created', width: '10%' },
        { id: 'responsibility', label: 'Currently Responsible', width: '10%' },
        { id: 'expectedCompletionDate', label: 'Expected Completion Date', width: '10%' },
        { id: 'status', label: 'Status', width: '10%' },
        { id: 'completedDate', label: 'Submitted for Verification', width: '10%' },
        { id: 'approvedDate', label: 'Completed', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.Task: {
      const columnTitles: HeadCell<TaskData>[] = [
        { id: 'number', label: 'Item #', width: '10%' },
        { id: 'title', label: 'Title', width: '10%' },
        { id: 'description', label: 'Description', width: '10%' },
        { id: 'assignedBy', label: 'Assigned By', width: '10%' },
        { id: 'assignedTo', label: 'Assigned To', width: '10%' },
        { id: 'priority', label: 'Priority', width: '10%' },
        { id: 'dueDate', label: 'Due', width: '10%' },
        { id: 'submittedDate', label: 'Submitted', width: '10%' },
        { id: 'status', label: 'Status', width: '10%' },
        { id: 'returnedDate', label: 'Returned', width: '10%' },
        { id: 'dateOfLastUpdate', label: 'Last Updated', width: '10%' },
      ];
      return columnTitles;
    }
    case DocumentTemplateType.DesignPackages: {
      const columnTitles: HeadCell<DesignPackageData>[] = [
        { id: 'number', label: 'Item #', width: '5%' },
        { id: 'title', label: 'Title', width: '5%' },
        { id: 'description', label: 'Description', width: '13%' },
        { id: 'assignedBy', label: 'Assigned By', width: '12%' },
        { id: 'assignedTo', label: 'Assigned To', width: '15%' },
        { id: 'dueDate', label: 'Due', width: '10%' },
        { id: 'submittedDate', label: 'Submitted', width: '10%' },
        { id: 'status', label: 'Status', width: '10%' },
        { id: 'returnedDate', label: 'Returned', width: '10%' },
        { id: 'dateOfLastUpdate', label: 'Last Updated', width: '10%' },
      ];
      return columnTitles;
    }
    default:
      return [];
  }
}

export default getHeaders;
