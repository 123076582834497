import React from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Attachment } from '@material-ui/icons';
import { IReceivedEmail } from '../../api-client/autogenerated';
import { formatEmailDate } from './utils';
import { EmailLogType } from './CorrespondenceLog';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      listStyle: 'none',
      position: 'relative',
      borderBottom: 'solid 2px lightgray',
      padding: '8px 16px 8px 8px',
      '&:hover': {
        background: 'lightgray',
      },
      cursor: 'pointer',
    },
    from: {
      fontSize: '18px',
      lineHeight: '21px',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '95%',
    },
    to: {
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    subject: {
      fontWeight: 500,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    date: {
      whiteSpace: 'nowrap',
    },
    unread: {
      position: 'absolute',
      left: 1,
      top: 4,
      height: '90%',
      width: 5,
      background: '#0947B9',
    },
    attachment: {
      position: 'absolute',
      rotate: '90deg',
      right: 10,
    },
  }),
);

type Props = {
  email: IReceivedEmail;
  onClick: () => void;
  isLastItem?: boolean;
};

export default function EmailItem(props: Props) {
  const classes = useStyles();
  const { email, onClick, isLastItem } = props;

  return (
    <li onClick={onClick} className={classes.root} style={isLastItem ? { border: 'none' } : {}}>
      {email.hasAttachment ? <Attachment fontSize="small" className={classes.attachment} /> : null}
      <Typography className={classes.from}>{email.senderAddress}</Typography>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography className={classes.to}>{email.recipientAddress}</Typography>
        <Typography className={classes.date}>
          {formatEmailDate(EmailLogType.CORRESPONDENCE_LOG, email.sendDate)}
        </Typography>
      </div>

      <Typography className={classes.subject}>{email.subject}</Typography>
    </li>
  );
}
