import React from 'react';
import Box from '@material-ui/core/Box';
import '../../css/main-style.css';

interface TriangleProps {
  color?: string;
}

export default function Triangle(props: TriangleProps) {
  const { color = '' } = props;

  return (
    <Box className="triangle-container-container">
      <Box className="triangle-container">
        <Box className="triangle" style={{ background: color }} />
      </Box>
    </Box>
  );
}
