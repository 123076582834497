import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { generateUniqueId } from '../../scripts/utils';

interface BiddingTableSimpleProps {
  headers: string[];
  rows: any[][];
  emptyText?: string;
}

const getStyle = (index: number, length: number) => {
  switch (index) {
    case 0:
      return {
        maxWidth: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      };

    case length - 1:
      return {
        textAlign: 'center',
      };

    default:
      return {};
  }
};

export default function BiddingTableSimple(props: BiddingTableSimpleProps) {
  const { headers, rows, emptyText = 'Nothing to see here.' } = props;

  const getTableRows = () => {
    return rows.map((row) => {
      return (
        <TableRow key={generateUniqueId()}>
          {row.map((column, index) => {
            return (
              // @ts-ignore
              <TableCell key={generateUniqueId()} style={getStyle(index, row.length)}>
                {typeof column === 'string' ? <span title={column}>{column}</span> : column}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };
  return (
    <TableContainer component={Paper} style={{ boxShadow: 'none', overflowX: 'hidden' }}>
      {rows.length > 0 ? (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => {
                return (
                  <TableCell
                    key={generateUniqueId()}
                    style={{
                      minWidth: 140,
                      textAlign: index === headers.length - 1 ? 'center' : 'left',
                    }}
                  >
                    {header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>{getTableRows()}</TableBody>
        </Table>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 24,
            paddingBottom: 12,
          }}
        >
          <div>{emptyText}</div>
        </div>
      )}
    </TableContainer>
  );
}
