import React, { useRef, useState } from 'react';
import { Grid, Popover, TextField } from '@material-ui/core';
import { ISimplifiedProject } from '../../api-client/autogenerated';
import AdminProjects from './AdminProjects';

type DashboardContainerProps = {
  availableProjects: number | null;
  projects: ISimplifiedProject[];
  refreshSubscriber: () => void;
};

const DashboardContainer = (props: DashboardContainerProps) => {
  const { availableProjects, projects, refreshSubscriber } = props;

  const [projectName, setProjectName] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const anchor = useRef<any>(null);

  const handleProjectClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AdminProjects
            projects={projects}
            availableProjects={availableProjects}
            refreshSubscriber={refreshSubscriber}
          />
        </Grid>
      </Grid>
      <Popover
        open={isOpen}
        onClose={handleClose}
        // onEnter={() => {
        //   setIsLoading(false);
        // }}
        anchorEl={anchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ width: '360px' }}
      >
        <TextField
          variant="outlined"
          margin="none"
          size="small"
          fullWidth
          id="project-number"
          label="Project Name"
          name="Title"
          autoFocus
          value={projectName}
          style={{ width: 'calc(100% - 48px)', margin: '24px 12px' }}
          onChange={(e) => setProjectName(e.target.value)}
        />
      </Popover>
    </>
  );
};

export default DashboardContainer;
