import { Snackbar } from './snackbar';
import { ADD_SNACKBAR, REMOVE_ALL_SNACKBAR, REMOVE_SNACKBAR } from './constants';
import { action } from 'typesafe-actions';

export const addSnackbar = (snackbar: Snackbar) => {
  return action(ADD_SNACKBAR, snackbar);
};

export const removeSnackbar = (id: number) => {
  return action(REMOVE_SNACKBAR, id);
};

export const removeAllSnackbars = () => {
  return action(REMOVE_ALL_SNACKBAR);
};
