import React, { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import '../css/App.css';
import '../css/bootstrap.min.css';
import '../css/bidding.css';
import '../css/featherlight-1.7.1.min.css';
import '../css/rc-feedback.min.css';
import '../css/style.css';
import '../css/theme.css';
import Projects from './projects/Projects';
import ProjectPage from './projects/ProjectPage';
import BiddingPortalLogin from './BiddingPortalLogin';
import BiddingPortalRegister from './BiddingPortalRegister';
import PrivateRoute from '../main-components/PrivateRoute';
import BiddingNavDrawer from './bidding-drawer/BiddingNavDrawer';
import { IBidSetup } from '../api-client/autogenerated';
import getCustomTheme from '../main-components/custom-components/customTheme';
import SettingsPage from '../main-components/form-pages/SettingsPage';
import BiddingPDFViewer from './BiddingPDFViewer';
import GlobalSnackbar from '../main-components/GlobalSnackbar';
import UnsubscribePage from './unsubscribe/UnsubscribePage';
import LockedBidPage from './projects/LockedBidPage';
import GlobalNavigationBlocker from '../main-components/GlobalNavigationBlocker';

function BiddingPortal() {
  const { path } = useRouteMatch();
  const theme = getCustomTheme();

  const [currentProject, setCurrentProject] = useState<IBidSetup | undefined>(undefined);
  const [projectInfoType, setProjectInfoType] = useState<string>('');

  const handleProjectInfoType = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    type: string,
  ) => {
    setProjectInfoType(type);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className="bidding" style={{ minHeight: '100vh', display: 'flex' }}>
        <CssBaseline />
        <BiddingNavDrawer
          projectInfoType={projectInfoType}
          handleProjectInfoType={handleProjectInfoType}
          setProjectInfoType={setProjectInfoType}
          currentProject={currentProject}
          setCurrentProject={setCurrentProject}
        />
        <div style={{ width: '100%', overflow: 'hidden' }}>
          <Switch>
            <Route path={`${path}/login`}>
              <BiddingPortalLogin />
            </Route>
            <Route path={`${path}/register`}>
              <BiddingPortalRegister />
            </Route>
            {/*<Route path={`${path}/setcompany`}>*/}
            {/*  <SelectCompanyPage />*/}
            {/*</Route>*/}
            <PrivateRoute path={`${path}/account`} redirect={`${path}/login`}>
              <SettingsPage isBiddingPortal />
            </PrivateRoute>
            <PrivateRoute path={`${path}/projects`} redirect={`${path}/login`}>
              <Projects />
            </PrivateRoute>
            <Route path={`${path}/pdf/:fileId`}>
              <BiddingPDFViewer />
            </Route>
            <PrivateRoute path={`${path}/locked`} redirect={`${path}/login`}>
              <LockedBidPage />
            </PrivateRoute>
            <PrivateRoute exact path={`${path}/:id`} redirect={`${path}/login`}>
              {(props) => (
                <ProjectPage
                  {...props}
                  setCurrentProject={setCurrentProject}
                  setProjectInfoType={setProjectInfoType}
                  projectInfoType={projectInfoType}
                />
              )}
            </PrivateRoute>
            <Route path={`${path}/:id/unsubscribe`}>
              <UnsubscribePage />
            </Route>
            <PrivateRoute path={`${path}`} redirect={`${path}/login`}>
              <Projects />
            </PrivateRoute>
          </Switch>
        </div>
      </div>
      <GlobalSnackbar />
      <GlobalNavigationBlocker />
    </MuiThemeProvider>
  );
}

export default BiddingPortal;
