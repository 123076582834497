import { ApiClient } from '../../api-client/api-client';
import { InsertBidProps } from '../../api-client/autogenerated';

export const getBidById = async (bidId: string) => {
  return ApiClient.getBidById({ bidId }).then((res) => res.data);
};

export const insertBid = async (
  userId: string,
  projectId: string,
  insertBidProps: InsertBidProps,
) => {
  const response = await ApiClient.insertBidByUserAndProject({
    userId,
    projectId,
    insertBidProps,
  });
  return response.data;
};

export const retractBid = async (bidId: string) => {
  return ApiClient.retractBidById({ bidId }).then((res) => res.data);
};

export const unsubscribePlanholder = async (userId: string, projectId: string) => {
  return ApiClient.unsubscribePlanholderByProjectIdAndUserId({ projectId, userId }).then(
    (res) => res.data,
  );
};
