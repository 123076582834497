// @ts-nocheck
import React from 'react';
import { Transition } from 'react-transition-group';
import AdminHome from './admin-home';
import ApplicationAdmins from './application-admins';
import Packages from './packages';
import Subscriptions from './subscriptions';
import Announcements from './announcements';
import Banner from './banner';
import Sidebar from './sidebar';
import { getAnnouncements } from '../models/api/announcements';
import SimpleUI from './admin-simpleui';

class AdminMainApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: <AdminHome mainApp={this} key="home" />,
      selected: 'Home',
      announcements: [],
    };
    this.bannerRef = React.createRef();
    this.sidebarRef = React.createRef();
    this.sidebar = <Sidebar mainApp={this} ref={this.sidebarRef} />;
    this.updateFunction = () => {};
    this.closeSidebar = (e) => {
      e.preventDefault();
      this.sidebarRef.current.closeSidebar();
    };
  }

  componentDidMount() {
    this.fetchAnnouncements().then((result) => {
      this.setState({ announcements: result });
    });
  }

  setContent(name) {
    switch (name) {
      case 'application-admins':
        this.setState({
          content: <ApplicationAdmins mainApp={this} key="admin" />,
          selected: 'Administrators',
        });
        break;
      case 'announcements':
        this.setState((prevState) => ({
          content: (
            <Announcements
              mainApp={this}
              key="announcements"
              announcements={prevState.announcements}
            />
          ),
          selected: 'Announcements',
        }));
        break;
      case 'packages':
        this.setState({
          content: <Packages mainApp={this} key="packages" />,
          selected: 'Packages',
        });
        break;
      case 'subscriptions':
        this.setState({
          content: <Subscriptions mainApp={this} key="subscriptions" />,
          selected: 'Subscriptions',
        });
        break;
      default:
        this.setState({ content: <AdminHome mainApp={this} key="home" />, selected: 'Home' });
    }
    this.updateFunction();
  }

  fetchAnnouncements = async () => {
    return getAnnouncements(1000);
  };

  render() {
    const { content, selected } = this.state;

    return (
      <>
        <div
          className="home-flex-box"
          role="button"
          tabIndex={0}
          onKeyDown={(e) => this.closeSidebar(e)}
          onClick={(e) => this.closeSidebar(e)}
        >
          <Banner currentPage={selected} />
          <Transition
            transitionName="view-transition"
            transitionEnterTimeout={400}
            transitionLeaveTimeout={400}
            timeout={1000}
          >
            {content}
          </Transition>
        </div>
        {this.sidebar}
        <SimpleUI />
      </>
    );
  }
}

export default AdminMainApp;
