import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { HighlightOffRounded } from '@material-ui/icons';
import {
  DesignActionTakenType,
  FileNode,
  FileNodeTypeEnum,
  IDesignFileAction,
} from '../../api-client/autogenerated';
import { getDesignFileActions } from '../../models/api/filesystem';
import List from '@material-ui/core/List';
import FileLogItem from './FileLogItem';
import SecondaryDateText from '../lists/SecondaryDateText';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowRightIcon from '@material-ui/icons/ArrowForward';
import CircularLoader from '../loader/CircularLoader';
import { useParams } from 'react-router';
import { ProjectMatchParams } from '../../scripts/utils';

interface Props {
  open: boolean;
  handleClose: () => void;
  file: FileNode;
  isRecycleBin?: boolean;
}

const useStyles = makeStyles({
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'none',
  },
  header: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#000',
    textTransform: 'uppercase',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px 8px 16px',
    borderBottom: '3px solid #EDECEC',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  scrollbar: {
    '&::-webkit-scrollbar': {
      width: '.66em',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: '10px',
      marginTop: 24,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.25)',
      borderRadius: '10px',
      marginTop: 24,
    },
  },
  '.MuiDialogContent-root': {
    paddingRight: 12,
    paddingLeft: 12,
    paddingBottom: 32,
    paddingTop: 0,
  },
  row: {
    height: '3.8em',
    inlineSize: 512,
    overflowWrap: 'break-word',
    overflow: 'hidden',
    borderBottom: '2px solid #EDECEC',
    fontSize: 15,
    textTransform: 'none',
    flexDirection: 'row',
    display: 'flex',
    marginBottom: 8,
    width: 600,
  },
  text: { flex: 3, marginRight: 32, fontWeight: 400, marginLeft: 8 },
  date: {
    flex: 1,
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'end',
    alignSelf: 'flex-end',
    color: '#7A797A',
    paddingBottom: 8,
    marginRight: 4,
  },
});

const PAGE_SIZE = 10;

const designFileActionCreationTypes = [
  DesignActionTakenType.CreatedFolder,
  DesignActionTakenType.UploadedFile,
];

export default function FileLogDialog(props: Props) {
  const { open, handleClose, file, isRecycleBin } = props;
  const classes = useStyles();

  const { projectId } = useParams<ProjectMatchParams>();

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [actions, setActions] = useState<IDesignFileAction[]>([]);
  const [creationAction, setCreationAction] = useState<IDesignFileAction>();
  const filteredActions = actions.filter(
    (a) => !designFileActionCreationTypes.includes(a.actionTaken),
  );

  const isFolder = file.type === FileNodeTypeEnum.Folder;

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      const fullKey = isRecycleBin
        ? `/${file.fullKey.split('/').splice(3).join('/')}`
        : file.fullKey;
      getDesignFileActions(projectId, fullKey, file.ownerId, page, PAGE_SIZE)
        .then(({ actions: { results, totalPages }, creationAction }) => {
          setActions(results);
          setTotalPages(totalPages);
          if (creationAction) setCreationAction(creationAction);
        })
        .finally(() => setIsLoading(false));
    }
  }, [projectId, file.fullKey, open, page]);

  const getHeader = () => {
    if (creationAction) {
      const { initiatorUser, createdOn, actionTaken } = creationAction;
      const actionText =
        actionTaken === DesignActionTakenType.CreatedFolder
          ? 'created this folder'
          : 'uploaded this file';
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            whiteSpace: 'nowrap',
            marginTop: 8,
          }}
        >
          <Typography
            className={classes.header}
          >{`${initiatorUser?.name}, ${initiatorUser?.company?.name}, ${actionText}`}</Typography>
          <SecondaryDateText noPadding date={createdOn!} />
        </div>
      );
    }
    return null;
  };

  const formattedName = file.relativeKey?.endsWith('/')
    ? file.relativeKey.slice(0, -1)
    : file.relativeKey;

  const handlePrev = () => {
    setPage((prev) => prev - 1);
  };

  const handleNext = () => {
    setPage((prev) => prev + 1);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { minWidth: 720 } }}
        maxWidth="sm"
      >
        <DialogTitle className={classes.titleContainer}>
          <Typography className={classes.title} align="left">
            Activity Log - {formattedName}
          </Typography>
          {getHeader()}
          <IconButton
            style={{ right: '10px', top: '10px', position: 'absolute' }}
            classes={{
              root: classes.rootIconButton,
            }}
            onClick={handleClose}
          >
            <HighlightOffRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '8px 16px' }}>
          {!isLoading ? (
            <List>
              {filteredActions.map((a, index, array) => {
                return (
                  <>
                    <FileLogItem key={a.id} designFileAction={a} isFolder={isFolder} />
                    {index !== array.length - 1 && <Divider />}
                  </>
                );
              })}
              {filteredActions.length === 0 && (
                <Typography>
                  There are no recorded activity entries or notifications for this{' '}
                  {isFolder ? 'folder' : 'file'}.
                </Typography>
              )}
            </List>
          ) : (
            <CircularLoader />
          )}
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          {totalPages > 1 && (
            <Button onClick={handlePrev} disabled={page === 0}>
              <ArrowBackIcon />
            </Button>
          )}
          {totalPages > 1 && (
            <Typography>
              {page + 1}/{totalPages}
            </Typography>
          )}
          {totalPages > 1 && (
            <Button onClick={handleNext} disabled={page + 1 === totalPages}>
              <ArrowRightIcon />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
