import { ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { SET_PRODUCT_PACKAGE, UPDATE_PRODUCT_PACKAGE } from './constants';
import * as productPackageActions from './actions';
import { ProductPackage } from './product-package';

export type ProductPackageAction = ActionType<typeof productPackageActions>;
export type ProductPackageState = { productPackage: ProductPackage };

export default combineReducers<ProductPackageState, ProductPackageAction>({
  productPackage: (state = null, action) => {
    let newState: ProductPackage = null;
    switch (action.type) {
      case SET_PRODUCT_PACKAGE:
        newState = action.payload;
        break;
      case UPDATE_PRODUCT_PACKAGE:
        if (state) newState = { ...state, ...action.payload };
        break;
      default:
        newState = state;
    }
    return newState;
  },
});
