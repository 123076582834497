import { IComment, IInsertionComment } from '../../api-client/autogenerated';
import { ApiClient } from '../../api-client/api-client';

export const getCommentsByDocumentId = async (documentId: string): Promise<IComment[]> => {
  const response = await ApiClient.getCommentsOnDocumentById({ documentId });
  return response.data.results;
};

export const getCommentsByProjectId = async (
  projectId: string,
  pageSize = 50,
): Promise<IComment[]> => {
  const response = await ApiClient.getCommentsOnProjectById({
    projectId,
    pageSize,
    orderByColumn: 'createdOn',
    orderByDirection: 'desc',
  });
  return response.data.results;
};

export const getCommentById = async (commentId: string): Promise<IComment> => {
  return ApiClient.getCommentById({ commentId }).then((res) => res.data);
};

export const insertCommentByDocumentId = async (documentId: string, comment: IInsertionComment) => {
  return (
    await ApiClient.insertCommentByDocumentId({
      documentId,
      iInsertionComment: {
        creatorUserId: comment.creatorUserId,
        text: comment.text,
        lastUpdatedByUserId: comment.creatorUserId,
      },
    })
  ).data;
};

export const insertCommentByProjectId = async (projectId: string, comment: IInsertionComment) => {
  return ApiClient.insertCommentByProjectId({
    projectId,
    iInsertionComment: comment,
  }).then((res) => res.data);
};

export const insertConsultantCommentsOnDocumentById = async (
  parentDocumentId: string,
  comment: IInsertionComment,
) => {
  return (
    await ApiClient.insertConsultantConfidentialCommentByDocumentId({
      parentDocumentId,
      iInsertionComment: {
        creatorUserId: comment.creatorUserId,
        text: comment.text,
        lastUpdatedByUserId: comment.creatorUserId,
      },
    })
  ).data;
};
