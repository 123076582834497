import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link, { LinkProps } from '@material-ui/core/Link';
import { ArrowForward } from '@material-ui/icons';

const useStyles = makeStyles({
  link: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
});

export default function SeeAll(props: LinkProps) {
  const classes = useStyles();

  return (
    <Link {...props} className={classes.link}>
      See All <ArrowForward fontSize="small" />
    </Link>
  );
}
