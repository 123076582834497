import { ActionType } from 'typesafe-actions';
import { Groups } from './groups';
import { combineReducers } from 'redux';
import * as groupsActions from './actions';
import { DELETE_GROUP, SET_GROUPS, UPSERT_GROUP } from './constants';

export type GroupsAction = ActionType<typeof groupsActions>;
export type GroupsState = {
  groups: Groups;
};

export default combineReducers<GroupsState, GroupsAction>({
  groups: (state = [], action) => {
    let newState: Groups;
    switch (action.type) {
      case SET_GROUPS:
        newState = action.payload;
        break;

      case UPSERT_GROUP:
        const index = state.findIndex(({ id }) => id === action.payload.id);
        if (index !== -1)
          newState = [...state.slice(0, index), action.payload, ...state.slice(index + 1)];
        else newState = [...state, action.payload];
        break;

      case DELETE_GROUP:
        newState = state.filter((group) => group.id !== action.payload.id);
        break;

      default:
        newState = state;
        break;
    }
    return newState;
  },
});
