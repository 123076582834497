import { getStates } from './countries';
import { IAddress, IInsertionAddress } from '../../api-client/autogenerated';
import { ApiClient } from '../../api-client/api-client';

// eslint-disable-next-line import/prefer-default-export
export const insertAddress = async (address: IInsertionAddress): Promise<IAddress> => {
  const states = await getStates();
  const response = await ApiClient.insertAddress({
    iInsertionAddress: { ...address, stateOrProvinceId: states[address.stateOrProvinceId!] },
  });
  return response.data;
};
