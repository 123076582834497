import React, { MutableRefObject, SyntheticEvent, useEffect, useRef, useState } from 'react';
import {
  ItemCoordinates,
  PunchListStatusType,
  RegionCoordinates,
} from '../../api-client/autogenerated';
import PunchListFlagIcon from '../icons/PunchListFlag-icon';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';

export interface PunchListMarker {
  id?: string;
  marker: ItemCoordinates;
  status?: PunchListStatusType;
  locationId?: string;
}

export interface LocationRegion {
  id: string;
  region: RegionCoordinates;
}

type Props = {
  imageProps: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  secondaryImageRef?: MutableRefObject<HTMLImageElement | null>;
  markers: PunchListMarker[];
  regions?: LocationRegion[];
  regionOnClick?: (id: string) => void;
  smallFlags?: boolean;
  onNavigation?: () => void;
  containerTop?: number;
  containerLeft?: number;
  fixedWidth?: number;
};

const FLAG_ORIGINAL_HEIGHT = 37;
const FLAG_ORIGINAL_WIDTH = 25;

function isClickWithinRegion(x: number, y: number, region: RegionCoordinates, width: number) {
  const s = (region.scale / region.width) * width;
  const regionX = region.x * s;
  const regionY = region.y * s;
  const regionW = region.W * s;
  const regionH = region.H * s;
  return x >= regionX && x <= regionX + regionW && y >= regionY && y <= regionY + regionH;
}

export function getColorFromPunchListStatus(status?: PunchListStatusType) {
  if (!status) return 'red';
  switch (status) {
    case PunchListStatusType.New:
    case PunchListStatusType.InProgress:
      return 'red';

    case PunchListStatusType.ReadyForVerification:
      return 'blue';

    default:
    case PunchListStatusType.Accepted:
    case PunchListStatusType.Rejected:
      return 'green';
  }
}

export default function ImageWithMarkers(props: Props) {
  const {
    imageProps,
    secondaryImageRef,
    markers,
    regions,
    smallFlags,
    regionOnClick,
    onNavigation,
    containerTop,
    containerLeft,
  } = props;

  const history = useHistory();

  const imageRef = useRef<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);

  const [parentLeftPadding, setParentLeftPadding] = useState(0);
  const [parentTopPadding, setParentTopPadding] = useState(0);

  useEffect(() => {
    if (imageRef.current?.offsetParent) {
      const styles = window.getComputedStyle(imageRef.current.offsetParent);
      setParentLeftPadding(parseInt(styles.paddingLeft));
      setParentTopPadding(parseInt(styles.paddingTop));
    }
  }, []);

  useEffect(() => {
    if (imageRef.current) {
      console.log(`canvasWidth: ${imageRef.current?.clientWidth}`);
      setCanvasWidth(imageRef.current.clientWidth);
      setCanvasHeight(imageRef.current.clientHeight);
    }
  });

  const canvasOnClick = (event: MouseEvent) => {
    // @ts-ignore
    const { layerX: eventX, layerY: eventY } = event;
    if (regions) {
      regions.forEach(({ id, region }) => {
        if (isClickWithinRegion(eventX, eventY, region, canvasWidth)) {
          if (regionOnClick) regionOnClick(id);
        }
      });
    }
  };

  useEffect(() => {
    if (canvasRef.current && canvasWidth) {
      canvasRef.current.addEventListener('click', canvasOnClick, false);
    }
  }, [canvasRef.current, canvasWidth]);

  const FLAG_HEIGHT = smallFlags ? 26 : FLAG_ORIGINAL_HEIGHT;
  const FLAG_WIDTH = smallFlags ? 18 : FLAG_ORIGINAL_WIDTH;

  const onLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    if (imageProps.onLoad) imageProps.onLoad(e);
    else {
      const target = e.target as HTMLImageElement;
      setCanvasWidth(target.width);
      setCanvasHeight(target.height);
    }
  };

  useEffect(() => {
    if (!canvasRef.current) return;
    canvasRef.current.getContext('2d')!.clearRect(0, 0, canvasWidth, canvasHeight);
    if (regions && canvasWidth && canvasHeight) {
      const context = canvasRef.current.getContext('2d')!;
      context.strokeStyle = '#2BB073';
      context.lineWidth = 2;
      regions.forEach(({ region }) => {
        const s = (region.scale / region.width) * canvasWidth;
        context.strokeRect(region.x * s, region.y * s, region.W * s, region.H * s);
      });
    }
  }, [regions, canvasWidth, canvasHeight]);

  const handleNavigate = (id: string) => {
    if (onNavigation) onNavigation();
    history.push(`punch-list/${id}/edit`);
  };

  const getMarkerTop = (marker: PunchListMarker) => {
    const location = regions?.find((r) => r.id === marker.locationId);
    const factor = location
      ? location.region.H * ((location.region.scale / location.region.width) * canvasWidth)
      : canvasHeight ?? 1;
    const locationTop = location
      ? location.region.y * ((location.region.scale / location.region.width) * canvasWidth)
      : 0;
    const value =
      marker.marker.y * factor +
      parentTopPadding +
      (FLAG_ORIGINAL_HEIGHT - FLAG_HEIGHT) +
      (containerTop || 0) +
      (locationTop || 0);
    return value;
  };

  const getMarkerLeft = (marker: PunchListMarker) => {
    const location = regions?.find((r) => r.id === marker.locationId);
    const factor = location
      ? location.region.W * ((location.region.scale / location.region.width) * canvasWidth)
      : canvasWidth ?? 1;
    const locationLeft = location
      ? location.region.x * ((location.region.scale / location.region.width) * canvasWidth)
      : 0;
    const value =
      marker.marker.x * factor + parentLeftPadding + (containerLeft || 0) + (locationLeft || 0);
    return value;
  };

  return (
    <>
      <img
        {...imageProps}
        ref={(node) => {
          imageRef.current = node;
          if (secondaryImageRef) secondaryImageRef.current = node;
        }}
        onLoad={onLoad}
        alt="marked location"
        width={props.fixedWidth ?? undefined}
      />
      <canvas
        ref={canvasRef}
        width={canvasWidth}
        height={canvasHeight}
        style={{
          position: 'absolute',
          top: imageRef.current?.offsetTop,
          left: imageRef.current?.offsetLeft,
        }}
      />
      {markers.map((marker, index) => (
        <div
          key={`${marker.marker.x}-${marker.marker.y}-${marker.id || index}`}
          style={{
            position: 'absolute',
            top: getMarkerTop(marker),
            left: getMarkerLeft(marker),
          }}
        >
          <IconButton
            onClick={() => {
              if (marker.id) handleNavigate(marker.id);
            }}
            style={{ padding: 0, cursor: marker.id ? 'pointer' : 'initial' }}
          >
            <PunchListFlagIcon
              transformed
              color={getColorFromPunchListStatus(marker.status)}
              width={FLAG_WIDTH}
              height={FLAG_HEIGHT}
            />
          </IconButton>
        </div>
      ))}
    </>
  );
}
