import { combineReducers } from 'redux';

import userReducer from '../features/user/reducer';
import loadingReducer from '../features/loading/reducer';
import projectReducer from '../features/project/reducer';
import documentReducer from '../features/document/reducer';
import documentsReducer from '../features/documents/reducer';
import projectsReducer from '../features/projects/reducer';
import securityReducer from '../features/security/reducer';
import bidReducer from '../features/bid/reducer';
import snackbarReducer from '../features/snackbar/reducer';
import navigationReducer from '../features/navigation/reducer';
import companiesReducer from '../features/companies/reducer';
import groupsReducer from '../features/groups/reducer';
import productPackageReducer from '../features/product-package/reducer';

const rootReducer = () =>
  combineReducers({
    user: userReducer,
    loading: loadingReducer,
    project: projectReducer,
    document: documentReducer,
    documents: documentsReducer,
    projects: projectsReducer,
    security: securityReducer,
    bid: bidReducer,
    snackbar: snackbarReducer,
    navigation: navigationReducer,
    companies: companiesReducer,
    groups: groupsReducer,
    productPackage: productPackageReducer,
  });
export default rootReducer;
