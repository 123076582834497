import { ApiClient } from '../../api-client/api-client';
import { CalendarEventType } from '../../api-client/autogenerated';

export type ProjectCalendarEvent = {
  name: string;
  description?: string | null;
  startTimestamp: string;
  endTimestamp: string;
  remindDaysBefore?: number | null;
  location?: string | null;
  type: CalendarEventType;
};

export const getAllProjectCalendarEvents = async (projectId: string, pageSize = 1000) => {
  return ApiClient.getAllProjectCalendarEventsByProjectId({ projectId, pageSize }).then(
    (res) => res.data.results,
  );
};

export const getProjectCalendarEventsBetweenDates = async (
  projectId: string,
  afterDate: string,
  beforeDate: string,
) => {
  return ApiClient.getAllProjectCalendarEventsBetweenDatesByProjectId({
    projectId,
    afterDate,
    beforeDate,
  }).then((res) => res.data);
};

export const insertProjectCalendarEvent = async (
  projectId: string,
  event: ProjectCalendarEvent,
) => {
  return ApiClient.insertProjectCalendarEvent({
    iInsertionProjectCalendarEvent: { projectId, ...event },
  });
};

export const insertMultipleProjectCalendarEvents = async (
  projectId: string,
  events: ProjectCalendarEvent[],
) => {
  return Promise.all(events.map((event) => insertProjectCalendarEvent(projectId, event)));
};
