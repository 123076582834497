import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import { parseDate } from '../../scripts/utils';

const MILLISECONDS_IN_A_MINUTE = 60000;
const MILLISECONDS_IN_AN_HOUR = 3600000;

type Props = {
  date: string;
  today?: boolean;
  noPadding?: boolean;
};

const useStyles = makeStyles<undefined, Props>(() =>
  createStyles({
    root: {
      fontSize: 12,
      paddingTop: ({ noPadding }) => (noPadding ? 0 : 4),
    },
  }),
);

export default function SecondaryDateText(props: Props) {
  const classes = useStyles(props);
  const { date, today } = props;

  const getTimeDifference = () => {
    const todayDateTime = dayjs().valueOf();
    const notificationDate = parseDate(date).valueOf();
    const timeDiff = todayDateTime - notificationDate;
    if (timeDiff < MILLISECONDS_IN_AN_HOUR) {
      return `${Math.round(timeDiff / MILLISECONDS_IN_A_MINUTE)} min`;
    }
    return `${Math.floor(timeDiff / MILLISECONDS_IN_AN_HOUR)} hr`;
  };

  return (
    <Typography color="textSecondary" className={classes.root}>
      <b>{!today && parseDate(date).format('M/D/YYYY ')}</b>
      {today ? getTimeDifference() : parseDate(date).format('@ h:mmA')}
    </Typography>
  );
}
