import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getGroupsState } from '../../../features/groups/selector';
import { getAllProjectUsersState } from '../../../features/project/selectors';
import { Typography } from '@material-ui/core';
import { ICompany, IUser } from '../../../api-client/autogenerated';
import { getFilteredUsersFromUserGroup } from '../../../scripts/utils';
import { getCompaniesState } from '../../../features/companies/selector';
import { ManagePermissionsDialogType } from '../../design/ManagePermissionsDialog';

type Props = {
  associatedUsers: string[];
  associatedGroups: string[];
};

interface CompanyWithUsers {
  company?: ICompany;
  users: IUser[];
}

export default function InlineNotificationList(props: Props) {
  const { associatedUsers, associatedGroups } = props;

  const groups = useSelector(getGroupsState);
  const companies = useSelector(getCompaniesState);
  const projectUsers = useSelector(getAllProjectUsersState);

  const [notifiedUsers, setNotifiedUsers] = useState<CompanyWithUsers[]>([]);
  useEffect(() => {
    const tempUsers: IUser[] = [];
    const tempCompaniesWithUsers: CompanyWithUsers[] = [];
    associatedGroups.forEach((groupId) => {
      const group = groups.find((g) => g.id === groupId);
      tempUsers.push(
        ...getFilteredUsersFromUserGroup(ManagePermissionsDialogType.AssociatedUsers, group),
      );
    });
    associatedUsers.forEach((userId) => {
      const user = projectUsers.find((pUser) => pUser.userId === userId)?.user;
      if (user) tempUsers.push(user);
    });
    tempUsers.forEach((user) => {
      const existingCompanyIndex = tempCompaniesWithUsers.findIndex(
        (n) => n.company?.id === user.companyId,
      );
      if (existingCompanyIndex !== -1)
        tempCompaniesWithUsers[existingCompanyIndex].users.push(user);
      else
        tempCompaniesWithUsers.push({
          company: companies.find((c) => c.id === user.companyId),
          users: [user],
        });
    });
    setNotifiedUsers(tempCompaniesWithUsers);
  }, [associatedUsers, associatedGroups]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {/*<Typography>Notification List:</Typography>*/}
      {notifiedUsers.map(({ company, users }, index) => (
        <div
          key={company?.id || index}
          style={{
            display: 'inline-flex',
            flexDirection: 'column',
            marginBottom: '4px',
          }}
        >
          <Typography style={{ fontSize: 16, fontWeight: 700 }}>
            {company?.name || 'Unknown Company'}
          </Typography>
          {users.map((user) => (
            <Typography key={user.id} style={{ fontSize: 12 }}>
              {user.name}
            </Typography>
          ))}
        </div>
      ))}
    </div>
  );
}
