import React, { useEffect, useState } from 'react';
import { Checkbox, MenuItem, Popover, Select, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import dayjs, { Dayjs } from 'dayjs';
import { CancelButton } from '../custom-components/CustomButtons';
import { EmailLogType } from './CorrespondenceLog';
import { useSelector } from 'react-redux';
import getProjectsState from '../../features/projects/selectors';

const useStyles = makeStyles(() =>
  createStyles({
    filterRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 8,
    },
    fieldIcon: {
      paddingRight: 0,
    },
  }),
);

type EmailFilters = {
  filterTo: string;
  filterFrom: string;
  filterSubject: string;
  filterHasAttachment: boolean;
  filterFromDate: Dayjs | null;
  filterToDate: Dayjs | null;
  filterIsUnread: boolean;
};

type Props = {
  open: boolean;
  close: () => void;
  type: EmailLogType;
  submit: (filters: EmailFilters) => void;
  filters: EmailFilters;
  anchor: any;
};

export default function EmailFilterDialog(props: Props) {
  const classes = useStyles();
  const {
    open,
    close,
    type,
    submit,
    anchor,
    filters: {
      filterFrom,
      filterTo,
      filterSubject,
      filterHasAttachment,
      filterFromDate,
      filterToDate,
      filterIsUnread,
    },
  } = props;

  const [tempFilterFrom, setTempFilterFrom] = useState(filterFrom);
  const [tempFilterTo, setTempFilterTo] = useState(filterTo);
  const [tempFilterSubject, setTempFilterSubject] = useState(filterSubject);
  const [tempFilterHasAttachment, setTempFilterHasAttachment] = useState(filterHasAttachment);
  const [tempFilterFromDate, setTempFilterFromDate] = useState<Dayjs | null>(filterFromDate);
  const [tempFilterToDate, setTempFilterToDate] = useState<Dayjs | null>(filterToDate);

  const [tempFilterIsUnread, setTempFilterIsUnread] = useState(filterIsUnread);

  useEffect(() => {
    if (open) {
      setTempFilterFrom(filterFrom);
      setTempFilterTo(filterTo);
      setTempFilterSubject(filterSubject);
      setTempFilterHasAttachment(filterHasAttachment);
      setTempFilterFromDate(filterFromDate);
      setTempFilterToDate(filterToDate);
      setTempFilterIsUnread(filterIsUnread);
    }
  }, [open]);

  const clearFilters = () => {
    setTempFilterFrom('');
    setTempFilterTo('');
    setTempFilterSubject('');
    setTempFilterHasAttachment(false);
    setTempFilterFromDate(null);
    setTempFilterToDate(null);
    setTempFilterIsUnread(false);
  };

  const handleSubmit = () => {
    submit({
      filterFrom: tempFilterFrom,
      filterTo: tempFilterTo,
      filterSubject: tempFilterSubject,
      filterHasAttachment: tempFilterHasAttachment,
      filterFromDate: tempFilterFromDate,
      filterToDate: tempFilterToDate,
      filterIsUnread: tempFilterIsUnread,
    });
    close();
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <Popover
        open={open}
        onClose={close}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ marginTop: 4 }}
      >
        <div style={{ padding: 16, minWidth: 350 }}>
          {type === EmailLogType.CORRESPONDENCE_LOG ? (
            <>
              <div className={classes.filterRow}>
                <Typography>Sender</Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  value={tempFilterFrom}
                  onChange={(e) => setTempFilterFrom(e.target.value)}
                  style={{ width: 200 }}
                />
              </div>
              <div className={classes.filterRow}>
                <Typography>Recipient</Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  value={tempFilterTo}
                  onChange={(e) => setTempFilterTo(e.target.value)}
                  style={{ width: 200 }}
                />
              </div>
              <div className={classes.filterRow}>
                <Typography>Subject</Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  value={tempFilterSubject}
                  onChange={(e) => setTempFilterSubject(e.target.value)}
                  style={{ width: 200 }}
                />
              </div>
            </>
          ) : null}

          {type === EmailLogType.CORRESPONDENCE_LOG ? (
            <div className={classes.filterRow}>
              <Typography>Attachment?</Typography>
              <Checkbox
                checked={tempFilterHasAttachment}
                onChange={(_, checked) => setTempFilterHasAttachment(checked)}
              />
            </div>
          ) : (
            <div className={classes.filterRow}>
              <Typography>Unread?</Typography>
              <Checkbox
                checked={tempFilterIsUnread}
                onChange={(_, checked) => setTempFilterIsUnread(checked)}
              />
            </div>
          )}
          <div className={classes.filterRow}>
            <Typography>Date</Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: 200,
              }}
            >
              <Typography>From</Typography>
              <KeyboardDatePicker
                inputVariant="outlined"
                size="small"
                format="MM/DD/YYYY"
                InputProps={{ classes: { adornedEnd: classes.fieldIcon } }}
                onChange={(date) => setTempFilterFromDate(date)}
                value={tempFilterFromDate}
                style={{ width: 150 }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: 200,
              }}
            >
              <Typography>To</Typography>
              <KeyboardDatePicker
                inputVariant="outlined"
                size="small"
                format="MM/DD/YYYY"
                InputProps={{ classes: { adornedEnd: classes.fieldIcon } }}
                onChange={(date) => setTempFilterToDate(date)}
                value={tempFilterToDate}
                style={{ width: 150 }}
              />
            </div>
          </div>
          <div className={classes.filterRow} style={{ marginTop: 16 }}>
            <CancelButton startIcon={undefined} onClick={clearFilters} style={{ width: 120 }}>
              Clear
            </CancelButton>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              style={{ width: 120 }}
            >
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    </MuiPickersUtilsProvider>
  );
}
