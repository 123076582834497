import React, { useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import {
  MenuItem,
  Select,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DashboardBlock from './DashboardBlock';
import { FormatListBulletedOutlined, Search } from '@material-ui/icons';
import UserActivityDialog from '../dialogs/UserActivityDialog';
import InputAdornment from '@material-ui/core/InputAdornment';
import { sortLastNamesDesc } from '../../scripts/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

export type UserRow = {
  id: string;
  name: string;
  company: string;
  email: string;
  projects: string[];
  disabled: boolean | null | undefined;
  isRegistered: boolean | null | undefined;
};

type Props = {
  rows: UserRow[];
};

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      fontSize: '14px',
    },
    icon: {
      fill: '#B2B1B2',
    },
    sortIcon: {
      display: 'flex',
      flexDirection: 'column',
      height: '22px',
      paddingTop: '1px',
      marginLeft: '8px',
    },
    sortIconEnabled: {
      color: '#616061',
    },
    sortIconDisabled: {
      color: '#B2B1B2',
    },
    disableDefaultSortIcon: {
      display: 'none',
    },
  }),
);

export default function UsersTable(props: Props) {
  const classes = useStyles();
  const { rows } = props;

  const [searchInput, setSearchInput] = useState<string>();
  const [orderBy, setOrderBy] = useState<'name' | 'company'>('name');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [selectedUser, setSelectedUser] = useState<UserRow>();
  const [userActivityDialogOpen, setUserActivityDialogOpen] = useState(false);

  const handleSort = (newOrderBy: 'name' | 'company') => {
    if (newOrderBy === orderBy) {
      setOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setOrderBy(newOrderBy);
      setOrder('desc');
    }
  };

  return (
    <DashboardBlock title="Users">
      <TextField
        size="small"
        variant="outlined"
        placeholder="Search Users"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search
                fontSize="small"
                classes={{
                  root: classes.icon,
                }}
              />
            </InputAdornment>
          ),
        }}
        style={{ margin: '8px 0px 8px 8px' }}
      />
      <TableContainer component={Paper} style={{ overflowX: 'visible' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  onClick={() => handleSort('name')}
                  classes={{ icon: classes.disableDefaultSortIcon }}
                >
                  Name
                  <div className={classes.sortIcon}>
                    <FontAwesomeIcon
                      icon={faCaretUp}
                      style={{ marginBottom: '-3px' }}
                      className={
                        orderBy === 'name' ? classes.sortIconEnabled : classes.sortIconDisabled
                      }
                    />
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      style={{ marginTop: '-3px' }}
                      className={
                        orderBy === 'name' ? classes.sortIconEnabled : classes.sortIconDisabled
                      }
                    />
                  </div>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  onClick={() => handleSort('company')}
                  classes={{ icon: classes.disableDefaultSortIcon }}
                >
                  Company
                  <div className={classes.sortIcon}>
                    <FontAwesomeIcon
                      icon={faCaretUp}
                      style={{ marginBottom: '-3px' }}
                      className={
                        orderBy === 'company' ? classes.sortIconEnabled : classes.sortIconDisabled
                      }
                    />
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      style={{ marginTop: '-3px' }}
                      className={
                        orderBy === 'company' ? classes.sortIconEnabled : classes.sortIconDisabled
                      }
                    />
                  </div>
                </TableSortLabel>
              </TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Projects</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .filter((row) => {
                if (!searchInput) return true;
                const formattedInput = searchInput.toLowerCase();
                return (
                  row.name.toLowerCase().includes(formattedInput) ||
                  row.email.toLowerCase().includes(formattedInput)
                );
              })
              .sort((a, b) => {
                if (orderBy === 'name') {
                  return order === 'desc'
                    ? sortLastNamesDesc(a.name, b.name)
                    : -sortLastNamesDesc(a.name, b.name);
                } else {
                  return order === 'desc'
                    ? a.company.localeCompare(b.company)
                    : b.company.localeCompare(a.company);
                }
              })
              .map((row) => (
                <TableRow
                  key={row.id}
                  style={{
                    backgroundColor: row.disabled
                      ? '#dcdcdc'
                      : row.isRegistered === false
                      ? '#ffd3d3'
                      : 'none',
                  }}
                >
                  {row.disabled === true ? (
                    <TableCell className={classes.text} style={{ width: '20%' }}>
                      <Typography>
                        {row.name}
                        <span style={{ color: 'red', fontSize: 14 }}> [Disabled]</span>
                      </Typography>
                    </TableCell>
                  ) : row.isRegistered === false ? (
                    <TableCell className={classes.text} style={{ width: '20%' }}>
                      <Typography>
                        {row.name}
                        <span style={{ color: 'red', fontSize: 14 }}> [Not Registered]</span>
                      </Typography>
                    </TableCell>
                  ) : (
                    <TableCell className={classes.text} style={{ width: '20%' }}>
                      <Typography>{row.name}</Typography>
                    </TableCell>
                  )}
                  <TableCell className={classes.text}>
                    <Typography>{row.company}</Typography>
                  </TableCell>
                  <TableCell className={classes.text} style={{ width: '20%' }}>
                    <Typography>{row.email}</Typography>
                  </TableCell>
                  <TableCell className={classes.text} style={{ width: '20%' }}>
                    <Select
                      variant="outlined"
                      defaultValue="Empty"
                      displayEmpty
                      fullWidth
                      style={{ height: '48px' }}
                    >
                      <MenuItem value="Empty">{`${row.projects.length} Projects`}</MenuItem>
                      {row.projects.map((name) => (
                        <MenuItem key={row.id + name} disabled>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell align="center" style={{ width: '20%' }}>
                    <Tooltip arrow placement="top" title="View Activity Log">
                      <IconButton
                        onClick={() => {
                          setSelectedUser(row);
                          setUserActivityDialogOpen(true);
                        }}
                        style={{ padding: 0 }}
                      >
                        <FormatListBulletedOutlined />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedUser && (
        <UserActivityDialog
          open={userActivityDialogOpen}
          handleClose={() => setUserActivityDialogOpen(false)}
          userId={selectedUser.id}
          name={selectedUser.name}
        />
      )}
    </DashboardBlock>
  );
}
