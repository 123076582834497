import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import {
  ManagePermissionsDialogType,
  PermissionActionType,
  PermissionsAndAction,
} from './ManagePermissionsDialog';
import { IconButton, TableCell } from '@material-ui/core';
import { Remove } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import PermissionTag, { PermissionTagType } from './PermissionTag';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import { IUser, IUserGroup } from '../../api-client/autogenerated';
import DistributionList from '../form-pages/user-groups/DistributionList';
import { getDocPermission } from '../../scripts/store-utils';
import { isPublicPage } from '../../scripts/utils';

type Props = {
  permissionRow?: PermissionsAndAction;
  groupRow?: IUserGroup;
  index: number;
  users?: IUser[];
  label: string;
  type: PermissionTagType;
  handleEditPermission?: (p: PermissionsAndAction) => void;
  handleDeletePermission?: (p: PermissionsAndAction) => void;
  defaultOpen?: boolean;
  hidePermissions?: boolean;
  dialogType?: ManagePermissionsDialogType;
  isDocumentDetail?: boolean;
  disablePermissions?: boolean;
  isNotifyEmail?: boolean;
};

export default function PermissionsDialogRow(props: Props) {
  const {
    permissionRow,
    groupRow,
    index,
    users,
    label,
    type,
    handleEditPermission,
    handleDeletePermission,
    defaultOpen,
    hidePermissions,
    dialogType,
    isDocumentDetail,
    disablePermissions = false,
    isNotifyEmail,
  } = props;

  const [expanded, setExpanded] = useState(!!defaultOpen);

  const backgroundColor = index % 2 === 0 && permissionRow ? '#F9F9F9' : 'white';

  const getBackgroundColor = () => {
    if (!permissionRow) return 'white';

    if (permissionRow.isFolderOwner) return '#ffd4c4';

    if (permissionRow.isFileManagerAdmin) return '#B6CEFC';

    return index % 2 === 0 ? '#F9F9F9' : 'white';
  };

  const handleChangeRead = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!permissionRow || !handleEditPermission) return;
    if (checked)
      handleEditPermission({
        ...permissionRow,
        action: permissionRow.read ? PermissionActionType.None : PermissionActionType.Modify,
        read: true,
      });
    else
      handleEditPermission({
        ...permissionRow,
        action:
          !permissionRow.read && !permissionRow.write && !permissionRow.admin
            ? PermissionActionType.None
            : PermissionActionType.Modify,
        read: false,
        write: false,
        admin: false,
      });
  };

  const handleChangeWrite = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!permissionRow || !handleEditPermission) return;
    if (checked)
      handleEditPermission({
        ...permissionRow,
        action:
          permissionRow.read && permissionRow.write
            ? PermissionActionType.None
            : PermissionActionType.Modify,
        read: true,
        write: true,
      });
    else
      handleEditPermission({
        ...permissionRow,
        action: !permissionRow.write ? PermissionActionType.None : PermissionActionType.Modify,
        write: false,
        admin: false,
      });
  };

  const handleChangeAdmin = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!permissionRow || !handleEditPermission) return;
    if (checked)
      handleEditPermission({
        ...permissionRow,
        action:
          permissionRow.read && permissionRow.admin
            ? PermissionActionType.None
            : PermissionActionType.Modify,
        read: true,
        write: true,
        admin: true,
      });
    else
      handleEditPermission({
        ...permissionRow,
        action: !permissionRow.admin ? PermissionActionType.None : PermissionActionType.Modify,
        admin: false,
      });
  };

  const getDisableDelete = () => {
    if (isPublicPage()) return true;
    switch (dialogType) {
      case ManagePermissionsDialogType.Design:
        return (
          !permissionRow?.shareable ||
          permissionRow?.isFileManagerAdmin ||
          permissionRow?.isFolderOwner
        );

      case ManagePermissionsDialogType.AssociatedUsers:
        const permission = getDocPermission();
        return !(permission && permission >= 2);

      default:
      case ManagePermissionsDialogType.None:
        return false;
    }
  };

  const EmailRow = () => {
    return (
      <>
        {groupRow && (
          <IconButton onClick={() => setExpanded((prev) => !prev)} style={{ padding: 0 }}>
            {expanded ? <Remove /> : <Add />}
          </IconButton>
        )}
        {permissionRow && (
          <PermissionTag
            label={label}
            type={type}
            triggerDeletePermission={() =>
              handleDeletePermission ? handleDeletePermission(permissionRow) : undefined
            }
            disableDelete={getDisableDelete()}
            parentFolder={permissionRow.parentFolder}
            isDocumentDetail={isDocumentDetail}
          />
        )}
        {groupRow && (
          <PermissionTag
            label={label}
            type={type}
            onClick={() => {
              if (handleEditPermission)
                handleEditPermission({
                  action: PermissionActionType.Modify,
                  group: groupRow,
                  read: true,
                  write: false,
                  admin: false,
                  shareable: true,
                });
            }}
            disableDelete
            isDocumentDetail={isDocumentDetail}
          />
        )}
        <DistributionList
          open={expanded}
          users={users}
          limitHeight={!!groupRow}
          isDocumentDetail={isDocumentDetail}
        />
      </>
    );
  };

  return isNotifyEmail ? (
    <EmailRow />
  ) : (
    <>
      <TableRow style={{ backgroundColor: getBackgroundColor() }}>
        <TableCell style={{ width: 24 }}>
          {type === PermissionTagType.Group && (
            <IconButton onClick={() => setExpanded((prev) => !prev)} style={{ padding: 0 }}>
              {expanded ? <Remove /> : <Add />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          {permissionRow && (
            <PermissionTag
              label={label}
              type={type}
              triggerDeletePermission={() =>
                handleDeletePermission ? handleDeletePermission(permissionRow) : undefined
              }
              disableDelete={getDisableDelete()}
              parentFolder={permissionRow.parentFolder}
              isDocumentDetail={isDocumentDetail}
            />
          )}
          {groupRow && (
            <PermissionTag
              label={label}
              type={type}
              onClick={() => {
                if (handleEditPermission)
                  handleEditPermission({
                    action: PermissionActionType.Modify,
                    group: groupRow,
                    read: true,
                    write: false,
                    admin: false,
                    shareable: true,
                  });
              }}
              disableDelete
              isDocumentDetail={isDocumentDetail}
            />
          )}
        </TableCell>
        {isDocumentDetail && <TableCell style={{ width: 24 }} />}
        {permissionRow && !hidePermissions && (
          <>
            <TableCell align="center">
              <GreenCheck
                checked={permissionRow.read}
                disabled={disablePermissions}
                onChange={handleChangeRead}
              />
            </TableCell>
            <TableCell align="center">
              <GreenCheck
                checked={permissionRow.write}
                disabled={disablePermissions}
                onChange={handleChangeWrite}
              />
            </TableCell>
            <TableCell align="center">
              <GreenCheck
                checked={permissionRow.admin}
                disabled={disablePermissions}
                onChange={handleChangeAdmin}
              />
            </TableCell>
          </>
        )}
      </TableRow>
      {type === PermissionTagType.Group && (
        <TableRow style={{ backgroundColor }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <DistributionList
              open={expanded}
              users={users}
              limitHeight={!!groupRow}
              isDocumentDetail={isDocumentDetail}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
