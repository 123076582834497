import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  InputBase,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  ActionTakenType,
  DocumentPriorityType,
  DocumentTemplateType,
  FileCategoryType,
  INumberedDocumentView,
  IProjectUser,
  IProjectView,
  PunchListStatusType,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import { getDocumentsState, getDocumentsType } from '../../features/documents/selectors';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import {
  getCurrentlyResponsibleUsers,
  getProjectUsers,
  getUserFriendlyTaskStatusFromWorkflowStatus,
} from '../../scripts/utils';
import _ from 'lodash';
import AssignedUserText from '../custom-components/AssignedUserText';
import { getCompaniesState } from '../../features/companies/selector';
import { getDocumentsByProjectIdAndType } from '../../models/api/project';
import Grid from '@material-ui/core/Grid';
import {
  getPhysicalBuildingsState,
  getPhysicalFloorsState,
  getProjectState,
} from '../../features/project/selectors';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { ascendingComparator, getShouldFilterByStatus } from '../document-index/DocumentIndexUtils';
import LabelWithCheckbox from '../custom-components/LabelWithCheckbox';
import Chip from '@material-ui/core/Chip';
import { Close } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { Autocomplete } from '@material-ui/lab';

export enum FilterDraftOption {
  ALL,
  DRAFT_ONLY,
  PUBLISHED_ONLY,
}

export interface DocumentLogFilters {
  filterFromDate: Dayjs | null;
  filterToDate: Dayjs | null;
  filterStatus: string[] | undefined;
  filterAssignTo: string | undefined;
  filterDownload: boolean | undefined;
  filterView: boolean | undefined;
  filterProcore: boolean | undefined;
  filterFromCost: number | undefined;
  filterToCost: number | undefined;
  filterPunchListStatus: string[] | undefined;
  filterBuilding: string | undefined;
  filterFloor: string[] | undefined;
  filterSourceFile: string | undefined;
  filterPackage: string | undefined;
  filterDraft: FilterDraftOption | undefined;
  filterSubcontractor: string | undefined;
}

export type setDocumentLogFilters = (
  filterFromDate?: Dayjs | null,
  filterToDate?: Dayjs | null,
  filterStatus?: string[],
  filterAssignTo?: string,
  filterDownload?: boolean,
  filterView?: boolean,
  filterProcore?: boolean,
  filterFromCost?: number,
  filterToCost?: number,
  filterPunchListStatus?: string[],
  filterBuilding?: string,
  filterFloor?: string[],
  filterSourceFile?: string,
  filterPackage?: string,
  filterDraft?: FilterDraftOption,
  filterSubcontractor?: string,
) => void;

interface DocumentLogFilterDialogProps {
  dialogOpen: boolean;
  handleClose: () => void;
  anchor: any;
  filters: DocumentLogFilters;
  updateFilters: setDocumentLogFilters;
  updateNumFilters: React.Dispatch<React.SetStateAction<number>>;
  updateRows: () => void;
  selectedProject: IProjectView;
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: 8,
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

const useStyles = makeStyles({
  popover: {
    '.rootElement > *': {
      maxHeight: '5000px',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '80px',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'uppercase',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  section: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    minWidth: '270px',
  },
  checkbox: {
    padding: 3,
  },
  inputLabel: {
    marginTop: 6,
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    paddingTop: 16,
  },
  calendar: {
    width: '100%',
    marginTop: 6,
    paddingTop: 4,
    paddingBottom: 4,
  },
  button: {
    width: 100,
    height: 36,
  },
  statusCheckbox: {},
  selectList: {
    maxHeight: 400,
  },
  textField: {
    marginTop: 4,
    marginBottom: 4,
    width: 120,
  },
  helperText: {
    fontSize: 8,
  },
  chipIcon: {
    marginRight: '0px !important',
    marginLeft: '4px',
  },
  chipLabel: {
    paddingLeft: 6,
    paddingRight: 6,
  },
});

const defaultStatusOptions = [
  { name: 'Submitted for Review', value: WorkflowStatusType.SubmittedForReview, isChecked: false },
  { name: 'Under Review', value: WorkflowStatusType.UnderReview, isChecked: false },
  { name: 'Review Complete', value: WorkflowStatusType.ReviewComplete, isChecked: false },
  { name: 'Overdue', value: 'overdue', isChecked: false },
];

const punchListStatusOptions = [
  { name: 'New', value: PunchListStatusType.New },
  { name: 'In Progress', value: PunchListStatusType.InProgress },
  { name: 'Ready for Verification', value: PunchListStatusType.ReadyForVerification },
  { name: 'Rejected', value: PunchListStatusType.Rejected },
  { name: 'Accepted', value: PunchListStatusType.Accepted },
];

export default function DocumentLogFilterDialog(props: DocumentLogFilterDialogProps) {
  const classes = useStyles();
  const {
    dialogOpen,
    handleClose,
    anchor,
    filters,
    updateFilters,
    updateNumFilters,
    updateRows,
    selectedProject,
  } = props;

  const documents = useSelector(getDocumentsState);
  const project = useSelector(getProjectState);
  const docType = useSelector(getDocumentsType);
  const companies = useSelector(getCompaniesState);
  const history = useHistory();
  const buildings = useSelector(getPhysicalBuildingsState);
  const floors = useSelector(getPhysicalFloorsState);

  const drawingsFiles =
    project?.files?.filter((f) => f.category === FileCategoryType.Drawings) || [];

  const specificationsFiles =
    project?.files?.filter((f) => f.category === FileCategoryType.Specifications) || [];

  const [assignedUsers, setAssignedUsers] = useState<IProjectUser[]>([]);
  const [packageNames, setPackageNames] = useState<string[]>([]);

  const [filterAssignTo, setFilterAssignTo] = useState<string | undefined>(filters.filterAssignTo);
  const [filterIsOverdue, setFilterIsOverdue] = useState(false);
  const [filterIsUpcoming, setFilterIsUpcoming] = useState(false);
  const [filterIsRevise, setFilterIsRevise] = useState(false);
  const [filterIsCloseout, setFilterIsCloseout] = useState(false);
  const [filterStatus, setFilterStatus] = useState<string[] | undefined>(filters.filterStatus);
  const [filterLastWeek, setFilterLastWeek] = useState(false);
  const [filterLastMonth, setFilterLastMonth] = useState(false);
  const [filterLast3Months, setFilterLast3Months] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState<Dayjs | null>(filters.filterFromDate);
  const [filterToDate, setFilterToDate] = useState<Dayjs | null>(filters.filterToDate);
  const [filterDownload, setFilterDownload] = useState(filters.filterDownload);
  const [filterView, setFilterView] = useState(filters.filterView);
  const [filterProcore, setFilterProcore] = useState(filters.filterProcore);
  const [filterFromCost, setFilterFromCost] = useState<number | undefined>(filters.filterFromCost);
  const [filterToCost, setFilterToCost] = useState<number | undefined>(filters.filterToCost);
  const [filterPunchListStatus, setFilterPunchListStatus] = useState<string[] | undefined>(
    filters.filterPunchListStatus,
  );
  const [filterBuilding, setFilterBuilding] = useState(filters.filterBuilding || '');
  const [filterFloor, setFilterFloor] = useState<string[]>(filters.filterFloor || []);
  const [filterSourceFile, setFilterSourceFile] = useState(filters.filterSourceFile || '');
  const [filterPackage, setFilterPackage] = useState(filters.filterPackage || '');
  const [filterSubcontractor, setFilterSubcontractor] = useState(filters.filterSubcontractor || '');

  const [filterFromCostInput, setFilterFromCostInput] = useState<number | undefined>(
    filters.filterFromCost,
  );
  const [filterToCostInput, setFilterToCostInput] = useState<number | undefined>(
    filters.filterToCost,
  );

  const [filterDraft, setFilterDraft] = useState(filters.filterDraft);

  const [costErrorText, setCostErrorText] = useState('');
  const [statusArray, setStatusArray] = useState<
    { name: string; value: string; isChecked: boolean }[]
  >([]);

  const shouldFilterByStatus = getShouldFilterByStatus(docType);

  const customStatusOptions = docType
    ? [
        DocumentTemplateType.Submittals,
        DocumentTemplateType.CloseoutSubmittals,
        DocumentTemplateType.RequestsForInformation,
        DocumentTemplateType.RequestsForChange,
        DocumentTemplateType.PotentialChangeOrders,
        DocumentTemplateType.AsBuilt,
        DocumentTemplateType.OperationsAndMaintenanceData,
        DocumentTemplateType.Task,
      ].includes(docType)
    : false;

  const shouldFilterByResponsibility = docType
    ? [
        DocumentTemplateType.PayApplications,
        DocumentTemplateType.RequestsForInformation,
        DocumentTemplateType.Submittals,
        DocumentTemplateType.PotentialChangeOrders,
        DocumentTemplateType.RequestsForChange,
        DocumentTemplateType.WorkChangeProposalRequests,
        DocumentTemplateType.CloseoutSubmittals,
        DocumentTemplateType.AsBuilt,
        DocumentTemplateType.OperationsAndMaintenanceData,
        DocumentTemplateType.Task,
      ].includes(docType)
    : false;

  const shouldFilterByDownloadOrView = docType === DocumentTemplateType.PlanholderList;
  const shouldFilterByProcore = docType
    ? !selectedProject.isProcoreIntegrationEnabled &&
      [DocumentTemplateType.MeetingMinutes, DocumentTemplateType.ContractorDailyLogs].includes(
        docType,
      )
    : false;
  const shouldFilterByCost = docType === DocumentTemplateType.PunchList;
  const shouldFilterBySubcontractor = docType === DocumentTemplateType.PunchList;
  const shouldFilterByBuildingAndFloor = docType === DocumentTemplateType.PunchList;
  const shouldFilterByPunchListStatus = docType === DocumentTemplateType.PunchList;
  // TODO: Discuss with Chuck how to implement and re-enable
  // const shouldFilterBySourceFile =
  //   docType === DocumentTemplateType.Drawings || docType === DocumentTemplateType.Specifications;
  const shouldFilterBySourceFile = false;
  const shouldFilterByPackage = docType === DocumentTemplateType.Drawings;
  const shouldFilterByDraft = docType
    ? [DocumentTemplateType.PunchList, DocumentTemplateType.FieldReports].includes(docType)
    : false;

  const responsibleSubcontractorOptions = _.uniq(
    documents.map((d) => d.customContractor).filter((c) => !!c) as string[],
  );

  const getStatusOptions = () => {
    if (!customStatusOptions) {
      setStatusArray(
        defaultStatusOptions.map((option) => ({
          ...option,
          isChecked: filterStatus?.includes(option.value) || false,
        })),
      );
    }

    const statusOptions: { name: string; value: string; isChecked: boolean }[] = [];
    if (
      docType === DocumentTemplateType.OperationsAndMaintenanceData ||
      docType === DocumentTemplateType.AsBuilt
    )
      statusOptions.push({ name: 'Initial', value: WorkflowStatusType.Initial, isChecked: false });
    if (docType === DocumentTemplateType.Task)
      statusOptions.push({
        name: 'For Information Only',
        value: DocumentPriorityType.ForInformationOnly,
        isChecked: false,
      });
    if (
      docType === DocumentTemplateType.Submittals ||
      docType === DocumentTemplateType.CloseoutSubmittals
    )
      statusOptions.push({
        name: 'Awaiting Submission',
        value: WorkflowStatusType.Initial,
        isChecked: false,
      });
    if (
      docType === DocumentTemplateType.OperationsAndMaintenanceData ||
      docType === DocumentTemplateType.AsBuilt
    )
      statusOptions.push(
        { name: 'Under Review', value: WorkflowStatusType.UnderReview, isChecked: false },
        { name: 'Completed', value: WorkflowStatusType.Completed, isChecked: false },
        { name: 'Resubmitted', value: WorkflowStatusType.Resubmitted, isChecked: false },
      );
    else if (docType === DocumentTemplateType.Task)
      statusOptions.push(
        {
          name: getUserFriendlyTaskStatusFromWorkflowStatus(WorkflowStatusType.SubmittedForReview),
          value: WorkflowStatusType.SubmittedForReview,
          isChecked: false,
        },
        {
          name: getUserFriendlyTaskStatusFromWorkflowStatus(WorkflowStatusType.UnderReview),
          value: WorkflowStatusType.UnderReview,
          isChecked: false,
        },
      );
    else
      statusOptions.push(
        {
          name: 'Submitted for Review',
          value: WorkflowStatusType.SubmittedForReview,
          isChecked: false,
        },
        { name: 'Under Review', value: WorkflowStatusType.UnderReview, isChecked: false },
      );

    if (
      docType === DocumentTemplateType.RequestsForInformation ||
      docType === DocumentTemplateType.RequestsForChange ||
      docType === DocumentTemplateType.PotentialChangeOrders
    ) {
      statusOptions.push(
        { name: 'Review Complete', value: WorkflowStatusType.ReviewComplete, isChecked: false },
        { name: 'Resolved', value: WorkflowStatusType.Resolved, isChecked: false },
        { name: 'Resubmitted', value: WorkflowStatusType.Resubmitted, isChecked: false },
      );
    } else if (docType === DocumentTemplateType.Task) {
      statusOptions.push(
        {
          name: getUserFriendlyTaskStatusFromWorkflowStatus(WorkflowStatusType.ReviewComplete),
          value: WorkflowStatusType.ReviewComplete,
          isChecked: false,
        },
        {
          name: getUserFriendlyTaskStatusFromWorkflowStatus(WorkflowStatusType.Resolved),
          value: WorkflowStatusType.Resolved,
          isChecked: false,
        },
        {
          name: getUserFriendlyTaskStatusFromWorkflowStatus(WorkflowStatusType.Resubmitted),
          value: WorkflowStatusType.Resubmitted,
          isChecked: false,
        },
      );
    }
    if (
      docType === DocumentTemplateType.Submittals ||
      docType === DocumentTemplateType.CloseoutSubmittals
    )
      statusOptions.push(
        { name: 'Review Complete', value: WorkflowStatusType.Completed, isChecked: false },
        { name: 'Revise & Resubmit', value: ActionTakenType.ReviseAndResubmit, isChecked: false },
      );
    if (
      docType !== DocumentTemplateType.OperationsAndMaintenanceData &&
      docType !== DocumentTemplateType.AsBuilt
    )
      statusOptions.push({ name: 'Overdue', value: 'overdue', isChecked: false });

    setStatusArray(
      statusOptions.map((option) => ({
        ...option,
        isChecked: filterStatus?.includes(option.value) || false,
      })),
    );
  };

  useEffect(() => {
    setFilterAssignTo(filters.filterAssignTo);
    setFilterFromDate(filters.filterFromDate);
    setFilterToDate(filters.filterToDate);
    setFilterStatus(filters.filterStatus);
    setFilterDownload(filters.filterDownload);
    setFilterView(filters.filterView);
    setFilterProcore(filters.filterProcore);
    setFilterPunchListStatus(filters.filterPunchListStatus);
    setFilterBuilding(filters.filterBuilding || '');
    setFilterFloor(filters.filterFloor || []);
    setFilterSourceFile(filters.filterSourceFile || '');
    setFilterPackage(filters.filterPackage || '');
    setFilterDraft(filters.filterDraft || FilterDraftOption.ALL);
    setFilterSubcontractor(filters.filterSubcontractor || '');
  }, [filters, buildings]);

  useEffect(() => {
    if (history.location.state?.status) {
      setFilterStatus([history.location.state.status]);
      setIndividualFilters({ filterStatus: [history.location.state.status] });
      setStatusArray((prev) =>
        prev.map((s) => ({ ...s, isChecked: s.value === history.location.state.status })),
      );
      history.location.state = undefined;
    }
  }, [history]);

  useEffect(() => {
    let numFilters = 0;
    if (filterFromDate || filterToDate) numFilters += 1;
    if (filterStatus) numFilters += filterStatus.length;
    if (filterAssignTo) numFilters += 1;
    if (filterDownload) numFilters += 1;
    if (filterView) numFilters += 1;
    if (filterProcore) numFilters += 1;
    if (filterFromCost || filterToCost) numFilters += 1;
    if (filterPunchListStatus) numFilters += filterPunchListStatus.length;
    if (buildings.length > 1 && filterBuilding) numFilters += 1;
    if (filterFloor.length > 0) numFilters += 1;
    if (filterSourceFile) numFilters += 1;
    if (filterPackage) numFilters += 1;
    if (filterDraft !== FilterDraftOption.ALL) numFilters += 1;
    if (filterSubcontractor) numFilters += 1;
    updateNumFilters(numFilters);
  }, [
    filterAssignTo,
    filterFromDate,
    filterToDate,
    filterStatus,
    filterDownload,
    filterView,
    filterProcore,
    filterFromCost,
    filterToCost,
    filterPunchListStatus,
    filterBuilding,
    filterFloor,
    filterSourceFile,
    filterPackage,
    filterDraft,
    filterSubcontractor,
  ]);

  const setAllFilters = () => {
    updateFilters(
      filterFromDate || null,
      filterToDate || null,
      filterStatus,
      filterAssignTo,
      filterDownload,
      filterView,
      filterProcore,
      filterFromCost,
      filterToCost,
      filterPunchListStatus,
      filterBuilding,
      filterFloor,
      filterSourceFile,
      filterPackage,
      filterDraft,
      filterSubcontractor,
    );
    handleClose();
  };

  const setIndividualFilters = (filters: Partial<DocumentLogFilters>) => {
    const {
      filterFromDate,
      filterToDate,
      filterStatus,
      filterAssignTo,
      filterDownload,
      filterView,
      filterProcore,
      filterFromCost,
      filterToCost,
      filterPunchListStatus,
      filterBuilding,
      filterFloor,
      filterSourceFile,
      filterPackage,
      filterDraft,
      filterSubcontractor,
    } = filters;

    updateFilters(
      filterFromDate,
      filterToDate,
      filterStatus,
      filterAssignTo,
      filterDownload,
      filterView,
      filterProcore,
      filterFromCost,
      filterToCost,
      filterPunchListStatus,
      filterBuilding,
      filterFloor,
      filterSourceFile,
      filterPackage,
      filterDraft,
      filterSubcontractor,
    );
    handleClose();
  };

  const clearFilters = () => {
    history.location.state = undefined;
    setFilterIsOverdue(false);
    setFilterIsUpcoming(false);
    setFilterIsRevise(false);
    setFilterIsCloseout(false);
    setFilterLastWeek(false);
    setFilterLastMonth(false);
    setFilterLast3Months(false);
    setFilterFromDate(null);
    setFilterToDate(null);
    setFilterStatus(undefined);
    setFilterAssignTo(undefined);
    setFilterDownload(false);
    setFilterView(false);
    setFilterProcore(false);
    setFilterFromCost(undefined);
    setFilterToCost(undefined);
    setFilterFromCostInput(undefined);
    setFilterToCostInput(undefined);
    setCostErrorText('');
    setFilterBuilding('');
    setFilterFloor([]);
    setFilterSourceFile('');
    setFilterPackage('');
    setFilterDraft(FilterDraftOption.ALL);
    setFilterSubcontractor('');
    updateFilters(
      null,
      null,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      FilterDraftOption.ALL,
      undefined,
    );
    updateNumFilters(0);
    updateRows();
    if (shouldFilterByStatus) {
      let tempArray = [...statusArray];
      tempArray.forEach((item) => (item.isChecked = false));
      setStatusArray(tempArray);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    history.location.state = undefined;

    if (
      (filterFromCostInput !== undefined && filterToCostInput === undefined) ||
      (filterFromCostInput === undefined && filterToCostInput !== undefined)
    ) {
      setCostErrorText('You must set a minimum and maximum cost.');
      return;
    } else if (
      filterToCostInput !== undefined &&
      filterFromCostInput !== undefined &&
      filterToCostInput < filterFromCostInput
    ) {
      setCostErrorText('Minimum cost must be less than maximum cost.');
      return;
    }

    setCostErrorText('');
    updateRows();
    setAllFilters();
  };

  const getStatusCheckBoxOptions = (
    <>
      {punchListStatusOptions.map((option) => (
        <FormControlLabel
          key={option.name}
          label={option.name}
          control={
            <GreenCheck
              checked={filterPunchListStatus?.includes(option.value)}
              onChange={(event, checked) => {
                handlePunchListStatusChange(checked, option.value);
              }}
              style={{ paddingBottom: 2, paddingTop: 2 }}
            />
          }
          className={classes.statusCheckbox}
        />
      ))}
    </>
  );

  const horizontalSeperator = (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <hr
        style={{
          color: ' #464546',
          backgroundColor: '#464546',
          height: 1,
          width: 14,
        }}
      />
    </div>
  );

  useEffect(() => {
    if (
      docType === DocumentTemplateType.Submittals ||
      docType === DocumentTemplateType.CloseoutSubmittals
    ) {
      getDocumentsByProjectIdAndType(
        selectedProject.id,
        docType === DocumentTemplateType.Submittals
          ? DocumentTemplateType.SubmittalPackages
          : DocumentTemplateType.CloseoutSubmittalPackages,
      ).then((docs) => setAssignedUsers(getAssignedUsers([...documents, ...docs])));
    } else {
      setAssignedUsers(getAssignedUsers(documents));
    }
  }, [documents, docType]);

  const getAssignedUsers = (documents: INumberedDocumentView[]) => {
    const assignedUsers = _.uniqBy(
      documents
        .map((doc) => getCurrentlyResponsibleUsers(doc))
        .flat()
        .filter((user) => user.id) as { id: string; name: string }[],
      ({ id }) => id,
    );
    return (
      getProjectUsers(selectedProject, false).filter((pUser) =>
        assignedUsers.some((user) => user.id === pUser.userId),
      ) || []
    );
  };

  const handlePunchListStatusChange = (checked: boolean, status: string) => {
    if (filterPunchListStatus) {
      if (checked) {
        setFilterPunchListStatus([...filterPunchListStatus, status]);
      } else {
        const newSelections = filterPunchListStatus.filter((selection) => selection !== status);
        setFilterPunchListStatus(newSelections);
      }
    } else {
      setFilterPunchListStatus([status]);
    }
  };

  useEffect(() => {
    getStatusOptions();
  }, [docType, filterStatus]);

  const handlestatusArrayChecked = (index: number, checked: boolean, status: string) => {
    var tempArray = [...statusArray];
    tempArray[index].isChecked = checked;
    setStatusArray(tempArray);

    if (filterStatus && checked) {
      setFilterStatus([...filterStatus, status]);
    } else if (filterStatus) {
      let secondTempArray = [...filterStatus];
      setFilterStatus(secondTempArray.filter((item) => item !== status));
    } else {
      setFilterStatus([status]);
    }
  };

  useEffect(() => {
    if (docType === DocumentTemplateType.Drawings) {
      setPackageNames([
        ...new Set(documents.filter((d) => !!d.simplePackage).map((d) => d.simplePackage!)),
      ]);
    }
  }, [docType, documents]);

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <Popover
        className={classes.popover}
        open={dialogOpen}
        onClose={handleSubmit}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <form onSubmit={handleSubmit}>
          {shouldFilterByStatus && (
            <Box className={classes.section}>
              <Typography variant="body1" style={{ marginBottom: 6 }}>
                Status
              </Typography>
              {statusArray.map((item, index) => {
                return (
                  <div key={item.name} style={{ display: 'flex' }}>
                    <GreenCheck
                      style={{ padding: 0 }}
                      checked={item.isChecked}
                      onChange={(event, checked) =>
                        handlestatusArrayChecked(index, checked, item.value)
                      }
                    />
                    <Typography
                      style={{ height: 30, display: 'flex', alignItems: 'center', paddingLeft: 9 }}
                    >
                      {item.name}
                    </Typography>
                  </div>
                );
              })}
            </Box>
          )}

          {shouldFilterByResponsibility && (
            <Box className={classes.section}>
              <Typography variant="body1">Responsibility</Typography>
              <FormControl fullWidth variant="outlined" className={classes.inputLabel}>
                <InputLabel>Assigned to ...</InputLabel>
                <Select
                  input={<BootstrapInput />}
                  value={filterAssignTo}
                  onChange={(e: any) => {
                    setFilterAssignTo(e.target.value === 'none' ? undefined : e.target.value);
                  }}
                  fullWidth
                  MenuProps={{ classes: { paper: classes.selectList } }}
                >
                  <MenuItem key="none" value="none">
                    <AssignedUserText name="Anyone" />
                  </MenuItem>
                  {assignedUsers.map((item) => {
                    return (
                      <MenuItem key={item.user!.id} value={item.userId}>
                        <AssignedUserText
                          name={item.user?.name}
                          company={
                            item.user?.company?.name ||
                            companies.find((c) => c.id === item.user?.companyId)?.name
                          }
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          )}
          {shouldFilterByDownloadOrView && (
            <Box className={classes.section}>
              <Typography variant="body1">Downloads/Views</Typography>
              <FormControlLabel
                label="Hide entries with no views"
                control={
                  <GreenCheck
                    checked={filterView}
                    onChange={(event, checked) => setFilterView(checked)}
                  />
                }
                className={classes.inputLabel}
              />

              <FormControlLabel
                label="Hide entries with no downloads"
                control={
                  <GreenCheck
                    checked={filterDownload}
                    onChange={(event, checked) => setFilterDownload(checked)}
                  />
                }
                className={classes.inputLabel}
              />
            </Box>
          )}

          {shouldFilterByProcore && (
            <>
              <Box className={classes.section}>
                <Typography variant="body1">Procore Sync</Typography>
                <FormControlLabel
                  label="Procore Sync"
                  control={
                    <GreenCheck
                      checked={filterProcore}
                      onChange={(event, checked) => setFilterProcore(checked)}
                    />
                  }
                  className={classes.inputLabel}
                />
              </Box>
              <Divider light />
            </>
          )}

          {shouldFilterByBuildingAndFloor && (
            <>
              <Box className={classes.section}>
                <Typography variant="body1">Building</Typography>
                <FormControl fullWidth variant="outlined" className={classes.inputLabel}>
                  <Select
                    // input={<BootstrapInput />}
                    fullWidth
                    displayEmpty
                    disabled={buildings.length === 0}
                    value={filterBuilding}
                    onChange={(e) => {
                      setFilterBuilding(e.target.value as string);
                    }}
                    style={{ height: 38 }}
                  >
                    <MenuItem value="">Any</MenuItem>
                    {buildings
                      .sort((a, b) => ascendingComparator(a, b, 'name'))
                      .map((building) => (
                        <MenuItem key={building.id} value={building.id}>
                          {building.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Divider light />
              <Box className={classes.section}>
                <Typography variant="body1">Floor</Typography>
                <FormControl fullWidth variant="outlined" className={classes.inputLabel}>
                  <Select
                    // input={<BootstrapInput />}
                    multiple
                    fullWidth
                    displayEmpty
                    disabled={!filterBuilding}
                    value={filterFloor}
                    renderValue={(value) => {
                      const values = value as string[];
                      if (values.length === 0) return 'Any';
                      if (values.length === 1) return '1 floor selected';
                      return `${values.length} floors selected`;
                    }}
                    onChange={(e) => {
                      setFilterFloor(e.target.value as string[]);
                    }}
                    style={{ height: 38 }}
                  >
                    {floors
                      .filter((floor) => floor.buildingId === filterBuilding)
                      .sort((a, b) => ascendingComparator(a, b, 'index'))
                      .map((floor) => (
                        <MenuItem key={floor.id} value={floor.id}>
                          <LabelWithCheckbox
                            label={floor.name}
                            checked={filterFloor.includes(floor.id)}
                            onClick={() => {}}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-evenly',
                    maxWidth: 260,
                  }}
                >
                  {filterFloor.map((v, index) => {
                    const name = floors.find((f) => f.id === v)!.name;
                    return (
                      <Chip
                        key={v}
                        variant="outlined"
                        label={name}
                        deleteIcon={<Close />}
                        onDelete={() => setFilterFloor((prev) => prev.filter((f) => f !== v))}
                        classes={{ deleteIcon: classes.chipIcon, label: classes.chipLabel }}
                        style={{
                          flexDirection: 'row-reverse',
                          width: 120,
                          marginRight: index % 2 === 0 ? 4 : 0,
                          marginTop: 4,
                        }}
                      />
                    );
                  })}
                </div>
              </Box>
              <Divider light />
            </>
          )}

          {shouldFilterBySubcontractor ? (
            <>
              <Box className={classes.section}>
                <Typography variant="body1">Responsible Subcontractor</Typography>
                <Autocomplete
                  size="small"
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="dense"
                        placeholder="Any"
                        fullWidth
                        style={{ height: 32 }}
                      />
                    );
                  }}
                  options={responsibleSubcontractorOptions}
                  value={filterSubcontractor}
                  onChange={(event, value) => setFilterSubcontractor(value || '')}
                  style={{ height: 32, marginBottom: 12 }}
                />
              </Box>
              <Divider light />
            </>
          ) : null}

          {shouldFilterByCost && (
            <>
              <Box className={classes.section}>
                <Typography variant="body1" style={{ marginBottom: 4 }}>
                  Cost
                </Typography>
                <Grid container>
                  <CurrencyTextField
                    variant="outlined"
                    label="MIN"
                    value={filterFromCostInput}
                    currencySymbol="$"
                    minimumValue="0"
                    outputFormat="number"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(event: any, value: number) => {
                      setFilterFromCostInput(value);
                      setFilterFromCost(Math.round(value * 100)); // contract amount is in cents
                    }}
                    InputProps={{ style: { height: 28 } }}
                    className={classes.textField}
                    style={{ marginRight: 4 }}
                  />
                  {horizontalSeperator}
                  <CurrencyTextField
                    variant="outlined"
                    label="MAX"
                    value={filterToCostInput}
                    currencySymbol="$"
                    minimumValue="0"
                    outputFormat="number"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(event: any, value: number) => {
                      setFilterToCostInput(value);
                      setFilterToCost(Math.round(value * 100)); // contract amount is in cents
                    }}
                    InputProps={{ style: { height: 28 } }}
                    className={classes.textField}
                    style={{ marginLeft: 4 }}
                  />
                </Grid>
                {costErrorText && (
                  <Typography style={{ color: 'red', maxWidth: 260 }}>{costErrorText}</Typography>
                )}
              </Box>
              <Divider light />
            </>
          )}

          {shouldFilterByDraft ? (
            <>
              <Box className={classes.section}>
                <Typography variant="body1">Draft</Typography>
                <FormControl fullWidth variant="outlined" className={classes.inputLabel}>
                  <Select
                    // input={<BootstrapInput />}
                    fullWidth
                    displayEmpty
                    value={filterDraft}
                    onChange={(e) => {
                      setFilterDraft(e.target.value as FilterDraftOption);
                    }}
                    style={{ height: 32 }}
                  >
                    <MenuItem value={FilterDraftOption.ALL}>Show All</MenuItem>
                    <MenuItem value={FilterDraftOption.DRAFT_ONLY}>Draft Only</MenuItem>
                    <MenuItem value={FilterDraftOption.PUBLISHED_ONLY}>Published Only</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Divider light />
            </>
          ) : null}

          {shouldFilterByPunchListStatus && (
            <>
              <Box className={classes.section}>
                <Typography variant="body1">Status</Typography>
                {getStatusCheckBoxOptions}
              </Box>
              <Divider light />
            </>
          )}

          {shouldFilterBySourceFile && (
            <Box className={classes.section}>
              <Typography variant="body1">Source Document</Typography>
              <FormControl fullWidth variant="outlined" className={classes.inputLabel}>
                <Select
                  fullWidth
                  displayEmpty
                  disabled={
                    docType === DocumentTemplateType.Drawings
                      ? drawingsFiles.length === 0
                      : specificationsFiles.length === 0
                  }
                  value={filterSourceFile}
                  onChange={(e) => {
                    setFilterSourceFile(e.target.value as string);
                  }}
                  style={{ height: 32 }}
                >
                  <MenuItem value="">Any</MenuItem>
                  {(docType === DocumentTemplateType.Drawings ? drawingsFiles : specificationsFiles)
                    .sort((a, b) => ascendingComparator(a, b, 'name'))
                    .map((f) => (
                      <MenuItem key={f.id} value={f.id}>
                        {f.name.length > 40 ? f.name.substr(0, 40).trim() + '...' : f.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )}

          {shouldFilterByPackage && (
            <Box className={classes.section}>
              <Typography variant="body1">Package</Typography>
              <FormControl fullWidth variant="outlined" className={classes.inputLabel}>
                <Select
                  fullWidth
                  displayEmpty
                  value={filterPackage}
                  onChange={(e) => {
                    setFilterPackage(e.target.value as string);
                  }}
                  style={{ height: 32 }}
                >
                  <MenuItem value="">Any</MenuItem>
                  <MenuItem value="NO_PACKAGE">None</MenuItem>
                  {packageNames.sort().map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          <Box className={classes.section}>
            <Box className={classes.inline}>
              <Button
                onClick={clearFilters}
                variant="outlined"
                className={classes.button}
                style={{ width: '50%' }}
              >
                Clear All
              </Button>
              <Button color="primary" type="submit" className={classes.button} variant="contained">
                Update
              </Button>
            </Box>
          </Box>
        </form>
      </Popover>
    </MuiPickersUtilsProvider>
  );
}
