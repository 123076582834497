// @ts-nocheck
import React from 'react';
import plusIcon from './images/plus-icon.png';
import bookIcon from './images/book-icon.png';
import '../css/admin.css';
import { IAnnouncement } from '../api-client/autogenerated';

function Tile(props) {
  const { description, title, endDate } = props;
  return (
    <>
      <div className="tile">
        <h3 className="tile-header">{title}</h3>
        <div className="tile-break" />
        <p className="tile-paragraph">{description}</p>
        <p className="tile-footer-container">
          <div className="inline-container">
            <p className="tile-footer">Announcement ends on {endDate}</p>
            <div className="tile-forward">{'>'}</div>
          </div>
        </p>
      </div>
    </>
  );
}

function Button({ image, text }) {
  return (
    <div className="custom-button">
      <img alt="" src={image} className="custom-button-image" />
      <div className="custom-button-text">{text}</div>
    </div>
  );
}

class Announcements extends React.Component {
  announcements: IAnnouncement[];

  constructor(props) {
    super(props);
    this.mainApp = props.mainApp;
    this.announcements = props.announcements;
  }

  setContent(name) {
    this.mainApp.setContent(name);
  }

  render() {
    return (
      <>
        <div className="home-content announcement-tile-styling">
          <Button image={plusIcon} text="Schedule new announcement" />
          <Button image={bookIcon} text="View announcement history" />
          <div className="inline-container-large">
            <div className="flex-columm">
              <h3 className="header">Current Announcements</h3>
              {this.announcements.map((announcement) => {
                return (
                  <Tile
                    key={announcement.id}
                    endDate={announcement.postTimestamp}
                    title={announcement.headline}
                    description={announcement.details}
                  />
                );
              })}
              {/* <Tile */}
              {/*  endDate="November 6th" */}
              {/*  title="Lorem ipsum dolor molin" */}
              {/*  description="ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum dolor sit amet, ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis" */}
              {/* /> */}
              {/* <Tile */}
              {/*  endDate="November 13th" */}
              {/*  title="Lorem ipsum dolor molin" */}
              {/*  description="ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum dolor sit amet, ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis" */}
              {/* /> */}
              {/* <Tile */}
              {/*  endDate="November 25th" */}
              {/*  title="Lorem ipsum dolor molin" */}
              {/*  description="ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum dolor sit amet, ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis" */}
              {/* /> */}
            </div>

            <div className="flex-column">
              <h3 className="header">Scheduled Announcements</h3>
              <Tile
                endDate="November 6th"
                title="Lorem ipsum dolor molin"
                description="ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum dolor sit amet, ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
              />
              <Tile
                endDate="November 13th"
                title="Lorem ipsum dolor molin"
                description="ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum dolor sit amet, ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
              />
              <Tile
                endDate="November 25th"
                title="Lorem ipsum dolor molin"
                description="ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum dolor sit amet, ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Announcements;
