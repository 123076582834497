import { ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { ALLOW_NAVIGATION, BLOCK_NAVIGATION } from './constants';
import * as navigationActions from './actions';
import { Navigation } from './navigation';

export type NavigationAction = ActionType<typeof navigationActions>;
export type NavigationState = {
  navigation: Navigation;
};

export default combineReducers<NavigationState, NavigationAction>({
  navigation: (state = false, action) => {
    let newState: Navigation;
    switch (action.type) {
      case BLOCK_NAVIGATION:
        newState = true;
        break;

      case ALLOW_NAVIGATION:
        newState = false;
        break;

      default:
        newState = state;
        break;
    }

    return newState;
  },
});
