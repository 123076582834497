import React, { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import NavAppbar from '../nav-top/NavAppbar';
import List from '@material-ui/core/List';
import EmailItem from './EmailItem';
import { getNoEmailText } from './utils';
import { descendingComparator } from '../document-index/DocumentIndexUtils';
import Hidden from '@material-ui/core/Hidden';
import { MenuItem, Select, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router';
import {
  copyToClipboard,
  downloadFileFromLink,
  generateUniqueId,
  isPdf,
  OptionalProjectMatchParams,
} from '../../scripts/utils';
import {
  InboxPermissionLevel,
  IReceivedEmail,
  UserNotificationWithEmail,
} from '../../api-client/autogenerated';
import PostalMime from 'postal-mime';
import {
  getAllReceivedEmails,
  getEmailSourceFromEmailId,
  getMyNotificationsWithEmails,
} from '../../models/api/email';
import EmailFilterDialog from './EmailFilterDialog';
import NotificationEmailItem from './NotificationEmailItem';
import CircularLoader from '../loader/CircularLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import { getProjectState } from '../../features/project/selectors';
import {
  ArrowBack,
  ArrowDownward,
  ArrowUpward,
  Assignment,
  Delete,
  DoneAll,
  FilterList,
  InsertDriveFileOutlined,
  SelectAll,
} from '@material-ui/icons';
import PDFIcon from '../icons/PDF-icon';
import DownloadIcon from '../icons/Download-icon';
import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash';
import {
  deleteUserNotificationsByIds,
  markManyNotificationsAsRead,
} from '../../models/api/notifications';
import { useLocation } from 'react-router-dom';
import { addSnackbar } from '../../features/snackbar/actions';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: 24,
      height: '100vh',
      overflow: 'hidden',
    },
    emailListContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      float: 'left',
      minWidth: 480,
      maxWidth: 600,
    },
    emailListContainerMobile: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    emailList: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflowY: 'auto',
      float: 'left',
      width: '100%',
    },
    emailListMobile: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      overflowY: 'auto',
    },
    emailPreviewContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      float: 'left',
      borderLeft: '8px solid #E5EEFE',
      borderTopLeftRadius: 8,
    },
    emailPreview: {
      width: '100%',
      height: '100%',
      overflowY: 'scroll',
      border: 'none',
      borderBottomRightRadius: 8,
    },
    mobileEmailPreviewContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      marginTop: 40,
    },
    userInfoRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '34px',
      lineHeight: '40px',
      letterSpacing: '-0.6px',
      color: '#0947B9', // Blue / Blue 700 Brand Dark
      marginBottom: 8,
    },
    deleteButton: {
      backgroundColor: '#ED3F26',
      '&:hover': {
        backgroundColor: '#FF5D45',
      },
    },
  }),
);

export enum EmailLogType {
  CORRESPONDENCE_LOG,
  MY_NOTIFICATIONS,
}

type Props = {
  type: EmailLogType;
};

const PAGE_SIZE = 20;

export default function CorrespondenceLog(props: Props) {
  const { type } = props;
  const theme = useTheme();
  const classes = useStyles();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const { projectId } = useParams<OptionalProjectMatchParams>();

  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);

  const [listLoading, setListLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [hasMoreResults, setHasMoreResults] = useState(true);

  const [userPermissionLevel, setUserPermissionLevel] = useState<InboxPermissionLevel>();
  const [
    selectedPermissionLevel,
    setSelectedPermissionLevel,
  ] = useState<InboxPermissionLevel | null>(null);

  const [emails, setEmails] = useState<IReceivedEmail[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<IReceivedEmail | null>(null);
  const [selectedEmailSource, setSelectedEmailSource] = useState<string>();
  const [selectedEmailAttachments, setSelectedEmailAttachments] = useState<
    { id: string; name: string; url: string }[]
  >([]);

  const [notifications, setNotifications] = useState<UserNotificationWithEmail[]>([]);
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number>();
  const [
    selectedNotification,
    setSelectedNotification,
  ] = useState<UserNotificationWithEmail | null>(null);

  const selectedIndicesNotifications = selectedIndices.map((index) => notifications[index]);
  const allSelectedNotificationsAreRead = _.compact(selectedIndicesNotifications).every(
    (n) => !!n.isRead,
  );

  const listRef = useRef<HTMLUListElement | null>(null);
  const infiniteScrollRef = useRef<InfiniteScroll | null>(null);
  const filterRef = useRef(null);

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filterFrom, setFilterFrom] = useState('');
  const [filterTo, setFilterTo] = useState('');
  const [filterSubject, setFilterSubject] = useState('');
  const [filterHasAttachment, setFilterHasAttachment] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState<Dayjs | null>(null);
  const [filterToDate, setFilterToDate] = useState<Dayjs | null>(null);
  const [filterIsUnread, setFilterIsUnread] = useState(true);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  useEffect(() => {
    clearFilters();
    setSelectedEmail(null);
    setSelectedNotification(null);
    setSelectedEmailSource(undefined);
    setSelectedEmailAttachments([]);
    setSelectedIndices([]);
    setLastSelectedIndex(undefined);
    setFilterIsUnread(true);
  }, [type]);

  const clearFilters = () => {
    setFilterFrom('');
    setFilterTo('');
    setFilterSubject('');
    setFilterHasAttachment(false);
    setFilterFromDate(null);
    setFilterToDate(null);
  };

  const getUserFriendlyPermissionLevel = () => {
    switch (userPermissionLevel) {
      case InboxPermissionLevel.NUMBER_0:
        return 'Personal';

      case InboxPermissionLevel.NUMBER_2:
        return 'Company';

      case InboxPermissionLevel.NUMBER_4:
        return 'Admin';

      default:
        return '';
    }
  };

  const getEmptyListText = () => {
    switch (userPermissionLevel) {
      case InboxPermissionLevel.NUMBER_0:
        return ' for you';

      case InboxPermissionLevel.NUMBER_2:
        return ' for your company';

      default:
      case InboxPermissionLevel.NUMBER_4:
        return '';
    }
  };

  const getFilterFromDate = () => {
    if (!filterFromDate) return undefined;

    return filterFromDate
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0)
      .toISOString();
  };

  const getFilterToDate = () => {
    if (!filterToDate) return undefined;

    return filterToDate
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .set('millisecond', 999)
      .toISOString();
  };

  const getEmailFromHeader = () => {
    if (selectedEmail) return `${selectedEmail?.senderName} <${selectedEmail?.senderAddress}>`;
    if (selectedNotification) return selectedNotification.email.Source;
  };

  const getEmailToHeader = () => {
    if (selectedEmail)
      return `${selectedEmail?.recipientName} <${selectedEmail?.recipientAddress}>`;
    if (selectedNotification) return selectedNotification.email.Destination.ToAddresses?.[0];
  };

  const getEmailSubjectHeader = () => {
    if (selectedEmail) return selectedEmail.subject;
    if (selectedNotification) return selectedNotification.email.Message.Subject.Data;
  };

  const getEmailDateHeader = () => {
    if (selectedEmail) return dayjs(selectedEmail.sendDate).format('MM/DD/YYYY HH:mm A z');
    if (selectedNotification)
      return dayjs(selectedNotification?.createdOn).format('MM/DD/YYYY HH:mm A z');
  };

  const markNotificationReadLocally = (id: string) => {
    const localNotificationIndex = notifications.findIndex((n) => n.id === id);

    if (localNotificationIndex !== -1) {
      setNotifications((prev) => [
        ...prev.slice(0, localNotificationIndex),
        { ...prev[localNotificationIndex], isRead: !allSelectedNotificationsAreRead },
        ...prev.slice(localNotificationIndex + 1),
      ]);
    }
  };

  const markNotificationRead = async (userNotificationId: string) => {
    await markManyNotificationsAsRead(
      user.id,
      [userNotificationId],
      !allSelectedNotificationsAreRead,
    );
    markNotificationReadLocally(userNotificationId);
  };

  const markSelectedNotificationsRead = async () => {
    const userNotificationIds = selectedIndices.map((index) => notifications[index].id);
    await markManyNotificationsAsRead(
      user.id,
      userNotificationIds,
      !allSelectedNotificationsAreRead,
    );
    userNotificationIds.map(markNotificationReadLocally);
  };

  const deleteSelectedNotifications = async () => {
    const userNotificationIds = selectedIndices.map((index) => notifications[index].id);
    await deleteUserNotificationsByIds(userNotificationIds);
    fetchEmails();
  };

  const selectAll = () => {
    setSelectedIndices(
      _.range((type === EmailLogType.MY_NOTIFICATIONS ? notifications : emails).length),
    );
  };

  const closeEmailPreview = () => {
    setSelectedEmail(null);
    setSelectedNotification(null);
    setSelectedEmailSource(undefined);
  };

  const fetchNextPage = async () => {
    if (type === EmailLogType.CORRESPONDENCE_LOG) {
      const data = await getAllReceivedEmails(
        projectId!,
        {
          fromEmail: filterFrom,
          toOrCcEmail: filterTo,
          hasAttachment: filterHasAttachment,
          subject: filterSubject,
          fromDate: getFilterFromDate(),
          toDate: getFilterToDate(),
          selectedPermission: selectedPermissionLevel ?? undefined,
        },
        { page: page + 1, pageSize: PAGE_SIZE },
      );
      setEmails((prev) => [...prev, ...data.results.results]);
      setPage((prev) => prev + 1);
      setHasMoreResults(data.results.results.length === PAGE_SIZE);
    } else {
      const data = await getMyNotificationsWithEmails(
        {
          projectId,
          fromDate: getFilterFromDate(),
          toDate: getFilterToDate(),
          isUnread: filterIsUnread,
        },
        { page: page + 1, pageSize: PAGE_SIZE, orderByDirection: sortOrder },
      );
      setNotifications((prev) => [...prev, ...data]);
      setPage((prev) => prev + 1);
      setHasMoreResults(data.length === PAGE_SIZE);
    }
  };

  const fetchEmails = async () => {
    setListLoading(true);
    setSelectedIndices([]);
    if (type === EmailLogType.CORRESPONDENCE_LOG) {
      try {
        const data = await getAllReceivedEmails(
          projectId!,
          {
            fromEmail: filterFrom,
            toOrCcEmail: filterTo,
            hasAttachment: filterHasAttachment,
            subject: filterSubject,
            fromDate: getFilterFromDate(),
            toDate: getFilterToDate(),
            selectedPermission: selectedPermissionLevel ?? undefined,
          },
          { page: 0, pageSize: PAGE_SIZE },
        );
        setPage(0);
        setEmails(data.results.results);
        setHasMoreResults(data.results.results.length === PAGE_SIZE);
        if (userPermissionLevel === undefined) {
          setUserPermissionLevel(data.permission);
        }
        setSelectedPermissionLevel(data.permission);
      } finally {
        setListLoading(false);
      }
    } else {
      try {
        const data = await getMyNotificationsWithEmails(
          {
            projectId,
            fromDate: getFilterFromDate(),
            toDate: getFilterToDate(),
            isUnread: filterIsUnread,
          },
          { page: 0, pageSize: PAGE_SIZE, orderByDirection: sortOrder },
        );
        setPage(0);
        setNotifications(data);
        setHasMoreResults(data.length === PAGE_SIZE);
      } finally {
        setListLoading(false);
      }
    }
  };

  useEffect(() => {
    setSelectedIndices([]);

    fetchEmails().then(async () => {
      const scrollHeight = listRef.current?.scrollHeight || 0;
      const clientHeight = listRef.current?.clientHeight || 0;
      if (!(scrollHeight > clientHeight)) {
        infiniteScrollRef.current?.setState({ showLoader: true });
        await fetchNextPage();
        infiniteScrollRef.current?.setState({ showLoader: false });
      }
    });
  }, [
    type,
    projectId,
    filterFrom,
    filterTo,
    filterSubject,
    filterHasAttachment,
    filterFromDate,
    filterToDate,
    filterIsUnread,
    sortOrder,
    selectedPermissionLevel,
  ]);

  useEffect(() => {
    setSelectedEmailAttachments([]);
    if (selectedEmail) {
      setPreviewLoading(true);
      getEmailSourceFromEmailId(selectedEmail.id).then((data) => {
        const parser = new PostalMime();
        parser.parse(data).then((email) => {
          console.log(email);
          setSelectedEmailSource(email.html || email.text);
          const attachments = email.attachments
            .filter((a) => a.disposition === 'attachment')
            .map((a) => ({
              id: generateUniqueId(),
              name: a.filename,
              url: URL.createObjectURL(new Blob([a.content])),
            }));
          setSelectedEmailAttachments(attachments);
          setPreviewLoading(false);
        });
      });
    }
  }, [selectedEmail]);

  useEffect(() => {
    if (selectedNotification) {
      setSelectedEmailSource(selectedNotification.email.Message.Body.Html?.Data);
    }
  }, [selectedNotification]);

  const filterCount = [
    filterFrom,
    filterTo,
    filterSubject,
    filterHasAttachment,
    filterFromDate,
    filterToDate,
  ]
    .map((v) => (!!v ? 1 : 0))
    .reduce<number>((a, b) => a + b, 0);

  const notificationOnClick = (
    event: React.MouseEvent<HTMLLIElement>,
    notification: UserNotificationWithEmail,
    index: number,
  ) => {
    event.stopPropagation();
    if (event.shiftKey) {
      if (lastSelectedIndex !== undefined) {
        setLastSelectedIndex(index);
        setSelectedIndices(
          _.range(lastSelectedIndex, index > lastSelectedIndex ? index + 1 : index - 1),
        );
      } else {
        setSelectedIndices([index]);
      }
    } else if (event.ctrlKey) {
      setLastSelectedIndex(index);
      if (selectedIndices.includes(index)) {
        setSelectedIndices((prev) => prev.filter((v) => v !== index));
      } else {
        setSelectedIndices((prev) => [...prev, index]);
      }
    } else {
      setLastSelectedIndex(index);
      setSelectedIndices([index]);
    }

    if (event.detail > 1 && event.buttons === 0) {
      setSelectedNotification(notification);
      if (!notification.isRead) {
        markNotificationRead(notification.id);
      }
    }
  };

  const getEmails = () => {
    if (type === EmailLogType.CORRESPONDENCE_LOG) {
      if (emails.length === 0) {
        if (filterCount > 0) {
          return (
            <Typography align="center" style={{ marginTop: 16 }}>
              No emails found with the current filters.
            </Typography>
          );
        }
        return (
          <Typography align="center" style={{ marginTop: 16 }}>
            No emails found{getEmptyListText()}.
          </Typography>
        );
      }
      return emails
        .sort((a, b) => descendingComparator(a, b, 'sendDate'))
        .map((e, index, array) => (
          <EmailItem
            key={e.id}
            email={e}
            onClick={() => setSelectedEmail(e)}
            isLastItem={index === array.length - 1}
          />
        ));
    } else {
      if (notifications.length === 0)
        return <Typography align="center">No notifications.</Typography>;
      return notifications.map((n, index) => (
        <NotificationEmailItem
          key={n.id}
          notificationWithEmail={n}
          onClick={(event) => notificationOnClick(event, n, index)}
          isSelected={selectedIndices.includes(index)}
        />
      ));
    }
  };

  const emailPreviewContent = (
    <div className={smDown ? classes.mobileEmailPreviewContainer : classes.emailPreviewContainer}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
        }}
      >
        {smDown ? (
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowBack />}
            onClick={closeEmailPreview}
            style={{ marginBottom: 8 }}
          >
            Go Back
          </Button>
        ) : null}
        <div
          style={{
            width: '100%',
            padding: '8px 4px',
            backgroundColor: '#E5EEFE',
            borderTopRightRadius: 8,
          }}
        >
          <div style={{ visibility: selectedEmail || selectedNotification ? 'visible' : 'hidden' }}>
            <Typography style={{ lineHeight: '20px' }}>From: {getEmailFromHeader()}</Typography>
            <Typography style={{ lineHeight: '20px' }}>To: {getEmailToHeader()}</Typography>
            <Typography style={{ lineHeight: '20px' }}>Date: {getEmailDateHeader()}</Typography>
            <Typography style={{ lineHeight: '20px', textIndent: '-58px', paddingLeft: '58px' }}>
              Subject: {getEmailSubjectHeader()}
            </Typography>
          </div>
        </div>
        {selectedEmailAttachments.length > 0 ? (
          <div style={{ width: '100%', backgroundColor: '#E5EEFE', padding: '0px 4px 8px 4px' }}>
            <Typography style={{ width: '100%', marginBottom: 4 }}>Attachments:</Typography>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                backgroundColor: '#E5EEFE',
              }}
            >
              {selectedEmailAttachments.map((file) => (
                <div
                  key={file.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid gray',
                    padding: 8,
                  }}
                >
                  {isPdf(file.name) ? (
                    <PDFIcon style={{ fill: '#0947B9' }} />
                  ) : (
                    <InsertDriveFileOutlined />
                  )}
                  <Typography style={{ marginLeft: 8, marginRight: 8 }}>{file.name}</Typography>
                  <IconButton
                    onClick={() => downloadFileFromLink(file.url, file.name)}
                    style={{ padding: 0 }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>

      {!previewLoading ? (
        <iframe
          className={classes.emailPreview}
          title="Email Preview"
          srcDoc={selectedEmailSource || getNoEmailText(type)}
          style={{ backgroundColor: !selectedEmailSource ? '#E5EEFE' : 'initial' }}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <CircularLoader />
        </div>
      )}
    </div>
  );

  if (smDown && (!!selectedEmail || !!selectedNotification)) {
    return <div className={classes.root}>{emailPreviewContent}</div>;
  }

  return (
    <main className={classes.root}>
      <NavAppbar />

      <div
        style={{
          display: 'flex',
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <div className={smDown ? classes.emailListContainerMobile : classes.emailListContainer}>
          <Typography className={classes.title}>
            {type === EmailLogType.CORRESPONDENCE_LOG ? 'Correspondence Log' : 'My Notifications'}
          </Typography>
          {type === EmailLogType.CORRESPONDENCE_LOG ? (
            <div style={{ paddingRight: 8 }}>
              <Typography className={classes.userInfoRow}>
                Project email address:{' '}
                <strong>
                  <IconButton
                    size="small"
                    style={{ padding: 0, marginRight: 4 }}
                    onClick={async () => {
                      await copyToClipboard(`${project?.emailSlug}@p.centerline.co`);
                      dispatch(
                        addSnackbar({
                          id: Date.now(),
                          message: 'Successfully copied project email address to clipboard!',
                          severity: 'success',
                        }),
                      );
                    }}
                  >
                    <Assignment />
                  </IconButton>
                  {project?.emailSlug}@p.centerline.co
                </strong>
              </Typography>
              <Typography className={classes.userInfoRow}>
                Your address: <strong>{user.email}</strong>
              </Typography>
              <Typography className={classes.userInfoRow} style={{ marginTop: 4 }}>
                Security level: <strong>{getUserFriendlyPermissionLevel()}</strong>
              </Typography>
              <div className={classes.userInfoRow} style={{ marginTop: 8 }}>
                <Typography>Displaying emails to/from:</Typography>
                <Select
                  variant="outlined"
                  disabled={userPermissionLevel === InboxPermissionLevel.NUMBER_0}
                  value={selectedPermissionLevel}
                  onChange={(event) =>
                    setSelectedPermissionLevel(event.target.value as InboxPermissionLevel)
                  }
                  style={{ width: 140, height: 32 }}
                >
                  <MenuItem key={0} value={InboxPermissionLevel.NUMBER_0}>
                    Me
                  </MenuItem>
                  {!!userPermissionLevel && userPermissionLevel >= InboxPermissionLevel.NUMBER_2 ? (
                    <MenuItem key={1} value={InboxPermissionLevel.NUMBER_2}>
                      My Company
                    </MenuItem>
                  ) : null}
                  {userPermissionLevel === InboxPermissionLevel.NUMBER_4 ? (
                    <MenuItem key={2} value={InboxPermissionLevel.NUMBER_4}>
                      Everyone
                    </MenuItem>
                  ) : null}
                </Select>
              </div>
            </div>
          ) : null}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '8px 8px 8px 0px',
            }}
          >
            {type === EmailLogType.MY_NOTIFICATIONS ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 8,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: 240,
                    }}
                  >
                    <ToggleButtonGroup
                      exclusive
                      value={filterIsUnread}
                      onChange={(event, value) => setFilterIsUnread(value)}
                    >
                      <ToggleButton
                        value={true}
                        style={{
                          color: filterIsUnread ? '#FAFAFA' : '#464546',
                          backgroundColor: filterIsUnread ? '#0947B9' : '#FAFAFA',
                          borderColor: '#0947B9',
                        }}
                      >
                        Unread
                      </ToggleButton>
                      <ToggleButton
                        value={false}
                        style={{
                          color: !filterIsUnread ? '#FAFAFA' : '#464546',
                          backgroundColor: !filterIsUnread ? '#0947B9' : '#FAFAFA',
                          borderColor: '#0947B9',
                        }}
                      >
                        All
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup
                      exclusive
                      value={sortOrder}
                      onChange={(event, value) => setSortOrder(value)}
                      style={{ marginLeft: 16 }}
                    >
                      <ToggleButton
                        value="desc"
                        style={{
                          color: sortOrder === 'desc' ? '#FAFAFA' : '#464546',
                          backgroundColor: sortOrder === 'desc' ? '#0947B9' : '#FAFAFA',
                          borderColor: '#0947B9',
                        }}
                      >
                        <ArrowDownward />
                      </ToggleButton>
                      <ToggleButton
                        value="asc"
                        style={{
                          color: sortOrder === 'asc' ? '#FAFAFA' : '#464546',
                          backgroundColor: sortOrder === 'asc' ? '#0947B9' : '#FAFAFA',
                          borderColor: '#0947B9',
                        }}
                      >
                        <ArrowUpward />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  {!xsDown ? (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<SelectAll />}
                      onClick={selectAll}
                      style={{ width: 170, whiteSpace: 'nowrap' }}
                    >
                      Select All
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={selectAll}
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      <SelectAll />
                    </Button>
                  )}
                </div>
                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DoneAll />}
                    disabled={selectedIndices.length === 0}
                    onClick={markSelectedNotificationsRead}
                    style={{ width: 240, whiteSpace: 'nowrap' }}
                  >
                    Mark as{' '}
                    {allSelectedNotificationsAreRead && selectedIndices.length > 0
                      ? 'Unread'
                      : 'Read'}
                  </Button>

                  {!xsDown ? (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Delete />}
                      disabled={selectedIndices.length === 0}
                      onClick={deleteSelectedNotifications}
                      className={classes.deleteButton}
                      style={{ width: 170, whiteSpace: 'nowrap' }}
                    >
                      Delete
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={selectedIndices.length === 0}
                      onClick={deleteSelectedNotifications}
                      className={classes.deleteButton}
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      <Delete />
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <Button
                ref={filterRef}
                variant="contained"
                color="primary"
                startIcon={<FilterList />}
                onClick={() => setFiltersOpen((prev) => !prev)}
                style={{ whiteSpace: 'nowrap' }}
              >
                Filters {filterCount ? `(${filterCount} Selected)` : null}
              </Button>
            )}
          </div>
          <List
            id="emailList"
            ref={listRef}
            disablePadding
            className={smDown ? classes.emailListMobile : classes.emailList}
          >
            {!listLoading ? (
              <InfiniteScroll
                next={fetchNextPage}
                ref={infiniteScrollRef}
                hasMore={hasMoreResults}
                loader={
                  <div style={{ paddingTop: 12, paddingBottom: 12 }}>
                    <CircularLoader />
                  </div>
                }
                dataLength={(page + 1) * PAGE_SIZE}
                scrollableTarget="emailList"
                scrollThreshold={0.92}
              >
                {getEmails()}
              </InfiniteScroll>
            ) : (
              <CircularLoader style={{ marginTop: 64 }} />
            )}
          </List>
        </div>

        <Hidden smDown>{emailPreviewContent}</Hidden>
      </div>
      <EmailFilterDialog
        open={filtersOpen}
        close={() => setFiltersOpen(false)}
        type={type}
        submit={(filters) => {
          setFilterTo(filters.filterTo);
          setFilterFrom(filters.filterFrom);
          setFilterSubject(filters.filterSubject);
          setFilterHasAttachment(filters.filterHasAttachment);
          setFilterFromDate(filters.filterFromDate);
          setFilterToDate(filters.filterToDate);
          setFilterIsUnread(filters.filterIsUnread);
        }}
        anchor={filterRef.current}
        filters={{
          filterTo,
          filterFrom,
          filterSubject,
          filterHasAttachment,
          filterFromDate,
          filterToDate,
          filterIsUnread,
        }}
      />
    </main>
  );
}
