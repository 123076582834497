import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListIcon from '@material-ui/icons/List';
import IconButton from '@material-ui/core/IconButton';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import DashboardUpcomingList from './DashboardUpcomingList';
import DashboardUpcomingCalendar from './DashboardUpcomingCalendar';
import {
  getSoonestDueDocuments,
  getSoonestDueDocumentsAssignedByUser,
  getSoonestDueDocumentsAssignedToUser,
} from '../../models/api/documents';
import { INumberedDocumentView } from '../../api-client/autogenerated';
import { getUserState } from '../../features/user/selectors';
import { getProjectState } from '../../features/project/selectors';
import { useReactToPrint } from 'react-to-print';
import { Print } from '@material-ui/icons';
import fileDownload from 'js-file-download';
import DownloadIcon from '../icons/Download-icon';
import ical from 'ical-generator';

const useStyles = makeStyles({
  root: {
    height: '100%',
    left: '234px',
    top: '589px',
    display: 'flex',
    flexDirection: 'column',
  },
  titleStyle: {
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
  },
});

function DashboardUpcoming() {
  const [viewList, setViewList] = useState(true);
  const [loadingAssigned, setLoadingAssigned] = useState(true);
  const classes = useStyles();
  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);
  const [upcomingType, setUpcomingType] = useState('assigned-to-me');

  const calendarRef = useRef<any>(null);
  const iCalendar = useRef(ical({ name: 'Centerline Calendar' }));

  const [soonestDueDocuments, setSoonestDueDocuments] = useState<INumberedDocumentView[]>([]);
  useEffect(() => {
    setLoadingAssigned(true);
    switch (upcomingType) {
      case 'assigned-to-me':
        getSoonestDueDocumentsAssignedToUser(user.id, project?.id)
          .then((result) => {
            setSoonestDueDocuments(result);
          })
          .catch((e) => console.log(e))
          .finally(() => setLoadingAssigned(false));

        break;
      case 'assigned-by-me':
        getSoonestDueDocumentsAssignedByUser(user.id, project?.id)
          .then((result) => {
            setSoonestDueDocuments(result);
          })
          .catch((e) => console.log(e))
          .finally(() => setLoadingAssigned(false));

        break;
      case 'all':
      default:
        getSoonestDueDocuments(user.id, project?.id)
          .then((result) => {
            setSoonestDueDocuments(result);
          })
          .catch((e) => console.log(e))
          .finally(() => setLoadingAssigned(false));

        break;
    }
  }, [upcomingType, project]);

  const handleShowList = (event: React.MouseEvent) => {
    event.preventDefault();
    setViewList(true);
  };

  const handleShowCalendar = (event: React.MouseEvent) => {
    event.preventDefault();
    setViewList(false);
  };

  const handlePrint = useReactToPrint({
    content: () => calendarRef.current,
  });

  const handleExport = () => {
    fileDownload(iCalendar.current.toString(), 'CenterlineCalendar.ics', 'application/ics');
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.titleStyle}>
        <h2
          className="h2"
          style={{
            textAlign: 'left',
            color: '#FFFFFF',
            margin: 0,
          }}
        >
          Upcoming
          {/* <ListIcon style={{ color: 'white', marginLeft: 30, width: '20.34px', height: '20px' }} />
          <CalendarTodayOutlinedIcon
            style={{ color: '#A6C4FF', width: '20.34px', height: '20px' }}
          /> */}
          <Tooltip
            title="List View"
            arrow
            placement="right"
            style={{ padding: '0px', marginLeft: '30px' }}
          >
            <IconButton onClick={handleShowList} aria-label="view list">
              <ListIcon style={{ color: viewList ? 'white' : '#A6C4FF' }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Calendar View"
            arrow
            placement="right"
            style={{ padding: '0px', marginLeft: '30px' }}
          >
            <IconButton onClick={handleShowCalendar} aria-label="view calendar">
              <CalendarTodayOutlinedIcon style={{ color: viewList ? '#A6C4FF' : 'white' }} />
            </IconButton>
          </Tooltip>
        </h2>
      </CardContent>
      <CardContent
        style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, paddingBottom: '16px' }}
        // style={{ height: '100%', overflowY: 'auto' }}
      >
        <div style={{ display: 'flex' }}>
          <Button
            onClick={() => setUpcomingType('all')}
            style={{ borderBottom: upcomingType === 'all' ? '2px solid #F28B00' : 'none' }}
          >
            All
          </Button>
          <Button
            onClick={() => setUpcomingType('assigned-to-me')}
            style={{
              borderBottom: upcomingType === 'assigned-to-me' ? '2px solid #F28B00' : 'none',
            }}
          >
            Assigned to me
          </Button>
          <Button
            onClick={() => setUpcomingType('assigned-by-me')}
            style={{
              borderBottom: upcomingType === 'assigned-by-me' ? '2px solid #F28B00' : 'none',
            }}
          >
            Assigned by me to others
          </Button>
          <div style={{ display: 'inline-flex', flexGrow: 100 }} />
          {!viewList && (
            <>
              <Tooltip arrow placement="top" title="Export">
                <IconButton size="small" onClick={handleExport} style={{ padding: 0 }}>
                  <DownloadIcon width={22} height={22} />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="top" title="Print">
                <IconButton
                  size="small"
                  onClick={handlePrint}
                  style={{ padding: 0, marginLeft: 8 }}
                >
                  <Print />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>

        {viewList ? (
          <DashboardUpcomingList
            soonestDueDocuments={soonestDueDocuments}
            upcomingType={upcomingType}
            loading={loadingAssigned}
          />
        ) : (
          <DashboardUpcomingCalendar
            soonestDueDocuments={soonestDueDocuments}
            loading={loadingAssigned}
            calendarRef={calendarRef}
            iCalendar={iCalendar}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default DashboardUpcoming;
