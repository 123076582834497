import React, { useEffect, useState } from 'react';
import {
  createMuiTheme,
  Grid,
  LinearProgress,
  Snackbar,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/Lock';
import Addenda from '../addenda/Addenda';
import ProjectDetails from '../project-details/ProjectDetails';
import BidDocuments from '../initial-bid-documents/BidDocuments';
import PrimeBidders from '../prime-bidders/PrimeBidders';
import MoreInfo from '../more-info/MoreInfo';
import RFIs from '../rfis/RFIs';
import SubstitutionRequests from '../substitution-requests/SubstitutionRequests';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import SubmitBid from '../submit-bid/SubmitBid';
import NavAppbar from '../../main-components/nav-top/NavAppbar';
import { IBid, IBidSetup, JobStatusType } from '../../api-client/autogenerated';
import { getBidSetupById } from '../../models/api/project-bid-setups';
import { getZippedDocumentsLink } from '../../models/api/filesystem';
import { Description, GetApp, Publish } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import dayjs from 'dayjs';
import SubmittedBids from '../submit-bid/SubmittedBids';
import { useDispatch, useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import { getBidsByProjectId } from '../../models/api/project';
import FolderAccordion from '../initial-bid-documents/FolderAccordion';
import BiddingTableSimple from '../initial-bid-documents/BiddingTableSimple';
import { downloadFileById, formatFileSize, openInNewTab, parseDate } from '../../scripts/utils';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '../../main-components/icons/Download-icon';
import EyeIcon from '@material-ui/icons/Visibility';
import { addSnackbar } from '../../features/snackbar/actions';
import { useHistory } from 'react-router-dom';

const theme = createMuiTheme({
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 450,
      md: 600,
      lg: 1100,
      xl: 2100,
    },
  },
  overrides: {
    MuiCard: {
      root: {
        boxShadow: '0px 5px 15px rgb(0, 0, 0, .15)',
      },
    },
    MuiButton: {
      outlinedPrimary: {
        height: 32,
        border: '2px solid #0947B9',
        boxSizing: 'border-box',
        borderRadius: '4px',
        paddingLeft: '30px',
        paddingRight: '30px',
        '&:hover': {
          border: '2px solid #4380ED',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        '&:active': {
          backgroundColor: 'rgba(0,0,0,0)',
        },
      },
      containedPrimary: {
        height: 32,
        color: 'white',
        borderRadius: '4px',
        paddingLeft: '30px',
        paddingRight: '30px',
        backgroundColor: '#0947B9',
        '&:hover': {
          backgroundColor: '#4380ED',
        },
        '&:active': {
          backgroundColor: '#4380ED',
        },
      },
    },
  },
});

type Props = {
  match: any;
  setCurrentProject: (project: IBidSetup) => void;
  setProjectInfoType: (str: string) => void;
  projectInfoType: string;
};

const useStyles = makeStyles((theme) => ({
  titleStyle: {
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
    padding: 16,
    marginBottom: 16,
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    lineHeight: '40px',
    letterSpacing: '-0.6px',
    paddingBottom: 48,
    paddingTop: 8,
    color: '#0947B9', // Blue / Blue 700 Brand Dark
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 81,
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 54,
    },
  },
}));

const ProjectPage: React.FC<Props> = (props) => {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUserState);

  const [bidSetup, setBidSetup] = useState<IBidSetup>();
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [downloadRetryCount, setDownloadRetryCount] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadMessage, setDownloadMessage] = useState('Initializing...');

  const [orderPrintsRetryCount, setOrderPrintsRetryCount] = useState(0);
  const [isOrdering, setIsOrdering] = useState(false);
  const [orderPrintsProgress, setOrderPrintsProgress] = useState(0);
  const [orderPrintsMessage, setOrderPrintsMessage] = useState('Initializing...');

  const [submitBidDialogOpen, setSubmitBidDialogOpen] = useState(false);

  const detailsRef = React.useRef<HTMLDivElement | null>(null);
  const primeBidderRef = React.useRef<HTMLDivElement | null>(null);
  const addendaRef = React.useRef<HTMLDivElement | null>(null);
  const rfiRef = React.useRef<HTMLDivElement | null>(null);
  const moreInfoRef = React.useRef<HTMLDivElement | null>(null);
  const substitutionRequestRef = React.useRef<HTMLDivElement | null>(null);
  const initialBidDocRef = React.useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = React.useState(false);

  const [bids, setBids] = React.useState<IBid[]>([]);
  const [bidDisabled, setBidDisabled] = useState(false);

  const bidTabulation = bidSetup?.project?.bidTabulationsFile;
  const [expandedPanel, setExpandedPanel] = useState<undefined | number>(undefined);

  const handleChangePanel = (num: number) => {
    if (expandedPanel === num) setExpandedPanel(undefined);
    else setExpandedPanel(num);
  };

  const { match, setCurrentProject, setProjectInfoType, projectInfoType } = props;
  const handleCloseError = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
      getBidSetupById(match.params.id).then((result) => {
        if (new Date(result.liveAt) > new Date()) {
          history.push('/bidding/locked', { liveAt: result.liveAt });
        } else {
          setBidSetup(result);
          setCurrentProject(result);
          setProjectInfoType('Details');
        }
      });
    }
  }, []);

  const fetchBids = async (projectId: string) => {
    return getBidsByProjectId(projectId)
      .then((res) => {
        const temp = res.filter((bid) => bid.userId === user.id);
        setBids(temp);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (bidSetup?.project) {
      fetchBids(bidSetup.project.id);
    }
  }, [user, bidSetup]);

  const handleDownload = async () => {
    if (!bidSetup) return;
    setIsDownloading(true);
    const response = await getZippedDocumentsLink(bidSetup.id);
    setDownloadMessage(
      response.jobStatus.status === JobStatusType.Pending ? 'Initializing...' : 'Loading...',
    );
    if (response.jobStatus.progress?.filesToZipTotal) {
      setDownloadProgress(
        Math.round(
          (response.jobStatus.progress.filesToZipCompleted /
            response.jobStatus.progress.filesToZipTotal) *
            100,
        ),
      );
    }

    if (
      response.jobStatus.status === JobStatusType.Complete ||
      response.jobStatus.status === JobStatusType.Canceled
    ) {
      setIsDownloading(false);
      if (
        response.jobStatus.status === JobStatusType.Complete &&
        response.s3Response?.downloadLink
      ) {
        window.location.href = response.s3Response.downloadLink;
      }
    } else {
      setDownloadRetryCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (downloadRetryCount > 0) {
      setTimeout(handleDownload, 3000);
    }
  }, [downloadRetryCount]);

  const handleCreateEmail = (link: string) => {
    const subject = `Prints Needed - CENTERLINE BidConnect Project: ${bidSetup?.project?.name}`;
    const formattedBody = `CENTERLINE BidConnect Project: ${bidSetup?.project?.name}\n\n<< INCLUDE SPECIAL INSTRUCTIONS HERE >>\n\n||||||||||||||||||||||||||||||||||||||||\n\nFor Print Company Use:\n\nDownload here: ${link}\n\nRequested by: ${user.name}, ${user.company?.name}, ${user.email}`;
    const mailto = `mailto:production@lettermans.com?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(formattedBody)}`;
    window.location.href = mailto;
  };

  const handleOrderPrints = async () => {
    if (!bidSetup) return;
    setIsOrdering(true);
    const response = await getZippedDocumentsLink(bidSetup.id);
    setOrderPrintsMessage(
      response.jobStatus.status === JobStatusType.Pending ? 'Initializing...' : 'Loading...',
    );
    if (response.jobStatus.progress?.filesToZipTotal) {
      setOrderPrintsProgress(
        Math.round(
          (response.jobStatus.progress.filesToZipCompleted /
            response.jobStatus.progress.filesToZipTotal) *
            100,
        ),
      );
    }

    if (
      response.jobStatus.status === JobStatusType.Complete ||
      response.jobStatus.status === JobStatusType.Canceled
    ) {
      setIsOrdering(false);
      if (
        response.jobStatus.status === JobStatusType.Complete &&
        response.s3Response?.downloadLink
      ) {
        handleCreateEmail(response.s3Response.downloadLink);
      }
    } else {
      setOrderPrintsRetryCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (orderPrintsRetryCount > 0) {
      setTimeout(handleOrderPrints, 3000);
    }
  }, [orderPrintsRetryCount]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (projectInfoType !== '') {
      myContent();
    }
  }, [projectInfoType]);

  const myContent = () => {
    const options = { behavior: 'smooth', block: 'start' } as ScrollIntoViewOptions;
    switch (projectInfoType) {
      case 'Details':
        detailsRef.current?.scrollIntoView(options);
        break;
      case 'Initial Bid Documents':
        initialBidDocRef.current?.scrollIntoView(options);
        break;
      case 'Addenda':
        addendaRef.current?.scrollIntoView(options);
        break;
      case 'Prime Bidders':
        primeBidderRef.current?.scrollIntoView(options);
        break;
      case 'RFIs':
        rfiRef.current?.scrollIntoView(options);
        break;
      case 'Substitution Requests':
        substitutionRequestRef.current?.scrollIntoView(options);
        break;
      case 'More Info':
        moreInfoRef.current?.scrollIntoView(options);
        break;
      default:
        detailsRef.current?.scrollIntoView();
        break;
    }
  };

  const handleOpenBidDialog = () => {
    if (!bidSetup) return;

    if (parseDate(bidSetup.dueDate).isBefore(dayjs())) {
      setBidDisabled(true);
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Bids must be submitted before the bid date',
          severity: 'error',
        }),
      );
    } else {
      setSubmitBidDialogOpen(true);
    }
  };

  return (
    <>
      <div ref={detailsRef} style={{ padding: 16 }}>
        <NavAppbar isBiddingPortal bidSetupName={bidSetup ? bidSetup.project!.name : ''} />
        <Typography className={classes.title}>
          {bidSetup ? bidSetup.project!.name : ''}
          {bidSetup && bidSetup.password && (
            <LockIcon style={{ marginLeft: 12 }} fontSize="large" />
          )}
        </Typography>
        <ThemeProvider theme={theme}>
          <div style={{ width: 600, marginBottom: 16 }}>
            {bidTabulation?.isPublic && (
              <FolderAccordion
                index={1}
                expandedIndex={expandedPanel}
                handleChangeIndex={handleChangePanel}
                name="Completed Bid Tabulation"
              >
                <BiddingTableSimple
                  headers={['Name', 'Size', 'View/Download']}
                  rows={[
                    [
                      bidTabulation.name,
                      bidTabulation.size ? formatFileSize(bidTabulation.size) : '',
                      <div
                        key={bidTabulation.id}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <IconButton
                          onClick={() => openInNewTab(bidTabulation.id, true)}
                          style={{ padding: 0, marginRight: 24 }}
                        >
                          <EyeIcon />
                        </IconButton>

                        <IconButton
                          onClick={() => downloadFileById(bidTabulation.id, bidTabulation.name)}
                          style={{ padding: 0 }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </div>,
                    ],
                  ]}
                  emptyText="No Bid Tabulation"
                />
              </FolderAccordion>
            )}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
            {isDownloading ? (
              <div style={{ display: 'flex', flexDirection: 'column', width: 250 }}>
                <Typography align="center" style={{ fontWeight: 500 }}>
                  {downloadMessage}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={downloadProgress}
                  style={{ height: 8 }}
                />
                <Typography align="center" style={{ fontWeight: 500 }}>
                  {downloadProgress}%
                </Typography>
              </div>
            ) : (
              <Button
                disabled={bidSetup === undefined}
                onClick={handleDownload}
                color="primary"
                variant="contained"
                startIcon={<GetApp />}
                style={{
                  width: 'auto',
                  paddingLeft: 22,
                  paddingRight: 22,
                  whiteSpace: 'nowrap',
                }}
              >
                Download All
              </Button>
            )}

            <Button
              disabled={
                !bidSetup ||
                !bidSetup.allowElectronicBidding ||
                parseDate(bidSetup.dueDate).isBefore(dayjs()) ||
                bids.some((b) => !b.isRetracted) ||
                bidDisabled
              }
              onClick={handleOpenBidDialog}
              color="primary"
              variant="contained"
              startIcon={<Publish />}
              style={{
                width: 'auto',
                paddingLeft: 32,
                paddingRight: 32,
                whiteSpace: 'nowrap',
                marginLeft: 16,
              }}
            >
              Submit Bid
            </Button>

            {isOrdering ? (
              <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 16 }}>
                <Typography align="center" style={{ fontWeight: 500 }}>
                  {orderPrintsMessage}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={orderPrintsProgress}
                  style={{ height: 8 }}
                />
                <Typography align="center" style={{ fontWeight: 500 }}>
                  {orderPrintsProgress}%
                </Typography>
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleOrderPrints}
                startIcon={<Description />}
                style={{ marginLeft: 16 }}
              >
                Order Prints
              </Button>
            )}
          </div>
          <Grid container spacing={2} style={{ fontFamily: 'Roboto' }}>
            <Grid item xs={12}>
              <SubmittedBids bidSetup={bidSetup} bids={bids} fetchBids={fetchBids} />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <ProjectDetails bidSetup={bidSetup} />
            </Grid>
            <Grid item xs={12} lg={6} xl={4} ref={initialBidDocRef}>
              <BidDocuments bidSetup={bidSetup} />
            </Grid>
            <Grid item xs={12} lg={6} xl={4} ref={addendaRef}>
              <Addenda bidSetup={bidSetup} />
            </Grid>
            <Grid item xs={12} lg={6} xl={4} ref={primeBidderRef}>
              <PrimeBidders bidSetup={bidSetup} />
            </Grid>
            <Grid item xs={12} lg={12} xl={8} ref={rfiRef}>
              <RFIs bidSetup={bidSetup} />
            </Grid>
            <Grid item xs={12} lg={12} xl={8} ref={substitutionRequestRef}>
              <SubstitutionRequests bidSetup={bidSetup} />
            </Grid>
            <Grid item xs={12} lg={6} xl={4} ref={moreInfoRef}>
              <MoreInfo bidSetup={bidSetup} />;
            </Grid>
          </Grid>
          <SubmitBid
            bidSetup={bidSetup}
            bids={bids}
            fetchBids={fetchBids}
            open={submitBidDialogOpen}
            handleClose={() => setSubmitBidDialogOpen(false)}
          />
        </ThemeProvider>
      </div>
      <div id="bottomShadow" />
      <div className="thumbContainer" id="thumbTT" style={{ display: 'none' }} />
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseError} severity="error" variant="filled">
          Could not download files. Please try again.
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProjectPage;
