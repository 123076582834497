import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Button, Popover, TextField } from '@material-ui/core';
import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import { modifyDocumentsByBatch } from '../../models/api/documents';
import { useDispatch } from 'react-redux';
import { reloadDocuments } from '../../features/documents/actions';
import CircularLoader from '../loader/CircularLoader';

type EditResponsiblePartyDialogProps = {
  dialogOpen: boolean;
  onClose: () => void;
  anchor?: any;
  selected: string[];
};

const useStyles = makeStyles(
  createStyles({
    popover: {
      '.rootElement > *': {
        maxHeight: '5000px',
      },
    },
    section: {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      minWidth: '270px',
    },
    button: {
      width: 100,
      height: 36,
    },
    textField: {
      width: '100%',
      marginBottom: 10,
    },
    helperText: {
      fontSize: 8,
    },
  }),
);

export default function EditResponsiblePartyDialog(props: EditResponsiblePartyDialogProps) {
  const classes = useStyles();
  const { dialogOpen, onClose, anchor, selected } = props;

  const dispatch = useDispatch();

  const [inputName, setInputName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);
      await modifyDocumentsByBatch(selected, { patch: { customContractor: inputName } });
      setIsSubmitting(false);
      handleCancel();
      dispatch(reloadDocuments());
    } catch (e: any) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setInputName('');
    onClose();
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <Popover
        className={classes.popover}
        open={dialogOpen}
        onClose={onClose}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box className={classes.section}>
            <Grid container direction="column">
              <TextField
                size="small"
                placeholder="Subcontractor Name"
                variant="outlined"
                value={inputName}
                onChange={(event) => {
                  setInputName(event.target.value);
                }}
                className={classes.textField}
              />
              <Grid container style={{ justifyContent: 'space-between' }}>
                <Button onClick={handleCancel} variant="outlined" className={classes.button}>
                  Cancel
                </Button>
                {!isSubmitting ? (
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!inputName}
                    className={classes.button}
                    variant="contained"
                  >
                    Update
                  </Button>
                ) : (
                  <CircularLoader size={25} />
                )}
              </Grid>
            </Grid>
          </Box>
        </form>
      </Popover>
    </MuiPickersUtilsProvider>
  );
}
