import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { formatDate } from '../../scripts/utils';
import { UserNotificationWithEmail } from '../../api-client/autogenerated';
import IconButton from '@material-ui/core/IconButton';
import { HighlightOffRounded } from '@material-ui/icons';
import dayjs from 'dayjs';

const useStyles = makeStyles({
  root: {
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
  },
  preview: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
});

type Props = {
  open: boolean;
  onClose: () => void;
  notification?: UserNotificationWithEmail | null;
};

export default function EmailPreviewDialog(props: Props) {
  const classes = useStyles();
  const { open, onClose, notification } = props;

  const getEmailFromHeader = () => {
    return notification?.email.Source;
  };

  const getEmailToHeader = () => {
    return notification?.email.Destination.ToAddresses?.[0];
  };

  const getEmailSubjectHeader = () => {
    return notification?.email.Message.Subject.Data;
  };

  const getEmailDateHeader = () => {
    return dayjs(notification?.createdOn).format('MM/DD/YYYY HH:mm A z');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{ style: { overflow: 'hidden' } }}
    >
      <div className={classes.root}>
        <IconButton
          onClick={onClose}
          style={{ padding: 0, position: 'absolute', top: 12, right: 12 }}
        >
          <HighlightOffRounded />
        </IconButton>
        <div style={{ padding: 16, backgroundColor: '#F9F9F9' }}>
          <Typography style={{ lineHeight: '20px' }}>From: {getEmailFromHeader()}</Typography>
          <Typography style={{ lineHeight: '20px' }}>To: {getEmailToHeader()}</Typography>
          <Typography style={{ lineHeight: '20px' }}>Date: {getEmailDateHeader()}</Typography>
          <Typography style={{ lineHeight: '20px', textIndent: '-58px', paddingLeft: '58px' }}>
            Subject: {getEmailSubjectHeader()}
          </Typography>
        </div>

        <iframe
          title="Email Preview"
          srcDoc={notification?.email.Message.Body.Html?.Data}
          className={classes.preview}
        />
      </div>
    </Dialog>
  );
}
