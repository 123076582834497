import {
  IInsertionSubscriber,
  IOptionalSubscriber,
  ISubscriber,
  ISubscriberAdminsSimplified,
} from '../../api-client/autogenerated';
import { ApiClient } from '../../api-client/api-client';

// GET //

// eslint-disable-next-line import/prefer-default-export
export const getSubscribers = async (limit = 50): Promise<ISubscriber[]> => {
  const response = await ApiClient.getAllSubscribers({ pageSize: limit });
  return response.data.results;
};

// eslint-disable-next-line import/prefer-default-export
export const getSubscriberById = async (id: string): Promise<ISubscriber> => {
  const response = await ApiClient.getSubscriberById({ subscriberId: id });
  return response.data;
};

export const getSubscriberAdminsSimplifiedBySubscriberId = async (
  id: string,
): Promise<ISubscriberAdminsSimplified> => {
  const response = await ApiClient.getSubscriberAdminsSimplifiedBySubscriberId({
    subscriberId: id,
  });
  return response.data;
};

export const getSubscriberProjectUserSummary = async (subscriberId: string) => {
  return ApiClient.getSubscriberProjectUserSummaryById({ subscriberId }).then((res) => res.data);
};

export const getAllProjectNamesBySubscriberId = async (subscriberId: string) => {
  return ApiClient.getAllProjectNamesBySubscriberId({ subscriberId }).then((res) => res.data);
};

// POST //

export const insertSubscriber = async (subscriber: IInsertionSubscriber): Promise<number> => {
  return (await ApiClient.insertSubscriber({ iInsertionSubscriber: subscriber })).status;
};

// PATCH //

export const updateSubscriber = async (
  subscriberId: string,
  subscriber: IOptionalSubscriber,
): Promise<number> => {
  return (
    await ApiClient.editSubscriberById({
      subscriberId,
      iOptionalSubscriber: subscriber,
    })
  ).status;
};

// DELETE //

export const deleteSubscriber = async (subscriberId: string): Promise<number> => {
  return (await ApiClient.deleteSubscriberById({ subscriberId })).status;
};
