import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { InboxPermissionLevel, SecurityPermissionLevel } from '../../../api-client/autogenerated';

interface PermissionControlProps {
  value?: SecurityPermissionLevel | InboxPermissionLevel | null;
  label: string;
  setValue?: React.Dispatch<React.SetStateAction<SecurityPermissionLevel | null>>;
  disabled: boolean;
  disableView?: boolean;
  disableComment?: boolean;
  disableCreate?: boolean;
  disableReview?: boolean;
  disableAdmin?: boolean;
  customLabels?: {
    viewLabel?: string;
    commentLabel?: string;
    createLabel?: string;
    reviewLabel?: string;
    adminLabel?: string;
  };

  customValues?: {
    viewValue?: SecurityPermissionLevel | null;
    commentValue?: SecurityPermissionLevel | null;
    createValue?: SecurityPermissionLevel | null;
    reviewValue?: SecurityPermissionLevel | null;
    adminValue?: SecurityPermissionLevel | null;
  };
}

export default function PermissionControl(props: PermissionControlProps) {
  const {
    value,
    label,
    setValue,
    disabled,
    disableView,
    disableComment,
    disableCreate,
    disableReview,
    disableAdmin,
    customLabels = {},
    customValues = {},
  } = props;

  const { viewLabel, commentLabel, createLabel, reviewLabel, adminLabel } = customLabels;
  const { viewValue, commentValue, createValue, reviewValue, adminValue } = customValues;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function DesktopRadioGroup() {
    return (
      setValue && (
        <Grid container>
          <RadioGroup
            style={{ width: '100%' }}
            row
            value={value}
            onChange={(event, val) => setValue(val ? parseInt(val, 10) : null)}
          >
            <Grid item xs={12} lg>
              <FormControlLabel value={null} control={<Radio color="primary" />} label="None" />
            </Grid>
            <Grid item xs={12} lg>
              {!disableView && (
                <FormControlLabel
                  value={viewValue ?? 0}
                  control={<Radio color="primary" />}
                  label={viewLabel ?? 'View'}
                />
              )}
            </Grid>
            <Grid item xs={12} lg>
              {!disableComment && (
                <FormControlLabel
                  value={commentValue ?? 1}
                  control={<Radio color="primary" />}
                  label={commentLabel ?? 'Comment'}
                />
              )}
            </Grid>
            <Grid item xs={12} lg>
              {!disableCreate && (
                <FormControlLabel
                  value={createValue ?? 2}
                  control={<Radio color="primary" />}
                  label={createLabel ?? 'Create'}
                />
              )}
            </Grid>
            <Grid item xs={12} lg>
              {!disableReview && (
                <FormControlLabel
                  value={reviewValue ?? 3}
                  control={<Radio color="primary" />}
                  label={reviewLabel ?? 'Review'}
                />
              )}
            </Grid>
            <Grid item xs={12} lg>
              {!disableAdmin && (
                <FormControlLabel
                  value={adminValue ?? 4}
                  control={<Radio color="primary" />}
                  label={adminLabel ?? 'Admin'}
                />
              )}
            </Grid>
          </RadioGroup>
        </Grid>
      )
    );
  }

  return (
    <>
      {isMobile && <Divider />}
      <FormControl disabled={disabled}>
        <Grid container>
          <Grid item xs={'auto'} lg={'auto'}>
            <Typography
              style={{
                width: 250,
                lineHeight: isMobile ? 'initial' : '42px',
                marginRight: isMobile ? 10 : 0,
                paddingTop: isMobile ? 12 : 0,
              }}
            >
              {label}
            </Typography>
          </Grid>
          <Grid item xs lg>
            {DesktopRadioGroup()}
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
}
