import { createMuiTheme } from '@material-ui/core/styles';
/* These overwrite the default Material UI styles to better reflect the style
      guidelines outlined on Figma */

// Colors
const primaryColor = '#0947B9'; // blue
const secondaryColor = '#F28B00'; // orange
const gray50 = '#F9F9F9';
const gray100 = '#EDECEC';
const gray200 = '#C9C8C9';
const gray300 = '#B2B1B2';
const gray400 = '#949494';
const gray700 = '#464546';
const blue100 = '#E6EEFF';
const blue400 = '#73A3FF';
const blue500 = '#4380ED';
const red400 = '#D02C14';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1760,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
      light: '#3D7FF0',
      contrastText: '#fff',
    },
    secondary: {
      main: secondaryColor,
      light: '#FF9C17',
      contrastText: '#fff',
    },
  },
  typography: {
    body1: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '18px',
      /* identical to box height */

      /* Gray / Gray 700 */

      color: gray700,
      textTransform: 'none',
    },
    body2: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '22px',
      /* identical to box height */

      /* Gray / Gray 700 */

      color: '#616061',
      textTransform: 'uppercase',
    },
    h1: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '34px',
      lineHeight: '15.23px',
      color: primaryColor,
    },
    h4: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '13px',
      lineHeight: '15.23px',
      color: '#A6C4FF',
      textTransform: 'uppercase',
    },
    subtitle1: {
      fontSize: 12,
      color: '#6B7876',
      fontFamily: 'Roboto',
    },
    subtitle2: {
      fontSize: 12,
      color: '#949494',
      fontFamily: 'Roboto',
      letterSpacing: 0,
    },
  },

  overrides: {
    MuiDivider: {
      light: {
        color: '#EDECEC',
        background: '#EDECEC',
        backgroundColor: '#EDECEC',
      },
    },
    MuiButton: {
      outlinedPrimary: {
        height: 32,
        border: '2px solid #0947B9',
        boxSizing: 'border-box',
        borderRadius: '4px',
        paddingLeft: '30px',
        paddingRight: '30px',
        '&:hover': {
          border: '2px solid #4380ED',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        '&:active': {
          backgroundColor: 'rgba(0,0,0,0)',
        },
      },
      containedSecondary: {
        '&:disabled': {
          backgroundColor: '#DCDCDC',
        },
      },
      outlinedSecondary: {
        height: 32,
        border: '2px solid #F28B00',
        boxSizing: 'border-box',
        borderRadius: '4px',
        paddingLeft: '30px',
        paddingRight: '30px',
        '&:hover': {
          border: '2px solid #FFAB08',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        '&:active': {
          backgroundColor: 'rgba(0,0,0,0)',
        },
      },
      containedPrimary: {
        height: 32,
        color: 'white',
        borderRadius: '4px',
        paddingLeft: '30px',
        paddingRight: '30px',
        backgroundColor: '#0947B9',
        '&:hover': {
          backgroundColor: '#4380ED',
        },
        '&:active': {
          backgroundColor: '#4380ED',
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&$disabled': {
          background: 'transparent',
          color: '#262626',
          //paddingLeft: 4,
          //paddingRight: 4,
          //border: '1px solid black',
        },
        background: 'white',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTable: {
      root: {
        color: '#616061',
        '&nth-of-type(even)': {
          backgroundColor: '#F9F9F9',
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: blue100,
        },
      },
    },
    MuiListItemText: {
      primary: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#CCDEFF',
        textTransform: 'none',
      },
      secondary: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#CCDEFF',
        textTransform: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '16px',
        lineHeight: '26px',
        textTransform: 'none',
      },
    },
    MuiAppBar: {
      // nav appbar
      colorPrimary: {
        backgroundColor: 'transparent',
      },
    },
    MuiPaper: {
      // card border
      elevation1: {
        boxShadow: 'none',
      },
      elevation4: {
        boxShadow: 'none',
      },
    },

    MuiCard: {
      root: {
        boxShadow: '0px 5px 15px rgb(0, 0, 0, .15)',
      },
    },

    MuiTableRow: {
      root: {
        '&:nth-of-type(even)': {
          backgroundColor: '#F9F9F9',
        },
      },
    },
    MuiTableHead: {
      root: {
        color: gray400,
      },
    },
    MuiTableBody: {
      root: {
        color: '#616061',
      },
    },
    MuiTableCell: {
      root: {
        padding: '8px 16px 8px 16px',
        borderBottom: 'none',
        color: 'inherit',
      },
      body: {
        color: 'inherit',
      },
      head: {
        color: 'inherit',
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: gray50,
        color: gray700,
        borderColor: gray400,
        borderRadius: '4px',
        fontFamily: 'Roboto',
        '&$disabled': {
          // read only fields
          backgroundColor: gray200,
        },
      },
    },
    MuiFormLabel: {
      // placeholder text
      root: {
        color: gray300,
        fontStyle: 'italic',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '18.5px 10px',
      },
    },
    MuiSelect: {
      select: {
        color: gray700,
        '&:not([multiple]) option': {
          backgroundColor: gray50,
          fontStyle: 'normal',
        },
      },
    },
    MuiTypography: {
      colorError: {
        color: red400,
      },
      colorTextPrimary: {
        color: gray700,
      },
      colorTextSecondary: {
        color: gray400,
      },
    },
    MuiLink: {
      root: {
        color: blue500,
        textTransform: 'none',
        fontStyle: 'bold',
        '&:hover': {
          color: blue400,
        },
      },
    },
  },

  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
    MuiIconButton: {
      disableFocusRipple: true,
    },
    MuiSelect: {
      // @ts-ignore
      MenuProps: { getContentAnchorEl: () => null },
    },
  },
});

export default function getCustomTheme() {
  return theme;
}
