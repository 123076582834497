import React, { useState } from 'react';
import { Button, Collapse, TableCell, TableRow, Typography } from '@material-ui/core';
import { openInNewWindow, parseDate } from '../../scripts/utils';
import { IFile } from '../../api-client/autogenerated';
import { addSpacing } from '../document-index/DocumentIndexUtils';

export type FileInfo = {
  file: IFile;
  missingSections?: { code: string; description: string }[];
  tableOfContentsFileId?: string;
};

type Props = {
  fileInfo: FileInfo;
};

export default function SpecificationJobTableRow(props: Props) {
  const {
    fileInfo: { file, tableOfContentsFileId, missingSections },
  } = props;

  const [rowVisible, setRowVisible] = useState(false);
  const [open, setOpen] = useState(false);

  const toggleRow = () => {
    if (!open) {
      setRowVisible(true);
      setTimeout(() => setOpen(true), 25);
    } else {
      setOpen(false);
      setTimeout(() => setRowVisible(false), 150);
    }
  };

  return (
    <>
      <TableRow key={file.id}>
        <TableCell
          title={file.name}
          style={{
            whiteSpace: 'nowrap',
            maxWidth: 180,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {file.name}
        </TableCell>
        <TableCell style={{ whiteSpace: 'nowrap' }}>
          {parseDate(file.createdOn!).format('MM/DD/YYYY hh:mm A')}
        </TableCell>
        <TableCell>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button
              variant="contained"
              color="primary"
              disabled={!tableOfContentsFileId}
              onClick={() => openInNewWindow(tableOfContentsFileId!)}
              style={{
                whiteSpace: 'nowrap',
                padding: '0px 12px',
                marginRight: 8,
              }}
            >
              View Table Of Contents
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleRow}
              style={{ whiteSpace: 'nowrap', padding: '0px 12px' }}
            >
              Sections Requiring Manual Match
            </Button>
          </div>
        </TableCell>
      </TableRow>
      {rowVisible && (
        <TableRow>
          <TableCell colSpan={3}>
            <Collapse in={open}>
              <div style={{ width: '100%', padding: '8px 48px 8px 8px' }}>
                {missingSections?.map(({ code, description }) => (
                  <div
                    key={code}
                    style={{
                      width: '100%',
                      display: 'flex',
                      marginBottom: 4,
                    }}
                  >
                    <Typography style={{ whiteSpace: 'pre' }}>{`${addSpacing(
                      code,
                    )}  —  ${description}`}</Typography>
                  </div>
                ))}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
