import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import {
  DocumentTemplateType,
  INotification,
  INumberedDocumentView,
  NotificationType,
  SimpleUserNotificationSendingStatus,
} from '../../api-client/autogenerated';
import { Button, capitalize, useMediaQuery, useTheme } from '@material-ui/core';
import {
  documentTypeToReadable,
  getActionTakenText,
  getSubmittalTitle,
  getTitle,
  getUserFriendlyDocumentFields,
  parseDate,
} from '../../scripts/utils';
import { useSelector } from 'react-redux';
import { getDocumentsType } from '../../features/documents/selectors';
import _ from 'lodash';
import { getAllProjectUsersState } from '../../features/project/selectors';
import { ascendingComparator } from '../document-index/DocumentIndexUtils';
import UserFilterListDialog from '../dialogs/UserFilterListDialog';

interface ActivityItemProps {
  notification: INotification;
  notificationRecipients?: SimpleUserNotificationSendingStatus[];
  viewRecipients?: () => void;
  isPlanholder?: boolean;
  rootStyle?: React.CSSProperties;
}

const primaryColor = '#0947B9'; // blue
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noPadding: {
      padding: 1,
    },
    padding: {
      padding: 5,
    },
    activityStatus: {
      fontWeight: 'bold',
      color: primaryColor,
    },
    company: {
      fontStyle: 'italic',
      color: '#949494', // gray
    },
    message: {
      color: '#000', // black
      whiteSpace: 'pre',
    },
    shortDate: {
      marginRight: theme.spacing(2),
      marginLeft: 0,
    },
    iconText: {
      color: '#000', // black
      paddingLeft: 0,
      fontSize: 14,
    },
    icon: {
      minWidth: 0,
      paddingRight: theme.spacing(2),
      marginLeft: 0,
    },
  }),
);
export default function ActivityItem(props: ActivityItemProps) {
  const classes = useStyles();
  const { notification, isPlanholder, rootStyle, notificationRecipients } = props;
  const theme = useTheme();
  const docType = useSelector(getDocumentsType);
  const projectUsers = useSelector(getAllProjectUsersState);
  const [recipientDialogOpen, setRecipientDialogOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [primaryUser, setPrimaryUser] = useState('');
  const [secondaryUser, setSecondaryUser] = useState('');
  const [primaryCompany, setPrimaryCompany] = useState('');
  const [secondaryCompany, setSecondaryCompany] = useState('');
  const [action, setAction] = useState('');
  const [text, setText] = useState('');
  const [status, setStatus] = useState('');

  const getDocumentTitle = (document?: INumberedDocumentView | null) => {
    if (document) {
      if (document.documentTemplate) {
        return `${
          documentTypeToReadable[document.documentTemplate.name as DocumentTemplateType]
        } #${document.documentNumber!}`;
      }
    }
    return 'a document';
  };

  useEffect(() => {
    setPrimaryUser(notification.initiatorUser?.name || 'A user');
    setPrimaryCompany(
      notification.initiatorUser?.company?.name ||
        notification.initiatorUser?.publicCompanyName ||
        'Unknown Company',
    );

    switch (notification.format) {
      case NotificationType.DocumentCreated:
        const procoreAssignerName =
          notification.document?.procoreResponsibleContractorName ??
          notification.document?.procoreCreatedByName;
        const procoreAssigneeName = notification.document?.procoreBallInCourtName;
        if (!isPlanholder && procoreAssignerName && procoreAssigneeName) {
          setAction(
            `${procoreAssignerName} assigned this document to ${procoreAssigneeName} in Procore.\nDocument created in CENTERLINE via Procore Sync.`,
          );
        } else if (!isPlanholder && notification.document?.isProcoreOriginated) {
          setAction(`Document created in CENTERLINE via Procore Sync`);
        } else if (!isPlanholder) setAction('created this document');
        else if (notification.document?.documentTemplate)
          setAction(
            `uploaded ${
              notification.document.documentTemplate.name === DocumentTemplateType.Addenda ||
              notification.document.documentTemplate.name ===
                DocumentTemplateType.InformationalItems
                ? 'an'
                : 'a'
            } ${notification.document?.documentTemplate.name} file to the bid package`,
          );
        else setAction(`uploaded a file to the bid package`);
        break;

      case NotificationType.DocumentDeleted:
        setAction('deleted this document');
        break;

      case NotificationType.DocumentRestored:
        setAction('restored this document');
        break;

      case NotificationType.DocumentSubcontractorChanged:
        setAction('was assigned to this document');
        if (notification.targetUser) {
          setSecondaryUser(notification.targetUser.name);
          setSecondaryCompany(
            notification.targetUser.company?.name ||
              notification.targetUser.publicCompanyName ||
              'Unknown Company',
          );
        } else {
          setSecondaryUser('A new subcontractor');
        }
        break;

      case NotificationType.DocumentSubcontractorUploaded:
        setAction('uploaded 1 file');
        if (notification.file) setText(notification.file.name);
        break;

      case NotificationType.DocumentFileCreated:
        setAction('uploaded 1 file');
        if (notification.file) setText(notification.file.name);
        break;

      case NotificationType.DocumentFileLocked:
        setAction('locked file uploads');
        break;

      case NotificationType.DocumentSubcontractorUploadAccepted:
        setAction('marked as ');
        setStatus('Approved');
        break;

      case NotificationType.DocumentSubcontractorUploadRejected:
        setAction('marked as ');
        setStatus('Rejected');
        break;

      case NotificationType.DocumentSubmittedForReview:
        if (notification.document?.documentTemplate?.name)
          setAction(
            `submitted ${getTitle(
              notification.document.documentTemplate.name as DocumentTemplateType,
              notification.document,
            )} for review`,
          );
        else setAction(`submitted ${getDocumentTitle(notification.document)} for review`);
        break;

      case NotificationType.DocumentSubmittedForInformationOnly:
        if (notification.document?.documentTemplate?.name)
          setAction(
            `submitted ${getTitle(
              notification.document.documentTemplate.name as DocumentTemplateType,
              notification.document,
            )} for information only`,
          );
        else setAction(`submitted ${getDocumentTitle(notification.document)} for information only`);
        break;

      case NotificationType.DocumentWithdrawn:
        if (notification.document?.documentTemplate?.name)
          setAction(
            `withdrew ${getTitle(
              notification.document.documentTemplate.name as DocumentTemplateType,
              notification.document,
            )}`,
          );
        else setAction(`withdrew ${getDocumentTitle(notification.document)}`);
        break;

      case NotificationType.DocumentProcoreSyncMismatch:
        setAction(
          notification.genericText ?? `failed to sync with Procore - Procore document missing`,
        );
        break;

      case NotificationType.DocumentConsultantAdded:
        if (notification.targetUser) {
          setAction('added consultant ');
          setSecondaryUser(notification.targetUser.name);
          setSecondaryCompany(
            notification.targetUser.company?.name ||
              notification.targetUser.publicCompanyName ||
              'Unknown Company',
          );
        } else {
          setAction('added a new consultant');
        }
        break;

      case NotificationType.DocumentConsultantFinished:
        setAction('finished their confidential review');
        break;

      case NotificationType.DocumentDueDateChanged:
        setAction(
          `changed this document's due date${
            notification.genericDate
              ? ` to ${parseDate(notification.genericDate).format('M/DD/YYYY')}`
              : ''
          }`,
        );
        break;

      case NotificationType.DocumentCommentAdded:
        setAction('posted a new comment ');
        break;

      case NotificationType.DocumentComplete:
        setAction('marked as ');
        if (
          (docType === DocumentTemplateType.AsBuilt ||
            docType === DocumentTemplateType.AsBuiltPackages) &&
          !!notification.document?.actionTaken
        ) {
          setStatus(getActionTakenText(notification.document.actionTaken));
        } else {
          setStatus('Complete');
        }
        break;

      case NotificationType.DocumentViewed:
        setAction('viewed this document');
        break;

      case NotificationType.DocumentUpdated:
        setAction('updated this document');
        break;

      case NotificationType.DocumentAssociationsModified:
        const associationsModified = notification.documentAssociationModifications;
        const modifications = associationsModified?.modifications ?? [];

        const removals = modifications.filter((m) => m.isRemoval);
        const additions = modifications.filter((m) => !m.isRemoval);

        const removalTexts = removals.map(
          (r) => `Removed association with ${r.userFriendlyIdentifier}`,
        );
        const additionTexts = additions.map(
          (a) => `Added association to ${a.userFriendlyIdentifier}`,
        );

        let removalText = removalTexts.length > 0 ? `\n\t${removalTexts.join('\n\t')}` : '';
        let additionText = additionTexts.length > 0 ? `\n\t${additionTexts.join('\n\t')}` : '';

        if (removals.length + additions.length === 1) {
          if (removals.length === 1) {
            setAction(`removed association with ${removals[0].userFriendlyIdentifier}`);
          } else {
            setAction(`added association to ${additions[0].userFriendlyIdentifier}`);
          }
        } else if (removalText || additionText) {
          setAction(`updated the associated documents list:${additionText}${removalText}`);
        } else {
          setAction('updated the associated documents list');
        }
        break;

      case NotificationType.DocumentFollowersUpdated:
        const followersUpdated = notification.followersUpdated;
        if (
          followersUpdated == null ||
          followersUpdated.users == null ||
          followersUpdated.userGroups == null
        ) {
          setAction('updated the notification list');
          break;
        }
        const userTexts = followersUpdated.users.map((u) => {
          const user = projectUsers.find((pUser) => pUser.userId === u.id)?.user;
          if (user && user.company) {
            return `${user.name} from ${user.company.name} (${user.email})`;
          }
          if (user) {
            return `${user.name} (${user.email})`;
          }
          return u.email;
        });
        const userText = userTexts ? `\n\t\t${userTexts.join('\n\t\t')}` : 'None';
        const userGroupText =
          followersUpdated.userGroups.length > 0
            ? `\n\t\t${followersUpdated.userGroups.map((ug) => ug.name).join('\n\t\t')}`
            : 'None';
        let actionText =
          `updated the notification list:` +
          `\n\tUsers: ${userText}` +
          `\n\tUser Groups: ${userGroupText}`;
        setAction(actionText);
        break;

      case NotificationType.DocumentFieldsUpdated:
        const filteredFieldsUpdated = _.omitBy(
          notification.fieldsUpdated,
          (_, key) => key.endsWith('Id') || ['dateOfLastUpdate'].includes(key),
        );
        const keyValueList = _.isEmpty(filteredFieldsUpdated)
          ? []
          : getUserFriendlyDocumentFields(filteredFieldsUpdated, docType);
        if (keyValueList.length === 0) {
        } else if (keyValueList.length === 1) {
          setAction(`changed this document's ${keyValueList[0].key} to ${keyValueList[0].value}`);
        } else {
          const pairText = keyValueList.map((row) => {
            return `\t${row.key} changed to ${row.value}`;
          });
          setAction(`modified the following fields:\n${pairText.join('\n')}`);
        }
        break;

      case NotificationType.DocumentFileViewed:
        setAction('viewed a file');
        if (notification.file) setText(notification.file.name);
        break;

      case NotificationType.DocumentFileDeleted:
        setAction('deleted a file');
        if (notification.deletedFileJsonb) setText(notification.deletedFileJsonb.name);
        break;

      case NotificationType.DocumentFileDownloaded:
        setAction('downloaded a file');
        if (notification.file) setText(notification.file.name);
        break;

      case NotificationType.DocumentFileOpenedBluebeam:
        setAction('opened a file in Bluebeam');
        if (notification.file) setText(notification.file.name);
        break;

      case NotificationType.DocumentDocumentRemovedFromPackage:
        setAction('removed a document from this package');
        if (notification.targetDocument) setText(getSubmittalTitle(notification.targetDocument));
        break;

      case NotificationType.DocumentSeparatedFromPackage:
        setAction('removed this document from a package');
        if (notification.targetDocument)
          setText(`Submittal Package ${notification.targetDocument.referenceNumber}`);
        break;

      case NotificationType.DocumentAddedToPackage:
        setAction('added this document to a package');
        if (notification.targetDocument)
          setText(`Submittal Package ${notification.targetDocument.referenceNumber}`);
        break;

      case NotificationType.ProjectBidSetupViewed:
        setAction('viewed this bid project on the bid module');
        break;

      case NotificationType.ProjectBidSetupDownloaded:
        setAction('downloaded the bid package on the bid module');
        break;

      case NotificationType.ProjectUserUnsubscribed:
        setAction('unsubscribed from this project');
        break;

      case NotificationType.ProjectBidSetupFileCreated:
        setAction('uploaded a file to the bid package');
        if (notification.file) setText(notification.file.name);
        break;

      case NotificationType.ProjectBidSubmitted:
        setAction('submitted a bid');
        break;

      case NotificationType.ProjectBidRetracted:
        setAction('retracted a bid');
        break;

      case NotificationType.BidFileViewed:
        setAction('viewed a bid file');
        if (notification.file) setText(notification.file.name);
        break;

      case NotificationType.BidFileDownloaded:
        setAction('downloaded a bid file');
        if (notification.file) setText(notification.file.name);
        break;

      case NotificationType.DocumentUndoCompleteReview:
        setAction('reopened this document for revision');
        break;

      case NotificationType.DocumentDueDateWarning:
        setAction('sent a due date warning');
        break;

      case NotificationType.DocumentPublished:
        setAction('published this document');
        break;

      case NotificationType.DocumentRespondedToReview:
        setAction('marked as ');
        setStatus(capitalize(notification.genericText!));
        break;
    }

    if (notification.copiedFromDocument)
      setAction(
        (prev) =>
          prev +
          ` (activity from Submittal Package ${notification.copiedFromDocument!.referenceNumber})`,
      );
  }, [notification]);

  if (
    primaryCompany.includes('undefined') ||
    primaryUser.includes('undefined') ||
    secondaryCompany.includes('undefined') ||
    secondaryUser.includes('undefined') ||
    (action as string).includes('undefined') ||
    !action ||
    text.includes('undefined')
  ) {
    return null;
  }

  return (
    <ListItem alignItems="flex-start" className={classes.noPadding} style={rootStyle}>
      <ListItemText
        primary={
          <>
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              style={{
                width: '100%',
                paddingTop: 8,
              }}
              direction={isSmallScreen ? 'column' : 'row'}
            >
              <Grid
                item
                xs
                md={5}
                style={{
                  paddingRight: 70,
                  maxWidth: 'auto',
                  whiteSpace: 'nowrap',
                  flexBasis: 0,
                }}
              >
                <Typography
                  color="textSecondary"
                  className={classes.shortDate}
                  style={{ width: 'fit-content' }}
                >
                  {parseDate(notification.createdOn!).format('M/DD/YYYY h:mm A')}
                </Typography>
              </Grid>
              <Grid item xs style={{ width: isSmallScreen ? '' : 800 }}>
                <Typography className={classes.message}>
                  <b>{primaryUser}</b>, <span className={classes.company}>{primaryCompany}</span>,{' '}
                  {action}
                  <b>{secondaryUser}</b>
                  {secondaryUser && secondaryCompany ? (
                    <>
                      <b>, </b>
                      <span className={classes.company}>{secondaryCompany}</span>
                    </>
                  ) : undefined}
                  <span className={classes.activityStatus}>{status}</span>
                </Typography>
                <Typography className={classes.padding} style={{ fontStyle: 'italic' }}>
                  {text}
                </Typography>
              </Grid>
              {notificationRecipients &&
                notificationRecipients.length > 0 &&
                notification.format !== NotificationType.DocumentRespondedToReview && (
                  <Grid item xs justify={'flex-end'} style={{ display: 'flex' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setRecipientDialogOpen(true)}
                      style={{
                        marginRight: 32,
                        marginLeft: 32,
                        marginTop: -6,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      View Recipients
                    </Button>
                  </Grid>
                )}
            </Grid>
            {recipientDialogOpen && (
              <UserFilterListDialog
                title="Recipients"
                users={
                  (notificationRecipients || [])
                    .map((r) => ({ ...r.user, emailStatus: r.emailStatus }))
                    .sort((a, b) => ascendingComparator(a, b, 'name')) || []
                }
                dialogOpen={recipientDialogOpen}
                handleClose={() => setRecipientDialogOpen(false)}
                emptyText="No recipients of this Notification."
                isActivityLog
              />
            )}
          </>
        }
        secondary={<></>}
      />
    </ListItem>
  );
}
