import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { INotification } from '../../api-client/autogenerated';
import { getNotificationsByUserId } from '../../models/api/notifications';
import List from '@material-ui/core/List';
import DashboardNotificationsItem from '../dashboard/DashboardNotificationsItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { HighlightOffRounded, Print } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import CircularLoader from '../loader/CircularLoader';
import { getProjectNameFromNotification } from '../../scripts/store-utils';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowRightIcon from '@material-ui/icons/ArrowForward';
import { useReactToPrint } from 'react-to-print';
import Tooltip from '@material-ui/core/Tooltip';

type Props = {
  open: boolean;
  handleClose: () => void;
  userId: string;
  projectId?: string;
  name: string;
  disablePrint?: boolean;
};

const useStyles = makeStyles({
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '23px',
    lineHeight: '26px',
    textAlign: 'left',
    color: '#0947B9',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 16px 8px 16px',
    borderBottom: '1px solid #000',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconButton: {
    right: '20px',
    top: '18px',
    position: 'absolute',
  },
  dateText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#616061',
    paddingLeft: 18,
  },
  subtitle: {
    margin: '4px 0px 0px 6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000', // Blue / Blue 700 Brand Dark
  },
});

export default function UserActivityDialog(props: Props) {
  const classes = useStyles();
  const { open, userId, projectId, name, handleClose, disablePrint } = props;

  const printRef = useRef(null);

  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [allNotifications, setAllNotifications] = useState<INotification[]>([]);
  const [total, setTotal] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const fetchNotifications = () => {
    if (userId) {
      setIsLoading(true);
      getNotificationsByUserId(userId, projectId, 25, currentPage)
        .then(({ results, total, totalPages }) => {
          setNotifications(results);
          setTotal(total);
          setNumPages(totalPages);
        })
        .catch(() => setNotifications([]))
        .finally(() => setIsLoading(false));
    }
  };

  const renderNotifications = (all = false) => {
    return (all ? allNotifications : notifications).map((n, i) => {
      return (
        <>
          <DashboardNotificationsItem
            userNotificationId={n.id}
            notification={n}
            projectName={getProjectNameFromNotification(n)}
          />
          {i !== notifications.length - 1 && <Divider variant="fullWidth" component="li" />}
        </>
      );
    });
  };

  const handlePrev = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  useEffect(() => {
    if (open) fetchNotifications();
    else {
      setCurrentPage(0);
      setAllNotifications([]);
    }
  }, [open, userId, currentPage]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () =>
      new Promise<void>((resolve) => {
        setIsPrinting(true);
        setTimeout(async () => {
          if (allNotifications.length === 0) {
            const { results } = await getNotificationsByUserId(userId, undefined, 2000);
            setAllNotifications(results);
          }
          resolve();
        }, 500);
      }),
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { minHeight: '12%' } }}
    >
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title}>{`${name}'s Activity`}</Typography>

        {total > 0 && (
          <div style={{ display: 'flex' }}>
            <Typography className={classes.subtitle}>{`${total} Total`}</Typography>
            {!isPrinting ? (
              <>
                {!disablePrint && (
                  <Tooltip title="Print" arrow placement="right">
                    <IconButton size="small" style={{ marginLeft: '4px' }}>
                      <Print style={{ display: 'inline-flex' }} onClick={handlePrint} />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            ) : (
              <div style={{ display: 'flex' }}>
                <CircularLoader size={20} style={{ marginLeft: '6px' }} />
                <Typography className={classes.subtitle}>Printing, please wait...</Typography>
              </div>
            )}
          </div>
        )}
        <IconButton
          className={classes.iconButton}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={notifications.length > 0 ? { padding: 0, borderBottom: '1px solid #000' } : {}}
      >
        <List ref={printRef} disablePadding>
          {isLoading ? (
            <CircularLoader />
          ) : (
            <>
              {notifications.length > 0 ? (
                renderNotifications(isPrinting)
              ) : (
                <Typography style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  No user activity.
                </Typography>
              )}
            </>
          )}
        </List>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        {numPages > 1 && (
          <Button onClick={handlePrev} disabled={currentPage === 0}>
            <ArrowBackIcon />
          </Button>
        )}
        {numPages > 0 && (
          <Typography>
            {currentPage + 1}/{numPages}
          </Typography>
        )}
        {numPages > 1 && (
          <Button onClick={handleNext} disabled={currentPage + 1 === numPages}>
            <ArrowRightIcon />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
