import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Auth } from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOriginalUser, getUserState } from '../../features/user/selectors';
import { setUser } from '../../features/user/actions';
import { getProjectState } from '../../features/project/selectors';
import { setProject } from '../../features/project/actions';
import { resetImpersonation } from '../../models/api/users';
import { getCurrentSecurityGroup } from '../../features/security/selectors';
import { hasAdminPermissions } from '../../scripts/store-utils';
import useMediaQuery from '@material-ui/core/useMediaQuery';

type UserDropdownProps = {
  isBiddingPortal?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    avatar: {
      marginRight: '10px',
      height: '22px',
      width: '22px',
    },
    userName: {
      [theme.breakpoints.down('xs')]: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
  }),
);

function UserDropdown(props: UserDropdownProps) {
  const classes = useStyles();
  const { isBiddingPortal } = props;
  const user = useSelector(getUserState);
  const originalUser = useSelector(getOriginalUser);
  const security = useSelector(getCurrentSecurityGroup);
  const project = useSelector(getProjectState);
  const dispatch = useDispatch();
  const history = useHistory();
  const hideName = useMediaQuery('(min-width:450px)');

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleSignOut = () => {
    Auth.signOut()
      .then(() => {
        dispatch(setUser());
        dispatch(setProject());
        history.push(isBiddingPortal ? '/bidding/login' : '/main/login');
      })
      .catch((e) => console.log('error signing out: ', e));
  };

  const handleMainPortalSettings = () => {
    history.push('/main/settings');
  };

  const handleBiddingPortalSettings = () => {
    history.push('/bidding/account');
  };

  const handleManageProject = () => {
    history.push(`/main/projects/${project!.id}/manage`);
  };

  const handleManageSecurityGroups = () => {
    history.push('/main/security-groups');
  };

  const handleResetImpersonation = async () => {
    await resetImpersonation();
    window.location.reload();
  };

  const handleManageUserGroups = () => {
    history.push(`/main/projects/${project!.id}/user-groups`);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        paddingLeft: '6px',
      }}
    >
      {isBiddingPortal ? (
        <Button
          id="user-dropdown-button"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="default"
          variant="text"
        >
          <Avatar color="primary" className={classes.avatar} />
          <span className={classes.userName}>{user.name}</span>
          <ArrowDropDownIcon style={{ color: '#7A797A', marginLeft: '5px' }} />
        </Button>
      ) : (
        <Button
          id="user-dropdown-button"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Avatar color="primary" className={classes.avatar} />
          {hideName && <div className={classes.userName}>{user.name}</div>}
          <ArrowDropDownIcon style={{ color: '#7A797A', marginLeft: '5px' }} />
        </Button>
      )}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 100000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            {/* card shadow from customtheme.tsx */}
            <Paper style={{ boxShadow: '0px 5px 15px rgb(0, 0, 0, .15)' }}>
              <ClickAwayListener onClickAway={handleClose}>
                {isBiddingPortal ? (
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={handleBiddingPortalSettings}>Account Settings</MenuItem>
                    <Divider style={{ color: '#EDECEC' }} />
                    <MenuItem
                      onClick={(event) => {
                        handleClose(event);
                        handleSignOut();
                      }}
                    >
                      Sign Out
                    </MenuItem>
                  </MenuList>
                ) : (
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {project &&
                      (hasAdminPermissions() || !!security?.manageProjectUsersPermission) && (
                        <MenuItem onClick={handleManageProject}>Manage Project</MenuItem>
                      )}
                    {project &&
                      (hasAdminPermissions() || (security && security.canModifyUserGroups)) && (
                        <MenuItem onClick={handleManageUserGroups}>Manage User Groups</MenuItem>
                      )}
                    {(user.isSiteAdmin || user.adminOfSubscriberId !== null) && (
                      <MenuItem onClick={handleManageSecurityGroups}>
                        Manage Security Groups
                      </MenuItem>
                    )}

                    <MenuItem onClick={handleMainPortalSettings}>Account Settings</MenuItem>
                    <Divider style={{ color: '#EDECEC' }} />
                    {originalUser && (
                      <>
                        <MenuItem onClick={handleResetImpersonation}>Reset Impersonation</MenuItem>
                        <Divider style={{ color: '#EDECEC' }} />
                      </>
                    )}
                    <MenuItem
                      onClick={(event) => {
                        handleClose(event);
                        handleSignOut();
                      }}
                    >
                      Sign Out
                    </MenuItem>
                  </MenuList>
                )}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default UserDropdown;
