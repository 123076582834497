import { action } from 'typesafe-actions';
import { Dispatch } from 'react';
import { SET_DOCUMENT, SET_DOCUMENT_WITH_PERMISSIONS, UPDATE_DOCUMENT } from './constants';
import { Document } from './document';
import {
  getDocumentById,
  getDocumentByKey,
  getDocumentUserPermissionByDocumentId,
} from '../../models/api/documents';

export const setDocument = (document: Document = null) => {
  if (!document) {
    return action(SET_DOCUMENT_WITH_PERMISSIONS, { document, permissions: [] });
  }
  return action(SET_DOCUMENT, document);
};

export const updateDocument = (document: Document): any => async (dispatch: Dispatch<any>) => {
  return dispatch({ type: UPDATE_DOCUMENT, payload: document });
};

export const reloadDocument = () => (dispatch: Dispatch<any>, getState: () => any) => {
  const { id } = getState().document.document;
  return dispatch(fetchDocument(id));
};

export const fetchDocument = (documentId: string, publicDoc = false): any => (
  dispatch: Dispatch<any>,
) => {
  dispatch({ type: 'SET_DOCUMENT_LOADING', payload: true });
  if (!publicDoc)
    return getDocumentById(documentId)
      .then(async (response) => {
        const permissions = await getDocumentUserPermissionByDocumentId(response.id);
        return dispatch({
          type: SET_DOCUMENT_WITH_PERMISSIONS,
          payload: { document: response, permissions },
        });
      })
      .catch()
      .finally(() => dispatch({ type: 'SET_DOCUMENT_LOADING', payload: false }));
  return getDocumentByKey(documentId)
    .then((response) => {
      localStorage.setItem('publicEmail', response.email);
      sessionStorage.setItem('publicUserId', response.userId);
      return dispatch({ type: SET_DOCUMENT, payload: response.document });
    })
    .catch()
    .finally(() => dispatch({ type: 'SET_DOCUMENT_LOADING', payload: false }));
};
