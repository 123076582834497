import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Card, Grid, TextField, Tooltip, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Auth } from '@aws-amplify/auth';
import { Link, useHistory } from 'react-router-dom';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { getUserState } from '../features/user/selectors';
import { CognitoUserStatus, DisciplineType } from '../api-client/autogenerated';
import CenterlineIcon from '../images/CenterlineLogo.png';
import ReCAPTCHA from 'react-google-recaptcha';
import { getPublicUserInfoByEmail, getRegistrationUserByKey } from '../models/api/users';
import CircularLoader from './loader/CircularLoader';
import { addSnackbar } from '../features/snackbar/actions';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  card: {
    display: 'flex',
    background: 'white',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectList: {
    maxHeight: 400,
  },

  lineCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  avatar: {
    margin: 8,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 8,
  },
  submit: {
    marginTop: 16,
    marginBottom: 24,
    maxWidth: 240,
  },
  textField: {
    maxWidth: 350,
  },
  checkbox: {
    marginTop: 8,
    width: 400,
  },
  links: {
    maxWidth: 400,
  },
  nameContainer: {
    maxWidth: 500,
  },
  nameField: {
    maxWidth: 300,
  },
  link: {
    textDecoration: 'none',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#0947B9',
    '&:hover': {
      color: '#4380ED',
    },
  },
});

function MainPortalRegister() {
  const classes = useStyles();

  const user = useSelector(getUserState);
  const history = useHistory();
  const dispatch = useDispatch();

  const [inputFirstName, setInputFirstName] = useState('');
  const [inputLastName, setInputLastName] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [captchaValue, setCaptchaValue] = useState('');
  const [associatedDisciplines, setAssociatedDisciplines] = useState<DisciplineType[]>([]);
  const [errorText, setErrorText] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [unauthorizedText, setUnauthorizedText] = useState<string | undefined>(undefined);

  interface AuthenticationError {
    code: string;
    name: string;
    message: string;
  }

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!captchaValue) {
        setIsLoading(false);
        setErrorText('Fill out the captcha. If captcha is not shown, refresh page.');
      } else if (inputPassword === repeatPassword && inputLastName !== '') {
        const disciplines = associatedDisciplines.length > 0 ? associatedDisciplines.join('|') : '';

        // Amplify has bug - validationData should should an object of key:value
        const captchaValidationAttribute: any = {
          captchaValue,
        };

        await Auth.signUp({
          username: inputEmail,
          password: inputPassword,
          attributes: {
            given_name: `${inputFirstName} ${inputLastName}`,
            // 'custom:discipline': disciplines,
          },
          validationData: captchaValidationAttribute,
        });

        setIsLoading(false);
        setErrorText('');
        setRegistered(true);
      } else {
        setErrorText('Passwords must match');
      }
    } catch (error: any) {
      setIsLoading(false);
      const message = error.toString();
      if (message.includes('AuthError')) {
        if (message.includes('Username')) setErrorText('Email Address cannot be empty');
        else setErrorText(message.slice(message.indexOf(':') + 2));
      }
      const errorObj = error as AuthenticationError;

      if (errorObj.code === 'UsernameExistsException') {
        setRegistered(true);
      }

      if (errorObj.code === 'InvalidParameterException') {
        if (errorObj.message.includes('Username should be an email'))
          setErrorText('Email address must be a valid email');
        else setErrorText('Password must have at least 8 characters');
      }

      if (errorObj.code === 'InvalidPasswordException') {
        if (errorObj.message.includes('Password not long enough'))
          setErrorText('Password must have at least 8 characters');
        else if (errorObj.message.includes('Password must have uppercase characters'))
          setErrorText('Password must have uppercase characters');
        else if (errorObj.message.includes('Password must have lowercase characters'))
          setErrorText('Password must have lowercase characters');
        else if (errorObj.message.includes('Password must have numeric characters'))
          setErrorText('Password must have numeric characters');
        else if (errorObj.message.includes('Password must have symbol characters'))
          setErrorText('Password must have symbol characters');
      }
      console.log('error signing up:', error);
    }
  };

  const handleConfirm = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await Auth.confirmSignUp(inputEmail, confirmationCode.replace(' ', ''));
      setErrorText('');
      history.push('/main/login');
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setErrorText(error.message);
      console.log('error confirming sign up: ', error);
      if (error.code === 'UserLambdaValidationException') history.push('/main/login');
      // TODO: remove history.push when error is fixed; add error handling for different exceptions
    }
  };

  const passwordOptions = {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
  };

  const verifyCaptcha = (value: string | null) => {
    setCaptchaValue(value || '');
  };

  const isValidEmail = (email: string) => {
    if (email.length === 0) return true;
    return validator.isEmail(email);
  };

  const isValidPassword = (password: string) => {
    if (password.length === 0) return true;
    return validator.isStrongPassword(password, passwordOptions);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputEmail(event.target.value);
    setEmailError(!isValidEmail(event.target.value));
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPassword(event.target.value);
    setPasswordError(!isValidPassword(event.target.value));
  };

  const handleResendCode = async () => {
    await Auth.resendSignUp(inputEmail);
    dispatch(
      addSnackbar({ id: Date.now(), message: 'Confirmation email sent!', severity: 'success' }),
    );
  };

  useEffect(() => {
    if (user.id) history.push('/main');
  }, [user]);

  useEffect(() => {
    if (window.location.search) {
      const url = new URL(window.location.href.replace('&#x3D;', '='));
      const key = url.search.replace('/', '').replace('?', '').replace('key=', '');
      getRegistrationUserByKey(key)
        .then(async (res) => {
          if (res.email) {
            const { cognitoUserStatus } = await getPublicUserInfoByEmail(res.email);
            if (cognitoUserStatus === CognitoUserStatus.Confirmed) {
              history.push('/main/login');
              dispatch(
                addSnackbar({
                  id: Date.now(),
                  message:
                    'Your account is already registered and confirmed. Please login or reset your password.',
                  severity: 'info',
                  autoHideDuration: null,
                }),
              );
            } else if (cognitoUserStatus === CognitoUserStatus.Unconfirmed) {
              await Auth.resendSignUp(res.email);
              dispatch(
                addSnackbar({
                  id: Date.now(),
                  message:
                    'Your account needs to be confirmed. Please check your email for a confirmation code.',
                  severity: 'info',
                  autoHideDuration: null,
                }),
              );
              setRegistered(true);
            }
          }

          if (res.isRegistered) {
            setUnauthorizedText('You have already created an account with this link.');
          } else {
            setInputEmail(res.email);
            const names = res.name.split(' ');
            if (names.length === 2) {
              setInputFirstName(names[0]);
              setInputLastName(names[1]);
            }
          }
        })
        .catch(() => {
          setUnauthorizedText('Invalid registration link.');
        });
    } else if (history.location.state.email) {
      dispatch(addSnackbar({ id: Date.now(), message: 'User already exists', severity: 'info' }));
      setInputEmail(history.location.state.email);
      setRegistered(true);
    } else {
      setUnauthorizedText('You need a valid registration link to view this page.');
    }
  }, [window.location.search]);

  return (
    <Grid container className={classes.root} justify="center" alignItems="center">
      <Grid item xl={4} lg={6} sm={10} xs={11}>
        <Card className={classes.card}>
          {!unauthorizedText ? (
            <div className={classes.paper}>
              <img
                src={CenterlineIcon}
                alt="centerlinelogo"
                style={{ width: '350px', paddingBottom: '20px' }}
              />
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign Up
              </Typography>
              {errorText ? (
                <Typography style={{ color: 'red' }}>{errorText}</Typography>
              ) : (
                <span>&nbsp;&nbsp;</span>
              )}
              {registered ? (
                <form onSubmit={handleConfirm} className={classes.form} noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="code"
                    label="Confirmation Code"
                    name="code"
                    autoComplete="one-time-code"
                    autoFocus
                    value={confirmationCode}
                    onChange={(e) => setConfirmationCode(e.target.value?.trim())}
                    className={classes.textField}
                  />
                  {!isLoading ? (
                    <>
                      <Button
                        onClick={handleConfirm}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Confirm
                      </Button>
                      <Typography
                        onClick={handleResendCode}
                        className={classes.link}
                        style={{ cursor: 'pointer' }}
                      >
                        RE-SEND CONFIRMATION EMAIL
                      </Typography>
                    </>
                  ) : (
                    <div style={{ paddingTop: 16 }}>
                      <CircularLoader fullWidth />
                    </div>
                  )}
                </form>
              ) : (
                <form className={classes.form} noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="First Name"
                    name="first name"
                    autoComplete="first name"
                    autoFocus
                    value={inputFirstName}
                    onChange={(e) => setInputFirstName(e.target.value)}
                    className={classes.textField}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Last Name"
                    name="Last Name"
                    autoComplete="Last Name"
                    value={inputLastName}
                    onChange={(e) => setInputLastName(e.target.value)}
                    className={classes.textField}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    error={emailError}
                    value={inputEmail}
                    onChange={handleChangeEmail}
                    className={classes.textField}
                  />
                  <Tooltip
                    title="Passwords must have at least 8 characters, and at least one of each of the
                  following: uppercase letters, lowercase letters, numbers, and symbols"
                    placement="right"
                    arrow
                  >
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      error={passwordError}
                      value={inputPassword}
                      onChange={handleChangePassword}
                      className={classes.textField}
                    />
                  </Tooltip>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="repeat-password"
                    label="Repeat Password"
                    type="password"
                    id="repeat-password"
                    autoComplete="repeat-password"
                    error={repeatPassword !== inputPassword && repeatPassword.length > 0}
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    className={classes.textField}
                  />
                  {/*<FormControl*/}
                  {/*  fullWidth*/}
                  {/*  variant="outlined"*/}
                  {/*  className={classes.textField}*/}
                  {/*  style={{ marginTop: 16 }}*/}
                  {/*>*/}
                  {/*  <InputLabel*/}
                  {/*    id="demo-simple-select-filled-label"*/}
                  {/*    style={{ marginTop: 0, marginBottom: 0, backgroundColor: 'white' }}*/}
                  {/*  >*/}
                  {/*    Associated Disciplines*/}
                  {/*  </InputLabel>*/}
                  {/*  <Select*/}
                  {/*    labelId="demo-mutiple-checkbox-label"*/}
                  {/*    id="demo-mutiple-checkbox"*/}
                  {/*    multiple*/}
                  {/*    fullWidth*/}
                  {/*    value={associatedDisciplines}*/}
                  {/*    onChange={(e) => setAssociatedDisciplines(e.target.value as DisciplineType[])}*/}
                  {/*    MenuProps={{*/}
                  {/*      // @ts-ignore*/}
                  {/*      getContentAnchorEl: () => null,*/}
                  {/*      classes: { paper: classes.selectList },*/}
                  {/*      autoFocus: true,*/}
                  {/*    }}*/}
                  {/*    renderValue={(selected) => `${(selected as string[]).length} selected`}*/}
                  {/*  >*/}
                  {/*    {Object.values(DisciplineType).map((discipline) => {*/}
                  {/*      return (*/}
                  {/*        <MenuItem*/}
                  {/*          key={discipline}*/}
                  {/*          style={{ textTransform: 'capitalize' }}*/}
                  {/*          value={discipline}*/}
                  {/*        >*/}
                  {/*          <Checkbox checked={associatedDisciplines.indexOf(discipline) > -1} />*/}
                  {/*          {(discipline as string).replaceAll('_', ' ')}*/}
                  {/*        </MenuItem>*/}
                  {/*      );*/}
                  {/*    })}*/}
                  {/*  </Select>*/}
                  {/*</FormControl>*/}
                  <div style={{ marginTop: 20 }}>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                      onChange={verifyCaptcha}
                    />
                  </div>
                  {!isLoading ? (
                    <>
                      <Button
                        onClick={handleRegister}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Register
                      </Button>
                      <Link to="/main/login" className={classes.link}>
                        Already have an account? Sign in
                      </Link>{' '}
                    </>
                  ) : (
                    <div style={{ paddingTop: 32 }}>
                      <CircularLoader fullWidth />
                    </div>
                  )}
                </form>
              )}
            </div>
          ) : (
            <div className={classes.paper}>
              <img
                src={CenterlineIcon}
                alt="centerlinelogo"
                style={{ width: '350px', paddingBottom: '20px' }}
              />
              <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <div>{unauthorizedText}</div>
              </div>
            </div>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}

export default MainPortalRegister;
