import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import { getUserLoadingState } from '../../features/loading/selectors';
import { setPublicPageRedirect } from '../../scripts/utils';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Centerlinelogo from '../../assets/icons/wordmark-white';
import CardContent from '@material-ui/core/CardContent';
import { IProject } from '../../api-client/autogenerated';
import { getBidSetupById } from '../../models/api/project-bid-setups';
import { unsubscribePlanholder } from '../../models/api/bid';
import CircularLoader from '../../main-components/loader/CircularLoader';
import { addSnackbar } from '../../features/snackbar/actions';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      padding: 16,
      height: '100vh',
    },
    paper: {
      color: theme.palette.text.secondary,
      height: '100%',
      boxShadow: '5px 5px 15px rgb(0, 0, 0, .15)',
    },
    titleStyle: {
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
    },
  }),
);

export default function UnsubscribePage() {
  const classes = useStyles();

  const user = useSelector(getUserState);
  const userLoading = useSelector(getUserLoadingState);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const [project, setProject] = useState<IProject>();

  useEffect(() => {
    if (user.id && params.id)
      getBidSetupById(params.id).then((bidSetup) => setProject(bidSetup.project || undefined));
  }, [user, params]);

  useEffect(() => {
    if (!userLoading && !user.id) {
      setPublicPageRedirect(`/bidding/${params.id}/unsubscribe`);
      history.push('/bidding/login');
    }
  }, [userLoading, user]);

  const handleUnsubscribe = async () => {
    try {
      await unsubscribePlanholder(user.id, project!.id);
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'You have successfully unsubscribed!',
          severity: 'success',
        }),
      );
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <main className={classes.content} style={{ fontFamily: 'Roboto' }}>
      <Grid container spacing={2} alignItems="center" justify="center" direction="row">
        <Grid item xs={9}>
          <Paper className={classes.paper} style={{ width: '100%', height: '50vh' }}>
            <CardContent className={classes.titleStyle}>
              <h2
                className="h2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left',
                  color: '#FFFFFF',
                  margin: 0,
                  fontFamily: 'Roboto',
                }}
              >
                <Centerlinelogo width="200" height="100" />
                <span style={{ paddingLeft: 10 }}>Manage Subscription</span>
              </h2>
            </CardContent>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                height: '90%',
              }}
            >
              {project ? (
                <Typography
                  onClick={handleUnsubscribe}
                  style={{ cursor: 'pointer', fontSize: '20px', textAlign: 'center' }}
                >
                  Click here to unsubscribe from planholder notifications for
                  <br /> <b style={{ color: '#0947B9' }}>{project.name}.</b>
                </Typography>
              ) : (
                <CircularLoader />
              )}
              <Link
                href="/bidding"
                style={{
                  cursor: 'pointer',
                  fontSize: '14px',
                  textAlign: 'center',
                }}
              >
                Return to Bidding Portal
              </Link>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </main>
  );
}
