import { action } from 'typesafe-actions';
import { ALLOW_NAVIGATION, BLOCK_NAVIGATION } from './constants';

export const blockNavigation = () => {
  return action(BLOCK_NAVIGATION);
};

export const allowNavigation = () => {
  return action(ALLOW_NAVIGATION);
};
