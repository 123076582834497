import React, { useEffect } from 'react';
import { Collapse, ListItem, ListItemText } from '@material-ui/core';
import { ArrowDropUp } from '@material-ui/icons';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import DocumentListItem from './DocumentListItem';
import {
  getDocumentsType,
  getSelectedDivision,
  getSelectedSection,
} from '../../features/documents/selectors';
import { DocumentTemplateType } from '../../api-client/autogenerated';
import { setSelectedDivision, setSelectedSection } from '../../features/documents/actions';
import { generateUniqueId } from '../../scripts/utils';

interface DocumentListProps {
  division: string;
  sections: { id: string; number: string; title?: string; fileId?: string }[];
}

const useStyles = makeStyles(() => ({
  listHeaderText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '172px',
    marginLeft: '24px',
    color: '#A6C4FF',
  },
  arrow: {
    color: '#A6C4FF',
    alignSelf: 'flex-end',
    marginLeft: '4px',
  },
  rectangleActive: {
    display: 'flex',
    flexShrink: 0,
    width: '4px',
    height: '32px',
    left: 0,
    background: '#FF9C17',
  },
  rectangleDisabled: {
    display: 'none',
    flexShrink: 0,
    alignSelf: 'start',
    width: '4px',
    height: '32px',
    background: 'transparent',
  },
  listHover: {
    '&:hover': {
      color: 'white',
      background: '#00308C',
    },
  },
}));

export default function DocumentList(props: DocumentListProps) {
  const classes = useStyles();

  const { division, sections } = props;
  const dispatch = useDispatch();
  const documentType = useSelector(getDocumentsType);
  const selectedSection = useSelector(getSelectedSection);
  const selectedDivision = useSelector(getSelectedDivision);

  const buildItems = (): JSX.Element[] => {
    return sections.sort().map((section) => {
      return <DocumentListItem key={generateUniqueId()} section={section} />;
    });
  };

  const [menuOpen, setMenuOpen] = React.useState(false);

  useEffect(() => {
    if (selectedDivision === division.substr(0, 2)) setMenuOpen(true);
    else setMenuOpen(false);
  }, [selectedDivision]);

  return (
    <>
      <ListItem
        className={classes.listHover}
        disableGutters
        dense
        button
        onClick={() => {
          if (
            documentType === DocumentTemplateType.Specifications ||
            documentType === DocumentTemplateType.Submittals
          ) {
            if (!selectedSection?.number.startsWith(division.substr(0, 2)))
              dispatch(setSelectedSection(null));
            if (selectedDivision === division.substr(0, 2)) dispatch(setSelectedDivision(''));
            else dispatch(setSelectedDivision(division.substr(0, 2)));
          }
          dispatch(setSelectedSection(null));
        }}
        style={{
          height: '32px',
          overflow: 'visible',
          flexGrow: 0,
          backgroundColor: division.substr(0, 2) === selectedDivision ? '#00308C' : '',
        }}
      >
        {(documentType === DocumentTemplateType.Specifications ||
          documentType === DocumentTemplateType.Submittals) && (
          <div
            className={
              division.substr(0, 2) === selectedDivision
                ? classes.rectangleActive
                : classes.rectangleDisabled
            }
          />
        )}
        <ListItemText
          disableTypography
          primary={division}
          classes={{ root: classes.listHeaderText }}
        />
        {menuOpen ? (
          <ArrowDropUp className={classes.arrow} />
        ) : (
          <ArrowDropDown className={classes.arrow} />
        )}
      </ListItem>
      <Collapse in={menuOpen}>
        <List style={{ padding: 0 }}>{buildItems()}</List>
      </Collapse>
    </>
  );
}
