import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

interface Props {
  onConfirm: (comment: { text: string }) => void;
  onOpen: () => void;
  onUpdate?: () => void;
  existingLocationNames?: string[];
}

const useStyles = makeStyles(() =>
  createStyles({
    tipCompact: {
      cursor: 'pointer',
      backgroundColor: '#3D464D',
      border: '1px solid rgba(255, 255, 255, 0.25)',
      color: 'white',
      padding: '5px 10px',
      borderRadius: 3,
    },
    tipCard: {
      padding: 10,
      background: '#FFF',
      backgroundClip: 'padding-box',
      border: '1px solid #E8E8E8',
      borderRadius: 4,
      boxShadow: '0 2px 4px rgba(37, 40, 43, 0.2)',
    },
    tipTextarea: {
      fontSize: 16,
      width: 200,
      height: 70,
    },
    tipInput: {
      marginTop: 5,
      fontSize: 'large',
      width: '100%',
    },
  }),
);

export default function CustomTip(props: Props) {
  const classes = useStyles();
  const { onConfirm, onOpen, onUpdate, existingLocationNames } = props;

  const [compact, setCompact] = useState(true);
  const [text, setText] = useState('');

  useEffect(() => {
    if (onUpdate) onUpdate();
  }, [compact]);

  const saveDisabled = existingLocationNames?.includes(text);

  return (
    <div>
      {compact ? (
        <div
          className={classes.tipCompact}
          onClick={() => {
            onOpen();
            setCompact(false);
          }}
        >
          Add room
        </div>
      ) : (
        <form
          className={classes.tipCard}
          onSubmit={(event) => {
            event.preventDefault();
            onConfirm({ text });
          }}
        >
          <div>
            <textarea
              className={classes.tipTextarea}
              placeholder="Room name"
              autoFocus
              value={text}
              onChange={(event) => setText(event.target.value)}
              onKeyDown={(event) => (event.key === 'Enter' ? onConfirm({ text }) : null)}
              ref={(node) => {
                if (node) {
                  node.focus();
                }
              }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {saveDisabled && <span style={{ color: 'red' }}>Room name already exists</span>}
            <input
              className={classes.tipInput}
              disabled={saveDisabled}
              type="submit"
              value="Save"
            />
          </div>
        </form>
      )}
    </div>
  );
}
