import {
  IInsertionProductPackage,
  IOptionalProductPackage,
  IProductPackage,
} from '../../api-client/autogenerated';
import { ApiClient } from '../../api-client/api-client';

// GET //

// eslint-disable-next-line import/prefer-default-export
export const getProductPackages = async (limit = 50): Promise<IProductPackage[]> => {
  const response = await ApiClient.getAllProductPackages({ pageSize: limit });
  return response.data.results;
};

// gets a product package by it's id
export const getProductPackageById = async (productPackageId: string): Promise<IProductPackage> => {
  const response = await ApiClient.getProductPackageById({ productPackageId: productPackageId });
  return response.data;
};

// POST //

export const insertProductPackage = async (
  productPackage: IInsertionProductPackage,
): Promise<number> => {
  return (await ApiClient.insertProductPackage({ iInsertionProductPackage: productPackage }))
    .status;
};

// PATCH //

export const updateProductPackage = async (
  productPackageId: string,
  productPackage: IOptionalProductPackage,
): Promise<number> => {
  return (
    await ApiClient.editProductPackageById({
      productPackageId,
      iOptionalProductPackage: productPackage,
    })
  ).status;
};

// DELETE //

export const deleteProductPackageById = async (productPackageId: string): Promise<number> => {
  return (await ApiClient.deleteProductPackageById({ productPackageId })).status;
};
