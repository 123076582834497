import { Dialog } from '@material-ui/core';
import React from 'react';
import { BimTreeNode, IProjectView } from '../../api-client/autogenerated';
import FileTree from '../design/FileTree';

interface ImportFromBIMDialogProps {
  open: boolean;
  handleClose: () => void;
  project: IProjectView;
  importBIMFile: (file: BimTreeNode) => void;
}

export default function ImportFromBIMDialog(props: ImportFromBIMDialogProps) {
  const { open, handleClose, project, importBIMFile } = props;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl">
      <div style={{ minHeight: 500, minWidth: 1000, display: 'flex' }}>
        <FileTree
          backButtonPressed={() => handleClose()}
          project={project}
          disableOptions
          disableUploadFile
          type="BIM360"
          handleSignOutOfBIM={() => {}}
          importFromBIM
          importBIMFile={importBIMFile}
        />
      </div>
    </Dialog>
  );
}
