import React, { ChangeEvent, CSSProperties } from 'react';
import { AddBoxOutlined, IndeterminateCheckBoxOutlined } from '@material-ui/icons';
import { ascendingComparator } from '../../document-index/DocumentIndexUtils';
import { TreeItem, TreeView } from '@material-ui/lab';
import clsx from 'clsx';
import { hasPermissionToView } from '../../../scripts/store-utils';
import { makeStyles } from '@material-ui/core/styles';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { GreenCheck } from '../../custom-components/CustomCheckboxes';
import { ManagePermissionsDialogType } from '../../design/ManagePermissionsDialog';
import { ICompany } from '../../../api-client/autogenerated';
import { sortLastNamesDesc } from '../../../scripts/utils';
import LabelWithCheckbox from '../../custom-components/LabelWithCheckbox';

const useStyles = makeStyles({
  button: {
    padding: '6px 20px',
  },
  treeRoot: {
    marginTop: 16,
    padding: 6,
    border: '1px solid #949494',
    borderRadius: '4px',
  },
  treeLabel: {
    padding: 0,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'capitalize',
  },
  treeLabelRoot: {
    fontWeight: 600,
  },
  treeLabelParent: {
    fontWeight: 500,
  },
  treeLabelChild: {
    fontWeight: 400,
  },
  treeGroup: {
    marginLeft: '22px',
  },
});

type Props = {
  type?: ManagePermissionsDialogType;
  companies: ICompany[];
  selectedCompanies?: string[];
  excludedUsers?: string[];
  selectedUsers?: string[];
  handleCheckCompany: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    companyId: string,
  ) => void;
  handleCheckNestedUser: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    userId: string,
    companyId: string,
  ) => void;
};

export default function CompanyUserTreeView(props: Props) {
  const classes = useStyles();
  const {
    type = ManagePermissionsDialogType.None,
    companies,
    selectedCompanies,
    excludedUsers,
    selectedUsers,
    handleCheckCompany,
    handleCheckNestedUser,
  } = props;

  return (
    <TreeView
      multiSelect
      defaultCollapseIcon={<IndeterminateCheckBoxOutlined />}
      defaultExpandIcon={<AddBoxOutlined />}
      className={classes.treeRoot}
    >
      {companies
        .sort((a, b) => ascendingComparator(a, b, 'name'))
        .map((company) => (
          <TreeItem
            key={company.id}
            nodeId={company.id}
            label={
              <LabelWithCheckbox
                label={company.name}
                indeterminate={
                  (!!selectedCompanies?.includes(company.id) &&
                    !!excludedUsers?.some(
                      (userId) => !!company.users?.some(({ id }) => userId === id),
                    )) ||
                  (!!selectedUsers?.some(
                    (userId) => !!company.users?.some(({ id }) => id === userId),
                  ) &&
                    !company?.users?.every(({ id }) => !!selectedUsers?.includes(id)))
                }
                checked={
                  !!selectedCompanies?.includes(company.id) ||
                  !!company?.users?.every(({ id }) => !!selectedUsers?.includes(id))
                }
                onChange={(e, checked) => handleCheckCompany(e, checked, company.id)}
              />
            }
            onIconClick={() => {}}
            classes={{
              label: clsx(classes.treeLabel, classes.treeLabelParent),
              group: classes.treeGroup,
            }}
          >
            {company.users
              ?.sort((a, b) => sortLastNamesDesc(a.name, b.name))
              .map((user) => (
                <TreeItem
                  key={user.id}
                  nodeId={user.id}
                  label={
                    <LabelWithCheckbox
                      label={user.name}
                      strikethrough={!hasPermissionToView(type, user)}
                      checked={
                        (!!selectedCompanies?.includes(company.id) &&
                          !excludedUsers?.includes(user.id)) ||
                        !!selectedUsers?.includes(user.id)
                      }
                      onChange={(e, checked) =>
                        handleCheckNestedUser(e, checked, user.id, company.id)
                      }
                    />
                  }
                  classes={{
                    label: clsx(classes.treeLabel, classes.treeLabelChild),
                    group: classes.treeGroup,
                  }}
                />
              ))}
          </TreeItem>
        ))}
    </TreeView>
  );
}
