import { RootState } from '../../store/root-state';
import { IDocument } from '../../api-client/autogenerated';

export const getDocumentState = (state: RootState) => state.document.document;

export const getDocumentAndPermissionsState = (state: RootState) => {
  const { document, permissions } = state.document;
  return { document, permissions };
};

export const getPermissionsState = (state: RootState) => state.document.permissions;

export const getDocumentId = (state: RootState) => state.document.document?.id;

export const getDocumentFiles = (state: RootState) => state.document.document?.files || [];

export const getAdditionalReviewDocuments = (state: RootState): IDocument[] =>
  state.document.document?.additionalReviewDocuments || [];

export const getSubmittalSectionFile = (state: RootState) =>
  state.document.document?.submittalSectionFile;
