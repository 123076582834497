import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import AssociatedDocumentList from '../associated-document-list/AssociatedDocumentList';
import IconButton from '@material-ui/core/IconButton';
import { HighlightOffRounded } from '@material-ui/icons';

interface Props {
  open: boolean;
  close: () => void;
  documentId: string;
  title: string;
}

export default function AssociatedDocumentsDialog(props: Props) {
  const { open, close, documentId, title } = props;

  return (
    <Dialog open={open} onClose={close} maxWidth="lg">
      <IconButton onClick={close} style={{ padding: 0, position: 'absolute', top: 16, right: 16 }}>
        <HighlightOffRounded />
      </IconButton>
      <DialogContent>
        <AssociatedDocumentList title={title} documentIdOverride={documentId} inDialog />
      </DialogContent>
    </Dialog>
  );
}
