import React from 'react';
import { ListItem, ListItemText, Typography } from '@material-ui/core';
import { DesignActionTakenType, IDesignFileAction, IUser } from '../../api-client/autogenerated';
import {
  getFileOrFolderNameFromFullKey,
  getUserFriendlyStringForDesignActionTakenType,
} from '../../scripts/utils';
import SecondaryDateText from '../lists/SecondaryDateText';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowRightAlt } from '@material-ui/icons';
import { ascendingComparator } from '../document-index/DocumentIndexUtils';

type Props = {
  designFileAction: IDesignFileAction;
  isFolder: boolean;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    fontWeight: 700,
    fontSize: 15,
    whiteSpace: 'pre',
    // color: '#464546', // gray 700
  },
  company: {
    fontSize: 15,
    fontStyle: 'italic',
    whiteSpace: 'pre',
    // color: '#949494', // gray
  },
  email: {
    fontStyle: 'italic',
  },
  rename: {
    width: '75%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontStyle: 'italic',
    whiteSpace: 'pre',
    marginTop: 8,
  },
  action: {
    whiteSpace: 'pre',
  },
});

export default function FileLogItem(props: Props) {
  const classes = useStyles();
  const { designFileAction, isFolder } = props;
  const {
    createdOn,
    actionTaken,
    initiatorUser,
    previousFullKey,
    newFullKey,
    sentToEmails,
    filePermissions,
    notifications,
  } = designFileAction;

  const emailedUsers = notifications?.length
    ? notifications.map((n) => n.emailedUsers || ([] as IUser[])).flat()
    : undefined;

  return (
    <ListItem disableGutters>
      <ListItemText
        primary={
          <>
            <div className={classes.container}>
              <div className={classes.container} style={{ alignSelf: 'flex-start' }}>
                <Typography className={classes.name}>{initiatorUser?.name}, </Typography>
                <Typography className={classes.company}>{initiatorUser?.company?.name} </Typography>
                <Typography className={classes.action}>
                  {getUserFriendlyStringForDesignActionTakenType(actionTaken, isFolder)}{' '}
                </Typography>
              </div>
              <div className={classes.container}>
                {actionTaken === DesignActionTakenType.SentDownloadLink && sentToEmails && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {sentToEmails.sort().map((email) => (
                      <Typography key={email} className={classes.email}>
                        {email}
                      </Typography>
                    ))}
                  </div>
                )}
                {actionTaken === DesignActionTakenType.AccessNotification && emailedUsers && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {emailedUsers.map((user) => (
                      <div key={user.id} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography className={classes.name}>{user.name}, </Typography>
                        <Typography className={classes.company}>{user?.company?.name} </Typography>
                      </div>
                    ))}
                  </div>
                )}
                {actionTaken === DesignActionTakenType.SentPublicFolderAccessLink && sentToEmails && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {sentToEmails.map((email) => (
                      <Typography className={classes.name}>{email}</Typography>
                    ))}
                  </div>
                )}
                {actionTaken === DesignActionTakenType.SharedNode && filePermissions && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {filePermissions
                      .sort((a, b) => ascendingComparator(a, b, 'company_name'))
                      .map(({ user_id, user_name, company_name }) => (
                        <div key={user_id} style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.name}>{user_name}, </Typography>
                          <Typography className={classes.company}>{company_name} </Typography>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
            {actionTaken === DesignActionTakenType.ModifiedNode && previousFullKey && newFullKey && (
              <Typography className={classes.rename}>
                {getFileOrFolderNameFromFullKey(previousFullKey)}
                <ArrowRightAlt style={{ marginLeft: 4, marginRight: 2 }} />
                {getFileOrFolderNameFromFullKey(newFullKey)}
              </Typography>
            )}
          </>
        }
        secondary={<SecondaryDateText date={createdOn!} />}
        secondaryTypographyProps={{ align: 'right' }}
      />
    </ListItem>
  );
}
