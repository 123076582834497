import React from 'react';

const DashboardIcon = (props: any) => (
  <svg
    width={24}
    height={22}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.943.3a.796.796 0 00-.793.8v6.8c0 .442.355.8.793.8h6.738a.796.796 0 00.792-.8V1.1c0-.442-.355-.8-.792-.8H.943zm0 11a.796.796 0 00-.793.8v6.8c0 .442.355.8.793.8h6.738a.796.796 0 00.792-.8v-6.8c0-.442-.355-.8-.792-.8H.943zM11.05 1.1c0-.442.355-.8.793-.8h6.738c.438 0 .792.358.792.8v6.8c0 .442-.355.8-.792.8h-6.738a.796.796 0 01-.793-.8V1.1zm.793 10.2a.796.796 0 00-.793.8v6.8c0 .442.355.8.793.8h6.738a.796.796 0 00.792-.8v-6.8c0-.442-.355-.8-.792-.8h-6.738zm.644 1.45h5.45v5.5h-5.45v-5.5zm5.45-11h-5.45v5.5h5.45v-5.5zm-16.349 0h5.45v5.5h-5.45v-5.5zm5.45 11h-5.45v5.5h5.45v-5.5z"
      fill="#73A3FF"
    />
  </svg>
);

export default DashboardIcon;
