import React, { useEffect, useState } from 'react';
import {
  Collapse,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloseOutlined, HighlightOffRounded } from '@material-ui/icons';
import { generateUniqueId } from '../../scripts/utils';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import { SubmitButton } from '../custom-components/CustomButtons';
import BuildingIcon from '@material-ui/icons/Business';
import { editPhysicalBuilding, insertManyPhysicalBuildings } from '../../models/api/punch-list';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPhysicalBuildingsState,
  getPhysicalFloorsState,
  getProjectState,
} from '../../features/project/selectors';
import { reloadPunchListLocations, updateProject } from '../../features/project/actions';
import Remove from '@material-ui/icons/Remove';
import { ascendingComparator } from '../document-index/DocumentIndexUtils';
import CircularLoader from '../loader/CircularLoader';
import pluralize from 'pluralize';
import { IPhysicalBuilding } from '../../api-client/autogenerated';

const useStyles = makeStyles(() =>
  createStyles({
    rootIconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    dialogTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '21px',
      textAlign: 'left',
      color: '#0947B9',
      textTransform: 'none',
      paddingBottom: 0,
    },
  }),
);

interface InputPhysicalBuilding {
  id: string;
  name: string;
  numberOfFloors: number;
  floors: string[];
  open: boolean;
}

function getDefaultItem(): InputPhysicalBuilding {
  return {
    id: generateUniqueId(),
    name: '',
    numberOfFloors: 0,
    floors: [],
    open: true,
  };
}

type Props = {
  open: boolean;
  onClose: (newBuildingId?: string) => void;
  buildingIdsToEdit?: string[];
};

export default function AddBuildingsDialog(props: Props) {
  const classes = useStyles();
  const { open, onClose, buildingIdsToEdit } = props;

  const dispatch = useDispatch();
  const project = useSelector(getProjectState);
  const allBuildings = useSelector(getPhysicalBuildingsState);
  const floors = useSelector(getPhysicalFloorsState);

  const isEditMode = !!buildingIdsToEdit?.length;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputBuildings, setInputBuildings] = useState<InputPhysicalBuilding[]>([getDefaultItem()]);

  const fetchExistingBuildings = (ids: string[]) => {
    const existingBuildings: InputPhysicalBuilding[] = ids
      .map((id) => {
        const building = allBuildings.find((building) => building.id === id);
        if (building) {
          const floorsInBuilding = floors.filter((floor) => floor.buildingId === building.id);
          const inputBuilding: InputPhysicalBuilding = {
            id: building.id,
            name: building.name,
            numberOfFloors: floorsInBuilding.length,
            floors: floorsInBuilding
              .sort((a, b) => ascendingComparator(a, b, 'index'))
              .map((floor) => floor.name),
            open: true,
          };
          return inputBuilding;
        }
      })
      .filter((e) => e) as InputPhysicalBuilding[];
    //this usage of "as" is safe because I'm typing the return value within the map() function
    setInputBuildings(existingBuildings);
  };

  useEffect(() => {
    if (buildingIdsToEdit?.length) fetchExistingBuildings(buildingIdsToEdit);
    else setInputBuildings([getDefaultItem()]);
  }, [buildingIdsToEdit]);

  const removeBuilding = (index: number) => {
    setInputBuildings((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  };

  const updateBuilding = (id: string, args: Partial<InputPhysicalBuilding>) => {
    setInputBuildings((prev) => {
      const index = prev.findIndex((b) => b.id === id);
      if (index !== -1) {
        return [...prev.slice(0, index), { ...prev[index], ...args }, ...prev.slice(index + 1)];
      }
      return prev;
    });
  };

  const updateFloorInBuilding = (id: string, index: number, value: string) => {
    const building = inputBuildings.find((b) => b.id === id);
    if (building) {
      updateBuilding(building.id, {
        floors: [...building.floors.slice(0, index), value, ...building.floors.slice(index + 1)],
      });
    }
  };

  const defineFloors = (building: InputPhysicalBuilding) => {
    updateBuilding(building.id, { floors: new Array(building.numberOfFloors).fill('') });
  };

  const submitDisabled =
    inputBuildings.length === 0 ||
    inputBuildings.some(
      (building) =>
        !building.name || building.floors.length === 0 || building.floors.some((floor) => !floor),
    );

  const handleSubmit = async () => {
    if (!project) return;

    let newBuildings: IPhysicalBuilding[] = [];

    try {
      setIsSubmitting(true);
      if (!isEditMode) {
        const insertionBuildings = inputBuildings.map((building) => ({
          projectId: project.id,
          name: building.name,
          floors: building.floors.map((floor, index) => ({ name: floor, index })),
        }));

        newBuildings = await insertManyPhysicalBuildings(insertionBuildings);
        dispatch(
          updateProject({
            physicalBuildings: [...(project.physicalBuildings || []), ...newBuildings],
          }),
        );
      } else {
        await Promise.all(
          inputBuildings.map((building) =>
            editPhysicalBuilding(building.id, {
              name: building.name,
              floors: building.floors.map((floor, index) => ({ name: floor, index })),
            }),
          ),
        );
        dispatch(reloadPunchListLocations());
      }
      handleClose(newBuildings[0]?.id);
    } catch (e: any) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = (newBuildingId?: string) => {
    onClose(newBuildingId);
    setInputBuildings([getDefaultItem()]);
  };

  return (
    <Dialog open={open} maxWidth="sm" PaperProps={{ style: { minWidth: 540 } }}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        {isEditMode ? `Edit ${pluralize('Building', buildingIdsToEdit?.length)}` : 'Add Buildings'}
        <IconButton
          style={{ right: 12, top: 15, position: 'absolute' }}
          onClick={() => handleClose()}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        {isEditMode && (
          <Typography style={{ marginBottom: 8 }}>
            Rename the building(s) and their floorplans.
          </Typography>
        )}
        {inputBuildings.map((building, index) => (
          <div key={building.id} style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 8, marginBottom: 8 }}>
              <div style={{ display: 'flex', alignItems: 'center', width: 280 }}>
                {building.floors.length > 0 && (
                  <IconButton
                    onClick={() => updateBuilding(building.id, { open: !building.open })}
                    style={{ padding: 0, marginRight: 8 }}
                  >
                    {building.open ? <Remove /> : <Add />}
                  </IconButton>
                )}
                <TextField
                  size="small"
                  variant="outlined"
                  label="Building Name"
                  value={building.name}
                  onChange={(e) => updateBuilding(building.id, { name: e.target.value })}
                  style={{ width: '100%', marginRight: 16 }}
                />
              </div>
              <TextField
                size="small"
                type="number"
                variant="outlined"
                label="# of Floorplans"
                value={building.numberOfFloors}
                onChange={(e) =>
                  updateBuilding(building.id, { numberOfFloors: parseInt(e.target.value) })
                }
                disabled={isEditMode}
                style={{ width: 130, marginRight: 16 }}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={building.numberOfFloors === 0 || !building.name || isEditMode}
                onClick={() => defineFloors(building)}
                style={{ whiteSpace: 'nowrap', marginRight: 8 }}
              >
                Define Floorplans
              </Button>
              {!isEditMode && (
                <IconButton onClick={() => removeBuilding(index)} style={{ padding: 0 }}>
                  <CloseOutlined />
                </IconButton>
              )}
            </div>
            <Collapse in={building.open}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {building.floors.length > 0 && (
                  <>
                    {!isEditMode && (
                      <Typography style={{ marginTop: 8 }}>
                        Provide a name for each floorplan.
                      </Typography>
                    )}
                    <div style={{ width: '50%', marginTop: 8 }}>
                      <Typography align="center" variant="subtitle2">
                        TOP OF BUILDING
                      </Typography>
                    </div>
                  </>
                )}
                {building.floors.map((floor, index) => (
                  <TextField
                    key={index}
                    size="small"
                    variant="outlined"
                    label="Floorplan name"
                    value={floor}
                    onChange={(e) => updateFloorInBuilding(building.id, index, e.target.value)}
                    style={{ marginTop: index === 0 ? 4 : 8, width: '50%' }}
                  />
                ))}

                {building.floors.length > 0 && (
                  <div style={{ width: '50%', marginTop: 4, marginBottom: 16 }}>
                    <Typography align="center" variant="subtitle2">
                      BOTTOM OF BUILDING
                    </Typography>
                  </div>
                )}
                {building.open && building.floors.length > 0 && <Divider />}
              </div>
            </Collapse>
          </div>
        ))}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isEditMode ? 'flex-end' : 'space-between',
            marginTop: 32,
          }}
        >
          {!isEditMode && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              disabled={isSubmitting}
              onClick={() => setInputBuildings((prev) => [...prev, getDefaultItem()])}
              style={{ whiteSpace: 'nowrap' }}
            >
              Add Another Building
            </Button>
          )}
          {!isSubmitting ? (
            <SubmitButton
              disabled={submitDisabled}
              startIcon={<BuildingIcon />}
              onClick={handleSubmit}
              style={{ paddingLeft: 24, paddingRight: 24 }}
            >
              {isEditMode ? 'Update Buildings' : 'Create Buildings'}
            </SubmitButton>
          ) : (
            <CircularLoader size={30} />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
