import React from 'react';
import LockIcon from '@material-ui/icons/Lock';
import { Link } from 'react-router-dom';
import { formatDate, formatMoney } from '../../scripts/utils';
import { IBidSetup } from '../../api-client/autogenerated';
import { Grid, Typography } from '@material-ui/core';

type Props = {
  bidSetup: IBidSetup;
};

const ProjectListing: React.FC<Props> = (props) => {
  const { bidSetup } = props;
  const name = bidSetup.project?.name || '';
  const contactCompanyName = bidSetup?.contactUser?.company?.name || 'N/A';
  const contactFullName = bidSetup?.contactUser?.name || 'N/A';
  const city = bidSetup.address?.city || '';
  const state = bidSetup.address?.stateOrProvince?.name || '';
  const description = bidSetup.project?.description || '';
  const dueDateFormatted = bidSetup.dueDate ? formatDate(bidSetup.dueDate) : '';
  const preBidDate =
    bidSetup.preDueDate && bidSetup.hasPreBidConference ? formatDate(bidSetup.preDueDate) : '';
  const createdOn = bidSetup.createdOn ? formatDate(bidSetup.createdOn) : '';
  const cost = bidSetup.estimatedConstructionCost
    ? formatMoney(bidSetup.estimatedConstructionCost)
    : '';

  const lock = bidSetup.password ? <LockIcon /> : <div />;
  return (
    <Link
      to={{
        pathname: `/bidding/${bidSetup.id}`,
        state: {
          project: bidSetup,
        },
      }}
      className="row"
      style={{ borderTop: 'none', fontFamily: 'Roboto' }}
    >
      <div className="col col-md-12">
        <div
          data-toggle="tooltip"
          data-html="true"
          className="text-md-right float-right notification bidding-today"
          data-original-title="Prebid Date: 10/16/20 10:00am<br>Bid Date: 10/22/20 2:00pm"
        />
        <Grid container>
          <Grid container xs={9}>
            <div
              className="name"
              style={{ paddingBottom: 12, alignItems: 'flex-end', display: 'flex' }}
            >
              <span style={{ paddingRight: 6 }}>{name}</span>
              <span>{lock}</span>
            </div>
          </Grid>
          <Grid item xs={3} alignItems="flex-end" justify="flex-end">
            <Typography variant="subtitle2" style={{ fontSize: 12 }} align="right">
              Created on {createdOn}
            </Typography>
          </Grid>
        </Grid>
        <div>
          <Typography variant="body1" style={{ fontSize: 12, margin: 0 }}>
            <span style={{ width: 80, display: 'inline-block', paddingRight: 'auto' }}>
              Company:
            </span>
            <span style={{ paddingLeft: 8 }}>{contactCompanyName}</span>
          </Typography>
          <Typography variant="body1" style={{ fontSize: 12, margin: 0 }}>
            <span style={{ width: 80, display: 'inline-block', paddingRight: 'auto' }}>
              Contact:
            </span>
            <span style={{ paddingLeft: 8 }}>{contactFullName}</span>
          </Typography>
          <Typography variant="body1" style={{ fontSize: 12, margin: 0 }}>
            <span style={{ width: 80, display: 'inline-block', paddingRight: 'auto' }}>
              Location:
            </span>
            <span style={{ paddingLeft: 8 }}>{`${city}, ${state}`}</span>
          </Typography>
          <Typography variant="body1" style={{ fontSize: 12, margin: 0 }}>
            <span style={{ width: 80, display: 'inline-block', paddingRight: 'auto' }}>
              Budget:
            </span>
            <span style={{ paddingLeft: 8 }}>{`${cost}`}</span>
          </Typography>
          <Typography variant="body1" style={{ fontSize: 12, margin: 0 }}>
            <span style={{ width: 80, display: 'inline-block', paddingRight: 'auto' }}>
              Bid Date:
            </span>
            <span style={{ paddingLeft: 8 }}>{`${dueDateFormatted}`}</span>
          </Typography>
          <Typography variant="body1" style={{ fontSize: 12, margin: 0, marginBottom: 24 }}>
            <span style={{ width: 80, display: 'inline-block', paddingRight: 'auto' }}>
              Pre-bid Date:
            </span>
            <span style={{ paddingLeft: 8 }}>{preBidDate ? `${preBidDate}` : 'N/A'}</span>
          </Typography>
        </div>
        <Typography variant="body1">{description}</Typography>
      </div>
    </Link>
  );
};

export default ProjectListing;
