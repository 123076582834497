import _, { Dictionary } from 'lodash';
import { ApiClient } from '../../api-client/api-client';
import { DocumentTemplateType } from '../../api-client/autogenerated';

// Maps document templates names to ids
const templateNameToId: Dictionary<string> = {};
export const getTemplates = async () => {
  if (!_.isEmpty(templateNameToId)) return templateNameToId;
  let response;
  try {
    response = await ApiClient.getAllDocumentTemplates({ pageSize: 1000 });
  } catch (e: any) {
    console.warn(e);
    return templateNameToId;
  }
  const documentTemplates = response.data.results;
  documentTemplates.forEach(({ id, name }) => {
    templateNameToId[name] = id!;
  });
  return templateNameToId;
};

export const getTemplateIds = async () => {
  return _.invert(await getTemplates());
};

export const getTemplateId = async (type: DocumentTemplateType) => {
  const templateToId = await getTemplates();
  return templateToId[type];
};

export const getTemplateName = async (id: string): Promise<DocumentTemplateType> => {
  const idToTemplate = await getTemplateIds();
  return idToTemplate[id] as DocumentTemplateType;
};
