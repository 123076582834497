import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Divider,
  Link,
  Table,
  TableCell,
  TableRow,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {
  DocumentTemplateType,
  ProjectBiddingSummaryType,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import { OpenInNew } from '@material-ui/icons';
import { documentTypeToUrl } from '../../scripts/utils';
import { useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import { useHistory } from 'react-router';
import { getProjectBiddingSummaryById } from '../../models/api/project';
import dayjs from 'dayjs';
import RightArrowIconWhite from '../icons/RightArrow-icon-white';
import { getCurrentSecurityGroup } from '../../features/security/selectors';
import { getUserState } from '../../features/user/selectors';

const useStyles = makeStyles({
  titleStyle: {
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
  },
  button: {
    height: 150,
    width: 150,
    margin: 4,
    paddingLeft: 7,
    paddingTop: 16,
    borderRadius: 8,
    border: '1px solid',
    backgroundColor: 'rgba(9,71,185,0.4)',
  },
  buttonHover: {
    height: 150,
    width: 150,
    margin: 4,
    paddingLeft: 7,
    paddingTop: 16,
    borderRadius: 8,
    border: '1px solid',
    backgroundColor: 'rgba(9,71,185,0.4)',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#6289d2',
    },
  },
  buttonTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  buttonDividerHorizontal: {
    marginTop: 4,
    marginRight: 8,
    backgroundColor: '#464546',
  },
  buttonDividerVertical: {
    height: 28,
    marginTop: -4,
    marginLeft: 4,
    marginRight: 8,
    backgroundColor: '#464546',
  },
  buttonRow: {
    display: 'flex',
    height: 28,
    paddingTop: 4,
    marginRight: 8,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(9,71,185,0.4)',
    },
  },
  buttonRowNoHover: {
    display: 'flex',
    height: 28,
    paddingTop: 4,
    marginRight: 8,
  },
  buttonLabelNumber: {
    textAlign: 'right',
    minWidth: 26,
  },
  table: {
    height: '100%',
    marginLeft: 4,
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    '& tr': {
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      '&>:nth-child(1)': {
        paddingRight: 0,
      },
      '&>:nth-child(2)': {
        paddingLeft: 0,
        whiteSpace: 'nowrap',
      },
    },
    '& td': {
      // paddingTop: 21,
      // paddingBottom: 21,
    },
  },
  noBlueHover: {
    backgroundColor: '#464546',
  },
  blueHover: {
    backgroundColor: '#464546',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(9,71,185,0.4)',
    },
  },
  link: {
    fontSize: 16,
    // fontStyle: 'bold',
    lineHeight: 0,
    '&:hover': {
      filter: 'brightness(0.8)',
      textDecoration: 'none',
      color: '#FFFFFF',
    },
    color: '#FFFFFF',
  },
});

export default function DashboardBidding() {
  const classes = useStyles();
  const history = useHistory();

  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);
  const securityGroup = useSelector(getCurrentSecurityGroup);

  const shouldStack = useMediaQuery('(max-width:1480px)');

  const [summary, setSummary] = useState<ProjectBiddingSummaryType>();

  const hasBidSetupPermission =
    user.isSiteAdmin ||
    user.adminOfSubscriberId === project?.subscriberId ||
    !!securityGroup?.bidManagementPermission;

  const handleBidConnectLink = () => {
    const newWindow = window.open(
      `${window.location.origin}/bidding/${project?.bidSetupId}`,
      '_blank',
      'noopener,noreferrer',
    );
    if (newWindow) newWindow.opener = null;
  };

  const handleDocumentIndexLink = (docType: DocumentTemplateType, status?: string) => {
    history.push(
      `/main/projects/${project!.id}/documents/${documentTypeToUrl[docType]}`,
      status && {
        status,
      },
    );
  };

  const handleBidSubmissionLink = () => {
    history.push(`/main/projects/${project?.id}/documents/bid-submissions`);
  };

  const handlePlanholderLink = () => {
    history.push(`/main/projects/${project?.id}/documents/planholder-list`);
  };

  const handleBidSetupLink = () => {
    history.push(`/main/projects/${project?.id}/bid-mgmt`);
  };

  const getButton = () => {
    if (project?.bidSetupId)
      return (
        <Button style={{ padding: 0 }}>
          <Link onClick={handleBidConnectLink} className={classes.link}>
            View in CENTERLINE BidConnect
            <OpenInNew style={{ fontSize: 18, marginBottom: -3, marginLeft: 4 }} />
          </Link>
        </Button>
      );

    if (hasBidSetupPermission)
      return (
        <Button style={{ padding: 0 }}>
          <Link onClick={handleBidSetupLink} className={classes.link}>
            Bid Setup
            <RightArrowIconWhite style={{ marginLeft: 4, marginBottom: -1 }} />
          </Link>
        </Button>
      );

    return null;
  };

  useEffect(() => {
    if (project) {
      getProjectBiddingSummaryById(project.id).then(setSummary);
    }
  }, [project]);

  return (
    <Card style={{ height: '100%' }}>
      <CardContent className={classes.titleStyle}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <h2
            style={{
              textAlign: 'left',
              color: '#FFFFFF',
              margin: 0,
            }}
          >
            Bidding
          </h2>

          {getButton()}
        </div>
      </CardContent>
      <CardContent>
        <div
          style={{
            display: 'flex',
            flexDirection: shouldStack ? 'column' : 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div onClick={handlePlanholderLink} className={classes.buttonHover}>
                <Typography className={classes.buttonTitle}>Planholders</Typography>
                <Divider className={classes.buttonDividerHorizontal} />
                <div className={classes.buttonRowNoHover}>
                  <Typography className={classes.buttonLabelNumber}>
                    {summary?.planholders.numUsers}
                  </Typography>
                  <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                  <Typography>Planholders</Typography>
                </div>
                <div className={classes.buttonRowNoHover}>
                  <Typography className={classes.buttonLabelNumber}>
                    {summary?.planholders.numViews}
                  </Typography>
                  <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                  <Typography>Views</Typography>
                </div>
                <div className={classes.buttonRowNoHover}>
                  <Typography className={classes.buttonLabelNumber}>
                    {summary?.planholders.numDownloads}
                  </Typography>
                  <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                  <Typography>Downloads</Typography>
                </div>
              </div>
              <div className={classes.button}>
                <Typography className={classes.buttonTitle}>Bidder RFIs</Typography>
                <Divider className={classes.buttonDividerHorizontal} />
                <div
                  onClick={() =>
                    handleDocumentIndexLink(
                      DocumentTemplateType.BidderRfIs,
                      WorkflowStatusType.SubmittedForReview,
                    )
                  }
                  className={classes.buttonRow}
                >
                  <Typography className={classes.buttonLabelNumber}>
                    {summary?.bidderRfis.numNew}
                  </Typography>
                  <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                  <Typography>New</Typography>
                </div>
                <div
                  onClick={() =>
                    handleDocumentIndexLink(
                      DocumentTemplateType.BidderRfIs,
                      WorkflowStatusType.UnderReview,
                    )
                  }
                  className={classes.buttonRow}
                >
                  <Typography className={classes.buttonLabelNumber}>
                    {summary?.bidderRfis.numUnderReview}
                  </Typography>
                  <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                  <Typography>Under Review</Typography>
                </div>
                <div
                  onClick={() =>
                    handleDocumentIndexLink(DocumentTemplateType.BidderRfIs, 'overdue')
                  }
                  className={classes.buttonRow}
                >
                  <Typography className={classes.buttonLabelNumber}>
                    {summary?.bidderRfis.numOverdue}
                  </Typography>
                  <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                  <Typography>Overdue</Typography>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className={classes.button}>
                <Typography className={classes.buttonTitle}>Subst. Requests</Typography>
                <Divider className={classes.buttonDividerHorizontal} />
                <div
                  onClick={() =>
                    handleDocumentIndexLink(
                      DocumentTemplateType.SubstitutionRequests,
                      WorkflowStatusType.SubmittedForReview,
                    )
                  }
                  className={classes.buttonRow}
                >
                  <Typography className={classes.buttonLabelNumber}>
                    {summary?.substRequests.numNew}
                  </Typography>
                  <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                  <Typography>New</Typography>
                </div>
                <div
                  onClick={() =>
                    handleDocumentIndexLink(
                      DocumentTemplateType.SubstitutionRequests,
                      WorkflowStatusType.UnderReview,
                    )
                  }
                  className={classes.buttonRow}
                >
                  <Typography className={classes.buttonLabelNumber}>
                    {summary?.substRequests.numUnderReview}
                  </Typography>
                  <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                  <Typography>Under Review</Typography>
                </div>
                <div
                  onClick={() =>
                    handleDocumentIndexLink(DocumentTemplateType.SubstitutionRequests, 'overdue')
                  }
                  className={classes.buttonRow}
                >
                  <Typography className={classes.buttonLabelNumber}>
                    {summary?.substRequests.numOverdue}
                  </Typography>
                  <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                  <Typography>Overdue</Typography>
                </div>
              </div>
              <div onClick={handleBidSubmissionLink} className={classes.buttonHover}>
                <Typography className={classes.buttonTitle}>Bids</Typography>
                <Divider className={classes.buttonDividerHorizontal} />
                <div className={classes.buttonRowNoHover}>
                  <Typography className={classes.buttonLabelNumber}>
                    {summary?.numBidSubmissions}
                  </Typography>
                  <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                  <Typography>Submissions</Typography>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: '100%', height: 308 }}>
            <Table className={classes.table}>
              <TableRow>
                <TableCell>
                  <Typography style={{ fontWeight: 500, fontSize: 16 }}>Live:</Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ fontSize: 16 }}>
                    {summary?.liveAtDate
                      ? dayjs(summary?.liveAtDate).format('MM/DD/YYYY hh:mm A')
                      : 'None'}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography style={{ fontWeight: 500, fontSize: 16 }}>
                    Prebid Conference:
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ fontSize: 16 }}>
                    {summary?.preDueDate
                      ? dayjs(summary?.preDueDate).format('MM/DD/YYYY hh:mm A')
                      : 'None'}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography style={{ fontWeight: 500, fontSize: 16 }}>
                    RFI/Subst. Request Deadline:
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ fontSize: 16 }}>
                    {summary?.requestDueDate
                      ? dayjs(summary?.requestDueDate).format('MM/DD/YYYY hh:mm A')
                      : 'None'}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography style={{ fontWeight: 500, fontSize: 16 }}>
                    Addenda Deadline:
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ fontSize: 16 }}>
                    {summary?.addendaDueDate
                      ? dayjs(summary?.addendaDueDate).format('MM/DD/YYYY hh:mm A')
                      : 'None'}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography style={{ fontWeight: 500, fontSize: 16 }}>Bids Due:</Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ fontSize: 16 }}>
                    {summary?.bidDueDate
                      ? dayjs(summary?.bidDueDate).format('MM/DD/YYYY hh:mm A')
                      : 'None'}
                  </Typography>
                </TableCell>
              </TableRow>
            </Table>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
