import React from 'react';
import Box from '@material-ui/core/Box';
import '../../css/main-style.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    background: '#EDECEC',
    height: 1,
    width: 'auto',
  },
});

export default function Break() {
  const classes = useStyles();
  return <Box className={classes.root} />;
}
