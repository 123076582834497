import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Document, Page, PDFPageProxy } from 'react-pdf';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { HighlightOffRounded } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import CircularLoader from '../loader/CircularLoader';
import FullscreenLoader from '../loader/FullscreenLoader';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28px',
    textAlign: 'left',
    color: '#0947B9',
  },
}));

type Props = {
  open: boolean;
  handleClose: () => void;
  file?: File;
  secondaryFile?: File;
};

const MAX_WIDTH = window.innerWidth - 112;

export default function FilePreviewDialog(props: Props) {
  const classes = useStyles();
  const { open, file, secondaryFile, handleClose } = props;

  const [currentFile, setCurrentFile] = useState(file);
  useEffect(() => {
    setCurrentFile(file);
  }, [file]);

  const [pageNum, setPageNum] = useState(1);
  const [pages, setPages] = useState(0);
  const [renderMode, setRenderMode] = useState<'canvas' | 'svg'>('canvas');
  const [width, setWidth] = useState<number>();

  const pageWidth = useRef(0);
  const pageHeight = useRef(0);

  const handlePreviousPage = () => {
    setPageNum((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleDialogClose = () => {
    handleClose();
    setPages(0);
    setPageNum(1);
    pageWidth.current = 0;
    pageHeight.current = 0;
  };

  const onPageLoadSuccess = ({ width: newWidth, height: newHeight }: PDFPageProxy) => {
    pageWidth.current = newWidth;
    pageHeight.current = newHeight;
    if (newWidth !== width) {
      console.log('changing width');
      setWidth(newWidth > MAX_WIDTH ? MAX_WIDTH : undefined);
    }
  };

  const changeFile = (file?: File) => {
    setPageNum(1);
    setCurrentFile(file);
  };

  return (
    <Dialog maxWidth={false} open={open} onClose={handleDialogClose} style={{ overflow: 'hidden' }}>
      <DialogTitle disableTypography>
        <Typography className={classes.title}>Previewing: {currentFile?.name}</Typography>
        <IconButton
          style={{ right: '0px', top: '0px', position: 'absolute' }}
          onClick={handleDialogClose}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          maxHeight: window.innerHeight - 124 - (secondaryFile ? 32 : 0),
          maxWidth: window.innerWidth - 64,
        }}
      >
        {secondaryFile && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant={currentFile === file ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => changeFile(file)}
            >
              View New Version
            </Button>
            <Button
              variant={currentFile !== file ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => changeFile(secondaryFile)}
              style={{ marginLeft: 8 }}
            >
              View Existing Version
            </Button>
          </div>
        )}
        {currentFile ? (
          <Document
            loading={<FullscreenLoader />}
            file={currentFile}
            onLoadSuccess={({ numPages }) => setPages(numPages)}
          >
            <Page
              loading={<div style={{ width: pageWidth.current, height: pageHeight.current }} />}
              pageNumber={pageNum}
              onLoadSuccess={onPageLoadSuccess}
              width={width}
              renderMode={renderMode}
              onRenderError={(e) => {
                console.error(e);
                setRenderMode('svg');
              }}
            />
          </Document>
        ) : (
          <FullscreenLoader />
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-evenly' }}>
        <Button disabled={pageNum <= 1} onClick={handlePreviousPage}>
          Previous Page
        </Button>
        <Typography>
          {pageNum} / {pages}
        </Typography>
        <Button disabled={pageNum >= pages} onClick={handleNextPage}>
          Next Page
        </Button>
      </DialogActions>
    </Dialog>
  );
}
