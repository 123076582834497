import {
  Card,
  CardContent,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { PublishType } from './DCCUtils';
import { IFile } from '../../api-client/autogenerated';
import { ascendingComparator } from '../document-index/DocumentIndexUtils';
import { parseDate } from '../../scripts/utils';
import SpecificationJobTableRow, { FileInfo } from './SpecificationJobTableRow';

type Props = {
  type: PublishType;
  files?: IFile[];
  fileInfo?: FileInfo[];
};

const useStyles = makeStyles(() =>
  createStyles({
    titleStyle: {
      backgroundColor: '#1F34BC',
      borderRadius: '4px 4px 0px 0px',
    },
    fileNameContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifySelf: 'center',
      width: '80%',
      maxHeight: 160,
      overflowY: 'auto',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'black',
    },
  }),
);

export default function DCCFileCard(props: Props) {
  const classes = useStyles();
  const { type, files, fileInfo } = props;

  const getTitle = () => {
    switch (type) {
      case PublishType.Submittals:
        return 'Submittal Placeholders';
      case PublishType.Specifications:
        return 'Specifications';
      case PublishType.Drawings:
        return 'Drawings';
      default:
        return 'N/A';
    }
  };

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Card style={{ width: 850, marginBottom: 25 }}>
        <CardContent className={classes.titleStyle}>
          <h2 style={{ textAlign: 'center', color: '#FFFFFF', margin: 0 }}>
            {getTitle()} - All Documents
          </h2>
        </CardContent>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography align="center" style={{ fontWeight: 500, fontSize: 18, marginBottom: 16 }}>
            All Documents
          </Typography>
          <TableContainer style={{ maxHeight: 250, width: '95%' }}>
            <Table stickyHeader>
              <TableHead>
                <TableCell width={type !== PublishType.Specifications ? '100%' : undefined}>
                  Name
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>Uploaded On</TableCell>
                {type === PublishType.Specifications && <TableCell width="100%" />}
              </TableHead>
              <TableBody>
                {type !== PublishType.Specifications
                  ? files
                      ?.sort((a, b) => ascendingComparator(a, b, 'createdOn'))
                      .map((file) => (
                        <TableRow key={file.id}>
                          <TableCell>{file.name}</TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            {parseDate(file.createdOn!).format('MM/DD/YYYY hh:mm A')}
                          </TableCell>
                        </TableRow>
                      ))
                  : fileInfo
                      ?.sort((a, b) => ascendingComparator(a.file, b.file, 'createdOn'))
                      .map((f) => <SpecificationJobTableRow key={f.file.id} fileInfo={f} />)}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  );
}
