import { ApiClient } from '../../api-client/api-client';
import { isPublicPage } from '../../scripts/utils';
import { IJobStatus, JobType } from '../../api-client/autogenerated';

export const getFileUrlById = async (fileId: string, fullSize?: boolean, filename?: string) => {
  const unauthenticatedUserEmail = isPublicPage()
    ? localStorage.getItem('publicEmail') || undefined
    : undefined;
  const response = await ApiClient.getFileLinkById({
    fileId,
    unauthenticatedUserEmail,
    fullSize,
    filename,
  });
  return response.data;
};

export const getFileUrlByIdForConsultant = async (fileId: string, reviewDocumentId: string) => {
  const unauthenticatedUserEmail = isPublicPage()
    ? localStorage.getItem('publicEmail') || undefined
    : undefined;
  return ApiClient.getFileLinkByIdForConsultant({
    fileId,
    reviewDocumentId,
    unauthenticatedUserEmail,
  }).then((res) => res.data);
};

export const getFileById = async (fileId: string) => {
  return ApiClient.getFileById({ fileId }).then((res) => res.data);
};

export const getBluebeamXML = (fileId: string, additionalReviewDocumentId?: string) => {
  return ApiClient.getBluebeamXml({ fileId, additionalReviewDocumentId }).then(
    (res) => (res.data as unknown) as string,
  );
};

export const getLatestSubmissionFileByDocumentId = (documentId: string) => {
  return ApiClient.getLatestSubmissionFileByDocumentId({ documentId }).then((res) => res.data.file);
};

export const getLatestSubmissionFileByKey = (key: string, consultantReviewDocumentId?: string) => {
  return ApiClient.getLatestSubmissionFileByKey({
    key,
    consultantReviewDocumentId,
  }).then((res) => res.data.file);
};

export const flattenBluebeamByDocumentId = (documentId: string, filename: string) => {
  return ApiClient.flattenBlueBeamByDocumentId({
    documentId,
    submittalFlattenRequest: { flattenedOutputName: filename },
  }).then((res) => res.data);
};

export const getParserJobStatusByObjectKey = (
  objectKey: string,
): Promise<IJobStatus | undefined> => {
  return ApiClient.getParserJobStatusByObjectKey({
    parserJobStatusRequest: { objectKey },
  }).then((res) => res.data);
};
