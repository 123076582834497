import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import FolderIcon from '@material-ui/icons/Folder';
import { HighlightOffRounded } from '@material-ui/icons';
import PublishIcon from '@material-ui/icons/Publish';

interface CreateFolderOrAddFileDialogProps {
  open: boolean;
  handleClose: () => void;
  handleUploadClick: () => void;
  handleCreateClick: () => void;
  disableUpload?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  paperRoot: {
    minWidth: 500,
  },
  button: {
    height: 128,
    width: 180,
    background: '#0947B9',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    borderRadius: 4,
    transition: '0.2s',
    cursor: 'pointer',
    '&:hover': {
      background: '#4380ED',
    },
  },
  buttonDisabled: {
    height: 128,
    width: 180,
    background: '#C9C8C9',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    borderRadius: 4,
    transition: '0.2s',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  tooltipText: {
    textAlign: 'center',
    textJustify: 'auto',
  },
}));
export default function CreateFolderOrAddFileDialog(props: CreateFolderOrAddFileDialogProps) {
  const { open, handleClose, handleUploadClick, handleCreateClick, disableUpload } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      className={classes.root}
      onClose={handleClose}
      maxWidth="md"
      PaperProps={{ classes: { root: classes.paperRoot } }}
    >
      <DialogTitle>
        <IconButton
          style={{ right: '24px', top: '24px', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>

      <DialogContent style={{ paddingTop: 48, paddingBottom: 48 }}>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Box
            className={classes.button}
            onClick={() => {
              handleClose();
              handleCreateClick();
            }}
          >
            <FolderIcon style={{ width: 48, height: 48, color: 'white' }} />{' '}
            <div style={{ color: 'white' }}>Create Folder</div>
          </Box>
          <Tooltip
            arrow
            placement="bottom"
            title={disableUpload ? 'You may only upload files inside of a folder' : ''}
            open
            classes={{ tooltip: classes.tooltipText }}
          >
            <Box
              className={disableUpload ? classes.buttonDisabled : classes.button}
              onClick={
                disableUpload
                  ? undefined
                  : () => {
                      handleClose();
                      handleUploadClick();
                    }
              }
            >
              <PublishIcon style={{ width: 48, height: 48, color: 'white' }} />{' '}
              <div style={{ color: 'white' }}>Upload Files</div>
            </Box>
          </Tooltip>
        </div>
      </DialogContent>
    </Dialog>
  );
}
