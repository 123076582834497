import React from 'react';
import {
  Button,
  Card,
  CardContent,
  createStyles,
  makeStyles,
  MenuItem,
  Select,
  TableCell,
} from '@material-ui/core';
import { IBid, IBidSetup } from '../../api-client/autogenerated';
import { useDispatch } from 'react-redux';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import AssignedUserText from '../../main-components/custom-components/AssignedUserText';
import Typography from '@material-ui/core/Typography';
import { retractBid } from '../../models/api/bid';
import { descendingComparator } from '../../main-components/document-index/DocumentIndexUtils';
import { addSnackbar } from '../../features/snackbar/actions';
import Chip from '@material-ui/core/Chip';
import dayjs from 'dayjs';
import { parseDate } from '../../scripts/utils';

type Props = {
  bidSetup?: IBidSetup;
  bids: IBid[];
  fetchBids: (projectId: string) => Promise<void>;
};

const useStyles = makeStyles(() =>
  createStyles({
    titleStyle: {
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
      fontSize: 26,
    },
    flex: {
      display: 'flex',
      marginBottom: 4,
      borderBottom: '1px solid #C9C8C9',
    },
    key: {
      width: '35%',
    },
    value: {
      width: '65%',
    },
  }),
);

export default function SubmittedBids(props: Props) {
  const { bidSetup, bids, fetchBids } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleRetract = async () => {
    const bidToRetract = bids.find((b) => !b.isRetracted);
    if (bidToRetract) {
      await retractBid(bidToRetract.id);
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Bid successfully retracted!',
          severity: 'success',
        }),
      );
      await fetchBids(bidSetup!.project!.id);
    }
  };

  if (bids.length === 0) return <></>;

  return (
    <Card>
      <CardContent className={classes.titleStyle}>
        <h2
          className="h2"
          style={{
            textAlign: 'left',
            color: '#FFFFFF',
            margin: 0,
            lineHeight: 1,
            fontSize: 26,
          }}
        >
          My Submitted Bid
        </h2>
      </CardContent>
      <CardContent>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRetract}
          disabled={
            !bidSetup?.dueDate ||
            new Date(Date.parse(bidSetup.dueDate)) < new Date() ||
            bids.every((bid) => bid.isRetracted)
          }
        >
          Retract Bid
        </Button>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>Submitted By</TableCell>
              <TableCell>License #</TableCell>
              <TableCell>Submitted On</TableCell>
              <TableCell>Attachments</TableCell>
            </TableHead>
            <TableBody>
              {bids
                .sort((a, b) => descendingComparator(a, b, 'createdOn'))
                .map((bid) => (
                  <TableRow key={bid.id}>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AssignedUserText
                          name={bid.projectUser?.primeBidder?.contactName || ''}
                          company={bid.projectUser?.primeBidder?.companyName || ''}
                        />
                        {bid.isRetracted && (
                          <Chip
                            size="small"
                            label="Retracted"
                            style={{ marginLeft: 24, backgroundColor: '#F28B00', color: '#FFF' }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Typography>{bid.projectUser?.primeBidder?.contractorLicenseNo}</Typography>
                    </TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ fontWeight: 500 }}>
                          {parseDate(bid.createdOn!).format('MM/DD/YYYY')}
                        </Typography>
                        <Typography color="textSecondary">
                          @{parseDate(bid.createdOn!).format('h:mm A')}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell style={{ paddingLeft: 0 }}>
                      <Select
                        variant="outlined"
                        displayEmpty
                        fullWidth
                        disabled={!bid.files?.length}
                        renderValue={() => `${bid.files?.length || 0} Attachments`}
                        style={{ marginLeft: 0 }}
                      >
                        {bid.files?.map((file) => (
                          <MenuItem disabled key={file.id}>
                            {file.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
