import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { HighlightOffRounded, Search } from '@material-ui/icons';
import {
  Avatar,
  FormControl,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  TextField,
} from '@material-ui/core';
import CircularLoader from '../loader/CircularLoader';
import {
  getColorFromEmailStatus,
  getUserFacingEmailStatus,
  sortLastNamesDesc,
} from '../../scripts/utils';
import { EmailStatus } from '../../api-client/autogenerated';

export type DisplayUser = {
  id: string;
  email: string;
  publicCompanyName?: string | null;
  name: string;
  company?: {
    name: string;
  } | null;
  emailStatus?: EmailStatus;
};

interface UserFilterListDialogProps {
  title: string;
  users: DisplayUser[];
  dialogOpen: boolean;
  handleClose: () => void;
  userOnClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, user: DisplayUser) => void;
  closeOnSelect?: boolean;
  isLoading?: boolean;
  emptyText?: string;
  isActivityLog?: boolean;
}

const useStyles = makeStyles({
  dialog: {
    width: '100%',
    borderTop: '1px solid #EDECEC',
    alignSelf: 'center',
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '23px',
    lineHeight: '26px',
    textAlign: 'left',
    color: '#0947B9',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '60px',
    padding: '16px 16px 8px 16px',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  formControl: {
    width: '92%',
    // marginLeft: '20px',
    marginBottom: '8px',
    alignSelf: 'center',
  },
  textfield: {
    height: '32px',
    background: '#F9F9F9',
    /* Gray / Gray 400 Brand dark */

    border: '1px solid #949494',
    borderRadius: '5px',
  },
  icon: {
    fill: '#B2B1B2',
    marginLeft: '4px',
  },
});

export default function UserFilterListDialog(props: UserFilterListDialogProps) {
  const classes = useStyles();
  const {
    title,
    users,
    dialogOpen,
    handleClose,
    userOnClick = () => {},
    closeOnSelect,
    isLoading = false,
    emptyText = 'No available users to assign to.',
    isActivityLog,
  } = props;

  const [input, setInput] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={dialogOpen} onClose={handleClose}>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
        <IconButton
          style={{ right: '20px', top: '18px', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>

      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          className={classes.textfield}
          placeholder="Search..."
          value={input}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search
                  fontSize="small"
                  classes={{
                    root: classes.icon,
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      {isActivityLog ? (
        <Typography style={{ marginLeft: 16, marginRight: 16 }}>
          These users received instant notification in Centerline. Email notifications are sent
          subject to the users' notification preferences.
        </Typography>
      ) : null}
      {isLoading ? (
        <div style={{ height: 48 }}>
          <CircularLoader />
        </div>
      ) : (
        <List className={classes.dialog}>
          {users.length > 0 ? (
            users
              .filter((user) => {
                if (input) {
                  const name = user.name.toLowerCase();
                  const company = (user.company?.name || user.publicCompanyName)?.toLowerCase();
                  if (company) {
                    return name.includes(input) || company.includes(input);
                  }
                  return name.includes(input);
                }

                return true;
              })
              .sort((a, b) => sortLastNamesDesc(a.name, b.name))
              .map((user) => {
                return (
                  <ListItem
                    key={user.id}
                    button
                    onClick={(event) => {
                      if (closeOnSelect) handleClose();
                      userOnClick(event, user);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar alt="" />
                    </ListItemAvatar>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {`${user.name}, ${user.company?.name || user.publicCompanyName || 'N/A'}`}

                      {user.emailStatus ? (
                        <span style={{ color: getColorFromEmailStatus(user.emailStatus) }}>
                          {`[Email ${getUserFacingEmailStatus(user.emailStatus)}]`}
                        </span>
                      ) : null}
                    </div>
                  </ListItem>
                );
              })
          ) : (
            <Typography variant="body2" style={{ padding: '16px 0px 16px 24px' }}>
              {emptyText}
            </Typography>
          )}
        </List>
      )}
    </Dialog>
  );
}
