import React, { useEffect, useRef, useState } from 'react';
import List from '@material-ui/core/List';
import { Card, IconButton, Theme, Tooltip } from '@material-ui/core';
import { getNotificationsByBidSetupId } from '../../models/api/notifications';
import { useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import { INotification } from '../../api-client/autogenerated';
import ActivityItem from '../activity/ActivityItem';
import CardContent from '@material-ui/core/CardContent';
import CircularLoader from '../loader/CircularLoader';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import { descendingComparator } from '../document-index/DocumentIndexUtils';
import { useReactToPrint } from 'react-to-print';
import { Print } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      overflowX: 'auto',
    },
    list: {
      padding: '0px 16px 16px 16px',
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '16px',
    },
    paginationUl: {
      flexWrap: 'nowrap',
    },
  }),
);

const PAGE_SIZE = 6;

export default function PlanholderLog() {
  const classes = useStyles();
  const project = useSelector(getProjectState);

  const [isLoading, setIsLoading] = useState(true);
  const [isPrinting, setIsPrinting] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [allNotifications, setAllNotifications] = useState<INotification[]>([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const printRef = useRef(null);

  useEffect(() => {
    if (project?.bidSetupId) {
      setIsLoading(true);
      getNotificationsByBidSetupId(project?.bidSetupId, page, PAGE_SIZE)
        .then(({ results, totalPages }) => {
          setNotifications(results);
          setTotalPages(totalPages);
        })
        .finally(() => setIsLoading(false));
    }
  }, [project, page]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, pageNum: number) => {
    setPage(pageNum - 1);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () =>
      new Promise<void>((resolve) => {
        setIsPrinting(true);
        setTimeout(async () => {
          if (allNotifications.length === 0) {
            const { results } = await getNotificationsByBidSetupId(project!.bidSetupId!, 0, 1000);
            setAllNotifications(results);
          }
          resolve();
        }, 500);
      }),
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });

  return (
    <Card className={classes.root}>
      <CardContent
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <h2 className="h2" style={{ color: '#0947B9', fontSize: '26px', fontWeight: 600 }}>
          Planholder Activity Log
        </h2>
        {!isPrinting ? (
          <Tooltip title="Print" arrow placement="top">
            <IconButton>
              <Print style={{ display: 'inline-flex' }} onClick={handlePrint} />
            </IconButton>
          </Tooltip>
        ) : (
          <CircularLoader size={24} />
        )}
      </CardContent>
      {!isLoading && notifications.length === 0 && (
        <Typography>No activity notifications for these planholders.</Typography>
      )}
      {isLoading ? (
        <div style={{ padding: 50, position: 'relative', top: '50%' }}>
          <CircularLoader />
        </div>
      ) : (
        <>
          <List ref={printRef} className={classes.list}>
            {(!isPrinting ? notifications : allNotifications)
              .sort((a, b) => descendingComparator(a, b, 'createdOn'))
              .map((n) => (
                <ActivityItem key={n.id} notification={n} isPlanholder />
              ))}
          </List>
          <Pagination
            count={totalPages}
            shape="rounded"
            color="primary"
            showFirstButton
            showLastButton
            page={page + 1}
            siblingCount={0}
            onChange={handleChangePage}
            className={classes.pagination}
            classes={{ ul: classes.paginationUl }}
          />
        </>
      )}
    </Card>
  );
}
