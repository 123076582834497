import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { HighlightOffRounded } from '@material-ui/icons';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import {
  getPhysicalBuildingsState,
  getPhysicalFloorsState,
} from '../../features/project/selectors';
import LabelWithCheckbox from '../custom-components/LabelWithCheckbox';
import { IPhysicalFloor } from '../../api-client/autogenerated';
import { getFileBlob } from '../../scripts/utils';

type Props = {
  open: boolean;
  onClose: () => void;
  onFinish: (floorplanFile: File, floorId: string) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    rootIconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    dialogTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '30px',
      textAlign: 'left',
      color: '#0947B9',
      textTransform: 'none',
    },
  }),
);

export default function SelectFloorPlanDialog(props: Props) {
  const classes = useStyles();
  const { open, onClose, onFinish } = props;

  const buildings = useSelector(getPhysicalBuildingsState);
  const floors = useSelector(getPhysicalFloorsState);

  const [selectedBuildingId, setSelectedBuildingId] = useState('');
  const [selectedFloorId, setSelectedFloorId] = useState('');

  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const selectedBuilding = buildings.find((b) => b.id === selectedBuildingId);

  const eligibleFloors = floors.filter(
    (f) => f.buildingId === selectedBuildingId && f.floorPlanDrawingFileId,
  );
  const selectedFloor = eligibleFloors.find((f) => f.id === selectedFloorId);

  const handleDownloadProgress = (event: any) => {
    setDownloadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleDownload = async () => {
    if (!selectedFloor?.floorPlanDrawingFile) {
      // something has gone wrong
      return;
    }

    setIsDownloading(true);
    const fileBlob = await getFileBlob(
      selectedFloor.floorPlanDrawingFile.id,
      false,
      handleDownloadProgress,
    );

    if (fileBlob) {
      const floorplanFile = new File([fileBlob], selectedFloor.floorPlanDrawingFile.name, {
        type: 'application/pdf',
      });

      onFinish(floorplanFile, selectedFloorId);
    }

    setIsDownloading(false);
  };

  const handleClose = () => {
    setSelectedBuildingId('');
    setSelectedFloorId('');
    onClose();
  };

  const getFloorLabel = (floor: IPhysicalFloor) => {
    if (!floor.floorPlanDrawingFile) return floor.name;
    return floor.name + ' — ' + floor.floorPlanDrawingFile.name;
  };

  return (
    <Dialog open={open} PaperProps={{ style: { maxWidth: 500 } }}>
      <DialogTitle disableTypography className={classes.dialogTitle} style={{ paddingBottom: 0 }}>
        SELECT A FLOORPLAN
        <IconButton
          style={{ right: '5%', top: '3%', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>

      <DialogContent style={{ paddingBottom: 24 }}>
        <Typography style={{ paddingBottom: 8 }}>Select a building.</Typography>
        <Select
          value={selectedBuildingId}
          onChange={(e) => setSelectedBuildingId(e.target.value as string)}
          style={{ width: '100%', height: 30 }}
          fullWidth
          variant="outlined"
          displayEmpty
          renderValue={() => selectedBuilding?.name || 'Select a building'}
        >
          {buildings
            .filter((b) => !b.isDefault)
            .map((building) => (
              <MenuItem key={building.id} value={building.id}>
                {building.name}
              </MenuItem>
            ))}
        </Select>
        <Typography style={{ paddingBottom: 8, paddingTop: 8 }}>
          Select a floor or area for identifying locations.
        </Typography>
        <Select
          value={selectedFloorId}
          onChange={(e) => setSelectedFloorId(e.target.value as string)}
          style={{ width: '100%', height: 30 }}
          fullWidth
          disabled={!selectedBuildingId}
          variant="outlined"
          displayEmpty
          renderValue={() => (selectedFloor ? getFloorLabel(selectedFloor) : 'Select a floor')}
          // @ts-ignore
          MenuProps={{ getContentAnchorEl: () => null }}
        >
          {eligibleFloors.map((floor) => (
            <MenuItem key={floor.id} value={floor.id}>
              {getFloorLabel(floor)}
            </MenuItem>
          ))}
        </Select>
        {!isDownloading ? (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={!selectedFloorId}
            style={{ padding: '3px 30px', marginTop: 24 }}
            onClick={handleDownload}
          >
            CONTINUE
          </Button>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography style={{ marginBottom: 4, marginTop: 4 }}>{downloadProgress}%</Typography>
            <LinearProgress
              variant="determinate"
              value={downloadProgress}
              style={{ height: 6, width: '100%' }}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
