import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import SecurityGroupsPage from '../form-pages/security-groups/SecurityGroupsPage';
import NavAppbar from '../nav-top/NavAppbar';
import { ISimplifiedProject, ISubscriber } from '../../api-client/autogenerated';
import { getSubscriberById, getSubscriberProjectUserSummary } from '../../models/api/subscribers';
import DashboardContainer from './DashboardContainer';
import UsersTable, { UserRow } from './UsersTable';

const SubscriberAdminDashboard = () => {
  const user = useSelector(getUserState);

  const [subscriber, setSubscriber] = useState<ISubscriber | null>(null);
  const [projects, setProjects] = useState<ISimplifiedProject[]>([]);
  const [userRows, setUserRows] = useState<UserRow[]>([]);

  const refreshSubscriber = () => {
    if (!user.adminOfSubscriberId) return;
    return getSubscriberById(user.adminOfSubscriberId).then(setSubscriber);
  };

  const buildRows = async (subscriberId: string) => {
    const summary = await getSubscriberProjectUserSummary(subscriberId);
    setProjects(summary.projects);
    setUserRows(
      summary.users.map((user) => {
        return {
          id: user.id,
          name: user.name,
          company: user.companyName || 'N/A',
          email: user.email,
          projects: summary.projects
            .filter((p) => user.projectIds.includes(p.id))
            .map((project) => project.name),
          disabled: user.isDisabled,
          isRegistered: user.isRegistered,
        };
      }),
    );
  };

  useEffect(() => {
    refreshSubscriber();
  }, [user]);

  useEffect(() => {
    if (subscriber) buildRows(subscriber.id);
  }, [subscriber]);

  return (
    <div>
      <NavAppbar />
      <h1 style={{ color: 'black' }}>Subscriber Admin Dashboard</h1>
      {!!user.adminOfSubscriberId ? (
        <>
          <DashboardContainer
            projects={projects}
            availableProjects={subscriber?.productPackage?.numberOfProjects || null}
            refreshSubscriber={refreshSubscriber}
          />
          <div style={{ marginBottom: '48px' }} />
          <UsersTable rows={userRows} />
          <SecurityGroupsPage isForAdminDashboard />
        </>
      ) : (
        <h2>You must be a subscriber admin to view this page.</h2>
      )}
    </div>
  );
};

export default SubscriberAdminDashboard;
