import React, { useEffect, useState } from 'react';
import {
  DocumentTemplateType,
  IBidSetup,
  INumberedDocumentView,
} from '../../api-client/autogenerated';
import { getDocumentsByProjectIdAndType } from '../../models/api/project';
import CircularLoader from '../../main-components/loader/CircularLoader';
import { Card, CardContent, createStyles, makeStyles } from '@material-ui/core';
import FolderAccordion from '../initial-bid-documents/FolderAccordion';
import BiddingTableSimple from '../initial-bid-documents/BiddingTableSimple';
import {
  fileIsPdf,
  formatFileSize,
  getFileBlob,
  getTitle,
  openInNewTab,
} from '../../scripts/utils';
import EyeIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import { ascendingComparator } from '../../main-components/document-index/DocumentIndexUtils';
import JSZip from 'jszip';
import fileDownload from 'js-file-download';
import Button from '@material-ui/core/Button';

type Props = {
  bidSetup?: IBidSetup;
};

const useStyles = makeStyles(() =>
  createStyles({
    titleStyle: {
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
      fontSize: 26,
    },
  }),
);

const Addenda: React.FC<Props> = (props) => {
  const { bidSetup } = props;
  const classes = useStyles();

  const [documents, setDocuments] = useState<INumberedDocumentView[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [expandedPanel, setExpandedPanel] = useState<undefined | number>(undefined);

  const handleChangePanel = (num: number) => {
    if (expandedPanel === num) setExpandedPanel(undefined);
    else setExpandedPanel(num);
  };

  const downloadAddenda = async (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    event.stopPropagation();
    const zip = new JSZip();
    const addendum = documents.find((doc) => doc.id === id);
    if (addendum && addendum.files) {
      await Promise.all(
        addendum.files.map(async (file) => {
          const blob = await getFileBlob(file.id);
          if (blob) zip.file(file.name, blob);
        }),
      );
      const contents = await zip.generateAsync({ type: 'blob', streamFiles: true });
      fileDownload(
        contents,
        `${bidSetup?.project ? `${bidSetup.project.name}_` : ''}${getTitle(
          DocumentTemplateType.Addenda,
          addendum,
        )}.zip`,
      );
    }
  };

  const getBody = () => {
    return documents.length > 0 ? (
      documents
        .sort((a, b) => ascendingComparator(a, b, 'documentNumber'))
        .map((document, index) => {
          const name = getTitle(DocumentTemplateType.Addenda, document);
          const { files } = document;
          return (
            <FolderAccordion
              key={document.id}
              index={index}
              expandedIndex={expandedPanel}
              handleChangeIndex={handleChangePanel}
              name={name}
              headerButton={
                <Button
                  onClick={(e) => downloadAddenda(e, document.id)}
                  style={{ padding: 0, marginRight: 32, color: 'white' }}
                >
                  Download
                </Button>
              }
            >
              <BiddingTableSimple
                headers={['Name', 'Size', 'View']}
                rows={
                  files
                    ? files.map((x) => [
                        x.name,
                        x.size ? formatFileSize(x.size) : '',
                        fileIsPdf(x) ? (
                          <IconButton
                            key={x.id}
                            onClick={() => openInNewTab(x.id, true)}
                            style={{ padding: 0 }}
                          >
                            <EyeIcon />
                          </IconButton>
                        ) : null,
                      ])
                    : []
                }
                emptyText={`No files in Addendum #${index + 1}`}
              />
            </FolderAccordion>
          );
        })
    ) : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: 32,
          paddingBottom: 32,
          color: 'rgb(130,130,130)',
        }}
      >
        <div>No Addenda for This Project.</div>
      </div>
    );
  };

  useEffect(() => {
    if (bidSetup?.project?.id) {
      setIsLoading(true);
      getDocumentsByProjectIdAndType(bidSetup.project.id, DocumentTemplateType.Addenda, 1000, [
        { whereColumn: 'isFileLocked', whereOperator: '=', whereValue: 'true' },
      ])
        .then((result) => {
          setDocuments(result.filter((x) => !x.isHidden));
        })
        .catch(() => setDocuments([]))
        .finally(() => setIsLoading(false));
    }
  }, [bidSetup?.project?.id]);

  return (
    <>
      <Card style={{ height: '100%', minHeight: 450 }}>
        <CardContent className={classes.titleStyle}>
          <h2
            className="h2"
            style={{
              textAlign: 'left',
              color: '#FFFFFF',
              margin: 0,
              lineHeight: 1,
              fontSize: 26,
            }}
          >
            Addenda
          </h2>
        </CardContent>
        <CardContent>
          {isLoading ? (
            <div style={{ position: 'relative', top: '50%', padding: 150 }}>
              <CircularLoader />
            </div>
          ) : (
            getBody()
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default Addenda;
