import React from 'react';

const DraftsIcon = (props: any) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.54 4.873V0H3.46v4.873L0 7.321v12.721h20V7.321l-3.46-2.448zm1.625 2.785l-1.625 1.16V6.52l1.625 1.14zM15.19 1.35v8.418L10 13.438l-5.19-3.67V1.35h10.38zM3.46 8.82l-1.625-1.16L3.46 6.497v2.32zm-2.11 9.873V8.966L10 15.084l8.65-6.118v9.747H1.35v-.021z"
      fill="#4380ED"
    />
  </svg>
);

export default DraftsIcon;
