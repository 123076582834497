import React from 'react';
import NavAppbar from '../../main-components/nav-top/NavAppbar';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { formatDateLong } from '../../scripts/utils';

const useStyles = makeStyles(() => ({
  content: {
    padding: 16,
  },
  title: {
    fontSize: 28,
    lineHeight: '36px',
    fontWeight: 500,
  },
}));
export default function LockedBidPage() {
  const classes = useStyles();
  const {
    location: { state },
  } = useHistory();

  return (
    <main className={classes.content}>
      <NavAppbar isBiddingPortal />
      <Typography align="center" className={classes.title} style={{ marginTop: '25vh' }}>
        This project goes live {formatDateLong(state.liveAt)}
      </Typography>
      <Typography align="center" className={classes.title}>
        Please check back then to get access to project documents.
      </Typography>
    </main>
  );
}
