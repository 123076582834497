// @ts-nocheck
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AdminMainApp from './admin-main-app';

function AdminPortal() {
  const { path } = useRouteMatch();
  return (
    <div className="admin">
      <Switch>
        <Route path={`${path}`} component={AdminMainApp} />
      </Switch>
    </div>
  );
}

export default AdminPortal;
