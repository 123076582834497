import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  getPhysicalBuildingsState,
  getPhysicalFloorsState,
  getPhysicalLocationsState,
} from '../../features/project/selectors';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { HighlightOffRounded } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { formatMoney, getImageByFileId } from '../../scripts/utils';
import { getDocumentsState } from '../../features/documents/selectors';
import {
  IPhysicalFloor,
  IPhysicalLocation,
  PunchListStatusType,
} from '../../api-client/autogenerated';
import { ascendingComparator } from '../document-index/DocumentIndexUtils';
import { Pagination } from '@material-ui/lab';
import { LocationRegion, PunchListMarker } from './ImageWithMarkers';
import ViewFloorplanWithMarkersDialog from './ViewFloorplanWithMarkersDialog';
import pluralize from 'pluralize';
import { ZOOM_FACTOR } from './PunchListRegionSelector';
import FullscreenLoader from '../loader/FullscreenLoader';
import CircularLoader from '../loader/CircularLoader';

const useStyles = makeStyles(() =>
  createStyles({
    rootIconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    dialogTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '21px',
      textAlign: 'left',
      color: '#0947B9',
      textTransform: 'none',
      paddingBottom: 0,
    },
    itemImage: {
      objectFit: 'contain',
      borderWidth: 2,
      borderColor: '#7A797A',
      borderStyle: 'solid',
    },
  }),
);

interface DialogProps {
  open: boolean;
  fileId: string;
  buildingName?: string;
  floorName?: string;
  locationName?: string;
  markers: PunchListMarker[];
  regions?: LocationRegion[];
  markerTop?: number;
  markerLeft?: number;
}

interface ViewByFloorplanState {
  buildingId: string;
  floorId: string;
  dialogProps: DialogProps;
}

interface LocationItem {
  id: string;
  location: IPhysicalLocation;
  floorName: string;
  buildingName: string;
  image: string;
  cost: number;
  countTotal: number;
  countOpen: number;
  countReadyForVerification: number;
  countClosed: number;
  markers: PunchListMarker[];
}

type Props = {
  open: boolean;
  onClose: () => void;
  defaultFloor?: string;
  setLocation?: (locationId: string) => void;
};

const ITEM_IMAGE_SIZE = 250;
const ITEMS_PER_PAGE = 6;

export default function ViewByFloorplanDialog(props: Props) {
  const classes = useStyles();
  const { open, onClose, defaultFloor, setLocation } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const documents = useSelector(getDocumentsState);
  const buildings = useSelector(getPhysicalBuildingsState);
  const floors = useSelector(getPhysicalFloorsState);
  const locations = useSelector(getPhysicalLocationsState);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedBuildingId, setSelectedBuildingId] = useState('');
  const [selectedFloorId, setSelectedFloorId] = useState(defaultFloor || '');
  const [floorPlanImageFile, setFloorPlanImageFile] = useState<string>();
  const [locationItems, setLocationItems] = useState<LocationItem[]>([]);
  const [page, setPage] = useState(0);
  const [viewImageDialogProps, setViewImageDialogProps] = useState<DialogProps>({
    open: false,
    markers: [],
    fileId: '',
  });

  const eligibleBuildings = buildings.filter((b) => !b.isDefault);

  useEffect(() => {
    const state = sessionStorage.getItem('viewByFloorplanState');
    if (state) {
      const { buildingId, floorId, dialogProps } = JSON.parse(state) as ViewByFloorplanState;
      setSelectedBuildingId(buildingId);
      setSelectedFloorId(floorId);
      setViewImageDialogProps(dialogProps);
    } else if (defaultFloor) {
      setSelectedFloorId(defaultFloor);
      const floor = floors.find((f) => f.id === defaultFloor);
      const building = buildings.find((b) => b.id === floor?.buildingId);
      if (building) setSelectedBuildingId(building.id);
    } else if (eligibleBuildings.length === 1) {
      setSelectedBuildingId(eligibleBuildings[0].id);
      const filteredFloors = floors.filter(
        (f) => f.buildingId === eligibleBuildings[0].id && !!f.floorPlanImageFileId,
      );
      if (filteredFloors.length === 1) {
        setSelectedFloorId(filteredFloors[0].id);
      }
    }
  }, [buildings, defaultFloor]);

  const renderFloor = async (floor: IPhysicalFloor) => {
    if (floor.floorPlanImageFileId) {
      const image = await getImageByFileId(floor.floorPlanImageFileId, true);
      setFloorPlanImageFile(image);
      await buildLocationsList(floor.id);
    }
    setIsLoading(false);
  };

  const buildLocationsList = async (floorId: string) => {
    const locationsOnFloor = locations.filter((location) => location.floorId === floorId);
    const floor = floors.find((floor) => floor.id === floorId);
    const building = buildings.find((building) => building.id === floor?.buildingId);
    const items: LocationItem[] = await Promise.all(
      locationsOnFloor
        .sort((a, b) => ascendingComparator(a, b, 'name'))
        .map(async (location) => {
          const itemsInLocation = documents.filter((doc) => doc.physicalLocationId === location.id);
          const openItems = itemsInLocation.filter(
            (doc) =>
              doc.punchListStatus &&
              [PunchListStatusType.New, PunchListStatusType.InProgress].includes(
                doc.punchListStatus,
              ),
          );
          const readyForVerificationItems = itemsInLocation.filter(
            (doc) => doc.punchListStatus === PunchListStatusType.ReadyForVerification,
          );
          const closedItems = itemsInLocation.filter(
            (doc) =>
              doc.punchListStatus &&
              [PunchListStatusType.Accepted, PunchListStatusType.Rejected].includes(
                doc.punchListStatus,
              ),
          );
          const totalCost = itemsInLocation
            .filter(
              (doc) =>
                doc.punchListStatus &&
                ![PunchListStatusType.Accepted, PunchListStatusType.Rejected].includes(
                  doc.punchListStatus,
                ),
            )
            .map((doc) => doc.cost ?? 0)
            .reduce((prev, cur) => prev + cur, 0);
          const image = location.locationImageFileId
            ? await getImageByFileId(location.locationImageFileId)
            : '';
          const markers = itemsInLocation
            .filter((doc) => doc.markedLocation)
            .map((doc) => ({
              id: doc.id,
              marker: doc.markedLocation!,
              status: doc.punchListStatus || undefined,
            }));

          return {
            id: location.id,
            location,
            floorName: floor?.name || '',
            buildingName: building?.name || '',
            cost: totalCost,
            countTotal: itemsInLocation.length,
            countOpen: openItems.length,
            countReadyForVerification: readyForVerificationItems.length,
            countClosed: closedItems.length,
            image,
            markers,
          };
        }),
    );
    setLocationItems(items);
  };

  useEffect(() => {
    if (open) {
      const selectedFloor = floors.find((f) => f.id === selectedFloorId);
      if (selectedFloor) {
        setFloorPlanImageFile(undefined);
        setLocationItems([]);
        setIsLoading(true);
        renderFloor(selectedFloor);
      }
    }
  }, [selectedFloorId, open]);

  const itemOnClick = (item: LocationItem) => {
    if (setLocation) setLocation(item.id);
    else if (item.location.locationImageFileId)
      setViewImageDialogProps({
        open: true,
        fileId: item.location.locationImageFileId,
        locationName: item.location.name,
        floorName: item.floorName,
        buildingName: item.buildingName,
        markers: item.markers,
      });
  };

  const floorPlanOnClick = () => {
    const selectedFloor = floors.find((f) => f.id === selectedFloorId);
    const building = buildings.find((building) => building.id === selectedFloor?.buildingId);
    if (selectedFloor?.floorPlanImageFileId) {
      setViewImageDialogProps({
        open: true,
        fileId: selectedFloor.floorPlanImageFileId,
        floorName: selectedFloor.name,
        buildingName: building?.name,
        markers: locationItems
          .filter((item) => item.location.floorId === selectedFloor.id)
          .map(({ markers, location }) =>
            markers.map((item) => {
              return {
                id: item.id,
                status: item.status,
                marker: {
                  x: item.marker.x,
                  y: item.marker.y,
                },
                locationId: location.id,
              };
            }),
          )
          .flat(),
        regions: selectedFloor.locations
          ?.filter((l) => !!l.markedLocation)
          .map((location) => ({
            id: location.id,
            region: location.markedLocation!,
          })),
      });
    }
  };

  const floorPlanRegionOnClick = (id: string) => {
    const item = locationItems.find((item) => item.id === id);
    if (item) {
      if (setLocation) {
        setLocation(item.location.id);
        setViewImageDialogProps({ open: false, fileId: '', markers: [] });
      } else if (item.location.locationImageFileId) {
        setViewImageDialogProps({ open: false, fileId: '', markers: [] });
        setViewImageDialogProps({
          open: true,
          fileId: item.location.locationImageFileId,
          locationName: item.location.name,
          floorName: item.floorName,
          buildingName: item.buildingName,
          markers: item.markers,
        });
      }
    }
  };

  const getItemCount = (floorId: string) => {
    const locationIds = locations.filter((loc) => loc.floorId === floorId).map((loc) => loc.id);
    return documents.filter(
      (doc) => doc.physicalLocationId && locationIds.includes(doc.physicalLocationId),
    ).length;
  };

  const onNavigation = () => {
    const state: ViewByFloorplanState = {
      buildingId: selectedBuildingId,
      floorId: selectedFloorId,
      dialogProps: viewImageDialogProps,
    };
    sessionStorage.setItem('viewByFloorplanState', JSON.stringify(state));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{ style: { minWidth: '50%', minHeight: '90vh' } }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        View By Floorplans
        <IconButton
          style={{ right: 12, top: 15, position: 'absolute' }}
          onClick={onClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        {!defaultFloor && (
          <Typography>Select which building and floor to view rooms for.</Typography>
        )}
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 8, marginBottom: 8 }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '30%', marginRight: 8 }}>
            <Typography style={{ fontSize: '14px', marginBottom: 4 }}>Building</Typography>
            <Select
              variant="outlined"
              displayEmpty
              disabled={!!defaultFloor || buildings.length === 1}
              value={selectedBuildingId}
              onChange={(e) => {
                setSelectedBuildingId(e.target.value as string);
                setSelectedFloorId('');
              }}
              style={{ height: 30 }}
            >
              <MenuItem disabled value="">
                Select Building
              </MenuItem>
              {eligibleBuildings
                .sort((a, b) => ascendingComparator(a, b, 'name'))
                .map((building) => (
                  <MenuItem key={building.id} value={building.id}>
                    {building.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
            <Typography style={{ fontSize: '14px', marginBottom: 4 }}>Floor</Typography>
            <Select
              variant="outlined"
              displayEmpty
              disabled={!!defaultFloor || !selectedBuildingId}
              value={selectedFloorId}
              onChange={(e) => setSelectedFloorId(e.target.value as string)}
              style={{ height: 30 }}
            >
              <MenuItem disabled value="">
                Select Floor
              </MenuItem>
              {floors
                .filter((floor) => floor.buildingId === selectedBuildingId)
                .sort((a, b) => ascendingComparator(a, b, 'index'))
                .map((floor) => {
                  const itemCount = getItemCount(floor.id);
                  return (
                    <MenuItem
                      key={floor.id}
                      disabled={!floor.floorPlanImageFileId}
                      value={floor.id}
                    >
                      {floor.name} ({itemCount} {pluralize('item', itemCount)})
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
        </div>
        {isLoading && !floorPlanImageFile ? (
          <CircularLoader style={{ marginTop: 64, marginBottom: 64 }} />
        ) : null}
        {floorPlanImageFile ? (
          <img
            src={floorPlanImageFile}
            alt="floor-plan"
            width="100%"
            onClick={floorPlanOnClick}
            className={classes.itemImage}
            style={{ objectFit: 'cover' }}
          />
        ) : null}
        <Divider style={{ backgroundColor: '#BFBFBF', marginTop: 8 }} />
        {floorPlanImageFile && (
          <>
            <Typography style={{ marginTop: 8, marginBottom: 16, fontSize: 14 }}>
              Select a location.
            </Typography>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: isMobile ? 'center' : 'space-between',
                gap: 16,
              }}
            >
              {locationItems
                .slice(page * ITEMS_PER_PAGE, (page + 1) * ITEMS_PER_PAGE)
                .map((item) => (
                  <div
                    key={item.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: 16,
                    }}
                  >
                    <Typography style={{ alignSelf: 'center', marginBottom: 8 }}>
                      {item.location.name}
                    </Typography>
                    <img
                      src={item.image}
                      alt={item.location.name}
                      width={ITEM_IMAGE_SIZE}
                      height={ITEM_IMAGE_SIZE}
                      onClick={() => itemOnClick(item)}
                      className={classes.itemImage}
                    />
                    {!defaultFloor && (
                      <>
                        <Typography style={{ marginTop: 8, marginBottom: 2 }}>
                          Total Cost: {formatMoney(item.cost)}
                        </Typography>
                        <Typography style={{ marginBottom: 2 }}>
                          Total Items: {item.countTotal}
                        </Typography>
                        <Typography style={{ marginBottom: 2 }}>{item.countOpen} Open</Typography>
                        <Typography style={{ marginBottom: 2 }}>
                          {item.countReadyForVerification} Ready For Verification
                        </Typography>
                        <Typography style={{ marginBottom: 2 }}>
                          {item.countClosed} Closed
                        </Typography>
                      </>
                    )}
                  </div>
                ))}
            </div>
            <Pagination
              count={Math.ceil(locationItems.length / ITEMS_PER_PAGE)}
              shape="rounded"
              color="primary"
              showFirstButton
              showLastButton
              page={page + 1}
              onChange={(e, page) => setPage(page - 1)}
              style={{ alignSelf: 'center' }}
            />
          </>
        )}
      </DialogContent>
      {viewImageDialogProps.fileId && (
        <ViewFloorplanWithMarkersDialog
          open={viewImageDialogProps.open}
          onClose={() => setViewImageDialogProps((prev) => ({ ...prev, open: false }))}
          fileId={viewImageDialogProps.fileId}
          locationName={viewImageDialogProps.locationName}
          floorName={viewImageDialogProps.floorName}
          buildingName={viewImageDialogProps.buildingName}
          markers={viewImageDialogProps.markers}
          regions={viewImageDialogProps.regions}
          regionOnClick={floorPlanRegionOnClick}
          onNavigation={onNavigation}
        />
      )}
    </Dialog>
  );
}
