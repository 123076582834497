// @ts-nocheck
import React from 'react';
// import HomeTile from './components/home-tile';
// import userIcon from './images/user-icon.png';
// import subscriptionIcon from './images/paper-icon.webp';
// import packageIcon from './images/package-icon.png';
// import announcementIcon from './images/megaphone-icon.webp';
import '../css/admin.css';

class AdminHome extends React.Component {
  constructor(props) {
    super(props);
    this.mainApp = props.mainApp;
  }

  setContent(name) {
    this.mainApp.setContent(name);
  }

  render() {
    return (
      <div className="home">
        <div className="home-content">
          {/* commented out the 4 big tiles on the admin portal as per lex. this might be needed later so just commented out for now */}
          {/* <div className="text-instructions">
            <div>Where would you like to go?</div>
          </div> */}
          {/* <div className="inline-container flex-grow">
            <HomeTile
              clickFunction={() => this.setContent('application-admins')}
              icon={userIcon}
              name="Application Administrators"
              description="Setup and manage administrator accounts with permission to access specific features."
            />
            <HomeTile
              clickFunction={() => this.setContent('subscriptions')}
              icon={subscriptionIcon}
              name="Client Subscriptions"
              description="Manage clients and their projects."
            />
          </div>
          <div className="inline-container flex-grow">
            <HomeTile
              clickFunction={() => this.setContent('packages')}
              icon={packageIcon}
              name="Subscription Packages"
              description="Manage the project and user packages available to clients."
            />
            <HomeTile
              clickFunction={() => this.setContent('announcements')}
              icon={announcementIcon}
              name="Announcements"
              description="Schedule and publish product announcements to clients and/or client administrators."
            />
          </div> */}
        </div>
      </div>
    );
  }
}

export default AdminHome;
