import { Dispatch } from 'react';
import {
  getAllSecurityGroupsBySubscriberId,
  getSecurityGroupsFromUserId,
} from '../../models/api/security-groups';
import { SET_SECURITY_LOADING } from '../loading/constants';
import { SET_SECURITY, SET_SUBSCRIBER_SECURITY } from './constants';
import { Security } from './security';
import { action } from 'typesafe-actions';

export const fetchSecurityGroups = (userId: string) => (dispatch: Dispatch<any>) => {
  dispatch({ type: SET_SECURITY_LOADING, payload: true });
  if (userId.length !== 0) {
    return getSecurityGroupsFromUserId(userId)
      .then((response) => dispatch({ type: SET_SECURITY, payload: response }))
      .catch()
      .finally(() => dispatch({ type: SET_SECURITY_LOADING, payload: false }));
  }
  dispatch({ type: SET_SECURITY_LOADING, payload: false });
  return dispatch({ type: SET_SECURITY, payload: [] });
};

export const fetchSubscriberSecurityGroups = (subscriberId: string) => (
  dispatch: Dispatch<any>,
) => {
  dispatch({ type: SET_SECURITY_LOADING, payload: true });
  if (subscriberId.length !== 0)
    return getAllSecurityGroupsBySubscriberId(subscriberId)
      .then((response) => dispatch({ type: SET_SUBSCRIBER_SECURITY, payload: response }))
      .catch()
      .finally(() => dispatch({ type: SET_SECURITY_LOADING, payload: false }));
  dispatch({ type: SET_SECURITY_LOADING, payload: false });
  return dispatch({ type: SET_SECURITY, payload: [] });
};

export const setSecurity = (security: Security) => {
  return action(SET_SECURITY, security);
};

export const setSubscriberSecurity = (security: Security) => {
  return action(SET_SUBSCRIBER_SECURITY, security);
};
