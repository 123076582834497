import React, { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FileTree from './FileTree';
import NavAppbar from '../nav-top/NavAppbar';
import { getProjectState } from '../../features/project/selectors';
import { patchProject } from '../../models/api/project';
import { fetchProject } from '../../features/project/actions';
import { IndexMatchParams, isPublicPage } from '../../scripts/utils';
import { DesignFileSharePublicUrlParams } from '../../scripts/url-utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 'auto',
      flexGrow: 1,
      maxWidth: 310,
      width: 210,
      alignSelf: 'flex-start',
      backgroundColor: 'white',
      maxHeight: '100%',
      overflowX: 'auto',
      overflowY: 'auto',
      '&>:nth-child(odd)': {
        backgroundColor: '#F9F9F9',
      },
    },
    cardRoot: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      maxWidth: 1680,
      minHeight: 730,
      maxHeight: 730,
      zIndex: 10,
    },
    centerIcon: {
      display: 'inline-flex',
      alignItems: 'center',
      flexBasis: 'auto',
      flexWrap: 'nowrap',
      height: '100%',
    },
    loadingIcon: {
      width: 32,
      height: 32,
    },
    titleStyle: {
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
      flexGrow: 1,
      padding: 16,
    },
    columnLayout: {
      display: 'flex',
      flexDirection: 'column',
    },
    rowButton: {
      cursor: 'pointer',
      display: 'flex',
      padding: '12px 16px 12px 16px',
      alignItems: 'center',
      borderBottom: '1px solid lightgray',
      justifyContent: 'space-between',
      boxShadow: 'none',
      transition: '0.2s',
      '&:hover': {
        boxShadow: '0px 3px 15px rgb(0, 0, 0, .04)',
      },
      '@global > div': {
        transition: '0.2s',
        transformOrigin: '0% 0%',
      },
      '&:hover > div': {
        transform: 'scale(1.05)',
      },
    },
    inlineCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    rowIcon: {
      marginRight: 16,
      height: 32,
      width: 32,
      color: '#7A797A',
    },
    content: {
      flexGrow: 1,
      minHeight: '100vh',
    },
  }),
);

interface FilesystemPageProps {
  BIM?: boolean;
}

export default function FilesystemPage(props: FilesystemPageProps) {
  const { BIM } = props;
  const classes = useStyles();
  const params = useParams<IndexMatchParams>();
  const publicParams = useParams<DesignFileSharePublicUrlParams>();
  const location = useLocation();
  const dispatch = useDispatch();
  const project = useSelector(getProjectState);

  const [selectedTab, setSelectedTab] = useState<'Project Files' | 'BIM360'>(
    BIM ? 'BIM360' : 'Project Files',
  );

  if (isPublicPage()) {
    const url = new URL(window.location.href.replace('&#x3D;', '='));
    publicParams.publicAccessKey = url.searchParams.get('publicAccessKey')!;
  }

  const logoutRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (isPublicPage()) {
      setSelectedTab('Project Files');
    }
  }, [publicParams]);

  const getFilesystem = () => {
    if (selectedTab) {
      if (selectedTab === 'Project Files') {
        return (
          <FileTree
            project={project}
            type="Project Files"
            publicParams={publicParams || undefined}
            handleBIMLogin={loginToBIM}
          />
        );
      }
      if (selectedTab === 'BIM360') {
        return (
          <FileTree
            project={project}
            disableOptions
            disableUploadFile
            type="BIM360"
            backButtonPressed={() => setSelectedTab('Project Files')}
            handleSignOutOfBIM={signOutOfBIM}
          />
        );
      }
    }
    return <div />;
  };

  useEffect(() => {
    if (!isPublicPage()) {
      if (!project) dispatch(fetchProject(params.projectId));
      const path = new URLSearchParams(location.search.substring(1)).get('fullKey');
      if (path) setSelectedTab('Project Files');
    }
  }, [location.key]);

  useEffect(() => {
    if (BIM) {
      setSelectedTab('BIM360');
    }
  }, [BIM]);

  const loginToBIM = () => {
    //User has already signed in to BIM
    if (project!.bimOwnerUserId) {
      setSelectedTab('BIM360');
    }
    //User needs to login to BIM and be redirected
    else {
      const callbackUrl = encodeURIComponent(process.env.REACT_APP_BIM_360_CALLBACK_URL!);
      const bimClientId = process.env.REACT_APP_BIM_360_CLIENT_ID;
      const urlToLogin = `https://developer.api.autodesk.com/authentication/v1/authorize?response_type=code&client_id=${bimClientId}&redirect_uri=${callbackUrl}&scope=data:read%20data:write`;
      localStorage.setItem('projectIdCallback', project!.id);
      window.location.href = urlToLogin;
    }
  };

  const signOutOfBIM = () => {
    logoutRef!.current!.src = 'https://accounts.autodesk.com/Authentication/LogOut';
    patchProject(project!.id, { bimOwnerUserId: null }).then(() => {
      dispatch(fetchProject(project!.id));
    });
  };

  return (
    <div className={classes.content}>
      <NavAppbar />
      {getFilesystem()}
      <iframe
        style={{ visibility: 'hidden', height: 0, width: 0, position: 'fixed' }}
        ref={logoutRef}
      />
    </div>
  );
}
