import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './root-reducer';

const store = createStore(
  rootReducer(),
  {
    user: {},
    loading: {},
    project: {},
    document: {},
    documents: {},
    projects: {},
    security: {},
    bid: {},
    snackbar: {},
    navigation: {},
    companies: {},
    groups: {},
    productPackage: {},
  },
  applyMiddleware(thunk),
);

export default store;
