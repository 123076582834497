import {
  Checkbox,
  createStyles,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Group, Person, Publish, Assignment } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { useEffect, useRef, useState } from 'react';
import { BimTreeNode, FileNode, FileNodeTypeEnum } from '../../api-client/autogenerated';
import {
  areFilesInSameParentFolder,
  formatDate,
  formatFileSize,
  isPublicPage,
  openFilesystemFileInNewTab,
  openPublicFilesystemFileInNewTab,
  PublicDesignMatchParams,
} from '../../scripts/utils';
import FileOptionsDialog from './FileOptionsDialog';
import EyeIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import FileLogDialog from './FileLogDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import EmailUsersDialog from './EmailUsersDialog';
import { useParams } from 'react-router';

interface FileTableRowProps {
  type: 'Project Files' | 'BIM360';
  disableOptions: boolean;
  name: string;
  date: string;
  size: number;
  checked: boolean;
  file?: FileNode;
  bimFile?: BimTreeNode;
  projectId: string | undefined;
  downloadFileFromBIM: (file: BimTreeNode) => Promise<void>;
  downloadFile: (name: string, fullKey: string, ownerId: string) => Promise<void>;
  updateFileName: (name: string, file: FileNode) => void;
  deleteFile: (file: FileNode) => void;
  importFromBIM?: boolean;
  importBIMFile: (file: BimTreeNode) => void;
  updateFileInActiveFolder?: (f: FileNode) => void;
  updateFolderName: (name: string, file: FileNode) => void;
  updateFileNode: (node: FileNode) => void;
  handleSelectRow: (fileNode: FileNode) => void;
  setTable: (a: any) => void;
  setActiveFolder: (fileNode: FileNode | undefined) => void;
  findParentFolder: (fullKey: string) => FileNode | undefined;
  manageParentFolder: (fullKey: string) => void;
  selectedNodes: FileNode[];
  allowOnlyPdfs?: boolean;
  isRecycleBin?: boolean;
}

const useStyles = makeStyles(
  createStyles({
    root: {
      height: 'auto',
      flexGrow: 1,
      maxWidth: 310,
      width: 310,
      alignSelf: 'flex-start',
      backgroundColor: 'white',
      maxHeight: '100%',
      overflowX: 'auto',
      overflowY: 'auto',
    },
    boxRoot: {
      backgroundColor: 'white',
    },
    rowLayout: {
      width: 'auto',
      display: 'flex',
      flexDirection: 'row',
      flexBasis: 'auto',
      flexGrow: 1,
    },
    table: {
      flexGrow: 1,
    },
    centerIcon: {
      display: 'inline-flex',
      alignItems: 'center',
      flexBasis: 'auto',
      flexWrap: 'nowrap',
      height: '100%',
    },
    downloadIcon: {
      color: 'rgba(0, 0, 0, 0.54);',
      cursor: 'pointer',
    },
    inlineCenter: {
      display: 'flex',
      flexBasis: 'auto',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      height: 48,
    },
    loadingIcon: {
      width: 32,
      height: 32,
    },
    sizeCell: {
      minWidth: 90,
    },
    smallSizeCell: {
      minWidth: 60,
    },
    row: {
      transition: '0.12s',
      height: 48,
    },
    tableBody: {
      '&>:nth-child(odd)': {
        backgroundColor: '#F9F9F9',
        '&:hover': {
          backgroundColor: '#E6EEFF',
        },
      },
      '&>:nth-child(even)': {
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: '#E6EEFF',
        },
      },
    },
    titleStyle: {
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
      flexGrow: 1,
      padding: 16,
    },
    addButton: {
      width: 108,
      height: 79,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      background: '#0947B9',
      transition: '0.2s',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        background: '#4380ED',
      },
    },
    tableCell: {
      padding: '0px 16px 0px 16px',
    },
  }),
);

export default function FileTableRow(props: FileTableRowProps) {
  const {
    type,
    disableOptions,
    name,
    date,
    size,
    checked,
    file,
    bimFile,
    projectId,
    downloadFileFromBIM,
    downloadFile,
    updateFileName,
    deleteFile,
    importFromBIM,
    importBIMFile,
    updateFileInActiveFolder = () => {},
    updateFileNode,
    handleSelectRow,
    setTable,
    setActiveFolder,
    findParentFolder,
    manageParentFolder,
    updateFolderName,
    selectedNodes,
    allowOnlyPdfs,
    isRecycleBin,
  } = props;

  const classes = useStyles();
  const publicParams = useParams<PublicDesignMatchParams>();

  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLElement | null>(null);

  const rightClickAnchor = useRef<HTMLDivElement | null>(null);
  const [optionsAnchor, setOptionsAnchor] = useState<null | HTMLElement>(null);

  const [notifyUsersDialogOpen, setNotifyUsersDialogOpen] = useState(false);

  const canNotify = areFilesInSameParentFolder(selectedNodes);

  useEffect(() => {
    document.addEventListener('contextmenu', () => setOptionsAnchor(null));
  }, []);

  const [localFileNode, setLocalFileNode] = useState<FileNode | undefined>(file);
  const isFolder = localFileNode?.type === FileNodeTypeEnum.Folder;

  //This prop is passed down to FileOptionsDialog so it can determine when the share button has been pressed
  const [shareButtonTrigger, setShareButtonTrigger] = useState<boolean | undefined>();

  const [logDialogOpen, setLogDialogOpen] = useState<boolean>(false);
  const showView = type !== 'BIM360' && localFileNode?.file?.extension === 'pdf';

  useEffect(() => {
    setLocalFileNode(file);
  }, [file]);

  const handleOpenOptions = (
    event: React.MouseEvent<HTMLButtonElement>,
    file: FileNode | undefined,
  ) => {
    event.stopPropagation();
    setOptionsAnchor(event.currentTarget);
  };

  const handleDelete = () => {
    deleteFile(localFileNode!);
  };

  const handleFinishEdit = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEditing(false);
    if (localFileNode?.type === FileNodeTypeEnum.File)
      updateFileName(e.target.value, localFileNode!);
    else updateFolderName(e.target.value, localFileNode!);
  };

  const keypressHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      inputRef!.current!.blur();
    }
  };

  const handleView = () => {
    if (isPublicPage())
      openPublicFilesystemFileInNewTab(
        projectId!,
        localFileNode!.file!.fullName!,
        localFileNode!.fullKey,
        publicParams.designFileShareId,
        publicParams.publicAccessKey,
      );
    else
      openFilesystemFileInNewTab(
        projectId!,
        localFileNode!.file!.fullName!,
        localFileNode!.fullKey,
        localFileNode!.ownerId,
        isRecycleBin,
      );
  };

  const handleDownload = async () =>
    type === 'BIM360'
      ? await downloadFileFromBIM(bimFile!)
      : await downloadFile(
          localFileNode!.file!.fullName!,
          localFileNode!.fullKey,
          localFileNode!.ownerId,
        );

  const handleFolderChange = () => {
    if (localFileNode?.type === FileNodeTypeEnum.Folder) {
      setTable(localFileNode.children);
      setActiveFolder(localFileNode);
    }
  };

  const getActionIcon = () => {
    if (!importFromBIM) {
      if (!isFolder) {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-evenly', whiteSpace: 'nowrap' }}>
            {showView ? (
              <Tooltip arrow placement="top" title="View">
                <IconButton
                  className={classes.downloadIcon}
                  onClick={handleView}
                  style={{ padding: 0 }}
                >
                  <EyeIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <div style={{ width: '24px' }} />
            )}
            <Tooltip arrow placement="top" title="Download">
              <IconButton
                className={classes.downloadIcon}
                onClick={handleDownload}
                style={{ padding: 0 }}
              >
                <GetAppIcon />
              </IconButton>
            </Tooltip>
            {!isPublicPage() && !isRecycleBin ? (
              <Tooltip arrow placement="top" title="Notify">
                <IconButton
                  onClick={() => setNotifyUsersDialogOpen(true)}
                  className={classes.downloadIcon}
                  style={{ padding: 0 }}
                >
                  <FontAwesomeIcon icon={faShareSquare} size="xs" />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        );
      }
      return (
        <Button color="primary" onClick={handleFolderChange}>
          Open Folder
        </Button>
      );
    }

    return (
      <Tooltip arrow placement="top" title="Import">
        <IconButton
          className={classes.downloadIcon}
          style={{ marginLeft: 8, marginRight: 8 }}
          size="small"
          onClick={() => {
            importBIMFile(bimFile!);
          }}
        >
          <Publish />
        </IconButton>
      </Tooltip>
    );
  };

  const updateFileLocally = (f: FileNode) => {
    setLocalFileNode(f);
    updateFileInActiveFolder!(f);
  };

  const shareButtonPressed = () => {
    setShareButtonTrigger(!shareButtonTrigger);
  };

  const handleContextMenu = (event: React.MouseEvent<HTMLTableRowElement>) => {
    event.preventDefault();
    event.stopPropagation();
    rightClickAnchor.current!.style.top = `${event.clientY + 24}px`;
    rightClickAnchor.current!.style.left = `${event.clientX}px`;
    setOptionsAnchor(rightClickAnchor.current);
  };

  useEffect(() => {
    if (editing) {
      inputRef!.current!.focus();
    }
  }, [editing]);

  return (
    <TableRow
      key={name}
      onContextMenu={handleContextMenu}
      onDoubleClick={handleFolderChange}
      className={classes.row}
    >
      <TableCell align="center" padding="none">
        {(allowOnlyPdfs !== true || (allowOnlyPdfs && showView)) && (
          <Checkbox
            checked={checked}
            onChange={() => handleSelectRow(localFileNode!)}
            style={{ padding: '0px 0px 0px 8px' }}
          />
        )}
      </TableCell>

      <TableCell align="center" padding="none">
        {getActionIcon()}
      </TableCell>
      {isRecycleBin ? (
        <TableCell
          classes={{ root: classes.tableCell }}
          style={{
            whiteSpace: 'nowrap',
            maxWidth: '320px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          component="th"
          scope="row"
        >
          {`/${file?.fullKey.split('/').slice(3, -1).join('/')}/`}
        </TableCell>
      ) : null}
      <TableCell
        classes={{ root: classes.tableCell }}
        style={{
          whiteSpace: 'nowrap',
          maxWidth: '320px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        component="th"
        scope="row"
        colSpan={isFolder ? 3 : 1}
      >
        <div
          style={{ position: 'fixed', top: 0, left: 0, width: 1, height: 1 }}
          ref={rightClickAnchor}
        />
        {editing ? (
          <div style={{ width: '100%' }}>
            <TextField
              inputRef={inputRef}
              defaultValue={name.includes('.') ? name.substr(0, name.lastIndexOf('.')) : name}
              onBlur={(e) => handleFinishEdit(e)}
              onKeyPress={(event) => keypressHandler(event)}
              fullWidth
              style={{ maxWidth: 280 }}
            />
          </div>
        ) : (
          name
        )}
      </TableCell>
      {!isFolder && (
        <>
          <TableCell style={{ maxWidth: 160 }} align="left">
            {formatDate(date)}
          </TableCell>
          <TableCell
            align="left"
            classes={{ root: classes.tableCell }}
            className={classes.sizeCell}
          >
            {formatFileSize(size)}
          </TableCell>
        </>
      )}
      {!isPublicPage() && !isRecycleBin ? (
        <TableCell
          align="center"
          classes={{ root: classes.tableCell }}
          className={classes.smallSizeCell}
        >
          {localFileNode?.fileNodePermissions && localFileNode?.fileNodePermissions.length === 0 ? (
            <IconButton onClick={shareButtonPressed}>
              <Person className={classes.downloadIcon} />
            </IconButton>
          ) : (
            <IconButton onClick={shareButtonPressed}>
              <Group className={classes.downloadIcon} />
            </IconButton>
          )}
        </TableCell>
      ) : null}

      <TableCell align="center" classes={{ root: classes.tableCell }}>
        <IconButton onClick={() => setLogDialogOpen(true)}>
          <Assignment className={classes.downloadIcon} />
        </IconButton>
      </TableCell>

      <TableCell align="center" classes={{ root: classes.tableCell }}>
        {disableOptions ? null : (
          <IconButton
            className={classes.downloadIcon}
            size="small"
            onClick={(event) => handleOpenOptions(event, localFileNode)}
          >
            <MoreHorizIcon />
          </IconButton>
        )}
      </TableCell>
      <FileOptionsDialog
        dialogOpen={optionsAnchor !== null}
        anchor={optionsAnchor}
        handleClose={() => setOptionsAnchor(null)}
        renameClicked={() => setEditing(true)}
        deleteClicked={() => handleDelete()}
        file={type !== 'BIM360' ? localFileNode : undefined}
        setFile={updateFileLocally}
        updateFileNode={updateFileNode}
        disableAddFile
        disableAddFolder
        shareButtonTrigger={shareButtonTrigger}
        downloadFile={!isFolder ? handleDownload : undefined}
        viewFile={!isFolder && localFileNode?.fullKey.endsWith('.pdf') ? handleView : undefined}
        findParentFolder={findParentFolder}
        manageParentFolder={manageParentFolder}
        selectedNodes={selectedNodes}
        isRecycleBin={isRecycleBin}
      />
      {localFileNode && (
        <EmailUsersDialog
          dialogOpen={notifyUsersDialogOpen}
          files={selectedNodes.length > 0 ? selectedNodes : [localFileNode]}
          closeDialog={() => setNotifyUsersDialogOpen(false)}
          isDesignNotify
        />
      )}
      {localFileNode && (
        <FileLogDialog
          open={logDialogOpen}
          handleClose={() => setLogDialogOpen(false)}
          file={localFileNode}
          isRecycleBin={isRecycleBin}
        />
      )}
    </TableRow>
  );
}
