import React, { useEffect, useState } from 'react';
import '../../css/Step.css';
import '../../css/FilePond.css';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FilledInput,
  Grid,
  LinearProgress,
  Select,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Add, HighlightOffRounded, Info } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Dropzone from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import Notification from '../notifications/Notification';
import { formatDateLong } from '../../scripts/utils';
import { FieldWidth, gray300, gray400, gray50, gray700 } from '../BiddingPortalTheme';
import { TextMaskCustom } from '../../main-components/custom-components/TextMaskCustom';
import { getCompanyById } from '../../models/api/companies';
import { insertBid } from '../../models/api/bid';
import { getUserState } from '../../features/user/selectors';
import {
  IBid,
  IBidSetup,
  IInsertionPrimeBidder,
  PhoneNumberType,
} from '../../api-client/autogenerated';
import { insertAddress } from '../../models/api/addresses';
import { insertPhoneNumberByUserId } from '../../models/api/phone-number';
import { getStateAndProvinceItems } from '../utils';
import PDFIcon from '../../main-components/icons/PDF-icon';
import { uploadToStaging } from '../../models/api/filesystem';
import CircularLoader from '../../main-components/loader/CircularLoader';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { GreenCheck } from '../../main-components/custom-components/CustomCheckboxes';
import RedAsterick from '../../main-components/custom-components/RedAsterick';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';

type StepProps = {
  children?: JSX.Element | string | (JSX.Element | string)[];
  number: string;
  title: string;
};

const SubmitBidStep: React.FC<StepProps> = (props) => {
  const { children = undefined, number, title } = props;
  return (
    <div className="step">
      <div className="number t-primary-bgcolor" style={{ marginTop: 13 }}>
        {number}
      </div>
      <div>
        <h2>{title}</h2>
        <div>{children}</div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  dragDropText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '40px',
    textAlign: 'center',
    textTransform: 'none',
    color: '#949494', // Gray 400
  },
  browseFileButton: {
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: '#0947B9',
  },
  dropzoneStyling: {
    width: '25vw',
    flexShrink: 0,
    background: '#F9F9F9',
    mixBlendMode: 'normal',
    border: '2px dashed #949494',
    boxSizing: 'border-box',
    borderRadius: '4px',
    paddingBottom: 48,
  },
  file: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  fileOuter: {
    display: 'inline-flex',
    marginTop: '16px',
    marginRight: '20px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '13px',
    textAlign: 'center',
    textTransform: 'none',
    color: '#949494', // Gray 400
    paddingLeft: 8,
  },
  columnLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  rowLayout: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  titleStyle: {
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
    fontSize: 26,
  },
  exitIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  filledInputRoot: {
    height: '34px',
    width: '216px',
    border: 'none',
    '& .Mui-disabled': {
      background: gray400,
    },
  },
  filledInputInput: {
    background: gray50,
    padding: '8px 0px 8px 8px',
    border: `1px solid ${gray400}`,
    borderRadius: '5px',
    textAlign: 'start',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '18px',
    color: gray700,
    '&::placeholder': {
      fontStyle: 'italic',
      color: gray300,
      opacity: 1,
    },
  },
  filledInputMultiline: {
    height: 'auto',
    width: 'auto',
    padding: 0,
  },
  textFieldLabel: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '32px',
    color: gray400,
    textTransform: 'uppercase',
  },
}));

interface Props {
  bidSetup?: IBidSetup;
  bids: IBid[];
  fetchBids: (projectId: string) => Promise<void>;
  open: boolean;
  handleClose: () => void;
}

const SubmitBid: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { bidSetup, bids, fetchBids, open, handleClose } = props;
  const user = useSelector(getUserState);

  const [snackOpen, setSnackOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Uploading');

  const [inputCompanyName, setInputCompanyName] = useState('');
  const [inputContactName, setInputContactName] = useState('');
  const [inputState, setInputState] = useState('');
  const [inputCity, setInputCity] = useState('');
  const [inputAddress1, setInputAddress1] = useState('');
  const [inputAddress2, setInputAddress2] = useState('');
  const [inputPostal, setInputPostal] = useState('');
  const [inputPhone, setInputPhone] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputLicense, setInputLicense] = useState('');
  const [inputFiles, setInputFiles] = useState<File[]>([]);
  const [inputCertified, setInputCertified] = useState(false);
  const [dueDate, setDueDate] = useState('');

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const formIsValid = () =>
    !!inputCompanyName &&
    !!inputLicense &&
    !!inputAddress1 &&
    !!inputCity &&
    !!inputState &&
    !!inputPostal &&
    !!inputContactName &&
    !!inputEmail &&
    inputCertified;

  const handleSubmit = async () => {
    if (formIsValid()) {
      setIsLoading(true);
      setLoadingMessage('Submitting bid...');
      try {
        const { id: addressId } = await insertAddress({
          lastUpdatedByUserId: user.id,
          stateOrProvinceId: inputState,
          address1: inputAddress1,
          address2: inputAddress2,
          city: inputCity,
          postalCode: inputPostal,
        });
        const { id: phoneNumberId } = await insertPhoneNumberByUserId(user.id, {
          lastUpdatedByUserId: user.id,
          number: inputPhone.replace(/[-()\s]/g, ''),
          type: PhoneNumberType.Mobile,
          countryId: '',
        });
        const primeBidder: IInsertionPrimeBidder = {
          lastUpdatedByUserId: user.id,
          companyName: inputCompanyName,
          contactName: inputContactName,
          email: inputEmail,
          addressId,
          phoneNumberId,
          contractorLicenseNo: inputLicense,
        };
        const uploadedS3Keys: string[] = [];
        for (let i = 0; i < inputFiles.length; i += 1) {
          const file = inputFiles[i];
          setLoadingMessage(`Uploading File ${i + 1}/${inputFiles.length} - ${file.name}`);
          const stagingUploadResponse = await uploadToStaging(
            { fileName: file.name, useMultiPartUpload: file.size > MULTI_PART_FILE_SIZE },
            file,
            handleUploadProgress,
          );
          uploadedS3Keys.push(stagingUploadResponse.s3Key);
        }

        const bid = { userId: user.id, projectId: bidSetup!.project!.id };
        await insertBid(user.id, bidSetup!.project!.id, {
          bid,
          primeBidder,
          bidStagingS3Keys: uploadedS3Keys,
        });

        await fetchBids(bidSetup!.project!.id);
        setSnackOpen(true);
      } catch (e: any) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleRemove = (index: number) => {
    const temp = [...inputFiles];
    temp.splice(index, 1);
    setInputFiles(temp);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const initialize = async () => {
    setInputContactName(user.name);
    setInputPhone(user.phoneNumbers && user.phoneNumbers[0] ? user.phoneNumbers[0].number : '');
    setInputEmail(user.email);
    setInputLicense(user.contractorLicenseNumber || '');

    if (user.companyId) {
      const company = await getCompanyById(user.companyId);

      setInputCompanyName(company.name || '');
      setInputAddress1(
        company.addresses && company.addresses[0] ? company.addresses[0].address1 : '',
      );
      setInputAddress2(
        company.addresses && company.addresses[0] && company.addresses[0].address2
          ? company.addresses[0].address2
          : '',
      );
      setInputCity(company.addresses && company.addresses[0] ? company.addresses[0].city : '');
      setInputState(
        company.addresses && company.addresses[0]?.stateOrProvince
          ? company.addresses[0].stateOrProvince.name
          : '',
      );
      setInputPostal(
        company.addresses && company.addresses[0] ? company.addresses[0].postalCode : '',
      );
    } else if (user.publicCompanyName) {
      setInputCompanyName(user.publicCompanyName);
    }
  };

  useEffect(() => {
    if (bidSetup) {
      setDueDate(formatDateLong(bidSetup!.dueDate));
    }
  }, [bidSetup]);

  useEffect(() => {
    initialize();
  }, [user]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} style={{ width: '100%' }} maxWidth={false}>
        <DialogTitle className={classes.titleStyle}>
          <h2
            className="h2"
            style={{
              textAlign: 'left',
              color: '#FFFFFF',
              margin: 0,
              lineHeight: 1,
              fontSize: 26,
            }}
          >
            Submit Bid
          </h2>
          <IconButton
            style={{ right: '12px', top: '12px', position: 'absolute' }}
            onClick={handleClose}
            classes={{
              root: classes.exitIconButton,
            }}
          >
            <HighlightOffRounded htmlColor="#FFF" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Notification icon={<Info />} message={`The submission deadline is ${dueDate}`} />
          {bids.filter((b) => !b.isRetracted).length > 0 ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: 50,
              }}
            >
              <Typography variant="h2">Bid submitted!</Typography>
            </div>
          ) : (
            <div>
              <SubmitBidStep title="Your Info" number="1" />
              <div className="px-4">
                <div className="form-group row required field-company">
                  <label htmlFor="company" className={classes.textFieldLabel}>
                    Company name <RedAsterick />
                  </label>
                  <div className="col-9">
                    <TextField
                      id="company"
                      name="company"
                      variant="filled"
                      value={inputCompanyName}
                      onChange={(event) => setInputCompanyName(event.target.value)}
                      InputProps={{
                        style: { width: FieldWidth.Long },
                        classes: {
                          root: classes.filledInputRoot,
                          multiline: classes.filledInputMultiline,
                          input: classes.filledInputInput,
                        },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row required field-contact">
                  <label htmlFor="contact" className={classes.textFieldLabel}>
                    Contact name <RedAsterick />
                  </label>
                  <div className="col-9">
                    <TextField
                      id="contact"
                      name="contact"
                      variant="filled"
                      value={inputContactName}
                      onChange={(event) => setInputContactName(event.target.value)}
                      InputProps={{
                        style: { width: FieldWidth.Long },
                        classes: {
                          root: classes.filledInputRoot,
                          multiline: classes.filledInputMultiline,
                          input: classes.filledInputInput,
                        },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row required field-address_1">
                  <label htmlFor="address_1" className={classes.textFieldLabel}>
                    Address 1 <RedAsterick />
                  </label>
                  <div className="col-9">
                    <TextField
                      id="address_1"
                      name="address_1"
                      variant="filled"
                      value={inputAddress1}
                      onChange={(event) => setInputAddress1(event.target.value)}
                      InputProps={{
                        style: { width: FieldWidth.Long },
                        classes: {
                          root: classes.filledInputRoot,
                          multiline: classes.filledInputMultiline,
                          input: classes.filledInputInput,
                        },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row field-address_2">
                  <label htmlFor="address_2" className={classes.textFieldLabel}>
                    Address 2
                  </label>
                  <div className="col-9">
                    <TextField
                      id="address_2"
                      name="address_2"
                      variant="filled"
                      value={inputAddress2}
                      onChange={(event) => setInputAddress2(event.target.value)}
                      InputProps={{
                        style: { width: FieldWidth.Long },
                        classes: {
                          root: classes.filledInputRoot,
                          multiline: classes.filledInputMultiline,
                          input: classes.filledInputInput,
                        },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row required field-city">
                  <label htmlFor="city" className={classes.textFieldLabel}>
                    City <RedAsterick />
                  </label>
                  <div className="col-9">
                    <TextField
                      id="city"
                      name="city"
                      variant="filled"
                      value={inputCity}
                      onChange={(event) => setInputCity(event.target.value)}
                      InputProps={{
                        style: { width: FieldWidth.Long },
                        classes: {
                          root: classes.filledInputRoot,
                          multiline: classes.filledInputMultiline,
                          input: classes.filledInputInput,
                        },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row required field-state">
                  <label htmlFor="city" className={classes.textFieldLabel}>
                    State <RedAsterick />
                  </label>
                  <div className="col-9">
                    <Select
                      id="state"
                      name="state"
                      variant="filled"
                      value={inputState}
                      onChange={(event) => setInputState(event.target.value as string)}
                      input={<FilledInput />}
                      inputProps={{
                        style: { width: FieldWidth.Short },
                        className: classes.filledInputInput,
                        disableUnderline: true,
                      }}
                      disableUnderline
                      style={{ width: FieldWidth.Short }}
                    >
                      {getStateAndProvinceItems()}
                    </Select>
                  </div>
                </div>
                <div className="form-group row required field-zip">
                  <label htmlFor="zip" className={classes.textFieldLabel}>
                    Zip code <RedAsterick />
                  </label>
                  <div className="col-9">
                    <TextField
                      id="zip"
                      name="zip"
                      variant="filled"
                      value={inputPostal}
                      onChange={(event) => setInputPostal(event.target.value)}
                      InputProps={{
                        style: { width: FieldWidth.Long },
                        classes: {
                          root: classes.filledInputRoot,
                          multiline: classes.filledInputMultiline,
                          input: classes.filledInputInput,
                        },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row required field-phone">
                  <label htmlFor="phone" className={classes.textFieldLabel}>
                    Phone <RedAsterick />
                  </label>
                  <div className="col-9">
                    <FilledInput
                      id="phone"
                      name="phone"
                      inputComponent={TextMaskCustom as any}
                      value={inputPhone}
                      onChange={(event) => setInputPhone(event.target.value)}
                      classes={{
                        root: classes.filledInputRoot,
                        multiline: classes.filledInputMultiline,
                        input: classes.filledInputInput,
                      }}
                      disableUnderline
                      style={{ width: FieldWidth.Long }}
                    />
                  </div>
                </div>
                <div className="form-group row required field-email">
                  <label htmlFor="email" className={classes.textFieldLabel}>
                    Email <RedAsterick />
                  </label>
                  <div className="col-9">
                    <TextField
                      id="email"
                      name="email"
                      value={inputEmail}
                      onChange={(event) => setInputEmail(event.target.value)}
                      InputProps={{
                        style: { width: FieldWidth.Long },
                        classes: {
                          root: classes.filledInputRoot,
                          multiline: classes.filledInputMultiline,
                          input: classes.filledInputInput,
                        },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row required field-identification">
                  <label htmlFor="identification" className={classes.textFieldLabel}>
                    License # <RedAsterick />
                  </label>
                  <div className="col-9">
                    <TextField
                      id="identification"
                      name="identification"
                      value={inputLicense}
                      onChange={(event) => setInputLicense(event.target.value)}
                      InputProps={{
                        style: { width: FieldWidth.Long },
                        classes: {
                          root: classes.filledInputRoot,
                          multiline: classes.filledInputMultiline,
                          input: classes.filledInputInput,
                        },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </div>
              </div>
              <SubmitBidStep title="PDF attachments" number="2">
                All bid forms, bid bonds, and any additional forms required for this bid should be
                attached.
                <br />
                Max filesize: 100MB
              </SubmitBidStep>
              <div className={classes.columnLayout}>
                <div className={classes.rowLayout} style={{ justifyContent: 'space-between' }}>
                  <div className={classes.dropzoneStyling}>
                    <Dropzone
                      accept={['.pdf', '.zip']}
                      onDropAccepted={(files) => {
                        setInputFiles([...files, ...inputFiles]);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          style={{ outline: 'none' }}
                          {...getRootProps({ className: 'dropzone' })}
                        >
                          <input {...getInputProps()} />
                          <p className={classes.dragDropText} style={{ paddingTop: 20 }}>
                            Drag &amp; Drop files here
                          </p>
                          <p className={classes.dragDropText}>or</p>
                          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            <Button variant="outlined" className={classes.browseFileButton}>
                              <Add />
                              Browse Files
                            </Button>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
                {inputFiles.length > 0 ? (
                  <Grid container spacing={1} style={{ maxWidth: 700 }}>
                    {inputFiles.map((file, i) => {
                      return (
                        <Grid key={`file${i}`} item style={{ marginLeft: 8 }}>
                          <div className={classes.fileOuter}>
                            <div className={classes.file}>
                              <PDFIcon />
                              <Typography className={classes.fileText}>{file.name}</Typography>
                            </div>
                            <IconButton
                              classes={{
                                root: classes.rootIconButton,
                              }}
                              onClick={() => handleRemove(i)}
                            >
                              <HighlightOffRounded />
                            </IconButton>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : null}
              </div>
              <SubmitBidStep title="Review & Verify" number="3">
                Please carefully review all your information and file attachments.
              </SubmitBidStep>
              <div
                data-balloon="Please check"
                data-balloon-pos="right"
                className="form-group field-verify pl-4"
              >
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="my-2">
                  <GreenCheck
                    id="verify_accurate"
                    name="verify_accurate"
                    checked={inputCertified}
                    onChange={(event, checked) => setInputCertified(checked)}
                    style={{ marginBottom: 2 }}
                  />
                  I certify that the information provided is complete and accurate <RedAsterick />
                </label>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {isLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                      marginTop: 12,
                    }}
                  >
                    {loadingMessage.toLowerCase().includes('uploading') ? (
                      <LinearProgress
                        variant="determinate"
                        value={uploadProgress}
                        style={{ height: 6, width: '60%' }}
                      />
                    ) : (
                      <CircularLoader />
                    )}
                    <Typography align="center" style={{ marginTop: 4 }}>
                      {loadingMessage}
                    </Typography>
                  </div>
                ) : (
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    style={{ marginBottom: 12, marginTop: 12 }}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackOpen}
        autoHideDuration={5000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackOpen(false)} severity="success">
          Successfully submitted your bid!
        </Alert>
      </Snackbar>
    </>
  );
};

export default SubmitBid;
