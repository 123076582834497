import React from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { getDocumentState } from '../../features/document/selectors';
import { getDocumentsType } from '../../features/documents/selectors';
import { DocumentTemplateType, NotifiedUserType } from '../../api-client/autogenerated';
import { documentTypeToUrl, isPublicPage, setPublicPageRedirect } from '../../scripts/utils';
import { useHistory } from 'react-router';
import { setDocument } from '../../features/document/actions';
import OptionsButtons from './OptionsButtons';

interface TitleProps {
  title: string;
  subtitle?: string;
  hasOptions?: boolean;
  isDrawings?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: '16px 16px 16px 0px',
    },
    title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '34px',
      lineHeight: '40px',
      letterSpacing: '-0.6px',
      color: '#0947B9', // Blue / Blue 700 Brand Dark
    },
    subtitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19px',
      color: '#000', // Blue / Blue 700 Brand Dark
    },
  }),
);

export default function PageTitle(props: TitleProps) {
  const classes = useStyles();
  const { title, subtitle, hasOptions = false, isDrawings = false } = props;
  const dispatch = useDispatch();
  const docType = useSelector(getDocumentsType);
  const document = useSelector(getDocumentState);
  const history = useHistory();
  const userType = new URLSearchParams(window.location.search).get('userType') as
    | NotifiedUserType
    | undefined;
  const projectBidSetupId = document?.project?.bidSetupId;
  const useBiddingLogin = userType === NotifiedUserType.PlanHolder && projectBidSetupId;

  const handlePublicPageLogin = () => {
    if (useBiddingLogin) {
      setPublicPageRedirect(`${projectBidSetupId}`);
      dispatch(setDocument(null));
      history.push('/bidding/login');
    } else {
      setPublicPageRedirect(
        `projects/${document!.projectId!}/documents/${documentTypeToUrl[docType!]}/${document!
          .id!}${docType === DocumentTemplateType.PunchList ? '/edit' : ''}`,
      );
      dispatch(setDocument(null));
      history.push('/main/login');
    }
  };

  return (
    <div className={classes.root} style={isDrawings ? { flexGrow: 0 } : {}}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={classes.title}>{title}</Typography>
      </div>
      {!!subtitle ? (
        <Typography gutterBottom className={classes.subtitle}>
          {subtitle}
        </Typography>
      ) : null}
      {hasOptions && !isPublicPage() && (
        <div style={{}}>
          <OptionsButtons />
        </div>
      )}
      {isPublicPage() && (
        <Button
          style={{ marginBottom: 8 }}
          variant="contained"
          color="primary"
          onClick={handlePublicPageLogin}
        >
          {useBiddingLogin ? 'Login to Centerline BidConnect' : 'Login'}
        </Button>
      )}
      {/*{isPublicPage() && (*/}
      {/*  <Grid item style={{ flexGrow: 1 }}>*/}
      {/*    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>*/}
      {/*      <Typography variant="body1">*/}
      {/*        This is a public page for viewing this document.*/}
      {/*      </Typography>*/}
      {/*      <Typography variant="body1">*/}
      {/*        Click{' '}*/}
      {/*        <Button*/}
      {/*          color="primary"*/}
      {/*          onClick={() => handlePublicPageLogin()}*/}
      {/*          style={{*/}
      {/*            margin: 0,*/}
      {/*            padding: 0,*/}
      {/*            marginLeft: 0,*/}
      {/*            minWidth: 34,*/}
      {/*            textTransform: 'none',*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          LOGIN*/}
      {/*        </Button>{' '}*/}
      {/*        to interact with this document.*/}
      {/*      </Typography>*/}
      {/*    </div>*/}
      {/*  </Grid>*/}
      {/*)}*/}
    </div>
  );
}
