import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import MainPortal from './main-components/MainPortal';
import BiddingPortal from './bidding-components/BiddingPortal';
import RootPage from './main-components/RootPage';
import AdminPortal from './admin-components/AdminPortal';
import { useKonami } from 'react-konami-code';
import { useDispatch } from 'react-redux';
import { addSnackbar, removeAllSnackbars } from './features/snackbar/actions';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import dayOfYear from 'dayjs/plugin/dayOfYear';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(dayOfYear);

const playBob = async () => {
  const audio = new Audio('/bob.mp3');
  audio.volume = 0.1;
  return audio.play();
};

const canWeFixIt = addSnackbar({ id: Date.now(), message: 'Can we fix it?', severity: 'warning' });
const yesWeCan = addSnackbar({ id: Date.now(), message: 'Yes we can!', severity: 'success' });

function App() {
  const dispatch = useDispatch();

  useKonami(() => {
    playBob().then(() => {
      setTimeout(() => dispatch(canWeFixIt), 2000);
      setTimeout(() => dispatch(yesWeCan), 5500);
      setTimeout(() => dispatch(canWeFixIt), 9000);
      setTimeout(() => dispatch(yesWeCan), 12750);
      setTimeout(() => dispatch(removeAllSnackbars()), 15000);
    });
  });

  return (
    <Router>
      <Switch>
        <Route
          path="/"
          exact
          component={() => {
            window.location.href = 'https://getcenterline.com';
            return null;
          }}
        />
        <Route path="/main" component={MainPortal} />
        <Route path="/bidding" component={BiddingPortal} />
        <Route path="/admin" component={AdminPortal} />
      </Switch>
    </Router>
  );
}

export default App;
