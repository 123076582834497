import { ApiClient } from '../../api-client/api-client';
import { IInsertionPhoneNumber, PhoneNumberType } from '../../api-client/autogenerated';
import { getCountries } from './countries';

// eslint-disable-next-line import/prefer-default-export
export const insertPhoneNumberByUserId = async (
  userId: string,
  phoneNumber: IInsertionPhoneNumber,
) => {
  const countries = await getCountries();
  return ApiClient.insertPhoneNumberByUserId({
    userId,
    iInsertionPhoneNumber: {
      ...phoneNumber,
      countryId: countries.US,
      type: PhoneNumberType.Mobile,
    },
  }).then((res) => res.data);
};
