import React from 'react';

const ConstructionIcon = (props: any) => (
  <svg
    width={21}
    height={21}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.681 20.137c-.24.246-.557.367-.874.367-.317 0-.635-.125-.875-.367l-4.819-4.864a3.146 3.146 0 01-.394-3.933L7.409 8H4.57L.853 3 3.33.5l4.954 3.75v2.867l3.31 3.34a3.074 3.074 0 013.897.394l4.815 4.864c.483.488.483 1.277.004 1.77l-2.628 2.652zm1.274-9.567a5.61 5.61 0 01-1.122.88l-.914-.923a4.503 4.503 0 001.161-.84 4.63 4.63 0 001.281-4.082L16.68 8.312l-3.216-.539-.534-3.242 2.682-2.71a4.544 4.544 0 00-4.045 1.293c-1.126 1.136-1.56 2.769-1.188 4.347l-.855-.863V3.949A5.768 5.768 0 0116.23.68c.368.094.662.38.763.75.1.368-.004.766-.271 1.036L14.248 4.96l.287 1.726 1.71.29L18.72 4.48c.27-.273.665-.375 1.033-.273.364.102.65.395.74.766a5.884 5.884 0 01-1.537 5.597zM7.045 6.75H5.189L2.49 3.117l.956-.965 3.6 2.723V6.75zM5.87 19.629a2.896 2.896 0 01-2.078.871 2.908 2.908 0 01-2.079-.867 2.995 2.995 0 010-4.2l5.655-5.706.875.882-5.655 5.707a1.735 1.735 0 000 2.43c.642.649 1.765.649 2.407 0l4.215-4.254c.143.41.34.805.6 1.164L5.87 19.63zm10.934-.379l-4.815-4.863a1.868 1.868 0 01-.546-1.325c0-.5.194-.972.546-1.324.352-.355.817-.55 1.312-.55.496 0 .964.195 1.312.55l4.819 4.86-2.628 2.652zM4.569 17.375a.624.624 0 01-.62.625.624.624 0 01-.619-.625c0-.344.279-.625.62-.625.34 0 .619.281.619.625z"
      fill="#73A3FF"
    />
  </svg>
);

export default ConstructionIcon;
