import React, { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import AddIcon from '@material-ui/icons/Add';
import CircularLoader from '../loader/CircularLoader';
import {
  AuthenticatedUploadRequestWithFiles,
  createFilesystemFolder,
  deleteUserFileSystemNode,
  downloadBIMFile,
  downloadFilesystemFile,
  downloadPublicFilesystemFile,
  downloadTrashItem,
  getBIMTopFolders,
  getFilesystemFileBlob,
  getFilesystemFromProjectId,
  getMultipleFilesystemFileDownloadLinks,
  getMultiplePublicFilesystemFileDownloadLinks,
  getPublicUserFileSystemFileNode,
  getTrashByProjectId,
  getUserFileSystemFileNode,
  modifyUserFileSystemNode,
  uploadMultipleFilesystemFiles,
} from '../../models/api/filesystem';
import { getUserState } from '../../features/user/selectors';
import FilesystemHeader, { HeadCell } from './FilesystemHeader';
import {
  AuthenticatedDownloadRequest,
  BimTreeNode,
  BimTreeNodeTypeEnum,
  DesignFileDownloadResponse,
  FileNode,
  FileNodePermissions,
  FileNodeTypeEnum,
  GetTrashItem,
  IProjectView,
} from '../../api-client/autogenerated';
import '../../css/main-style.css';
import FileUploadDialog from '../dialogs/FileUploadDialog';
import CreateFolderOrAddFileDialog from '../dialogs/CreateFolderOrAddFileDialog';
import CreateFolderDialog from '../dialogs/CreateFolderDialog';
import { getComparator, Order, stableSort } from '../document-index/DocumentIndexUtils';
import { RedButton } from '../custom-components/CustomButtons';
import FileTableRow from './FileTableRow';
import StyledTreeItem from './StyledTreeItem';
import { generateUniqueId, isPublicPage } from '../../scripts/utils';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import { DesignFileSharePublicUrlParams } from '../../scripts/url-utils';
import ManagePermissionsDialog, { ManagePermissionsDialogType } from './ManagePermissionsDialog';
import { useLocation } from 'react-router-dom';
import JSZip from 'jszip';
import fileDownload from 'js-file-download';
import { getCurrentSecurityGroup } from '../../features/security/selectors';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { DESIGN_PATH_CHARACTER_LIMIT_FILE, MULTI_PART_FILE_SIZE } from '../../scripts/constants';
import DeleteIcon from '@material-ui/icons/Delete';
import usePrevious from '../custom-components/usePrevious';

const loginBIM360 = () => {
  const callbackUrl = encodeURIComponent('https://dev.centerline.dev/bim360-callback');
  window.location.href = `https://developer.api.autodesk.com/authentication/v1/authorize?response_type=token&client_id=JqN33rhQy18m38wOdqYVkP39jK8qmdhZ&redirect_uri=${callbackUrl}&scope=data:read`;
};

let currentNodeId = 0;
const getNodeId = () => {
  currentNodeId += 1;
  return currentNodeId.toString();
};

const useStyles = makeStyles(
  createStyles({
    root: {
      height: 'auto',
      flexGrow: 1,
      maxWidth: 310,
      width: 310,
      alignSelf: 'flex-start',
      backgroundColor: 'white',
      maxHeight: '100%',
      overflowX: 'auto',
      overflowY: 'auto',
    },
    boxRoot: {
      backgroundColor: 'white',
    },
    rowLayout: {
      width: 'auto',
      display: 'flex',
      flexDirection: 'row',
      flexBasis: 'auto',
      flexGrow: 1,
      maxHeight: (props: FileTreeProps) => (!props.small ? 650 : 470),
    },
    cardRoot: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      maxWidth: 1680,
      minHeight: (props: FileTreeProps) => (!props.small ? 730 : 550),
      maxHeight: (props: FileTreeProps) => (!props.small ? 730 : 550),
      zIndex: 10,
    },
    table: {
      flexGrow: 1,
    },
    centerIcon: {
      display: 'inline-flex',
      alignItems: 'center',
      flexBasis: 'auto',
      flexWrap: 'nowrap',
      height: '100%',
    },
    downloadIcon: {
      color: 'rgba(0, 0, 0, 0.54);',
      cursor: 'pointer',
    },
    inlineCenter: {
      display: 'flex',
      flexBasis: 'auto',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      height: 48,
    },
    loadingIcon: {
      width: 32,
      height: 32,
    },
    sizeCell: {
      minWidth: 90,
    },
    row: {
      transition: '0.12s',
      height: 48,
    },
    tableBody: {
      '&>:nth-child(odd)': {
        backgroundColor: '#F9F9F9',
        '&:hover': {
          backgroundColor: '#E6EEFF',
        },
      },
      '&>:nth-child(even)': {
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: '#E6EEFF',
        },
      },
    },
    titleStyle: {
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
      flexGrow: 1,
      // padding: 16,
      display: 'flex',
    },
    addButton: {
      width: 108,
      height: 80,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      background: '#0947B9',
      transition: '0.2s',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        background: '#4380ED',
      },
    },
    addButtonDisabled: {
      width: 108,
      height: 80,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      background: '#b8b8b8',
    },
    tableCell: {
      padding: '0px 16px 0px 16px',
    },
  }),
);

type FileData = {
  name: string;
  date: string;
  size: number;
  file?: FileNode | BimTreeNode;
  type: FileNodeTypeEnum | BimTreeNodeTypeEnum;
};

function flatten(node: FileNode): FileNode[] {
  return [
    node,
    ...(node.children && node.children.length > 0
      ? node.children.map((node) => flatten(node)).flat()
      : []),
  ];
}

function createData(
  name: string,
  date: string,
  size: number,
  file: FileNode | BimTreeNode | undefined,
  type: FileNodeTypeEnum | BimTreeNodeTypeEnum,
): FileData {
  return { name, date, size, file, type };
}

const defaultColumnTitles: HeadCell[] = [
  {
    disablePadding: true,
    id: 'select',
    label: '',
  },
  {
    disablePadding: true,
    id: 'view',
    label: '',
  },
  {
    disablePadding: false,
    id: 'name',
    label: 'Name',
  },
  {
    disablePadding: false,
    id: 'date',
    label: 'Date Modified',
  },
  {
    disablePadding: false,
    id: 'size',
    label: 'Size',
  },
  ...(!isPublicPage()
    ? [
        {
          disablePadding: true,
          id: 'sharing',
          label: 'Access',
        },
      ]
    : []),
  {
    disablePadding: true,
    id: 'log',
    label: 'Activity Log',
  },
  {
    disablePadding: true,
    id: 'options',
    label: 'Options',
  },
];

const recycleBinColumnTitles = [
  {
    disablePadding: true,
    id: 'select',
    label: '',
  },
  {
    disablePadding: true,
    id: 'view',
    label: '',
  },
  {
    disablePadding: false,
    id: 'path',
    label: 'Path',
  },
  {
    disablePadding: false,
    id: 'name',
    label: 'Name',
  },
  {
    disablePadding: false,
    id: 'date',
    label: 'Date Deleted',
  },
  {
    disablePadding: false,
    id: 'size',
    label: 'Size',
  },
  {
    disablePadding: true,
    id: 'log',
    label: 'Activity Log',
  },
  {
    disablePadding: true,
    id: 'options',
    label: 'Options',
  },
];

type FileTreeProps = {
  project: IProjectView | null;
  backButtonPressed?: () => void;
  disableOptions?: boolean;
  disableUploadFile?: boolean;
  type: 'Project Files' | 'BIM360';
  handleBIMLogin?: () => void;
  handleSignOutOfBIM?: () => void;
  importFromBIM?: boolean;
  importBIMFile?: (file: BimTreeNode) => void;
  publicParams?: DesignFileSharePublicUrlParams;
  handleFileSelect?: (files: FileNode[]) => void;
  small?: boolean;
  disableSelectAll?: boolean;
  singleFile?: boolean;
  allowOnlyPdfs?: boolean;
};

function FileTree(props: FileTreeProps) {
  const classes = useStyles(props);
  const {
    project,
    backButtonPressed,
    disableOptions,
    disableUploadFile,
    type,
    handleBIMLogin = () => {},
    handleSignOutOfBIM = () => {},
    importFromBIM,
    importBIMFile = () => {},
    publicParams,
    handleFileSelect = (files: FileNode[]) => {},
    small = false,
    disableSelectAll,
    singleFile = false,
    allowOnlyPdfs,
  } = props;

  const [topLevelBIMFolders, setTopLevelBIMFolders] = useState<any | null>(
    <Box
      className={classes.inlineCenter}
      style={{ height: 48, background: 'white', overflow: 'hidden' }}
    >
      <CircularLoader />
    </Box>,
  );

  const [topLevelFolders, setTopLevelFolders] = useState<any | null>(
    <Box
      className={classes.inlineCenter}
      style={{ height: 48, background: 'white', overflow: 'hidden' }}
    >
      <CircularLoader />
    </Box>,
  );

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const headerRef = useRef<HTMLDivElement | null>(null);

  const [topLevelFolderData, setTopLevelFolderData] = useState<FileNode[] | undefined>(undefined);
  const [topLevelBIMFolderData, setTopLevelBIMFolderData] = useState<BimTreeNode[] | undefined>(
    undefined,
  );

  const [tableRows, setTableRows] = useState<any | null>([]);
  const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
  const [sharedWorkflowDialogOpen, setSharedWorkflowDialogOpen] = useState(false);
  const [createFolderOrAddFileDialogOpen, setCreateFolderOrAddFileDialogOpen] = useState(false);
  const [createFolderOpen, setCreateFolderOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadMessage, setUploadMessage] = useState<string>();
  const [activeFolder, setActiveFolder] = useState<undefined | FileNode>();
  const [activeFolderSubfolders, setActiveFolderSubfolders] = useState<any>([]);
  const [forceRefresh, setForceRefresh] = useState(0);
  const [expandedNodes, setExpandedNodes] = useState<string[]>(['/']);
  const [selectedNode, setSelectedNode] = useState<string>('/');
  const [orderBy, setOrderBy] = useState<string | undefined>();
  const [order, setOrder] = React.useState<Order>('desc');
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [treeIsLoading, setTreeIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [allNodesExpanded, setAllNodesExpanded] = useState<boolean>(false);

  const [total, setTotal] = useState(0);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [selectedNodes, setSelectedNodes] = useState<FileNode[]>([]);

  const [recycleBin, setRecycleBin] = useState<FileNode[]>([]);
  const [continuationToken, setContinuationToken] = useState<string>();

  const previousActiveFolder = usePrevious<FileNode | undefined>(activeFolder);
  const activeFolderIsRecycleBin = activeFolder?.fullKey === '/Recycle Bin/';

  useEffect(() => {
    if (
      (previousActiveFolder?.fullKey === '/Recycle Bin/' &&
        activeFolder?.fullKey !== '/Recycle Bin/') ||
      (previousActiveFolder?.fullKey !== '/Recycle Bin/' &&
        activeFolder?.fullKey === '/Recycle Bin/')
    ) {
      setSelectedNodes([]);
      setSelectedFiles([]);
    }
  }, [previousActiveFolder, activeFolder]);

  const getColumnTitles = (): HeadCell[] => {
    if (activeFolderIsRecycleBin) return recycleBinColumnTitles;

    return defaultColumnTitles;
  };

  const newFilePaths = uploadedFiles.map((file) => `${activeFolder?.fullKey}${file.name}`);

  const isNewFilePathTooLong = newFilePaths.some(
    (p) => p.length > DESIGN_PATH_CHARACTER_LIMIT_FILE,
  );

  useEffect(() => {
    if (isNewFilePathTooLong) {
      setErrorMessage(
        `The resulting file path will exceed the character limit of ${DESIGN_PATH_CHARACTER_LIMIT_FILE}`,
      );
    } else {
      setErrorMessage(undefined);
    }
  }, [isNewFilePathTooLong, fileUploadDialogOpen]);

  const activeFolderRef = useRef(activeFolder);
  useEffect(() => {
    if (activeFolder) {
      activeFolderRef.current = activeFolder;
      let node =
        activeFolder.type === FileNodeTypeEnum.Folder
          ? activeFolder.fullKey
          : activeFolder.fullKey.substring(0, activeFolder.fullKey.lastIndexOf('/'));
      if (activeFolder.fullKey !== '/' && !activeFolderIsRecycleBin) node += activeFolder.ownerId;
      setSelectedNode(node);
    }
  }, [activeFolder]);

  const [nodesToShare, setNodesToShare] = useState<FileNode[]>([]);

  const [projectId, setProjectId] = useState<string | undefined>(undefined);

  const user = useSelector(getUserState);
  const security = useSelector(getCurrentSecurityGroup);

  // Broken in Node 20 upgrade
  // const client = new BIMClient('');

  const canSeeRecycleBin = () => {
    return user.isSiteAdmin;
  };

  const getFileNodeFromTrashItem = (item: GetTrashItem): FileNode => {
    const { projectId, ownerId, fullKey, size, lastModified, s3Key } = item;
    const pathSplit = fullKey.split('/');
    const relativeKey = pathSplit.pop();
    const extensionSplit = relativeKey!.split('.')!;
    return {
      projectId,
      ownerId,
      fullKey: s3Key,
      relativeKey,
      type: FileNodeTypeEnum.File,
      file: {
        extension: extensionSplit[extensionSplit.length - 1],
        fullName: relativeKey,
        name: extensionSplit.slice(0, extensionSplit.length - 1).join('.'),
        fullPrefix: pathSplit.join('/'),
        lastModified,
        size,
      },
    };
  };

  const getLocalFileNodeForTrash = () => {
    if (!project) return;
    return {
      projectId: project.id,
      fullKey: '/Recycle Bin/',
      ownerId: user.id,
      relativeKey: '/Recycle Bin/',
      children: recycleBin,
      type: FileNodeTypeEnum.Folder,
    };
  };

  useEffect(() => {
    if (project) {
      if (canSeeRecycleBin()) {
        getTrashByProjectId(project.id).then(({ trashItems, continuationToken }) => {
          setRecycleBin(trashItems.map(getFileNodeFromTrashItem));
          setContinuationToken(continuationToken);
        });
      }
    }
  }, [project]);

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  function setTable(data: any) {
    let formattedData;
    if (type === 'BIM360') {
      formattedData = data.filter((file: BimTreeNode) => {
        return file.type === 'FILE';
      });
      if (importFromBIM) {
        formattedData = formattedData.filter((file: BimTreeNode) => {
          return file.fileAttributes.fileType === 'pdf';
        });
      }
      formattedData = formattedData.map((file: BimTreeNode) =>
        createData(
          file.name,
          file.fileAttributes.lastModifiedTime,
          file.fileAttributes.storageSize,
          file,
          file.type,
        ),
      );
    } else {
      formattedData = data.map((file: any) =>
        createData(
          file.file?.fullName || file.relativeKey.replaceAll('/', ''),
          file.file?.lastModified || '',
          file.file?.size || file.children?.length || 0,
          file,
          file.type,
        ),
      );
    }
    setTableIsLoading(false);
    setTableRows(formattedData);
  }

  const handleExpandedNodes = (event: React.ChangeEvent<{}> | null, nodeIds: string[]) => {
    setExpandedNodes(nodeIds);
  };

  const addExpandedNode = (nodeId: string) => {
    const tmp = [...expandedNodes];
    tmp.push(nodeId);
    setExpandedNodes(tmp);
  };

  const handleSelectNode = (event: React.ChangeEvent<{}>, nodeId: string) => {
    setSelectedNode(nodeId);
  };

  const getTitle = () => {
    let str = '';
    if (isPublicPage()) {
      return 'Project Files';
    }
    if (project) {
      str = str.concat(project.name);
      if (type === 'Project Files') {
        str = str.concat(' Project Files');
      }
      return str;
    }
    return 'N/A';
  };

  const getSubtitle = () => {
    let str = '';
    if (activeFolder?.fullKey && activeFolder.fullKey !== '/') {
      const folders = activeFolder.fullKey.split('/').filter((f) => f);
      folders.forEach((folder, index) => {
        if (index === 0) str = str.concat(folder);
        else str = str.concat('  >  ').concat(folder);
      });
    }
    return str;
  };

  async function getTopLevelFolders() {
    if (type === 'Project Files') {
      if (isPublicPage())
        return new Promise<void>((resolve, reject) => {
          getPublicUserFileSystemFileNode(
            publicParams?.projectId || '',
            publicParams?.designFileShareId || '',
            publicParams?.publicAccessKey || '',
          )
            .then((result) => {
              setTopLevelFolderData([result]);
              if (result.type === 'FILE') {
                setActiveFolder(undefined);
                setTable([result]);
              } else {
                setActiveFolder(result);
                setTable(result.children);
                setSelectedNode(result.fullKey);
              }

              resolve(undefined);
            })
            .catch(() => {
              reject();
            });
        });
      else
        return new Promise<void>((resolve, reject) => {
          getFilesystemFromProjectId(projectId!)
            .then((result) => {
              setTopLevelFolderData(result.children);
              setActiveFolder(result);
              setTable(result.children);
              resolve(undefined);
            })
            .catch((e) => {
              console.log(e);
              reject();
            });
        });
    } else if (type === 'BIM360') {
      return new Promise<void>((resolve, reject) => {
        getBIMTopFolders(projectId!)
          .then((topFolders) => {
            setTopLevelBIMFolderData(topFolders);
            resolve(undefined);
          })
          .catch(() => reject());
      });
    }
  }

  const displayTopLevelFolders = () => topLevelFolders;

  //Copies a new object into an old object while still keeping the old object's pointer.
  function replaceReferencedObj(refObj: any, newObj: any) {
    let keysR = Object.keys(refObj);
    let keysN = Object.keys(newObj);
    for (let i = 0; i < keysR.length; i++) {
      delete refObj[keysR[i]];
    }
    for (let i = 0; i < keysN.length; i++) {
      refObj[keysN[i]] = newObj[keysN[i]];
    }
  }

  const findNodeInTree = (nodeId: string) => {
    if (topLevelFolderData && topLevelFolderData.length > 0) {
      const data = topLevelFolderData;
      let found: FileNode | null = null;
      for (var i = 0; i < data.length && !found; i++) {
        found = findNodeInTreeRecursive(data[i], nodeId);
      }
      return found;
    }
    return null;
  };

  const findNodeInTreeRecursive = (node: FileNode, id: string): FileNode | null => {
    if (node.fullKey === id) {
      return node;
    }
    if (node.children!.length > 0) {
      const data = node.children!;
      let found: FileNode | null = null;
      for (var i = 0; i < data.length && !found; i++) {
        found = findNodeInTreeRecursive(data[i], id);
      }
      return found;
    }
    return null;
  };

  function renderTopLevelFolders() {
    const rootFolder: FileNode = {
      projectId: projectId!,
      fullKey: '/',
      ownerId: user.id,
      relativeKey: '/',
      children: topLevelFolderData,
      type: FileNodeTypeEnum.Folder,
    };
    let formattedData: any = undefined;
    //For creating the baselevel folder with all nested folders in it
    if (!isPublicPage() || (isPublicPage() && topLevelFolderData![0]!.type! === 'FILE')) {
      formattedData = topLevelFolderData ? (
        <StyledTreeItem
          id={rootFolder.relativeKey!}
          nodeId={rootFolder.fullKey}
          labelText={project?.name || 'Root'}
          hasChildren={rootFolder.children && rootFolder.children.length > 0}
          labelIcon={FolderIcon}
          callback={setTable}
          fileNode={rootFolder}
          parentNode={undefined}
          setActiveFolder={setActiveFolder}
          setActiveFolderSubfolders={setActiveFolderSubfolders}
          updateFolderName={updateFolderName}
          deleteFolder={deleteFolder}
          forceRefresh={forceRefresh}
          updateFileNode={updateFileNode}
          setSelectedNode={setSelectedNode}
          openCreateFolderDialog={() => setCreateFolderOpen(true)}
          openUploadFileDialog={() => setFileUploadDialogOpen(true)}
          handleDownload={handleDownload}
          findParentFolder={findParentFolder}
          manageParentFolder={manageParentFolderPermissions}
          selectedNodes={selectedNodes}
        />
      ) : (
        []
      );
    }
    //For viewing a file through a public link
    else {
      formattedData = (
        <StyledTreeItem
          id={topLevelFolderData![0]!.relativeKey!}
          nodeId={topLevelFolderData![0]!.fullKey}
          labelText={topLevelFolderData![0]!.relativeKey!}
          hasChildren={true}
          labelIcon={FolderIcon}
          callback={setTable}
          fileNode={topLevelFolderData![0]!}
          parentNode={undefined}
          setActiveFolder={(folder) => {
            setActiveFolder(folder);
          }}
          setActiveFolderSubfolders={(folder) => {
            setActiveFolderSubfolders(folder);
          }}
          updateFolderName={updateFolderName}
          deleteFolder={deleteFolder}
          forceRefresh={forceRefresh}
          updateFileNode={updateFileNode}
          openCreateFolderDialog={() => setCreateFolderOpen(true)}
          openUploadFileDialog={() => setFileUploadDialogOpen(true)}
          handleDownload={handleDownload}
          findParentFolder={findParentFolder}
          manageParentFolder={manageParentFolderPermissions}
          selectedNodes={selectedNodes}
        />
      );
    }
    if (formattedData.length === 0) {
      formattedData = (
        <Typography
          variant="body2"
          style={{ height: 48, paddingLeft: 16, paddingTop: 8, background: 'white' }}
        >
          No folders exist yet
        </Typography>
      );
    }
    setTopLevelFolders(formattedData);
  }

  function renderTopLevelBIMFolders() {
    let formattedData: any = topLevelBIMFolderData?.map((folder) => {
      return (
        <StyledTreeItem
          key={folder.id}
          id={folder.id}
          nodeId={folder.id}
          labelText={folder.name}
          hasChildren={true}
          labelIcon={FolderIcon}
          callback={setTable}
          projectId={projectId}
          bimTreeNode={folder}
          setActiveFolder={(folder) => {
            setActiveFolder(folder);
          }}
          setActiveFolderSubfolders={(folder) => {
            setActiveFolderSubfolders(folder);
          }}
          updateFolderName={updateFolderName}
          forceRefresh={forceRefresh}
          setTableIsLoading={setTableIsLoading}
          findParentFolder={findParentFolder}
          manageParentFolder={manageParentFolderPermissions}
          BIM
          selectedNodes={selectedNodes}
        />
      );
    });
    if (formattedData?.length === 0) {
      formattedData = (
        <Typography
          variant="body2"
          style={{ height: 48, paddingLeft: 16, paddingTop: 8, background: 'white' }}
        >
          No folders exist
        </Typography>
      );
    }
    setTopLevelBIMFolders(formattedData);
  }

  const collapseAllNodes = () => {
    setExpandedNodes([]);
    setAllNodesExpanded(false);
  };

  const expandAllNodes = () => {
    let expanded = ['/'];
    expanded = expanded.concat(expandNodes(topLevelFolderData));
    setExpandedNodes(expanded);
    setAllNodesExpanded(true);
  };

  const expandNodes = (nodes: FileNode[] | undefined) => {
    let expanded: string[] = [];
    if (nodes) {
      nodes.forEach((node) => {
        expanded.push(node.fullKey + node.ownerId);
        if (node.children && node.children.length > 0)
          expanded = expanded.concat(expandNodes(node.children));
      });
    }
    return expanded;
  };

  const getExpandButton = () => {
    return !allNodesExpanded ? (
      <Button onClick={expandAllNodes}>
        <span>
          <Typography variant="body2">Expand All</Typography>
        </span>
        <ArrowRightIcon />
      </Button>
    ) : (
      <Button onClick={collapseAllNodes}>
        <Typography variant="body2">Collapse All</Typography>
        <ArrowDropDownIcon />
      </Button>
    );
  };

  useEffect(() => {
    if (topLevelFolderData) renderTopLevelFolders();
  }, [forceRefresh, topLevelFolderData, expandedNodes]);

  useEffect(() => {
    if (topLevelBIMFolderData) renderTopLevelBIMFolders();
  }, [topLevelBIMFolderData]);

  useEffect(() => {
    // Update the document title using the browser API
    //  uploadFilesystemFile(projectId, subscriber.userId, 'File33.pdf', 'FILE', {
    //  ame: 'File 33',
    //  extension: '.pdf',
    //  });
    //getTopLevelBIMFolders();
    if (project) {
      setProjectId(project.id);
    } else if (
      isPublicPage() &&
      publicParams &&
      publicParams?.publicAccessKey &&
      publicParams?.projectId &&
      publicParams?.designFileShareId
    ) {
      setProjectId(publicParams.projectId);
    }
  }, [project, type, publicParams]);

  const getParentFolderNodeIds = (node: FileNode) => {
    const nodeIds = ['/'];
    const keys = node.fullKey.split('/').filter((k) => k);
    keys.pop();
    keys.forEach((key, index) => {
      if (index === 0) nodeIds.push(`/${key}/${node.ownerId}`);
      else nodeIds.push(`/${keys.slice(0, index + 1).join('/')}/${node.ownerId}`);
    });
    return nodeIds;
  };

  useEffect(() => {
    if (projectId) {
      getTopLevelFolders().then(() => {
        const path = new URLSearchParams(location.search.substring(1)).get('fullKey');
        if (path && activeFolderRef.current) {
          const allNodes = flatten(activeFolderRef.current);
          const nodeToFind = allNodes.find((node) => node.fullKey === path);
          if (nodeToFind) {
            if (nodeToFind.type === FileNodeTypeEnum.Folder) {
              setActiveFolder(nodeToFind);
              setTable(nodeToFind.children);
              setSelectedNode(nodeToFind.fullKey + nodeToFind.ownerId);
              handleExpandedNodes(null, getParentFolderNodeIds(nodeToFind));
            } else {
              const paths = path.split('/').filter((p) => p);
              const folderToFind = allNodes.find(
                (node) => node.fullKey === `/${paths.slice(0, paths.length - 1).join('/')}/`,
              );
              if (folderToFind) {
                setActiveFolder(folderToFind);
                setTable(folderToFind.children);
                setSelectedNode(folderToFind.fullKey + folderToFind.ownerId);
                handleExpandedNodes(null, getParentFolderNodeIds(folderToFind));
              }
            }
          }
        }
      });
    }
  }, [projectId]);

  const removeUploadedFile = (f: File) => {
    const tmp = [...uploadedFiles];
    tmp.splice(tmp.indexOf(f), 1);
    setUploadedFiles(tmp);
  };

  const addUploadedFiles = (newFiles: File[]) => {
    setUploadedFiles([...uploadedFiles, ...newFiles]);
  };

  const addPermissionsToFileNodeRecursively = (
    node: FileNode,
    permissions: FileNodePermissions[],
  ) => {
    node.fileNodePermissions = permissions;
    if (node.children?.length) {
      node.children.map((c) => addPermissionsToFileNodeRecursively(c, permissions));
    }
    return;
  };

  const buildRequest = (files: (File | FileNode)[]) => {
    const request = [] as AuthenticatedUploadRequestWithFiles[];
    files.forEach((file) => {
      request.push({
        fullKey: activeFolder
          ? activeFolder.fullKey.concat((file as File).name)
          : '/'.concat((file as File).name),
        ownerId: activeFolder?.ownerId || user.id,
        file: file as File,
        useMultiPartUpload: (file as File).size > MULTI_PART_FILE_SIZE,
      });
    });
    return request;
  };

  const uploadFiles = async () => {
    const shareableFileNodes: FileNode[] = [];

    if (uploadedFiles.length > 0) {
      dispatch(blockNavigation());
      const request = buildRequest(uploadedFiles);
      const newNodes = await uploadMultipleFilesystemFiles(
        projectId!,
        request,
        handleUploadProgress,
        (nextFile) => {
          setUploadProgress(0);
          setUploadMessage(`Uploading ${nextFile.name}`);
        },
      );
      setUploadProgress(0);
      setUploadMessage(undefined);
      dispatch(allowNavigation());
      const tempPermissions = activeFolder?.fileNodePermissions || [];
      const localFolder = await getUserFileSystemFileNode(
        projectId!,
        activeFolder!.fullKey,
        activeFolder?.ownerId || user.id,
      );
      addPermissionsToFileNodeRecursively(localFolder, tempPermissions);
      updateFileNode(localFolder);
      const newNodesFiltered = newNodes.filter((n) => !!n) as FileNode[];
      shareableFileNodes.push(
        ...newNodesFiltered.map((n) => ({ ...n, fileNodePermissions: tempPermissions })),
      );
      setUploadProgress(0);
      setFileUploadDialogOpen(false);
      setUploadedFiles([]);
      setErrorMessage(undefined);

      if (type !== 'BIM360') {
        setNodesToShare(shareableFileNodes);
        setSharedWorkflowDialogOpen(true);
      }
    }
  };

  const updateFileName = (name: string, file: FileNode) => {
    const nameWithExt = `${name}.${file!.file!.extension.substr(
      file!.file!.extension.lastIndexOf('/') + 1,
      file!.file!.extension.length,
    )}`;

    if (nameWithExt === file!.file!.fullName) {
      return;
    }

    //Temporary instant local rename.
    const updatedRows = [...activeFolder!.children!];
    const f = updatedRows.find((x) => x.fullKey === file.fullKey);
    if (f) {
      f.file!.fullName = nameWithExt;
      setTable(updatedRows);
    }

    /* API CALL TO UPDATE FILE NAME */
    modifyUserFileSystemNode(file.projectId, file, nameWithExt).then((newFileNode) => {
      //local rename
      const updatedRows = [...activeFolder!.children!];
      const index = updatedRows.findIndex((x) => x.fullKey === file.fullKey);
      if (index !== -1) {
        updatedRows.splice(index, 1, newFileNode);
      }
      setTable(updatedRows);

      let nodeInTree = findNodeInTree(activeFolder!.fullKey);
      if (nodeInTree) {
        nodeInTree.children = updatedRows;
        setForceRefresh(forceRefresh + 1);
      }
    });
  };

  const updateFileInActiveFolder = (f: FileNode) => {
    const fIndex = activeFolder!.children!.findIndex((x) => x.fullKey === f.fullKey!);
    activeFolder!.children![fIndex] = f;
  };

  const updateFolderName = async (name: string, file: FileNode) => {
    //API call to change folder name
    try {
      setTableIsLoading(true);
      setTreeIsLoading(true);
      await modifyUserFileSystemNode(file.projectId, file, name);
      await getTopLevelFolders();
    } finally {
      setTableIsLoading(false);
      setTreeIsLoading(false);
    }
    // updateFileNode({ ...file, fullKey: '/newFolder2' });
    //
    // //local name change
    // file.relativeKey = `${name}/`;
    // setForceRefresh(forceRefresh + 1);
  };

  const deleteFile = async (file: FileNode) => {
    const proceed = window.confirm(
      `Are you sure you want to delete this ${
        file.type === FileNodeTypeEnum.File ? 'file' : 'folder'
      }?`,
    );
    if (!proceed) return;
    //API CALL TO DELETE FILE NAME
    try {
      await deleteUserFileSystemNode(file.projectId, file);
      const updatedRows = [...activeFolder!.children!];
      let index = updatedRows.findIndex((x) => x.fullKey === file.fullKey);
      updatedRows.splice(index, 1);
      index = activeFolder!.children!.findIndex((x) => x.fullKey === file.fullKey);
      activeFolder!.children!.splice(index, 1);
      setTable(updatedRows);
    } catch (e: any) {
      console.error(e);
    }
  };

  const deleteFolder = async (file: FileNode, parent: FileNode) => {
    const proceed = window.confirm('Are you sure you want to delete this folder?');
    if (!proceed) return;
    //API CALL TO DELETE FILE NAME
    try {
      await deleteUserFileSystemNode(file.projectId, file);
      await getTopLevelFolders();
      // local delete
      // const index = parent!.children!.findIndex((x) => x.fullKey === file.fullKey);
      // const fileId = parent.fullKey;
      // parent.children!.splice(index, 1);
      // setDeleteId(fileId);
    } catch (e: any) {
      console.error(e);
    }
  };

  const createFolder = async (fullKey: string) => {
    const ownerId = activeFolder?.ownerId || user.id;
    await createFilesystemFolder(projectId!, fullKey.trim(), ownerId).then(
      async ({ sanitizedFullKey }) => {
        const localFolder: FileNode = await getUserFileSystemFileNode(
          projectId!,
          sanitizedFullKey,
          ownerId,
        );
        if (!activeFolder || activeFolder.fullKey === '/') topLevelFolderData?.push(localFolder);
        else {
          const node = findNodeInTree(activeFolder!.fullKey);
          if (node) node.children!.push(localFolder);
        }
        setActiveFolder(localFolder);
        setSelectedNode(localFolder.fullKey + localFolder.ownerId);
        addExpandedNode(activeFolder!.fullKey);

        setTimeout(() => setForceRefresh(forceRefresh + 1), 5000);
        if (type !== 'BIM360' && localFolder.ownerId === user.id) {
          setNodesToShare([localFolder]);
          setSharedWorkflowDialogOpen(true);
        }
      },
    );
  };

  const downloadFile = async (name: string, fullKey: string, ownerId: string) => {
    if (isPublicPage()) {
      await downloadPublicFilesystemFile(
        projectId!,
        name,
        fullKey,
        publicParams!.designFileShareId,
        publicParams!.publicAccessKey,
      );
    } else if (activeFolderIsRecycleBin) {
      await downloadTrashItem(fullKey, name);
    } else {
      await downloadFilesystemFile(projectId!, name, fullKey, false, ownerId);
    }
  };

  const downloadFileFromBIM = async (treeNode: BimTreeNode) => {
    await downloadBIMFile(projectId!, treeNode);
  };

  const onRequestSort = (headId: string) => {
    if (orderBy === headId) {
      if (order === 'desc') setOrder('asc');
      else setOrder('desc');
    }
    setOrderBy(headId);
  };

  const getSelectedFolderName = () => {
    if (type === 'BIM360') {
      return 'this folder';
    }
    if (activeFolder) {
      return activeFolder.fullKey === '/' ? type.concat('/') : activeFolder.fullKey;
    }
    return type.concat('/');
  };

  const updateFileNode = (node: FileNode) => {
    if (!activeFolderRef.current) return;
    if (activeFolderRef.current.fullKey === node.fullKey) {
      setActiveFolder(node);
      let tmp = findNodeInTree(node.fullKey);
      if (tmp) {
        replaceReferencedObj(tmp, node);
      }
      setTable(node.children);
      return;
    }

    if (topLevelFolderData) {
      const replaceIndex = topLevelFolderData.findIndex((f) => f.fullKey === node.fullKey);
      if (replaceIndex !== -1) {
        const updatedNodes = [
          ...topLevelFolderData.slice(0, replaceIndex),
          node,
          ...topLevelFolderData.slice(replaceIndex + 1),
        ];
        setTopLevelFolderData(updatedNodes);
        if (topLevelFolderData[replaceIndex].type === 'FILE') setTable(updatedNodes);
        return;
      }
    }

    const children = activeFolderRef.current.children ? [...activeFolderRef.current.children] : [];
    const replaceIndex = children.findIndex((n) => n.fullKey === node.fullKey);
    if (replaceIndex !== -1) {
      children[replaceIndex] = node;
      setTable(children);
      let tmp = findNodeInTree(activeFolder!.fullKey);
      if (tmp) {
        replaceReferencedObj(tmp.children, children);
      }
    }
  };

  const getAllFiles = (node: FileNode): FileNode[] => {
    switch (node.type) {
      case FileNodeTypeEnum.File:
        return [node];

      case FileNodeTypeEnum.Folder:
        if (!node.children) return [];
        return node.children.map((n) => getAllFiles(n)).flat();
    }
  };

  const handleDownload = async () => {
    if (selectedFiles.length > 0) await handleDownloadSelected();
    else await handleDownloadAll();
  };

  const handleDownloadAll = async () => {
    if (!activeFolderRef.current?.children) return;
    const files = getAllFiles(activeFolderRef.current).map(({ fullKey, ownerId }) => {
      return { fullKey, ownerId, isForViewing: false };
    });
    await handleDownloadFiles(files);
  };

  const handleDownloadSelected = async () => {
    if (!activeFolderRef.current?.children || activeFolderRef.current?.children.length === 0)
      return;
    const files = activeFolderRef.current.children
      .filter((node) => selectedFiles.includes(node.fullKey))
      .map((node) => {
        if (node.type === FileNodeTypeEnum.File)
          return { ownerId: node.ownerId, fullKey: node.fullKey, isForViewing: false };
        return getAllFiles(node).map(({ ownerId, fullKey }) => {
          return { ownerId, fullKey, isForViewing: false };
        });
      })
      .flat();
    await handleDownloadFiles(files);
  };

  const handleDownloadFiles = async (requests: AuthenticatedDownloadRequest[]) => {
    if (!activeFolderRef.current?.children) return;
    const zip = new JSZip();
    let downloads: DesignFileDownloadResponse[];
    if (isPublicPage())
      downloads = await getMultiplePublicFilesystemFileDownloadLinks(
        projectId!,
        requests.map(({ fullKey, formatted }) => {
          return {
            fullKey,
            formatted,
            designFileShareId: publicParams!.designFileShareId!,
            publicAccessKey: publicParams!.publicAccessKey,
          };
        }),
      );
    else downloads = await getMultipleFilesystemFileDownloadLinks(projectId!, requests);
    setTotal(downloads.length);
    await Promise.all(
      downloads.map(async ({ sanitizedFullKey, downloadLink }) => {
        if (sanitizedFullKey)
          return zip.file(
            sanitizedFullKey.slice(1),
            getFilesystemFileBlob(downloadLink).then((result) => {
              setDownloadProgress((prev) => prev + 1);
              return result;
            }),
          );
      }),
    );
    const contents = await zip.generateAsync({ type: 'blob', streamFiles: true });
    fileDownload(
      contents,
      `${project?.name ? `${project.name}_` : ''}${
        activeFolderRef.current.relativeKey === '/'
          ? 'Project Files'
          : activeFolderRef.current.relativeKey?.slice(
              0,
              activeFolderRef.current.relativeKey.length - 1,
            )
      }.zip`,
    );
    setDownloadProgress(0);
  };

  const handleSelectRow = (fileNode: FileNode | undefined) => {
    if (!fileNode) return;

    let fullKey = fileNode.fullKey;
    if (singleFile) {
      setSelectedFiles([fullKey]);
      setSelectedNodes([fileNode]);
    } else {
      if (!selectedFiles.includes(fullKey)) {
        setSelectedFiles([...selectedFiles, fullKey]);
        setSelectedNodes([...selectedNodes, fileNode]);
      } else {
        setSelectedFiles(selectedFiles.filter((key) => key !== fullKey));
        setSelectedNodes(selectedNodes.filter((node) => node.fullKey !== fileNode.fullKey));
      }
    }
  };

  const handleSelectAll = () => {
    if (!activeFolder?.children) return;

    // theres a bug here (statement is true in unintended situations) -trent
    if (
      selectedFiles.length > 0 &&
      selectedFiles.every((fullKey) => activeFolder.children!.some((n) => n.fullKey === fullKey))
    ) {
      setSelectedFiles([]);
      setSelectedNodes([]);
    } else {
      setSelectedFiles((prev) =>
        _.uniq([...prev, ...activeFolder.children!.map((n) => n.fullKey)]),
      );
      setSelectedNodes((prev) => _.uniqBy([...prev, ...activeFolder.children!], (n) => n.fullKey));
    }
  };

  useEffect(() => {
    handleFileSelect(selectedNodes);
  }, [handleFileSelect, selectedNodes]);

  const findParentFolder = (fullKey: string) => {
    const fullKeyToFind = fullKey.split('/').filter((k) => k)[0];
    if (topLevelFolderData && fullKeyToFind) {
      const nodeToFind = topLevelFolderData.find((node) => node.fullKey === `/${fullKeyToFind}/`);
      if (nodeToFind?.fullKey !== fullKey) return nodeToFind;
    }
    return undefined;
  };

  const manageParentFolderPermissions = (fullKey: string) => {
    const node = findParentFolder(fullKey);
    if (node) {
      setNodesToShare([node]);
      setSharedWorkflowDialogOpen(true);
    }
  };

  const getSortableRows = (): any[] =>
    tableRows.map((row: FileData) => {
      return {
        name: row.name,
        size: row.size,
        date: row.date,
        file: row.file,
        sharing:
          (row.file as FileNode).fileNodePermissions &&
          (row.file as FileNode).fileNodePermissions!.length > 0,
        checked: selectedFiles.some(
          (fullKey) => row.file && 'fullKey' in row.file && row.file.fullKey === fullKey,
        ),
        type: row.type,
      };
    });

  return (
    <Card
      className={classes.cardRoot}
      style={isPublicPage() ? { marginLeft: 32, marginTop: 32 } : undefined}
    >
      <Grid container justify="space-between" alignItems="center">
        <Grid ref={headerRef} item className={classes.titleStyle}>
          {disableUploadFile ? null : (
            <Button
              disabled={isPublicPage()}
              className={isPublicPage() ? classes.addButtonDisabled : classes.addButton}
              onClick={() => setCreateFolderOrAddFileDialogOpen(true)}
            >
              <AddIcon style={{ height: 48, width: 48, color: 'white' }} />
            </Button>
          )}
          <div>
            <h2
              className="h2"
              style={{
                fontSize: '30px',
                lineHeight: '50px',
                textAlign: 'left',
                color: '#FFFFFF',
                whiteSpace: 'pre',
                margin: '0px 0px 0px 8px',
              }}
            >
              {getTitle()}
            </h2>
            <h2
              className="h2"
              style={{
                fontSize: '20px',
                lineHeight: '24px',
                textAlign: 'left',
                color: '#FFFFFF',
                whiteSpace: 'pre',
                margin: '0px 0px 6px 8px',
              }}
            >
              {getSubtitle() || <i>No folder selected</i>}
            </h2>
          </div>
        </Grid>
      </Grid>
      <div className={classes.rowLayout}>
        <div style={{ borderRight: '1px solid #d1d1d1', display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              height: 48,
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 8,
              paddingRight: 8,
              justifyContent: backButtonPressed ? 'space-between' : 'flex-end',
            }}
          >
            {!isPublicPage() && (
              <>
                {backButtonPressed && (
                  <IconButton onClick={backButtonPressed} size="small">
                    <ArrowBackIcon style={{ color: '#7A797A' }} />
                  </IconButton>
                )}
                {/*{type !== 'BIM360' && (security?.bimPermission === 4 || hasAdminPermissions()) && (*/}
                {/*  <Button*/}
                {/*    variant="contained"*/}
                {/*    color="primary"*/}
                {/*    onClick={handleBIMLogin}*/}
                {/*    style={{ height: 28 }}*/}
                {/*  >*/}
                {/*    BIM 360*/}
                {/*  </Button>*/}
                {/*)}*/}
                {type !== 'BIM360' ? getExpandButton() : null}
              </>
            )}
          </div>
          <Box style={{ flexGrow: 1, overflowY: 'auto', position: 'relative' }}>
            <TreeView
              className={classes.root}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              expanded={expandedNodes}
              selected={selectedNode}
              onNodeToggle={handleExpandedNodes}
              onNodeSelect={handleSelectNode}
              style={treeIsLoading ? { overflowY: 'hidden' } : undefined}
            >
              {!treeIsLoading ? (
                type === 'BIM360' ? (
                  topLevelBIMFolders
                ) : (
                  <>
                    {displayTopLevelFolders()}
                    {canSeeRecycleBin() ? (
                      <StyledTreeItem
                        id="/Recycle Bin/"
                        nodeId="/Recycle Bin/"
                        labelIcon={DeleteIcon}
                        labelText="Recycle Bin"
                        callback={setTable}
                        setActiveFolder={setActiveFolder}
                        setActiveFolderSubfolders={setActiveFolderSubfolders}
                        forceRefresh={forceRefresh}
                        findParentFolder={findParentFolder}
                        manageParentFolder={manageParentFolderPermissions}
                        selectedNodes={selectedNodes}
                        fileNode={getLocalFileNodeForTrash()}
                        isRecycleBin
                      />
                    ) : null}
                  </>
                )
              ) : (
                <CircularLoader />
              )}
            </TreeView>
          </Box>
          {type === 'BIM360' && (
            <div
              style={{
                justifyContent: 'flex-start',
                padding: 12,
                marginTop: 'auto',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <RedButton onClick={handleSignOutOfBIM}>Sign Out of BIM</RedButton>
            </div>
          )}
        </div>
        <div style={{ flexGrow: 1, overflowY: 'auto' }}>
          <Table aria-label="customized table">
            <FilesystemHeader
              type={type}
              columnTitles={getColumnTitles()}
              orderBy={orderBy || ''}
              setOrderBy={onRequestSort}
              download={handleDownload}
              progress={downloadProgress}
              total={total}
              selected={selectedFiles}
              children={activeFolder?.children || []}
              handleSelectAll={handleSelectAll}
              disableSelectAll={disableSelectAll}
              isRecycleBin={activeFolderIsRecycleBin}
            />
            <TableBody classes={{ root: classes.tableBody }}>
              {tableIsLoading ? (
                <div
                  style={{ columnSpan: 'all', width: '330%', background: 'white', paddingTop: 16 }}
                >
                  <CircularLoader fullWidth />
                </div>
              ) : (
                stableSort(
                  getSortableRows(),
                  getComparator(order, orderBy || 'type'),
                ).map((row: any) => (
                  <FileTableRow
                    allowOnlyPdfs={allowOnlyPdfs}
                    key={generateUniqueId()}
                    name={row.name}
                    size={row.size}
                    date={row.date}
                    file={type === 'BIM360' ? undefined : row.file}
                    bimFile={type === 'BIM360' ? row.file : undefined}
                    downloadFile={downloadFile}
                    downloadFileFromBIM={downloadFileFromBIM}
                    type={type}
                    disableOptions={disableOptions || false}
                    updateFileName={updateFileName}
                    deleteFile={deleteFile}
                    importFromBIM={importFromBIM}
                    importBIMFile={importBIMFile}
                    updateFileNode={updateFileNode}
                    updateFolderName={updateFolderName}
                    projectId={projectId}
                    checked={row.checked}
                    handleSelectRow={handleSelectRow}
                    setTable={setTable}
                    setActiveFolder={setActiveFolder}
                    findParentFolder={findParentFolder}
                    manageParentFolder={manageParentFolderPermissions}
                    selectedNodes={selectedNodes}
                    isRecycleBin={activeFolderIsRecycleBin}
                  />
                ))
              )}
            </TableBody>
            {tableRows.length === 0 && !tableIsLoading ? (
              <TableRow>
                <TableCell colSpan={7}>
                  {activeFolder?.fullKey === '/'
                    ? 'Create a folder or select one to view its contents'
                    : `No files in ${getSelectedFolderName()}`}
                </TableCell>
              </TableRow>
            ) : null}
          </Table>
        </div>
        <FileUploadDialog
          disableDesignUpload
          open={fileUploadDialogOpen}
          handleClose={() => {
            setFileUploadDialogOpen(false);
            setErrorMessage(undefined);
          }}
          title="Upload Files"
          addFiles={(files) => addUploadedFiles(files as File[])}
          removeFile={(file) => removeUploadedFile(file as File)}
          canSubmit={uploadedFiles.length > 0 && !isNewFilePathTooLong}
          newFiles={uploadedFiles}
          handleSubmit={uploadFiles}
          buttonType="upload"
          acceptAll
          disableComments
          uploadProgress={uploadProgress}
          errorMessage={errorMessage}
          uploadMessage={uploadMessage}
          customMessage={
            <p style={{ maxWidth: 400 }}>
              File(s) will be uploaded in{' '}
              <strong style={{ color: '#0947B9' }}>
                {project?.name}
                {activeFolder?.fullKey}
              </strong>
            </p>
          }
        />
        <CreateFolderOrAddFileDialog
          open={createFolderOrAddFileDialogOpen}
          handleClose={() => setCreateFolderOrAddFileDialogOpen(false)}
          handleCreateClick={() => {
            setCreateFolderOpen(true);
          }}
          handleUploadClick={() => setFileUploadDialogOpen(true)}
          disableUpload={!activeFolder || activeFolder.fullKey === '/'}
        />

        <CreateFolderDialog
          open={createFolderOpen}
          handleClose={() => setCreateFolderOpen(false)}
          activeFolder={activeFolder}
          handleSubmit={createFolder}
        />
        <ManagePermissionsDialog
          dialogOpen={sharedWorkflowDialogOpen}
          closeDialog={() => setSharedWorkflowDialogOpen(false)}
          files={type !== 'BIM360' ? nodesToShare : undefined}
          setFile={updateFileNode}
          type={ManagePermissionsDialogType.Design}
          findParentFolder={findParentFolder}
          manageParentFolder={manageParentFolderPermissions}
        />
      </div>
    </Card>
  );
}

export default FileTree;
