import React, { CSSProperties } from 'react';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { GreenCheck } from './CustomCheckboxes';

type Props = {
  label?: string;
  icon?: JSX.Element;
  strikethrough?: boolean;
  labelStyle?: CSSProperties;
} & CheckboxProps;

export default function LabelWithCheckbox(props: Props) {
  const { label, icon, strikethrough, labelStyle, style, ...rest } = props;

  return (
    <div style={{ display: 'flex', alignItems: 'center', ...style }}>
      <GreenCheck
        size="small"
        onClick={(e) => e.stopPropagation()}
        style={{
          padding: 0,
          margin: '0px 4px 0px 0px',
        }}
        {...rest}
      />
      <span style={{ textDecoration: strikethrough ? 'line-through' : 'initial', ...labelStyle }}>
        {label}
      </span>
      {icon}
    </div>
  );
}
