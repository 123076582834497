import React from 'react';
import Grid from '@material-ui/core/Grid';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { AssignmentInd, HighlightOffRounded } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import dayjs from 'dayjs';
import { parseDate } from '../../scripts/utils';

export interface CustomComment {
  id: string;
  creatorUser?: {
    name: string;
    company?: {
      name: string;
    } | null;
  } | null;
  text: string;
  createdOn?: string | null;
  isCopiedFromConsultantReview?: boolean | null;
}

interface CommentProps {
  comment: CustomComment;
  hideIcon?: boolean;
  iconPath?: string;
  deleteComment?: () => void;
}

const useStyles = makeStyles({
  inline: {
    display: 'inline',
  },
  username: {
    fontWeight: 'bold',
    color: '#000', // black
  },
  company: {
    fontStyle: 'italic',
    color: '#949494', // gray
  },
  date: {
    fontSize: 14,
  },
});

export default function Comment(props: CommentProps) {
  const classes = useStyles();
  const { comment, hideIcon, iconPath, deleteComment } = props;

  const name = comment.creatorUser?.name;
  const company = comment.creatorUser?.company
    ? comment.creatorUser.company.name
    : 'Company Not Found';
  const date = comment.createdOn!;
  const text = comment.text;

  return (
    <ListItem alignItems="flex-start">
      {comment.isCopiedFromConsultantReview && (
        <Tooltip arrow placement="top" title="Copied from Consultant Review">
          <AssignmentInd style={{ position: 'absolute', top: '-4px', left: '2px' }} />
        </Tooltip>
      )}
      {!hideIcon && (
        <ListItemAvatar>
          <Avatar alt={name} src={iconPath} />
        </ListItemAvatar>
      )}
      <ListItemText
        primary={
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
            <Grid item style={{ paddingRight: 32 }}>
              <span className={classes.username}>{name},&nbsp;</span>
              <span className={classes.company}>{company}</span>
            </Grid>
            <Grid item>
              <Typography className={classes.date} color="textSecondary" style={{ paddingTop: 2 }}>
                {parseDate(date).format('M/DD/YYYY @ h:mm A')}
              </Typography>
            </Grid>
          </Grid>
        }
        secondary={
          <Typography component="span" className={classes.inline} color="textPrimary">
            {text}
          </Typography>
        }
      />
      {deleteComment && (
        <IconButton
          onClick={deleteComment}
          style={{ position: 'absolute', top: '12px', right: '6px', padding: 0 }}
        >
          <HighlightOffRounded />
        </IconButton>
      )}
    </ListItem>
  );
}
