import React, { CSSProperties, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Add,
  CheckCircleTwoTone,
  CloudUploadTwoTone,
  DoubleArrow,
  HourglassFullTwoTone,
  Info,
  Remove,
} from '@material-ui/icons';
import {
  ExpandedJobStatusType,
  fetchFilesWithStatus,
  FileWithStatus,
  getButtonBackgroundColor,
  getButtonIsDisabled,
  getCurrentParserStep,
  getPieDataByType,
  PARSER_JOB_EXPIRY_TIME_MS,
  pollForStatus,
  publish,
  PublishType,
} from './DCCUtils';
import CircularLoader from '../loader/CircularLoader';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDisableProcoreIntegrationFeatures,
  getProjectState,
} from '../../features/project/selectors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  FileNode,
  IConformingCenterProjectSummary,
  JobType,
  S3UploadResponse,
} from '../../api-client/autogenerated';
import { formatFileSize, waitForFileToBeVerified } from '../../scripts/utils';
import PDFIcon from '../icons/PDF-icon';
import CancelIcon from '@material-ui/icons/Cancel';
import { descendingComparator } from '../document-index/DocumentIndexUtils';
import { useTimer } from 'react-timer-hook';
import dayjs from 'dayjs';
import { useInterval } from '../custom-components/useInterval';
import { getConformingCenterSummaryByProjectId } from '../../models/api/dcc';
import FileUploadDialog from '../dialogs/FileUploadDialog';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import {
  editFileById,
  importProjectSpecificationFileFromDesign,
  uploadProjectSpecificationFile,
} from '../../models/api/filesystem';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';
import { addSnackbar } from '../../features/snackbar/actions';
import { reloadProject } from '../../features/project/actions';
import { reloadDocuments } from '../../features/documents/actions';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
      overflowX: 'hidden',
    },
    paper: {
      color: theme.palette.text.secondary,
      height: '100%',
      boxShadow: '5px 5px 15px rgb(0, 0, 0, .15)',
      width: '100%',
    },
    cardRoot: {
      left: '235px',
      top: '1511px',
    },
    titleStyle: {
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    text: {
      fontSize: 12,
      color: '#727272',
      lineHeight: '14.06px',
      marginBottom: 25,
    },
    formControl: {
      display: 'flex',
      width: '40%',
      marginLeft: 20,
    },
    selectAnchor: {
      position: 'relative',
      top: 15,
      left: 20,
    },
    drawerSelect: {
      height: '32px',
      /* Gray / Gray 50 */
      background: '#F9F9F9',

      /* Gray / Gray 400 Brand dark */
      border: '1px solid #949494',
      'border-radius': '5px',
    },
    menuPaper: {
      maxHeight: 400,
      maxWidth: 300,
      overflowWrap: 'break-word',
      whiteSpace: 'unset',
    },
    buttonAndForm: {
      display: 'flex',
      width: '85%',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 32,
    },
    cardBody: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column',
    },
    statusHidden: {
      display: 'none',
    },
    statusVisible: {
      display: 'inline',
    },
    fileContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      overflowY: 'auto',
      columnGap: 16,
      rowGap: '8px', //cannot use number for this property for some reason
      justifyContent: 'space-evenly',
      width: '85%',
      height: 304,
      paddingTop: 8,
      marginBottom: 32,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: 'black',
    },
    progressBar: {
      backgroundColor: '#128750',
    },
    iconSpin: {
      position: 'absolute',
      top: 0,
      right: 0,
      animation: 'spin, spinback',
      animationDelay: '.5s, .5s',
      animationDuration: '1s',
      animationIterationCount: 'infinite',
    },
  }),
);

type Props = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  summary: IConformingCenterProjectSummary | undefined;
  fetchSummary: () => Promise<IConformingCenterProjectSummary>;
  isDocumentLogPage?: boolean;
};

export default function PublishingCenterSpecifications(props: Props) {
  const { setIsLoading, summary, fetchSummary, isDocumentLogPage } = props;
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const currentProject = useSelector(getProjectState);

  const [open, setOpen] = useState(true);

  const [specificationsFiles, setSpecificationsFiles] = useState<FileWithStatus[]>([]);

  const [specificationsDialogOpen, setSpecificationsDialogOpen] = useState(false);
  const [inputSpecificationsFile, setInputSpecificationsFile] = useState<File | FileNode>();

  const [isPublishing, setIsPublishing] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [specPieData, setSpecPieData] = useState<number[]>([0, 0, 0]);

  const currentSpecificationFile: FileWithStatus | undefined = specificationsFiles.sort((a, b) =>
    descendingComparator(a.file, b.file, 'createdOn'),
  )[0];

  const {
    minutes: specTimerMinutes,
    seconds: specTimerSeconds,
    totalSeconds: specTimerTotalSeconds,
    restart: specTimerRestart,
  } = useTimer({
    autoStart: false,
    expiryTimestamp: currentSpecificationFile?.file.createdOn
      ? dayjs(currentSpecificationFile?.file.createdOn)
          .add(PARSER_JOB_EXPIRY_TIME_MS, 'ms')
          .toDate()
      : dayjs().add(PARSER_JOB_EXPIRY_TIME_MS, 'ms').toDate(),
  });

  useEffect(() => {
    if (currentSpecificationFile?.file.createdOn)
      specTimerRestart(
        dayjs(currentSpecificationFile.file.createdOn)
          .add(PARSER_JOB_EXPIRY_TIME_MS, 'ms')
          .toDate(),
      );
  }, [currentSpecificationFile]);

  useInterval(() => {
    pollForStatus(
      specificationsFiles,
      setSpecificationsFiles,
      JobType.SpecificationsParser,
      fetchSummary,
    );
  }, 10000);

  useEffect(() => {
    if (!summary) return;
    setSpecPieData(getPieDataByType(summary, PublishType.Specifications));
  }, [summary]);

  useEffect(() => {
    if (currentProject && summary) {
      fetchFilesWithStatus(currentProject, summary, PublishType.Specifications).then(
        setSpecificationsFiles,
      );
    }
  }, [currentProject, summary]);

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const addSpecFile = (newFile: File | FileNode) => {
    setInputSpecificationsFile(newFile);
  };

  const removeSpecFile = () => {
    setInputSpecificationsFile(undefined);
  };

  const handleSpecificationsUpload = async () => {
    if (inputSpecificationsFile) {
      try {
        let response: S3UploadResponse;
        let verified = false;
        dispatch(blockNavigation());
        setUploadProgress(0);

        if (currentSpecificationFile) {
          await editFileById(currentSpecificationFile.file.id, {
            isHiddenInPublishingCenter: true,
          });
        }

        if ((inputSpecificationsFile as FileNode).relativeKey !== undefined) {
          response = (
            await importProjectSpecificationFileFromDesign(currentProject!.id, {
              ownerId: (inputSpecificationsFile as FileNode).ownerId,
              fullKey: (inputSpecificationsFile as FileNode).fullKey,
              fullFileName: (inputSpecificationsFile as FileNode).relativeKey!,
              projectId: (inputSpecificationsFile as FileNode).projectId,
            })
          ).s3Response;
          setUploadProgress(100);
        } else {
          response = (
            await uploadProjectSpecificationFile(
              currentProject!.id,
              {
                fullFileName: (inputSpecificationsFile as File).name,
                useMultiPartUpload: (inputSpecificationsFile as File).size > MULTI_PART_FILE_SIZE,
              },
              inputSpecificationsFile as File,
              handleUploadProgress,
              true,
            )
          ).s3Response;
        }

        if (response.file) {
          verified = await waitForFileToBeVerified(response.file);
        }

        if (!verified) {
          dispatch(
            addSnackbar({
              id: Date.now(),
              message: `Your specifications file failed to upload. Please try again.`,
              severity: 'error',
            }),
          );
          return;
        }

        dispatch(
          addSnackbar({
            id: Date.now(),
            message: `Successfully uploaded specification file!`,
            severity: 'success',
          }),
        );
      } finally {
        dispatch(allowNavigation());
        setInputSpecificationsFile(undefined);
        setSpecificationsDialogOpen(false);
        setUploadProgress(0);
        dispatch(reloadProject());
      }
    }
  };

  const getSpecificationsProgressContent = () => {
    let currentFile: FileWithStatus | null = currentSpecificationFile;
    // currentFile = {
    //   file: {
    //     id: '',
    //     lastUpdatedByUserId: '',
    //     category: FileCategoryType.Specifications,
    //     creatorUserId: '',
    //     isPublic: false,
    //     name: 'file.pdf',
    //   },
    //   status: ExpandedJobStatusType.CreatingJob,
    //   percentComplete: 0,
    // };
    if (!currentFile) return null;
    const jobFailed = [
      ExpandedJobStatusType.Canceled,
      ExpandedJobStatusType.JobCreationFailed,
    ].includes(currentFile.status);
    const width = 117;
    const height = 143;
    const completeFill = jobFailed ? '#ED3F26' : '#128750';
    const labelStyle: CSSProperties = {
      position: 'absolute',
      bottom: 17,
      textAlign: 'center',
      lineHeight: '16px',
      fontWeight: 600,
    };
    const iconStyle: CSSProperties = {
      position: 'absolute',
      top: 0,
      right: 0,
      fontSize: '1.6rem',
    };
    const step1Color = [
      ExpandedJobStatusType.Initiated,
      ExpandedJobStatusType.Complete,
      ExpandedJobStatusType.Canceled,
      ExpandedJobStatusType.JobCreationFailed,
    ].includes(currentFile.status)
      ? completeFill
      : '#0947B9';
    const step2Color =
      currentFile.status === ExpandedJobStatusType.Initiated
        ? '#0947B9'
        : [ExpandedJobStatusType.CreatingJob, ExpandedJobStatusType.Pending].includes(
            currentFile.status,
          )
        ? undefined
        : completeFill;
    return (
      <div
        style={{
          width: '85%',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 16,
        }}
      >
        <Typography style={{ fontSize: 18, fontWeight: 600, marginBottom: 16 }}>
          {currentFile.file.name}{' '}
          {currentFile.file.size ? `(${formatFileSize(currentFile.file.size)})` : ''}
        </Typography>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: 16,
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {[ExpandedJobStatusType.CreatingJob, ExpandedJobStatusType.Pending].includes(
              currentFile.status,
            ) || jobFailed ? (
              <CloudUploadTwoTone
                style={{
                  ...iconStyle,
                  color: step1Color,
                }}
              />
            ) : (
              <CheckCircleTwoTone style={{ ...iconStyle, color: '#128750' }} />
            )}
            <span
              style={{
                ...labelStyle,
                color: step1Color,
              }}
            >
              Loading
            </span>
            <PDFIcon width={width} height={height} fill={step1Color} />
          </div>
          <DoubleArrow fontSize="large" />
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {currentFile.status !== ExpandedJobStatusType.Complete || jobFailed ? (
              <HourglassFullTwoTone
                className={
                  [ExpandedJobStatusType.Initiated].includes(currentFile.status)
                    ? classes.iconSpin
                    : undefined
                }
                style={{
                  ...iconStyle,
                  color: step2Color,
                }}
              />
            ) : (
              <CheckCircleTwoTone style={{ ...iconStyle, color: '#128750' }} />
            )}
            <span
              style={{
                ...labelStyle,
                color: step2Color,
              }}
            >
              Parsing
            </span>
            <PDFIcon width={width} height={height} fill={step2Color} />
          </div>
          <DoubleArrow fontSize="large" />
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {[ExpandedJobStatusType.Canceled, ExpandedJobStatusType.JobCreationFailed].includes(
              currentFile.status,
            ) ? (
              <CancelIcon style={{ ...iconStyle, color: '#FF5D45' }} />
            ) : (
              <CheckCircleTwoTone
                style={{
                  ...iconStyle,
                  color:
                    currentFile.status === ExpandedJobStatusType.Complete ? '#2BB073' : undefined,
                }}
              />
            )}
            <span
              style={{
                ...labelStyle,
                color: [
                  ExpandedJobStatusType.Complete,
                  ExpandedJobStatusType.Canceled,
                  ExpandedJobStatusType.JobCreationFailed,
                ].includes(currentFile.status)
                  ? completeFill
                  : undefined,
              }}
            >
              {jobFailed ? 'Failed' : 'Success'}
            </span>
            <PDFIcon
              width={width}
              height={height}
              fill={
                [
                  ExpandedJobStatusType.Complete,
                  ExpandedJobStatusType.Canceled,
                  ExpandedJobStatusType.JobCreationFailed,
                ].includes(currentFile.status)
                  ? completeFill
                  : undefined
              }
            />
          </div>
        </div>
        {currentFile.percentComplete !== 100 &&
        ![
          ExpandedJobStatusType.Complete,
          ExpandedJobStatusType.Canceled,
          ExpandedJobStatusType.JobCreationFailed,
        ].includes(currentFile.status) ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: 16 }}>
              <LinearProgress
                variant="determinate"
                value={
                  currentFile.percentComplete ??
                  (1 - specTimerTotalSeconds / (PARSER_JOB_EXPIRY_TIME_MS / 1000)) * 100
                }
                classes={{ bar: classes.progressBar }}
                style={{
                  width: '100%',
                  height: 32,
                  borderRadius: 8,
                  backgroundColor: '#a4d1bc',
                }}
              />
              <Typography
                style={{ fontSize: 16, fontWeight: 600, marginLeft: 8 }}
              >{`${specTimerMinutes.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })}:${specTimerSeconds.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })}`}</Typography>
            </div>
            <Typography
              align="center"
              style={{ width: '100%', fontSize: 16, fontWeight: 600, marginTop: 4 }}
            >
              Step {getCurrentParserStep(currentFile)}/5 Completed
            </Typography>
          </>
        ) : null}
        {jobFailed ? (
          <Typography
            align="center"
            style={{ marginTop: 16, fontSize: 18, lineHeight: '21px', fontWeight: 600 }}
          >
            Something went wrong while parsing your file. If the problem persists, please email
            support@centerline.co.
          </Typography>
        ) : null}
      </div>
    );
  };

  const publishAll = async () => {
    await publish(
      summary,
      currentProject,
      PublishType.Specifications,
      setIsPublishing,
      fetchSummary,
      dispatch,
      {
        publishGoodMatches: true,
        publishPoorMatches: true,
        publishExistingMatches: true,
      },
    );
    if (isDocumentLogPage) {
      dispatch(reloadDocuments());
    }
  };

  const specsButtons = [
    {
      name: 'Review Before Publishing',
      onClick: () =>
        history.push(`/main/projects/${currentProject!.id}/pub-center/conform-specifications`),
      color: '#0947B9',
    },
    {
      name: `Publish All`,
      onClick: publishAll,
      color: '#128750',
      pieDataIndex: undefined,
    },
  ];

  const content = (
    <CardContent className={classes.cardBody}>
      <Typography className={classes.text}>
        Centerline automatically builds a specifications log with individual entries for each
        section that are named and numbered according to the CSI Masterformat conventions, and can
        automatically conform new versions of those sections as they are issued. Centerline also
        analyzes specification sections to identify Submittal requirements and can create a
        Submittal Log with placeholders for each item. Use the “Upload Specification Document”
        button below to begin.
      </Typography>
      <div className={classes.buttonAndForm} style={{ marginBottom: 16 }}>
        <Tooltip
          arrow
          placement="top"
          title="Upload the Project Manual as a single multipage PDF. Combine multi-volume Project Manuals into a single file before uploading."
          style={{ marginRight: 8 }}
        >
          <Info />
        </Tooltip>
        <Button
          color="primary"
          variant="contained"
          disabled={
            !!currentSpecificationFile &&
            ![ExpandedJobStatusType.JobCreationFailed, ExpandedJobStatusType.Canceled].includes(
              currentSpecificationFile.status,
            )
          }
          onClick={() => setSpecificationsDialogOpen(true)}
          style={{ width: '100%', padding: 0, lineHeight: 1 }}
        >
          Upload Project Manual
        </Button>
      </div>
      {getSpecificationsProgressContent()}
      {!isPublishing ? (
        <>
          {specsButtons.map((item) => {
            const disabled = getButtonIsDisabled(
              specificationsFiles,
              specPieData,
              item.pieDataIndex,
            );
            const isProcessing = specificationsFiles.some(
              (f) =>
                f.status === ExpandedJobStatusType.Pending ||
                f.status === ExpandedJobStatusType.Initiated ||
                f.status === ExpandedJobStatusType.CreatingJob,
            );
            return (
              <Button
                key={item.name}
                disabled={disabled}
                color="inherit"
                variant="contained"
                onClick={item.onClick}
                style={{
                  width: '85%',
                  marginBottom: 15,
                  lineHeight: 1,
                  backgroundColor: getButtonBackgroundColor(item.color, disabled),
                  color: disabled ? '#A3A3A3' : 'white',
                }}
              >
                {!isProcessing ? item.name : 'Processing...'}
              </Button>
            );
          })}
        </>
      ) : (
        <CircularLoader />
      )}
    </CardContent>
  );

  return (
    <>
      <Grid container item xs={12} lg={6}>
        <Grid container item xs={12}>
          <Paper className={classes.paper}>
            <Card style={{ height: '100%' }}>
              <CardContent className={classes.titleStyle}>
                <h2 style={{ textAlign: 'left', color: '#FFFFFF', margin: 0 }}>
                  {isDocumentLogPage ? 'Publish & Conform' : 'Specifications'}
                </h2>
                {isDocumentLogPage ? (
                  <IconButton onClick={() => setOpen((prev) => !prev)} style={{ padding: 0 }}>
                    {open ? <Remove htmlColor="#FFF" /> : <Add htmlColor="#FFF" />}
                  </IconButton>
                ) : null}
              </CardContent>
              {isDocumentLogPage ? <Collapse in={open}>{content}</Collapse> : content}
            </Card>
          </Paper>
        </Grid>
      </Grid>
      <FileUploadDialog
        open={specificationsDialogOpen}
        handleClose={() => setSpecificationsDialogOpen(false)}
        title="Upload Specification"
        addFile={addSpecFile}
        removeFile={removeSpecFile}
        canSubmit={inputSpecificationsFile !== undefined}
        file={inputSpecificationsFile}
        allowMultiple={false}
        disableComments
        buttonType="submit"
        handleSubmit={handleSpecificationsUpload}
        uploadProgress={uploadProgress}
      />
    </>
  );
}
