import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, InputLabel, MenuItem, Select, Theme } from '@material-ui/core';
import { BootstrapInput } from '../custom-components/CustomButtons';
import { NotificationSettingType } from '../../api-client/autogenerated';
import { editDocumentSettings } from '../../models/api/documents';
import { useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import { getDocumentState } from '../../features/document/selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 180,
    },
    formControl: {
      paddingBottom: 3,
    },
    buttonContainer: {
      display: 'flex',
      padding: '16px 12px 0px 12px',
    },
  }),
);

export default function NotificationsForm() {
  const classes = useStyles();
  const [setting, setSetting] = useState<NotificationSettingType>();
  const user = useSelector(getUserState);
  const document = useSelector(getDocumentState)!;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    editDocumentSettings(user.id, document.id, setting!);
  };

  return (
    <div className={classes.root}>
      <FormControl variant="filled" fullWidth className={classes.formControl}>
        <InputLabel id="addToPackage">Notification Setting</InputLabel>
        <Select
          value={setting}
          onChange={(event) => setSetting(event.target.value as NotificationSettingType)}
          input={<BootstrapInput />}
        >
          <MenuItem value={NotificationSettingType.AllAsIssued}>As Issued</MenuItem>
          <MenuItem value={NotificationSettingType.AllWeekly}>Weekly</MenuItem>
          <MenuItem value={NotificationSettingType.AllDaily}>Daily</MenuItem>
          <MenuItem value={NotificationSettingType.None}>Never</MenuItem>
        </Select>
      </FormControl>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          disabled={!setting}
          onClick={handleSubmit}
          style={{ height: '32px', width: '120px', marginLeft: '10px' }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
