import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import React from 'react';

type Props = {
  open: boolean;
  submit: (willPublish: boolean) => void;
};

export default function PublishFileDialog(props: Props) {
  const { open, submit } = props;

  return (
    <Dialog open={open}>
      <DialogContent>
        <Typography>
          Would you like to publish this file in the public-facing Bidding portal?
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-evenly' }}>
        <Button variant="contained" color="primary" onClick={() => submit(false)}>
          No
        </Button>
        <Button variant="contained" color="primary" onClick={() => submit(true)}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
