import React from 'react';
import { ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addSpacing } from '../document-index/DocumentIndexUtils';
import { getDocumentsType, getSelectedSection } from '../../features/documents/selectors';
import { DocumentTemplateType } from '../../api-client/autogenerated';
import { documentTypeToUrl, openInNewTab } from '../../scripts/utils';
import { setSelectedSection } from '../../features/documents/actions';
import { setDocument } from '../../features/document/actions';
import { getProjectState } from '../../features/project/selectors';

interface DocumentListItemProps {
  section: { id: string; number: string; title?: string; fileId?: string };
}

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '25px',
    textTransform: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '216px',
    color: '#CCDEFF',

    marginBottom: 0,
    marginTop: 0,
  },
  inset: {
    paddingLeft: '25px',
  },
  button: {
    '&:hover': {
      backgroundColor: '#00308C',
    },
    '&:hover *': {
      color: '#FFFFFF',
    },
    '&$selected, &$selected:hover': {
      backgroundColor: '#00308C',
    },
  },
  selected: {
    background: '#002366',
    '& *': {
      color: '#FFFFFF',
    },
  },
  rectangleActive: {
    display: 'flex',
    flexShrink: 0,
    width: '4px',
    height: '25px',
    left: 0,
    background: '#FF9C17',
  },
  rectangleDisabled: {
    display: 'flex',
    flexShrink: 0,
    alignSelf: 'start',
    width: '4px',
    height: '25px',
    background: 'transparent',
  },
}));

export default function DocumentListItem(props: DocumentListItemProps) {
  const classes = useStyles();

  const { section } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const currentProject = useSelector(getProjectState)!;
  const documentType = useSelector(getDocumentsType)!;
  const selectedSection = useSelector(getSelectedSection);

  const handleSectionClick = () => {
    if (documentType === DocumentTemplateType.Specifications) {
      history.push(
        `/main/projects/${currentProject.id}/documents/${
          documentTypeToUrl[DocumentTemplateType.Specifications]
        }/${section.id}`,
      );
    } else {
      if (selectedSection?.number === section.number) dispatch(setSelectedSection(null));
      else dispatch(setSelectedSection({ number: section.number, title: section.title! }));
      dispatch(setDocument(null));
      history.push(
        `/main/projects/${currentProject.id}/documents/${documentTypeToUrl[documentType]}`,
      );
    }
  };

  return (
    <Tooltip
      title={section.title ? section.title.trim() : ''}
      placement="left"
      arrow
      PopperProps={{
        modifiers: {
          offset: {
            enabled: true,
            offset: '0px, -20px',
          },
        },
      }}
    >
      <ListItem
        dense
        button
        disableGutters
        selected={section.number === selectedSection?.number}
        style={{ padding: 0, margin: 0 }}
        classes={{
          button: classes.button,
          selected: classes.selected,
        }}
        onClick={handleSectionClick}
      >
        {documentType === DocumentTemplateType.Submittals && (
          <div
            className={
              section.number === selectedSection?.number
                ? classes.rectangleActive
                : classes.rectangleDisabled
            }
          />
        )}
        <ListItemText
          inset
          disableTypography
          primary={`${addSpacing(section.number)} ${section.title ? section.title! : ''}`}
          classes={{ root: classes.root, inset: classes.inset }}
        />
      </ListItem>
    </Tooltip>
  );
}
