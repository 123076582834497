import { RootState } from '../../store/root-state';
import { Security } from './security';
import { ISecurityGroup } from '../../api-client/autogenerated';

export const getSecurityGroupsState = (state: RootState): Security => state.security.security;

export const getCurrentSecurityGroup = (state: RootState): ISecurityGroup | null | undefined =>
  state.security.security.find(
    (securityGroup) =>
      securityGroup.id ===
      state.project.project?.projectUserList?.find(
        (projectUser) => projectUser.userId === state.user.user.id,
      )?.securityGroupId,
  );

export const getSubscriberSecurityGroupState = (state: RootState) =>
  state.security.subscriberSecurity;
