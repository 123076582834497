import React, { useState } from 'react';
import {
  ClickAwayListener,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropUp } from '@material-ui/icons';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

interface DrawerListItemProps {
  title: string;
  listIndex: number;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => void;
  selectedIndex: number;
  icon: JSX.Element;
  drawerOpen: boolean;
  menuOpen?: boolean;
  nestedOnClick?: () => void;
  handleDocumentType?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: string) => void;
  handlePageChange?: () => void;
  selectedFunc?: () => boolean;
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  button: {
    height: '40px',
    '&:hover': {
      backgroundColor: '#0947B9',
    },
    '&:hover *': {
      fontWeight: 700,
      color: '#FFFFFF',
      fill: '#FFFFFF',
    },
    '&$selected, &$selected:hover': {
      backgroundColor: '#002366',
    },
  },
  selected: {
    '&:hover *': {
      fill: '#FF9C17',
    },
    '& *': {
      fontWeight: 700,
      color: '#FFFFFF',
      fill: '#FF9C17',
    },
  },
  drawerArrow: {
    color: '#A6C4FF',
  },
  rectangleActive: {
    display: 'flex',
    flexShrink: 0,
    width: '4px',
    height: '40px',
    background: '#FF9C17',
  },
  rectangleDisabled: {},
  iconRoot: {
    color: 'rgb(115, 163, 255)',
    paddingLeft: '16px',
  },
}));

export default function DrawerListItem(props: DrawerListItemProps) {
  const classes = useStyles();
  const {
    title,
    listIndex,
    onClick,
    selectedIndex,
    icon,
    drawerOpen,
    menuOpen,
    nestedOnClick,
    handleDocumentType,
    handlePageChange,
    selectedFunc,
    disabled = false,
  } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const isSelected = () => {
    if (selectedFunc) {
      return selectedFunc();
    }
    return selectedIndex === listIndex;
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 3000);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const drawerOpenTitle = disabled ? 'Select a project first.' : '';

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        title={drawerOpen ? drawerOpenTitle : title}
        open={tooltipOpen}
        onClose={handleTooltipClose}
        disableFocusListener={drawerOpen ? disabled : false}
        disableHoverListener={drawerOpen ? disabled : false}
        disableTouchListener={drawerOpen ? disabled : false}
        onMouseOverCapture={drawerOpen ? undefined : handleTooltipOpen}
        placement="left"
        arrow
        PopperProps={{
          modifiers: {
            offset: {
              enabled: true,
              offset: '0px, -17px',
            },
          },
        }}
      >
        <ListItem
          button
          disableGutters
          selected={isSelected()}
          onClick={(event) => {
            if (!disabled) {
              onClick(event, listIndex);
              if (nestedOnClick !== undefined) nestedOnClick();
              if (handleDocumentType !== undefined) handleDocumentType(event, title);
              if (handlePageChange !== undefined) handlePageChange();
            } else {
              handleTooltipOpen();
            }
          }}
          key={title}
          classes={{
            button: classes.button,
            selected: classes.selected,
          }}
        >
          <div className={isSelected() ? classes.rectangleActive : classes.rectangleDisabled} />
          <ListItemIcon
            classes={{
              root: classes.iconRoot,
            }}
          >
            {' '}
            {icon}{' '}
          </ListItemIcon>
          {drawerOpen && <ListItemText primary={title} />}
          {menuOpen !== undefined &&
            (menuOpen ? (
              <ArrowDropUp className={classes.drawerArrow} />
            ) : (
              <ArrowDropDown className={classes.drawerArrow} />
            ))}
        </ListItem>
      </Tooltip>
    </ClickAwayListener>
  );
}
