import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
      justifyContent: 'center',
      alignItems: 'center',
    },
    root2: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
  }),
);

type Props = {
  fullWidth?: boolean;
  size?: number | string;
  variant?: 'determinate' | 'indeterminate' | 'static';
  value?: number;
  loaderStyle?: React.CSSProperties;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function CircularLoader(props: Props) {
  const classes = useStyles();
  const { fullWidth, size, variant = 'indeterminate', value, loaderStyle, ...rest } = props;

  return (
    <div className={fullWidth ? classes.root2 : classes.root} {...rest}>
      <CircularProgress
        variant={variant}
        value={value}
        color="primary"
        size={size}
        style={loaderStyle}
      />
    </div>
  );
}
