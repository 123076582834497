import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Card, Chip, Collapse, Divider, Tooltip, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Add, ChatBubbleOutline, Close, Help, Publish, Remove } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import CommentsDialog from '../dialogs/CommentsDialog';
import {
  DocumentTemplateType,
  FileCategoryType,
  IComment,
  IFile,
  INotification,
  INumberedDocumentView,
  NotificationType,
  RespondToReviewPropsResponseEnum,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import ConsultantFileUploadDialog from '../dialogs/ConsultantFileUploadDialog';
import {
  documentTypeToUrl,
  downloadFileById,
  formatDate,
  getSubmittalTitle,
  isPublicPage,
  openInNewTab,
  parseDate,
  setPublicPageRedirect,
} from '../../scripts/utils';
import { getUserState } from '../../features/user/selectors';
import { getAdditionalReviewDocuments, getDocumentState } from '../../features/document/selectors';
import { descendingComparator } from '../document-index/DocumentIndexUtils';
import {
  getMostRecentConsultantNotificationsByDocumentIds,
  getPermissionByUserIdAndDocumentId,
  respondToReview,
} from '../../models/api/documents';
import { fetchDocument, updateDocument } from '../../features/document/actions';
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from 'react-router-dom';
import { getDocumentsType } from '../../features/documents/selectors';
import RefusePackageDialog from '../submittal-action-center/packages/RefusePackageDialog';
import ConfidentialAdditionalReviewRow, { RowData } from './ConfidentialAdditionalReviewRow';
import { getDocPermission } from '../../scripts/store-utils';
import Button from '@material-ui/core/Button';
import CompileConsultantReviewDialog from '../dialogs/CompileConsultantReviewDialog';
import dayjs from 'dayjs';
import BluebeamStudioLink from '../custom-components/BluebeamStudioLink';
import FileUploadDialog from '../dialogs/FileUploadDialog';
import BluebeamStudioHelpDialog from '../dialogs/BluebeamStudioHelpDialog';
import { deleteFile, uploadGeneralDocumentFile } from '../../models/api/filesystem';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import { addSnackbar } from '../../features/snackbar/actions';
import PDFIcon from '../icons/PDF-icon';
import EyeIcon from '@material-ui/icons/Visibility';
import DownloadIcon from '../icons/Download-icon';

const getRow = (
  document: INumberedDocumentView,
  packageRowParentDocument?: INumberedDocumentView,
): RowData => {
  const assignedUser = document.additionalReviewRecommendedBy;
  const reviewer = assignedUser?.name || 'User Not Found';
  const company = assignedUser?.company?.name || 'Company Not Found';
  const file = document.files?.slice().sort((a, b) => descendingComparator(a, b, 'createdOn'))[0];
  const fileName = file?.name || 'File Not Found';
  const fileDate = file?.createdOn ? parseDate(file.createdOn).valueOf() : dayjs().valueOf();
  const fileCreatorUserId = file?.creatorUserId || '';
  return {
    additionalReviewRecommendedById: document.additionalReviewRecommendedById || '',
    assignedDate: document.createdOn ? formatDate(document.createdOn!, true) : 'N/A',
    comments: document.comments!,
    company,
    dueDate: document.dueDate ? formatDate(document.dueDate!, true) : 'N/A',
    fileDate,
    fileId: file?.id || '',
    fileName,
    fileCreatorUserId,
    isVerified: file?.isVerified,
    id: document.id,
    packageItems: document.reviewChildDocuments?.map((doc) =>
      getRow(doc, doc.additionalReviewForDocument || undefined),
    ),
    response: document.recommendedAction!,
    returnedDate: document.reviewCompleteOn ? formatDate(document.reviewCompleteOn!, true) : 'N/A',
    reviewer,
    reviewForPackage: !!document.additionalReviewForPackageDocumentId,
    status: document.workflowStatus!,
    title: packageRowParentDocument ? getSubmittalTitle(packageRowParentDocument) : undefined,
  };
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'inline-flex',
      flexDirection: 'column',
      flexBasis: 'auto',
      zIndex: -1,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: 'auto',
      width: 'auto',
    },
    paper: {
      width: '100%',
      marginBottom: '16px',
    },
    table: {
      width: '100%',
      minWidth: 1100,

      paddingTop: 4,
    },
    margin: {
      margin: 5,
    },
  }),
);

type Props = {
  title?: string;
  isPackage?: boolean;
};

type selectedActionForDocument = {
  documentId: string;
  action: string | undefined;
};

export default function ConfidentialAdditionalReview(props: Props) {
  const classes = useStyles();
  const { title, isPackage } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUserState);
  const reviewDocuments = useSelector(getAdditionalReviewDocuments);
  const parentDocument = useSelector(getDocumentState);
  const docType = useSelector(getDocumentsType);
  const [collapseOpen, setCollapseOpen] = useState(true);
  const [consultantFileUploadDialog, setConsultantFileUploadDialog] = useState(false);
  const [permissions, setPermissions] = useState<{ id: string; disabled: boolean }[]>([]);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [commentDialogProps, setCommentDialogProps] = useState<{
    id: string;
    comments: IComment[];
    returnForAdditionalReview: boolean;
    commentType: 'single' | 'shared';
  }>({ id: '', comments: [], returnForAdditionalReview: false, commentType: 'single' });
  const [additionalReview, setAdditionalReview] = useState<INumberedDocumentView>();
  const [selectedActions, setSelectedActions] = useState<selectedActionForDocument[] | undefined>(
    undefined,
  );

  const [compileDialogOpen, setCompileDialogOpen] = useState(false);

  const [studioLogUploadDialogOpen, setStudioLogUploadDialogOpen] = useState(false);
  const [studioLogFile, setStudioLogFile] = useState<File>();
  const [studioLogHelpDialogOpen, setStudioLogHelpDialogOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const hasOrangeHighlight = notifications?.some(
    (n) => n.format === NotificationType.DocumentConsultantDownloaded,
  );

  const hasBlueHighlight = notifications?.some(
    (n) => n.format === NotificationType.DocumentConsultantBluebeamStarted,
  );

  const permission = getDocPermission();

  const acceptedReviews = reviewDocuments.filter(
    (doc) => doc.workflowStatus === WorkflowStatusType.Accepted,
  );

  const acceptedReviewFiles = acceptedReviews
    .map(({ files }) => files?.slice().sort((a, b) => descendingComparator(a, b, 'updatedOn'))[0])
    .filter((file) => file) as IFile[];

  const parentIsPackage =
    parentDocument?.documentTemplate?.name === DocumentTemplateType.SubmittalPackages ||
    parentDocument?.documentTemplate?.name === DocumentTemplateType.CloseoutSubmittalPackages;

  const parentIsComplete =
    parentDocument?.workflowStatus &&
    [
      WorkflowStatusType.Completed,
      WorkflowStatusType.ReviewComplete,
      WorkflowStatusType.Accepted,
      WorkflowStatusType.Resolved,
      WorkflowStatusType.Resubmitted,
    ].includes(parentDocument.workflowStatus);

  const parentHasReviewFile =
    reviewDocuments.some(
      (r) => r.workflowStatus === WorkflowStatusType.Resolved && !!r.files?.length,
    ) ||
    parentDocument?.files?.some((f) => f.category === FileCategoryType.DocumentArchitectApproved);

  const shouldHideResponse =
    docType === DocumentTemplateType.RequestsForInformation ||
    docType === DocumentTemplateType.DesignPackages;

  const bluebeamStudioLogFile = parentDocument?.files?.find(
    (f) => f.category === FileCategoryType.DocumentBluebeamStudioLog,
  );

  const getAction = (status: string) => {
    switch (status) {
      case WorkflowStatusType.Resubmitted:
        return RespondToReviewPropsResponseEnum.Resubmitted;

      case WorkflowStatusType.Accepted:
      case WorkflowStatusType.Resolved:
        return RespondToReviewPropsResponseEnum.Accepted;

      case WorkflowStatusType.Canceled:
        return RespondToReviewPropsResponseEnum.Canceled;

      default:
        return undefined;
    }
  };

  const getRowActionValue = (id: string) => {
    const d = selectedActions?.find((x) => x.documentId === id);
    if (d && d.action) {
      return d.action;
    }
    return 'undefined';
  };

  const [commentDialogOpen, setCommentDialogOpen] = useState(false);

  const handleCommentDialogOpen = (
    id: string,
    comments: IComment[],
    returnForAdditionalReview = false,
  ) => {
    setCommentDialogProps({ id, comments, returnForAdditionalReview, commentType: 'single' });
    setCommentDialogOpen(true);
  };

  const handleCommentDialogOpenShared = (
    id: string,
    comments: IComment[],
    returnForAdditionalReview = false,
  ) => {
    setCommentDialogProps({ id, comments, returnForAdditionalReview, commentType: 'shared' });
    setCommentDialogOpen(true);
  };

  const handleCommentDialogClose = () => {
    setCommentDialogOpen(false);
  };

  const handleAcceptOrReturn = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    documentId: string,
  ) => {
    if (!parentDocument?.id) return;
    if (selectedActions) {
      const actions = [...selectedActions];
      actions.find((x) => x!.documentId === documentId)!.action = event.target.value as string;
      setSelectedActions(actions);
    }
    if (event.target.value === RespondToReviewPropsResponseEnum.Resubmitted) {
      const d = reviewDocuments.find((x) => x.id === documentId);
      if (d) handleCommentDialogOpen(d.id, d.comments || [], true);
    } else {
      await respondToReview(documentId, {
        response: event.target.value as RespondToReviewPropsResponseEnum,
      });
      dispatch(fetchDocument(parentDocument.id));
    }
  };

  useEffect(() => {
    if (!commentDialogOpen) {
      const actions = reviewDocuments.map((x) => {
        return { documentId: x.id, action: getAction(x.workflowStatus || '') };
      });
      setSelectedActions(actions);
    }
  }, [commentDialogOpen, reviewDocuments, parentDocument]);

  const getPermissions = async () => {
    const newPermissions = await Promise.all(
      reviewDocuments.map(async ({ id }) => {
        const { isAssignableToDocument } = await getPermissionByUserIdAndDocumentId(user.id, id);
        return { id, disabled: !isAssignableToDocument };
      }),
    );
    setPermissions(newPermissions);
  };

  const getNotifications = async () => {
    const notifications = await getMostRecentConsultantNotificationsByDocumentIds(
      reviewDocuments.map(({ id }) => id),
    );
    setNotifications(notifications);
  };

  const hasArchitectPermission = () => {
    return permission && permission >= 3;
  };

  const getReviewActionDisabled = (row: RowData) => {
    return (
      permissions.find((p) => p.id === row.id)?.disabled || parentIsComplete || row.reviewForPackage
    );
  };

  const canReviseSubmission = (row: RowData) => {
    const reviewDocument = reviewDocuments.find((doc) => doc.id === row.id);
    if (!reviewDocument) return false;
    return !!(
      reviewDocument.additionalReviewRecommendedById === user.id ||
      reviewDocument.additionalReviewRecommendedBy?.companyId === user.companyId ||
      user.isSiteAdmin
    );
  };

  const handleReviewClick = (id: string) => {
    if (isPublicPage()) {
      setPublicPageRedirect(
        `projects/${parentDocument!.projectId!}/documents/${
          documentTypeToUrl[docType!]
        }/${parentDocument!.id!}`,
      );
      history.push('/main/login');
    } else {
      const reviewDoc = reviewDocuments.find((doc) => doc.id === id);
      if (
        notifications.some(
          (n) =>
            [
              NotificationType.DocumentConsultantDownloaded,
              NotificationType.DocumentConsultantBluebeamStarted,
            ].includes(n.format) && n.documentId !== reviewDoc?.id,
        )
      )
        alert(
          'The consultants highlighted in orange or blue below have downloaded a version of this document for offline review. All consultant markups performed in Bluebeam use a shared version of the file, but any markups added during offline review are stored in a separate version.',
        );
      setAdditionalReview(reviewDoc);
      setConsultantFileUploadDialog(true);
    }
  };

  const getArchitectActionDisabled = (row: RowData) => {
    return (
      !!permissions?.find((p) => p.id === row.id)?.disabled ||
      isPublicPage() ||
      parentIsComplete ||
      parentHasReviewFile ||
      row.reviewForPackage ||
      row.status === WorkflowStatusType.Resubmitted ||
      row.status === WorkflowStatusType.Canceled ||
      !hasArchitectPermission()
    );
  };

  const canUploadStudioLog = () => {
    return (
      parentDocument?.workflowStatus &&
      [WorkflowStatusType.ReviewComplete, WorkflowStatusType.Resolved].includes(
        parentDocument.workflowStatus,
      ) &&
      permission &&
      permission >= 3
    );
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleUploadStudioLog = async () => {
    if (!parentDocument || !studioLogFile) return;

    try {
      dispatch(blockNavigation());
      const { file } = await uploadGeneralDocumentFile(
        parentDocument.id,
        {
          useMultiPartUpload: studioLogFile.size > MULTI_PART_FILE_SIZE,
          fullFileName: studioLogFile.name,
          fileType: FileCategoryType.DocumentBluebeamStudioLog,
        },
        studioLogFile,
        handleUploadProgress,
      );
      dispatch(allowNavigation());
      if (file) {
        dispatch(
          updateDocument({ ...parentDocument, files: [...(parentDocument.files || []), file] }),
        );
      }
      setStudioLogUploadDialogOpen(false);
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Bluebeam Studio Activity Log successfully uploaded',
          severity: 'success',
        }),
      );
    } finally {
      dispatch(allowNavigation());
    }
  };

  useEffect(() => {
    if (reviewDocuments.length > 0) {
      getNotifications();
      if (!isPublicPage()) getPermissions();
    }
  }, [reviewDocuments]);

  const getCompleteConsultantReviewDisabled = () => {
    if (parentIsComplete || parentHasReviewFile) return true;

    if (docType === DocumentTemplateType.DesignPackages) {
      if (parentDocument?.bluebeamStudioSessionId) {
        return false;
      } else {
        return acceptedReviewFiles.length < 2;
      }
    } else if (parentDocument?.bluebeamStudioSessionId) {
      return acceptedReviews.length === 0;
    } else {
      return acceptedReviewFiles.length < 2;
    }
  };

  const viewLogFile = () => {
    if (!bluebeamStudioLogFile) return;

    openInNewTab(bluebeamStudioLogFile.id);
  };

  const downloadLogFile = () => {
    if (!bluebeamStudioLogFile) return;

    return downloadFileById(bluebeamStudioLogFile.id, bluebeamStudioLogFile.name);
  };

  const deleteLogFile = async () => {
    if (!bluebeamStudioLogFile || !parentDocument) return;

    try {
      await deleteFile(parentDocument.id, bluebeamStudioLogFile.id);
      dispatch(fetchDocument(parentDocument.id));
      dispatch(
        addSnackbar({ id: Date.now(), message: 'File successfully deleted', severity: 'success' }),
      );
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <h2 className="h2">
            {title ? `${title} Confidential Consultant Review` : 'Confidential Consultant Review'}
          </h2>
          <Box display="flex" alignItems="center" style={{ marginLeft: 12, marginBottom: 4 }}>
            <IconButton
              onClick={() =>
                handleCommentDialogOpenShared(
                  parentDocument!.id,
                  parentDocument?.consultantComments!,
                )
              }
              style={{ padding: '0px 8px 0px 0px' }}
            >
              <ChatBubbleOutline />
            </IconButton>

            <Chip
              size="small"
              label={parentDocument?.consultantComments!.length}
              onClick={() =>
                handleCommentDialogOpenShared(
                  parentDocument!.id,
                  parentDocument?.consultantComments!,
                )
              }
              style={{ marginBottom: 2 }}
            />
          </Box>
          <div style={{ display: 'inline-flex', flexGrow: 100 }} />
          <IconButton style={{ padding: 0 }}>
            {collapseOpen ? (
              <Remove htmlColor="#0947B9" onClick={() => setCollapseOpen(false)} />
            ) : (
              <Add htmlColor="#0947B9" onClick={() => setCollapseOpen(true)} />
            )}
          </IconButton>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            rowGap: '16px',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>
              The Confidential Consultant Review Card is not visible to the General Contractor or
              Subcontractors
            </Typography>
            {hasOrangeHighlight ? (
              <Typography>
                The consultants highlighted in{' '}
                <span style={{ color: '#FFBA34', fontWeight: 600 }}>ORANGE</span> below have
                downloaded a version of this document for offline review.
              </Typography>
            ) : null}
            {hasBlueHighlight ? (
              <Typography>
                The consultants highlighted in{' '}
                <span style={{ color: '#66B3FF', fontWeight: 600 }}>BLUE</span> below are reviewing
                this document offline using Bluebeam Revu.
              </Typography>
            ) : null}
            {hasOrangeHighlight || hasBlueHighlight ? (
              <Typography>
                When a Consultant updates an offline review, the most current updated version of the
                file is shared with ALL consultants with open review assignments.
              </Typography>
            ) : null}
          </div>
        </div>
        {parentDocument?.submittalPackageDocument && (
          <Typography style={{ marginTop: '4px' }}>
            This Submittal is a part of{' '}
            <span
              onClick={() => history.push(parentDocument!.submittalPackageDocumentId!)}
              style={{ cursor: 'pointer', color: '#0947B9', fontWeight: 500 }}
            >
              Submittal Package {parentDocument.submittalPackageDocument.referenceNumber}.
            </span>{' '}
            Please go to the package to review & comment
          </Typography>
        )}
      </CardContent>
      {parentDocument?.bluebeamStudioSessionId ? (
        <div
          style={{
            marginLeft: 16,
            marginRight: 16,
            marginBottom: 8,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BluebeamStudioLink studioSessionId={parentDocument.bluebeamStudioSessionId} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
            {canUploadStudioLog() ? (
              <div style={{ display: 'flex', alignItems: 'center', marginRight: 32 }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: '32px' }}
                  disabled={!!bluebeamStudioLogFile}
                  onClick={() => setStudioLogUploadDialogOpen(true)}
                  startIcon={<Publish fill="#2C69D6" />}
                >
                  Upload Bluebeam Studio Activity Log
                </Button>
                <Tooltip arrow placement="top" title="Help">
                  <IconButton
                    onClick={() => setStudioLogHelpDialogOpen(true)}
                    style={{ padding: 0, marginLeft: 8 }}
                  >
                    <Help />
                  </IconButton>
                </Tooltip>
              </div>
            ) : null}
            {bluebeamStudioLogFile ? (
              <>
                <PDFIcon />
                <div style={{ marginLeft: 8 }}>
                  <Typography style={{ fontWeight: 700 }}>Bluebeam Studio Activity Log</Typography>
                  <Typography>{bluebeamStudioLogFile.name}</Typography>
                </div>
                <IconButton onClick={viewLogFile} style={{ padding: '0 0 0 16px' }}>
                  <EyeIcon />
                </IconButton>
                <IconButton onClick={downloadLogFile} style={{ padding: '0 0 0 12px' }}>
                  <DownloadIcon />
                </IconButton>
                <IconButton onClick={deleteLogFile} style={{ padding: '0 0 0 6px' }}>
                  <Close />
                </IconButton>
              </>
            ) : null}
          </div>
        </div>
      ) : null}
      {reviewDocuments.length > 0 ? (
        <Collapse in={collapseOpen}>
          <Divider variant="middle" className={classes.margin} />
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Reviewer</TableCell>
                  <TableCell align="left">File</TableCell>
                  <TableCell align="left">Reviewer Action</TableCell>
                  {!shouldHideResponse ? (
                    <TableCell align={parentIsPackage ? 'center' : 'left'}>Response</TableCell>
                  ) : null}
                  <TableCell align="left">Comments</TableCell>
                  <TableCell align="left">Dates</TableCell>
                  <TableCell>Architect Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hasArchitectPermission() ? (
                  <TableRow>
                    <TableCell colSpan={!shouldHideResponse ? 6 : 5} />
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={getCompleteConsultantReviewDisabled()}
                        onClick={() => setCompileDialogOpen(true)}
                        style={{ whiteSpace: 'nowrap', marginRight: '5%' }}
                      >
                        Finalize Consultant Review
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : null}
                {reviewDocuments
                  .map((row) => getRow(row))
                  .map((row) => (
                    <ConfidentialAdditionalReviewRow
                      key={row.id}
                      row={row}
                      parentIsPackage={parentIsPackage}
                      getArchitectActionDisabled={getArchitectActionDisabled}
                      getReviewerActionDisabled={getReviewActionDisabled}
                      getRowActionValue={getRowActionValue}
                      canReviseSubmission={canReviseSubmission(row)}
                      openComments={handleCommentDialogOpen}
                      acceptOrReturn={handleAcceptOrReturn}
                      reviewOnClick={handleReviewClick}
                      packageRows={row.packageItems}
                      notification={notifications.find((n) => n.documentId === row.id)}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      ) : null}
      {additionalReview && !isPackage && (
        <ConsultantFileUploadDialog
          open={consultantFileUploadDialog}
          title={title ? `${title} Confidential Review` : 'Confidential Review'}
          handleClose={() => setConsultantFileUploadDialog(false)}
          reviewDocument={additionalReview}
          refreshNotifications={getNotifications}
        />
      )}
      {additionalReview && isPackage && (
        <RefusePackageDialog
          isOpen={consultantFileUploadDialog}
          handleClose={() => setConsultantFileUploadDialog(false)}
          packageDocuments={parentDocument?.submittalChildDocuments || []}
          reviewDocument={additionalReview}
          refreshNotifications={getNotifications}
          isConsultantReview
        />
      )}
      {commentDialogProps.id && (
        <CommentsDialog
          dialogOpen={commentDialogOpen}
          handleClose={handleCommentDialogClose}
          comments={commentDialogProps.comments}
          documentId={commentDialogProps.id}
          returnForAdditionalReview={commentDialogProps.returnForAdditionalReview}
          commentType={commentDialogProps.commentType}
        />
      )}
      <CompileConsultantReviewDialog
        open={compileDialogOpen}
        onClose={() => setCompileDialogOpen(false)}
      />
      <FileUploadDialog
        open={studioLogUploadDialogOpen}
        handleClose={() => setStudioLogUploadDialogOpen(false)}
        title="Upload Activity Log"
        file={studioLogFile}
        removeFile={() => setStudioLogFile(undefined)}
        addFile={(file) => setStudioLogFile(file as File)}
        buttonType="submit"
        handleSubmit={handleUploadStudioLog}
        canSubmit={!!studioLogFile}
        uploadProgress={uploadProgress}
        disableComments
        disableDesignUpload
      />
      <BluebeamStudioHelpDialog
        open={studioLogHelpDialogOpen}
        handleClose={() => setStudioLogHelpDialogOpen(false)}
      />
    </Card>
  );
}
