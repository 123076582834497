import { ApiClient } from '../../api-client/api-client';
import { DocumentTemplateType } from '../../api-client/autogenerated';

type UnpublishedDocumentTemplateType =
  | DocumentTemplateType.Submittals
  | DocumentTemplateType.AsBuilt
  | DocumentTemplateType.Specifications
  | DocumentTemplateType.Drawings;

export const getConformingCenterSummaryByProjectId = async (projectId: string) => {
  const response = await ApiClient.getConformingCenterSummaryByProjectId({ projectId });
  return response.data;
};

export const publishDocuments = async (documentIds: string[], allowReplaceExisting = true) => {
  const result = await ApiClient.modifyDocumentsByBatch({
    iModifyDocumentsByBatchRequest: {
      allowReplaceExisting,
      documentIds,
      modificationRequest: { patch: { isDraft: false } },
    },
  });
  return result.data.couldNotPatch;
};

export const generatePlaceholders = async (
  documentIds: string[],
  options: {
    ignoredSpecificationDivisions?: string[];
    ignoredSpecificationSections?: string[];
    ignoredSheetNumbers?: string[];
    shouldDeleteIgnoredDocuments?: boolean;
  } = {},
) => {
  return ApiClient.generatePlaceholdersForDocuments({
    iGeneratePlaceholdersRequest: {
      documentIds,
      ...options,
    },
  }).then((res) => res.data);
};

export const getConformingCenterDocumentsByType = async (
  projectId: string,
  type: UnpublishedDocumentTemplateType,
) => {
  return ApiClient.getConformingCenterDocumentsByProjectId({
    projectId,
    documentTemplateType: type,
  }).then((res) => res.data);
};

export const deleteParsedFile = async (projectId: string, fileId: string) => {
  return ApiClient.deleteParsedFileByProjectIdAndFileId({ projectId, fileId }).then(
    (res) => res.data,
  );
};
