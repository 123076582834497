import {
  createStyles,
  IconButton,
  makeStyles,
  SvgIconProps,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { TreeItem, TreeItemProps } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { BimTreeNode, FileNode, IProject } from '../../api-client/autogenerated';
import { getBIMTreeNode } from '../../models/api/filesystem';
import FolderIcon from '@material-ui/icons/Folder';
import CircularLoader from '../loader/CircularLoader';
import FileOptionsDialog from './FileOptionsDialog';
import { generateUniqueId } from '../../scripts/utils';
import { ascendingComparator } from '../document-index/DocumentIndexUtils';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import { patchProject } from '../../models/api/project';
import { updateProject } from '../../features/project/actions';
import { getUserState } from '../../features/user/selectors';

type StyledTreeItemProps = TreeItemProps & {
  id: string;
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  hasChildren?: boolean;
  fileNode?: FileNode;
  parentNode?: FileNode;
  bimTreeNode?: BimTreeNode;
  BIM?: boolean;
  projectId?: string;
  labelText: string;
  callback: (a: any) => void;
  setActiveFolder: (fileNode: FileNode | undefined) => void;
  setActiveFolderSubfolders: (folders: any) => void;
  updateFolderName?: (name: string, file: FileNode) => void;
  setTableIsLoading?: React.Dispatch<any>;
  deleteFolder?: (file: FileNode, parent: FileNode) => void;
  forceRefresh: number;
  updateFileNode?: (node: FileNode) => void;
  setSelectedNode?: React.Dispatch<React.SetStateAction<string>>;
  openCreateFolderDialog?: () => void;
  openUploadFileDialog?: () => void;
  isRoot?: boolean;
  handleDownload?: () => Promise<void>;
  findParentFolder: (fullKey: string) => FileNode | undefined;
  manageParentFolder: (fullKey: string) => void;
  selectedNodes: FileNode[];
  isRecycleBin?: boolean;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&$selected > $content $label, &$selected:focus > $content $label, &$selected:hover > $content $label': {
        backgroundColor: '#E6EEFF',
      },
      '&:focus > $content $label': {
        backgroundColor: 'inherit',
      },
      minHeight: 48,
      position: 'relative',
    },
    rootRecycleBin: {
      '&$selected > $content $label, &$selected:focus > $content $label, &$selected:hover > $content $label': {
        backgroundColor: '#E6EEFF',
      },
      '&:focus > $content $label': {
        backgroundColor: 'inherit',
      },
      minHeight: 48,
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
    content: {
      transition: '0.1s',
      paddingLeft: 8,
      color: theme.palette.text.secondary,
      height: 48,
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
      '$selected > &:hover, &:hover, &:focus > &, $selected > &': {
        backgroundColor: '#E6EEFF',
      },
    },
    group: {
      marginLeft: 16,
      /*
        '& $content': {
          paddingLeft: theme.spacing(2),
        },
        */
    },
    expanded: {
      backgroundColor: 'inherit',
    },
    selected: {},
    label: {
      transition: '0.1s',
      '&:hover': {
        backgroundColor: '#E6EEFF',
      },
      height: '100%',
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      height: '100%',
      display: 'flex',
      paddingRight: 8,
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
      position: 'relative',
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
      maxWidth: '181px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    iconContainer: {
      width: '10px',
    },
  }),
);

export default function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const {
    id,
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color = 'black',
    bgColor = 'white',
    hasChildren,
    callback,
    setActiveFolder,
    setActiveFolderSubfolders,
    fileNode,
    parentNode,
    forceRefresh,
    bimTreeNode,
    BIM,
    projectId,
    setTableIsLoading,
    updateFolderName = () => {},
    deleteFolder = () => {},
    updateFileNode,
    setSelectedNode = () => {},
    openCreateFolderDialog = () => {},
    openUploadFileDialog = () => {},
    isRoot,
    handleDownload,
    findParentFolder,
    manageParentFolder,
    selectedNodes,
    isRecycleBin,
    ...other
  } = props;

  const user = useSelector(getUserState);
  const project = useSelector(getProjectState)!;
  const dispatch = useDispatch();
  const [localFileNode, setLocalFileNode] = useState(fileNode);

  useEffect(() => {
    document.addEventListener('contextmenu', () => {
      setOptionsAnchor(null);
    });
  }, []);

  useEffect(() => {
    setLocalFileNode(fileNode);
  }, [fileNode]);

  const defaultContent = hasChildren ? (
    <div style={{ paddingTop: 12, paddingBottom: 4, overflow: 'hidden', paddingRight: 16 }}>
      <CircularLoader />
    </div>
  ) : (
    <div />
  );
  const [subfolders, setSubfolders] = useState<JSX.Element[] | JSX.Element | null>(defaultContent);
  const [bimSubfoldersLoaded, setBimSubfoldersLoaded] = useState(false);
  const [optionsAnchor, setOptionsAnchor] = useState<null | HTMLElement>(null);
  const rightClickAnchor = useRef<HTMLDivElement | null>(null);
  const [editing, setEditing] = useState<boolean>(false);
  const inputRef = useRef<HTMLElement | null>(null);

  const handleBIMExpand = () => {
    if (BIM && !bimSubfoldersLoaded) {
      getBIMTreeNode(projectId!, bimTreeNode!)
        .then((treeNodes) => {
          if (Array.isArray(treeNodes)) {
            const formattedData = treeNodes.filter((a: BimTreeNode) => a.type === 'FOLDER');

            const subfoldersTemp = formattedData.map((folder: BimTreeNode) => {
              return (
                <StyledTreeItem
                  key={folder.id}
                  id={folder.id}
                  nodeId={folder.id}
                  labelText={folder.name}
                  hasChildren={folder.type === 'FOLDER'}
                  labelIcon={FolderIcon}
                  callback={callback}
                  setActiveFolder={setActiveFolder}
                  bimTreeNode={folder}
                  setActiveFolderSubfolders={setActiveFolderSubfolders}
                  forceRefresh={forceRefresh}
                  setTableIsLoading={setTableIsLoading}
                  updateFolderName={updateFolderName}
                  projectId={projectId}
                  setSelectedNode={setSelectedNode}
                  openCreateFolderDialog={openCreateFolderDialog}
                  openUploadFileDialog={openUploadFileDialog}
                  BIM
                  handleDownload={handleDownload}
                  findParentFolder={findParentFolder}
                  manageParentFolder={manageParentFolder}
                  selectedNodes={selectedNodes}
                />
              );
            });
            setSubfolders(subfoldersTemp);
            setBimSubfoldersLoaded(true);
          }
        })
        .catch(() => {
          setSubfolders([]);
        });
    }
  };

  const handleOpenOptions = (
    event: React.MouseEvent<HTMLButtonElement>,
    file: FileNode | undefined,
  ) => {
    event.stopPropagation();

    setActiveFolder(localFileNode);

    setOptionsAnchor(event.currentTarget!);
  };

  useEffect(() => {
    if (localFileNode?.children) {
      const formattedData = localFileNode!.children!.filter((a: FileNode) => a.type === 'FOLDER');
      const subfoldersTemp = formattedData
        .sort((a, b) => ascendingComparator(a, b, 'fullKey'))
        .map((folder: FileNode) => (
          <StyledTreeItem
            key={generateUniqueId()}
            id={folder.fullKey}
            nodeId={folder.fullKey + folder.ownerId}
            labelText={folder.relativeKey!}
            hasChildren={folder.type === 'FOLDER'}
            labelIcon={FolderIcon}
            callback={callback}
            setActiveFolder={setActiveFolder}
            fileNode={folder}
            parentNode={localFileNode}
            setActiveFolderSubfolders={setActiveFolderSubfolders}
            forceRefresh={forceRefresh}
            updateFolderName={updateFolderName}
            deleteFolder={deleteFolder}
            updateFileNode={updateFileNode}
            setSelectedNode={setSelectedNode}
            openCreateFolderDialog={openCreateFolderDialog}
            openUploadFileDialog={openUploadFileDialog}
            handleDownload={handleDownload}
            findParentFolder={findParentFolder}
            manageParentFolder={manageParentFolder}
            selectedNodes={selectedNodes}
          />
        ));
      setSubfolders(subfoldersTemp);
    }
  }, [forceRefresh, localFileNode]);

  //Function for when the label is left clicked on.
  async function handleClick(event: React.MouseEvent<Element, MouseEvent>) {
    event.preventDefault();
    if (BIM) {
      setTableIsLoading!(true);
      getBIMTreeNode(projectId!, bimTreeNode!).then((treeNodes) => {
        if (Array.isArray(treeNodes)) {
          callback(treeNodes.filter((a: BimTreeNode) => a.type == 'FILE'));
        }
      });
    } else {
      const formattedData = localFileNode!.children!;
      callback(formattedData);
    }
    setActiveFolder(localFileNode);
    setActiveFolderSubfolders(subfolders);
  }

  //Function for when any part of the tree item is clicked on (right mouse button)
  function handleContextMenu(event: any) {
    event.preventDefault();
    event.stopPropagation();

    if (BIM) {
      return;
    }
    rightClickAnchor.current!.style.top = `${event.clientY + 24}px`;
    rightClickAnchor.current!.style.left = `${event.clientX}px`;
    setOptionsAnchor(rightClickAnchor.current!);

    const formattedData = localFileNode!.children!;
    callback(formattedData);
    setActiveFolder(localFileNode);
    setActiveFolderSubfolders(subfolders);
    if (!BIM) setSelectedNode!(localFileNode!.fullKey + localFileNode!.ownerId);
  }

  const handleFinishEdit = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEditing(false);
    updateFolderName!(e.target.value, localFileNode!);
  };

  const keypressHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      inputRef!.current!.blur();
    }
  };

  useEffect(() => {
    if (editing) {
      inputRef!.current!.focus();
    }
  }, [editing]);

  const getFolderLabel = () => {
    if (localFileNode!.fullKey === '/') return labelText;
    return localFileNode!.relativeKey?.replaceAll('/', '');
  };

  const handleDelete = () => {
    deleteFolder!(localFileNode!, parentNode!);
  };

  const handleBIMProjectSelect = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (!bimTreeNode) return;
    let updatedProject = {} as IProject;
    if (!project.bimOwnerUserId) {
      updatedProject = await patchProject(project.id, {
        preferredBimProject: bimTreeNode.id,
      });
    } else if (project.bimOwnerUserId === user.id) {
      if (project.preferredBimProject === bimTreeNode.id) {
        updatedProject = await patchProject(project.id, {
          preferredBimProject: null,
        });
      } else {
        updatedProject = await patchProject(project.id, {
          preferredBimProject: bimTreeNode.id,
        });
      }
    }
    if (updatedProject.id) dispatch(updateProject(updatedProject));
  };

  return (
    <>
      {!isRoot ? (
        <TreeItem
          onContextMenu={handleContextMenu}
          label={
            <div className={classes.labelRoot}>
              {/*An anchor point to open the options menu when the tree item is right clicked*/}
              <div
                style={{ position: 'fixed', top: 0, left: 0, width: 1, height: 1 }}
                ref={rightClickAnchor}
              />

              <LabelIcon color="inherit" className={classes.labelIcon} />
              {editing ? (
                <TextField
                  inputRef={inputRef}
                  defaultValue={localFileNode?.relativeKey?.substr(
                    0,
                    localFileNode.relativeKey.lastIndexOf('/'),
                  )}
                  onBlur={(e) => handleFinishEdit(e)}
                  onKeyPress={(event) => keypressHandler(event)}
                  fullWidth
                  style={{ maxWidth: 250, paddingRight: 16 }}
                />
              ) : (
                <Typography variant="body2" className={classes.labelText}>
                  {BIM ? labelText : getFolderLabel()}
                </Typography>
              )}
              {!BIM && !editing && !isRecycleBin && (
                <IconButton size="small" onClick={(event) => handleOpenOptions(event, fileNode)}>
                  <MoreHorizIcon />
                </IconButton>
              )}
              {BIM && (
                <GreenCheck
                  disabled={!!project.bimOwnerUserId && project.bimOwnerUserId !== user.id}
                  checked={project.preferredBimProject === bimTreeNode?.id}
                  onClick={handleBIMProjectSelect}
                  style={{ padding: 0 }}
                />
              )}
            </div>
          }
          style={{
            color,
          }}
          classes={{
            root: isRecycleBin ? classes.rootRecycleBin : classes.root,
            content: classes.content,
            expanded: classes.expanded,
            selected: classes.selected,
            group: classes.group,
            label: classes.label,
            iconContainer: classes.iconContainer,
          }}
          onIconClick={handleBIMExpand}
          onLabelClick={handleClick}
          {...other}
        >
          {hasChildren ? subfolders : null}
        </TreeItem>
      ) : (
        <>{subfolders}</>
      )}
      <FileOptionsDialog
        dialogOpen={optionsAnchor !== null}
        anchor={optionsAnchor}
        handleClose={() => setOptionsAnchor(null)}
        file={!BIM ? localFileNode : undefined}
        setFile={setLocalFileNode}
        renameClicked={() => setEditing(true)}
        deleteClicked={() => handleDelete()}
        disableShare={localFileNode?.fullKey === '/' || isRecycleBin}
        disableRename={localFileNode?.fullKey === '/' || isRecycleBin}
        disableDelete={localFileNode?.fullKey === '/' || isRecycleBin}
        disableAddFile={localFileNode?.fullKey === '/' || isRecycleBin}
        disableAddFolder={localFileNode?.fullKey === isRecycleBin}
        updateFileNode={updateFileNode}
        openCreateFolderDialog={openCreateFolderDialog}
        openUploadFileDialog={openUploadFileDialog}
        downloadFile={handleDownload}
        findParentFolder={findParentFolder}
        manageParentFolder={manageParentFolder}
        selectedNodes={selectedNodes}
      />
    </>
  );
}
