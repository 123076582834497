import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  LinearProgress,
  MenuItem,
  Popover,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { useDispatch, useSelector } from 'react-redux';
import { Dayjs } from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import DeleteIcon from '@material-ui/icons/Delete';
import { CameraAlt, Clear, Publish } from '@material-ui/icons';
import {
  deleteDocumentsByIds,
  getSubmittalsBySubmittalSection,
  insertSubmissionByDocumentId,
  modifyDocumentsByBatch,
  skipAssignSubcontractorByDocumentId,
  submitToArchitectByDocumentId,
} from '../../models/api/documents';
import {
  DocumentTemplateType,
  FileCategoryType,
  FileNode,
  IFile,
  INumberedDocumentView,
  IProjectUser,
  IRestrictedDocumentPatch,
  SecurityPermissionLevel,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import {
  docTypeHasDueDateEditField,
  DocumentFileTree,
  FileWithData,
  formatDays,
  formatMoney,
  generateUniqueId,
  getDocumentEditFields,
  getDocumentTypesWithReferenceNumber,
  getProjectUsers,
  getSubmittalTitle,
  parseDate,
  readFileAsync,
  removeNewlines,
  waitForFileToBeVerified,
} from '../../scripts/utils';
import { reloadDocuments } from '../../features/documents/actions';
import FileUploadDialog from './FileUploadDialog';
import CircularLoader from '../loader/CircularLoader';
import AssociateDocumentDialog from '../add-associations/AssociateDocumentDialog';
import {
  addSpacing,
  addSpacingIncremental,
  removeSpacing,
} from '../document-index/DocumentIndexUtils';
import {
  getDocumentsState,
  getDocumentsType,
  getSubmittalSections,
} from '../../features/documents/selectors';
import { getDocPermission, getUserPermissionForCurrentDocType } from '../../scripts/store-utils';
import { Autocomplete } from '@material-ui/lab';
import {
  getDisableProcoreIntegrationFeatures,
  getProjectId,
  getProjectState,
} from '../../features/project/selectors';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import { addSnackbar } from '../../features/snackbar/actions';
import ManagePermissionsDialog, {
  ManagePermissionsDialogType,
} from '../design/ManagePermissionsDialog';
import { reloadDocument } from '../../features/document/actions';
import { getUserState } from '../../features/user/selectors';
import { getDocumentState } from '../../features/document/selectors';
import AssignedUserText from '../custom-components/AssignedUserText';
import { getCompaniesState } from '../../features/companies/selector';
import InlineNotificationList from '../form-pages/user-groups/InlineNotificationList';
import { TooltipIfDisabledComponent } from '../custom-components/CustomButtons';
import { getNavigationState } from '../../features/navigation/selectors';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';
import _ from 'lodash';
import { FileWithPath, useDropzone } from 'react-dropzone';

interface EditDocumentsDialogProps {
  dialogOpen: boolean;
  handleClose: () => void;
  anchor: Element | ((e: Element) => Element) | undefined | null;
  selected: string[];
  addToCloseout?: () => void;
  removeFromCloseout?: () => void;
  inOptions?: boolean;
  selectedDocument?: INumberedDocumentView;
  canChangeAnticipatedSubmission?: boolean;
}

const useStyles = makeStyles({
  popover: {
    //marginLeft: 300,
    '.rootElement > *': {
      maxHeight: '5000px',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '80px',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'uppercase',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  contentFlow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  section: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    minWidth: '280px',
    maxWidth: '400px',
  },
  checkbox: {
    padding: 3,
  },
  inputLabel: {
    marginTop: 12,
  },
  status: {
    marginBottom: 4,
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    paddingTop: 16,
  },
  calendar: {
    maxWidth: 225,
    paddingTop: 4,
    paddingBottom: 4,
  },
  dueDate: {
    paddingTop: 8,
  },
  button: {
    height: 32,
    width: '48%',
  },
  field: {
    marginTop: 8,
    marginBottom: 8,
    padding: 0,
  },
  selectList: {
    maxHeight: 400,
  },
  deleteButton: {
    backgroundColor: '#ED3F26',
    '&:hover': {
      backgroundColor: '#FF5D45',
    },
  },
});

const docTypesEnableDesignImport = [
  DocumentTemplateType.Submittals,
  DocumentTemplateType.CloseoutSubmittals,
  DocumentTemplateType.SubmittalPackages,
  DocumentTemplateType.CloseoutSubmittalPackages,
  DocumentTemplateType.Drawings,
  DocumentTemplateType.Specifications,
  DocumentTemplateType.RequestsForInformation,
  DocumentTemplateType.WorkChangeProposalRequests,
];

export default function EditDocumentsDialog(props: EditDocumentsDialogProps) {
  const classes = useStyles();
  const {
    dialogOpen,
    handleClose,
    anchor,
    selected,
    addToCloseout,
    removeFromCloseout,
    inOptions = false,
    selectedDocument,
    canChangeAnticipatedSubmission = false,
  } = props;

  const dispatch = useDispatch();

  const user = useSelector(getUserState);
  const selectedProject = useSelector(getProjectState);
  const documents = useSelector(getDocumentsState);
  const disableProcoreIntegrationFeatures = useSelector(getDisableProcoreIntegrationFeatures);
  const docType = useSelector(getDocumentsType);
  const submittalCodes = useSelector(getSubmittalSections);
  const projectId = useSelector(getProjectId)!;
  const currentDocument = useSelector(getDocumentState);
  const companies = useSelector(getCompaniesState);
  const shouldBlockNavigation = useSelector(getNavigationState);

  const originalValues = useRef<INumberedDocumentView>({} as INumberedDocumentView);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Editing Document(s)...');
  const [uploadProgress, setUploadProgress] = useState(0);

  // String fields
  const [inputTitle, setInputTitle] = useState<string>();
  const [inputDescription, setInputDescription] = useState<string>();
  const [impactToCost, setImpactToCost] = useState<number | null>();
  const [costTBD, setCostTBD] = useState(false);
  const [impactToTime, setImpactToTime] = useState<number | null>();
  const [timeTBD, setTimeTBD] = useState(false);
  const [inputLocation, setInputLocation] = useState<string>();
  const [inputAgency, setInputAgency] = useState<string>();
  const [inputSection, setInputSection] = useState<string>();
  const [pageNumber, setPageNumber] = useState<number | null>();
  const [sheetNumber, setSheetNumber] = useState<string | null>();
  const [referenceNumber, setReferenceNumber] = useState<string>();
  const [responsibility, setResponsibility] = useState<string>();
  const [mc_responsibility, setMCResponsibility] = useState<string>();
  const [changeOrdersValue, setChangeOrdersValue] = useState<number | null>();
  const [changeOrdersDaysAdded, setChangeOrdersDaysAdded] = useState<number | null>();
  const [submittalSectionDescription, setSubmittalSectionDescription] = useState<string>();
  const [simplePackage, setSimplePackage] = useState<string>();
  const [subcontractor, setSubcontractor] = useState<string>();

  const dynamicWidth = useMediaQuery('(max-width:435px)');

  // Other fields
  const [due, setDue] = useState<Dayjs | null>(null);
  const [anticipatedDate, setAnticipatedDate] = useState<Dayjs | null>(null);
  const [inputFinalExecuted, setInputFinalExecuted] = useState<boolean>();
  const [file, setFile] = useState<File | FileNode>();
  const [inputFiles, setInputFiles] = useState<(File | FileNode)[]>([]);
  const [docFiles, setDocFiles] = useState<IFile[]>([]);
  const [generalContractor, setGeneralContractor] = useState<string>(
    selectedProject?.defaultGeneralContractorUserId || '',
  );
  const [architect, setArchitect] = useState<string>(selectedProject?.defaultArchitectUserId || '');

  const [associatedUsers, setAssociatedUsers] = useState<string[]>([]);
  const [associatedGroups, setAssociatedGroups] = useState<string[]>([]);

  // Associations
  const [associationDialogOpen, setAssociationDialogOpen] = useState(false);

  // Document association
  const [associateDocumentsDialogOpen, setAssociateDocumentsDialogOpen] = useState(false);
  const [documentFileTrees, setDocumentFileTrees] = useState<DocumentFileTree[]>([]);

  const [existingRefNumbers, setExistingRefNumbers] = useState<string[]>([]);

  const [editArchitect, setEditArchitect] = useState(false);
  const [editContractor, setEditContractor] = useState(false);
  const contractorUser = selectedProject?.projectUserList?.find(
    (pUser) => pUser.userId === generalContractor,
  )?.user;
  const architectUser = selectedProject?.projectUserList?.find(
    (pUser) => pUser.userId === architect,
  )?.user;

  const [costDifference, setCostDifference] = useState(0);
  const [timeDifference, setTimeDifference] = useState(0);

  const [newImages, setNewImages] = useState<FileWithData[]>([]);
  const [imageDescriptions, setImageDescriptions] = useState<string[]>([]);

  const originalCost = Number(selectedProject?.contractAmount ?? 0) + costDifference;
  const originalTime = Number(selectedProject?.daysToCompletion ?? 0) + timeDifference;

  const newCost = originalCost + Number(changeOrdersValue ?? 0);
  const newTime = originalTime + Number(changeOrdersDaysAdded ?? 0);

  const permission = getDocPermission();

  const selectedDocuments = selected
    .map((documentId) => documents.find((doc) => doc.id === documentId))
    .filter((x) => !!x) as INumberedDocumentView[];

  useEffect(() => {
    setExistingRefNumbers(documents.map((doc) => doc.referenceNumber || ''));

    if (docType === DocumentTemplateType.ChangeOrders) {
      setCostDifference(
        documents.reduce((prev, current) => prev + Number(current.changeOrdersValue ?? 0), 0),
      );
      setTimeDifference(
        documents.reduce((prev, current) => prev + (current.changeOrdersDaysAdded ?? 0), 0),
      );
    }
  }, [documents]);

  const clearState = () => {
    setInputTitle(undefined);
    setInputDescription(undefined);
    setImpactToCost(undefined);
    setImpactToTime(undefined);
    setCostTBD(false);
    setTimeTBD(false);
    setChangeOrdersDaysAdded(undefined);
    setChangeOrdersValue(undefined);
    setInputLocation('');
    setInputAgency('');
    setInputSection('');
    setPageNumber(undefined);
    setSheetNumber(undefined);
    setResponsibility('');
    setInputFinalExecuted(false);
    setInputFiles([]);
    setDocFiles([]);
    setAnticipatedDate(null);
    setDue(null);
    setSimplePackage(undefined);
    setGeneralContractor('');
    setArchitect('');
    setAssociatedUsers([]);
    setAssociatedGroups([]);
    setSubcontractor(undefined);
  };

  const getDocumentTitle = (
    document: INumberedDocumentView,
    type: DocumentTemplateType,
  ): string => {
    switch (type) {
      case DocumentTemplateType.Specifications:
        if (document.submittalSection && document.submittalSectionDescription)
          return `${addSpacing(document.submittalSection!)} - ${
            document.submittalSectionDescription
          }`;
        return document.title!;

      case DocumentTemplateType.Submittals:
        return document.submittalSection ? getSubmittalTitle(document, false) : document.title!;

      case DocumentTemplateType.Drawings:
        return document.sheetNumber!;

      default:
        return document.title!;
    }
  };

  const [architects, setArchitects] = useState<IProjectUser[]>([]);
  const [contractors, setContractors] = useState<IProjectUser[]>([]);

  const canBeArchitect = (permission?: SecurityPermissionLevel | null) => {
    return permission && permission >= 3;
  };

  const canBeContractor = (permission?: SecurityPermissionLevel | null) => {
    return permission && permission >= 2;
  };

  const fetchEligibleUsers = () => {
    const newArchitects: IProjectUser[] = [];
    const newContractors: IProjectUser[] = [];
    getProjectUsers(selectedProject, false).forEach((pUser) => {
      const permission = getUserPermissionForCurrentDocType(pUser);
      if (canBeContractor(permission)) {
        newContractors.push(pUser);
        if (canBeArchitect(permission)) newArchitects.push(pUser);
      }
    });
    setArchitects(newArchitects);
    setContractors(newContractors);
  };

  useEffect(() => {
    fetchEligibleUsers();
  }, [selectedProject, docType]);

  const getOptions = (label: string) => {
    switch (label) {
      case 'Responsible General Contractor':
        return contractors;

      case 'Responsible Architect':
        return architects;

      default:
        return selectedProject?.projectUserList || [];
    }
  };

  useEffect(() => {
    if (file) {
      const temp: (File | FileNode)[] = inputFiles;
      if (temp) {
        temp.push(file);
        setInputFiles(temp);
      }
    }
  }, [file]);

  // useEffect(() => {
  //   if (selectedProject && dialogOpen && documentFileTrees.length === 0) getDocuments();
  // }, [dialogOpen, selectedProject]);

  useEffect(() => {
    if (selectedDocument) {
      const {
        title,
        description,
        proposedCostImpact,
        proposedTimeImpact,
        changeOrdersValue,
        changeOrdersDaysAdded,
        location,
        agency,
        submittalSection,
        pageNumber,
        sheetNumber,
        referenceNumber,
        isFinalExecuted,
        files,
        anticipatedInitialSubmissionDate,
        dueDate,
        assignedToUserId,
        generalContractorUserId,
        architectUserId,
        documentUserList,
        documentUserGroupList,
        submittalSectionDescription,
        simplePackage,
        customContractor,
      } = selectedDocument;

      setInputTitle(title || undefined);
      setInputDescription(description || undefined);

      if (proposedCostImpact != null) setImpactToCost(Math.round(proposedCostImpact));
      else setCostTBD(true);

      if (proposedTimeImpact !== null && proposedTimeImpact !== undefined)
        setImpactToTime(proposedTimeImpact);
      else setTimeTBD(true);

      setChangeOrdersValue(
        changeOrdersValue != null ? Math.round(changeOrdersValue) : changeOrdersValue,
      );
      setChangeOrdersDaysAdded(changeOrdersDaysAdded);

      setInputLocation(location || undefined);
      setInputAgency(agency || undefined);
      setInputSection(submittalSection || undefined);
      setPageNumber(pageNumber);
      setSheetNumber(sheetNumber);
      setReferenceNumber(referenceNumber || undefined);
      setResponsibility(assignedToUserId || undefined);
      setMCResponsibility(assignedToUserId || undefined);
      setInputFinalExecuted(isFinalExecuted || undefined);
      setDocFiles(files || []);
      setAnticipatedDate(
        anticipatedInitialSubmissionDate ? parseDate(anticipatedInitialSubmissionDate) : null,
      );
      setDue(dueDate ? parseDate(dueDate) : null);
      setGeneralContractor(generalContractorUserId || '');
      setArchitect(architectUserId || '');

      setAssociatedUsers(documentUserList?.map(({ userId }) => userId) || []);
      setAssociatedGroups(documentUserGroupList?.map(({ userGroupId }) => userGroupId) || []);

      setSubmittalSectionDescription(submittalSectionDescription || undefined);
      setSimplePackage(simplePackage || undefined);
      setSubcontractor(customContractor || undefined);

      originalValues.current = {
        ...selectedDocument,
      };
    } else {
      clearState();
    }
  }, [selectedDocument]);

  const closeClicked = () => {
    if (shouldBlockNavigation || isLoading) return;
    handleClose();
    if (selected.length !== 1) {
      clearState();
    }
    setEditContractor(false);
    setEditArchitect(false);
  };

  const getFileCategoryType = () => {
    if (docType === DocumentTemplateType.Submittals)
      return FileCategoryType.DocumentGeneralContractorApproved;
    return FileCategoryType.DocumentAttachments;
  };

  const onUploadComplete = async () => {
    //checks that the user is on a specification
    if (docType === DocumentTemplateType.Specifications) {
      // Find the most recent file on the Specification document
      const submittalSection = currentDocument?.submittalSection;
      if (submittalSection) {
        const submittals = await getSubmittalsBySubmittalSection(projectId, submittalSection);
        //calls getSubmittalBySpecificationFileId to check for submittals that use the specification
        if (submittals.length > 0) {
          //checks if the length of SpecificationFile is more than 1 (there exists a correlating submittal)
          //First snackbar message
          return dispatch(
            addSnackbar({
              id: Date.now(),
              message:
                'Specification section successfully updated! New submittal placeholders may be manually added from the submittal log page.',
              severity: 'info',
              autoHideDuration: null,
            }),
          );
        }
      }
      //alternative snackbar message
      return dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Specification section successfully updated!',
          severity: 'info',
          autoHideDuration: null,
        }),
      );
    }
  };

  const getUploadDisabled = () => {
    switch (docType) {
      case DocumentTemplateType.Submittals:
        return !(
          !!selectedDocument &&
          selectedDocument.workflowStatus === WorkflowStatusType.SubmittedForReview &&
          (user.isSiteAdmin || user.id === selectedDocument.generalContractorUserId)
        );

      default:
        return false;
    }
  };

  function handleFileDelete(currFile: File | FileNode) {
    const items = [...inputFiles];
    items.splice(inputFiles.indexOf(currFile), 1);
    setInputFiles([...items]);
  }

  const getTitleLabel = () => {
    switch (docType) {
      case DocumentTemplateType.Drawings:
        return 'Sheet Name';
      case DocumentTemplateType.FieldReports:
      case DocumentTemplateType.RequestsForChange:
      case DocumentTemplateType.Task:
      case DocumentTemplateType.MiscellaneousDocuments:
      case DocumentTemplateType.RequestsForInformation:
        return 'Title';
      case DocumentTemplateType.Specifications:
        return 'Section Description';
      default:
        return 'Description';
    }
  };

  const getFieldObjects = (fields: string[]) => {
    const objs: {
      label: string;
      value: any | undefined;
      setValue: React.Dispatch<React.SetStateAction<any>> | undefined;
      type?: string;
      optional?: boolean;
      options?: string[];
    }[] = [];
    fields.forEach((label) => {
      // Document #
      switch (label) {
        case 'Upload':
          return objs.push({ label, value: inputFiles, setValue: setInputFiles, type: 'file' });
        case 'Images':
          return objs.push({ label, value: newImages, setValue: setNewImages, type: 'images' });
        case 'Responsibility':
          return objs.push({
            label,
            value: responsibility,
            setValue: setResponsibility,
            type: 'responsibility',
          });
        case 'MC_Responsibility':
          return objs.push({
            label,
            value: responsibility,
            setValue: setResponsibility,
            type: 'mc_responsibility',
          });
        case 'Description':
          return objs.push({
            label,
            value: inputDescription,
            setValue: setInputDescription,
            type: 'textarea',
            optional: true,
          });
        case 'Impact to Cost':
          return objs.push({
            label,
            value: impactToCost,
            setValue: setImpactToCost,
            type: 'impact',
          });
        case 'Impact to Time':
          return objs.push({
            label: 'Impact to Time (days)',
            value: impactToTime,
            setValue: setImpactToTime,
            type: 'impact',
          });
        case 'Change Orders Value':
          return objs.push({
            label: 'Impact to Cost',
            value: changeOrdersValue,
            setValue: setChangeOrdersValue,
            type: 'change',
          });
        case 'Change Orders Days Added':
          return objs.push({
            label: 'Impact to Time',
            value: changeOrdersDaysAdded,
            setValue: setChangeOrdersDaysAdded,
            type: 'change',
          });
        case 'Location':
          return objs.push({ label, value: inputLocation, setValue: setInputLocation });
        case 'Title':
          return objs.push({
            label: getTitleLabel(),
            value:
              docType !== DocumentTemplateType.Specifications
                ? inputTitle
                : submittalSectionDescription,
            setValue:
              docType !== DocumentTemplateType.Specifications
                ? setInputTitle
                : setSubmittalSectionDescription,
          });
        case 'Agency':
          return objs.push({ label, value: inputAgency, setValue: setInputAgency });
        case 'Section':
        case 'Specification Section':
          return objs.push({
            label,
            value: inputSection,
            type: 'autocomplete',
            setValue: setInputSection,
            optional: true,
            options: [selected.length > 1 ? 'yes' : 'no'],
          });
        case 'Subcontractor':
          return objs.push({
            label: 'Responsible Subcontractor',
            value: subcontractor,
            setValue: setSubcontractor,
            optional: true,
          });
        case 'Final Executed?':
          return objs.push({
            label,
            value: inputFinalExecuted,
            setValue: setInputFinalExecuted,
            type: 'boolean',
          });
        case 'Anticipated Initial Submission Date':
          return objs.push({
            label: 'Anticipated Submission Date',
            value: anticipatedDate,
            setValue: setAnticipatedDate,
            type: 'date',
          });
        case 'Due':
          return objs.push({ label, value: due, setValue: setDue, type: 'date' });
        case 'Simple Package':
          return objs.push({ label, value: simplePackage, setValue: setSimplePackage });
        case 'Reference #':
        case 'ASI #':
        case 'Change Order #':
        case 'CCD #':
        case 'WCPR #':
        case 'PCO #':
        case 'RFI #':
        case 'Field Report #':
        case 'RFC #':
        case 'Pay App #':
        case 'Testing #':
        case 'Package #':
        case 'Item #':
          return objs.push({
            label,
            value: referenceNumber,
            setValue: setReferenceNumber,
            type: 'document_number',
          });
        default:
          // return console.log(`field ${label} not found`);
          break;
      }
    });
    return objs;
  };

  const getCostImpact = () => {
    if (costTBD) return null;
    if (impactToCost !== originalValues.current.proposedCostImpact) {
      if (impactToCost !== null && impactToCost !== undefined) {
        return Math.round(impactToCost);
      }
      return impactToCost;
    }
    return undefined;
  };

  const getTimeImpact = () => {
    if (timeTBD) return null;
    if (impactToTime !== originalValues.current.proposedTimeImpact) {
      if (impactToTime !== null && impactToTime !== undefined) {
        return Math.round(impactToTime);
      }
      return impactToTime;
    }
    return undefined;
  };

  const getChangeOrdersDaysAdded = () => {
    if (changeOrdersDaysAdded !== originalValues.current.changeOrdersDaysAdded) {
      if (changeOrdersDaysAdded !== null && changeOrdersDaysAdded !== undefined) {
        return Math.round(changeOrdersDaysAdded);
      }
      return changeOrdersDaysAdded;
    }
    return undefined;
  };

  const getChangeOrdersValue = () => {
    if (changeOrdersValue !== originalValues.current.changeOrdersValue) {
      if (changeOrdersValue !== null && changeOrdersValue !== undefined) {
        return Math.round(changeOrdersValue);
      }
      return changeOrdersValue;
    }
    return undefined;
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const getDocumentObject = () => {
    const doc: IRestrictedDocumentPatch = {
      title: inputTitle?.trim() !== originalValues.current.title ? inputTitle?.trim() : undefined,
      description:
        inputDescription?.trim() !== originalValues.current.description
          ? inputDescription?.trim()
          : undefined,
      proposedCostImpact: getCostImpact(),
      proposedTimeImpact: getTimeImpact(),
      changeOrdersDaysAdded: getChangeOrdersDaysAdded(),
      changeOrdersValue: getChangeOrdersValue(),
      location:
        inputLocation?.trim() && inputLocation !== originalValues.current.location
          ? inputLocation?.trim()
          : undefined,
      agency:
        inputAgency?.trim() && inputAgency !== originalValues.current.agency
          ? inputAgency?.trim()
          : undefined,
      submittalSection:
        inputSection?.trim() &&
        inputSection.replace(/\s/g, '') !== originalValues.current.submittalSection
          ? inputSection.replace(/\s/g, '').trim()
          : undefined,
      pageNumber: pageNumber !== originalValues.current.pageNumber ? pageNumber : undefined,
      sheetNumber:
        sheetNumber?.trim() !== originalValues.current.sheetNumber
          ? sheetNumber?.trim()
          : undefined,
      dueDate:
        due?.toISOString() !== originalValues.current.dueDate && docTypeHasDueDateEditField(docType)
          ? due?.toISOString()
          : undefined,
      isFinalExecuted:
        inputFinalExecuted !== undefined &&
        inputFinalExecuted !== originalValues.current.isFinalExecuted &&
        docType === DocumentTemplateType.PayApplications
          ? inputFinalExecuted
          : undefined,
      generalContractorUserId:
        generalContractor && generalContractor !== originalValues.current.generalContractorUserId
          ? generalContractor
          : undefined,
      architectUserId:
        architect && architect !== originalValues.current.architectUserId ? architect : undefined,
      anticipatedInitialSubmissionDate:
        anticipatedDate?.format('YYYY-MM-DD') !==
          originalValues.current.anticipatedInitialSubmissionDate &&
        !editInitialSubmissionDateDisabled()
          ? anticipatedDate?.format('YYYY-MM-DD')
          : undefined,
      assignedToUserId:
        responsibility && responsibility !== originalValues.current.assignedToUserId
          ? responsibility
          : undefined,
      referenceNumber:
        referenceNumber?.trim() && referenceNumber !== originalValues.current.referenceNumber
          ? referenceNumber.trim()
          : undefined,
      submittalSectionDescription:
        submittalSectionDescription?.trim() &&
        submittalSectionDescription !== originalValues.current.submittalSectionDescription
          ? submittalSectionDescription.trim()
          : undefined,
      simplePackage:
        simplePackage?.trim() && simplePackage !== originalValues.current.simplePackage
          ? simplePackage.trim()
          : undefined,
      customContractor:
        subcontractor?.trim() && subcontractor !== originalValues.current.customContractor
          ? subcontractor.trim()
          : undefined,
    };

    return doc;
  };

  const updateDocuments = async () => {
    const doc = getDocumentObject();

    const associatedDocuments = documentFileTrees
      .map((tree) => tree.documents.filter((doc) => doc.checked).map((doc) => doc.id))
      .flat();
    const usersToAdd = selectedDocument
      ? associatedUsers.filter(
          (associatedUserId) =>
            !selectedDocument.documentUserList?.some(
              (docUser) => associatedUserId === docUser.userId,
            ),
        )
      : associatedUsers;
    const usersToRemove = selectedDocument
      ? selectedDocument.documentUserList
          ?.filter(
            (docUser) =>
              !associatedUsers.some((associatedUserId) => docUser.userId === associatedUserId),
          )
          ?.map((docUser) => docUser.userId) || []
      : [];
    const groupsToAdd = selectedDocument
      ? associatedGroups.filter(
          (id) =>
            !selectedDocument.documentUserGroupList?.some(({ userGroupId }) => userGroupId === id),
        )
      : associatedGroups;
    const groupsToRemove = selectedDocument
      ? selectedDocument.documentUserGroupList
          ?.filter(({ userGroupId }) => !associatedGroups.some((id) => id === userGroupId))
          .map(({ userGroupId }) => userGroupId) || []
      : [];
    setIsLoading(true);
    dispatch(blockNavigation());
    let ranIntoError: unknown | undefined;
    if (selected.length > 0) {
      if (
        Object.values(doc).some((v) => v !== undefined) ||
        usersToAdd ||
        usersToRemove ||
        groupsToAdd ||
        groupsToRemove ||
        associatedDocuments.length > 0 ||
        inputFiles.length > 0
      ) {
        const submittalSectionFile = _.last(
          inputFiles.filter((_) => docType === DocumentTemplateType.Specifications),
        );
        const otherFiles = inputFiles.filter(
          (_) => docType !== DocumentTemplateType.Specifications,
        );

        await modifyDocumentsByBatch(
          selected,
          {
            patch: doc,
            followers: {
              addUserIds: usersToAdd,
              removeUserIds: usersToRemove,
              addUserGroupIds: groupsToAdd,
              removeUserGroupIds: groupsToRemove,
            },
            associatedDocuments: { addIds: associatedDocuments },
            submittalSectionFileUpload:
              submittalSectionFile && submittalSectionFile instanceof File
                ? {
                    useMultiPartUpload: submittalSectionFile.size > MULTI_PART_FILE_SIZE,
                    fullFileName: submittalSectionFile.name,
                    fileType: getFileCategoryType(),
                  }
                : undefined,
            fileUploads: [
              ...otherFiles.map((file) =>
                file instanceof File
                  ? {
                      useMultiPartUpload: file.size > MULTI_PART_FILE_SIZE,
                      fullFileName: file.name,
                      fileType: getFileCategoryType(),
                    }
                  : {
                      // design file import
                      useMultiPartUpload: false,
                      fileType: getFileCategoryType(),
                      ownerId: file.ownerId,
                      fullKey: file.fullKey,
                      fullFileName: file.relativeKey!,
                      projectId: file.projectId,
                    },
              ),
              ...newImages.map((image, index) => ({
                useMultiPartUpload: image.file.size > MULTI_PART_FILE_SIZE,
                fullFileName: image.file.name,
                fileType: FileCategoryType.DocumentAttachments,
                description: imageDescriptions[index],
              })),
            ],
          },
          undefined,
          {
            submittalSectionFile,
            files: [...otherFiles, ...newImages.map((i) => i.file)],
            onUploadProgress: handleUploadProgress,
            onBeginFileUpload: (file) => {
              if (file instanceof File) setLoadingText(`Uploading ${file.name}`);
              else setLoadingText(`Importing ${file.relativeKey}`);
            },
            onBeginSubmittalSectionUpload: (file) => {
              if (file instanceof File) setLoadingText(`Uploading ${file.name}`);
              else setLoadingText(`Importing ${file.relativeKey}`);
            },
          },
        );
        await onUploadComplete();
      }
    }

    dispatch(allowNavigation());
    setIsLoading(false);

    if (ranIntoError) throw ranIntoError;
  };

  const handleSubmitAsBuilt = async () => {
    if (!selectedDocument || inputFiles.length === 0) return;

    let verified = false;
    const fileToUpload = inputFiles[0] as File;

    try {
      setIsLoading(true);
      dispatch(blockNavigation());

      setLoadingText('Updating document...');
      await skipAssignSubcontractorByDocumentId(selectedDocument.id);

      setLoadingText(`Uploading ${fileToUpload.name}`);
      const response = await insertSubmissionByDocumentId(
        selectedDocument.id,
        fileToUpload.name,
        fileToUpload,
        undefined,
        handleUploadProgress,
      );

      setLoadingText('Verifying upload...');
      if (response.file) verified = await waitForFileToBeVerified(response.file);

      if (!verified) {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: `File ${fileToUpload.name} has not finished uploading. You must cancel the upload and try again`,
            severity: 'error',
          }),
        );
        return;
      }

      setLoadingText('Updating document...');
      await submitToArchitectByDocumentId(selectedDocument.id);
    } finally {
      setIsLoading(false);
      dispatch(allowNavigation());
    }
  };

  const deleteDocuments = async () => {
    const shouldDelete = window.confirm(
      `Are you sure you want to delete ${
        selected.length === 1 ? 'this document' : 'these documents'
      }?`,
    );
    if (shouldDelete)
      try {
        await deleteDocumentsByIds(selected);
        handleClose();
        dispatch(reloadDocuments());
      } catch (e: any) {
        console.error(e);
      }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      docType &&
      getDocumentTypesWithReferenceNumber().includes(docType) &&
      referenceNumber &&
      referenceNumber !== originalValues.current.referenceNumber &&
      existingRefNumbers.includes(referenceNumber)
    )
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'A document with this Reference # already exists!',
          severity: 'warning',
        }),
      );
    else if (
      docType === DocumentTemplateType.Drawings &&
      documents.some(
        (doc) =>
          doc.id !== selectedDocument?.id &&
          doc.sheetNumber === selectedDocument?.sheetNumber &&
          doc.simplePackage === simplePackage,
      )
    ) {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'A document with this Sheet Number and Package name already exists!',
          severity: 'warning',
        }),
      );
    } else {
      if (selected.length >= 5) {
        const proceed = window.confirm(
          `You are about to make changes to ${selected.length} documents.`,
        );
        if (!proceed) return;
      }
      if (docType === DocumentTemplateType.AsBuilt) await handleSubmitAsBuilt();
      else await updateDocuments();
      closeClicked();
      dispatch(inOptions ? reloadDocument() : reloadDocuments());
    }
  };

  const canDeleteDocument = () => {
    return permission === 4;
  };

  const editDueDateDisabled = () => {
    if (
      !selectedDocument?.workflowStatus ||
      ![
        WorkflowStatusType.SubmittedForReview,
        WorkflowStatusType.UnderReview,
        WorkflowStatusType.ArchitectUploaded,
      ].includes(selectedDocument.workflowStatus)
    ) {
      return true;
    }
    if (
      selectedDocuments.some(
        (doc) =>
          !doc.workflowStatus ||
          ![
            WorkflowStatusType.SubmittedForReview,
            WorkflowStatusType.UnderReview,
            WorkflowStatusType.ArchitectUploaded,
          ].includes(doc.workflowStatus),
      )
    ) {
      return true;
    }
    return !permission || permission < 3;
  };

  const editInitialSubmissionDateDisabled = () => {
    return !permission || permission < 2 || !canChangeAnticipatedSubmission;
  };

  const addRemoveFollowersDisabled = () => {
    return !permission || permission < 2;
  };

  const getSpecificationsNoOptionsText = () => {
    if (!submittalCodes || submittalCodes.length === 0)
      return 'Something went wrong! Please refresh the page.';
    if (!inputSection || inputSection.length < 2) return 'Type more to see suggestions';
    return 'No results found';
  };

  const getDocumentType = () => {
    if (selectedDocument?.submittalChildDocuments?.length) {
      if (docType === DocumentTemplateType.Submittals)
        return DocumentTemplateType.SubmittalPackages;
      if (docType === DocumentTemplateType.CloseoutSubmittals)
        return DocumentTemplateType.CloseoutSubmittalPackages;
    }
    return docType || undefined;
  };

  const getGeneralFieldError = (label: string) => {
    switch (label) {
      case 'Simple Package':
        return documents.some(
          (doc) =>
            doc.id !== selectedDocument?.id &&
            doc.sheetNumber === selectedDocument?.sheetNumber &&
            doc.simplePackage === simplePackage,
        );

      default:
        return false;
    }
  };

  const getNumberFieldError = () => {
    return (
      !!docType &&
      getDocumentTypesWithReferenceNumber().includes(docType) &&
      !!referenceNumber &&
      existingRefNumbers.includes(referenceNumber) &&
      referenceNumber !== originalValues.current.referenceNumber
    );
  };

  const onDrop = useCallback(
    async (acceptedFiles: FileWithPath[]) => {
      try {
        const filesWithData: FileWithData[] = [];

        for (const file of acceptedFiles) {
          const dataUrl = await readFileAsync(file);
          filesWithData.push({ file: file as File, data: dataUrl });
        }

        setNewImages([...newImages, ...filesWithData]);
        setImageDescriptions([...imageDescriptions, ...acceptedFiles.map(() => '')]);
      } catch (error: any) {
        console.error(`Error uploading files: ${error.message}`);
      }
    },
    [newImages, imageDescriptions],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    multiple: true,
    onDrop,
  });

  const handleImageDelete = (i: number) => {
    const tempImages = [...newImages];
    tempImages.splice(i, 1);
    setNewImages([...tempImages]);
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <Popover
        className={classes.popover}
        open={dialogOpen}
        onClose={closeClicked}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <form
          className={classes.contentFlow}
          onSubmit={handleSubmit}
          style={{ width: dynamicWidth ? '90vw' : '400px' }}
        >
          <Box className={classes.section}>
            {getFieldObjects(getDocumentEditFields(getDocumentType())).map(
              (field: any, index: number) => {
                if (!field.type) {
                  return (
                    <TextField
                      key={field.label}
                      variant="outlined"
                      margin="none"
                      fullWidth
                      error={getGeneralFieldError(field.label)}
                      label={field.label}
                      autoFocus={index === 0}
                      value={field.value}
                      InputProps={{
                        endAdornment: originalValues.current.revisionNumber ? (
                          <span>-R{originalValues.current.revisionNumber}</span>
                        ) : (
                          ''
                        ),
                      }}
                      className={classes.field}
                      onChange={(e) =>
                        field.setValue(
                          field.label === 'Description'
                            ? e.target.value
                            : removeNewlines(e.target.value),
                        )
                      }
                    />
                  );
                }
                if (field.type === 'document_number') {
                  return (
                    <TextField
                      key={field.label}
                      variant="outlined"
                      margin="none"
                      fullWidth
                      error={getNumberFieldError()}
                      label={field.label}
                      autoFocus={index === 0}
                      value={field.value}
                      InputProps={{
                        endAdornment: originalValues.current.revisionNumber ? (
                          <span>-R{originalValues.current.revisionNumber}</span>
                        ) : (
                          ''
                        ),
                      }}
                      className={classes.field}
                      onChange={(e) => field.setValue(removeNewlines(e.target.value))}
                    />
                  );
                }
                if (field.type === 'textarea') {
                  return (
                    <TextField
                      key={field.label}
                      variant="outlined"
                      margin="none"
                      size="small"
                      fullWidth
                      multiline
                      rows="5"
                      label={field.label}
                      required={!field.optional}
                      autoFocus={index === 0}
                      value={field.value}
                      onChange={(e: any) =>
                        field.setValue(
                          field.label === 'Description'
                            ? e.target.value
                            : removeNewlines(e.target.value),
                        )
                      }
                      style={{ marginTop: 8, marginBottom: 8 }}
                    />
                  );
                }
                if (field.type === 'date') {
                  return (
                    <KeyboardDatePicker
                      key={field.label}
                      inputVariant="outlined"
                      value={field.value}
                      disabled={
                        field.label === 'Due'
                          ? editDueDateDisabled()
                          : editInitialSubmissionDateDisabled()
                      }
                      InputProps={
                        (
                          field.label === 'Due'
                            ? editDueDateDisabled()
                            : editInitialSubmissionDateDisabled()
                        )
                          ? { notched: true }
                          : undefined
                      }
                      format="MM/DD/YYYY"
                      onChange={(date) => field.setValue(date)}
                      className={classes.field}
                      label={field.label}
                    />
                  );
                }
                if (field.type === 'boolean') {
                  return (
                    <div
                      key={field.label}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        fontSize: '15px',
                        color: '#464546',
                      }}
                    >
                      {field.label}
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => field.setValue(e.target.checked)}
                      />
                    </div>
                  );
                }
                if (field.type === 'file') {
                  return (
                    <div key={field.label}>
                      <div style={{ fontSize: '15px' }}>FILES:</div>
                      {docFiles &&
                        docFiles.length > 0 &&
                        docFiles.map((item) => {
                          return (
                            <div
                              key={item.id}
                              style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '2px',
                                height: '24px',
                              }}
                            >
                              <div
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: '90%',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {item.name}
                              </div>
                            </div>
                          );
                        })}
                      {inputFiles &&
                        inputFiles.length > 0 &&
                        inputFiles.map((item) => {
                          return (
                            <div
                              key={
                                (item as File).name + (item as File).lastModified ||
                                (item as FileNode).fullKey
                              }
                              style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '2px',
                              }}
                            >
                              <div
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: '90%',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {(item as File).name || (item as FileNode).relativeKey}
                              </div>
                              <Clear
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleFileDelete(item)}
                                fill="#949494"
                              />
                            </div>
                          );
                        })}
                      <div>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            height: '32px',
                            width: '100%',
                            marginTop: 8,
                            marginBottom: 8,
                          }}
                          disabled={getUploadDisabled()}
                          onClick={() => setIsOpen(true)}
                          startIcon={<Publish fill="#2C69D6" />}
                        >
                          UPLOAD
                        </Button>
                        <FileUploadDialog
                          open={isOpen}
                          addFile={setFile}
                          addFiles={(files) => {
                            setInputFiles([...inputFiles, ...files]);
                          }}
                          newFiles={inputFiles}
                          removeFile={(f) => {
                            const tmp = [...inputFiles];
                            tmp.splice(tmp.indexOf(f), 1);
                            setInputFiles([...tmp]);
                          }}
                          canSubmit={file !== undefined || inputFiles.length > 0}
                          file={file}
                          disableComments
                          handleClose={() => setIsOpen(false)}
                          title="Upload File"
                          disableDesignUpload={
                            !docType || !docTypesEnableDesignImport.includes(docType)
                          }
                        />
                      </div>
                    </div>
                  );
                }
                if (field.type === 'impact') {
                  const isCurrency = field.label === 'Impact to Cost';
                  return (
                    <div key={field.label} style={{ display: 'flex' }}>
                      {isCurrency ? (
                        <CurrencyTextField
                          variant="outlined"
                          margin="none"
                          size="small"
                          fullWidth
                          rows="5"
                          id="project-number"
                          label={field.label}
                          disabled={docType === DocumentTemplateType.ChangeOrders ? false : costTBD}
                          currencySymbol="$"
                          outputFormat="string"
                          decimalCharacter="."
                          digitGroupSeparator=","
                          required={!field.optional}
                          autoFocus={index === 0}
                          value={field.value ? parseInt(field.value, 10) / 100 : undefined}
                          className={classes.field}
                          onBlur={(e: any, val: string) => {
                            if (val === '') field.setValue(undefined);
                            else field.setValue((Number(val) * 100).toString());
                          }}
                          style={{ width: '100%', flex: 3 }}
                        />
                      ) : (
                        <TextField
                          variant="outlined"
                          margin="none"
                          size="small"
                          fullWidth
                          id="project-number"
                          label={field.label}
                          disabled={docType === DocumentTemplateType.ChangeOrders ? false : timeTBD}
                          required={!field.optional}
                          autoFocus={index === 0}
                          value={field.value}
                          className={classes.field}
                          onChange={(e) => {
                            field.setValue(removeNewlines(e.target.value));
                          }}
                          style={{ flex: 3 }}
                        />
                      )}
                      {docType !== DocumentTemplateType.ChangeOrders && (
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            width: '100%',
                            fontSize: '14px',
                            color: '#464546',
                          }}
                        >
                          <Checkbox
                            checked={isCurrency ? costTBD : timeTBD}
                            onChange={(e) =>
                              isCurrency
                                ? setCostTBD(e.target.checked)
                                : setTimeTBD(e.target.checked)
                            }
                          />
                          TBD
                        </div>
                      )}
                    </div>
                  );
                }
                if (field.type === 'change') {
                  const isCurrency = field.label === 'Impact to Cost';
                  return (
                    <>
                      <Typography style={{ fontWeight: 500, marginTop: 8 }}>
                        {field.label}
                      </Typography>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
                        <Typography style={{ minWidth: 90 }}>Current:</Typography>
                        <Typography>
                          {isCurrency ? formatMoney(originalCost) : formatDays(originalTime)}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ minWidth: 90 }}>Difference:</Typography>
                        {isCurrency ? (
                          <CurrencyTextField
                            variant="outlined"
                            margin="none"
                            size="small"
                            fullWidth
                            rows="5"
                            id="project-number"
                            currencySymbol="$"
                            outputFormat="string"
                            decimalCharacter="."
                            digitGroupSeparator=","
                            required={!field.optional}
                            autoFocus={index === 0}
                            value={field.value ? parseInt(field.value, 10) / 100 : undefined}
                            className={classes.field}
                            onBlur={(e: any, val: string) => {
                              if (val === '') field.setValue(undefined);
                              else field.setValue((Number(val) * 100).toString());
                            }}
                            InputProps={{ style: { height: 32 } }}
                            style={{ width: '100%', flex: 3 }}
                          />
                        ) : (
                          <>
                            <TextField
                              variant="outlined"
                              margin="none"
                              size="small"
                              fullWidth
                              id="project-number"
                              required={!field.optional}
                              autoFocus={index === 0}
                              value={field.value}
                              className={classes.field}
                              onChange={(e) => {
                                field.setValue(removeNewlines(e.target.value));
                              }}
                              InputProps={{ style: { height: 32 } }}
                              style={{ flex: 3 }}
                            />
                            <Typography style={{ marginLeft: 8 }}>days</Typography>
                          </>
                        )}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ minWidth: 90 }}>New:</Typography>
                        {isCurrency ? (
                          <CurrencyTextField
                            variant="outlined"
                            margin="none"
                            size="small"
                            fullWidth
                            rows="5"
                            id="project-number"
                            currencySymbol="$"
                            outputFormat="string"
                            decimalCharacter="."
                            digitGroupSeparator=","
                            required={!field.optional}
                            autoFocus={index === 0}
                            value={newCost / 100}
                            className={classes.field}
                            onBlur={(e: any, val: string) => {
                              // if (val === '') field.setValue(undefined);
                              // else field.setValue((Number(val) * 100).toString());
                              setChangeOrdersValue(Number(val) * 100 - originalCost);
                            }}
                            InputProps={{ style: { height: 32 } }}
                            style={{ width: '100%', flex: 3 }}
                          />
                        ) : (
                          <>
                            <TextField
                              variant="outlined"
                              margin="none"
                              size="small"
                              fullWidth
                              id="project-number"
                              required={!field.optional}
                              autoFocus={index === 0}
                              value={newTime}
                              className={classes.field}
                              onChange={(e) => {
                                setChangeOrdersDaysAdded(Number(e.target.value) - originalTime);
                              }}
                              InputProps={{ style: { height: 32 } }}
                              style={{ flex: 3 }}
                            />
                            <Typography style={{ marginLeft: 8 }}>days</Typography>
                          </>
                        )}
                      </div>
                    </>
                  );
                }
                if (field.type === 'responsibility') {
                  return (
                    <>
                      {editContractor || !generalContractor ? (
                        <TextField
                          select
                          variant="outlined"
                          label="Assign Contractor"
                          fullWidth
                          value={generalContractor}
                          onChange={(e) => setGeneralContractor(e.target.value)}
                          style={{ marginBottom: 8, marginTop: 8 }}
                        >
                          {selectedProject ? (
                            getProjectUsers(selectedProject, false).map((item) => {
                              return (
                                <MenuItem key={item.id} value={item.userId}>
                                  <AssignedUserText
                                    name={item.user!.name}
                                    company={
                                      item.user?.company?.name ||
                                      companies.find((c) => c.id === item.user?.companyId)?.name
                                    }
                                  />
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem value="">No selected project</MenuItem>
                          )}
                        </TextField>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography style={{ marginBottom: 8, marginTop: 8 }}>
                            <span style={{ fontWeight: 500 }}>Assigned Contractor:</span>{' '}
                            <AssignedUserText
                              horizontal
                              name={contractorUser?.name}
                              company={
                                contractorUser?.company?.name ||
                                companies.find((c) => c.id === contractorUser?.companyId)?.name
                              }
                            />
                          </Typography>
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => setEditContractor(true)}
                            style={{ marginTop: 4 }}
                          >
                            EDIT
                          </Button>
                        </div>
                      )}
                      {editArchitect || !architect ? (
                        <TextField
                          select
                          variant="outlined"
                          label="Assign Architect"
                          fullWidth
                          value={architect}
                          onChange={(e) => setArchitect(e.target.value)}
                          style={{ marginBottom: 8, marginTop: 8 }}
                        >
                          {selectedProject ? (
                            getProjectUsers(selectedProject, false).map((item) => {
                              return (
                                <MenuItem key={item.id} value={item.userId}>
                                  <AssignedUserText
                                    name={item.user!.name}
                                    company={
                                      item.user?.company?.name ||
                                      companies.find((c) => c.id === item.user?.companyId)?.name
                                    }
                                  />
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem value="">No selected project</MenuItem>
                          )}
                        </TextField>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography style={{ marginBottom: 8, marginTop: 8 }}>
                            <span style={{ fontWeight: 500 }}>Assigned Architect:</span>{' '}
                            <AssignedUserText
                              horizontal
                              name={architectUser?.name}
                              company={
                                architectUser?.company?.name ||
                                companies.find((c) => c.id === architectUser?.companyId)?.name
                              }
                            />
                          </Typography>
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => setEditArchitect(true)}
                            style={{ marginTop: 4 }}
                          >
                            EDIT
                          </Button>
                        </div>
                      )}
                    </>
                  );
                }
                if (field.type === 'autocomplete') {
                  return (
                    <Autocomplete
                      key={field.label}
                      id="combo-box-demo"
                      fullWidth
                      disabled={
                        addRemoveFollowersDisabled()
                          ? true
                          : field.label === 'Specification Section'
                          ? field.options[0] === 'yes'
                          : false
                      }
                      options={submittalCodes}
                      getOptionLabel={(option) => option.code}
                      inputValue={addSpacingIncremental(inputSection)}
                      onInputChange={(e, value) => setInputSection(removeSpacing(value))}
                      onChange={(e, value) => setInputSection(value?.code)}
                      autoComplete
                      filterOptions={(specs, state) =>
                        state.inputValue.length >= 2
                          ? specs.filter((x) =>
                              x.code
                                ?.toLowerCase()
                                .startsWith(removeSpacing(state.inputValue.toLowerCase())),
                            )
                          : []
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Specification Section"
                            autoComplete="Company"
                            name="email"
                          />
                        );
                      }}
                      noOptionsText={getSpecificationsNoOptionsText()}
                      renderOption={(option, state) => (
                        <Typography>{addSpacingIncremental(option.code)}</Typography>
                      )}
                      value={submittalCodes.find((x) => x.code === removeSpacing(inputSection))}
                    />
                  );
                }
                if (field.type === 'images') {
                  return (
                    <div key={field.label}>
                      <div style={{ fontSize: '15px', marginTop: 8 }}>IMAGES:</div>

                      <em>Only *.jpeg and *.png images will be accepted</em>

                      <div {...getRootProps()} style={{ marginBottom: 16 }}>
                        <input {...getInputProps()} />
                        <Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          startIcon={<CameraAlt />}
                        >
                          Take Photo / Select Images
                        </Button>
                      </div>
                      {newImages.length > 0 ? (
                        <>
                          {newImages.map((item, index) => {
                            return (
                              <div key={item.file.name + index} style={{ marginBottom: 16 }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center',
                                  }}
                                >
                                  <img
                                    alt={imageDescriptions[index]}
                                    src={item.data?.toString()}
                                    style={{ objectFit: 'contain', width: '100%' }}
                                  />
                                  <Clear
                                    style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
                                    onClick={() => handleImageDelete(index)}
                                    fill="#949494"
                                  />
                                </div>
                                <TextField
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  placeholder="Description"
                                  style={{ marginTop: 8, maxWidth: 500 }}
                                  value={imageDescriptions[index]}
                                  onChange={(e) => {
                                    const descriptions = [...imageDescriptions];
                                    descriptions[index] = e.target.value;
                                    setImageDescriptions([...descriptions]);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                  );
                }
                return <div key={generateUniqueId()} />;
              },
            )}
            <Button
              color="primary"
              variant="contained"
              onClick={() => setAssociationDialogOpen(true)}
              fullWidth
              style={{ height: '100%', marginBottom: 16, marginTop: 8, alignSelf: 'center' }}
            >
              Define Notification List
            </Button>
            {selectedDocument && (
              <InlineNotificationList
                associatedUsers={associatedUsers}
                associatedGroups={associatedGroups}
              />
            )}
            {!inOptions && (
              <Button
                variant="contained"
                color="primary"
                disabled={!canDeleteDocument()}
                className={classes.deleteButton}
                style={{ marginTop: 8 }}
                startIcon={<DeleteIcon />}
                onClick={deleteDocuments}
              >
                {selected.length === 1 ? 'Delete Document' : 'Delete Selected'}
              </Button>
            )}
            {docType === DocumentTemplateType.Submittals && !inOptions && (
              <TooltipIfDisabledComponent
                title={'This is disabled for projects synced with Procore'}
                disabled={disableProcoreIntegrationFeatures}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 10 }}
                  disabled={disableProcoreIntegrationFeatures}
                  onClick={() => {
                    handleClose();
                    if (addToCloseout) addToCloseout();
                  }}
                >
                  Add to Closeout
                </Button>
              </TooltipIfDisabledComponent>
            )}
            {docType === DocumentTemplateType.CloseoutSubmittals && !inOptions && (
              <TooltipIfDisabledComponent
                title={'This is disabled for projects synced with Procore'}
                disabled={disableProcoreIntegrationFeatures}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 10 }}
                  disabled={disableProcoreIntegrationFeatures}
                  onClick={() => {
                    handleClose();
                    if (removeFromCloseout) removeFromCloseout();
                  }}
                >
                  Remove from Closeout
                </Button>
              </TooltipIfDisabledComponent>
            )}

            <Box
              className={classes.inline}
              style={{ justifyContent: isLoading ? 'center' : 'space-between' }}
            >
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '6px',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="body1" style={{ fontWeight: 500 }}>
                    {loadingText}
                  </Typography>
                  {loadingText.includes('Uploading') ? (
                    <>
                      <Typography variant="body1" style={{ fontSize: 14, marginTop: 4 }}>
                        {uploadProgress}%
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={uploadProgress}
                        style={{ height: 6, width: '100%', marginBottom: 8, marginTop: 4 }}
                      />
                    </>
                  ) : (
                    <CircularLoader style={{ margin: '4px 0px 4px 0px' }} />
                  )}
                  <Typography variant="body1" style={{ fontWeight: 500 }}>
                    DO NOT NAVIGATE AWAY FROM THIS PAGE
                  </Typography>
                </div>
              ) : (
                <>
                  <Button onClick={closeClicked} variant="outlined" className={classes.button}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    className={classes.button}
                    variant="contained"
                  >
                    Update
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </form>
      </Popover>
      <ManagePermissionsDialog
        dialogOpen={associationDialogOpen}
        closeDialog={() => {
          if (selectedDocument) window.dispatchEvent(new CustomEvent('resize'));
          setAssociationDialogOpen(false);
        }}
        type={ManagePermissionsDialogType.AssociatedUsers}
        associatedUsers={associatedUsers}
        associatedGroups={associatedGroups}
        setAssociatedUsers={setAssociatedUsers}
        setAssociatedGroups={setAssociatedGroups}
      />
      <AssociateDocumentDialog
        open={associateDocumentsDialogOpen}
        handleClose={() => setAssociateDocumentsDialogOpen(false)}
        documents={documentFileTrees}
        setDocuments={setDocumentFileTrees}
      />
    </MuiPickersUtilsProvider>
  );
}
