import { ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { SET_PROJECT, UPDATE_PHYSICAL_LOCATIONS, UPDATE_PROJECT } from './constants';
import * as projectActions from './actions';
import { Project } from './project';

export type ProjectAction = ActionType<typeof projectActions>;
export type ProjectState = { project: Project };

export default combineReducers<ProjectState, ProjectAction>({
  project: (state = null, action) => {
    let newState: Project = null;
    switch (action.type) {
      case SET_PROJECT:
        newState = action.payload;
        break;
      case UPDATE_PROJECT:
        if (state) newState = { ...state, ...action.payload };
        break;
      case UPDATE_PHYSICAL_LOCATIONS:
        if (state) newState = { ...state, physicalBuildings: action.payload };
        break;
      default:
        newState = state;
    }
    return newState;
  },
});
