import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Grid, TextField, Typography } from '@material-ui/core';
import CenterlineIcon from '../../images/CenterlineLogo.png';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import SplashImage from '../../admin-components/images/splash-image1.jpg';
import { parse } from 'query-string';
import { Auth } from '@aws-amplify/auth';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import useMediaQueries from '../custom-components/useMediaQueries';
import { getPublicUserInfoByEmail } from '../../models/api/users';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    background: `url(${SplashImage})`,
    backgroundSize: 'cover',
  },
  card: {
    display: 'flex',
    background: 'white',
    opacity: 1,
    width: '375px',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '0px',
    left: '0px',
  },
  paper: {
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  login: {
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  avatar: {
    margin: 8,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 8,
  },
  submit: {
    marginTop: 16,
    marginBottom: 24,
    maxWidth: 240,
  },
  textField: {
    maxWidth: 350,
  },
  links: {
    maxWidth: 400,
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    textDecoration: 'none',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#0947B9',
    '&:hover': {
      color: '#4380ED',
    },
  },
  passwordRequirement: {
    marginLeft: 15,
  },
  textContainer: {
    position: 'absolute',
    left: 450,
    top: '22vh',
  },
  splashText: {
    color: 'white',
    fontSize: 64,
    lineHeight: '64px',
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },
  splashTextSmall: {
    color: 'white',
    fontSize: 26,
    lineHeight: '26px',
    fontWeight: 300,
    whiteSpace: 'nowrap',
  },
});

const passwordOptions = {
  minLength: 8,
  minLowercase: 1,
  minUppercase: 1,
  minNumbers: 1,
  minSymbols: 1,
};

interface AuthenticationError {
  code: string;
  name: string;
  message: string;
}

export default function ForgotPasswordPage() {
  const classes = useStyles();
  const { email, username, verification_code } = parse(window.location.search);
  const { isXs } = useMediaQueries();
  const history = useHistory();
  const user = useSelector(getUserState);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorText, setErrorText] = useState('');

  const [isBidding, setIsBidding] = useState(false);

  useEffect(() => {
    if (typeof email === 'string') {
      getPublicUserInfoByEmail(email).then(({ isPartOfMainPortal }) =>
        setIsBidding(!isPartOfMainPortal),
      );
    }
  }, [username]);

  const isValidPassword = (password: string) => {
    if (password.length === 0) return true;
    return validator.isStrongPassword(password, passwordOptions);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!username || !verification_code || !password || !confirmPassword) return;
    try {
      await Auth.forgotPasswordSubmit(username as string, verification_code as string, password);
      history.push(isBidding ? '/bidding/login' : '/main/login');
    } catch (error: any) {
      const message = error.toString();
      if (message.includes('AuthError')) {
        if (message.includes('Username')) setErrorText('Email Address cannot be empty');
        else setErrorText(message.slice(message.indexOf(':') + 2));
      }
      const errorObj = error as AuthenticationError;
      if (errorObj.code === 'InvalidPasswordException') {
        if (errorObj.message.includes('Password not long enough'))
          setErrorText('Password must have at least 8 characters');
        else if (errorObj.message.includes('Password must have uppercase characters'))
          setErrorText('Password must have uppercase characters');
        else if (errorObj.message.includes('Password must have lowercase characters'))
          setErrorText('Password must have lowercase characters');
        else if (errorObj.message.includes('Password must have numeric characters'))
          setErrorText('Password must have numeric characters');
        else if (errorObj.message.includes('Password must have symbol characters'))
          setErrorText('Password must have symbol characters');
      }
      if (errorObj.code === 'InvalidParameterException') {
        if (errorObj.message.includes('Member must have length greater than or equal to 6'))
          setErrorText('Password must have at least 8 characters');
      }
      if (errorObj.code === 'ExpiredCodeException')
        setErrorText('Your code has expired. Please request another password reset email.');
      if (errorObj.code === 'LimitExceededException')
        setErrorText('Too many attempts, please try again later.');
      console.log('error signing up:', error);
    }
  };

  useEffect(() => {
    if (user.id) history.push(isBidding ? '/bidding/login' : '/main/login');
  }, [user]);

  return (
    <div className={classes.root}>
      {!isBidding && (
        <header className="welcome-login">
          <h1 className="welcome">WELCOME TO CENTERLINE</h1>
          <p className="module">PROJECT MANAGEMENT</p>
        </header>
      )}
      <Card className={classes.card}>
        <div className={classes.paper}>
          <div className={classes.login}>
            <img
              src={CenterlineIcon}
              alt="centerlinelogo"
              style={{ width: '350px', paddingBottom: '20px' }}
            />
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Create New Password
            </Typography>
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <div style={{ alignSelf: 'flex-start', marginLeft: 18 }}>
                <Typography style={{ marginBottom: 6 }}>
                  Passwords must meet the following requirements:
                </Typography>
                <Typography component="li" className={classes.passwordRequirement}>
                  At least 8 characters
                </Typography>
                <Typography component="li" className={classes.passwordRequirement}>
                  At least 1 uppercase letter
                </Typography>
                <Typography component="li" className={classes.passwordRequirement}>
                  At least 1 lowercase letter
                </Typography>
                <Typography component="li" className={classes.passwordRequirement}>
                  At least 1 number
                </Typography>
                <Typography component="li" className={classes.passwordRequirement}>
                  At least 1 symbol
                </Typography>
              </div>
              {errorText ? (
                <Typography style={{ color: 'red', marginTop: 6 }}>{errorText}</Typography>
              ) : (
                <span>&nbsp;&nbsp;</span>
              )}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="password"
                id="password"
                label="New Password"
                name="password"
                autoFocus
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={classes.textField}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirm-password"
                label="Confirm Password"
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={classes.textField}
              />
              <Button
                fullWidth
                disabled={
                  password?.length === 0 ||
                  confirmPassword?.length === 0 ||
                  password !== confirmPassword
                }
                variant="contained"
                color="primary"
                type="submit"
                className={classes.submit}
              >
                Confirm
              </Button>
              <Grid container className={classes.links}>
                <Grid item>
                  <Button
                    onClick={() => history.push(isBidding ? '/bidding/login' : '/main/login')}
                    className={classes.link}
                  >
                    Return to login
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </Card>

      {isBidding && !isXs && (
        <div className={classes.textContainer}>
          <Typography
            className={classes.splashText}
            style={{
              fontFamily: 'Uniform Rounded Medium',
              fontWeight: 500,
              marginTop: 0,
              letterSpacing: 8,
            }}
          >
            CENTERLINE BidConnect
          </Typography>
          <Typography
            className={classes.splashText}
            style={{
              fontSize: 48,
              lineHeight: '48px',
              fontWeight: 500,
              marginTop: 48,
            }}
          >
            Find Construction Bid Opportunities
          </Typography>
          <Typography className={classes.splashTextSmall} style={{ marginTop: 32 }}>
            Centerline BidConnect is a cloud-hosted platform for Architects and Owners
          </Typography>
          <Typography className={classes.splashTextSmall}>
            to distribute Bid Documents and Addenda which allows Contractors to
          </Typography>
          <Typography className={classes.splashTextSmall}>
            submit Bid-Phase RFIs & Substitution Requests, as well as secure Bid Submission.
          </Typography>
          <Typography className={classes.splashTextSmall} style={{ marginTop: 24 }}>
            Registration to view projects is FREE.
          </Typography>
          <Typography
            className={classes.splashTextSmall}
            style={{ marginTop: 32, fontSize: 32, lineHeight: '32px', fontWeight: 400 }}
          >
            To post your projects here, contact us: bidconnect@centerline.co
          </Typography>
        </div>
      )}
    </div>
  );
}
