import {
  Button,
  Card,
  CardContent,
  createStyles,
  FormControl,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { useEffect, useRef, useState } from 'react';
import NavAppbar from '../nav-top/NavAppbar';
import PageTitle from '../page-title/PageTitle';
import { documentTypeToUrl, openInNewWindow } from '../../scripts/utils';
import { blueHighlight, DCCRow, PublishType, TableType, yellowHighlight } from './DCCUtils';
import {
  getConformingCenterDocumentsByType,
  getConformingCenterSummaryByProjectId,
} from '../../models/api/dcc';
import {
  DocumentTemplateType,
  FileCategoryType,
  IConformingCenterProjectSummary,
  IConformingDocumentsSummary,
  IFile,
  IRestrictedDocumentPatch,
  PublishStatusType,
} from '../../api-client/autogenerated';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import ConfidenceMatchesTable from './ConfidenceMatchesTable';
import FilePreviewDialog from '../dialogs/FilePreviewDialog';
import DCCFileCard from './DCCFileCard';
import { useHistory } from 'react-router-dom';
import { addSnackbar } from '../../features/snackbar/actions';
import { SubmitButton, TooltipIfDisabledComponent } from '../custom-components/CustomButtons';
import {
  deleteDocumentsByIds,
  ModifyDocumentRow,
  modifyDocumentsIndividually,
} from '../../models/api/documents';
import FullscreenLoader from '../loader/FullscreenLoader';
import DCCNavigationBlocker from '../custom-components/DCCNavigationBlocker';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
    },
    titleStyle: {
      backgroundColor: '#1F34BC',
      borderRadius: '4px 4px 0px 0px',
    },
    legend: {
      height: 16,
      width: 16,
      backgroundColor: blueHighlight,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'black',
    },
    deleteButton: {
      padding: '0px 10px',
      backgroundColor: '#ED3F26',
      marginRight: 12,
      lineHeight: 1,
      color: 'white',
      '&:hover': {
        backgroundColor: '#FF5D45',
      },
    },
  }),
);

export default function DCCSubmittals() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const project = useSelector(getProjectState);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<TableType | null>(null);

  const [rows, setRows] = useState<DCCRow[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [deletedRows, setDeletedRows] = useState<DCCRow[]>([]);

  const [limitHeight, setLimitHeight] = useState(true);

  const [projectSummary, setProjectSummary] = useState<IConformingCenterProjectSummary>();
  const [summary, setSummary] = useState<IConformingDocumentsSummary>();

  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [fileToView, setFileToView] = useState<File>();

  const checkedRows = rows.filter((r) => r.isChecked);
  const checkedDeletedRows = deletedRows.filter((r) => r.isChecked);

  const originalRows = useRef(rows);

  const fetchData = async (projectId: string) => {
    setIsLoading(true);
    const s = await getConformingCenterSummaryByProjectId(projectId);
    setProjectSummary(s);
    setSummary(s.submittalSummary);
    await fetchDocuments(projectId);
  };

  const fetchDocuments = async (projectId: string) => {
    const documents = await getConformingCenterDocumentsByType(
      projectId,
      DocumentTemplateType.Submittals,
    );
    const unpublishedDocuments = documents.filter(
      (doc) => doc.document.publishStatus === PublishStatusType.Unpublished,
    );
    const newRows = unpublishedDocuments
      .filter(({ document }) => !deletedRows.map((r) => r.id).includes(document.id))
      .map<DCCRow>(({ document, isConflicting, isGoodMatch, conflictType }) => {
        const fileName = project?.files?.find((f) => f.id === document.parsedFromFileId)?.name;
        return {
          id: document.id,
          number: document.submittalSection || '',
          paragraphNumber: document.submittalParagraphNumber || '',
          title: document.title || '',
          sectionDescription: document.submittalSectionDescription || '',
          pdfFileId: document.submittalSectionFileId || '',
          fileName,
          isConflicting,
          conflictType,
          isGoodMatch,
          isChecked: false,
          associatedUserIds: [],
          associatedGroupIds: [],
        };
      });
    setRows(newRows);
    originalRows.current = [...newRows];
    setIsLoading(false);
  };

  useEffect(() => {
    if (project) {
      fetchData(project.id);
    }
  }, [project]);

  const handleView = async (row: DCCRow) => {
    openInNewWindow(row.pdfFileId);
  };

  const handleIncrementalSave = async () => {
    const rowsToSave = [...rows].filter((row) => {
      const originalRow = originalRows.current.find((r) => r.id === row.id);
      if (!originalRow) return true;
      return (
        row.number !== originalRow.number ||
        row.title !== originalRow.title ||
        row.paragraphNumber !== originalRow.paragraphNumber
      );
    });
    const patches: ModifyDocumentRow[] = rowsToSave.map((row) => ({
      documentId: row.id,
      modification: {
        patch: {
          submittalSection: row.number,
          title: row.title,
          submittalParagraphNumber: row.paragraphNumber,
        },
      },
    }));
    if (patches.length > 0) {
      await modifyDocumentsIndividually(patches, false);
      originalRows.current = [...rows];
    }
  };

  const handleSaveOrPublish = async (willPublishSelected: boolean) => {
    if (!project) return;
    dispatch(blockNavigation());
    setIsSubmitting(TableType.Unpublished);
    const rowsToSave = willPublishSelected ? [...checkedRows] : [...rows];
    const patches: ModifyDocumentRow[] = rowsToSave.map((row) => {
      const patch: IRestrictedDocumentPatch = { isDraft: !willPublishSelected };
      const originalRow = originalRows.current.find((r) => r.id === row.id);
      if (row.number !== originalRow?.number) patch.submittalSection = row.number;
      if (row.title !== originalRow?.title) patch.title = row.title;
      if (row.paragraphNumber !== originalRow?.paragraphNumber)
        patch.submittalParagraphNumber = row.paragraphNumber;
      return {
        documentId: row.id,
        modification: { patch },
      };
    });
    await modifyDocumentsIndividually(patches);
    dispatch(
      addSnackbar({
        id: Date.now(),
        message: willPublishSelected
          ? `Successfully published ${patches.length} documents`
          : 'Saved changes!',
        severity: 'success',
      }),
    );
    dispatch(allowNavigation());
    if (!willPublishSelected) {
      history.push(`/main/projects/${project.id}/pub-center`);
    } else {
      await fetchData(project!.id);
    }
    setIsSubmitting(null);
  };

  const handleDelete = async (type: TableType, fromDialog = false) => {
    if (type === TableType.Deleted) {
      const documentIdsToDelete = (fromDialog ? [...deletedRows] : [...checkedDeletedRows]).map(
        (row) => row.id,
      );

      const proceed = window.confirm(
        `Are you sure you want to permanently delete ${documentIdsToDelete.length} submittal placeholders? You must re-parse to create these items again.`,
      );
      if (!proceed) return;
      dispatch(blockNavigation());
      setIsSubmitting(TableType.Deleted);
      await deleteDocumentsByIds(documentIdsToDelete);
      setDeletedRows((prev) => prev.filter((row) => !documentIdsToDelete.includes(row.id)));
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: `Successfully deleted ${documentIdsToDelete.length} items`,
          severity: 'success',
        }),
      );
      dispatch(allowNavigation());
      if (!fromDialog) {
        await fetchData(project!.id);
      }
      setIsSubmitting(null);
    } else {
      const rowsToDelete = [...checkedRows];
      setRows((prev) => prev.filter((p) => !rowsToDelete.map(({ id }) => id).includes(p.id)));
      setDeletedRows((prev) => [
        ...prev,
        ...rowsToDelete.map((row) => ({ ...row, isChecked: false })),
      ]);
    }
  };

  const handleClearAll = () => {
    setDeletedRows(rows.map((r) => ({ ...r, isChecked: false })));
    setRows([]);
  };

  const handleRestore = () => {
    const rowsToRestore = [...deletedRows].filter((row) => row.isChecked);
    setRows((prev) => [...prev, ...rowsToRestore.map((r) => ({ ...r, isChecked: false }))]);
    setDeletedRows((prev) => prev.filter((p) => !rowsToRestore.map(({ id }) => id).includes(p.id)));
  };

  const getCurrentFiles = (): IFile[] => {
    return (
      project?.files?.filter(
        (f) =>
          f.category === FileCategoryType.Specifications &&
          projectSummary?.summaryByParsedFromFileId[f.id] &&
          projectSummary?.summaryByParsedFromFileId[f.id].submittalSummary.unpublished
            .numDocuments > 0,
      ) || []
    );
  };

  const isPublishDisabled = () => {
    return checkedRows.length === 0 || checkedRows.some((row) => !row.title);
  };

  const handleSubmittalsLink = () => {
    history.push(
      `/main/projects/${project!.id}/documents/${
        documentTypeToUrl[DocumentTemplateType.Submittals]
      }`,
    );
  };

  const publishedCount = summary?.published.numDocuments || 0;
  const unpublishedCount = summary?.unpublished.numDocuments || 0;
  const totalCount = publishedCount + unpublishedCount;

  const getPageTitle = () => {
    if (publishedCount > 0) return 'Submittal Placeholders Conforming Center';
    return 'Submittal Placeholders Publishing Center';
  };

  return (
    <main className={classes.root}>
      <NavAppbar />
      <PageTitle title={getPageTitle()} />
      {!isLoading ? (
        <>
          <DCCFileCard type={PublishType.Submittals} files={getCurrentFiles()} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ fontWeight: 500, fontSize: 20 }}>
              Published Submittals - {publishedCount}/{totalCount}
            </Typography>
            <Button
              color="primary"
              size="large"
              onClick={handleSubmittalsLink}
              endIcon={<ArrowForward />}
              style={{ marginLeft: 16 }}
            >
              Go to Submittals
            </Button>
          </div>
          <Typography style={{ fontWeight: 500, fontSize: 18, color: '#797979', marginTop: 25 }}>
            Centerline has automatically generated the following list of proposed submittals from
            the uploaded specifications. Use the tool below to update submittal descriptions if
            needed and delete any unnecessary placeholders before publishing to Centerline.
          </Typography>

          {/* With Descriptions */}
          <Card style={{ marginTop: 15 }}>
            <CardContent
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                display: 'flex',
                borderBottom: '1px solid #D8D8D8',
              }}
            >
              <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
                <Typography style={{ fontWeight: 500, fontSize: 20, marginRight: 20 }}>
                  New Submittal Placeholders - {rows.length}
                </Typography>
                <div style={{ display: 'flex' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleSaveOrPublish(false)}
                    style={{ padding: '0px 10px', marginRight: 12, minWidth: 150 }}
                  >
                    Save All Changes
                  </Button>
                  <TooltipIfDisabledComponent
                    arrow
                    placement="top"
                    title={
                      checkedRows.length === 0
                        ? 'You must select at least one item'
                        : 'You may not publish an item without a description'
                    }
                    disabled={isPublishDisabled()}
                    spanStyle={{ width: undefined }}
                  >
                    <SubmitButton
                      color="primary"
                      variant="contained"
                      disabled={isPublishDisabled()}
                      onClick={() => handleSaveOrPublish(true)}
                      style={{
                        padding: '0px 10px',
                        marginRight: 12,
                        minWidth: 280,
                      }}
                    >
                      Publish Selected To Centerline
                    </SubmitButton>
                  </TooltipIfDisabledComponent>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleDelete(TableType.Unpublished)}
                    style={{
                      padding: '0px 10px',
                      backgroundColor: '#ED3F26',
                      minWidth: 180,
                      marginRight: 12,
                    }}
                  >
                    Move to Delete Queue
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={rows.length === 0}
                    onClick={handleClearAll}
                    className={classes.deleteButton}
                  >
                    Clear All
                  </Button>
                </div>
                <div style={{ display: 'inline-flex', flexGrow: 100 }} />

                <div>
                  {publishedCount > 0 && (
                    <div style={{ display: 'flex', marginBottom: 4 }}>
                      <div className={classes.legend} />
                      <Typography style={{ marginLeft: 6, marginRight: 16 }}>
                        Updates existing version
                      </Typography>
                    </div>
                  )}
                  <div style={{ display: 'flex' }}>
                    <div className={classes.legend} style={{ backgroundColor: yellowHighlight }} />
                    <Typography style={{ marginLeft: 6, marginRight: 16 }}>
                      Extra attention suggested
                    </Typography>
                  </div>
                </div>
              </div>
              <FormControl style={{ margin: '15px 0px' }}>
                <TextField
                  InputProps={{
                    style: { height: 25 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search style={{ color: '#B2B1B2' }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                  required
                  id="search"
                  placeholder="Search"
                  name="Search"
                  value={searchInput}
                  onChange={(event) => setSearchInput(event.target.value)}
                />
              </FormControl>
            </CardContent>
            <CardContent style={{ padding: 0 }}>
              <ConfidenceMatchesTable
                type={PublishType.Submittals}
                tableType={TableType.Unpublished}
                summary={summary}
                rows={rows}
                setRows={setRows}
                view={handleView}
                limitHeight={limitHeight}
                saveChanges={handleIncrementalSave}
                searchResult={searchInput}
                isLoading={isSubmitting === TableType.Unpublished}
              />
            </CardContent>
          </Card>
          <div
            style={{
              width: '90%',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 15,
              marginBottom: 25,
            }}
          >
            {!limitHeight ? (
              <Button color="primary" onClick={() => setLimitHeight((prev) => !prev)}>
                <ArrowForward style={{ transform: 'rotate(180deg)' }} /> See Less
              </Button>
            ) : (
              <Button color="primary" onClick={() => setLimitHeight((prev) => !prev)}>
                See All <ArrowForward />
              </Button>
            )}
          </div>

          <Typography style={{ fontWeight: 500, fontSize: 18, color: '#797979', marginTop: 25 }}>
            Restore or permanently delete items here.
          </Typography>
          {/* Deleted Items */}
          <Card style={{ marginTop: 8 }}>
            <CardContent
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                display: 'flex',
                borderBottom: '1px solid #D8D8D8',
              }}
            >
              <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
                <Typography style={{ fontWeight: 500, fontSize: 20, marginRight: 20 }}>
                  Deleted Submittal Placeholders - {deletedRows.length}
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleRestore}
                  style={{ padding: '0px 10px', marginRight: 15, minWidth: 150 }}
                >
                  Restore Selected
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleDelete(TableType.Deleted)}
                  style={{ padding: '0px 10px', backgroundColor: '#ED3F26', minWidth: 180 }}
                >
                  Delete Selected
                </Button>
              </div>
            </CardContent>
            <CardContent style={{ padding: 0 }}>
              <ConfidenceMatchesTable
                type={PublishType.Submittals}
                tableType={TableType.Deleted}
                summary={summary}
                rows={deletedRows}
                setRows={setDeletedRows}
                view={handleView}
                limitHeight
                searchResult=""
                isLoading={isSubmitting === TableType.Deleted}
              />
            </CardContent>
          </Card>
        </>
      ) : (
        <FullscreenLoader />
      )}
      <FilePreviewDialog
        open={viewDialogOpen}
        handleClose={() => {
          setViewDialogOpen(false);
          setTimeout(() => setFileToView(undefined), 100);
        }}
        file={fileToView}
      />
      <DCCNavigationBlocker
        shouldBlock={deletedRows.length > 0}
        deletedItemsCount={deletedRows.length}
        onDelete={() => handleDelete(TableType.Deleted, true)}
      />
    </main>
  );
}
