import React, { useEffect, useState } from 'react';
import { descendingComparator } from './document-index/DocumentIndexUtils';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getSnackbarState } from '../features/snackbar/selectors';
import { SnackbarConfig } from '../features/snackbar/snackbar';
import { removeSnackbar } from '../features/snackbar/actions';

export default function GlobalSnackbar() {
  const dispatch = useDispatch();
  const snackbars = useSelector(getSnackbarState);
  const [alerts, setAlerts] = useState<SnackbarConfig>([]);

  const [initializedAlertIds, setInitializedAlertIds] = useState<number[]>([]);

  const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
    // I commented this out for right now since clicking on the page gets rid of
    // all the current alerts.
    // -Daniel
    //dispatch(removeAllSnackbars());
  };

  const handleDropSnackbar = (event: React.SyntheticEvent, id: number) => {
    dispatch(removeSnackbar(id));
  };

  useEffect(() => {
    const uninitializedSnackbars = snackbars.filter((s) => !initializedAlertIds.includes(s.id));
    uninitializedSnackbars.forEach((s) => {
      initializedAlertIds.push(s.id);
      if (s.autoHideDuration !== null) {
        setTimeout(() => dispatch(removeSnackbar(s.id)), s.autoHideDuration || 5000);
      }
    });
  }, [snackbars]);

  return snackbars && snackbars.length && snackbars.length > 0 ? (
    <Snackbar
      open={true}
      autoHideDuration={null}
      onClose={handleAlertClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <div>
        {snackbars
          .filter((s) => s.message)
          .sort((a, b) => descendingComparator(a, b, 'id'))
          .map((snackbar) => (
            <Alert
              key={snackbar.id}
              onClose={(event) => handleDropSnackbar(event, snackbar.id)}
              severity={snackbar.severity}
              variant="filled"
              style={{ marginBottom: '2px', textAlign: 'center' }}
            >
              {snackbar.message}
            </Alert>
          ))}
      </div>
    </Snackbar>
  ) : null;
}
