import {
  Container,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { useDispatch, useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import {
  CancelButton,
  SubmitButton,
  TooltipIfDisabledComponent,
} from '../../main-components/custom-components/CustomButtons';
import { makeStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Close, HighlightOffRounded, InsertDriveFileOutlined } from '@material-ui/icons';
import SubmitIcon from '../../main-components/icons/Submit-icon';
import _ from 'lodash';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import {
  sendBidConnectFileDeliveryEmail,
  uploadBidConnectFile,
} from '../../models/api/project-bid-setups';
import { getNavigationState } from '../../features/navigation/selectors';
import { addSnackbar } from '../../features/snackbar/actions';
import CircularLoader from '../../main-components/loader/CircularLoader';
import PDFIcon from '../../main-components/icons/PDF-icon';
import DayjsUtils from '@date-io/dayjs';
import { Dayjs } from 'dayjs';
import { BidConnectFileDeliveryEmailRequest } from '../../api-client/autogenerated';

type Props = {
  open: boolean;
  onClose: () => void;
};

const useStyles = makeStyles(
  createStyles({
    textfield: {
      marginBottom: 8,
    },
    dragDropText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '22px',
      lineHeight: '32px',
      textAlign: 'center',
      textTransform: 'none',
      color: '#949494', // Gray 400
      marginTop: 8,
      marginBottom: 8,
    },
    browseFileButton: {
      border: '2px solid #0947B9',
      boxSizing: 'border-box',
      borderRadius: '4px',
      color: '#0947B9',
    },
    dropzoneStyling: {
      width: '100%',
      flexShrink: 0,
      background: '#F9F9F9',
      mixBlendMode: 'normal',
      border: '2px dashed #949494',
      boxSizing: 'border-box',
      borderRadius: '4px',
      paddingBottom: 24,
      paddingTop: 8,
      marginTop: 8,
    },
    title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '30px',
      textAlign: 'left',
      color: '#0947B9',
      textTransform: 'none',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    rootIconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    row: {
      display: 'flex',
      alignItems: 'center',
    },
    unSelected: {
      color: 'black',
      backgroundColor: '#D9D9D9',
      '&:hover': {
        color: 'black',
        backgroundColor: '#D9D9D9',
      },
    },
    selected: {
      backgroundColor: '#2A60C2',
      color: 'white',
      '&&': {
        backgroundColor: '#2A60C2',
        color: 'white',
        '&:hover': {
          color: 'white',
          backgroundColor: '#2A60C2',
        },
      },
    },
    disabled: {
      '&&': {
        backgroundColor: 'transparent',
        color: '#D9D9D9',
      },
    },
    unToggled: {
      color: 'white',
      backgroundColor: 'white',
    },
    toggled: {
      backgroundColor: 'white',
      color: 'white',
      '&&': {
        backgroundColor: '#0947B9',
        color: '#0947B9',
      },
      '&:hover': {
        color: '#0947B9',
      },
    },
  }),
);

export default function SendFilesDialog(props: Props) {
  const { open, onClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const shouldBlockNavigation = useSelector(getNavigationState);

  const [isNewProject, setIsNewProject] = useState<boolean>();

  const [inputProject, setInputProject] = useState('');
  const [inputCompany, setInputCompany] = useState('');
  const [inputContact, setInputContact] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputPhone, setInputPhone] = useState('');
  const [inputLink, setInputLink] = useState('');
  const [inputInstructions, setInputInstructions] = useState('');
  const [inputFiles, setInputFiles] = useState<File[]>([]);
  const [inputDescription, setInputDescription] = useState('');
  const [inputCost, setInputCost] = useState('');
  const [inputOwnerProjectNumber, setInputOwnerProjectNumber] = useState('');
  const [inputArchitectProjectNumber, setInputArchitectProjectNumber] = useState('');
  const [inputCity, setInputCity] = useState('');
  const [inputState, setInputState] = useState('');
  const [preBidDate, setPreBidDate] = useState<Dayjs | null>(null);
  const [bidDate, setBidDate] = useState<Dayjs | null>(null);
  const [requestDueDate, setRequestDueDate] = useState<Dayjs | null>(null);
  const [liveDate, setLiveDate] = useState<Dayjs | null>(null);
  const [isPrivate, setIsPrivate] = useState(false);

  const [allowSubstitutionRequests, setAllowSubstitutionRequests] = useState(true);
  const [allowElectronicBidding, setAllowElectronicBidding] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Sending email...');
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (open && user.id) {
      setInputCompany(user?.company?.name || user.publicCompanyName || '');
      setInputContact(user.name);
      setInputEmail(user.email);
      setInputPhone(_.nth(user.phoneNumbers, 0)?.number || '');
    }
  }, [user, open]);

  const isSubmitDisabled = () => {
    if (isNewProject) {
      return (
        !inputProject ||
        !inputContact ||
        !inputCompany ||
        !inputEmail ||
        !bidDate ||
        !liveDate ||
        (!inputLink && inputFiles.length === 0) ||
        (!!inputLink && inputFiles.length > 0)
      );
    }
    return (
      !inputProject ||
      !inputContact ||
      !inputCompany ||
      !inputEmail ||
      (!inputLink && inputFiles.length === 0) ||
      (!!inputLink && inputFiles.length > 0)
    );
  };

  const resetState = () => {
    setIsNewProject(undefined);
    setInputProject('');
    setInputCompany('');
    setInputContact('');
    setInputEmail('');
    setInputPhone('');
    setInputInstructions('');
    setInputLink('');
    setInputFiles([]);
    setInputDescription('');
    setInputCost('');
    setInputOwnerProjectNumber('');
    setInputArchitectProjectNumber('');
    setInputCity('');
    setInputState('');
    setPreBidDate(null);
    setBidDate(null);
    setLiveDate(null);
    setRequestDueDate(null);
    setAllowSubstitutionRequests(true);
    setAllowElectronicBidding(true);
  };

  const handleClose = () => {
    if (shouldBlockNavigation) return;
    onClose();
    resetState();
  };

  const handleRemoveFile = (file: File) => {
    setInputFiles((prev) => prev.filter((f) => f !== file));
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      dispatch(blockNavigation());

      const objectKeys: string[] = [];

      for (let i = 0; i < inputFiles.length; i += 1) {
        const file = inputFiles[i];
        setLoadingMessage(`Uploading ${file.name}...`);
        const { objectKey } = await uploadBidConnectFile(file.name, file, handleUploadProgress);
        setUploadProgress(0);
        objectKeys.push(objectKey);
      }

      setLoadingMessage('Sending email...');

      const params: BidConnectFileDeliveryEmailRequest = isNewProject
        ? {
            projectName: inputProject,
            contactName: inputContact,
            company: inputCompany,
            email: inputEmail,
            phone: inputPhone,
            projectDescription: inputDescription,
            estimatedCost: inputCost,
            ownerProjectNumber: inputOwnerProjectNumber,
            architectProjectNumber: inputArchitectProjectNumber,
            city: inputCity,
            state: inputState,
            preBidDate: preBidDate?.toISOString() || undefined,
            bidDate: bidDate?.toISOString() || undefined,
            requestDueDate: requestDueDate?.toISOString() || undefined,
            allowElectronicSubstitutionRequests: allowSubstitutionRequests,
            allowElectronicBidding: allowElectronicBidding,
            liveDate: liveDate?.toISOString() || undefined,
            isPrivate,
            instructions: inputInstructions,
            customLink: inputLink,
            objectKeys,
          }
        : {
            projectName: inputProject,
            contactName: inputContact,
            company: inputCompany,
            email: inputEmail,
            phone: inputPhone,
            instructions: inputInstructions,
            customLink: inputLink,
            objectKeys,
          };

      await sendBidConnectFileDeliveryEmail(params);

      dispatch(
        addSnackbar({ id: Date.now(), message: 'Email successfully sent.', severity: 'success' }),
      );
    } finally {
      setIsLoading(false);
      dispatch(allowNavigation());
      onClose();
      resetState();
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <Dialog maxWidth="xs" open={open} PaperProps={{ style: { minWidth: 360 } }}>
        <DialogTitle>
          <div className={classes.titleContainer}>
            <span className={classes.title}>Send Us Files</span>
          </div>
          <IconButton
            style={{ right: '12px', top: '12px', position: 'absolute' }}
            onClick={handleClose}
            classes={{
              root: classes.rootIconButton,
            }}
          >
            <HighlightOffRounded />
          </IconButton>
          <Typography style={{ marginTop: 8 }}>
            Designers and project owners can use this form to send files to create/update your
            project in Centerline BidConnect.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>What are you sending today?</Typography>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="space-between"
            style={{ paddingTop: '10px', paddingBottom: '20px' }}
          >
            <Grid item style={{ height: '35px' }}>
              <ToggleButton
                classes={{
                  root: classes.unSelected,
                  selected: classes.selected,
                }}
                style={{ width: '300px', height: '30px', fontSize: 12 }}
                selected={isNewProject === true}
                onChange={() => setIsNewProject(true)}
              >
                Files for a new project
              </ToggleButton>
            </Grid>
            <Grid item style={{ height: '35px' }}>
              <ToggleButton
                classes={{ root: classes.unSelected, selected: classes.selected }}
                style={{ width: '300px', height: '30px', fontSize: 12 }}
                selected={isNewProject === false}
                onChange={() => setIsNewProject(false)}
              >
                Addendum for an existing project
              </ToggleButton>
            </Grid>
            <Grid item style={{ width: '100%', paddingTop: '20px' }}>
              <Container>
                {isNewProject !== undefined && (
                  <>
                    <Typography style={{ paddingBottom: '10px' }}>Contact Info</Typography>
                    <div className={classes.row} style={{ columnGap: 8 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="Company Name"
                        required
                        value={inputCompany}
                        onChange={(e) => setInputCompany(e.target.value)}
                        className={classes.textfield}
                      />
                      <TextField
                        size="small"
                        variant="outlined"
                        label="Email"
                        required
                        value={inputEmail}
                        onChange={(e) => setInputEmail(e.target.value)}
                        className={classes.textfield}
                      />
                    </div>
                    <div className={classes.row} style={{ columnGap: 8, marginBottom: 24 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="Contact Name"
                        required
                        value={inputContact}
                        onChange={(e) => setInputContact(e.target.value)}
                        className={classes.textfield}
                      />

                      <TextField
                        size="small"
                        variant="outlined"
                        label="Phone Number"
                        value={inputPhone}
                        onChange={(e) => setInputPhone(e.target.value)}
                        className={classes.textfield}
                      />
                    </div>
                    <Typography style={{ paddingBottom: '10px' }}>Project Info</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Project Name"
                      required
                      value={inputProject}
                      onChange={(e) => setInputProject(e.target.value)}
                      className={classes.textfield}
                    />
                  </>
                )}
                {isNewProject && (
                  <>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      variant="outlined"
                      label="Project Description"
                      onChange={(e) => setInputDescription(e.target.value)}
                      className={classes.textfield}
                      style={{ marginBottom: 24, marginTop: 24 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Estimated Construction Cost"
                      onChange={(e) => setInputCost(e.target.value)}
                      className={classes.textfield}
                      style={{ marginBottom: 24 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Owner Project Number"
                      onChange={(e) => setInputOwnerProjectNumber(e.target.value)}
                      className={classes.textfield}
                      style={{ marginBottom: 24 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Architect Project Number"
                      onChange={(e) => setInputArchitectProjectNumber(e.target.value)}
                      className={classes.textfield}
                      style={{ marginBottom: 24 }}
                    />
                    <div className={classes.row} style={{ columnGap: 8, marginBottom: 16 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="City"
                        onChange={(e) => setInputCity(e.target.value)}
                        className={classes.textfield}
                      />

                      <TextField
                        size="small"
                        variant="outlined"
                        label="State"
                        onChange={(e) => setInputState(e.target.value)}
                        className={classes.textfield}
                      />
                    </div>

                    <KeyboardDateTimePicker
                      fullWidth
                      inputVariant="outlined"
                      InputProps={{ style: { padding: 0 } }}
                      disablePast
                      size="small"
                      value={preBidDate}
                      placeholder="Pre-Bid Date"
                      format="MM/DD/YYYY hh:mm A"
                      onChange={(date) => setPreBidDate(date)}
                      className={classes.textfield}
                    />

                    <KeyboardDateTimePicker
                      fullWidth
                      inputVariant="outlined"
                      InputProps={{ style: { padding: 0 } }}
                      disablePast
                      size="small"
                      value={bidDate}
                      placeholder="Bid Date *"
                      format="MM/DD/YYYY hh:mm A"
                      onChange={(date) => setBidDate(date)}
                      className={classes.textfield}
                      style={{ marginBottom: 24 }}
                    />

                    <div style={{ marginBottom: 24 }}>
                      <KeyboardDateTimePicker
                        inputVariant="outlined"
                        InputProps={{ style: { padding: 0 } }}
                        disablePast
                        fullWidth
                        size="small"
                        value={requestDueDate}
                        placeholder="RFI/Subst. Request Submission Deadline"
                        format="MM/DD/YYYY hh:mm A"
                        onChange={(date) => setRequestDueDate(date)}
                        className={classes.textfield}
                      />
                    </div>
                    <Typography>Allow Electronic RFI/Substitution Request Submission?</Typography>
                    <RadioGroup
                      value={allowSubstitutionRequests}
                      onChange={(e, value) => setAllowSubstitutionRequests(value === 'true')}
                      row
                      style={{ marginBottom: 16 }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio color="primary" />}
                        label="Yes"
                        style={{ marginRight: 64 }}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                    <Typography>Allow Electronic Bid Submission?</Typography>
                    <RadioGroup
                      value={allowElectronicBidding}
                      onChange={(e, value) => setAllowElectronicBidding(value === 'true')}
                      row
                      style={{ marginBottom: 24 }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio color="primary" />}
                        label="Yes"
                        style={{ marginRight: 64 }}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>

                    <KeyboardDateTimePicker
                      inputVariant="outlined"
                      InputProps={{ style: { padding: 0 } }}
                      disablePast
                      fullWidth
                      size="small"
                      value={liveDate}
                      placeholder='When should the project go "live"? *'
                      format="MM/DD/YYYY hh:mm A"
                      onChange={(date) => setLiveDate(date)}
                      className={classes.textfield}
                      style={{ marginBottom: 24 }}
                    />

                    <Typography>Is this project public or private?</Typography>
                    <RadioGroup
                      value={isPrivate}
                      onChange={(e, value) => setIsPrivate(value === 'true')}
                      row
                      style={{ marginBottom: isPrivate ? 12 : 0 }}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio color="primary" />}
                        label="Public"
                        style={{ marginRight: 64 }}
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio color="primary" />}
                        label="Private"
                      />
                    </RadioGroup>

                    {isPrivate && (
                      <Typography>
                        Add email addresses for invited bidders in the "Additional Instructions" box
                        below.
                      </Typography>
                    )}
                  </>
                )}
                {isNewProject !== undefined && (
                  <>
                    <TextField
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      label="Additional Instructions"
                      value={inputInstructions}
                      onChange={(e) => setInputInstructions(e.target.value)}
                      style={{ marginBottom: 24, marginTop: 24 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Link to files (Dropbox, Google Drive, etc.)"
                      type="url"
                      value={inputLink}
                      onChange={(e) => setInputLink(e.target.value)}
                      className={classes.textfield}
                    />
                    <Typography align="center" style={{ fontWeight: 500 }}>
                      OR
                    </Typography>
                    <div className={classes.dropzoneStyling}>
                      <Dropzone
                        accept={['.pdf', '.zip']}
                        onDropAccepted={(files) => {
                          setInputFiles((prev) => [...prev, ...files]);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            style={{ outline: 'none' }}
                            {...getRootProps({ className: 'dropzone' })}
                          >
                            <input {...getInputProps()} />
                            <p className={classes.dragDropText}>Drag &amp; Drop files here</p>
                            <p className={classes.dragDropText}>or</p>
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button variant="outlined" className={classes.browseFileButton}>
                                <Add />
                                Browse Files
                              </Button>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        gap: 8,
                        marginTop: 16,
                      }}
                    >
                      {inputFiles.map((file) => (
                        <div key={file.name} className={classes.row} style={{ minWidth: 190 }}>
                          <IconButton
                            onClick={() => handleRemoveFile(file)}
                            style={{ padding: 0, marginRight: 2 }}
                          >
                            <Close />
                          </IconButton>
                          {file.name.toLowerCase().endsWith('.pdf') ? (
                            <PDFIcon style={{ flexShrink: 0 }} />
                          ) : (
                            <InsertDriveFileOutlined
                              htmlColor="#7A797A"
                              style={{ flexShrink: 0 }}
                            />
                          )}
                          <Typography
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              marginLeft: 6,
                            }}
                          >
                            {file.name}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </Container>
            </Grid>
          </Grid>
        </DialogContent>
        <Typography align="center" style={{ fontWeight: 500, marginTop: 16 }}>
          NOT FOR ELECTRONIC BID SUBMISSIONS
        </Typography>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 16,
            marginTop: 8,
          }}
        >
          {!isLoading ? (
            <>
              <CancelButton onClick={handleClose} />
              <TooltipIfDisabledComponent
                arrow
                placement="top"
                title="You may not attach a link and upload a file. Please remove one or the other."
                disabled={!!inputLink && inputFiles.length > 0}
                spanStyle={{ width: 'initial' }}
              >
                <SubmitButton
                  disabled={isSubmitDisabled()}
                  onClick={handleSubmit}
                  startIcon={<SubmitIcon fill="white" />}
                  style={{ paddingLeft: 25, paddingRight: 25 }}
                >
                  Submit
                </SubmitButton>
              </TooltipIfDisabledComponent>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <Typography>{loadingMessage}</Typography>
              {loadingMessage.includes('Uploading') ? (
                <>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                    style={{ width: '100%', height: 6, margin: '4px 0px' }}
                  />
                  <Typography>{uploadProgress}%</Typography>
                </>
              ) : (
                <CircularLoader style={{ marginTop: 8 }} />
              )}
            </div>
          )}
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
}
