import React from 'react';
import { IProjectUser } from '../../api-client/autogenerated';
import { TableCell, TableRow, Button, Typography } from '@material-ui/core';
import { formatPhoneNumber } from '../../scripts/utils';
import { useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import { deletePrimeBidder } from '../../models/api/prime-bidder';

type Props = {
  data: IProjectUser;
  setPrimeBidders: React.Dispatch<React.SetStateAction<IProjectUser[]>>;
};

const PrimeBidderRow = (props: Props) => {
  const { data, setPrimeBidders } = props;
  const user = useSelector(getUserState);

  const canDeletePrimeBidder = user.isSiteAdmin || user.id === data.userId;

  const handleDelete = async () => {
    await deletePrimeBidder(data.userId, data.projectId);
    setPrimeBidders((prev) => prev.filter((x) => x.id !== data.id));
  };

  return (
    <TableRow>
      <TableCell>
        <Typography>
          {data.primeBidder!.contractorLicenseNo
            ? `#${data.primeBidder!.contractorLicenseNo}`
            : 'N/A'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {data.primeBidder!.companyName ? <strong>{data.primeBidder!.companyName}</strong> : ''}
        </Typography>
        <Typography>
          {data.primeBidder!.address ? data.primeBidder!.address.address1 : ''}
        </Typography>
        <Typography>
          {(data.primeBidder!.address?.city ? `${data.primeBidder!.address.city}, ` : '') +
            [
              data.primeBidder!.address?.stateOrProvince?.name,
              data.primeBidder!.address?.postalCode,
            ].join(' ')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          <strong>{data.primeBidder!.alternateName || data.primeBidder!.contactName}</strong>
        </Typography>
        <Typography>{data.primeBidder!.alternateEmail || data.primeBidder!.email}</Typography>
        <Typography>
          {formatPhoneNumber(
            data.primeBidder!.alternatePhoneNumber || data.primeBidder!.phoneNumber?.number || '',
          ) || ''}
        </Typography>
      </TableCell>
      <TableCell>
        {canDeletePrimeBidder && (
          <Button color="secondary" variant="contained" onClick={handleDelete}>
            Remove
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default PrimeBidderRow;
