import { ApiClient } from '../../api-client/api-client';
import { IInsertionPrimeBidder } from '../../api-client/autogenerated';
import { DefaultApiDeletePrimeBidderByUserAndProjectRequest } from '../../api-client/autogenerated';

// eslint-disable-next-line import/prefer-default-export
export const postPrimeBidder = async (
  userId: string,
  projectId: string,
  primeBidder: IInsertionPrimeBidder,
) => {
  return ApiClient.insertPrimeBidderByUserAndProject({
    userId,
    projectId,
    iInsertionPrimeBidder: primeBidder,
  });
};

export const deletePrimeBidder = async (userId: string, projectId: string) => {
  return ApiClient.deletePrimeBidderByUserAndProject({
    userId,
    projectId,
  });
};
