import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import NameCompanyText from '../lists/NameCompanyText';
import SecondaryDateText from '../lists/SecondaryDateText';
import MessageText from '../lists/MessageText';
import { parseDate } from '../../scripts/utils';

interface DashboardMessageProps {
  date: string;
  name: string;
  company: string;
  message: string;
}

const useStyles = makeStyles((theme) => ({
  chat: {
    border: '1px solid #EDECEC',
    borderRadius: 4,
    padding: 10,
    // display: 'flex',
    background: '#EDECEC',
    '&::before': {
      // triangle
      content: '""',
      width: 0,
      height: 0,
      marginLeft: '-20px',
      marginTop: 10,
      borderTop: '7px solid transparent',
      borderRight: '9px solid #EDECEC', // gray 100
      borderBottom: '7px solid transparent',
    },
  },
  chatText: {
    paddingLeft: 10,
  },
  paddingTop: {
    [theme.breakpoints.up('md')]: {
      paddingTop: 15,
    },
  },
  paddingBottom: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 15,
    },
  },
}));

export default function DashboardMessage(props: DashboardMessageProps) {
  const classes = useStyles();
  const { date, name, company, message } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={
            <>
              <Grid container alignItems="flex-start">
                <Grid item xs={12} sm={12} md={1} lg={2} className={classes.paddingTop}>
                  <Typography color="textSecondary">{parseDate(date).format('MMM DD')}</Typography>
                </Grid>
                <Grid item xs={4} sm={2} md={1} lg={2} className={classes.paddingBottom}>
                  <ListItemAvatar>
                    <Avatar alt="" />
                  </ListItemAvatar>
                </Grid>
                <Grid
                  item
                  container
                  xs={8}
                  sm={10}
                  md={10}
                  lg={8}
                  className={classes.chat}
                  alignItems="stretch"
                >
                  <Grid item xs={12}>
                    <Typography className={classes.chatText}>
                      <NameCompanyText name={name} company={company} />
                      <MessageText message={message} />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.chatText} align="right">
                      <SecondaryDateText date={date} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          }
        />
      </ListItem>
    </div>
  );
}
