import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { Auth } from '@aws-amplify/auth';
import CircularLoader from '../loader/CircularLoader';
import { useDispatch } from 'react-redux';
import { addSnackbar } from '../../features/snackbar/actions';

const useStyles = makeStyles({
  root: {
    width: '100%',
    textAlign: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 8,
  },
  submit: {
    marginTop: 16,
    marginBottom: 24,
    maxWidth: 240,
  },
  textField: {
    maxWidth: 350,
  },
  links: {
    maxWidth: 400,
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    textDecoration: 'none',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#0947B9',
    '&:hover': {
      color: '#4380ED',
    },
  },
});

type ForgotPasswordProps = {
  setForgotPassword: (forgotPassword: boolean) => void;
  existingEmail?: string;
  isLettermansLogin?: boolean;
};

function ForgotPassword({
  setForgotPassword,
  existingEmail,
  isLettermansLogin,
}: ForgotPasswordProps) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [inputUsername, setInputUsername] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPassword = async (event?: React.FormEvent) => {
    event?.stopPropagation();
    event?.preventDefault();
    const input = existingEmail?.trim() || inputUsername.trim();
    if (!input || submitted) return;
    setIsLoading(true);
    try {
      await Auth.forgotPassword(input);
      setSubmitted(true);
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: !!existingEmail
            ? 'Check your inbox for reset email.'
            : 'If this user exists, an email will be in your inbox.',
          severity: 'success',
        }),
      );
    } catch (e: any) {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: e.message || 'Something went wrong resetting your password',
          severity: 'error',
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (existingEmail) setInputUsername(existingEmail);
  }, [existingEmail]);

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h5">
        Reset password
      </Typography>
      {isLettermansLogin && (
        <Typography style={{ marginBottom: 8 }}>
          For security purposes, you must reset your password to continue.
        </Typography>
      )}
      <Typography>
        Click the button below to send a password reset email to your email address.
      </Typography>
      <form onSubmit={handleForgotPassword} className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          // disabled={!!existingEmail}
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={inputUsername}
          onChange={(e) => setInputUsername(e.target.value)}
          className={classes.textField}
        />
        {isLoading ? (
          <CircularLoader />
        ) : (
          <Button
            fullWidth
            disabled={!inputUsername || submitted}
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submit}
          >
            Send Reset Email
          </Button>
        )}
        <Grid container className={classes.links}>
          <Grid item>
            <Button onClick={() => setForgotPassword(false)} className={classes.link}>
              Return to login
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default ForgotPassword;
