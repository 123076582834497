import React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';

export interface ImageForPrint {
  image: ArrayBuffer | string;
  description?: string | null;
}

export function* getImageRowsOld(images: ImageForPrint[]) {
  for (let i = 0; i < images.length; i += 2) {
    const image1 = images[i];
    const image2 = i + 1 < images.length ? images[i + 1] : null;
    yield (
      <TableRow>
        <TableCell>
          <img src={image1.image!.toString()} alt="" style={{ maxWidth: '100%' }} />
          <Typography variant={'body1'}>{image1.description}</Typography>
        </TableCell>
        <TableCell>
          {image2 != null && (
            <>
              <img src={image2.image!.toString()} alt="" style={{ maxWidth: '100%' }} />
              <Typography variant={'body1'}>{image2.description}</Typography>
            </>
          )}
        </TableCell>
      </TableRow>
    );
  }
}
export function* getImageRows(images: ImageForPrint[]) {
  for (let i = 0; i < images.length; i += 2) {
    const image1 = images[i];
    const image2 = i + 1 < images.length ? images[i + 1] : null;
    yield (
      <div className="image-row">
        <div className="image-with-caption" style={{ marginRight: 16 }}>
          <img src={image1.image!.toString()} alt="" className="image" />
          <Typography variant={'body1'} className="caption">
            {image1.description}
          </Typography>
        </div>

        {image2 != null && (
          <div className="image-with-caption">
            <img src={image2.image!.toString()} alt="" className="image" />
            <Typography variant={'body1'} className="caption">
              {image2.description}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

export function* getTableRows(items: string[]) {
  for (let i = 0; i < items.length; i += 3) {
    const item1 = items[i];
    const item2 = i + 1 < items.length ? items[i + 1] : null;
    const item3 = i + 2 < items.length ? items[i + 2] : null;
    yield (
      <TableRow>
        <TableCell style={{ width: '33%' }}>
          <Typography variant="body1">{item1}</Typography>
        </TableCell>
        <TableCell>{item2 != null && <Typography variant="body1">{item2}</Typography>}</TableCell>
        <TableCell>{item3 != null && <Typography variant="body1">{item3}</Typography>}</TableCell>
      </TableRow>
    );
  }
}
