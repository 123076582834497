import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { makeStyles } from '@material-ui/core/styles';
import { CheckBoxOutlined } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import clsx from 'clsx';
import StepStatus from './StepStatus';
import { IUser } from '../../api-client/autogenerated';
import AssignAndSubmit from './AssignAndSubmit';
import ReviewAndReturn from './ReviewAndReturn';
import { formatDate } from '../../scripts/utils';
import Complete from '../simple-action-center/Complete';

interface MobileTaskStepProps {
  label: string;
  status: StepStatus;
  date: string;
  index: number;
  defaultContractor?: IUser;
  defaultArchitect?: IUser;
  handleClose: () => void;
  currentIndex: number;
  onClick?: () => void;
  isOpen?: boolean;

  isRfi?: boolean;
  isWcpr?: boolean;
  documentTitle?: string;
}

const useStyles = makeStyles({
  accordion: {
    display: 'flex',
    maxWidth: 80,
    margin: 0,
    transition: 'max-width 440ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
  },
  accordionExpanded: {
    maxWidth: 600,
    margin: 0,
  },
  accordionSummary: {
    minWidth: '80px',
    width: '80px',
    height: '100%',
    backgroundColor: '#2BB073',
    color: 'white',
    padding: 0,
    flexDirection: 'column',
    justifyContent: 'flex-end',

    transition:
      'margin 440ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, min-width 440ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, width 440ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  containerActive: {
    background: '#2BB073',
    '&:hover': {
      background: '#31cc85',
    },
  },
  containerDisabled2: {
    background: '#0947B9',
  },
  containerDisabled3: {
    background: '#002366',
  },
  accordionSummaryContent: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    overflow: 'hidden',
    margin: 0,
  },
  accordionSummaryContentExpanded: {
    margin: 0,
    padding: 0,
    minWidth: 0,
    width: 0,
  },
  textDirection: {
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    transform: 'rotate(-180deg)',
    fontWeight: 500,
    fontSize: '15px',
  },
  index: {
    fontWeight: 900,
    fontSize: '25px',
    color: '#E5EEFF',
    opacity: 0.5,
    paddingTop: 12,
    paddingBottom: 8,
  },
});

export default function MobileTaskStep(props: MobileTaskStepProps) {
  const classes = useStyles();
  const {
    label,
    status,
    date,
    onClick,
    isOpen = false,
    index,
    handleClose,
    defaultContractor,
    defaultArchitect,
    isRfi = false,
    isWcpr = false,
    documentTitle,
    currentIndex,
  } = props;

  const [maxHeight, setMaxHeight] = useState(200);

  const getIcon = () => {
    return <CheckBoxOutlined htmlColor="white" />;
  };

  const getDialog = (num: number) => {
    console.log(num);
    if (num != -1) {
      if (num === 0 && !isRfi && !isWcpr)
        return (
          <AssignAndSubmit
            defaultContractor={defaultContractor}
            defaultArchitect={defaultArchitect}
            handleClose={handleClose}
            setMaxHeight={setMaxHeight}
          />
        );
      if (num === 1)
        return (
          <ReviewAndReturn
            handleClose={handleClose}
            setMaxHeight={setMaxHeight}
            isRfi={isRfi}
            isWcpr={isWcpr}
            documentTitle={documentTitle}
          />
        );
      if (num === 2 && isRfi)
        return (
          <Complete
            handleClose={handleClose}
            setMaxHeight={setMaxHeight}
            documentTitle={documentTitle}
          />
        );
    }
    return undefined;
  };

  return <div>{getDialog(currentIndex)}</div>;
}
