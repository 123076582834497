import { ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { SET_USER, UPDATE_USER, SET_IMPERSONATED_USER } from './constants';
import * as userActions from './actions';
import { User } from './auth-user';

export type UserAction = ActionType<typeof userActions>;
export type UserState = {
  user: User;
  originalUser: User | null;
};

export default combineReducers<UserState, UserAction>({
  user: (
    state = {
      id: '',
      lastUpdatedByUserId: '',
      name: '',
      email: '',
    },
    action,
  ) => {
    let newState: User = state;
    switch (action.type) {
      case SET_USER:
        newState = action.payload;
        break;
      case UPDATE_USER:
        if (state) newState = { ...state, ...action.payload };
        break;
      default:
        newState = state;
    }
    return newState;
  },
  originalUser: (state = null, action) => {
    let newState: User | null = state;
    switch (action.type) {
      case SET_IMPERSONATED_USER:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
});
