import React, { useEffect, useRef, useState } from 'react';
import { FormControlLabel, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import NavAppbar from '../nav-top/NavAppbar';
import PageTitle from '../page-title/PageTitle';
import SubmittalActionCenter from '../submittal-action-center/SubmittalActionCenter';
import SubmittalsInThisPackage from '../submittals-in-this-package/SubmittalsInThisPackage';
import SummaryCard from '../summary-card/SummaryCard';
import SupportingDocumentsCard from '../supporting-documents/SupportingDocumentsCard';
import SupportingDocumentsFieldReports from '../supporting-documents/SupportingDocumentsFieldReports';
import ConfidentialAdditionalReview from '../confidential-additional-review/ConfidentialAdditionalReview';
import Activity from '../activity/Activity';
import {
  DocumentPriorityType,
  DocumentTemplateType,
  GetUserDocumentPermissionResponse,
  IComment,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import { getDocumentState } from '../../features/document/selectors';
import FullscreenLoader from '../loader/FullscreenLoader';
import {
  DocumentMatchParams,
  documentTypeToUrl,
  getPageTitle,
  getSubtitle,
  getTitle,
  getUserFacingDocumentStatus,
  isPublicPage,
  setPublicPageRedirect,
} from '../../scripts/utils';
import DocumentCommentDialog from '../dialogs/DocumentCommentDialog';
import { getCommentById, insertCommentByDocumentId } from '../../models/api/comments';
import SimpleActionCenter from '../simple-action-center/SimpleActionCenter';
import { getDocumentsType } from '../../features/documents/selectors';
import Associations from '../associations/Associations';
import { getBidState } from '../../features/bid/selectors';
import logoSvg from '../../images/wordmark-blue.svg';
import { getPermissionByUserIdAndDocumentId } from '../../models/api/documents';
import { getUserState } from '../../features/user/selectors';
import CommentsCard from '../comment/CommentsCard';
import { setDocument, updateDocument } from '../../features/document/actions';
import { getDocPermission } from '../../scripts/store-utils';
import AssociatedDocumentList from '../associated-document-list/AssociatedDocumentList';
import ToggleReview from '../custom-components/ToggleReview';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import Button from '@material-ui/core/Button';
import AddDocumentDialog from '../dialogs/AddDocumentDialog';

interface DocumentDetailsContentProps {
  enableCurrentlyResponsible?: boolean;
  enableAdditionalReview?: boolean;
  enableSupportingDocuments?: boolean;
  disableOptions?: boolean;
  isRfi?: boolean;
  isWcpr?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: '100%', // align bottom of grid items
  },
  margin: {
    margin: theme.spacing(1),
    paddingTop: '3px',
    width: 'min-content',
    height: '70%', // make buttons same height
    background: 'transparent',
  },
  logo: {
    width: '256px',
    marginBottom: '24px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  minWidth: {
    //minWidth: 1450,
    maxWidth: '100%',
  },
  finalExecuted: {
    '&.Mui-disabled': {
      color: '#464546',
    },
  },
}));

const DocumentDetailsContent: React.FC<DocumentDetailsContentProps> = (props) => {
  const classes = useStyles();
  const {
    enableCurrentlyResponsible = false,
    enableAdditionalReview = false,
    enableSupportingDocuments = true,
    disableOptions = false,
    isRfi = false,
    isWcpr = false,
  } = props;

  const { commentId } = useParams<DocumentMatchParams>();
  const dispatch = useDispatch();
  const document = useSelector(getDocumentState);
  const bid = useSelector(getBidState);
  const docType = useSelector(getDocumentsType);
  const history = useHistory();
  const user = useSelector(getUserState);
  const permission = getDocPermission();
  const isUnder700px = useMediaQuery('(max-width: 700px)');

  const isPackage =
    document?.documentTemplate?.name === DocumentTemplateType.SubmittalPackages ||
    document?.documentTemplate?.name === DocumentTemplateType.CloseoutSubmittalPackages ||
    document?.documentTemplate?.name === DocumentTemplateType.AsBuiltPackages;

  const createDialogAnchor = useRef(null);

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [commentDialogOpen, setCommentDialogOpen] = useState(true);
  const [comment, setComment] = useState<IComment>();
  const [docPermissions, setDocPermissions] = useState<GetUserDocumentPermissionResponse>();

  useEffect(() => {
    if (document && !isPublicPage()) {
      getPermissionByUserIdAndDocumentId(user.id, document.id).then((res) =>
        setDocPermissions(res),
      );
    }
  }, [document]);

  const canSeeConsultantCard = () => {
    return (
      (document?.additionalReviewDocuments && document.additionalReviewDocuments.length > 0) ||
      (document?.bluebeamStudioSessionId &&
        (docPermissions?.isAssignableToDocument || user.id === document.assignedToUserId))
    );
  };

  const handlePublicPageLogin = () => {
    setPublicPageRedirect(
      `projects/${document!.projectId!}/documents/${documentTypeToUrl[docType!]}/${document!.id!}`,
    );
    dispatch(setDocument(null));
    history.push('/main/login');
  };

  const handleSubmitComment = async (comment: string) => {
    if (document && comment) {
      const newComment = await insertCommentByDocumentId(document.id, {
        text: comment,
        creatorUserId: user.id,
      });
      dispatch(
        updateDocument({ ...document, comments: [...(document.comments || []), newComment] }),
      );
    }
  };

  const showProcoreFieldsInSummary = !!document?.procoreId || !!document?.isResponseOnProcore;

  useEffect(() => {
    if (commentId)
      getCommentById(commentId)
        .then(setComment)
        .catch(() => setComment(undefined));
  }, [docType]);

  const shouldShowCreateNewSubmittalButton = () => {
    return (
      docType === DocumentTemplateType.Submittals &&
      permission &&
      permission >= 2 &&
      document?.workflowStatus &&
      [
        WorkflowStatusType.SubmittedForReview,
        WorkflowStatusType.UnderReview,
        WorkflowStatusType.ArchitectUploaded,
        WorkflowStatusType.Completed,
      ].includes(document.workflowStatus)
    );
  };

  const summaryCardComponent = (
    <SummaryCard
      title={getPageTitle(docType!, document, bid)}
      status={getUserFacingDocumentStatus(document)}
      enableCurrentlyResponsible={enableCurrentlyResponsible!}
      syncedWithProcore={showProcoreFieldsInSummary ? !!document?.procoreId : undefined}
      responseOnProcore={showProcoreFieldsInSummary ? !!document?.isResponseOnProcore : undefined}
    />
  );

  return (
    <main className={classes.content}>
      {!isPublicPage() && <NavAppbar />}
      {isPublicPage() && (
        <img
          src={logoSvg}
          className={classes.logo}
          alt="centerline-logo"
          onClick={handlePublicPageLogin}
        />
      )}
      {document || bid ? (
        <>
          <PageTitle
            title={getPageTitle(docType!, document, bid)}
            subtitle={getSubtitle(document)}
            hasOptions={!!document && !disableOptions}
          />
          {commentId && (
            <DocumentCommentDialog
              open={commentDialogOpen}
              handleClose={() => setCommentDialogOpen(false)}
              comment={comment}
            />
          )}

          <div style={{ display: 'flex' }}>
            {(docType === DocumentTemplateType.BidderRfIs ||
              docType === DocumentTemplateType.SubstitutionRequests) &&
              permission &&
              permission >= 3 && (
                <div style={{ marginBottom: 16 }}>
                  <ToggleReview />
                </div>
              )}
            {docType === DocumentTemplateType.PayApplications && (
              <FormControlLabel
                control={
                  <GreenCheck
                    checked={!!document?.isFinalExecuted}
                    disabled
                    id="do-procore-integration"
                    name="Final Executed"
                  />
                }
                label="Final Executed"
                classes={{ label: classes.finalExecuted }}
              />
            )}
            {shouldShowCreateNewSubmittalButton() ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCreateDialogOpen(true)}
                ref={createDialogAnchor}
                style={{
                  marginBottom: 16,
                  paddingTop: isUnder700px ? 28 : undefined,
                  paddingBottom: isUnder700px ? 28 : undefined,
                  width: isUnder700px ? '100%' : undefined,
                }}
              >
                Create a New Submittal For This Requirement
              </Button>
            ) : null}
          </div>

          <div className={classes.root}>
            <Grid
              container
              spacing={2}
              direction="column"
              wrap="nowrap"
              style={{ display: 'flex' }}
            >
              {(docType === DocumentTemplateType.Submittals ||
                docType === DocumentTemplateType.CloseoutSubmittals) &&
                !isPublicPage() && (
                  <Grid item xs={12} style={{ zIndex: 2, alignSelf: 'stretch' }}>
                    <SubmittalActionCenter docPermissions={docPermissions} />
                  </Grid>
                )}
              {(docType === DocumentTemplateType.RequestsForChange ||
                docType === DocumentTemplateType.WorkChangeProposalRequests ||
                docType === DocumentTemplateType.RequestsForInformation ||
                docType === DocumentTemplateType.PotentialChangeOrders ||
                docType === DocumentTemplateType.PayApplications ||
                ((docType === DocumentTemplateType.Task ||
                  docType === DocumentTemplateType.DesignPackages) &&
                  document?.priority !== DocumentPriorityType.ForInformationOnly)) && (
                <Grid item xs={12} style={{ zIndex: 2, alignSelf: 'stretch' }}>
                  <SimpleActionCenter
                    documentTitle={getTitle(docType!, document)}
                    docPermissions={docPermissions}
                    isRfi={isRfi}
                    isWcpr={isWcpr}
                  />
                </Grid>
              )}

              {enableAdditionalReview && canSeeConsultantCard() ? (
                <Grid item xs={12} lg={8} className={classes.minWidth}>
                  <ConfidentialAdditionalReview
                    isPackage={isPackage}
                    title={getTitle(docType!, document)}
                  />
                </Grid>
              ) : null}

              {(docType === DocumentTemplateType.FieldReports ||
                docType === DocumentTemplateType.WarrantyItems) && (
                <Grid item xs={12} lg={8} className={classes.minWidth}>
                  {summaryCardComponent}
                </Grid>
              )}

              {enableSupportingDocuments && (
                <Grid
                  item
                  xs={12}
                  lg={8}
                  className={classes.minWidth}
                  style={{ alignSelf: 'stretch' }}
                >
                  {docType === DocumentTemplateType.FieldReports ||
                  docType === DocumentTemplateType.WarrantyItems ? (
                    <SupportingDocumentsFieldReports title={getPageTitle(docType!, document)} />
                  ) : (
                    <SupportingDocumentsCard
                      title={getPageTitle(docType!, document, bid)}
                      isBidTabulation={docType === DocumentTemplateType.BidTabulation}
                      submittedDate={document ? document.dateSubmitted : bid?.createdOn}
                    />
                  )}
                </Grid>
              )}

              {(docType === DocumentTemplateType.Task ||
                docType === DocumentTemplateType.AsBuilt) && (
                <Grid item xs={12} lg={8} className={classes.minWidth}>
                  {summaryCardComponent}
                </Grid>
              )}

              {/* comments */}
              {(isPublicPage() && document && document.comments && document.comments.length > 0) ||
              (!isPublicPage() && document) ? (
                <Grid item container xs={12} lg={8} className={classes.minWidth} spacing={0}>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ boxShadow: '0px 5px 15px rgb(0, 0, 0, .15)' }}
                  >
                    <CommentsCard
                      handleSubmit={handleSubmitComment}
                      comments={document?.comments}
                      disableAddComment={
                        !!docType &&
                        [
                          DocumentTemplateType.BidderRfIs,
                          DocumentTemplateType.SubstitutionRequests,
                        ].includes(docType)
                      }
                    />
                  </Grid>
                </Grid>
              ) : null}

              <Grid item container lg={8} className={classes.minWidth}>
                {isPackage && document && (
                  <Grid item xs={12}>
                    <SubmittalsInThisPackage />
                  </Grid>
                )}
              </Grid>

              {/* summary */}
              {docType !== DocumentTemplateType.AsBuilt &&
                docType !== DocumentTemplateType.Task &&
                docType !== DocumentTemplateType.FieldReports &&
                docType !== DocumentTemplateType.WarrantyItems && (
                  <Grid item xs={12} lg={8} className={classes.minWidth}>
                    {summaryCardComponent}
                  </Grid>
                )}

              {/* distribution */}
              {document && (
                <Grid item xs={12} lg={8} className={classes.minWidth}>
                  <Associations title={getPageTitle(docType!, document)} />
                </Grid>
              )}

              {document && docType !== DocumentTemplateType.Task && (
                <Grid item xs={12} lg={8} className={classes.minWidth}>
                  <AssociatedDocumentList title={getPageTitle(docType!, document)} />
                </Grid>
              )}

              {(document || bid) && (
                <Grid item xs={12} lg={8} className={classes.minWidth}>
                  <Activity title={getPageTitle(docType!, document, bid)} />
                </Grid>
              )}
            </Grid>
          </div>
        </>
      ) : (
        <FullscreenLoader />
      )}
      {docType === DocumentTemplateType.Submittals ? (
        <AddDocumentDialog
          dialogOpen={createDialogOpen}
          handleClose={() => setCreateDialogOpen(false)}
          anchor={createDialogAnchor.current}
          isDocumentDetails
        />
      ) : null}
    </main>
  );
};

export default DocumentDetailsContent;
