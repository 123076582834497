import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import pluralize from 'pluralize';
import {
  DocumentTemplateType,
  ProjectSummaryType,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import { getProjectSummaryById } from '../../models/api/project';
import CircularLoader from '../loader/CircularLoader';
import { getProjectState } from '../../features/project/selectors';
import { documentTypeToUrl, formatMoney, parseDate } from '../../scripts/utils';
import {
  Divider,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

const useStyles = makeStyles({
  root: {
    // height: '291px',
    left: '235px',
    top: '1511px',
  },
  titleStyle: {
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
  },
  amount: {
    paddingBottom: 8,
    fontSize: 28,
    fontWeight: 500,
    lineHeight: '32px',
  },
  amountGreen: {
    paddingBottom: 8,
    fontSize: 28,
    fontWeight: 500,
    lineHeight: '32px',
    color: '#188D57',
  },
  amountRed: {
    paddingBottom: 8,
    fontSize: 28,
    fontWeight: 500,
    lineHeight: '32px',
    color: '#D02C14',
  },
  description: {
    fontSize: 16,
    letterSpacing: 1,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  onTrack: {
    fontSize: 32,
    letterSpacing: 1,
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#188D57',
    paddingBottom: 15,
    lineHeight: '2rem',
  },
  grid: {
    textAlign: 'center',
    paddingTop: 30,
  },
  button: {
    height: 150,
    width: 150,
    margin: 4,
    paddingLeft: 7,
    paddingTop: 16,
    borderRadius: 8,
    border: '1px solid',
    backgroundColor: 'rgba(9,71,185,0.4)',
  },
  buttonTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  buttonDividerHorizontal: {
    marginTop: 4,
    marginRight: 8,
    backgroundColor: '#464546',
  },
  buttonDividerVertical: {
    height: 28,
    marginTop: -4,
    marginLeft: 4,
    marginRight: 8,
    backgroundColor: '#464546',
  },
  buttonRow: {
    display: 'flex',
    height: 28,
    paddingTop: 4,
    marginRight: 8,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(9,71,185,0.4)',
    },
  },
  buttonLabelNumber: {
    textAlign: 'right',
    minWidth: 26,
  },
  table: {
    height: 308,
    '& td, th': {
      border: '1px solid',
    },
  },
  noBlueHover: {
    backgroundColor: '#464546',
  },
  blueHover: {
    backgroundColor: '#464546',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(9,71,185,0.4)',
    },
  },
});

type Props = {
  isDocumentLogTablePage?: boolean;
};

export default function DashboardChanges(props: Props) {
  const { isDocumentLogTablePage } = props;

  const classes = useStyles();
  const history = useHistory();
  const project = useSelector(getProjectState);

  const [projectSummary, setProjectSummary] = useState<ProjectSummaryType>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchProjectSummary = () => {
    return getProjectSummaryById(project!.id)
      .then((result) => {
        setProjectSummary(result);
      })
      .catch(() => {
        setProjectSummary(undefined);
      });
  };

  useEffect(() => {
    if (project) {
      setIsLoading(true);
      fetchProjectSummary().finally(() => setIsLoading(false));
    }
  }, [project]);

  const handleLink = (docType: DocumentTemplateType, status?: string) => {
    history.push(
      `/main/projects/${project!.id}/documents/${documentTypeToUrl[docType]}`,
      status && {
        status,
      },
    );
  };

  const getClass = (amount: number) => {
    if (amount > 0) return classes.amountRed;
    if (amount < 0) return classes.amountGreen;
    return classes.amount;
  };

  const getSign = (amount: number) => {
    if (amount > 0) return '+';
    return '';
  };

  const getInitialConstructionDays = () => {
    if (!project) return 0;
    if (project.scheduledEndDate && project.noticeToProceedDate)
      return parseDate(project.scheduledEndDate).diff(
        parseDate(project.noticeToProceedDate),
        'days',
      );
    return 0;
  };

  const initialContractAmount = project?.contractAmount ? Number(project.contractAmount) : 0;
  const contractDifference = projectSummary?.costAdded ?? 0;
  const newContractAmount = initialContractAmount + contractDifference;

  const initialConstructionDays = getInitialConstructionDays();
  const dayDifference = projectSummary?.daysAdded ?? 0;
  const newConstructionDays = initialConstructionDays + dayDifference;

  const initialScheduledEndDate = project?.scheduledEndDate
    ? parseDate(project.scheduledEndDate).valueOf()
    : null;
  const currentScheduledEndDate = initialScheduledEndDate
    ? initialScheduledEndDate + dayDifference * 86400000
    : null;

  const tableContent = (
    <>
      <Hidden smDown>
        <TableContainer style={{ marginLeft: 4 }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  onClick={() => handleLink(DocumentTemplateType.ChangeOrders)}
                  className={isDocumentLogTablePage ? classes.noBlueHover : classes.blueHover}
                >
                  <Typography className={classes.description} style={{ color: '#FFF' }}>
                    {projectSummary?.numCo} Approved
                    <br />
                    Change Orders
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="textPrimary" className={classes.description}>
                    Contract Amount
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="textPrimary" className={classes.description}>
                    Construction Days
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="textPrimary" className={classes.description}>
                    Scheduled End Date
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="right">
                  <Typography color="textPrimary" className={classes.description}>
                    Value at NTP
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="textPrimary" className={classes.amount}>
                    {formatMoney(initialContractAmount)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="textPrimary" className={classes.amount}>
                    {initialConstructionDays} days
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    color="textPrimary"
                    className={classes.amount}
                    style={{ fontSize: 24, whiteSpace: 'nowrap' }}
                  >
                    {initialScheduledEndDate
                      ? dayjs(initialScheduledEndDate).format('MMMM DD, YYYY')
                      : 'N/A'}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow style={{ backgroundColor: '#FFF' }}>
                <TableCell align="right">
                  <Typography color="textPrimary" className={classes.description}>
                    Cumulative Approved Changes
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    align="right"
                    color="textPrimary"
                    className={getClass(contractDifference)}
                  >
                    {getSign(contractDifference)}
                    {formatMoney(contractDifference)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="textPrimary" className={getClass(dayDifference)}>
                    {getSign(dayDifference)}
                    {dayDifference} {pluralize('day', dayDifference)}
                  </Typography>
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell align="right">
                  <Typography color="textPrimary" className={classes.description}>
                    Current Calculated Values
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography align="right" color="textPrimary" className={classes.amount}>
                    {formatMoney(newContractAmount)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography align="right" color="textPrimary" className={classes.amount}>
                    {newConstructionDays} days
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    color="textPrimary"
                    className={classes.amount}
                    style={{ fontSize: 24, whiteSpace: 'nowrap' }}
                  >
                    {currentScheduledEndDate
                      ? dayjs(currentScheduledEndDate).format('MMMM DD, YYYY')
                      : 'N/A'}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
      <Hidden mdUp>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            align="center"
            color="textPrimary"
            className={classes.description}
            style={{ fontSize: 24, lineHeight: '28px', marginBottom: 16 }}
          >
            {projectSummary?.numCo} Approved Change Orders
          </Typography>
          <Divider variant="middle" style={{ width: '100%' }} />
          <Typography
            color="textPrimary"
            className={classes.description}
            style={{ fontSize: 20, marginTop: 16, marginBottom: 24 }}
          >
            Contract Amount
          </Typography>
          <Typography
            color="textPrimary"
            className={classes.description}
            style={{ marginBottom: 4 }}
          >
            Value at NTP
          </Typography>
          <Typography align="right" color="textPrimary" className={classes.amount}>
            {formatMoney(initialContractAmount)}
          </Typography>
          <Typography
            color="textPrimary"
            className={classes.description}
            style={{ marginBottom: 4 }}
          >
            Cumulative Approved Changes
          </Typography>
          <Typography align="right" color="textPrimary" className={getClass(contractDifference)}>
            {getSign(contractDifference)}
            {formatMoney(contractDifference)}
          </Typography>
          <Typography
            color="textPrimary"
            className={classes.description}
            style={{ marginBottom: 4 }}
          >
            Current Calculated Values
          </Typography>
          <Typography align="right" color="textPrimary" className={classes.amount}>
            {formatMoney(newContractAmount)}
          </Typography>
          <Divider variant="middle" style={{ width: '100%' }} />
          <Typography
            color="textPrimary"
            className={classes.description}
            style={{ fontSize: 20, marginTop: 16, marginBottom: 24 }}
          >
            Construction Days
          </Typography>
          <Typography
            color="textPrimary"
            className={classes.description}
            style={{ marginBottom: 4 }}
          >
            Value at NTP
          </Typography>
          <Typography color="textPrimary" className={classes.amount}>
            {initialConstructionDays} days
          </Typography>
          <Typography
            color="textPrimary"
            className={classes.description}
            style={{ marginBottom: 4 }}
          >
            Cumulative Approved Changes
          </Typography>
          <Typography color="textPrimary" className={getClass(dayDifference)}>
            {getSign(dayDifference)}
            {dayDifference} {pluralize('day', dayDifference)}
          </Typography>
          <Typography
            color="textPrimary"
            className={classes.description}
            style={{ marginBottom: 4 }}
          >
            Current Calculated Values
          </Typography>
          <Typography color="textPrimary" className={classes.amount}>
            {newConstructionDays} days
          </Typography>
          <Divider variant="middle" style={{ width: '100%' }} />
          <Typography
            color="textPrimary"
            className={classes.description}
            style={{ fontSize: 20, marginTop: 16, marginBottom: 24 }}
          >
            Scheduled End Date
          </Typography>
          <Typography
            color="textPrimary"
            className={classes.description}
            style={{ marginBottom: 4 }}
          >
            Value at NTP
          </Typography>
          <Typography color="textPrimary" className={classes.amount} style={{ fontSize: 24 }}>
            {initialScheduledEndDate
              ? dayjs(initialScheduledEndDate).format('MMMM DD, YYYY')
              : 'N/A'}
          </Typography>
          <Typography
            color="textPrimary"
            className={classes.description}
            style={{ marginBottom: 4 }}
          >
            Current Calculated Values
          </Typography>
          <Typography color="textPrimary" className={classes.amount} style={{ fontSize: 24 }}>
            {currentScheduledEndDate
              ? dayjs(currentScheduledEndDate).format('MMMM DD, YYYY')
              : 'N/A'}
          </Typography>
        </div>
      </Hidden>
    </>
  );

  if (isDocumentLogTablePage) return tableContent;

  return (
    <Card className={classes.root}>
      <CardContent className={classes.titleStyle}>
        <h2
          style={{
            textAlign: 'left',
            color: '#FFFFFF',
            margin: 0,
          }}
        >
          Changes
        </h2>
      </CardContent>
      <CardContent>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 48,
            }}
          >
            <CircularLoader />
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Hidden mdDown>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={classes.button}>
                  <Typography className={classes.buttonTitle}>RFC</Typography>
                  <Divider className={classes.buttonDividerHorizontal} />
                  <div
                    onClick={() =>
                      handleLink(
                        DocumentTemplateType.RequestsForChange,
                        WorkflowStatusType.SubmittedForReview,
                      )
                    }
                    className={classes.buttonRow}
                  >
                    <Typography className={classes.buttonLabelNumber}>
                      {projectSummary?.requestsForChange.numNew}
                    </Typography>
                    <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                    <Typography>New</Typography>
                  </div>
                  <div
                    onClick={() =>
                      handleLink(
                        DocumentTemplateType.RequestsForChange,
                        WorkflowStatusType.UnderReview,
                      )
                    }
                    className={classes.buttonRow}
                  >
                    <Typography className={classes.buttonLabelNumber}>
                      {projectSummary?.requestsForChange.numUnderReview}
                    </Typography>
                    <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                    <Typography>Under Review</Typography>
                  </div>
                  <div
                    onClick={() => handleLink(DocumentTemplateType.RequestsForChange, 'overdue')}
                    className={classes.buttonRow}
                  >
                    <Typography className={classes.buttonLabelNumber}>
                      {projectSummary?.requestsForChange.numOverdue}
                    </Typography>
                    <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                    <Typography>Overdue</Typography>
                  </div>
                </div>
                <div className={classes.button}>
                  <Typography className={classes.buttonTitle}>PCO</Typography>
                  <Divider className={classes.buttonDividerHorizontal} />
                  <div
                    onClick={() =>
                      handleLink(
                        DocumentTemplateType.PotentialChangeOrders,
                        WorkflowStatusType.SubmittedForReview,
                      )
                    }
                    className={classes.buttonRow}
                  >
                    <Typography className={classes.buttonLabelNumber}>
                      {projectSummary?.potentialChangeOrders.numNew}
                    </Typography>
                    <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                    <Typography>New</Typography>
                  </div>
                  <div
                    onClick={() =>
                      handleLink(
                        DocumentTemplateType.PotentialChangeOrders,
                        WorkflowStatusType.UnderReview,
                      )
                    }
                    className={classes.buttonRow}
                  >
                    <Typography className={classes.buttonLabelNumber}>
                      {projectSummary?.potentialChangeOrders.numUnderReview}
                    </Typography>
                    <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                    <Typography>Under Review</Typography>
                  </div>
                  <div
                    onClick={() =>
                      handleLink(DocumentTemplateType.PotentialChangeOrders, 'overdue')
                    }
                    className={classes.buttonRow}
                  >
                    <Typography className={classes.buttonLabelNumber}>
                      {projectSummary?.potentialChangeOrders.numOverdue}
                    </Typography>
                    <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                    <Typography>Overdue</Typography>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={classes.button}>
                  <Typography className={classes.buttonTitle}>WCPR</Typography>
                  <Divider className={classes.buttonDividerHorizontal} />
                  <div
                    onClick={() =>
                      handleLink(
                        DocumentTemplateType.WorkChangeProposalRequests,
                        WorkflowStatusType.SubmittedForReview,
                      )
                    }
                    className={classes.buttonRow}
                  >
                    <Typography className={classes.buttonLabelNumber}>
                      {projectSummary?.workChangePropReqs.numNew}
                    </Typography>
                    <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                    <Typography>New</Typography>
                  </div>
                  <div
                    onClick={() =>
                      handleLink(
                        DocumentTemplateType.WorkChangeProposalRequests,
                        WorkflowStatusType.UnderReview,
                      )
                    }
                    className={classes.buttonRow}
                  >
                    <Typography className={classes.buttonLabelNumber}>
                      {projectSummary?.workChangePropReqs.numUnderReview}
                    </Typography>
                    <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                    <Typography>Under Review</Typography>
                  </div>
                  <div
                    onClick={() =>
                      handleLink(DocumentTemplateType.WorkChangeProposalRequests, 'overdue')
                    }
                    className={classes.buttonRow}
                  >
                    <Typography className={classes.buttonLabelNumber}>
                      {projectSummary?.workChangePropReqs.numOverdue}
                    </Typography>
                    <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                    <Typography>Overdue</Typography>
                  </div>
                </div>
                <div className={classes.button}>
                  <Typography className={classes.buttonTitle}>CCD</Typography>
                  <Divider className={classes.buttonDividerHorizontal} />
                  <div
                    onClick={() => handleLink(DocumentTemplateType.ConstructionChangeDirectives)}
                    className={classes.buttonRow}
                  >
                    <Typography className={classes.buttonLabelNumber}>
                      {projectSummary?.constChangeDirectives.numIssued}
                    </Typography>
                    <Divider orientation="vertical" className={classes.buttonDividerVertical} />
                    <Typography>Issued</Typography>
                  </div>
                </div>
              </div>
            </Hidden>
            {tableContent}
          </div>
        )}
      </CardContent>
    </Card>
  );
}
