import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    color: 'red',
  },
});

export default function RedAsterick() {
  const classes = useStyles();
  return <span className={classes.root}>*</span>;
}
