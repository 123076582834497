import React, { useCallback, useEffect, useState } from 'react';
import {
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  MobileStepper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { generateUniqueId } from '../../scripts/utils';
import {
  AreaHighlight,
  IHighlight,
  PdfHighlighter,
  PdfLoader,
  Popup,
  Scaled,
} from 'react-pdf-highlighter';
import {
  ArrowBack,
  ArrowForward,
  Close,
  DeleteForeverOutlined,
  Edit,
  Replay,
  SaveOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
  ZoomIn,
  ZoomOut,
} from '@material-ui/icons';
import CustomTip from './CustomTip';
import './RegionSelector.css';
import { PDFViewer } from 'pdfjs-dist/legacy/web/pdf_viewer';
import { SubmitButton } from '../custom-components/CustomButtons';
import CircularLoader from '../loader/CircularLoader';
import { IPhysicalLocation } from '../../api-client/autogenerated';

export type RoomRegionType = {
  x: number;
  y: number;
  W: number;
  H: number;
  num: number;
  title: string;
  image: Blob;
  scale: number;
  width: number;
};

export interface PunchListLocationsResult {
  floorPlanImageFile: File;
  rooms: RoomRegionType[];
}

const useStyles = makeStyles(() =>
  createStyles({
    rootContainer: {
      display: 'flex',
      overflow: 'hidden',
    },
    controlsContainer: {
      position: 'absolute',
      zIndex: 100,
      padding: 8,
      borderRight: '1px solid #000',
      borderBottom: '1px solid #000',
      background: '#FFF',
    },
    pdfContainer: {
      display: 'flex',
      height: '100vh',
      width: '80vw',
      position: 'relative',
    },
    previewContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '20vw',
      height: '100%',
      padding: '32px 64px',
      alignItems: 'center',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    previewContainer2: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflowX: 'auto',
    },
    previewItem: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      marginBottom: 16,
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '20vw',
      padding: '16px 32px',
      justifyContent: 'space-evenly',
    },
    instructionsText: {
      fontSize: 18,
      lineHeight: '21px',
      // marginBottom: 16,
    },
    stepper: {
      backgroundColor: '#FFF',
      fontSize: 16,
      width: '100%',
    },
    previewTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 48,
      padding: 8,
      marginBottom: 8,
      marginTop: 8,
    },
    previewTitle: {
      fontSize: 22,
      lineHeight: '24px',
      fontWeight: 500,
      width: '100%',
    },
    previewImage: {
      width: '100%',
      height: '35vh',
      objectFit: 'contain',
    },
    previewButton: {
      padding: 0,
    },
    stepperButton: {
      padding: 0,
      fontSize: '1.9rem',
    },
  }),
);

type Props = {
  open: boolean;
  file: File;
  existingLocations?: IPhysicalLocation[];
  onClose: () => void;
  onSubmit: (results: PunchListLocationsResult) => Promise<void>;
};

interface ICustomHighlight extends IHighlight {
  existing?: boolean;
}

const HighlightPopup = ({ comment }: { comment: { text: string } }) =>
  comment.text ? <div className="Highlight__popup">{comment.text}</div> : null;

const ErrorMessage = (props: { error?: Error }) => {
  const { error } = props;
  return <div>{error?.message}</div>;
};

export const ZOOM_FACTOR = 0.7;

export default function PunchListRegionSelector(props: Props) {
  const classes = useStyles();
  const { open, file, existingLocations, onClose, onSubmit } = props;

  let pos = { top: 0, left: 0, x: 0, y: 0 };

  const [localFileUrl, setLocalFileUrl] = useState(URL.createObjectURL(file));
  const [floorPlanImage, setFloorPlanImage] = useState<Blob | null>(null);

  const [pdfContainer, setPdfContainer] = useState<HTMLDivElement | null>();
  const [pdfViewer, setPdfViewer] = useState<PDFViewer>();

  const [scrollPosition, setScrollPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });

  const [naturalWidth, setNaturalWidth] = useState(0);
  const [naturalHeight, setNaturalHeight] = useState(0);
  const [pdfScale, setPdfScale] = useState(1);

  const [highlights, setHighlights] = useState<ICustomHighlight[]>([]);
  const [highlightsHidden, setHighlightsHidden] = useState(false);
  const [deletedHighlights, setDeletedHighlights] = useState<ICustomHighlight[]>([]);

  const [stepperIndex, setStepperIndex] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleRef = useCallback<(node: PdfHighlighter<ICustomHighlight> | null) => void>((node) => {
    setPdfContainer(node?.containerNode);
    setPdfViewer(node?.viewer);
  }, []);

  const [existingHighlights, setExistingHighlights] = useState<ICustomHighlight[]>([]);

  const allHighlights = [...existingHighlights, ...highlights];

  const existingLocationNames = existingLocations?.map((l) => l.name);

  const newHighlightsHaveSameName = highlights.some((h1) =>
    highlights.find((h2) => h1.id !== h2.id && h1.comment.text === h2.comment.text),
  );

  const submitDisabled = highlights.length === 0 || newHighlightsHaveSameName;

  useEffect(() => {
    if (
      existingLocations &&
      naturalWidth !== 0 &&
      naturalHeight !== 0 &&
      existingHighlights.length === 0
    ) {
      setExistingHighlights(
        existingLocations
          .filter((l) => !!l.markedLocation)
          .map(({ markedLocation, ...l }) => {
            const s = markedLocation!.scale;
            const height = naturalHeight / (naturalWidth / markedLocation!.width);
            return {
              id: l.id,
              position: {
                boundingRect: {
                  x1: markedLocation!.x * s,
                  y1: markedLocation!.y * s,
                  x2: (markedLocation!.x + markedLocation!.W) * s,
                  y2: (markedLocation!.y + markedLocation!.H) * s,
                  width: markedLocation!.width,
                  height,
                },
                rects: [],
                pageNumber: 1,
              },
              comment: { text: l.name, emoji: '' },
              content: {},
              existing: true,
            };
          }),
      );
    }
  }, [existingLocations, naturalWidth, naturalHeight]);

  const addHighlight = (h: Omit<ICustomHighlight, 'id'>) => {
    setHighlights((prev) => {
      const newHighlights = [...prev, { id: generateUniqueId(), ...h }];
      setStepperIndex(newHighlights.length - 1);
      return newHighlights;
    });
  };

  const updateHighlight = (
    id: string,
    { boundingRect }: { boundingRect: Scaled },
    { image }: { image: string },
  ) => {
    setHighlights((prev) => {
      const index = prev.findIndex((h) => h.id === id);
      if (index !== -1) {
        const current = prev[index];
        return [
          ...prev.slice(0, index),
          {
            ...current,
            position: { ...current.position, boundingRect },
            content: { ...current.content, image },
          },
          ...prev.slice(index + 1),
        ];
      }
      return prev;
    });
  };

  function mouseMove(this: HTMLDivElement, event: MouseEvent | TouchEvent) {
    let dx: number, dy: number;
    if (event instanceof MouseEvent) {
      dx = event.clientX - pos.x;
      dy = event.clientY - pos.y;
    } else {
      dx = event.touches[0].clientX - pos.x;
      dy = event.touches[0].clientY - pos.y;
    }

    this.scrollTop = pos.top - dy;
    this.scrollLeft = pos.left - dx;
  }

  function mouseUp(this: HTMLDivElement, event: MouseEvent | TouchEvent) {
    if (event instanceof MouseEvent) {
      if (event.button === 2) {
        if (pdfContainer) {
          pdfContainer.removeEventListener('mousemove', mouseMove, false);
          pdfContainer.removeEventListener('mouseup', mouseUp, false);
        }

        this.style.cursor = 'grab';
        this.style.removeProperty('user-select');
      }
    } else {
      if (pdfContainer) {
        pdfContainer.removeEventListener('touchmove', mouseMove, false);
        pdfContainer.removeEventListener('touchend', mouseUp, false);
      }

      this.style.removeProperty('user-select');
    }
  }

  function mouseDown(this: HTMLDivElement, event: MouseEvent | TouchEvent) {
    if (event instanceof MouseEvent) {
      if (event.button === 2) {
        this.style.cursor = 'grabbing';
        this.style.userSelect = 'none';
        pos = { left: this.scrollLeft, top: this.scrollTop, x: event.clientX, y: event.clientY };

        if (pdfContainer) {
          pdfContainer.addEventListener('mousemove', mouseMove, false);
          pdfContainer.addEventListener('mouseup', mouseUp, false);
        }
      }
    } else {
      this.style.userSelect = 'none';
      pos = {
        left: this.scrollLeft,
        top: this.scrollTop,
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      };

      if (pdfContainer) {
        pdfContainer.addEventListener('touchmove', mouseMove, false);
        pdfContainer.addEventListener('touchend', mouseUp, false);
      }
    }
  }

  const toggleEdit = () => {
    setIsEditing((prev) => !prev);
  };

  const handleSaveEdit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // @ts-ignore
    const text = event.target.value as string;
    if (event.key === 'Enter') {
      if (text) {
        setHighlights((prev) => {
          const current = prev[stepperIndex];
          return [
            ...prev.slice(0, stepperIndex),
            { ...current, comment: { ...current.comment, text } },
            ...prev.slice(stepperIndex + 1),
          ];
        });
      }
      setIsEditing(false);
    }
  };

  const handleDelete = () => {
    const itemToDelete = highlights[stepperIndex];
    setHighlights((prev) => {
      if (stepperIndex === highlights.length - 1) {
        setStepperIndex((prev) => prev - 1);
      }
      return [...prev.slice(0, stepperIndex), ...prev.slice(stepperIndex + 1)];
    });
    setDeletedHighlights((prev) => [...prev, itemToDelete]);
  };

  const handleUndo = () => {
    const itemToRestore = [...deletedHighlights].pop();
    if (itemToRestore) {
      setHighlights((prev) => {
        const newHighlights = [...prev, itemToRestore];
        setStepperIndex(newHighlights.length - 1);
        return newHighlights;
      });
      setDeletedHighlights((prev) => prev.slice(0, -1));
    }
  };

  const getRoomRegionFromHighlight = async (
    highlight: ICustomHighlight,
    index: number,
  ): Promise<RoomRegionType> => {
    const {
      position: { boundingRect },
      content: { image },
      comment: { text },
    } = highlight;

    const { x1, y1, x2, y2, width } = boundingRect;

    const scale = width / naturalWidth;

    const imageBlob = await (await fetch(image!)).blob();

    return {
      x: x1 / scale,
      y: y1 / scale,
      W: (x2 - x1) / scale,
      H: (y2 - y1) / scale,
      num: index,
      title: text,
      image: imageBlob,
      scale,
      width,
    };
  };

  const handleClose = () => {
    const proceed = window.confirm(
      'Are you sure you want to exit? Your work in progress will not be saved.',
    );
    if (!proceed) return;
    resetState();
    onClose();
  };

  const handleSubmit = async () => {
    const proceed = window.confirm(
      'Are you finished with your room selections? Click "Cancel" to continue selecting rooms.',
    );
    if (!proceed) return;
    setIsLoading(true);
    const rooms = await Promise.all(highlights.map(getRoomRegionFromHighlight));
    await onSubmit({
      rooms,
      floorPlanImageFile: new File(
        [floorPlanImage!],
        file.name.substring(0, file.name.lastIndexOf('.')) + '.png',
      ),
    });
    setIsLoading(false);
    resetState();
  };

  const navigateToHighlight = (index: number) => {
    setStepperIndex(index);
  };

  const takeScreenshot = () => {
    const canvas = pdfContainer?.getElementsByTagName('canvas')[0];
    if (canvas) {
      setNaturalWidth(canvas.width);
      setNaturalHeight(canvas.height);
      if (!floorPlanImage) canvas.toBlob(setFloorPlanImage);
    }
  };

  const restoreScroll = () => {
    if (pdfContainer) {
      pdfContainer.scrollLeft = scrollPosition.left;
      pdfContainer.scrollTop = scrollPosition.top;
    }
  };

  const zoomOut = () => {
    if (pdfContainer) {
      setScrollPosition({
        top: pdfContainer.scrollTop * ZOOM_FACTOR,
        left: pdfContainer.scrollLeft * ZOOM_FACTOR,
      });
    }
    setPdfScale((prev) => prev * ZOOM_FACTOR);
    reload();
  };

  const zoomIn = () => {
    if (pdfContainer) {
      setScrollPosition({
        top: pdfContainer.scrollTop / ZOOM_FACTOR,
        left: pdfContainer.scrollLeft / ZOOM_FACTOR,
      });
    }
    setPdfScale((prev) => prev / ZOOM_FACTOR);
    reload();
  };

  const toggleHighlights = () => {
    setHighlightsHidden((prev) => !prev);
  };

  const resetState = () => {
    setFloorPlanImage(null);
    setPdfScale(1);
    setPdfViewer(undefined);
    setPdfContainer(undefined);
    setHighlights([]);
    setHighlightsHidden(false);
    setDeletedHighlights([]);
    setNaturalWidth(0);
    setNaturalHeight(0);
    setExistingHighlights([]);
    setIsEditing(false);
    setStepperIndex(0);
    setScrollPosition({ top: 0, left: 0 });
  };

  const reload = () => {
    setLocalFileUrl(URL.createObjectURL(file));
  };

  useEffect(() => {
    if (open) {
      window.scrollTo(0, 0);
    }
  }, [open]);

  useEffect(() => {
    reload();
  }, [file]);

  useEffect(() => {
    if (pdfContainer) {
      pdfContainer.style.cursor = 'grab';
      pdfContainer.addEventListener('mousedown', mouseDown, false);
      pdfContainer.addEventListener('touchstart', mouseDown, false);
    }
  }, [pdfContainer]);

  useEffect(() => {
    if (pdfViewer) {
      pdfViewer.eventBus.on('pagerender', restoreScroll);
      pdfViewer.eventBus.on('pagerendered', takeScreenshot, { once: true });
    }
  }, [pdfViewer]);

  const canZoomIn = naturalWidth / ZOOM_FACTOR < 5000;

  return (
    <Dialog open={open} fullScreen>
      <div className={classes.controlsContainer}>
        <Button startIcon={<Close />} onClick={handleClose}>
          Exit
        </Button>
        <Button startIcon={<ZoomOut />} onClick={zoomOut}>
          Zoom out
        </Button>
        <Button disabled={!canZoomIn} startIcon={<ZoomIn />} onClick={zoomIn}>
          Zoom in
        </Button>
        <Button
          startIcon={highlightsHidden ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
          onClick={toggleHighlights}
          style={{ minWidth: 171 }}
        >
          {`${highlightsHidden ? 'Show' : 'Hide'} Highlights`}
        </Button>
        <Button
          startIcon={<Replay />}
          onClick={handleUndo}
          disabled={deletedHighlights.length === 0}
        >
          Undo Delete
        </Button>
      </div>
      <div className={classes.rootContainer}>
        <div className={classes.pdfContainer}>
          <PdfLoader
            beforeLoad={<div>Loading...</div>}
            url={localFileUrl}
            // @ts-ignore
            errorMessage={<ErrorMessage />}
          >
            {(pdf) => {
              return (
                <PdfHighlighter
                  ref={handleRef}
                  pdfDocument={pdf}
                  enableAreaSelection={(event) => event.button === 0}
                  onScrollChange={() => {}}
                  pdfScaleValue={pdfScale.toString()}
                  scrollRef={() => {}}
                  onSelectionFinished={(
                    position,
                    content,
                    hideTipAndSelection,
                    transformSelection,
                  ) => (
                    <CustomTip
                      onOpen={transformSelection}
                      onConfirm={({ text }) => {
                        addHighlight({
                          content,
                          position,
                          comment: { text, emoji: '' },
                        });

                        hideTipAndSelection();
                      }}
                      existingLocationNames={existingLocationNames}
                    />
                  )}
                  highlightTransform={(
                    highlight,
                    index,
                    setTip,
                    hideTip,
                    viewportToScaled,
                    screenshot,
                    isScrolledTo,
                  ) => {
                    const component = (
                      <AreaHighlight
                        isScrolledTo={isScrolledTo}
                        highlight={highlight}
                        onChange={(boundingRect) => {
                          updateHighlight(
                            highlight.id,
                            { boundingRect: viewportToScaled(boundingRect) },
                            { image: screenshot(boundingRect) },
                          );
                        }}
                        // @ts-ignore
                        disableDragging
                        // @ts-ignore
                        onMouseDown={() => navigateToHighlight(index)}
                        // @ts-ignore
                        style={{
                          background: highlight.existing ? 'rgba(144, 238, 144, 0.6)' : undefined,
                        }}
                      />
                    );

                    return (
                      <Popup
                        popupContent={<HighlightPopup {...highlight} />}
                        onMouseOver={(popupContent) => setTip(highlight, () => popupContent)}
                        onMouseOut={hideTip}
                        key={index}
                        children={component}
                      />
                    );
                  }}
                  highlights={!highlightsHidden ? allHighlights : []}
                />
              );
            }}
          </PdfLoader>
        </div>
        <div className={classes.infoContainer}>
          <Typography className={classes.instructionsText}>
            Click and drag with the right mouse button to pan. Use the buttons in the top-left panel
            to zoom in or out as needed.
          </Typography>
          <Typography className={classes.instructionsText}>
            Click and drag with the left mouse button to draw a room. Select "Add Room" to save your
            selection. You may resize your selection by placing your mouse cursor at an edge or
            corner and dragging.
          </Typography>
          <Typography className={classes.instructionsText}>
            You may browse your selections below and rename or delete them if needed.
          </Typography>
          <div style={{ width: '100%' }}>
            <div className={classes.previewTitleContainer}>
              <Tooltip arrow placement="top" title={isEditing ? 'Cancel' : 'Rename'}>
                <IconButton
                  onClick={toggleEdit}
                  className={classes.previewButton}
                  style={{
                    visibility: !highlights[stepperIndex] ? 'hidden' : 'visible',
                  }}
                >
                  {!isEditing ? <Edit htmlColor="#0947B9" /> : <Close htmlColor="#0947B9" />}
                </IconButton>
              </Tooltip>

              {!isEditing ? (
                <Typography align="center" className={classes.previewTitle}>
                  {highlights[stepperIndex]?.comment.text}
                </Typography>
              ) : (
                <TextField
                  variant="outlined"
                  autoFocus
                  defaultValue={highlights[stepperIndex]?.comment.text}
                  onKeyDown={handleSaveEdit}
                  label="Press Enter to save"
                  InputProps={{ style: { height: 32 } }}
                  inputProps={{ style: { padding: '12px 10px' } }}
                  style={{ width: '100%', margin: '0px 12px' }}
                />
              )}

              <Tooltip arrow placement="top" title="Delete">
                <IconButton
                  onClick={handleDelete}
                  className={classes.previewButton}
                  style={{
                    visibility: !highlights[stepperIndex] ? 'hidden' : 'visible',
                  }}
                >
                  <DeleteForeverOutlined htmlColor="#D02C14" />
                </IconButton>
              </Tooltip>
            </div>

            <img
              src={highlights[stepperIndex] ? highlights[stepperIndex].content.image : undefined}
              alt={highlights[stepperIndex]?.comment.text}
              className={classes.previewImage}
            />
            <MobileStepper
              position="static"
              variant="text"
              activeStep={stepperIndex}
              backButton={
                <IconButton
                  disabled={stepperIndex <= 0}
                  onClick={() => setStepperIndex((prev) => prev - 1)}
                  className={classes.stepperButton}
                >
                  <ArrowBack
                    fontSize="inherit"
                    htmlColor={stepperIndex <= 0 ? undefined : '#0947B9'}
                  />
                </IconButton>
              }
              nextButton={
                <IconButton
                  disabled={stepperIndex + 1 >= highlights.length}
                  onClick={() => setStepperIndex((prev) => prev + 1)}
                  className={classes.stepperButton}
                >
                  <ArrowForward
                    fontSize="inherit"
                    htmlColor={stepperIndex + 1 >= highlights.length ? undefined : '#0947B9'}
                  />
                </IconButton>
              }
              steps={highlights.length}
              className={classes.stepper}
            />
          </div>
          <Typography className={classes.instructionsText}>
            Create as many rooms as you please, then select <strong>Finish Parsing.</strong>
          </Typography>
          {!isLoading ? (
            <SubmitButton
              disabled={submitDisabled}
              startIcon={<SaveOutlined />}
              onClick={handleSubmit}
              style={{ width: '100%' }}
            >
              Finish Parsing
            </SubmitButton>
          ) : (
            <CircularLoader />
          )}
        </div>
      </div>
    </Dialog>
  );
}
