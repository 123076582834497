import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { HighlightOffRounded } from '@material-ui/icons';
import { CancelButton } from '../custom-components/CustomButtons';
import LogoWhiteIcon from '../icons/LogoWhite-icon';

interface CreateShortcutDialogProps {
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fill: '#B2B1B2',
    marginLeft: '4px',
  },
  rootIconButton: {
    padding: '0px 8px 0px 0px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonContainer: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,
  },
}));

export default function CreateShortcutDialog(props: CreateShortcutDialogProps) {
  const { open, handleClose } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      PaperProps={{ style: { maxWidth: 480 } }}
      fullWidth
    >
      <DialogTitle>
        <Typography className={classes.title}>Create Desktop Shortcut</Typography>
        <IconButton
          style={{ right: '14px', top: '14px', position: 'absolute' }}
          onClick={() => handleClose()}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="body1">
          Drag and drop this icon onto your desktop to create a shortcut.
        </Typography>
        <a title="Centerline" href={window.location.origin} style={{ marginTop: 16 }}>
          <LogoWhiteIcon fill="#0947B9" />
          <div id="title" style={{ display: 'none' }}>
            Centerline
          </div>
        </a>
      </DialogContent>
      <div className={classes.buttonContainer}>
        <CancelButton onClick={handleClose}>Close</CancelButton>
      </div>
    </Dialog>
  );
}
