import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import AssignedUserText from '../custom-components/AssignedUserText';
import IconButton from '@material-ui/core/IconButton';
import PDFIcon from '../icons/PDF-icon';
import Typography from '@material-ui/core/Typography';
import {
  downloadFileById,
  getUserFriendlyConsultantResponse,
  openInNewTab,
} from '../../scripts/utils';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DownloadIcon from '../icons/Download-icon';
import { Button, MenuItem, Select, Tooltip } from '@material-ui/core';
import {
  ActionTakenType,
  DocumentTemplateType,
  IComment,
  INotification,
  NotificationType,
  RespondToReviewPropsResponseEnum,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import {
  Add,
  ChatBubbleOutline,
  Clear,
  Info,
  KeyboardArrowDown,
  KeyboardArrowUp,
  RotateLeft,
  WarningOutlined,
} from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentState } from '../../features/document/selectors';
import { undoCompleteReviewByDocumentId } from '../../models/api/documents';
import { reloadDocument } from '../../features/document/actions';
import { deleteFile } from '../../models/api/filesystem';
import { getUserState } from '../../features/user/selectors';
import dayjs from 'dayjs';
import { getDocumentsType } from '../../features/documents/selectors';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export interface RowData {
  id: string;
  reviewer: string;
  fileId: string;
  company: string;
  fileName: string;
  fileDate: number;
  fileCreatorUserId: string;
  isVerified?: boolean | null;
  response: ActionTakenType;
  comments: IComment[];
  assignedDate: string;
  dueDate: string;
  returnedDate: string;
  status: WorkflowStatusType;
  additionalReviewRecommendedById: string;
  reviewForPackage: boolean;
  title?: string;
  packageItems?: RowData[];
}

type Props = {
  row: RowData;
  getArchitectActionDisabled: (row: RowData) => boolean;
  getReviewerActionDisabled: (row: RowData) => boolean;
  getRowActionValue: (id: string) => string | undefined;
  canReviseSubmission: boolean;
  acceptOrReturn: (event: React.ChangeEvent<{ name?: string; value: unknown }>, id: string) => void;
  reviewOnClick: (id: string) => void;
  openComments: (id: string, comments: IComment[]) => void;
  parentIsPackage?: boolean;
  isPackageRow?: boolean;
  packageRows?: RowData[];
  isHidden?: boolean;
  notification?: INotification;
};

const useStyles = makeStyles(() =>
  createStyles({
    cellReviewer: {
      // maxWidth: '15%',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '22px',
      textTransform: 'none',
      color: '#464546',
      paddingRight: 0,
    },
    cellCheckbox: {
      // maxWidth: '15%',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '22px',
      textTransform: 'none',
      color: '#464546',
    },
    cellFile: {
      whiteSpace: 'nowrap',
    },
    cellResponse: {
      fontFamily: 'Roboto',
      fontStyle: 'italic',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      textTransform: 'none',
    },
    cellResponseGreen: {
      fontFamily: 'Roboto',
      fontStyle: 'italic',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      textTransform: 'none',
      color: '#128750',
    },
    cellResponseRed: {
      fontFamily: 'Roboto',
      fontStyle: 'italic',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      textTransform: 'none',
      color: '#ED3F26',
    },
    cellComments: {
      verticalAlign: 'middle',
    },
  }),
);

export default function ConfidentialAdditionalReviewRow(props: Props) {
  const classes = useStyles();
  const {
    row,
    getArchitectActionDisabled,
    getReviewerActionDisabled,
    getRowActionValue,
    canReviseSubmission,
    reviewOnClick,
    openComments,
    acceptOrReturn,
    notification,
    parentIsPackage = false,
    isPackageRow = false,
    packageRows = [],
    isHidden = false,
  } = props;

  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const docType = useSelector(getDocumentsType);
  const currentDocument = useSelector(getDocumentState);

  const [open, setOpen] = useState(true);

  const hasFile = row.returnedDate !== 'N/A' && row.fileName !== 'File Not Found';

  const isReviewForPackageItem = !!currentDocument?.submittalPackageDocumentId;

  const isReviewingOffline =
    notification?.format === NotificationType.DocumentConsultantDownloaded &&
    (row.status === WorkflowStatusType.SubmittedForReview ||
      row.status === WorkflowStatusType.UnderReview);

  const isReviewingInBluebeam =
    notification?.format === NotificationType.DocumentConsultantBluebeamStarted &&
    (row.status === WorkflowStatusType.SubmittedForReview ||
      row.status === WorkflowStatusType.UnderReview);

  const shouldHideResponse =
    docType === DocumentTemplateType.RequestsForInformation ||
    docType === DocumentTemplateType.DesignPackages;

  const getResponseClass = (response: ActionTakenType) => {
    // TODO: Decide text color for other types
    switch (response) {
      case ActionTakenType.NoExceptionsTaken:
        return classes.cellResponseGreen;

      case ActionTakenType.ReviseAndResubmit:
      case ActionTakenType.SubmitSpecifiedItem:
      case ActionTakenType.RemoveFromPackage:
        return classes.cellResponseRed;

      default:
        return classes.cellResponse;
    }
  };

  const getBackgroundColor = () => {
    if (isReviewingOffline)
      return {
        backgroundColor: '#FFBA34',
        color: 'white !important',
      };
    if (isReviewingInBluebeam)
      return {
        backgroundColor: '#B6CEFC',
        color: 'white !important',
      };
    return {};
  };

  const handleReviseSubmission = async () => {
    const proceed = window.confirm('Are you sure you want to revise your submission?');
    if (proceed) {
      await undoCompleteReviewByDocumentId(row.id);
      dispatch(reloadDocument());
    }
  };

  const handleCancelUpload = async () => {
    if (!row.fileId) return;
    await deleteFile(row.id, row.fileId);
    dispatch(reloadDocument());
  };

  const getArchitectActions = () => {
    const actions: JSX.Element[] = [];
    if (
      row.status !== WorkflowStatusType.SubmittedForReview &&
      row.status !== WorkflowStatusType.UnderReview
    )
      actions.push(
        <MenuItem key="accepted" value={RespondToReviewPropsResponseEnum.Accepted}>
          Accept
        </MenuItem>,
        <MenuItem key="resubmit" value={RespondToReviewPropsResponseEnum.Resubmitted}>
          Return for Consultant Review
        </MenuItem>,
      );
    actions.push(
      <MenuItem key="canceled" value={RespondToReviewPropsResponseEnum.Canceled}>
        Cancel this Consultant Review
      </MenuItem>,
    );
    return actions;
  };

  const getFileActions = () => {
    if (!row.fileId) return null;
    if (row.isVerified)
      return (
        <>
          <IconButton
            onClick={() => openInNewTab(row.fileId)}
            style={{ display: 'inline-flex', padding: '0px 0px 0px 12px' }}
          >
            <VisibilityIcon htmlColor="#464546" />
          </IconButton>
          <IconButton
            onClick={() => downloadFileById(row.fileId, row.fileName)}
            style={{ display: 'inline-flex', padding: '0px 0px 0px 12px' }}
          >
            <DownloadIcon width={21} height={21} fill="#464546" />
          </IconButton>
        </>
      );
    return (
      <>
        <IconButton style={{ display: 'inline-flex', padding: '0px 0px 0px 12px' }}>
          <Tooltip arrow placement="top" title="Upload in progress...">
            <WarningOutlined htmlColor="#F28B00" />
          </Tooltip>
        </IconButton>

        {(row.fileCreatorUserId === user.id || user.isSiteAdmin) && (
          <IconButton
            onClick={handleCancelUpload}
            style={{ display: 'inline-flex', padding: '0px 0px 0px 12px' }}
          >
            <Tooltip arrow placement="top" title="Cancel Upload">
              <Clear htmlColor="#ED3F26" />
            </Tooltip>
          </IconButton>
        )}
      </>
    );
  };

  const getStatusText = () => {
    if (isReviewingOffline || isReviewingInBluebeam) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ backgroundColor: '#616061', color: '#FFF' }}>
            {isReviewingOffline ? 'Offline' : 'Offline Bluebeam'} Review In Progress
          </Typography>
          <Tooltip
            arrow
            placement="top"
            title="When a Consultant updates an offline review, the most current updated version of the
                file is shared with ALL consultants with open review assignments."
            PopperProps={{ style: { paddingLeft: 4 } }}
          >
            <IconButton style={{ padding: '0 0 0 8px', cursor: 'default' }}>
              <Info />
            </IconButton>
          </Tooltip>
        </div>
      );
    }

    if (
      [
        WorkflowStatusType.ReviewComplete,
        WorkflowStatusType.Accepted,
        WorkflowStatusType.Canceled,
        WorkflowStatusType.Resubmitted,
      ].includes(row.status)
    ) {
      return <Typography>N/A</Typography>;
    }

    return (
      <Typography>
        {row.status === WorkflowStatusType.UnderReview ? 'Under Review' : 'Awaiting Review'}
      </Typography>
    );
  };

  return (
    <>
      <TableRow
        key={row.id}
        style={{
          height: '60px',
          display: !isHidden ? 'table-row' : 'none',
          ...getBackgroundColor(),
        }}
      >
        {!isPackageRow && (
          <TableCell className={classes.cellReviewer}>
            <AssignedUserText name={row.reviewer} company={row.company} />
          </TableCell>
        )}
        <TableCell colSpan={isPackageRow ? 3 : 1} className={classes.cellFile}>
          {!isPackageRow ? (
            <div style={{ display: 'flex', width: '100%' }}>
              {hasFile ? (
                <>
                  <IconButton
                    style={{
                      display: 'inline-flex',
                      padding: '0px 16px 0px 0px',
                      cursor: 'auto',
                    }}
                  >
                    <PDFIcon />
                  </IconButton>
                  <Typography
                    style={{
                      maxWidth: '200px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {`${row.fileName}`}
                    <br />
                    <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                      <Typography>{dayjs(row.fileDate).format('M/DD/YYYY  ')}</Typography>
                      <Typography>{`@ ${dayjs(row.fileDate).format('h:mmA')}`}</Typography>
                    </div>
                  </Typography>
                  <div style={{ display: 'inline-flex', flexGrow: 100 }} />
                  {row.fileId && (
                    <>
                      <IconButton
                        onClick={() => openInNewTab(row.fileId)}
                        style={{ display: 'inline-flex', padding: '0px 0px 0px 12px' }}
                      >
                        <VisibilityIcon htmlColor="#464546" />
                      </IconButton>
                      <IconButton
                        onClick={() => downloadFileById(row.fileId, row.fileName)}
                        style={{ display: 'inline-flex', padding: '0px 0px 0px 12px' }}
                      >
                        <DownloadIcon width={21} height={21} fill="#464546" />
                      </IconButton>
                    </>
                  )}
                </>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  {getStatusText()}
                </div>
              )}
            </div>
          ) : (
            <Typography style={{ fontWeight: 500 }}>{row.title}</Typography>
          )}
        </TableCell>
        {!isPackageRow && (
          <TableCell>
            {row.returnedDate === 'N/A' ? (
              <Button
                variant="outlined"
                color="primary"
                disabled={isReviewForPackageItem || getReviewerActionDisabled(row)}
                startIcon={<Add style={{ marginBottom: 1, marginRight: -4 }} />}
                onClick={() => reviewOnClick(row.id)}
                style={{ padding: '0px 8px 0px 6px' }}
              >
                Review
              </Button>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {(row.status === WorkflowStatusType.ReviewComplete ||
                  row.status === WorkflowStatusType.Accepted ||
                  row.status === WorkflowStatusType.Resolved) && (
                  <Typography className={classes.cellResponseGreen}>Complete</Typography>
                )}
                {row.status === WorkflowStatusType.Canceled && (
                  <Typography className={classes.cellResponseRed}>Canceled</Typography>
                )}
                {row.status === WorkflowStatusType.ReviewComplete &&
                  !isPackageRow &&
                  canReviseSubmission && (
                    <Tooltip arrow placement="top" title="Revise Submission">
                      <IconButton
                        onClick={handleReviseSubmission}
                        style={{ padding: 0, marginLeft: '8px' }}
                      >
                        <RotateLeft htmlColor="#464546" />
                      </IconButton>
                    </Tooltip>
                  )}
              </div>
            )}
          </TableCell>
        )}

        {!shouldHideResponse ? (
          <TableCell
            align={parentIsPackage ? 'center' : 'left'}
            colSpan={isPackageRow ? 2 : 1}
            className={getResponseClass(row.response)}
          >
            {!parentIsPackage && getUserFriendlyConsultantResponse(row.response)}
            {parentIsPackage && (
              <IconButton onClick={() => setOpen((prev) => !prev)} style={{ padding: 0 }}>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            )}
          </TableCell>
        ) : null}

        <TableCell colSpan={isPackageRow ? 2 : 1} align="left" className={classes.cellComments}>
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={() => openComments(row.id, row.comments)}
              style={{ padding: '0px 8px 0px 0px' }}
            >
              <ChatBubbleOutline />
            </IconButton>

            <Chip
              size="small"
              label={row.comments.length}
              onClick={() => openComments(row.id, row.comments)}
              style={{ ...getBackgroundColor() }}
            />
          </Box>
        </TableCell>
        {!isPackageRow && (
          <TableCell>
            <div style={{ display: 'flex' }}>
              <div style={{ width: 120 }}>Assigned</div>
              <div>{row.assignedDate}</div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ width: 120 }}>Due Internal</div>
              <div>{row.dueDate}</div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ width: 120 }}>
                {row.status === WorkflowStatusType.Canceled ? 'Canceled' : 'Returned'}
              </div>
              <div>{row.returnedDate}</div>
            </div>
          </TableCell>
        )}
        {!isPackageRow && (
          <TableCell className={classes.cellCheckbox}>
            <Select
              variant="outlined"
              fullWidth
              value={getRowActionValue(row.id)}
              disabled={isReviewForPackageItem || getArchitectActionDisabled(row)}
              style={{ width: 274 }}
              onChange={(event) => acceptOrReturn(event, row.id)}
              MenuProps={{
                // @ts-ignore
                getContentAnchorEl: () => null,
                autoFocus: true,
              }}
            >
              <MenuItem key="select" value={'undefined'} disabled>
                Select an Action
              </MenuItem>
              {getArchitectActions()}
            </Select>
          </TableCell>
        )}
      </TableRow>
      {packageRows.map((row) => (
        <ConfidentialAdditionalReviewRow
          key={row.id}
          row={row}
          getArchitectActionDisabled={getArchitectActionDisabled}
          getReviewerActionDisabled={getReviewerActionDisabled}
          getRowActionValue={getRowActionValue}
          canReviseSubmission={false}
          reviewOnClick={reviewOnClick}
          openComments={openComments}
          acceptOrReturn={acceptOrReturn}
          isPackageRow
          isHidden={!open}
        />
      ))}
    </>
  );
}
