import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import SeeAll from './SeeAll';
import DashboardMessage from './DashboardMessage';
import { getMostRecentProjects } from '../../models/api/project';
import { IProjectView } from '../../api-client/autogenerated';
import { descendingComparator } from '../document-index/DocumentIndexUtils';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  titleStyle: {
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
  },
  messageActivityText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '21px',
    color: '#464546', // Gray / Gray 700
    paddingBottom: 15,
  },
  underlineTitle: {
    borderBottom: '3px solid #874080',
    paddingBottom: 0,
    fontSize: 16,
    textTransform: 'none',
  },
  newMessages: {
    color: '#4380ED', // blue 500
    fontStyle: 'normal',
  },
  link: {
    fontSize: 16,
    // fontStyle: 'bold',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  list: {
    '&>:nth-child(even) > div > li > div > span > div :nth-child(3)': {
      background: '#E6EEFF',
    },
  },
});

export default function DashboardMessageBoards() {
  const classes = useStyles();
  const history = useHistory();
  const [projects, setProjects] = useState<IProjectView[]>();

  const messageBoard = projects?.map((p) => {
    return (
      <Grid
        key={p.id}
        item
        container
        xs={12}
        lg={6}
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item container className={classes.underlineTitle} spacing={0}>
          {p.name.includes('-') ? p.name.substring(0, p.name.indexOf('-')) : p.name}
          <div style={{ display: 'inline-flex', flexGrow: 100 }} />
          <div style={{ whiteSpace: 'nowrap' }}>
            <SeeAll onClick={() => history.push(`/main/projects/${p.id}`)} />
          </div>
        </Grid>

        <Grid item xs={12}>
          <List className={classes.list}>
            {p.comments
              ?.sort((a, b) => descendingComparator(a, b, 'createdOn'))
              .slice(0, 2)
              .map((comment) => {
                return (
                  <div key={comment.id}>
                    <DashboardMessage
                      date={comment.createdOn!}
                      name={comment.creatorUser!.name}
                      company={comment.creatorUser!.company?.name || 'Dev company'}
                      message={comment.text}
                    />
                  </div>
                );
              })}
          </List>
        </Grid>
      </Grid>
    );
  });

  useEffect(() => {
    getMostRecentProjects()
      .then((result) => setProjects(result))
      .catch(() => setProjects([]));
  }, []);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.titleStyle}>
        <h2
          style={{
            textAlign: 'left',
            color: '#FFFFFF',
            margin: 0,
          }}
        >
          Message Boards
        </h2>
      </CardContent>
      <CardContent style={{ overflowY: 'auto', height: '100%' }}>
        {projects && projects.length > 0 ? (
          <>
            <Typography className={classes.messageActivityText}>
              Recent activity on your message boards displayed below.
            </Typography>
            <Grid
              container
              spacing={3}
              direction="row"
              alignItems="flex-start"
              style={{ marginBottom: 0, paddingBottom: 8 }}
            >
              {messageBoard}
            </Grid>
          </>
        ) : (
          <Typography>You have no message boards to display.</Typography>
        )}
      </CardContent>
    </Card>
  );
}
