import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { getImageByFileId } from '../../scripts/utils';
import CircularLoader from '../loader/CircularLoader';
import { HighlightOffRounded } from '@material-ui/icons';

type Props = {
  open: boolean;
  handleClose: () => void;
  fileId: string;
};

export default function ViewImageDialog(props: Props) {
  const { open, handleClose, fileId } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState<string>();

  useEffect(() => {
    setImage(undefined);
    setIsLoading(true);
    getImageByFileId(fileId, true)
      .then(setImage)
      .finally(() => setIsLoading(false));
  }, [fileId]);

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ style: { maxWidth: '90vw' } }}>
      <DialogContent style={{ padding: 32, objectFit: 'contain' }}>
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', top: 8, right: 8, padding: 0 }}
        >
          <HighlightOffRounded />
        </IconButton>
        {!isLoading ? (
          <img src={image} alt="" style={{ maxWidth: '100%', maxHeight: 'calc(100vh - 96px)' }} />
        ) : (
          <CircularLoader />
        )}
      </DialogContent>
    </Dialog>
  );
}
