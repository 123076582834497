import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useState } from 'react';
import { generateUniqueId } from '../../scripts/utils';

interface BiddingTableProps {
  headers: string[];
  rows: JSX.Element[];
  rowsPerPage: number;
}

const useStyles = makeStyles({
  tableBody: {
    color: '#616061',
    '&>:nth-child(odd)': {
      transition: '0.11s',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: '#E5EEFE',
      },
    },
    '&>:nth-child(even)': {
      transition: '0.11s',
      backgroundColor: '#F9F9F9',
      '&:hover': {
        backgroundColor: '#E5EEFE',
      },
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export default function BiddingTable(props: BiddingTableProps) {
  const { headers, rows, rowsPerPage } = props;
  const classes = useStyles();
  const [page, setPage] = useState(0);

  const handleChange = (event: any, value: number) => {
    setPage(value - 1);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map((header) => {
                return (
                  <TableCell key={generateUniqueId()} style={{ minWidth: 140 }}>
                    {header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {rows.slice(
              page * rowsPerPage,
              Math.min(rows.length, page * rowsPerPage + rowsPerPage),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ flexGrow: 1 }} />
      {Math.ceil(rows.length / rowsPerPage) > 1 && (
        <Pagination
          count={Math.ceil(rows.length / rowsPerPage)}
          page={page + 1}
          style={{ paddingTop: 32 }}
          classes={{ ul: classes.center }}
          onChange={handleChange}
        />
      )}
    </div>
  );
}
