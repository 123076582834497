import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Popover,
  Select,
} from '@material-ui/core';
import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import { modifyDocumentsByBatch } from '../../models/api/documents';
import { PunchListStatusType, SecurityPermissionLevel } from '../../api-client/autogenerated';
import { useDispatch } from 'react-redux';
import { reloadDocuments } from '../../features/documents/actions';
import { getDocPermission } from '../../scripts/store-utils';

type EditPunchListStatusProps = {
  dialogOpen: boolean;
  handleClose: () => void;
  anchor?: any;
  selected: string[];
};

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: 8,
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

const useStyles = makeStyles(
  createStyles({
    popover: {
      '.rootElement > *': {
        maxHeight: '5000px',
      },
    },
    section: {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      minWidth: '270px',
    },
    button: {
      width: 100,
      height: 36,
    },
  }),
);

export default function EditPunchListStatusDialog(props: EditPunchListStatusProps) {
  const classes = useStyles();
  const { dialogOpen, handleClose, anchor, selected } = props;

  const dispatch = useDispatch();

  const [status, setStatus] = useState<string | null>(null);
  const [punchListPermission, setPunchListPermission] = useState<SecurityPermissionLevel>(
    SecurityPermissionLevel.NUMBER_0,
  );

  useEffect(() => {
    const permission = getDocPermission();
    setPunchListPermission(permission || SecurityPermissionLevel.NUMBER_0);
  }, []);

  const getStatusOptions = () => {
    const options = [
      { name: 'In Progress', value: PunchListStatusType.InProgress },
      { name: 'Ready For Verification', value: PunchListStatusType.ReadyForVerification },
    ];
    if (punchListPermission >= SecurityPermissionLevel.NUMBER_3)
      options.push(
        { name: 'Rejected', value: PunchListStatusType.Rejected },
        { name: 'Accepted', value: PunchListStatusType.Accepted },
      );
    return options;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (status) {
      await modifyDocumentsByBatch(selected, {
        patch: {
          punchListStatus: status as PunchListStatusType,
        },
      });
      handleClose();
      dispatch(reloadDocuments());
    }
  };

  const handleCancel = () => {
    setStatus(null);
    handleClose();
  };

  useEffect(() => {
    setStatus(null);
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <Popover
        className={classes.popover}
        open={dialogOpen}
        onClose={handleClose}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box className={classes.section}>
            <Grid container direction="column">
              <FormControl fullWidth variant="outlined" style={{ marginBottom: 12 }}>
                <InputLabel>Select Status</InputLabel>
                <Select
                  input={<BootstrapInput />}
                  fullWidth
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value as string);
                  }}
                >
                  {getStatusOptions().map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid container style={{ justifyContent: 'space-between' }}>
                <Button onClick={handleCancel} variant="outlined" className={classes.button}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  disabled={!status}
                  className={classes.button}
                  variant="contained"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Popover>
    </MuiPickersUtilsProvider>
  );
}
