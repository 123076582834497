import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import NavAppbar from '../nav-top/NavAppbar';
import PageTitle from '../page-title/PageTitle';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import { Business, CropLandscapeRounded, Edit, Info } from '@material-ui/icons';
import CreateUserGroupDialog from '../design/CreateUserGroupDialog';
import EyeIcon from '@material-ui/icons/Visibility';
import SearchIcon from '@material-ui/icons/Search';
import { IPhysicalLocation, IUser, IUserGroup } from '../../api-client/autogenerated';
import {
  getPhysicalBuildingsState,
  getPhysicalFloorsState,
  getPhysicalLocationsState,
  getProjectState,
} from '../../features/project/selectors';
import { getGroupsState } from '../../features/groups/selector';
import {
  getUsersFromUserGroup,
  ManagePermissionsDialogType,
} from '../design/ManagePermissionsDialog';
import UploadFloorPlanDialog from './UploadFloorPlanDialog';
import PunchListRegionSelector, {
  PunchListLocationsResult,
  RoomRegionType,
} from './PunchListRegionSelector';
import {
  CustomInsertionPhysicalLocation,
  deletePhysicalBuildingById,
  deletePhysicalLocationById,
  editManyPhysicalFloors,
  insertFloorPlanImageByProjectId,
  insertLocationImageByProjectId,
  insertManyPhysicalLocations,
} from '../../models/api/punch-list';
import { reloadPunchListLocations, updateProject } from '../../features/project/actions';
import { patchProject } from '../../models/api/project';
import { getCurrentSecurityGroup } from '../../features/security/selectors';
import { getUserState } from '../../features/user/selectors';
import ViewImageDialog from '../dialogs/ViewImageDialog';
import { ascendingComparator, descendingComparator } from '../document-index/DocumentIndexUtils';
import Add from '@material-ui/icons/Add';
import AddBuildingsDialog from './AddBuildingsDialog';
import _ from 'lodash';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import useMediaQueries from '../custom-components/useMediaQueries';
import SelectFloorPlanDialog from './SelectFloorPlanDialog';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      height: '100%',
      flexGrow: 1,
      padding: theme.spacing(3),
      '&MuiPaper-outlined': {
        border: 0,
      },
      overflowX: 'auto',
    },
    title: {
      padding: 0,
    },
    paper: {
      height: '100%',
      color: theme.palette.text.secondary,
      boxShadow: '0px 5px 15px rgb(0, 0, 0, .15)',
      padding: '20px',
    },
    deleteButton: {
      padding: '3px 30px',
      height: 32,
      color: 'white',
      backgroundColor: '#ED3F26',
    },
  }),
);

export default function PunchListSetup() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);
  const security = useSelector(getCurrentSecurityGroup);
  const buildings = useSelector(getPhysicalBuildingsState);
  const locations = useSelector(getPhysicalLocationsState);
  const floors = useSelector(getPhysicalFloorsState);

  const { isXs } = useMediaQueries();

  // top table state
  const groups = useSelector(getGroupsState);
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [selectedGroupUsers, setSelectedGroupUsers] = useState<IUser[]>();
  const [groupToEdit, setGroupToEdit] = useState<IUserGroup>();

  // middle table state
  const [selectedBuildingIds, setSelectedBuildingIds] = useState<string[]>([]);
  const [floorSearchText, setFloorSearchText] = useState('');

  // bottom table state
  const [selectedBuildingId, setSelectedBuildingId] = useState('');
  const [selectedFloorId, setSelectedFloorId] = useState('');
  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);
  const [locationSearchText, setLocationSearchText] = useState('');

  // dialog state
  const [dialogFloorId, setDialogFloorId] = useState<string>();
  const [dialogFloorPlan, setDialogFloorPlan] = useState<File>();

  const [imageToView, setImageToView] = useState<string>();

  // dialog open/closed state
  const [uploadFileDialogOpen, setUploadFileDialogOpen] = useState(false);
  const [defaultBuildingId, setDefaultBuildingId] = useState('');
  const [selectFloorplanDialogOpen, setSelectFloorplanDialogOpen] = useState(false);
  const [punchListDialogOpen, setPunchListDialogOpen] = useState(false);
  const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState(false);
  const [viewImageDialogOpen, setViewImageDialogOpen] = useState(false);
  const [createBuildingsDialogOpen, setCreateBuildingsDialogOpen] = useState(false);

  const filteredLocations = locations.filter(
    (loc) =>
      (!selectedBuildingId ||
        floors.find((floor) => floor.id === loc.floorId)?.buildingId === selectedBuildingId) &&
      (!selectedFloorId || loc.floorId === selectedFloorId) &&
      (!locationSearchText || loc.name.toLowerCase().includes(locationSearchText.toLowerCase())) &&
      !buildings.find((b) => floors.find((f) => f.id === loc.floorId)?.buildingId === b.id)
        ?.isDefault,
  );

  const areAllBuildingsChecked =
    selectedBuildingIds.length > 0 &&
    _.isEqual(buildings.map((building) => building.id).sort(), selectedBuildingIds.sort());

  const areAllLocationsChecked =
    selectedLocationIds.length > 0 &&
    _.isEqual(filteredLocations.map((location) => location.id).sort(), selectedLocationIds.sort());

  const existingLocations = floors.find((f) => f.id === dialogFloorId)?.locations;

  const handleAddGroup = (group: IUserGroup) => {
    if (groupToEdit) {
      setSelectedGroupUsers(getUsersFromUserGroup(group));
    } else {
      setSelectedGroupId(group.id);
    }
  };

  useEffect(() => {
    if (project?.punchListNotificationGroupId)
      setSelectedGroupId(project.punchListNotificationGroupId);
  }, [project]);

  useEffect(() => {
    if (selectedGroupId) {
      const group = groups.filter((group) => group.id === selectedGroupId)[0];
      setSelectedGroupUsers(getUsersFromUserGroup(group));
    } else {
      setSelectedGroupUsers(undefined);
    }
  }, [groups, selectedGroupId]);

  const handleDeleteLocations = async () => {
    const locationsToDelete = locations.filter((loc) => selectedLocationIds.includes(loc.id));

    const proceed = window.confirm(
      `You are about to delete rooms:\n\n${locationsToDelete
        .map(({ name }) => name)
        .join('\n')}\n\nYou will need to reparse to restore these rooms.\nDo you want to continue?`,
    );

    if (!proceed) return;

    // delete all checked locations in the backend
    await Promise.all(selectedLocationIds.map((id) => deletePhysicalLocationById(id)));

    // delete all checked locations in the frontend
    setSelectedLocationIds([]);
    dispatch(reloadPunchListLocations());
  };

  const handleDeleteBuildings = async () => {
    const buildingsToDelete = buildings.filter((building) =>
      selectedBuildingIds.includes(building.id),
    );

    const proceed = window.confirm(
      `You are about to delete buildings:\n\n${buildingsToDelete
        .map(({ name }) => name)
        .join(
          '\n',
        )}\n\nAll of the floors and parsed locations associated with this building will be deleted, too.\nDo you want to continue?`,
    );

    if (!proceed) return;

    // delete all checked floors in the backend
    await Promise.all(selectedBuildingIds.map((id) => deletePhysicalBuildingById(id)));

    // delete all checked floors in the frontend
    setSelectedBuildingIds([]);
    dispatch(reloadPunchListLocations());
  };

  const handleChangeGroup = async () => {
    if (!project) return;
    await patchProject(project.id, { punchListNotificationGroupId: selectedGroupId });
    dispatch(updateProject({ punchListNotificationGroupId: selectedGroupId }));
  };

  const getPunchListLocationFromRoomRegion = async (
    room: RoomRegionType,
  ): Promise<CustomInsertionPhysicalLocation> => {
    const { image, title, x, y, W, H, scale, width } = room;
    const fullFileName = `${title}.png`;
    const file = new File([image], fullFileName);
    const { file: locationImage } = await insertLocationImageByProjectId(
      project!.id,
      { fullFileName, useMultiPartUpload: file.size > MULTI_PART_FILE_SIZE },
      file,
    );
    return {
      name: title,
      markedLocation: { x, y, W, H, scale, width },
      locationImageFileId: locationImage!.id,
    };
  };

  const handleSubmitLocations = async (results: PunchListLocationsResult) => {
    try {
      if (project?.id && dialogFloorId) {
        const { floorPlanImageFile, rooms } = results;

        const { file: floorPlanImage } = await insertFloorPlanImageByProjectId(
          project.id,
          {
            fullFileName: floorPlanImageFile.name,
            useMultiPartUpload: floorPlanImageFile.size > MULTI_PART_FILE_SIZE,
          },
          floorPlanImageFile,
        );

        if (floorPlanImage)
          await editManyPhysicalFloors([dialogFloorId], {
            floorPlanImageFileId: floorPlanImage.id,
          });

        const locations = await Promise.all(rooms.map(getPunchListLocationFromRoomRegion));
        await insertManyPhysicalLocations([dialogFloorId], locations);

        setPunchListDialogOpen(false);
        setSelectFloorplanDialogOpen(false);
        dispatch(reloadPunchListLocations());
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  const canCreateAndEditGroups = () => {
    return (
      user.isSiteAdmin ||
      user.adminOfSubscriberId === project?.subscriberId ||
      security?.canModifyUserGroups
    );
  };

  const getEmptyText = () => {
    if (selectedFloorId) return ' on this floor';
    if (selectedBuildingId) return ' in this building';
    return '';
  };

  return (
    <>
      <main className={classes.content}>
        <style type="text/css" media="print">
          {' @page { size: landscape; } '}
        </style>
        <NavAppbar />
        <PageTitle title="Punch List Setup" />
        <Paper
          className={classes.paper}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.title} variant="h2">
              Punch List Notification Group
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              value={selectedGroupId}
              onChange={(event) => {
                setSelectedGroupId(event.target.value as string);
              }}
              style={{ height: 30 }}
              variant="outlined"
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Group
              </MenuItem>
              {groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
            <Button
              variant="contained"
              color="primary"
              disabled={
                (!project?.punchListNotificationGroupId && !selectedGroupId) ||
                project?.punchListNotificationGroupId === selectedGroupId
              }
              onClick={handleChangeGroup}
              style={{ marginLeft: 8, whiteSpace: 'nowrap' }}
            >
              Set Group
            </Button>
            <Hidden xsDown>
              <Button
                variant="contained"
                color="primary"
                disabled={!canCreateAndEditGroups()}
                onClick={() => setCreateGroupDialogOpen(true)}
                style={{ marginLeft: 8, whiteSpace: 'nowrap' }}
              >
                Create New Group
              </Button>
            </Hidden>
          </div>
          <Hidden smUp>
            <Button
              variant="contained"
              color="primary"
              disabled={!canCreateAndEditGroups()}
              onClick={() => setCreateGroupDialogOpen(true)}
              style={{ marginTop: 8, whiteSpace: 'nowrap', maxWidth: 200 }}
            >
              Create New Group
            </Button>
          </Hidden>
          {selectedGroupUsers && (
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                <Typography variant="subtitle1" style={{ fontSize: 15 }}>
                  Notification List
                </Typography>
                <Button
                  onClick={() => {
                    setGroupToEdit(groups.find((g) => g.id === selectedGroupId));
                    setCreateGroupDialogOpen(true);
                  }}
                  disabled={!canCreateAndEditGroups()}
                  variant="contained"
                  color="primary"
                  startIcon={<Edit />}
                  style={{ padding: '6px 10px', marginLeft: 8 }}
                >
                  EDIT
                </Button>
              </div>
              <TableContainer style={{ overflowX: 'hidden', marginTop: 16 }}>
                <Table>
                  <TableHead>
                    <TableRow style={{ borderTop: '1px solid #EDECEC' }}>
                      <TableCell>Name</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedGroupUsers.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.company?.name || 'No company found'}</TableCell>
                        <TableCell>{user.email}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Paper>
        <Paper className={classes.paper} style={{ marginTop: 32 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography className={classes.title} variant="h2">
              Manage Buildings and Floorplans
            </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15, height: 30 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => {
                setSelectedBuildingIds([]);
                setCreateBuildingsDialogOpen(true);
              }}
              style={{ marginRight: 8, whiteSpace: 'nowrap' }}
            >
              {!isXs ? 'CREATE BUILDINGS' : <Business />}
            </Button>
            <Button
              onClick={() => {
                setUploadFileDialogOpen(true);
              }}
              disabled={buildings.filter((b) => !b.isDefault).length === 0}
              variant="contained"
              color="primary"
              startIcon={<Add />}
              style={{ marginRight: 8, whiteSpace: 'nowrap' }}
            >
              {!isXs ? 'UPLOAD A FLOORPLAN' : <CropLandscapeRounded />}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Edit />}
              disabled={selectedBuildingIds.length === 0}
              onClick={() => setCreateBuildingsDialogOpen(true)}
              style={{ marginRight: 8 }}
            >
              EDIT
            </Button>
            <Button
              variant="contained"
              disabled={selectedBuildingIds.length === 0}
              onClick={handleDeleteBuildings}
              startIcon={<DeleteIcon />}
              className={classes.deleteButton}
            >
              DELETE
            </Button>
            <div style={{ flex: 1 }} />
            <Hidden smDown>
              <TextField
                variant="outlined"
                InputProps={{
                  style: { height: 30, paddingTop: 0, paddingBottom: 0 },
                  startAdornment: (
                    <SearchIcon style={{ color: '#B2B1B2', marginLeft: -10, marginRight: 6 }} />
                  ),
                }}
                placeholder="Search"
                value={floorSearchText}
                onChange={(event) => {
                  setFloorSearchText(event.target.value);
                }}
              />
            </Hidden>
          </div>
          <Hidden mdUp>
            <TextField
              variant="outlined"
              InputProps={{
                style: { height: 30, paddingTop: 0, paddingBottom: 0 },
                startAdornment: (
                  <SearchIcon style={{ color: '#B2B1B2', marginLeft: -10, marginRight: 6 }} />
                ),
              }}
              placeholder="Search"
              value={floorSearchText}
              onChange={(event) => {
                setFloorSearchText(event.target.value);
              }}
              style={{ marginBottom: 8 }}
            />
          </Hidden>
          <Divider style={{ width: '100%', height: '2px' }} />
          <TableContainer style={{ overflow: 'hidden' }}>
            <Table>
              <TableHead>
                <TableRow style={{ borderTop: '1px solid #EDECEC' }}>
                  <TableCell style={{ width: 0, padding: 0 }}>
                    <GreenCheck
                      checked={areAllBuildingsChecked}
                      indeterminate={!areAllBuildingsChecked && selectedBuildingIds.length > 0}
                      onChange={() =>
                        setSelectedBuildingIds(
                          areAllBuildingsChecked ? [] : buildings.map((building) => building.id),
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>Building</TableCell>
                  <TableCell>
                    Floors
                    <Tooltip
                      arrow
                      placement="right"
                      title="Select a floor to view its floorplan image (if it exists)"
                    >
                      <Info htmlColor="#7A797A" style={{ verticalAlign: 'top', marginLeft: 8 }} />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {buildings.map((building) => {
                  const filteredFloors = floors.filter((floor) => floor.buildingId === building.id);
                  return (
                    <TableRow key={building.id}>
                      <TableCell style={{ width: 0, padding: 0 }}>
                        {!building.isDefault ? (
                          <GreenCheck
                            checked={selectedBuildingIds.includes(building.id)}
                            onChange={(e, checked) =>
                              setSelectedBuildingIds((prev) =>
                                checked
                                  ? [...prev, building.id]
                                  : prev.filter((id) => id !== building.id),
                              )
                            }
                          />
                        ) : null}
                      </TableCell>
                      <TableCell>{building.name}</TableCell>
                      <TableCell>
                        <Select
                          variant="outlined"
                          displayEmpty
                          value=""
                          style={{ width: '15vw', height: 32, verticalAlign: 'middle' }}
                        >
                          <MenuItem value="">{filteredFloors.length} Floors</MenuItem>
                          {filteredFloors.map((floor) => (
                            <MenuItem
                              key={floor.id}
                              value={floor.id}
                              disabled={!floor.floorPlanImageFileId}
                              onClick={() => {
                                setImageToView(floor.floorPlanImageFileId!);
                                setViewImageDialogOpen(true);
                              }}
                            >
                              {floor.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Paper className={classes.paper} style={{ marginTop: 32 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 16,
            }}
          >
            <Typography className={classes.title} variant="h2">
              Identify Rooms and Locations by Floorplan
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              marginBottom: 16,
              height: 30,
            }}
          >
            <Button
              onClick={() => {
                setSelectFloorplanDialogOpen(true);
              }}
              disabled={!buildings.some((b) => !b.isDefault)}
              variant="contained"
              color="primary"
              startIcon={<Add />}
              style={{ whiteSpace: 'nowrap' }}
            >
              SELECT A FLOORPLAN
            </Button>
            <Button
              variant="contained"
              disabled={selectedLocationIds.length === 0}
              onClick={handleDeleteLocations}
              startIcon={<DeleteIcon />}
              className={classes.deleteButton}
              style={{ marginLeft: 8 }}
            >
              DELETE
            </Button>
            <div style={{ flex: 1 }} />
            <Hidden smDown>
              <div
                style={{ display: 'flex', flexDirection: 'column', marginLeft: 8, marginRight: 8 }}
              >
                <Typography style={{ fontSize: 14, marginBottom: 4 }}>
                  Filter by Building
                </Typography>
                <Select
                  value={selectedBuildingId}
                  onChange={(event) => {
                    setSelectedBuildingId(event.target.value as string);
                    setSelectedFloorId('');
                    setSelectedLocationIds([]); // uncheck all floors
                  }}
                  style={{ minWidth: '15vw', height: 30 }}
                  variant="outlined"
                  displayEmpty
                >
                  <MenuItem value="">Any</MenuItem>
                  {buildings
                    .filter((b) => !b.isDefault)
                    .sort((a, b) => descendingComparator(a, b, 'name'))
                    .map((building) => (
                      <MenuItem key={building.id} value={building.id}>
                        {building.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginRight: 8 }}>
                <Typography style={{ fontSize: 14, marginBottom: 4 }}>Filter by Floor</Typography>
                <Select
                  value={selectedFloorId}
                  disabled={
                    !selectedBuildingId ||
                    (!!selectedBuildingId && !selectedFloorId && filteredLocations.length === 0)
                  }
                  onChange={(event) => {
                    setSelectedFloorId(event.target.value as string);
                    setSelectedLocationIds([]); // uncheck all locations
                  }}
                  style={{ minWidth: '15vw', height: 30 }}
                  variant="outlined"
                  displayEmpty
                >
                  <MenuItem value="">Any</MenuItem>
                  {floors
                    .filter((floor) => floor.buildingId === selectedBuildingId)
                    .sort((a, b) => ascendingComparator(a, b, 'index'))
                    .map((floor) => (
                      <MenuItem key={floor.id} value={floor.id}>
                        {floor.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
              <TextField
                variant="outlined"
                InputProps={{
                  style: { height: 30, paddingTop: 0, paddingBottom: 0 },
                  startAdornment: (
                    <SearchIcon style={{ color: '#B2B1B2', marginLeft: -10, marginRight: 6 }} />
                  ),
                }}
                placeholder="Search"
                value={locationSearchText}
                onChange={(event) => {
                  setLocationSearchText(event.target.value);
                }}
              />
            </Hidden>
          </div>
          <Hidden mdUp>
            <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 8 }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginRight: 8 }}>
                <Typography style={{ fontSize: 14, marginBottom: 4 }}>
                  Filter by Building
                </Typography>
                <Select
                  value={selectedBuildingId}
                  onChange={(event) => {
                    setSelectedBuildingId(event.target.value as string);
                    setSelectedFloorId('');
                    setSelectedLocationIds([]); // uncheck all floors
                  }}
                  style={{ minWidth: '15vw', height: 30 }}
                  variant="outlined"
                  displayEmpty
                >
                  <MenuItem value="">Any</MenuItem>
                  {buildings
                    .filter((b) => !b.isDefault)
                    .sort((a, b) => descendingComparator(a, b, 'name'))
                    .map((building) => (
                      <MenuItem key={building.id} value={building.id}>
                        {building.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginRight: 8 }}>
                <Typography style={{ fontSize: 14, marginBottom: 4 }}>Filter by Floor</Typography>
                <Select
                  value={selectedFloorId}
                  disabled={
                    !selectedBuildingId ||
                    (!!selectedBuildingId && !selectedFloorId && filteredLocations.length === 0)
                  }
                  onChange={(event) => {
                    setSelectedFloorId(event.target.value as string);
                    setSelectedLocationIds([]); // uncheck all locations
                  }}
                  style={{ minWidth: '15vw', height: 30 }}
                  variant="outlined"
                  displayEmpty
                >
                  <MenuItem value="">Any</MenuItem>
                  {floors
                    .filter((floor) => floor.buildingId === selectedBuildingId)
                    .sort((a, b) => ascendingComparator(a, b, 'index'))
                    .map((floor) => (
                      <MenuItem key={floor.id} value={floor.id}>
                        {floor.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
              <TextField
                variant="outlined"
                InputProps={{
                  style: { height: 30, paddingTop: 0, paddingBottom: 0 },
                  startAdornment: (
                    <SearchIcon style={{ color: '#B2B1B2', marginLeft: -10, marginRight: 6 }} />
                  ),
                }}
                placeholder="Search"
                value={locationSearchText}
                onChange={(event) => {
                  setLocationSearchText(event.target.value);
                }}
              />
            </div>
          </Hidden>
          <Divider style={{ width: '100%', height: '2px' }} />
          <TableContainer style={{ overflow: 'hidden' }}>
            <Table>
              <TableHead>
                <TableRow style={{ borderTop: '1px solid #EDECEC' }}>
                  <TableCell style={{ width: 0, padding: 0 }}>
                    <GreenCheck
                      checked={areAllLocationsChecked}
                      indeterminate={!areAllLocationsChecked && selectedLocationIds.length > 0}
                      onChange={() =>
                        setSelectedLocationIds(
                          areAllLocationsChecked
                            ? []
                            : filteredLocations.map((location) => location.id),
                        )
                      }
                    />
                  </TableCell>
                  <TableCell style={{ width: 0, padding: 0 }} />
                  <TableCell>Building Name</TableCell>
                  <TableCell>Floor Name</TableCell>
                  <TableCell>Location Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(_.uniqBy(
                  filteredLocations,
                  (loc) => loc.locationImageFileId,
                ) as IPhysicalLocation[]).map((location) => {
                  return (
                    <TableRow key={location.id}>
                      <TableCell style={{ width: 0, padding: 0 }}>
                        <GreenCheck
                          checked={selectedLocationIds.includes(location.id)}
                          onChange={(e, checked) =>
                            setSelectedLocationIds((prev) =>
                              checked
                                ? [...prev, location.id]
                                : prev.filter((id) => id !== location.id),
                            )
                          }
                        />
                      </TableCell>
                      <TableCell style={{ width: 0, padding: 0 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={!location.locationImageFileId}
                          onClick={() => {
                            if (location.locationImageFileId) {
                              setImageToView(location.locationImageFileId);
                              setViewImageDialogOpen(true);
                            }
                          }}
                          startIcon={<EyeIcon />}
                          style={{ padding: 10 }}
                        >
                          View
                        </Button>
                      </TableCell>
                      <TableCell>
                        {
                          buildings.find(
                            (building) =>
                              building.id ===
                              floors.find((floor) => floor.id === location.floorId)?.buildingId,
                          )?.name
                        }
                      </TableCell>
                      <TableCell>{floors.find((f) => f.id === location.floorId)?.name}</TableCell>
                      <TableCell>{location.name}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {filteredLocations.length === 0 && (
            <Typography style={{ marginTop: 8, marginLeft: 8 }}>
              There are no locations parsed{getEmptyText()}.
            </Typography>
          )}
        </Paper>
      </main>
      <CreateUserGroupDialog
        open={createGroupDialogOpen}
        handleClose={() => {
          setCreateGroupDialogOpen(false);
          setGroupToEdit(undefined);
        }}
        handleAddGroup={handleAddGroup}
        type={ManagePermissionsDialogType.AssociatedUsers}
        existingGroup={groupToEdit}
      />
      <UploadFloorPlanDialog
        open={uploadFileDialogOpen}
        onClose={() => {
          setUploadFileDialogOpen(false);
        }}
        defaultBuildingId={defaultBuildingId}
      />
      <SelectFloorPlanDialog
        open={selectFloorplanDialogOpen}
        onClose={() => setSelectFloorplanDialogOpen(false)}
        onFinish={(file, floorId) => {
          setDialogFloorId(floorId);
          setDialogFloorPlan(file);
          setPunchListDialogOpen(true);
        }}
      />
      {dialogFloorPlan && (
        <PunchListRegionSelector
          open={punchListDialogOpen}
          onClose={() => setPunchListDialogOpen(false)}
          file={dialogFloorPlan}
          existingLocations={existingLocations}
          onSubmit={handleSubmitLocations}
        />
      )}
      {imageToView && (
        <ViewImageDialog
          open={viewImageDialogOpen}
          handleClose={() => setViewImageDialogOpen(false)}
          fileId={imageToView}
        />
      )}
      <AddBuildingsDialog
        open={createBuildingsDialogOpen}
        onClose={(newBuildingId) => {
          setCreateBuildingsDialogOpen(false);
          if (newBuildingId) {
            setDefaultBuildingId(newBuildingId);
            setUploadFileDialogOpen(true);
          }
        }}
        buildingIdsToEdit={selectedBuildingIds}
      />
    </>
  );
}
