import _ from 'lodash';
import {
  AdditionalConsultantProps,
  ICustomInsertionUser,
  ICustomOptionalUser,
  IProjectView,
  IUser,
  IUserSimplified,
  NotificationSettingType,
} from '../../api-client/autogenerated';
import {
  ApiClient,
  createApiClient,
  getQueryFilterString,
  QueryFilter,
} from '../../api-client/api-client';

// GET //

export const getAllUsersSimplifiedBySubscriberId = async (
  subscriberId: string,
): Promise<IUserSimplified[]> => {
  const response = await ApiClient.getAllUsersSimplifiedBySubscriberId({ subscriberId });
  return response.data;
};

export const getUsers = async (
  limit = 50,
  orderByColumn = '',
  filterByName = '',
): Promise<IUser[]> => {
  const filter = getQueryFilterString([
    { whereColumn: 'name', whereOperator: 'ILIKE', whereValue: `%${filterByName}%` },
  ]);
  const response = await ApiClient.getAllUsers({
    pageSize: limit,
    orderByColumn: orderByColumn,
    filterByObject: filterByName ? filter : undefined,
  });
  return response.data.results;
};

export const getAdmins = async (limit = 50): Promise<IUser[]> => {
  const response = await ApiClient.getAllAdminUsers({ pageSize: limit });
  return response.data.results;
};

export const getUserById = async (userId: string): Promise<IUser> => {
  const response = await ApiClient.getUserById({ id: userId });
  return response.data;
};

export const getUserByEmail = async (email: string): Promise<IUser | undefined> => {
  const filter: QueryFilter = [{ whereColumn: 'email', whereOperator: 'ILIKE', whereValue: email }];
  const response = await ApiClient.getAllUsers({ filterByObject: getQueryFilterString(filter) });
  return _.nth(response.data.results, 0);
};

export const getUsersByEmail = async (
  email: string,
  limit = 9999,
): Promise<IUser[] | undefined> => {
  const filter: QueryFilter = [{ whereColumn: 'email', whereOperator: 'ILIKE', whereValue: email }];
  const response = await ApiClient.getAllUsers({
    pageSize: limit,
    filterByObject: getQueryFilterString(filter),
  });
  return response.data.results;
};

export const insertUser = async (
  user: ICustomInsertionUser,
  projectId: string,
  securityGroupId: string,
) => {
  const response = await ApiClient.insertUser({
    iAddNewUser: { user: { ...user, email: user.email.trim() }, projectId, securityGroupId },
  });
  return response.data;
};

export const insertProjectlessUser = async (user: ICustomInsertionUser) => {
  return ApiClient.insertProjectlessUser({ iCustomInsertionUser: user }).then((res) => res.data);
};

export const getLoggedInUser = async () => {
  const response = await ApiClient.getCurrentUser();
  return response.data;
};

export const putDocumentUser = async (
  documentId: string,
  userId: string,
  notificationSetting?: NotificationSettingType,
) => {
  const response = await ApiClient.insertDocumentUser({
    iInsertionDocumentUser: { userId, documentId, notificationSetting, isOnNotificationList: true },
  });
  return response.data;
};

export const patchUser = async (userId: string, user: ICustomOptionalUser): Promise<IUser> => {
  const response = await ApiClient.editUserById({ userId, iCustomOptionalUser: user });
  return response.data;
};

export const insertConsultantByDocumentId = async (
  documentId: string,
  additionalConsultantProps: AdditionalConsultantProps,
  options: any = {},
) => {
  const response = await ApiClient.insertConsultantByDocumentId(
    { documentId, additionalConsultantProps },
    options,
  );
  return response.data;
};

export const removeUserFromProjectByProjectId = async (
  projectId: string,
  userId: string,
): Promise<IProjectView> => {
  const response = await ApiClient.removeUserFromProjectByProjectId({ projectId, userId });
  return response.data;
};

export const favoriteProject = async (
  userId: string,
  projectId: string,
  isFavorite: boolean = true,
) => {
  const response = await ApiClient.editFavoriteProjectByUserIdAndProjectId({
    userId,
    projectId,
    editFavoriteProjectParams: { isFavorite },
  });
  return response.data;
};

export const getRegistrationUserByKey = async (key: string) => {
  const response = await ApiClient.getRegistrationUserByKey({ key });
  return response.data;
};

export const deleteInviteEmailByProjectIdAndUserId = async (projectId: string, userId: string) => {
  const response = await ApiClient.deleteInviteEmailByProjectIdAndUserId({ projectId, userId });
  return response.data;
};

export const impersonateUser = async (userId: string) => {
  return ApiClient.impersonateUser({ userId });
};

export const resetImpersonation = async () => {
  return ApiClient.resetUser().then((res) => res.data);
};

export const assignUsersToCompanyByEmailDomain = async (
  excludedUserIds: Array<string>,
  domain: string,
  companyId: string,
  options: any = {},
) => {
  return ApiClient.assignUsersToCompanyByEmailDomain(
    {
      assignByDomainRequest: {
        excludedUserIds: excludedUserIds,
        domain: domain,
        companyId: companyId,
      },
    },
    options,
  );
};
export const getDocumentPublicLink = async (documentId: string) => {
  return ApiClient.getDocumentPublicLink({ documentId }).then((res) => res.data.link);
};

export const getManyDocumentPublicLinks = async (documentIds: string[]) => {
  return ApiClient.getManyDocumentPublicLinks({
    getManyDocumentLinksRequest: { documentIds },
  }).then((res) => res.data);
};

export const disableRegistrationReminderByUserId = async (userId: string) => {
  return ApiClient.disableRegistrationReminderByUserId({ userId }).then((res) => res.data);
};

export const getPublicUserInfoByEmail = async (email: string) => {
  return createApiClient()
    .getPublicUserInfoByEmail({ email })
    .then((res) => res.data);
};

// PUT - NOT IMPLEMENTED //

// export const putUser = async(userId: string, user: IUser): Promise<number> => {
//   return API.put(`${URL}/${userId}`, user).then((res) => res.status);
