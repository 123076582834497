import React, { useEffect, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  createStyles,
  Divider,
  InputBase,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Theme,
  withStyles,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DayjsUtils from '@date-io/dayjs';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Dayjs } from 'dayjs';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Clear, HighlightOffRounded, Publish } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionTakenType,
  DocumentTemplateType,
  FileCategoryType,
  IFile,
  INumberedDocumentView,
  IProjectUser,
  IUser,
  SecurityPermissionLevel,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import BluebeamFlattenDialog from '../dialogs/BluebeamFlattenDialog';
import FileUploadDialog from '../dialogs/FileUploadDialog';
import {
  downloadFileById,
  fileIsPdf,
  openInBluebeam,
  openInNewTab,
  sortLastNamesDesc,
  waitForFileToBeVerified,
} from '../../scripts/utils';
import {
  acceptSubmissionByDocumentId,
  cancelSubmissionByDocumentId,
  completeReviewByDocumentId,
  editSubcontractorByDocumentId,
  insertSubmissionByDocumentId,
  modifyDocumentById,
  modifyDocumentByIdWithResponse,
  refuseAssignmentByDocumentId,
  refuseSubmissionByDocumentId,
  skipAssignSubcontractorByDocumentId,
  submitToArchitectByDocumentId,
} from '../../models/api/documents';
import { getUserState } from '../../features/user/selectors';
import BluebeamIcon from '../icons/Bluebeam-icon';
import DownloadIcon from '../icons/Download-icon';
import { CancelButton, SubmitButton, UploadButton } from '../custom-components/CustomButtons';
import PDFIcon from '../icons/PDF-icon';
import CircularLoader from '../loader/CircularLoader';
import { getLatestSubmissionFileByDocumentId } from '../../models/api/files';
import { updateDocument } from '../../features/document/actions';
import { getDocumentState } from '../../features/document/selectors';
import { getProjectUsersState } from '../../features/project/selectors';
import { getProjectUserPermissions } from '../../scripts/store-utils';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import ManagePermissionsDialog, {
  ManagePermissionsDialogType,
} from '../design/ManagePermissionsDialog';
import AssignedUserText from '../custom-components/AssignedUserText';
import InlineNotificationList from '../form-pages/user-groups/InlineNotificationList';
import { addSnackbar } from '../../features/snackbar/actions';
import { getDocumentsType } from '../../features/documents/selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#FFFFFF',
    borderRadius: '4px',
    padding: '16px',
    width: '400px',
    zIndex: 1,
    minWidth: '400px',
    transition: 'height 440ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: '100%',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '8px',
    marginBottom: '16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21px',
    textAlign: 'left',
    color: '#0947B9',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    // padding: '8px 16px 8px 16px',
  },
  rootIconButton: {
    padding: 0,
    justifySelf: 'center',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  textfield: {
    height: '32px',
    width: '100%',

    /* Gray / Gray 50 */
    background: '#F9F9F9',

    /* Gray / Gray 400 Brand dark */
    border: '1px solid #949494',
    borderRadius: '5px',
  },
  textfieldLarge: {
    height: '96px',
    width: '100%',

    /* Gray / Gray 50 */
    background: '#F9F9F9',

    /* Gray / Gray 400 Brand dark */
    border: '1px solid #949494',
    borderRadius: '5px',
  },
  formcontrol: {
    padding: '8px 0px 8px 0px',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
  },
  cancelButton: {
    height: '32px',
    border: '2px solid #949494',
    borderRadius: '4px',
    color: '#7A797A',
  },
  refuseButton: {
    height: '32px',
    color: '#ED3F26',
    border: '2px solid #ED3F26',
    borderRadius: '4px',
    '&:hover': {
      border: '2px solid #FF5D45',
      background: 'transparent',
    },
  },
  submitButton: {
    height: '32px',
    background: '#0947B9',
    color: '#FFF',
    borderRadius: '4px',
    '&:hover': {
      background: '#4380ED',
    },
  },
  acceptButton: {
    height: '32px',
    background: '#128750',
    color: '#FFF',
    borderRadius: '4px',
    '&:hover': {
      background: '#2BB073',
    },
  },
  viewButton: {
    width: '70%',
    height: '32px',
    color: '#0947B9',
    border: '2px solid #0947B9',
    borderRadius: '4px',
    alignSelf: 'center',
    '&:hover': {
      border: '2px solid #4380ED',
      color: '#4380ED',
      background: 'transparent',
    },
  },
  icon: {
    fill: '#B2B1B2',
    marginLeft: '4px',
  },
  inputLabel: {
    marginTop: 8,
    marginBottom: 16,
  },
  selectList: {
    maxHeight: 400,
  },
  procore: {
    background: '#F28B00',
    '&:hover': {
      background: '#FF9C17',
    },
  },
  noPadding: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  buttonContainer: {
    display: 'flex',
    paddingTop: '8px',
    width: '100%',
    paddingBottom: '8px',
  },
  submitToArchButton: {
    width: '65%',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
  },
}));

type Props = {
  setMaxHeight: React.Dispatch<React.SetStateAction<number>>;
  defaultArchitect?: IUser;
  defaultContractor?: IUser;
  handleClose: () => void;
};

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: 8,
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

export default function AssignAndSubmit(props: Props) {
  const classes = useStyles();
  const user = useSelector(getUserState);
  const { defaultArchitect, defaultContractor, handleClose, setMaxHeight } = props;
  const dispatch = useDispatch();
  const document = useSelector(getDocumentState)!;
  const documentType = useSelector(getDocumentsType);
  const projectUsers = useSelector(getProjectUsersState);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [skipAssignClicked, setSkipAssignClicked] = useState(false);

  // TODO: Change back to 'Assign Subcontractor' when subcontractor is allowed again
  const [title, setTitle] = useState('Submit to Architect');

  const [subcontractorEmail, setSubcontractorEmail] = useState<string>(
    defaultContractor?.email || '',
  );
  const [subcontractorDueDate, setSubcontractorDueDate] = useState<Dayjs | null>(null);

  const [willAssignSubcontractor, setWillAssignSubcontractor] = useState(false);
  const [reviewRefused, setReviewRefused] = useState(false);

  const [refuseComment, setRefuseComment] = useState('');

  const [bluebeamOpen, setBluebeamOpen] = useState(false);
  const [bluebeamDone, setBluebeamDone] = useState(false);
  const [flattenedFile, setFlattenedFile] = useState<IFile>();

  const [subcontractorUploadOpen, setSubcontractorUploadOpen] = useState(false);
  const [subcontractorFile, setSubcontractorFile] = useState<File>();

  const [generalContractorUploadOpen, setGeneralContractorUploadOpen] = useState(false);
  const [uploadComment, setUploadComment] = useState('');
  const [generalContractorFile, setGeneralContractorFile] = useState<File>();
  const [assignedArchitectId, setAssignedArchitectId] = useState(defaultArchitect?.id || '');
  const [forInformationOnly, setForInformationOnly] = useState(false);

  const [associationsDialogOpen, setAssociationsDialogOpen] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [inputDescription, setInputDescription] = useState(document.title || '');

  // toggle between subcontractor and general contractor here
  const isAssignedSubcontractor = !!document.subcontractorUserId;
  // const isAssignedSubcontractor = user.userId === document.subcontractorUserId;
  const emailInvalid = !/\S+@\S+\.\S+/.test(subcontractorEmail);

  const isSubmittal =
    documentType === DocumentTemplateType.Submittals ||
    documentType === DocumentTemplateType.CloseoutSubmittals;

  const [architects, setArchitects] = useState<IProjectUser[]>([]);

  const associatedUsers =
    (document?.documentUserList?.map((x) => x.user?.id).filter((e) => e) as string[]) || [];
  const associatedGroups = document?.documentUserGroupList?.map((x) => x.userGroupId) || [];

  const canBeArchitect = (permission?: SecurityPermissionLevel | null) => {
    return permission && permission >= 3;
  };

  const fetchArchitects = () => {
    setArchitects(
      getProjectUserPermissions()
        .filter(({ permission }) => canBeArchitect(permission))
        .map(({ projectUser }) => projectUser),
    );
  };

  useEffect(() => {
    if (projectUsers.length > 0 && architects.length === 0) {
      fetchArchitects();
    }
  }, [projectUsers, document]);

  const addSubcontractorFile = (file: File) => {
    setSubcontractorFile(file);
  };

  const addGeneralContractorFile = (file: File) => {
    setGeneralContractorFile(file);
  };

  const handleBluebeamFinish = (file: IFile) => {
    dispatch(
      addSnackbar({
        id: Date.now(),
        message: 'Your Bluebeam file is being processed',
        severity: 'success',
      }),
    );
    setFlattenedFile(file);
    setBluebeamDone(true);
  };

  const acceptSubmission = () => {
    acceptSubmissionByDocumentId(document.id)
      .then((doc) => dispatch(updateDocument(doc)))
      .catch((e) => console.error(e));
  };

  const cancelSubmission = () => {
    setSkipAssignClicked(true);
    cancelSubmissionByDocumentId(document.id)
      .then((doc) => dispatch(updateDocument(doc)))
      .catch((e) => console.error(e))
      .finally(() => setSkipAssignClicked(false));
  };

  const refuseAssignment = () => {
    setWillAssignSubcontractor(false);
    setSubcontractorFile(undefined);
    setSubcontractorEmail('');
    setSubcontractorDueDate(null);
    refuseAssignmentByDocumentId(document.id)
      .then((doc) => dispatch(updateDocument(doc)))
      .catch((e) => console.error(e));
  };

  const skipAssignSubcontractor = async () => {
    setSkipAssignClicked(true);
    skipAssignSubcontractorByDocumentId(document.id)
      .then((doc) => dispatch(updateDocument(doc)))
      .catch((e) => console.error(e))
      .finally(() => setSkipAssignClicked(false));
  };

  const generalContractorUpload = () => {
    return insertSubmissionByDocumentId(
      document.id,
      generalContractorFile!.name,
      generalContractorFile!,
      uploadComment ? { creatorUserId: user.id, text: uploadComment } : undefined,
      handleUploadProgress,
    );
  };

  const subcontractorUploadSubmittal = () => {
    setIsLoading(true);
    dispatch(blockNavigation());
    return insertSubmissionByDocumentId(document.id, subcontractorFile!.name, subcontractorFile!)
      .then(({ document: doc }) => {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: 'Submittal uploaded!',
            severity: 'success',
          }),
        );
        dispatch(updateDocument(doc));
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setIsLoading(false);
        dispatch(allowNavigation());
      });
  };

  const editSubcontractor = () => {
    editSubcontractorByDocumentId(
      document.id,
      subcontractorDueDate!.toISOString(),
      subcontractorEmail,
    )
      .then((doc) => {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: 'Subcontractor assigned!',
            severity: 'success',
          }),
        );
        dispatch(updateDocument(doc));
      })
      .catch((e) => console.error(e));
  };

  const submitToArchitect = async () => {
    let updatedDocument: INumberedDocumentView;
    if (forInformationOnly) {
      updatedDocument = (
        await completeReviewByDocumentId(document.id, {
          actionTaken: ActionTakenType.ForInformationOnly,
        })
      ).document;
    } else {
      updatedDocument = await submitToArchitectByDocumentId(document.id, assignedArchitectId);
    }
    dispatch(
      addSnackbar({
        id: Date.now(),
        message: `Submitted${forInformationOnly ? '' : ' to architect'}!`,
        severity: 'success',
      }),
    );
    return updatedDocument;
  };

  const handleContractorSubmission = async () => {
    setIsLoading(true);
    dispatch(blockNavigation());
    try {
      let verified = false;
      let olderDocument: INumberedDocumentView | null = null;
      if (bluebeamDone && flattenedFile) {
        setLoadingMessage('Verifying upload...');
        verified = await waitForFileToBeVerified(flattenedFile, true);
      } else {
        setLoadingMessage(`Uploading ${generalContractorFile?.name}...`);
        const response = await generalContractorUpload();
        olderDocument = response.document;
        setLoadingMessage('Verifying upload...');
        if (!response.file) verified = false;
        else verified = await waitForFileToBeVerified(response.file);
      }
      if (!verified) {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: `File ${
              flattenedFile || generalContractorFile
                ? (flattenedFile || generalContractorFile)!.name
                : ''
            } has not finished uploading. You must cancel the upload and try again`,
            severity: 'error',
          }),
        );
        return;
      }
      setLoadingMessage('Updating document...');

      if (inputDescription.trim() !== document.title)
        await modifyDocumentById(document.id, { patch: { title: inputDescription.trim() } });
      const updatedDocument = await submitToArchitect();
      dispatch(updateDocument({ ...(olderDocument && olderDocument), ...updatedDocument }));
    } finally {
      setIsLoading(false);
      dispatch(allowNavigation());
    }
  };

  const handleRefuse = () => {
    // TODO: Add comments
    refuseSubmissionByDocumentId(
      document.id,
      refuseComment ? { creatorUserId: user.id, text: refuseComment } : undefined,
    ).then((doc) => {
      dispatch(updateDocument(doc));
      setWillAssignSubcontractor(true);
      setReviewRefused(false);
      setAssignedArchitectId('');
    });
  };

  const handleBluebeam = () => {
    return getLatestSubmissionFileByDocumentId(document.id)
      .then((file) => {
        if (file) {
          openInBluebeam(file.id, file.name);
        }
      })
      .catch((e) => console.error(e));
  };

  const addNewUsersToNotificationList = async (
    userIds: string[],
    associatedGroupIds: string[],
    associatedDocumentIds: string[],
  ) => {
    try {
      const addGroupIds = associatedGroupIds.filter(
        (id) => !document!.documentUserGroupList!.some(({ userGroupId }) => userGroupId === id),
      );
      const removeGroupIds = document!
        .documentUserGroupList!.filter(
          ({ userGroupId }) => !associatedGroupIds.some((id) => id === userGroupId),
        )
        .map(({ userGroupId }) => userGroupId);

      const removeUserIds = document!
        .documentUserList!.filter(({ userId }) => !userIds.some((id) => id === userId))
        .map(({ userId }) => userId);

      const { updatedDocument } = await modifyDocumentByIdWithResponse(document!.id, {
        followers: {
          addUserIds: userIds,
          removeUserIds: removeUserIds,
          removeUserGroupIds: removeGroupIds,
          addUserGroupIds: addGroupIds,
        },
        associatedDocuments: {
          addIds: associatedDocumentIds,
        },
      });

      dispatch(updateDocument(updatedDocument));
    } catch (e: any) {
      console.log(e);
    } finally {
      setAssociationsDialogOpen(false);
    }
  };

  const ref = useRef<any>();
  useEffect(() => {
    if (ref) setMaxHeight(ref!.current!.clientHeight);
  });

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const submitToArchitectContent = () => (
    <FormControl>
      {isSubmittal ? (
        <TextField
          variant="outlined"
          margin="none"
          size="small"
          fullWidth
          multiline
          rows="4"
          label="Description"
          value={inputDescription}
          onChange={(e) => {
            setInputDescription(e.target.value);
          }}
          style={{ marginBottom: 8 }}
        />
      ) : null}
      <Typography>Upload GC-approved PDF</Typography>
      {document.subcontractorUserId && (
        <div style={{ display: 'flex', paddingTop: 16, paddingBottom: 8 }}>
          <Button
            variant="outlined"
            color="primary"
            style={{ height: '32px', width: 170 }}
            onClick={() =>
              downloadFileById(
                document.files!.slice().reverse()[0].id,
                document.files!.slice().reverse()[0].name,
              )
            }
            startIcon={<DownloadIcon fill="#2C69D6" />}
          >
            Download
          </Button>
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', paddingTop: 8, paddingBottom: 16 }}>
          <Button
            variant="outlined"
            color="primary"
            disabled={bluebeamDone}
            style={{ height: '32px', width: 170 }}
            onClick={() => setGeneralContractorUploadOpen(true)}
            startIcon={<Publish fill="#2C69D6" />}
          >
            UPLOAD
          </Button>
          {document.subcontractorUserId && (
            <>
              <div style={{ display: 'inline-flex', flexGrow: 100 }} />
              <Button
                variant="contained"
                color="primary"
                disabled={!fileIsPdf(document.files!.slice().reverse()[0])}
                onClick={() => setBluebeamOpen(true)}
                style={{ marginRight: '16px', width: 170 }}
                startIcon={<BluebeamIcon fill="white" width={16} height={16} />}
              >
                MARKUP IN BLUEBEAM
              </Button>
            </>
          )}
        </div>
        {generalContractorFile ? (
          <div style={{ display: 'flex', paddingBottom: 12, width: '100%', alignItems: 'center' }}>
            <IconButton onClick={() => setGeneralContractorFile(undefined)} style={{ padding: 0 }}>
              <HighlightOffRounded htmlColor="#7A797A" />
            </IconButton>
            <PDFIcon style={{ marginLeft: 8, minWidth: '20px' }} />{' '}
            <Typography
              style={{
                paddingLeft: 12,
                width: '95%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {generalContractorFile.name}
            </Typography>
          </div>
        ) : null}
      </div>

      {isSubmittal ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            width: '100%',
            fontSize: '14px',
            color: '#464546',
            marginBottom: 8,
          }}
        >
          <Checkbox
            checked={forInformationOnly}
            onChange={(e) => {
              setForInformationOnly(e.target.checked);
            }}
            style={{ paddingLeft: 0 }}
          />
          For Information Only. No Response Needed.
        </div>
      ) : null}

      <Typography>Assign to Architect</Typography>

      <FormControl fullWidth variant="outlined" className={classes.inputLabel}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          input={<BootstrapInput />}
          value={assignedArchitectId}
          onChange={(e: any) => {
            setAssignedArchitectId(e.target.value);
          }}
          fullWidth
          MenuProps={{ classes: { paper: classes.selectList } }}
        >
          <MenuItem value="">No Architect Assigned</MenuItem>
          {architects
            ? architects
                .sort((a, b) => sortLastNamesDesc(a.user?.name, b.user?.name))
                .map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.user!.id}>
                      <AssignedUserText
                        name={item.user!.name!}
                        company={item.user?.company?.name}
                      />
                    </MenuItem>
                  );
                })
            : undefined}
        </Select>
      </FormControl>

      <TextField
        multiline
        rows={2}
        placeholder="Comments..."
        value={uploadComment}
        onChange={(event) => setUploadComment(event.target.value)}
        style={{ marginBottom: 16 }}
        InputProps={{ disableUnderline: true, style: { padding: '6px' } }}
        className={classes.textfieldLarge}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={() => setAssociationsDialogOpen(true)}
        style={{ marginBottom: 16 }}
      >
        Define Notification List
      </Button>
      <InlineNotificationList
        associatedUsers={associatedUsers}
        associatedGroups={associatedGroups}
      />
    </FormControl>
  );

  const submitToArchitectActions = () => (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 8,
          }}
        >
          <Typography style={{ fontWeight: 500, marginBottom: 8 }}>{loadingMessage}</Typography>
          {loadingMessage.includes('Uploading') ? (
            <>
              <Typography>{uploadProgress}%</Typography>
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                style={{ height: 6, width: '100%', marginTop: 4 }}
              />
            </>
          ) : (
            <CircularLoader />
          )}
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="outlined"
            disabled={skipAssignClicked}
            style={{
              height: '32px',
              padding: '0px 20px',
              color: '#949494',
              border: '2px solid #949494',
            }}
            onClick={() => {
              setWillAssignSubcontractor(false);
              cancelSubmission();
            }}
            startIcon={<Clear fill="#949494" />}
          >
            CANCEL
          </Button>
          <div style={{ display: 'inline-flex', flexGrow: 100 }} />
          <SubmitButton
            variant="contained"
            disabled={!(generalContractorFile || bluebeamDone) || !assignedArchitectId}
            onClick={handleContractorSubmission}
            style={{ minWidth: '100px' }}
          >
            SUBMIT
          </SubmitButton>
        </div>
      )}
    </>
  );

  const getContent = () => {
    switch (document.workflowStatus) {
      case WorkflowStatusType.Initial:
        if (isAssignedSubcontractor) {
          return (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {subcontractorFile ? (
                  <div
                    style={{
                      display: 'flex',
                      paddingBottom: 12,
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <PDFIcon />
                    <div style={{ paddingLeft: 8 }}>{subcontractorFile.name} </div>
                  </div>
                ) : null}
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <UploadButton
                    onClick={() => setSubcontractorUploadOpen(true)}
                    style={{ marginTop: 8, marginBottom: 16, width: '80%' }}
                  />
                </div>
              </div>
              <Divider style={{ marginBottom: 16 }} />
            </>
          );
        }
        if (willAssignSubcontractor)
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: 16,
              }}
            >
              <FormControl className={classes.formcontrol} style={{ width: '100%', maxWidth: 275 }}>
                <Typography variant="body1" style={{ paddingBottom: 4 }}>
                  Subcontractor Email
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="subcontractor@example.com"
                  required
                  autoFocus
                  value={subcontractorEmail}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => setSubcontractorEmail(event.target.value)}
                />
              </FormControl>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <FormControl
                  className={classes.formcontrol}
                  style={{ width: '100%', maxWidth: 275 }}
                >
                  <Typography variant="body1" style={{ paddingBottom: 4 }}>
                    Due Date
                  </Typography>
                  <KeyboardDatePicker
                    value={subcontractorDueDate}
                    onChange={(date) => setSubcontractorDueDate(date)}
                    inputVariant="outlined"
                    InputProps={{ style: { padding: 0 } }}
                    format="MM/DD/YYYY"
                    placeholder="MM/DD/YYYY"
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            </div>
          );
        return <></>;

      case WorkflowStatusType.SubcontractorUploaded:
        const file = document
          .files!.slice()
          .reverse()
          .find((file) => file.category === FileCategoryType.DocumentUploads);
        if (!reviewRefused)
          return (
            <>
              <Button
                variant="outlined"
                disabled={!file || !fileIsPdf(file)}
                onClick={() => openInNewTab(file!.id)}
                className={classes.viewButton}
              >
                <VisibilityIcon />
                &nbsp;VIEW
              </Button>
              <Divider style={{ marginTop: 16, marginBottom: 16 }} />
              <FormControl>
                <Typography style={{ paddingBottom: 16 }}>After reviewing submission:</Typography>
                <div style={{ display: 'flex' }}>
                  <Button
                    variant="outlined"
                    onClick={() => setReviewRefused(true)}
                    className={classes.refuseButton}
                  >
                    DECLINE
                  </Button>
                  <div style={{ display: 'inline-flex', flexGrow: 100 }} />
                  <Button
                    variant="contained"
                    onClick={acceptSubmission}
                    className={classes.acceptButton}
                  >
                    ACCEPT
                  </Button>
                </div>
              </FormControl>
            </>
          );
        return (
          <>
            <TextField
              multiline
              rows={2}
              placeholder="Comments..."
              value={refuseComment}
              onChange={(event) => setRefuseComment(event.target.value)}
              InputProps={{ disableUnderline: true, style: { padding: '6px' } }}
              className={classes.textfieldLarge}
            />
            <div className={classes.buttonContainer}>
              <Button
                variant="outlined"
                style={{
                  height: '32px',
                  padding: '6px 16px',
                  marginLeft: '35px',
                  marginTop: '5px',
                  color: '#ED3F26',
                  border: '2px solid #ED3F26',
                  minWidth: '100px',
                }}
                onClick={handleRefuse}
              >
                REFUSE
              </Button>
              <div style={{ display: 'inline-flex', flexGrow: 1, minWidth: '16px' }} />
              <Button
                variant="outlined"
                style={{
                  height: '32px',
                  padding: '6px 16px',
                  marginRight: '35px',
                  marginTop: '5px',
                  color: '#949494',
                  border: '2px solid #949494',
                }}
                onClick={() => setReviewRefused(false)}
                startIcon={<Clear fill="#949494" />}
              >
                CANCEL
              </Button>
            </div>
          </>
        );

      case WorkflowStatusType.ReadyForSubmissionToArchitect:
      case WorkflowStatusType.GeneralContractorUploaded:
        return submitToArchitectContent();

      case WorkflowStatusType.SubmittedForReview:
        return <Typography>This step is complete!</Typography>;

      default:
        return <></>;
    }
  };

  const getActions = () => {
    switch (document.workflowStatus) {
      case WorkflowStatusType.Initial:
        if (isAssignedSubcontractor)
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
              }}
            >
              {isLoading ? (
                <CircularLoader />
              ) : (
                <SubmitButton
                  variant="contained"
                  style={{ width: '50%', marginBottom: 16 }}
                  disabled={subcontractorFile === undefined}
                  onClick={subcontractorUploadSubmittal}
                >
                  SUBMIT TO GC
                </SubmitButton>
              )}
              <Button
                variant="outlined"
                onClick={refuseAssignment}
                className={classes.refuseButton}
                style={{ width: '50%' }}
              >
                REASSIGN TO GC
              </Button>
            </div>
          );
        if (willAssignSubcontractor)
          return (
            <>
              <div style={{ display: 'inline-flex', flexGrow: 60 }} />
              <CancelButton
                onClick={() => {
                  setWillAssignSubcontractor(false);
                  setSubcontractorEmail('');
                  setSubcontractorDueDate(null);
                }}
              >
                CANCEL
              </CancelButton>
              <div style={{ display: 'inline-flex', flexGrow: 90 }} />
              {/* TODO: Close, alert assigned subcontractor, change document status to SubcontractorUploaded */}
              <SubmitButton
                variant="contained"
                disabled={emailInvalid || !subcontractorDueDate}
                onClick={editSubcontractor}
              >
                SUBMIT
              </SubmitButton>
              <div style={{ display: 'inline-flex', flexGrow: 60 }} />
            </>
          );
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/*<SubmitButton*/}
              {/*  variant="contained"*/}
              {/*  style={{ marginBottom: 16, width: '65%' }}*/}
              {/*  onClick={() => setWillAssignSubcontractor(true)}*/}
              {/*>*/}
              {/*  Assign To Subcontractor*/}
              {/*</SubmitButton>*/}
              <Button
                variant="contained"
                color="primary"
                disabled={skipAssignClicked}
                className={classes.submitToArchButton}
                onClick={skipAssignSubcontractor}
              >
                Submit to architect
              </Button>
            </div>
          </div>
        );

      case WorkflowStatusType.ReadyForSubmissionToArchitect:
      case WorkflowStatusType.GeneralContractorUploaded:
        return submitToArchitectActions();

      default:
        return <></>;
    }
  };

  useEffect(() => {
    switch (document.workflowStatus) {
      default:
      case WorkflowStatusType.Initial:
        if (isAssignedSubcontractor) setTitle('Upload Submittal');
        else setTitle('Get Started');
        break;
      case WorkflowStatusType.SubcontractorUploaded:
        if (reviewRefused) setTitle('Request Resubmission');
        else setTitle('Review Submission');
        break;
      case WorkflowStatusType.ReadyForSubmissionToArchitect:
        setTitle('Submit To Architect');
        break;
    }
  }, [document, isAssignedSubcontractor, reviewRefused]);

  return (
    <div className={classes.container} ref={ref}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
        <div style={{ display: 'inline-flex', flexGrow: 100 }} />
        <IconButton
          onClick={!isLoading ? handleClose : undefined}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </div>
      <div className={classes.content}>{getContent()}</div>
      <div className={classes.actions}>{getActions()}</div>
      <BluebeamFlattenDialog
        dialogOpen={bluebeamOpen}
        handleClose={() => setBluebeamOpen(false)}
        handleMarkup={handleBluebeam}
        documentId={document.id}
        finish={handleBluebeamFinish}
      />
      <FileUploadDialog
        open={subcontractorUploadOpen}
        addFile={(f) => addSubcontractorFile(f as File)}
        removeFile={() => setSubcontractorFile(undefined)}
        canSubmit={subcontractorFile !== undefined}
        file={subcontractorFile}
        disableComments
        handleClose={() => setSubcontractorUploadOpen(false)}
        title="Upload Submittal"
        disableDesignUpload
      />
      <FileUploadDialog
        open={generalContractorUploadOpen}
        addFile={(f) => addGeneralContractorFile(f as File)}
        removeFile={() => setGeneralContractorFile(undefined)}
        canSubmit={generalContractorFile !== undefined}
        file={generalContractorFile}
        disableComments
        handleClose={() => setGeneralContractorUploadOpen(false)}
        title="Upload Submittal"
        disableDesignUpload
      />
      <ManagePermissionsDialog
        dialogOpen={associationsDialogOpen}
        closeDialog={() => setAssociationsDialogOpen(false)}
        type={ManagePermissionsDialogType.AssociatedUsers}
        associatedUsers={associatedUsers}
        associatedGroups={associatedGroups}
        submitAssociatedUsersAndGroups={addNewUsersToNotificationList}
      />
    </div>
  );
}
