import {
  Box,
  Button,
  CircularProgress,
  CircularProgressProps,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  GetProjectZipResponse,
  ISimplifiedProject,
  JobStatusType,
} from '../../api-client/autogenerated';
import { makeStyles } from '@material-ui/core/styles';
import { FileCopy, HighlightOffRounded } from '@material-ui/icons';
import '../../css/exportDialog.css';
import DownloadIcon from '../icons/Download-icon';
import { copyToClipboard, openFileInNewTab } from '../../scripts/utils';
import { addSnackbar } from '../../features/snackbar/actions';
import { useDispatch } from 'react-redux';

type ExportDialogProps = {
  project: ISimplifiedProject;
  isExportDialogOpen: boolean;
  closeDialog: () => void;
  projectState: JobStatusType | undefined;
  projectInProgress: boolean;
  projectExportPercentage: number;
  response?: GetProjectZipResponse;
};

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '50px',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
    minWidth: 500,
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '30px',
    textAlign: 'center',
    color: '#0947B9',
    textTransform: 'uppercase',
    justifySelf: 'center',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const ExportDialog = (props: ExportDialogProps) => {
  const {
    project,
    isExportDialogOpen,
    closeDialog,
    projectState,
    projectInProgress,
    projectExportPercentage,
    response,
  } = props;

  const dispatch = useDispatch();

  const isLoading =
    projectState !== JobStatusType.Complete && projectState !== JobStatusType.Canceled;

  const classes = useStyles();

  const handleCopy = async () => {
    if (!response?.s3Response?.downloadLink) return;
    try {
      await copyToClipboard(response.s3Response.downloadLink);
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Link copied to clipboard',
          severity: 'success',
        }),
      );
    } catch {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Error copying link',
          severity: 'error',
        }),
      );
    }
  };

  const handleDownload = async () => {
    if (!response?.file?.id) return;
    await openFileInNewTab(response.file.id);
  };

  const getText = () => {
    let typographyMessage: String;
    switch (projectState) {
      case JobStatusType.Initiated: {
        if (projectInProgress) typographyMessage = 'The project is being zipped';
        else typographyMessage = 'The project is being indexed';
        break;
      }
      case JobStatusType.Pending:
        typographyMessage = 'The project is being initialized';
        break;
      case JobStatusType.Complete:
        typographyMessage = 'The project is now downloading';
        break;
      default:
        typographyMessage = 'The project is loading';
    }
    return (
      <Typography
        className="fade-in"
        style={{ textAlign: 'center', transition: 'opacity 40s ease' }}
      >
        {typographyMessage}
      </Typography>
    );
  };

  const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
    return (
      <Box style={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          style={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            style={{ fontWeight: 'bolder' }}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog open={isExportDialogOpen} maxWidth="sm">
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title} align="center">
          Export Project
        </Typography>
        <IconButton
          id="export-dialog-close-button"
          style={{ right: '20px', top: '12px', position: 'absolute' }}
          onClick={closeDialog}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <>
            <div style={{ display: 'flex', margin: '50px 0px' }}>
              <div
                style={{
                  width: '30%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {projectInProgress ? (
                  <CircularProgressWithLabel
                    value={projectExportPercentage}
                    size={70}
                    thickness={3}
                  />
                ) : (
                  <CircularProgress color="primary" size={70} thickness={3} />
                )}
              </div>
              <div style={{ width: '70%' }}>
                <Typography
                  style={{
                    marginBottom: 20,
                    fontWeight: 'bolder',
                    color: 'black',
                    textAlign: 'center',
                    transition: 'opacity 6s ease',
                  }}
                >
                  {`Project ${project.name}`}
                </Typography>
                {getText()}
              </div>
            </div>
            <Typography style={{ textAlign: 'center' }}>
              To export a project takes time, larger projects may take longer
            </Typography>
          </>
        ) : (
          <>
            <Typography
              style={{
                fontSize: 18,
                marginBottom: 20,
                fontWeight: 'bolder',
                color: 'black',
                textAlign: 'center',
                transition: 'opacity 6s ease',
              }}
            >
              {`Project ${project.name}`}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FileCopy />}
                disabled={!response?.s3Response?.downloadLink}
                onClick={handleCopy}
              >
                Copy Link
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<DownloadIcon fill="#fff" />}
                disabled={!response?.file?.id}
                onClick={handleDownload}
              >
                Download
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default ExportDialog;
