import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import MonthDayCalendar from './MonthDayCalendar';
import {
  getSoonestDueDocuments,
  getSoonestDueDocumentsAssignedByUser,
  getSoonestDueDocumentsAssignedToUser,
} from '../../models/api/documents';
import { INumberedDocumentView, IProjectCalendarEvent } from '../../api-client/autogenerated';
import { getUserState } from '../../features/user/selectors';
import { getProjectState } from '../../features/project/selectors';
import IconButton from '@material-ui/core/IconButton';
import { Print } from '@material-ui/icons';
import { useReactToPrint } from 'react-to-print';
import fileDownload from 'js-file-download';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadIcon from '../icons/Download-icon';
import { getAllProjectCalendarEvents } from '../../models/api/calendar';
import ical from 'ical-generator';

const useStyles = makeStyles({
  root: {
    height: '100%',
    left: '234px',
    top: '589px',
  },
  titleStyle: {
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
  },
});

function CalendarUpcoming() {
  const [loadingAssigned, setLoadingAssigned] = useState(true);
  const classes = useStyles();
  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);
  const [upcomingType, setUpcomingType] = useState('assigned-to-me');

  const calendarRef = useRef<any>(null);
  const iCalendar = useRef(ical({ name: 'Centerline Calendar' }));

  const [projectEvents, setProjectEvents] = useState<IProjectCalendarEvent[]>([]);
  useEffect(() => {
    if (project?.id) getAllProjectCalendarEvents(project.id).then(setProjectEvents);
  }, [project]);

  const [soonestDueDocuments, setSoonestDueDocuments] = useState<INumberedDocumentView[]>([]);
  useEffect(() => {
    setLoadingAssigned(true);
    switch (upcomingType) {
      case 'assigned-to-me':
        getSoonestDueDocumentsAssignedToUser(user.id, project?.id)
          .then((result) => {
            setSoonestDueDocuments(result);
          })
          .finally(() => setLoadingAssigned(false));
        break;
      case 'assigned-by-me':
        getSoonestDueDocumentsAssignedByUser(user.id, project?.id)
          .then((result) => {
            setSoonestDueDocuments(result);
          })
          .catch((e) => console.log(e))
          .finally(() => setLoadingAssigned(false));
        break;
      case 'all':
      default:
        getSoonestDueDocuments(user.id, project?.id)
          .then((result) => {
            setSoonestDueDocuments(result);
          })
          .catch((e) => console.log(e))
          .finally(() => setLoadingAssigned(false));
        break;
    }
  }, [upcomingType, project]);

  const handlePrint = useReactToPrint({
    content: () => calendarRef.current,
  });

  const handleExport = () => {
    fileDownload(iCalendar.current.toString(), 'CenterlineCalendar.ics', 'application/ics');
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.titleStyle}>
        <h2
          className="h2"
          style={{
            textAlign: 'left',
            color: '#FFFFFF',
            margin: 0,
          }}
        >
          Calendar
        </h2>
      </CardContent>
      <CardContent>
        <div style={{ display: 'flex' }}>
          <Button
            onClick={() => setUpcomingType('all')}
            style={{ borderBottom: upcomingType === 'all' ? '2px solid #F28B00' : 'none' }}
          >
            All
          </Button>
          <Button
            onClick={() => setUpcomingType('assigned-to-me')}
            style={{
              borderBottom: upcomingType === 'assigned-to-me' ? '2px solid #F28B00' : 'none',
            }}
          >
            Assigned to me
          </Button>
          <Button
            onClick={() => setUpcomingType('assigned-by-me')}
            style={{
              borderBottom: upcomingType === 'assigned-by-me' ? '2px solid #F28B00' : 'none',
            }}
          >
            Assigned by me to others
          </Button>
          <div style={{ display: 'inline-flex', flexGrow: 100 }} />

          <Tooltip arrow placement="top" title="Export">
            <IconButton size="small" onClick={handleExport} style={{ padding: 0 }}>
              <DownloadIcon width={22} height={22} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Print">
            <IconButton size="small" onClick={handlePrint} style={{ padding: 0, marginLeft: 8 }}>
              <Print />
            </IconButton>
          </Tooltip>
        </div>

        <MonthDayCalendar
          soonestDueDocuments={soonestDueDocuments}
          projectEvents={projectEvents}
          loading={loadingAssigned}
          calendarRef={calendarRef}
          iCalendar={iCalendar}
        />
      </CardContent>
    </Card>
  );
}

export default CalendarUpcoming;
