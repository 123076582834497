import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { HighlightOffRounded } from '@material-ui/icons';
import { IProjectView, ISimplifiedProjectView } from '../../api-client/autogenerated';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import { getProjectsSimplified } from '../../models/api/project';
import { ascendingComparator } from '../document-index/DocumentIndexUtils';

interface FavoriteProjectsDialogProps {
  dialogOpen: boolean;
  handleClose: () => void;
  handleApply: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    projectIds: string[],
  ) => void;
  initialFavoriteProjectIds: string[];
}

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '80px',
    padding: '16px 157px 24px 24px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  formLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '22px',
    textTransform: 'uppercase',
    color: '#616061',
    marginTop: '20px',
  },
  formControl: {
    width: '100%',
  },
  projectSelect: {
    background: '#F9F9F9',
    border: '1px solid #949494',
    borderRadius: '5px',
  },
  buttonContainer: {
    display: 'inline-flex',
    padding: '16px 12px 12px 12px',
    flexGrow: 1,
  },
  instructions: {
    paddingBottom: 16,
  },
  marginBottom: {
    marginBottom: 2,
  },
  button: {
    height: '32px',
    width: '120px',
  },
});

export default function FavoriteProjectsDialog(props: FavoriteProjectsDialogProps) {
  const classes = useStyles();
  const { dialogOpen, handleClose, handleApply, initialFavoriteProjectIds } = props;

  const [projects, setProjects] = useState<ISimplifiedProjectView[]>([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);

  const handleProjectSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedProjectIds.includes(event.target.value))
      setSelectedProjectIds(
        selectedProjectIds.filter((projectId) => projectId !== event.target.value),
      );
    else setSelectedProjectIds([...selectedProjectIds, event.target.value]);
  };

  const hasError = selectedProjectIds.length > 5;

  useEffect(() => {
    setSelectedProjectIds(initialFavoriteProjectIds);
  }, [initialFavoriteProjectIds]);

  useEffect(() => {
    if (dialogOpen) {
      getProjectsSimplified().then((data) => setProjects(data.results));
    }
  }, [dialogOpen]);

  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth="sm">
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title}>Favorite Projects</Typography>
        <IconButton
          style={{ right: '20px', top: '5px', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.instructions}>
          {projects.length > 0 ? (
            <span>
              High-level information from the projects that you favorite will be displayed on the
              dashboard. You can change your favorite projects at any time.
            </span>
          ) : (
            <span>You don't have any projects.</span>
          )}
        </Typography>
        {projects.length > 0 && (
          <FormControl
            error={hasError}
            component="fieldset"
            variant="outlined"
            className={classes.formControl}
            focused={selectedProjectIds.length > 0}
          >
            <FormLabel component="legend" className={classes.marginBottom}>
              Select up to 5
            </FormLabel>
            <Grid container spacing={1}>
              {projects
                .sort((a, b) => ascendingComparator(a, b, 'name'))
                .map((project) => {
                  return (
                    <Grid key={project.id} item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <GreenCheck
                              onChange={handleProjectSelect}
                              defaultChecked={initialFavoriteProjectIds.includes(project.id)}
                            />
                          }
                          label={project.name}
                          value={project.id}
                        />
                      </FormGroup>
                    </Grid>
                  );
                })}
            </Grid>
          </FormControl>
        )}
        <div className={classes.buttonContainer}>
          {projects.length > 0 ? (
            <Button
              variant="contained"
              color="primary"
              disabled={hasError}
              className={classes.button}
              onClick={(event) => {
                handleApply(event, selectedProjectIds);
                handleClose();
              }}
            >
              APPLY
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                handleClose();
              }}
            >
              CANCEL
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
