import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { useDispatch } from 'react-redux';
import './PortalLogin.scss';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import { fetchUser } from '../../features/user/actions';
import { getPublicPageRedirect, setPublicPageRedirect } from '../../scripts/utils';
import { resetApiClient } from '../../api-client/api-client';
import { addSnackbar } from '../../features/snackbar/actions';
import CreateShortcutDialog from './CreateShortcutDialog';
import { getPublicUserInfoByEmail } from '../../models/api/users';
import CircularLoader from '../loader/CircularLoader';

const useStyles = makeStyles({
  root: {
    width: '100%',
    textAlign: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 8,
  },
  submit: {
    marginTop: 16,
    marginBottom: 24,
    maxWidth: 240,
  },
  textField: {
    maxWidth: 350,
  },
  checkbox: {
    marginTop: 8,
  },
  links: {
    maxWidth: 400,
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    textDecoration: 'none',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#0947B9',
    '&:hover': {
      color: '#4380ED',
    },
  },
  bidModuleLoginButton: {
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#0947B9',
    '&:hover': {
      color: '#4380ED',
    },
    textTransform: 'uppercase',
  },
  transparentBack: {
    background: 'transparent',
  },
});

type SignInProps = {
  isBidding: boolean;
  setForgotPassword: (forgotPassword: boolean) => void;
  setEmail: (email: string) => void;
  setIsLettermansLogin?: (val: boolean) => void;
};

interface AuthenticationError {
  code: string;
  name: string;
  message: string;
}

function SignIn({ isBidding, setForgotPassword, setEmail, setIsLettermansLogin }: SignInProps) {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const [inputUsername, setInputUsername] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleLogin = async (e: React.FormEvent) => {
    setIsLoggingIn(true);
    e.preventDefault();
    e.stopPropagation();
    const username = inputUsername.trim();
    const password = inputPassword.trim();
    if (!username) {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Email field is empty',
          severity: 'error',
        }),
      );
      setIsLoggingIn(false);
      return;
    }
    Auth.configure({ storage: rememberMe ? localStorage : sessionStorage });
    const { isLettermansImport, isRegistered, isPartOfMainPortal } = await getPublicUserInfoByEmail(
      username,
    );
    try {
      await Auth.signIn(username, password);
      resetApiClient();
      dispatch(fetchUser());
      const redirect = getPublicPageRedirect();
      if (redirect) {
        setPublicPageRedirect(null);
        history.push(redirect);
      } else if (isBidding || !isPartOfMainPortal) {
        history.push('/bidding');
      } else {
        history.push('/main');
      }
    } catch (error: any) {
      console.log('error signing in:', error);
      const errorObj = error as AuthenticationError;
      if (isLettermansImport && !isRegistered) {
        setEmail(username);
        setForgotPassword(true);
        if (setIsLettermansLogin) setIsLettermansLogin(true);
      } else if (errorObj.code === 'UserNotConfirmedException') {
        history.push('/main/register', { email: username });
      } else if (errorObj.code === 'PasswordResetRequiredException') {
        setEmail(username);
        setForgotPassword(true);
      } else {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: errorObj.message || 'Incorrect username or password',
            severity: 'error',
          }),
        );
      }
    } finally {
      setIsLoggingIn(false);
    }
  };

  return (
    <div className={classes.root}>
      <Typography
        component="h1"
        variant="h5"
        style={{
          textTransform: 'none',
          fontWeight: 256,
          fontSize: 28,
          height: 32,
          margin: 0,
          lineHeight: 1.25,
        }}
      >
        {isBidding ? 'BidConnect SIGN IN' : 'SIGN IN'}
      </Typography>
      {/*<Button color="primary" onClick={() => setDialogOpen(true)} style={{ marginTop: 8 }}>*/}
      {/*  Create Desktop Shortcut*/}
      {/*</Button>*/}
      {isBidding && (
        <>
          <Typography style={{ fontWeight: 600, marginTop: 16, marginBottom: 8 }}>
            Welcome Lettermans BidConnect users!
          </Typography>
          <Typography>We have already created an account for you.</Typography>
          <Typography>Use your Lettermans credentials to login.</Typography>
        </>
      )}
      <form onSubmit={handleLogin} className={classes.form} noValidate>
        <TextField
          variant="filled"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          InputLabelProps={{ classes: { root: classes.transparentBack } }}
          name="email"
          autoComplete="email"
          autoFocus
          value={inputUsername}
          onChange={(e) => setInputUsername(e.target.value)}
          className={classes.textField}
        />
        <TextField
          variant="filled"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          InputLabelProps={{ classes: { root: classes.transparentBack } }}
          type="password"
          id="password"
          autoComplete="current-password"
          value={inputPassword}
          onChange={(e) => setInputPassword(e.target.value)}
          className={classes.textField}
        />
        <FormControlLabel
          control={
            <GreenCheck
              value={rememberMe}
              checked={rememberMe}
              onChange={(event: any) => setRememberMe(event.target.checked)}
              name="remember"
            />
          }
          label="Remember me"
          style={{ margin: 0 }}
        />
        {!isLoggingIn ? (
          <Button
            id="sign-in-button"
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submit}
          >
            Sign In
          </Button>
        ) : (
          <CircularLoader style={{ marginTop: 16, marginBottom: 16 }} />
        )}
        <Grid container className={classes.links} style={{ height: 32 }} direction="row">
          <Grid item>
            <Button
              id="forgot-password"
              onClick={() => {
                setForgotPassword(true);
                if (setIsLettermansLogin) setIsLettermansLogin(false);
              }}
              className={classes.link}
            >
              Forgot password?
            </Button>
          </Grid>
          {!isBidding && (
            <Grid item style={{ paddingTop: '20px' }}>
              <Typography style={{ fontSize: 18, lineHeight: '21px' }}>
                If you are trying to view public bid documents or want to add a project for bidding,
                click the link below.
              </Typography>
            </Grid>
          )}
          {isBidding ? (
            <Grid item>
              <Link
                to="/bidding/register"
                className={classes.link}
                style={{ textDecoration: 'none' }}
              >
                Don&apos;t have an account? Sign Up
              </Link>
            </Grid>
          ) : (
            <div style={{ width: '100%' }} />
          )}
        </Grid>
      </form>
      <CreateShortcutDialog open={dialogOpen} handleClose={() => setDialogOpen(false)} />
    </div>
  );
}

export default SignIn;
