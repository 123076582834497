import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowRight, Folder } from '@material-ui/icons';
import React from 'react';

interface FolderAccordionProps {
  expandedIndex: number | undefined;
  index: number;
  name: string;
  children: React.ReactNode;
  handleChangeIndex: (num: number) => void;
  style?: React.CSSProperties;
  headerButton?: React.ReactNode;
}

export default function FolderAccordion(props: FolderAccordionProps) {
  const { index, name, children, expandedIndex, handleChangeIndex, style, headerButton } = props;
  return (
    <Accordion
      square
      expanded={expandedIndex === index}
      onChange={() => handleChangeIndex(index)}
      style={{ marginBottom: 16, ...style }}
    >
      <AccordionSummary
        style={{
          background: '#0947b9',
          color: 'white',
          display: 'flex',
        }}
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        <div style={{ paddingLeft: 0, paddingRight: 8, position: 'relative', bottom: 1 }}>
          <Folder style={{ padding: 0, margin: 0 }} />
        </div>
        <Typography style={{ flexGrow: 1 }}>{name}</Typography>
        {headerButton}
        {expandedIndex === index ? <ArrowDropDown /> : <ArrowRight />}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
