import { ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { SET_SECURITY, SET_SUBSCRIBER_SECURITY } from './constants';
import * as securityActions from './actions';
import { Security } from './security';

export type SecurityAction = ActionType<typeof securityActions>;
export type SecurityState = {
  security: Security;
  subscriberSecurity: Security;
};

export default combineReducers<SecurityState, SecurityAction>({
  security: (state = [], action) => {
    let newState: Security;
    switch (action.type) {
      case SET_SECURITY:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
  subscriberSecurity: (state = [], action) => {
    let newState: Security;
    switch (action.type) {
      case SET_SUBSCRIBER_SECURITY:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
});
