import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import userIconWhite2 from './images/user-icon2-white.png';
import downArrowIconWhite from './images/down-arrow-icon-white.png';
import wrenchIcon from './images/wrench-icon.webp';
import exitIcon from './images/exit-icon.webp';
import '../css/admin.css';
import { getUserState } from '../features/user/selectors';

function UserInfo() {
  const history = useHistory();
  const user = useSelector(getUserState);

  useEffect(() => {
    if (user.id) {
      if (false) {
        history.push('/main/login');
      }
    }
  }, [user]);

  return (
    <div className="user-info">
      <div className="user-info-top">
        <img alt="" src={userIconWhite2} className="small-user-icon" />
        <span className="user-info-username">{user.name}</span>
        <img alt="" src={downArrowIconWhite} className="down-arrow-icon" />
      </div>
      <div className="user-info-dropdown">
        <div className="user-info-dropdown-item">
          <div>
            <img alt="" src={wrenchIcon} style={{ marginRight: 12 }} className="small-user-icon" />
            <span>Settings</span>
          </div>
        </div>
        <div className="user-info-dropdown-break" />
        <div className="user-info-dropdown-item red-text">
          <div>
            <img alt="" src={exitIcon} className="small-user-icon" style={{ marginRight: 12 }} />
            <span>Log Out</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserInfo;
