import {
  Button,
  Card,
  CardContent,
  createStyles,
  FormControl,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { useEffect, useState } from 'react';
import NavAppbar from '../nav-top/NavAppbar';
import PageTitle from '../page-title/PageTitle';
import { documentTypeToUrl, generateUniqueId, openInNewWindow } from '../../scripts/utils';
import ConfidenceMatchesTable from './ConfidenceMatchesTable';
import {
  blueHighlight,
  DCCRow,
  LocalStorageKeyEnum,
  mergeRowsFromCache,
  PublishType,
  TableType,
  updateRowsInLocalStorage,
  yellowHighlight,
} from './DCCUtils';
import {
  getConformingCenterDocumentsByType,
  getConformingCenterSummaryByProjectId,
} from '../../models/api/dcc';
import {
  ConformingCenterConflictResolutionType,
  DocumentTemplateType,
  FileCategoryType,
  IConformingCenterProjectSummary,
  IConformingDocumentsSummary,
  IFile,
  PublishStatusType,
} from '../../api-client/autogenerated';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDisableProcoreIntegrationFeatures,
  getProjectState,
} from '../../features/project/selectors';
import FilePreviewDialog from '../dialogs/FilePreviewDialog';
import PublishedSectionsDialog from '../dialogs/PublishedSectionsDialog';
import DCCFileCard from './DCCFileCard';
import Add from '@material-ui/icons/Add';
import { SubmitButton, TooltipIfDisabledComponent } from '../custom-components/CustomButtons';
import {
  deleteDocumentsByIds,
  insertDocumentWithType,
  modifyDocumentById,
  ModifyDocumentRow,
  modifyDocumentsIndividually,
} from '../../models/api/documents';
import { getUserState } from '../../features/user/selectors';
import { uploadGeneralDocumentFile } from '../../models/api/filesystem';
import { addSnackbar } from '../../features/snackbar/actions';
import { useHistory } from 'react-router-dom';
import FullscreenLoader from '../loader/FullscreenLoader';
import DCCNavigationBlocker from '../custom-components/DCCNavigationBlocker';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import CreatePlaceholdersDialog from './CreatePlaceholdersDialog';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import { divisionToDisplayString } from '../document-display/DocumentDisplay';
import ManagePermissionsDialog, {
  ManagePermissionsDialogType,
} from '../design/ManagePermissionsDialog';
import { getParserJobStatusByObjectKey } from '../../models/api/files';
import { FileInfo } from './SpecificationJobTableRow';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
      overflowX: 'hidden',
    },
    titleStyle: {
      backgroundColor: '#1F34BC',
      borderRadius: '4px 4px 0px 0px',
    },
    cardContentStyle: {
      paddingTop: 10,
      paddingBottom: 10,
      display: 'flex',
      borderBottom: '1px solid #D8D8D8',
    },
    tableHeader: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      textAlign: 'center',
    },
    headerFont: {
      fontWeight: 500,
      fontSize: 20,
      marginRight: 20,
    },
    deletedItems: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    deletedItemsButtons: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
      },
    },
    legend: {
      height: 16,
      width: 16,
      backgroundColor: blueHighlight,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'black',
    },
    deleteButton: {
      padding: '0px 10px',
      backgroundColor: '#ED3F26',
      lineHeight: 1,
      marginRight: 12,
      color: 'white',
      '&:hover': {
        backgroundColor: '#FF5D45',
      },
    },
  }),
);

function getEmptyCustomRow(): DCCRow {
  return {
    id: generateUniqueId(),
    number: '',
    title: '',
    pdfFileId: '',
    isConflicting: false,
    conflictType: ConformingCenterConflictResolutionType.NoConflict,
    isGoodMatch: true,
    isChecked: false,
    associatedUserIds: [],
    associatedGroupIds: [],
  };
}

export default function DCCSpecifications() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);
  const disableProcoreIntegrationFeatures = useSelector(getDisableProcoreIntegrationFeatures);

  const buttonColumn = useMediaQuery('(max-width:1300px)');
  const innerButtonColumn = useMediaQuery('(max-width:720px)');

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<TableType | null>(null);

  const [projectSummary, setProjectSummary] = useState<IConformingCenterProjectSummary>();
  const [summary, setSummary] = useState<IConformingDocumentsSummary>();

  const [rows, setRows] = useState<DCCRow[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectedDivisions, setSelectedDivisions] = useState<string[]>([]);

  const [customRows, setCustomRows] = useState<DCCRow[]>([getEmptyCustomRow()]);

  const [deletedRows, setDeletedRows] = useState<DCCRow[]>([]);
  const [deletedSearchInput, setDeletedSearchInput] = useState('');

  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [fileToView, setFileToView] = useState<File>();

  const [updateExistingDialogOpen, setUpdateExistingDialogOpen] = useState(false);
  const [rowToUpdate, setRowToUpdate] = useState<DCCRow>();
  const [rowToAssociate, setRowToAssociate] = useState<DCCRow>();
  const [isAssociatingCustomRow, setIsAssociatingCustomRow] = useState(false);

  const [createSubmittalsDialogOpen, setCreateSubmittalsDialogOpen] = useState(false);
  const [associationDialogOpen, setAssociationDialogOpen] = useState(false);

  const [limitHeight, setLimitHeight] = useState(true);

  const [fileInfo, setFileInfo] = useState<FileInfo[]>([]);

  const checkedRows = rows.filter((r) => r.isChecked);
  const checkedCustomRows = customRows.filter((r) => r.isChecked);
  const checkedDeletedRows = deletedRows.filter((r) => r.isChecked);

  const hasPendingCustomRows =
    customRows.length > 0 && !!customRows.find((r) => !!r.title || !!r.number || !!r.localFile);

  useEffect(() => {
    updateRowsInLocalStorage(LocalStorageKeyEnum.SpecificationsUnpublished, rows);
  }, [rows]);

  const fetchData = async (projectId: string) => {
    setIsLoading(true);
    const s = await getConformingCenterSummaryByProjectId(projectId);
    setProjectSummary(s);
    setSummary(s.specificationSummary);
    await fetchDocuments(projectId);
    return s.specificationSummary;
  };

  const fetchDocuments = async (projectId: string) => {
    const documents = await getConformingCenterDocumentsByType(
      projectId,
      DocumentTemplateType.Specifications,
    );
    const unpublishedDocuments = documents.filter(
      (doc) => doc.document.publishStatus === PublishStatusType.Unpublished,
    );
    const newRows = unpublishedDocuments
      .filter(({ document }) => !deletedRows.map((r) => r.id).includes(document.id))
      .map<DCCRow>(({ document, isConflicting, isGoodMatch, conflictType }) => {
        const fileName = project?.files?.find((f) => f.id === document.parsedFromFileId)?.name;
        const submittalSectionFile = document.submittalSectionFile;
        const pageRange =
          submittalSectionFile?.pageStart && submittalSectionFile?.pageEnd
            ? [submittalSectionFile.pageStart, submittalSectionFile.pageEnd]
            : undefined;
        return {
          id: document.id,
          number: document.submittalSection || '',
          title: document.submittalSectionDescription || '',
          pdfFileId: document.submittalSectionFileId || '',
          pageRange,
          fileName,
          isConflicting,
          conflictType,
          isGoodMatch,
          isChecked: false,
          associatedUserIds: [],
          associatedGroupIds: [],
        };
      });
    const mergedRows = mergeRowsFromCache(LocalStorageKeyEnum.SpecificationsUnpublished, newRows);
    setRows(mergedRows);
    setIsLoading(false);
  };

  const getFileInfo = async () => {
    const validFiles = getCurrentFiles().filter((f) => !!f.url);
    const results = (
      await Promise.all(
        validFiles.map(async (f) => {
          const job = await getParserJobStatusByObjectKey(f.url!);
          if (job && job.output) {
            const { missingSections, tableOfContentsFileId } = job.output as {
              missingSections: { code: string; description: string }[];
              tableOfContentsFileId: string;
            };
            return { file: f, missingSections, tableOfContentsFileId };
          }
        }),
      )
    ).filter((x) => !!x) as FileInfo[];
    setFileInfo(results);
  };

  useEffect(() => {
    if (project) {
      fetchData(project.id);
    }
  }, [project]);

  useEffect(() => {
    if (project && projectSummary) {
      getFileInfo();
    }
  }, [project, projectSummary]);

  const handleView = async (row: DCCRow) => {
    if (row.pdfFileId) {
      openInNewWindow(row.pdfFileId);
    } else if (row.localFile) {
      setViewDialogOpen(true);
      setFileToView(row.localFile);
    }
  };

  const handleUpdateExisting = (row: DCCRow) => {
    setRowToUpdate(row);
    setUpdateExistingDialogOpen(true);
  };

  const handleSaveOrPublish = async (tableType: TableType, willPublishSelected: boolean) => {
    if (!project) return;
    if (tableType === TableType.Custom) {
      const rowsToPublish = [...checkedCustomRows];
      if (
        rowsToPublish.every(
          (r) => r.associatedUserIds.length === 0 && r.associatedGroupIds.length === 0,
        )
      ) {
        const proceed = window.confirm(
          'Are you sure you want to publish these documents without associating users?',
        );
        if (!proceed) return;
      }
      setIsSubmitting(tableType);
      dispatch(blockNavigation());
      await Promise.all(
        rowsToPublish.map(async (row) => {
          const newDocument = await insertDocumentWithType(
            {
              projectId: project.id,
              submittalSection: row.number,
              creatorUserId: user.id,
            },
            DocumentTemplateType.Specifications,
            {
              submittalSectionDescription: row.title,
              followerUserIds: row.associatedUserIds,
              userGroupIds: row.associatedGroupIds,
            },
          );
          const { file } = await uploadGeneralDocumentFile(
            newDocument.id,
            {
              fullFileName: row.localFile!.name,
              fileType: FileCategoryType.SpecificationSection,
              useMultiPartUpload: row.localFile!.size > MULTI_PART_FILE_SIZE,
            },
            row.localFile!,
          );
          if (file) {
            await modifyDocumentById(newDocument.id, { patch: { parsedFromFileId: file.id } });
          }
        }),
      );
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: `Successfully published ${rowsToPublish.length} documents`,
          severity: 'success',
        }),
      );
      const newCustomRows = customRows.filter((row) => !rowsToPublish.find((r) => r.id === row.id));
      setCustomRows(newCustomRows.length === 0 ? [getEmptyCustomRow()] : newCustomRows);
      await fetchData(project.id);
    } else {
      const rowsToSave = willPublishSelected ? [...checkedRows] : [...rows];
      if (
        rowsToSave.every(
          (r) => r.associatedUserIds.length === 0 && r.associatedGroupIds.length === 0,
        )
      ) {
        const proceed = window.confirm(
          'Are you sure you want to publish these documents without associating users?',
        );
        if (!proceed) return;
      }
      setIsSubmitting(tableType);
      dispatch(blockNavigation());
      const patches: ModifyDocumentRow[] = rowsToSave.map((row) => {
        const patch: ModifyDocumentRow = {
          documentId: row.id,
          modification: {
            patch: {
              isDraft: !willPublishSelected,
              submittalSection: row.number,
              submittalSectionDescription: row.title,
            },
            followers: {
              addUserIds: row.associatedUserIds,
              addUserGroupIds: row.associatedGroupIds,
            },
          },
        };
        return patch;
      });
      await modifyDocumentsIndividually(patches);
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: willPublishSelected
            ? `Successfully published ${patches.length} documents`
            : 'Saved changes!',
          severity: 'success',
        }),
      );
      dispatch(allowNavigation());
      if (!willPublishSelected) {
        history.push(`/main/projects/${project.id}/pub-center`);
      } else {
        const newSummary = await fetchData(project.id);
        if (newSummary.unpublished.numDocuments === 0 && !disableProcoreIntegrationFeatures)
          setCreateSubmittalsDialogOpen(true);
      }
    }
    dispatch(allowNavigation());
    setIsSubmitting(null);
  };

  const handleDelete = async (tableType: TableType, fromDialog = false) => {
    if (tableType === TableType.Deleted) {
      const documentIdsToDelete = (fromDialog ? [...deletedRows] : [...checkedDeletedRows]).map(
        (row) => row.id,
      );

      const proceed = window.confirm(
        `Are you sure you want to permanently delete ${documentIdsToDelete.length} specifications? You must re-parse to create these items again.`,
      );
      if (!proceed) return;
      dispatch(blockNavigation());
      setIsSubmitting(TableType.Deleted);
      await deleteDocumentsByIds(documentIdsToDelete);
      setDeletedRows((prev) => prev.filter((row) => !documentIdsToDelete.includes(row.id)));
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: `Successfully deleted ${documentIdsToDelete.length} items`,
          severity: 'success',
        }),
      );
      dispatch(allowNavigation());
      if (!fromDialog) {
        const newSummary = await fetchData(project!.id);
        if (newSummary.unpublished.numDocuments === 0 && !disableProcoreIntegrationFeatures)
          setCreateSubmittalsDialogOpen(true);
      }
      setIsSubmitting(null);
    } else if (tableType === TableType.Unpublished) {
      const rowsToDelete = [...checkedRows];
      setRows((prev) => prev.filter((p) => !rowsToDelete.map(({ id }) => id).includes(p.id)));
      setDeletedRows((prev) => [
        ...prev,
        ...rowsToDelete.map((row) => ({ ...row, isChecked: false })),
      ]);
    } else {
      const rowsToDelete = [...checkedCustomRows];
      setCustomRows((prev) => prev.filter((p) => !rowsToDelete.map(({ id }) => id).includes(p.id)));
    }
  };

  const handleClearAll = () => {
    setDeletedRows(rows.map((r) => ({ ...r, isChecked: false })));
    setRows([]);
  };

  const handleRestore = () => {
    const rowsToRestore = [...deletedRows].filter((row) => row.isChecked);
    setDeletedRows((prev) => prev.filter((p) => !rowsToRestore.map(({ id }) => id).includes(p.id)));
    setRows((prev) => [...prev, ...rowsToRestore]);
  };

  const handleAddNewSection = () => {
    setCustomRows((prev) => [...prev, getEmptyCustomRow()]);
  };

  const areSameSectionNumbersSelected = checkedRows.some((row) =>
    checkedRows.some((row2) => row.id !== row2.id && row.number === row2.number),
  );

  const getPublishTooltipTitle = () => {
    if (checkedRows.length === 0) return 'You must select at least one item';
    if (areSameSectionNumbersSelected)
      return 'You may not publish two items with the same section number';
    if (checkedRows.some((row) => !row.number || !row.title))
      return 'You may not publish an item without a section number and title';
    return '';
  };

  const isPublishDisabled = () => {
    return (
      checkedRows.length === 0 ||
      areSameSectionNumbersSelected ||
      checkedRows.some((row) => !row.number || !row.title)
    );
  };

  const getPublishCustomTooltipTitle = () => {
    if (checkedCustomRows.length === 0) return 'You must select at least one item';
    if (customRows.filter((r) => r.isChecked).some((r) => !r.localFile || !r.number || !r.title))
      return 'You may not publish an item without a section number, title, and file';
    return '';
  };

  const isPublishCustomDisabled = () => {
    return (
      checkedCustomRows.length === 0 ||
      customRows.filter((r) => r.isChecked).some((r) => !r.localFile || !r.number || !r.title)
    );
  };

  const getUniqueDivisions = () => {
    if (!summary) return [];
    return [
      ...new Set(summary.unpublished.uniqueSpecSections.map((section) => section.substring(0, 2))),
    ];
  };

  const getCurrentFiles = (): IFile[] => {
    return (
      project?.files?.filter(
        (f) =>
          f.category === FileCategoryType.Specifications &&
          projectSummary?.summaryByParsedFromFileId[f.id] &&
          projectSummary?.summaryByParsedFromFileId[f.id].specificationSummary.unpublished
            .numDocuments > 0,
      ) || []
    );
  };

  const handleSpecificationsLink = () => {
    history.push(
      `/main/projects/${project!.id}/documents/${
        documentTypeToUrl[DocumentTemplateType.Specifications]
      }`,
    );
  };

  const publishedCount = summary?.published.numDocuments || 0;
  const unpublishedCount = summary?.unpublished.numDocuments || 0;
  const totalCount = publishedCount + unpublishedCount;

  const getPageTitle = () => {
    if (publishedCount > 0) return 'Specifications Conforming Center';
    return 'Specifications Publishing Center';
  };

  const handleOpenAssociationsDialog = (isCustom: boolean) => {
    const candidateRows = isCustom ? checkedCustomRows : checkedRows;
    if (candidateRows.length === 1) {
      setRowToAssociate(candidateRows[0]);
    } else {
      setRowToAssociate(undefined);
    }

    setIsAssociatingCustomRow(isCustom);

    setAssociationDialogOpen(true);
  };

  const handleCloseAssociationsDialog = () => {
    setRowToAssociate(undefined);
    setAssociationDialogOpen(false);
  };

  const handleSetAssociatedUsers = (userIds: string[]) => {
    if (!isAssociatingCustomRow) {
      setRows((prev) =>
        prev.map((r) => {
          if (checkedRows.some((row) => row.id === r.id)) {
            return { ...r, associatedUserIds: userIds };
          }

          return r;
        }),
      );
    } else {
      setCustomRows((prev) =>
        prev.map((r) => {
          if (checkedCustomRows.some((row) => row.id === r.id)) {
            return { ...r, associatedUserIds: userIds };
          }

          return r;
        }),
      );
    }
  };

  const handleSetAssociatedGroups = (groupIds: string[]) => {
    if (!isAssociatingCustomRow) {
      setRows((prev) =>
        prev.map((r) => {
          if (checkedRows.some((row) => row.id === r.id)) {
            return { ...r, associatedGroupIds: groupIds };
          }

          return r;
        }),
      );
    } else {
      setCustomRows((prev) =>
        prev.map((r) => {
          if (checkedCustomRows.some((row) => row.id === r.id)) {
            return { ...r, associatedGroupIds: groupIds };
          }

          return r;
        }),
      );
    }
  };

  return (
    <main className={classes.root}>
      <NavAppbar />
      <PageTitle title={getPageTitle()} />
      {!isLoading ? (
        <>
          <DCCFileCard type={PublishType.Specifications} fileInfo={fileInfo} />
          <Card style={{ marginTop: 15 }}>
            <CardContent className={classes.cardContentStyle}>
              <Typography style={{ fontWeight: 500, fontSize: 20, lineHeight: '25px' }}>
                Published Specifications - {publishedCount}/{totalCount}
              </Typography>
            </CardContent>
            <Typography style={{ fontSize: 16, lineHeight: '21px', marginLeft: 16, marginTop: 8 }}>
              After publishing, be sure to associate design team users with the specifications for
              which they are responsible.
            </Typography>
            <Typography style={{ fontSize: 16, lineHeight: '21px', marginLeft: 16 }}>
              This can be done at the Specifications page.
            </Typography>
            <Button
              color="primary"
              size="large"
              onClick={handleSpecificationsLink}
              endIcon={<ArrowForward />}
              style={{ marginLeft: 6 }}
            >
              Go to Specifications
            </Button>
          </Card>

          <Typography style={{ fontWeight: 500, fontSize: 18, color: '#797979', marginTop: 25 }}>
            Centerline has identified and created the following specification sections from your
            uploaded files. Please check this list carefully against the table of contents for
            accuracy.
          </Typography>
          <Typography style={{ fontWeight: 500, fontSize: 18, color: '#797979', marginTop: 6 }}>
            (If any sections are missing you may manually add them below)
          </Typography>
          <Card style={{ marginTop: 15 }}>
            <CardContent
              className={classes.cardContentStyle}
              style={{ flexDirection: buttonColumn ? 'column' : 'row' }}
            >
              <div
                className={classes.tableHeader}
                style={{ flexDirection: buttonColumn ? 'column' : 'row' }}
              >
                <Typography className={classes.headerFont}>
                  Unpublished Items - {rows.length}
                </Typography>
                <div
                  style={{
                    marginTop: buttonColumn ? 10 : 0,
                    flexDirection: innerButtonColumn ? 'column' : 'row',
                    display: 'flex',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={checkedRows.length === 0}
                    onClick={() => handleOpenAssociationsDialog(false)}
                    style={{
                      padding: '0px 10px',
                      lineHeight: 1,
                      marginRight: 15,
                    }}
                  >
                    Associate Users
                  </Button>
                  <TooltipIfDisabledComponent
                    arrow
                    placement="top"
                    title={getPublishTooltipTitle()}
                    disabled={isPublishDisabled()}
                    spanStyle={{ width: undefined }}
                  >
                    <SubmitButton
                      color="primary"
                      variant="contained"
                      disabled={isPublishDisabled()}
                      onClick={() => handleSaveOrPublish(TableType.Unpublished, true)}
                      style={{
                        padding: '0px 10px',
                        lineHeight: 1,
                        marginRight: 12,
                      }}
                    >
                      Publish Selected to Centerline
                    </SubmitButton>
                  </TooltipIfDisabledComponent>
                  <Button
                    variant="contained"
                    disabled={checkedRows.length === 0}
                    onClick={() => handleDelete(TableType.Unpublished)}
                    className={classes.deleteButton}
                  >
                    Move to Delete Queue
                  </Button>
                  <Button
                    variant="contained"
                    disabled={rows.length === 0}
                    onClick={handleClearAll}
                    className={classes.deleteButton}
                  >
                    Clear All
                  </Button>
                </div>
                <div style={{ display: 'inline-flex', flexGrow: 100 }} />
                <div className={classes.legend} style={{ backgroundColor: yellowHighlight }} />
                <Typography style={{ marginLeft: 6, marginRight: 16 }}>
                  Requires manual match
                </Typography>
                {publishedCount > 0 && (
                  <>
                    <div className={classes.legend} />
                    <Typography style={{ marginLeft: 6, marginRight: 16 }}>
                      Updates existing version
                    </Typography>
                  </>
                )}
                <Select
                  variant="outlined"
                  multiple
                  displayEmpty
                  value={selectedDivisions}
                  renderValue={(v) =>
                    (v as string[]).length === 0 ? 'All Divisions' : (v as string[]).join(', ')
                  }
                  onChange={(e) => setSelectedDivisions(e.target.value as string[])}
                  style={{ height: 25, width: 150, marginRight: 8, textAlign: 'left' }}
                >
                  {getUniqueDivisions()
                    .sort()
                    .map((division) => (
                      <MenuItem
                        key={division}
                        value={division}
                        style={{ paddingTop: 4, paddingBottom: 4 }}
                      >
                        <GreenCheck
                          checked={selectedDivisions.includes(division)}
                          style={{ padding: 0, marginLeft: -2, marginRight: 4 }}
                        />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>{division} —</span>
                          <span style={{ whiteSpace: 'pre-wrap', maxWidth: 200, marginLeft: 6 }}>
                            {divisionToDisplayString[division]}
                          </span>
                        </div>
                      </MenuItem>
                    ))}
                </Select>
              </div>

              <FormControl style={{ margin: '15px 0px' }}>
                <TextField
                  style={{ alignItems: 'center' }}
                  InputProps={{
                    style: { height: 25, maxWidth: buttonColumn ? 500 : 'none' },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search style={{ color: '#B2B1B2' }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                  required
                  id="search"
                  placeholder="Search"
                  name="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </FormControl>
            </CardContent>
            <CardContent style={{ padding: 0, overflowX: 'auto' }}>
              <ConfidenceMatchesTable
                type={PublishType.Specifications}
                tableType={TableType.Unpublished}
                summary={summary}
                rows={rows.filter(
                  (row) =>
                    selectedDivisions.length === 0 ||
                    selectedDivisions.includes(row.number.substring(0, 2)),
                )}
                setRows={setRows}
                view={handleView}
                limitHeight={limitHeight}
                updateExisting={publishedCount > 0 ? handleUpdateExisting : undefined}
                searchResult={searchInput}
                isLoading={isSubmitting === TableType.Unpublished}
                missingSections={fileInfo.map((f) => f.missingSections || []).flat()}
              />
            </CardContent>
          </Card>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 15,
              marginBottom: 25,
            }}
          >
            {rows.length > 9 && (
              <Button color="primary" onClick={() => setLimitHeight((prev) => !prev)}>
                See All <ArrowForward />
              </Button>
            )}
          </div>

          <Typography
            style={{
              fontWeight: 500,
              fontSize: 18,
              lineHeight: '21px',
              color: '#797979',
              marginTop: 25,
            }}
          >
            If your project manual contains custom specification sections whose numbers do not
            conform to current CSI standards, use the tool below to upload these specification
            sections manually and provide a number and title for them.
          </Typography>
          <Card style={{ marginTop: 15 }}>
            <CardContent
              className={classes.cardContentStyle}
              style={{ flexDirection: buttonColumn ? 'column' : 'row' }}
            >
              <div
                className={classes.tableHeader}
                style={{ flexDirection: buttonColumn ? 'column' : 'row' }}
              >
                <Typography className={classes.headerFont}>
                  Custom Items - {customRows.filter((r) => r.localFile).length}
                </Typography>
                <div
                  style={{
                    marginTop: buttonColumn ? 10 : 0,
                    flexDirection: innerButtonColumn ? 'column' : 'row',
                    display: 'flex',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={checkedCustomRows.length === 0}
                    onClick={() => handleOpenAssociationsDialog(true)}
                    style={{
                      padding: '0px 10px',
                      lineHeight: 1,
                      marginRight: 15,
                    }}
                  >
                    Associate Users
                  </Button>
                  <TooltipIfDisabledComponent
                    arrow
                    placement="top"
                    title={getPublishCustomTooltipTitle()}
                    disabled={isPublishCustomDisabled()}
                  >
                    <SubmitButton
                      color="primary"
                      variant="contained"
                      disabled={isPublishCustomDisabled()}
                      onClick={() => handleSaveOrPublish(TableType.Custom, true)}
                      style={{
                        padding: '0px 10px',
                        lineHeight: 1,
                        marginRight: 15,
                      }}
                    >
                      Publish Selected to Centerline
                    </SubmitButton>
                  </TooltipIfDisabledComponent>
                  <Button
                    variant="contained"
                    disabled={checkedCustomRows.length === 0}
                    onClick={() => handleDelete(TableType.Custom)}
                    className={classes.deleteButton}
                  >
                    Delete Selected
                  </Button>
                </div>
              </div>
            </CardContent>
            <CardContent style={{ padding: 0, overflowX: 'auto' }}>
              <ConfidenceMatchesTable
                type={PublishType.Specifications}
                tableType={TableType.Custom}
                summary={summary}
                rows={customRows}
                setRows={setCustomRows}
                view={handleView}
                limitHeight={false}
                searchResult=""
                isLoading={isSubmitting === TableType.Custom}
              />
            </CardContent>
          </Card>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: 15,
              marginBottom: 25,
            }}
          >
            <Button color="primary" onClick={handleAddNewSection} startIcon={<Add />}>
              Add another section
            </Button>
          </div>

          <Typography style={{ fontWeight: 500, fontSize: 18, color: '#797979', marginTop: 25 }}>
            Restore or permanently delete items here.
          </Typography>
          <Card style={{ marginTop: 15 }}>
            <CardContent
              className={classes.cardContentStyle}
              style={{ flexDirection: buttonColumn ? 'column' : 'row' }}
            >
              <div
                className={classes.tableHeader}
                style={{ flexDirection: buttonColumn ? 'column' : 'row' }}
              >
                <Typography className={classes.headerFont}>
                  Deleted Items - {deletedRows.length}
                </Typography>
                <div
                  style={{
                    marginTop: buttonColumn ? 10 : 0,
                    flexDirection: 'row',
                    display: 'flex',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={checkedDeletedRows.length === 0}
                    onClick={handleRestore}
                    style={{
                      padding: '0px 10px',
                      lineHeight: 1,
                      marginRight: 15,
                    }}
                  >
                    Restore Selected
                  </Button>
                  <Button
                    variant="contained"
                    disabled={checkedDeletedRows.length === 0}
                    onClick={() => handleDelete(TableType.Deleted)}
                    className={classes.deleteButton}
                  >
                    Delete Selected
                  </Button>
                </div>
              </div>
              <FormControl style={{ margin: '15px 0px' }}>
                <TextField
                  style={{ alignItems: 'center' }}
                  InputProps={{
                    style: { height: 25, maxWidth: buttonColumn ? 500 : 'none' },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search style={{ color: '#B2B1B2' }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                  required
                  id="search"
                  placeholder="Search"
                  name="Search"
                  value={deletedSearchInput}
                  onChange={(e) => setDeletedSearchInput(e.target.value)}
                />
              </FormControl>
            </CardContent>
            <CardContent style={{ padding: 0, overflowX: 'auto' }}>
              <ConfidenceMatchesTable
                type={PublishType.Specifications}
                tableType={TableType.Deleted}
                summary={summary}
                rows={deletedRows}
                setRows={setDeletedRows}
                view={handleView}
                limitHeight
                searchResult={deletedSearchInput}
                isLoading={isSubmitting === TableType.Deleted}
              />
            </CardContent>
          </Card>
        </>
      ) : (
        <FullscreenLoader />
      )}

      <FilePreviewDialog
        open={viewDialogOpen}
        handleClose={() => {
          setViewDialogOpen(false);
          setTimeout(() => setFileToView(undefined), 100);
        }}
        file={fileToView}
      />
      {rowToUpdate && (
        <PublishedSectionsDialog
          type={PublishType.Specifications}
          open={updateExistingDialogOpen}
          handleClose={() => setUpdateExistingDialogOpen(false)}
          row={rowToUpdate}
          refreshData={() => fetchData(project!.id)}
        />
      )}
      <DCCNavigationBlocker
        shouldBlock={deletedRows.length > 0 || hasPendingCustomRows}
        deletedItemsCount={deletedRows.length}
        isCustom={hasPendingCustomRows}
        onDelete={() => handleDelete(TableType.Deleted, true)}
      />
      {projectSummary && !disableProcoreIntegrationFeatures ? (
        <CreatePlaceholdersDialog
          open={createSubmittalsDialogOpen}
          handleClose={() => setCreateSubmittalsDialogOpen(false)}
          summary={projectSummary}
          type={DocumentTemplateType.Submittals}
        />
      ) : null}
      <ManagePermissionsDialog
        dialogOpen={associationDialogOpen}
        closeDialog={handleCloseAssociationsDialog}
        type={ManagePermissionsDialogType.AssociatedUsers}
        associatedUsers={rowToAssociate?.associatedUserIds || []}
        associatedGroups={rowToAssociate?.associatedGroupIds || []}
        setAssociatedUsers={handleSetAssociatedUsers}
        setAssociatedGroups={handleSetAssociatedGroups}
      />
    </main>
  );
}
