import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Card, createStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useSelector } from 'react-redux';
import SplashImage from '../assets/images/main_login_background.png';
import CenterlineIcon from '../images/CenterlineLogo.png';
import './sign-in/PortalLogin.scss';
import { getUserState } from '../features/user/selectors';
import SignIn from './sign-in/sign-in';
import ForgotPassword from './sign-in/forgot-password';
import { getPublicPageRedirect, setPublicPageRedirect } from '../scripts/utils';
import useMediaQueries from './custom-components/useMediaQueries';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    background: `url(${SplashImage})`,
    backgroundSize: 'cover',
  },
  card: {
    display: 'flex',
    background: 'white',
    opacity: 1,
    width: '375px',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '0px',
    left: '0px',
  },

  lineCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  login: {
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  avatar: {
    margin: 8,
  },
  bidModuleLoginButton: {
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#0947B9',
    '&:hover': {
      color: '#4380ED',
    },
    textTransform: 'none',
  },
  textContainer: {
    position: 'absolute',
    left: 450,
    top: 210,
  },
  splashText: {
    color: 'white',
    fontSize: 64,
    lineHeight: '64px',
    fontWeight: 400,
    marginTop: 48,
    whiteSpace: 'nowrap',
  },
  splashTextSmall: {
    color: 'white',
    fontSize: 26,
    lineHeight: '26px',
    whiteSpace: 'nowrap',
  },
});

function MainPortalLogin() {
  const classes = useStyles();

  const history = useHistory();
  const user = useSelector(getUserState);

  const { isXs } = useMediaQueries();

  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>();

  useEffect(() => {
    if (user.id) {
      const redirect = getPublicPageRedirect();
      if (redirect) {
        setPublicPageRedirect(null);
        history.push(redirect);
      } else history.push(!!user.companyId ? '/main' : '/bidding');
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.paper}>
          <div className={classes.login}>
            <img
              src={CenterlineIcon}
              alt="centerlinelogo"
              style={{ width: '350px', paddingBottom: '20px' }}
            />
            {!forgotPassword ? (
              <SignIn
                isBidding={false}
                setForgotPassword={setForgotPassword}
                setEmail={setForgotPasswordEmail}
              />
            ) : (
              <ForgotPassword
                setForgotPassword={setForgotPassword}
                existingEmail={forgotPasswordEmail}
              />
            )}
          </div>
        </div>
        <Button
          startIcon={<ArrowBack />}
          className={classes.bidModuleLoginButton}
          onClick={() => history.push('/bidding/login')}
        >
          CENTERLINE BidConnect LOGIN
        </Button>
      </Card>

      {!isXs && (
        <div className={classes.textContainer}>
          <Typography
            className={classes.splashText}
            style={{
              fontFamily: 'Uniform Rounded Medium',
              fontWeight: 500,
              marginTop: 0,
              letterSpacing: 12,
            }}
          >
            CENTERLINE.CO
          </Typography>
          <Typography
            className={classes.splashText}
            style={{
              fontSize: 52,
              lineHeight: '52px',
              fontWeight: 500,
              letterSpacing: -3,
            }}
          >
            Take control of your projects. From anywhere.
          </Typography>
          <Typography className={classes.splashTextSmall} style={{ marginTop: 32 }}>
            Centerline is a cloud-hosted platform for architects and
          </Typography>
          <Typography className={classes.splashTextSmall}>
            construction professionals to manage document-based
          </Typography>
          <Typography className={classes.splashTextSmall}>
            communication from design phase through closeout.
          </Typography>
          <Typography className={classes.splashTextSmall} style={{ marginTop: 24 }}>
            Simple. Powerful. Scalable.
          </Typography>
          <Typography
            className={classes.splashText}
            style={{ marginTop: 32, fontSize: 35, lineHeight: '35px' }}
          >
            contact us to get started: info@centerline.co
          </Typography>
        </div>
      )}
    </div>
  );
}

export default MainPortalLogin;
