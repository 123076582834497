import React, { useEffect, useState } from 'react';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import {
  Card,
  CardContent,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { HighlightOffRounded } from '@material-ui/icons';
import Dropzone from 'react-dropzone';
import { openInNewTab, parseDate, toggleState } from '../../scripts/utils';
import { insertDocument } from '../../models/api/documents';
import { getUserState } from '../../features/user/selectors';
import { getDocumentsByProjectIdAndType } from '../../models/api/project';
import { uploadGeneralDocumentFile } from '../../models/api/filesystem';
import CircularLoader from '../../main-components/loader/CircularLoader';
import { getTemplateId } from '../../models/api/templates';
import {
  DocumentTemplateType,
  FileCategoryType,
  IBidSetup,
  IInsertionComment,
  INumberedDocumentView,
  ManufacturersWarrantyType,
} from '../../api-client/autogenerated';
import PDFIcon from '../../main-components/icons/PDF-icon';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import BiddingTable from '../initial-bid-documents/BiddingTable';
import getSubstitutionRequestRows from './getSubstitutionRequestRows';
import { gray300, gray400, gray50, gray700 } from '../BiddingPortalTheme';
import dayjs, { Dayjs } from 'dayjs';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';
import { getNavigationState } from '../../features/navigation/selectors';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';

type Props = {
  bidSetup?: IBidSetup;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    content: {
      // flexGrow: 1,
      // padding: theme.spacing(3),
      // minHeight: '100vh',
      display: 'flex',
      padding: 16,
      justifyContent: 'flex-end',
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      height: '100%',
      background: '#fff',
    },
    titleStyle: {
      display: 'flex',
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
      fontSize: 26,
    },
    textfield: {
      width: '320px',
      height: '260px',
    },
    actions: {
      padding: '8px 29px 28px',
    },
    dragDropText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '22px',
      lineHeight: '40px',
      textAlign: 'center',
      textTransform: 'none',
      color: '#949494', // Gray 400
    },
    browseFileButton: {
      border: '2px solid #0947B9',
      boxSizing: 'border-box',
      borderRadius: '4px',
      color: '#0947B9',
    },
    dropzoneStyling: {
      width: '100%',
      flexShrink: 0,
      background: '#F9F9F9',
      mixBlendMode: 'normal',
      border: '2px dashed #949494',
      boxSizing: 'border-box',
      borderRadius: '4px',
      paddingBottom: 48,
    },
    file: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '16px',
    },
    fileOuter: {
      display: 'inline-flex',
      marginTop: '16px',
      marginRight: '20px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    fileText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '13px',
      textAlign: 'center',
      textTransform: 'none',
      color: '#949494', // Gray 400
      paddingLeft: 8,
    },
    columnLayout: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    rowLayout: {
      display: 'flex',
      flexWrap: 'nowrap',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '-16px',
    },
    rootIconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '30px',
      textAlign: 'left',
      color: '#0947B9',
      marginBottom: '8px',
    },
    filledInputRoot: {
      height: '34px',
      width: '216px',
      border: 'none',
      '& .Mui-disabled': {
        background: gray400,
      },
    },
    filledInputInput: {
      background: gray50,
      padding: '8px 0px 8px 8px',
      border: `1px solid ${gray400}`,
      borderRadius: '5px',
      textAlign: 'start',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '15px',
      lineHeight: '18px',
      color: gray700,
      '&::placeholder': {
        fontStyle: 'italic',
        color: gray300,
        opacity: 1,
      },
    },
    filledInputMultiline: {
      height: 'auto',
      width: 'auto',
      padding: 0,
    },
    textFieldLabel: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '12px',
      lineHeight: '32px',
      color: gray400,
      textTransform: 'uppercase',
      marginBottom: '16px',
    },
  }),
);

const numericRegex = /[0-9]/g;
const sectionRegex = /\d{6}(?:\.\d{2})?/;

const SubstitutionRequests: React.FC<Props> = (props) => {
  const { bidSetup } = props;
  const classes = useStyles();

  const [substitutionRequests, setSubstitutionRequests] = useState<INumberedDocumentView[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [addApprovalVisible, setAddApprovalVisible] = useState(false);
  const toggleVisible = () => toggleState(addApprovalVisible, setAddApprovalVisible);
  const [snackOpen, setSnackOpen] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const shouldBlockNavigation = useSelector(getNavigationState);

  const [fileWasRejected, setFileWasRejected] = useState(false);
  const [inputFile, setInputFile] = useState<File | null>();
  const [submittalSection, setSubmittalSection] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [paragraphNumber, setParagraphNumber] = useState(0);
  const [description, setDescription] = useState('');
  const [proposedSubstitution, setProposedSubstitution] = useState('');
  const [substitutionReason, setSubstitutionReason] = useState('');
  const [waysAffectingDrawings, setWaysAffectingDrawings] = useState('');
  const [affectsOtherTrades, setAffectsOtherTrades] = useState('');
  const [agencyApproval, setAgencyApproval] = useState('');
  const [differences, setDifferences] = useState('');
  const [
    manufacturersWarranties,
    setManufacturersWarranties,
  ] = useState<ManufacturersWarrantyType>();
  const [explanation, setExplanation] = useState('');
  const [availabilityInfo, setAvailabilityInfo] = useState('');
  const [inputComment, setInputComment] = useState('');
  const [submissionDueDate, setSubmissionDueDate] = useState<Dayjs>();

  const comments: IInsertionComment[] = [{ text: inputComment, creatorUserId: user.id }].filter(
    (c) => c.text,
  );

  const [submittalSectionError, setSubmittalSectionError] = useState(false);

  const handleRemove = () => {
    setInputFile(null);
  };

  const handleSubmittalSectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setSubmittalSection(input);
    setSubmittalSectionError(input.length > 0 && !sectionRegex.test(input));
  };

  const handlePageNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    if (input === '' || numericRegex.test(input)) setPageNumber(parseInt(input, 10));
  };

  const handleParagraphNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    if (input === '' || numericRegex.test(input)) setParagraphNumber(parseInt(input, 10));
  };

  const fetchDocuments = async () => {
    setIsLoading(true);
    await getDocumentsByProjectIdAndType(
      bidSetup!.project!.id,
      DocumentTemplateType.SubstitutionRequests,
      1000,
      user.company?.name || user.publicCompanyName
        ? [
            {
              whereColumn: 'contactCompany',
              whereOperator: '=',
              whereValue: user.company?.name || user.publicCompanyName!,
            },
          ]
        : [],
    )
      .then((result) => setSubstitutionRequests(result.filter((x) => !x.isHidden)))
      .catch(() => setSubstitutionRequests([]))
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = async () => {
    if (!bidSetup?.project?.id) return;
    try {
      dispatch(blockNavigation());
      setIsLoading(true);

      const document = await insertDocument({
        creatorUserId: user.id,
        projectId: bidSetup.project.id,
        documentTemplateId: await getTemplateId(DocumentTemplateType.SubstitutionRequests),
        contactName: user.name,
        contactCompany: user.company?.name || user.publicCompanyName,
        submittalSection,
        pageNumber,
        paragraphNumber,
        description,
        proposedSubstitution,
        substitutionReason,
        substitutionAffectsDrawing: waysAffectingDrawings,
        substitutionAffectsTrades: affectsOtherTrades,
        agency: agencyApproval,
        substitutionDifferences: differences,
        manufacturersWarranties,
        manufacturersWarrantiesExplanation: explanation,
        informationAvailable: availabilityInfo,
        comments,
      });

      if (inputFile) {
        await uploadGeneralDocumentFile(
          document.id,
          {
            fullFileName: inputFile.name,
            fileType: FileCategoryType.DocumentAttachments,
            useMultiPartUpload: inputFile.size > MULTI_PART_FILE_SIZE,
          },
          inputFile,
        );
      }

      await fetchDocuments();
      toggleVisible();
      setSnackOpen(true);
    } finally {
      setIsLoading(false);
      dispatch(allowNavigation());
    }
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    if (bidSetup?.project?.id) {
      if (bidSetup.requestDueDate) {
        setSubmissionDueDate(parseDate(bidSetup.requestDueDate));
      }
      fetchDocuments();
    }
  }, [bidSetup]);

  return (
    <>
      <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent className={classes.titleStyle}>
          <h2
            className="h2"
            style={{
              textAlign: 'left',
              color: '#FFFFFF',
              margin: 0,
              lineHeight: 1,
              fontSize: 26,
            }}
          >
            Substitution Requests
          </h2>
          {submissionDueDate && (
            <h2
              className="h2"
              style={{
                textAlign: 'left',
                color: '#FFFFFF',
                margin: 0,
                lineHeight: 1,
                fontSize: 26,
                whiteSpace: 'pre',
              }}
            >
              {' '}
              — Submit by: {submissionDueDate.format('MM/DD/YYYY')}
            </h2>
          )}
        </CardContent>
        <CardContent
          style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 450 }}
        >
          {submissionDueDate && dayjs().isBefore(submissionDueDate) ? (
            <div>
              <Button
                type="button"
                disabled={
                  bidSetup?.allowElectronicSubstitutionRequestSubmittals === null
                    ? false
                    : !bidSetup?.allowElectronicSubstitutionRequestSubmittals
                }
                tabIndex={0}
                color="primary"
                variant="contained"
                onKeyDown={toggleVisible}
                onClick={toggleVisible}
                startIcon={<Add />}
                style={{
                  marginBottom: 24,
                  marginTop: 12,
                  width: 'auto',
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
              >
                Add substitution request
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 32,
                paddingBottom: 32,
                color: 'rgb(130,130,130)',
              }}
            >
              <div>Substitution Request submission deadline has passed.</div>
            </div>
          )}
          {(user.company?.name || user.publicCompanyName) && (
            <span style={{ textAlign: 'center' }}>
              This only shows Substitution Requests submitted by{' '}
              {user.company?.name || user.publicCompanyName}
            </span>
          )}
          {isLoading ? (
            <div style={{ position: 'relative', top: '25%' }}>
              <CircularLoader />
            </div>
          ) : (
            <BiddingTable
              headers={[
                'Substitution #',
                'Date Submitted',
                'Author',
                'Company',
                'Description of Substitution',
                'Status',
                'View',
                'Response to be Published By Addendum?',
              ]}
              rows={getSubstitutionRequestRows(substitutionRequests, (id) => {
                if (id) openInNewTab(id, true);
              })}
              rowsPerPage={5}
            />
          )}
          <Dialog
            open={addApprovalVisible}
            onClose={() => {
              if (!shouldBlockNavigation) setAddApprovalVisible(false);
            }}
          >
            <DialogTitle>
              <div className={classes.titleContainer}>
                <span className={classes.title}>Add Substitution Request</span>
              </div>
              <IconButton
                style={{ right: '12px', top: '12px', position: 'absolute' }}
                onClick={() => {
                  if (!shouldBlockNavigation) setAddApprovalVisible(false);
                }}
                classes={{
                  root: classes.rootIconButton,
                }}
              >
                <HighlightOffRounded />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <form>
                <div className="modal-body">
                  {isLoading ? (
                    <CircularLoader />
                  ) : (
                    <>
                      <div className="form-group row required">
                        <label htmlFor="last_name" className={classes.textFieldLabel}>
                          Spec Section
                        </label>
                        <div className="col-8">
                          <TextField
                            variant="filled"
                            InputProps={{
                              style: { width: '100%' },
                              classes: {
                                root: classes.filledInputRoot,
                                multiline: classes.filledInputMultiline,
                                input: classes.filledInputInput,
                              },
                              disableUnderline: true,
                            }}
                            required
                            error={submittalSectionError}
                            value={submittalSection}
                            onChange={handleSubmittalSectionChange}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>
                      <div className="form-group row required">
                        <label htmlFor="last_name" className={classes.textFieldLabel}>
                          Page Number
                        </label>
                        <div className="col-8">
                          <TextField
                            variant="filled"
                            InputProps={{
                              style: { width: '100%' },
                              classes: {
                                root: classes.filledInputRoot,
                                multiline: classes.filledInputMultiline,
                                input: classes.filledInputInput,
                              },
                              disableUnderline: true,
                            }}
                            required
                            type="number"
                            value={pageNumber}
                            onChange={handlePageNumberChange}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>
                      <div className="form-group row required">
                        <label htmlFor="last_name" className={classes.textFieldLabel}>
                          Paragraph Number
                        </label>
                        <div className="col-8">
                          <TextField
                            variant="filled"
                            InputProps={{
                              style: { width: '100%' },
                              classes: {
                                root: classes.filledInputRoot,
                                multiline: classes.filledInputMultiline,
                                input: classes.filledInputInput,
                              },
                              disableUnderline: true,
                            }}
                            required
                            type="number"
                            value={paragraphNumber}
                            onChange={handleParagraphNumberChange}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>
                      <div className="form-group row required">
                        <label htmlFor="last_name" className={classes.textFieldLabel}>
                          Description of substitution
                        </label>
                        <div className="col-8">
                          <TextField
                            variant="filled"
                            InputProps={{
                              style: { width: '100%' },
                              classes: {
                                root: classes.filledInputRoot,
                                multiline: classes.filledInputMultiline,
                                input: classes.filledInputInput,
                              },
                              disableUnderline: true,
                            }}
                            required
                            multiline
                            rows={3}
                            value={description}
                            onChange={(event) => setDescription(event.target.value)}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>
                      <div className="form-group row required">
                        <label htmlFor="last_name" className={classes.textFieldLabel}>
                          Proposed Substitution
                        </label>
                        <div className="col-8">
                          <TextField
                            variant="filled"
                            InputProps={{
                              style: { width: '100%' },
                              classes: {
                                root: classes.filledInputRoot,
                                multiline: classes.filledInputMultiline,
                                input: classes.filledInputInput,
                              },
                              disableUnderline: true,
                            }}
                            multiline
                            required
                            rows={3}
                            value={proposedSubstitution}
                            onChange={(event) => setProposedSubstitution(event.target.value)}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>
                      <div className="form-group row required">
                        <label htmlFor="last_name" className={classes.textFieldLabel}>
                          Reason for Substitution
                        </label>
                        <div className="col-8">
                          <TextField
                            variant="filled"
                            InputProps={{
                              style: { width: '100%' },
                              classes: {
                                root: classes.filledInputRoot,
                                multiline: classes.filledInputMultiline,
                                input: classes.filledInputInput,
                              },
                              disableUnderline: true,
                            }}
                            multiline
                            rows={3}
                            required
                            value={substitutionReason}
                            onChange={(event) => setSubstitutionReason(event.target.value)}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>
                      <div className="form-group row required">
                        <label
                          htmlFor="last_name"
                          className={classes.textFieldLabel}
                          style={{ lineHeight: '15px' }}
                        >
                          List ways in which the proposed substitution affects dimensions shown on
                          drawings
                        </label>
                        <div className="col-8">
                          <TextField
                            variant="filled"
                            InputProps={{
                              style: { width: '100%' },
                              classes: {
                                root: classes.filledInputRoot,
                                multiline: classes.filledInputMultiline,
                                input: classes.filledInputInput,
                              },
                              disableUnderline: true,
                            }}
                            required
                            multiline
                            rows={7}
                            value={waysAffectingDrawings}
                            onChange={(event) => setWaysAffectingDrawings(event.target.value)}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>
                      <div className="form-group row required">
                        <label htmlFor="last_name" className={classes.textFieldLabel}>
                          List effects of proposed substitution on other trades
                        </label>
                        <div className="col-8">
                          <TextField
                            variant="filled"
                            InputProps={{
                              style: { width: '100%' },
                              classes: {
                                root: classes.filledInputRoot,
                                multiline: classes.filledInputMultiline,
                                input: classes.filledInputInput,
                              },
                              disableUnderline: true,
                            }}
                            required
                            multiline
                            rows={5}
                            value={affectsOtherTrades}
                            onChange={(event) => setAffectsOtherTrades(event.target.value)}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>
                      <div className="form-group row required">
                        <label
                          htmlFor="last_name"
                          className={classes.textFieldLabel}
                          style={{ lineHeight: '15px' }}
                        >
                          List ways in which substitution would be affected by applicable code
                          requirements and agency approval
                        </label>
                        <div className="col-8">
                          <TextField
                            variant="filled"
                            InputProps={{
                              style: { width: '100%' },
                              classes: {
                                root: classes.filledInputRoot,
                                multiline: classes.filledInputMultiline,
                                input: classes.filledInputInput,
                              },
                              disableUnderline: true,
                            }}
                            required
                            multiline
                            rows={8}
                            value={agencyApproval}
                            onChange={(event) => setAgencyApproval(event.target.value)}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>
                      <div className="form-group row required">
                        <label
                          htmlFor="last_name"
                          className={classes.textFieldLabel}
                          style={{ lineHeight: '15px' }}
                        >
                          List differences between proposed substitution and specified item
                        </label>
                        <div className="col-8">
                          <TextField
                            variant="filled"
                            InputProps={{
                              style: { width: '100%' },
                              classes: {
                                root: classes.filledInputRoot,
                                multiline: classes.filledInputMultiline,
                                input: classes.filledInputInput,
                              },
                              disableUnderline: true,
                            }}
                            required
                            multiline
                            rows={5}
                            value={differences}
                            onChange={(event) => setDifferences(event.target.value)}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>

                      <div className="form-group row required">
                        <label htmlFor="last_name" className={classes.textFieldLabel}>
                          Manufacturers warranties
                        </label>
                        <div className="col-8">
                          <RadioGroup
                            row
                            value={manufacturersWarranties}
                            onChange={() => setManufacturersWarranties(manufacturersWarranties)}
                          >
                            <FormControlLabel
                              value="same"
                              control={<Radio color="primary" />}
                              label="Same"
                              style={{ textTransform: 'none' }}
                            />
                            <FormControlLabel
                              value="different"
                              control={<Radio color="primary" />}
                              label="Different"
                              style={{ textTransform: 'none' }}
                            />
                          </RadioGroup>
                        </div>
                      </div>

                      <div className="form-group row required">
                        <label htmlFor="last_name" className={classes.textFieldLabel}>
                          Explain
                        </label>
                        <div className="col-8">
                          <TextField
                            variant="filled"
                            InputProps={{
                              style: { width: '100%' },
                              classes: {
                                root: classes.filledInputRoot,
                                multiline: classes.filledInputMultiline,
                                input: classes.filledInputInput,
                              },
                              disableUnderline: true,
                            }}
                            required
                            multiline
                            rows={5}
                            value={explanation}
                            onChange={(event) => setExplanation(event.target.value)}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>

                      <div className="form-group row required">
                        <label
                          htmlFor="last_name"
                          className={classes.textFieldLabel}
                          style={{ lineHeight: '15px' }}
                        >
                          List information on availability of maintenance service and source of
                          replacement materials
                        </label>
                        <div className="col-8">
                          <TextField
                            variant="filled"
                            InputProps={{
                              style: { width: '100%' },
                              classes: {
                                root: classes.filledInputRoot,
                                multiline: classes.filledInputMultiline,
                                input: classes.filledInputInput,
                              },
                              disableUnderline: true,
                            }}
                            required
                            multiline
                            rows={8}
                            value={availabilityInfo}
                            onChange={(event) => setAvailabilityInfo(event.target.value)}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="last_name" className={classes.textFieldLabel}>
                          Additional File{' '}
                          <strong style={{ color: 'black' }}>
                            (Only 1 .pdf or .zip file is allowed)
                          </strong>
                        </label>
                        {fileWasRejected ? (
                          <>
                            <br />
                            <label style={{ color: 'red' }}>
                              Your file was not uploaded because it is not a PDF or zip file.
                            </label>
                          </>
                        ) : null}
                        <div className="col-8">
                          {!inputFile ? (
                            <div className={classes.dropzoneStyling}>
                              <Dropzone
                                accept={['.pdf', '.zip']}
                                onDropRejected={() => setFileWasRejected(true)}
                                onDropAccepted={(files) => {
                                  setFileWasRejected(false);
                                  setInputFile(files[0]);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    style={{ outline: 'none' }}
                                    {...getRootProps({ className: 'dropzone' })}
                                  >
                                    <input {...getInputProps()} />
                                    <p className={classes.dragDropText} style={{ paddingTop: 10 }}>
                                      Drag &amp; Drop file here
                                    </p>
                                    <p className={classes.dragDropText}>or</p>
                                    <div
                                      style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <Button
                                        variant="outlined"
                                        className={classes.browseFileButton}
                                      >
                                        <Add />
                                        Browse Files
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            </div>
                          ) : (
                            <div className={classes.fileOuter}>
                              <div className={classes.file}>
                                <PDFIcon />
                                <Typography className={classes.fileText}>
                                  {inputFile.name}
                                </Typography>
                              </div>
                              <IconButton
                                classes={{
                                  root: classes.rootIconButton,
                                }}
                                onClick={() => handleRemove()}
                              >
                                <HighlightOffRounded />
                              </IconButton>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {!isLoading ? (
                  <div className="modal-footer">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        paddingTop: 12,
                        paddingBottom: 12,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        tabIndex={-1}
                        onKeyDown={toggleVisible}
                        onClick={toggleVisible}
                        style={{ background: '#F28B00', marginRight: '6px', height: 32 }}
                      >
                        Cancel
                      </Button>
                      <Button color="primary" variant="contained" onClick={handleSubmit}>
                        Add
                      </Button>
                    </div>
                  </div>
                ) : null}
              </form>
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
      <Snackbar
        open={snackOpen}
        autoHideDuration={5000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackOpen(false)} severity="success">
          Successfuly uploaded your request!
        </Alert>
      </Snackbar>
    </>
  );
};

export default SubstitutionRequests;
