import React, { SVGProps } from 'react';

const ProcoreIcon = (props: SVGProps<SVGSVGElement>) => {
  const { width = 16, height = 16, fill = '#FFFFFF', ...rest } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...rest}>
      <path
        d="M 12.897119,0.00165778 H 3.1028818 A 3.0031108,3.0031108 0 0 0 0.00165778,3.1028818 v 9.0008002 a 3.0031108,3.0031108 0 0 0 3.10122402,3.101224 h 9.7942372 a 3.0031108,3.0031108 0 0 0 3.101223,-3.099091 V 9.6423258 H 12.351098 L 10.768493,12.372427 H 5.2229758 l -2.772759,-4.7734112 2.772759,-4.777676 h 5.5455172 l 1.584738,2.736499 h 3.645111 V 3.1028818 A 3.0031108,3.0031108 0 0 0 12.897119,0.00165778 Z"
        fill={fill}
      />
      <path
        d="m 6.5005768,5.0139518 -1.501555,2.58933 1.501555,2.5871972 h 2.998846 L 11.000978,7.6032818 9.4994228,5.0139518 Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProcoreIcon;
