import React, { useEffect, useMemo, useState } from 'react';
import pluralize from 'pluralize';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, Divider, Grid, List, makeStyles, Typography } from '@material-ui/core';

import DocumentListItem from './DocumentListItem';
import CircularLoader from '../loader/CircularLoader';
import DownloadIcon from '../icons/Download-icon';
import { getDocumentState } from '../../features/document/selectors';
import { downloadFileById, generateUniqueId, getImageByFileId } from '../../scripts/utils';
import { getFileById } from '../../models/api/files';
import { updateDocument } from '../../features/document/actions';
import ViewImageDialog from '../dialogs/ViewImageDialog';

interface Props {
  title?: string;
}

const SupportingDocumentsFieldReports: React.FC<Props> = ({ title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const document = useSelector(getDocumentState)!;
  const files = document.files;
  const imageFiles = useMemo(
    () =>
      files
        ? files.filter(
            (file) =>
              !file.name.toLowerCase().endsWith('.pdf') &&
              !file.name.toLowerCase().endsWith('.zip'),
          )
        : [],
    [files],
  );
  const pdfAndZipFiles = files
    ? files.filter(
        (file) =>
          file.name.toLowerCase().endsWith('.pdf') || file.name.toLowerCase().endsWith('.zip'),
      )
    : [];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageFilesUrls, setImageFilesUrls] = useState<string[]>([]);
  const [showMore, setShowMore] = useState(true);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [selectedImageFileId, setSelectedImageFileId] = useState<string>();

  const viewFullSizeImage = (fileId: string) => {
    setSelectedImageFileId(fileId);
    setImageDialogOpen(true);
  };

  const handleImageDownload = (i: number) => {
    downloadFileById(imageFiles[i].id, imageFiles[i].name, 'image/jpeg', true);
  };

  useEffect(() => {
    if (imageFiles.length > 0) {
      setIsLoading(true);
      Promise.all(imageFiles.map((file) => getImageByFileId(file.id, false))).then((results) => {
        setImageFilesUrls(results);
        setIsLoading(false);
      });
    }
  }, [imageFiles]);

  const showShowMoreButton = (i: number) => {
    return (
      imageFiles.length > 1 && ((i === 0 && !showMore) || (i === imageFiles.length - 1 && showMore))
    );
  };

  useEffect(() => {
    if (files) {
      const interval = setInterval(async () => {
        const unverfiedFiles = document.files!.filter((f) => !f.isVerified);
        if (unverfiedFiles.length === 0) clearInterval(interval);
        for (let i = 0; i < unverfiedFiles.length; i += 1) {
          const file = unverfiedFiles[i];
          const { isVerified } = await getFileById(file.id);
          if (isVerified) {
            const newDocument = { ...document };
            const fileToChange = newDocument.files?.find((f) => f.id === file.id);
            if (fileToChange) {
              fileToChange.isVerified = true;
              dispatch(updateDocument(newDocument)).then(() => {
                if (!document.files?.some((f) => !f.isVerified)) clearInterval(interval);
              });
            }
          }
        }
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [files]);

  if (pdfAndZipFiles.length === 0 && imageFiles.length === 0) return <></>;

  return (
    <Card style={{ padding: 16 }}>
      <h2 className="h2">{title ? `${title} Supporting Items` : 'Supporting Items'}</h2>
      <div className={classes.root}>
        {imageFiles.length > 0 && (
          <>
            <h3 style={{ marginBottom: '24px' }}>
              {pluralize('Image Attachment', imageFiles.length, true)}
            </h3>
            {isLoading && <CircularLoader style={{ paddingBottom: 80 }} />}
            <Grid container spacing={3}>
              {imageFilesUrls.map((url, i) => {
                const image = imageFiles[i];

                if (i > 0 && !showMore) return null;

                return (
                  <Grid key={generateUniqueId()} item xs={12} md={12}>
                    <Box position="relative">
                      <img
                        src={url}
                        alt={image.name}
                        onClick={() => viewFullSizeImage(image.id)}
                        className={classes.image}
                      />
                    </Box>
                    <Typography variant="body2" className={classes.imageDescription}>
                      {image.description && image.description !== ''
                        ? image.description
                        : image.name}
                    </Typography>
                    <div
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                      id="buttons"
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleImageDownload(i)}
                        disableElevation
                        style={{ paddingLeft: 16, paddingRight: 16, marginTop: 16 }}
                      >
                        <DownloadIcon style={{ width: 24, height: 24 }} fill={'#2C69D6'} /> Download
                      </Button>
                      {showShowMoreButton(i) ? (
                        <Button
                          color="primary"
                          size="medium"
                          disableElevation
                          style={{ marginTop: 40 }}
                          onClick={() => setShowMore(!showMore)}
                        >
                          {showMore ? 'Show less' : 'Show more'}
                        </Button>
                      ) : null}
                    </div>
                    <Divider style={{ width: '100%', marginTop: showShowMoreButton(i) ? 8 : 24 }} />
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
        {pdfAndZipFiles.length > 0 && (
          <>
            <h3>{pluralize('File Attachment', pdfAndZipFiles.length, true)}</h3>
            <List style={{ paddingTop: 0, paddingBottom: 16 }}>
              {pdfAndZipFiles.map((file) => (
                <DocumentListItem key={file.id} show={true} file={file} />
              ))}
            </List>
            <Divider />
          </>
        )}
      </div>
      {selectedImageFileId && (
        <ViewImageDialog
          open={imageDialogOpen}
          handleClose={() => setImageDialogOpen(false)}
          fileId={selectedImageFileId}
        />
      )}
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  image: {
    width: 'auto',
    height: '300px',
    maxWidth: '100%',
    objectFit: 'cover',
    border: '1px solid #eeeeee',
    borderRadius: 4,
  },
  imageButton: {
    position: 'absolute',
    right: 8,
    bottom: 8,
  },
  imageButtonIcon: {
    height: '24px',
    width: '24px',
  },
  imageDescription: {
    marginTop: 8,
    color: '#000000',
  },
}));

export default SupportingDocumentsFieldReports;
