import React, { useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import '../../css/main-style.css';
import { combineClasses, getTitle } from '../../scripts/utils';
import { getDocumentState } from '../../features/document/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentTemplateType, SecurityPermissionLevel } from '../../api-client/autogenerated';
import Button from '@material-ui/core/Button';
import { getDocumentTypePermission } from '../../scripts/store-utils';
import EditAsBuiltStatusDialog from '../dialogs/EditAsBuiltStatusDialog';
import { useHistory } from 'react-router-dom';
import RefusePackageDialog, {
  SubmittalInfo,
} from '../submittal-action-center/packages/RefusePackageDialog';
import { completeReviewForPackageByDocumentId } from '../../models/api/documents';
import { reloadDocument } from '../../features/document/actions';

interface SummaryAttributeProps {
  attributeName: string | null | undefined;
  attributeValue: string | null | number | JSX.Element | undefined;
  large?: boolean;
  spaceBetween?: number;
  editStatusButton?: boolean;
  viewRecipients?: () => void;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 'auto',
    paddingBottom: 32,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 'auto',
    width: 'auto',
  },
  responsibleMargin: {
    marginRight: 10,
  },
  buttonMargin: {
    marginRight: 8,
  },
  followerButtonMargin: {
    marginRight: 16,
  },
  padding: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 14,
    paddingBottom: 14,
  },
  noTopPadding: { paddingLeft: 16, paddingRight: 16, paddingBottom: 14 },
  widthMed: (props: SummaryAttributeProps) => ({
    width: props.spaceBetween,
  }),
  widthLarge: {
    width: 140,
  },
  centerIcon: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: 'auto',
    flexWrap: 'nowrap',
    height: '100%',
    justifyContent: 'flex-end',
  },
  icon: {
    color: '#7A797A',
    cursor: 'pointer',
  },
  fill: {
    flexGrow: 1,
    height: '100%',
    borderColor: '#EDECEC',
  },
  field: {
    flexWrap: 'nowrap',
    alignSelf: 'center',
  },
  inlineFlex: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 'auto',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: 'auto',
    height: '100%',
  },
  noShrink: {
    flexShrink: 0,
  },
  status: {},
});

export default function SummaryAttribute(props: SummaryAttributeProps) {
  // spaceBetween is used above in styles
  const {
    attributeName,
    attributeValue,
    large,
    spaceBetween = 150,
    viewRecipients,
    editStatusButton,
  } = props;

  const isMobile = useMediaQuery('(max-width:670px)');
  const isUnder760px = useMediaQuery('(max-width:760px)');

  const statusDialogAnchor = useRef(null);

  const classes = useStyles(props);
  const history = useHistory();
  const dispatch = useDispatch();
  const document = useSelector(getDocumentState);

  const [statusDialogOpen, setStatusDialogOpen] = useState(false);

  const permission = getDocumentTypePermission();

  const showEditStatusButton =
    editStatusButton && permission && permission >= SecurityPermissionLevel.NUMBER_3;

  const valueIsObject = typeof attributeValue === 'object';

  const isPackage =
    !!document?.documentTemplate?.name &&
    [
      DocumentTemplateType.AsBuiltPackages,
      DocumentTemplateType.SubmittalPackages,
      DocumentTemplateType.CloseoutSubmittalPackages,
    ].includes(document.documentTemplate.name as DocumentTemplateType);

  const handleSubmitPackageReview = async (review: SubmittalInfo[]) => {
    if (!document) return;
    await completeReviewForPackageByDocumentId(
      document.id,
      review.map(({ document, actionTaken, comment }) => ({
        documentId: document.id,
        actionTaken,
        comment,
      })),
    );
    dispatch(reloadDocument());
  };

  return (
    <Grid item xs={large ? 12 : 6} style={{ padding: 0 }}>
      <Box className={classes.fill} borderRight={1} borderBottom={1}>
        <Box className={combineClasses(classes.inlineFlex)}>
          <Grid container direction={isMobile ? 'column' : 'row'} wrap="nowrap">
            <Typography
              className={combineClasses(
                classes.widthMed,
                classes.padding,
                classes.field,
                classes.noShrink,
              )}
              style={{ alignSelf: !valueIsObject ? 'flex-start' : undefined, minWidth: 285 }}
              variant="body2"
            >
              {attributeName}
            </Typography>
            <Grid
              container
              direction={isUnder760px ? 'column' : 'row'}
              wrap="nowrap"
              alignItems="center"
            >
              {!valueIsObject ? (
                <Typography
                  variant="body1"
                  className={combineClasses(classes.noTopPadding, classes.field)}
                  style={{
                    whiteSpace: 'pre-wrap',
                    paddingTop: isMobile ? 0 : 14,
                    minWidth: isMobile ? undefined : 266,
                  }}
                >
                  {attributeValue}
                </Typography>
              ) : (
                <div
                  className={combineClasses(classes.noTopPadding, classes.field)}
                  style={{
                    whiteSpace: 'pre-wrap',
                    paddingTop: isMobile ? 0 : 14,
                    minWidth: isMobile ? undefined : 266,
                  }}
                >
                  {attributeValue}
                </div>
              )}
              {viewRecipients && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={viewRecipients}
                  style={{ marginRight: 32, whiteSpace: 'nowrap' }}
                >
                  View Recipients
                </Button>
              )}
              {showEditStatusButton && (
                <>
                  {!document?.submittalPackageDocumentId ? (
                    <Button
                      ref={statusDialogAnchor}
                      variant="outlined"
                      color="primary"
                      onClick={() => setStatusDialogOpen(true)}
                      style={{ marginRight: 'auto' }}
                    >
                      Review As-Built
                    </Button>
                  ) : (
                    <Typography>
                      This As-Built document is a part of{' '}
                      <span
                        onClick={() => history.push(document.submittalPackageDocumentId!)}
                        style={{ cursor: 'pointer', color: '#0947B9', fontWeight: 500 }}
                      >
                        {getTitle(DocumentTemplateType.AsBuilt, document.submittalPackageDocument!)}
                        .
                      </span>{' '}
                      Please go to the package to review and comment.
                    </Typography>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {editStatusButton ? (
        <>
          {!isPackage && document ? (
            <EditAsBuiltStatusDialog
              anchor={statusDialogAnchor.current}
              dialogOpen={statusDialogOpen}
              handleClose={() => setStatusDialogOpen(false)}
              selectedDocument={document}
              inSummaryCard
            />
          ) : (
            <RefusePackageDialog
              isOpen={statusDialogOpen}
              handleClose={() => setStatusDialogOpen(false)}
              packageDocuments={document?.submittalChildDocuments || []}
              submit={handleSubmitPackageReview}
            />
          )}
        </>
      ) : null}
    </Grid>
  );
}
