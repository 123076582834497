import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  LinearProgress,
  List,
  ListItem,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CameraAlt, Clear, Close, HighlightOffRounded, Publish, Star } from '@material-ui/icons';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import dayjs, { Dayjs } from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import {
  ActionTakenType,
  DocumentPriorityType,
  DocumentTemplateType,
  FileCategoryType,
  FileNode,
  IInsertionDocumentWithGraph,
  INumberedDocumentView,
  IProject,
  IProjectUser,
  S3UploadResponse,
  SecurityPermissionLevel,
} from '../../api-client/autogenerated';
import { getUserState } from '../../features/user/selectors';
import {
  deleteDocumentById,
  generateAsBuiltDocument,
  insertDocument,
  modifyDocumentById,
} from '../../models/api/documents';
import CircularLoader from '../loader/CircularLoader';
import { getTemplates } from '../../models/api/templates';
import {
  getDocumentsState,
  getDocumentsType,
  getSubmittalSections,
} from '../../features/documents/selectors';
import {
  docTypeHasDueDateCreationField,
  DocumentFileTree,
  documentTypeToUrl,
  FileWithData,
  formatDays,
  formatMoney,
  generateUniqueId,
  getDocumentCreationFields,
  getDocumentTreesForAssociations,
  getDocumentTypesWithReferenceNumber,
  getProjectUsers,
  getUserFriendlyDocumentTemplateNamePlural,
  isSimpleWorkflowDoc,
  readFileAsync,
  removeNewlines,
  sortLastNamesDesc,
  waitForFileToBeVerified,
} from '../../scripts/utils';
import FileUploadDialog from './FileUploadDialog';
import {
  importGeneralDocumentFileFromDesign,
  uploadGeneralDocumentFile,
} from '../../models/api/filesystem';
import AssociateDocumentDialog from '../add-associations/AssociateDocumentDialog';
import { getDocumentsByProjectIdAndType } from '../../models/api/project';
import {
  addSpacingIncremental,
  ascendingComparator,
  removeSpacing,
} from '../document-index/DocumentIndexUtils';
import { getProjectState } from '../../features/project/selectors';
import { Autocomplete } from '@material-ui/lab';
import { getNavigationState } from '../../features/navigation/selectors';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import { addSnackbar } from '../../features/snackbar/actions';
import ManagePermissionsDialog, {
  ManagePermissionsDialogType,
} from '../design/ManagePermissionsDialog';
import { getDocumentTypePermission } from '../../scripts/store-utils';
import _, { Dictionary } from 'lodash';
import { fetchDocumentsByType, reloadDocuments } from '../../features/documents/actions';
import InlineNotificationList from '../form-pages/user-groups/InlineNotificationList';
import AssignedUserText from '../custom-components/AssignedUserText';
import { getCompaniesState } from '../../features/companies/selector';
import IconButton from '@material-ui/core/IconButton';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';
import {
  getCurrentSecurityGroup,
  getSubscriberSecurityGroupState,
} from '../../features/security/selectors';
import { getDocumentsLoadingState } from '../../features/loading/selectors';
import { getDocumentState } from '../../features/document/selectors';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { BluebeamButton } from '../custom-components/CustomButtons';
import { StudioLinkTextMask } from '../custom-components/TextMaskCustom';
import SelectCompanyUsersDialog from './SelectCompanyUsersDialog';
import ASIConfirmationDialog from './ASIConfirmationDialog';

type AddDocumentsDialogProps = {
  dialogOpen: boolean;
  handleClose: () => void;
  anchor?: any;
  isDashboard?: boolean;
  isDocumentDetails?: boolean;
};

const useStyles = makeStyles({
  popover: {
    '.rootElement > *': {
      maxHeight: '5000px',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '80px',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'uppercase',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  contentFlow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  section: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    minWidth: '280px',
    maxWidth: '400px',
  },
  checkbox: {
    padding: 3,
  },
  inputLabel: {
    marginTop: 12,
  },
  status: {
    marginBottom: 4,
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    paddingTop: 16,
  },
  calendar: {
    maxWidth: 225,
    paddingTop: 4,
    paddingBottom: 4,
  },
  dueDate: {
    paddingTop: 8,
  },
  button: {
    width: '48%',
    height: 32,
  },
  field: {
    marginTop: 8,
    marginBottom: 8,
    padding: 0,
  },
  selectList: {
    maxHeight: 400,
  },
  textArea: {
    marginTop: 8,
    marginBottom: 8,
    padding: 0,
    resize: 'vertical',
  },
});

const sortAssignableUsers = (a: IProjectUser, b: IProjectUser) => {
  return (
    a.user?.company?.name.localeCompare(b.user?.company?.name || '') ||
    sortLastNamesDesc(a.user?.name, b.user?.name)
  );
};

const getDocumentReviewPeriod = (
  documentType: DocumentTemplateType,
  project: IProject,
  defaultReviewPeriod = 14,
) => {
  if (documentType === DocumentTemplateType.RequestsForInformation) {
    return project?.rfiReviewPeriod ?? project?.documentReviewPeriod ?? defaultReviewPeriod;
  }
  return project?.documentReviewPeriod ?? defaultReviewPeriod;
};

const docTypesEnableDesignImport = [
  DocumentTemplateType.Submittals,
  DocumentTemplateType.CloseoutSubmittals,
  DocumentTemplateType.SubmittalPackages,
  DocumentTemplateType.CloseoutSubmittalPackages,
  DocumentTemplateType.Drawings,
  DocumentTemplateType.Specifications,
  DocumentTemplateType.RequestsForInformation,
  DocumentTemplateType.WorkChangeProposalRequests,
];

const docTypesNoAssociateDocuments = [
  DocumentTemplateType.Task,
  DocumentTemplateType.DesignPackages,
];

function AddDocumentDialog(props: AddDocumentsDialogProps) {
  const classes = useStyles();
  const { dialogOpen, handleClose, anchor, isDashboard, isDocumentDetails } = props;

  const currentDocument = useSelector(getDocumentState);
  const documentTypeState = useSelector(getDocumentsType)!;
  const documents = useSelector(getDocumentsState);
  const documentsLoading = useSelector(getDocumentsLoadingState);
  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);
  const submittalCodes = useSelector(getSubmittalSections);
  const shouldBlockNavigation = useSelector(getNavigationState);
  const companies = useSelector(getCompaniesState);
  const securityGroups = useSelector(getSubscriberSecurityGroupState);
  const security = useSelector(getCurrentSecurityGroup);
  const dispatch = useDispatch();
  const history = useHistory();

  const documentType = isDashboard ? DocumentTemplateType.Task : documentTypeState;

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Creating Document...');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  // String fields
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [referenceNumber, setReferenceNumber] = useState<string>();
  const [impactToCost, setImpactToCost] = useState<string>();
  const [costTBD, setCostTBD] = useState(false);
  const [impactToTime, setImpactToTime] = useState<string>();
  const [timeTBD, setTimeTBD] = useState(false);
  const [location, setLocation] = useState<string>();
  const [agency, setAgency] = useState<string>();
  const [inputSection, setInputSection] = useState<string>();
  const [inputDrawingsDocumentId, setInputDrawingsDocumentId] = useState<string>();
  const [pageNumber, setPageNumber] = useState<number>();
  const [sheetNumber, setSheetNumber] = useState<string>();
  const [responsibility, setResponsibility] = useState<string>();
  const [mc_responsibility, setMCResponsibility] = useState<string>();
  const [generalContractor, setGeneralContractor] = useState<string>(
    project?.defaultGeneralContractorUserId || '',
  );
  const [architect, setArchitect] = useState<string>(project?.defaultArchitectUserId || '');
  const [architects, setArchitects] = useState<string[]>([]);
  const [associatedUsers, setAssociatedUsers] = useState<string[]>([]);
  const [paragraphNumber, setParagraphNumber] = useState<string>();
  const [subparagraphNumber, setSubparagraphNumber] = useState<string>();
  const [associatedGroups, setAssociatedGroups] = useState<string[]>([]);
  const [changeOrdersValue, setChangeOrdersValue] = useState<number | null>();
  const [changeOrdersDaysAdded, setChangeOrdersDaysAdded] = useState<number | null>();
  const [priority, setPriority] = useState<DocumentPriorityType>(DocumentPriorityType.Medium);
  const [forInformationOnly, setForInformationOnly] = useState(false);
  const [subcontractor, setSubcontractor] = useState<string>();
  const [willEnterStudioLink, setWillEnterStudioLink] = useState(false);
  const [studioLink, setStudioLink] = useState<string>();

  // Other fields
  const [due, setDue] = useState<Dayjs | null>(null);
  const [dateOfObservation, setDateOfObservation] = useState<Dayjs | null>(null);
  const [anticipatedDate, setAnticipatedDate] = useState<Dayjs | null>(null);
  const [isFinalExecuted, setIsFinalExecuted] = useState<boolean>(false);
  const [file, setFile] = useState<File | FileNode>();
  const [files, setFiles] = useState<(File | FileNode)[]>([]);
  const [images, setImages] = useState<FileWithData[]>([]);
  const [imageDescriptions, setImageDescriptions] = useState<string[]>([]);

  // Field reports creating option
  const [isUploadFileSelected, setIsUploadFileSelected] = useState(false);

  // Associations
  const [associationDialogOpen, setAssociationDialogOpen] = useState(false);

  // Document association
  const [associateDocumentsDialogOpen, setAssociateDocumentsDialogOpen] = useState(false);
  const [documentFileTrees, setDocumentFileTrees] = useState<DocumentFileTree[]>([]);

  const [existingSections, setExistingSections] = useState<string[]>([]);
  const [existingRefNumbers, setExistingRefNumbers] = useState<string[]>([]);
  const [existingDrawingsDocuments, setExistingDrawingsDocuments] = useState<
    INumberedDocumentView[]
  >([]);

  const [isInvalid, setIsInvalid] = useState(false);

  const [editArchitect, setEditArchitect] = useState(false);
  const [editContractor, setEditContractor] = useState(false);
  const contractorUser =
    documentType === DocumentTemplateType.DesignPackages ||
    documentType === DocumentTemplateType.Task
      ? user
      : project?.projectUserList?.find((pUser) => pUser.userId === generalContractor)?.user;
  const architectUser = project?.projectUserList?.find((pUser) => pUser.userId === architect)?.user;
  const designPackageAssignees = architects.map((architectId) => {
    return project?.projectUserList?.find((pUser) => pUser.userId === architectId)?.user;
  });
  const [costDifference, setCostDifference] = useState(0);
  const [timeDifference, setTimeDifference] = useState(0);

  const [templates, setTemplates] = useState<Dictionary<string>>({});
  const [assignableUsers, setAssignableUsers] = useState<IProjectUser[]>([]);

  const [asiDialogOpen, setAsiDialogOpen] = useState(false);
  const [newDocumentId, setNewDocumentId] = useState<string>();

  const [assignUsersDialogOpen, setAssignUsersDialogOpen] = useState(false);

  useEffect(() => {
    getTemplates().then(setTemplates);
  }, []);

  useEffect(() => {
    if (project?.defaultNotificationGroupId) {
      setAssociatedGroups([project.defaultNotificationGroupId]);
    }

    const associatedUserIds: string[] = [];
    if (user) associatedUserIds.push(user.id);
    if (project?.defaultGeneralContractorUserId)
      associatedUserIds.push(project.defaultGeneralContractorUserId);
    if (project?.defaultArchitectUserId) associatedUserIds.push(project.defaultArchitectUserId);
    if (architects) associatedUserIds.push(...architects);
    setAssociatedUsers(_.uniq(associatedUserIds));
  }, [user, project, architects]);

  useEffect(() => {
    if (isDashboard && dialogOpen && project && !documentsLoading) {
      dispatch(fetchDocumentsByType(project.id, DocumentTemplateType.Task));
    }
  }, [dialogOpen, isDashboard, project, documentsLoading]);

  useEffect(() => {
    if (isDocumentDetails && currentDocument && dialogOpen) {
      setInputSection(currentDocument?.submittalSection || undefined);
      setTitle(currentDocument?.title || undefined);
    }
  }, [currentDocument, isDocumentDetails, dialogOpen]);

  useEffect(() => {
    const projectUsers = getProjectUsers(project, false);

    const projectUsersFiltered = projectUsers.filter((projectUser) => {
      const { user, securityGroupId } = projectUser;

      const permission = securityGroups
        .find((s) => s.id === securityGroupId)
        ?.securityGroupDocumentTemplateList?.find(
          (dt) => dt.documentTemplateId === templates[documentType],
        )?.securityPermissionLevel;

      return (
        user?.isSiteAdmin ||
        user?.adminOfSubscriberId === project?.subscriberId ||
        (permission !== null && permission !== undefined)
      );
    });

    setAssignableUsers(projectUsersFiltered);
  }, [project, templates, documentType, securityGroups]);

  const originalCost = Number(project?.contractAmount ?? 0) + costDifference;
  const originalTime = Number(project?.daysToCompletion ?? 0) + timeDifference;

  const newCost = originalCost + Number(changeOrdersValue ?? 0);
  const newTime = originalTime + Number(changeOrdersDaysAdded ?? 0);

  const popoverRef = useRef<HTMLDivElement>(null);

  const permission = getDocumentTypePermission();

  const shouldCreatePlaceholder = () => {
    return !!permission && permission >= 3;
  };

  const isPlaceholder =
    [DocumentTemplateType.Submittals, DocumentTemplateType.CloseoutSubmittals].includes(
      documentType,
    ) &&
    shouldCreatePlaceholder() &&
    !isDocumentDetails;

  //Task assignees for design
  useEffect(() => {
    if (documentType === DocumentTemplateType.Task) {
      setGeneralContractor(user.id);
      setArchitect('');
    } else {
      setGeneralContractor(project?.defaultGeneralContractorUserId || '');
      setArchitect(project?.defaultArchitectUserId || '');
    }
    if (documentType === DocumentTemplateType.DesignPackages) {
      setGeneralContractor(user.id);
      setArchitects([]);
    }
  }, [documentType, project, user]);

  useEffect(() => {
    if (!file) return;
    const temp: (File | FileNode)[] = files;
    temp.push(file);
    setFiles(temp);
    setFile(undefined);
  }, [file, files, setFile]);

  useEffect(() => {
    setExistingRefNumbers(documents.map((doc) => doc.referenceNumber || ''));

    if (
      documentType === DocumentTemplateType.Submittals ||
      documentType === DocumentTemplateType.CloseoutSubmittals
    ) {
      setExistingSections(documents.map((d) => d.submittalSection).filter((s) => s) as string[]);
    }

    if (documentType === DocumentTemplateType.ChangeOrders) {
      setCostDifference(
        documents.reduce((prev, current) => prev + Number(current.changeOrdersValue ?? 0), 0),
      );
      setTimeDifference(
        documents.reduce((prev, current) => prev + (current.changeOrdersDaysAdded ?? 0), 0),
      );
    }
  }, [documents]);

  useEffect(() => {
    if (project && documentType === DocumentTemplateType.AsBuilt) {
      getDocumentsByProjectIdAndType(project.id, DocumentTemplateType.Drawings).then(
        setExistingDrawingsDocuments,
      );
    }
  }, [documentType, project]);

  const clearState = () => {
    setTitle('');
    setDescription('');
    setImpactToCost('');
    setImpactToTime('');
    setChangeOrdersDaysAdded(undefined);
    setChangeOrdersValue(undefined);
    setLocation('');
    setAgency('');
    setInputSection('');
    setPageNumber(undefined);
    setResponsibility('');
    setMCResponsibility('');
    setDateOfObservation(null);
    setAnticipatedDate(null);
    setIsFinalExecuted(false);
    setFiles([]);
    setPriority(DocumentPriorityType.Medium);
    setIsUploadFileSelected(false);
    setSubcontractor(undefined);
    setWillEnterStudioLink(false);
    setStudioLink(undefined);
    setArchitects([]);
  };

  const closeClicked = () => {
    if (shouldBlockNavigation || isLoading) return;
    setEditArchitect(false);
    setEditContractor(false);
    handleClose();
    clearState();
  };

  const getFileCategoryType = () => {
    if (documentType === DocumentTemplateType.PayApplications && isFinalExecuted)
      return FileCategoryType.DocumentFinalExecuted;
    if (isSimpleWorkflowDoc(documentType))
      return FileCategoryType.DocumentGeneralContractorApproved;
    if (
      (documentType === DocumentTemplateType.Submittals ||
        documentType === DocumentTemplateType.CloseoutSubmittals) &&
      !isPlaceholder
    )
      return FileCategoryType.DocumentGeneralContractorApproved;
    if (documentType === DocumentTemplateType.Drawings) return FileCategoryType.DrawingsSection;
    return FileCategoryType.DocumentAttachments;
  };

  const uploadFile = async (docId: string, fileToUpload: File, fileDescription?: string) => {
    const fileType = getFileCategoryType();
    return uploadGeneralDocumentFile(
      docId,
      {
        useMultiPartUpload: fileToUpload.size > MULTI_PART_FILE_SIZE,
        fullFileName: fileToUpload.name,
        fileType,
        description: fileDescription,
      },
      fileToUpload,
      handleUploadProgress,
    );
  };

  const handleDocumentCreated = (id: string) => {
    if (!project || !documentType) return;

    if (documentType === DocumentTemplateType.ChangeOrders) dispatch(reloadDocuments());
    else if (
      documentType === DocumentTemplateType.AsiDocuments &&
      (user.isSiteAdmin ||
        user.adminOfSubscriberId === project?.subscriberId ||
        (security?.publishingCenterPermission && security.publishingCenterPermission > 0))
    ) {
      setAsiDialogOpen(true);
      setNewDocumentId(id);
    } else
      history.push(
        `/main/projects/${project.id}/documents/${documentTypeToUrl[documentType]}/${id}`,
      );
  };

  const getTitleLabel = () => {
    switch (documentType) {
      case DocumentTemplateType.Drawings:
        return 'Sheet Name';
      case DocumentTemplateType.FieldReports:
      case DocumentTemplateType.RequestsForChange:
        return 'Title';
      default:
        return 'Description';
    }
  };

  const getFieldObjects = (fields: string[]) => {
    const objs: {
      label: string;
      value: any | undefined;
      setValue: React.Dispatch<React.SetStateAction<any>> | undefined;
      type?: string;
      optional?: boolean;
      options?: string[];
    }[] = [];
    fields.forEach((label) => {
      // Document #
      switch (label) {
        case 'Upload':
          return objs.push({ label, value: files, setValue: setFiles, type: 'file' });
        case 'Images':
          return objs.push({ label, value: images, setValue: setImages, type: 'images' });
        case 'Responsibility':
          return objs.push({
            label,
            value: responsibility,
            setValue: setResponsibility,
            type: 'responsibility',
          });
        case 'MC_Responsibility':
          return objs.push({
            label,
            value: mc_responsibility,
            setValue: setMCResponsibility,
            type: 'mc_responsibility',
          });
        case 'General Observation':
          return objs.push({
            label,
            value: description,
            setValue: setDescription,
            type: 'textarea',
            optional: false,
          });
        case 'Description':
          return objs.push({
            label,
            value: description,
            setValue: setDescription,
            type: 'textarea',
            optional: documentType !== DocumentTemplateType.WarrantyItems,
          });
        case 'Impact to Cost':
          return objs.push({
            label,
            value: impactToCost,
            setValue: setImpactToCost,
            type: 'impact',
          });
        case 'Impact to Time':
          return objs.push({
            label: 'Impact to Time (days)',
            value: impactToTime,
            setValue: setImpactToTime,
            type: 'impact',
          });
        case 'Change Orders Value':
          return objs.push({
            label: 'Impact to Cost',
            value: changeOrdersValue,
            setValue: setChangeOrdersValue,
            type: 'change',
          });
        case 'Change Orders Days Added':
          return objs.push({
            label: 'Impact to Time',
            value: changeOrdersDaysAdded,
            setValue: setChangeOrdersDaysAdded,
            type: 'change',
          });
        case 'Location':
          return objs.push({ label, value: location, setValue: setLocation });
        case 'Title':
          return objs.push({
            label: getTitleLabel(),
            value: title,
            setValue: setTitle,
            type: 'Title',
          });
        case 'Agency':
          return objs.push({ label, value: agency, setValue: setAgency, optional: true });
        case 'Section':
        case 'Specification Section':
          return objs.push({
            label,
            value: inputSection,
            type: 'autocomplete',
            setValue: setInputSection,
          });
        case 'Submittal Paragraph Number':
          return objs.push({
            label,
            value: paragraphNumber,
            setValue: setParagraphNumber,
          });
        case 'Submittal Subparagraph Number':
          return objs.push({
            label,
            value: subparagraphNumber,
            setValue: setSubparagraphNumber,
          });
        case 'Drawings Sheet':
          return objs.push({
            label,
            value: inputDrawingsDocumentId,
            setValue: setInputDrawingsDocumentId,
            type: 'autocomplete',
          });
        case 'Anticipated Initial Submission Date':
          return objs.push({
            label: 'Anticipated Submission Date',
            value: anticipatedDate,
            setValue: setAnticipatedDate,
            type: 'date',
          });
        case 'Date of Observation':
        case 'Meeting Date':
          return objs.push({
            label,
            value: dateOfObservation,
            setValue: setDateOfObservation,
            type: 'date',
          });
        case 'Log Date':
          return objs.push({
            label,
            value: dateOfObservation,
            setValue: setDateOfObservation,
            type: 'date',
          });
        case 'Due Date':
          return objs.push({ label, value: due, setValue: setDue, type: 'date' });
        case 'dueOptional':
          return objs.push({
            label: 'Due',
            value: due,
            setValue: setDue,
            type: 'date',
            optional: true,
          });
        case 'Sheet #':
          return objs.push({
            label,
            value: documentType === DocumentTemplateType.Drawings ? sheetNumber : pageNumber,
            setValue:
              documentType === DocumentTemplateType.Drawings ? setSheetNumber : setPageNumber,
          });
        case 'Final Executed?':
          return objs.push({
            label,
            value: isFinalExecuted,
            setValue: setIsFinalExecuted,
            type: 'boolean',
          });
        case 'Priority':
          return objs.push({ label, value: priority, setValue: setPriority, type: 'priority' });
        case 'Subcontractor':
          return objs.push({
            label: 'Responsible Subcontractor',
            value: subcontractor,
            setValue: setSubcontractor,
            optional: true,
          });
        case 'Reference #':
        case 'ASI #':
        case 'Change Order #':
        case 'CCD #':
        case 'WCPR #':
        case 'PCO #':
        case 'RFI #':
        case 'Field Report #':
        case 'RFC #':
        case 'Pay App #':
        case 'Testing #':
        case 'Item #':
          return objs.push({
            label,
            value: referenceNumber,
            setValue: setReferenceNumber,
            optional: documentType === DocumentTemplateType.WarrantyItems,
          });
        default:
          break;
      }
    });
    return objs;
  };

  const getSubmittalParagraphNumberForPlaceholder = () => {
    if (
      (documentType === DocumentTemplateType.Submittals ||
        documentType === DocumentTemplateType.CloseoutSubmittals) &&
      inputSection
    ) {
      const countedSections = _.countBy(existingSections);
      const count = countedSections[inputSection];
      if (count !== undefined)
        return (count + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
      return '01';
    }
    return undefined;
  };

  const getNextSubmittalParagraphNumberForRequirement = () => {
    if (
      documentType === DocumentTemplateType.Submittals ||
      documentType === DocumentTemplateType.CloseoutSubmittals
    ) {
      const submittalHasSubparagraphNumber = !!currentDocument?.submittalSubparagraphNumber;
      return (
        Math.max(
          ...documents
            .filter((d) =>
              d.submittalSection === currentDocument?.submittalSection &&
              submittalHasSubparagraphNumber
                ? d.submittalParagraphNumber === currentDocument?.submittalParagraphNumber
                : !d.submittalSubparagraphNumber,
            )
            .map(
              (d) =>
                Number(
                  submittalHasSubparagraphNumber
                    ? d.submittalSubparagraphNumber
                    : d.submittalParagraphNumber,
                ) ?? 0,
            ),
        ) + 1
      ).toLocaleString(undefined, { minimumIntegerDigits: 2 });
    }
    return undefined;
  };

  const shouldSetDueDate = () => {
    if (
      [
        DocumentTemplateType.Task,
        DocumentTemplateType.Submittals,
        DocumentTemplateType.CloseoutSubmittals,
      ].includes(documentType)
    ) {
      return !forInformationOnly;
    }

    return true;
  };

  const getPriorityValue = () => {
    if (documentType !== DocumentTemplateType.Task) return undefined;
    return forInformationOnly ? DocumentPriorityType.ForInformationOnly : priority;
  };

  const getAnticipatedSubmissionDate = () => {
    if (
      !project ||
      ![DocumentTemplateType.Submittals, DocumentTemplateType.CloseoutSubmittals].includes(
        documentType,
      ) ||
      forInformationOnly
    ) {
      return undefined;
    }

    return (
      anticipatedDate?.format('YYYY-MM-DD') ||
      dayjs().add(getDocumentReviewPeriod(documentType, project, 0), 'day').format('YYYY-MM-DD')
    );
  };

  const getDocumentObject = (): Partial<IInsertionDocumentWithGraph> => {
    return {
      title: title?.trim() || undefined,
      description: description?.trim() || undefined,
      proposedCostImpact: costTBD
        ? null
        : impactToCost != null
        ? Math.round(Number(impactToCost))
        : impactToCost,
      proposedTimeImpact: timeTBD ? null : Number(impactToTime) || undefined,
      changeOrdersDaysAdded,
      changeOrdersValue:
        changeOrdersValue != null ? Math.round(changeOrdersValue) : changeOrdersValue,
      location: location?.trim() || undefined,
      agency: agency?.trim() || undefined,
      submittalSection: inputSection?.trim() || undefined,
      submittalParagraphNumber: isDocumentDetails
        ? !!currentDocument?.submittalSubparagraphNumber
          ? currentDocument?.submittalParagraphNumber
          : getNextSubmittalParagraphNumberForRequirement()
        : getSubmittalParagraphNumberForPlaceholder(),
      submittalSubparagraphNumber:
        isDocumentDetails && !!currentDocument?.submittalSubparagraphNumber
          ? getNextSubmittalParagraphNumberForRequirement()
          : undefined,
      pageNumber,
      sheetNumber: sheetNumber?.trim(),
      generalContractorUserId: generalContractor || undefined,
      architectUserId: architect || undefined,
      isFinalExecuted: isFinalExecuted || undefined,
      anticipatedInitialSubmissionDate: getAnticipatedSubmissionDate(),
      dueDate:
        due && docTypeHasDueDateCreationField(documentType) && shouldSetDueDate()
          ? due.toISOString()
          : undefined,
      dateOfObservation: dateOfObservation ? dateOfObservation?.format('YYYY-MM-DD') : undefined,
      referenceNumber: referenceNumber?.trim() || undefined,
      priority: getPriorityValue(),
      actionTaken:
        forInformationOnly && getPriorityValue() !== DocumentPriorityType.ForInformationOnly
          ? ActionTakenType.ForInformationOnly
          : undefined,
      customContractor: subcontractor?.trim() || undefined,
      bluebeamStudioSessionId: studioLink?.trim() || undefined,
      // disciplines: associatedDisciplines.map((discipline) => {
      //   return { discipline };
      // }),
    };
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const referenceNumberIsValid = () => {
    if (documentType === DocumentTemplateType.WarrantyItems) {
      return !referenceNumber || !existingRefNumbers.includes(referenceNumber);
    }
    return !(!referenceNumber || existingRefNumbers.includes(referenceNumber));
  };

  const createDocument = async () => {
    const templates = await getTemplates();

    const document: IInsertionDocumentWithGraph = {
      documentTemplateId: templates[documentType],
      creatorUserId: user.id,
      lastUpdatedByUserId: user.id,
      projectId: project!.id,
      ...getDocumentObject(),
    };

    let associatedDocuments = documentFileTrees
      .map((tree) => tree.documents.filter((doc) => doc.checked).map((doc) => doc.id))
      .flat();

    if (isDocumentDetails && currentDocument?.parsedParentDocumentId) {
      associatedDocuments.push(currentDocument.parsedParentDocumentId);
    }

    return insertDocument(document, {
      followerUserIds: !isDocumentDetails
        ? associatedUsers
        : [...associatedUsers, ...(currentDocument?.documentUserList?.map((u) => u.userId) || [])],
      associatedDocumentIds: associatedDocuments,
      userGroupIds: !isDocumentDetails
        ? associatedGroups
        : [
            ...associatedGroups,
            ...(currentDocument?.documentUserGroupList?.map((g) => g.userGroupId) || []),
          ],
      skipToReviewComplete: forInformationOnly,
      skipInitialStatus: !isPlaceholder,
      skipToResolved: documentType === DocumentTemplateType.PayApplications && isFinalExecuted,
      copyFileIds: isDocumentDetails
        ? currentDocument?.files
            ?.filter((f) => f.category === FileCategoryType.SpecificationSection)
            .map((f) => f.id)
        : undefined,
      consultantUserIds:
        documentType === DocumentTemplateType.DesignPackages ? architects : undefined,
    });
  };

  const uploadFiles = async (documentId: string) => {
    try {
      const results: S3UploadResponse[] = [];
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        if ((file as FileNode).relativeKey !== undefined) {
          const fileNode = file as FileNode;
          setLoadingText(`Importing ${fileNode.relativeKey}`);
          const res = await importGeneralDocumentFileFromDesign(documentId, getFileCategoryType(), {
            ownerId: fileNode.ownerId,
            fullKey: fileNode.fullKey,
            fullFileName: fileNode.relativeKey!,
            projectId: fileNode.projectId,
          });
          results.push(res);
        } else {
          setLoadingText(`Uploading ${(file as File).name}`);
          const res = await uploadFile(documentId, file as File);
          results.push(res);
        }
      }

      if (documentType === DocumentTemplateType.Drawings && results.length && results[0].file) {
        await modifyDocumentById(documentId, {
          patch: {
            drawingsSectionFileId: results[0].file.id,
          },
        });
      }

      return results;
    } catch (e: any) {
      setSnackOpen(true);
      await deleteDocumentById(documentId);
      console.error(`Error uploading files: ${e}`);
    }
  };

  const uploadImages = async (documentId: string) => {
    try {
      const results: S3UploadResponse[] = [];
      for (let i = 0; i < images.length; i += 1) {
        const image = images[i];
        const description = imageDescriptions[i] || '';
        setLoadingText(`Uploading ${image.file.name}`);
        const res = await uploadFile(documentId, image.file, description);
        results.push(res);
        setUploadProgress(0);
      }
      return results;
    } catch (e: any) {
      setSnackOpen(true);
      await deleteDocumentById(documentId);
      console.error(`Error uploading images: ${e}`);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!project) return;
    let newDocument: INumberedDocumentView;

    if (
      documentType &&
      getDocumentTypesWithReferenceNumber().includes(documentType) &&
      !referenceNumberIsValid()
    ) {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'A document with this Reference # already exists!',
          severity: 'warning',
        }),
      );
      return;
    }

    setIsLoading(true);
    dispatch(blockNavigation());

    try {
      if (documentType === DocumentTemplateType.AsBuilt && inputDrawingsDocumentId) {
        const associatedDocuments = documentFileTrees
          .map((tree) => tree.documents.filter((doc) => doc.checked).map((doc) => doc.id))
          .flat();

        newDocument = await generateAsBuiltDocument(
          inputDrawingsDocumentId,
          associatedUsers,
          associatedGroups,
          associatedDocuments,
        );
      } else {
        newDocument = await createDocument();

        const fileResults = await uploadFiles(newDocument.id);
        const imageResults = await uploadImages(newDocument.id);
        const allResults = [...(fileResults || []), ...(imageResults || [])];

        if (documentType === DocumentTemplateType.DesignPackages) {
          if (allResults.length > 0) {
            setLoadingText('Verifying files');

            await Promise.all(
              allResults.map(({ file }) => {
                if (file) {
                  return waitForFileToBeVerified(file);
                }
              }),
            );
          }
        }
      }

      dispatch(allowNavigation());
      handleDocumentCreated(newDocument.id);
    } finally {
      dispatch(allowNavigation());
      closeClicked();
      setIsLoading(false);
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles: FileWithPath[]) => {
      try {
        const filesWithData: FileWithData[] = [];

        for (const file of acceptedFiles) {
          const dataUrl = await readFileAsync(file);
          filesWithData.push({ file: file as File, data: dataUrl });
        }

        setImages([...images, ...filesWithData]);
        setImageDescriptions([...imageDescriptions, ...acceptedFiles.map(() => '')]);
      } catch (error: any) {
        console.error(`Error uploading files: ${error.message}`);
        setSnackOpen(true);
      }
    },
    [images, imageDescriptions],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    multiple: true,
    onDrop,
  });

  const handleImageDelete = (i: number) => {
    const tempImages = [...images];
    tempImages.splice(i, 1);
    setImages([...tempImages]);
  };

  function handleFileDelete(currFile: File | FileNode) {
    const items = [...files];
    items.splice(files.indexOf(currFile), 1);
    setFiles([...items]);
  }

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const getAssignedContractorLabel = () => {
    if (
      documentType === DocumentTemplateType.Task ||
      documentType === DocumentTemplateType.DesignPackages
    ) {
      return 'By';
    }

    return 'Contractor';
  };

  const getAssignedArchitectLabel = () => {
    if (
      documentType === DocumentTemplateType.Task ||
      documentType === DocumentTemplateType.DesignPackages
    ) {
      return 'To';
    }

    return 'Architect';
  };

  useEffect(() => {
    setIsInvalid(getIsInvalid());
  }, [
    dialogOpen,
    files,
    responsibility,
    mc_responsibility,
    architect,
    architects,
    generalContractor,
    description,
    impactToCost,
    impactToTime,
    costTBD,
    timeTBD,
    changeOrdersValue,
    changeOrdersDaysAdded,
    location,
    title,
    agency,
    inputSection,
    anticipatedDate,
    due,
    dateOfObservation,
    pageNumber,
    sheetNumber,
    isFinalExecuted,
    referenceNumber,
    file,
    isPlaceholder,
    priority,
    forInformationOnly,
    subcontractor,
    willEnterStudioLink,
    studioLink,
  ]);

  useEffect(() => {
    if (project) {
      setDue(dayjs().add(getDocumentReviewPeriod(documentType, project), 'day'));
    }
  }, [project, documentType]);

  useEffect(() => {
    if (project) {
      getDocumentTreesForAssociations(project.id).then(setDocumentFileTrees);
    }
  }, [project]);

  const typeRequiresFile = (documentType?: DocumentTemplateType | null) => {
    if (!documentType) return false;
    switch (documentType) {
      case DocumentTemplateType.Submittals:
        return !isPlaceholder;

      case DocumentTemplateType.FieldReports:
        return true;

      case DocumentTemplateType.DesignPackages:
        return !studioLink || studioLink.includes('_');

      case DocumentTemplateType.RequestsForInformation:
      case DocumentTemplateType.Task:
        return !description?.trim();

      default:
        return false;
    }
  };

  const getSpecificationsNoOptionsText = () => {
    if (!submittalCodes || submittalCodes.length === 0)
      return 'Something went wrong! Please refresh the page.';
    if (!inputSection || inputSection.length < 2) return 'Type more to see suggestions';
    return 'No results found';
  };

  /**
   * Returns whether or not the required fields are filled out
   */
  function getIsInvalid() {
    return getDocumentCreationFields(documentType || undefined).some((field) => {
      switch (field) {
        case 'Upload':
          return typeRequiresFile(documentType) && files.length === 0 && !file;
        case 'Responsibility':
          return documentType === DocumentTemplateType.Task && !architect; // This seems kinda broken
        case 'MC_Responsibility':
          return architects.length === 0;
        case 'Description':
          return documentType === DocumentTemplateType.WarrantyItems ? !description?.trim() : false; // Not sure if we need to check this
        case 'Impact to Cost':
          return !impactToCost && !costTBD;
        case 'Impact to Time':
          return !impactToTime && !timeTBD;
        case 'Location':
          return !location;
        case 'Title':
          return !title;
        // case 'Agency':
        //   return !agency;
        case 'Section':
        case 'Specification Section':
          return !inputSection;
        case 'Anticipated Initial Submission Date':
          return false; // Not usually set
        case 'Due':
          return false; // Not usually set
        case 'Date of Observation':
        case 'Log Date':
          return !dateOfObservation;
        case 'Sheet #':
          return documentType === DocumentTemplateType.Drawings ? !sheetNumber : !pageNumber;
        case 'Final Executed?':
          return false; // This is a boolean.  Idk if we need to check this either.
        case 'Reference #':
        case 'ASI #':
        case 'Change Order #':
        case 'CCD #':
        case 'WCPR #':
        case 'PCO #':
        case 'RFI #':
        case 'Field Report #':
        case 'RFC #':
        case 'Pay App #':
        case 'Testing #':
          if (documentType === DocumentTemplateType.WarrantyItems) return false;
          return !referenceNumber || existingRefNumbers.includes(referenceNumber);
        case 'General Observation':
          return !description?.trim();
        default:
          return false;
      }
    });
  }

  const getDateFieldDisabled = (label: string) => {
    return (
      (documentType === DocumentTemplateType.Submittals ||
        documentType === DocumentTemplateType.CloseoutSubmittals) &&
      label === 'Due Date' &&
      isPlaceholder
    );
  };

  const removeDocumentAssociation = (type: DocumentTemplateType, id: string) => {
    const newDocumentFileTrees = [...documentFileTrees];
    newDocumentFileTrees
      .find((tree) => tree.type === type)!
      .documents.find((doc) => doc.id === id)!.checked = false;
    setDocumentFileTrees(newDocumentFileTrees);
  };

  // useEffect(() => {
  //   if (dialogOpen && documentFileTrees.length === 0) {
  //     getDocuments();
  //   }
  // }, [dialogOpen]);

  /** Handle unique case for field reports document.
   * TODO: Refactor for better maintainability in the future */
  if (documentType === DocumentTemplateType.FieldReports && !isUploadFileSelected) {
    return (
      <Popover
        className={classes.popover}
        open={dialogOpen}
        onClose={closeClicked}
        onEnter={() => {
          setIsLoading(false);
        }}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box className={classes.section}>
          <Typography style={{ marginTop: '8px' }}>Select field reports option</Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '16px 0px 8px' }}
            onClick={() => setIsUploadFileSelected(true)}
          >
            Upload a Created Report
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '8px 0px 16px' }}
            onClick={() => history.push(`${documentTypeToUrl[documentType!]}/create`)}
          >
            Create a new field report
          </Button>
        </Box>
      </Popover>
    );
  }

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <Popover
        className={classes.popover}
        ref={popoverRef}
        open={dialogOpen}
        onClose={closeClicked}
        onEnter={() => {
          setIsLoading(false);
        }}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorPosition={{
          top: window.innerHeight / 5,
          left: window.innerWidth / 2 - 140,
        }}
        anchorReference={anchor ? 'anchorEl' : 'anchorPosition'}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <form className={classes.contentFlow}>
          <Box className={classes.section}>
            <Typography style={{ color: '#0947B9', fontSize: 20, fontWeight: 500 }}>
              Create
            </Typography>
            <IconButton
              style={{ right: 14, top: 12, position: 'absolute' }}
              onClick={closeClicked}
              classes={{
                root: classes.rootIconButton,
              }}
            >
              <HighlightOffRounded />
            </IconButton>
            {getFieldObjects(getDocumentCreationFields(documentType || undefined)).map(
              (field: any, index: number) => {
                if (!field.type) {
                  return (
                    <TextField
                      key={field.label}
                      variant="outlined"
                      margin="none"
                      size="small"
                      fullWidth
                      error={
                        field.label.includes('#') &&
                        documentType &&
                        getDocumentTypesWithReferenceNumber().includes(documentType) &&
                        !!referenceNumber &&
                        existingRefNumbers.includes(referenceNumber)
                      }
                      label={field.label}
                      required={!field.optional}
                      autoFocus={index === 0}
                      value={field.value}
                      className={classes.field}
                      onChange={(e) => {
                        field.setValue(removeNewlines(e.target.value));
                      }}
                    />
                  );
                }
                if (field.type === 'Title') {
                  return (
                    <TextField
                      key={field.label}
                      variant="outlined"
                      margin="none"
                      size="small"
                      fullWidth
                      label="Title"
                      required={!field.optional}
                      autoFocus={index === 0}
                      value={field.value}
                      className={classes.field}
                      onChange={(e) => {
                        field.setValue(removeNewlines(e.target.value));
                      }}
                    />
                  );
                }
                if (field.type === 'date') {
                  if (
                    field.label === 'Anticipated Submission Date' &&
                    (!permission ||
                      permission < SecurityPermissionLevel.NUMBER_3 ||
                      isDocumentDetails)
                  )
                    return null;
                  if (
                    [
                      DocumentTemplateType.Task,
                      DocumentTemplateType.Submittals,
                      DocumentTemplateType.CloseoutSubmittals,
                    ].includes(documentType) &&
                    forInformationOnly
                  ) {
                    return null;
                  }
                  return (
                    <>
                      <div style={{ fontSize: '12px', lineHeight: '15px', marginTop: 4 }}>
                        {field.label}
                      </div>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        InputProps={{ style: { padding: 0 } }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{ style: { padding: '8px' } }}
                        value={field.value}
                        placeholder={field.label}
                        disabled={getDateFieldDisabled(field.label)}
                        format="MM/DD/YYYY"
                        onChange={(date) => field.setValue(date)}
                        className={classes.field}
                        required={!field.optional}
                        style={{ marginTop: 4 }}
                      />
                    </>
                  );
                }
                if (field.type === 'boolean') {
                  if (
                    documentType !== DocumentTemplateType.PayApplications ||
                    user.isSiteAdmin ||
                    (permission && permission >= 3)
                  )
                    return (
                      <div
                        key={field.label}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                          fontSize: '15px',
                          color: '#464546',
                        }}
                      >
                        {field.label}
                        <Checkbox
                          checked={field.value}
                          onChange={(e) => field.setValue(e.target.checked)}
                        />
                      </div>
                    );
                }
                if (field.type === 'textarea') {
                  return (
                    <TextField
                      key={field.label}
                      variant="outlined"
                      margin="none"
                      size="small"
                      fullWidth
                      multiline
                      // rows="5"
                      label={field.label}
                      required={!field.optional}
                      autoFocus={index === 0}
                      value={field.value}
                      className={classes.textArea}
                      onChange={(e: any) => {
                        field.setValue(
                          field.label === 'Description'
                            ? e.target.value
                            : removeNewlines(e.target.value),
                        );
                      }}
                    />
                  );
                }
                if (field.type === 'file') {
                  return (
                    <div key={field.label}>
                      <div style={{ fontSize: '15px' }}>FILES:</div>
                      {files &&
                        files.length > 0 &&
                        files.map((item) => {
                          return (
                            <div
                              key={generateUniqueId()}
                              style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '2px',
                              }}
                            >
                              <div
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: '80%',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {(item as File).name || (item as FileNode).relativeKey}
                              </div>
                              <Clear
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleFileDelete(item)}
                                fill="#949494"
                              />
                            </div>
                          );
                        })}
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ height: '32px', width: '100%', marginTop: 8 }}
                          onClick={() => setFileUploadDialogOpen(true)}
                          startIcon={<Publish fill="#2C69D6" />}
                        >
                          UPLOAD
                          {typeRequiresFile(documentType) && '*'}
                        </Button>
                        <FileUploadDialog
                          open={fileUploadDialogOpen}
                          newFiles={files}
                          addFile={setFile}
                          addFiles={(newFiles) => setFiles([...files, ...newFiles])}
                          canSubmit={files.length > 0}
                          removeFile={(f) => handleFileDelete(f)}
                          disableComments
                          handleClose={() => setFileUploadDialogOpen(false)}
                          title="Upload File"
                          allowMultiple={
                            documentType !== DocumentTemplateType.Submittals &&
                            documentType !== DocumentTemplateType.CloseoutSubmittals &&
                            documentType !== DocumentTemplateType.Drawings
                          }
                          acceptAll={documentType === DocumentTemplateType.Schedules}
                          disableDesignUpload={!docTypesEnableDesignImport.includes(documentType)}
                        />
                      </div>
                      {documentType === DocumentTemplateType.DesignPackages ||
                      documentType === DocumentTemplateType.Task ? (
                        <>
                          <Typography
                            align="center"
                            style={{ marginTop: 8, marginBottom: 8, fontWeight: 700 }}
                          >
                            OR
                          </Typography>
                          {!willEnterStudioLink ? (
                            <BluebeamButton
                              fullWidth
                              onClick={() => setWillEnterStudioLink(true)}
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Enter A Bluebeam Studio Session
                            </BluebeamButton>
                          ) : (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <TextField
                                variant="outlined"
                                margin="none"
                                size="small"
                                fullWidth
                                label="Bluebeam Studio Session ID"
                                autoFocus={index === 0}
                                value={studioLink}
                                className={classes.field}
                                InputProps={{
                                  inputComponent: StudioLinkTextMask,
                                }}
                                onChange={(e) => {
                                  setStudioLink(removeNewlines(e.target.value));
                                }}
                              />

                              <IconButton
                                onClick={() => {
                                  setStudioLink(undefined);
                                  setWillEnterStudioLink(false);
                                }}
                                style={{ padding: '0 0 0 8px' }}
                              >
                                <Tooltip arrow placement="top" title="Clear">
                                  <Close />
                                </Tooltip>
                              </IconButton>
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  );
                }
                if (field.type === 'images') {
                  return (
                    <div key={field.label}>
                      <div style={{ fontSize: '15px', marginTop: 8 }}>IMAGES:</div>

                      <em>Only *.jpeg and *.png images will be accepted</em>

                      <div {...getRootProps()} style={{ marginBottom: 16 }}>
                        <input {...getInputProps()} />
                        <Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          startIcon={<CameraAlt />}
                        >
                          Take Photo / Select Images
                        </Button>
                      </div>
                      {images.length > 0 ? (
                        <>
                          {images.map((item, index) => {
                            return (
                              <div key={item.file.name + index} style={{ marginBottom: 16 }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center',
                                  }}
                                >
                                  <img
                                    alt={imageDescriptions[index]}
                                    src={item.data?.toString()}
                                    style={{ objectFit: 'contain', width: '100%' }}
                                  />
                                  <Clear
                                    style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
                                    onClick={() => handleImageDelete(index)}
                                    fill="#949494"
                                  />
                                </div>
                                <TextField
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  placeholder="Description"
                                  style={{ marginTop: 8, maxWidth: 500 }}
                                  value={imageDescriptions[index]}
                                  onChange={(e) => {
                                    const descriptions = [...imageDescriptions];
                                    descriptions[index] = e.target.value;
                                    setImageDescriptions([...descriptions]);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                  );
                }
                if (field.type === 'responsibility') {
                  return (
                    <>
                      {editContractor || !generalContractor ? (
                        <TextField
                          select
                          variant="outlined"
                          label={`Assign ${getAssignedContractorLabel()}`}
                          fullWidth
                          value={generalContractor}
                          onChange={(e) => setGeneralContractor(e.target.value)}
                          style={{ marginBottom: 8, marginTop: 8 }}
                        >
                          {assignableUsers.sort(sortAssignableUsers).map((item) => {
                            return (
                              <MenuItem key={item.id} value={item.user!.id}>
                                <AssignedUserText
                                  name={item.user!.name}
                                  company={
                                    item.user?.company?.name ||
                                    companies.find((c) => c.id === item.user?.companyId)?.name
                                  }
                                />
                              </MenuItem>
                            );
                          })}
                          {assignableUsers.length === 0 ? (
                            <Typography
                              align="center"
                              style={{
                                maxWidth: 300,
                                marginLeft: 16,
                                marginRight: 16,
                                fontWeight: 600,
                              }}
                            >
                              There are no users in the project with the VIEW permission for this
                              document type.
                            </Typography>
                          ) : null}
                        </TextField>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: 8,
                            marginBottom: 8,
                            minWidth: 320,
                          }}
                        >
                          <span style={{ fontWeight: 500 }}>
                            Assigned {getAssignedContractorLabel()}:
                          </span>{' '}
                          <AssignedUserText
                            name={contractorUser?.name}
                            company={
                              contractorUser?.company?.name ||
                              companies.find((c) => c.id === contractorUser?.companyId)?.name
                            }
                          />
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => setEditContractor(true)}
                          >
                            EDIT
                          </Button>
                        </div>
                      )}
                      {editArchitect || !architect ? (
                        <TextField
                          select
                          variant="outlined"
                          label={`Assign ${getAssignedArchitectLabel()}`}
                          fullWidth
                          value={architect}
                          onChange={(e) => setArchitect(e.target.value)}
                          style={{ marginBottom: 8, marginTop: 8 }}
                        >
                          {assignableUsers.sort(sortAssignableUsers).map((item) => {
                            return (
                              <MenuItem key={item.id} value={item.userId}>
                                <AssignedUserText
                                  name={item.user!.name}
                                  company={
                                    item.user?.company?.name ||
                                    companies.find((c) => c.id === item.user?.companyId)?.name
                                  }
                                />
                              </MenuItem>
                            );
                          })}
                          {assignableUsers.length === 0 ? (
                            <Typography
                              align="center"
                              style={{
                                maxWidth: 300,
                                marginLeft: 16,
                                marginRight: 16,
                                fontWeight: 600,
                              }}
                            >
                              There are no users in the project with the VIEW permission for this
                              document type.
                            </Typography>
                          ) : null}
                        </TextField>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: 8,
                            marginBottom: 8,
                            minWidth: 320,
                          }}
                        >
                          <span style={{ fontWeight: 500 }}>
                            Assigned {getAssignedArchitectLabel()}:
                          </span>{' '}
                          <AssignedUserText
                            name={architectUser?.name}
                            company={
                              architectUser?.company?.name ||
                              companies.find((c) => c.id === architectUser?.companyId)?.name
                            }
                          />
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => setEditArchitect(true)}
                          >
                            EDIT
                          </Button>
                        </div>
                      )}
                    </>
                  );
                }
                if (field.type === 'mc_responsibility') {
                  return (
                    <>
                      {editContractor || !generalContractor ? (
                        <TextField
                          select
                          variant="outlined"
                          label={`Assign ${getAssignedContractorLabel()}`}
                          fullWidth
                          value={generalContractor}
                          onChange={(e) => setGeneralContractor(e.target.value)}
                          style={{ marginBottom: 8, marginTop: 8 }}
                        >
                          {assignableUsers.map((item) => {
                            return (
                              <MenuItem key={item.id} value={item.user!.id}>
                                <AssignedUserText
                                  name={item.user!.name}
                                  company={
                                    item.user?.company?.name ||
                                    companies.find((c) => c.id === item.user?.companyId)?.name
                                  }
                                />
                              </MenuItem>
                            );
                          })}
                          {assignableUsers.length === 0 ? (
                            <Typography
                              align="center"
                              style={{
                                maxWidth: 300,
                                marginLeft: 16,
                                marginRight: 16,
                                fontWeight: 600,
                              }}
                            >
                              There are no users in the project with the VIEW permission for this
                              document type.
                            </Typography>
                          ) : null}
                        </TextField>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: 8,
                            marginBottom: 8,
                            minWidth: 320,
                          }}
                        >
                          <span style={{ fontWeight: 500 }}>
                            Assigned {getAssignedContractorLabel()}:
                          </span>{' '}
                          <AssignedUserText
                            name={contractorUser?.name}
                            company={
                              contractorUser?.company?.name ||
                              companies.find((c) => c.id === contractorUser?.companyId)?.name
                            }
                          />
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => setEditContractor(true)}
                          >
                            EDIT
                          </Button>
                        </div>
                      )}

                      <div>Assign {getAssignedArchitectLabel()}:</div>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setAssignUsersDialogOpen(true)}
                        style={{ marginTop: 4 }}
                      >
                        Select Assigned Users
                      </Button>
                      <List>
                        {designPackageAssignees
                          .filter((a) => !!a)
                          .map((user) => (
                            <ListItem key={user?.id}>
                              <AssignedUserText
                                name={user?.name}
                                company={
                                  user?.company?.name ||
                                  companies.find((c) => c.id === user?.companyId)?.name
                                }
                              />
                            </ListItem>
                          ))}
                      </List>
                    </>
                  );
                }
                if (field.type === 'impact') {
                  const isCurrency = field.label === 'Impact to Cost';
                  return (
                    <div key={field.label} style={{ display: 'flex' }}>
                      {isCurrency ? (
                        <CurrencyTextField
                          variant="outlined"
                          margin="none"
                          size="small"
                          fullWidth
                          rows="5"
                          id="project-number"
                          label={field.label}
                          disabled={costTBD}
                          currencySymbol="$"
                          outputFormat="string"
                          decimalCharacter="."
                          digitGroupSeparator=","
                          required={!field.optional}
                          autoFocus={index === 0}
                          value={field.value ? parseInt(field.value, 10) / 100 : undefined}
                          className={classes.textArea}
                          onBlur={(e: any, val: string) => {
                            if (val === '') field.setValue(undefined);
                            else field.setValue((Number(val) * 100).toString());
                          }}
                          style={{ width: '100%', flex: 3 }}
                        />
                      ) : (
                        <TextField
                          variant="outlined"
                          margin="none"
                          size="small"
                          fullWidth
                          id="project-number"
                          label={field.label}
                          disabled={timeTBD}
                          required={!field.optional}
                          autoFocus={index === 0}
                          value={field.value}
                          className={classes.field}
                          onChange={(e) => {
                            field.setValue(removeNewlines(e.target.value));
                          }}
                          style={{ flex: 3 }}
                        />
                      )}
                      {documentType !== DocumentTemplateType.ChangeOrders && (
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            width: '100%',
                            fontSize: '14px',
                            color: '#464546',
                          }}
                        >
                          <Checkbox
                            checked={isCurrency ? costTBD : timeTBD}
                            onChange={(e) =>
                              isCurrency
                                ? setCostTBD(e.target.checked)
                                : setTimeTBD(e.target.checked)
                            }
                          />
                          TBD
                        </div>
                      )}
                    </div>
                  );
                }
                if (field.type === 'change') {
                  const isCurrency = field.label === 'Impact to Cost';
                  return (
                    <>
                      <Typography style={{ fontWeight: 500, marginTop: 8 }}>
                        {field.label}
                      </Typography>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
                        <Typography style={{ minWidth: 90 }}>Current:</Typography>
                        <Typography>
                          {isCurrency
                            ? formatMoney(originalCost)
                            : `${formatDays(originalTime)} from NTP`}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ minWidth: 90 }}>Difference:</Typography>
                        {isCurrency ? (
                          <CurrencyTextField
                            variant="outlined"
                            margin="none"
                            size="small"
                            fullWidth
                            rows="5"
                            id="project-number"
                            currencySymbol="$"
                            outputFormat="string"
                            decimalCharacter="."
                            digitGroupSeparator=","
                            required={!field.optional}
                            autoFocus={index === 0}
                            value={field.value ? parseInt(field.value, 10) / 100 : undefined}
                            className={classes.textArea}
                            onBlur={(e: any, val: string) => {
                              if (val === '') field.setValue(undefined);
                              else field.setValue((Number(val) * 100).toString());
                            }}
                            InputProps={{ style: { height: 32 } }}
                            style={{ width: '100%', flex: 3 }}
                          />
                        ) : (
                          <>
                            <TextField
                              variant="outlined"
                              margin="none"
                              size="small"
                              fullWidth
                              id="project-number"
                              required={!field.optional}
                              autoFocus={index === 0}
                              value={field.value}
                              className={classes.field}
                              onChange={(e) => {
                                field.setValue(removeNewlines(e.target.value));
                              }}
                              InputProps={{ style: { height: 32 } }}
                              style={{ flex: 3 }}
                            />
                            <Typography style={{ marginLeft: 8 }}>days</Typography>
                          </>
                        )}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ minWidth: 90 }}>New:</Typography>
                        {isCurrency ? (
                          <CurrencyTextField
                            variant="outlined"
                            margin="none"
                            size="small"
                            fullWidth
                            rows="5"
                            id="project-number"
                            currencySymbol="$"
                            outputFormat="string"
                            decimalCharacter="."
                            digitGroupSeparator=","
                            required={!field.optional}
                            autoFocus={index === 0}
                            value={newCost / 100}
                            className={classes.textArea}
                            onBlur={(e: any, val: string) => {
                              // if (val === '') field.setValue(undefined);
                              // else field.setValue((Number(val) * 100).toString());
                              setChangeOrdersValue(Number(val) * 100 - originalCost);
                            }}
                            InputProps={{ style: { height: 32 } }}
                            style={{ width: '100%', flex: 3 }}
                          />
                        ) : (
                          <>
                            <TextField
                              variant="outlined"
                              margin="none"
                              size="small"
                              fullWidth
                              id="project-number"
                              required={!field.optional}
                              autoFocus={index === 0}
                              value={newTime}
                              className={classes.field}
                              onChange={(e) => {
                                setChangeOrdersDaysAdded(Number(e.target.value) - originalTime);
                              }}
                              InputProps={{ style: { height: 32 } }}
                              style={{ flex: 3 }}
                            />
                            <Typography style={{ marginLeft: 8 }}>days</Typography>
                          </>
                        )}
                      </div>
                    </>
                  );
                }
                if (field.type === 'autocomplete') {
                  if (documentType === DocumentTemplateType.AsBuilt) {
                    return (
                      <Autocomplete
                        key={field.label}
                        fullWidth
                        options={existingDrawingsDocuments.sort((a, b) =>
                          ascendingComparator(a, b, 'pageNumber'),
                        )}
                        getOptionLabel={(option) =>
                          `${option.sheetNumber || 'N/A'}  ${option.title || ''}`
                        }
                        onChange={(e, value) => setInputDrawingsDocumentId(value?.id)}
                        autoComplete
                        filterOptions={(drawings, state) =>
                          state.inputValue.length >= 1
                            ? drawings.filter(
                                (x) =>
                                  x.sheetNumber
                                    ?.toLowerCase()
                                    .includes(removeSpacing(state.inputValue.toLowerCase())) ||
                                  x.title
                                    ?.toLowerCase()
                                    .includes(removeSpacing(state.inputValue.toLowerCase())),
                              )
                            : drawings
                        }
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              label="Drawings Sheet"
                            />
                          );
                        }}
                        value={existingDrawingsDocuments.find(
                          (doc) => doc.id === inputDrawingsDocumentId,
                        )}
                      />
                    );
                  }
                  return (
                    <Autocomplete
                      key={field.label}
                      fullWidth
                      options={submittalCodes}
                      getOptionLabel={(option) => option.code}
                      disabled={isDocumentDetails}
                      inputValue={addSpacingIncremental(inputSection)}
                      onInputChange={(e, value) => setInputSection(removeSpacing(value))}
                      onChange={(e, value) => setInputSection(value?.code)}
                      autoComplete
                      filterOptions={(specs, state) =>
                        state.inputValue.length >= 2
                          ? specs.filter((x) =>
                              x.code
                                ?.toLowerCase()
                                .startsWith(removeSpacing(state.inputValue.toLowerCase())),
                            )
                          : []
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Specification Section"
                            InputProps={
                              isDocumentDetails
                                ? {
                                    endAdornment: (
                                      <span style={{ whiteSpace: 'nowrap' }}>
                                        {currentDocument?.submittalSubparagraphNumber
                                          ? `-${
                                              currentDocument?.submittalParagraphNumber
                                            }-${getNextSubmittalParagraphNumberForRequirement()}`
                                          : `-${getNextSubmittalParagraphNumberForRequirement()}`}
                                      </span>
                                    ),
                                  }
                                : params.InputProps
                            }
                          />
                        );
                      }}
                      noOptionsText={getSpecificationsNoOptionsText()}
                      renderOption={(option, state) => (
                        <Typography style={{ width: '100%' }}>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <b>{addSpacingIncremental(option.code)}</b>
                            {option.is_project ? <Star fontSize="small" /> : null}
                          </div>
                          {option.description}
                        </Typography>
                      )}
                      value={submittalCodes.find((x) => x.code === removeSpacing(inputSection))}
                    />
                  );
                }
                if (field.type === 'priority')
                  return (
                    <>
                      {!isPlaceholder ? (
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            width: '100%',
                            fontSize: '14px',
                            color: '#464546',
                            marginTop: 8,
                            marginBottom: 8,
                          }}
                        >
                          <Checkbox
                            checked={forInformationOnly}
                            onChange={(e) => {
                              setForInformationOnly(e.target.checked);
                              if (e.target.checked)
                                setPriority(DocumentPriorityType.ForInformationOnly);
                              else setPriority(DocumentPriorityType.Medium);
                            }}
                            style={{ paddingLeft: 0 }}
                          />
                          For Information Only.
                          <br />
                          No Response Needed.
                        </div>
                      ) : null}
                      {documentType === DocumentTemplateType.Task ? (
                        <>
                          <div style={{ fontSize: '12px', lineHeight: '15px', marginTop: 4 }}>
                            {field.label}
                          </div>
                          <TextField
                            select
                            variant="outlined"
                            fullWidth
                            disabled={forInformationOnly}
                            value={priority}
                            onChange={(e) => setPriority(e.target.value as DocumentPriorityType)}
                            SelectProps={{
                              SelectDisplayProps: { style: { paddingTop: 12, paddingBottom: 12 } },
                            }}
                            style={{ marginBottom: 8, marginTop: 8 }}
                          >
                            <MenuItem value={DocumentPriorityType.High}>High</MenuItem>
                            <MenuItem value={DocumentPriorityType.Medium}>Medium</MenuItem>
                            <MenuItem value={DocumentPriorityType.Low}>Low</MenuItem>
                            {forInformationOnly && (
                              <MenuItem value={DocumentPriorityType.ForInformationOnly}>
                                For Information Only
                              </MenuItem>
                            )}
                          </TextField>
                        </>
                      ) : null}
                    </>
                  );
                if (field.type === '') return <div key={generateUniqueId()} />;
              },
            )}
            <Button
              color="primary"
              variant="contained"
              onClick={() => setAssociationDialogOpen(true)}
              fullWidth
              style={{ height: '100%', marginTop: 24, marginBottom: 8, alignSelf: 'center' }}
            >
              Define Notification List
            </Button>
            <InlineNotificationList
              associatedUsers={associatedUsers}
              associatedGroups={associatedGroups}
            />
            {!!documentType && !docTypesNoAssociateDocuments.includes(documentType) && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => setAssociateDocumentsDialogOpen(true)}
                fullWidth
                style={{ height: '100%', marginTop: 8, marginBottom: 8, alignSelf: 'center' }}
              >
                Associate Existing Documents
              </Button>
            )}
            {documentFileTrees
              .filter((t) => t.documents.filter((doc) => doc.checked).length > 0)
              .map((tree) => (
                <div key={tree.type} style={{ marginBottom: 8 }}>
                  <Typography style={{ fontWeight: 500 }}>
                    {getUserFriendlyDocumentTemplateNamePlural(tree.type)}
                  </Typography>
                  {tree.documents
                    .filter((doc) => doc.checked)
                    .map((doc) => (
                      <div
                        key={doc.id}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="body2" style={{ lineHeight: '18px' }}>
                          {doc.title}
                        </Typography>
                        <IconButton
                          onClick={() => removeDocumentAssociation(tree.type, doc.id)}
                          style={{ padding: 0 }}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      </div>
                    ))}
                </div>
              ))}
            {!isLoading ? (
              <Box className={classes.inline}>
                <Button onClick={closeClicked} variant="outlined" className={classes.button}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isInvalid}
                  onClick={handleSubmit}
                  className={classes.button}
                  variant="contained"
                >
                  Create
                </Button>
              </Box>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '6px',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {loadingText}
                </Typography>
                {loadingText.includes('Uploading') ? (
                  <>
                    <Typography variant="body1" style={{ fontSize: 14, marginTop: 4 }}>
                      {uploadProgress}%
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress}
                      style={{ width: '100%', height: 6, marginBottom: 8, marginTop: 4 }}
                    />
                  </>
                ) : (
                  <CircularLoader style={{ margin: '4px 0px 4px 0px' }} />
                )}

                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  DO NOT NAVIGATE AWAY FROM THIS PAGE
                </Typography>
              </div>
            )}
          </Box>
        </form>
      </Popover>
      <Snackbar
        open={snackOpen}
        autoHideDuration={5000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackOpen(false)} severity="error">
          Failed to create files. Contact Centerline support if this issue persists. You will need
          to create another document.
        </Alert>
      </Snackbar>
      <ManagePermissionsDialog
        dialogOpen={associationDialogOpen}
        closeDialog={() => {
          // repositions popover window to accommodate for InlineNotificationList
          window.dispatchEvent(new CustomEvent('resize'));
          setAssociationDialogOpen(false);
        }}
        type={ManagePermissionsDialogType.AssociatedUsers}
        associatedGroups={associatedGroups}
        associatedUsers={associatedUsers}
        setAssociatedUsers={setAssociatedUsers}
        setAssociatedGroups={setAssociatedGroups}
      />
      <AssociateDocumentDialog
        open={associateDocumentsDialogOpen}
        handleClose={() => setAssociateDocumentsDialogOpen(false)}
        documents={documentFileTrees}
        setDocuments={setDocumentFileTrees}
      />
      <SelectCompanyUsersDialog
        open={assignUsersDialogOpen}
        handleClose={() => setAssignUsersDialogOpen(false)}
        existingUserIds={[]}
        eligibleUserIds={assignableUsers.map((pU) => pU.userId)}
        allowSelectingCurrentUser
        submit={(selectedUsers) => setArchitects(selectedUsers)}
      />
      <ASIConfirmationDialog
        open={asiDialogOpen}
        handleClose={() => setAsiDialogOpen(false)}
        newDocumentId={newDocumentId}
      />
    </MuiPickersUtilsProvider>
  );
}

export default AddDocumentDialog;
