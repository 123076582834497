import _, { Dictionary } from 'lodash';
import {
  getCurrentSecurityGroup,
  getSecurityGroupsState,
  getSubscriberSecurityGroupState,
} from '../features/security/selectors';
import { getTemplateId, getTemplates } from '../models/api/templates';
import store from '../store/index';
import {
  DocumentTemplateType,
  INotification,
  IProjectUser,
  IUser,
  SecurityPermissionLevel,
} from '../api-client/autogenerated';
import { getDocumentsType } from '../features/documents/selectors';
import { ManagePermissionsDialogType } from '../main-components/design/ManagePermissionsDialog';
import { getProjectState, getProjectUsersState } from '../features/project/selectors';
import { canViewDesign, getProjectUsers } from './utils';
import { getUserState } from '../features/user/selectors';

let templates: Dictionary<string> | null = null;
try {
  setTimeout(() => getTemplates().then((t) => (templates = t)), 100);
} catch (error: any) {
  console.log(error);
}

export const getDocPermission = () => {
  const state = store.getState();
  const user = getUserState(state);
  if (hasAdminPermissions()) return SecurityPermissionLevel.NUMBER_4;
  const documentPermission = state.document.permissions.find((p) => p.userId === user.id)
    ?.securityPermissionLevel;
  const documentTypePermission = getDocumentTypePermission();

  return Math.max(documentPermission ?? 0, documentTypePermission ?? 0);
};

export const getDocumentTypePermission = () => {
  const state = store.getState();
  const security = getCurrentSecurityGroup(state);
  if (hasAdminPermissions()) return SecurityPermissionLevel.NUMBER_4;
  if (!templates || !security || !state.documents?.type) return null;
  return security.securityGroupDocumentTemplateList?.find(
    (group) => group.documentTemplateId === templates![state.documents?.type!],
  )?.securityPermissionLevel;
};

export const hasAdminPermissions = (user?: IUser) => {
  const state = store.getState();
  const userTemp = user ? user : state.user.user;
  if (userTemp) {
    if (userTemp.isSiteAdmin) return true;
    if (userTemp.adminOfSubscriberId) {
      if (!state.project.project) return false;
      return userTemp.adminOfSubscriberId === state.project.project.subscriberId;
    }
  }
  return false;
};

export const isLoggedIn = () => {
  const state = store.getState();
  if (state.user) {
    if (state.user.user) {
      if (state.user.user.id !== '') return true;
    }
  }
  return false;
};

export const hasCompletedConstructionSetup = () => {
  const state = store.getState();
  if (state.project?.project?.defaultArchitectUserId) {
    return true;
  }
  return false;
};

export const getProjectUserPermissions = () => {
  const state = store.getState();
  if (
    !state.project.project?.projectUserList ||
    !state.document.document ||
    state.document.permissions.length === 0
  )
    return [];
  return getProjectUsers(state.project.project, false).map((projectUser) => {
    const permission = state.document.permissions.find(
      ({ userId }) => userId === projectUser.userId,
    )?.securityPermissionLevel;
    return { projectUser, permission };
  });
};

export const getProjectUserPermissionsForAdditionalReview = async () => {
  const state = store.getState();
  if (
    !state.project.project?.projectUserList ||
    !state.document.document ||
    state.security.subscriberSecurity.length === 0
  )
    return [];
  const additionalReviewTemplateId = templates
    ? templates[DocumentTemplateType.AdditionalReview]
    : await getTemplateId(DocumentTemplateType.AdditionalReview);
  return getProjectUsers(state.project.project, false).map((projectUser) => {
    const permission = state.security.subscriberSecurity
      .find((s) => s.id === projectUser.securityGroupId)
      ?.securityGroupDocumentTemplateList?.find(
        (list) => list.documentTemplateId === additionalReviewTemplateId,
      )?.securityPermissionLevel;
    return { projectUser, permission };
  });
};

export const getUserPermissionForCurrentDocType = (pUser: IProjectUser) => {
  const state = store.getState();
  const docType = getDocumentsType(state);
  if (!docType) return SecurityPermissionLevel.NUMBER_4;
  if (!templates) return null;
  const templateId = templates[docType];
  const allSecurityGroups = _.uniqBy(
    [...getSubscriberSecurityGroupState(state), ...getSecurityGroupsState(state)],
    (group) => group.id,
  );
  const security = allSecurityGroups
    .find((group) => group.id === pUser.securityGroupId)
    ?.securityGroupDocumentTemplateList?.find((list) => list.documentTemplateId === templateId);
  return security?.securityPermissionLevel;
};

export const hasPermissionToView = (
  type: ManagePermissionsDialogType,
  user?: IProjectUser | IUser | null,
): boolean | null | undefined => {
  const userTemp = user as IUser;
  const state = store.getState();
  const project = getProjectState(state);
  const projectUsers = getProjectUsersState(state);
  if (type === ManagePermissionsDialogType.Design) {
    //user parameter can be assumed to be of type IUser.
    return (
      user &&
      (userTemp.adminOfSubscriberId === project?.subscriberId ||
        userTemp.isSiteAdmin ||
        canViewDesign(
          project?.subscriber?.productPackage,
          projectUsers.find(({ userId }) => userId === userTemp.id)?.securityGroup,
        ))
    );
  } else if (type === ManagePermissionsDialogType.AssociatedUsers) {
    //If user is of type IUser, find the IProjectUser object in projectUsers. Otherwise, just keep using user.
    const pUser: IProjectUser | undefined = user!.hasOwnProperty('userId')
      ? (user as IProjectUser)
      : projectUsers.find((x) => x.userId === user!.id);
    if (pUser) {
      const permission = getUserPermissionForCurrentDocType(pUser);
      return (permission !== null && permission !== undefined) || hasAdminPermissions(pUser.user!);
    }
    return false;
  } else if (
    type === ManagePermissionsDialogType.None ||
    type === ManagePermissionsDialogType.PartiesPresent
  ) {
    return true;
  }
  return true;
};

export const getProjectNameFromNotification = (notification?: INotification | null) => {
  if (!notification) return undefined;
  return (
    notification.project?.name ||
    notification.document?.project?.name ||
    store
      .getState()
      .projects.projects.find(
        (p) =>
          p.id ===
          (notification.projectId ||
            notification.document?.projectId ||
            notification.designFile?.projectId ||
            notification.designFileShare?.designFile?.projectId),
      )?.name
  );
};
