import React from 'react';

const RightArrowIconWhite = (props: any) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00391 1.83789C5.71094 2.13086 5.74023 2.57031 6.00391 2.86328L9.54883 6.20312H1.14062C0.730469 6.20312 0.4375 6.52539 0.4375 6.90625V7.84375C0.4375 8.25391 0.730469 8.54688 1.14062 8.54688H9.54883L6.00391 11.916C5.74023 12.209 5.74023 12.6484 6.00391 12.9414L6.64844 13.5859C6.94141 13.8496 7.38086 13.8496 7.64453 13.5859L13.3574 7.87305C13.6211 7.60938 13.6211 7.16992 13.3574 6.87695L7.64453 1.19336C7.38086 0.929688 6.94141 0.929688 6.64844 1.19336L6.00391 1.83789Z"
      fill="#FFFFFF"
    />
  </svg>
);

export default RightArrowIconWhite;
