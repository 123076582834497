import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { HighlightOffRounded } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { FileNode } from '../../api-client/autogenerated';
import { DESIGN_PATH_CHARACTER_LIMIT_FOLDER } from '../../scripts/constants';
import { useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';

interface CreateFolderDialogProps {
  open: boolean;
  handleClose: () => void;
  activeFolder: FileNode | undefined;
  handleSubmit: (folderName: string) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    marginBottom: '8px',
    paddingLeft: 8,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '-16px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: '7px',
    marginLeft: '7px',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  paperRoot: {
    minWidth: 500,
  },
}));

export default function CreateFolderDialog(props: CreateFolderDialogProps) {
  const { open, handleClose, activeFolder, handleSubmit } = props;
  const [folderName, setFolderName] = useState('');
  const classes = useStyles();

  const project = useSelector(getProjectState);

  const newPath = `${activeFolder?.fullKey}${folderName}`;

  const isPathTooLong = newPath.length > DESIGN_PATH_CHARACTER_LIMIT_FOLDER;

  useEffect(() => {
    if (!open) setFolderName('');
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      className={classes.root}
      PaperProps={{ classes: { root: classes.paperRoot } }}
    >
      <DialogTitle>
        <div className={classes.titleContainer}>
          <span className={classes.title}>Create Folder</span>
        </div>
        <IconButton
          style={{ right: '24px', top: '24px', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.contentContainer}>
        <p>
          Folder will be created in{' '}
          <strong style={{ color: '#0947B9' }}>
            {project?.name}
            {activeFolder ? activeFolder.fullKey : '/'}
          </strong>
        </p>
        <Grid container spacing={2} justify="center">
          <Grid item xs={9}>
            <TextField
              autoFocus
              variant="outlined"
              id="folder-name"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              name="textmask"
              fullWidth
              label="New Folder Name"
            />
          </Grid>
          {isPathTooLong ? (
            <Grid item xs={12}>
              <p style={{ textAlign: 'center', margin: 0, color: 'red' }}>
                The resulting file path will exceed the character limit of{' '}
                {DESIGN_PATH_CHARACTER_LIMIT_FOLDER}
              </p>
            </Grid>
          ) : null}
          <Grid item container justify="flex-end" xs={12}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              disabled={isPathTooLong}
              onClick={() => {
                handleSubmit(
                  activeFolder
                    ? activeFolder.fullKey.concat(folderName.trim())
                    : '/'.concat(folderName.trim()),
                );
                handleClose();
              }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
