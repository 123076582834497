import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { HighlightOffRounded } from '@material-ui/icons';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ISecurityGroup } from '../../api-client/autogenerated';

interface EditUserDialogProps {
  dialogOpen: boolean;
  handleClose: () => void;
  type?: string;
  id: string;
  name?: string;
  description?: string;
  email?: string;
  website?: string;
  securityGroups: ISecurityGroup[];
  securityGroupId: undefined | string;
  updateUserRow: (
    id: string,
    name: string | undefined,
    description: string | undefined,
    email: string | undefined,
    website: string | undefined,
    securityGroupId: string | undefined,
  ) => void;
}

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '80px',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
    minWidth: 500,
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'center',
    color: '#0947B9',
    textTransform: 'uppercase',
    justifySelf: 'center',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 8,
  },
  textField: {
    maxWidth: 350,
  },
  submit: {
    marginTop: 16,
    marginBottom: 24,
    maxWidth: 240,
  },
});

export default function EditUserDialog(props: EditUserDialogProps) {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    dialogOpen,
    handleClose,
    type = 'add',
    // eslint-disable-next-line
    id,
    name,
    description,
    email,
    website,
    securityGroupId,
    securityGroups,
    updateUserRow,
  } = props;

  const [inputName, setInputName] = useState('');
  const [inputDescription, setInputDescription] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputWebsite, setInputWebsite] = useState('');
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (type === 'update') {
      setInputName(name!);
      setInputDescription(description!);
      setInputEmail(email!);
      setInputWebsite(website!);
      setSelectedGroupId(securityGroupId);
    }
  }, [securityGroupId]);

  const submitDetails = () => {
    if (type === 'update') {
      updateUserRow(id, inputName, inputDescription, inputEmail, inputWebsite, selectedGroupId);
      handleClose();
    }
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose}>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title} align="center">
          {type === 'add' ? 'Add User' : 'Update User'}
        </Typography>
        <IconButton
          id="close-button"
          style={{ right: '20px', top: '5px', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <div style={{ height: '17%' }} />
      <DialogContent>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            disabled
            id="name"
            label="Name"
            name="name"
            autoComplete="email"
            autoFocus
            className={classes.textField}
            value={inputName}
            onChange={(e) => setInputName(e.target.value)}
          />
          {/*<TextField*/}
          {/*  variant="outlined"*/}
          {/*  margin="normal"*/}
          {/*  fullWidth*/}
          {/*  name="Description"*/}
          {/*  label="Description"*/}
          {/*  id="Description"*/}
          {/*  autoComplete="current-password"*/}
          {/*  className={classes.textField}*/}
          {/*  value={inputDescription}*/}
          {/*  onChange={(e) => setInputDescription(e.target.value)}*/}
          {/*/>*/}
          <TextField
            variant="outlined"
            margin="normal"
            disabled
            fullWidth
            label="Email"
            id="email"
            name="email"
            autoFocus
            className={classes.textField}
            value={inputEmail}
            onChange={(e) => setInputEmail(e.target.value)}
          />
          {/*<TextField*/}
          {/*  variant="outlined"*/}
          {/*  margin="normal"*/}
          {/*  fullWidth*/}
          {/*  disabled*/}
          {/*  id="website"*/}
          {/*  name="website"*/}
          {/*  label="Website"*/}
          {/*  className={classes.textField}*/}
          {/*  value={inputWebsite}*/}
          {/*  onChange={(e) => setInputWebsite(e.target.value)}*/}
          {/*/>*/}
          <FormControl
            variant="outlined"
            className={classes.textField}
            fullWidth
            margin="none"
            style={{ marginTop: 16 }}
          >
            <InputLabel id="demo-simple-select-outlined-label">Security Group</InputLabel>
            <Select
              id="security-group-dropdown"
              value={selectedGroupId}
              style={{ minWidth: 300, marginRight: 24 }}
              onChange={(event) => setSelectedGroupId(event.target.value as string)}
              margin="none"
              fullWidth
            >
              {securityGroups?.map((group) => {
                return (
                  <MenuItem key={group.id} value={group.id!}>
                    {group.name!}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            fullWidth
            id="add-update-button"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitDetails}
          >
            {type === 'add' ? 'Add' : 'Update'}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
