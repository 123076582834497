import { ICompany, IInsertionCompany } from '../../api-client/autogenerated';
import { ApiClient, getQueryFilterString, QueryFilter } from '../../api-client/api-client';

// GET //

export const getCompanies = async (limit = 50): Promise<ICompany[]> => {
  return (await ApiClient.getAllCompanies({ pageSize: limit })).data.results;
};

export const getCompaniesByProjectId = async (projectId: string): Promise<ICompany[]> => {
  return (await ApiClient.getCompaniesByProjectId({ projectId })).data;
};

export const getCompanyById = async (companyId: string): Promise<ICompany> => {
  return (await ApiClient.getCompanyById({ companyId })).data;
};

export const getCompanyByName = async (name: string): Promise<ICompany> => {
  const filter: QueryFilter = [{ whereColumn: 'name', whereOperator: '=', whereValue: name }];

  const { results } = (
    await ApiClient.getAllCompanies({ filterByObject: getQueryFilterString(filter) })
  ).data;
  if (results.length === 1) {
    return results[0];
  }
  return {} as ICompany;
};

export const insertCompany = async (company: IInsertionCompany): Promise<ICompany> => {
  const response = await ApiClient.insertCompany({ iInsertionCompany: company });
  return response.data;
};

// PUT - NOT IMPLEMENTED //

// export const putCompany = (companyId: string, company: ICompany): Promise<number> => {
//   return API.put(`${URL}/${companyId}`, company).then((res) => res.status);
// };
