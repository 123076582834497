import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import NavAppbar from '../nav-top/NavAppbar';
import PageTitle from '../page-title/PageTitle';
import SettingsAccountForm from './settings/SettingsAccountForm';
import SettingsPasswordForm from './settings/SettingsPasswordForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    paper: {
      padding: theme.spacing(2),
      height: '100%',
      boxShadow: '1px 1px 10px 1px rgba(0, 0, 0, 0.1)',
    },
    margin: {
      margin: theme.spacing(1),
      height: '70%', // make buttons same height
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
      overflowX: 'hidden',
    },
    alignLeft: {
      textAlign: 'left',
    },
  }),
);

interface SettingsPageProps {
  isBiddingPortal?: boolean;
}

export default function SettingsPage(props: SettingsPageProps) {
  const classes = useStyles();
  const { isBiddingPortal } = props;

  return (
    <main className={classes.content}>
      <NavAppbar isBiddingPortal={isBiddingPortal} />
      <Grid container>
        <Grid item xs={10}>
          <PageTitle title="Settings" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={10}>
          <Paper className={classes.paper}>
            <h2 className={classes.alignLeft}>Account Settings</h2>
            <SettingsAccountForm isBiddingPortal={isBiddingPortal} />
            <Divider light style={{ marginBottom: 25, marginTop: 10 }} />
            <h2 className={classes.alignLeft}>Reset Password</h2>
            <SettingsPasswordForm />
          </Paper>
        </Grid>
      </Grid>
    </main>
  );
}
