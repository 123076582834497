import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ViewSDKClient from '../main-components/pdf-viewer/ViewSDKClient';
import { PdfMatchParams } from '../scripts/utils';
import { getFileUrlById } from '../models/api/files';

function useScript(src: string) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');
  useEffect(() => {
    // Allow falsy src value if waiting on other data needed for
    // constructing the script URL passed to this hook.
    if (!src) {
      setStatus('idle');
      return;
    }
    // Fetch existing script element by src
    // It may have been added by another intance of this hook
    let script: any = document.querySelector(`script[src="${src}"]`);
    if (!script) {
      // Create script
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', 'loading');
      // Add script to document body
      document.body.appendChild(script);
      // Store status in attribute on script
      // This can be read by other instances of this hook
      const setAttributeFromEvent = (event: any) => {
        script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
      };
      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      // Grab existing script status from attribute and set to state.
      setStatus(script.getAttribute('data-status'));
    }
    // Script event handler to update status in state
    // Note: Even if the script already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const setStateFromEvent = (event: any) => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };
    // Add event listeners
    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);
    // Remove event listeners on cleanup
    // eslint-disable-next-line consistent-return
    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [src]);

  return status;
}

export default function BiddingPDFViewer() {
  const params = useParams<PdfMatchParams>();

  const fetchUrl = () =>
    getFileUrlById(params.fileId)
      .then((result) => {
        return { fileName: result.file?.name, link: result.downloadLink! };
      })
      .catch(() => {
        return {
          fileName: 'Bodea Brochure',
          link: 'https://acrobatservices.adobe.com/view-sdk-demo/PDFs/Bodea Brochure.pdf',
        };
      });

  const showPdf = (url: string, fileName: string) => {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(
        'adobe-dc-view',
        {
          showAnnotationTools: false,
          showLeftHandPanel: true,
          showPageControls: true,
          showDownloadPDF: false,
          showPrintPDF: false,
        },
        url,
        fileName,
      );
    });
  };

  useScript('https://acrobatservices.adobe.com/view-sdk/viewer.js');

  useEffect(() => {
    fetchUrl().then(({ fileName, link }) => showPdf(link, fileName || 'File Name Not Found'));
  }, []);

  return <div id="adobe-dc-view" style={{ height: '100vh' }} />;
}
