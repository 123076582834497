import { EventEmitter } from 'events';

export const emitter = new EventEmitter();

emitter.setMaxListeners(0);

export enum Events {
  ExpandRows = 'EXPAND_ROWS',
  CloseRows = 'CLOSE_ROWS',
}
