import React, { MutableRefObject, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import '../dashboard/calendar/sass/styles.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  DocumentTemplateType,
  INumberedDocumentView,
  IProjectCalendarEvent,
} from '../../api-client/autogenerated';
import CircularLoader from '../loader/CircularLoader';
import {
  documentTypeToUrl,
  getUserFacingDocumentStatus,
  getUserFriendlyDocumentTemplateNameSingular,
  parseDate,
} from '../../scripts/utils';
import { addSpacing } from '../document-index/DocumentIndexUtils';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProjectId } from '../../features/project/selectors';
import dayjs from 'dayjs';
import { ICalCalendar } from 'ical-generator';

interface MonthDayCalendarProps {
  soonestDueDocuments: INumberedDocumentView[];
  projectEvents: IProjectCalendarEvent[];
  loading: boolean;
  calendarRef: MutableRefObject<any>;
  iCalendar: MutableRefObject<ICalCalendar>;
}

interface EventType {
  id: string;
  projectId: string;
  title: string;
  start: Date;
  end: Date;
  allDay?: boolean;
  source?: string;
  company?: string;
  address?: string;
  type?: string;
  parentId?: string;
  parentType?: string;
}

const localizer = momentLocalizer(moment);

const getTitleColor = (title: any) => {
  switch (title) {
    case 'Approved':
      return '#128750';
    case 'Rejected':
      return '#ED3F26';
    default:
      return '#464546';
  }
};

const EventComponent = (properties: any) => (
  <div
    style={{
      background: '#EDECEC',
      color: '#464546',
      lineHeight: '17px',
    }}
  >
    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '15px' }}>
      {properties.event.address}
    </div>
    <div
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontWeight: 'bold',
        color: getTitleColor(properties.event.title),
      }}
    >
      {properties.event.title}
    </div>
    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 'bold' }}>
      {properties.event.source}
    </div>
    <div
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      {properties.event.company}
    </div>
  </div>
);

const useStyles = makeStyles({
  root: {
    fontFamily: 'Sans-Serif',
    font: 'Roboto',
    textTransform: 'capitalize',
    width: '100%',
    marginTop: 20,
  },

  calendarStyling: {
    fontSize: '13px',
    padding: '0 0px',
    width: '100%',
    margin: 'auto',
    minHeight: 'calc(100vh - 100px)',
    maxHeight: 'max-content',
    height: 'calc(100vh - 100px)',
  },
});

const eventStyler = () => {
  return { style: { backgroundColor: '#EDECEC', borderLeft: '3px #4380ED solid' } };
};

const formats = {
  eventTimeRangeFormat: () => {
    return '';
  },
};

export default function MonthDayCalendar(props: MonthDayCalendarProps) {
  const classes = useStyles();
  const history = useHistory();
  const projectId = useSelector(getProjectId);
  const [open, setOpen] = useState(false);
  const [dialogueContent, setDialogueContent] = useState({});
  const { soonestDueDocuments, projectEvents, loading, calendarRef, iCalendar } = props;
  const data: EventType[] = [];

  const handleClickOpen = (event: EventType) => {
    setDialogueContent(event);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getRedirectLink = (event: EventType) => {
    console.log(event.type, documentTypeToUrl[event.type as DocumentTemplateType]);
    if (event.type !== 'N/A') {
      if (event.type !== DocumentTemplateType.AdditionalReview)
        return `/main/projects/${event.projectId}/documents/${
          documentTypeToUrl[event.type as DocumentTemplateType]
        }/${event.id}`;
      else if (event.parentId && event.parentType)
        return `/main/projects/${event.projectId}/documents/${
          documentTypeToUrl[event.parentType as DocumentTemplateType]
        }/${event.parentId}`;
    }
  };

  console.log(documentTypeToUrl, documentTypeToUrl['Requests for Information']);

  soonestDueDocuments.forEach((document) => {
    if (!document.dueDate) return;
    const dueDate = parseDate(document.dueDate).toDate();

    const newCalendarEvent: EventType = {
      id: document.id,
      projectId: document.projectId,
      title: getUserFacingDocumentStatus(document),
      start: dueDate,
      end: dueDate,
      allDay: true,
      source: document.submittalSection
        ? addSpacing(document.submittalSection)
        : document.documentNumber!.toString(),
      company: document.project!.name!,
      address: '', // no address yet
      type: document.documentTemplate?.name || '', // no type yet
      parentId: document.additionalReviewForDocumentId || undefined,
      parentType: document.additionalReviewForDocument?.documentTemplate?.name || undefined,
    };

    const link = getRedirectLink(newCalendarEvent);

    iCalendar.current.createEvent({
      id: document.id,
      start: dueDate,
      end: dueDate,
      allDay: true,
      summary: `${newCalendarEvent.type} ${newCalendarEvent.source}`,
      description: `Project: ${document.project!.name}`,
      url: link ? `https://centerline.co${link}` : undefined,
    });

    data.push(newCalendarEvent);
  });

  if (projectId)
    projectEvents.forEach(({ id, name, startTimestamp, endTimestamp }) => {
      const start = parseDate(startTimestamp).toDate();
      const end = parseDate(endTimestamp).toDate();
      const newEvent: EventType = {
        id,
        projectId,
        title: name,
        start,
        end,
      };

      iCalendar.current.createEvent(newEvent);

      data.push(newEvent);
    });

  return (
    <div className={classes.root}>
      {loading && (
        <div style={{ position: 'relative', top: '50%', padding: 200 }}>
          <CircularLoader />
        </div>
      )}

      {!loading && (
        <div>
          <div className={classes.calendarStyling}>
            <Calendar
              ref={calendarRef}
              formats={formats}
              events={data}
              step={30}
              showMultiDayTimes
              defaultDate={dayjs().toDate()}
              defaultView="month"
              components={{
                event: EventComponent,
              }}
              popup
              localizer={localizer}
              eventPropGetter={eventStyler}
              onSelectEvent={(event) => {
                handleClickOpen(event);
              }}
              views={{ month: true, day: true }}
            />
          </div>
          <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogContent>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Project</TableCell>
                      <TableCell align="center">Type</TableCell>
                      <TableCell align="center">Item</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell scope="row" align="center">
                        {/* the data on the object is named poorly, sorry!!!
                        will clean up when the backend is more solid -trent */}
                        {/* company = project */}
                        {`${(dialogueContent as EventType).company}`}
                      </TableCell>
                      <TableCell align="center">{`${
                        (dialogueContent as EventType).type
                      }`}</TableCell>
                      <TableCell align="center">
                        {/* source = item */}
                        {`${(dialogueContent as EventType).source}`}
                      </TableCell>
                      <TableCell align="center">
                        {/* title = status */}
                        {`${(dialogueContent as EventType).title}`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => {
                  const link = getRedirectLink(dialogueContent as EventType);
                  if (link) history.push(link);
                }}
                color="primary"
              >
                View
              </Button>
              <Button autoFocus onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
}
