import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  GetProjectZipResponse,
  ISimplifiedProject,
  JobStatusType,
} from '../../api-client/autogenerated';
import {
  archiveProjectById,
  getLatestProjectArchiveZipFileLink,
  patchProject,
} from '../../models/api/project';
import { formatMoney, parseDate } from '../../scripts/utils';
import { ascendingComparator, descendingComparator } from '../document-index/DocumentIndexUtils';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DeleteProjectDialog from '../dialogs/DeleteProjectDialog';
import ArchiveDialog from './ArchiveDialog';
import ExportDialog from './ExportDialog';
import { addSnackbar } from '../../features/snackbar/actions';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

//need these to be global varibles so the change happens immediately and an extra runthrough isn't made
let projectDownloading = false;
let timeoutInMilliSeconds = 2000;
let myTimeout: NodeJS.Timeout;

type ProjectsTableProps = {
  projects: ISimplifiedProject[];
  refreshSubscriber: () => void;
  availableProjects: number | null;
  handleNewProject: () => void;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      fill: '#B2B1B2',
    },
    deleteButton: {
      marginLeft: '5rem',
      [theme.breakpoints.down('lg')]: {
        marginBottom: '1rem',
        marginTop: '1rem',
        marginLeft: 0,
      },
    },
    progressBar: {
      height: '20px',
      width: '40%',
      borderRadius: '3rem',
      background: '#C4C4C4',
    },
    progressFiller: {
      height: '100%',
      borderRadius: 'inherit',
      transition: 'width .2s ease-in',
    },
    filterButton: {
      marginRight: 8,
      marginLeft: 4,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    sortIcon: {
      display: 'flex',
      flexDirection: 'column',
      height: '22px',
      paddingTop: '1px',
      marginLeft: '8px',
    },
    sortIconEnabled: {
      color: '#616061',
    },
    sortIconDisabled: {
      color: '#B2B1B2',
    },
    disableDefaultSortIcon: {
      display: 'none',
    },
  }),
);

type ColumnName = 'name' | 'createdOn';

const ProjectsTable = (props: ProjectsTableProps) => {
  const { projects, refreshSubscriber, availableProjects, handleNewProject } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState<string>();
  const [orderBy, setOrderBy] = useState<ColumnName>('createdOn');
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('desc');
  const [projectToDelete, setProjectToDelete] = useState<ISimplifiedProject>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [activeProject, setActiveProject] = useState<ISimplifiedProject>();
  const [projectFilter, setProjectFilter] = useState('All');
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [projectState, setProjectState] = useState<JobStatusType>();
  const [projectInProgress, setProjectInProgress] = useState(false);
  const [projectExportPercentage, setProjectExportPercentage] = useState(0);
  const [exportResponse, setExportResponse] = useState<GetProjectZipResponse>();
  const [exportCount, setExportCount] = useState(0);

  const percentage = projects.length / (availableProjects ?? 1);

  const handleEditClick = (id: string) => {
    history.push(`/main/projects/${id}/manage`, { projectId: id });
  };

  const Archive = async (id: string) => {
    await archiveProjectById(id);
    refreshSubscriber();
  };

  const Restore = async (id: string) => {
    await patchProject(id, { isArchived: false });
    refreshSubscriber();
  };

  const handleDeleteClick = (id: string) => {
    const project = projects.find((p) => p.id === id);
    if (!project) return;
    setProjectToDelete(project);
    setDeleteDialogOpen(true);
  };

  const exportArchivedProject = async (
    project: ISimplifiedProject,
    setAnActiveProject?: boolean,
  ) => {
    let filesAlreadyZipped = 0;
    let projectCompleted: boolean;
    const response = await getLatestProjectArchiveZipFileLink(project.id);
    setExportResponse(response);
    if (setAnActiveProject === true) setActiveProject(project);

    setProjectState(response.jobStatus.status);
    projectCompleted =
      response.jobStatus.status === JobStatusType.Complete ||
      response.jobStatus.status === JobStatusType.Canceled;

    if (!projectCompleted) {
      console.log(`${timeoutInMilliSeconds}: ${response.jobStatus.status}`);
      console.log(response.jobStatus.progress);
      timeoutInMilliSeconds = 2000;
      if (
        projectState === JobStatusType.Pending &&
        response.jobStatus.status === JobStatusType.Initiated
      ) {
        setProjectState(JobStatusType.Initiated);
      } else if (
        projectState === JobStatusType.Initiated &&
        response.jobStatus.progress !== null &&
        response.jobStatus.progress !== undefined
      ) {
        timeoutInMilliSeconds = 1000;
        setProjectInProgress(true);
        if (response.jobStatus.progress?.filesToZipCompleted !== filesAlreadyZipped) {
          filesAlreadyZipped = response.jobStatus.progress?.filesToZipCompleted
            ? response.jobStatus.progress.filesToZipCompleted
            : 0;
          let filesToZip = response.jobStatus.progress?.filesToZipTotal
            ? response.jobStatus.progress?.filesToZipTotal
            : 1;
          setProjectExportPercentage((filesAlreadyZipped / filesToZip) * 100);
        }
      }
    } else {
      console.log(`${timeoutInMilliSeconds}: ${response.jobStatus.status}`);
      clearTimeout(myTimeout);
      timeoutInMilliSeconds = 2000;
      projectDownloading = true;
      setProjectState(JobStatusType.Complete);
      setProjectExportPercentage(100);
      console.log(response.jobStatus.progress?.uploadedBytes);
      // if (response.file) await openFileInNewTab(response.file.id);
      if (response.jobStatus.status === JobStatusType.Canceled) {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: `Something went wrong exporting your archive. Please contact support@centerline.co.`,
            severity: 'error',
          }),
        );
      }

      console.log(response.jobStatus.progress?.uploadedBytes);
      projectDownloading = false;
      // setProjectState(undefined);
      // setIsExportDialogOpen(false);
    }
  };

  const toggleArchiveDialog = (project: ISimplifiedProject) => {
    if (isArchiveDialogOpen) {
      setIsArchiveDialogOpen(false);
    } else {
      setActiveProject(project);
      setIsArchiveDialogOpen(true);
    }
  };

  const closeDialog = () => {
    setIsArchiveDialogOpen(false);
    setIsExportDialogOpen(false);
  };

  const handleChange = (event: any) => {
    setProjectFilter(event.target.value);
  };

  useEffect(() => {
    const filterProjects = () => {
      if (projectFilter === 'All') {
        setFilteredProjects(projects);
      }
      if (projectFilter === 'Active') {
        setFilteredProjects(projects);
        setFilteredProjects(projects.filter((p) => !p.isArchived));
      }
      if (projectFilter === 'Archived') {
        setFilteredProjects(projects);
        setFilteredProjects(projects.filter((p) => p.isArchived));
      }
    };
    filterProjects();
  }, [projectFilter, filteredProjects, projects]);

  useEffect(() => {
    if (
      isExportDialogOpen &&
      !projectDownloading &&
      activeProject &&
      projectState !== JobStatusType.Complete &&
      projectState !== JobStatusType.Canceled
    )
      myTimeout = setTimeout(() => exportArchivedProject(activeProject), timeoutInMilliSeconds);
  }, [exportCount]);

  useEffect(() => {
    if (isExportDialogOpen)
      setTimeout(() => setExportCount(exportCount + 1), timeoutInMilliSeconds);
  });

  const handleSort = (column: ColumnName) => {
    if (orderBy === column) {
      setOrderDirection((prev) => (prev === 'desc' ? 'asc' : 'desc'));
    } else {
      setOrderBy(column);
      setOrderDirection(column === 'createdOn' ? 'desc' : 'asc');
    }
  };

  const clearState = () => {
    setProjectState(undefined);
    setExportResponse(undefined);
    setExportCount(0);
    setActiveProject(undefined);
    setProjectExportPercentage(0);
  };

  useEffect(() => {
    if (!isExportDialogOpen) {
      clearState();
    }
  }, [isExportDialogOpen]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          size="small"
          variant="outlined"
          placeholder="Search Projects"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search
                  fontSize="small"
                  classes={{
                    root: classes.icon,
                  }}
                />
              </InputAdornment>
            ),
          }}
          style={{ marginLeft: '1rem', width: '20rem' }}
        />
        <Tooltip
          placement="bottom"
          title={`${projects.length} Projects used out of ${
            availableProjects ?? 'unlimited'
          } available project licenses`}
          arrow
        >
          <div className={classes.progressBar} style={{ marginLeft: '13rem' }}>
            <div
              className={classes.progressFiller}
              style={{
                width: `${percentage > 1 ? 100 : percentage * 100}%`,
                background: percentage >= 1 && availableProjects !== null ? '#ED3F26' : '#0947B9',
              }}
            />
          </div>
        </Tooltip>
        <FormControl style={{ marginLeft: '7rem', width: '10rem', marginRight: '2rem' }}>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={projectFilter}
            label="Status"
            onChange={handleChange}
          >
            <MenuItem value={'All'}>All</MenuItem>
            <MenuItem value={'Active'}>Active</MenuItem>
            <MenuItem value={'Archived'}>Archived</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNewProject}
          style={{ height: '3rem', marginRight: 16, lineHeight: 1.3 }}
        >
          Create New Projects
        </Button>
      </div>
      <TableContainer style={{ overflowX: 'visible' }}>
        <Table size="small" aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  onClick={() => handleSort('name')}
                  classes={{ icon: classes.disableDefaultSortIcon }}
                >
                  Name
                  <div className={classes.sortIcon}>
                    <FontAwesomeIcon
                      icon={faCaretUp}
                      style={{ marginBottom: '-3px' }}
                      className={
                        orderBy === 'name' ? classes.sortIconEnabled : classes.sortIconDisabled
                      }
                    />
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      style={{ marginTop: '-3px' }}
                      className={
                        orderBy === 'name' ? classes.sortIconEnabled : classes.sortIconDisabled
                      }
                    />
                  </div>
                </TableSortLabel>
              </TableCell>
              <TableCell>AMOUNT</TableCell>
              <TableCell>DESCRIPTION</TableCell>
              <TableCell>USERS</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  onClick={() => handleSort('createdOn')}
                  classes={{ icon: classes.disableDefaultSortIcon }}
                >
                  Date Created
                  <div className={classes.sortIcon}>
                    <FontAwesomeIcon
                      icon={faCaretUp}
                      style={{ marginBottom: '-3px' }}
                      className={
                        orderBy === 'createdOn' ? classes.sortIconEnabled : classes.sortIconDisabled
                      }
                    />
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      style={{ marginTop: '-3px' }}
                      className={
                        orderBy === 'createdOn' ? classes.sortIconEnabled : classes.sortIconDisabled
                      }
                    />
                  </div>
                </TableSortLabel>
              </TableCell>
              <TableCell>STATUS</TableCell>
              <TableCell align="center">ACTIONS</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProjects
              .filter((row) => {
                if (!searchInput) return true;
                return row.name.toLowerCase().includes(searchInput.toLowerCase());
              })
              .sort((a, b) =>
                (orderDirection === 'asc' ? ascendingComparator : descendingComparator)(
                  a,
                  b,
                  orderBy,
                ),
              )
              .map((project) => (
                <TableRow key={project.id}>
                  <TableCell component="th" scope="row">
                    {project.name}
                  </TableCell>
                  <TableCell>{formatMoney(project.contractAmount || 0)}</TableCell>
                  <TableCell style={{ maxWidth: '30rem' }}>{project.description}</TableCell>
                  <TableCell>{project.numProjectUsers}</TableCell>
                  <TableCell>{parseDate(project.createdOn!).format('MM/DD/YYYY')}</TableCell>
                  <TableCell>{project.isArchived ? 'Archived' : 'Active'}</TableCell>
                  <TableCell>
                    <div style={{ marginLeft: '2rem', display: 'flex' }}>
                      <Tooltip
                        arrow
                        title={
                          project.isArchived ? 'Only active projects can be edited' : 'Edit Project'
                        }
                        placement="top"
                      >
                        <span style={{ display: 'grid' }}>
                          <Button
                            disabled={!!project.isArchived}
                            variant="contained"
                            color="primary"
                            onClick={() => handleEditClick(project.id)}
                          >
                            Edit
                          </Button>
                        </span>
                      </Tooltip>
                      {/*<Tooltip arrow title="Archive Project" placement="top">*/}
                      {/*  <Button*/}
                      {/*    style={{ margin: 0, padding: 0, minWidth: '32px', height: '32px' }}*/}
                      {/*    onClick={() => handleArchiveClick(project.id)}*/}
                      {/*  >*/}
                      {/*    <SafeIcon />*/}
                      {/*  </Button>*/}
                      {/*</Tooltip>*/}
                      {project.isArchived ? (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: '1rem' }}
                          onClick={() => {
                            toggleArchiveDialog(project);
                          }}
                        >
                          Restore
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: '1rem' }}
                          onClick={() => {
                            toggleArchiveDialog(project);
                          }}
                        >
                          Archive
                        </Button>
                      )}
                      {project.isArchived! ? (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: '1rem' }}
                          onClick={() => {
                            setProjectInProgress(false);
                            setIsExportDialogOpen(true);
                            exportArchivedProject(project, true);
                          }}
                        >
                          Export
                        </Button>
                      ) : (
                        <Tooltip
                          arrow
                          title="Only archived projects can be exported"
                          placement="top"
                        >
                          <span style={{ display: 'grid' }}>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginLeft: '1rem' }}
                              disabled
                            >
                              Export
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Tooltip arrow title="Delete Project" placement="top">
                      <Button
                        variant="contained"
                        className={classes.deleteButton}
                        style={{ color: 'white', backgroundColor: 'red' }}
                        onClick={() => handleDeleteClick(project.id)}
                      >
                        Delete
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {activeProject && (
        <ArchiveDialog
          project={activeProject}
          isArchiveDialogOpen={isArchiveDialogOpen}
          closeDialog={closeDialog}
          Archive={Archive}
          Restore={Restore}
        />
      )}
      {activeProject && (
        <ExportDialog
          project={activeProject}
          isExportDialogOpen={isExportDialogOpen}
          closeDialog={closeDialog}
          projectState={projectState}
          projectInProgress={projectInProgress}
          projectExportPercentage={projectExportPercentage}
          response={exportResponse}
        />
      )}
      {projectToDelete && (
        <DeleteProjectDialog
          open={deleteDialogOpen}
          handleClose={() => {
            setProjectToDelete(undefined);
            setDeleteDialogOpen(false);
          }}
          refreshSubscriber={refreshSubscriber}
          project={projectToDelete}
        />
      )}
    </>
  );
};

export default ProjectsTable;
