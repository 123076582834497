import React, { useEffect, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Close, HighlightOffRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, MenuItem, Select } from '@material-ui/core';
import { BootstrapInput } from '../custom-components/CustomButtons';
import Button from '@material-ui/core/Button';
import { respondToReview } from '../../models/api/documents';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentState } from '../../features/document/selectors';
import {
  DocumentTemplateType,
  FileCategoryType,
  RespondToReviewPropsResponseEnum,
} from '../../api-client/autogenerated';
import ResubmitDialog from '../dialogs/ResubmitDialog';
import { reloadDocument, updateDocument } from '../../features/document/actions';
import { useHistory } from 'react-router-dom';
import {
  documentTypeToUrl,
  isSimpleWorkflowDoc,
  waitForFileToBeVerified,
} from '../../scripts/utils';
import { getDocumentsType } from '../../features/documents/selectors';
import { uploadGeneralDocumentFile } from '../../models/api/filesystem';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import FileUploadDialog from '../dialogs/FileUploadDialog';
import PDFIcon from '../icons/PDF-icon';
import Add from '@material-ui/icons/Add';
import { addSnackbar } from '../../features/snackbar/actions';
import CircularLoader from '../loader/CircularLoader';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';

type Props = {
  handleClose: () => void;
  setMaxHeight: React.Dispatch<React.SetStateAction<number>>;
  documentTitle?: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#FFFFFF',
    borderRadius: '4px',
    padding: '16px',
    width: '400px',
    zIndex: 1,
    minWidth: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: '100%',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '8px',
    marginBottom: '16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21px',
    textAlign: 'left',
    color: '#0947B9',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  submitButton: {
    height: '32px',
    background: '#0947B9',
    color: '#FFF',
    borderRadius: '4px',
    '&:hover': {
      background: '#4380ED',
    },
  },
}));

export default function Complete(props: Props) {
  const classes = useStyles();
  const { handleClose, setMaxHeight, documentTitle } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const docType = useSelector(getDocumentsType)!;
  const document = useSelector(getDocumentState)!;

  const [acceptance, setAcceptance] = useState<RespondToReviewPropsResponseEnum>();
  const [resubmitDialogOpen, setResubmitDialogOpen] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadMessage, setUploadMessage] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const shouldShowUpload =
    docType === DocumentTemplateType.PayApplications ||
    docType === DocumentTemplateType.DesignPackages;

  const ref = useRef<any>();
  useEffect(() => {
    if (ref) setMaxHeight(ref!.current!.clientHeight);
  });

  const handleSubmit = async () => {
    if (shouldShowUpload) {
      if (!uploadedFile) return;
      setIsSubmitting(true);
      dispatch(blockNavigation());

      let verified = false;
      setLoadingText(`Uploading ${uploadedFile.name}...`);
      const { file } = await uploadGeneralDocumentFile(
        document.id,
        {
          fileType:
            docType === DocumentTemplateType.PayApplications
              ? FileCategoryType.DocumentFinalExecuted
              : FileCategoryType.DocumentFinalDeliverable,
          fullFileName: uploadedFile.name,
          useMultiPartUpload: uploadedFile.size > MULTI_PART_FILE_SIZE,
        },
        uploadedFile,
        handleUploadProgress,
      );

      setLoadingText('Verifying upload...');
      verified = file ? await waitForFileToBeVerified(file) : false;

      if (verified) {
        setLoadingText('Updating document...');
        await respondToReview(document.id, { response: RespondToReviewPropsResponseEnum.Resolved });
      } else {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: `File ${uploadedFile.name} has not finished uploading. You must cancel the upload and try again`,
            severity: 'error',
          }),
        );
        return;
      }

      dispatch(allowNavigation());
      dispatch(reloadDocument());
    } else if (acceptance === RespondToReviewPropsResponseEnum.Resolved) {
      try {
        const { document: updatedDocument } = await respondToReview(document.id, {
          response: acceptance,
        });
        dispatch(updateDocument(updatedDocument));
      } catch (e: any) {
        console.error(e);
      } finally {
        handleClose();
      }
    } else {
      setResubmitDialogOpen(true);
    }
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleResubmit = async (files: File[], newDescription?: string) => {
    try {
      dispatch(blockNavigation());
      const { revisedDocument } = await respondToReview(document.id, {
        response: RespondToReviewPropsResponseEnum.Resubmitted,
        newDescription,
      });
      if (revisedDocument) {
        for (let i = 0; i < files.length; i += 1) {
          const file = files[i];

          setUploadMessage(`Uploading ${(file as File).name}`);
          await uploadGeneralDocumentFile(
            revisedDocument.id,
            {
              fullFileName: (file as File).name,
              fileType: isSimpleWorkflowDoc(docType)
                ? FileCategoryType.DocumentGeneralContractorApproved
                : FileCategoryType.DocumentAttachments,
              useMultiPartUpload: file.size > MULTI_PART_FILE_SIZE,
            },
            file as File,
            handleUploadProgress,
          );

          setUploadProgress(0);
        }
      }
      dispatch(allowNavigation());
      history.push(
        `/main/projects/${document.projectId}/documents/${documentTypeToUrl[docType]}${
          revisedDocument ? `/${revisedDocument.id}` : ''
        }`,
      );
    } catch (e: any) {
      console.error(e);
    } finally {
      dispatch(allowNavigation());
    }
  };

  const getUploadButtonLabel = () => {
    if (docType === DocumentTemplateType.DesignPackages) return 'Upload Final Deliverable';

    return 'Upload Final Executed';
  };

  return (
    <div className={classes.container} ref={ref}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>
          {documentTitle ? `Complete ${documentTitle}` : 'Complete'}
        </Typography>
        <div style={{ display: 'inline-flex', flexGrow: 100 }} />
        <IconButton
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </div>
      <div className={classes.content}>
        {shouldShowUpload ? (
          <>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setUploadDialogOpen(true)}
              startIcon={<Add />}
              style={{ marginTop: 16, marginBottom: 16 }}
            >
              {getUploadButtonLabel()}
            </Button>
            {uploadedFile && (
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                <IconButton onClick={() => setUploadedFile(undefined)} style={{ padding: 0 }}>
                  <Close />
                </IconButton>
                <PDFIcon style={{ marginLeft: 4, marginRight: 8 }} />
                <span>{uploadedFile.name}</span>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                visibility: isSubmitting ? 'visible' : 'hidden',
                marginBottom: 16,
              }}
            >
              <Typography style={{ fontWeight: 500, marginBottom: 4 }}>{loadingText}</Typography>
              {loadingText.includes('Uploading') ? (
                <>
                  <Typography>{uploadProgress}%</Typography>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                    style={{ height: 6, width: '100%', marginTop: 2 }}
                  />
                </>
              ) : (
                <CircularLoader />
              )}
            </div>
          </>
        ) : (
          <Select
            displayEmpty
            value={acceptance}
            onChange={(event) =>
              setAcceptance(event.target.value as RespondToReviewPropsResponseEnum)
            }
            input={<BootstrapInput />}
            style={{ margin: '24px 8px 64px 8px' }}
          >
            <MenuItem value={undefined} disabled>
              {docType === DocumentTemplateType.Task ? 'Assigner' : 'Contractor'} Acceptance
            </MenuItem>
            <MenuItem value={RespondToReviewPropsResponseEnum.Resubmitted}>
              {docType === DocumentTemplateType.Task ? 'Resubmit' : 'Resubmit to Architect'}
            </MenuItem>
            <MenuItem value={RespondToReviewPropsResponseEnum.Resolved}>
              {docType === DocumentTemplateType.Task ? 'Accepted' : 'Resolved'}
            </MenuItem>
          </Select>
        )}
      </div>
      <div className={classes.actions}>
        <Button
          variant="contained"
          disabled={isSubmitting || (shouldShowUpload ? !uploadedFile : !acceptance)}
          onClick={handleSubmit}
          className={classes.submitButton}
        >
          Submit
        </Button>
      </div>
      <ResubmitDialog
        open={resubmitDialogOpen}
        handleClose={() => setResubmitDialogOpen(false)}
        originalReferenceNumber={`${document.referenceNumber}-R${
          (document.revisionNumber || 0) + 1
        }`}
        handleSubmit={handleResubmit}
        uploadProgress={uploadProgress}
        uploadMessage={uploadMessage}
      />
      <FileUploadDialog
        open={uploadDialogOpen}
        handleClose={() => setUploadDialogOpen(false)}
        title="Upload Final Deliverable"
        addFile={(f) => setUploadedFile(f as File)}
        removeFile={() => setUploadedFile(undefined)}
        file={uploadedFile}
        disableComments
        disableDesignUpload
      />
    </div>
  );
}
