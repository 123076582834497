import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchProject } from '../features/project/actions';
import { getProjectState } from '../features/project/selectors';
import { getSubscriberAdminsSimplifiedBySubscriberId } from '../models/api/subscribers';
import { ProjectMatchParams } from '../scripts/utils';
import NavAppbar from './nav-top/NavAppbar';
import { ISubscriberAdminsSimplified } from '../api-client/autogenerated';
import FullscreenLoader from './loader/FullscreenLoader';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh',
    overflowX: 'hidden',
  },
}));

export default function LockedProjectPage() {
  const classes = useStyles();

  const project = useSelector(getProjectState);
  const params = useParams<ProjectMatchParams>();
  const dispatch = useDispatch();

  const [subscriber, setSubscriber] = useState<ISubscriberAdminsSimplified>();

  // this makes sure we are on the same project if we refresh the page
  useEffect(() => {
    if (params.projectId) dispatch(fetchProject(params.projectId));
  }, [dispatch, params.projectId]);

  useEffect(() => {
    if (project?.subscriberId)
      getSubscriberAdminsSimplifiedBySubscriberId(project.subscriberId).then((response) => {
        setSubscriber(response);
      });
  }, [project?.subscriberId]);

  const getLockedScreenText = () => {
    if (project?.subscriber?.lockedReason)
      return <Typography variant="h6">{project.subscriber.lockedReason}</Typography>;
    if (subscriber?.users)
      return (
        <>
          <Typography variant="h6">
            {project?.subscriber?.name
              ? `Please contact a subscriber admin at ${project?.subscriber?.name}`
              : 'Please contact a subscriber admin'}
          </Typography>
          <div style={{ padding: 8 }} />
          <Typography style={{ padding: 4 }}>
            You may contact one of the following users:
          </Typography>
          {subscriber?.users.map((user) => (
            <Typography style={{ padding: 4 }}>
              {user.name} <a href={`mailto:${user.email}`}>{user.email}</a>
            </Typography>
          ))}
        </>
      );
    return (
      <Typography variant="h6">You are not allowed to create projects at this time.</Typography>
    );
  };

  return !params.projectId || subscriber?.users ? (
    <main className={classes.content}>
      <NavAppbar />
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
          overflowY: 'hidden',
        }}
      >
        {/* TODO: loading and error states could probably be handled better */}

        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {getLockedScreenText()}
        </Box>
      </Grid>
    </main>
  ) : (
    <FullscreenLoader />
  );
}
