import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import { Typography, useMediaQuery } from '@material-ui/core';

export default function HelpButton() {
  const isMobile = useMediaQuery('(max-width:540px)');

  return (
    <IconButton href="mailto:support@centerline.co" style={{ padding: 4 }}>
      {!isMobile && (
        <Typography
          style={{
            color: '#7A797A',
            fontFamily: 'Uniform Rounded Medium',
            fontWeight: 600,
            marginRight: 6,
          }}
        >
          Need Help?
        </Typography>
      )}
      <HelpIcon style={{ color: '#7A797A' }} />
    </IconButton>
  );
}
