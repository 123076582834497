import { action } from 'typesafe-actions';
import { Dispatch } from 'react';
import { SET_BID, UPDATE_BID } from './constants';
import { Bid } from './bid';
import { getBidById } from '../../models/api/bid';

export const setBid = (bid: Bid = null) => {
  return action(SET_BID, bid);
};

export const updateBid = (bid: Bid) => {
  return action(UPDATE_BID, bid);
};

export const fetchBid = (bidId: string) => (dispatch: Dispatch<any>) => {
  dispatch({ type: 'SET_DOCUMENT_LOADING', payload: true });
  return getBidById(bidId)
    .then((response) => dispatch({ type: SET_BID, payload: response }))
    .catch()
    .finally(() => {
      dispatch({ type: 'SET_DOCUMENT_LOADING', payload: false });
    });
};
