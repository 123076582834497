/* eslint-disable */
import { createMuiTheme } from '@material-ui/core';

// Colors
export const primaryColor = '#0947B9'; // blue
export const secondaryColor = '#F28B00'; // orange
export const gray50 = '#F9F9F9';
export const gray200 = '#C9C8C9';
export const gray300 = '#B2B1B2';
export const gray400 = '#949494';
export const gray700 = '#464546';
export const blue100 = '#E6EEFF';
export const blue400 = '#73A3FF';
export const blue500 = '#4380ED';
export const blue600 = '#2C69D6';
export const orange400 = '#FFBA34';
export const orange500 = '#FFAB08';
export const orange600 = '#FF9C17';
export const orange700 = '#F28B00';
export const red400 = '#D02C14';
export const green300 = '#2BB073';
export const white = '#FFFFFF';
export const black = '#000000';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1500,
      xl: 5000,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
      // light: blue500,
      // contrastText: '#fff',
    },
    secondary: {
      main: secondaryColor,
      // light: '#FF9C17',
      // contrastText: '#fff',
    },
    action: {
      disabledBackground: gray200,
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        height: '34px',
        width: '216px',
        border: 'none',
        '& .Mui-disabled': {
          background: gray400,
        },
      },
      multiline: {
        height: 'auto',
        width: 'auto',
        padding: 0,
      },
      input: {
        background: gray50,
        padding: '8px 0px 8px 8px',
        border: `1px solid ${gray400}`,
        borderRadius: '5px',
        textAlign: 'start',
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '15px',
        lineHeight: '18px',
        color: gray700,
        '&::placeholder': {
          fontStyle: 'italic',
          color: gray300,
          opacity: 1,
        },
      },
    },

    MuiAppBar: {
      // nav appbar
      colorPrimary: {
        backgroundColor: 'transparent',
      },
      root: {
        boxShadow: 'none',
      },
    },
    MuiListItemText: {
      primary: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#CCDEFF',
        textTransform: 'none',
      },
      secondary: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '25px',
        color: '#CCDEFF',
        textTransform: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '16px',
        lineHeight: '26px',
        textTransform: 'none',
      },
    },
    MuiPaper: {
      // card border
      elevation1: {
        boxShadow: 'none',
      },
      elevation4: {
        boxShadow: 'none',
      },
    },

    MuiButton: {
      root: {
        height: '32px',
        width: 'auto',
        minWidth: '78px',
        borderRadius: '4px !important',
        color: gray700, // color="default"
      },
      containedPrimary: {
        // default color or color="primary"
        '&:hover': {
          background: `${blue500} !important`,
        },
        label: {
          color: white,
        },
      },
      containedSecondary: {
        '&:hover': {
          background: `${orange600} !important`,
        },
      },
      label: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        textAlign: 'center',
      },
    },
    MuiCheckbox: {
      root: {
        padding: '0px 8px 2px 0px',
        '&$checked': {
          color: `${green300} !important`,
        },
      },
    },
    MuiSelect: {
      icon: {
        fill: gray700,
      },
    },
    MuiTypography: {
      root: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
      },
      h1: {
        fontWeight: 700,
        fontSize: '34px',
        lineHeight: '40px',
        letterSpacing: '-0.6px',
        color: `${primaryColor} !important`,
      },
      h2: {
        fontWeight: 500,
        fontSize: '26px',
        lineHeight: '30px',
        color: gray700,
      },
      h3: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '21px',
        color: primaryColor,
      },
      h4: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '21px',
        color: primaryColor,
        textTransform: 'uppercase',
      },
      subtitle1: {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '19px',
        color: black,
        textTransform: 'uppercase',
      },
      body1: {
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '18px',
        color: gray700,
      },
      colorError: {
        color: red400,
      },
      colorTextPrimary: {
        color: gray700,
      },
      colorTextSecondary: {
        color: gray400,
      },
    },
    MuiTableCell: {
      root: {
        textTransform: 'inherit',
      },
    },
    MuiList: {
      padding: {
        paddingBottom: '8px !important',
        paddingTop: '0px',
      },
    },
  },
  props: {
    MuiButton: {
      variant: 'contained',
      disableElevation: true,
      color: 'primary',
    },
    MuiTextField: {
      variant: 'filled',
    },
    MuiFilledInput: {
      disableUnderline: true,
    },
    MuiSelect: {
      variant: 'filled',
    },
  },
});

export enum FieldWidth {
  Navigation = '184px',
  Short = '216px',
  Long = '319px',
}

export default function getBiddingTheme() {
  return theme;
}
