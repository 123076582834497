import { Button, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import { ClearTwoTone, Group } from '@material-ui/icons';

export enum PermissionTagType {
  Person,
  Group,
  Everyone,
}

interface PermissionTagProps {
  type: PermissionTagType;
  disableDelete?: boolean;
  label: string;
  triggerDeletePermission?: () => void;
  onClick?: () => void;
  parentFolder?: string;
  style?: React.CSSProperties;
  disableEllipsis?: boolean;
  isDocumentDetail?: boolean;
}

const useStyles = makeStyles({
  rootTag: (props: PermissionTagProps) => ({
    color: props.isDocumentDetail ? '#0947B9' : 'white',
    // borderRadius: '24px',
    paddingLeft: '25px',
    paddingRight: '25px',
    marginLeft: props.isDocumentDetail ? 0 : 8,
    marginRight: props.isDocumentDetail ? 0 : 8,
    marginTop: 8,
    marginBottom: 8,
    whiteSpace: 'nowrap',
    maxWidth: props.isDocumentDetail ? '320px' : '250px',
  }),
  containedTag: {
    backgroundColor: '#0947B9',
    '&:hover': {
      backgroundColor: '#4380ED',
    },
    '&:active': {
      backgroundColor: '#4380ED',
    },
  },
  labelTag: {
    justifyContent: 'flex-start',
  },
  buttonLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default function PermissionTag(props: PermissionTagProps) {
  const {
    type,
    disableDelete,
    label,
    onClick,
    triggerDeletePermission,
    parentFolder,
    style,
    disableEllipsis,
    isDocumentDetail,
  } = props;
  const classes = useStyles(props);

  const getStartIcon = () => {
    if (type === PermissionTagType.Person) return <PersonIcon />;
    else return <Group />;
  };

  return (
    <>
      <Tooltip
        arrow
        placement="top"
        title={
          disableDelete && parentFolder
            ? `Permission can be managed through parent folder: ${parentFolder}`
            : ''
        }
      >
        <Button
          classes={{
            root: classes.rootTag,
            containedPrimary: classes.containedTag,
            label: isDocumentDetail ? classes.labelTag : undefined,
          }}
          style={{ paddingRight: disableDelete ? 30 : 50, ...style }}
          startIcon={getStartIcon()}
          variant={isDocumentDetail ? 'outlined' : 'contained'}
          color="primary"
          fullWidth
          onClick={onClick}
        >
          <span className={!disableEllipsis ? classes.buttonLabel : undefined}>{label}</span>
          {!disableDelete && (
            <IconButton
              size="small"
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                color: isDocumentDetail ? '#0947B9' : 'white',
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (triggerDeletePermission) triggerDeletePermission();
              }}
            >
              <ClearTwoTone style={{ height: 20, width: 20 }} />
            </IconButton>
          )}
        </Button>
      </Tooltip>
    </>
  );
}
