import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import { getCompanyById } from '../../models/api/companies';
import { getDocumentState } from '../../features/document/selectors';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider';
import DayjsUtils from '@date-io/dayjs';
import dayjs, { Dayjs } from 'dayjs';
import { DatePickerToolbar } from '@material-ui/pickers/DatePicker/DatePickerToolbar';
import { getTomorrowDate, parseDate } from '../../scripts/utils';
import { SubmitButton } from '../custom-components/CustomButtons';
import CircularLoader from '../loader/CircularLoader';
import { getDocPermission } from '../../scripts/store-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        [theme.breakpoints.down('md')]: {
          marginLeft: 8,
          marginRight: 16,
          marginBottom: 16,
        },
        [theme.breakpoints.up('lg')]: {
          margin: theme.spacing(1),
        },
      },
      flexGrow: 1,
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
    margin: {
      margin: theme.spacing(1),
      height: '36px', // make buttons same height
    },
    username: {
      fontWeight: 'bold',
      fontSize: 18,
    },
    company: {
      fontStyle: 'italic',
      fontColor: 'default',
      fontSize: 18,
    },
    datepickerLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '22px',
      textTransform: 'uppercase',
      color: '#616061',
    },
    datepicker: {
      background: '#F9F9F9',
      height: '35px',
    },
  }),
);

type Props = {
  returnForAdditionalReview?: boolean;
  onSubmit: (comment: string, selectedDate?: Dayjs | null) => Promise<void>;
};

function CommentTextfield(props: Props) {
  const classes = useStyles();
  const { returnForAdditionalReview, onSubmit } = props;
  const user = useSelector(getUserState);
  const document = useSelector(getDocumentState);

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [company, setCompany] = useState('No Company');
  const [inputComment, setInputComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isDisabled = () => {
    const permission = getDocPermission();
    return !permission || permission === 0;
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await onSubmit(inputComment, selectedDate);
      setInputComment('');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user.company) {
      if (user.company.name) setCompany(user.company.name);
    } else if (user.companyId) getCompanyById(user.companyId).then(({ name }) => setCompany(name));
  }, [user]);

  const getButton = () => {
    if (returnForAdditionalReview)
      return (
        <SubmitButton style={{ marginTop: 16 }} onClick={handleSubmit}>
          Return for Additional Review
        </SubmitButton>
      );
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={handleSubmit}
        disableElevation
        disabled={!user}
        className={classes.margin}
      >
        Comment
      </Button>
    );
  };

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ paddingLeft: 8 }}
          className={'hide-on-print'}
        >
          <Grid item>
            <Avatar color="primary" className={classes.avatar} alt={user.name} />
          </Grid>
          <Grid item>
            <Typography className={classes.username}>{user.name || 'No User'},&nbsp;</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.company} color="textSecondary">
              {company}
            </Typography>
          </Grid>
        </Grid>
        <div className={`${classes.root} hide-on-print`}>
          <TextField
            disabled={isDisabled()}
            required
            id="outlined-multiline-static"
            label="Type here..."
            multiline
            rows={6}
            variant="outlined"
            fullWidth
            value={inputComment}
            onChange={(e) => {
              setInputComment(e.target.value);
            }}
          />
          {returnForAdditionalReview && (
            <Grid container justify="flex-end" spacing={1}>
              <Grid item>
                <Typography className={classes.datepickerLabel}>due date for consultant</Typography>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  InputProps={{ style: { padding: 0 } }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{ style: { padding: '8px' } }}
                  disablePast
                  maxDate={document?.dueDate ? parseDate(document.dueDate) : undefined}
                  minDate={getTomorrowDate()}
                  value={selectedDate}
                  ToolbarComponent={(props) => (
                    <div>
                      <DatePickerToolbar {...props} />
                      {document?.dueDate && (
                        <Typography
                          style={{
                            position: 'absolute',
                            top: '34px',
                            right: '28px',
                            color: '#FFF',
                          }}
                        >
                          Due date:
                          <br />
                          {parseDate(document.dueDate).format('MM/DD')}
                        </Typography>
                      )}
                    </div>
                  )}
                  placeholder="mm/dd/yyyy"
                  format="MM/DD/YYYY"
                  onChange={(date) => setSelectedDate(date)}
                  className={classes.datepicker}
                />
              </Grid>
            </Grid>
          )}
          <Grid container justify={isLoading ? 'center' : 'flex-end'} spacing={1}>
            <Grid item>{isLoading ? <CircularLoader /> : getButton()}</Grid>
          </Grid>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default CommentTextfield;
