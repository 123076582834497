import { isPublicPage } from '../../scripts/utils';
import { getCompaniesByProjectId } from '../../models/api/companies';
import { Dispatch } from 'react';
import { SET_COMPANIES } from './constants';
import { Companies } from './companies';
import { action } from 'typesafe-actions';

export const setCompanies = (companies: Companies) => {
  return action(SET_COMPANIES, companies);
};

export const fetchCompanies = (projectId: string) => (dispatch: Dispatch<any>) => {
  if (!isPublicPage()) {
    getCompaniesByProjectId(projectId).then((response) =>
      dispatch({ type: SET_COMPANIES, payload: response }),
    );
  }
};
