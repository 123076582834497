import React from 'react';
import { Checkbox, CheckboxProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { IndeterminateCheckBox } from '@material-ui/icons';

export const GreenCheck = withStyles({
  root: {
    '&$checked': {
      color: '#2BB073',
    },
  },
  checked: {},
})((props: CheckboxProps) => (
  <Checkbox
    color="default"
    indeterminateIcon={<IndeterminateCheckBox htmlColor="#2BB073" />}
    {...props}
  />
));
