import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Grid, Hidden, IconButton, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Box from '@material-ui/core/Box';
import UndoIcon from '@material-ui/icons/Undo';
import {
  DocumentTemplateType,
  FileCategoryType,
  INumberedDocumentView,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import { removeDocumentFromPackageByDocumentId } from '../../models/api/documents';
import AddSubmittalToPackageDialog from '../dialogs/AddSubmittalToPackageDialog';
import {
  ascendingComparator,
  descendingComparator,
  formatSubmittalNumber,
} from '../document-index/DocumentIndexUtils';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentState } from '../../features/document/selectors';
import { openInNewTab } from '../../scripts/utils';
import _ from 'lodash';
import { addSnackbar } from '../../features/snackbar/actions';
import { getDocumentsType } from '../../features/documents/selectors';

const useStyles = makeStyles({
  root: {
    height: '409px',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 'auto',
    width: 'auto',
    paddingLeft: '16px',
    paddingBottom: '0px',
    borderBottom: '1px solid #EDECEC',
  },
  inlineBox: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexBasis: 'auto',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: 'auto',
  },
  table: {
    minWidth: '750px',
    maxWidth: '100%',
  },
  cellPackage: {
    maxWidth: '15%',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    textTransform: 'none',
    color: '#464546',
  },
  // style for "Undo" icon on table for the conversion to individual submittal
  centerIcon: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: 'auto',
    flexWrap: 'nowrap',
    height: '100%',
    justifyContent: 'flex-end',
  },
  icon: {
    color: '#7A797A',
    cursor: 'pointer',
  },
  index: {
    width: 40,
  },
  listItem: {
    height: 40,
    transition: '0.09s',
    display: 'flex',
    '&:hover': {
      background: '#0947B9',
      color: 'white',
    },
    '@global > div > button': {
      color: '#949494',
    },
    '&:hover > div > button': {
      color: '#FF9C17',
    },
  },
  submittalId: {
    width: 215,
    paddingRight: 24,
  },
  description: {
    width: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    webkitLineClamp: 2 /* number of lines to show */,
    WebkitBoxOrient: 'vertical',
    maxLines: 2,
    maxHeight: 36,
    lineClamp: 2,
  },
  undoIconContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingRight: 8,
    color: '#949494',
  },
  buttonContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'center',
    color: '#2C69D6',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: 8,
  },
  paginationCenter: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

export default function SubmittalsInThisPackage() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const document = useSelector(getDocumentState);
  const docType = useSelector(getDocumentsType);

  const [packageDocuments, setPackageDocuments] = useState<INumberedDocumentView[]>([]);
  const [addSubmittalDialogOpen, setAddSubmittalDialogOpen] = useState(false);

  const [page, setPage] = useState(0);
  useEffect(() => {
    if (document?.submittalChildDocuments) {
      if (
        docType === DocumentTemplateType.Submittals ||
        docType === DocumentTemplateType.CloseoutSubmittals
      )
        setPackageDocuments([
          ...document?.submittalChildDocuments?.sort((a, b) =>
            formatSubmittalNumber(a).localeCompare(formatSubmittalNumber(b), undefined, {
              numeric: true,
            }),
          ),
        ]);
      else if (docType === DocumentTemplateType.AsBuilt)
        setPackageDocuments([
          ...document?.submittalChildDocuments?.sort((a, b) =>
            ascendingComparator(a, b, 'pageNumber'),
          ),
        ]);
    }
  }, [document, docType]);

  const removeSubmittal = (
    e: React.MouseEvent<HTMLButtonElement>,
    packageDocument: INumberedDocumentView,
  ) => {
    e.stopPropagation();
    // @ts-ignore
    removeDocumentFromPackageByDocumentId(packageDocument.id).then(() => {
      const temp = [...packageDocuments];
      const index = temp.findIndex((item) => item.id === packageDocument.id);
      temp.splice(index, 1);
      setPackageDocuments(temp);
    });
  };

  const addDocument = (submittal: INumberedDocumentView) => {
    const temp = [...packageDocuments];
    temp.push(submittal);
    setPackageDocuments(temp);
  };

  const handleChangePageBig = (event: unknown, newPage: number) => {
    setPage((newPage - 1) * 2);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleOpenFile = (document: INumberedDocumentView) => {
    const sortedFiles = document.files?.sort((a, b) => descendingComparator(a, b, 'createdOn'));
    const fileId =
      document.submittalSectionFileId ||
      document.drawingsSectionFileId ||
      _.nth(sortedFiles, 0)?.id;
    if (fileId) openInNewTab(fileId);
    else
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'This document has no files to open',
          severity: 'warning',
        }),
      );
  };

  const mapPackageDocuments = (pageNum: number) => {
    if (packageDocuments) {
      if (packageDocuments.length > pageNum * 7) {
        const documentsOnPage = packageDocuments.slice(
          pageNum * 7,
          Math.min(pageNum * 7 + 7, packageDocuments.length),
        );
        return documentsOnPage.map((item, index) => {
          return (
            <ListItem
              key={item.id}
              onClick={() => handleOpenFile(item)}
              className={classes.listItem}
              style={{ cursor: 'pointer' }}
            >
              <Typography variant="body1" color="inherit" className={classes.index}>
                {index + 1 + pageNum * 7}.
              </Typography>
              <Typography variant="body1" color="inherit" className={classes.submittalId}>
                <b>
                  {docType === DocumentTemplateType.AsBuilt
                    ? item.sheetNumber
                    : formatSubmittalNumber(item)}
                </b>
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                className={classes.description}
                style={{ width: docType === DocumentTemplateType.AsBuilt ? '100%' : 'initial' }}
              >
                {item.title || 'N/A'}
              </Typography>
              {docType !== DocumentTemplateType.AsBuilt && (
                <Box className={classes.undoIconContainer}>
                  <Tooltip title="Convert to individual submittal" arrow placement="right">
                    <IconButton onClick={(e) => removeSubmittal(e, item)}>
                      <UndoIcon color="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </ListItem>
          );
        });
      }
    }
    return <div />;
  };

  const getTitle = () => {
    if (docType === DocumentTemplateType.AsBuilt) return 'As-Builts';
    if (docType === DocumentTemplateType.CloseoutSubmittals) return 'Closeout Submittals';
    return 'Submittals';
  };

  const getButtonLabel = () => {
    if (docType === DocumentTemplateType.AsBuilt) return 'As-Built';
    if (docType === DocumentTemplateType.CloseoutSubmittals) return 'Closeout Submittal';
    return 'Submittal';
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Box className={classes.inlineBox}>
          <h2 className="h2" style={{ textAlign: 'left' }}>
            {getTitle()} In This Package
          </h2>
          {document?.workflowStatus === WorkflowStatusType.Initial && (
            <Box className={classes.buttonContainer}>
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: 64 }}
                startIcon={<AddIcon />}
                onClick={() => setAddSubmittalDialogOpen(true)}
              >
                Add {getButtonLabel()}
              </Button>
            </Box>
          )}
        </Box>
      </CardContent>
      <Hidden lgDown>
        <Box className={classes.column}>
          <Grid container>
            <Grid xs={6}>
              <List dense>{mapPackageDocuments(page)}</List>
            </Grid>
            <Grid xs={6}>
              <List dense>{mapPackageDocuments(page + 1)}</List>
            </Grid>
          </Grid>
          <Box className={classes.paginationCenter}>
            {Math.ceil(packageDocuments.length / 14) > 1 && (
              <Pagination
                count={Math.ceil(packageDocuments.length / 14)}
                shape="rounded"
                color="primary"
                showFirstButton
                showLastButton
                page={Math.floor(page / 2) + 1}
                onChange={handleChangePageBig}
                className={classes.tablePagination}
              />
            )}
          </Box>
        </Box>
      </Hidden>
      <Hidden xlUp>
        <Box className={classes.column}>
          <Box>
            <List dense>{mapPackageDocuments(page)}</List>
          </Box>
          <Box className={classes.paginationCenter}>
            {Math.ceil(packageDocuments.length / 7) > 1 && (
              <Pagination
                count={Math.ceil(packageDocuments.length / 7)}
                shape="rounded"
                color="primary"
                showFirstButton
                showLastButton
                page={page + 1}
                onChange={handleChangePage}
                className={classes.tablePagination}
              />
            )}
          </Box>
        </Box>
      </Hidden>
      <AddSubmittalToPackageDialog
        open={addSubmittalDialogOpen}
        handleAddDocument={addDocument}
        handleClose={() => {
          setAddSubmittalDialogOpen(false);
        }}
      />
    </Card>
  );
}
