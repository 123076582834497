import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  CircularProgress,
  // Dialog,
  // DialogTitle,
  // Typography,
  // IconButton,
  // DialogContent,
  makeStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Add, HighlightOffRounded, ArrowBack } from '@material-ui/icons';
import { Key, ReactChild, ReactFragment, ReactPortal, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IProjectUser } from '../../api-client/autogenerated';
import { ISecurityGroup, IUserSimplified } from '../../api-client/autogenerated/api';
import { getProjectState } from '../../features/project/selectors';
import { getUserState } from '../../features/user/selectors';
// import { getSubscriberById } from '../../models/api/subscribers';
// import { sortLastNamesDesc } from '../../scripts/utils';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import { UserActionType } from '../form-pages/AddOrManageProjectPage';

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '50px',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
    minWidth: 500,
  },
  bottomButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '50px',
    borderTop: '1px solid #EDECEC',
    minWidth: 500,
    padding: 0,
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'center',
    color: '#0947B9',
    textTransform: 'uppercase',
    justifySelf: 'center',
  },
});

type AddPreviousUsersTableProps = {
  handleBack: () => void;
  createProjectUsers: (projectUser: IProjectUser[], action?: UserActionType) => void;
  //used to filter out users in the table that are already in the project
  users: IProjectUser[] | undefined;
  simplifiedUsers: IUserSimplified[] | undefined;
  securityGroups: ISecurityGroup[];
  handleUsersAdded: () => void;
};

export default function AddPreviousUsersTable(props: AddPreviousUsersTableProps) {
  function createData(
    id: number,
    name: string,
    companyName: string,
    user: IUserSimplified,
    isChecked: boolean = false,
  ) {
    return { id, name, companyName, securityGroupId: user.securityGroupId, user, isChecked };
  }

  const {
    handleBack,
    createProjectUsers,
    users,
    simplifiedUsers,
    securityGroups,
    handleUsersAdded,
  } = props;

  // const TestUser = '205f1f19-1b2c-4a9d-a4aa-3c46950b27f4'; //This is a subscriber ID
  // const subscriberId = useSelector(getProjectState);
  const authUser = useSelector(getUserState);
  // const TestUser2 = '87dd7837-fca7-4a8a-9571-dc1fb3f518bc';

  const [rows, setRows] = useState<any[]>([]);

  const [isNameDesc, setNameDesc] = useState<boolean>(true);
  const [isCompanyDesc, setCompanyDesc] = useState<boolean>(true);
  const classes = useStyles();

  function compareEntries(
    a: { name: string; companyName: string },
    b: { name: string; companyName: string },
  ) {
    var companyNameA = a.companyName.toUpperCase();
    var companyNameB = b.companyName.toUpperCase();
    var nameA = a.name.toUpperCase();
    var nameB = b.name.toUpperCase();
    let comparison = 0;

    comparison = companyNameA < companyNameB ? 1 : -1;
    if (isCompanyDesc) comparison = -comparison;

    if (companyNameA === companyNameB) {
      comparison = nameA < nameB ? 1 : -1;
      if (isNameDesc) comparison = -comparison;
    }

    return comparison;
  }

  function handleClick(index: number) {
    setRows((prevState: string | any[]) => [
      ...(prevState?.slice(0, index) || []),
      {
        ...prevState[index],
        isChecked: prevState ? !prevState[index].isChecked : false,
      },
      ...(prevState?.slice(index + 1) || []),
    ]);
  }

  rows.sort(compareEntries);

  useEffect(() => {
    if (simplifiedUsers) {
      const allUsers = simplifiedUsers.filter((user) => {
        let alreadyAdded = false;

        users?.forEach((element) => {
          if (element!.user!.id === user.id) {
            alreadyAdded = true;
          }
        });

        return !alreadyAdded;
      });
      setRows(
        allUsers.map((user: IUserSimplified, index: number) =>
          createData(
            index,
            user.name,
            user.company?.name === undefined ? '-' : user.company?.name,
            user,
          ),
        ),
      );
    }
  }, [simplifiedUsers]);

  const handleAddUsersPressed = () => {
    const selectedRows = rows.filter((x) => x.isChecked);
    if (selectedRows.length > 0) {
      const usersToAdd: IProjectUser[] = [];
      selectedRows.forEach((x) => {
        usersToAdd.push({
          user: x.user,
          securityGroupId: x.securityGroupId,
          securityGroup: securityGroups.find((y) => y.id === x.securityGroupId),
        } as IProjectUser);
      });
      createProjectUsers(usersToAdd);
      handleUsersAdded();
    }
  };

  const getDirection = (desc: boolean) => {
    if (desc) {
      return 'desc';
    }
    return 'asc';
  };

  const updateSelectedSecurityGroup = (newSecurityGroupId: string, index: number) => {
    if (newSecurityGroupId !== rows[index].securityGroupId) {
      const rowsTemp = [...rows];
      rowsTemp[index].securityGroupId = newSecurityGroupId;
      rowsTemp[index].isChecked = true;
      setRows(rowsTemp);
    }
  };

  let dialogContent =
    rows && rows.length > 0 ? (
      <TableContainer style={{ maxHeight: '450px' }}>
        <Table aria-label="dense">
          <TableHead>
            <TableCell padding="checkbox">
              <GreenCheck
                onChange={(e) => {
                  setRows((prevState: any[]) =>
                    prevState.map((row: any) => {
                      return { ...row, isChecked: e.target.checked };
                    }),
                  );
                }}
              />
            </TableCell>
            <TableCell
              align="left"
              onClick={() => {
                setCompanyDesc(!isCompanyDesc);
              }}
            >
              <TableSortLabel active={true} direction={getDirection(isCompanyDesc)}>
                Company Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="left"
              onClick={() => {
                setNameDesc(!isNameDesc);
              }}
            >
              <TableSortLabel active={true} direction={getDirection(isNameDesc)}>
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel>Security Group</TableSortLabel>
            </TableCell>
          </TableHead>
          <TableBody>
            {rows.map(
              (
                row: {
                  id: Key | null | undefined;
                  isChecked: boolean | undefined;
                  name: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
                  companyName:
                    | boolean
                    | ReactChild
                    | ReactFragment
                    | ReactPortal
                    | null
                    | undefined;
                  securityGroupId: string | undefined;
                },
                index: number,
              ) => {
                return (
                  <TableRow hover key={row.id} role="checkbox">
                    <TableCell padding="checkbox">
                      <GreenCheck
                        onClick={() => handleClick(index)}
                        checked={row.isChecked}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="left">{row.companyName}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">
                      <FormControl
                        variant="outlined"
                        size="small"
                        style={{ marginTop: 4, marginBottom: 4 }}
                      >
                        <Select
                          id="security-group-dropdown"
                          value={row.securityGroupId}
                          style={{ width: 250 }}
                          onChange={(event) =>
                            updateSelectedSecurityGroup(event.target.value as string, index)
                          }
                        >
                          {securityGroups?.map((group) => {
                            return (
                              <MenuItem key={group.id} value={group.id!}>
                                {group.name!}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <Typography style={{ paddingLeft: 24, paddingTop: 16, paddingBottom: 32 }}>
        No available users from past projects.
      </Typography>
    );

  return (
    <div>
      {!simplifiedUsers ? (
        <div
          style={{ width: '100%', margin: '20px 0px', display: 'flex', justifyContent: 'center' }}
        >
          <CircularProgress size="40px" thickness={3} color="primary" />
        </div>
      ) : (
        dialogContent
      )}
      <div
        style={{
          width: '100%',
          paddingTop: 16,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button variant="outlined" startIcon={<ArrowBack />} onClick={handleBack}>
          Back
        </Button>
        <Button
          onClick={handleAddUsersPressed}
          variant="contained"
          color="primary"
          disabled={!rows?.find((x) => x.isChecked)}
          style={{
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <Add style={{ paddingRight: 2 }} />
          Add Users
        </Button>
      </div>
    </div>
  );
}
