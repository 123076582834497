import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { HighlightOffRounded } from '@material-ui/icons';
import CommentTextfield from '../comment/CommentTextfield';
import {
  IComment,
  IInsertionComment,
  RespondToReviewPropsResponseEnum,
} from '../../api-client/autogenerated';
import CommentSection from '../comment/CommentSection';
import { descendingComparator } from '../document-index/DocumentIndexUtils';
import {
  InsertConsultantConfidentialCommentByDocumentId,
  modifyDocumentById,
  respondToReview,
} from '../../models/api/documents';
import { Dayjs } from 'dayjs';
import { insertCommentByDocumentId } from '../../models/api/comments';
import { useDispatch, useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import { reloadDocument, updateDocument } from '../../features/document/actions';
import { getDocumentState } from '../../features/document/selectors';

interface CommentsDialogProps {
  dialogOpen: boolean;
  handleClose: () => void;
  comments: IComment[];
  returnForAdditionalReview: boolean;
  documentId: string;
  commentType: 'single' | 'shared';
}

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 16px 16px 16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'uppercase',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  paper: {
    width: '100%',
  },
});

export default function CommentsDialog(props: CommentsDialogProps) {
  const classes = useStyles();
  const {
    dialogOpen,
    handleClose,
    comments,
    documentId,
    returnForAdditionalReview,
    commentType,
  } = props;

  const dispatch = useDispatch();
  const document = useSelector(getDocumentState);
  const user = useSelector(getUserState);

  const handleSubmit = async (comment: string, selectedDate?: Dayjs | null) => {
    if (returnForAdditionalReview && documentId) {
      await respondToReview(documentId, {
        response: RespondToReviewPropsResponseEnum.Resubmitted,
      }).then((newDoc) => {
        if (selectedDate) {
          void modifyDocumentById(newDoc.revisedDocument!.id, {
            patch: {
              dueDate: selectedDate.set('hours', 0).set('minutes', 0).toISOString(),
            },
          });
        }
      });
    }

    let newComment: IComment | null = null;

    if (comment) {
      const insertionComment: IInsertionComment = {
        text: comment,
        creatorUserId: user.id,
      };
      if (commentType === 'single') {
        newComment = await insertCommentByDocumentId(documentId, insertionComment);
      } else {
        newComment = await InsertConsultantConfidentialCommentByDocumentId(
          documentId,
          insertionComment,
        );
      }
    }

    if (newComment && commentType === 'shared' && document) {
      dispatch(
        updateDocument({ ...document, comments: [...(document.comments || []), newComment] }),
      );
    } else {
      dispatch(reloadDocument());
    }

    handleClose();
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      maxWidth="sm"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title}>
          {returnForAdditionalReview ? 'LEAVE CONSULTANT FEEDBACK' : 'Comments'}
        </Typography>
        <IconButton
          style={{ right: '20px', top: '5px', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CommentSection
          comments={comments.sort((a, b) => descendingComparator(a, b, 'createdOn'))}
        />
        <CommentTextfield
          returnForAdditionalReview={returnForAdditionalReview}
          onSubmit={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
}
