import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  DocumentTemplateType,
  IConformingCenterProjectSummary,
} from '../../api-client/autogenerated';
import SubmittalPlaceholderQuestionDialog from './SubmittalPlaceholderQuestionDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import { getSummaryObjectByType, PublishType } from './DCCUtils';
import { generatePlaceholders } from '../../models/api/dcc';
import { addSnackbar } from '../../features/snackbar/actions';
import { useHistory } from 'react-router-dom';
import { Add, HighlightOffRounded } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { documentTypeToUrl } from '../../scripts/utils';

type Props = {
  open: boolean;
  handleClose: () => void;
  summary: IConformingCenterProjectSummary;
  type: DocumentTemplateType.Submittals | DocumentTemplateType.AsBuilt;
};

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '30px',
      color: '#0947B9',
      textTransform: 'none',
    },
    rootIconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
);

export default function CreatePlaceholdersDialog(props: Props) {
  const classes = useStyles();
  const { open, handleClose, summary, type } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const project = useSelector(getProjectState);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateSubmittals = async (generateForFirstDivisions: boolean) => {
    if (!project) return;

    setIsLoading(true);

    try {
      const documentIds =
        getSummaryObjectByType(summary, PublishType.Specifications)?.published.documentIds || [];

      const generatedPlaceholderDocumentIds = await generatePlaceholders(documentIds, {
        ignoredSpecificationDivisions: !generateForFirstDivisions ? ['00', '01'] : [],
      });

      dispatch(
        addSnackbar({
          id: Date.now(),
          message: `Successfully generated ${generatedPlaceholderDocumentIds.length} placeholders!`,
          severity: 'success',
        }),
      );

      history.push(`/main/projects/${project!.id}/pub-center/conform-submittals`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateAsBuilts = async () => {
    if (!project) return;

    setIsLoading(true);

    try {
      const documentIds =
        getSummaryObjectByType(summary, PublishType.Drawings)?.published.documentIds || [];

      const generatedPlaceholderDocumentIds = await generatePlaceholders(documentIds);

      dispatch(
        addSnackbar({
          id: Date.now(),
          message: `Successfully generated ${generatedPlaceholderDocumentIds.length} placeholders!`,
          severity: 'success',
        }),
      );

      history.push(
        `/main/projects/${project!.id}/documents/${
          documentTypeToUrl[DocumentTemplateType.AsBuilt]
        }`,
      );
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ paddingLeft: 16, paddingTop: 12 }}>
          <Typography className={classes.title}>
            {type === DocumentTemplateType.Submittals ? 'Submittals' : 'As-Built Placeholders'}
          </Typography>
          <IconButton
            style={{ right: '12px', top: '12px', position: 'absolute' }}
            onClick={handleClose}
            classes={{
              root: classes.rootIconButton,
            }}
          >
            <HighlightOffRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {type === DocumentTemplateType.Submittals ? (
            <>
              <Typography align="center">
                We found {summary?.submittalSummary?.beforeUnpublished?.numDocuments} submittals in
                your published specifications.
              </Typography>
              <Typography align="center" style={{ marginTop: 8 }}>
                Would you like to create Submittal placeholders for these items?
              </Typography>
              <Typography align="center" style={{ marginTop: 8 }}>
                Customize Submittal placeholders from the Submittals page at any time.
              </Typography>
            </>
          ) : (
            <>
              <Typography align="center">
                We found {summary?.numAsBuiltsBeforeUnpublishedWithPublishedDrawing} drawings in
                your uploaded drawings document.
              </Typography>
              <Typography align="center" style={{ marginTop: 8 }}>
                Would you like to create As-Built placeholders for each one?
              </Typography>
              <Typography align="center" style={{ marginTop: 8 }}>
                Customize As-Built placeholders in the Close Out tab.
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around', paddingBottom: 16 }}>
          {type === DocumentTemplateType.Submittals ? (
            <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)}>
              Review Submittal Placeholders Before Publishing
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={handleCreateAsBuilts}
            >
              Create As-Built Placeholders
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <SubmittalPlaceholderQuestionDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        submit={(res) => handleCreateSubmittals(res)}
      />
    </>
  );
}
