import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router';
import { createStyles, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { makeStyles } from '@material-ui/core/styles';
import { HighlightOffRounded } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { CancelButton } from './CustomButtons';

type Props = {
  shouldBlock: boolean;
  deletedItemsCount: number;
  isCustom?: boolean;
  onDelete: () => Promise<void>;
};

const useStyles = makeStyles(() =>
  createStyles({
    rootIconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
);

export default function DCCNavigationBlocker(props: Props) {
  const classes = useStyles();
  const { shouldBlock, deletedItemsCount, isCustom, onDelete } = props;

  const location = useLocation();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const [lastLocation, setLastLocation] = useState<Location>(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setOpen(false);
    setShouldUnload(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  const showModal = (nextLocation: Location) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation: Location) => {
    if (!confirmedNavigation && shouldBlock) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    setConfirmedNavigation(true);
  };

  const handleDelete = async () => {
    await onDelete();
    handleConfirmNavigationClick();
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = (event: BeforeUnloadEvent) => {
      if (shouldBlock && !shouldUnload) {
        event.returnValue = 'Something';
      }
      if (shouldUnload) {
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [shouldBlock, shouldUnload]);

  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      <Dialog open={open}>
        <IconButton
          style={{ right: '12px', top: '10px', position: 'absolute' }}
          onClick={closeModal}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
        <DialogContent style={{ marginTop: 32 }}>
          <Typography align="center">
            {!isCustom
              ? `You have ${deletedItemsCount} items waiting to be deleted.`
              : 'You have pending custom items that will be lost when navigating away from this page.'}
          </Typography>
          <Typography align="center" style={{ marginTop: 8 }}>
            {!isCustom
              ? 'Please restore or permanently delete these items before navigating away from this page.'
              : 'Please publish or delete these items before leaving.'}
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around', marginTop: 16 }}>
          {!isCustom ? (
            <>
              <CancelButton startIcon={undefined} onClick={closeModal} />
              <Button
                color="primary"
                variant="contained"
                onClick={handleConfirmNavigationClick}
                style={{ padding: '0px 16px', lineHeight: 1 }}
              >
                Restore All
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleDelete}
                style={{ padding: '0px 16px', backgroundColor: '#ED3F26' }}
              >
                Delete All
              </Button>
            </>
          ) : (
            <Button color="primary" variant="contained" onClick={closeModal}>
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
