import React, { useEffect, useState } from 'react';
import { IProjectUser } from '../../api-client/autogenerated';
import { useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import { getPrimeBiddersByProject } from '../../models/api/project';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PrimeBidderRow from '../../bidding-components/prime-bidders/PrimeBidderRow';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: '100%',
      width: '100%',
      marginBottom: 32,
    },
    list: {
      padding: '0px 16px 16px 16px',
      overflow: 'auto',
    },
    textPrimary: {
      color: '#464546',
    },
    textSecondary: {
      color: '#464546',
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

export default function PrimeBiddersList() {
  const classes = useStyles();
  const currentProject = useSelector(getProjectState);
  const [page, setPage] = useState(0);
  const handleChange = (event: any, value: number) => {
    setPage(value - 1);
  };

  const [primeBidders, setPrimeBidders] = useState<IProjectUser[]>([]);

  useEffect(() => {
    if (currentProject)
      getPrimeBiddersByProject(currentProject.id)
        .then(({ results }) => setPrimeBidders(results))
        .catch(() => setPrimeBidders([]));
  }, [currentProject]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <h2 className="h2" style={{ color: '#0947B9', fontSize: '26px', fontWeight: 600 }}>
          Prime Bidders
        </h2>
      </CardContent>
      {primeBidders.length === 0 ? (
        <Typography style={{ paddingBottom: 16, paddingLeft: 16 }}>
          There are no prime bidders for this project.
        </Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead style={{ color: 'rgb(97, 96, 97)' }}>
              <TableCell variant="head">License #</TableCell>
              <TableCell variant="head">Company</TableCell>
              <TableCell variant="head">Contact</TableCell>
              <TableCell width={1} />
            </TableHead>
            <TableBody>
              {primeBidders
                .slice(page * 5, Math.min(primeBidders.length, page * 5 + 5))
                .map((row) => (
                  <PrimeBidderRow key={row.id} data={row} setPrimeBidders={setPrimeBidders} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <div style={{ flexGrow: 1 }} />
      {Math.ceil(primeBidders.length / 5) > 1 && (
        <Pagination
          count={Math.ceil(primeBidders.length / 5)}
          page={page + 1}
          style={{ paddingTop: 32 }}
          classes={{ ul: classes.center }}
          onChange={handleChange}
        />
      )}
    </Card>
  );
}
