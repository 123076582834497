import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Grid, Toolbar, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

interface LogCardProps {
  headerColor?: string;
  icon?: JSX.Element;
  title?: string;
  children?: React.ReactNode;
  hideChildren?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      minHeight: 33,
      paddingLeft: 16,
      paddingBottom: 6,
      paddingTop: 8,
      color: 'white',
      fontSize: 20,
      fontWeight: 500,
      background: (props: LogCardProps) => props.headerColor!,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    root: {
      boxShadow: 'none',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardContent: {
      padding: 0,
      paddingBottom: '0px !important',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    body: {
      minHeight: 100,
    },
    flexGrow: {
      flexGrow: 1,
      whiteSpace: 'nowrap',
    },
  }),
);

export default function LogCard(props: LogCardProps) {
  const {
    icon = <WarningIcon />,
    title = 'Overdue',
    children = <div />,
    hideChildren = false,
  } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.header}>
          <div className={classes.icon}>{icon}</div>
          <Typography variant="h6" className={classes.flexGrow}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      {!hideChildren ? (
        <Grid container justify="flex-start" className={classes.flexGrow}>
          <Grid item xs={12} className={classes.body}>
            {children}
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
}
