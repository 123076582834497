import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  message?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const useStyles = makeStyles({
  message: {
    color: '#464546', // gray 700
  },
});

export default function MessageText(props: Props) {
  const classes = useStyles();
  const { message, ...rest } = props;

  return (
    <span className={classes.message} {...rest}>
      {message}
    </span>
  );
}
