import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Delete, FormatListBulletedOutlined, ReplayOutlined } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { IUser } from '../../api-client/autogenerated';
import EditUserDialog from '../dialogs/EditUserDialog';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import { getUserState } from '../../features/user/selectors';
import UserActivityDialog from '../dialogs/UserActivityDialog';
import { deleteInviteEmailByProjectIdAndUserId } from '../../models/api/users';
import { getProjectState } from '../../features/project/selectors';
import { ISecurityGroup } from '../../api-client/autogenerated';
import { getCurrentSecurityGroup } from '../../features/security/selectors';

const useStyles = makeStyles(() => ({
  cellCheckbox: {
    maxWidth: '7%',
    border: 'none',
  },
  cellNumber: {
    maxWidth: '10%',
    border: 'none',
  },
  numberText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '22px',
    color: '#464546',
    cursor: 'pointer',
  },
  cellTitle: {
    height: '60px',
    border: 'none',
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    textTransform: 'none',
    color: '#464546',
    paddingRight: 32,
  },
  cellDescription: {
    maxWidth: '400px',
    border: 'none',
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    textTransform: 'none',
    color: '#464546',
    paddingRight: 32,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  cellEmail: {
    maxWidth: '20%',
    border: 'none',
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    textTransform: 'none',
    color: '#464546',
    paddingRight: 32,
  },
  cellIssueDate: {
    maxWidth: '11%',
    border: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21px',
    color: '#464546',
  },
  cellDueDate: {
    maxWidth: '10%',
    border: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21px',
    color: '#464546',
  },
  cellStatus: {
    maxWidth: '15%',
    border: 'none',
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    textTransform: 'none',
    color: '#464546',
  },
  cellStatusRed: {
    maxWidth: '15%',
    border: 'none',
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '22px',
    textTransform: 'none',
    color: '#ED3F26',
  },
  cellStatusGreen: {
    maxWidth: '15%',
    border: 'none',
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '22px',
    textTransform: 'none',
    color: '#2BB073',
  },
  cellFile: {
    whiteSpace: 'nowrap',
    maxWidth: '27%',
    border: 'none',
  },
  fileText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    color: '#464546',
  },
  dateText: {
    whiteSpace: 'pre-wrap',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '21px',
    color: '#7A797A',
  },
  timeText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '21px',
    color: '#7A797A',
  },
  collapseContainer: {
    display: 'flex',
    padding: '16px',
    background: '#F3F7FF',
  },
  editButton: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 4,
  },
  tableRow: {
    transition: '0.1s',
    '&:hover': {
      background: '#E5EEFE !important',
    },
  },
}));

interface UserIndexRowProps {
  row: any;
  index: number;
  createProjectPage?: boolean;
  handleClick: (event: React.MouseEvent<unknown>, name: string, locationGroup: Array<any>) => void;
  isSelected: (name: string) => boolean;
  handleDelete?: (id: string) => void;
  securityGroups: ISecurityGroup[];
  updateUserRow: (
    id: string,
    name: string | undefined,
    description: string | undefined,
    email: string | undefined,
    website: string | undefined,
    securityGroupId: string | undefined,
  ) => void;
  isDirectory?: boolean;
}

export default function UserIndexRow(props: UserIndexRowProps) {
  const classes = useStyles();
  const {
    row,
    index,
    handleClick,
    isSelected,
    createProjectPage,
    handleDelete = () => {},
    securityGroups,
    updateUserRow,
    isDirectory = false,
  } = props;

  const [collapseOpen, setCollapseOpen] = useState(false);

  const isItemSelected = isSelected(row.id);
  const user = useSelector(getUserState);
  const security = useSelector(getCurrentSecurityGroup);
  const labelId = `enhanced-table-checkbox-${index}`;

  // if the row is selected, the background should be light green
  // If not, the background should alternate shades of gray
  let rowColor = '#EFFFF5';
  if (!isItemSelected) {
    if (index % 2 === 0) {
      rowColor = '#FFFFFF';
    } else {
      rowColor = '#F9F9F9';
    }
  }
  if (row.isDisabled === true) {
    rowColor = '#dcdcdc';
  } else if (row.isRegistered === false) {
    rowColor = '#ffd3d3';
  }
  const background = collapseOpen ? rowColor : rowColor;

  const [updateUserDialogOpen, setUpdateUserDialogOpen] = useState(false);
  const [userActivityDialogOpen, setUserActivityDialogOpen] = useState(false);

  const project = useSelector(getProjectState);

  const [inviteResent, setInviteResent] = useState(false);

  const handleUpdateUserDialogOpen = () => {
    setUpdateUserDialogOpen(true);
  };

  const handleUpdateUserDialogClose = () => {
    setUpdateUserDialogOpen(false);
  };

  const resendInvite = () => {
    deleteInviteEmailByProjectIdAndUserId(project!.id, row.id).then(() => setInviteResent(true));
  };

  const getButtons = () => {
    const buttons: JSX.Element[] = [];
    if (row.isInvited && !row.isRegistered) {
      if (!inviteResent)
        buttons.push(
          <Button
            onClick={resendInvite}
            className={classes.editButton}
            style={{ marginRight: 10, width: 150 }}
            variant="contained"
            color="primary"
          >
            <ReplayOutlined className={classes.icon} />
            Resend Invite
          </Button>,
        );
      else
        buttons.push(
          <Typography style={{ marginRight: 16 }} variant="body2">
            Invite sent.
          </Typography>,
        );
    }
    if (
      createProjectPage &&
      (user.isSiteAdmin ||
        user.adminOfSubscriberId !== null ||
        !!security?.manageProjectUsersPermission)
    ) {
      buttons.push(
        <Button
          onClick={handleUpdateUserDialogOpen}
          className={classes.editButton}
          variant="contained"
          color="primary"
        >
          <EditOutlinedIcon className={classes.icon} />
          Edit
        </Button>,
        <Tooltip arrow placement="top" title="View Activity Log">
          <IconButton
            onClick={() => setUserActivityDialogOpen(true)}
            style={{ padding: 0, marginLeft: 10 }}
            size="small"
          >
            <FormatListBulletedOutlined />
          </IconButton>
        </Tooltip>,
      );
      if (row.id !== 'me' && row.id !== user.id)
        buttons.push(
          <Tooltip arrow placement="top" title="Remove User From Project">
            <IconButton
              onClick={() => handleDelete!(row.id)}
              style={{ padding: 0, marginLeft: 10 }}
              size="small"
            >
              <Delete />
            </IconButton>
          </Tooltip>,
        );
      else buttons.push(<div style={{ width: 34, height: 24 }} />);
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {buttons}
      </div>
    );
  };

  return (
    <>
      <TableRow
        hover
        onClick={() => setCollapseOpen(!collapseOpen)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.name}
        selected={isItemSelected}
        style={{ height: '56px', background }}
        className={classes.tableRow}
      >
        {!isDirectory ? (
          <TableCell padding="checkbox" className={classes.cellCheckbox}>
            {row.id === 'me' || row.id === user.id ? (
              <div />
            ) : (
              <GreenCheck
                onClick={(event) => {
                  event.stopPropagation();
                  handleClick(event, row.id, []);
                }}
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            )}
          </TableCell>
        ) : null}
        <TableCell aria-label="section-title" align="left" className={classes.cellTitle}>
          {row.isDisabled === true ? (
            <div>
              {row.name}
              <span style={{ color: 'red', fontSize: 13 }}> [Disabled]</span>
            </div>
          ) : row.isRegistered === false ? (
            <div>
              {row.name}
              <span style={{ color: 'red', fontSize: 13 }}> [Not Registered]</span>
            </div>
          ) : (
            <Typography>{row.name}</Typography>
          )}
        </TableCell>
        <TableCell aria-label="issue-date" align="left" className={classes.cellDescription}>
          <div className={classes.cellDescription}>{row.company}</div>
        </TableCell>
        <TableCell aria-label="due-date" align="left" className={classes.cellEmail}>
          {row.email}
        </TableCell>
        <TableCell aria-label="status" align="left" className={classes.cellEmail}>
          {row.isSiteAdmin
            ? 'Site Admin'
            : row.adminOfSubscriberId != null && row.adminOfSubscriberId === project?.subscriberId
            ? 'Subscriber Admin'
            : row.securityGroup != null
            ? row.securityGroup.name
            : 'No role assigned'}
        </TableCell>
        {!isDirectory ? (
          <TableCell aria-label="status" align="right" className={classes.cellEmail}>
            {getButtons()}
          </TableCell>
        ) : null}
      </TableRow>
      {!isDirectory ? (
        <>
          <EditUserDialog
            dialogOpen={updateUserDialogOpen}
            handleClose={handleUpdateUserDialogClose}
            type="update"
            id={row.id}
            name={row.name}
            description={row.description}
            email={row.email}
            website={row.website}
            securityGroups={securityGroups}
            securityGroupId={row.securityGroup ? row.securityGroup.id : undefined}
            updateUserRow={updateUserRow}
          />
          <UserActivityDialog
            open={userActivityDialogOpen}
            handleClose={() => setUserActivityDialogOpen(false)}
            userId={row.id}
            projectId={project?.id}
            name={row.name}
            disablePrint
          />
        </>
      ) : null}
    </>
  );
}
