import { ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import SET_PROJECTS from './constants';
import * as projectsActions from './actions';
import { Projects } from './projects';

export type ProjectsAction = ActionType<typeof projectsActions>;
export type ProjectsState = {
  projects: Projects;
};

export default combineReducers<ProjectsState, ProjectsAction>({
  projects: (state = [], action) => {
    let newState: Projects;
    switch (action.type) {
      case SET_PROJECTS:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
});
