import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import CalendarUpcoming from './CalendarUpcoming';
import NavAppbar from '../nav-top/NavAppbar';
import { useParams } from 'react-router-dom';
import { ProjectMatchParams } from '../../scripts/utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProject } from '../../features/project/actions';
import { getProjectLoadingState } from '../../features/loading/selectors';
import FullscreenLoader from '../loader/FullscreenLoader';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh',
  },
  paper: {
    color: theme.palette.text.secondary,
    height: '100%',
    boxShadow: '5px 5px 15px rgb(0, 0, 0, .15)',
  },
  paperDefaultHeight: {
    color: theme.palette.text.secondary,
    height: '100%',
  },
}));

export default function Calendar() {
  const classes = useStyles();
  const params = useParams<ProjectMatchParams>();
  const dispatch = useDispatch();
  const projectLoading = useSelector(getProjectLoadingState);

  useEffect(() => {
    if (params.projectId) dispatch(fetchProject(params.projectId));
  }, [params.projectId]);

  return (
    <main className={classes.content}>
      <NavAppbar />
      <div className={classes.root}>
        <Grid container spacing={2} style={{ display: 'table' }}>
          <Grid
            xs={12}
            lg={12}
            item
            container
            spacing={2}
            alignItems="stretch"
            direction="row"
            style={{ display: 'inline-flex' }}
          >
            <Grid spacing={2} item xs={12} lg={9}>
              <Grid style={{ paddingBottom: 16 }} item xs={12} lg={12}>
                <Paper className={classes.paper}>
                  {projectLoading ? <FullscreenLoader /> : <CalendarUpcoming />}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          {/* )} */}
        </Grid>
      </div>
    </main>
  );
}
