import React, { SyntheticEvent, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { ItemCoordinates } from '../../api-client/autogenerated';
import { CancelButton, SubmitButton } from '../custom-components/CustomButtons';
import PunchListFlagIcon from '../icons/PunchListFlag-icon';
import { Save } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { addSnackbar } from '../../features/snackbar/actions';
import {
  ReactZoomPanPinchContentRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import Typography from '@material-ui/core/Typography';
import { isDesktop } from 'react-device-detect';

type Props = {
  open: boolean;
  handleClose: () => void;
  file: string;
  existingFlag?: ItemCoordinates | null;
  onSubmit: (result: ItemCoordinates) => void;
};

export default function PunchListItemMarker(props: Props) {
  const { open, handleClose, file, onSubmit, existingFlag } = props;

  const dispatch = useDispatch();

  const dialogRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const transformRef = useRef<ReactZoomPanPinchContentRef>(null);

  const [_originalWidth, _setOriginalWidth] = useState(0);
  const originalWidth = useRef(_originalWidth);
  const setOriginalWidth = (w: number) => {
    originalWidth.current = w;
    _setOriginalWidth(w);
  };

  const [_originalHeight, _setOriginalHeight] = useState(0);
  const originalHeight = useRef(_originalHeight);
  const setOriginalHeight = (h: number) => {
    originalHeight.current = h;
    _setOriginalHeight(h);
  };

  const [isMarkerVisible, setIsMarkerVisible] = useState(false);
  const [pointX, setPointX] = useState(0);
  const [pointY, setPointY] = useState(0);
  const [pointXPercentage, setPointXPercentage] = useState(0);
  const [pointYPercentage, setPointYPercentage] = useState(0);

  const mouseDown = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    const imageWidth = imageRef.current?.width || 0;
    const imageHeight = imageRef.current?.height || 0;
    const scale = transformRef.current?.instance.transformState?.scale || 1;

    const rect = (event.target as HTMLImageElement).getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // Calculate the true coordinates on the original image
    const trueX = x / scale;
    const trueY = y / scale;

    // Make sure the coordinates are within the image bounds
    const boundedTrueX = Math.max(0, Math.min(trueX, imageWidth));
    const boundedTrueY = Math.max(0, Math.min(trueY, imageHeight));

    setIsMarkerVisible(true);
    setPointX(boundedTrueX + (imageRef.current?.offsetLeft || 0));
    setPointY(boundedTrueY + (imageRef.current?.offsetTop || 0));
    const xPercentage = boundedTrueX / (originalWidth.current ?? 1);
    const yPercentage = boundedTrueY / (originalHeight.current ?? 1);
    if (xPercentage > 1 || yPercentage > 1) {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Something went wrong. Please close and re-open the dialog',
          severity: 'error',
        }),
      );
    }

    setPointXPercentage(parseFloat(xPercentage.toFixed(3)));
    setPointYPercentage(parseFloat(yPercentage.toFixed(3)));
  };

  const onLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    const targetWidth = (e.target as HTMLImageElement).naturalWidth;
    const targetHeight = (e.target as HTMLImageElement).naturalHeight;

    setOriginalWidth(targetWidth);
    setOriginalHeight(targetHeight);

    if (existingFlag) {
      setPointX(existingFlag.x * targetWidth + (imageRef.current?.offsetLeft || 0));
      setPointY(existingFlag.y * targetHeight + (imageRef.current?.offsetTop || 0));
    }
  };

  const resetState = () => {
    setOriginalWidth(0);
    setOriginalHeight(0);
    setPointX(0);
    setPointY(0);
    setPointXPercentage(0);
    setPointYPercentage(0);
  };

  const handleSubmit = () => {
    onSubmit({ x: pointXPercentage, y: pointYPercentage });
    resetState();
    handleClose();
  };

  const submitDisabled =
    pointXPercentage === 0 ||
    pointYPercentage === 0 ||
    pointXPercentage > 1 ||
    pointYPercentage > 1;

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      PaperProps={{
        ref: dialogRef,
        style: {
          height: '90vh',
          width: '90vw',
          margin: 16,
        },
      }}
    >
      {isDesktop ? (
        <DialogTitle style={{ borderBottom: '1px solid black' }}>
          <Typography>
            Click and drag with the right mouse button to pan, use the scroll wheel to zoom, and
            click with the left mouse button to place the flag.
          </Typography>
        </DialogTitle>
      ) : null}
      <DialogContent
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <TransformWrapper
          ref={transformRef}
          minScale={0.3}
          panning={{
            allowLeftClickPan: false,
            allowMiddleClickPan: false,
            allowRightClickPan: true,
          }}
          limitToBounds={false}
        >
          <TransformComponent
            contentStyle={{
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            wrapperProps={{ onContextMenu: (e) => e.preventDefault() }}
            wrapperStyle={{ width: '100%', height: '100%' }}
          >
            <img
              ref={imageRef}
              src={file}
              onLoad={onLoad}
              alt=""
              onClick={mouseDown}
              style={{ objectFit: 'contain', pointerEvents: 'all' }}
            />
            <PunchListFlagIcon
              transformed
              color="red"
              style={{
                position: 'absolute',
                display: isMarkerVisible ? 'initial' : 'none',
                left: pointX,
                top: pointY,
                zIndex: 1001,
              }}
            />
          </TransformComponent>
        </TransformWrapper>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', borderTop: '1px solid black' }}>
        <CancelButton onClick={handleClose}>Close</CancelButton>
        <SubmitButton
          disabled={submitDisabled}
          startIcon={<Save />}
          onClick={handleSubmit}
          style={{ paddingLeft: 24, paddingRight: 24 }}
        >
          Submit
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
}
