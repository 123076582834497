import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { Dayjs } from 'dayjs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import FormControl from '@material-ui/core/FormControl';

type Props = {
  open: boolean;
  handleClose: () => void;
  minDate: ParsableDate;
  bidDate?: Dayjs | null;
  setBidDate: (date: Dayjs | null) => void;
};

export default function ModifyBidDateDialog(props: Props) {
  const { open, handleClose, minDate, bidDate, setBidDate } = props;

  const [newBidDate, setNewBidDate] = useState<Dayjs | null>(null);
  const [willChangeDate, setWillChangeDate] = useState(false);

  return (
    <Dialog open={open}>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <DialogContent>
          {willChangeDate ? (
            <FormControl>
              <Typography style={{ marginBottom: 8 }}>
                Current Bid Date: {bidDate?.format('MM/DD/YYYY hh:mm A')}
              </Typography>
              <KeyboardDateTimePicker
                inputVariant="outlined"
                InputProps={{ style: { padding: 0 } }}
                minDate={minDate}
                value={newBidDate}
                onChange={setNewBidDate}
                disablePast
                format="MM/DD/YYYY hh:mm A"
              />
            </FormControl>
          ) : (
            <Typography>Does this Addendum modify the Bid Date?</Typography>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-evenly' }}>
          {!willChangeDate ? (
            <>
              <Button variant="contained" color="primary" onClick={handleClose}>
                No
              </Button>
              <Button variant="contained" color="primary" onClick={() => setWillChangeDate(true)}>
                Yes
              </Button>
            </>
          ) : (
            <>
              <Button variant="contained" color="primary" onClick={() => setWillChangeDate(false)}>
                Go Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!newBidDate}
                onClick={() => {
                  setBidDate(newBidDate);
                  handleClose();
                }}
              >
                Confirm
              </Button>
            </>
          )}
        </DialogActions>
      </MuiPickersUtilsProvider>
    </Dialog>
  );
}
