import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { copyToClipboard } from '../../scripts/utils';
import { addSnackbar } from '../../features/snackbar/actions';
import { useDispatch } from 'react-redux';
import { BluebeamButton } from './CustomButtons';

type Props = {
  studioSessionId: string;
};

export default function BluebeamStudioLink(props: Props) {
  const { studioSessionId } = props;

  const dispatch = useDispatch();

  return (
    <>
      <BluebeamButton
        onClick={() =>
          window.open(
            `https://studio.bluebeam.com/join.html?ID=${studioSessionId}`,
            '_blank',
            'noopener,noreferrer',
          )
        }
        style={{ marginRight: 8 }}
      >
        Join Bluebeam Studio Session
      </BluebeamButton>
      <Typography>OR</Typography>
      <Button
        onClick={async () => {
          await copyToClipboard(studioSessionId);
          dispatch(
            addSnackbar({
              id: Date.now(),
              message: 'Studio session ID copied!',
              severity: 'success',
            }),
          );
        }}
      >
        Copy ID: {studioSessionId}
      </Button>
    </>
  );
}
