import _, { Dictionary } from 'lodash';
import { ICountry } from '../../api-client/autogenerated';
import { ApiClient } from '../../api-client/api-client';

const states: Dictionary<string> = {};
const countries: Dictionary<string> = {};

// GET //

export const getStates = async (limit = 100) => {
  if (_.isEmpty(states)) {
    const response = await ApiClient.getAllCountries({ pageSize: limit });
    response.data.results.forEach((o) => {
      o.statesOrProvinces?.forEach((state) => {
        states[state.name] = state.id;
      });
    });
  }
  return states;
};

export const getStateIds = async () => {
  return _.invert(await getStates());
};

export const getCountries = async (limit = 100) => {
  if (_.isEmpty(countries)) {
    const response = await ApiClient.getAllCountries({ pageSize: limit });
    response.data.results.forEach((country) => {
      countries[country.name] = country.id;
    });
  }
  return countries;
};

export const getCountryById = async (countryId: string): Promise<ICountry> => {
  const response = await ApiClient.getCountryById({ countryId });
  return response.data;
};

// PUT - NOT IMPLEMENTED //

// export const putCountry = async(countryId: string, country: ICountry): Promise<number> => {
//   return API.put(`${URL}/${countryId}`, country).then((res) => res.status);
// };
