import { ApiClient, getQueryFilterString } from '../../api-client/api-client';
import { NotificationType } from '../../api-client/autogenerated';
import _ from 'lodash';

export const getNotificationsByDocumentId = (documentId: string, page = 0, pageSize = 50) => {
  return ApiClient.getNotificationsByDocumentId({
    id: documentId,
    page,
    pageSize,
    filterByObject: getQueryFilterString([
      {
        whereColumn: 'format',
        whereOperator: 'NOT IN',
        whereValue: [
          NotificationType.DocumentDueDateWarning,
          NotificationType.DocumentSubmittedForReviewReminder,
        ],
      },
    ]),
    orderByColumn: 'createdOn',
    orderByDirection: 'desc',
  }).then((res) => res.data);
};

export const getNotificationRecipientsByIds = (recipientIds: string[]) => {
  return ApiClient.getNotificationRecipientsByIds({ requestBody: recipientIds }).then(
    (res) => res.data,
  );
};

export const getNotificationsByBidId = (bidId: string, page = 0, pageSize = 50) => {
  return ApiClient.getNotificationsByBidId({
    id: bidId,
    page,
    pageSize,
    orderByColumn: 'createdOn',
    orderByDirection: 'desc',
  }).then((res) => res.data);
};

export const getNotificationsByKey = (key: string, page = 0, pageSize = 50) => {
  return ApiClient.getNotificationsByKey({
    key,
    page,
    pageSize,
    orderByColumn: 'createdOn',
    orderByDirection: 'desc',
  }).then((res) => res.data);
};

export const getNotificationsForCurrentUser = async (pageSize = 50, page = 0) => {
  const res = await ApiClient.getMyNotifications({
    pageSize,
    page,
    orderByColumn: 'createdOn',
    orderByDirection: 'desc',
    filterByObject: getQueryFilterString([
      { whereColumn: 'notif:doc.isHidden', whereOperator: 'IS NOT', whereValue: true },
      { whereColumn: 'notif:doc:addRevForDoc.isHidden', whereOperator: 'IS NOT', whereValue: true },
      { whereColumn: 'isRead', whereOperator: '=', whereValue: 'false' },
    ]),
  });
  return res.data;
};

export const getNotificationsByProjectId = async (projectId: string, pageSize = 50, page = 0) => {
  const res = await ApiClient.getMyNotifications({
    pageSize,
    page,
    orderByColumn: 'createdOn',
    orderByDirection: 'desc',
    filterByObject: getQueryFilterString([
      { whereColumn: 'notif:doc.projectId', whereOperator: '=', whereValue: projectId },
      { whereColumn: 'notif:doc.isHidden', whereOperator: 'IS NOT', whereValue: true },
      { whereColumn: 'notif:doc:addRevForDoc.isHidden', whereOperator: 'IS NOT', whereValue: true },
      { whereColumn: 'isRead', whereOperator: '=', whereValue: 'false' },
    ]),
  });
  return res.data;
};

export const getNotificationsByUserId = (
  userId: string,
  projectId?: string,
  pageSize = 50,
  page = 0,
) => {
  return ApiClient.getAllNotifications({
    pageSize,
    page,
    projectId,
    orderByColumn: 'createdOn',
    orderByDirection: 'desc',
    filterByObject: getQueryFilterString([
      { whereColumn: 'initiatorUserId', whereOperator: '=', whereValue: userId },
    ]),
  }).then((res) => res.data);
};

export const getNotificationsByBidSetupId = (bidSetupId: string, page: number, pageSize = 8) => {
  return ApiClient.getNotificationsByBidSetupId({
    bidSetupId,
    page,
    pageSize,
    orderByColumn: 'createdOn',
    orderByDirection: 'desc',
  }).then((res) => res.data);
};

export const getPlanholderData = async (
  projectId: string,
  userId: string,
  pageSize = 1000,
): Promise<{ date: string; type: string }[]> => {
  const views = await ApiClient.getNotificationsByProjectId({
    projectId,
    filterByObject: getQueryFilterString([
      {
        whereColumn: 'format',
        whereOperator: '=',
        whereValue: NotificationType.ProjectBidSetupViewed,
      },
      {
        whereColumn: 'initiatorUserId',
        whereOperator: '=',
        whereValue: userId,
      },
    ]),
    pageSize,
  }).then((res) => res.data.results);
  const downloads = await ApiClient.getNotificationsByProjectId({
    projectId,
    filterByObject: getQueryFilterString([
      {
        whereColumn: 'format',
        whereOperator: '=',
        whereValue: NotificationType.ProjectBidSetupDownloaded,
      },
      {
        whereColumn: 'initiatorUserId',
        whereOperator: '=',
        whereValue: userId,
      },
    ]),
  }).then((res) => res.data.results);
  return _.concat(
    views.map((view) => {
      return { date: view.createdOn!, type: 'VIEW' };
    }),
    downloads.map((download) => {
      return { date: download.createdOn!, type: 'DOWNLOAD' };
    }),
  );
};

export const markNotificationAsRead = (userNotificationId: string) => {
  return ApiClient.markNotificationAsRead({ notificationId: userNotificationId }).then(
    (res) => res.data,
  );
};

export const markManyNotificationsAsRead = async (
  userId: string,
  userNotificationIds: string[],
  isRead = true,
) => {
  return ApiClient.editUserNotificationsByIds({
    userId,
    editUserNotificationsParams: { userNotificationIds, isRead },
  }).then((res) => res.data);
};

export const markAllNotificationsAsReadByUserId = (userId: string) => {
  return ApiClient.markNotificationsAsReadByUserId({ userId }).then((res) => res.data);
};

export const deleteUserNotificationsByIds = (userNotificationIds: string[]) => {
  return ApiClient.deleteUserNotificationsByIds({
    deleteUserNotificationsParams: { userNotificationIds },
  }).then((res) => res.data);
};
