import { action } from 'typesafe-actions';
import { Dispatch } from 'react';
import { getProductPackageById } from '../../models/api/product-package';
import { SET_PRODUCT_PACKAGE, UPDATE_PRODUCT_PACKAGE } from './constants';
import { ProductPackage } from './product-package';

export const setProductPackage = (productPackage: ProductPackage = null) => {
  return action(SET_PRODUCT_PACKAGE, productPackage);
};

export const updateProductPackage = (productPackage: ProductPackage) => {
  return action(UPDATE_PRODUCT_PACKAGE, productPackage);
};

export const fetchProductPackage = (productPackageId: string) => (dispatch: Dispatch<any>) => {
  return getProductPackageById(productPackageId).then((response) =>
    dispatch({ type: SET_PRODUCT_PACKAGE, payload: response }),
  );
};
