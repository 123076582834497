import React from 'react';
import { Prompt } from 'react-router';
import { useSelector } from 'react-redux';
import { getNavigationState } from '../features/navigation/selectors';

export default function GlobalNavigationBlocker() {
  const shouldBlock = useSelector(getNavigationState);

  if (shouldBlock) window.onbeforeunload = () => true;
  else window.onbeforeunload = null;

  return <Prompt when={shouldBlock} message={'Are you sure you want to leave?'} />;
}
