import { Theme, useMediaQuery } from '@material-ui/core';

export default function useMediaQueries() {
  const isXs = useMediaQuery<Theme>((t) => t.breakpoints.only('xs'));
  const isSm = useMediaQuery<Theme>((t) => t.breakpoints.only('sm'));
  const isMd = useMediaQuery<Theme>((t) => t.breakpoints.only('md'));
  const isLg = useMediaQuery<Theme>((t) => t.breakpoints.only('lg'));
  const isXl = useMediaQuery<Theme>((t) => t.breakpoints.only('xl'));

  return { isXs, isSm, isMd, isLg, isXl };
}
