import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@material-ui/core';
import CompanyUserTreeView from '../form-pages/user-groups/CompanyUserTreeView';
import { useSelector } from 'react-redux';
import { getCompaniesState } from '../../features/companies/selector';
import { ICompany, IUser } from '../../api-client/autogenerated';
import { getProjectUsersState } from '../../features/project/selectors';
import _ from 'lodash';
import DistributionList from '../form-pages/user-groups/DistributionList';
import { ManagePermissionsDialogType } from '../design/ManagePermissionsDialog';
import { Add, HighlightOffRounded, Remove } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CancelButton, SubmitButton } from '../custom-components/CustomButtons';
import { getUserState } from '../../features/user/selectors';
import { hasPermissionToView } from '../../scripts/store-utils';
import GroupUserTreeView from '../form-pages/user-groups/GroupUserTreeView';
import { getGroupsState } from '../../features/groups/selector';
import { getFilteredUsersFromUserGroup } from '../../scripts/utils';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'none',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  mobileButton: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      width: '90%',
      padding: 0,
    },
  },
}));

type Props = {
  open: boolean;
  handleClose: () => void;
  type?: ManagePermissionsDialogType;
  existingUserIds: string[];
  defaultSelectedUserIds?: string[];
  eligibleUserIds?: string[];
  eligibleUserGroupIds?: string[];
  allowSelectingCurrentUser?: boolean;
  submit: (
    selectedUsers: string[],
    selectedGroupIds: string[],
    nonCenterlineUsers: string[],
  ) => void;
};

export default function SelectCompanyUsersDialog(props: Props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    type = ManagePermissionsDialogType.None,
    existingUserIds,
    defaultSelectedUserIds,
    eligibleUserIds,
    eligibleUserGroupIds,
    allowSelectingCurrentUser,
    submit,
  } = props;

  const currentUser = useSelector(getUserState);
  const companies = useSelector(getCompaniesState);
  const projectUsers = useSelector(getProjectUsersState);
  const groups = useSelector(getGroupsState);

  const [inputParty, setInputParty] = useState('');
  const [nonCenterlineUsers, setNonCenterlineUsers] = useState<string[]>([]);

  const [eligibleCompanies, setEligibleCompanies] = useState<ICompany[]>([]);
  useEffect(() => {
    setEligibleCompanies(
      companies
        .map((company) => {
          return {
            ...company,
            users: company.users?.filter(
              (user) =>
                projectUsers.some(({ userId }) => userId === user.id) &&
                hasPermissionToView(type, user) &&
                !existingUserIds.includes(user.id) &&
                (allowSelectingCurrentUser || user.id !== currentUser.id) &&
                (!eligibleUserIds || eligibleUserIds.includes(user.id)),
            ),
          };
        })
        .filter(({ users }) => users && users.length > 0),
    );
  }, [companies, existingUserIds, projectUsers, eligibleUserIds]);

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>(defaultSelectedUserIds || []);

  const [listOpen, setListOpen] = useState(true);

  const handleCheckCompany = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    companyId: string,
  ) => {
    if (checked)
      setSelectedUserIds((prev) =>
        _.uniq([
          ...prev,
          ...(eligibleCompanies.find(({ id }) => id === companyId)?.users?.map(({ id }) => id) ||
            []),
        ]),
      );
    else
      setSelectedUserIds((prev) =>
        prev.filter(
          (userId) =>
            !eligibleCompanies
              .find(({ id }) => id === companyId)
              ?.users?.some(({ id }) => id === userId),
        ),
      );
  };

  const handleCheckNestedUser = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    userId: string,
  ) => {
    if (checked) setSelectedUserIds((prev) => [...prev, userId]);
    else setSelectedUserIds((prev) => prev.filter((id) => id !== userId));
  };

  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);

  const handleCheckGroup = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    groupId: string,
  ) => {
    if (checked) setSelectedGroupIds((prev) => [...prev, groupId]);
    else setSelectedGroupIds((prev) => prev.filter((id) => id !== groupId));
  };

  const handleSubmit = () => {
    submit(selectedUserIds, selectedGroupIds, nonCenterlineUsers);
    setSelectedUserIds(existingUserIds);
    setSelectedGroupIds([]);
    setNonCenterlineUsers([]);
    handleClose();
  };

  const resetUsers = () => {
    setSelectedUserIds(existingUserIds);
    setNonCenterlineUsers([]);
  };

  const getPendingUsers = () => {
    const individualUsers = eligibleCompanies
      .map(({ users }) => users)
      .flat()
      .filter((user) => user && selectedUserIds.includes(user.id)) as IUser[];
    const groupUsers = groups
      .filter((g) => selectedGroupIds.includes(g.id))
      .map((g) => getFilteredUsersFromUserGroup(type, g))
      .flat();
    return _.uniqBy([...individualUsers, ...groupUsers], (user) => user.id);
  };

  const handleAddParty = (name: string, event?: React.KeyboardEvent<HTMLDivElement>) => {
    if ((!event || event.key === 'Enter') && inputParty) {
      setNonCenterlineUsers((prev) => _.uniq([...prev, name]));
      setInputParty('');
    }
  };

  const getSubmitLabel = () => {
    switch (type) {
      default:
      case ManagePermissionsDialogType.PartiesPresent:
      case ManagePermissionsDialogType.None:
        return 'Select Users';

      case ManagePermissionsDialogType.AssociatedUsers:
        return 'Define Notification List';

      case ManagePermissionsDialogType.Design:
        return 'Add Selected Recipients';
    }
  };

  const getUserListLabel = () => {
    switch (type) {
      default:
      case ManagePermissionsDialogType.PartiesPresent:
        return 'Parties Present';

      case ManagePermissionsDialogType.None:
        return 'Assigned Users';

      case ManagePermissionsDialogType.AssociatedUsers:
        return 'Pending Notification List';

      case ManagePermissionsDialogType.Design:
        return 'Pending Notification List';
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography className={classes.title}>
        {eligibleUserGroupIds ? 'Select Notification Recipients' : 'Select Users'}
        <IconButton
          style={{ right: '18px', top: '14px', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {type !== ManagePermissionsDialogType.None &&
          (!eligibleUserGroupIds ? (
            <Typography>
              Users who do not have permission to view{' '}
              {type === ManagePermissionsDialogType.Design ? 'the Design tab ' : 'this document '}{' '}
              will not appear below.
            </Typography>
          ) : (
            <Typography>The following users have access to this file.</Typography>
          ))}
        {eligibleUserGroupIds && (
          <GroupUserTreeView
            groupIds={eligibleUserGroupIds}
            selectedGroupIds={selectedGroupIds}
            handleCheckGroup={handleCheckGroup}
            type={type}
          />
        )}
        <CompanyUserTreeView
          type={type}
          companies={eligibleCompanies}
          selectedUsers={selectedUserIds}
          handleCheckCompany={handleCheckCompany}
          handleCheckNestedUser={handleCheckNestedUser}
        />
        {type === ManagePermissionsDialogType.PartiesPresent ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label="Manual Entry for Non-Centerline Users"
                value={inputParty}
                onChange={(e) => {
                  setInputParty(e.target.value);
                }}
                onKeyDown={(e) => handleAddParty(inputParty, e)}
                style={{ flexShrink: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => handleAddParty(inputParty)}
                style={{ marginLeft: 8, padding: '0px 12px' }}
              >
                Add
              </Button>
            </div>
          </>
        ) : null}
        <div style={{ display: 'flex', marginTop: '16px', marginBottom: '4px' }}>
          <IconButton onClick={() => setListOpen((prev) => !prev)} style={{ padding: 0 }}>
            {listOpen ? <Remove /> : <Add />}
          </IconButton>
          <Typography
            variant="h1"
            style={{ fontSize: 22, lineHeight: '22px', textTransform: 'none' }}
          >
            {getUserListLabel()}
          </Typography>
        </div>
        <DistributionList
          open={listOpen}
          users={getPendingUsers()}
          nonCenterlineUsers={nonCenterlineUsers}
          limitHeight
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', padding: '8px 16px 16px 16px' }}>
        <CancelButton onClick={resetUsers}>Reset</CancelButton>
        <SubmitButton onClick={handleSubmit} className={classes.mobileButton}>
          {getSubmitLabel()}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
}
