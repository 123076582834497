import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import BuildIcon from '@material-ui/icons/BuildRounded';
import { useHistory } from 'react-router-dom';

const SubscriberAdminButton: React.FC = () => {
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    history.push('/main/subscriber-admin');
  };

  return (
    <IconButton style={{ padding: '4px', marginLeft: '8px' }} onClick={handleClick}>
      <BuildIcon style={{ height: '21px', color: '#7A797A' }} />
    </IconButton>
  );
};

export default SubscriberAdminButton;
