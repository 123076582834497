import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { ISecurityGroup, ISecurityGroupUserSummary } from '../../../api-client/autogenerated';
import { HighlightOffRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import getProjectsState from '../../../features/projects/selectors';
import { ascendingComparator } from '../../document-index/DocumentIndexUtils';
import { sortLastNamesDesc } from '../../../scripts/utils';
import CircularLoader from '../../loader/CircularLoader';
import AssignedUserText from '../../custom-components/AssignedUserText';

const useStyles = makeStyles(() => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'none',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  deleteButton: {
    backgroundColor: '#ED3F26',
    '&:hover': {
      backgroundColor: '#FF5D45',
    },
    '&:focus': {
      backgroundColor: '#FF5D45',
    },
  },
}));

type Props = {
  open: boolean;
  handleClose: () => void;
  securityGroups: ISecurityGroup[];
  summary?: ISecurityGroupUserSummary;
  transferAndDelete: (newSecurityGroupId: string) => Promise<void>;
};

export default function DeleteSecurityGroupDialog(props: Props) {
  const { open, handleClose, securityGroups, summary, transferAndDelete } = props;
  const classes = useStyles();

  const projects = useSelector(getProjectsState);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedSecurityGroupId, setSelectedSecurityGroupId] = useState('');

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await transferAndDelete(selectedSecurityGroupId);
      setSelectedSecurityGroupId('');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title} align="left">
          Re-assign Users
        </Typography>
        <IconButton
          style={{ right: '20px', top: '12px', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          The security group which you have selected to delete still has users assigned to it.
        </Typography>
        <Typography style={{ marginBottom: 12 }}>
          Please select an existing security group to transfer these users to before deletion.
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select
            variant="outlined"
            defaultValue=""
            value={selectedSecurityGroupId}
            displayEmpty
            onChange={(event) => setSelectedSecurityGroupId(event.target.value as string)}
            style={{ height: 42 }}
          >
            <MenuItem value="" disabled>
              Select Security Group
            </MenuItem>
            {securityGroups
              .sort((a, b) => ascendingComparator(a, b, 'name'))
              .map((group) => {
                return (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                );
              })}
          </Select>
          {!isLoading ? (
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedSecurityGroupId}
              onClick={handleDelete}
              className={classes.deleteButton}
              style={{ marginLeft: 16 }}
            >
              Transfer and Delete
            </Button>
          ) : (
            <CircularLoader size={30} style={{ marginLeft: 32 }} />
          )}
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell padding="none">Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell># of Projects</TableCell>
            </TableHead>
            <TableBody>
              {summary?.users
                .sort((a, b) => sortLastNamesDesc(a.name, b.name))
                .map((user) => (
                  <TableRow key={user.id}>
                    <TableCell padding="none">
                      <AssignedUserText name={user.name} company={user.company} />
                    </TableCell>
                    <TableCell>
                      <Typography>{user.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Select
                        variant="outlined"
                        defaultValue=""
                        displayEmpty
                        fullWidth
                        style={{ height: 42 }}
                      >
                        <MenuItem disabled key="" value="">
                          {user.projectIds.length} Projects
                        </MenuItem>
                        {user.projectIds.map((projectId) => (
                          <MenuItem disabled key={projectId} value={projectId}>
                            {projects.find((p) => p.id === projectId)?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}
