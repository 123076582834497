import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import RightArrowIconWhite from '../icons/RightArrow-icon-white';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  link: {
    fontSize: 16,
    // fontStyle: 'bold',
    lineHeight: 0,
    '&:hover': {
      filter: 'brightness(0.8)',
      textDecoration: 'none',
      color: '#FFFFFF',
    },
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    cursor: 'pointer',
  },
});

type Props = {
  link: string;
};

export default function SeeAll(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const { link } = props;

  return (
    <Link onClick={() => history.push(link)} className={classes.link}>
      See All <RightArrowIconWhite />
    </Link>
  );
}
