import { ApiClient, getQueryFilterString } from '../../api-client/api-client';
import {
  CustomInsertionPhysicalBuilding,
  CustomOptionalPhysicalBuilding,
  FileUploadRequestParams,
  IOptionalPhysicalFloor,
  PickIInsertionPhysicalLocationExcludeKeyofIInsertionPhysicalLocationFloorId,
} from '../../api-client/autogenerated';
import { uploadS3File } from './filesystem';

export type CustomInsertionPhysicalLocation = PickIInsertionPhysicalLocationExcludeKeyofIInsertionPhysicalLocationFloorId;

export const getPhysicalBuildingsByProjectId = async (
  projectId: string,
  page = 0,
  pageSize = 50,
  floor?: string,
) => {
  return ApiClient.getPhysicalBuildingsByProjectId({
    projectId,
    page,
    pageSize,
    filterByObject:
      floor &&
      getQueryFilterString([{ whereColumn: 'floor', whereOperator: '=', whereValue: floor }]),
  }).then((res) => res.data);
};

export const insertManyPhysicalBuildings = async (buildings: CustomInsertionPhysicalBuilding[]) => {
  return ApiClient.insertManyPhysicalBuildings({
    insertManyPhysicalBuildingsRequest: { buildings },
  }).then((res) => res.data);
};

export const editPhysicalBuilding = async (
  buildingId: string,
  building: CustomOptionalPhysicalBuilding,
) => {
  return ApiClient.editPhysicalBuildingById({
    buildingId,
    customOptionalPhysicalBuilding: building,
  }).then((res) => res.data);
};

export const insertManyPhysicalLocations = async (
  floorIds: string[],
  locations: CustomInsertionPhysicalLocation[],
) => {
  return ApiClient.insertManyPhysicalLocationsByFloorIds({
    insertManyPhysicalLocationsRequest: { floorIds, locations },
  }).then((res) => res.data);
};

export const editManyPhysicalFloors = async (floorIds: string[], patch: IOptionalPhysicalFloor) => {
  return ApiClient.editManyPhysicalFloorsByIds({
    editManyPhysicalFloorsRequest: { floorIds, patch },
  }).then((res) => res.data);
};

export const insertFloorPlanDrawingByProjectId = async (
  projectId: string,
  params: FileUploadRequestParams,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const response = await ApiClient.insertFloorPlanDrawingFileByProjectId({
    projectId,
    fileUploadRequestParams: params,
  });
  await uploadS3File(response.data, file, onUploadProgress);
  return response.data;
};

export const insertFloorPlanImageByProjectId = async (
  projectId: string,
  params: FileUploadRequestParams,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const response = await ApiClient.insertFloorPlanImageFileByProjectId({
    projectId,
    fileUploadRequestParams: params,
  });
  await uploadS3File(response.data, file, onUploadProgress);
  return response.data;
};

export const insertLocationImageByProjectId = async (
  projectId: string,
  params: FileUploadRequestParams,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const response = await ApiClient.insertLocationImageFileByProjectId({
    projectId,
    fileUploadRequestParams: params,
  });
  await uploadS3File(response.data, file, onUploadProgress);
  return response.data;
};

export const deletePhysicalBuildingById = async (buildingId: string) => {
  return ApiClient.deletePhysicalBuildingById({ buildingId }).then((res) => res.data);
};

export const deletePhysicalFloorById = async (floorId: string) => {
  return ApiClient.deletePhysicalFloorById({ floorId }).then((res) => res.data);
};

export const deletePhysicalLocationById = async (locationId: string) => {
  return ApiClient.deletePhysicalLocationById({ locationId }).then((res) => res.data);
};
