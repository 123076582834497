import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { CancelButton } from '../custom-components/CustomButtons';
import { useSelector } from 'react-redux';
import { getDocumentsType } from '../../features/documents/selectors';
import { documentTypeToReadable } from '../../scripts/utils';
import FileUploadDialog from './FileUploadDialog';
import IconButton from '@material-ui/core/IconButton';
import { HighlightOffRounded } from '@material-ui/icons';
import PDFIcon from '../icons/PDF-icon';
import Add from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { getNavigationState } from '../../features/navigation/selectors';

type Props = {
  open: boolean;
  handleClose: () => void;
  originalReferenceNumber: string;
  handleSubmit: (files: File[], newDescription?: string) => Promise<void>;
  uploadProgress: number;
  uploadMessage: string;
};

export default function ResubmitDialog(props: Props) {
  const {
    open,
    handleClose,
    originalReferenceNumber,
    handleSubmit,
    uploadProgress,
    uploadMessage,
  } = props;

  const docType = useSelector(getDocumentsType)!;
  const shouldBlockNavigation = useSelector(getNavigationState);

  const [description, setDescription] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const handleAddFiles = (files: File[]) => {
    setUploadedFiles((prev) => [...prev, ...files]);
  };

  const handleRemoveFile = (file: File) => {
    setUploadedFiles((prev) => [...prev.filter((f) => f !== file)]);
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>
        <h2
          style={{
            color: '#0947B9',
            fontSize: '26px',
            fontWeight: 600,
            textTransform: 'none',
            margin: 0,
          }}
        >
          Resubmit {documentTypeToReadable[docType]}
        </h2>
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          variant="outlined"
          label={`${documentTypeToReadable[docType]} #`}
          fullWidth
          disabled
          defaultValue={originalReferenceNumber}
          style={{ marginBottom: 12 }}
        />
        <TextField
          variant="outlined"
          label="Description"
          fullWidth
          multiline
          rows={5}
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => setUploadDialogOpen(true)}
          style={{ marginTop: '12px', marginBottom: '4px' }}
        >
          Upload Files
        </Button>
        {uploadedFiles.map((file) => (
          <div
            key={file.name + file.lastModified}
            style={{ display: 'flex', marginTop: '8px', alignItems: 'center' }}
          >
            <IconButton
              onClick={() => handleRemoveFile(file)}
              style={{ padding: 0, marginRight: '6px' }}
            >
              <HighlightOffRounded />
            </IconButton>
            <PDFIcon style={{ marginRight: '6px' }} />
            <span>{file.name}</span>
          </div>
        ))}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', paddingLeft: 24, paddingRight: 24 }}>
        {!isSubmitting ? (
          <>
            <CancelButton onClick={isSubmitting || shouldBlockNavigation ? undefined : handleClose}>
              Cancel
            </CancelButton>
            <Button
              variant="contained"
              color="primary"
              disabled={!description && uploadedFiles.length === 0}
              onClick={async () => {
                setIsSubmitting(true);
                await handleSubmit(uploadedFiles, description);
              }}
            >
              Submit
            </Button>
          </>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: 8,
            }}
          >
            <Typography style={{ fontWeight: 500 }}>{uploadMessage}</Typography>
            <Typography style={{ marginTop: 4, marginBottom: 4 }}>{uploadProgress}%</Typography>
            <LinearProgress
              variant="determinate"
              value={uploadProgress}
              style={{ height: 6, width: '100%' }}
            />
          </div>
        )}
      </DialogActions>
      <FileUploadDialog
        open={uploadDialogOpen}
        handleClose={() => setUploadDialogOpen(false)}
        disableComments
        title="Upload Files"
        addFiles={(files) => handleAddFiles(files as File[])}
        newFiles={uploadedFiles}
        removeFile={(file) => handleRemoveFile(file as File)}
        disableDesignUpload
      />
    </Dialog>
  );
}
