import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export default function FullscreenLoader() {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ height: '100vh', width: '100vw' }}>
      <CircularProgress color="primary" />
    </div>
  );
}
