import React from 'react';

const PDFIcon = (props: any) => {
  const { fill, ...rest } = props;

  return (
    <svg
      width={22}
      height={27}
      viewBox="0 0 22 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0098 1.49696C14.402 0.864348 13.9712 0.678711 13.1121 0.678711H2.21735C1.02019 0.678711 0.549805 1.16833 0.549805 2.41441V25.0481C0.549805 26.2942 1.02019 26.7839 2.21735 26.7839H19.7823C20.9797 26.7839 21.4498 26.2942 21.4498 25.0481V9.35693C21.4498 8.46239 21.2712 8.01426 20.6637 7.38164L15.0098 1.49696ZM19.9913 24.8031C19.9913 25.1355 19.8659 25.2657 19.5465 25.2657H2.4531C2.13375 25.2657 2.00835 25.1352 2.00835 24.8031V19.1884H19.9913V24.8031ZM4.82483 17.8115V12.75C5.16843 12.6891 5.65108 12.6436 6.33102 12.6436C7.01822 12.6436 7.50811 12.7808 7.83722 13.0543C8.15155 13.313 8.36362 13.7397 8.36362 14.2418C8.36362 14.7442 8.20283 15.1705 7.91051 15.4594C7.53041 15.8325 6.96722 15.9998 6.30901 15.9998C6.16299 15.9998 6.03118 15.992 5.92891 15.9769V17.8115H4.82483ZM9.0731 17.7883V12.75C9.48246 12.6816 10.0161 12.6436 10.5793 12.6436C11.5151 12.6436 12.122 12.8188 12.5974 13.1915C13.1093 13.5874 13.4309 14.2189 13.4309 15.125C13.4309 16.1066 13.0873 16.7841 12.6119 17.2027C12.093 17.6517 11.3033 17.8649 10.338 17.8649C9.76057 17.8646 9.35093 17.8266 9.0731 17.7883ZM17.0511 14.8048V15.7483H15.281V17.8115H14.1621V12.6816H17.1748V13.633H15.281V14.8051H17.0511V14.8048ZM19.9913 11.32H2.00835V2.65951C2.00835 2.32711 2.13375 2.19687 2.4531 2.19687H13.5569C13.7162 2.19687 13.7792 2.26213 13.7792 2.42805V6.92712C13.7792 8.17321 14.2496 8.66282 15.4465 8.66282H19.7689C19.9286 8.66282 19.9913 8.72838 19.9913 8.89429V11.32Z"
        fill={fill || '#7A797A'}
      />
    </svg>
  );
};

export default PDFIcon;
