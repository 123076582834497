import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TableCell,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { HighlightOffRounded, Save } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import PermissionTag, { PermissionTagType } from './PermissionTag';
import { CancelButton, SubmitButton } from '../custom-components/CustomButtons';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectState, getProjectUsersState } from '../../features/project/selectors';
import {
  FileNode,
  FileNodeTypeEnum,
  FilePermissionType,
  IUser,
  IUserGroup,
  SecurityPermissionLevel,
  ShareFileSystemObjectRequest,
  ShareFileSystemTarget,
} from '../../api-client/autogenerated';
import { shareMultipleUserFileSystemObject } from '../../models/api/filesystem';
import CircularLoader from '../loader/CircularLoader';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import PermissionsDialogRow from './PermissionsDialogRow';
import TableRow from '@material-ui/core/TableRow';
import CreateUserGroupDialog from './CreateUserGroupDialog';
import { getGroupsState } from '../../features/groups/selector';
import { upsertGroup } from '../../features/groups/actions';
import _ from 'lodash';
import { ascendingComparator } from '../document-index/DocumentIndexUtils';
import {
  getFilteredUsersFromUserGroup,
  isPublicPage,
  nodeIsSubfolder,
  shouldDisplayUser,
} from '../../scripts/utils';
import SelectCompanyUsersDialog from '../dialogs/SelectCompanyUsersDialog';
import Button from '@material-ui/core/Button';
import { getUserState } from '../../features/user/selectors';
import { getSubscriberSecurityGroupState } from '../../features/security/selectors';

export enum ManagePermissionsDialogType {
  Design,
  AssociatedUsers,
  None,
  PartiesPresent,
}

interface ManagePermissionsDialogProps {
  dialogOpen: boolean;
  files?: FileNode[];
  setFile?: React.Dispatch<React.SetStateAction<FileNode | undefined>> | ((f: FileNode) => void);
  associatedUsers?: string[];
  setAssociatedUsers?: (users: string[]) => void;
  associatedGroups?: string[];
  setAssociatedGroups?: (groups: string[]) => void;
  submitAssociatedUsersAndGroups?: (
    userIds: string[],
    associatedGroupIds: string[],
    associatedDocumentIds: string[],
  ) => Promise<void>;
  closeDialog: () => void;
  type: ManagePermissionsDialogType;
  findParentFolder?: (fullKey: string) => FileNode | undefined;
  manageParentFolder?: (fullKey: string) => void;
}

export enum PermissionActionType {
  Modify,
  Delete,
  None,
}

export interface PermissionsAndAction {
  action: PermissionActionType;
  read: boolean;
  write: boolean;
  admin: boolean;
  userId?: string;
  group?: IUserGroup;
  isEveryone?: boolean;
  shareable?: boolean;
  parentFolder?: string;
  isFileManagerAdmin?: boolean;
  isFolderOwner?: boolean;
}

export const getPermissionTagType = (permission: PermissionsAndAction) => {
  if (permission.userId) return PermissionTagType.Person;
  else return PermissionTagType.Group;
};

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 650,
    maxHeight: '720px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: '100%',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'none',
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '21px',
    textAlign: 'left',
    textTransform: 'none',
    color: '#464546',
    maxWidth: '600px',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export function getUsersAndUserGroupsFromFileNodes(files: FileNode[]) {
  const userIds = _.intersection(
    ...files.map(
      (f) => (f.fileNodePermissions || []).map((p) => p.userIdTarget).filter((s) => s) as string[],
    ),
  );
  const userGroupIds = _.intersection(
    ...files.map(
      (f) =>
        (f.fileNodePermissions || []).map((p) => p.userGroupIdTarget).filter((s) => s) as string[],
    ),
  );

  return { userIds, userGroupIds };
}

export const getUsersFromUserGroup = (group?: IUserGroup | null): IUser[] => {
  if (!group) return [];
  const users: IUser[] = [];
  const excludedUserIds: string[] = [];
  group.userGroupCompanyList?.forEach(({ company }) => {
    if (company?.users)
      users.push(
        ...company.users
          .filter((u) => shouldDisplayUser(u, false))
          .map((user) => {
            return { ...user, company };
          }),
      );
  });
  // group.userGroupDisciplineList?.forEach((group) => {
  //   if (group?.userDisciplineList)
  //     users.push(...(group.userDisciplineList.map(({ user }) => user).filter((u) => u) as IUser[]));
  // });
  group.userGroupUserList
    ?.filter((u) => u.user && shouldDisplayUser(u.user, false))
    .forEach(({ user, isExcluded }) => {
      if (user) {
        if (isExcluded) excludedUserIds.push(user.id);
        else users.push(user);
      }
    });
  const filteredUsers = users.filter(({ id }) => !excludedUserIds.includes(id));
  return _.uniqBy(filteredUsers, (user) => user.id);
};

export default function ManagePermissionsDialog(props: ManagePermissionsDialogProps) {
  const classes = useStyles();
  const {
    dialogOpen,
    closeDialog,
    files,
    setFile,
    type,
    associatedUsers,
    setAssociatedUsers,
    associatedGroups,
    setAssociatedGroups,
    submitAssociatedUsersAndGroups,
    findParentFolder,
    manageParentFolder,
  } = props;

  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);
  const projectUsers = useSelector(getProjectUsersState);
  const groups = useSelector(getGroupsState);
  const securityGroups = useSelector(getSubscriberSecurityGroupState);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:600px)');

  const [groupRows, setGroupRows] = useState<IUserGroup[]>([]);
  const [selectUsersDialogOpen, setSelectUsersDialogOpen] = useState(false);
  const [permissions, setPermissions] = useState<PermissionsAndAction[]>([]);
  const [submitPermissionsLoading, setSubmitPermissionsLoading] = useState(false);
  const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState(false);

  const isFile = !!files?.some((node) => node.type === FileNodeTypeEnum.File);
  const isSubfolder = !!(files && nodeIsSubfolder(files[0]));
  const parentFolder =
    files?.length && findParentFolder ? findParentFolder(files[0].fullKey) : undefined;
  const parentFolderName = parentFolder?.fullKey.slice(1, parentFolder?.fullKey.length - 1);

  const isUserOwner = () => {
    if (type !== ManagePermissionsDialogType.Design) return true;
    if (!files || files.length === 0) return false;
    if (files.some((f) => f.ownerId === user.id)) return true;
    const relevantPermission = permissions.find((p) => {
      const groupUsers = getUsersFromUserGroup(p.group);
      return p.userId === user.id || groupUsers?.map((u) => u.id).includes(user.id);
    });
    return relevantPermission?.admin;
  };

  const handleAddPermission = (permission: PermissionsAndAction) => {
    const tempPermissions = [...permissions];
    const existingPermissionPos = tempPermissions.findIndex(
      (x) =>
        (permission.userId && x.userId === permission.userId) ||
        (permission.group && x.group?.id === permission.group.id) ||
        (permission.isEveryone && x.isEveryone),
    );
    if (existingPermissionPos !== -1) {
      tempPermissions[existingPermissionPos] = permission;
    } else {
      tempPermissions.push(permission);
    }
    setPermissions(tempPermissions);
  };

  const handleAddMultiplePermissions = (newPermissions: PermissionsAndAction[]) => {
    const tempPermissions = [...permissions];
    newPermissions.forEach((permission) => {
      const existingPermissionPos = tempPermissions.findIndex(
        (x) =>
          (permission.userId && x.userId === permission.userId) ||
          (permission.group && x.group?.id === permission.group.id) ||
          (permission.isEveryone && x.isEveryone),
      );
      if (existingPermissionPos !== -1) {
        tempPermissions[existingPermissionPos] = permission;
      } else {
        tempPermissions.push(permission);
      }
    });
    setPermissions(tempPermissions);
  };

  const handleAddIndividualUsers = (userIds: string[]) => {
    handleAddMultiplePermissions(
      userIds.map((userId) => {
        return {
          action: PermissionActionType.Modify,
          userId,
          read: true,
          write: false,
          admin: false,
          shareable: true,
        };
      }),
    );
  };

  const handleAddGroup = (group: IUserGroup) => {
    dispatch(upsertGroup(group));
  };

  useEffect(() => {
    if (files && files.length > 0 && files[0].fileNodePermissions) {
      const tempPermissions: PermissionsAndAction[] = [];
      files[0].fileNodePermissions
        .filter((perm) => perm.shareTarget !== ShareFileSystemTarget.ExternalEmail)
        .forEach((perm) => {
          tempPermissions.push({
            action: PermissionActionType.None,
            read: perm.permissions.includes(FilePermissionType.Read),
            write: perm.permissions.includes(FilePermissionType.Modify),
            admin: perm.permissions.includes(FilePermissionType.Delete),
            userId: perm.userIdTarget,
            group: groups.find((group) => perm.userGroupIdTarget === group.id),
            isEveryone: perm.shareToEveryone,
            shareable: perm.shareable,
            parentFolder: perm.parentFolderSharedName,
            isFileManagerAdmin:
              projectUsers.find((pU) => pU.userId === perm.userIdTarget)?.user
                ?.adminOfSubscriberId === project?.subscriberId ||
              securityGroups.find(
                (sg) =>
                  projectUsers.find((pU) => pU.userId === perm.userIdTarget)?.securityGroupId ===
                  sg.id,
              )?.designTabPermission === SecurityPermissionLevel.NUMBER_4,
            isFolderOwner: files.some((f) => f.ownerId === perm.userIdTarget),
          });
        });
      setPermissions(tempPermissions);
    } else setPermissions([]);
  }, [files]);

  /* Populate this component's permission state by iterating over the document's current
  associatedUsers and associatedGroups */
  useEffect(() => {
    const tempPermissions: PermissionsAndAction[] = [];

    if (associatedUsers) {
      associatedUsers.forEach((userId) => {
        tempPermissions.push({
          action: PermissionActionType.None,
          read: true,
          write: true,
          admin: true,
          userId,
        });
      });
    }

    if (associatedGroups) {
      associatedGroups.forEach((groupId) => {
        const currentGroup = groups.find((x) => x.id === groupId);
        tempPermissions.push({
          action: PermissionActionType.None,
          read: true,
          write: true,
          admin: true,
          group: currentGroup,
        });
      });
    }

    setPermissions(tempPermissions);
  }, [associatedUsers, associatedGroups]);

  useEffect(() => {
    setGroupRows(
      groups
        .filter(
          (group) =>
            !permissions.some(
              (p) => p.action !== PermissionActionType.Delete && p.group?.id === group.id,
            ),
        )
        .sort((a, b) => ascendingComparator(a, b, 'name')),
    );
  }, [groups, permissions]);

  const getPermissionTagLabel = (permission: PermissionsAndAction): string => {
    if (permission.userId) {
      const u = projectUsers.find((x) => x.userId === permission.userId);
      if (u) return u.user!.name;
    }
    if (permission.group) {
      return permission.group.name;
    }
    if (permission.isEveryone) {
      return 'Everyone';
    }
    return '';
  };

  const sortPermissions = (a: PermissionsAndAction, b: PermissionsAndAction) => {
    const aTag = getPermissionTagType(a);
    const bTag = getPermissionTagType(b);
    if (bTag - aTag !== 0) return bTag - aTag;
    else if (getPermissionTagLabel(b).toLowerCase() < getPermissionTagLabel(a).toLowerCase())
      return 1;
    else return -1;
  };

  const getFilePermissions = (p: PermissionsAndAction) => {
    const perms = [];
    if (p.read) perms.push(FilePermissionType.Read);
    if (p.write) perms.push(FilePermissionType.Modify);
    if (p.admin) perms.push(FilePermissionType.Delete);
    return perms;
  };

  const getShareTargetType = (p: PermissionsAndAction) => {
    if (p.userId) return ShareFileSystemTarget.ProjectUsers;
    if (p.group) return ShareFileSystemTarget.UserGroup;
    return ShareFileSystemTarget.Everyone;
  };

  const getShareTargetFromType = (p: PermissionsAndAction, type: ShareFileSystemTarget) => {
    switch (type) {
      case ShareFileSystemTarget.ProjectUsers:
        return [p.userId!];
      case ShareFileSystemTarget.UserGroup:
        return [p.group!.id];
      case ShareFileSystemTarget.Everyone:
      default:
        return undefined;
    }
  };

  const buildRequest = (
    p: PermissionsAndAction,
    fileNodeKey: string,
    creatorUserId?: string,
  ): ShareFileSystemObjectRequest => {
    const targetType = getShareTargetType(p);
    const filePermissions = getFilePermissions(p);
    const target = getShareTargetFromType(p, targetType);
    return { fileNodeKey, targetType, filePermissions, target, creatorUserId };
  };

  const submitPermissionChanges = async () => {
    setSubmitPermissionsLoading(true);
    if (files && type === ManagePermissionsDialogType.Design) {
      const requests = permissions
        .filter((x) => x.action !== PermissionActionType.None)
        .map((p) => files.map((file) => buildRequest(p, file.fullKey, file.ownerId)))
        .flat();
      try {
        const newFiles = await shareMultipleUserFileSystemObject(project!.id, requests);
        if (setFile) for (const file of newFiles) setFile(file);
      } catch (e: any) {
        console.log(e);
      } finally {
        setSubmitPermissionsLoading(false);
        closeDialog();
      }
    } else if (type === ManagePermissionsDialogType.AssociatedUsers) {
      /*
      const associatedUserIds: string[] = [];

      //Iterate through the permissions and add unique userIds.
      permissions.forEach((permission) => {
        if (permission.userId) {
          if (!associatedUserIds.includes(permission.userId))
            associatedUserIds.push(permission.userId);
        } else if (permission.group && permission.group.userGroupUserList) {
          permission.group.userGroupUserList
            .map((x) => x.userId)
            .forEach((userId) => {
              if (!associatedUserIds.includes(userId)) associatedUserIds.push(userId);
            });
        }
      });
      */

      if (submitAssociatedUsersAndGroups) {
        await submitAssociatedUsersAndGroups(
          permissions.filter((x) => x.userId).map((x) => x.userId!),
          permissions.filter((x) => x.group).map((x) => x.group?.id!),
          [],
        );
        setSubmitPermissionsLoading(false);
        closeDialog();
      } else {
        setAssociatedUsers!(permissions.filter((x) => x.userId).map((x) => x.userId!));
        setAssociatedGroups!(permissions.filter((x) => x.group).map((x) => x.group?.id!));
        setSubmitPermissionsLoading(false);
        closeDialog();
      }
    }
  };

  const handleDeletePermission = (permission: PermissionsAndAction) => {
    let tempPermissions = [...permissions];

    //For editing a single node's permissions
    if (files && files.length === 1) {
      const existingFilePermission = files[0].fileNodePermissions?.some(
        (x) =>
          (permission.userId && x.userIdTarget === permission.userId) ||
          (permission.group?.id && x.userGroupIdTarget === permission.group.id) ||
          (permission.isEveryone && x.shareToEveryone),
      );
      const existingTempPermissionIndex = tempPermissions.findIndex(
        (x) =>
          (permission.userId && x.userId === permission.userId) ||
          (permission.group?.id && x.group?.id === permission.group.id) ||
          (permission.isEveryone && x.isEveryone),
      );
      if (existingFilePermission) {
        tempPermissions[existingTempPermissionIndex] = {
          action: PermissionActionType.Delete,
          read: false,
          write: false,
          admin: false,
          userId: permission.userId,
          group: permission.group,
          isEveryone: permission.isEveryone,
          shareable: permission.shareable,
        };
      } else if (existingTempPermissionIndex !== -1) {
        tempPermissions.splice(existingTempPermissionIndex, 1);
      }
      setPermissions(tempPermissions);
    }

    //For multiple file permissions
    else {
      const existingTempPermissionIndex = tempPermissions.findIndex(
        (x) =>
          (permission.userId && x.userId === permission.userId) ||
          (permission.group?.id && x.group?.id === permission.group.id) ||
          (permission.isEveryone && x.isEveryone),
      );
      tempPermissions.splice(existingTempPermissionIndex, 1);
      setPermissions(tempPermissions);
    }
  };

  const getNoPermissionsText = () => {
    if (type === ManagePermissionsDialogType.Design)
      return 'The ‘admin’ permission allows users from the same company and security group to automatically have access to each other’s folders.';

    return 'There are no users selected for notifications on this document';
  };

  const getTitle = () => {
    if (type === ManagePermissionsDialogType.Design)
      return isFile || isSubfolder
        ? 'Current Access and Permissions'
        : 'Set User Access and Permissions';

    return `Define Notification List`;
  };

  const handleManageParentFolder = () => {
    closeDialog();
    if (manageParentFolder) manageParentFolder(files![0].fullKey);
  };

  const canSubmit = () => {
    if (type === ManagePermissionsDialogType.Design)
      return !isFile && !isSubfolder && isUserOwner();
    return true;
  };

  const getSubmitTitle = () => {
    switch (type) {
      case ManagePermissionsDialogType.Design:
        return 'Save';
      case ManagePermissionsDialogType.AssociatedUsers:
      case ManagePermissionsDialogType.None:
      default:
        return 'Save Changes';
    }
  };

  const shouldShowUserAndGroupsList = () => {
    return !isFile && !isSubfolder && isUserOwner();
  };

  const getExistingUserIds = () => {
    return permissions
      .filter((p) => p.action !== PermissionActionType.Delete && p.userId)
      .map((p) => p.userId!);
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={closeDialog}
      onContextMenu={(e) => e.stopPropagation()}
      maxWidth="md"
      PaperProps={{ className: classes.container }}
      onDoubleClick={(e) => e.stopPropagation()}
    >
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title} align="left">
          {getTitle()}
        </Typography>
        <IconButton
          style={{ right: '20px', top: '12px', position: 'absolute' }}
          onClick={closeDialog}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ paddingBottom: 16 }}>
        {!isFile && !isSubfolder && !isUserOwner() && (
          <Typography
            variant="body2"
            style={{
              fontSize: '16px',
              fontWeight: 500,
              margin: '16px 0px',
              textAlign: 'center',
            }}
          >
            Only the folder owner may define Access
          </Typography>
        )}
        {shouldShowUserAndGroupsList() && (
          <>
            <Typography className={classes.subtitle} style={{ marginBottom: 8 }}>
              Add Users
            </Typography>
            <Tooltip
              title={'This list only shows users who have completed Centerline user registration.'}
              arrow
              placement={'right'}
            >
              <Typography style={{ width: 'fit-content' }}>
                <i>Missing users?</i>
              </Typography>
            </Tooltip>
            <div
              style={{
                display: isMobile ? 'block' : 'flex',
                justifyContent: 'space-between',
                paddingBottom: '16px',
              }}
            >
              <Table size="small" padding="none">
                {groupRows.slice(0, Math.ceil(groupRows.length / 2)).map((group, index) => (
                  <PermissionsDialogRow
                    key={group.id}
                    groupRow={group}
                    users={getFilteredUsersFromUserGroup(type, group)}
                    index={index}
                    label={group.name}
                    type={PermissionTagType.Group}
                    handleEditPermission={handleAddPermission}
                    dialogType={type}
                  />
                ))}
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <PermissionTag
                      label="Create A New Group"
                      type={PermissionTagType.Group}
                      onClick={() => setCreateGroupDialogOpen(true)}
                      disableDelete
                    />
                  </TableCell>
                </TableRow>
              </Table>
              <Table size="small" padding="none">
                {groupRows.length > 1 && (
                  <>
                    {groupRows.slice(Math.ceil(groupRows.length / 2)).map((group, index) => (
                      <PermissionsDialogRow
                        key={group.id}
                        groupRow={group}
                        users={getFilteredUsersFromUserGroup(type, group)}
                        index={index}
                        label={group.name}
                        type={PermissionTagType.Group}
                        handleEditPermission={handleAddPermission}
                        dialogType={type}
                      />
                    ))}
                  </>
                )}
                {groupRows.length % 2 !== 0 && (
                  <TableRow>
                    <TableCell style={{ height: '48px' }} />
                  </TableRow>
                )}
                <TableRow style={{ backgroundColor: 'white' }}>
                  <TableCell />
                  <TableCell>
                    <PermissionTag
                      label="Select Individual Users"
                      type={PermissionTagType.Person}
                      onClick={() => setSelectUsersDialogOpen(true)}
                      disableDelete
                      disableEllipsis
                    />
                  </TableCell>
                </TableRow>
              </Table>
              <Divider variant="fullWidth" style={{ marginBottom: 16 }} />
            </div>
            <Typography className={classes.subtitle} style={{ paddingBottom: 8 }}>
              {type === ManagePermissionsDialogType.Design
                ? 'Set Permissions'
                : 'Notification List'}
            </Typography>
          </>
        )}
        <div
          style={{
            marginBottom: 24,
            paddingTop: 0,
            width: '100%',
            overflowY: 'auto',
            maxHeight: '400px',
          }}
        >
          {!isPublicPage() &&
            type === ManagePermissionsDialogType.Design &&
            parentFolder &&
            manageParentFolder &&
            isFile && (
              <Button
                color="primary"
                onClick={handleManageParentFolder}
                style={{ paddingLeft: 0, fontSize: '16px', margin: '16px 0px', display: 'block' }}
              >
                Click here to define Access and Distribution for
                <span
                  style={{ whiteSpace: 'pre', fontWeight: 700 }}
                >{` ${parentFolderName} `}</span>
                folder.
              </Button>
            )}
          {!isPublicPage() &&
            type === ManagePermissionsDialogType.Design &&
            parentFolder &&
            isSubfolder && (
              <Typography
                variant="body2"
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  margin: '16px 0px',
                  textAlign: 'center',
                }}
              >
                Access must be set at the <b style={{ fontWeight: 700 }}>{parentFolderName}</b>{' '}
                parent folder.
              </Typography>
            )}
          {permissions.filter((x) => x.action !== PermissionActionType.Delete).length === 0 ? (
            <Typography variant="body2" style={{ maxWidth: 650 }}>
              {getNoPermissionsText()}
            </Typography>
          ) : (
            <>
              {type === ManagePermissionsDialogType.Design &&
              permissions.some((p) => p.isFileManagerAdmin) ? (
                <Typography variant="body2" style={{ maxWidth: 650, marginBottom: 16 }}>
                  Project Admins are highlighted in <strong>BLUE</strong> and are automatically
                  added to all files and folders.
                </Typography>
              ) : null}
              {type === ManagePermissionsDialogType.Design &&
              permissions.some((p) => p.isFolderOwner) ? (
                <Typography variant="body2" style={{ maxWidth: 650, marginBottom: 16 }}>
                  The top-level folder creator is highlighted in <strong>ORANGE</strong>.
                </Typography>
              ) : null}
              <Table size="small" padding="none" stickyHeader>
                <TableHead>
                  <TableRow style={{ backgroundColor: 'white' }}>
                    <TableCell style={{ backgroundColor: 'white' }} />
                    <TableCell style={{ backgroundColor: 'white' }} />
                    {type === ManagePermissionsDialogType.Design && (
                      <>
                        <TableCell align="center" style={{ backgroundColor: 'white' }}>
                          View
                        </TableCell>
                        <TableCell align="center" style={{ backgroundColor: 'white' }}>
                          Create
                        </TableCell>
                        <TableCell align="center" style={{ backgroundColor: 'white' }}>
                          Admin
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {permissions
                    .filter((x) => x.action !== PermissionActionType.Delete)
                    .sort(sortPermissions)
                    .map((row, index) => (
                      <PermissionsDialogRow
                        key={`${row.userId || row.group?.id}-${row.action}`}
                        permissionRow={row}
                        index={index}
                        users={getFilteredUsersFromUserGroup(type, row.group)}
                        label={getPermissionTagLabel(row)}
                        type={getPermissionTagType(row)}
                        handleEditPermission={handleAddPermission}
                        handleDeletePermission={handleDeletePermission}
                        defaultOpen
                        dialogType={type}
                        hidePermissions={type !== ManagePermissionsDialogType.Design}
                        disablePermissions={
                          !isUserOwner() ||
                          isFile ||
                          isSubfolder ||
                          row.isFileManagerAdmin ||
                          row.isFolderOwner
                        }
                      />
                    ))}
                </TableBody>
              </Table>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between', padding: 16 }}>
        {!submitPermissionsLoading ? (
          <>
            {canSubmit() && (
              <SubmitButton
                onClick={submitPermissionChanges}
                startIcon={<Save />}
                style={{ paddingLeft: 16, paddingRight: 16 }}
              >
                {getSubmitTitle()}
              </SubmitButton>
            )}
            <CancelButton onClick={closeDialog} />
            {type === ManagePermissionsDialogType.Design && isFile && (
              <SubmitButton onClick={handleManageParentFolder}>Modify</SubmitButton>
            )}
          </>
        ) : (
          <CircularLoader fullWidth />
        )}
      </DialogActions>
      <SelectCompanyUsersDialog
        open={selectUsersDialogOpen}
        handleClose={() => setSelectUsersDialogOpen(false)}
        type={type}
        existingUserIds={getExistingUserIds()}
        submit={handleAddIndividualUsers}
      />
      <CreateUserGroupDialog
        open={createGroupDialogOpen}
        handleClose={() => setCreateGroupDialogOpen(false)}
        handleAddGroup={handleAddGroup}
        type={type}
      />
    </Dialog>
  );
}
