import { useEffect } from 'react';
import * as queryString from 'querystring';
import { useHistory, useLocation } from 'react-router-dom';
import { ApiClient } from '../api-client/api-client';
import { OauthProviderType } from '../api-client/autogenerated';
import { generateProcoreLoginLink } from '../scripts/utils';

interface AuthCallbackProps {
  redirectTo: string;
  provider: OauthProviderType;
}

export default function OAuthCallback(props: AuthCallbackProps) {
  const { redirectTo, provider } = props;

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const projectId = localStorage.getItem('projectIdCallback');
    if (projectId) {
      console.log(generateProcoreLoginLink());
      let parsedHash = queryString.parse(location.search.replace('?', ''));
      let { code } = parsedHash;
      switch (provider) {
        case OauthProviderType.Bim:
          ApiClient.authorizeBIM360({
            centerLineProjectId: projectId,
            iAuthorizeParams: {
              code: code as string,
            },
          }).then(() => {
            history.push(`${redirectTo}/projects/${projectId}/design/BIM`);
          });
          break;
        case OauthProviderType.Procore:
          ApiClient.authorizeProcore({
            centerLineProjectId: projectId,
            iAuthorizeParams: {
              code: code as string,
            },
          }).then(() => {
            history.push(`${redirectTo}/projects/${projectId}/const-mgmt`);
          });
          break;
        default:
          throw new Error('Not supported provider');
      }
    }
  }, []);

  return null;
}
