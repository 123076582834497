import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Collapse,
  Divider,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  ActionTakenType,
  DocumentTemplateType,
  IComment,
  INumberedDocumentView,
} from '../../../api-client/autogenerated';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { getDocumentIdentifier, openInNewTab } from '../../../scripts/utils';
import _ from 'lodash';
import { descendingComparator } from '../../document-index/DocumentIndexUtils';
import { addSnackbar } from '../../../features/snackbar/actions';
import { useDispatch, useSelector } from 'react-redux';
import Remove from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import List from '@material-ui/core/List';
import Comment from '../../comment/Comment';
import { getDocumentsType } from '../../../features/documents/selectors';

interface PackageListItemProps {
  index: number;
  document: INumberedDocumentView;
  actionTaken: ActionTakenType | undefined;
  onActionTakenChange: (event: any, index: number) => void;
  comment?: string;
  onCommentChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => void;
  existingComments?: IComment[];
  onRemoveComment: (commentId: string, index: number) => void;
  isConsultantReview?: boolean;
}

const useStyles = makeStyles({
  section: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#464546',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
});

export default function PackageListItem(props: PackageListItemProps) {
  const classes = useStyles();
  const {
    index,
    document,
    actionTaken,
    onActionTakenChange,
    comment,
    onCommentChange,
    onRemoveComment,
    existingComments,
    isConsultantReview,
  } = props;

  const dispatch = useDispatch();
  const documentType = useSelector(getDocumentsType)!;

  const [open, setOpen] = useState(true);
  const [commentsOpen, setCommentsOpen] = useState(true);

  const handleOpenSubmittalFile = () => {
    const fileId =
      document.submittalSectionFileId ||
      _.nth(
        document.files?.sort((a, b) => descendingComparator(a, b, 'createdOn')),
        0,
      )?.id;
    if (fileId) openInNewTab(fileId);
    else
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'This document has no files to open',
          severity: 'warning',
        }),
      );
  };

  const getActionTakenOptions = () => {
    const options = [
      <MenuItem key="empty" value={undefined} disabled>
        {documentType !== DocumentTemplateType.AsBuilt ? 'Action Taken' : 'Status'}
      </MenuItem>,
      <MenuItem key={ActionTakenType.NoExceptionsTaken} value={ActionTakenType.NoExceptionsTaken}>
        No Exceptions Taken
      </MenuItem>,
    ];
    if (documentType !== DocumentTemplateType.AsBuilt) {
      options.push(
        <MenuItem key={ActionTakenType.AmendAsNoted} value={ActionTakenType.AmendAsNoted}>
          Amend as Noted
        </MenuItem>,
        <MenuItem
          key={ActionTakenType.SeeSubmittalComments}
          value={ActionTakenType.SeeSubmittalComments}
        >
          See Submittal Comments
        </MenuItem>,
        <MenuItem
          key={ActionTakenType.SeeTransmittalComments}
          value={ActionTakenType.SeeTransmittalComments}
        >
          See Transmittal Comments
        </MenuItem>,
      );
    }
    options.push(
      <MenuItem key={ActionTakenType.ReviseAndResubmit} value={ActionTakenType.ReviseAndResubmit}>
        Revise & Resubmit
      </MenuItem>,
    );
    if (documentType !== DocumentTemplateType.AsBuilt) {
      options.push(
        <MenuItem
          key={ActionTakenType.SubmitSpecifiedItem}
          value={ActionTakenType.SubmitSpecifiedItem}
        >
          Submit Specified Item
        </MenuItem>,
        <MenuItem key={ActionTakenType.RemoveFromPackage} value={ActionTakenType.RemoveFromPackage}>
          Remove from Package
        </MenuItem>,
      );
    }
    return options;
  };

  return (
    <>
      <TableRow style={{ backgroundColor: 'transparent', height: 40 }}>
        <TableCell align="left" width={80}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ minWidth: '6%', color: 'black' }}>{`${index + 1}.`}</Typography>
          </div>
        </TableCell>
        <TableCell width={200} style={{ cursor: 'pointer' }}>
          <Typography
            onClick={handleOpenSubmittalFile}
            className={classes.section}
            style={{ minWidth: '20%' }}
          >
            {getDocumentIdentifier(document, documentType)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography style={{ color: 'black' }}>{document.title}</Typography>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => setOpen((prev) => !prev)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: 'transparent' }}>
        <TableCell colSpan={4} style={{ padding: 0 }}>
          <Collapse in={open} style={{ paddingLeft: 38 }}>
            <div className={classes.flexContainer} style={{ paddingBottom: 10 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: isConsultantReview ? 'row' : 'column',
                  width: isConsultantReview ? '100%' : '40%',
                }}
              >
                <Select
                  value={actionTaken}
                  onChange={(event) => onActionTakenChange(event, index)}
                  displayEmpty
                  variant="outlined"
                  style={{
                    width: '100%',
                    marginBottom: isConsultantReview ? 0 : 12,
                    height: isConsultantReview ? 60 : 'initial',
                  }}
                >
                  {getActionTakenOptions()}
                </Select>
                <TextField
                  variant="outlined"
                  placeholder={isConsultantReview ? 'Consultant Response...' : 'Comments...'}
                  multiline
                  rows={5}
                  value={comment}
                  style={{
                    width: '100%',
                    marginLeft: isConsultantReview ? 12 : 0,
                    marginRight: isConsultantReview ? 24 : 0,
                  }}
                  onChange={(event) => onCommentChange(event, index)}
                />
              </div>
              {!isConsultantReview && documentType !== DocumentTemplateType.AsBuilt ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '60%',
                    marginLeft: 32,
                    marginRight: 16,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography>Consultant Comments:</Typography>
                    <IconButton
                      onClick={() => {
                        setCommentsOpen((prev) => !prev);
                      }}
                      style={{ padding: 0 }}
                    >
                      {commentsOpen ? <Remove htmlColor="#0947B9" /> : <Add htmlColor="#0947B9" />}
                    </IconButton>
                  </div>
                  {commentsOpen && (
                    <Collapse in={commentsOpen}>
                      <List>
                        {existingComments?.map((comment) => (
                          <Comment
                            key={comment.id}
                            comment={comment}
                            deleteComment={() => onRemoveComment(comment.id, index)}
                          />
                        ))}
                        {existingComments?.length === 0 && (
                          <Typography style={{ marginLeft: '8px', fontWeight: 600 }}>
                            No comments to display.
                          </Typography>
                        )}
                      </List>
                    </Collapse>
                  )}
                </div>
              ) : null}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: 'transparent' }}>
        <TableCell colSpan={4} style={{ padding: 0 }}>
          <Divider />
        </TableCell>
      </TableRow>
    </>
  );
}
