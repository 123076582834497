import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { DocumentTemplateType, IBidSetup, IFile } from '../../api-client/autogenerated';
import { getDocumentsByProjectIdAndType } from '../../models/api/project';
import { Card, CardContent, createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import FolderAccordion from './FolderAccordion';
import BiddingTableSimple from './BiddingTableSimple';
import {
  downloadFileById,
  fileIsPdf,
  formatFileSize,
  getBidDrawingAndSpecificationFiles,
  openInNewTab,
} from '../../scripts/utils';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '../../main-components/icons/Download-icon';
import EyeIcon from '@material-ui/icons/Visibility';

type Props = {
  bidSetup?: IBidSetup;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    content: {
      display: 'flex',
      padding: 16,
      justifyContent: 'flex-end',
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      height: '100%',
      background: '#fff',
    },
    titleStyle: {
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
      fontSize: 26,
    },
  }),
);

function BidDocuments(props: Props) {
  const { bidSetup } = props;
  const classes = useStyles();

  const [bidAdvertisementFiles, setBidAdvertisementFiles] = useState<IFile[]>([]);
  const [specFiles, setSpecFiles] = useState<IFile[]>([]);
  const [drawingsFiles, setDrawingsFiles] = useState<IFile[]>([]);
  const [expandedPanel, setExpandedPanel] = useState<undefined | number>(undefined);

  const handleChangePanel = (num: number) => {
    if (expandedPanel === num) setExpandedPanel(undefined);
    else setExpandedPanel(num);
  };

  useEffect(() => {
    if (bidSetup) {
      if (bidSetup.project?.id) {
        getDocumentsByProjectIdAndType(
          bidSetup.project.id,
          DocumentTemplateType.BidAdvertisement,
        ).then((result) => {
          setBidAdvertisementFiles(
            _.flatten(
              result.map((doc) => {
                return doc.files ? doc.files : [];
              }),
            ),
          );
        });
      }

      const { drawingFiles, specificationFiles } = getBidDrawingAndSpecificationFiles(bidSetup);
      setSpecFiles(specificationFiles);
      setDrawingsFiles(drawingFiles);
    }
  }, [bidSetup]);

  return (
    <Card style={{ height: '100%' }}>
      <CardContent className={classes.titleStyle}>
        <h2
          className="h2"
          style={{
            textAlign: 'left',
            color: '#FFFFFF',
            margin: 0,
            lineHeight: 1,
            fontSize: 26,
          }}
        >
          Initial Bid Documents
        </h2>
      </CardContent>
      <CardContent>
        <FolderAccordion
          index={0}
          expandedIndex={expandedPanel}
          handleChangeIndex={handleChangePanel}
          name="Advertisement for Bids"
        >
          <BiddingTableSimple
            headers={['Name', 'Size', 'View/Download']}
            rows={bidAdvertisementFiles.map((x) => [
              x.name,
              x.size ? formatFileSize(x.size) : '',
              <div
                key={x.id}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <IconButton
                  onClick={() => openInNewTab(x.id, true)}
                  style={{ padding: 0, marginRight: 24 }}
                >
                  <EyeIcon />
                </IconButton>

                <IconButton onClick={() => downloadFileById(x.id, x.name)} style={{ padding: 0 }}>
                  <DownloadIcon />
                </IconButton>
              </div>,
            ])}
            emptyText="No Advertisement for Bids"
          />
        </FolderAccordion>
        <FolderAccordion
          index={2}
          expandedIndex={expandedPanel}
          handleChangeIndex={handleChangePanel}
          name="Project Manual"
        >
          <BiddingTableSimple
            headers={['Name', 'Size', 'View']}
            rows={specFiles.map((x) => [
              x.name,
              x.size ? formatFileSize(x.size) : '',
              fileIsPdf(x) ? (
                <IconButton
                  key={x.id}
                  onClick={() => openInNewTab(x.id, true)}
                  style={{ padding: 0 }}
                >
                  <EyeIcon />
                </IconButton>
              ) : null,
            ])}
            emptyText="No Project Manual"
          />
        </FolderAccordion>
        <FolderAccordion
          index={3}
          expandedIndex={expandedPanel}
          handleChangeIndex={handleChangePanel}
          name="Plans"
        >
          <BiddingTableSimple
            headers={['Name', 'Size', 'View']}
            rows={drawingsFiles.map((x) => [
              x.name,
              x.size ? formatFileSize(x.size) : '',
              fileIsPdf(x) ? (
                <IconButton
                  key={x.id}
                  onClick={() => openInNewTab(x.id, true)}
                  style={{ padding: 0 }}
                >
                  <EyeIcon />
                </IconButton>
              ) : null,
            ])}
            emptyText="No Plans"
          />
        </FolderAccordion>
      </CardContent>
    </Card>
  );
}

export default BidDocuments;
