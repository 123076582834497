import React from 'react';
import pluralize from 'pluralize';
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import Comment, { CustomComment } from './Comment';
import { IComment } from '../../api-client/autogenerated';
import CircularLoader from '../loader/CircularLoader';
import { isPublicPage } from '../../scripts/utils';

interface CommentSectionProps {
  comments?: CustomComment[];
  maxHeight?: number;
  isLoading?: boolean;
  commentsCountOverride?: number;
}

// Colors
const primaryColor = '#0947B9'; // blue

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    flexBasis: 'auto',
    boxDecoration: '0',
    flexGrow: 1,
    width: '100%',
  },
  list: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    flexBasis: 'auto',
    boxDecoration: '0',
    // background: '#F9F9F9',
    '&>:nth-child(even)': {
      background: '#E6EEFF',
    },
    '&>:nth-child(odd)': {
      background: '#F9F9F9',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    flexGrow: 1,
    padding: 0,
    margin: 0,
  },
  blue: {
    color: primaryColor,
    fontSize: 24,
    textTransform: 'none',
  },
  comment: {
    wordBreak: 'break-word',
    display: 'inline-block',
    width: '100%',
  },
});

export default function CommentSection(props: CommentSectionProps) {
  const classes = useStyles();
  const { comments, maxHeight = 460, isLoading, commentsCountOverride } = props;
  const commentsCount = commentsCountOverride || comments?.length || 0;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {commentsCount > 0 && (
          <h3 style={{ padding: 0, margin: '0px 0px 32px 0px' }} className={classes.blue}>
            {pluralize('Comment', commentsCount, true)}
          </h3>
        )}

        {comments?.length === 0 ? (
          <div style={{ paddingTop: 16, paddingBottom: 16 }}>No comments exist yet.</div>
        ) : (
          <div
            style={{
              display: 'flex',
              overflowX: 'hidden',
              overflowY: 'auto',
              flexGrow: 1,
              maxWidth: isPublicPage() ? 900 : undefined,
              maxHeight,
            }}
          >
            {isLoading ? (
              <CircularLoader fullWidth />
            ) : (
              <List className={classes.list}>
                {comments?.map((comment) => {
                  return (
                    <div key={comment.id} className={`${classes.comment}`}>
                      <Comment comment={comment} />
                    </div>
                  );
                })}
              </List>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
