import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DocumentTemplateType } from '../../api-client/autogenerated';
import { setSelectedDivision, setSelectedSection } from '../../features/documents/actions';
import { setDocument } from '../../features/document/actions';
import { documentTypeLookup, documentTypeToUrl } from '../../scripts/utils';
import { getProjectState } from '../../features/project/selectors';

interface DrawerNestedListItemProps {
  title: string;
  listIndex: number;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => void;
  selected: number;
  biddingPortal?: boolean;
  projectInfoType?: string;
  disabled?: boolean;
  handlePageChange?: () => void;
  handleTypeSelectBid?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: string) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: '0px 0px 10px 9px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&$selected, &$selected:hover': {
      backgroundColor: '#001A4D',
    },
  },
  biddingRoot: {
    padding: '8px 0px 0px 9px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&$selected, &$selected:hover': {
      backgroundColor: '#001A4D',
    },
  },
  button: {
    width: 216,
    height: 25,
    '&:hover': {
      backgroundColor: '#001A4D',
    },
  },
  selected: {
    backgroundColor: '#001A4D',
    '& *': {
      fontWeight: 700,
      color: '#FFFFFF',
    },
  },
}));

export default function DrawerNestedListItem(props: DrawerNestedListItemProps) {
  const classes = useStyles();
  const {
    title,
    listIndex,
    onClick,
    selected,
    biddingPortal,
    projectInfoType,
    disabled,
    handlePageChange,
    handleTypeSelectBid,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const currentProject = useSelector(getProjectState);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const itemDocType = title in documentTypeLookup ? documentTypeLookup[title] : title;

  const isSelected = () => {
    if (biddingPortal) {
      return projectInfoType === title;
    }
    const pathSplits = window.location.pathname.split('/');
    if (title === 'Bid Management') return pathSplits.includes('bid-mgmt');
    if (title === 'Construction Management') return pathSplits.includes('const-mgmt');
    if (title === 'Publishing Center') return pathSplits.includes('pub-center');
    if (title === 'File Manager') return pathSplits.includes('design');
    if (title === 'My Notifications') return pathSplits.includes('my-notifications');
    if (title === 'Correspondence Log') return pathSplits.includes('correspondence-log');
    return pathSplits.includes(documentTypeToUrl[itemDocType as DocumentTemplateType]);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 3000);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTypeSelect = async () => {
    if (itemDocType !== DocumentTemplateType.Submittals) {
      dispatch(setSelectedSection(null));
    }
    dispatch(setSelectedDivision(''));
    dispatch(setDocument(null));
    history.push(
      `/main/projects/${currentProject!.id}/documents/${
        documentTypeToUrl[itemDocType as DocumentTemplateType]
      }`,
    );
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <ListItem
        button
        selected={isSelected()}
        onClick={(event) => {
          if (!disabled) {
            onClick(event, listIndex);
            if (handlePageChange !== undefined) handlePageChange();
            else if (handleTypeSelectBid) handleTypeSelectBid(event, title);
            else handleTypeSelect();
          } else {
            handleTooltipOpen();
          }
        }}
        classes={{
          root: biddingPortal ? classes.biddingRoot : classes.root,
          button: classes.button,
          selected: classes.selected,
        }}
      >
        <Tooltip
          open={tooltipOpen}
          onClose={handleTooltipClose}
          arrow
          placement="left"
          title={disabled ? 'Select a project first.' : ''}
          disableHoverListener
          disableFocusListener
          disableTouchListener
        >
          <ListItemText
            secondary={title === 'Testing' ? 'Testing Reports' : title}
            style={{ flexGrow: 0 }}
          />
        </Tooltip>
      </ListItem>
    </ClickAwayListener>
  );
}
