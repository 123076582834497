import * as snackbarActions from './actions';
import { ActionType } from 'typesafe-actions';
import { SnackbarConfig } from './snackbar';
import { combineReducers } from 'redux';
import { ADD_SNACKBAR, REMOVE_ALL_SNACKBAR, REMOVE_SNACKBAR } from './constants';

export type SnackbarAction = ActionType<typeof snackbarActions>;
export type SnackbarState = {
  snackbar: SnackbarConfig;
};

export default combineReducers<SnackbarState, SnackbarAction>({
  snackbar: (state = [], action) => {
    let newState: SnackbarConfig;
    switch (action.type) {
      case ADD_SNACKBAR:
        newState = [...state, action.payload];
        break;
      case REMOVE_SNACKBAR:
        newState = [...state];
        const messageToDrop = state.findIndex((s) => s.id === action.payload);
        if (messageToDrop !== -1) newState.splice(messageToDrop, 1);
        break;
      case REMOVE_ALL_SNACKBAR:
        newState = [];
        break;
      default:
        newState = state;
    }
    return newState;
  },
});
