import { action } from 'typesafe-actions';
import { SET_IMPERSONATED_USER, SET_USER, UPDATE_USER } from './constants';
import { User } from './auth-user';
import { Dispatch } from 'react';
import { getLoggedInUser } from '../../models/api/users';
import { fetchSecurityGroups } from '../security/actions';
import { fetchProjects } from '../projects/actions';

export const fetchUser = (): any => (dispatch: Dispatch<any>) => {
  dispatch({ type: 'SET_USER_LOADING', payload: true });
  return getLoggedInUser()
    .then(({ user, originalUser }) => {
      if (originalUser) dispatch({ type: SET_IMPERSONATED_USER, payload: originalUser });
      dispatch(fetchProjects());
      dispatch(fetchSecurityGroups(user.id));
      return dispatch({ type: SET_USER, payload: user });
    })
    .catch(() => {})
    .finally(() => dispatch({ type: 'SET_USER_LOADING', payload: false }));
};

export const setUser = (user: User = { id: '', lastUpdatedByUserId: '', name: '', email: '' }) => {
  return action(SET_USER, user);
};

export const setImpersonatedUser = (
  user: User = { id: '', lastUpdatedByUserId: '', name: '', email: '' },
) => {
  return action(SET_IMPERSONATED_USER, user);
};

export const updateUser = (user: User) => {
  return action(UPDATE_USER, user);
};
