import { ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import {
  SET_DOCUMENT,
  SET_DOCUMENT_WITH_PERMISSIONS,
  SET_PERMISSIONS,
  UPDATE_DOCUMENT,
} from './constants';
import * as documentActions from './actions';
import { Document, DocumentPermissions } from './document';

export type DocumentAction = ActionType<typeof documentActions>;
export type DocumentState = { document: Document; permissions: DocumentPermissions };

export default combineReducers<DocumentState, DocumentAction>({
  document: (state = null, action) => {
    let newState: Document = null;
    switch (action.type) {
      case SET_DOCUMENT_WITH_PERMISSIONS:
        newState = action.payload.document;
        break;
      case SET_DOCUMENT:
        newState = action.payload;
        break;
      case UPDATE_DOCUMENT:
        if (state) newState = { ...state, ...action.payload };
        break;
      default:
        newState = state;
    }
    return newState;
  },
  permissions: (state = [], action) => {
    let newState: DocumentPermissions;
    switch (action.type) {
      case SET_DOCUMENT_WITH_PERMISSIONS:
        newState = action.payload.permissions;
        break;
      case SET_PERMISSIONS:
        newState = action.payload;
        break;
      default:
        newState = state;
    }
    return newState;
  },
});
