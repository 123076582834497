import React, { useEffect, useState } from 'react';
import { RouteChildrenProps, RouteComponentProps } from 'react-router';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserState } from '../features/user/selectors';
import FullscreenLoader from './loader/FullscreenLoader';
import { getUserLoadingState } from '../features/loading/selectors';
import { getProjectState } from '../features/project/selectors';
import {
  canViewBidding,
  canViewConstructionAndCloseout,
  canViewDesign,
  userCanViewDocument,
} from '../scripts/utils';
import { ISubscriber } from '../api-client/autogenerated';
import { fetchProject } from '../features/project/actions';
import { getSubscriberById } from '../models/api/subscribers';
import { getProductPackageState } from '../features/product-package/selectors';

interface PrivateRouteProps {
  children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
  render?: (props: RouteComponentProps<any>) => React.ReactNode;
  redirect?: string;
  path?: string | string[];
  exact?: boolean;
}

function PrivateRoute(props: PrivateRouteProps) {
  const { children, redirect = '/main/login', ...rest } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);
  const userLoading = useSelector(getUserLoadingState);
  const productPackage = useSelector(getProductPackageState);

  const [isLoading, setIsLoading] = useState(true);
  const [subscriber, setSubscriber] = useState<ISubscriber | undefined>(undefined);

  const isProject = history.location.pathname.includes('/projects/');
  const isCreateProject = history.location.pathname === '/main/project';
  const isDocument =
    history.location.pathname.includes('/documents') ||
    history.location.pathname.includes('/design');

  // this gets section of the url after /projects/
  // ex: for '/main/projects/7ccaae80-290a-4846-ac1d-0aa742434623/locked-project' it'll return 7ccaae80-290a-4846-ac1d-0aa742434623
  // for now this will either return the projectId or 'locked-project' on the lock create project page
  const getProjectSlug = (): string | undefined => {
    const splitUrl = history.location.pathname.split('/');
    if (splitUrl[2] === 'projects' && splitUrl[3]) return splitUrl[3];
  };

  // checks if the project has loaded in yet
  // if we don't need the project (for example, if we're not on a project page) this will always return false
  const isProjectLoading = (): boolean => {
    if (!isProject || getProjectSlug() === 'locked-project') return false;
    return (
      !project?.subscriber ||
      project.subscriber.isLocked === undefined ||
      project.id !== getProjectSlug() ||
      (isDocument && !productPackage)
    );
  };

  // if we need to fetch the project and the project hasn't loaded yet then load it using fetchProject()
  useEffect(() => {
    const slug = getProjectSlug();
    const correctProjectIsLoaded = !project || project.id !== slug;
    if (isProject && slug && slug !== 'locked-project' && correctProjectIsLoaded) {
      dispatch(fetchProject(slug));
    }
  }, [project, history.location.pathname]);

  // if we have don't have access to a project, we can't get the subscriber from the project object
  // so instead, we have to get it from the user object
  // we do this by calling getSubscriberId() with the user's adminOfSubscriberId
  // this might be a bit slower than calling project.subscriber
  useEffect(() => {
    const subscriberId = user.adminOfSubscriberId;
    if (isCreateProject && subscriberId) {
      getSubscriberById(subscriberId)
        .then((newSubscriber) => {
          setSubscriber(newSubscriber);
        })
        .catch((error) => console.log(error));
    }
  }, [isCreateProject, user.adminOfSubscriberId]);

  // check if data is still loading in
  useEffect(() => {
    // redirects to the private document page if a user is logged in
    if (history.location.state?.publicRedirect) {
      window.history.replaceState({}, document.title);
      window.location.reload();
    }
    if (isCreateProject) {
      setIsLoading(!subscriber || !user.id);
    } else {
      setIsLoading(!user.id);
    }
    // if user is not logged in, redirect to login page
    if (!user.id && !userLoading) {
      history.push(history.location.pathname.startsWith('/main') ? '/main' : '/bidding');
      setIsLoading(false);
    }
  }, [user, userLoading, isCreateProject, subscriber]);

  return (
    <>
      {isLoading || isProjectLoading() ? (
        <FullscreenLoader />
      ) : (
        <Route {...rest}>
          {((isProject && project?.subscriber?.isLocked) ||
            (isCreateProject && subscriber?.isLocked)) &&
          !history.location.pathname.includes('locked-project') ? (
            <Redirect
              to={
                isProject && project
                  ? `/main/projects/${project.id}/locked-project`
                  : '/main/projects/locked-project'
              }
            />
          ) : project &&
            isDocument &&
            !history.location.pathname.includes('access-restricted') &&
            !userCanViewDocument(
              history.location.pathname,
              canViewDesign(productPackage),
              canViewBidding(productPackage),
              canViewConstructionAndCloseout(productPackage),
            ) &&
            !user?.isSiteAdmin ? (
            <Redirect to={`/main/projects/${project.id}/documents/access-restricted`} />
          ) : user.id ? (
            children
          ) : (
            <Redirect to={redirect} />
          )}
        </Route>
      )}
    </>
  );
}

export default PrivateRoute;
