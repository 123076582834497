import { ActionType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { SET_BID, UPDATE_BID } from './constants';
import * as bidActions from './actions';
import { Bid } from './bid';

export type BidAction = ActionType<typeof bidActions>;
export type BidState = { bid: Bid };

export default combineReducers<BidState, BidAction>({
  bid: (state = null, action) => {
    let newState: Bid = null;
    switch (action.type) {
      case SET_BID:
        newState = action.payload;
        break;
      case UPDATE_BID:
        if (state) newState = { ...state, ...action.payload };
        break;
      default:
        newState = state;
    }
    return newState;
  },
});
