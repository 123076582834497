import React, { ChangeEvent, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, TextField, Tooltip } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectState, getProjectUsersState } from '../../../features/project/selectors';
import { getCompaniesState } from '../../../features/companies/selector';
import { ICompany, IProjectUser, IUser, IUserGroup } from '../../../api-client/autogenerated';
import { CancelButton } from '../../custom-components/CustomButtons';
import Button from '@material-ui/core/Button';
import { editUserGroup, insertUserGroup } from '../../../models/api/user-groups';
import CircularLoader from '../../loader/CircularLoader';
import { addSnackbar } from '../../../features/snackbar/actions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DistributionList from './DistributionList';
import _ from 'lodash';
import { ManagePermissionsDialogType } from '../../design/ManagePermissionsDialog';
import CompanyUserTreeView from './CompanyUserTreeView';

const useStyles = makeStyles((theme) => ({
  mobileButton: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      width: '90%',
      padding: 0,
    },
  },
}));

export function removeElementAtIndex<T>(prev: T[], index: number) {
  const tmp = [...prev];
  tmp.splice(index, 1);
  return tmp;
}

function getArrayDifference<T>(allElements: T[], existingElements: T[]) {
  return allElements.filter(
    (userId) => !existingElements.some((existingUserId) => userId === existingUserId),
  );
}

function removeNonProjectUsers(group: IUserGroup, projectUsers: IProjectUser[]): IUserGroup {
  return {
    ...group,
    userGroupCompanyList:
      group.userGroupCompanyList?.map((userGroupCompany) => {
        return {
          ...userGroupCompany,
          company: userGroupCompany.company
            ? {
                ...userGroupCompany.company,
                users:
                  userGroupCompany.company?.users?.filter(({ id }) =>
                    projectUsers.some(({ userId }) => userId === id),
                  ) || [],
              }
            : undefined,
        };
      }) || undefined,
  };
}

type Props = {
  existingGroup?: IUserGroup | null;
  onSubmit?: (userGroup: IUserGroup) => void;
  type?: ManagePermissionsDialogType;
};

export default function CreateUserGroup(props: Props) {
  const { existingGroup, onSubmit, type } = props;
  const classes = useStyles();

  const project = useSelector(getProjectState);
  const projectUsers = useSelector(getProjectUsersState);
  const companies = useSelector(getCompaniesState);
  const dispatch = useDispatch();

  const isDesign = type === ManagePermissionsDialogType.Design;

  const [eligibleCompanies, setEligibleCompanies] = useState<ICompany[]>([]);
  useEffect(() => {
    setEligibleCompanies(
      companies
        .map((company) => {
          return {
            ...company,
            users: company.users?.filter(({ id }) =>
              projectUsers.some(({ userId }) => userId === id),
            ),
          };
        })
        .filter(({ users }) => users && users.length > 0),
    );
  }, [companies]);

  const [inputName, setInputName] = useState<string>();
  const [inputDescription, setInputDescription] = useState<string | null>();

  // const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [excludedUsers, setExcludedUsers] = useState<string[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(!isDesign);

  // EDITING ONLY
  // const [existingUsers, setExistingUsers] = useState<string[]>([]);
  const [existingExcludedUsers, setExistingExcludedUsers] = useState<string[]>([]);
  const [existingCompanies, setExistingCompanies] = useState<string[]>([]);

  const getFieldsFromGroup = (existingGroup: IUserGroup) => {
    setInputName(existingGroup.name);
    setInputDescription(existingGroup.description);
    // const users =
    //   existingGroup.userGroupUserList
    //     ?.slice()
    //     .filter(({ isExcluded }) => !isExcluded)
    //     .map(({ userId }) => userId) || [];
    const excludedUsers =
      existingGroup.userGroupUserList
        ?.slice()
        .filter(({ isExcluded }) => isExcluded)
        .map(({ userId }) => userId) || [];
    const companies = existingGroup.userGroupCompanyList?.map(({ companyId }) => companyId) || [];
    // setSelectedUsers(users);
    // setExistingUsers(users);
    setExcludedUsers(excludedUsers);
    setExistingExcludedUsers(excludedUsers);
    setSelectedCompanies(companies);
    setExistingCompanies(companies);
  };

  useEffect(() => {
    resetFields();
    if (existingGroup) getFieldsFromGroup(existingGroup);
    else resetFieldsExisting();
  }, [existingGroup]);

  const getPendingUsers = () => {
    const pendingUsers = [] as IUser[];
    // selectedUsers.forEach((userId) => {
    //   const user = eligibleUsers.find((pUser) => pUser.userId === userId)?.user;
    //   if (user) pendingUsers.push(user);
    // });
    selectedCompanies.forEach((companyId) => {
      const companyUsers = eligibleCompanies.find((company) => company.id === companyId)?.users;
      if (companyUsers) pendingUsers.push(...companyUsers);
    });
    return _.uniqBy(
      pendingUsers.filter(({ id }) => !excludedUsers.some((userId) => userId === id)),
      (user) => user.id,
    );
  };

  const handleCheckCompany = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    companyId: string,
  ) => {
    if (checked) setSelectedCompanies([...selectedCompanies, companyId]);
    else {
      const index = selectedCompanies.findIndex((id) => id === companyId);
      if (index !== -1) {
        setSelectedCompanies((prev) => removeElementAtIndex(prev, index));
        const usersToRemove = eligibleCompanies.find(({ id }) => companyId === id)?.users;
        if (usersToRemove?.length)
          setExcludedUsers((prev) =>
            prev.filter((id) => !usersToRemove.some((user) => user.id === id)),
          );
      }
    }
  };

  const handleCheckNestedUser = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    userId: string,
    companyId: string,
  ) => {
    if (!checked) setExcludedUsers([...excludedUsers, userId]);
    else {
      if (selectedCompanies.some((id) => id === companyId)) {
        const index = excludedUsers.findIndex((id) => id === userId);
        if (index !== -1) setExcludedUsers((prev) => removeElementAtIndex(prev, index));
      } else {
        setSelectedCompanies([...selectedCompanies, companyId]);
        setExcludedUsers([
          ...excludedUsers,
          ...(eligibleCompanies
            .find(({ id }) => id === companyId)
            ?.users?.map(({ id }) => id)
            ?.filter((id) => id !== userId) || []),
        ]);
      }
    }
  };

  const resetFields = () => {
    setInputName('');
    setInputDescription('');
    // setSelectedUsers([]);
    setExcludedUsers([]);
    setSelectedCompanies([]);
  };

  const resetFieldsExisting = () => {
    // setExistingUsers([]);
    setExistingExcludedUsers([]);
    setExistingCompanies([]);
  };

  const handleReset = () => {
    if (existingGroup) getFieldsFromGroup(existingGroup);
    else resetFields();
  };

  const handleCreate = async () => {
    if (!inputName || !project) return;
    setIsSubmitting(true);
    try {
      let newGroup: IUserGroup;
      if (existingGroup) {
        // const usersToAdd = getArrayDifference(selectedUsers, existingUsers)?.map((userId) => {
        //   return { userId, isExcluded: false };
        // });
        const excludedUsersToAdd = getArrayDifference(excludedUsers, existingExcludedUsers)?.map(
          (userId) => {
            return { userId, isExcluded: true };
          },
        );
        const companiesToAdd = getArrayDifference(selectedCompanies, existingCompanies);

        // const usersToRemove = getArrayDifference(existingUsers, selectedUsers);
        const excludedUsersToRemove = getArrayDifference(existingExcludedUsers, excludedUsers);
        const companiesToRemove = getArrayDifference(existingCompanies, selectedCompanies);

        // const userGroupIdsToRemove = [...(usersToRemove || []), ...(excludedUsersToRemove || [])]
        const userGroupIdsToRemove = [...(excludedUsersToRemove || [])]
          .map(
            (userId) =>
              existingGroup.userGroupUserList?.find((group) => group.userId === userId)?.id,
          )
          .filter((id) => id) as string[];
        const companyGroupIdsToRemove = companiesToRemove
          ?.map(
            (companyId) =>
              existingGroup.userGroupCompanyList?.find((group) => group.companyId === companyId)
                ?.id,
          )
          .filter((id) => id) as string[];

        console.log('User changes', excludedUsersToAdd);

        newGroup = await editUserGroup(
          existingGroup.id,
          inputName !== existingGroup.name ? inputName : undefined,
          inputDescription !== existingGroup.description ? inputDescription || '' : undefined,
          [...(excludedUsersToAdd || [])],
          // [...(usersToAdd || []), ...(excludedUsersToAdd || [])],
          companiesToAdd,
          userGroupIdsToRemove,
          companyGroupIdsToRemove,
        );
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: `${existingGroup.name} modified successfully!`,
            severity: 'success',
          }),
        );
      } else {
        newGroup = await insertUserGroup(
          project.id,
          inputName.trim(),
          inputDescription?.trim(),
          [
            // ...selectedUsers.map((u) => {
            //   return { userId: u, isExcluded: false };
            // }),
            ...excludedUsers.map((e) => {
              return { userId: e, isExcluded: true };
            }),
          ],
          selectedCompanies,
        );
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: `${newGroup.name} created successfully!`,
            severity: 'success',
          }),
        );
        resetFields();
      }
      newGroup = removeNonProjectUsers(newGroup, projectUsers);
      if (onSubmit) onSubmit(newGroup);
    } catch (e: any) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getSubmitDisabled = () => {
    if (existingGroup)
      return (
        // _.isEqual(selectedUsers, existingUsers) &&
        inputName === existingGroup.name &&
        inputDescription === existingGroup.description &&
        _.isEqual(excludedUsers, existingExcludedUsers) &&
        _.isEqual(selectedCompanies, existingCompanies)
      );
    // return !inputName?.trim() || (selectedUsers.length === 0 && selectedCompanies.length === 0);
    else return !inputName?.trim() || selectedCompanies.length === 0;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={5}>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          label={existingGroup ? undefined : 'Name'}
          required
          // disabled={!!existingGroup}
          value={inputName}
          onChange={(e) => setInputName(e.target.value)}
        />
        {type !== ManagePermissionsDialogType.None && (
          <Typography style={{ marginTop: '16px' }}>
            Users listed with a <del>strikethrough</del> do not have permission to view{' '}
            {type === ManagePermissionsDialogType.AssociatedUsers
              ? 'this document'
              : 'the Design tab'}
          </Typography>
        )}
        <Tooltip
          title={'This list only shows users who have completed Centerline user registration.'}
          arrow
          placement={'right'}
        >
          <Typography style={{ width: 'fit-content', marginTop: '16px' }}>
            <i>Missing users?</i>
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={7}>
        <TextField
          size="small"
          multiline
          rows={5}
          variant="outlined"
          fullWidth
          label="Description (optional)"
          value={inputDescription}
          onChange={(e) => setInputDescription(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <CompanyUserTreeView
          type={type}
          companies={eligibleCompanies}
          selectedCompanies={selectedCompanies}
          excludedUsers={excludedUsers}
          handleCheckCompany={handleCheckCompany}
          handleCheckNestedUser={handleCheckNestedUser}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 16 }}>
        <div style={{ display: 'flex' }}>
          <IconButton onClick={() => setOpen((prev) => !prev)} style={{ padding: 0 }}>
            {open ? <Remove /> : <Add />}
          </IconButton>
          <Typography
            variant="h1"
            style={{ fontSize: 22, lineHeight: '22px', textTransform: 'none' }}
          >
            {type === ManagePermissionsDialogType.Design
              ? 'Pending Distribution List:'
              : 'Pending Notification List:'}
          </Typography>
        </div>
        <DistributionList open={open} users={getPendingUsers()} />
      </Grid>
      <Grid
        item
        container
        justify="space-between"
        wrap="nowrap"
        style={{ padding: '16px 24px 16px 24px' }}
      >
        <CancelButton onClick={handleReset}>Reset</CancelButton>
        {isSubmitting ? (
          <CircularLoader />
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={getSubmitDisabled()}
            onClick={handleCreate}
            className={classes.mobileButton}
          >
            {existingGroup ? 'Modify Group' : 'Create Group'}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
