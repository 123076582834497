import React, { useEffect, useState } from 'react';
import pluralize from 'pluralize';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import { Button, Card, Grid, Typography } from '@material-ui/core';
import { Pie } from 'react-chartjs-2';
import CircularLoader from '../loader/CircularLoader';
import { getProjectsSummary } from '../../models/api/project';
import DashboardCountdown from './DashboardCountdown';
import { useDispatch, useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import { setProject } from '../../features/project/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: 'none', // shadow override to fix misplaced shadow on the countdown
  },
  titleStyle: {
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
  },
  projects: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '44px',
    lineHeight: '52px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.6px',
    color: '#464546',
    alignSelf: 'center',
    paddingRight: 16,
  },
  openProjects: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '18px',
    letterSpacing: '1.21333px',
    textTransform: 'uppercase',
    color: '#464546',
    alignSelf: 'center',
    marginTop: 20,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  flexRow: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    alignSelf: 'center',
  },
  pieChartContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    padding: '20px 0px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  createNewProject: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    background: '#0947b9',
    cursor: 'pointer',
    // '&:hover': {
    //   color: '#0947b9',
    // },
  },
}));

export default function DashboardProjects() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const user = useSelector(getUserState);

  const [isLoading, setIsLoading] = useState(false);
  const [numProjects, setNumProjects] = useState(0);
  const [data, setPieData] = useState<number[]>([0, 0, 0]);

  useEffect(() => {
    setIsLoading(true);
    getProjectsSummary()
      .then(
        ({ numProjects: num, numProjectsBidding, numProjectsConstruction, numProjectsDesign }) => {
          setNumProjects(num);
          setPieData([numProjectsConstruction, numProjectsDesign, numProjectsBidding]);
        },
      )
      .catch(() => {
        setNumProjects(0);
        setPieData([0, 0, 0]);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const pieData = {
    labels: ['Construction', 'Design', 'Bidding'],
    datasets: [
      {
        data,
        backgroundColor: ['#73A3FF', '#A6C4FF', '#4380ED'],
        hoverBackgroundColor: ['#C4C4C4', '#C4C4C4', '#C4C4C4'],
      },
    ],
    options: {
      responsive: true,
      aspectRatio: 1,
      maintainAspectRatio: true,
      legend: {
        display: false,
      },
    },
    text: '',
  };

  const history = useHistory();

  const handleNewProject = () => {
    dispatch(setProject());
    history.push('/main/project');
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.titleStyle}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <h2
              className="h2"
              style={{
                textAlign: 'left',
                color: '#FFFFFF',
                margin: 0,
              }}
            >
              Projects
            </h2>
          </Grid>
        </Grid>
      </CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <DashboardCountdown />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CardContent className={classes.flexColumn}>
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  position: 'relative',
                  top: '50%',
                  paddingTop: 150,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularLoader />
              </div>
            ) : (
              <Grid container justify="center">
                {(user.isSiteAdmin || user.adminOfSubscriberId) && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    justify="center"
                    style={{ paddingBottom: 12 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.createNewProject}
                      onClick={handleNewProject}
                      style={{ paddingTop: '26px', paddingBottom: '26px' }}
                    >
                      Create New Project
                    </Button>
                  </Grid>
                )}
                <Grid className={classes.pieChartContainer}>
                  <Pie data={pieData} options={pieData.options} />
                </Grid>
                <Grid item>
                  <Typography className={classes.projects}>{numProjects}</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.openProjects}>
                    {pluralize('Open Project', numProjects)}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}
