import React from 'react';

const LogoWhiteIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={104.641}
    height={104.635}
    viewBox="0 0 27.686 27.685"
    fill="#FFF"
    {...props}
  >
    <g>
      <path d="M6.35 0A6.35 6.35 0 000 6.35v14.985a6.35 6.35 0 006.35 6.35h14.986a6.35 6.35 0 006.35-6.35V6.35A6.35 6.35 0 0021.336 0H6.35zm6.978 5.77c1.786 0 2.878.7 3.368 1.064.303.21.469.485.469.804 0 .469-.364.8-.805.8a.996.996 0 01-.59-.21c-.336-.259-1.091-.849-2.475-.849-2.155 0-3.792 1.67-3.792 3.853 0 1.89 1.228 3.394 2.957 3.764v-2.988c0-.502.364-.865.877-.865.501 0 .865.363.865.865v2.986c.837-.178 1.335-.58 1.656-.868.166-.132.38-.253.623-.253.469 0 .805.33.805.788 0 .27-.138.512-.32.683-.343.33-1.218 1.061-2.764 1.284v3.648h4.84c.457 0 .771.315.771.772 0 .469-.314.788-.771.788h-5.705c-.513 0-.877-.364-.877-.882V16.64c-2.718-.369-4.699-2.586-4.699-5.38 0-3.065 2.442-5.49 5.567-5.49z" />
    </g>
  </svg>
);

export default LogoWhiteIcon;
