import React, { useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  DocumentTemplateType,
  INumberedDocumentView,
  RespondToReviewPropsResponseEnum,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import EyeIcon from '@material-ui/icons/Visibility';
import DownloadIcon from '../icons/Download-icon';
import { Clear, HighlightOffRounded, InsertDriveFileOutlined, Publish } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { downloadFileById, fileIsPdf, openInNewTab } from '../../scripts/utils';
import FileUploadDialog from './FileUploadDialog';
import PDFIcon from '../icons/PDF-icon';
import { insertSubmissionByDocumentId, respondToReview } from '../../models/api/documents';
import { useDispatch, useSelector } from 'react-redux';
import { getAdditionalReviewDocuments, getDocumentState } from '../../features/document/selectors';
import { fetchDocument, updateDocument } from '../../features/document/actions';
import { CancelButton } from '../custom-components/CustomButtons';
import { descendingComparator } from '../document-index/DocumentIndexUtils';
import AssignedUserText from '../custom-components/AssignedUserText';
import CircularLoader from '../loader/CircularLoader';
import BluebeamStudioLink from '../custom-components/BluebeamStudioLink';
import { getDocumentsType } from '../../features/documents/selectors';

type Props = {
  open: boolean;
  onClose: () => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '30px',
      textAlign: 'left',
      textTransform: 'none',
      whiteSpace: 'pre',
      color: '#0947B9',
      marginBottom: '8px',
    },
    icon: {
      padding: 6,
    },
    rootIconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    bottomBorder: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#000',
    },
    file: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 16,
    },
    fileText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '13px',
      textAlign: 'center',
      textTransform: 'none',
      color: '#949494', // Gray 400
      paddingLeft: 8,
    },
    text: {
      display: 'flex',
      alignItems: 'center',
      height: 24,
    },
    centerIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 24,
      width: 24,
    },
  }),
);

function getMostRecentFile(document: INumberedDocumentView) {
  return document.files?.slice().sort((a, b) => descendingComparator(a, b, 'createdOn'))[0];
}

export default function CompileConsultantReviewDialog(props: Props) {
  const classes = useStyles();
  const { open, onClose } = props;

  const dispatch = useDispatch();
  const parentDocument = useSelector(getDocumentState)!;
  const docType = useSelector(getDocumentsType);
  const reviewDocuments = useSelector(getAdditionalReviewDocuments);

  const [removedDocuments, setRemovedDocuments] = useState<string[]>([]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File>();

  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const acceptedReviewDocuments = reviewDocuments.filter(
    (doc) => doc.workflowStatus === WorkflowStatusType.Accepted,
  );
  const activeReviewDocuments = acceptedReviewDocuments.filter(
    (d) => !removedDocuments.includes(d.id),
  );
  const lastReview = activeReviewDocuments.length === 1 ? activeReviewDocuments[0] : undefined;

  const removeDocument = (id: string) => {
    setRemovedDocuments((prev) => [...prev, id]);
  };

  const downloadAll = async () => {
    try {
      setIsDownloading(true);
      await Promise.all(
        activeReviewDocuments.map((doc) => {
          const file = getMostRecentFile(doc);
          if (file) return downloadFileById(file.id, file.name);
        }),
      );
    } finally {
      setIsDownloading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (uploadedFile) {
        const { document: updatedDocument } = await insertSubmissionByDocumentId(
          parentDocument.id,
          uploadedFile.name,
          uploadedFile,
        );
        setUploadedFile(undefined);
        dispatch(updateDocument(updatedDocument));
        onClose();
      } else if (lastReview) {
        await respondToReview(lastReview.id, {
          response: RespondToReviewPropsResponseEnum.Resolved,
        });
        dispatch(fetchDocument(parentDocument.id));
        onClose();
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setRemovedDocuments([]);
  }, [open]);

  const lastReviewHasFile = () => {
    return lastReview?.files?.length;
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { minWidth: 720 } }}>
      <DialogTitle style={{ paddingBottom: 0 }}>
        <Typography className={classes.title}>Combine & Compile Consultant Reviews</Typography>
        <IconButton
          style={{ right: '22px', top: '18px', position: 'absolute' }}
          onClick={onClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ marginBottom: 8 }}>
          The results of your accepted Consultant Reviews are displayed here. If multiple versions
          have been uploaded, review each to determine which markups you want to distribute. If a
          Bluebeam Studio session is active, download the markups from there as well. Combine all
          markups into one FINAL version that you would like to distribute, and UPLOAD it here.
        </Typography>
        <Typography className={classes.text}>
          VIEW (<EyeIcon htmlColor="#757575" />) the files below
        </Typography>
        <Typography className={classes.text}>
          REMOVE (<Clear htmlColor="#757575" />) any files containing duplicate markups
        </Typography>
        <Typography className={classes.text}>
          DOWNLOAD (
          <div className={classes.centerIcon}>
            <DownloadIcon marginRight={-1} marginBottom={0} />
          </div>
          ) files that contain separate markups
        </Typography>
        <Typography className={classes.text}>
          COMBINE the marked up versions offline into a single PDF file
        </Typography>
        <Typography className={classes.text} style={{ marginBottom: 8 }}>
          UPLOAD (<Publish htmlColor="#757575" />) the new combined version here for{' '}
          {docType === DocumentTemplateType.DesignPackages
            ? 'distribution'
            : 'return to the Contractor'}
        </Typography>
        <TableContainer
          style={{ maxHeight: 500, borderWidth: 1, borderStyle: 'solid', borderColor: '#000' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableCell className={classes.bottomBorder} style={{ width: '25%' }}>
                Reviewer
              </TableCell>
              <TableCell className={classes.bottomBorder} style={{ width: '99%' }}>
                File
              </TableCell>
              <TableCell className={classes.bottomBorder} style={{ paddingLeft: 24 }}>
                Actions
              </TableCell>
            </TableHead>
            <TableBody>
              {activeReviewDocuments.map((document, index) => {
                const file = getMostRecentFile(document);
                const reviewer = document?.additionalReviewRecommendedBy;
                const reviewerCompany = reviewer?.company?.name;

                return (
                  <TableRow key={file?.id || index}>
                    <TableCell>
                      <AssignedUserText name={reviewer?.name} company={reviewerCompany} />
                    </TableCell>
                    <TableCell>
                      <Typography>{file?.name || 'No File'}</Typography>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      <Tooltip
                        arrow
                        placement="top"
                        title="View"
                        style={{ visibility: file && fileIsPdf(file) ? 'visible' : 'hidden' }}
                      >
                        <IconButton onClick={() => openInNewTab(file!.id)} className={classes.icon}>
                          <EyeIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        arrow
                        placement="top"
                        title="Download"
                        style={{ visibility: file ? 'visible' : 'hidden' }}
                      >
                        <IconButton
                          onClick={() => downloadFileById(file!.id, file!.name)}
                          className={classes.icon}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip arrow placement="top" title="Remove">
                        <IconButton
                          onClick={() => removeDocument(document.id)}
                          className={classes.icon}
                          style={{ paddingLeft: 0 }}
                        >
                          <Clear />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {parentDocument.bluebeamStudioSessionId ? (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
            <BluebeamStudioLink studioSessionId={parentDocument.bluebeamStudioSessionId} />
          </div>
        ) : null}
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
          <Button
            variant="outlined"
            color="primary"
            style={{ height: 32, width: 150, alignItems: 'center' }}
            disabled={!!uploadedFile}
            onClick={() => setUploadDialogOpen(true)}
            startIcon={<Publish fill="#2C69D6" />}
          >
            UPLOAD
          </Button>
          <div className={classes.file} style={{ visibility: !uploadedFile ? 'hidden' : 'unset' }}>
            <IconButton
              onClick={() => {
                setUploadedFile(undefined);
              }}
              style={{ padding: 0, marginRight: 8, marginLeft: -2 }}
            >
              <HighlightOffRounded />
            </IconButton>
            {uploadedFile?.name.endsWith('.pdf') ? (
              <PDFIcon />
            ) : (
              <InsertDriveFileOutlined htmlColor="#7A797A" style={{ fontSize: 30 }} />
            )}
            <Typography className={classes.fileText}>{uploadedFile?.name}</Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', padding: 24 }}>
        <CancelButton onClick={onClose} />
        {!isLoading ? (
          <Button
            variant="contained"
            color="primary"
            disabled={!uploadedFile && !lastReviewHasFile()}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        ) : (
          <CircularLoader />
        )}
      </DialogActions>
      <FileUploadDialog
        open={uploadDialogOpen}
        handleClose={() => setUploadDialogOpen(false)}
        title="Upload File"
        removeFile={() => setUploadedFile(undefined)}
        addFile={(f) => setUploadedFile(f as File)}
        file={uploadedFile}
        disableComments
        disableDesignUpload
      />
    </Dialog>
  );
}
