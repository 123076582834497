import React from 'react';

const SubmitIcon = (props: any) => (
  <svg width={19} height={19} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.469.496L1.844 8.934c-1.23.703-1.125 2.496.176 3.023l4.043 1.652v2.954c0 1.652 2.074 2.32 3.023 1.019l1.547-2.074 3.937 1.617c.211.105.422.14.633.14.281 0 .563-.07.844-.21a1.81 1.81 0 00.844-1.23l2.074-13.606c.21-1.406-1.3-2.426-2.496-1.723zM7.75 16.563v-2.25l1.266.527-1.266 1.723zm7.453-.985l-5.414-2.25 4.957-7.101c.352-.563-.351-1.196-.844-.739L6.45 11.957l-3.761-1.582 14.624-8.438-2.109 13.641z"
      fill="#4380ED"
      {...props}
    />
  </svg>
);

export default SubmitIcon;
