import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Add, HighlightOffRounded } from '@material-ui/icons';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  getAllUsersSimplifiedBySubscriberId,
  getUserByEmail,
  patchUser,
} from '../../models/api/users';
import CircularLoader from '../loader/CircularLoader';
import {
  ICompany,
  IProjectUser,
  ISecurityGroup,
  IUser,
  IUserSimplified,
  PhoneNumberType,
} from '../../api-client/autogenerated';
import { addOrModifyUsersOfProject } from '../../models/api/project';
import { getCompanies, getCompanyByName, insertCompany } from '../../models/api/companies';
import { Divider, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { UserActionType } from '../form-pages/AddOrManageProjectPage';
import { Autocomplete } from '@material-ui/lab';
import AddPreviousUsersTable from './AddPreviousUsersTable';
import { useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import { getProjectState } from '../../features/project/selectors';

type AddUserDialogProps = {
  dialogOpen: boolean;
  selectedProjectId: string | undefined;
  users: IProjectUser[] | undefined;
  handleClose: () => void;
  reloadUsers: () => void | undefined;
  createProjectPage?: boolean;
  callback?: (projectUser: IProjectUser, action?: UserActionType) => void;
  createProjectUsers: (projectUser: IProjectUser[], action?: UserActionType) => void;
  securityGroups: ISecurityGroup[];
};

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '50px',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
    minWidth: 500,
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'center',
    color: '#0947B9',
    textTransform: 'uppercase',
    justifySelf: 'center',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 8,
  },
  textField: {
    maxWidth: 350,
  },
  submit: {
    marginTop: 16,
    marginBottom: 24,
    maxWidth: 280,
    fontSize: '1em',
  },
  addFromPrev: {
    maxWidth: 280,
    fontSize: '1em',
  },
  smallButton: {
    marginTop: 16,
    marginBottom: 24,
    maxWidth: 240,
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  center2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '13px',
    whiteSpace: 'nowrap',
  },
});

function AddUserDialog(props: AddUserDialogProps) {
  const classes = useStyles();
  const {
    dialogOpen,
    handleClose,
    selectedProjectId,
    users,
    reloadUsers,
    createProjectPage,
    callback = () => {},
    createProjectUsers,
    securityGroups,
  } = props;

  const [dialogState, setDialogState] = useState('add');
  const [inputEmail, setInputEmail] = useState('');
  const [inputFirstName, setInputFirstName] = useState('');
  const [inputLastName, setInputLastName] = useState('');
  const [inputPhone, setInputPhone] = useState('');
  const [inputCompany, setInputCompany] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
  const [inputCompanyLocation, setInputCompanyLocation] = useState('');
  const [inputCompanyPhone, setInputCompanyPhone] = useState('');
  const [inputCompanyEmail, setInputCompanyEmail] = useState('');
  const [inputCompanyWebsite, setInputCompanyWebsite] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(undefined);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [userToAdd, setUserToAdd] = useState<IUser | undefined>();
  const [simplifiedUsers, setSimplifiedUsers] = useState<IUserSimplified[]>();

  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);

  useEffect(() => {
    if (!simplifiedUsers && (user.adminOfSubscriberId || user.isSiteAdmin)) {
      //If create project page, use the subscriber id the user is an admin of. On manage project page, just use project's subscriber id.
      getAllUsersSimplifiedBySubscriberId(
        project ? project.subscriberId : user.adminOfSubscriberId!,
      )
        .then((newUsers) => {
          setSimplifiedUsers(newUsers);
        })
        .catch(() => {});
    }
  }, [user, project, simplifiedUsers]);

  useEffect(() => {
    if (dialogOpen) {
      setDialogState('add');
      setEmailError(false);
    }
  }, [dialogOpen]);

  const resetDialog = (initialPage = 'add') => {
    setDialogState(initialPage);
    setEmailError(false);
    setInputEmail('');
    setInputFirstName('');
    setInputLastName('');
    setInputPhone('');
  };

  useEffect(() => {
    getCompanies(5000).then((comp) => {
      setCompanies(comp);
    });
  }, []);

  const addUser = () => {
    // TODO: should not assume these are not undefined (even though that's usually true)
    let valid = true;
    if (!selectedGroupId) valid = false;
    if (!selectedCompany && !userToAdd!.companyId) valid = false;
    if (valid) {
      setDialogState('adding');
      if (!createProjectPage) {
        addOrModifyUsersOfProject(selectedProjectId!, [
          { securityGroupId: selectedGroupId!, userId: userToAdd!.id! },
        ])
          .then(() => {
            setDialogState('added');
            reloadUsers();
          })
          .catch(() => {
            setDialogState('unexpected error');
          });
      } else {
        const tempUser: IUser = { ...userToAdd! };
        if (!userToAdd!.companyId) {
          patchUser(userToAdd!.id, { companyId: selectedCompany!.id });
          tempUser.companyId = selectedCompany!.id;
          // @ts-ignore
          tempUser.company = {
            id: 'N/A',
            name: selectedCompany!.name,
          };
        }
        // @ts-ignore // TODO: this is very wrong
        callback!({
          user: tempUser!,
          securityGroupId: selectedGroupId,
          securityGroup: {
            lastUpdatedByUserId: 'N/A',
            id: 'N/A',
            subscriberId: 'N/A',
            name: securityGroups!.find((x) => x.id === selectedGroupId)!.name!,
          },
        });
        setDialogState('added');
      }
    }
  };

  const findUserByEmail = (email: string) => {
    setEmailError(false);
    if (
      users!.filter((user) => user.user!.email.toLowerCase() === email.toLowerCase()).length > 0
    ) {
      setEmailError(true);
    } else {
      // Could just pass down the user from when we get it in handleInvite
      getUserByEmail(email).then((result) => {
        if (result && ((result.id && selectedProjectId) || (result.id && createProjectPage))) {
          setUserToAdd(result);
          setDialogState('add details');
        } else {
          setDialogState('user does not exist');
        }
      });
    }
  };

  const closeSelf = () => {
    handleClose();
  };

  const handleInvite = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      selectedCompany !== null &&
      inputFirstName !== '' &&
      inputLastName !== '' &&
      selectedGroupId !== undefined
    ) {
      getCompanyByName(inputCompany)
        .then((company) => {
          if (company.name) {
            callback!(createDummyUser(company.id), UserActionType.Invite);
            setDialogState('added');
            reloadUsers();
            /*
            insertUser(
              { email: inputEmail, name: inputName, companyId: company.id },
              selectedProjectId || '',
              selectedGroupId || '',
            )
              .then(async () => {
                if (!inputPhone) findUserByEmail(inputEmail);
                const user = await getUserByEmail(inputEmail);
                if (!user) throw new Error('Failed to find user by email.');
                await insertPhoneNumberByUserId(user.id, {
                  number: inputPhone.replace(/[-()\s]/g, ''),
                  type: PhoneNumberType.Mobile,
                  countryId: '',
                });
                findUserByEmail(inputEmail);
              })
              .catch(() => {
                setDialogState('unexpected error');
              });
              */
          } else {
            setDialogState('company does not exist');
          }
        })
        .catch(() => {
          setDialogState('company does not exist');
        });
    }
  };

  const createDummyUser = (companyId: string) => {
    return {
      id: 'N/A1',
      securityGroupId: selectedGroupId,
      securityGroup: {
        id: selectedGroupId || '',
        name: securityGroups.find((x) => x.id === selectedGroupId)!.name || '',
      },
      lastUpdatedByUserId: 'N/A2',
      projectId: 'N/A3',
      userId: 'N/A4',
      user: {
        id: 'N/A5',
        lastUpdatedByUserId: 'N/A6',
        email: inputEmail,
        name: `${inputFirstName} ${inputLastName}`,
        companyId: companyId,
        company: {
          id: companyId,
          name: inputCompany,
        },
        phoneNumbers: [
          {
            id: 'N/A7',
            lastUpdatedByUserId: 'N/A8',
            number: inputPhone.replace(/[-()\s]/g, ''),
            type: PhoneNumberType.Mobile,
            countryId: '',
          },
        ],
      },
    } as IProjectUser;
  };

  const handleAddCompany = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      inputCompany !== '' &&
      inputCompanyEmail !== '' &&
      inputCompanyLocation !== '' &&
      inputCompanyWebsite !== '' &&
      inputCompanyPhone !== ''
    ) {
      let website = inputCompanyWebsite;
      if (
        inputCompanyWebsite &&
        !inputCompanyWebsite.includes('http://') &&
        !inputCompanyWebsite.includes('https://')
      ) {
        website = `http://${inputCompanyWebsite}`;
      }
      insertCompany({
        name: inputCompany,
        email: inputCompanyEmail,
        website,
      })
        .then(() => {
          setDialogState('company added');
        })
        .catch((e) => {
          console.error(e);
          setDialogState('unexpected error');
        });
    }
  };

  let dialogContent = (
    <form className={classes.form} noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email of user"
        name="email"
        autoComplete="email"
        className={classes.textField}
        value={inputEmail}
        onChange={(e) => setInputEmail(e.target.value.trim())}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        error={emailError}
        helperText={emailError ? 'User already exists in this project with that email' : ''}
      />
      <Button
        variant="contained"
        color="primary"
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          marginTop: 16,
          marginBottom: 16,
          marginRight: 16,
          alignSelf: 'flex-end',
        }}
        id="initial-add-button"
        onClick={() => findUserByEmail(inputEmail)}
      >
        <Add style={{ paddingRight: 2 }} />
        Add
      </Button>
      <Divider style={{ width: 'calc(100% - 32px)' }} />
      <div
        style={{
          paddingTop: 16,
          paddingBottom: 16,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2">or</Typography>
        <Button
          color="primary"
          fullWidth
          className={classes.addFromPrev}
          onClick={() => setDialogState('add previous users')}
        >
          Add Users From Previous Projects
        </Button>
      </div>
    </form>
  );

  const addNewCompany = () => {
    console.log('Attempt to add company');
    const companiesTemp = [...companies];
    insertCompany({ name: inputCompany }).then((comp) => {
      companiesTemp.push(comp);
      setCompanies(companiesTemp);
      setInputCompany(comp.name);
      setSelectedCompany(comp);
    });
  };

  if (dialogState === 'adding') {
    dialogContent = <CircularLoader />;
  } else if (dialogState === 'added') {
    dialogContent = (
      <div className={classes.center2}>
        <span style={{ alignSelf: 'center', marginBottom: '16px', marginTop: 16 }}>
          User added to project invitation list.
        </span>
        <span
          style={{
            alignSelf: 'center',
            color: 'red',
            marginBottom: '8px',
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          User(s) will not be invited until project details are submitted below.
        </span>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: 190 }}
            className={classes.submit}
            onClick={handleClose}
          >
            Done
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ width: 190 }}
            className={classes.submit}
            onClick={() => {
              resetDialog();
            }}
          >
            Add Another User
          </Button>
        </div>
      </div>
    );
  } else if (dialogState === 'users added') {
    //Yes this is the exact same code as above but with an "(s)" added to the end of user
    dialogContent = (
      <div className={classes.center2}>
        <span style={{ alignSelf: 'center', marginBottom: '16px', marginTop: 16 }}>
          User(s) added to project invitation list.
        </span>
        <span
          style={{
            alignSelf: 'center',
            color: 'red',
            marginBottom: '8px',
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          User(s) will not be invited until project details are submitted below.
        </span>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: 190 }}
            className={classes.submit}
            onClick={handleClose}
          >
            Done
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ width: 190 }}
            className={classes.submit}
            onClick={() => {
              resetDialog();
            }}
          >
            Add Another User
          </Button>
        </div>
      </div>
    );
  } else if (dialogState === 'unexpected error') {
    dialogContent = <div className={classes.center}>An unexpected error has occurred.</div>;
  } else if (dialogState === 'user does not exist') {
    dialogContent = (
      <div>
        <div className={classes.center}>
          User does not currently exist. Would you like to invite {inputEmail}?
        </div>
        <div className={classes.center}>
          <Button
            id="invite-add-user-dialog-button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => setDialogState('invite')}
          >
            Invite
          </Button>
        </div>
      </div>
    );
  } else if (dialogState === 'invite') {
    dialogContent = (
      <form className={classes.form}>
        <div className={classes.center}>Please fill in the information below to invite a user</div>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="Email"
          className={classes.textField}
          defaultValue={inputEmail}
          disabled
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="First Name"
          id="first-name"
          autoComplete="First Name"
          required
          className={classes.textField}
          value={inputFirstName}
          onChange={(e) => setInputFirstName(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Last Name"
          id="last-name"
          autoComplete="Last Name"
          className={classes.textField}
          required
          value={inputLastName}
          onChange={(e) => setInputLastName(e.target.value)}
        />

        {/*
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="phone"
          label="Phone"
          name="phone"
          autoComplete="Phone"
          className={classes.textField}
          defaultValue={inputPhone}
          InputProps={{
            inputComponent: TextMaskCustom as any,
            value: inputPhone,
            onChange: (e) => setInputPhone(e.target.value),
          }}
        />
        */}
        <Autocomplete
          fullWidth
          options={companies}
          getOptionLabel={(option: ICompany) => option.name}
          inputValue={inputCompany}
          onInputChange={(e, value) => setInputCompany(value)}
          onChange={(e, value) => setSelectedCompany(value)}
          className={classes.textField}
          limitTags={5}
          filterOptions={(comps, state) =>
            state.inputValue.length > 2
              ? comps
                  .filter((x) => x.name.toLowerCase().includes(state.inputValue.toLowerCase()))
                  .slice(0, 4)
              : []
          }
          noOptionsText={
            inputCompany.length > 2 ? (
              <MenuItem test-id="add-new-company" onMouseDown={addNewCompany}>
                Add "{inputCompany}" as a new company
              </MenuItem>
            ) : (
              'Type to see results'
            )
          }
          autoComplete
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                test-id="company-input"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Company"
                autoComplete="Company"
                name="company"
                className={classes.textField}
              />
            );
          }}
          value={selectedCompany}
        />
        <FormControl
          variant="outlined"
          className={classes.textField}
          fullWidth
          margin="none"
          style={{ marginTop: 16 }}
        >
          <InputLabel id="demo-simple-select-outlined-label">Security Group</InputLabel>
          <Select
            id="security-group-dropdown"
            value={selectedGroupId}
            style={{ minWidth: 300, marginRight: 24 }}
            onChange={(event) => setSelectedGroupId(event.target.value as string)}
            margin="none"
            fullWidth
          >
            {securityGroups?.map((group) => {
              return (
                <MenuItem key={group.id} value={group.id!}>
                  {group.name!}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button
          id="invite-add-user-dialog-button"
          onClick={handleInvite}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Invite
        </Button>
      </form>
    );
  } else if (dialogState === 'add details') {
    dialogContent = (
      <form className={classes.form}>
        <div className={classes.center}>
          Select a security group{!userToAdd!.companyId && ' and company'} for this user
        </div>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="Email"
          className={classes.textField}
          defaultValue={inputEmail}
          disabled
        />
        {!userToAdd!.companyId && (
          <Autocomplete
            id="combo-box-demo"
            fullWidth
            options={companies}
            getOptionLabel={(option: ICompany) => option.name}
            inputValue={inputCompany}
            onInputChange={(e, value) => setInputCompany(value)}
            onChange={(e, value) => setSelectedCompany(value)}
            className={classes.textField}
            limitTags={5}
            filterOptions={(comps, state) =>
              state.inputValue.length > 2
                ? comps
                    .filter((x) => x.name.toLowerCase().includes(state.inputValue.toLowerCase()))
                    .slice(0, 4)
                : []
            }
            noOptionsText={
              inputCompany.length > 2 ? (
                <MenuItem onMouseDown={addNewCompany}>
                  Add "{inputCompany}" as a new company
                </MenuItem>
              ) : (
                'Type to see results'
              )
            }
            autoComplete
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Company"
                autoComplete="Company"
                name="email"
                className={classes.textField}
              />
            )}
            value={selectedCompany}
          />
        )}
        <FormControl
          id="security-group-dropdown"
          variant="outlined"
          className={classes.textField}
          fullWidth
          required
          margin="none"
          style={{ marginTop: 16 }}
        >
          <InputLabel id="demo-simple-select-outlined-label">Security Group</InputLabel>
          <Select
            value={selectedGroupId}
            style={{ minWidth: 300, marginRight: 24 }}
            onChange={(event) => setSelectedGroupId(event.target.value as string)}
            margin="none"
            fullWidth
          >
            {securityGroups?.map((group) => {
              return (
                <MenuItem key={group.id} value={group.id!}>
                  {group.name!}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button
          id="security-group-step-add-button"
          onClick={addUser}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Add to Invitation List
        </Button>
      </form>
    );
  } else if (dialogState === 'company does not exist') {
    dialogContent = (
      <div>
        <div className={classes.center}>
          {inputCompany} does not currently exist. Would you like to fill out information for this
          company?
        </div>
        <div className={classes.spaceBetween}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.smallButton}
            onClick={() => setDialogState('invite')}
          >
            Back
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.smallButton}
            onClick={() => setDialogState('add company')}
          >
            Fill out company info
          </Button>
        </div>
      </div>
    );
  } else if (dialogState === 'add company') {
    dialogContent = (
      <form className={classes.form} noValidate>
        <div className={classes.center}>Please fill in the information below to add a company</div>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Company Name"
          name="email"
          autoComplete="Company Name"
          className={classes.textField}
          defaultValue={inputCompany}
          disabled
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Location"
          id="Description"
          required
          autoComplete="Location"
          className={classes.textField}
          value={inputCompanyLocation}
          onChange={(e) => setInputCompanyLocation(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Phone Number"
          autoComplete="Phone Number"
          name="email"
          className={classes.textField}
          value={inputCompanyPhone}
          onChange={(e) => setInputCompanyPhone(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Email"
          autoComplete="Email"
          name="email"
          className={classes.textField}
          value={inputCompanyEmail}
          onChange={(e) => setInputCompanyEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Website"
          autoComplete="Website"
          name="email"
          className={classes.textField}
          value={inputCompanyWebsite}
          onChange={(e) => setInputCompanyWebsite(e.target.value)}
        />
        <Button
          onClick={handleAddCompany}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Add Company
        </Button>
      </form>
    );
  } else if (dialogState === 'company added') {
    dialogContent = (
      <div>
        <div className={classes.center}>Company successfully added</div>
        <div className={classes.center}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => setDialogState('invite')}
          >
            Return to invite user
          </Button>
        </div>
      </div>
    );
  } else if (dialogState === 'add previous users') {
    dialogContent = (
      <AddPreviousUsersTable
        createProjectUsers={createProjectUsers}
        handleBack={() => setDialogState('add')}
        users={users}
        securityGroups={securityGroups}
        handleUsersAdded={() => setDialogState('users added')}
        simplifiedUsers={simplifiedUsers}
      />
    );
  }

  return (
    <Dialog maxWidth="lg" open={dialogOpen} onClose={closeSelf}>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title} align="center">
          Add User
        </Typography>
        <IconButton
          id="add-user-dialog-close-button"
          style={{ right: '20px', top: '12px', position: 'absolute' }}
          onClick={closeSelf}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <div style={{ height: '17%' }} />
      <DialogContent style={{ padding: 0 }}>{dialogContent}</DialogContent>
    </Dialog>
  );
}

export default AddUserDialog;
