import React, { useEffect, useState } from 'react';
import { HighlightOffRounded } from '@material-ui/icons';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { DocumentTemplateType, INumberedDocumentView } from '../../api-client/autogenerated';
import { getDocumentsWithNoPackage } from '../../models/api/project';
import { addDocumentToPackage } from '../../models/api/documents';
import { useSelector } from 'react-redux';
import { getDocumentsType } from '../../features/documents/selectors';
import { getDocumentState } from '../../features/document/selectors';
import { getProjectId } from '../../features/project/selectors';
import { divisionToDisplayString } from '../document-display/DocumentDisplay';
import { BootstrapInput } from '../custom-components/CustomButtons';
import { getDocumentIdentifier } from '../../scripts/utils';

interface AddSubmittalToPackageProps {
  open: boolean;
  handleClose: () => void;
  handleAddDocument: (submittal: INumberedDocumentView) => void;
}

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  rootIconButton: {
    padding: '0px 8px 0px 0px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  fileBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    padding: '12px',
    marginBottom: '16px',
    border: '1px solid #EDECEC',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  fileName: {
    paddingLeft: '8px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '29px',
    color: '#464546',
  },
  body: {
    marginBottom: '24px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    color: '#464546',
  },
  textfield: {
    /* Gray / Gray 50 */
    background: '#F9F9F9',
    borderRadius: '5px',
    color: '#464546',
    fontStyle: 'normal',
  },
  icon: {
    fill: '#B2B1B2',
    marginLeft: '4px',
  },
  formControl: {
    display: 'inline-flex',
    width: '60%',
    marginRight: '8px',
    marginBottom: '12px',
  },
  datepickerLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '22px',
    textTransform: 'uppercase',
    color: '#616061',
  },
  datepicker: {
    background: '#F9F9F9',
    width: '60%',
    height: '35px',
  },
  actions: {
    padding: '24px',
  },
  centerIcon: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: 'auto',
    flexWrap: 'nowrap',
    height: '100%',
    justifyContent: 'flex-end',
  },
  index: {
    width: 48,
  },
  listItem: {
    height: 32,
    transition: '0.09s',
    display: 'flex',
    '&:hover': {
      background: '#0947B9',
      color: 'white',
    },
    '@global > div > button': {
      color: '#949494',
    },
    '&:hover > div > button': {
      color: '#FF9C17',
    },
  },
  submittalId: {
    width: '30%',
    paddingRight: 24,
  },
  undoIconContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingRight: 8,
    color: '#949494',
  },
}));

export default function AddSubmittalToPackageDialog(props: AddSubmittalToPackageProps) {
  const classes = useStyles();
  const { open, handleClose, handleAddDocument } = props;

  const documentPackage = useSelector(getDocumentState);
  const docType = useSelector(getDocumentsType)!;
  const projectId = useSelector(getProjectId);

  const [documents, setDocuments] = useState<INumberedDocumentView[]>([]);
  const [searchInput, setSearchInput] = useState<string>();
  const [selectedDivision, setSelectedDivision] = useState<string>();

  const isSubmittal =
    docType === DocumentTemplateType.Submittals ||
    docType === DocumentTemplateType.CloseoutSubmittals;

  useEffect(() => {
    if (docType && projectId) {
      getDocumentsWithNoPackage(projectId, docType).then((res) => {
        setDocuments(res);
        if (res.length > 0) {
          if (res[0].submittalDivision) setSelectedDivision(res[0].submittalDivision);
        }
      });
    }
  }, [open, docType, projectId]);

  const addDocToPackage = async (submittal: INumberedDocumentView) => {
    if (!documentPackage?.id) return;
    try {
      await addDocumentToPackage(submittal.id, documentPackage.id);
      const temp = [...documents];
      const index = temp.findIndex((item) => item.id === submittal.id);
      temp.splice(index, 1);
      setDocuments(temp);
      handleAddDocument(submittal);
    } catch (e: any) {
      console.error(e);
    }
  };

  const isEmptyDivision = () =>
    documents.filter((s) => s.submittalDivision === selectedDivision).length === 0;

  return (
    <Dialog open={open} onClose={() => handleClose()} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography className={classes.title}>
          Add {isSubmittal ? 'a Submittal' : 'an As-Built'} to This Package
        </Typography>
        <IconButton
          style={{ right: '14px', top: '14px', position: 'absolute' }}
          onClick={() => handleClose()}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isSubmittal ? (
            <Select
              value={selectedDivision}
              input={<BootstrapInput />}
              onChange={(e) => setSelectedDivision(e.target.value as string)}
              variant="outlined"
              style={{ width: '10%' }}
            >
              {Object.keys(divisionToDisplayString)
                .sort()
                .map((division) => (
                  <MenuItem key={division} value={division}>
                    {division}
                  </MenuItem>
                ))}
            </Select>
          ) : null}
          {isEmptyDivision() ? (
            <Typography variant="body1" color="inherit" style={{ marginLeft: '16px' }}>
              <b>There are no eligible submittals in this division.</b>
            </Typography>
          ) : (
            <TextField
              variant="outlined"
              placeholder="Search..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value as string)}
              InputProps={{ style: { height: 41 } }}
              style={{ marginLeft: '16px' }}
            />
          )}
        </div>
        <List dense>
          {documents
            .filter(
              (s) =>
                (selectedDivision ? s.submittalDivision === selectedDivision : true) &&
                (searchInput
                  ? getDocumentIdentifier(s, docType)
                      .toLowerCase()
                      .includes(searchInput.toLowerCase()) ||
                    s.title?.toLowerCase().includes(searchInput.toLowerCase())
                  : true),
            )
            .map((item, index, array) => {
              return (
                <ListItem key={item.id} className={classes.listItem}>
                  <Typography variant="body1" color="inherit" className={classes.index}>
                    {index + 1}.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="inherit"
                    className={classes.submittalId}
                    style={{}}
                  >
                    <b>{getDocumentIdentifier(item, docType)}</b>
                  </Typography>
                  <Typography variant="body1" color="inherit" style={{ width: '60%' }}>
                    {item.title || 'N/A'}
                  </Typography>
                  <Box className={classes.undoIconContainer} style={{ paddingLeft: 96 }}>
                    <Tooltip title="Add Submittal to Package" arrow placement="right">
                      <IconButton onClick={() => addDocToPackage(item)}>
                        <AddCircleIcon color="inherit" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </ListItem>
              );
            })}
        </List>
      </DialogContent>
    </Dialog>
  );
}
