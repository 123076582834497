import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { HighlightOffRounded } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { IComment } from '../../api-client/autogenerated';
import CircularLoader from '../loader/CircularLoader';
import dayjs from 'dayjs';
import { parseDate } from '../../scripts/utils';

type Props = {
  open: boolean;
  handleClose: () => void;
  comment?: IComment;
};

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px 24px 24px',
  },
  titleUser: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '21px',
    textAlign: 'left',
    whiteSpace: 'pre',
    color: '#464546',
  },
  titleCompany: {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '21px',
    textAlign: 'left',
    color: '#949494',
  },
  titleDate: {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'left',
    color: '#949494',
  },
  rootIconButton: {
    position: 'absolute',
    right: '22px',
    top: '19px',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    paddingBottom: '16px',
  },
});

export default function DocumentCommentDialog(props: Props) {
  const classes = useStyles();
  const { open, handleClose, comment } = props;

  return (
    <Dialog maxWidth="xs" fullWidth open={open}>
      {comment ? (
        <>
          <DialogTitle disableTypography className={classes.titleContainer}>
            <div style={{ display: 'flex' }}>
              <Typography
                className={classes.titleUser}
              >{`${comment.creatorUser?.name}, `}</Typography>
              <Typography className={classes.titleCompany}>
                {comment.creatorUser?.company?.name}
              </Typography>
            </div>
            <Typography className={classes.titleDate}>
              {parseDate(comment.createdOn!).format('M/DD/YYYY @ h:mm A')}
            </Typography>
            <IconButton className={classes.rootIconButton} onClick={handleClose}>
              <HighlightOffRounded />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Typography>{comment.text}</Typography>
          </DialogContent>
        </>
      ) : (
        <CircularLoader />
      )}
    </Dialog>
  );
}
