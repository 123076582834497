import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import CircularLoader from '../../main-components/loader/CircularLoader';
import ProjectListing from './ProjectListing';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { IBidSetup } from '../../api-client/autogenerated';
import { blue600, orange600 } from '../BiddingPortalTheme';

type Props = {
  isLoaded: boolean;
  bidSetups: IBidSetup[];
};

const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      '&>:nth-child(odd)': {
        borderLeft: `2px solid ${orange600}`,
      },
      '&>:nth-child(even)': {
        borderLeft: `2px solid ${blue600}`,
      },
    },
  }),
);

const ProjectsList: React.FC<Props> = (props) => {
  const { isLoaded, bidSetups }: Props = props;
  const classes = useStyles();

  const loading = isLoaded ? null : (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 48 }}>
      <CircularLoader />
    </div>
  );

  return (
    <div className="listview__body">
      <div className={classes.listContainer}>
        {loading}
        {bidSetups.map((proj) => (
          <div key={proj.id} style={{ paddingLeft: 16, paddingRight: 16 }}>
            <ProjectListing bidSetup={proj} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectsList;
