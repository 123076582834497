import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import SeeAll from './SeeAll';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import { DocumentTemplateType } from '../../api-client/autogenerated';
import { documentTypeToUrl } from '../../scripts/utils';
import { ascendingComparator } from '../document-index/DocumentIndexUtils';

export interface DoughnutDataItem {
  id: string;
  section: string;
  name: string;
}

interface DoughnutDataProps {
  icon?: JSX.Element;
  title?: string;
  color?: string;
  data: DoughnutDataItem[];
  numItems: number;
  isReviewCompleteCard?: boolean;
  setFilter?: () => void;
  type?: DocumentTemplateType;
}

const useStyles = makeStyles({
  card: {
    padding: 5,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    width: '100%',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    textTransform: 'capitalize',
  },
  padding: {
    margin: 0,
  },
  date: {
    fontSize: 12,
    color: '#4380ED', // blue
    fontWeight: 'bold',
  },
  description: {
    width: '100%',
    fontSize: 14,
    color: '#464546', // gray
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  list: {
    width: '100%',
    listStyle: 'none',
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: 16,
    padding: 5,
    border: 'none',
    paddingBottom: '0px',
    marginBottom: '0px',
    marginTop: 8,
  },
});

export default function DoughnutData(props: DoughnutDataProps) {
  const classes = useStyles();
  const {
    icon = <></>,
    title = '',
    color,
    data,
    numItems,
    isReviewCompleteCard,
    setFilter,
    type,
  } = props;
  const history = useHistory();
  const project = useSelector(getProjectState);

  const [expanded, setExpanded] = React.useState(false);
  const [count, setCount] = useState(2);

  const handleSeeMoreClick = () => {
    if (count >= numItems) {
      setExpanded(false);
      setCount(2);
    } else {
      setExpanded(true);
      setCount((prevCount) => prevCount + 3);
    }
  };

  const handleSeeAllClick = () => {
    if (!setFilter) {
      if (count >= numItems) {
        setExpanded(false);
        setCount(2);
      } else {
        setExpanded(true);
        setCount(numItems + 1);
      }
    } else setFilter();
  };

  const handleReviewCompleteClick = () => {
    if (setFilter) setFilter();
  };

  const dataItem = data
    .sort((a, b) => ascendingComparator(a, b, 'section'))
    .map((d) => {
      return (
        <li
          key={d.id}
          style={{
            cursor: project && type ? 'pointer' : 'auto',
            whiteSpace: 'nowrap',
            width: '100%',
            display: 'flex',
          }}
          onClick={() => {
            if (project && type)
              history.push(
                `/main/projects/${project.id}/documents/${documentTypeToUrl[type]}/${d.id}`,
              );
          }}
        >
          <span className={classes.date}>{d.section}</span> &nbsp;
          <span className={classes.description}>{d.name}</span>
        </li>
      );
    });

  return (
    <div style={{ height: '100%' }}>
      {isReviewCompleteCard ? (
        <div>
          <Grid item xs={12}>
            <Typography className={classes.title} style={{ color }}>
              {icon}
              {` ${title}`}
            </Typography>
            {numItems === 0 ? (
              <div />
            ) : (
              <ul className={classes.list}>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  {dataItem}
                </Collapse>
              </ul>
            )}
          </Grid>
          {numItems === 0 ? (
            <div />
          ) : (
            <Grid
              item
              container
              justify="flex-end"
              alignItems="center"
              xs={12}
              style={{ alignItems: 'flex-end' }}
            >
              <Button onClick={handleReviewCompleteClick}>
                <SeeAll />
              </Button>
            </Grid>
          )}
        </div>
      ) : (
        <div className={classes.card}>
          <Grid item container direction="column" xs={12}>
            <Typography className={classes.title} style={{ color }}>
              {icon}
              {` ${title}`}
            </Typography>
            {numItems === 0 ? (
              <div style={{ width: '100%' }} className={classes.list}>
                No results.
              </div>
            ) : (
              <ul className={classes.list}>
                {dataItem.slice(0, 2)}
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  {dataItem.slice(2, count)}
                </Collapse>
              </ul>
            )}
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            xs={12}
            style={{ alignItems: 'flex-end' }}
          >
            {numItems <= 3 ? (
              <div />
            ) : (
              <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
                <Button onClick={handleSeeMoreClick}>
                  +{numItems - count >= 0 ? numItems - count : 0} More
                </Button>
                <Button onClick={handleSeeAllClick}>
                  <SeeAll />
                </Button>
              </div>
            )}
          </Grid>
        </div>
      )}
    </div>
  );
}
