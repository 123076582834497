import React from 'react';

type Props = {
  icon: JSX.Element;
  message: string;
};

const Notification: React.FC<Props> = (props) => {
  const { icon, message } = props;
  return (
    <>
      <div style={{ top: '0px' }}>
        <div
          className="t-primary-bgcolor"
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '.75rem 1.25rem',
            fontSize: '18px',
            opacity: '0.5',
            marginRight: '15px',
          }}
        >
          {icon}
          <div style={{ marginLeft: '16px' }}>
            <strong>{message}</strong>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
