import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import DashboardProjects from './DashboardProjects';
import DashboardCountdown from './DashboardCountdown';
import DashboardUpcoming from './DashboardUpcoming';
import DashboardMessageBoards from './DashboardMessageBoards';
import DashboardMessageBoard from './DashboardMessageBoard';
import DashboardChanges from './DashboardChanges';
import DashboardFieldTools from './DashboardFieldTools';
import NavAppbar from '../nav-top/NavAppbar';
import DashboardDoughnut from './DashboardDoughnut';
import { getProjectState } from '../../features/project/selectors';
import { fetchProject, setProject } from '../../features/project/actions';
import { canViewConstructionAndCloseout, ProjectMatchParams } from '../../scripts/utils';
import { getProjectLoadingState } from '../../features/loading/selectors';
import FullscreenLoader from '../loader/FullscreenLoader';
import { isMobile } from 'react-device-detect';
import { setDocumentsType } from '../../features/documents/actions';
import { getProductPackageState } from '../../features/product-package/selectors';
import { DocumentTemplateType } from '../../api-client/autogenerated';
import DashboardBidding from './DashboardBidding';
import DashboardEmails from './DashboardEmails';
import { hasCompletedConstructionSetup } from '../../scripts/store-utils';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh',
    overflowX: 'hidden',
  },
  paper: {
    color: theme.palette.text.secondary,
    height: '100%',
    boxShadow: '5px 5px 15px rgb(0, 0, 0, .15)',
  },
  paperNoFill: {
    color: theme.palette.text.secondary,
    boxShadow: '5px 5px 15px rgb(0, 0, 0, .15)',
  },
  paperDefaultHeight: {
    color: theme.palette.text.secondary,
    height: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

export default function Dashboard() {
  const classes = useStyles();

  const params = useParams<ProjectMatchParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const productPackage = useSelector(getProductPackageState);
  const project = useSelector(getProjectState);
  const projectLoading = useSelector(getProjectLoadingState);

  useEffect(() => {
    if (params.projectId !== project?.id && !projectLoading) {
      dispatch(fetchProject(params.projectId)).then((action: any) => {
        if (!action?.payload) history.push('/main');
      });
    } else if (!params.projectId) dispatch(setProject());

    dispatch(setDocumentsType(null));
  }, [params, projectLoading]);

  if (projectLoading) return <FullscreenLoader />;

  return (
    <main className={classes.content}>
      <NavAppbar />
      <div>
        {project ? (
          <Grid container spacing={2} alignItems="stretch" direction="row">
            <Grid item container xs={12} xl={6}>
              <Grid item xs={12} style={{ height: '100%' }}>
                <div className={classes.column}>
                  <Paper className={classes.paperNoFill} style={{ marginBottom: 16 }}>
                    <DashboardCountdown maxHeight />
                  </Paper>
                  {isMobile &&
                  canViewConstructionAndCloseout(productPackage) &&
                  hasCompletedConstructionSetup() ? (
                    <Paper className={classes.paper} style={{ marginBottom: 16 }}>
                      <DashboardFieldTools />
                    </Paper>
                  ) : null}
                  <Paper className={classes.paper}>
                    <DashboardUpcoming />
                  </Paper>
                </div>
              </Grid>
            </Grid>
            <Grid item lg={6} xs={12} xl={3}>
              <Paper
                className={classes.paperDefaultHeight}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <DashboardMessageBoard />
              </Paper>
            </Grid>
            <Grid item lg={6} xs={12} xl={3}>
              <Paper
                className={classes.paperDefaultHeight}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <DashboardEmails />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
                <DashboardDoughnut type={DocumentTemplateType.Task} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
                <DashboardBidding />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
                {/* Submittal */}
                <DashboardDoughnut type={DocumentTemplateType.Submittals} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
                {/* RFI */}
                <DashboardDoughnut type={DocumentTemplateType.RequestsForInformation} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ height: 'auto' }} className={classes.paper}>
                <DashboardChanges />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} alignItems="stretch" direction="row">
            <Grid item xs={12} style={{ display: 'inline-flex' }}>
              <Paper className={classes.paper} style={{ width: '100%' }}>
                <DashboardProjects />
              </Paper>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Paper className={classes.paper}>
                <DashboardEmails />
              </Paper>
            </Grid>
            <Grid spacing={2} item xs={12} lg={8}>
              <Grid style={{ paddingBottom: 16 }} item xs={12}>
                <Paper className={classes.paper} style={{ height: 550 }}>
                  <DashboardUpcoming />
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper className={classes.paper} style={{ height: 700 }}>
                  <DashboardMessageBoards />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </main>
  );
}
