import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularLoader from '../loader/CircularLoader';

type Props = {
  open: boolean;
  handleClose: () => void;
  submit: (res: boolean) => Promise<void>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: { height: 32, width: '20%' },
  }),
);

export default function SubmittalPlaceholderQuestionDialog(props: Props) {
  const classes = useStyles();
  const { open, handleClose, submit } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (generateForFirstDivisions: boolean) => {
    setIsLoading(true);
    await submit(generateForFirstDivisions);
    setIsLoading(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Typography>
          Would you like to create submittal placeholders for divisions 00 and 01?
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-around' }}>
        {!isLoading ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(true)}
              className={classes.button}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleSubmit(false)}
              className={classes.button}
            >
              No
            </Button>
          </>
        ) : (
          <CircularLoader />
        )}
      </DialogActions>
    </Dialog>
  );
}
