import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

type Props = {
  text: string;
  user?: string;
  company?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    iconText: {
      color: '#000', // black
      paddingLeft: 0,
      fontSize: 14,
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      // direction: 'rtl',
    },
  }),
);

export default function PrimaryIconText(props: Props) {
  const classes = useStyles();
  const { text, user, company } = props;

  return (
    <>
      <span className={classes.iconText}>{text}</span>
      {user && company && (
        <div style={{ display: 'flex' }}>
          <span className={classes.iconText} style={{ fontWeight: 600 }}>
            {user},
          </span>
          <span className={classes.iconText} style={{ whiteSpace: 'pre' }}>{` ${company}`}</span>
        </div>
      )}
    </>
  );
}
