// @ts-nocheck
import React from 'react';
import userIcon from './images/user-icon.png';
import userIconWhite from './images/user-icon-white.png';
import subscriptionIcon from './images/paper-icon.webp';
import subscriptionIconWhite from './images/paper-icon-white.webp';
import packageIcon from './images/package-icon.png';
import packageIconWhite from './images/package-icon-white.png';
import announcementIcon from './images/megaphone-icon.webp';
import announcementIconWhite from './images/megaphone-icon-white.webp';
import homeIcon from './images/home-icon.svg';
import homeIconWhite from './images/home-icon-white.png';
import rightArrowIcon from './images/forward-icon.webp';
import SidebarItem from './components/sidebar-item';
import '../css/admin.css';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    const { isActive } = this.props;
    this.state = { isActive };
    this.mainApp = props.mainApp;
    this.items = [];
    this.mainApp.updateFunction = () => {
      this.items.forEach((x) => x.updateActive());
    };
    this.closeSidebar = () => {
      this.setState({ isActive: false });
    };
  }

  setContent(name) {
    this.mainApp.setContent(name);
  }

  render() {
    let classes;
    const { isActive } = this.state;
    if (isActive) {
      classes = 'sidebar sidebar-active';
    } else {
      classes = 'sidebar';
    }

    return (
      <>
        <div className={classes}>
          <div className="sidebar-top-margin" />
          <SidebarItem
            clickFunction={() => this.setContent('home')}
            name="Home"
            iconInactive={homeIconWhite}
            iconActive={homeIcon}
            sidebar={this}
          />
          <SidebarItem
            clickFunction={() => this.setContent('application-admins')}
            name="Administrators"
            iconInactive={userIconWhite}
            iconActive={userIcon}
            sidebar={this}
          />
          <SidebarItem
            clickFunction={() => this.setContent('subscriptions')}
            name="Subscriptions"
            iconInactive={subscriptionIconWhite}
            iconActive={subscriptionIcon}
            sidebar={this}
          />
          <SidebarItem
            clickFunction={() => this.setContent('packages')}
            name="Packages"
            iconInactive={packageIconWhite}
            iconActive={packageIcon}
            sidebar={this}
          />
          <SidebarItem
            clickFunction={() => this.setContent('announcements')}
            name="Announcements"
            iconInactive={announcementIconWhite}
            iconActive={announcementIcon}
            sidebar={this}
          />
          <div
            className="sidebar-arrow-active"
            role="button"
            tabIndex={0}
            onKeyDown={() => this.setState({ isActive: !isActive })}
            onClick={() => this.setState({ isActive: !isActive })}
          >
            <img
              alt="Right arrow"
              src={rightArrowIcon}
              className="sidebar-arrow-icon"
              style={{ transition: '0.4s', transform: isActive ? 'scaleX(-1)' : 'none' }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Sidebar;
