import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  Add,
  AttachMoney,
  Close,
  Delete,
  Edit,
  EditOutlined,
  Info,
  Person,
  Print,
  Publish,
  Save,
  Search,
} from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import { Hidden, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import {
  ProcoreButton,
  SmallButton,
  SubmitButton,
  TooltipIfDisabledComponent,
} from '../custom-components/CustomButtons';
import CSVIcon from '../icons/CSV-icon';
import DocumentLogFilterDialog, {
  DocumentLogFilters,
  setDocumentLogFilters,
} from '../dialogs/DocumentLogFilterDialog';
import EditDocumentsDialog from '../dialogs/EditDocumentsDialog';
import AddPackageDialog from '../dialogs/AddPackageDialog';
import {
  DocumentTemplateType,
  INumberedDocumentView,
  IProjectView,
  SecurityPermissionLevel,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import AddDocumentDialog from '../dialogs/AddDocumentDialog';
import { addSpacing, getCsvRowsFromDocumentIndexRows, IPackage } from './DocumentIndexUtils';
import {
  getDocumentsState,
  getDocumentsType,
  getSelectedDivision,
  getSelectedSection,
} from '../../features/documents/selectors';
import {
  completeReviewForPackageByDocumentId,
  deleteDocumentsByIds,
  modifyDocumentsByBatch,
  sendDocumentPublicLinkEmails,
} from '../../models/api/documents';
import ManagePermissionsDialog, {
  ManagePermissionsDialogType,
} from '../design/ManagePermissionsDialog';
import { getCurrentSecurityGroup } from '../../features/security/selectors';
import { getUserState } from '../../features/user/selectors';
import { getTemplateId } from '../../models/api/templates';
import { reloadDocuments } from '../../features/documents/actions';
import {
  beginSyncPunchLists,
  beginSyncRfis,
  beginSyncSubmittalsAndPackages,
} from '../../models/api/procore';
import { addSnackbar } from '../../features/snackbar/actions';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube } from '@fortawesome/free-solid-svg-icons/faCube';
import { divisionToDisplayString } from '../document-display/DocumentDisplay';
import EditResponsiblePartyDialog from '../punch-lists/EditResponsiblePartyDialog';
import { downloadFileById, getUserFriendlyDocumentTemplateNamePlural } from '../../scripts/utils';
import EditPunchListStatusDialog from '../punch-lists/EditPunchListStatusDialog';
import ViewByFloorplanDialog from '../punch-lists/ViewByFloorplanDialog';
import {
  getDisableProcoreIntegrationFeatures,
  getPhysicalBuildingsState,
  getPhysicalFloorsState,
  getPhysicalLocationsState,
} from '../../features/project/selectors';
import { faCopy } from '@fortawesome/free-regular-svg-icons/faCopy';
import { getDocumentPublicLink } from '../../models/api/users';
import { getDocPermission } from '../../scripts/store-utils';
import EditAsBuiltStatusDialog from '../dialogs/EditAsBuiltStatusDialog';
import EmailUsersDialog from '../design/EmailUsersDialog';
import { emitter, Events } from '../../scripts/events';
import CircularLoader from '../loader/CircularLoader';
import RefusePackageDialog, {
  SubmittalInfo,
} from '../submittal-action-center/packages/RefusePackageDialog';
import CreatePunchListDialog from '../punch-lists/CreatePunchListDialog';
import CsvDownloader from 'react-csv-downloader';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      padding: '12px 0px',
      [theme.breakpoints.down('xs')]: {
        padding: '0px 0px 16px',
      },
    },
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginTop: '8px',
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'wrap',
        rowGap: '8px',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'baseline',
      },
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '30px',
      color: '#464546',
      textTransform: 'none',
      marginRight: 24,
    },
    button: {
      paddingLeft: '16px',
      paddingRight: '16px',
      marginRight: '12px',
      [theme.breakpoints.down('xs')]: {
        padding: '6px 16px',
        margin: '5px 0px',
      },
    },
    filterButton: {
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        margin: '5px 0px',
        lineHeight: '32px',
      },
    },
    icon: {
      fill: '#B2B1B2',
    },
    formControl: {
      width: '216px',
      marginRight: '8px',
      [theme.breakpoints.down('xs')]: {
        flexGrow: 1,
      },
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        marginTop: 12,
        width: '100%',
        display: 'flex',
      },
    },
    packageIcon: {
      marginLeft: 8,
    },
    deleteButton: {
      backgroundColor: '#ED3F26',
      '&:hover': {
        backgroundColor: '#FF5D45',
      },
      '&:focus': {
        backgroundColor: '#FF5D45',
      },
    },
  }),
);

export interface PunchListCsvRow {
  building: string;
  floor: string;
  location: string;
  description: string;
  subcontractor: string;
  status: string;
  cost: string;
  postedDate: string;
}

const punchListCsvHeaders = [
  { displayName: 'Building', id: 'building' },
  { displayName: 'Floor', id: 'floor' },
  { displayName: 'Room', id: 'location' },
  { displayName: 'Description', id: 'description' },
  { displayName: 'Responsible Subcontractor', id: 'subcontractor' },
  { displayName: 'Status', id: 'status' },
  { displayName: 'Cost', id: 'cost' },
  { displayName: 'Posted', id: 'postedDate' },
  { displayName: 'Public Document Link', id: 'publicDocumentLink' },
];

interface DocumentIndexToolbarProps {
  selected: string[];
  selectedProject: IProjectView;
  updateSearch: (input: string) => void;
  filteredRows: any[];
  packages: IPackage[];
  filters: DocumentLogFilters;
  setFilters: setDocumentLogFilters;
  updateRows: () => void;
  addToCloseout: () => void;
  removeFromCloseout: () => void;
  componentReference: any;
  selectedDocument: INumberedDocumentView | undefined;
  canChangeAnticipatedSubmission?: boolean;
  uploadWorkflowOpen?: boolean;
  setUploadWorkflowOpen?: React.Dispatch<SetStateAction<boolean>>;
  disableCreatePackage?: boolean;
  isEditingCost: boolean;
  setIsEditingCost: React.Dispatch<SetStateAction<boolean>>;
  submitCosts: () => Promise<void>;
  visible?: boolean;
  setVisible?: Function;
  setIndexDrawerOpen?: (input: boolean) => void;
}

const docTypesNoFilter = [
  DocumentTemplateType.BidDrawings,
  DocumentTemplateType.ProjectManual,
  DocumentTemplateType.Addenda,
  DocumentTemplateType.InformationalItems,
  DocumentTemplateType.BidTabulation,
  DocumentTemplateType.RegulatoryApprovals,
  DocumentTemplateType.AsiDocuments,
  DocumentTemplateType.ChangeOrders,
  DocumentTemplateType.ConstructionChangeDirectives,
  DocumentTemplateType.MiscellaneousDocuments,
  DocumentTemplateType.Schedules,
  DocumentTemplateType.Testing,
  DocumentTemplateType.CertificateOfSubstantialCompletion,
  DocumentTemplateType.ClearLp,
  // TODO: Remove below line when "Source Document" filter is re-enabled
  DocumentTemplateType.Specifications,
];

const docTypesProcoreFilter = [
  DocumentTemplateType.ContractorDailyLogs,
  DocumentTemplateType.MeetingMinutes,
];

const docTypesNoCreateOrEdit = [
  DocumentTemplateType.BidDrawings,
  DocumentTemplateType.ProjectManual,
  DocumentTemplateType.BidTabulation,
  DocumentTemplateType.PlanholderList,
  DocumentTemplateType.SubstitutionRequests,
  DocumentTemplateType.BidderRfIs,
  DocumentTemplateType.BidDrawings,
  DocumentTemplateType.Addenda,
  DocumentTemplateType.InformationalItems,
  DocumentTemplateType.Specifications,
  DocumentTemplateType.Drawings,
];

const docTypesUploadWorkflow = [
  DocumentTemplateType.Submittals,
  DocumentTemplateType.CloseoutSubmittals,
  DocumentTemplateType.AsBuilt,
];

export default function DocumentIndexToolbar(props: DocumentIndexToolbarProps) {
  const classes = useToolbarStyles();
  const {
    selected,
    selectedProject,
    updateSearch,
    filteredRows,
    packages,
    filters,
    setFilters,
    updateRows,
    addToCloseout,
    removeFromCloseout,
    componentReference,
    selectedDocument,
    canChangeAnticipatedSubmission = false,
    uploadWorkflowOpen,
    setUploadWorkflowOpen,
    disableCreatePackage,
    isEditingCost,
    setIsEditingCost,
    submitCosts,
    visible,
    setIndexDrawerOpen,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const documents = useSelector(getDocumentsState);
  const selectedDivision = useSelector(getSelectedDivision);
  const selectedSection = useSelector(getSelectedSection);
  const documentType = useSelector(getDocumentsType);
  const disableProcoreIntegrationFeatures = useSelector(getDisableProcoreIntegrationFeatures);
  const security = useSelector(getCurrentSecurityGroup);
  const locations = useSelector(getPhysicalLocationsState);
  const floors = useSelector(getPhysicalFloorsState);
  const buildings = useSelector(getPhysicalBuildingsState);
  const permission = getDocPermission() || SecurityPermissionLevel.NUMBER_0;

  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [addEditPermission, setAddEditPermission] = useState(false);
  const [createPackagePermission, setCreatePackagePermission] = useState(false);
  const [createPlaceholderPackagePermission, setCreatePlaceholderPackagePermission] = useState(
    false,
  );
  const [deletePermission, setDeletePermission] = useState(false);

  const [addPackageDialogOpen, setAddPackageDialogOpen] = useState(false);
  const [addPlaceholderPackageDialogOpen, setAddPlaceholderPackageDialogOpen] = useState(false);
  const [associateDialogOpen, setAssociateDialogOpen] = useState(false);
  const [associatedUsers, setAssociatedUsers] = useState<string[]>([]);
  const [associatedGroups, setAssociatedGroups] = useState<string[]>([]);
  const [inputSearch, setInputSearch] = useState<string>();

  const filterAnchor = useRef<any>(null);
  const editAnchor = useRef<any>(null);
  const associateAnchor = useRef<any>(null);
  const packageAnchor = useRef<any>(null);
  const placeholderPackageAnchor = useRef<any>(null);
  const addAnchor = useRef<any>(null);
  const responsiblePartyAnchor = useRef<HTMLButtonElement>(null);
  const punchListStatusAnchor = useRef<HTMLButtonElement>(null);
  const editCostAnchor = useRef<HTMLButtonElement>(null);
  const sendALinkAnchor = useRef<HTMLButtonElement>(null);

  const [numFilters, setNumFilters] = useState(0);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  const [isSyncingWithProcore, setIsSyncingWithProcore] = useState(false);
  const [editResponsiblePartyDialogOpen, setEditResponsiblePartyDialogOpen] = useState(false);
  const [editPunchListStatusDialogOpen, setEditPunchListStatusDialogOpen] = useState(false);
  const [sendALinkDialogOpen, setSendALinkDialogOpen] = useState(false);
  const [editAsBuiltStatusDialogOpen, setEditAsBuiltStatusDialogOpen] = useState(false);
  const [createPunchListDialogOpen, setCreatePunchListDialogOpen] = useState(false);

  const [floorplanDialogOpen, setFloorplanDialogOpen] = useState(false);

  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPublishingPunchListItems, setIsPublishingPunchListItems] = useState(false);

  const [csvLoading, setCsvLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery('(min-width:600px)');

  const selectedDocuments = selected
    .map((documentId) => documents.find((doc) => doc.id === documentId))
    .filter((x) => !!x) as INumberedDocumentView[];

  const disableCreatePunchListItems =
    documentType === DocumentTemplateType.PunchList && locations.length === 0;

  const isAsBuiltPackage =
    documentType === DocumentTemplateType.AsBuilt &&
    !!selectedDocument?.submittalChildDocuments?.length;

  useEffect(() => {
    if (!user.isSiteAdmin && user.adminOfSubscriberId !== selectedProject?.subscriberId) {
      if (documentType)
        getTemplateId(documentType).then((templateId) => {
          const permissionLevel = security?.securityGroupDocumentTemplateList?.find(
            (group) => group.documentTemplateId === templateId,
          )?.securityPermissionLevel;
          if (permissionLevel && permissionLevel >= 2) {
            setAddEditPermission(true);
            setCreatePackagePermission(true);
            if (permissionLevel >= 3) {
              setCreatePlaceholderPackagePermission(true);
            } else {
              setCreatePlaceholderPackagePermission(false);
            }
          } else {
            getTemplateId(DocumentTemplateType.AdditionalReview).then((templateId) => {
              const permissionLevel = security?.securityGroupDocumentTemplateList?.find(
                (group) => group.documentTemplateId === templateId,
              )?.securityPermissionLevel;
              if (permissionLevel && permissionLevel === 3) setCreatePackagePermission(true);
            });
            setAddEditPermission(false);
          }

          if (permissionLevel && permissionLevel === 4) {
            setDeletePermission(true);
          }
        });
    } else {
      setAddEditPermission(true);
      setCreatePackagePermission(true);
      setCreatePlaceholderPackagePermission(true);
      setDeletePermission(true);
    }
  }, [documentType, security, user, selectedProject]);

  const getDrawerText = () => {
    if (!documentType) return 'Open Drawer';
    return `Open ${
      documentType === DocumentTemplateType.Submittals ? 'Submittals' : 'Specifications'
    } Index`;
  };

  const getFilterText = () => {
    if (numFilters === 0)
      return (
        <>
          <Add style={{ paddingRight: isDesktop ? 2 : 0 }} />
          FILTER
        </>
      );
    if (numFilters === 1) return '1 FILTER SELECTED';
    return numFilters.toString().concat(' FILTERS SELECTED');
  };

  const sendSearchUpdate = (newValue: string) => {
    setSearchTimeout(undefined);
    updateSearch(newValue || '');
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.preventDefault();
    const newValue = e.target.value;
    setInputSearch(newValue);
    if (!searchTimeout) {
      setSearchTimeout(setTimeout(() => sendSearchUpdate(newValue), 600));
    } else {
      clearTimeout(searchTimeout);
      setSearchTimeout(setTimeout(() => sendSearchUpdate(newValue), 600));
    }
  };

  const getTitle = () => {
    if (
      documentType === DocumentTemplateType.Submittals ||
      documentType === DocumentTemplateType.CloseoutSubmittals
    ) {
      if (selectedSection) {
        return `${addSpacing(selectedSection.number)} ${selectedSection.title}`;
      }
      if (selectedDivision) {
        return `Division ${selectedDivision} - ${divisionToDisplayString[selectedDivision]}`;
      }
      return 'All Submittals';
    }
    return '';
  };

  const getCreateDisabled = () => {
    return (
      !addEditPermission ||
      (disableProcoreIntegrationFeatures &&
        (documentType === DocumentTemplateType.Submittals ||
          documentType === DocumentTemplateType.RequestsForInformation)) ||
      disableCreatePunchListItems
    );
  };

  const getDisabledTooltipTitle = () => {
    if (
      disableProcoreIntegrationFeatures &&
      (documentType === DocumentTemplateType.Submittals ||
        documentType === DocumentTemplateType.RequestsForInformation)
    )
      return 'This is disabled for projects synced with Procore';
    if (disableCreatePunchListItems)
      return 'Complete the setup process before Punch List items can created.';
    return '';
  };

  const getCreatePackageDisabled = () => {
    if (
      selected.length <= 0 ||
      (disableProcoreIntegrationFeatures && documentType === DocumentTemplateType.Submittals)
    ) {
      return true;
    }
    if (selected.length > 1 && disableCreatePackage) {
      return true;
    }
    if (
      documentType === DocumentTemplateType.AsBuilt &&
      selected.length === 1 &&
      selectedDocument
    ) {
      return (
        selectedDocument.workflowStatus !== WorkflowStatusType.Initial ||
        !!selectedDocument.submittalPackageDocumentId
      );
    }
    return selected.length > 1 && !createPackagePermission;
  };

  const getCreatePlaceholderPackageDisabled = () => {
    if (
      selected.length <= 0 ||
      disableCreatePackage ||
      (disableProcoreIntegrationFeatures && documentType === DocumentTemplateType.Submittals)
    )
      return true;

    if (
      documentType === DocumentTemplateType.AsBuilt &&
      selected.length === 1 &&
      selectedDocument
    ) {
      return selectedDocument.workflowStatus !== WorkflowStatusType.Initial;
    }

    return !createPlaceholderPackagePermission || selected.length < 2;
  };

  const getEditAsBuiltStatusDisabled = () => {
    return (
      selected.length !== 1 ||
      permission < SecurityPermissionLevel.NUMBER_3 ||
      !selectedDocument?.workflowStatus ||
      ![WorkflowStatusType.SubmittedForReview, WorkflowStatusType.UnderReview].includes(
        selectedDocument.workflowStatus,
      ) ||
      !!selectedDocument.submittalPackageDocumentId
    );
  };

  const handleSendEmails = async (emails: string[]) => {
    await Promise.all(
      selected.map((documentId) => sendDocumentPublicLinkEmails(documentId, emails)),
    );
  };

  const handleGetLink = async () => {
    if (selected.length !== 1) return '';
    return getDocumentPublicLink(selected[0]);
  };

  const handleDownloadFiles = async () => {
    setIsDownloading(true);
    try {
      const rowsToDownload =
        selected.length > 0 ? filteredRows.filter((r) => selected.includes(r.id)) : filteredRows;
      for (let i = 0; i < rowsToDownload.length; i += 1) {
        const row = rowsToDownload[i];
        const filename =
          documentType === DocumentTemplateType.Drawings
            ? `${row.page.toLocaleString(undefined, { minimumIntegerDigits: 3 })}_${row.number}${
                row.title && row.title !== 'N/A' ? `_${row.title}` : ''
              }.pdf`
            : row.viewButton.name;
        await downloadFileById(row.viewButton.id, filename);
      }
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDeleteDocuments = async () => {
    const shouldDelete = window.confirm(
      `Are you sure you want to delete ${
        selected.length === 1 ? 'this document' : 'these documents'
      }?`,
    );
    if (shouldDelete)
      try {
        setIsDeleting(true);
        await deleteDocumentsByIds(selected);
        dispatch(reloadDocuments());
      } catch (e: any) {
        console.error(e);
      } finally {
        setIsDeleting(false);
      }
  };

  const handlePublishPunchListItems = async () => {
    setIsPublishingPunchListItems(true);
    try {
      const selectedDraftDocumentIds = selectedDocuments.filter((d) => d.isDraft).map((d) => d.id);
      await modifyDocumentsByBatch(selectedDraftDocumentIds, { patch: { isDraft: false } });
      dispatch(reloadDocuments());
    } finally {
      setIsPublishingPunchListItems(false);
    }
  };

  const getToolbarButton = () => {
    const buttons: (JSX.Element | null)[] = [];
    const shouldCreatePlaceholder = permission && permission >= SecurityPermissionLevel.NUMBER_3;

    if (documentType === DocumentTemplateType.AsBuilt) {
      if (uploadWorkflowOpen) {
        buttons.push(
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Close />}
            onClick={() => {
              if (setUploadWorkflowOpen) setUploadWorkflowOpen(false);
            }}
            style={{ height: 32, paddingLeft: 24, paddingRight: 24, marginRight: 4 }}
          >
            Cancel
          </Button>,
        );
      } else {
        buttons.push(
          <>
            {!shouldCreatePlaceholder ? (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<Add />}
                disabled={documents.length === 0}
                onClick={() => {
                  if (setUploadWorkflowOpen) setUploadWorkflowOpen(true);
                }}
                style={{ height: 32, paddingLeft: 24, paddingRight: 24, marginRight: 4 }}
              >
                Upload As-Builts
              </Button>
            ) : (
              <Button
                ref={addAnchor}
                variant="contained"
                color="secondary"
                startIcon={<Add />}
                onClick={() => setAddDialogOpen(true)}
                style={{ height: 32, paddingLeft: 24, paddingRight: 24, marginRight: 4 }}
              >
                Create Placeholder
              </Button>
            )}
          </>,
        );
      }
    }

    if (docTypesUploadWorkflow.includes(documentType as DocumentTemplateType)) {
      if (uploadWorkflowOpen) {
        buttons.push(
          <TooltipIfDisabledComponent
            title={getDisabledTooltipTitle()}
            disabled={disableProcoreIntegrationFeatures}
          >
            <Button
              ref={packageAnchor}
              variant="contained"
              color="primary"
              disabled={getCreatePackageDisabled()}
              onClick={() => {
                if (selected.length === 1) {
                  if (
                    documentType === DocumentTemplateType.Submittals ||
                    documentType === DocumentTemplateType.CloseoutSubmittals
                  ) {
                    history.push(
                      `${
                        documentType === DocumentTemplateType.Submittals
                          ? 'submittals'
                          : 'closeout-submittals'
                      }/${selected[0]}`,
                    );
                  } else setEditDialogOpen(true);
                } else {
                  setAddPackageDialogOpen(true);
                }
              }}
            >
              {selected.length > 1 ? (
                <>
                  <FontAwesomeIcon icon={faCube} style={{ marginRight: '6px' }} />
                  Create Package
                </>
              ) : (
                'Continue'
              )}
            </Button>
          </TooltipIfDisabledComponent>,
        );
      } else {
        buttons.push(
          documentType === DocumentTemplateType.AsBuilt ? (
            <Button
              variant="contained"
              color="primary"
              disabled={getEditAsBuiltStatusDisabled()}
              ref={editAnchor}
              onClick={() => setEditAsBuiltStatusDialogOpen(true)}
              startIcon={<EditOutlined />}
              style={{ marginLeft: '4px', marginRight: '4px' }}
            >
              Review As-Built
            </Button>
          ) : (
            <SmallButton
              variant="contained"
              color="primary"
              disabled={!addEditPermission || selected.length === 0}
              ref={editAnchor}
              style={isDesktop ? { marginLeft: '4px', marginRight: '4px' } : { margin: '5px 0px' }}
              onClick={() => setEditDialogOpen(true)}
            >
              <EditOutlined style={{ width: 20, height: 20, paddingRight: 2 }} />
              Edit
            </SmallButton>
          ),
        );
      }

      if (createPlaceholderPackagePermission) {
        buttons.push(
          <Button
            variant="contained"
            color="primary"
            disabled={getCreatePlaceholderPackageDisabled()}
            ref={placeholderPackageAnchor}
            startIcon={<FontAwesomeIcon icon={faCube} style={{ marginRight: '6px' }} />}
            onClick={() => setAddPlaceholderPackageDialogOpen(true)}
            style={{ marginLeft: 8 }}
          >
            Create Placeholder Package
          </Button>,
        );
      }

      if (deletePermission) {
        buttons.push(
          !isDeleting ? (
            <SmallButton
              variant="contained"
              color="primary"
              disabled={selected.length === 0}
              className={classes.deleteButton}
              style={
                isDesktop
                  ? { marginLeft: 8, marginRight: 16, paddingLeft: 12, paddingRight: 12 }
                  : { margin: '5px 0px' }
              }
              onClick={handleDeleteDocuments}
            >
              <Delete style={{ width: 20, height: 20, paddingRight: 2 }} />
              Delete
            </SmallButton>
          ) : (
            <CircularLoader size={30} style={{ marginLeft: 32, marginRight: 32 }} />
          ),
        );
      }
    } else if (!docTypesNoCreateOrEdit.includes(documentType as DocumentTemplateType)) {
      buttons.push(
        selected.length > 0 ? (
          <>
            {documentType !== DocumentTemplateType.PunchList && (
              <SmallButton
                variant="contained"
                color="primary"
                disabled={
                  !addEditPermission ||
                  (documentType === DocumentTemplateType.FieldReports && selected.length !== 1)
                }
                ref={editAnchor}
                style={
                  isDesktop ? { marginLeft: '4px', marginRight: '12px' } : { margin: '5px 0px' }
                }
                onClick={() =>
                  documentType !== DocumentTemplateType.FieldReports ||
                  !selectedDocument?.dateOfObservation
                    ? setEditDialogOpen(true)
                    : history.push(`field-reports/${selected[0]}/edit`)
                }
              >
                <EditOutlined style={{ width: 20, height: 20, paddingRight: 2 }} />
                Edit
              </SmallButton>
            )}
          </>
        ) : (
          <span>
            <Hidden xsDown>
              <TooltipIfDisabledComponent
                disabled={disableProcoreIntegrationFeatures || disableCreatePunchListItems}
                title={getDisabledTooltipTitle()}
              >
                <Button
                  variant="contained"
                  color="primary"
                  ref={addAnchor}
                  disabled={getCreateDisabled()}
                  className={classes.button}
                  onClick={() => {
                    if (documentType === DocumentTemplateType.PunchList) {
                      setCreatePunchListDialogOpen(true);
                    } else {
                      setAddDialogOpen(true);
                    }
                  }}
                  style={{ marginBottom: isMobile ? 6 : 0 }}
                >
                  <Add style={{ paddingRight: 2 }} />
                  CREATE
                </Button>
              </TooltipIfDisabledComponent>
            </Hidden>
            <Hidden smUp>
              <Button
                variant="contained"
                color="primary"
                ref={addAnchor}
                disabled={getCreateDisabled()}
                className={classes.button}
                onClick={() => {
                  if (documentType === DocumentTemplateType.PunchList) {
                    setCreatePunchListDialogOpen(true);
                  } else {
                    setAddDialogOpen(true);
                  }
                }}
              >
                CREATE
              </Button>
            </Hidden>
          </span>
        ),
        <span>
          <>
            {documentType === DocumentTemplateType.PunchList && (
              <>
                {!isEditingCost ? (
                  <Button
                    ref={editCostAnchor}
                    variant="contained"
                    startIcon={<AttachMoney />}
                    color="primary"
                    disabled={permission < SecurityPermissionLevel.NUMBER_3}
                    onClick={() => setIsEditingCost(true)}
                    className={classes.button}
                    style={{ margin: isMobile ? ' 5px 5px 12px 0px' : '0px 12px 0px 0px' }}
                  >
                    Edit Cost
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => setIsEditingCost(false)}
                      className={classes.button}
                      style={{ backgroundColor: '#ED3F26', color: '#FFF', height: 32 }}
                    >
                      Cancel
                    </Button>
                    <SubmitButton
                      variant="contained"
                      startIcon={<Save />}
                      onClick={submitCosts}
                      className={classes.button}
                      style={{ marginLeft: 4 }}
                    >
                      Save Changes
                    </SubmitButton>
                  </>
                )}
                {!isEditingCost && (
                  <>
                    <Button
                      ref={punchListStatusAnchor}
                      disabled={
                        selected.length === 0 ||
                        isEditingCost ||
                        permission < SecurityPermissionLevel.NUMBER_2
                      }
                      variant="contained"
                      color="primary"
                      startIcon={<Edit />}
                      className={classes.button}
                      onClick={() => setEditPunchListStatusDialogOpen(true)}
                      style={{ margin: isMobile ? ' 5px 5px 12px 0px' : '0px 12px 0px 0px' }}
                    >
                      EDIT STATUS
                    </Button>

                    <Button
                      ref={responsiblePartyAnchor}
                      disabled={
                        selected.length === 0 ||
                        isEditingCost ||
                        permission < SecurityPermissionLevel.NUMBER_2
                      }
                      variant="contained"
                      startIcon={<Person />}
                      color="primary"
                      className={classes.button}
                      onClick={() => setEditResponsiblePartyDialogOpen(true)}
                      style={{ margin: isMobile ? '5px 5px 12px 0px' : '0px 12px 0px 0px' }}
                    >
                      Set Responsible Subcontractor
                    </Button>

                    <Button
                      variant="contained"
                      startIcon={
                        isPublishingPunchListItems ? (
                          <CircularLoader size={18} loaderStyle={{ color: '#A3A3A3' }} />
                        ) : (
                          <Publish />
                        )
                      }
                      color="primary"
                      className={classes.button}
                      disabled={
                        selected.length === 0 ||
                        selectedDocuments.some((d) => !d.isDraft) ||
                        isPublishingPunchListItems
                      }
                      onClick={handlePublishPunchListItems}
                    >
                      Publish
                    </Button>

                    <Button
                      ref={sendALinkAnchor}
                      disabled={selected.length !== 1 || isEditingCost}
                      variant="contained"
                      startIcon={
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ width: 18, height: 18, color: '#FFF' }}
                        />
                      }
                      color="primary"
                      className={classes.button}
                      onClick={() => setSendALinkDialogOpen(true)}
                    >
                      Send A Link
                    </Button>

                    {/*<Button*/}
                    {/*  disabled={documents.filter((d) => !d.isDraft).length === 0}*/}
                    {/*  variant="contained"*/}
                    {/*  startIcon={<Share />}*/}
                    {/*  color="primary"*/}
                    {/*  className={classes.button}*/}
                    {/*  onClick={() => history.push('punch-list/print')}*/}
                    {/*  style={{ margin: isMobile ? ' 5px 5px 12px 0px' : 0 }}*/}
                    {/*>*/}
                    {/*  Export All*/}
                    {/*</Button>*/}
                  </>
                )}
              </>
            )}
          </>
        </span>,
      );
    } else if (
      (documentType === DocumentTemplateType.Drawings ||
        documentType === DocumentTemplateType.Specifications) &&
      deletePermission
    ) {
      buttons.push(
        !isDeleting ? (
          <SmallButton
            variant="contained"
            color="primary"
            disabled={selected.length === 0}
            className={classes.deleteButton}
            style={isDesktop ? { marginRight: '16px' } : { margin: '5px 0px' }}
            onClick={handleDeleteDocuments}
          >
            <Delete style={{ width: 20, height: 20, paddingRight: 2 }} />
            Delete
          </SmallButton>
        ) : (
          <CircularLoader size={30} style={{ marginLeft: 32, marginRight: 32 }} />
        ),
      );
    }

    if (
      documentType === DocumentTemplateType.Drawings ||
      documentType === DocumentTemplateType.Specifications
    ) {
      buttons.push(
        <>
          {!isDownloading ? (
            <Button
              color="primary"
              variant="contained"
              disabled={filteredRows.length === 0}
              onClick={handleDownloadFiles}
              style={{ marginRight: 12 }}
            >
              Download{' '}
              {selected.length > 0 && selected.length !== filteredRows.length ? 'Selected' : 'All'}
            </Button>
          ) : (
            <CircularLoader size={30} style={{ marginLeft: 32, marginRight: 40 }} />
          )}
        </>,
      );
    }

    if (documentType === DocumentTemplateType.Drawings) {
      buttons.push(
        <>
          <Button
            variant="contained"
            color="primary"
            ref={associateAnchor}
            disabled={selected.length === 0 || !addEditPermission}
            style={
              isDesktop
                ? {
                    marginLeft: '4px',
                    marginRight: '4px',
                    height: '32px',
                    padding: '6px 10px',
                  }
                : { margin: '5px 0px' }
            }
            onClick={() => setAssociateDialogOpen(true)}
          >
            <EditOutlined style={{ width: 20, height: 20, paddingRight: 2 }} />
            Associate Users with Drawings
          </Button>
          <Tooltip
            arrow
            placement="top"
            title="Use this button to associate design team members with their respective drawings."
            style={{ marginLeft: 4 }}
          >
            <Info />
          </Tooltip>
        </>,
      );
    }

    if (documentType === DocumentTemplateType.Specifications && (!isMobile || !visible)) {
      buttons.push(
        <>
          <Button
            variant="contained"
            color="primary"
            ref={associateAnchor}
            disabled={selected.length === 0 || !addEditPermission}
            style={{
              marginLeft: '4px',
              marginRight: '4px',
              height: '32px',
              padding: '6px 10px',
            }}
            onClick={() => setAssociateDialogOpen(true)}
          >
            <EditOutlined style={{ width: 20, height: 20, paddingRight: 2 }} />
            Associate Users with Specifications
          </Button>
          <Tooltip
            arrow
            placement="top"
            title="Use this button to associate design team members with their respective specifications."
            style={{ marginLeft: 4 }}
          >
            <Info />
          </Tooltip>
        </>,
      );
    }

    return buttons;
  };

  // export all rows to csv if none are selected, otherwise only export the ones that are selected
  const getCsvData = async () => {
    setCsvLoading(true);
    if (!documentType) return [];

    const targetRows =
      selected.length === 0
        ? filteredRows
        : filteredRows.filter((row) => selected.includes(row.id));

    const rows = await getCsvRowsFromDocumentIndexRows(
      targetRows,
      documentType,
      packages,
      buildings,
      floors,
      locations,
    );

    setCsvLoading(false);
    return rows;
  };

  const getCsvFilename = () => {
    return `${selectedProject!.name} - ${getUserFriendlyDocumentTemplateNamePlural(
      documentType!,
    )}.csv`;
  };

  const handlePrint = useReactToPrint({
    content: () => componentReference.current,
    onBeforeGetContent: async () => {
      emitter.emit(Events.ExpandRows);
    },
    onAfterPrint: async () => {
      emitter.emit(Events.CloseRows);
    },
  });

  const showDrawer = () => {
    if (!documentType) return false;
    if (
      documentType === DocumentTemplateType.Specifications ||
      documentType === DocumentTemplateType.Submittals ||
      documentType === DocumentTemplateType.CloseoutSubmittals
    )
      return true;
    return false;
  };

  const showFilter = () => {
    if (!documentType) return false;
    if (docTypesNoFilter.includes(documentType)) return false;
    return !(
      !selectedProject.isProcoreIntegrationEnabled && docTypesProcoreFilter.includes(documentType)
    );
  };

  const handleSyncProcoreDocument = async () => {
    setIsSyncingWithProcore(true);
    switch (documentType) {
      case DocumentTemplateType.Submittals:
        beginSyncSubmittalsAndPackages(selectedProject.id);
        break;
      case DocumentTemplateType.RequestsForInformation:
        beginSyncRfis(selectedProject.id);
        break;
      case DocumentTemplateType.PunchList:
        beginSyncPunchLists(selectedProject.id);
        break;
    }
    setIsSyncingWithProcore(false);
    dispatch(reloadDocuments());
  };

  const handleSubmitAssociations = async (
    userIds: string[],
    associatedGroupIds: string[],
    associatedDocumentIds: string[],
  ) => {
    const usersToAdd = selectedDocument
      ? userIds.filter((id) => !associatedUsers.includes(id))
      : userIds;
    const usersToRemove = selectedDocument
      ? associatedUsers.filter((id) => !userIds.includes(id))
      : [];
    const groupsToAdd = selectedDocument
      ? associatedGroupIds.filter((id) => !associatedGroups.includes(id))
      : associatedGroupIds;
    const groupsToRemove = selectedDocument
      ? associatedGroups.filter((id) => !associatedGroupIds.includes(id))
      : [];

    await modifyDocumentsByBatch(selected, {
      followers: {
        addUserIds: usersToAdd,
        removeUserIds: usersToRemove,
        addUserGroupIds: groupsToAdd,
        removeUserGroupIds: groupsToRemove,
      },
    });

    dispatch(reloadDocuments());
    dispatch(
      addSnackbar({
        id: Date.now(),
        message: 'Successfully assigned users to specifications.',
        severity: 'success',
      }),
    );
  };

  const handleSubmitPackageReview = async (review: SubmittalInfo[]) => {
    if (!selectedDocument) return;
    await completeReviewForPackageByDocumentId(
      selectedDocument.id,
      review.map(({ document, actionTaken, comment }) => ({
        documentId: document.id,
        actionTaken,
        comment,
      })),
    );
    dispatch(reloadDocuments());
  };

  useEffect(() => {
    if (selectedDocument) {
      setAssociatedGroups(
        selectedDocument?.documentUserGroupList?.map(({ userGroupId }) => userGroupId) || [],
      );
      setAssociatedUsers(selectedDocument.documentUserList?.map(({ userId }) => userId) || []);
    } else {
      setAssociatedUsers([]);
      setAssociatedGroups([]);
    }
  }, [selectedDocument]);

  useEffect(() => {
    if (documentType === DocumentTemplateType.PunchList) {
      if (sessionStorage.getItem('viewByFloorplanState')) setFloorplanDialogOpen(true);
    }
  }, []);

  return (
    <Toolbar className={clsx(classes.toolbar)}>
      <div className={classes.root}>
        {(documentType === DocumentTemplateType.Submittals ||
          documentType === DocumentTemplateType.CloseoutSubmittals) && (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            {getTitle()}
          </Typography>
        )}
        {getToolbarButton()}
        {selectedProject.isProcoreIntegrationEnabled &&
          user.isSiteAdmin &&
          (documentType === DocumentTemplateType.RequestsForInformation ||
            documentType === DocumentTemplateType.Submittals) && (
            <ProcoreButton
              variant="contained"
              color="primary"
              onClick={handleSyncProcoreDocument}
              style={{ margin: isDesktop ? 0 : '5px 0px' }}
            >
              {isSyncingWithProcore ? 'Syncing...' : 'Sync with Procore'}
            </ProcoreButton>
          )}
        <div style={{ display: 'inline-flex', flexGrow: 100 }} />
        {showDrawer() && !isDesktop && (
          <Button
            color="primary"
            variant="contained"
            style={{ margin: isDesktop ? 0 : '5px 0px' }}
            onClick={() => {
              if (setIndexDrawerOpen !== undefined) setIndexDrawerOpen(true);
            }}
          >
            {getDrawerText()}
          </Button>
        )}
        {showFilter() ? (
          <Button
            color="primary"
            variant="contained"
            ref={filterAnchor}
            className={classes.filterButton}
            onClick={() => setFilterDialogOpen(true)}
          >
            {getFilterText()}
          </Button>
        ) : null}
        <div className={classes.searchContainer}>
          {/* Search Bar */}
          <FormControl className={classes.formControl}>
            <TextField
              placeholder="Search"
              variant="outlined"
              size="small"
              value={inputSearch}
              onChange={(e) => handleSearchChange(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      fontSize="small"
                      classes={{
                        root: classes.icon,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <Tooltip title="Print" arrow placement="left">
            <IconButton size="small" style={{ marginRight: '4px' }}>
              <Print style={{ display: 'inline-flex' }} onClick={handlePrint} />
            </IconButton>
          </Tooltip>
          <CsvDownloader
            filename={getCsvFilename()}
            datas={getCsvData}
            columns={
              documentType === DocumentTemplateType.PunchList ? punchListCsvHeaders : undefined
            }
          >
            {csvLoading ? (
              <CircularLoader size={27} />
            ) : (
              <Tooltip title="Export to CSV" arrow placement="left">
                <IconButton size="small">
                  <CSVIcon style={{ display: 'inline-flex' }} />
                </IconButton>
              </Tooltip>
            )}
          </CsvDownloader>
        </div>
      </div>
      <DocumentLogFilterDialog
        dialogOpen={filterDialogOpen}
        handleClose={() => setFilterDialogOpen(false)}
        anchor={filterAnchor.current}
        selectedProject={selectedProject}
        filters={filters}
        updateFilters={setFilters}
        updateNumFilters={setNumFilters}
        updateRows={updateRows}
      />
      <EditDocumentsDialog
        dialogOpen={editDialogOpen}
        handleClose={() => {
          setEditDialogOpen(false);
          if (setUploadWorkflowOpen) setUploadWorkflowOpen(false);
        }}
        anchor={editAnchor.current || packageAnchor.current}
        selected={selected}
        addToCloseout={addToCloseout}
        removeFromCloseout={removeFromCloseout}
        selectedDocument={selectedDocument}
        canChangeAnticipatedSubmission={canChangeAnticipatedSubmission}
      />
      <ManagePermissionsDialog
        dialogOpen={associateDialogOpen}
        closeDialog={() => setAssociateDialogOpen(false)}
        type={ManagePermissionsDialogType.AssociatedUsers}
        associatedUsers={associatedUsers}
        // setAssociatedGroups={setAssociatedUsers}
        associatedGroups={associatedGroups}
        // setAssociatedUsers={setAssociatedGroups}
        submitAssociatedUsersAndGroups={handleSubmitAssociations}
      />
      <AddPackageDialog
        dialogOpen={addPackageDialogOpen}
        handleClose={() => setAddPackageDialogOpen(false)}
        anchor={packageAnchor.current}
        selected={selected}
      />
      <AddPackageDialog
        dialogOpen={addPlaceholderPackageDialogOpen}
        handleClose={() => setAddPlaceholderPackageDialogOpen(false)}
        anchor={placeholderPackageAnchor.current}
        selected={selected}
        isPlaceholder
      />
      <AddDocumentDialog
        dialogOpen={addDialogOpen}
        handleClose={() => setAddDialogOpen(false)}
        anchor={addAnchor.current}
      />
      <EditResponsiblePartyDialog
        dialogOpen={editResponsiblePartyDialogOpen}
        onClose={() => setEditResponsiblePartyDialogOpen(false)}
        anchor={responsiblePartyAnchor.current}
        selected={selected}
      />
      <EditPunchListStatusDialog
        dialogOpen={editPunchListStatusDialogOpen}
        handleClose={() => setEditPunchListStatusDialogOpen(false)}
        anchor={punchListStatusAnchor.current}
        selected={selected}
      />
      <ViewByFloorplanDialog
        open={floorplanDialogOpen}
        onClose={() => setFloorplanDialogOpen(false)}
      />
      <EmailUsersDialog
        dialogOpen={sendALinkDialogOpen}
        closeDialog={() => setSendALinkDialogOpen(false)}
        onSubmit={handleSendEmails}
        getLink={handleGetLink}
        defaultSubject={`New Link to Document in ${selectedProject.name}`}
      />
      {selectedDocument ? (
        <>
          {!isAsBuiltPackage ? (
            <EditAsBuiltStatusDialog
              anchor={editAnchor.current}
              dialogOpen={editAsBuiltStatusDialogOpen}
              handleClose={() => setEditAsBuiltStatusDialogOpen(false)}
              selectedDocument={selectedDocument}
            />
          ) : (
            <RefusePackageDialog
              isOpen={editAsBuiltStatusDialogOpen}
              handleClose={() => setEditAsBuiltStatusDialogOpen(false)}
              packageDocuments={selectedDocument?.submittalChildDocuments || []}
              submit={handleSubmitPackageReview}
              selectedDocument={selectedDocument}
            />
          )}
        </>
      ) : null}
      <CreatePunchListDialog
        open={createPunchListDialogOpen}
        handleClose={() => setCreatePunchListDialogOpen(false)}
        anchor={addAnchor.current}
      />
    </Toolbar>
  );
}
