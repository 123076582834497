import React from 'react';

const CalendarIcon = (props: any) => (
  <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.655 7.273V3.636h1.801v1.819h1.802V3.636H9.86v1.819h1.801V3.636h3.603v1.819h1.802V3.636h1.801v3.637H2.655zm0 10.909h16.213V9.09H2.655v9.09zM17.067 1.818V0h-1.802v1.818h-3.603V0H9.861v1.818H6.258V0H4.456v1.818H.853V20h19.816V1.818h-3.602z"
      fill="#7A797A"
      {...props}
    />
  </svg>
);

export default CalendarIcon;
