// eslint-disable-next-line import/prefer-default-export
export const VISIBILITY_FILTERS = {
  ALL: 'all',
  COMPLETED: 'completed',
  INCOMPLETE: 'incomplete',
};

export const MULTI_PART_FILE_SIZE = 500 * 1024 * 1024;
// export const MULTI_PART_FILE_SIZE = 0;

export const MULTI_PART_FILE_CHUNK_SIZE = 55 * 1024 * 1024;
// export const MULTI_PART_FILE_CHUNK_SIZE = 1024 * 1024;

export const DESIGN_PATH_CHARACTER_LIMIT_FOLDER = 140;
export const DESIGN_PATH_CHARACTER_LIMIT_FILE = 160;
