import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import { Button, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ascendingComparator } from '../document-index/DocumentIndexUtils';
import { useSelector } from 'react-redux';
import {
  getPhysicalBuildingsState,
  getPhysicalFloorsState,
  getPhysicalLocationsState,
} from '../../features/project/selectors';
import IconButton from '@material-ui/core/IconButton';
import { HighlightOffRounded } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { documentTypeToUrl } from '../../scripts/utils';
import { DocumentTemplateType } from '../../api-client/autogenerated';
import ViewByFloorplanDialog from './ViewByFloorplanDialog';

const useStyles = makeStyles({
  popover: {
    '.rootElement > *': {
      maxHeight: '5000px',
    },
  },
  select: {
    marginTop: 4,
  },
  column: {
    width: '100%',
  },
});

interface Props {
  open: boolean;
  handleClose: () => void;
  anchor: any;
}

export default function CreatePunchListDialog(props: Props) {
  const classes = useStyles();
  const { open, handleClose, anchor } = props;

  const history = useHistory();
  const buildings = useSelector(getPhysicalBuildingsState);
  const locations = useSelector(getPhysicalLocationsState);
  const floors = useSelector(getPhysicalFloorsState);

  const [selectedBuildingId, setSelectedBuildingId] = useState('');
  const [selectedFloorId, setSelectedFloorId] = useState('');
  const [selectedLocationId, setSelectedLocationId] = useState('');

  const [floorLayoutDialogOpen, setFloorLayoutDialogOpen] = useState(false);

  const selectedBuilding = buildings.find((b) => b.id === selectedBuildingId);
  const selectedFloor = floors.find((f) => f.id === selectedFloorId);

  useEffect(() => {
    const filteredBuildings = buildings.filter((b) => !b.isDefault);
    if (filteredBuildings.length === 1) {
      setSelectedBuildingId(filteredBuildings[0].id);
    }
  }, [buildings]);

  useEffect(() => {
    if (selectedBuildingId) {
      const filteredFloors = floors.filter(
        (f) =>
          f.buildingId === selectedBuildingId &&
          (!!f.floorPlanImageFileId || selectedBuilding?.isDefault),
      );
      if (filteredFloors.length === 1) {
        setSelectedFloorId(filteredFloors[0].id);
      }
    }
  }, [selectedBuildingId, floors]);

  useEffect(() => {
    if (selectedFloorId) {
      const filteredLocations = locations.filter((l) => l.floorId === selectedFloorId);
      if (filteredLocations.length === 1) {
        setSelectedLocationId(filteredLocations[0].id);
      }
    }
  }, [selectedFloorId, locations]);

  const closeClicked = () => {
    handleClose();
  };

  const handleProceed = () => {
    history.push(`${documentTypeToUrl[DocumentTemplateType.PunchList]}/create`, {
      selectedBuildingId,
      selectedFloorId,
      selectedLocationId,
    });
  };

  return (
    <Popover
      className={classes.popover}
      open={open}
      onClose={closeClicked}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div
        style={{ display: 'flex', flexDirection: 'column', minWidth: 300, rowGap: 16, padding: 16 }}
      >
        <Typography style={{ color: '#0947B9', fontSize: 20, fontWeight: 500 }}>Create</Typography>
        <IconButton
          onClick={closeClicked}
          style={{ position: 'absolute', right: 12, top: 12, padding: 0 }}
        >
          <HighlightOffRounded />
        </IconButton>
        <div className={classes.column}>
          <Typography>Building</Typography>
          <Select
            fullWidth
            variant="outlined"
            value={selectedBuildingId}
            onChange={(e) => {
              setSelectedBuildingId(e.target.value as string);
              setSelectedFloorId('');
              setSelectedLocationId('');
            }}
            displayEmpty
            className={classes.select}
          >
            <MenuItem key="empty" value="" disabled>
              <span style={{ color: '#BFBFBF' }}>Building</span>
            </MenuItem>
            {buildings
              .filter((building) =>
                floors
                  .filter((floor) => floor.buildingId === building.id)
                  .some((floor) => floor.floorPlanImageFileId || building.isDefault),
              )
              .map((building) => (
                <MenuItem key={building.id} value={building.id}>
                  {building.name}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div className={classes.column}>
          <Typography>Floor</Typography>
          <Select
            fullWidth
            variant="outlined"
            value={selectedFloorId}
            disabled={!selectedBuildingId}
            onChange={(e) => {
              setSelectedFloorId(e.target.value as string);
              setSelectedLocationId('');
            }}
            displayEmpty
            className={classes.select}
          >
            <MenuItem key="empty" value="" disabled>
              <span style={{ color: '#BFBFBF' }}>Floor</span>
            </MenuItem>
            {floors
              .filter((floor) => floor.buildingId === selectedBuildingId)
              .sort((a, b) => ascendingComparator(a, b, 'index'))
              .map((floor) => (
                <MenuItem key={floor.id} disabled={!floor.floorPlanImageFileId} value={floor.id}>
                  {floor.name}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div className={classes.column}>
          <Typography>Location</Typography>
          <Select
            fullWidth
            variant="outlined"
            value={selectedLocationId}
            onChange={(e) => setSelectedLocationId(e.target.value as string)}
            disabled={!selectedFloorId}
            displayEmpty
            className={classes.select}
          >
            <MenuItem key="empty" value="" disabled>
              <span style={{ color: '#BFBFBF' }}>Location</span>
            </MenuItem>
            {locations
              .filter((location) => location.floorId === selectedFloorId)
              .sort((a, b) => ascendingComparator(a, b, 'name'))
              .map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {location.name}
                </MenuItem>
              ))}
          </Select>
        </div>
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedFloorId || !selectedFloor?.floorPlanImageFileId}
          onClick={() => setFloorLayoutDialogOpen(true)}
          style={{ whiteSpace: 'nowrap' }}
        >
          VIEW FLOOR LAYOUT
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedBuildingId || !selectedFloorId || !selectedLocationId}
          onClick={handleProceed}
        >
          Proceed with Creation
        </Button>
      </div>
      {selectedFloorId && (
        <ViewByFloorplanDialog
          open={floorLayoutDialogOpen}
          onClose={() => setFloorLayoutDialogOpen(false)}
          defaultFloor={selectedFloorId}
          setLocation={(id) => {
            setSelectedLocationId(id);
            setFloorLayoutDialogOpen(false);
          }}
        />
      )}
    </Popover>
  );
}
