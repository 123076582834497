import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Hidden from '@material-ui/core/Hidden';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ExitToApp } from '@material-ui/icons';
import DashboardIcon from '../../main-components/icons/Dashboard-icon';
import DescriptionIcon from '@material-ui/icons/Description';
import DrawerListItem from '../../main-components/drawer/DrawerListItem';
import DrawerNestedList from '../../main-components/drawer/DrawerNestedList';
import '../../main-components/drawer/NavDrawer.scss';
import { IBidSetup } from '../../api-client/autogenerated';
import DashboardLockup from '../../main-components/icons/Dashboard-lockup';
import { Auth } from '@aws-amplify/auth';
import { setUser } from '../../features/user/actions';
import { setProject } from '../../features/project/actions';
import { AppBar, CssBaseline, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { isPdfViewer } from '../../scripts/utils';

const drawerWidth = 230;

interface BiddingNavDrawerProps {
  currentProject: IBidSetup | undefined;
  handleProjectInfoType: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    type: string,
  ) => void;
  setProjectInfoType: (type: string) => void;
  projectInfoType: string;
  setCurrentProject: (project: IBidSetup | undefined) => void;
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    overflowX: 'hidden',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    'box-shadow': '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '60px',
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    'box-shadow': '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  toolbarClosed: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarOpen: {
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  // align logo to arrow
  marginAutoItem: {
    marginBottom: 35,
    marginTop: 30,
    marginRight: 1,
    position: 'absolute',
  },
  formControl: {
    display: 'flex',
    width: '100%',
    paddingLeft: '16px',
    paddingRight: '16px',
    marginTop: 60,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  drawerArrow: {
    color: '#A6C4FF',
    textAlign: 'right',
  },
  drawerSelect: {
    height: '32px',
    /* Gray / Gray 50 */
    background: '#F9F9F9',

    /* Gray / Gray 400 Brand dark */
    border: '1px solid #949494',
    'border-radius': '5px',
  },
  drawerDivider: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  menuPaper: {
    maxHeight: 400,
    maxWidth: 300,
    overflowWrap: 'break-word',
    whiteSpace: 'unset',
  },
  wrap: {
    whiteSpace: 'unset',
    overflowWrap: 'break-word',
  },
  dropdownPadding: {
    paddingTop: 40,
  },
  selectAnchor: {
    position: 'relative',
    top: 60,
  },
  exitButton: {
    margin: 'auto 0px 12px 5px',
    alignSelf: 'flex-start',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '25px',
    textTransform: 'none',
    color: '#CCDEFF',
  },
  hidden: {
    display: 'none',
    width: 0,
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    background: '#00308C',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export default function BiddingNavDrawer(props: BiddingNavDrawerProps) {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    currentProject,
    handleProjectInfoType,
    projectInfoType,
    setProjectInfoType,
    setCurrentProject,
  } = props;
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [biddingMenuOpen, setBiddingMenuOpen] = React.useState(true);
  const [drawerIndex, setDrawerIndex] = React.useState(0);

  const openDrawerIcon =
    theme.direction === 'rtl' ? (
      <ArrowBackIcon className={classes.drawerArrow} />
    ) : (
      <ArrowForwardIcon className={classes.drawerArrow} />
    );
  const closeDrawerIcon =
    theme.direction === 'rtl' ? (
      <ArrowForwardIcon className={classes.drawerArrow} />
    ) : (
      <ArrowBackIcon className={classes.drawerArrow} />
    );

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleBiddingMenu = () => {
    setDrawerOpen(true);
    setBiddingMenuOpen(!biddingMenuOpen);
  };

  const handleDrawerItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setDrawerIndex(index);
  };

  const handleDashboardClick = () => {
    setProjectInfoType('');
    setCurrentProject(undefined);
    history.push('/bidding');
  };

  const projectInfoItems = [
    'Details',
    'Initial Bid Documents',
    'Addenda',
    'Prime Bidders',
    // 'Plan Holders',
    'RFIs',
    'Substitution Requests',
    'More Info',
    // 'Activity',
  ];

  const handleSignOut = () => {
    Auth.signOut()
      .then(() => {
        dispatch(setUser());
        dispatch(setProject());
        history.push('/bidding/login');
      })
      .catch((e) => console.log('error signing out: ', e));
  };

  const drawer = (
    <>
      <div className={drawerOpen ? classes.toolbarOpen : classes.toolbarClosed}>
        <IconButton onClick={toggleDrawer} style={{ background: 'transparent', outline: 'none' }}>
          {drawerOpen ? closeDrawerIcon : openDrawerIcon}
        </IconButton>
      </div>
      <IconButton
        style={{ backgroundColor: 'transparent', outline: 'none' }}
        onClick={handleDashboardClick}
        disableRipple
      >
        <DashboardLockup
          className={classes.marginAutoItem}
          style={{ width: drawerOpen ? '80px' : '40px', height: drawerOpen ? '80px' : '40px' }}
        />
        {/* <img
          src={Logobug}
          alt="hi"
          style={{ width: drawerOpen ? '80px' : '40px' }}
          className={classes.marginAutoItem}
        /> */}
      </IconButton>
      <Divider
        className={classes.drawerDivider}
        style={{ marginTop: drawerOpen ? '60px' : '32px' }}
      />
      <List>
        <DrawerListItem
          key={0}
          title="Projects"
          listIndex={0}
          onClick={handleDrawerItemClick}
          selectedIndex={drawerIndex}
          icon={<DashboardIcon />}
          drawerOpen={drawerOpen}
          handlePageChange={handleDashboardClick}
          selectedFunc={() => {
            return projectInfoType === '';
          }}
        />
        {currentProject && (
          <DrawerNestedList
            key={2}
            title="Project Info"
            listIndex={1}
            handleDrawer={handleDrawerItemClick}
            handleNestedLists={toggleBiddingMenu}
            selected={drawerIndex}
            icon={<DescriptionIcon />}
            drawerOpen={drawerOpen}
            menuOpen={biddingMenuOpen}
            subtitles={projectInfoItems}
            handleTypeSelect={handleProjectInfoType}
            projectInfoType={projectInfoType}
            biddingPortal
          />
        )}
        {/* <DrawerListItem
          key={7}
          title="Addenda"
          listIndex={7}
          onClick={handleDrawerItemClick}
          selectedIndex={drawerIndex}
          icon={<DashboardIcon />}
          drawerOpen={drawerOpen}
          handleDocumentType={handleDocumentType}
        /> */}
      </List>
      <div style={{ flexGrow: 1 }} />
      <DrawerListItem
        key={0}
        title="Sign Out"
        listIndex={99}
        onClick={handleSignOut}
        selectedIndex={drawerIndex}
        icon={
          <ExitToApp style={{ marginRight: '16px', transform: 'scaleX(-1)', color: '#73A3FF' }} />
        }
        drawerOpen={drawerOpen}
        handlePageChange={() => {}}
      />
      <div style={{ height: 8 }} />
    </>
  );

  const shouldHideDrawer = () => {
    return (
      location.pathname === '/bidding/register' ||
      location.pathname === '/bidding/login' ||
      location.pathname.includes('unsubscribe') ||
      isPdfViewer()
    );
  };

  return (
    <div className={clsx('drawer', shouldHideDrawer() ? classes.hidden : '')}>
      {/* mobile toolbar */}
      <Hidden smUp>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              style={{
                fontSize: '1.25rem',
                fontFamily: 'Roboto',
                fontWeight: 500,
                lineHeight: 1.6,
                letterSpacing: '.0075em',
                textTransform: 'uppercase',
                marginTop: 8,
              }}
            >
              Centerline
            </Typography>
          </Toolbar>
        </AppBar>
      </Hidden>

      {/* Mobile drawer */}
      <Hidden smUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={drawerOpen}
          onClose={toggleDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            }),
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      {/* desktop drawer */}
      <Hidden xsDown implementation="js">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            }),
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </div>
  );
}
