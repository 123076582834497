import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { HighlightOffRounded, InsertDriveFileOutlined, Publish } from '@material-ui/icons';
import PDFIcon from '../icons/PDF-icon';
import {
  ActionTakenType,
  DocumentTemplateType,
  IFile,
  INumberedDocumentView,
} from '../../api-client/autogenerated';
import {
  insertAdditionalReviewByDocumentId,
  submitAdditionalReviewByDocumentId,
} from '../../models/api/documents';
import CircularLoader from '../loader/CircularLoader';
import {
  downloadFileAsConsultant,
  fileIsPdf,
  openInBluebeam,
  openInNewTab,
  waitForFileToBeVerified,
} from '../../scripts/utils';
import { BluebeamButton, CancelButton, DownloadButton } from '../custom-components/CustomButtons';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentState } from '../../features/document/selectors';
import { fetchDocument, reloadDocument } from '../../features/document/actions';
import BluebeamFlattenDialog from './BluebeamFlattenDialog';
import { addSnackbar } from '../../features/snackbar/actions';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import { getNavigationState } from '../../features/navigation/selectors';
import { getLatestSubmissionFileByDocumentId } from '../../models/api/files';
import FileUploadDialog from './FileUploadDialog';
import { deleteFile } from '../../models/api/filesystem';
import { getUserState } from '../../features/user/selectors';
import { getDocumentsType } from '../../features/documents/selectors';

interface ConsultantFileUploadDialogProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  reviewDocument: INumberedDocumentView;
  refreshNotifications?: () => Promise<void>;
}

const defaultTipHeading =
  'Markups added in Bluebeam are automatically shared among all reviewers of this file.';
const designPackageTipHeading =
  'Markups added in Bluebeam Revu are automatically flattened shared among all reviewers of this file.';
const defaultTipBody =
  'Downloading the file for offline review will create a new version of the file which is visible to all reviewers after upload. However, this marked-up version may have to be manually combined with other reviews by the architect before return to contractor.';
const designPackageTipBody =
  'Downloading the file for offline review will create a new version of the file which is visible to all reviewers after upload. However, this marked-up version may have to be manually combined with other reviews.';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '30px',
      textAlign: 'left',
      textTransform: 'none',
      whiteSpace: 'pre',
      color: '#0947B9',
      marginBottom: '8px',
    },
    subtitle: {
      maxWidth: '450px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '21px',
      textAlign: 'left',
      textTransform: 'none',
      color: '#464546',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '-16px',
      marginRight: '4px',
    },
    contentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    rootIconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    textfield: {
      width: '320px',
    },
    actions: {
      padding: '8px 24px 28px',
      flexDirection: 'column',
      alignItems: 'center',
    },
    file: {
      display: 'inline-flex',
      marginTop: '16px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    fileText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '13px',
      textAlign: 'center',
      textTransform: 'none',
      color: '#949494', // Gray 400
      paddingLeft: 8,
    },
    columnLayout: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    rowLayout: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
  }),
);

export default function ConsultantFileUploadDialog(props: ConsultantFileUploadDialogProps) {
  const classes = useStyles();

  const { open, handleClose, title, reviewDocument, refreshNotifications } = props;

  const docType = useSelector(getDocumentsType);
  const user = useSelector(getUserState);
  const parentDocument = useSelector(getDocumentState)!;
  const shouldBlockNavigation = useSelector(getNavigationState);
  const dispatch = useDispatch();

  const [latestFile, setLatestFile] = useState<IFile>();
  useEffect(() => {
    getLatestSubmissionFileByDocumentId(reviewDocument.id)
      .then(setLatestFile)
      .catch(() => setLatestFile(undefined));
  }, [reviewDocument]);

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [bluebeamMarkupDialogOpen, setBluebeamMarkupDialogOpen] = useState(false);
  const [bluebeamStudioDialogOpen, setBluebeamStudioDialogOpen] = useState(false);
  const [bluebeamDone, setBluebeamDone] = useState(false);
  const [bluebeamFile, setBluebeamFile] = useState<IFile>();
  const [flattenedFile, setFlattenedFile] = useState<IFile>();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [readyForReview, setReadyForReview] = useState(false);
  const [inputActionTaken, setInputActionTaken] = useState<ActionTakenType>();
  const [inputComment, setInputComment] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File>();

  const isRfi = docType === DocumentTemplateType.RequestsForInformation;
  const isDesignPackage = docType === DocumentTemplateType.DesignPackages;

  const uploadConsultantFile = async () => {
    setIsLoading(true);
    dispatch(blockNavigation());
    let verified = !uploadedFile && !flattenedFile;

    try {
      if (uploadedFile) {
        setLoadingText(`Uploading ${uploadedFile.name}`);
        const { file } = await insertAdditionalReviewByDocumentId(
          reviewDocument.id,
          uploadedFile,
          handleUploadProgress,
        );
        setLoadingText('Verifying upload...');
        if (!file) verified = false;
        else verified = await waitForFileToBeVerified(file);
      } else if (flattenedFile) {
        setLoadingText('Verifying upload...');
        verified = await waitForFileToBeVerified(flattenedFile, true);
      }

      if (!verified) {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: `File ${
              flattenedFile || uploadedFile ? (flattenedFile || uploadedFile)!.name : ''
            } has not finished uploading. You must cancel the upload and try again`,
            severity: 'error',
          }),
        );
        return;
      }

      setLoadingText('Submitting review...');
      await submitAdditionalReviewByDocumentId(
        reviewDocument.id,
        inputActionTaken,
        inputComment,
        !readyForReview,
      );
      dispatch(allowNavigation());
      dispatch(fetchDocument(parentDocument.id));
    } catch (e: any) {
      console.log(e);
      dispatch(allowNavigation());
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const handleBluebeam = async () => {
    if (latestFile) {
      await openInBluebeam(latestFile.id, latestFile.name, reviewDocument.id);
      if (refreshNotifications) refreshNotifications();
      setBluebeamFile(latestFile);
    }
  };

  const handleBluebeamFinish = (flattenedFile?: IFile) => {
    setBluebeamDone(true);
    setFlattenedFile(flattenedFile);
    dispatch(
      addSnackbar({
        id: Date.now(),
        message: 'Your Bluebeam file is being processed',
        severity: 'success',
      }),
    );
  };

  const getSubmitDisabled = () => {
    if (isRfi || isDesignPackage)
      return !inputComment && !(uploadedFile || (bluebeamDone && flattenedFile));
    else {
      if (readyForReview)
        return !(
          (uploadedFile ||
            (bluebeamDone && flattenedFile) ||
            (!!parentDocument.bluebeamStudioSessionId && !!inputComment)) &&
          inputActionTaken
        );
      else return false;
    }
  };

  const handleDownload = async (fileId: string, filename: string) => {
    try {
      setIsDownloading(true);
      await downloadFileAsConsultant(fileId, filename, reviewDocument.id);
      if (refreshNotifications) {
        await refreshNotifications();
      }
    } finally {
      setIsDownloading(false);
    }
  };

  const closeClicked = () => {
    if (isLoading || shouldBlockNavigation) return;
    handleClose();
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleCancelUpload = async () => {
    if (!latestFile) return;
    await deleteFile(reviewDocument.id, latestFile.id);
    dispatch(reloadDocument());
  };

  const getTooltipTitle = () => {
    if (!latestFile) return '';
    if (!latestFile.isVerified) return 'Upload in progress...';
    if (fileIsPdf(latestFile)) return 'View PDF';
    return '';
  };

  return (
    <Dialog
      open={open}
      onClose={closeClicked}
      maxWidth="md"
      PaperProps={{ style: { minWidth: 720 } }}
      className={classes.root}
    >
      <DialogTitle style={{ paddingBottom: '20px' }}>
        <div className={classes.titleContainer}>
          <span className={classes.title}>{title}</span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              lineHeight: 0,
            }}
          >
            <span className={classes.subtitle}>
              {latestFile ? (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <b>Reviewing: </b>
                    {latestFile?.name.endsWith('.pdf') ? (
                      <PDFIcon
                        fill={latestFile.isVerified ? '#0947B9' : '#7A797A'}
                        style={{ marginLeft: 4, marginRight: 6 }}
                      />
                    ) : (
                      <InsertDriveFileOutlined htmlColor="#7A797A" style={{ fontSize: 30 }} />
                    )}
                    <Tooltip arrow placement="right" title={getTooltipTitle()}>
                      <span
                        onClick={() =>
                          fileIsPdf(latestFile) && latestFile.isVerified
                            ? openInNewTab(latestFile.id)
                            : null
                        }
                        style={
                          fileIsPdf(latestFile) && latestFile.isVerified
                            ? { color: '#0947B9', cursor: 'pointer' }
                            : {}
                        }
                      >
                        {latestFile.name}
                      </span>
                    </Tooltip>
                  </div>
                  {!latestFile.isVerified &&
                    (latestFile.creatorUserId === user.id || user.isSiteAdmin) && (
                      <CancelButton
                        variant="outlined"
                        onClick={handleCancelUpload}
                        style={{
                          width: '50%',
                          marginTop: 8,
                          borderColor: '#ED3F26',
                          color: '#ED3F26',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Cancel Upload
                      </CancelButton>
                    )}
                </>
              ) : (
                <b>No File Uploaded</b>
              )}
            </span>
          </div>
        </div>
        <IconButton
          style={{ right: '26px', top: '18px', position: 'absolute' }}
          onClick={closeClicked}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.contentContainer}>
        <div className={classes.columnLayout} style={{ width: '50%' }}>
          <div
            className={classes.rowLayout}
            style={{ width: '100%', justifyContent: 'space-between' }}
          >
            {!isDownloading ? (
              <DownloadButton
                disabled={!latestFile?.isVerified}
                onClick={() => {
                  handleDownload(latestFile!.id, latestFile!.name);
                }}
                style={{ marginRight: 4, width: 150 }}
              />
            ) : (
              <CircularLoader size={30} style={{ width: 150 }} />
            )}
            <Button
              variant="outlined"
              color="primary"
              style={{ height: 32, width: 150 }}
              disabled={bluebeamDone || !!uploadedFile}
              onClick={() => setUploadDialogOpen(true)}
              startIcon={<Publish fill="#2C69D6" />}
            >
              UPLOAD
            </Button>
          </div>
          <Typography style={{ fontWeight: 600, alignSelf: 'center', marginTop: 12 }}>
            OR
          </Typography>
          <BluebeamButton
            disabled={!!uploadedFile || !!flattenedFile || !latestFile?.isVerified}
            onClick={() => setBluebeamMarkupDialogOpen(true)}
            style={{ width: '100%', marginTop: 12 }}
          >
            Markup in Bluebeam Revu
          </BluebeamButton>

          <Typography style={{ fontWeight: 600, alignSelf: 'center', marginTop: 12 }}>
            OR
          </Typography>
          <BluebeamButton
            disabled={!parentDocument.bluebeamStudioSessionId}
            onClick={() => setBluebeamStudioDialogOpen(true)}
            style={{ width: '100%', marginTop: 12 }}
          >
            Join Bluebeam Studio Session
          </BluebeamButton>

          <div
            className={classes.file}
            style={{ visibility: !uploadedFile && !flattenedFile ? 'hidden' : 'unset' }}
          >
            <IconButton
              onClick={() => {
                setUploadedFile(undefined);
                setBluebeamDone(false);
                setFlattenedFile(undefined);
              }}
              style={{ padding: 0, marginRight: 8, marginLeft: -2 }}
            >
              <HighlightOffRounded />
            </IconButton>
            {flattenedFile?.name.endsWith('.pdf') || uploadedFile?.name.endsWith('.pdf') ? (
              <PDFIcon />
            ) : (
              <InsertDriveFileOutlined htmlColor="#7A797A" style={{ fontSize: 30 }} />
            )}
            <Typography className={classes.fileText}>
              {flattenedFile?.name || (uploadedFile as File)?.name}
            </Typography>
          </div>
          <FormControl style={{ marginTop: 16 }}>
            <RadioGroup
              value={String(readyForReview)}
              onChange={(event, value) => setReadyForReview(value === 'true')}
            >
              <FormControlLabel
                value="false"
                control={<Radio style={{ padding: '0px 9px' }} color="primary" />}
                label="Review in progress"
              />
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Review complete, return to architect"
              />
            </RadioGroup>
          </FormControl>
          {!isRfi && !isDesignPackage && (
            <FormControl fullWidth variant="outlined" style={{ marginTop: 12, width: '100%' }}>
              <Typography variant="body1" style={{ paddingBottom: 4 }}>
                Recommended action
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                disabled={!readyForReview}
                value={inputActionTaken}
                onChange={(e: any) => {
                  setInputActionTaken(e.target.value);
                }}
                MenuProps={{
                  // @ts-ignore
                  getContentAnchorEl: () => null,
                  autoFocus: true,
                }}
                fullWidth
              >
                <MenuItem value={ActionTakenType.AmendAsNoted}>Amend As Noted</MenuItem>
                <MenuItem value={ActionTakenType.NoExceptionsTaken}>No Exceptions Taken</MenuItem>
                <MenuItem value={ActionTakenType.ReviseAndResubmit}>Revise & Resubmit</MenuItem>
                <MenuItem value={ActionTakenType.SeeSubmittalComments}>
                  See Submittal Comments
                </MenuItem>
                <MenuItem value={ActionTakenType.SeeTransmittalComments}>
                  See Transmittal Comments
                </MenuItem>
                <MenuItem value={ActionTakenType.SubmitSpecifiedItem}>
                  Submit Specified Item
                </MenuItem>
              </Select>
            </FormControl>
          )}
        </div>
        <div className={classes.columnLayout} style={{ width: 320 }}>
          <Typography style={{ fontSize: 14, fontWeight: 600 }}>TIP:</Typography>
          <div
            className={classes.columnLayout}
            style={{ borderWidth: 1, borderStyle: 'solid', borderColor: '#000', padding: 8 }}
          >
            <Typography style={{ fontSize: 13 }}>
              {docType === DocumentTemplateType.DesignPackages
                ? designPackageTipHeading
                : defaultTipHeading}
            </Typography>
            <Typography style={{ fontSize: 13, marginTop: 16 }}>
              {docType === DocumentTemplateType.DesignPackages
                ? designPackageTipBody
                : defaultTipBody}
            </Typography>
          </div>

          <TextField
            variant="outlined"
            multiline
            rows={!isRfi && !isDesignPackage ? 9 : 4}
            value={inputComment}
            onChange={(event) => setInputComment(event.target.value)}
            label="Comments..."
            InputLabelProps={{ style: { textTransform: 'capitalize' } }}
            InputProps={{ style: { padding: '10px' } }}
            className={classes.textfield}
            style={{ marginTop: 20 }}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {isLoading ? (
          <>
            <Typography style={{ fontWeight: 500 }}>{loadingText}</Typography>
            {loadingText.includes('Uploading') ? (
              <>
                <Typography style={{ fontSize: 14, marginTop: 4 }}>{uploadProgress}%</Typography>
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress}
                  style={{ height: 8, width: '100%', marginTop: 4, marginBottom: 8 }}
                />
              </>
            ) : (
              <CircularLoader style={{ marginTop: 4, marginBottom: 4 }} />
            )}
            <Typography style={{ fontWeight: 500 }}>DO NOT NAVIGATE AWAY FROM THIS PAGE</Typography>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={getSubmitDisabled()}
            onClick={uploadConsultantFile}
            startIcon={<Publish style={{ marginBottom: 3 }} />}
            style={{ width: '100%' }}
          >
            {readyForReview ? 'Submit' : 'Save (In Progress Selected)'}
          </Button>
        )}
      </DialogActions>
      <BluebeamFlattenDialog
        dialogOpen={bluebeamMarkupDialogOpen}
        handleClose={() => setBluebeamMarkupDialogOpen(false)}
        handleMarkup={handleBluebeam}
        documentId={reviewDocument.id}
        finish={handleBluebeamFinish}
        file={bluebeamFile}
        isConsultant
        disableMarkup={!latestFile?.isVerified}
      />
      <BluebeamFlattenDialog
        dialogOpen={bluebeamStudioDialogOpen}
        handleClose={() => setBluebeamStudioDialogOpen(false)}
        documentId={reviewDocument.id}
        isConsultant
        disableMarkup
        studioSessionId={parentDocument.bluebeamStudioSessionId}
      />
      <FileUploadDialog
        open={uploadDialogOpen}
        handleClose={() => setUploadDialogOpen(false)}
        title="Consultant Upload"
        addFile={(f) => setUploadedFile(f as File)}
        removeFile={() => setUploadedFile(undefined)}
        file={uploadedFile}
        canSubmit={!!uploadedFile}
        disableDesignUpload
        disableComments
      />
    </Dialog>
  );
}
