import React, { useEffect, useState } from 'react';
import {
  Card,
  Divider,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs, { Dayjs } from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import { Add, Save } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NavAppbar from '../nav-top/NavAppbar';
import CircularLoader from '../loader/CircularLoader';
import {
  ICustomInsertionProject,
  IProject,
  IProjectName,
  IProjectUser,
  ISubscriber,
  PhoneNumberType,
} from '../../api-client/autogenerated';
import {
  addOrModifyUsersOfProject,
  getSuggestedProjectEmailSlug,
  insertProject,
  patchProject,
} from '../../models/api/project';
import PageTitle from '../page-title/PageTitle';
import CreateProjectUsers from '../project-users/CreateProjectUsers';
import { insertUser, removeUserFromProjectByProjectId } from '../../models/api/users';
import { getUserState } from '../../features/user/selectors';
import { fetchProjects } from '../../features/projects/actions';
import { getAdminProjectUsersState, getProjectState } from '../../features/project/selectors';
import {
  fetchSecurityGroups,
  fetchSubscriberSecurityGroups,
} from '../../features/security/actions';
import FullscreenLoader from '../loader/FullscreenLoader';
import { useParams } from 'react-router';
import { parseDate, ProjectMatchParams, removeNewlines } from '../../scripts/utils';
import { fetchProject } from '../../features/project/actions';
import { insertPhoneNumberByUserId } from '../../models/api/phone-number';
import { SubmitButton } from '../custom-components/CustomButtons';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import { getAllProjectNamesBySubscriberId, getSubscribers } from '../../models/api/subscribers';
import { hasAdminPermissions } from '../../scripts/store-utils';
import { addSnackbar } from '../../features/snackbar/actions';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    marginTop: 8,
    paddingBottom: 32,
  },
  submit: {
    margin: theme.spacing(1),
    lineHeight: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh',
    overflowX: 'hidden',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: '100%',
  },
  alignLeft: {
    textAlign: 'left',
  },
  paddingLeft: {
    paddingLeft: 15,
    paddingTop: 10,
  },
  checkbox: {
    paddingLeft: 25,
    marginLeft: 20,
  },
  loadingCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  loadingNotCenter: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  loadingMargin: {
    paddingBottom: 16,
  },
  title: {
    padding: 0,
  },
  textField: {
    maxWidth: 300,
    paddingTop: 4,
    paddingBottom: 8,
  },
  textFieldSmall: {
    maxWidth: 142,
  },
  rootGrid: {
    maxWidth: 700,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    padding: 0,
  },
  fileInstructions: {
    maxWidth: 500,
  },
  endDiv: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 8,
    paddingBottom: 8,
  },
  widthConstraint: {
    maxWidth: 700,
  },
  placeholder: {
    fontStyle: 'italic',
  },
}));

interface AddOrManageProjectPageProps {
  edit?: boolean;
}

export enum UserActionType {
  None,
  // eslint-disable-next-line
  Add,
  Delete,
  Edit,
  Invite,
}

interface UserAndAction {
  user: IProjectUser;
  action: UserActionType;
}

function AddOrManageProjectPage(props: AddOrManageProjectPageProps) {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<ProjectMatchParams>();
  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const { edit } = props;
  const manageCurrentProject = useSelector(getProjectState);
  const currentProject = edit ? manageCurrentProject : null;
  const nullablePropertyToString = (property: string | null | undefined) => {
    if (property) return property;
    return '';
  };

  const date = currentProject?.createdOn
    ? parseDate(currentProject.createdOn).format('MM/DD/YYYY')
    : dayjs().format('MM/DD/YYYY');
  // Adding 'Demo' here temporarily to let the project have the demo account as the project owner
  // Remove 'Demo' when issue #255 is resolved
  const projectOwner = currentProject ? currentProject.owner : user.name;

  const [isLoading, setIsLoading] = useState(false);

  const [projectName, setProjectName] = useState<string>();
  const [projectNameError, setProjectNameError] = useState<string>();
  const [projectDescription, setProjectDescription] = useState<string>();
  const [ownerProjectNumber, setOwnerProjectNumber] = useState<string>();
  const [architectProjectNumber, setArchitectProjectNumber] = useState<string>();
  const [approvedDate, setApprovedDate] = useState<Dayjs | null>();
  const [emailSlug, setEmailSlug] = useState<string>();
  const [emailSlugError, setEmailSlugError] = useState<string>();
  const [hasDuplicateSlug, setHasDuplicateSlug] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const projectUsers = useSelector(getAdminProjectUsersState);
  const [users, setUsers] = useState<UserAndAction[]>([]);

  const [existingProjects, setExistingProjects] = useState<IProjectName[]>([]);

  const [subscribers, setSubscribers] = useState<ISubscriber[]>([]);
  const [selectedSubscriberId, setSelectedSubscriberId] = useState('');
  const [changeSubscriberLoading, setChangeSubscriberLoading] = useState(false);

  const canChangeSubscriber = !!user.isSiteAdmin && !!currentProject;

  const isAdminUser = currentProject
    ? hasAdminPermissions()
    : !!user.adminOfSubscriberId || user.isSiteAdmin;

  const hasDuplicateName =
    !!projectName &&
    existingProjects.some((p) => p.name === projectName.trim() && p.id !== currentProject?.id);

  const verifyEmailSlug = async () => {
    if (!projectName || !emailSlug) return;
    const suggestedSlug = await getSuggestedProjectEmailSlug(projectName, emailSlug);
    setHasDuplicateSlug(suggestedSlug !== emailSlug);
  };

  useEffect(() => {
    if (!manageCurrentProject) {
      if (user.adminOfSubscriberId) {
        dispatch(fetchSubscriberSecurityGroups(user.adminOfSubscriberId));
      }
    }
  }, [manageCurrentProject]);

  useEffect(() => {
    if (canChangeSubscriber) {
      getSubscribers(1000).then(setSubscribers);
    }
  }, [user, currentProject]);

  useEffect(() => {
    if (currentProject) {
      getAllProjectNamesBySubscriberId(currentProject.subscriberId).then(setExistingProjects);
    } else if (user.adminOfSubscriberId) {
      getAllProjectNamesBySubscriberId(user.adminOfSubscriberId).then(setExistingProjects);
    }
  }, [user, currentProject]);

  useEffect(() => {
    setProjectNameError(hasDuplicateName ? 'Project name already exists.' : undefined);
  }, [hasDuplicateName]);

  useEffect(() => {
    setEmailSlugError(hasDuplicateSlug ? 'Project email address already exists.' : undefined);
  }, [hasDuplicateSlug]);

  const addProject = async (e: React.FormEvent, shouldRedirect: boolean) => {
    e.preventDefault();
    dispatch(blockNavigation());

    if (hasDuplicateSlug) return;

    setProjectNameError(undefined);
    setEmailSlugError(undefined);
    setIsLoading(true);

    setLoadingMessage(currentProject ? 'Updating Project' : 'Creating Project');

    try {
      // Update already existing project
      let newOrPatchedProject: IProject;
      if (currentProject) {
        if (!isAdminUser) {
          newOrPatchedProject = currentProject;
        } else {
          newOrPatchedProject = await patchProject(currentProject.id, {
            name: removeNewlines(projectName),
            description: projectDescription,
            ownerProjectNumber,
            architectProjectNumber,
            approvedDate: approvedDate ? approvedDate.format('YYYY-MM-DD') : undefined,
            emailSlug,
          });
        }
      } else {
        const project: ICustomInsertionProject = {
          name: removeNewlines(projectName!)!,
          description: projectDescription?.trim(),
          owner: currentProject ? undefined : projectOwner,
          ownerProjectNumber,
          architectProjectNumber,
          approvedDate: approvedDate ? approvedDate.format('YYYY-MM-DD') : undefined,
          emailSlug,
        };
        newOrPatchedProject = await insertProject(project);
      }

      // Add new users
      const newUsers = users.filter((x) => x.action === UserActionType.Add).map((x) => x.user);
      const removeUsers = users
        .filter((x) => x.action === UserActionType.Delete)
        .map((x) => x.user);
      const editUsers = users.filter((x) => x.action === UserActionType.Edit).map((x) => x.user);
      const inviteUsers = users
        .filter((x) => x.action === UserActionType.Invite)
        .map((x) => x.user);

      //Users who don't already exist in Centerline and must be invited and added to the database
      setLoadingMessage('Adding Project Users');
      await Promise.all(
        inviteUsers.map((projectUser) =>
          insertUser(
            {
              email: projectUser!.user!.email,
              name: projectUser!.user!.name,
              companyId: projectUser!.user!.companyId,
            },
            newOrPatchedProject.id || '',
            projectUser.securityGroupId || '',
          ).then((newUser) => {
            insertPhoneNumberByUserId(newUser.id, {
              number: projectUser!.user!.phoneNumbers![0].number,
              type: PhoneNumberType.Mobile,
              countryId: '',
            });
          }),
        ),
      );

      // Users who do exist in Centerline but not in this project
      setLoadingMessage('Updating Project Users');
      await Promise.all(
        newUsers.map((projectUser) =>
          addOrModifyUsersOfProject(newOrPatchedProject.id, [
            {
              securityGroupId: projectUser.securityGroupId || undefined,
              userId: projectUser.user!.id!,
            },
          ]),
        ),
      );

      // Removing users
      await Promise.all(
        removeUsers.map((projectUser) =>
          removeUserFromProjectByProjectId(newOrPatchedProject.id, projectUser.user!.id),
        ),
      );

      // Editing users
      await Promise.all(
        editUsers.map((projectUser) =>
          addOrModifyUsersOfProject(newOrPatchedProject.id, [
            { securityGroupId: projectUser.securityGroupId!, userId: projectUser.user!.id! },
          ]),
        ),
      );

      dispatch(allowNavigation());
      dispatch(fetchSecurityGroups);
      dispatch(fetchProject(newOrPatchedProject.id));
      dispatch(fetchProjects()).then(() => {
        setIsLoading(false);
        if (!shouldRedirect) {
          dispatch(addSnackbar({ id: Date.now(), message: 'Saved changes!', severity: 'success' }));
        }
        history.push(`/main/projects/${newOrPatchedProject.id}${!shouldRedirect ? '/manage' : ''}`);
      });
    } catch (e: any) {
      setIsLoading(false);
      dispatch(allowNavigation());
    }
  };

  const handleDeleteUser = (id: string) => {
    const newUsers = [...users];

    const index = newUsers.findIndex((x) => x.user!.user!.id === id);
    //  If the user has just been added but the user hasn't pressed submit, simply remove the user from the user list.
    if (
      newUsers[index].action === UserActionType.Add ||
      newUsers[index].action === UserActionType.Invite
    ) {
      newUsers.splice(index, 1);
    } else {
      /* If the deletion above hasn't occurred, then that means the user is trying to delete
    a user that already exists in the database. We need to add a delete action to know to remove them
    from the database when the user submits the form */
      newUsers[index].action = UserActionType.Delete;
    }

    setUsers(newUsers);
  };

  const createProjectUser = (projectUser: IProjectUser, action = UserActionType.Add) => {
    const newUsers = [...users];
    newUsers.push({ user: projectUser, action });
    setUsers(newUsers);
  };

  const createProjectUsers = (projectUsers: IProjectUser[], action = UserActionType.Add) => {
    const newUsers = [...users];
    projectUsers.forEach((projectUser) => {
      newUsers.push({ user: projectUser, action });
    });
    setUsers(newUsers);
  };

  /* eslint-disable */
  const updateUser = (
    id: string,
    name: string | undefined,
    description: string | undefined,
    email: string | undefined,
    website: string | undefined,
    securityGroupId: string | undefined,
    securityGroupName: string | undefined,
  ) => {
    //TODO: Add other fields
    const newUsers = [...users];
    const u = newUsers.find((x) => x.user.user!.id === id);
    if (u) {
      u.user.securityGroupId = securityGroupId;

      // This info is wrong, but only the above user id is needed in the API call
      u.user.securityGroup = {
        id: securityGroupId!,
        name: securityGroupName || 'N/A',
        lastUpdatedByUserId: user.id,
        subscriberId: user.adminOfSubscriberId!,
      };

      if (u.action !== UserActionType.Add && u.action !== UserActionType.Invite) {
        u.action = UserActionType.Edit;
      }
      setUsers(newUsers);
    }
  };

  const getSuggestedEmailSlug = async () => {
    if (!projectName) {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Please set a project name before generating an email slug',
          severity: 'warning',
        }),
      );
    } else {
      setHasDuplicateSlug(false);
      const slug = await getSuggestedProjectEmailSlug(projectName, emailSlug);
      setEmailSlug(slug);
    }
  };

  useEffect(() => {
    if (user && !edit) {
      const me = { ...user };
      // me.id = 'me';
      setUsers([
        {
          // @ts-ignore
          user: { user: me },
          action: UserActionType.None,
        },
        ...projectUsers
          .filter((x) => !x.user?.isSiteAdmin)
          .map((x) => {
            return { user: x, action: UserActionType.None };
          }),
      ]);
    } else
      setUsers(
        projectUsers
          .filter((x) => !x.user?.isSiteAdmin)
          .map((x) => {
            return { user: x, action: UserActionType.None };
          }),
      );
  }, [user, projectUsers]);

  useEffect(() => {
    if (params.projectId) dispatch(fetchProject(params.projectId));
  }, [params.projectId]);

  useEffect(() => {
    if (manageCurrentProject) {
      setProjectName(manageCurrentProject.name);
      setOwnerProjectNumber(manageCurrentProject.ownerProjectNumber || '');
      setArchitectProjectNumber(manageCurrentProject.architectProjectNumber || '');
      setProjectDescription(manageCurrentProject.description || '');
      setApprovedDate(
        manageCurrentProject.approvedDate ? parseDate(manageCurrentProject.approvedDate) : dayjs(),
      );
      setEmailSlug(manageCurrentProject.emailSlug || '');
    }
  }, [manageCurrentProject]);

  const handleChangeSubscriber = async () => {
    if (!selectedSubscriberId || !canChangeSubscriber) return;

    try {
      setChangeSubscriberLoading(true);
      await patchProject(currentProject!.id, { subscriberId: selectedSubscriberId });
      window.location.reload();
    } finally {
      setChangeSubscriberLoading(false);
    }
  };

  return (
    <main className={classes.root}>
      <NavAppbar />
      <Grid container justify="center">
        <Grid item xs={12}>
          <PageTitle title={edit ? 'Manage Project' : 'Create New Project'} />
        </Grid>
      </Grid>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} container zeroMinWidth>
          {(edit && currentProject) || (!edit && !currentProject) ? (
            <Card className={classes.paper}>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <form className={classes.form}>
                  <div style={{ maxWidth: user.isSiteAdmin ? 'unset' : 700 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography className={classes.title} variant="h2">
                          STEP 1. SETUP PROJECT DETAILS
                        </Typography>
                      </Grid>
                      <Grid item sm={user.isSiteAdmin ? 4 : 6} xs={12}>
                        <div className={classes.column}>
                          <FormControl className={classes.formControl}>
                            <Typography variant="body1">
                              Project Name <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="project-name"
                              placeholder="Project Name"
                              name="Project Name"
                              required
                              autoFocus
                              className={classes.textField}
                              disabled={!isAdminUser}
                              value={projectName}
                              error={hasDuplicateName}
                              helperText={projectNameError}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => setProjectName(e.target.value)}
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <Typography variant="body1">Description</Typography>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="description"
                              placeholder="Project Description"
                              name="Description"
                              disabled={!isAdminUser}
                              value={projectDescription}
                              multiline
                              rows={5}
                              className={classes.textField}
                              onChange={(e) => setProjectDescription(e.target.value)}
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <div
                              style={{
                                maxWidth: 300,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography variant="body1">Project Email Address</Typography>
                              <SubmitButton
                                disabled={!projectName || !!currentProject?.emailSlug}
                                onClick={getSuggestedEmailSlug}
                                style={{ marginLeft: 8 }}
                              >
                                Auto-generate
                              </SubmitButton>
                            </div>
                            <TextField
                              variant="outlined"
                              fullWidth
                              name="Project Email Address"
                              placeholder="customize..."
                              required
                              className={classes.textField}
                              disabled={!!currentProject?.emailSlug}
                              value={emailSlug}
                              helperText={
                                hasDuplicateSlug
                                  ? emailSlugError
                                  : 'Project email address cannot be changed once set.'
                              }
                              FormHelperTextProps={{ style: { marginLeft: 4, marginRight: 4 } }}
                              error={hasDuplicateSlug}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: <strong>@p.centerline.co</strong>,
                              }}
                              onChange={(e) =>
                                setEmailSlug(
                                  e.target.value.replace(/[^0-9a-zA-Z-._]+/, '').toLowerCase(),
                                )
                              }
                              onBlur={verifyEmailSlug}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item sm={user.isSiteAdmin ? 4 : 6} xs={12}>
                        <div className={classes.column}>
                          <FormControl>
                            <Typography variant="body1">Created by</Typography>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="created-by"
                              placeholder="N/A"
                              name="Created By"
                              disabled
                              className={classes.textField}
                              value={projectOwner}
                              InputLabelProps={{
                                style: { background: 'transparent' },
                              }}
                            />
                          </FormControl>
                          <FormControl>
                            <Typography variant="body1">Created On</Typography>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="created-on"
                              placeholder="Created on"
                              name="Created on"
                              disabled
                              className={classes.textField}
                              value={date}
                              InputLabelProps={{
                                style: { background: 'transparent' },
                              }}
                            />
                          </FormControl>
                          <FormControl>
                            <Typography variant="body1">Owner&apos;s Project Number</Typography>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="owner-project-number"
                              className={classes.textField}
                              placeholder="Owner's Project Number"
                              name="Owner's Project Number"
                              disabled={!isAdminUser}
                              value={ownerProjectNumber}
                              onChange={(e) => setOwnerProjectNumber(e.target.value)}
                            />
                          </FormControl>
                          <FormControl>
                            <Typography variant="body1">Architect&apos;s Project Number</Typography>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="architect-project-number"
                              placeholder="Architect's Project Number"
                              name="Architect's Project Number"
                              className={classes.textField}
                              disabled={!isAdminUser}
                              value={architectProjectNumber}
                              onChange={(e) => setArchitectProjectNumber(e.target.value)}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      {canChangeSubscriber ? (
                        <Grid item sm={4} xs={12}>
                          <div className={classes.column}>
                            <FormControl className={classes.formControl} style={{ maxWidth: 300 }}>
                              <Typography variant="body1" style={{ marginBottom: 12 }}>
                                Current Subscriber:{' '}
                                {
                                  subscribers.find((s) => s.id === currentProject?.subscriberId)
                                    ?.name
                                }
                              </Typography>
                              <Typography variant="body1" style={{ marginBottom: 4 }}>
                                Change Subscriber
                              </Typography>
                              <Select
                                variant="outlined"
                                displayEmpty
                                value={selectedSubscriberId}
                                onChange={(event) =>
                                  setSelectedSubscriberId(event.target.value as string)
                                }
                              >
                                <MenuItem key="none" value="" disabled>
                                  Select New Subscriber
                                </MenuItem>
                                {subscribers
                                  .filter((s) => s.id !== currentProject?.subscriberId)
                                  .map((s) => (
                                    <MenuItem key={s.id} value={s.id}>
                                      {s.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            {!changeSubscriberLoading ? (
                              <SubmitButton
                                disabled={!selectedSubscriberId}
                                onClick={handleChangeSubscriber}
                                style={{ marginTop: 12, width: 180 }}
                              >
                                Change Subscriber
                              </SubmitButton>
                            ) : (
                              <CircularLoader style={{ marginTop: 12, width: 180 }} />
                            )}
                          </div>
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        <Divider light />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">Dates</Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <div className={classes.column}>
                          <FormControl className={classes.formControl}>
                            <Typography variant="body1">Approved Date</Typography>
                            <KeyboardDatePicker
                              disabled={!isAdminUser}
                              value={approvedDate}
                              id="approved-date"
                              onChange={(d) => setApprovedDate(d)}
                              inputVariant="outlined"
                              InputProps={{ style: { padding: 0 } }}
                              format="MM/DD/YYYY"
                              placeholder="Approved Date"
                              className={classes.textField}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12} alignItems="flex-start">
                        {!isLoading ? (
                          <SubmitButton
                            id="submit-button"
                            onClick={(e) => addProject(e, false)}
                            disabled={!projectName || hasDuplicateName || hasDuplicateSlug}
                            variant="contained"
                            color="primary"
                            startIcon={<Save />}
                            className={classes.submit}
                            style={{ marginLeft: 0 }}
                          >
                            Save
                          </SubmitButton>
                        ) : (
                          <CircularLoader
                            style={{ justifyContent: 'flex-start', marginLeft: 32 }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ maxWidth: 1600, marginTop: 16 }}>
                    <CreateProjectUsers
                      label="STEP 2. MANAGE PROJECT USERS"
                      users={users
                        .filter((x) => x.action !== UserActionType.Delete)
                        .map((x) => {
                          return { ...x.user, isInvited: x.action !== UserActionType.Invite };
                        })}
                      createProjectUser={createProjectUser}
                      createProjectUsers={createProjectUsers}
                      handleDeleteUser={handleDeleteUser}
                      updateUser={updateUser}
                    />
                  </div>

                  <div className={classes.widthConstraint}>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12}>
                      <Divider />
                    </Grid> */}
                      <Grid item xs={12} style={{ marginTop: 16 }}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.title} variant="h2">
                          STEP 3.{' '}
                          {edit
                            ? 'UPDATE PROJECT DETAILS AND INVITE USERS'
                            : 'COMPLETE PROJECT SETUP AND INVITE USERS'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <div className={classes.endDiv}>
                          {isLoading ? (
                            <div className={classes.loadingCenter}>
                              <div className={classes.loadingMargin}>
                                <CircularLoader />
                              </div>
                              <div style={{ alignSelf: 'center' }}>{loadingMessage}</div>
                            </div>
                          ) : (
                            <div style={{ alignSelf: 'flex-start' }}>
                              <SubmitButton
                                id="submit-button"
                                onClick={(e) => addProject(e, true)}
                                disabled={!projectName || hasDuplicateName || hasDuplicateSlug}
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                              >
                                <Add />
                                {edit
                                  ? 'Update Project Details and invite Users'
                                  : 'Complete project setup and invite users'}
                              </SubmitButton>
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </MuiPickersUtilsProvider>
            </Card>
          ) : (
            <FullscreenLoader />
          )}
        </Grid>
      </Grid>
    </main>
  );
}

export default AddOrManageProjectPage;
