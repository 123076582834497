import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { createStyles, Dialog, DialogContent, useMediaQuery } from '@material-ui/core';
import { computeOptimalImageSize, getImageByFileId } from '../../scripts/utils';
import CircularLoader from '../loader/CircularLoader';
import ImageWithMarkers, { LocationRegion, PunchListMarker } from './ImageWithMarkers';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PunchListFlagIcon from '../icons/PunchListFlag-icon';
import IconButton from '@material-ui/core/IconButton';
import { HighlightOffRounded, HighlightOffTwoTone } from '@material-ui/icons';
import { PunchListStatusType } from '../../api-client/autogenerated';

const useStyles = makeStyles(() =>
  createStyles({
    rootIconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    dialogTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '21px',
      textAlign: 'left',
      color: '#0947B9',
      textTransform: 'none',
      whiteSpace: 'nowrap',
    },
    button: {
      whiteSpace: 'nowrap',
      marginLeft: 8,
      paddingLeft: 16,
      paddingRight: 16,
    },
  }),
);

type FilterType = 'none' | 'open' | 'closed' | 'ready_for_verification';

type Props = {
  open: boolean;
  onClose: () => void;
  fileId: string;
  locationName?: string;
  floorName?: string;
  buildingName?: string;
  markers: PunchListMarker[];
  regions?: LocationRegion[];
  regionOnClick: (id: string) => void;
  onNavigation: () => void;
};

const DIALOG_VERTICAL_MARGINS = 64;
const CONTENT_LEFT_MARGINS = 8;

export default function ViewFloorplanWithMarkersDialog(props: Props) {
  const classes = useStyles();
  const {
    open,
    onClose,
    fileId,
    locationName,
    floorName,
    buildingName,
    markers,
    regions,
    regionOnClick,
    onNavigation,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [marginLeft, setMarginLeft] = useState(0);
  const [closeButtonTop, setCloseButtonTop] = useState(8);

  const imageRef = useRef<HTMLDivElement>(null);
  const paperRef = useRef<HTMLDivElement>(null);

  const CONTENT_TOP_MARGINS = 128;
  const CONTENT_VERTICAL_MARGINS = CONTENT_TOP_MARGINS + 8;
  const SCROLL_HEIGHT_DIFFERENCE = paperRef.current
    ? paperRef.current.scrollHeight - paperRef.current.clientHeight
    : 0;

  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState<string>();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [imageWidth, setImageWidth] = useState<number>();
  const [filter, setFilter] = useState<FilterType>();

  useEffect(() => {
    setImage(undefined);
    if (open) {
      setIsLoading(true);
      getImageByFileId(fileId, true)
        .then(setImage)
        .finally(() => setIsLoading(false));
    }
  }, [fileId, open]);

  const onLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    const target = e.target as HTMLImageElement;
    const maxHeight = window.innerHeight - DIALOG_VERTICAL_MARGINS - CONTENT_VERTICAL_MARGINS;

    const { width } = computeOptimalImageSize(
      target.naturalWidth,
      target.naturalHeight,
      720,
      maxHeight,
    );

    setImageWidth(width);
    setScaleFactor(target.width / width);
    setImageLoaded(true);
  };

  const onScroll = (e: Event) => {
    setMarginLeft((e.target as HTMLDivElement).scrollLeft);
    setCloseButtonTop((e.target as HTMLDivElement).scrollTop);
  };

  useEffect(() => {
    if (paperRef.current) {
      paperRef.current.addEventListener('scroll', onScroll, { passive: true });
    }
  }, [paperRef.current]);

  const handleClose = () => {
    setImage(undefined);
    setImageLoaded(false);
    setScaleFactor(1);
    sessionStorage.removeItem('viewByFloorplanState');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ ref: paperRef, style: { minWidth: '50%', maxWidth: 'unset' } }}
    >
      <DialogContent style={{ padding: 8, objectFit: 'contain', overflow: 'visible' }}>
        <div style={{ width: '100%', marginLeft, position: 'relative' }}>
          <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 4 }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {buildingName && (
                <Typography className={classes.dialogTitle}>Building: {buildingName}</Typography>
              )}
              {floorName && (
                <Typography className={classes.dialogTitle} style={{ marginTop: 3 }}>
                  Floor: {floorName}
                </Typography>
              )}
              {locationName && (
                <Typography className={classes.dialogTitle} style={{ marginTop: 3 }}>
                  Location: {locationName}
                </Typography>
              )}
            </div>
            <div style={{ display: 'inline-flex', flexGrow: 100 }} />
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
              classes={{
                root: classes.rootIconButton,
              }}
              style={{ position: 'absolute', top: closeButtonTop, right: 6, zIndex: 1000 }}
            >
              <HighlightOffTwoTone htmlColor="#0947B9" fontSize="large" />
            </IconButton>
          </div>
          <Typography className={classes.dialogTitle} style={{ marginTop: 8, marginBottom: 2 }}>
            View Existing Punch List Items:
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
            <Button
              variant={filter === undefined ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setFilter(undefined)}
              className={classes.button}
              style={{ marginLeft: 0 }}
            >
              ALL
            </Button>
            <Button
              variant={filter === 'none' ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setFilter('none')}
              className={classes.button}
            >
              NONE
            </Button>
            <Button
              variant={filter === 'closed' ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setFilter('closed')}
              startIcon={
                !isMobile ? <PunchListFlagIcon width={12} height={17} color="green" /> : undefined
              }
              className={classes.button}
            >
              CLOSED
            </Button>
            <Button
              variant={filter === 'ready_for_verification' ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setFilter('ready_for_verification')}
              startIcon={
                !isMobile ? <PunchListFlagIcon width={12} height={17} color="blue" /> : undefined
              }
              className={classes.button}
            >
              READY
            </Button>
            <Button
              variant={filter === 'open' ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setFilter('open')}
              startIcon={
                !isMobile ? <PunchListFlagIcon width={12} height={17} color="red" /> : undefined
              }
              className={classes.button}
            >
              OPEN
            </Button>
          </div>
        </div>
        {!isLoading ? (
          <div ref={imageRef}>
            <ImageWithMarkers
              imageProps={{
                src: image,
                onLoad,
                width: imageLoaded ? imageWidth : undefined,
                style: { border: '2px solid #7A797A' },
              }}
              markers={markers.filter(({ status }) => {
                if (!status) return true;
                if (filter === 'none') return false;
                if (filter === 'closed') return status === PunchListStatusType.Accepted;
                if (filter === 'open')
                  return [PunchListStatusType.New, PunchListStatusType.InProgress].includes(status);
                if (filter === 'ready_for_verification')
                  return status === PunchListStatusType.ReadyForVerification;
                return true;
              })}
              regions={regions}
              regionOnClick={regionOnClick}
              onNavigation={onNavigation}
              containerTop={CONTENT_TOP_MARGINS}
              containerLeft={CONTENT_LEFT_MARGINS}
            />
          </div>
        ) : (
          <CircularLoader />
        )}
      </DialogContent>
    </Dialog>
  );
}
