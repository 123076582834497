import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, LinearProgress, MenuItem, Popover, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import {
  DocumentTemplateType,
  FileCategoryType,
  FileNode,
  IInsertionDocumentWithGraph,
} from '../../api-client/autogenerated';
import FileUploadDialog from './FileUploadDialog';
import { Clear, Publish } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import { documentTypeToUrl, generateUniqueId, getProjectUsers } from '../../scripts/utils';
import ManagePermissionsDialog, {
  ManagePermissionsDialogType,
} from '../design/ManagePermissionsDialog';
import InlineNotificationList from '../form-pages/user-groups/InlineNotificationList';
import Typography from '@material-ui/core/Typography';
import AssignedUserText from '../custom-components/AssignedUserText';
import { getCompaniesState } from '../../features/companies/selector';
import { getNavigationState } from '../../features/navigation/selectors';
import { CancelButton } from '../custom-components/CustomButtons';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import { getTemplateId } from '../../models/api/templates';
import { addDocumentToPackage, insertDocument } from '../../models/api/documents';
import {
  importGeneralDocumentFileFromDesign,
  uploadGeneralDocumentFile,
} from '../../models/api/filesystem';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';
import { reloadDocuments } from '../../features/documents/actions';
import { useHistory } from 'react-router-dom';
import { getDocumentsType } from '../../features/documents/selectors';
import { getUserState } from '../../features/user/selectors';

interface AddPackageDialogProps {
  dialogOpen: boolean;
  handleClose: () => void;
  anchor: any;
  selected: string[];
  isPlaceholder?: boolean;
}

const useStyles = makeStyles({
  popover: {
    '.rootElement > *': {
      maxHeight: '5000px',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '80px',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'uppercase',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  contentFlow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  section: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  checkbox: {
    padding: 3,
  },
  inputLabel: {
    marginTop: 12,
  },
  status: {
    marginBottom: 4,
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    paddingTop: 16,
  },
  calendar: {
    maxWidth: 225,
    paddingTop: 4,
    paddingBottom: 4,
  },
  dueDate: {
    paddingTop: 8,
  },
  button: {
    width: 115,
  },
  field: {
    marginTop: 8,
    marginBottom: 8,
    padding: 0,
  },
  selectList: {
    maxHeight: 400,
  },
});

export default function AddPackageDialog(props: AddPackageDialogProps) {
  const classes = useStyles();
  const { dialogOpen, handleClose, anchor, selected, isPlaceholder = false } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const selectedProject = useSelector(getProjectState);
  const docType = useSelector(getDocumentsType);
  const user = useSelector(getUserState);
  const companies = useSelector(getCompaniesState);
  const shouldBlockNavigation = useSelector(getNavigationState);

  const [inputPackageNumber, setInputPackageNumber] = useState<string>();
  const [inputDescription, setInputDescription] = useState<string>();
  const [inputArchitect, setInputArchitect] = useState(
    selectedProject?.defaultArchitectUserId || undefined,
  );
  const [inputContractor, setInputContractor] = useState(
    selectedProject?.defaultGeneralContractorUserId || undefined,
  );

  const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
  const [file, setFile] = useState<File | FileNode>();

  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [assignToType, setAssignToType] = useState('user');
  const [subcontractorEmail, setSubcontractorEmail] = useState<string>();

  const [managePermissionsDialogOpen, setManagePermissionsDialogOpen] = useState(false);
  const [associatedUsers, setAssociatedUsers] = useState<string[]>([]);
  const [associatedGroups, setAssociatedGroups] = useState<string[]>([]);

  const [editArchitect, setEditArchitect] = useState(false);
  const [editContractor, setEditContractor] = useState(false);
  const contractorUser = getProjectUsers(selectedProject, false).find(
    (pUser) => pUser.userId === inputContractor,
  )?.user;
  const architectUser = getProjectUsers(selectedProject, false).find(
    (pUser) => pUser.userId === inputArchitect,
  )?.user;

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const getPackageDocumentType = () => {
    if (docType === DocumentTemplateType.AsBuilt) return DocumentTemplateType.AsBuiltPackages;
    if (docType === DocumentTemplateType.CloseoutSubmittals)
      return DocumentTemplateType.CloseoutSubmittalPackages;
    return DocumentTemplateType.SubmittalPackages;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    if (!inputPackageNumber || !inputDescription) return;
    e.preventDefault();
    setIsLoading(true);
    const packageDocumentType = getPackageDocumentType();
    if (selected.length <= 0) {
      return;
    }
    try {
      dispatch(blockNavigation());
      const documentTemplateId = await getTemplateId(packageDocumentType);
      const document: IInsertionDocumentWithGraph = {
        documentTemplateId,
        creatorUserId: user.id,
        lastUpdatedByUserId: user.id,
        projectId: selectedProject!.id,
        referenceNumber: inputPackageNumber,
        title: inputDescription,
        architectUserId: inputArchitect,
        generalContractorUserId: inputContractor,
      };

      const newPackage = await insertDocument(document, {
        followerUserIds: associatedUsers,
        userGroupIds: associatedGroups,
        skipInitialStatus: !isPlaceholder,
      });

      if (file) {
        if ((file as FileNode).relativeKey !== undefined) {
          await importGeneralDocumentFileFromDesign(
            newPackage.id,
            FileCategoryType.DocumentGeneralContractorApproved,
            {
              ownerId: (file as FileNode).ownerId,
              fullKey: (file as FileNode).fullKey,
              fullFileName: (file as FileNode).relativeKey!,
              projectId: (file as FileNode).projectId,
            },
          );
        } else {
          await uploadGeneralDocumentFile(
            newPackage.id,
            {
              fullFileName: (file as File).name,
              fileType: FileCategoryType.DocumentGeneralContractorApproved,
              useMultiPartUpload: (file as File).size > MULTI_PART_FILE_SIZE,
            },
            file as File,
            handleUploadProgress,
          );
        }
      }

      await Promise.all(selected.map((docId) => addDocumentToPackage(docId, newPackage.id)));

      dispatch(allowNavigation());
      dispatch(reloadDocuments());
      history.push(
        `/main/projects/${newPackage.projectId}/documents/${documentTypeToUrl[docType!]}/${
          newPackage.id
        }`,
      );
    } finally {
      dispatch(allowNavigation());
      setIsLoading(false);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <Popover
        className={classes.popover}
        open={dialogOpen}
        onClose={() => {
          if (isLoading || shouldBlockNavigation) return;
          setEditArchitect(false);
          setEditContractor(false);
          handleClose();
        }}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <form className={classes.contentFlow} onSubmit={handleSubmit}>
          <Box className={classes.section}>
            <TextField
              variant="outlined"
              margin="none"
              size="small"
              fullWidth
              label="Package #"
              value={inputPackageNumber}
              className={classes.field}
              onChange={(e: any) => setInputPackageNumber(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="none"
              size="small"
              fullWidth
              multiline
              rows={5}
              label="Description"
              value={inputDescription}
              className={classes.field}
              onChange={(e: any) => setInputDescription(e.target.value)}
            />
            {/*<RadioGroup*/}
            {/*  row*/}
            {/*  value={assignToType}*/}
            {/*  onChange={(event, value) => setAssignToType(value)}*/}
            {/*  style={{ marginBottom: 0, paddingBottom: 0 }}*/}
            {/*>*/}
            {/*  <FormControlLabel value="user" control={<Radio color="primary" />} label="User" />*/}
            {/*  <FormControlLabel*/}
            {/*    value="subcontractor"*/}
            {/*    control={<Radio color="primary" />}*/}
            {/*    label="Subcontractor"*/}
            {/*  />*/}
            {/*</RadioGroup>*/}
            {assignToType === 'user' ? (
              <>
                {editContractor || !inputContractor ? (
                  <TextField
                    select
                    variant="outlined"
                    label="Assign Contractor"
                    fullWidth
                    value={inputContractor}
                    onChange={(e) => setInputContractor(e.target.value)}
                    style={{ marginBottom: 8, marginTop: 8 }}
                  >
                    {selectedProject ? (
                      getProjectUsers(selectedProject, false).map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.user!.id}>
                            <AssignedUserText
                              name={item.user!.name}
                              company={
                                item.user?.company?.name ||
                                companies.find((c) => c.id === item.user?.companyId)?.name
                              }
                            />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem value="">No selected project</MenuItem>
                    )}
                  </TextField>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography style={{ marginBottom: 8, marginTop: 8 }}>
                      <span style={{ fontWeight: 500 }}>Assigned Contractor:</span>{' '}
                      {contractorUser?.name}
                    </Typography>
                    <Button variant="text" color="primary" onClick={() => setEditContractor(true)}>
                      EDIT
                    </Button>
                  </div>
                )}
                {editArchitect || !inputArchitect ? (
                  <TextField
                    select
                    variant="outlined"
                    label="Assign Architect"
                    fullWidth
                    value={inputArchitect}
                    onChange={(e) => setInputArchitect(e.target.value)}
                    style={{ marginBottom: 8, marginTop: 8 }}
                  >
                    {selectedProject ? (
                      getProjectUsers(selectedProject, false).map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.userId}>
                            <AssignedUserText
                              name={item.user!.name}
                              company={
                                item.user?.company?.name ||
                                companies.find((c) => c.id === item.user?.companyId)?.name
                              }
                            />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem value="">No selected project</MenuItem>
                    )}
                  </TextField>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography style={{ marginBottom: 8, marginTop: 8 }}>
                      <span style={{ fontWeight: 500 }}>Assigned Architect:</span>{' '}
                      {architectUser?.name}
                    </Typography>
                    <Button variant="text" color="primary" onClick={() => setEditArchitect(true)}>
                      EDIT
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <TextField
                variant="outlined"
                margin="none"
                size="small"
                fullWidth
                id="project-number"
                label="Subcontractor's email"
                name="Title"
                value={subcontractorEmail}
                className={classes.field}
                onChange={(e: any) => setSubcontractorEmail(e.target.value)}
              />
            )}
            <div style={{ fontSize: '15px' }}>FILES: {!file && 'None'}</div>
            {file && (
              <div
                key={generateUniqueId()}
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '2px',
                }}
              >
                <div
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '80%',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {(file as File).name || (file as FileNode).relativeKey}
                </div>
                <Clear
                  style={{ cursor: 'pointer' }}
                  onClick={() => setFile(undefined)}
                  fill="#949494"
                />
              </div>
            )}
            <Button
              variant="outlined"
              color="primary"
              style={{ height: '32px', width: '100%', marginTop: 8 }}
              onClick={() => setFileUploadDialogOpen(true)}
              startIcon={<Publish fill="#2C69D6" />}
            >
              UPLOAD
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setManagePermissionsDialogOpen(true)}
              fullWidth
              style={{ height: '100%', marginTop: 12, marginBottom: 8, alignSelf: 'center' }}
            >
              Define Notification List
            </Button>
            <InlineNotificationList
              associatedUsers={associatedUsers}
              associatedGroups={associatedGroups}
            />
            {isLoading ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: 8,
                }}
              >
                <Typography>{uploadProgress}%</Typography>
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress}
                  style={{ height: 6, width: '100%' }}
                />
              </div>
            ) : (
              <div className={classes.inline}>
                <CancelButton
                  onClick={handleClose}
                  variant="outlined"
                  className={classes.button}
                  style={{ color: '#949494' }}
                >
                  Cancel
                </CancelButton>

                <Button
                  color="primary"
                  type="submit"
                  disabled={!inputDescription || !inputPackageNumber || (!isPlaceholder && !file)}
                  className={classes.button}
                  variant="contained"
                >
                  Create
                </Button>
              </div>
            )}
          </Box>
        </form>
      </Popover>
      <FileUploadDialog
        open={fileUploadDialogOpen}
        handleClose={() => setFileUploadDialogOpen(false)}
        title="Upload File"
        addFile={setFile}
        file={file}
        canSubmit={!!file}
        removeFile={() => setFile(undefined)}
        disableComments
      />
      <ManagePermissionsDialog
        dialogOpen={managePermissionsDialogOpen}
        closeDialog={() => {
          window.dispatchEvent(new CustomEvent('resize'));
          setManagePermissionsDialogOpen(false);
        }}
        type={ManagePermissionsDialogType.AssociatedUsers}
        associatedUsers={associatedUsers}
        associatedGroups={associatedGroups}
        setAssociatedUsers={setAssociatedUsers}
        setAssociatedGroups={setAssociatedGroups}
      />
    </MuiPickersUtilsProvider>
  );
}
