import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Close, Refresh } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import CommentSection from '../comment/CommentSection';
import { IComment } from '../../api-client/autogenerated';
import { getCommentsByProjectId, insertCommentByProjectId } from '../../models/api/comments';
import { getUserState } from '../../features/user/selectors';
import { getProjectState } from '../../features/project/selectors';

const useStyles = makeStyles({
  root: {
    background: '#FFFFFF',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  titleStyle: {
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
  },
  margins: {
    marginLeft: 9,
    marginTop: 3,
    height: '70%', // make buttons have same height
  },
  projectDirectory: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: 8,
  },
  refreshIcon: {
    padding: 0,
    width: '22px',
    height: '22px',
  },
  refreshIconSpin: {
    padding: 0,
    width: '22px',
    height: '22px',
    animation: 'spin 0.5s linear infinite',
  },
});

export default function DashboardMessageBoard() {
  const classes = useStyles();
  const history = useHistory();
  const project = useSelector(getProjectState)!;
  const user = useSelector(getUserState);

  const [isLoading, setIsLoading] = useState(true);
  const [isRotating, setIsRotating] = useState(false);
  const [comments, setComments] = useState<IComment[]>();
  const [inputComment, setInputComment] = useState('');

  const handleProjectDirectory = () => {
    history.push(`/main/projects/${project.id}/directory`);
  };

  const refreshComments = () => {
    setIsLoading(true);
    getCommentsByProjectId(project.id)
      .then((result) => setComments(result))
      .catch()
      .finally(() => setIsLoading(false));
  };

  const submitComment = () => {
    if (project && inputComment)
      insertCommentByProjectId(project.id, {
        creatorUserId: user.id,
        text: inputComment,
      })
        .then((newComment) => {
          setComments(comments ? [newComment, ...comments] : [newComment]);
          setInputComment('');
        })
        .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (project) refreshComments();
  }, [project]);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.titleStyle}>
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Grid item>
            <h2
              className="h2"
              style={{
                textAlign: 'left',
                color: '#FFFFFF',
                margin: 0,
              }}
            >
              Message Board
            </h2>
          </Grid>
          <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography className={classes.projectDirectory} onClick={handleProjectDirectory}>
              Project Directory
            </Typography>
            <IconButton
              onClick={() => {
                setIsRotating(true);
                setTimeout(() => setIsRotating(false), 500);
                refreshComments();
              }}
              className={isRotating ? classes.refreshIconSpin : classes.refreshIcon}
              style={{ padding: 0 }}
            >
              <Refresh htmlColor="#FFF" />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <CommentSection maxHeight={378} comments={comments} isLoading={isLoading} />
        <div
          style={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <TextField
            id="outlined-multiline-static"
            placeholder="Type here..."
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={inputComment}
            onChange={(event) => setInputComment(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') submitComment();
            }}
          />
          <Grid
            container
            justify="flex-end"
            alignItems="center"
            spacing={1}
            style={{ paddingTop: 5 }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="default"
                size="medium"
                type="reset"
                disableElevation
                onClick={() => setInputComment('')}
                className={classes.margins}
              >
                <Close />
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disableElevation
                onClick={submitComment}
                className={classes.margins}
              >
                Comment
              </Button>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
}
