import React from 'react';

const CloseOutIcon = (props: any) => (
  <svg
    width={24}
    height={21}
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.426 19.813c-.185.11-.388.191-.619.191H2.092c-.684 0-1.239-.56-1.239-1.25v-17.5c0-.69.555-1.25 1.239-1.25h3.715c.23 0 .434.081.619.192.185-.11.389-.192.62-.192h3.715c.684 0 1.238.56 1.238 1.25v.27c.157.092.191.193.235.322l.013.038c.633-.091 2.285-.654 2.922-1.032-.137-.396-.007-.63.243-.718l.146-.053c.142-.052.208-.077.299-.077.192 0 .372.121.439.314l6.825 17.516a.471.471 0 01-.285.596l-.292.104c-.242.085-.493-.003-.634-.409-.633.091-2.285.654-2.923 1.032.138.397.007.63-.242.718l-.147.053c-.142.052-.208.076-.298.076a.467.467 0 01-.44-.313L12 4.648v14.106c0 .69-.555 1.25-1.239 1.25H7.046c-.231 0-.435-.08-.62-.191zM2.092 3.754h3.715v-2.5H2.092v2.5zm8.67 0H7.045v-2.5h3.715v2.5zm1.849-.825l6.013 15.182c.445-.238 1.946-.846 2.922-1.032L15.533 1.897c-.445.238-1.946.847-2.922 1.032zM5.807 15.004H2.092v-10h3.715v10zm1.239 0h3.715v-10H7.046v10zm-1.239 3.75H2.092v-2.5h3.715v2.5zm1.239 0h3.715v-2.5H7.046v2.5z"
      fill="#73A3FF"
    />
  </svg>
);

export default CloseOutIcon;
