import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MenuItem, Select, Tooltip } from '@material-ui/core';
import {
  ActionTakenType,
  DocumentTemplateType,
  IDocument,
  INumberedDocumentView,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import {
  documentTypeToUrl,
  formatDateUpcoming,
  getSubmittalTitle,
  getUserFacingDocumentStatus,
} from '../../scripts/utils';
import CircularLoader from '../loader/CircularLoader';
import { useHistory } from 'react-router-dom';
import { getTemplateIds } from '../../models/api/templates';
import { Dictionary } from 'lodash';
import { Info } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    upcomingTable: {
      // height: '100%',
      marginTop: '15px',
      borderBottom: '1px solid #EDECEC',
    },
    tableCell: {
      whiteSpace: 'nowrap',
      border: 'none',
    },
    projectDueDate: {
      fontFamily: 'Roboto',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '21px',
      color: '#464546', // Gray / Gray 700
      textTransform: 'capitalize',
      whiteSpace: 'pre',
      textAlign: 'center',
    },
    projectInfo: {
      fontFamily: 'Roboto',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '21px',
      color: '#464546', // Gray / Gray 700
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
    },
    tablePagination: {
      display: 'flex',
      justifyContent: 'center',
    },
    pageSelector: {
      position: 'absolute',
      width: '100%',
      justifyContent: 'center',
      [theme.breakpoints.down(700)]: {
        width: 'auto',
        position: 'static',
        flexDirection: 'column ',
      },
    },
    pageOptions: {
      marginBottom: '40px',
      marginTop: '8px',
      [theme.breakpoints.down(700)]: {
        display: 'flex',
        flexDirection: 'row ',
        justifyContent: 'end',
      },
      [theme.breakpoints.down(495)]: {
        display: 'flex',
        flexDirection: 'column ',
      },
    },
  }),
);

interface DashboardUpcomingListProps {
  soonestDueDocuments: INumberedDocumentView[];
  upcomingType: string;
  loading: boolean;
}

export default function DashboardUpcomingList(props: DashboardUpcomingListProps) {
  const classes = useStyles();
  const { soonestDueDocuments, upcomingType, loading } = props;
  const history = useHistory();

  const currentProject = useSelector(getProjectState);

  const [templates, setTemplates] = useState<undefined | Dictionary<string>>(undefined);

  useEffect(() => {
    getTemplateIds().then((res) => {
      setTemplates(res);
    });
  }, []);

  const getColor = (doc: INumberedDocumentView) => {
    if (doc.workflowStatus === WorkflowStatusType.Completed) {
      switch (doc.actionTaken) {
        case ActionTakenType.ReviseAndResubmit:
        case ActionTakenType.SubmitSpecifiedItem:
        case ActionTakenType.AmendAsNoted:
        case ActionTakenType.SeeSubmittalComments:
        case ActionTakenType.SeeTransmittalComments:
          return '#ED3F26';
        case ActionTakenType.NoExceptionsTaken:
        default:
          return '#128750';
      }
    }

    return '#464546';
  };

  const [page, setPage] = useState(0);
  const [rowsLength, setRowsLength] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const handlePaginationChange = (event: any) => {
    setRowsLength(event.target.value);
    setPage(0);
  };

  const getDocType = (doc: INumberedDocumentView) => {
    if (!doc.additionalReviewRecommendedById) {
      return doc.documentTemplate?.name || 'Type';
    }

    if (templates && doc.additionalReviewForDocument?.documentTemplateId) {
      return `Consultant Review - ${templates[doc.additionalReviewForDocument.documentTemplateId]}`;
    }

    return 'Consultant Review';
  };

  const getDocNumber = (doc: IDocument): string => {
    const TRUNCATE_LENGTH = soonestDueDocuments.some((doc) => doc.additionalReviewForDocumentId)
      ? 70
      : 94;
    if (doc.submittalSection) return getSubmittalTitle(doc, true);
    if (doc.additionalReviewForDocument) return getDocNumber(doc.additionalReviewForDocument);
    let number = `${doc.referenceNumber || doc.documentNumber?.toString()}${
      doc.revisionNumber ? `-R${doc.revisionNumber}` : ''
    }`;
    if (
      doc.documentTemplate?.name === DocumentTemplateType.SubmittalPackages ||
      doc.documentTemplate?.name === DocumentTemplateType.CloseoutSubmittalPackages
    )
      number += ` — ${
        doc.title
          ? `${
              doc.title.length > TRUNCATE_LENGTH
                ? doc.title.substring(0, TRUNCATE_LENGTH + 3) + '...'
                : doc.title
            }`
          : ''
      }`;
    if (doc.documentTemplate?.name === DocumentTemplateType.RequestsForInformation && doc.title)
      number += ` — ${doc.title}`;
    if (doc.documentTemplate?.name === DocumentTemplateType.PunchList && doc.description)
      number =
        doc.description.length > TRUNCATE_LENGTH
          ? doc.description.substring(0, TRUNCATE_LENGTH + 3) + '...'
          : doc.description;
    if (doc.documentTemplate?.name === DocumentTemplateType.AsBuilt && doc.sheetNumber) {
      number = `${doc.sheetNumber} ${doc.title}`;
    }
    return number || 'N/A';
  };

  const handleClick = (doc: INumberedDocumentView) => {
    let link = '';
    if (doc.documentTemplate?.name && doc.projectId) {
      if (doc.documentTemplate.name !== DocumentTemplateType.AdditionalReview && doc.id) {
        link = `/main/projects/${doc.projectId}/documents/${
          documentTypeToUrl[doc.documentTemplate.name as DocumentTemplateType]
        }/${doc.id}`;
        if (doc.documentTemplate.name === DocumentTemplateType.PunchList) {
          link += '/edit';
        }
      } else if (doc.additionalReviewForDocument && doc.additionalReviewForDocumentId)
        link = `/main/projects/${doc.projectId}/documents/${
          documentTypeToUrl[
            templates![doc.additionalReviewForDocument.documentTemplateId] as DocumentTemplateType
          ]
        }/${doc.additionalReviewForDocumentId}`;
      history.push(link);
    }
  };

  const mapDocs = (pageNum: number, rowsLength: number) => {
    if (soonestDueDocuments) {
      if (soonestDueDocuments.length > pageNum * rowsLength) {
        const docsOnPage = soonestDueDocuments.slice(
          pageNum * rowsLength,
          Math.min(pageNum * rowsLength + rowsLength, soonestDueDocuments.length),
        );
        return docsOnPage.map((doc) => {
          return (
            <TableRow key={doc.id} onClick={() => handleClick(doc)} style={{ cursor: 'pointer' }}>
              <TableCell className={classes.projectDueDate}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  {formatDateUpcoming(doc.dueDate ? doc.dueDate : '')}
                </div>
              </TableCell>
              <TableCell className={classes.projectInfo}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {getDocType(doc)}
                  {doc.documentTemplate?.name === DocumentTemplateType.PunchList &&
                    doc.customContractor && (
                      <Tooltip
                        arrow
                        placement="top"
                        title={`Assigned to Subcontractor: ${doc.customContractor}`}
                      >
                        <Info style={{ marginLeft: 4 }} />
                      </Tooltip>
                    )}
                </div>
              </TableCell>
              <TableCell
                className={classes.projectInfo}
                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {getDocNumber(doc)}
              </TableCell>
              <TableCell className={classes.projectInfo} style={{ color: getColor(doc) }}>
                {getUserFacingDocumentStatus(doc)}
              </TableCell>
              {!currentProject ? (
                <TableCell className={classes.projectInfo}>{doc.project?.name}</TableCell>
              ) : null}
            </TableRow>
          );
        });
      }
    }
    return <div />;
  };

  const displayText = () => {
    if (soonestDueDocuments.length === 0 && upcomingType === 'assigned-to-me')
      return (
        <h2 style={{ fontSize: '1rem', paddingTop: '5px' }}>
          No upcoming documents assigned to you.
        </h2>
      );
    if (soonestDueDocuments.length === 0 && upcomingType === 'assigned-by-me')
      return (
        <h2 style={{ fontSize: '1rem', paddingTop: '5px' }}>
          No upcoming documents assigned by you to others.
        </h2>
      );
    if (soonestDueDocuments.length === 0 && upcomingType === 'all')
      return (
        <h2 style={{ fontSize: '1rem', paddingTop: '5px' }}>
          No upcoming documents in this project.
        </h2>
      );
  };

  const displayTable = () => {
    return (
      <>
        <TableContainer style={{ height: 360, overflowY: 'auto' }}>
          <Table className={classes.upcomingTable}>
            <TableHead>
              <TableRow style={{ borderTop: '1px solid #EDECEC' }}>
                <TableCell className={classes.tableCell}>Due</TableCell>
                <TableCell className={classes.tableCell}>Type</TableCell>
                <TableCell className={classes.tableCell} style={{ width: '99%' }}>
                  Item
                </TableCell>
                <TableCell className={classes.tableCell}>Status</TableCell>
                {!currentProject ? (
                  <TableCell className={classes.tableCell}>Project</TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>{mapDocs(page, rowsLength)}</TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  useEffect(() => {
    setPage(0);
  }, [soonestDueDocuments]);

  return (
    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
      {loading && (
        <div style={{ paddingTop: '50px', paddingBottom: '50px' }}>
          <CircularLoader />
        </div>
      )}
      {!loading && <div>{soonestDueDocuments.length > 0 ? displayTable() : displayText()}</div>}
      <div className={classes.pageOptions}>
        <div className={classes.pageSelector}>
          <div style={{ position: 'revert' }}>
            {Math.ceil(soonestDueDocuments.length / rowsLength) > 1 && (
              <Pagination
                count={Math.ceil(soonestDueDocuments.length / rowsLength)}
                shape="rounded"
                color="primary"
                showFirstButton
                showLastButton
                page={page + 1}
                onChange={handleChangePage}
                className={classes.tablePagination}
              />
            )}
          </div>
        </div>
        <div style={{ marginBottom: '40px', marginTop: '8px' }}>
          <Select
            labelId="rowLength-select-label"
            id="rowLength-select"
            value={rowsLength}
            onChange={handlePaginationChange}
            style={{
              margin: '0px 10px',
              background: 'transparent',
              float: 'right',
            }}
            SelectDisplayProps={{ style: { background: 'transparent' } }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
          </Select>
          <p
            style={{
              lineHeight: '0',
              float: 'right',
              marginTop: '18px',
            }}
          >
            Rows per page
          </p>
        </div>
      </div>
    </div>
  );
}
