import React from 'react';

interface BluebeamIconProps {
  width?: number;
  height?: number;
}

const BluebeamIcon = (props: BluebeamIconProps | any) => {
  const { width = 23, height = 23 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.7449 0V3.71717H15.7245H19.4796V7.43434V15.3333V19.0505H15.7245H7.7449H3.9898V15.3333V7.43434V3.71717V0H0V23H23V0H7.7449Z M15.7247 7.43457H7.74512V15.3336H15.7247V7.43457Z"
        fill="#7A797A"
        {...props}
      />
    </svg>
  );
};

export default BluebeamIcon;
