import React, { useEffect, useState } from 'react';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import {
  Card,
  CardContent,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  FilledInput,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
  Theme,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { toggleState } from '../../scripts/utils';
import { IBidSetup, IProjectUser, PhoneNumberType } from '../../api-client/autogenerated';
import { getUserState } from '../../features/user/selectors';
import { getCompanyById } from '../../models/api/companies';
import { postPrimeBidder } from '../../models/api/prime-bidder';
import { FieldWidth, gray300, gray400, gray50, gray700 } from '../BiddingPortalTheme';
import { insertPhoneNumberByUserId } from '../../models/api/phone-number';
import { TextMaskCustom } from '../../main-components/custom-components/TextMaskCustom';
import { getPrimeBiddersByProject } from '../../models/api/project';
import CircularLoader from '../../main-components/loader/CircularLoader';
import { HighlightOffRounded } from '@material-ui/icons';
import { addSnackbar } from '../../features/snackbar/actions';
import PrimeBidderRow from './PrimeBidderRow';
import { Pagination } from '@material-ui/lab';

type Props = {
  bidSetup?: IBidSetup;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    content: {
      // flexGrow: 1,
      // padding: theme.spacing(3),
      // minHeight: '100vh',
      display: 'flex',
      padding: 16,
      justifyContent: 'flex-end',
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      height: '100%',
      background: '#fff',
    },
    titleStyle: {
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
      fontSize: 26,
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '-16px',
    },
    rootIconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '30px',
      textAlign: 'left',
      color: '#0947B9',
      marginBottom: '8px',
    },
    filledInputRoot: {
      height: '34px',
      width: '216px',
      border: 'none',
      '& .Mui-disabled': {
        background: gray400,
      },
    },
    filledInputInput: {
      background: gray50,
      padding: '8px 0px 8px 8px',
      border: `1px solid ${gray400}`,
      borderRadius: '5px',
      textAlign: 'start',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '15px',
      lineHeight: '18px',
      color: gray700,
      '&::placeholder': {
        fontStyle: 'italic',
        color: gray300,
        opacity: 1,
      },
    },
    filledInputMultiline: {
      height: 'auto',
      width: 'auto',
      padding: 0,
    },
    textFieldLabel: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '12px',
      lineHeight: '26px',
      color: gray400,
      textTransform: 'uppercase',
    },
    maxWidth: {
      maxWidth: '370px',
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

const PrimeBidders: React.FC<Props> = (props) => {
  const [addPrimeBidderVisible, setAddPrimeBidderVisible] = useState(false);
  const toggleVisible = () => toggleState(addPrimeBidderVisible, setAddPrimeBidderVisible);
  const user = useSelector(getUserState);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { bidSetup } = props;

  const [primeBidders, setPrimeBidders] = useState<IProjectUser[]>([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [inputCompanyName, setInputCompanyName] = useState<string>();
  const [inputContactName, setInputContactName] = useState<string>();
  const [inputEmail, setInputEmail] = useState<string>();
  const [inputPhone, setInputPhone] = useState<string>();
  const [inputLicense, setInputLicense] = useState<string>();
  const [inputAlternateName, setInputAlternateName] = useState<string>();
  const [inputAlternateEmail, setInputAlternateEmail] = useState<string>();
  const [inputAlternatePhone, setInputAlternatePhone] = useState<string>();

  const [page, setPage] = useState(0);
  const handleChange = (event: any, value: number) => {
    setPage(value - 1);
  };

  const formIsValid = () =>
    !!inputCompanyName &&
    !!inputLicense &&
    !!inputContactName &&
    !!inputEmail &&
    !!inputPhone &&
    !!inputLicense;

  const fetchPrimeBidders = async () =>
    getPrimeBiddersByProject(bidSetup!.project!.id!).then((response) => {
      setPrimeBidders(
        // @ts-ignore
        response.results,
      );
    });

  const handleSubmit = async () => {
    if (formIsValid()) {
      setIsLoading(true);
      const { id: phoneNumberId } = await insertPhoneNumberByUserId(user.id, {
        number: inputPhone!.replace(/[-()\s]/g, ''),
        type: PhoneNumberType.Mobile,
        countryId: '',
      });
      await postPrimeBidder(user.id, bidSetup!.project!.id!, {
        companyName: inputCompanyName,
        contactName: inputContactName,
        email: inputEmail,
        phoneNumberId,
        contractorLicenseNo: inputLicense!,
        alternateName: inputAlternateName,
        alternateEmail: inputAlternateEmail,
        alternatePhoneNumber: inputAlternatePhone,
      }).catch((e) => {
        console.log(`Error adding to prime bidder: ${e}`);
        setIsLoading(false);
      });
      fetchPrimeBidders()
        .then(() => {
          toggleVisible();
          setSnackOpen(true);
        })
        .catch((e) => console.log(e))
        .finally(() => setIsLoading(false));
    } else
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Please fill in all required fields',
          severity: 'error',
        }),
      );
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    if (bidSetup) {
      fetchPrimeBidders().finally(() => setIsLoading(false));
    }
    if (user.companyId) {
      getCompanyById(user.companyId)
        .then((result) => {
          setInputCompanyName(result.name ? result.name : '');
        })
        .catch((e) => console.error(e));
    } else {
      setInputCompanyName(user.publicCompanyName || '');
    }
    setInputContactName(user.name);
    setInputEmail(user.email);
    setInputPhone(user.phoneNumbers && user.phoneNumbers[0] ? user.phoneNumbers[0].number : '');
  }, [user, bidSetup]);

  return (
    <>
      <Card style={{ height: '100%', display: 'flex', minHeight: 450, flexDirection: 'column' }}>
        <CardContent className={classes.titleStyle}>
          <h2
            className="h2"
            style={{
              textAlign: 'left',
              color: '#FFFFFF',
              margin: 0,
              lineHeight: 1,
              fontSize: 26,
            }}
          >
            Prime Bidders
          </h2>
        </CardContent>
        <CardContent style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <div>
            <Button
              type="button"
              tabIndex={0}
              onKeyDown={toggleVisible}
              onClick={toggleVisible}
              color="primary"
              variant="contained"
              startIcon={<Add />}
              style={{
                marginBottom: 24,
                marginTop: 12,
                width: 'auto',
                paddingLeft: 16,
                paddingRight: 16,
              }}
            >
              Add me as a prime bidder
            </Button>
          </div>
          {isLoading ? (
            <div style={{ position: 'relative', top: '25%' }}>
              <CircularLoader />
            </div>
          ) : (
            <TableContainer>
              <Table>
                <TableHead style={{ color: 'rgb(97, 96, 97)', width: '100%' }}>
                  <TableCell variant="head">License #</TableCell>
                  <TableCell variant="head">Company</TableCell>
                  <TableCell variant="head">Contact</TableCell>
                  <TableCell variant="head"></TableCell>
                </TableHead>
                <TableBody>
                  {primeBidders
                    .slice(page * 5, Math.min(primeBidders.length, page * 5 + 5))
                    .map((row) => (
                      <PrimeBidderRow key={row.id} data={row} setPrimeBidders={setPrimeBidders} />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <div style={{ flexGrow: 1 }} />
          {Math.ceil(primeBidders.length / 5) > 1 && (
            <Pagination
              count={Math.ceil(primeBidders.length / 5)}
              page={page + 1}
              style={{ paddingTop: 32 }}
              classes={{ ul: classes.center }}
              onChange={handleChange}
            />
          )}
        </CardContent>
      </Card>
      <Dialog
        open={addPrimeBidderVisible}
        onClose={() => setAddPrimeBidderVisible(false)}
        maxWidth={false}
        id="userDetails"
        className="modal"
        classes={{ paperWidthFalse: classes.maxWidth }}
      >
        <DialogTitle>
          <div className={classes.titleContainer}>
            <span className={classes.title}>Add Prime Bidder</span>
          </div>
          <IconButton
            style={{ right: '12px', top: '12px', position: 'absolute' }}
            onClick={() => setAddPrimeBidderVisible(false)}
            classes={{
              root: classes.rootIconButton,
            }}
          >
            <HighlightOffRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ minWidth: 340 }}>
          <form>
            <div className="modal-body">
              {isLoading ? (
                <CircularLoader />
              ) : (
                <>
                  <div className="form-group row required">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <h3>Prime Bidder Information (REQUIRED)</h3>
                    </div>
                    <label htmlFor="first_name" className={classes.textFieldLabel}>
                      Company Name
                    </label>
                    <div className="col-8">
                      <TextField
                        value={inputCompanyName}
                        variant="filled"
                        InputProps={{
                          style: { width: FieldWidth.Long },
                          classes: {
                            root: classes.filledInputRoot,
                            multiline: classes.filledInputMultiline,
                            input: classes.filledInputInput,
                          },
                          disableUnderline: true,
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row required">
                    <label htmlFor="email" className={classes.textFieldLabel}>
                      Contact Name
                    </label>
                    <div className="col-8">
                      <TextField
                        disabled={!!user.name}
                        value={inputContactName}
                        variant="filled"
                        InputProps={{
                          style: { width: FieldWidth.Long },
                          classes: {
                            root: classes.filledInputRoot,
                            multiline: classes.filledInputMultiline,
                            input: classes.filledInputInput,
                          },
                          disableUnderline: true,
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row required">
                    <label htmlFor="email" className={classes.textFieldLabel}>
                      Email
                    </label>
                    <div className="col-8">
                      <TextField
                        disabled={!!user.email}
                        value={inputEmail}
                        variant="filled"
                        InputProps={{
                          style: { width: FieldWidth.Long },
                          classes: {
                            root: classes.filledInputRoot,
                            multiline: classes.filledInputMultiline,
                            input: classes.filledInputInput,
                          },
                          disableUnderline: true,
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row required">
                    <label htmlFor="email" className={classes.textFieldLabel}>
                      Phone
                    </label>
                    <div className="col-8">
                      <FilledInput
                        value={inputPhone}
                        inputComponent={TextMaskCustom as any}
                        onChange={(event) => setInputPhone(event.target.value)}
                        classes={{
                          root: classes.filledInputRoot,
                          multiline: classes.filledInputMultiline,
                          input: classes.filledInputInput,
                        }}
                        disableUnderline
                        style={{ width: FieldWidth.Long }}
                      />
                    </div>
                  </div>
                  <div className="form-group row required">
                    <label htmlFor="first_name" className={classes.textFieldLabel}>
                      Contractor License #
                    </label>
                    <div className="col-8">
                      <TextField
                        value={inputLicense}
                        onChange={(event) => setInputLicense(event.target.value)}
                        variant="filled"
                        InputProps={{
                          style: { width: FieldWidth.Long },
                          classes: {
                            root: classes.filledInputRoot,
                            multiline: classes.filledInputMultiline,
                            input: classes.filledInputInput,
                          },
                          disableUnderline: true,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <h3>Alternate Contact Information for Subcontractor Communication</h3>
                  </div>
                  <div className="form-group row required">
                    <label htmlFor="email" className={classes.textFieldLabel}>
                      Name
                    </label>
                    <div className="col-8">
                      <TextField
                        value={inputAlternateName}
                        onChange={(event) => setInputAlternateName(event.target.value)}
                        variant="filled"
                        InputProps={{
                          style: { width: FieldWidth.Long },
                          classes: {
                            root: classes.filledInputRoot,
                            multiline: classes.filledInputMultiline,
                            input: classes.filledInputInput,
                          },
                          disableUnderline: true,
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row required">
                    <label htmlFor="email" className={classes.textFieldLabel}>
                      Email
                    </label>
                    <div className="col-8">
                      <TextField
                        value={inputAlternateEmail}
                        onChange={(event) => setInputAlternateEmail(event.target.value)}
                        variant="filled"
                        InputProps={{
                          style: { width: FieldWidth.Long },
                          classes: {
                            root: classes.filledInputRoot,
                            multiline: classes.filledInputMultiline,
                            input: classes.filledInputInput,
                          },
                          disableUnderline: true,
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row required">
                    <label htmlFor="email" className={classes.textFieldLabel}>
                      Phone
                    </label>
                    <div className="col-8">
                      <FilledInput
                        value={inputAlternatePhone}
                        inputComponent={TextMaskCustom as any}
                        onChange={(event) => setInputAlternatePhone(event.target.value)}
                        classes={{
                          root: classes.filledInputRoot,
                          multiline: classes.filledInputMultiline,
                          input: classes.filledInputInput,
                        }}
                        disableUnderline
                        style={{ width: FieldWidth.Long }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <div style={{ display: 'flex', justifyContent: 'space-evenly', paddingTop: 12 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  tabIndex={-1}
                  onKeyDown={toggleVisible}
                  onClick={toggleVisible}
                  style={{ background: '#F28B00', marginRight: '6px', height: 32 }}
                >
                  Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={handleSubmit}>
                  Add
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackOpen}
        autoHideDuration={5000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackOpen(false)} severity="success">
          Successfully added you to prime bidders!
        </Alert>
      </Snackbar>
    </>
  );
};

export default PrimeBidders;
