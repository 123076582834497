import { GreenToggleButton, RedToggleButton } from './CustomButtons';
import { WorkflowStatusType } from '../../api-client/autogenerated';
import { ToggleButtonGroup } from '@material-ui/lab';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentState } from '../../features/document/selectors';
import {
  completeReviewByDocumentId,
  modifyDocumentById,
  modifyDocumentByIdWithResponse,
} from '../../models/api/documents';
import { updateDocument } from '../../features/document/actions';
import ReviewCommentDialog from '../dialogs/ReviewCommentDialog';

export default function ToggleReview() {
  const dispatch = useDispatch();
  const document = useSelector(getDocumentState);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleReview = async (event: React.MouseEvent<HTMLElement>, value: WorkflowStatusType) => {
    if (!document) return;

    if (
      value === WorkflowStatusType.UnderReview &&
      document.workflowStatus !== WorkflowStatusType.UnderReview
    ) {
      const { updatedDocument } = await modifyDocumentByIdWithResponse(document.id, {
        patch: {
          workflowStatus: value,
        },
      });
      dispatch(updateDocument({ ...document, workflowStatus: updatedDocument.workflowStatus }));
    } else if (
      value === WorkflowStatusType.Completed &&
      document.workflowStatus !== WorkflowStatusType.Completed
    ) {
      setDialogOpen(true);
    }
  };

  const handleSubmitResponse = async (reviewComment: string, willPublishResponse: boolean) => {
    if (!document) return;
    try {
      setIsLoading(true);
      await modifyDocumentById(document.id, {
        patch: {
          reviewComment: reviewComment || undefined,
        },
      });
      const response = await completeReviewByDocumentId(document.id, { willPublishResponse });
      dispatch(updateDocument({ ...response.document }));
    } finally {
      setIsLoading(false);
      setDialogOpen(false);
    }
  };

  return (
    <>
      <ToggleButtonGroup
        exclusive
        value={document?.workflowStatus}
        onChange={toggleReview}
        size="small"
      >
        <RedToggleButton value={WorkflowStatusType.UnderReview}>Under Review</RedToggleButton>
        <GreenToggleButton value={WorkflowStatusType.Completed}>Review Complete</GreenToggleButton>
      </ToggleButtonGroup>
      <ReviewCommentDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSubmit={handleSubmitResponse}
        isLoading={isLoading}
      />
    </>
  );
}
