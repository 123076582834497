import React, { MutableRefObject, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import './calendar/sass/styles.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  DocumentTemplateType,
  INumberedDocumentView,
  IProjectCalendarEvent,
} from '../../api-client/autogenerated';
import CircularLoader from '../loader/CircularLoader';
import {
  documentTypeToUrl,
  getUserFacingDocumentStatus,
  getUserFriendlyDocumentTemplateNameSingular,
  parseDate,
} from '../../scripts/utils';
import { addSpacing } from '../document-index/DocumentIndexUtils';
import IconButton from '@material-ui/core/IconButton';
import { HighlightOffRounded } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProjectId } from '../../features/project/selectors';
import { getAllProjectCalendarEvents } from '../../models/api/calendar';
import dayjs from 'dayjs';
import { ICalCalendar } from 'ical-generator';

interface DashboardUpcomingCalendarProps {
  soonestDueDocuments: INumberedDocumentView[];
  loading: boolean;
  calendarRef: MutableRefObject<any>;
  iCalendar: MutableRefObject<ICalCalendar>;
}

interface EventType {
  id: string;
  projectId: string;
  title: string;
  start: Date;
  end: Date;
  allDay?: boolean;
  source?: string;
  company?: string;
  address?: string;
  displayType?: string;
  type?: string;
  parentId?: string;
  parentType?: string;
}

const localizer = momentLocalizer(moment);

const getTitleColor = (title: any) => {
  // this switch is copied from DashboardUpcomingList (sorry)
  // it will probably need to be updated, so hopefully that happens in both files!! :(( -trent
  switch (title) {
    case 'Approved':
      return '#128750';
    case 'Rejected':
      return '#ED3F26';
    default:
      return '#464546';
  }
};

const EventComponent = (properties: any) => (
  <div
    style={{
      background: '#EDECEC',
      color: '#464546',
      lineHeight: '17px',
    }}
  >
    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '15px' }}>
      {properties.event.address}
    </div>
    <div
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontWeight: 'bold',
        color: getTitleColor(properties.event.title),
      }}
    >
      {properties.event.title}
    </div>
    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 'bold' }}>
      {properties.event.source}
    </div>
    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{properties.event.company}</div>
  </div>
);

const useStyles = makeStyles({
  root: {
    fontFamily: 'Sans-Serif',
    font: 'Roboto',
    textTransform: 'capitalize',
    width: '100%',
    marginTop: 20,
    height: '100%',
  },

  calendarStyling: {
    fontSize: '13px',
    padding: '0 0px',
    width: '100%',
    margin: 'auto',
  },
});

const eventStyler = () => {
  return { style: { backgroundColor: '#EDECEC', borderLeft: '3px #4380ED solid' } };
};

const formats = {
  eventTimeRangeFormat: () => {
    return '';
  },
};

export default function DashboardUpcomingCalendar(props: DashboardUpcomingCalendarProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [currentState, setCurrentState] = useState('week');
  const [dialogueContent, setDialogueContent] = useState<EventType>({} as EventType);
  const [projectEvents, setProjectEvents] = useState<IProjectCalendarEvent[]>([]);
  const { soonestDueDocuments, loading, calendarRef, iCalendar } = props;
  const history = useHistory();
  const projectId = useSelector(getProjectId)!;
  const data: EventType[] = [];

  const handleClickOpen = (event: EventType) => {
    setDialogueContent(event);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getRedirectLink = (event: EventType) => {
    if (event.type !== 'N/A') {
      if (event.type !== DocumentTemplateType.AdditionalReview && event.type && event.id)
        return `/main/projects/${event.projectId}/documents/${
          documentTypeToUrl[event.type as DocumentTemplateType]
        }/${event.id}`;
      else if (event.parentId && event.parentType)
        return `/main/projects/${event.projectId}/documents/${
          documentTypeToUrl[event.parentType as DocumentTemplateType]
        }/${event.parentId}`;
    }
  };

  useEffect(() => {
    if (projectId) getAllProjectCalendarEvents(projectId).then(setProjectEvents);
  }, [projectId]);

  soonestDueDocuments.forEach((document) => {
    if (!document.dueDate) return;
    const dueDate = parseDate(document.dueDate).toDate();

    const newCalendarEvent: EventType = {
      id: document.id,
      projectId: document.projectId,
      title: getUserFacingDocumentStatus(document),
      start: dueDate,
      end: dueDate,
      allDay: true,
      source: document.submittalSection
        ? addSpacing(document.submittalSection)
        : document.documentNumber!.toString(),
      company: document.project?.name,
      address: '', // no address yet
      displayType: document.documentTemplate?.name
        ? getUserFriendlyDocumentTemplateNameSingular(
            document.documentTemplate.name as DocumentTemplateType,
          )
        : 'N/A',
      type: document.documentTemplate?.name, // no type yet
      parentId: document.additionalReviewForDocumentId || undefined,
      parentType: document.additionalReviewForDocument?.documentTemplate?.name,
    };

    const link = getRedirectLink(newCalendarEvent);

    iCalendar.current.createEvent({
      id: document.id,
      start: dueDate,
      end: dueDate,
      allDay: true,
      summary: `${newCalendarEvent.parentType || newCalendarEvent.type} ${newCalendarEvent.source}`,
      description: `Project: ${document.project?.name || 'Unknown'}`,
      url: link ? `https://centerline.co${link}` : undefined,
    });

    data.push(newCalendarEvent);
  });

  projectEvents.forEach(({ id, name, startTimestamp, endTimestamp }) => {
    const start = parseDate(startTimestamp).toDate();
    const end = parseDate(endTimestamp).toDate();
    const newEvent: EventType = {
      id,
      projectId,
      title: name,
      start,
      end,
    };

    iCalendar.current.createEvent(newEvent);

    data.push(newEvent);
  });

  return (
    <div className={classes.root}>
      {loading && <CircularLoader />}

      {!loading && (
        <div>
          <div
            className={classes.calendarStyling}
            style={{ height: currentState === 'month' ? 'calc(100vh - 100px)' : '355.5px' }}
          >
            <Calendar
              ref={calendarRef}
              formats={formats}
              events={data}
              step={30}
              showMultiDayTimes
              defaultDate={dayjs().toDate()}
              defaultView="week"
              components={{
                event: EventComponent,
              }}
              popup
              localizer={localizer}
              eventPropGetter={eventStyler}
              onSelectEvent={(event) => {
                handleClickOpen(event);
              }}
              onView={(view) => {
                setCurrentState(view);
              }}
              views={{ week: true, agenda: true }}
            />
          </div>
          <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle>
              <IconButton
                onClick={handleClose}
                style={{ right: '6px', top: '6px', position: 'absolute' }}
              >
                <HighlightOffRounded />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ width: '99%', marginTop: '4px' }}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Project</TableCell>
                      <TableCell align="center">Type</TableCell>
                      <TableCell align="center">Item</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell scope="row" align="center">
                        {/* the data on the object is named poorly, sorry!!!
                        will clean up when the backend is more solid -trent */}
                        {/* company = project */}
                        {`${(dialogueContent as EventType).company}`}
                      </TableCell>
                      <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>{`${
                        (dialogueContent as EventType).displayType
                      }`}</TableCell>
                      <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                        {/* source = item */}
                        {`${(dialogueContent as EventType).source}`}
                      </TableCell>
                      <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                        {/* title = status */}
                        {`${(dialogueContent as EventType).title}`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-evenly' }}>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="contained"
                style={{ height: '32px', width: '94px' }}
              >
                Close
              </Button>
              <Button
                onClick={() => {
                  const link = getRedirectLink(dialogueContent as EventType);
                  if (link) history.push(link);
                }}
                autoFocus
                color="primary"
                variant="contained"
              >
                View
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
}
