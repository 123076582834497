import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Card, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useSelector } from 'react-redux';
import SplashImage from '../assets/images/bidding_login_background.jpg';
import CenterlineIcon from '../images/CenterlineLogo.png';
import '../main-components/sign-in/PortalLogin.scss';
import { getUserState } from '../features/user/selectors';
import SignIn from '../main-components/sign-in/sign-in';
import ForgotPassword from '../main-components/sign-in/forgot-password';
import useMediaQueries from '../main-components/custom-components/useMediaQueries';
import SendFilesDialog from './send-files/SendFilesDialog';

const useStyles = makeStyles({
  // body CSS background is overriding this
  root: {
    width: '100%',
    height: '100vh',
    background: `url(${SplashImage})`,
    backgroundSize: 'cover',
  },
  card: {
    display: 'flex',
    background: 'white',
    width: '375px',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '0px',
    left: '0px',
  },

  lineCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  login: {
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  avatar: {
    margin: 8,
  },
  projManagementLoginButton: {
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#0947B9',
    '&:hover': {
      color: '#4380ED',
    },
    textTransform: 'uppercase',
  },
  textContainer: {
    position: 'absolute',
    left: 450,
    top: '22vh',
  },
  splashText: {
    color: 'white',
    fontSize: 64,
    lineHeight: '64px',
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },
  splashTextSmall: {
    color: 'white',
    fontSize: 26,
    lineHeight: '26px',
    fontWeight: 300,
    whiteSpace: 'nowrap',
  },
});

function BiddingPortalLogin() {
  const classes = useStyles();

  const history = useHistory();
  const user = useSelector(getUserState);

  const { isXs } = useMediaQueries();

  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>();
  const [isLettermansLogin, setIsLettermansLogin] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (user.id) history.push('/bidding');
  }, [user]);

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.paper}>
          <div className={classes.login}>
            <img
              src={CenterlineIcon}
              alt="centerlinelogo"
              style={{ width: '350px', paddingBottom: '20px' }}
            />
            {/*<Avatar className={classes.avatar}>*/}
            {/*  <LockOutlinedIcon />*/}
            {/*</Avatar>*/}
            {!forgotPassword ? (
              <SignIn
                isBidding
                setForgotPassword={setForgotPassword}
                setEmail={setForgotPasswordEmail}
                setIsLettermansLogin={setIsLettermansLogin}
              />
            ) : (
              <ForgotPassword
                existingEmail={forgotPasswordEmail}
                setForgotPassword={setForgotPassword}
                isLettermansLogin={isLettermansLogin}
              />
            )}
            {!forgotPassword && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setDialogOpen(true)}
                style={{ marginTop: 48 }}
              >
                Send Us Files
              </Button>
            )}
          </div>
        </div>
        {/*<Button*/}
        {/*  startIcon={<ArrowBack />}*/}
        {/*  className={classes.projManagementLoginButton}*/}
        {/*  onClick={() => history.push('/main/login')}*/}
        {/*  style={{ padding: '6px 8px' }}*/}
        {/*>*/}
        {/*  Project Management Login*/}
        {/*</Button>*/}
      </Card>

      {!isXs && (
        <div className={classes.textContainer}>
          <Typography
            className={classes.splashText}
            style={{
              fontFamily: 'Uniform Rounded Medium',
              fontWeight: 500,
              marginTop: 0,
              letterSpacing: 8,
            }}
          >
            CENTERLINE BidConnect
          </Typography>
          <Typography
            className={classes.splashText}
            style={{
              fontSize: 48,
              lineHeight: '48px',
              fontWeight: 500,
              marginTop: 48,
            }}
          >
            Find Construction Bid Opportunities
          </Typography>
          <Typography className={classes.splashTextSmall} style={{ marginTop: 32 }}>
            Centerline BidConnect is a cloud-hosted platform for Architects and Owners
          </Typography>
          <Typography className={classes.splashTextSmall}>
            to distribute Bid Documents and Addenda which allows Contractors to
          </Typography>
          <Typography className={classes.splashTextSmall}>
            submit Bid-Phase RFIs & Substitution Requests, as well as secure Bid Submission.
          </Typography>
          <Typography className={classes.splashTextSmall} style={{ marginTop: 24 }}>
            Registration to view projects is FREE.
          </Typography>
          <Typography
            className={classes.splashTextSmall}
            style={{ marginTop: 32, fontSize: 32, lineHeight: '32px', fontWeight: 400 }}
          >
            To post your projects here, contact us: bidconnect@centerline.co
          </Typography>
        </div>
      )}
      <SendFilesDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
    </div>
  );
}

export default BiddingPortalLogin;
