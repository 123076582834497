import {
  DocumentTemplateType,
  INumberedDocumentView,
  IPhysicalBuilding,
  IPhysicalFloor,
  IPhysicalLocation,
  IProjectUser,
  PunchListStatusType,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import {
  getActionTakenText,
  getSubmittalTitle,
  getUserFacingWorkflowStatus,
  parseDate,
} from '../../scripts/utils';
import {
  getAddendaRows,
  getAsBuiltRows,
  getASIRows,
  getBidderRfiRows,
  getBidDrawingsRows,
  getChangeOrderRows,
  getClearLPRows,
  getCloseoutRows,
  getConstructionChangeRows,
  getContractorDailyLogRows,
  getDesignPackageRows,
  getDrawingsRows,
  getFieldReportRows,
  getInformationalItemsRows,
  getMeetingMinutesRows,
  getMiscRows,
  getOperationAndMaintenanceRows,
  getPayApplicationRows,
  getPlanholderListRows,
  getPotentialChangeOrderRows,
  getProjectManualRows,
  getPunchListRows,
  getRegulatoryApprovalRows,
  getRequestForChangeRows,
  getRFIRows,
  getScheduleRows,
  getSpecificationsRows,
  getSubmittalRows,
  getSubstantialCompletionCertRows,
  getSubstitutionRequestRows,
  getTaskRows,
  getTestingRows,
  getWarrantyClaimsRows,
  getWorkChangeRows,
} from './DocumentIndexData';
import { Section } from '../../features/documents/documents';
import _ from 'lodash';
import { PunchListLocationRow } from './DocumentIndex';
import getHeaders from './DocumentLogHeaders';
import { getManyDocumentPublicLinks } from '../../models/api/users';

export type Order = 'asc' | 'desc';

type ObjectWithName = {
  name: string;
};

export type IPackage = {
  id: string;
  children: any[];
};

const hasNumber = (str: string) => /\d/.test(str);

export const docTypeShouldOpenFile = [
  DocumentTemplateType.BidDrawings,
  DocumentTemplateType.ProjectManual,
  DocumentTemplateType.Addenda,
  DocumentTemplateType.InformationalItems,
];

export const statusToGeneralString: Record<WorkflowStatusType | PunchListStatusType, string> = {
  initial: 'Awaiting Submission',
  subcontractor_uploaded: 'Awaiting Submission',
  ready_for_submission_to_architect: 'Awaiting Submission',
  general_contractor_uploaded: 'Submitted for Review',
  architect_uploaded: 'Under Review',
  submitted_for_review: 'Submitted for Review',
  under_review: 'Under Review',
  completed: 'Review Complete',
  review_complete: 'Review Complete',
  resolved: 'Resolved',
  resubmitted: 'Resubmitted to Architect',
  canceled: 'Canceled',
  in_progress: 'In Progress',
  new: 'New',
  rejected: 'Rejected',
  accepted: 'Accepted',
  ready_for_verification: 'Ready for Verification',
};

export function ascendingComparator<T>(
  a: T,
  b: T,
  orderBy: keyof T,
  checkObjects = false,
): 1 | -1 | 0 {
  if (checkObjects && a && b && typeof a[orderBy] === 'object' && typeof b[orderBy] === 'object') {
    const c = (a[orderBy] as unknown) as object;
    const d = (b[orderBy] as unknown) as object;
    if (c.hasOwnProperty('name') && d.hasOwnProperty('name'))
      return ascendingComparator(c as ObjectWithName, d as ObjectWithName, 'name');
  }

  if (
    typeof a[orderBy] === 'string' &&
    typeof b[orderBy] === 'string' &&
    (hasNumber((a[orderBy] as unknown) as string) || hasNumber((b[orderBy] as unknown) as string))
  )
    // @ts-ignore
    return a[orderBy].localeCompare(b[orderBy], 'en', { numeric: true });

  if (b[orderBy] < a[orderBy]) {
    return 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return -1;
  }
  return 0;
}

export function descendingComparator<T>(
  a: T,
  b: T,
  orderBy: keyof T,
  checkObjects = false,
): 1 | -1 | 0 {
  if (checkObjects && a && b && typeof a[orderBy] === 'object' && typeof b[orderBy] === 'object') {
    const c = (a[orderBy] as unknown) as object;
    const d = (b[orderBy] as unknown) as object;
    if (c.hasOwnProperty('name') && d.hasOwnProperty('name'))
      return descendingComparator(c as ObjectWithName, d as ObjectWithName, 'name');
  }

  if (
    typeof a[orderBy] === 'string' &&
    typeof b[orderBy] === 'string' &&
    (hasNumber((a[orderBy] as unknown) as string) || hasNumber((b[orderBy] as unknown) as string))
  )
    // @ts-ignore
    return b[orderBy].localeCompare(a[orderBy], 'en', { numeric: true });

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | boolean },
  b: { [key in Key]: number | string | boolean },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, true)
    : (a, b) => -descendingComparator(a, b, orderBy, true);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const addSpacing = (sectionNumber?: string | null) => {
  if (!sectionNumber) return '';
  if (sectionNumber.includes(' ')) return sectionNumber;
  return `${sectionNumber.slice(0, 2)} ${sectionNumber.slice(2, 4)} ${sectionNumber.slice(
    4,
    sectionNumber.length,
  )}`;
};

export const addSpacingIncremental = (sectionNumber?: string | null) => {
  if (!sectionNumber) return '';
  if (sectionNumber.length <= 2) return sectionNumber;
  if (sectionNumber.length <= 4) {
    return `${sectionNumber.slice(0, 2)} ${sectionNumber.slice(2, 4)}`;
  }
  return `${sectionNumber.slice(0, 2)} ${sectionNumber.slice(2, 4)} ${sectionNumber.slice(
    4,
    sectionNumber.length,
  )}`;
};

export const removeSpacing = (sectionNumber?: string | null) => {
  if (!sectionNumber) return '';
  return sectionNumber.replace(/\s/g, '');
};

export const formatSubmittalNumber = (document: INumberedDocumentView) => {
  return document.submittalSection
    ? `${getSubmittalTitle(document, false)}`
    : ((document.documentNumber! as unknown) as string);
};

export const getShouldFilterByStatus = (docType?: DocumentTemplateType | null) => {
  if (!docType) return false;
  return [
    DocumentTemplateType.SubstitutionRequests,
    DocumentTemplateType.BidderRfIs,
    DocumentTemplateType.PayApplications,
    DocumentTemplateType.RequestsForInformation,
    DocumentTemplateType.Task,
    DocumentTemplateType.Submittals,
    DocumentTemplateType.PotentialChangeOrders,
    DocumentTemplateType.RequestsForChange,
    DocumentTemplateType.WorkChangeProposalRequests,
    DocumentTemplateType.CloseoutSubmittals,
    DocumentTemplateType.AsBuilt,
    DocumentTemplateType.OperationsAndMaintenanceData,
  ].includes(docType);
};

export const getDocumentRows = async (
  documents: INumberedDocumentView[],
  docType: DocumentTemplateType | null,
  packageDocuments?: INumberedDocumentView[],
  projectUsers?: IProjectUser[],
  selectedSection?: Section | null,
  selectedDivision?: string | null,
  bidSetupId?: string | null,
) => {
  switch (docType) {
    case DocumentTemplateType.AsiDocuments: {
      return getASIRows(documents);
    }
    case DocumentTemplateType.ChangeOrders: {
      return getChangeOrderRows(documents);
    }
    case DocumentTemplateType.ConstructionChangeDirectives: {
      return getConstructionChangeRows(documents);
    }
    case DocumentTemplateType.ContractorDailyLogs: {
      return getContractorDailyLogRows(documents);
    }
    case DocumentTemplateType.FieldReports: {
      return getFieldReportRows(documents);
    }
    case DocumentTemplateType.MeetingMinutes: {
      return getMeetingMinutesRows(documents);
    }
    case DocumentTemplateType.MiscellaneousDocuments: {
      return getMiscRows(documents);
    }
    case DocumentTemplateType.Schedules: {
      return getScheduleRows(documents);
    }
    case DocumentTemplateType.Testing: {
      return getTestingRows(documents);
    }
    case DocumentTemplateType.RequestsForInformation: {
      return getRFIRows(documents);
    }
    case DocumentTemplateType.WorkChangeProposalRequests: {
      return getWorkChangeRows(documents);
    }
    case DocumentTemplateType.PayApplications: {
      return getPayApplicationRows(documents);
    }
    case DocumentTemplateType.PotentialChangeOrders: {
      return getPotentialChangeOrderRows(documents);
    }
    case DocumentTemplateType.RequestsForChange: {
      return getRequestForChangeRows(documents);
    }
    case DocumentTemplateType.SubmittalPackages:
    case DocumentTemplateType.Submittals: {
      return getSubmittalRows(
        packageDocuments ? [...documents, ...packageDocuments] : documents,
        selectedDivision || undefined,
        selectedSection?.number,
      );
    }
    case DocumentTemplateType.Specifications: {
      return getSpecificationsRows(documents, selectedDivision || undefined);
    }
    case DocumentTemplateType.Addenda: {
      return getAddendaRows(documents);
    }
    case DocumentTemplateType.CloseoutSubmittalPackages:
    case DocumentTemplateType.CloseoutSubmittals: {
      return getCloseoutRows(
        packageDocuments ? [...documents, ...packageDocuments] : documents,
        selectedDivision || undefined,
        selectedSection?.number,
      );
    }
    case DocumentTemplateType.OperationsAndMaintenanceData: {
      return getOperationAndMaintenanceRows(documents);
    }
    case DocumentTemplateType.RegulatoryApprovals: {
      return getRegulatoryApprovalRows(documents);
    }

    case DocumentTemplateType.PlanholderList: {
      return getPlanholderListRows(projectUsers || []);
    }
    case DocumentTemplateType.PunchList: {
      return getPunchListRows(documents);
    }
    case DocumentTemplateType.BidderRfIs: {
      return getBidderRfiRows(documents);
    }
    case DocumentTemplateType.SubstitutionRequests: {
      return getSubstitutionRequestRows(documents);
    }
    case DocumentTemplateType.ProjectManual: {
      return bidSetupId ? getProjectManualRows(bidSetupId) : [];
    }
    case DocumentTemplateType.Drawings: {
      return getDrawingsRows(documents);
    }
    case DocumentTemplateType.InformationalItems: {
      return getInformationalItemsRows(documents);
    }
    case DocumentTemplateType.WarrantyItems: {
      return getWarrantyClaimsRows(documents);
    }
    case DocumentTemplateType.ClearLp: {
      return getClearLPRows(documents);
    }
    case DocumentTemplateType.AsBuiltPackages:
    case DocumentTemplateType.AsBuilt: {
      return getAsBuiltRows(packageDocuments ? [...documents, ...packageDocuments] : documents);
    }
    case DocumentTemplateType.CertificateOfSubstantialCompletion: {
      return getSubstantialCompletionCertRows(documents);
    }
    case DocumentTemplateType.BidDrawings: {
      return bidSetupId ? getBidDrawingsRows(bidSetupId) : [];
    }
    case DocumentTemplateType.Task: {
      return getTaskRows(documents);
    }
    case DocumentTemplateType.DesignPackages: {
      return getDesignPackageRows(documents);
    }
    default:
      return [];
  }
};

export const getSortOrderPropertyFromDocumentType = (docType: DocumentTemplateType | null) => {
  switch (docType) {
    case DocumentTemplateType.Drawings:
      return 'page';
    case DocumentTemplateType.AsBuilt:
      return 'pageNumber';
    case DocumentTemplateType.ContractorDailyLogs:
      return 'logDate';
    case DocumentTemplateType.PunchList:
      return 'locationId';
    default:
      return 'number';
  }
};

export const getCsvRowsFromDocumentIndexRows = async (
  rows: any[],
  docType: DocumentTemplateType,
  packages: IPackage[],
  buildings: IPhysicalBuilding[],
  floors: IPhysicalFloor[],
  locations: IPhysicalLocation[],
) => {
  let csvRows: any[] = [];
  const headers: { id: string; label: string }[] = getHeaders(docType);
  const allRows = packages.length > 0 ? [...rows, ...packages.map((p) => p.children).flat()] : rows;

  if (docType === DocumentTemplateType.PunchList) {
    const punchListRows = rows as Omit<PunchListLocationRow, 'locationObject'>[];
    const links = await getManyDocumentPublicLinks(
      punchListRows.map((r) => r.children.map((c) => c.id)).flat(),
    );
    punchListRows.forEach(({ children }) => {
      children?.forEach((item) => {
        const location = locations.find((loc) => loc.id === item.locationId);
        const floor = floors.find((floor) => floor.id === location?.floorId);
        const building = buildings.find((building) => building.id === floor?.buildingId);
        const link = links.find((l) => l.documentId === item.id)?.link;
        csvRows.push({
          building: `"${building?.name}"`,
          floor: `"${floor?.name}"`,
          location: `="${location?.name}"`,
          description: `"${item.title}"`,
          subcontractor: `"${item.responsibleParty}"`,
          status: `"${item.status}"`,
          cost: `"$${(item.cost / 100).toFixed(2)}"`,
          postedDate: `"${parseDate(item.postedDate).format('YYYY-MM-DD')}"`,
          publicDocumentLink: `=HYPERLINK("${link}")`,
        });
      });
    });
  } else {
    const shouldIncludeLink = ![
      DocumentTemplateType.BidTabulation,
      DocumentTemplateType.PlanholderList,
    ].includes(docType);

    const links = shouldIncludeLink
      ? await getManyDocumentPublicLinks(allRows.map((r) => r.id))
      : [];

    allRows
      .sort((a, b) => ascendingComparator(a, b, getSortOrderPropertyFromDocumentType(docType)))
      .forEach((row) => {
        const csvRow: { [index: string]: string } = {};

        for (const key in row) {
          if (['id', 'typeId', 'packageDocumentId', 'assignedToUserId', 'overdue'].includes(key))
            continue;

          if (['priority'].includes(key) && docType !== DocumentTemplateType.Task) continue;

          let value = row[key];

          if (key.toLowerCase().includes('date') && value !== 'N/A') {
            value = parseDate(value).format('YYYY-MM-DD');
          } else if (key === 'submittalDescription') {
            value = `"${
              value.sectionTitle && value.sectionTitle !== 'N/A' ? `${value.sectionTitle}: ` : ''
            }${value.title}"`;
          } else if (key === 'responsibility' && typeof value === 'object') {
            const { names, company } = value;
            if (company && names) {
              value = `${company}: ${names.join('; ')}`;
            } else if (names) {
              value = names.length > 1 ? names.join('; ') : names[0];
            }
          } else if (key === 'action') {
            value = getActionTakenText(value);
          } else if (key === 'status') {
            if (Object.values(WorkflowStatusType).includes(value)) {
              value = getUserFacingWorkflowStatus(value);
            } else {
              value = _.startCase(value);
            }
          } else if (key === 'openedOn') {
            value = !!value ? 'Opened' : 'Sealed';
          } else {
            value = `"${value}"`;
          }

          const label = headers.find((h) => h.id === key)?.label || _.startCase(key);
          csvRow[label] = value;
        }

        if (
          [DocumentTemplateType.Submittals, DocumentTemplateType.CloseoutSubmittals].includes(
            docType,
          )
        ) {
          csvRow['Is Package?'] = allRows.some((r) => r.packageDocumentId === row.id)
            ? 'Yes'
            : 'No';
          csvRow['Parent Package'] = row.packageDocumentId
            ? allRows.find((r) => r.id === row.packageDocumentId)?.number
            : 'N/A';
        }

        if (shouldIncludeLink) {
          csvRow['Public Document Link'] = links.find((l) => l.documentId === row.id)?.link || '';
        }

        csvRows.push(csvRow);
      });
  }

  if (
    [DocumentTemplateType.Submittals, DocumentTemplateType.CloseoutSubmittals].includes(docType)
  ) {
    csvRows = csvRows.sort((a, b) => b['Is Package?'].localeCompare(a['Is Package?']));
  }

  return csvRows;
};
