import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { ISimplifiedProject } from '../../api-client/autogenerated';
import { makeStyles } from '@material-ui/core/styles';
import { Clear } from '@material-ui/icons';

type ArchiveDialogProps = {
  project: ISimplifiedProject;
  isArchiveDialogOpen: boolean;
  closeDialog: () => void;
  Archive: (id: string) => Promise<void>;
  Restore: (id: string) => Promise<void>;
};

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '50px',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
    minWidth: 500,
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '30px',
    textAlign: 'center',
    color: '#0947B9',
    textTransform: 'uppercase',
    justifySelf: 'center',
  },
});

const ArchiveDialog = (props: ArchiveDialogProps) => {
  const { project, isArchiveDialogOpen, closeDialog, Archive, Restore } = props;
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const activateArchive = async () => {
    try {
      setIsLoading(true);
      await Archive(project.id);
    } finally {
      closeDialog();
      setIsLoading(false);
    }
  };

  const activateRestore = async () => {
    try {
      setIsLoading(true);
      await Restore(project.id);
    } finally {
      closeDialog();
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isArchiveDialogOpen} onClose={closeDialog} maxWidth="sm">
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title} align="center">
          {project.isArchived ? 'Restore Project' : 'Archive Project'}
        </Typography>
      </DialogTitle>
      <div style={{ height: '17%' }} />
      <DialogContent style={{ margin: '20px 0px' }}>
        {isLoading ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 120 }}
          >
            <CircularProgress size="60px" thickness={3} color="primary" />
          </div>
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography style={{ whiteSpace: 'pre' }}>
                {project.isArchived
                  ? 'You are attempting to restore project '
                  : 'You are attempting to archive project '}
              </Typography>
              <Typography style={{ fontWeight: 'bolder', color: 'black' }}>
                {project.name}
              </Typography>
            </div>
            <Typography style={{ marginTop: 20 }}>
              {project.isArchived
                ? 'When a project is restored users will be able to see and interact with it.'
                : 'When a project is archived no users will be able to see or interact with it.'}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', padding: '8px 16px' }}>
        <Button
          disabled={isLoading}
          onClick={closeDialog}
          variant="outlined"
          startIcon={<Clear />}
          style={{ border: '1px #464546 solid', color: 'black', width: 120.87 }}
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          variant="contained"
          color="primary"
          style={{ margin: 0 }}
          onClick={project.isArchived ? activateRestore : activateArchive}
        >
          {project.isArchived ? 'Restore' : 'Archive'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ArchiveDialog;
