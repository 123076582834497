import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CancelButton } from '../custom-components/CustomButtons';
import CircularLoader from '../loader/CircularLoader';
import Button from '@material-ui/core/Button';
import React, { useState } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (reviewComment: string, willPublishResponse: boolean) => Promise<void>;
  isLoading: boolean;
};

export default function ReviewCommentDialog(props: Props) {
  const { open, onClose, onSubmit, isLoading } = props;

  const [reviewComment, setReviewComment] = useState('');
  const [willPublishResponse, setWillPublishResponse] = useState<boolean>();

  return (
    <Dialog maxWidth="xs" fullWidth open={open}>
      <DialogTitle disableTypography>
        <Typography variant="h1" style={{ fontSize: 28, textTransform: 'none' }}>
          Post a Comment
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>Will a response be published in a future Addendum?</Typography>
        <RadioGroup
          row
          value={willPublishResponse?.toString()}
          onChange={(e, value) => setWillPublishResponse(value === 'true')}
          style={{ justifyContent: 'space-evenly', marginBottom: 8 }}
        >
          <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
        </RadioGroup>
        <TextField
          variant="outlined"
          placeholder="Comment..."
          multiline
          fullWidth
          rows={4}
          value={reviewComment}
          onChange={(event) => setReviewComment(event.target.value)}
          InputProps={{ style: { padding: 8 } }}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', paddingLeft: 24, paddingRight: 24 }}>
        <CancelButton onClick={onClose} />
        {isLoading ? (
          <CircularLoader />
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={willPublishResponse === undefined}
            onClick={() => onSubmit(reviewComment, willPublishResponse!)}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
