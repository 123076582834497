import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Triangle from '../submittal-action-center/Triangle';
import '../../css/main-style.css';
import {
  formatDays,
  formatMoney,
  formatPhoneNumber,
  getActionTakenText,
  getCurrentlyResponsibleCompany,
  getCurrentlyResponsibleUsers,
  getReferenceNumberLabelFromDocumentType,
  getUserFriendlyDocumentPriority,
  isPublicPage,
  parseDate,
} from '../../scripts/utils';
import {
  DocumentPriorityType,
  DocumentTemplateType,
  IProjectUser,
  IUser,
  PunchListStatusType,
  ResponsiblePartyType,
  SecurityPermissionLevel,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import SummaryAttribute from './SummaryAttribute';
import Break from './Break';
import UserFilterListDialog, { DisplayUser } from '../dialogs/UserFilterListDialog';
import { getDocumentState } from '../../features/document/selectors';
import { getProjectUsersState } from '../../features/project/selectors';
import {
  addSpacing,
  ascendingComparator,
  statusToGeneralString,
} from '../document-index/DocumentIndexUtils';
import { modifyDocumentByIdWithResponse } from '../../models/api/documents';
import { updateDocument } from '../../features/document/actions';
import { Alert } from '@material-ui/lab';
import { Collapse, MenuItem, Snackbar, TextField, useMediaQuery } from '@material-ui/core';
import { Color } from '@material-ui/lab/Alert';
import { getBidState } from '../../features/bid/selectors';
import IconButton from '@material-ui/core/IconButton';
import { Remove } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import {
  getDocPermission,
  getProjectUserPermissions,
  getUserPermissionForCurrentDocType,
} from '../../scripts/store-utils';
import { getDocumentsType } from '../../features/documents/selectors';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider';
import DayjsUtils from '@date-io/dayjs';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import { Dayjs } from 'dayjs';
import { CancelButton } from '../custom-components/CustomButtons';
import { addSnackbar } from '../../features/snackbar/actions';
import AssignedUserText from '../custom-components/AssignedUserText';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    flexBasis: 'auto',
    paddingBottom: 20,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 'auto',
    width: 'auto',
  },
  responsibleMargin: {
    marginRight: 10,
  },
  buttonMargin: {
    marginRight: 8,
    marginBottom: 8,
    marginTop: 8,
  },
  followerButtonMargin: {
    marginRight: 16,
  },
  padding: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 14,
    paddingBottom: 14,
  },
  widthMed: {
    width: 130,
  },
  widthLarge: {
    width: 140,
  },
  centerIcon: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: 'auto',
    flexWrap: 'nowrap',
    height: '24px',
    justifyContent: 'flex-end',
  },
  icon: {
    color: '#7A797A',
    cursor: 'pointer',
  },
});

const dictionary: { [key: string]: string } = {
  contactPhone: 'Contact Phone',
  contactEmail: 'Contact Email',
  title: 'Title',
  description: 'Description',
  sheetNumber: 'Sheet Number',
  referenceNumber: 'Reference Number',
  proposedCostImpact: 'Proposed Cost Impact',
  proposedTimeImpact: 'Proposed Time Impact',
  actionTaken: 'Action Taken',
  proposedSubstitution: 'Proposed Substitution',
  substitutionReason: 'Substitution Reason',
  substitutionAffectsDrawing: 'Substitution Effect on Drawings',
  substitutionAffectsTrades: 'Substitution Effect on Trades',
  substitutionAffectedBy: 'Substitution Affect By',
  substitutionDifferences: 'Substitution Differences',
  manufacturersWarranties: 'Manufacturer Warranties',
  manufacturersWarrantiesExplanation: 'Warranty Explanation',
  informationAvailable: 'Information Available',
  numberOfSheets: 'Number of Sheets',
  agency: 'Agency',
  location: 'Location',
  downloads: 'Downloads',
  views: 'Views',
  licenseNumber: 'License Number',
  contractorAcceptance: 'Contractor Acceptance',
  architectAcceptance: 'Architect Acceptance',
  partiesPresent: 'Parties Present',
  customArchitect: 'Organization Architect',
  customContractor: 'Organization Contractor',
  currentWorkInProgress: 'Work in Progress',
  punchListStatus: 'Status',
};

const bidDictionary: { [key: string]: any } = {
  contactName: 'Contact Name',
  companyName: 'Company Name',
  address: 'Address',
  phoneNumber: 'Phone Number',
  email: 'email',
  user: 'User',
};

const docTypesNoReferenceNumber = [
  DocumentTemplateType.Drawings,
  DocumentTemplateType.BidDrawings,
  DocumentTemplateType.Submittals,
  DocumentTemplateType.CloseoutSubmittals,
  DocumentTemplateType.SubmittalPackages,
  DocumentTemplateType.CloseoutSubmittalPackages,
];

interface SummaryCardProps {
  title?: string;
  primary?: { title: string; number: string };
  secondary?: { title: string; number: string };
  status: string;
  enableCurrentlyResponsible: boolean;
  syncedWithProcore?: boolean;
  responseOnProcore?: boolean;
}

export default function SummaryCard(props: SummaryCardProps) {
  const isUnder760px = useMediaQuery('(max-width:760px)');
  const isMobile = useMediaQuery('(max-width:670px)');

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    title,
    primary,
    secondary,
    status,
    enableCurrentlyResponsible,
    syncedWithProcore,
    responseOnProcore,
  } = props;

  const document = useSelector(getDocumentState);
  const docType = useSelector(getDocumentsType);
  const bid = useSelector(getBidState);
  const projectUsers = useSelector(getProjectUsersState);
  const [collapseOpen, setCollapseOpen] = useState(true);

  const [editDialogOpen, setOpenEditDialog] = useState(false);
  const [recipientDialogOpen, setRecipientDialogOpen] = useState(false);

  const [assignees, setAssignees] = useState<IProjectUser[]>([]);
  const [assigneesLoading, setAssigneesLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackText, setSnackText] = useState<string>();
  const [snackType, setSnackType] = useState<Color>();

  const isRfiLike =
    docType === DocumentTemplateType.RequestsForInformation ||
    docType === DocumentTemplateType.RequestsForChange ||
    docType === DocumentTemplateType.PotentialChangeOrders ||
    docType === DocumentTemplateType.Task ||
    docType === DocumentTemplateType.DesignPackages;
  const isSubmittal =
    docType === DocumentTemplateType.Submittals ||
    docType === DocumentTemplateType.CloseoutSubmittals;
  const isDrawing = docType === DocumentTemplateType.Drawings;
  const isRfcLike =
    docType === DocumentTemplateType.RequestsForChange ||
    docType === DocumentTemplateType.PotentialChangeOrders;
  const isFieldReport = docType === DocumentTemplateType.FieldReports;
  const isImmediatelySubmittedForReview =
    docType &&
    [DocumentTemplateType.BidderRfIs, DocumentTemplateType.SubstitutionRequests].includes(docType);

  const [editDueDateOpen, setEditDueDateOpen] = useState(false);
  const [dueDate, setDueDate] = useState<Dayjs | null>(
    document?.dueDate ? parseDate(document.dueDate) : null,
  );

  const [editExpectedCompletionDateOpen, setEditExpectedCompletionDateOpen] = useState(false);
  const [expectedCompletionDate, setExpectedCompletionDate] = useState<Dayjs | null>(
    document?.expectedCompletionDate ? parseDate(document.expectedCompletionDate) : null,
  );

  const [priority, setPriority] = useState<DocumentPriorityType | null>(document?.priority || null);
  const [editPriority, setEditPriority] = useState(false);

  const [inputStatus, setInputStatus] = useState<PunchListStatusType | null>(
    document?.punchListStatus || null,
  );
  const [editStatus, setEditStatus] = useState(false);

  const [subcontractor, setSubcontractor] = useState<string | null>(
    document?.customContractor || null,
  );
  const [editSubcontractor, setEditSubcontractor] = useState(false);

  const [responsiblePartyType, setResponsiblePartyType] = useState<ResponsiblePartyType>();
  const [responsiblePartyAssignees, setResponsiblePartyAssignees] = useState<IUser[]>([]);

  const [architects, setArchitects] = useState<IProjectUser[]>([]);
  const [contractors, setContractors] = useState<IProjectUser[]>([]);

  const permission = getDocPermission();

  const canBeArchitect = (permission?: SecurityPermissionLevel | null) => {
    return permission && permission >= 3;
  };

  const canBeContractor = (permission?: SecurityPermissionLevel | null) => {
    return permission && permission >= 2;
  };

  const fetchEligibleUsers = () => {
    const newArchitects: IProjectUser[] = [];
    const newContractors: IProjectUser[] = [];
    projectUsers.forEach((pUser) => {
      const permission = getUserPermissionForCurrentDocType(pUser);
      if (canBeContractor(permission)) {
        newContractors.push(pUser);
        if (canBeArchitect(permission)) newArchitects.push(pUser);
      }
    });
    setArchitects(newArchitects);
    setContractors(newContractors);
  };

  useEffect(() => {
    fetchEligibleUsers();
  }, [projectUsers, docType]);

  const getAssignees = () => {
    if (bid) return [];
    return getProjectUserPermissions()
      .filter(({ permission }) => permission && permission >= 2)
      .map(({ projectUser }) => projectUser);
  };

  const handleEditDialogOpen = (type: ResponsiblePartyType) => {
    setResponsiblePartyType(type);
    if (type === ResponsiblePartyType.Architect) {
      if (
        docType === DocumentTemplateType.Task ||
        docType === DocumentTemplateType.DesignPackages
      ) {
        setResponsiblePartyAssignees(
          assignees
            .map(({ user }) => user!)
            .filter((user) => user.id !== document?.architectUserId),
        );
      } else {
        setResponsiblePartyAssignees(
          architects
            .map(({ user }) => user!)
            .filter((user) => user.id !== document?.architectUserId),
        );
      }
    } else if (type === ResponsiblePartyType.GeneralContractor)
      setResponsiblePartyAssignees(
        contractors
          .map(({ user }) => user!)
          .filter((user) => user.id !== document?.generalContractorUserId),
      );
    setOpenEditDialog(true);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
  };

  const userHasEditPermission = () => {
    return permission && permission >= 2;
  };

  const canChangeDueDate = () => {
    if (
      document?.workflowStatus !== WorkflowStatusType.SubmittedForReview &&
      document?.workflowStatus !== WorkflowStatusType.UnderReview &&
      document?.workflowStatus !== WorkflowStatusType.ArchitectUploaded
    )
      return false;
    if (docType === DocumentTemplateType.Task || docType === DocumentTemplateType.DesignPackages) {
      return (
        permission &&
        permission >= 2 &&
        (docType !== DocumentTemplateType.DesignPackages || !document.linkedParentDocumentId)
      );
    }
    return permission && permission >= 2;
  };

  const handleChangeDueDate = async () => {
    if (!document || !dueDate) return;
    try {
      const { updatedDocument } = await modifyDocumentByIdWithResponse(document.id, {
        patch: { dueDate: dueDate.toISOString() },
      });
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Due date changed successfully!',
          severity: 'success',
        }),
      );
      dispatch(updateDocument(updatedDocument));
    } catch (e: any) {
      console.log(e);
    } finally {
      setEditDueDateOpen(false);
    }
  };

  const handleEditExpectedCompletionDate = async () => {
    if (!document || !expectedCompletionDate) return;
    try {
      const { updatedDocument } = await modifyDocumentByIdWithResponse(document.id, {
        patch: { expectedCompletionDate: expectedCompletionDate.toISOString() },
      });
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Expected completion date changed successfully!',
          severity: 'success',
        }),
      );
      dispatch(updateDocument(updatedDocument));
    } catch (e: any) {
      console.log(e);
    } finally {
      setEditExpectedCompletionDateOpen(false);
    }
  };

  const handleEditResponsibleSubcontractor = async () => {
    if (!document) return;
    try {
      const { updatedDocument } = await modifyDocumentByIdWithResponse(document.id, {
        patch: { customContractor: subcontractor?.trim() || null },
      });
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Responsible subcontractor set successfully!',
          severity: 'success',
        }),
      );
      dispatch(updateDocument(updatedDocument));
    } catch (e: any) {
      console.log(e);
    } finally {
      setEditSubcontractor(false);
    }
  };

  const handleEditPriority = async () => {
    if (!document || !priority) return;
    try {
      const { updatedDocument } = await modifyDocumentByIdWithResponse(document.id, {
        patch: { priority },
      });
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Priority changed successfully!',
          severity: 'success',
        }),
      );
      dispatch(updateDocument(updatedDocument));
    } catch (e: any) {
      console.log(e);
    } finally {
      setEditPriority(false);
    }
  };

  const handleEditWarrantyItemStatus = async () => {
    if (!document || !inputStatus) return;
    try {
      const { updatedDocument } = await modifyDocumentByIdWithResponse(document.id, {
        patch: { punchListStatus: inputStatus },
      });
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Status changed successfully!',
          severity: 'success',
        }),
      );
      dispatch(updateDocument(updatedDocument));
    } catch (e: any) {
      console.log(e);
    } finally {
      setEditStatus(false);
    }
  };

  const canEditAssigned = () => {
    return (
      document &&
      userHasEditPermission() &&
      (document.responsibleParty === ResponsiblePartyType.Architect ||
        document.responsibleParty === ResponsiblePartyType.GeneralContractor)
    );
  };

  const handleEditAssigned = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    user: DisplayUser,
  ) => {
    const patch =
      responsiblePartyType === ResponsiblePartyType.Architect
        ? { architectUserId: user.id }
        : { generalContractorUserId: user.id };
    modifyDocumentByIdWithResponse(document!.id, { patch })
      .then(({ updatedDocument }) => {
        dispatch(updateDocument(updatedDocument));
        setSnackType('success');
        setSnackText(`Assigned user changed to ${user.name}`);
        setSnackOpen(true);
      })
      .catch(() => {
        setSnackType('error');
        setSnackText(`Failed to change assigned user`);
        setSnackOpen(true);
      })
      .finally(() => {
        setOpenEditDialog(false);
      });
  };

  useEffect(() => {
    if (!isPublicPage() && projectUsers.length > 0 && canEditAssigned() && assignees.length === 0) {
      setAssigneesLoading(true);
      setAssignees(getAssignees());
      setAssigneesLoading(false);
    }
  }, [editDialogOpen]);

  const hasDocumentPermission = (permission?: number | null): boolean => {
    if (!document) return false;
    if (permission) {
      if (document!.responsibleParty === ResponsiblePartyType.Architect) {
        if (document!.isWorkflowInverted) return !!(permission && permission > 1);
        return !!(permission && permission > 2);
      }
      if (document!.isWorkflowInverted) return !!(permission && permission > 2);
      return !!(permission && permission > 1);
    }
    return false;
  };

  const responsiblePartyElement = (
    label: string,
    value: JSX.Element,
    type: ResponsiblePartyType,
    canEdit = true,
  ) =>
    enableCurrentlyResponsible &&
    (docType === DocumentTemplateType.PayApplications ||
      docType === DocumentTemplateType.PotentialChangeOrders ||
      docType === DocumentTemplateType.RequestsForChange ||
      docType === DocumentTemplateType.Submittals ||
      docType === DocumentTemplateType.RequestsForInformation ||
      docType === DocumentTemplateType.WorkChangeProposalRequests ||
      docType === DocumentTemplateType.AsBuilt ||
      docType === DocumentTemplateType.CloseoutSubmittals ||
      docType === DocumentTemplateType.PunchList ||
      docType === DocumentTemplateType.Task ||
      docType === DocumentTemplateType.DesignPackages) ? (
      <Grid
        container
        style={{
          display: 'inline-flex',
          width: '100%',
          flexWrap: 'nowrap',
          alignItems: 'center',
          borderBottom: '1px solid #EDECEC',
        }}
        direction={isMobile ? 'column' : 'row'}
      >
        <Typography
          variant="body2"
          className={classes.responsibleMargin}
          style={{
            paddingLeft: '16px',
            paddingTop: '14px',
            paddingBottom: '14px',
            minWidth: 290,
            alignSelf: 'flex-start',
          }}
        >
          {label}
        </Typography>

        <Box
          style={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: isMobile ? 16 : '',
          }}
        >
          <div style={{ minWidth: isUnder760px ? 190 : 250 }}>{value}</div>
          {!isPublicPage() &&
            canEditAssigned() &&
            canEdit &&
            (isUnder760px ? (
              <div style={{ flexGrow: 1, display: 'flex' }}>
                <IconButton
                  color="primary"
                  className={classes.buttonMargin}
                  onClick={() => handleEditDialogOpen(type)}
                  style={{ marginLeft: isMobile ? 16 : '' }}
                >
                  <EditIcon />
                </IconButton>
              </div>
            ) : (
              <div style={{ flexGrow: 1, display: 'flex' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.buttonMargin}
                  onClick={() => handleEditDialogOpen(type)}
                  style={{ marginLeft: isMobile ? 16 : '' }}
                >
                  Edit
                </Button>
              </div>
            ))}
        </Box>
      </Grid>
    ) : (
      <Break />
    );

  const currentlyResponsibleElement =
    enableCurrentlyResponsible &&
    (docType === DocumentTemplateType.PayApplications ||
      docType === DocumentTemplateType.PotentialChangeOrders ||
      docType === DocumentTemplateType.RequestsForChange ||
      docType === DocumentTemplateType.Submittals ||
      docType === DocumentTemplateType.RequestsForInformation ||
      docType === DocumentTemplateType.WorkChangeProposalRequests ||
      docType === DocumentTemplateType.AsBuilt ||
      docType === DocumentTemplateType.CloseoutSubmittals ||
      docType === DocumentTemplateType.PunchList) ? (
      <Box
        className="inline-box orange-vertical-borders"
        style={{ width: '100%', flexWrap: 'nowrap' }}
      >
        <Triangle />

        <Grid container direction={isMobile ? 'column' : 'row'} style={{}}>
          <Typography
            variant="body2"
            className={classes.responsibleMargin}
            style={{ minWidth: 250, alignSelf: 'center' }}
          >
            CURRENTLY RESPONSIBLE:
          </Typography>

          <Box
            style={{
              flexGrow: 1,
              display: 'flex',
              alignItems: ' center',
            }}
          >
            {/*<Typography*/}
            {/*  variant="body1"*/}
            {/*  style={{ fontWeight: 'bold', flexGrow: 1000, paddingRight: 16 }}*/}
            {/*>*/}
            {/*  /!*{getDisplayName()}*!/*/}
            {/*  {getCurrentlyResponsibleUsers(document)}*/}
            {/*</Typography>*/}
            <AssignedUserText
              company={getCurrentlyResponsibleCompany(document)}
              name={getCurrentlyResponsibleUsers(document)
                .map((user) => user.name)
                .join(', ')}
            />
            {/*{!isPublicPage() && canEditAssigned() && (*/}
            {/*  <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>*/}
            {/*    <Button*/}
            {/*      variant="outlined"*/}
            {/*      color="primary"*/}
            {/*      className={classes.buttonMargin}*/}
            {/*      onClick={handleEditDialogOpen}*/}
            {/*    >*/}
            {/*      Edit*/}
            {/*    </Button>*/}
            {/*  </div>*/}
            {/*)}*/}
          </Box>
        </Grid>
      </Box>
    ) : (
      <Break />
    );

  let numOfSmallRows = 0;

  const getGeneralContractorLabel = () => {
    if (docType === DocumentTemplateType.Task || docType === DocumentTemplateType.DesignPackages) {
      return 'Assigned By';
    }

    return 'Responsible General Contractor';
  };

  const getArchitectLabel = () => {
    if (docType === DocumentTemplateType.Task || docType === DocumentTemplateType.DesignPackages) {
      return 'Assigned To';
    }

    return 'Responsible Architect';
  };

  const getDateReturnedToContractorLabel = () => {
    if (docType === DocumentTemplateType.Task || docType === DocumentTemplateType.DesignPackages) {
      return 'Date Completed';
    }

    if (document?.isWorkflowInverted) {
      return 'Date Returned to Architect';
    }

    return 'Date Returned to Contractor';
  };

  return (
    <Card className={classes.root} style={{ height: collapseOpen ? '100%' : 'auto' }}>
      <CardContent className={classes.content}>
        <div style={{ display: 'flex' }}>
          <h2 className="h2">
            {title
              ? `${title} Summary`
              : `${document?.referenceNumber}${
                  document?.revisionNumber ? `-R${document?.revisionNumber}` : ''
                }Summary`}
          </h2>
          <div style={{ display: 'inline-flex', flexGrow: 100 }} />
          <IconButton style={{ padding: 0 }}>
            {collapseOpen ? (
              <Remove htmlColor="#0947B9" onClick={() => setCollapseOpen(false)} />
            ) : (
              <Add htmlColor="#0947B9" onClick={() => setCollapseOpen(true)} />
            )}
          </IconButton>
        </div>

        {/* NOTE: This section only applies to Field Reports for now,
        clicking edit or publish button will go to a specific page for editting draft field reports */}
        {document?.isDraft && (
          <Box style={{ margin: '12px 0px' }}>
            <Typography>
              This is a <strong>draft</strong> document. It has not been published yet.
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              style={{ marginTop: 12 }}
              onClick={() => history.push(`${document?.id}/edit`)}
            >
              Edit or Publish Draft
            </Button>
          </Box>
        )}
      </CardContent>
      <Collapse in={collapseOpen}>
        {currentlyResponsibleElement}
        <Grid container spacing={0}>
          {primary && (
            <SummaryAttribute attributeName={primary.title} attributeValue={primary.number} />
          )}
          {secondary && (
            <SummaryAttribute attributeName={secondary.title} attributeValue={secondary.number} />
          )}

          {status &&
            status !== 'N/A' &&
            document?.documentTemplate?.name !== DocumentTemplateType.FieldReports &&
            document?.documentTemplate?.name !== DocumentTemplateType.WarrantyItems && (
              <SummaryAttribute
                attributeName="Current Status"
                large
                attributeValue={status}
                editStatusButton={
                  docType === DocumentTemplateType.AsBuilt &&
                  (document?.workflowStatus === WorkflowStatusType.SubmittedForReview ||
                    document?.workflowStatus === WorkflowStatusType.UnderReview)
                }
              />
            )}

          {document?.workflowStatus &&
          [
            WorkflowStatusType.Accepted,
            WorkflowStatusType.Resolved,
            WorkflowStatusType.Resubmitted,
          ].includes(document.workflowStatus) ? (
            <SummaryAttribute
              attributeName="Contractor Acceptance"
              attributeValue={statusToGeneralString[document.workflowStatus]}
            />
          ) : null}

          {document?.generalContractorUser &&
            responsiblePartyElement(
              getGeneralContractorLabel(),
              <AssignedUserText
                company={document.generalContractorUser.company?.name}
                name={document.generalContractorUser.name}
              />,
              ResponsiblePartyType.GeneralContractor,
              docType !== DocumentTemplateType.Task &&
                docType !== DocumentTemplateType.DesignPackages,
            )}

          {document?.architectUser &&
            responsiblePartyElement(
              getArchitectLabel(),
              <AssignedUserText
                company={document.architectUser.company?.name}
                name={document.architectUser.name}
              />,
              ResponsiblePartyType.Architect,
            )}

          {document?.creatorUser && document.creatorUser.name !== document?.contactName && (
            <SummaryAttribute
              large
              attributeName="Created By"
              attributeValue={
                <AssignedUserText
                  name={document.creatorUser.name}
                  company={
                    document.creatorUser.company?.name || document.creatorUser.publicCompanyName
                  }
                />
              }
            />
          )}

          {document?.contactName != null && (
            <SummaryAttribute
              large
              attributeName={isImmediatelySubmittedForReview ? 'Submitted By' : 'Contact Name'}
              attributeValue={document.contactName}
            />
          )}

          {document?.contactCompany != null && (
            <SummaryAttribute
              large
              attributeName={
                isImmediatelySubmittedForReview ? 'Submitting Company' : 'Contact Company'
              }
              attributeValue={document.contactCompany}
            />
          )}

          {!!document?.submittalSection && (
            <SummaryAttribute
              large
              attributeName={'Specification Section'}
              attributeValue={addSpacing(document.submittalSection)}
            />
          )}

          {!!document?.submittalSectionDescription && (
            <SummaryAttribute
              large
              attributeName={'Section Description'}
              attributeValue={document.submittalSectionDescription}
            />
          )}

          {document?.pageNumber != null && !isDrawing && (
            <SummaryAttribute
              large
              attributeName={'Page Number'}
              attributeValue={document.pageNumber}
            />
          )}

          {document?.paragraphNumber != null && (
            <SummaryAttribute
              large
              attributeName={'Paragraph Number'}
              attributeValue={document.paragraphNumber}
            />
          )}

          {document?.reviewComment != null && (
            <SummaryAttribute
              large
              attributeName={'Posted Comment'}
              attributeValue={document.reviewComment}
            />
          )}

          {document &&
            Object.entries(document).map(([key, value]) => {
              if (dictionary[key]) {
                if (value != null) {
                  if (dictionary[key].includes('Date') || dictionary[key].includes('On'))
                    return (
                      <SummaryAttribute
                        large
                        attributeName={dictionary[key]}
                        attributeValue={parseDate(value as string).format('MM/DD/YYYY')}
                      />
                    );
                  if (dictionary[key] === 'Action Taken') {
                    if (isRfiLike) return undefined;
                    return (
                      <SummaryAttribute
                        large
                        attributeName={dictionary[key]}
                        attributeValue={getActionTakenText(value)}
                      />
                    );
                  }
                  if (dictionary[key] === 'Title') {
                    if (isSubmittal)
                      return (
                        <SummaryAttribute
                          large
                          attributeName={'Description'}
                          attributeValue={value}
                        />
                      );
                  }
                  if (dictionary[key] === 'Description') {
                    if (isRfiLike || isSubmittal || isDrawing) return undefined;
                    if (isFieldReport)
                      return (
                        <SummaryAttribute
                          large
                          attributeName="General Observation"
                          attributeValue={value}
                        />
                      );
                  }
                  if (dictionary[key] === 'Proposed Cost Impact')
                    return (
                      <SummaryAttribute
                        large
                        attributeName={dictionary[key]}
                        attributeValue={formatMoney(Number(value))}
                      />
                    );
                  if (dictionary[key] === 'Proposed Time Impact')
                    return (
                      <SummaryAttribute
                        large
                        attributeName={dictionary[key]}
                        attributeValue={formatDays(value)}
                      />
                    );
                  if (dictionary[key] === 'Parties Present')
                    return (
                      <SummaryAttribute
                        large
                        attributeName={dictionary[key]}
                        attributeValue={value.join(', ')}
                      />
                    );

                  if (dictionary[key] === 'Reference Number') {
                    if (docType && docType in docTypesNoReferenceNumber) return null;
                    return (
                      <SummaryAttribute
                        large
                        attributeName={getReferenceNumberLabelFromDocumentType(docType)}
                        attributeValue={value}
                      />
                    );
                  }

                  if (
                    key === 'customContractor' &&
                    docType === DocumentTemplateType.WarrantyItems
                  ) {
                    return null;
                  }

                  if (key === 'punchListStatus') {
                    return (
                      <Grid
                        style={{
                          display: 'inline-flex',
                          width: '100%',
                          flexWrap: 'nowrap',
                          alignItems: 'center',
                          borderBottom: '1px solid #EDECEC',
                        }}
                        direction={isMobile ? 'column' : 'row'}
                      >
                        <Typography
                          variant="body2"
                          className={classes.responsibleMargin}
                          style={{
                            padding: '14px 16px',
                            minWidth: 275,
                            alignSelf: 'center',
                          }}
                        >
                          Status
                        </Typography>

                        <Box
                          style={{
                            flexGrow: 1,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {editStatus ? (
                            <TextField
                              select
                              variant="outlined"
                              value={inputStatus}
                              onChange={(e) =>
                                setInputStatus(e.target.value as PunchListStatusType)
                              }
                              style={{
                                paddingLeft: 16,
                                marginTop: 5,
                                marginBottom: 5,
                                minWidth: 280,
                              }}
                            >
                              <MenuItem disabled value={PunchListStatusType.New}>
                                New
                              </MenuItem>
                              <MenuItem
                                disabled={[
                                  PunchListStatusType.Accepted,
                                  PunchListStatusType.Rejected,
                                ].includes(document.punchListStatus!)}
                                value={PunchListStatusType.InProgress}
                              >
                                In Progress
                              </MenuItem>
                              <MenuItem
                                disabled={[
                                  PunchListStatusType.Accepted,
                                  PunchListStatusType.Rejected,
                                ].includes(document.punchListStatus!)}
                                value={PunchListStatusType.ReadyForVerification}
                              >
                                Ready for Verification
                              </MenuItem>
                              <MenuItem
                                disabled={
                                  !permission ||
                                  permission < SecurityPermissionLevel.NUMBER_3 ||
                                  document.punchListStatus === PunchListStatusType.Rejected
                                }
                                value={PunchListStatusType.Accepted}
                              >
                                Accepted
                              </MenuItem>
                              <MenuItem
                                disabled={
                                  !permission || permission < SecurityPermissionLevel.NUMBER_3
                                }
                                value={PunchListStatusType.Rejected}
                              >
                                Rejected
                              </MenuItem>
                            </TextField>
                          ) : (
                            <Typography
                              variant="body1"
                              style={{
                                paddingLeft: 16,
                                minWidth: isUnder760px ? 190 : 250,
                              }}
                            >
                              {status}
                            </Typography>
                          )}
                          {!isPublicPage() && userHasEditPermission() && (
                            <div style={{ flexGrow: 1, display: 'flex', marginLeft: 16 }}>
                              {editStatus ? (
                                isUnder760px ? (
                                  <>
                                    <IconButton
                                      color="inherit"
                                      onClick={() => setEditStatus(false)}
                                      style={{ marginRight: '8px', width: '40px', color: 'red' }}
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                    <IconButton
                                      color="inherit"
                                      onClick={handleEditWarrantyItemStatus}
                                      style={{ width: '40px', marginRight: '8px', color: 'green' }}
                                    >
                                      <DoneIcon />
                                    </IconButton>
                                  </>
                                ) : (
                                  <>
                                    <CancelButton
                                      onClick={() => setEditStatus(false)}
                                      style={{ marginRight: '8px', width: '100px' }}
                                    />
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={handleEditWarrantyItemStatus}
                                      style={{ width: '100px', marginRight: '8px' }}
                                    >
                                      Submit
                                    </Button>
                                  </>
                                )
                              ) : isUnder760px ? (
                                <IconButton
                                  color="primary"
                                  className={classes.buttonMargin}
                                  onClick={() => setEditStatus(true)}
                                >
                                  <EditIcon />
                                </IconButton>
                              ) : (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  className={classes.buttonMargin}
                                  onClick={() => setEditStatus(true)}
                                >
                                  Edit
                                </Button>
                              )}
                            </div>
                          )}
                        </Box>
                      </Grid>
                    );
                  }

                  return (
                    (!!value || value === 0) &&
                    dictionary[key] && (
                      <SummaryAttribute
                        large
                        attributeName={dictionary[key]}
                        attributeValue={value}
                      />
                    )
                  );
                } else {
                  if (isRfcLike && dictionary[key].endsWith('Impact'))
                    return (
                      <SummaryAttribute
                        large
                        attributeName={dictionary[key]}
                        attributeValue="TBD"
                      />
                    );
                }
              }
              return null;
            })}
          {docType === DocumentTemplateType.WarrantyItems ? (
            <>
              <Grid
                style={{
                  display: 'inline-flex',
                  width: '100%',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  borderBottom: '1px solid #EDECEC',
                }}
                direction={isMobile ? 'column' : 'row'}
              >
                <Typography
                  variant="body2"
                  className={classes.responsibleMargin}
                  style={{
                    padding: '14px 16px',
                    minWidth: 275,
                    alignSelf: 'center',
                  }}
                >
                  Responsible Subcontractor
                </Typography>

                <Box
                  style={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    {editSubcontractor ? (
                      <TextField
                        variant="outlined"
                        value={subcontractor}
                        onChange={(e) => setSubcontractor(e.target.value)}
                        style={{ marginLeft: 14 }}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        style={{
                          paddingLeft: 16,
                          minWidth: isUnder760px ? 190 : 250,
                        }}
                      >
                        {subcontractor || 'N/A'}
                      </Typography>
                    )}
                  </div>
                  {!isPublicPage() && userHasEditPermission() && (
                    <div style={{ flexGrow: 1, display: 'flex', marginLeft: 16 }}>
                      {editSubcontractor ? (
                        isUnder760px ? (
                          <>
                            <IconButton
                              color="inherit"
                              onClick={() => setEditSubcontractor(false)}
                              style={{ marginRight: '8px', width: '40px', color: 'red' }}
                            >
                              <CancelIcon />
                            </IconButton>
                            <IconButton
                              color="inherit"
                              onClick={handleEditResponsibleSubcontractor}
                              style={{ width: '40px', marginRight: '8px', color: 'green' }}
                            >
                              <DoneIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <CancelButton
                              onClick={() => setEditSubcontractor(false)}
                              style={{ marginRight: '8px', width: '100px' }}
                            />
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleEditResponsibleSubcontractor}
                              style={{ width: '100px', marginRight: '8px' }}
                            >
                              Submit
                            </Button>
                          </>
                        )
                      ) : isUnder760px ? (
                        <IconButton
                          color="primary"
                          className={classes.buttonMargin}
                          onClick={() => setEditSubcontractor(true)}
                        >
                          <EditIcon />
                        </IconButton>
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.buttonMargin}
                          onClick={() => setEditSubcontractor(true)}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid
                style={{
                  display: 'inline-flex',
                  width: '100%',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  borderBottom: '1px solid #EDECEC',
                }}
                direction={isMobile ? 'column' : 'row'}
              >
                <Typography
                  variant="body2"
                  className={classes.responsibleMargin}
                  style={{
                    padding: '14px 16px',
                    minWidth: 275,
                    alignSelf: 'center',
                  }}
                >
                  Expected Completion Date
                </Typography>

                <Box
                  style={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <MuiPickersUtilsProvider utils={DayjsUtils}>
                    {editExpectedCompletionDateOpen ? (
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        value={expectedCompletionDate}
                        format="MM/DD/YYYY"
                        minDate={Date.now()}
                        onChange={setExpectedCompletionDate}
                        style={{ paddingLeft: 16, marginBottom: 5, marginTop: 5 }}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        style={{
                          paddingLeft: 16,
                          minWidth: isUnder760px ? 190 : 250,
                        }}
                      >
                        {document?.expectedCompletionDate
                          ? parseDate(document.expectedCompletionDate).format('MM/DD/YYYY')
                          : 'N/A'}
                      </Typography>
                    )}
                  </MuiPickersUtilsProvider>
                  {!isPublicPage() && userHasEditPermission() && (
                    <div style={{ flexGrow: 1, display: 'flex', marginLeft: 16 }}>
                      {editExpectedCompletionDateOpen ? (
                        isUnder760px ? (
                          <>
                            <IconButton
                              color="inherit"
                              onClick={() => setEditExpectedCompletionDateOpen(false)}
                              style={{ marginRight: '8px', width: '40px', color: 'red' }}
                            >
                              <CancelIcon />
                            </IconButton>
                            <IconButton
                              color="inherit"
                              disabled={!expectedCompletionDate}
                              onClick={handleEditExpectedCompletionDate}
                              style={{ width: '40px', marginRight: '8px', color: 'green' }}
                            >
                              <DoneIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <CancelButton
                              onClick={() => setEditExpectedCompletionDateOpen(false)}
                              style={{ marginRight: '8px', width: '100px' }}
                            />
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={!expectedCompletionDate}
                              onClick={handleEditExpectedCompletionDate}
                              style={{ width: '100px', marginRight: '8px' }}
                            >
                              Submit
                            </Button>
                          </>
                        )
                      ) : isUnder760px ? (
                        <IconButton
                          color="primary"
                          className={classes.buttonMargin}
                          onClick={() => setEditExpectedCompletionDateOpen(true)}
                        >
                          <EditIcon />
                        </IconButton>
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.buttonMargin}
                          onClick={() => setEditExpectedCompletionDateOpen(true)}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  )}
                </Box>
              </Grid>
            </>
          ) : null}
          {document?.createdOn != null &&
            !(
              document.dateSubmitted &&
              parseDate(document.createdOn).format('MM/DD/YYYY') ===
                parseDate(document.dateSubmitted).format('MM/DD/YYYY')
            ) && (
              <SummaryAttribute
                attributeName={'Created on'}
                large
                attributeValue={parseDate(document.createdOn).format('MM/DD/YYYY')}
              />
            )}
          {bid?.createdOn != null && (
            <SummaryAttribute
              attributeName={'Submitted on'}
              large
              attributeValue={parseDate(bid.createdOn).format('MM/DD/YYYY hh:mm A z')}
            />
          )}
          {document?.priority != null &&
            document?.priority !== DocumentPriorityType.ForInformationOnly && (
              <Grid
                style={{
                  display: 'inline-flex',
                  width: '100%',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  borderBottom: '1px solid #EDECEC',
                }}
                direction={isMobile ? 'column' : 'row'}
              >
                <Typography
                  variant="body2"
                  className={classes.responsibleMargin}
                  style={{
                    padding: '14px 16px',
                    minWidth: 275,
                    alignSelf: 'center',
                  }}
                >
                  Priority
                </Typography>

                <Box
                  style={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {editPriority ? (
                    <TextField
                      select
                      variant="outlined"
                      value={priority}
                      onChange={(e) => setPriority(e.target.value as DocumentPriorityType)}
                      style={{ paddingLeft: 16, marginTop: 5, marginBottom: 5, minWidth: 280 }}
                    >
                      <MenuItem value={DocumentPriorityType.Low}>Low</MenuItem>
                      <MenuItem value={DocumentPriorityType.Medium}>Medium</MenuItem>
                      <MenuItem value={DocumentPriorityType.High}>High</MenuItem>
                    </TextField>
                  ) : (
                    <Typography
                      variant="body1"
                      style={{
                        paddingLeft: 16,
                        minWidth: isUnder760px ? 190 : 250,
                      }}
                    >
                      {getUserFriendlyDocumentPriority(document.priority)}
                    </Typography>
                  )}
                  {!isPublicPage() && userHasEditPermission() && (
                    <div style={{ flexGrow: 1, display: 'flex', marginLeft: 16 }}>
                      {editPriority ? (
                        isUnder760px ? (
                          <>
                            <IconButton
                              color="inherit"
                              onClick={() => setEditPriority(false)}
                              style={{ marginRight: '8px', width: '40px', color: 'red' }}
                            >
                              <CancelIcon />
                            </IconButton>
                            <IconButton
                              color="inherit"
                              onClick={handleEditPriority}
                              style={{ width: '40px', marginRight: '8px', color: 'green' }}
                            >
                              <DoneIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <CancelButton
                              onClick={() => setEditPriority(false)}
                              style={{ marginRight: '8px', width: '100px' }}
                            />
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleEditPriority}
                              style={{ width: '100px', marginRight: '8px' }}
                            >
                              Submit
                            </Button>
                          </>
                        )
                      ) : isUnder760px ? (
                        <IconButton
                          color="primary"
                          className={classes.buttonMargin}
                          onClick={() => setEditPriority(true)}
                        >
                          <EditIcon />
                        </IconButton>
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.buttonMargin}
                          onClick={() => setEditPriority(true)}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  )}
                </Box>
              </Grid>
            )}
          {document?.anticipatedInitialSubmissionDate != null && (
            <SummaryAttribute
              attributeName={'Anticipated Submission Date'}
              large
              attributeValue={parseDate(document.anticipatedInitialSubmissionDate).format(
                'MM/DD/YYYY',
              )}
            ></SummaryAttribute>
          )}
          {document?.actualSubmissionDate != null && (
            <SummaryAttribute
              attributeName={'Actual Submission Date'}
              large
              attributeValue={parseDate(document.actualSubmissionDate).format('MM/DD/YYYY')}
            ></SummaryAttribute>
          )}
          {document?.dateSubmitted != null && (
            <SummaryAttribute
              attributeName={'Date Submitted'}
              large
              attributeValue={parseDate(document.dateSubmitted).format('MM/DD/YYYY')}
            ></SummaryAttribute>
          )}
          {document?.dateApproved != null && (
            <SummaryAttribute
              attributeName={'Date Approved'}
              large
              attributeValue={parseDate(document.dateApproved).format('MM/DD/YYYY')}
            ></SummaryAttribute>
          )}
          {document?.dateSentOut != null && (
            <SummaryAttribute
              attributeName={'Date Sent Out'}
              large
              attributeValue={parseDate(document.dateSentOut).format('MM/DD/YYYY')}
            ></SummaryAttribute>
          )}
          {document?.dateReceived != null && (
            <SummaryAttribute
              attributeName={'Date Received'}
              large
              attributeValue={parseDate(document.dateReceived).format('MM/DD/YYYY')}
            ></SummaryAttribute>
          )}
          {document?.dueDate && (
            <Grid
              style={{
                display: 'inline-flex',
                width: '100%',
                flexWrap: 'nowrap',
                alignItems: 'center',
                borderBottom: '1px solid #EDECEC',
              }}
              direction={isMobile ? 'column' : 'row'}
            >
              <Typography
                variant="body2"
                className={classes.responsibleMargin}
                style={{
                  padding: '14px 16px',
                  minWidth: 275,
                  alignSelf: 'center',
                }}
              >
                Due On
              </Typography>

              <Box
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <MuiPickersUtilsProvider utils={DayjsUtils}>
                  {editDueDateOpen ? (
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      value={dueDate}
                      format="MM/DD/YYYY"
                      onChange={setDueDate}
                      style={{ paddingLeft: 16, marginBottom: 5, marginTop: 5 }}
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      style={{
                        paddingLeft: 16,
                        minWidth: isUnder760px ? 190 : 250,
                      }}
                    >
                      {parseDate(document.dueDate).format('MM/DD/YYYY')}
                    </Typography>
                  )}
                </MuiPickersUtilsProvider>
                {!isPublicPage() && canChangeDueDate() && (
                  <div style={{ flexGrow: 1, display: 'flex', marginLeft: 16 }}>
                    {editDueDateOpen ? (
                      isUnder760px ? (
                        <>
                          <IconButton
                            color="inherit"
                            onClick={() => setEditDueDateOpen(false)}
                            style={{ marginRight: '8px', width: '40px', color: 'red' }}
                          >
                            <CancelIcon />
                          </IconButton>
                          <IconButton
                            color="inherit"
                            onClick={handleChangeDueDate}
                            style={{ width: '40px', marginRight: '8px', color: 'green' }}
                          >
                            <DoneIcon />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <CancelButton
                            onClick={() => setEditDueDateOpen(false)}
                            style={{ marginRight: '8px', width: '100px' }}
                          />
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleChangeDueDate}
                            style={{ width: '100px', marginRight: '8px' }}
                          >
                            Submit
                          </Button>
                        </>
                      )
                    ) : isUnder760px ? (
                      <IconButton
                        color="primary"
                        className={classes.buttonMargin}
                        onClick={() => setEditDueDateOpen(true)}
                      >
                        <EditIcon />
                      </IconButton>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.buttonMargin}
                        onClick={() => setEditDueDateOpen(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                )}
              </Box>
            </Grid>
          )}
          {document?.dateReturnedToReviewer != null && (
            <SummaryAttribute
              attributeName={'Date Returned to Reviewer'}
              large
              attributeValue={parseDate(document.dateReturnedToReviewer).format('MM/DD/YYYY')}
            ></SummaryAttribute>
          )}
          {document?.dateReturnedToContractor != null && (
            <SummaryAttribute
              attributeName={getDateReturnedToContractorLabel()}
              large
              attributeValue={parseDate(document.dateReturnedToContractor).format('MM/DD/YYYY')}
            />
          )}
          {document?.dateContractorCompleted != null && (
            <SummaryAttribute
              attributeName={
                docType === DocumentTemplateType.WarrantyItems
                  ? 'Submitted for Verification'
                  : 'Date of Contractor Acceptance'
              }
              large
              attributeValue={parseDate(document.dateContractorCompleted).format('MM/DD/YYYY')}
            ></SummaryAttribute>
          )}
          {document?.dateOfLastUpdate != null && (
            <SummaryAttribute
              attributeName={'Last Updated'}
              large
              attributeValue={parseDate(document.dateOfLastUpdate).format('MM/DD/YYYY')}
            ></SummaryAttribute>
          )}
          {document?.dateFinalExecuted != null && (
            <SummaryAttribute
              attributeName={'Executed Final Date'}
              large
              attributeValue={parseDate(document.dateFinalExecuted).format('MM/DD/YYYY')}
            ></SummaryAttribute>
          )}
          {bid &&
            bid.projectUser?.primeBidder &&
            Object.entries(bid.projectUser.primeBidder).map(([key, value]) => {
              if (bidDictionary[key] === 'Address' && value) {
                return (
                  <>
                    <SummaryAttribute
                      large
                      attributeName="State"
                      attributeValue={value.stateOrProvince?.name}
                    />
                    <SummaryAttribute large attributeName="City" attributeValue={value.city} />
                    <SummaryAttribute
                      large
                      attributeName="Address"
                      attributeValue={value.address1}
                    />
                  </>
                );
              }
              if (bidDictionary[key] === 'Phone Number' && value) {
                return (
                  <>
                    <SummaryAttribute
                      large
                      attributeName="Phone Number"
                      attributeValue={formatPhoneNumber(value.number)}
                    />
                  </>
                );
              }
              return (
                value &&
                bidDictionary[key] && (
                  <SummaryAttribute
                    large
                    attributeName={bidDictionary[key]}
                    attributeValue={value}
                  />
                )
              );
            })}
          {bid &&
            bid.projectUser?.primeBidder &&
            bid.projectUser.primeBidder.contractorLicenseNo && (
              <SummaryAttribute
                large
                attributeName="Contractor License #"
                attributeValue={bid.projectUser.primeBidder.contractorLicenseNo}
              />
            )}

          {/* Empty column to keep creation date on right side */}
          {/* <SummaryAttribute attributeName="" attributeValue="" /> */}

          {document?.changeOrdersValue != null && (
            <SummaryAttribute
              large
              attributeName="Impact to Cost"
              attributeValue={formatMoney(document!.changeOrdersValue)}
            />
          )}
          {document?.changeOrdersDaysAdded != null && (
            <SummaryAttribute
              large
              attributeName="Impact to Time"
              attributeValue={formatDays(document.changeOrdersDaysAdded)}
            />
          )}
          {numOfSmallRows % 2 === 1 ? (
            <SummaryAttribute attributeName="" attributeValue="" />
          ) : null}
          {document?.dateOfObservation && (
            <SummaryAttribute
              large
              attributeName="Date Observed"
              attributeValue={parseDate(document.dateOfObservation).format('MM/DD/YYYY')}
            />
          )}
          {document?.lockedNotificationJson && (
            <>
              <SummaryAttribute
                large
                attributeName="Distributed"
                attributeValue={parseDate(document.lockedNotificationJson.createdOn!).format(
                  'MM/DD/YYYY hh:mm A',
                )}
                viewRecipients={() => setRecipientDialogOpen(true)}
              />
              <UserFilterListDialog
                title="Recipients"
                users={
                  document.lockedNotificationJson.userNotificationList
                    ?.map((notif) => notif.user!)
                    .sort((a, b) => ascendingComparator(a, b, 'name')) || []
                }
                dialogOpen={recipientDialogOpen}
                handleClose={() => setRecipientDialogOpen(false)}
                emptyText="No recipients of this Addendum."
              />
            </>
          )}
          {syncedWithProcore !== undefined && (
            <SummaryAttribute
              attributeName={'Synced With Procore'}
              large
              attributeValue={syncedWithProcore ? 'Yes' : 'No'}
            />
          )}
          {responseOnProcore !== undefined && (
            <SummaryAttribute
              attributeName={'Response On Procore'}
              large
              attributeValue={responseOnProcore ? 'Yes' : 'No'}
            />
          )}
        </Grid>
      </Collapse>
      <Snackbar
        open={snackOpen}
        autoHideDuration={2000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackOpen(false)} severity={snackType} variant="filled">
          {snackText}
        </Alert>
      </Snackbar>
      <UserFilterListDialog
        title="Edit Assigned User"
        dialogOpen={editDialogOpen}
        handleClose={handleEditDialogClose}
        users={responsiblePartyAssignees}
        userOnClick={handleEditAssigned}
        closeOnSelect
        isLoading={assigneesLoading}
      />
    </Card>
  );
}
