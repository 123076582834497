import logoSvg from '../../images/wordmark-blue.svg';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useEffect, useMemo, useState } from 'react';
import { Card, Theme, Typography } from '@material-ui/core';
import { disableRegistrationReminderByUserId } from '../../models/api/users';
import { disableRemindersByDocumentKey } from '../../models/api/documents';
import CircularLoader from '../loader/CircularLoader';
import { useLocation, useParams } from 'react-router';
import { RemindersMatchParams } from '../../scripts/utils';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      maxWidth: '160px',
      paddingTop: '2px',
      transition: 'filter .2s',
      '&:hover': {
        filter: 'opacity(85%)',
      },
    },
    card: {
      paddingLeft: 16,
      paddingRight: 16,
      [theme.breakpoints.up('sm')]: { width: '60%', height: '60vh' },
    },
  }),
);

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function ManageRemindersPage() {
  const classes = useStyles();
  const query = useQuery();
  const userId = query.get('userKey');
  const disableReminders = query.get('disableReminders') === 'true';
  const disableNotifications = query.get('disableNotifications') === 'true';
  const { key } = useParams<RemindersMatchParams>();

  const [isLoading, setIsLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState('');

  const handleDisableRegistrationReminder = async (userId: string) => {
    try {
      await disableRegistrationReminderByUserId(userId);
      setStatusMessage('You have successfully been unsubscribed from registration reminders.');
    } catch (e: any) {
      setStatusMessage('Something went wrong. Please try again or contact Centerline support.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisableDocumentEmails = async (
    key: string,
    disableReminders: boolean,
    disableNotifications: boolean,
  ) => {
    try {
      await disableRemindersByDocumentKey(key, disableReminders, disableNotifications);
      if (disableReminders && disableNotifications) {
        setStatusMessage(
          'You have successfully been unsubscribed from reminders and notifications from this document.',
        );
      }
      if (disableReminders && !disableNotifications) {
        setStatusMessage(
          'You have successfully been unsubscribed from reminders from this document.',
        );
      }
      if (!disableReminders && disableNotifications) {
        setStatusMessage(
          'You have successfully been unsubscribed from notifications from this document.',
        );
      }
    } catch (e: any) {
      setStatusMessage('Something went wrong. Please try again or contact Centerline support.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (key) {
      handleDisableDocumentEmails(key, disableReminders, disableNotifications);
    } else if (userId) {
      handleDisableRegistrationReminder(userId);
    } else {
      setStatusMessage(
        'Something went wrong. Please try again tomorrow or contact Centerline support. URL was invalid.',
      );
      setIsLoading(false);
    }
  }, [userId, key, disableReminders, disableNotifications]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
      <Card className={classes.card}>
        <img
          src={logoSvg}
          alt="centerline-logo"
          className={classes.logo}
          style={{ position: 'relative', left: 16, top: 12 }}
        />
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10vh' }}>
          {isLoading ? (
            <CircularLoader />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography style={{ fontSize: '16px', textAlign: 'center' }}>
                {statusMessage}
              </Typography>
              <Link href="/main" style={{ fontSize: '16px', color: '#3047A3', marginTop: '64px' }}>
                Return to Centerline
              </Link>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
