import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import { documentTypeToUrl } from '../../scripts/utils';
import { DocumentTemplateType } from '../../api-client/autogenerated';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: { height: 32, width: '20%' },
  }),
);

type Props = {
  open: boolean;
  handleClose: () => void;
  newDocumentId?: string;
};

export default function ASIConfirmationDialog(props: Props) {
  const classes = useStyles();
  const { open, handleClose, newDocumentId } = props;

  const history = useHistory();
  const project = useSelector(getProjectState);

  const navigateToPublishingCenter = () => {
    history.push(`/main/projects/${project?.id}/pub-center`);
  };

  const navigateToDocument = () => {
    if (!newDocumentId) handleClose();
    else {
      history.push(
        `/main/projects/${project?.id}/documents/${
          documentTypeToUrl[DocumentTemplateType.AsiDocuments]
        }/${newDocumentId}`,
      );
    }
  };

  return (
    <Dialog open={open}>
      <DialogContent>
        <Typography>
          You just uploaded an ASI document, would you like to conform this ASI into the existing
          drawings and specifications?
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-around' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={navigateToPublishingCenter}
          className={classes.button}
        >
          Yes
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={navigateToDocument}
          className={classes.button}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
