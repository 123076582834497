// @ts-nocheck
import React from 'react';
import homeIconWhite from './images/home-icon-white.png';
import announcementsIconWhite from './images/megaphone-icon-white.webp';
import subscriptionIconWhite from './images/paper-icon-white.webp';
import packageIconWhite from './images/package-icon-white.png';
import userIconWhite from './images/user-icon-white.png';
import centerlineLogoWhite from './images/centerline-logo-white.png';
import UserInfo from './user-info';
import '../css/admin.css';

function Banner(props) {
  let icon = homeIconWhite;
  const { currentPage } = props;
  if (currentPage === 'Administrators') icon = userIconWhite;
  else if (currentPage === 'Subscriptions') icon = subscriptionIconWhite;
  else if (currentPage === 'Packages') icon = packageIconWhite;
  else if (currentPage === 'Announcements') icon = announcementsIconWhite;
  return (
    <div className="header-bar">
      <div className="header-whitespace">
        <div className="centerline-branding">
          <img alt="" src={centerlineLogoWhite} className="centerline-logo" />
          <div>
            <h3 className="centerline-branding-title">CENTERLINE</h3>
          </div>
        </div>
      </div>
      <div className="current-view-container">
        <img alt="" src={icon} className="current-view-icon" />
        <span className="admin-header">{currentPage}</span>
      </div>
      <div className="header-whitespace">
        <UserInfo />
      </div>
    </div>
  );
}

export default Banner;
