import React from 'react';

const RefuseReturnIcon = (props: any) => {
  const { width = 14, height = 11, style, ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3851 7.35402L13.7862 4.95277C14.0713 4.66766 14.0713 4.2068 13.7862 3.92169C13.5011 3.63657 13.0403 3.63657 12.7552 3.92169L10.3541 6.32293L7.95301 3.92169C7.66792 3.63657 7.2071 3.63657 6.922 3.92169C6.6369 4.2068 6.6369 4.66766 6.922 4.95277L9.32308 7.35402L6.922 9.75526C6.6369 10.0396 6.6369 10.5012 6.922 10.7863C7.06418 10.9285 7.25084 11 7.43751 11C7.62417 11 7.81083 10.9285 7.95301 10.7863L10.3541 8.3851L12.7552 10.7863C12.8974 10.9285 13.084 11 13.2707 11C13.4573 11 13.644 10.9285 13.7862 10.7863C14.0713 10.5012 14.0713 10.0396 13.7862 9.75526L11.3851 7.35402ZM2.96271 6.33303C2.77751 6.33303 2.59231 6.26303 2.45012 6.12302L0.216751 3.9347C-0.0698031 3.65178 -0.0727197 3.19019 0.210918 2.90362C0.493097 2.61632 0.955376 2.61486 1.24193 2.89706L2.95761 4.57421L6.77469 0.714572C7.05905 0.430185 7.51987 0.427997 7.8057 0.710926C8.09153 0.995313 8.09298 1.45689 7.80862 1.74274L3.47968 6.11792C3.33677 6.26084 3.14938 6.33303 2.96271 6.33303Z"
        fill="#0947B9"
        {...rest}
      />
    </svg>
  );
};

export default RefuseReturnIcon;
