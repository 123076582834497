import React from 'react';
import { formatDate, formatMoney } from '../../scripts/utils';
import { IBidSetup } from '../../api-client/autogenerated';
import { Card, CardContent, createStyles, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

type Props = {
  bidSetup: IBidSetup | undefined;
};

const useStyles = makeStyles(() =>
  createStyles({
    titleStyle: {
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
      fontSize: 26,
    },
    flex: {
      display: 'flex',
      marginBottom: 4,
      borderBottom: '1px solid #C9C8C9',
    },
    key: {
      width: '35%',
    },
    value: {
      width: '65%',
    },
  }),
);

function ProjectDetails(props: Props) {
  const { bidSetup } = props;
  const classes = useStyles();

  const description = bidSetup?.project?.description || '';
  const contactCompanyName = bidSetup?.contactUser?.company?.name || '';
  const contactUserName = bidSetup?.contactUser?.name || '';
  const contactEmail = bidSetup?.contactUser?.email || '';
  const city = bidSetup?.address?.city || '';
  const state = bidSetup?.address?.stateOrProvince?.name || '';

  const liveDateFormatted = bidSetup?.liveAt ? formatDate(bidSetup.liveAt) : '';
  const preDateFormatted =
    bidSetup?.preDueDate && bidSetup?.hasPreBidConference ? formatDate(bidSetup.preDueDate) : '';
  const rfiDateFormatted = bidSetup?.requestDueDate ? formatDate(bidSetup.requestDueDate) : '';
  const addendaDateFormatted = bidSetup?.addendaDueDate ? formatDate(bidSetup.addendaDueDate) : '';
  const dueDateFormatted = bidSetup?.dueDate ? formatDate(bidSetup.dueDate) : '';

  return (
    <Card style={{ height: '100%' }}>
      <CardContent className={classes.titleStyle}>
        <h2
          className="h2"
          style={{
            textAlign: 'left',
            color: '#FFFFFF',
            margin: 0,
            lineHeight: 1,
            fontSize: 26,
          }}
        >
          Details
        </h2>
      </CardContent>
      <CardContent>
        <div className={classes.flex}>
          <Typography className={classes.key}>Location:</Typography>
          <Typography className={classes.value}>
            {city}, {state}
          </Typography>
        </div>
        <div className={classes.flex}>
          <Typography className={classes.key}>Description:</Typography>
          <Typography className={classes.value}>{description}</Typography>
        </div>
        <div className={classes.flex}>
          <Typography className={classes.key}>Budget:</Typography>
          <Typography className={classes.value}>
            {formatMoney(bidSetup?.estimatedConstructionCost ?? undefined)}
          </Typography>
        </div>
        <div className={classes.flex}>
          <Typography className={classes.key}>Project Contact:</Typography>
          <Typography className={classes.value}>
            {contactCompanyName}
            <br />
            {contactUserName}
            <br />
            {contactEmail}
          </Typography>
        </div>
        <div className={classes.flex}>
          <Typography className={classes.key}>Live:</Typography>
          <Typography className={classes.value}>{liveDateFormatted}</Typography>
        </div>
        <div className={classes.flex}>
          <Typography className={classes.key}>Prebid Conference:</Typography>
          <Typography className={classes.value}>{preDateFormatted || 'None'}</Typography>
        </div>
        <div className={classes.flex}>
          <Typography className={classes.key}>RFI/Subst. Request Deadline:</Typography>
          <Typography className={classes.value}>{rfiDateFormatted || 'None'}</Typography>
        </div>
        <div className={classes.flex}>
          <Typography className={classes.key}>Addenda Deadline:</Typography>
          <Typography className={classes.value}>{addendaDateFormatted || 'None'}</Typography>
        </div>
        <div className={classes.flex}>
          <Typography className={classes.key}>Bids Due:</Typography>
          <Typography className={classes.value}>{dueDateFormatted}</Typography>
        </div>
      </CardContent>
    </Card>
  );
}

export default ProjectDetails;
