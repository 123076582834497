import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
} from '@material-ui/core';
import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import { completeReviewByDocumentId, getDocumentById } from '../../models/api/documents';
import { ActionTakenType, INumberedDocumentView } from '../../api-client/autogenerated';
import { useDispatch } from 'react-redux';
import { reloadDocuments } from '../../features/documents/actions';
import CircularLoader from '../loader/CircularLoader';
import { reloadDocument } from '../../features/document/actions';
import { getActionTakenText } from '../../scripts/utils';

type Props = {
  dialogOpen: boolean;
  handleClose: () => void;
  selectedDocument: INumberedDocumentView;
  anchor?: any;
  inSummaryCard?: boolean;
};

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: 8,
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

const useStyles = makeStyles(
  createStyles({
    popover: {
      '.rootElement > *': {
        maxHeight: '5000px',
      },
    },
    section: {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      minWidth: '300px',
    },
    button: {
      width: 100,
      height: 36,
    },
  }),
);

export default function EditAsBuiltStatusDialog(props: Props) {
  const classes = useStyles();
  const { dialogOpen, handleClose, anchor, selectedDocument, inSummaryCard } = props;

  const dispatch = useDispatch();

  const [actionTaken, setActionTaken] = useState<ActionTakenType>();
  const [inputComment, setInputComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!actionTaken) return;

    try {
      setIsSubmitting(true);
      const document = await getDocumentById(selectedDocument.id);

      await completeReviewByDocumentId(document.id, {
        actionTaken,
        comment: inputComment,
      });

      dispatch(inSummaryCard ? reloadDocument() : reloadDocuments());
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setActionTaken(undefined);
    handleClose();
  };

  useEffect(() => {
    setActionTaken(undefined);
  }, []);

  return (
    <>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <Popover
          className={classes.popover}
          open={dialogOpen}
          onClose={handleClose}
          anchorEl={anchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box className={classes.section}>
              <Grid container direction="column">
                <FormControl fullWidth variant="outlined" style={{ marginBottom: 12 }}>
                  <InputLabel>Select Status</InputLabel>
                  <Select
                    input={<BootstrapInput />}
                    fullWidth
                    value={actionTaken}
                    onChange={(e) => {
                      setActionTaken(e.target.value as ActionTakenType);
                    }}
                  >
                    {[ActionTakenType.NoExceptionsTaken, ActionTakenType.ReviseAndResubmit].map(
                      (action) => (
                        <MenuItem key={action} value={action}>
                          {getActionTakenText(action)}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
                <TextField
                  variant="outlined"
                  label="Comment"
                  multiline
                  rows={4}
                  value={inputComment}
                  onChange={(e) => setInputComment(e.target.value)}
                  style={{ marginBottom: 12 }}
                />
                <Grid container style={{ justifyContent: 'space-between' }}>
                  <Button onClick={handleCancel} variant="outlined" className={classes.button}>
                    Cancel
                  </Button>
                  {!isSubmitting ? (
                    <Button
                      color="primary"
                      type="submit"
                      disabled={!actionTaken}
                      className={classes.button}
                      variant="contained"
                    >
                      Update
                    </Button>
                  ) : (
                    <div
                      className={classes.button}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <CircularLoader size={30} />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Box>
          </form>
        </Popover>
      </MuiPickersUtilsProvider>
    </>
  );
}
