import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import { Button, Card, Collapse, ListItem, ListItemText, Snackbar, Table } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import {
  DocumentTemplateType,
  IDocumentDocument,
  IFile,
  INumberedDocumentView,
} from '../../api-client/autogenerated';
import {
  DocumentFileTree,
  documentTypeToUrl,
  fileIsPdf,
  getDocumentTitleForAssociations,
  getDocumentTreesForAssociations,
  isPublicPage,
  openInNewTab,
} from '../../scripts/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentState } from '../../features/document/selectors';
import { Alert, Pagination } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import { Remove } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import CardContent from '@material-ui/core/CardContent';
import { getTemplateName } from '../../models/api/templates';
import { SmallButton } from '../custom-components/CustomButtons';
import { Link, useHistory } from 'react-router-dom';
import { getDocPermission } from '../../scripts/store-utils';
import AssociateDocumentDialog from '../add-associations/AssociateDocumentDialog';
import { getProjectState } from '../../features/project/selectors';
import { fetchDocument, updateDocument } from '../../features/document/actions';
import {
  getAssociatedDocumentsByDocumentId,
  modifyDocumentByIdWithResponse,
} from '../../models/api/documents';
import getHeaders from '../document-index/DocumentLogHeaders';
import DocumentIndexHeader from '../document-index/DocumentIndexHeader';
import {
  ascendingComparator,
  docTypeShouldOpenFile,
  getDocumentRows,
} from '../document-index/DocumentIndexUtils';
import DocumentIndexRow from '../document-index/DocumentIndexRow';
import { getDocumentsType } from '../../features/documents/selectors';
import { Color } from '@material-ui/lab/Alert';

const primaryColor = '#0947B9'; // blue
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'inline-flex',
      flexDirection: 'column',
      flexBasis: 'auto',
      zIndex: -1,
    },
    content: {
      display: 'flex',
      // flexDirection: 'column',
      flexBasis: 'auto',
      width: 'auto',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    noPadding: {
      padding: '0',
    },
    padding: {
      padding: 5,
    },
    box: {
      overflow: 'none',
      display: 'flex',
      flexDirection: 'column',
    },
    empty: {
      textTransform: 'none',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '21px',
      color: '#464546', // Gray / Gray 700
    },
    tablePagination: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '16px',
    },
    activityStatus: {
      fontWeight: 'bold',
      color: primaryColor,
    },
    company: {
      fontStyle: 'italic',
      color: '#949494', // gray
    },
    message: {
      color: '#000', // black
    },
    hover: {
      '&:hover': {
        background: '#E5EEFE !important',
        cursor: 'pointer',
      },
    },
    shortDate: {
      marginRight: theme.spacing(2),
      marginLeft: 0,
    },
    iconText: {
      color: '#000', // black
      paddingLeft: 0,
      fontSize: 14,
    },
    icon: {
      minWidth: 0,
      paddingRight: theme.spacing(2),
      marginLeft: 0,
    },
    title: {
      fontSize: 26,
      margin: 0,
      fontWeight: 500,
      color: '#464546',
      textTransform: 'none',
    },
  }),
);

type Props = {
  title?: string;
  documentIdOverride?: string;
  inDialog?: boolean;
  // isReverse: boolean;
};

const docTypesDisableAssociations = [
  DocumentTemplateType.BidDrawings,
  DocumentTemplateType.ProjectManual,
  DocumentTemplateType.Addenda,
  DocumentTemplateType.InformationalItems,
  DocumentTemplateType.PlanholderList,
  DocumentTemplateType.BidderRfIs,
  DocumentTemplateType.SubstitutionRequests,
];

const getStandardDocumentTypeFromPackageType = (docType: DocumentTemplateType) => {
  switch (docType) {
    case DocumentTemplateType.CloseoutSubmittalPackages:
      return DocumentTemplateType.CloseoutSubmittals;
    case DocumentTemplateType.SubmittalPackages:
      return DocumentTemplateType.Submittals;
    case DocumentTemplateType.AsBuiltPackages:
      return DocumentTemplateType.AsBuilt;

    default:
      return docType;
  }
};

const getPackageDocuments = async (
  document: INumberedDocumentView,
  docType: DocumentTemplateType,
) => {
  switch (docType) {
    case DocumentTemplateType.AsBuiltPackages:
    case DocumentTemplateType.CloseoutSubmittalPackages:
    case DocumentTemplateType.SubmittalPackages:
      return getDocumentRows(
        document.submittalChildDocuments || [],
        getStandardDocumentTypeFromPackageType(docType),
      );
    default:
      return undefined;
  }
};

export default function AssociatedDocumentList(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { title = '', documentIdOverride, inDialog = false } = props;
  const project = useSelector(getProjectState);
  const currentDocument = useSelector(getDocumentState);
  const documentType = useSelector(getDocumentsType);

  console.log(inDialog);

  const [collapseOpen, setCollapseOpen] = useState(true);
  const [associatedDocumentsList, setAssociatedDocumentsList] = useState<
    {
      title: string;
      id: string;
      type: DocumentTemplateType;
      projectId: string;
      row: any;
      packageItems?: any[];
    }[]
  >([]);
  const [addAssociationDialogOpen, setOpenAddAssociationDialog] = useState(false);
  const [documentFileTrees, setDocumentFileTrees] = useState<DocumentFileTree[]>([]);
  const [page, setPage] = useState(0);
  const [rowsLength, setRowsLength] = useState(10);

  const [overrideAssociatedDocuments, setOverrideAssociatedDocuments] = useState<
    IDocumentDocument[]
  >([]);
  const [overrideReverseAssociatedDocuments, setOverrideReverseAssociatedDocuments] = useState<
    IDocumentDocument[]
  >([]);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState<Color>('success');
  const [snackMessage, setSnackMessage] = useState<string>();

  const addSnackbar = (severity: Color, message: string) => {
    setSnackSeverity(severity);
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const trueDocumentId = inDialog ? documentIdOverride : currentDocument?.id;

  useEffect(() => {
    if (addAssociationDialogOpen && project && trueDocumentId) {
      getDocumentTreesForAssociations(project.id, [
        trueDocumentId,
        ...associatedDocumentsList.map((doc) => doc.id),
      ]).then(setDocumentFileTrees);
    }
  }, [addAssociationDialogOpen, project, trueDocumentId]);

  const fetchAssociatedDocuments = async () => {
    if (!trueDocumentId) return;
    const {
      associatedDocumentsList,
      reverseAssociatedDocumentsList,
    } = await getAssociatedDocumentsByDocumentId(trueDocumentId);
    setOverrideAssociatedDocuments(associatedDocumentsList);
    setOverrideReverseAssociatedDocuments(reverseAssociatedDocumentsList);
  };

  useEffect(() => {
    if (inDialog && trueDocumentId) {
      fetchAssociatedDocuments();
    }
  }, [inDialog, trueDocumentId]);

  useEffect(() => {
    (async () => {
      const associatedDocuments: {
        title: string;
        id: string;
        type: DocumentTemplateType;
        projectId: string;
        row: any;
        packageItems?: any[];
      }[] = [];

      if (!trueDocumentId) return;

      let combinedAssociatedDocumentsList: IDocumentDocument[];

      // don't show associations with a document that is hidden or a draft
      if (inDialog) {
        const filteredReverseAssociatedDocumentsList = overrideReverseAssociatedDocuments.filter(
          (d) => !d.baseDocument?.isHidden && !d.baseDocument?.isDraft,
        );
        const filteredAssociatedDocumentsList = overrideAssociatedDocuments.filter(
          (d) => !d.associatedDocument?.isHidden && !d.associatedDocument?.isDraft,
        );
        combinedAssociatedDocumentsList = [
          ...filteredReverseAssociatedDocumentsList,
          ...filteredAssociatedDocumentsList,
        ];
      } else {
        const filteredReverseAssociatedDocumentsList = (
          currentDocument?.reverseAssociatedDocumentsList || []
        ).filter((d) => !d.baseDocument?.isHidden && !d.baseDocument?.isDraft);
        const filteredAssociatedDocumentsList = (
          currentDocument?.associatedDocumentsList || []
        ).filter((d) => !d.associatedDocument?.isHidden && !d.associatedDocument?.isDraft);
        combinedAssociatedDocumentsList = [
          ...filteredReverseAssociatedDocumentsList,
          ...filteredAssociatedDocumentsList,
        ];
      }

      await Promise.all(
        combinedAssociatedDocumentsList.map(async ({ associatedDocument, baseDocument }) => {
          let combinedAssociatedDocument: INumberedDocumentView | undefined;
          if (associatedDocument && associatedDocument.id !== trueDocumentId) {
            combinedAssociatedDocument = associatedDocument;
          } else if (baseDocument && baseDocument.id !== trueDocumentId) {
            combinedAssociatedDocument = baseDocument;
          }
          if (combinedAssociatedDocument) {
            const documentTemplateName = await getTemplateName(
              combinedAssociatedDocument.documentTemplateId,
            );
            const tempTitle = getDocumentTitleForAssociations(
              combinedAssociatedDocument,
              documentTemplateName,
              false,
            );
            // returns a title for us to display and an id for us to navigate. for each of the reference objects
            associatedDocuments.push({
              title: tempTitle,
              id: combinedAssociatedDocument!.id,
              type: documentTemplateName,
              projectId: combinedAssociatedDocument!.projectId,
              row: (await getDocumentRows([combinedAssociatedDocument], documentTemplateName))[0],
              packageItems: await getPackageDocuments(
                combinedAssociatedDocument,
                documentTemplateName,
              ),
            });
          }
        }),
      );
      setAssociatedDocumentsList(
        associatedDocuments.sort((a, b) => ascendingComparator(a, b, 'type')),
      );
    })();
  }, [
    currentDocument,
    overrideAssociatedDocuments,
    overrideReverseAssociatedDocuments,
    trueDocumentId,
  ]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  // TODO: double check permission level
  const hasEditPermission = () => {
    const permission = getDocPermission();
    return permission && permission >= 2;
  };

  const handleAddAssociationDialogOpen = () => {
    setOpenAddAssociationDialog(true);
  };

  const handleAddAssociationDialogClose = () => {
    setOpenAddAssociationDialog(false);
  };

  const handleDeleteAssociation = async (event: React.MouseEvent, associatedDocumentId: string) => {
    try {
      if (!trueDocumentId) return;

      const { updatedDocument } = await modifyDocumentByIdWithResponse(trueDocumentId, {
        associatedDocuments: { removeIds: [associatedDocumentId] },
      });
      if (!inDialog) {
        dispatch(updateDocument(updatedDocument));
      } else {
        fetchAssociatedDocuments();
      }

      addSnackbar('success', 'Association successfully removed');
    } catch (e: any) {}
  };

  const handleSelectDocument = async (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    id: string,
    docType: DocumentTemplateType,
    file?: IFile,
  ) => {
    if (docType && docTypeShouldOpenFile.includes(docType) && file && fileIsPdf(file)) {
      openInNewTab(file.id);
    } else if (docType === DocumentTemplateType.PunchList) {
      history.push(`/main/projects/${project?.id}/documents/punch-list/${id}/edit`);
    } else {
      dispatch(fetchDocument(id));
      history.push(`/main/projects/${project?.id}/documents/${documentTypeToUrl[docType!]}/${id}`);
    }
  };

  return (
    <Card className={classes.root} style={inDialog ? { boxShadow: 'none' } : {}}>
      <CardContent className={classes.content}>
        <h2 className={inDialog ? classes.title : 'h2'}>Documents associated with {title}</h2>

        {!isPublicPage() &&
          hasEditPermission() &&
          documentType &&
          !docTypesDisableAssociations.includes(documentType) && (
            <SmallButton
              variant="outlined"
              color="primary"
              style={{ marginLeft: '36px', marginRight: '4px', width: 100 }}
              onClick={handleAddAssociationDialogOpen}
            >
              Add
            </SmallButton>
          )}

        <div style={{ display: 'inline-flex', flexGrow: 100 }} />
        {!inDialog ? (
          <IconButton style={{ padding: 0 }}>
            {collapseOpen ? (
              <Remove htmlColor="#0947B9" onClick={() => setCollapseOpen(false)} />
            ) : (
              <Add htmlColor="#0947B9" onClick={() => setCollapseOpen(true)} />
            )}
          </IconButton>
        ) : null}
      </CardContent>
      <Collapse in={collapseOpen}>
        <Box className={classes.box}>
          <List style={{ padding: '16px' }}>
            {associatedDocumentsList.length === 0 && (
              <div className={classes.empty}>No related documents for this document.</div>
            )}
            {associatedDocumentsList.length !== 0 &&
              associatedDocumentsList
                .sort((a, b) => ascendingComparator(a, b, 'title'))
                .slice(
                  page * rowsLength,
                  Math.min(page * rowsLength + rowsLength, associatedDocumentsList!.length),
                )
                .map((associatedDocument, index) => {
                  return (
                    <div key={`${associatedDocument.id}`}>
                      <ListItem alignItems="flex-start" className={classes.noPadding}>
                        <ListItemText
                          primary={
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  marginTop: 16,
                                  marginBottom: 16,
                                  paddingLeft: 16,
                                }}
                              >
                                <Link
                                  to={`/main/projects/${associatedDocument.projectId}/documents/${
                                    documentTypeToUrl[associatedDocument.type]
                                  }/${associatedDocument.id}${
                                    associatedDocument.type === DocumentTemplateType.PunchList
                                      ? '/edit'
                                      : ''
                                  }`}
                                >
                                  {associatedDocument.title}
                                </Link>
                                <Button
                                  variant="outlined"
                                  color="inherit"
                                  onClick={(event) =>
                                    handleDeleteAssociation(event, associatedDocument.id)
                                  }
                                  disableElevation
                                  style={{
                                    height: '32px',
                                    color: '#ED3F26',
                                    width: '130px',
                                    border: '2px solid #ED3F26',
                                    borderRadius: '4px',
                                    margin: 1,
                                  }}
                                >
                                  <DeleteIcon style={{ marginRight: '6px' }} /> Remove
                                </Button>
                              </div>
                              <div style={{ overflowX: 'auto' }}>
                                <Table size="small">
                                  <DocumentIndexHeader
                                    columnTitles={getHeaders(associatedDocument.type)}
                                    hideCheckboxes
                                    order="desc"
                                    orderBy="number"
                                    numSelected={0}
                                    filteredRows={[]}
                                    onSelectAllClick={() => {}}
                                    onRequestSort={() => {}}
                                    rowCount={0}
                                  />
                                  <DocumentIndexRow
                                    isAssociatedDocumentsList
                                    trueDocType={associatedDocument.type}
                                    row={associatedDocument.row}
                                    packageItems={associatedDocument.packageItems}
                                    index={0}
                                    onSelect={(event, id, file) =>
                                      handleSelectDocument(event, id, associatedDocument.type, file)
                                    }
                                    isEditingCost={false}
                                    updateRowCost={() => {}}
                                  />
                                </Table>
                              </div>
                            </div>
                          }
                        />
                      </ListItem>
                      {index !== associatedDocumentsList!.length - 1 && <Divider component="li" />}
                    </div>
                  );
                })}
          </List>
          <div style={{ flexGrow: 1 }} />
          {Math.ceil(associatedDocumentsList.length / rowsLength) > 1 && (
            <Pagination
              count={Math.ceil(associatedDocumentsList.length / rowsLength)}
              shape="rounded"
              color="primary"
              showFirstButton
              showLastButton
              page={page + 1}
              onChange={handleChangePage}
              className={classes.tablePagination}
            />
          )}
        </Box>
      </Collapse>
      <AssociateDocumentDialog
        documents={documentFileTrees}
        setDocuments={setDocumentFileTrees}
        open={addAssociationDialogOpen}
        handleClose={handleAddAssociationDialogClose}
        documentId={trueDocumentId}
        onComplete={() => {
          fetchAssociatedDocuments();
          addSnackbar('success', 'Successfully added new associations');
        }}
      />
      <Snackbar
        open={snackOpen}
        autoHideDuration={5000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert variant="filled" onClose={() => setSnackOpen(false)} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
}
