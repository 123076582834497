import { action } from 'typesafe-actions';
import { Dispatch } from 'react';
import { SET_PROJECT, UPDATE_PROJECT } from './constants';
import { Project } from './project';
import { getProjectById } from '../../models/api/project';
import { fetchDocumentsByType, fetchSubmittalCodes } from '../documents/actions';
import { fetchCompanies } from '../companies/actions';
import { fetchGroups } from '../groups/actions';
import { fetchSubscriberSecurityGroups } from '../security/actions';
import { getPhysicalBuildingsByProjectId } from '../../models/api/punch-list';
import { IProjectView } from '../../api-client/autogenerated';
import { fetchProductPackage, setProductPackage } from '../product-package/actions';

export const setProject = (project: Project = null) => {
  return action(SET_PROJECT, project);
};

export const fetchProject = (projectId: string): any => (
  dispatch: Dispatch<any>,
  getState: () => any,
) => {
  dispatch({ type: 'SET_PROJECT_LOADING', payload: true });
  return getProjectById(projectId)
    .then((response) => {
      const ret = dispatch({ type: SET_PROJECT, payload: response });
      if (getState().documents.documents.length > 0)
        dispatch(fetchDocumentsByType(response.id, getState().documents.type));
      dispatch(fetchSubscriberSecurityGroups(response.subscriberId));
      dispatch(fetchCompanies(response.id));
      dispatch(fetchGroups(response.id, response.projectUserList || []));
      dispatch(fetchSubmittalCodes(response.id));
      if (response.subscriber?.productPackage)
        dispatch(setProductPackage(response.subscriber.productPackage));
      else if (response.subscriber?.productPackageId)
        dispatch(fetchProductPackage(response.subscriber.productPackageId));
      return ret;
    })
    .catch(() => {})
    .finally(() => dispatch({ type: 'SET_PROJECT_LOADING', payload: false }));
};

export const reloadProject = (): any => (dispatch: Dispatch<any>, getState: () => any) => {
  const { project } = getState().project;
  if (project?.id) return dispatch(fetchProject(project.id));
};

export const reloadPunchListLocations = (): any => (
  dispatch: Dispatch<any>,
  getState: () => any,
) => {
  const { project } = getState().project;
  if (project?.id) {
    return getPhysicalBuildingsByProjectId(project.id, 0, 1000).then((res) =>
      dispatch({ type: UPDATE_PROJECT, payload: { physicalBuildings: res.results } }),
    );
  }
};

export const updateProject = (project: Partial<IProjectView>) => {
  return action(UPDATE_PROJECT, project);
};
