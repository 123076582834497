import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  FormControl,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { HighlightOffRounded, Search } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import {
  DCCRow,
  getDocumentFileIdPropertyFromPublishType,
  getDocumentNumberPropertyFromPublishType,
  getDocumentTemplateTypeFromPublishType,
  getDocumentTitlePropertyFromPublishType,
  getSortPropertyFromPublishType,
  PublishType,
} from '../document-conforming-center/DCCUtils';
import { useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import { getConformingCenterDocumentsByType } from '../../models/api/dcc';
import { getFileBlob, getUserFriendlyDocumentTemplateNamePlural } from '../../scripts/utils';
import FilePreviewDialog from './FilePreviewDialog';
import { ascendingComparator } from '../document-index/DocumentIndexUtils';
import { PublishStatusType } from '../../api-client/autogenerated';
import CircularLoader from '../loader/CircularLoader';
import { SubmitButton } from '../custom-components/CustomButtons';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { modifyDocumentsIndividually } from '../../models/api/documents';

const useStyles = makeStyles(() =>
  createStyles({
    rootIconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
);

type SimpleRow = {
  id: string;
  title: string;
  number: string;
  pageNumber?: number;
  pdfFileId: string;
};

type PublishedSectionsDialogProps = {
  type: PublishType;
  open: boolean;
  handleClose: () => void;
  row: DCCRow;
  refreshData: () => Promise<any>;
};

export default function PublishedSectionsDialog(props: PublishedSectionsDialogProps) {
  const classes = useStyles();
  const { type, open, handleClose, row, refreshData } = props;

  const project = useSelector(getProjectState);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<string | null>(null);

  const [search, setSearchValue] = useState('');
  const [publishedDocuments, setPublishedDocuments] = useState<SimpleRow[]>([]);

  const [compareDialogOpen, setCompareDialogOpen] = useState(false);
  const [newFile, setNewFile] = useState<File>();
  const [existingFile, setExistingFile] = useState<File>();

  const fetchDocuments = async (projectId: string, type: PublishType) => {
    setIsLoading(true);
    const documents = await getConformingCenterDocumentsByType(
      projectId,
      getDocumentTemplateTypeFromPublishType(type),
    );
    const publishedDocuments = documents.filter(
      (d) => d.document.publishStatus === PublishStatusType.Published,
    );
    setPublishedDocuments(
      publishedDocuments.map(({ document }) => ({
        id: document.id,
        number: document[getDocumentNumberPropertyFromPublishType(type)] || 'N/A',
        title: document[getDocumentTitlePropertyFromPublishType(type)] || 'N/A',
        pageNumber: document.pageNumber || undefined,
        pdfFileId: document[getDocumentFileIdPropertyFromPublishType(type)] || '',
      })),
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (project && open) {
      fetchDocuments(project.id, type);
    }
  }, [project, type, open]);

  const handleCompare = async (publishedRow: SimpleRow) => {
    setCompareDialogOpen(true);
    const newFileBlob = await getFileBlob(row.pdfFileId);
    const existingFileBlob = await getFileBlob(publishedRow.pdfFileId);
    if (newFileBlob) {
      setNewFile(
        new File([newFileBlob], `${row.number} - ${row.title}`, { type: 'application/pdf' }),
      );
    }
    if (existingFileBlob) {
      setExistingFile(
        new File([existingFileBlob], `${publishedRow.number} - ${publishedRow.title}`, {
          type: 'application/pdf',
        }),
      );
    }
  };

  const handlePublish = async (publishedRow: SimpleRow) => {
    setIsSubmitting(publishedRow.id);
    await modifyDocumentsIndividually([
      {
        documentId: publishedRow.id,
        modification: { patch: { [getDocumentNumberPropertyFromPublishType(type)]: row.number } },
      },
      {
        documentId: row.id,
        modification: { patch: { isDraft: false } },
      },
    ]);
    setIsSubmitting(null);
    refreshData();
    handleClose();
  };

  const getTitle = () => {
    switch (type) {
      case PublishType.Drawings:
        return 'Drawing Sheets';
      case PublishType.Specifications:
        return 'Specification Sections';
      case PublishType.Submittals:
        return 'Submittal Placeholders';
    }
  };

  const getNumberTableHeaderLabel = () => {
    switch (type) {
      case PublishType.Drawings:
        return 'Sheet Number';
      case PublishType.Specifications:
      case PublishType.Submittals:
        return 'Section Number';
    }
  };

  const getTitleTableHeaderLabel = () => {
    switch (type) {
      case PublishType.Drawings:
        return 'Sheet Name';
      case PublishType.Specifications:
      case PublishType.Submittals:
        return 'Description';
    }
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose} fullWidth>
      <IconButton
        style={{ right: '8px', top: '8px', position: 'absolute' }}
        onClick={handleClose}
        classes={{
          root: classes.rootIconButton,
        }}
      >
        <HighlightOffRounded />
      </IconButton>
      <DialogContent
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #D8D8D8',
          padding: '15px 24px',
        }}
      >
        <Typography style={{ fontWeight: 500, fontSize: 20 }}>Published {getTitle()}</Typography>
        <FormControl style={{ marginRight: 16 }}>
          <TextField
            InputProps={{
              style: { height: 25 },
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ color: '#B2B1B2' }} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            fullWidth
            required
            id="search"
            placeholder="Search"
            name="Search"
            value={search}
            onChange={(event) => setSearchValue(event.target.value as string)}
          />
        </FormControl>
      </DialogContent>
      <DialogContent style={{ padding: 0 }}>
        <TableContainer style={{ maxHeight: '450px' }}>
          <Table aria-label="dense">
            <TableHead>
              <TableCell align="left">{getNumberTableHeaderLabel()}</TableCell>
              <TableCell align="left">{getTitleTableHeaderLabel()}</TableCell>
            </TableHead>
            <TableBody>
              {!isLoading ? (
                publishedDocuments
                  .filter((doc) => {
                    if (!search) return true;
                    return (
                      doc.number.toLowerCase().includes(search.toLowerCase()) ||
                      doc.title.toLowerCase().includes(search.toLowerCase())
                    );
                  })
                  .sort((a, b) => ascendingComparator(a, b, getSortPropertyFromPublishType(type)))
                  .map((row) => {
                    return (
                      <TableRow hover key={row.id} role="checkbox">
                        <TableCell align="left">{row.number}</TableCell>
                        <TableCell align="left">{row.title}</TableCell>
                        <TableCell align="right">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              color="primary"
                              variant="contained"
                              disabled={isSubmitting !== null}
                              onClick={() => handleCompare(row)}
                              style={{ padding: '8px 15px', marginRight: 15 }}
                            >
                              Compare
                            </Button>
                            {isSubmitting !== row.id ? (
                              <SubmitButton
                                color="primary"
                                variant="contained"
                                disabled={isSubmitting !== null}
                                onClick={() => handlePublish(row)}
                                style={{
                                  padding: '8px 15px',
                                  marginRight: 15,
                                }}
                              >
                                Replace and Publish
                              </SubmitButton>
                            ) : (
                              <CircularLoader size={30} style={{ width: 205 }} />
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={100} style={{ height: 100 }}>
                    <CircularLoader fullWidth />
                  </TableCell>
                </TableRow>
              )}
              {!isLoading && publishedDocuments.length === 0 && (
                <Typography style={{ marginLeft: 16, marginBottom: 16 }}>
                  No published{' '}
                  {getUserFriendlyDocumentTemplateNamePlural(
                    getDocumentTemplateTypeFromPublishType(type),
                  )}{' '}
                  documents exist in this project.
                </Typography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <FilePreviewDialog
        open={compareDialogOpen}
        handleClose={() => setCompareDialogOpen(false)}
        file={newFile}
        secondaryFile={existingFile}
      />
    </Dialog>
  );
}
