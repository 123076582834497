import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Add } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import AddUserDialog from '../dialogs/AddUserDialog';
import { IProjectUser, IProjectView, ISecurityGroup } from '../../api-client/autogenerated';
import { UserActionType } from '../form-pages/AddOrManageProjectPage';
import { getCurrentSecurityGroup } from '../../features/security/selectors';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '16px',
      flexWrap: 'wrap',
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      marginBottom: '8px',
      marginRight: '16px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '30px',
      color: '#464546',
      textTransform: 'none',
      whiteSpace: 'nowrap',
      flexGrow: 1,
    },
    button: {
      display: 'inline-flex',
      padding: '6px 6px 6px 12px',
      whiteSpace: 'nowrap',
    },
    textfield: {
      height: '32px',

      /* Gray / Gray 50 */
      background: '#F9F9F9',

      /* Gray / Gray 400 Brand dark */
      border: '1px solid #949494',
      'border-radius': '5px',
    },
    icon: {
      fill: '#B2B1B2',
      marginLeft: '4px',
    },
    formControl: {
      display: 'inline-flex',
      width: '18%',
      marginRight: '8px',
      flexGrow: 1,
    },
  }),
);

interface UserIndexToolbarProps {
  label: string;
  selectedProject: IProjectView | null;
  users: IProjectUser[] | undefined;
  reloadUsers: () => void;
  createProjectPage?: boolean;
  createProjectUser?: (projectUser: IProjectUser, action?: UserActionType) => void;
  createProjectUsers: (projectUser: IProjectUser[], action?: UserActionType) => void;

  securityGroups: ISecurityGroup[];
}

export default function UserIndexToolbar(props: UserIndexToolbarProps) {
  const classes = useToolbarStyles();
  const {
    selectedProject,
    users,
    reloadUsers,
    createProjectPage,
    createProjectUser = () => {},
    createProjectUsers,
    securityGroups,
    label,
  } = props;

  const user = useSelector(getUserState);
  const security = useSelector(getCurrentSecurityGroup);

  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);

  const handleAddUserDialogOpen = () => {
    setAddUserDialogOpen(true);
  };
  const handleAddUserDialogClose = () => {
    setAddUserDialogOpen(false);
  };

  const getTitle = () => {
    if (createProjectPage) {
      return label;
    }
    if (selectedProject) return 'Users in '.concat(selectedProject!.name!);
    return 'No project selected';
  };

  return (
    <Toolbar className={clsx(classes.toolbar)} style={{ paddingLeft: 0 }}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {getTitle()}
      </Typography>
      <div
        style={{
          display: 'flex',
          marginTop: '8px',
          maxWidth: 350,
        }}
      >
        {(user.isSiteAdmin ||
          user.adminOfSubscriberId ||
          !!security?.manageProjectUsersPermission) && (
          <Button
            id="add-user-button"
            variant="contained"
            color="primary"
            style={{ marginLeft: '8x', marginRight: '4px' }}
            onClick={handleAddUserDialogOpen}
          >
            <Add />
            ADD USER
          </Button>
        )}

        <AddUserDialog
          selectedProjectId={!selectedProject ? '' : selectedProject.id}
          dialogOpen={addUserDialogOpen}
          handleClose={handleAddUserDialogClose}
          users={users}
          reloadUsers={reloadUsers}
          createProjectPage={createProjectPage}
          callback={createProjectUser}
          createProjectUsers={createProjectUsers}
          securityGroups={securityGroups}
        />
      </div>
    </Toolbar>
  );
}
