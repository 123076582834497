import React from 'react';

const DesignIcon = (props: any) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.42 18.852a.59.59 0 01-.815.475l-1.983-.855-2.867-5.012a9.859 9.859 0 01-3.406.624 9.75 9.75 0 01-3.54-.68L3.91 18.472l-1.984.855a.59.59 0 01-.815-.475L.853 16.69l2.938-5.135c-.744-.65-1.418-1.39-1.949-2.254a.303.303 0 01.106-.416l.51-.298a.289.289 0 01.393.102c.425.69.955 1.288 1.536 1.824l2.792-4.881a3.546 3.546 0 01-.443-1.695c0-1.967 1.58-3.562 3.53-3.562 1.95 0 3.53 1.595 3.53 3.562 0 .618-.17 1.19-.443 1.695l2.857 4.994a8.841 8.841 0 001.636-1.937.29.29 0 01.394-.103l.51.298a.303.303 0 01.105.416 10.024 10.024 0 01-2.05 2.368l2.874 5.023-.258 2.161zM7.914 3.937a2.364 2.364 0 002.353 2.375c1.3 0 2.353-1.063 2.353-2.375a2.364 2.364 0 00-2.353-2.374 2.364 2.364 0 00-2.353 2.374zm6.34 8.017l-2.694-4.709a3.503 3.503 0 001.003-.622l2.709 4.736c-.327.222-.666.42-1.019.595zM2.067 16.941l.118.983.901-.389 5.886-10.29a3.504 3.504 0 01-1.003-.622L2.068 16.941zm8.28-4.045c-1.019 0-2.011-.19-2.945-.53l2.788-4.873.074.007.075-.007 2.815 4.92a8.685 8.685 0 01-2.806.483zm7.097 4.64l.901.388.117-.983-2.605-4.555c-.328.219-.667.418-1.017.597l2.604 4.552z"
      fill={props.fill ? props.fill : '#73A3FF'}
    />
  </svg>
);

export default DesignIcon;
