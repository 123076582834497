/* eslint-disable react/no-unused-state */
// TODO: How does isActive work? Why is the above necessary?
// @ts-nocheck
import React from 'react';

export default class SidebarItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isActive: true };
    const { sidebar } = this.props;
    sidebar.items.push(this);

    this.updateActive = () => {
      this.setState({ isActive: true });
    };
  }

  render() {
    let classes;
    let imageActiveClasses;
    let imageInactiveClasses;
    const { iconInactive, iconActive, sidebar, name, clickFunction } = this.props;
    if (name === sidebar.mainApp.state.selected) {
      classes = 'sidebar-item sidebar-item-active';
      imageActiveClasses = 'sidebar-item-image visible';
      imageInactiveClasses = 'sidebar-item-image not-visible';
    } else {
      classes = 'sidebar-item';
      imageActiveClasses = 'sidebar-item-image not-visible';
      imageInactiveClasses = 'sidebar-item-image visible';
    }
    return (
      <>
        <div
          className={classes}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => clickFunction(e)}
          onClick={(e) => clickFunction(e)}
        >
          <div className="sidebar-item-image-container-container">
            <div className="sidebar-item-image-container">
              <img alt="" className={imageActiveClasses} src={iconActive} />
              <img alt="" className={imageInactiveClasses} src={iconInactive} />
            </div>
          </div>
          <div className="sidebar-text">{name}</div>
        </div>
      </>
    );
  }
}
