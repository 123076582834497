import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Add, Clear, HighlightOffRounded } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import PDFIcon from '../icons/PDF-icon';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPhysicalBuildingsState,
  getPhysicalFloorsState,
  getProjectState,
} from '../../features/project/selectors';
import LabelWithCheckbox from '../custom-components/LabelWithCheckbox';
import { IPhysicalFloor } from '../../api-client/autogenerated';
import {
  editManyPhysicalFloors,
  insertFloorPlanDrawingByProjectId,
} from '../../models/api/punch-list';
import { reloadPunchListLocations } from '../../features/project/actions';
import { CancelButton } from '../custom-components/CustomButtons';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';

type Props = {
  open: boolean;
  onClose: () => void;
  defaultBuildingId?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    removeIcon: {
      color: '#f5f5f5',
      background: '#0947B9',
    },
    rootIconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    dragDropText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '22px',
      lineHeight: '0',
      textAlign: 'center',
      textTransform: 'none',
      color: '#949494', // Gray 400
    },
    dialogTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '30px',
      textAlign: 'left',
      color: '#0947B9',
      textTransform: 'none',
    },
    browseFileButton: {
      border: '2px solid #0947B9',
      boxSizing: 'border-box',
      borderRadius: '4px',
      color: '#0947B9',
    },
    dropzoneStyling: {
      width: '100%',
      flexShrink: 0,
      background: '#F9F9F9',
      mixBlendMode: 'normal',
      border: '2px dashed #949494',
      boxSizing: 'border-box',
      borderRadius: '4px',
      paddingBottom: 48,
      marginTop: 10,
      marginBottom: 10,
    },
    subtitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '21px',
      textAlign: 'left',
      textTransform: 'capitalize',
      color: '#464546',
    },
    file: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    fileText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '13px',
      textAlign: 'center',
      textTransform: 'none',
      color: '#949494', // Gray 400
      paddingLeft: 8,
    },
  }),
);

export default function UploadFloorPlanDialog(props: Props) {
  const classes = useStyles();
  const { open, onClose, defaultBuildingId } = props;

  const dispatch = useDispatch();
  const project = useSelector(getProjectState);
  const buildings = useSelector(getPhysicalBuildingsState);
  const floors = useSelector(getPhysicalFloorsState);

  const [selectedBuildingId, setSelectedBuildingId] = useState('');
  const [selectedFloorIds, setSelectedFloorIds] = useState<string[]>([]);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const eligibleBuildings = buildings.filter((b) => !b.isDefault);

  const eligibleFloors = floors.filter((f) => f.buildingId === selectedBuildingId);
  const selectedFloors = selectedFloorIds
    .map((id) => eligibleFloors.find((f) => f.id === id))
    .filter((f) => f) as IPhysicalFloor[];

  const selectedFloorsHaveFloorplans = selectedFloors.some((f) => f?.floorPlanDrawingFileId);
  const selectedFloorsHaveLocations = selectedFloors
    .filter((f) => f?.floorPlanDrawingFileId)
    .some((f) => f?.locations?.length);
  const selectedFloorFloorplan = selectedFloors[0]?.floorPlanDrawingFile;

  useEffect(() => {
    if (defaultBuildingId) setSelectedBuildingId(defaultBuildingId);
  }, [defaultBuildingId]);

  const getSubmitDisabled = () => {
    return !uploadedFile || selectedFloorIds.length === 0;
  };

  const handleUploadAgain = () => {
    setUploadedFile(undefined);
    setSelectedFloorIds([]);
    setUploadProgress(0);
  };

  const resetState = () => {
    setUploadedFile(undefined);
    setSelectedBuildingId('');
    setSelectedFloorIds([]);
    setUploadProgress(0);
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleSubmit = async () => {
    if (!uploadedFile || !project?.id || selectedFloorIds.length === 0) return;

    const numFloorsWithoutFloorplansInThisBuilding = floors.filter(
      (f) => f.buildingId === selectedBuildingId && !f.floorPlanDrawingFileId,
    ).length;

    setIsLoading(true);

    const { file } = await insertFloorPlanDrawingByProjectId(
      project.id,
      {
        fullFileName: uploadedFile.name,
        useMultiPartUpload: uploadedFile.size > MULTI_PART_FILE_SIZE,
      },
      uploadedFile,
      handleUploadProgress,
    );

    if (file) {
      await editManyPhysicalFloors(selectedFloorIds, { floorPlanDrawingFileId: file.id });
    }

    dispatch(reloadPunchListLocations());
    setIsLoading(false);
    if (selectedFloorIds.length < numFloorsWithoutFloorplansInThisBuilding) {
      handleUploadAgain();
    } else {
      handleClose();
    }
  };

  const getFloorLabel = (floor: IPhysicalFloor) => {
    if (!floor.floorPlanDrawingFile) return floor.name;
    return floor.name + ' — ' + floor.floorPlanDrawingFile.name;
  };

  const getFloorOptionDisabled = (floor: IPhysicalFloor) => {
    return (
      selectedFloors.length > 0 &&
      selectedFloors.some((f) => f?.floorPlanDrawingFileId !== floor.floorPlanDrawingFileId)
    );
  };

  const dropzoneElement = (
    <>
      <div className={classes.dropzoneStyling}>
        <Dropzone
          accept="application/pdf"
          maxFiles={1}
          onDropAccepted={(files) => {
            setUploadedFile(files[0]);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div style={{ outline: 'none' }} {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p className={classes.dragDropText} style={{ paddingTop: 32, paddingBottom: 16 }}>
                Drag &amp; Drop file here
              </p>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <p className={classes.dragDropText}>or</p>
                <div style={{ width: 16 }} />
                <Button className={classes.browseFileButton}>
                  <Add />
                  Browse My Computer
                </Button>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      {uploadedFile && (
        <div className={classes.file}>
          <IconButton
            onClick={() => setUploadedFile(undefined)}
            style={{ padding: '0px 8px 0px 0px' }}
          >
            <Clear />
          </IconButton>
          <PDFIcon />
          <Typography className={classes.fileText}>{uploadedFile.name}</Typography>
        </div>
      )}
    </>
  );

  return (
    <Dialog open={open} PaperProps={{ style: { maxWidth: 450 } }}>
      <DialogTitle disableTypography className={classes.dialogTitle} style={{ paddingBottom: 0 }}>
        UPLOAD A FLOORPLAN
        <IconButton
          style={{ right: '5%', top: '3%', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography style={{ paddingBottom: 8 }}>Select a building.</Typography>
        <Select
          value={selectedBuildingId}
          onChange={(e) => setSelectedBuildingId(e.target.value as string)}
          style={{ width: '100%', height: 30 }}
          fullWidth
          variant="outlined"
          displayEmpty
        >
          <MenuItem disabled value="">
            Select a building
          </MenuItem>
          {eligibleBuildings.map((building) => (
            <MenuItem key={building.id} value={building.id}>
              {building.name}
            </MenuItem>
          ))}
        </Select>
        <Typography style={{ paddingBottom: 8, paddingTop: 8 }}>
          Select each floor or area that will use this floor plan.
        </Typography>
        <Select
          multiple
          value={selectedFloorIds}
          onChange={(e) => setSelectedFloorIds(e.target.value as string[])}
          style={{ width: '100%', height: 30 }}
          fullWidth
          disabled={!selectedBuildingId}
          variant="outlined"
          displayEmpty
          renderValue={(value) => {
            const values = value as string[];
            if (values.length === 0) return 'Select floors';
            if (values.length > 2) return `${values.length} floors selected`;
            return values.map((v) => eligibleFloors.find((f) => f.id === v)?.name).join(', ');
          }}
          // @ts-ignore
          MenuProps={{ getContentAnchorEl: () => null }}
        >
          {eligibleFloors.map((floor) => (
            <MenuItem key={floor.id} value={floor.id} disabled={getFloorOptionDisabled(floor)}>
              <LabelWithCheckbox
                label={getFloorLabel(floor)}
                checked={selectedFloorIds.includes(floor.id)}
                onClick={() => {}}
                labelStyle={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
              />
            </MenuItem>
          ))}
        </Select>
        {selectedFloors.length > 0 && (
          <>
            {!selectedFloorsHaveFloorplans ? (
              <>
                <Typography style={{ paddingBottom: 8, paddingTop: 8 }}>
                  Upload one floorplan at a time.
                </Typography>
                <span className={classes.subtitle}>Attach Your File</span>
                {dropzoneElement}
              </>
            ) : (
              <>
                <Typography style={{ paddingTop: 8 }}>
                  The file <strong>{selectedFloorFloorplan?.name}</strong> has already been
                  associated with these floors: <br />
                </Typography>
                <Typography style={{ marginTop: 16, marginBottom: 16 }}>
                  <strong>{selectedFloors.map((f) => f.name).join(', ')}</strong>
                </Typography>
                {!selectedFloorsHaveLocations ? (
                  <>
                    <Typography>Do you want to replace this file?</Typography>
                    {dropzoneElement}
                  </>
                ) : (
                  <>
                    <Typography>
                      One or more of these floors already has defined locations, therefore the
                      associated file cannot be replaced.
                    </Typography>
                  </>
                )}
              </>
            )}
          </>
        )}
        {!isLoading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: 16,
              paddingBottom: 16,
            }}
          >
            <CancelButton onClick={handleClose} />
            <Button
              variant="contained"
              color="primary"
              disabled={getSubmitDisabled()}
              style={{ padding: '3px 30px' }}
              onClick={handleSubmit}
            >
              SUBMIT
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: 16,
              paddingBottom: 16,
            }}
          >
            <Typography style={{ marginBottom: 4, marginTop: 4 }}>{uploadProgress}%</Typography>
            <LinearProgress
              variant="determinate"
              value={uploadProgress}
              style={{ height: 6, width: '100%' }}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
