import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getDocumentId } from '../../features/document/selectors';
import FullscreenLoader from '../loader/FullscreenLoader';
import DocumentDetailsContent from './DocumentDetailsContent';
import {
  DocumentMatchParams,
  documentTypeToUrl,
  isPublicPage,
  PublicDocumentMatchParams,
  showDocumentDisplay,
  urlToDocumentType,
} from '../../scripts/utils';
import { fetchDocument, setDocument } from '../../features/document/actions';
import { getProjectState } from '../../features/project/selectors';
import { fetchProject } from '../../features/project/actions';
import { getDocumentsState, getDocumentsType } from '../../features/documents/selectors';
import { fetchDocumentsByType } from '../../features/documents/actions';
import { DocumentTemplateType, INumberedDocumentView } from '../../api-client/autogenerated';
import DocumentDisplay from '../document-display/DocumentDisplay';
import {
  getDocumentLoadingState,
  getDocumentsLoadingState,
} from '../../features/loading/selectors';
import { getUserState } from '../../features/user/selectors';
import { getBidState } from '../../features/bid/selectors';
import { fetchBid, setBid } from '../../features/bid/actions';
import { Auth } from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';
import CreatePunchListPage from '../punch-lists/CreatePunchListPage';
import { useMediaQuery } from '@material-ui/core';

export default function DocumentDetailsPage(props: { indexDrawerOpen: boolean }) {
  const { indexDrawerOpen } = props;
  const params = useParams<DocumentMatchParams>();
  const publicParams = useParams<PublicDocumentMatchParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const documentsType = useSelector(getDocumentsType);
  const documentId = useSelector(getDocumentId);
  const bid = useSelector(getBidState);
  const documentLoading = useSelector(getDocumentLoadingState);
  const documentsLoading = useSelector(getDocumentsLoadingState);
  const project = useSelector(getProjectState);
  const documents = useSelector(getDocumentsState);
  const user = useSelector(getUserState);
  const [visible, setVisible] = useState(false);

  const isMobile = useMediaQuery('(max-width:600px)');

  //Test code for quickly assigning a subcontractor
  /*
  useEffect(() => {
    editSubcontractorByDocumentId(
      '14087646-a9ae-44a5-b490-0b710f32cced',
      dayjs().toISOString(),
      'test@test.com',
    );
  }, []);
  */

  // getContent will return a DocumentDetailsContent component, passing it the relevant props per document type
  const getContent = () => {
    if (bid) {
      return <DocumentDetailsContent />;
    }
    switch (documentsType) {
      case DocumentTemplateType.CloseoutSubmittals:
      case DocumentTemplateType.Submittals:
        return <DocumentDetailsContent enableCurrentlyResponsible enableAdditionalReview />;

      case DocumentTemplateType.PotentialChangeOrders:
      case DocumentTemplateType.RequestsForInformation:
      case DocumentTemplateType.RequestsForChange:
      case DocumentTemplateType.PayApplications:
      case DocumentTemplateType.DesignPackages:
        return <DocumentDetailsContent enableCurrentlyResponsible enableAdditionalReview isRfi />;

      case DocumentTemplateType.WorkChangeProposalRequests:
        return <DocumentDetailsContent enableCurrentlyResponsible isWcpr />;

      case DocumentTemplateType.OperationsAndMaintenanceData:
      case DocumentTemplateType.AsBuilt:
        return <DocumentDetailsContent enableCurrentlyResponsible />;
      case DocumentTemplateType.Task:
        return <DocumentDetailsContent enableCurrentlyResponsible isRfi />;

      case DocumentTemplateType.PunchList:
        return <CreatePunchListPage mode="PUBLIC" />;

      // Base document
      case DocumentTemplateType.Specifications ||
        DocumentTemplateType.Addenda ||
        DocumentTemplateType.Drawings ||
        DocumentTemplateType.ChangeOrders ||
        DocumentTemplateType.InformationalItems ||
        DocumentTemplateType.PlanholderList ||
        DocumentTemplateType.RegulatoryApprovals ||
        DocumentTemplateType.CertificateOfSubstantialCompletion ||
        DocumentTemplateType.ClearLp ||
        DocumentTemplateType.PunchList ||
        DocumentTemplateType.WarrantyItems ||
        DocumentTemplateType.BidderRfIs ||
        DocumentTemplateType.SubstitutionRequests:
        return <DocumentDetailsContent />;
      // Base workflow document
      default:
        return <DocumentDetailsContent enableCurrentlyResponsible />;
    }
  };

  const isLoading = () => {
    if (!isPublicPage()) {
      if (bid) return false;
      return (
        documents.length === 0 ||
        !documentId ||
        !project ||
        documentId !== params.documentId ||
        documentLoading ||
        documentsType !== urlToDocumentType[params.type]
      );
    }
    return documentId === null;
  };

  useEffect(() => {
    if (!isPublicPage()) {
      if (
        (urlToDocumentType[params.type] as DocumentTemplateType) ===
        DocumentTemplateType.BidTabulation
      ) {
        dispatch(setDocument(null));
        dispatch(fetchBid(params.documentId));
        if (!project || project?.id !== params.projectId) dispatch(fetchProject(params.projectId));
      } else {
        dispatch(setBid(null));
        if (!project || project?.id !== params.projectId) dispatch(fetchProject(params.projectId));
        if ((!documentId || documentId !== params.documentId) && !documentLoading)
          dispatch(fetchDocument(params.documentId));
        if (
          project &&
          (documents.length === 0 || documentsType !== urlToDocumentType[params.type]) &&
          !documentsLoading
        )
          dispatch(
            fetchDocumentsByType(
              project.id,
              urlToDocumentType[params.type] as DocumentTemplateType,
            ),
          );
      }
    } else {
      if ((!documentId || params.documentId !== documentId) && !documentLoading) {
        dispatch(fetchDocument(publicParams.key, true)).then(
          (result: { payload?: INumberedDocumentView }) => {
            Auth.currentAuthenticatedUser()
              .then((data) => {
                if (
                  data.attributes.sub === sessionStorage.getItem('publicUserId') &&
                  result.payload?.documentTemplate?.name
                ) {
                  const documentTemplate = result.payload.documentTemplate
                    .name as DocumentTemplateType;
                  if (documentTemplate === DocumentTemplateType.PunchList) {
                    history.push(
                      `/main/projects/${result.payload.projectId}/documents/${documentTypeToUrl[documentTemplate]}/${result.payload.id}/edit`,
                      { publicRedirect: true },
                    );
                  } else {
                    history.push(
                      `/main/projects/${result.payload.projectId}/documents/${documentTypeToUrl[documentTemplate]}/${result.payload.id}`,
                      { publicRedirect: true },
                    );
                  }
                }
              })
              .catch(() => {});
          },
        );
      }
    }
  }, [project, documentId, params, documentsType, user]);

  return (
    <>
      {!isMobile && showDocumentDisplay(params.type) ? (
        <DocumentDisplay
          isDocumentDetailsPage
          indexDrawerOpen={indexDrawerOpen}
          visible={visible}
          setVisible={setVisible}
        />
      ) : null}
      <div style={{ overflowX: 'auto', width: '100%' }}>
        {isLoading() ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 48,
            }}
          >
            <FullscreenLoader />
          </div>
        ) : (
          getContent()
        )}
      </div>
    </>
  );
}
