import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import { Card, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: 'none', // shadow override to fix misplaced shadow on the countdown
    overflow: 'visible',
  },
  titleStyle: {
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
  },
  projects: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '44px',
    lineHeight: '52px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.6px',
    color: '#464546',
    alignSelf: 'center',
    paddingRight: 16,
  },
  openProjects: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '18px',
    letterSpacing: '1.21333px',
    textTransform: 'uppercase',
    color: '#464546',
    alignSelf: 'center',
    marginTop: 20,
  },
  flexRow: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    alignSelf: 'center',
  },
  pieChartContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    padding: '20px 0px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  createNewProject: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    background: '#0947b9',
    cursor: 'pointer',
    // '&:hover': {
    //   color: '#0947b9',
    // },
  },
}));

type DashboardBlockProps = {
  title: string;
  children: React.ReactNode;
};

export default function DashboardBlock({ title, children }: DashboardBlockProps) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.titleStyle}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <h2
              className="h2"
              style={{
                textAlign: 'left',
                color: '#FFFFFF',
                margin: 0,
              }}
            >
              {title}
            </h2>
          </Grid>
        </Grid>
      </CardContent>
      {children}
    </Card>
  );
}
