import React from 'react';

interface DownloadIconProps {
  width?: number;
  height?: number;
  marginBottom?: number;
  marginRight?: number;
  disabled?: boolean;
}

const DownloadIcon = (props: DownloadIconProps | any) => {
  const { width = 18, height = 18, marginBottom = 2, marginRight = 4, disabled, ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      style={{ marginBottom, marginRight }}
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40786 13.8476V18.4163H18.5932V13.8476C18.5932 13.1891 19.1316 12.6558 19.7965 12.6558C20.4613 12.6558 20.9998 13.1891 20.9998 13.8476V18.5717C21 19.8006 19.9907 20.8002 18.7501 20.8002H2.25057C1.00989 20.8002 0 19.8006 0 18.5717V13.8476C0 13.1891 0.53949 12.6558 1.20393 12.6558C1.86816 12.6558 2.40786 13.1891 2.40786 13.8476Z M11.3507 14.0887L16.2878 9.19859C16.758 8.73267 16.758 7.97784 16.2878 7.51234C15.8184 7.04642 15.0559 7.04642 14.5862 7.51234L11.7436 10.328V1.19163C11.7436 0.533312 11.2052 0 10.5403 0C9.87545 0 9.3368 0.533312 9.3368 1.19163V10.4081L6.41465 7.51234C5.94446 7.04642 5.18216 7.04642 4.71197 7.51234C4.47698 7.74509 4.35938 8.05064 4.35938 8.35536C4.35938 8.66008 4.47698 8.96605 4.71197 9.1988L9.64928 14.0889C9.87461 14.3127 10.1806 14.4383 10.5 14.4383C10.8194 14.4383 11.1252 14.3127 11.3507 14.0887Z"
        fill={disabled ? '#bdbdbd' : '#7A797A'}
        {...rest}
      />
    </svg>
  );
};

export default DownloadIcon;
